"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.channelArchivesList = channelArchivesList;
exports.checkApplyVerify = checkApplyVerify;
exports.exportHdFlAll = exportHdFlAll;
exports.exportNdFlAll = exportNdFlAll;
exports.exportRebateFlowMonth = exportRebateFlowMonth;
exports.getActiveFlRecord = getActiveFlRecord;
exports.getBlackList = getBlackList;
exports.getCashHx = getCashHx;
exports.getCheckApplyLog = getCheckApplyLog;
exports.getDebateParam = getDebateParam;
exports.getFlHdList = getFlHdList;
exports.getFlNdList = getFlNdList;
exports.getFlZcList = getFlZcList;
exports.getHdFlZh = getHdFlZh;
exports.getNdFlRecord = getNdFlRecord;
exports.getNdFlZh = getNdFlZh;
exports.getQdDJ = getQdDJ;
exports.getQdFl = getQdFl;
exports.getRebateAccumulative = getRebateAccumulative;
exports.getRebateAccumulativeFlowDetail = getRebateAccumulativeFlowDetail;
exports.getRebateFlowMonth = getRebateFlowMonth;
exports.getRebateRangeById = getRebateRangeById;
exports.getRebateSchemeById = getRebateSchemeById;
exports.getRebateSchemeConfig = getRebateSchemeConfig;
exports.getRebateSchemeProBySchemeId = getRebateSchemeProBySchemeId;
exports.getRebateSurplus = getRebateSurplus;
exports.getRebateSurplusFlowDetail = getRebateSurplusFlowDetail;
exports.getRebateSurplusTotal = getRebateSurplusTotal;
exports.getScanCodes = getScanCodes;
exports.getScanYearCodes = getScanYearCodes;
exports.getVerifyApplyList = getVerifyApplyList;
exports.getVerifyDetail = getVerifyDetail;
exports.getWarnCardRecord = getWarnCardRecord;
exports.passFlSh = passFlSh;
exports.rejectFlSh = rejectFlSh;
exports.saveBlackList = saveBlackList;
exports.saveDebateParam = saveDebateParam;
exports.saveHdFlData = saveHdFlData;
exports.scanCodeDetail = scanCodeDetail;
exports.stopHdList = stopHdList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 *  活动返利流水
 * **/
function getActiveFlRecord(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeMonth',
    method: 'post',
    params: param
  });
}

/**
 * 获取参数
 * **/

function getDebateParam() {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateAllocation/getRebateAllocation',
    method: 'get'
  });
}

/***
* 保存参数
**/
function saveDebateParam(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateAllocation/conservationRebateAllocation',
    method: 'post',
    data: data
  });
}

/****
* 获取返利活动列表
**/
function getFlHdList(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getMonthRebateScheme?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
 * 获取返利年度列表
 * **/

function getFlNdList(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getYearRebateScheme?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/***
*根据pid获取活动方案详情
**/
function getRebateSchemeById(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getRebateSchemeById',
    method: 'get',
    params: param
  });
}

/***
* 获取返利活动产品
**/
function getRebateSchemeProBySchemeId(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemePro/getRebateSchemeProBySchemeId',
    method: 'get',
    params: param
  });
}

/**
 * 获取返利范围
 * **/
function getRebateRangeById(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeOrg/getRebateSchemeOrgBySchemeId',
    method: 'get',
    params: param
  });
}

/**
 * 获取返利方案配置
**/
function getRebateSchemeConfig(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeConfig/getRebateSchemeConfig',
    method: 'get',
    params: param
  });
}

/**
 * 获取黑名单
**/

function getBlackList(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateBlacklist/getRebateBlacklist',
    method: 'get',
    params: param
  });
}

/**
 * 保存黑名单
 * **/
function saveBlackList(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateBlacklist/conservationRebateBlacklist',
    method: 'post',
    data: data
  });
}

/**
 *  活动返利批量终止
 * **/

function stopHdList(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/stop',
    method: 'put',
    params: param
  });
}

/***
* 获取渠道分类
* ***/

function getQdFl(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelClass/getList',
    method: 'post',
    params: param
  });
}

/***
* 获取渠道等级
* ***/

function getQdDJ(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelGrade/getList',
    method: 'post',
    params: param
  });
}

/**
* 返利政策
* **/

function getFlZcList(param) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/pageList',
    method: 'post',
    params: param
  });
}

/***
* 保存返利活动数据
**/

function saveHdFlData(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/saveSubmissionRebateScheme',
    method: 'post',
    data: data
  });
}

/***
* 审核通过
**/

function passFlSh(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/auditApproved',
    method: 'put',
    params: param
  });
}

/***
* 审核拒绝
**/

function rejectFlSh(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/refusalToReview',
    method: 'put',
    params: param
  });
}

/***
 * 获取返利活动账户
**/

function getHdFlZh(param) {
  return (0, _request.default)({
    url: '/api-rebate/flRebateAccount/rebateAccountMonth',
    method: 'post',
    params: param
  });
}

/***
 * 获取年度返利活动账户
**/

function getNdFlZh(param) {
  return (0, _request.default)({
    url: '/api-rebate/flRebateAccount/rebateAccountYear',
    method: 'post',
    params: param
  });
}

/***
* 经销商核算审核
***/

function getVerifyApplyList(param) {
  return (0, _request.default)({
    url: '/api-rebate/checkApply/getVerifyApplyList',
    method: 'post',
    params: param
  });
}

/**
 *  获取经销商审核详情 
 * ***/

function getVerifyDetail(param) {
  return (0, _request.default)({
    url: '/api-rebate/checkApply/getCheckApplyDetail',
    method: 'get',
    params: param
  });
}

/**
 *  核算核销日志
 * ****/

function getCheckApplyLog(param) {
  return (0, _request.default)({
    url: '/api-rebate/checkApply/getCheckApplyLog',
    method: 'get',
    params: param
  });
}

/***
  核算核销审核
***/
function checkApplyVerify(param) {
  return (0, _request.default)({
    url: '/api-rebate/checkApply/checkApplyVerify',
    method: 'put',
    params: param
  });
}

/****
  活动返利流水导出
***/
function exportHdFlAll(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeMonthExport',
    responseType: 'blob',
    method: 'get',
    params: param
  });
}

/***
  年度返利流水记录
**/

function getNdFlRecord(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeYear',
    method: 'post',
    params: param
  });
}

/****
  年度返利流水导出
***/
function exportNdFlAll(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeYearExport',
    responseType: 'blob',
    method: 'get',
    params: param
  });
}

/***
  分润收支明细
**/
function getRebateFlowMonth(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowMonth',
    method: 'post',
    params: param
  });
}

/***
  分润明细导出
**/
function exportRebateFlowMonth(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowExport',
    responseType: 'blob',
    method: 'get',
    params: param
  });
}

/****
  获取酒券使用记录
***/
function getWarnCardRecord(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateUse',
    method: 'post',
    params: param
  });
}

/***
  获取渠道,供应商
**/
function channelArchivesList(data) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/selectChannelAndOrg',
    method: 'post',
    data: data
  });
}

/***
  获取查看月度数码
**/

function getScanCodes(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeDetailMonth?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/****
  获取查看年度数码
***/

function getScanYearCodes(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateFlowCodeDetailYear',
    method: 'post',
    params: param
  });
}

/***
  活动结余表
***/
function getRebateSurplus(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateSurplus',
    method: 'post',
    params: param
  });
}

/*****
  活动结余表统计
***/
function getRebateSurplusTotal(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateSurplusTotal',
    method: 'post',
    params: param
  });
}

/****
  返利累计统计表
***/

function getRebateAccumulative(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateAccumulative',
    method: 'post',
    params: param
  });
}

/***
返利结余明细
   ***/

function getRebateSurplusFlowDetail(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateSurplusFlowDetail',
    method: 'post',
    params: param
  });
}

/****
 返利累计明细
***/

function getRebateAccumulativeFlowDetail(param) {
  return (0, _request.default)({
    url: '/api-rebate/rebateStatistics/rebateAccumulativeFlowDetail',
    method: 'post',
    params: param
  });
}

/**
* 现金核销
* **/

function getCashHx(param) {
  return (0, _request.default)({
    url: '/api-rebate/flCashRecords/getCashRecordList',
    method: 'post',
    params: param
  });
}

//详情查看数码
function scanCodeDetail(param) {
  return (0, _request.default)({
    url: '/api-rebate/flRebateRecord/getBarCodeByRnAndCa',
    method: 'get',
    params: param
  });
}