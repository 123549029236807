"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
var _detailsMzfn = _interopRequireDefault(require("@/components/detailsMzfn"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentersaleorderCcdetail',
  components: {
    detailsMzfn: _detailsMzfn.default
  },
  filters: {
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary',
          9: 'primary'
        };
        return statusMap[value];
      }
    },
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '\xa0';
      } else {
        var statusMap = {
          1: '创建订单',
          2: '预审通过',
          3: '预审不通过',
          4: '确款审核通过',
          5: '确款审核不通过',
          6: '修改订单'
        };
        return statusMap[value];
      }
    },
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '\xa0';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      n = String((n * 1).toFixed(2));
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      mzPid: '',
      mzFShow: false,
      cardPadding: {
        padding: '0 0 20px'
      },
      activeName: '',
      totalZp: 0.0,
      orderStatus: [],
      productList: [],
      zpList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      loading: false,
      auditLoading: false
    };
  },
  computed: {
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentersaleorder-ccindex'
      });
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$router.go(-1);
    },
    showMzF: function showMzF(row) {
      this.mzPid = row.activityId;
      this.mzFShow = true;
    },
    mzFClose: function mzFClose() {
      this.mzPid = '';
      this.mzFShow = false;
    },
    toogleExpand: function toogleExpand(idx) {
      if (this.productList[idx].giftProductAOS.length) {
        var $table = this.$refs.addTable;
        for (var i = 0; i < this.productList.length; i++) {
          if (idx != i) {
            $table.toggleRowExpansion(this.productList[i], false);
          }
        }
        $table.toggleRowExpansion(this.productList[idx]);
      } else {
        this.$message.info('当前产品无赠品');
      }
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this2 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this2.addData = res.data.data;
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              parentDetailId: item.parentDetailId,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum,
              activityName: item.activityName,
              activityId: item.activityId
            };
            if (item.productType == 3) {
              obj.winePrice = item.productPrice;
              obj.wineTotalPrice = item.productAmount || 0;
              zpArr.push(obj);
              console.log(zpArr, 'zp');
            } else {
              proArr.push(obj);
            }
          });
          _this2.productList = (0, _utils.transTreeData)(proArr, 'pid', 'parentDetailId', 'giftProductAOS');
          _this2.zpList = zpArr;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      }).then(function () {
        if (_this2.addData.tradeStatus > 1) {
          _this2.auditLoading = true;
          (0, _order.getAuditLog)({
            orderNo: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this2.auditList = res.data.data;
            } else {
              _this2.$message.error('审核日志获取失败');
            }
            _this2.auditLoading = false;
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;