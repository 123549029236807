"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
require('echarts/map/js/china');
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '600px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  // props: ['chartData'],
  data: function data() {
    return {
      chart: null,
      ac: [{
        'name': '北京',
        'value': 599
      }, {
        'name': '上海',
        'value': 142
      }, {
        'name': '黑龙江',
        'value': 44
      }, {
        'name': '深圳',
        'value': 92
      }, {
        'name': '湖北',
        'value': 810
      }, {
        'name': '四川',
        'value': 453
      }]
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions(val) {
      this.chart.setOption({
        // 进行相关配置
        // backgroundColor: '#ff7f50',
        title: {
          text: '会员省市分布',
          left: 'center',
          textStyle: {
            color: '#000'
          }
        },
        tooltip: {},
        // 鼠标移到图里面的浮动提示框

        visualMap: [
        // 左下角的颜色区域
        // { type: 'piecewise', // 定义为分段型 visualMap

        //   itemWidth: 40,
        //   bottom: 60,
        //   left: 20,
        //   pieces: [ // 自定义『分段式视觉映射组件（visualMapPiecewise）』的每一段的范围，以及每一段的文字，以及每一段的特别的样式
        //     { gt: 900, lte: 1000, label: '非常好', color: '#6ad86e' }, // (900, 1000]
        //     { gt: 500, lte: 900, label: '正常', color: '#9adcfa' }, // (500, 900]
        //     { gt: 310, lte: 500, label: '警告', color: '#ffeb3b' }, // (310, 500]
        //     { gt: 200, lte: 300, label: '较差', color: '#ff9800' }, // (200, 300]
        //     { gt: 10, lte: 200, label: '非常差', color: 'orangered' }, // (10, 200]
        //     { value: 0, label: '无数据', color: '#999' } // [0]
        //   ] },
        {
          min: 0,
          max: 50,
          right: 30,
          top: 40,
          type: 'continuous',
          orient: 'horizontal',
          align: 'bottom',
          realtime: false,
          calculable: true,
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        }],
        geo: {
          // 这个是重点配置区
          map: 'china',
          // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true,
              // 是否显示对应地名
              textStyle: {
                color: 'rgba(0,0,0,0.4)'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              shadowBlur: 20,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [{
          type: 'scatter',
          coordinateSystem: 'geo' // 对应上方配置
        }, {
          name: '会员人数',
          // 浮动框的标题
          type: 'map',
          geoIndex: 0,
          data: val
        }
        // {
        //   name: 'barSer',
        //   type: 'bar',
        //   roam: false,
        //   visualMap: false,
        //   zlevel: 2,
        //   barMaxWidth: 20,
        //   itemStyle: {
        //     normal: {
        //       color: '#40a9ed'
        //     },
        //     emphasis: {
        //       color: '#3596c0'
        //     }
        //   },
        //   label: {
        //     normal: {
        //       show: false,
        //       position: 'right',
        //       offset: [0, 10]
        //     },
        //     emphasis: {
        //       show: true,
        //       position: 'right',
        //       offset: [10, 0]
        //     }
        //   },
        //   data: this.ac
        // }
        ]
      });
    }
  }
};
exports.default = _default;