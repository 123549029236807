"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dirVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    isShow: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      title: '',
      listLoading: false,
      dialogVisible: false,
      mloading: false,
      btnLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        isEnable: 1,
        name: ' ',
        pid: this.id
      },
      ruleForm: {
        bewrite: '',
        figure: '',
        dataDictionary: this.id,
        isDelete: 1,
        isEnable: 1,
        name: '',
        sequenceNumber: ''
      },
      rules: {
        name: [{
          required: true,
          message: '名称不能为空，请维护',
          trigger: 'change'
        }],
        figure: [{
          required: true,
          message: '数据值不能为空，请维护',
          trigger: 'change'
        }]
      },
      tableData: [],
      normalList: [],
      brandList: [],
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.listLoading = true;
      (0, _dictionary.getDataDictionaryItemList)(that.searchForm).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        isEnable: 1,
        name: ' ',
        pid: this.id
      };
      this.filterText = '';
      this.getList();
    },
    selCancel: function selCancel() {
      this.$emit('close');
    },
    add: function add() {
      this.title = '新增';
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.title = '编辑';
      this.ruleForm = {
        pid: row.pid,
        bewrite: row.bewrite,
        figure: row.figure,
        dataDictionary: this.id,
        isEnable: row.isEnable,
        name: row.name,
        sequenceNumber: row.sequenceNumber
      };
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        bewrite: '',
        figure: '',
        dataDictionary: this.id,
        isDelete: 1,
        isEnable: 1,
        name: '',
        sequenceNumber: ''
      };
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var submitData = (0, _utils.filterKeyNull)(_this2.ruleForm);
          if (_this2.ruleForm.pid) {
            (0, _dictionary.updateDataDictionaryItem)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          } else {
            (0, _dictionary.addDataDictionaryItem)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          }
        }
      });
    },
    del: function del(row) {
      var _this3 = this;
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _dictionary.delDataDictionaryItem)({
          pid: row.pid
        }).then(function (res) {
          if (res.data.code != 200) {
            _this3.$message.error(res.data.msg);
          } else {
            _this3.$message({
              message: '操作成功',
              type: 'success'
            });
            _this3.getList();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default2;