"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _tgItem = require("@/api/chanelmange/tgItem");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        name: '',
        archivesStatus: 1,
        pageSize: 10,
        pageNo: 1,
        groupSysType: 0
      },
      tableData: [],
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(val) {
      var _this = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      submitData.groupSysType = this.type;
      (0, _tgItem.getGroupList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        name: '',
        archivesStatus: 1,
        pageSize: 10,
        pageNo: 1
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.searchForm = {
        name: '',
        archivesStatus: 1,
        pageSize: 10,
        pageNo: 1
      };
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;