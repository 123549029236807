"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchTagging = batchTagging;
exports.batchUnTagging = batchUnTagging;
exports.ccrrTag = ccrrTag;
exports.createGroup = createGroup;
exports.createNews = createNews;
exports.createNewsList = createNewsList;
exports.createSend = createSend;
exports.deleteDataByHfId = deleteDataByHfId;
exports.deleteFiles = deleteFiles;
exports.deleteFollowReply = deleteFollowReply;
exports.deleteGroup = deleteGroup;
exports.deleteNews = deleteNews;
exports.findKeyword = findKeyword;
exports.getAllTag = getAllTag;
exports.getDeleteuser = getDeleteuser;
exports.getGroup = getGroup;
exports.getMedia = getMedia;
exports.getSearchuser = getSearchuser;
exports.getUserCode = getUserCode;
exports.getgzhlist = getgzhlist;
exports.getsearchDetail = getsearchDetail;
exports.getsearchMsg = getsearchMsg;
exports.insertKeyWordReply = insertKeyWordReply;
exports.materialVideoInfo = materialVideoInfo;
exports.materialVoiceDownload = materialVoiceDownload;
exports.moWxTemplate = moWxTemplate;
exports.modeleteById = modeleteById;
exports.mosave = mosave;
exports.mosearch = mosearch;
exports.moselectById = moselectById;
exports.motemsearch = motemsearch;
exports.postReply = postReply;
exports.saveReply = saveReply;
exports.searchByType = searchByType;
exports.searchResource = searchResource;
exports.selectByKeyWordId = selectByKeyWordId;
exports.selectKeyWordBut = selectKeyWordBut;
exports.selectNews = selectNews;
exports.tWsearch = tWsearch;
exports.updateFiles = updateFiles;
exports.updateGroup = updateGroup;
exports.updateKeyWordBut = updateKeyWordBut;
exports.updateKeyWordReply = updateKeyWordReply;
exports.updateNews = updateNews;
exports.updateNewsNew = updateNewsNew;
exports.updateTaskById = updateTaskById;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
var baseUrl = '';
// 获取公众号
function getgzhlist(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxMpConfig/list',
    // url: 'http://testgw.panpass.cn/api/v1/api-memberscrm/member/admin/wxMpConfig/list',
    headers: {
      'appId': 1
    },
    timeout: 60000,
    method: 'get',
    params: params
  });
}
// 自动回复
function saveReply(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/updateFollowReply',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 删除回复信息
function deleteFollowReply(data, argument) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/deleteFollowReply?appId=' + argument,
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 获取保存信息
function searchByType(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/searchByType',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 视频播放
function materialVideoInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/materialVideoInfo',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 语音播放
function materialVoiceDownload(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/materialVoiceDownload',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    responseType: 'blob',
    method: 'get',
    params: params
  });
}
// 保存关键词回复开关
function updateKeyWordBut(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/updateKeyWordBut',
    method: 'get',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    params: params
  });
}
// 获取关键词回复开关
function selectKeyWordBut(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/selectKeyWordBut',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 关键词回显
function selectByKeyWordId(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/selectByKeyWordId',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 删除关键字
function deleteDataByHfId(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/deleteDataByHfId',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'delete',
    params: params
  });
}
// 关键词修改
function updateKeyWordReply(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/updateKeyWordReply',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 修改任务值
function updateTaskById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/updateTaskByIds',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}
// 查询分组
function getGroup(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/group/search',
    method: 'get',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 创建分组
function createGroup(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/group/createGroup',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    params: params
  });
}

// 删除分组
function deleteGroup(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/group/deleteGroup',
    method: 'DELETE',
    params: params
  });
}

// 修改分组
function updateGroup(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/group/updateGroup',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    params: params
  });
}

// 创建图文
function createNews(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/createNews',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 创建多图文
function createNewsList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/createNewsList',
    method: 'post',
    timeout: 60000,
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 删除图文
function deleteNews(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/deleteNews',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'delete',
    params: params
  });
}

// 修改图文
function updateNews(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/updateNews',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 修改图文新
function updateNewsNew(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/updateNewsNew',
    method: 'post',
    timeout: 60000,
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 查询图文
function tWsearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    timeout: 60000,
    method: 'get',
    params: params
  });
}

// 查看图文
function selectNews(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/selectNewsById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    timeout: 60000,
    method: 'get',
    params: params
  });
}

// 修改文件名
function updateFiles(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/updateFiles',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 删除文件
function deleteFiles(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/deleteFiles',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'delete',
    params: params
  });
}

// 查询图片，语音，视频列表
function searchResource(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    timeout: 60000,
    method: 'get',
    params: params
  });
}

// 添加关键词
function insertKeyWordReply(data, argument) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxReply/insertKeyWordReply?appId=' + argument,
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 查询关键词
function findKeyword(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxKeyWord/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 创建标签
function ccrrTag(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/createTag',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    params: params
  });
}

// 获取所有标签
function getAllTag(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/getAllTag',
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/getAllTag',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 用户查询
function getSearchuser(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/search',
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 获取渠道信息
function getUserCode(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/getUserCode',
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/getUserCode',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 删除用户
function getDeleteuser(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/delete',
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/delete',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'delete',
    params: params
  });
}

// 打标签
function batchTagging(data, argument) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/batchTagging?tagId=' + argument,
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/batchTagging?tagId=' + argument,
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 删除标签
function batchUnTagging(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/userInfo/batchUnTagging',
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/batchUnTagging',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'delete',
    params: params
  });
}

// 消息列表查询
function getsearchMsg(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/search',
    url: baseUrl + '/api-memberscrm/member/admin/wxMsgInfo/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 消息详情查询
function getsearchDetail(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/searchDetail',
    url: baseUrl + '/api-memberscrm/member/admin/wxMsgInfo/searchDetail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 客服回复消息
function postReply(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/wxMsgInfo/reply',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    params: params
  });
}
// 获取临时素材
function getMedia(param) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/getMedia',
    url: baseUrl + '/api-memberscrm/member/admin/wxMsgInfo/getMedia',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    responseType: 'blob',
    params: param
  });
}

/**
 *
 * 模版消息
 */
// 模版列表查询
function mosearch(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplate/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 模版保存
function mosave(data) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplate/save',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 根据模板id查询数据
function moselectById(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplate/selectById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    params: params
  });
}

// 根据模板id删除数据
function modeleteById(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplate/deleteById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'DELETE',
    params: params
  });
}

// 模版群发记录查询
function motemsearch(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplateSendsRecord/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 模板列表查询
function moWxTemplate(params) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplate/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 创建群发
function createSend(data) {
  return (0, _request.default)({
    // url: 'http://testsaas.panpass.com/api-memberscrm/member/admin/wxMsgInfo/reply',
    url: baseUrl + '/api-memberscrm/member/admin/moWxTemplateSends/createSend',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}