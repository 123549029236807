var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orderNo", label: "订单编码:" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orderNo", $$v)
                                  },
                                  expression: "listQuery.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "sendOutComId",
                                label: "发货单位:",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { readonly: "" },
                                  model: {
                                    value: _vm.searchSendOutComName,
                                    callback: function ($$v) {
                                      _vm.searchSendOutComName = $$v
                                    },
                                    expression: "searchSendOutComName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCompany(
                                          "searchSendOutComName",
                                          2,
                                          11
                                        )
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "receiveComId",
                                    label: "采购单位:",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchReceiveComName,
                                        callback: function ($$v) {
                                          _vm.searchReceiveComName = $$v
                                        },
                                        expression: "searchReceiveComName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchReceiveComName",
                                              1,
                                              11
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderStartTime",
                                    label: "订单起始日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "请选择日期",
                                    },
                                    model: {
                                      value: _vm.listQuery.beginDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "beginDate",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.beginDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderEndTime",
                                    label: "订单结束日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "请选择日期",
                                    },
                                    model: {
                                      value: _vm.listQuery.endDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "endDate", $$v)
                                      },
                                      expression: "listQuery.endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderStatus",
                                    label: "状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.orderStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "orderStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.orderStatus",
                                      },
                                    },
                                    _vm._l(_vm.orderStatus, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.name,
                                          value: [item.figure],
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "260px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", icon: "el-icon-refresh" },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset(_vm.listQuery)
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n              "
                              ),
                              _c("i", {
                                class: _vm.showCol
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "订单编码",
                  "min-width": "160",
                  prop: "orderNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "采购单位组织",
                  "min-width": "120",
                  prop: "purchaseOwnerOrgName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "采购单位类型",
                  "min-width": "120",
                  prop: "purchaseComType",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("purchaseComType")(row.purchaseComType))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "采购单位",
                  "min-width": "120",
                  prop: "purchaseCom",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发货单位类型",
                  "min-width": "120",
                  prop: "sendOutComType",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("purchaseComType")(row.sendOutComType))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发货单位",
                  "min-width": "120",
                  prop: "sendOutCom",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "min-width": "110",
                  prop: "creatorUser",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人类型",
                  "min-width": "110",
                  prop: "creatorType",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("creatorType")(row.creatorType))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "采购数量(箱)",
                  "min-width": "110",
                  prop: "planTotalChestNum",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "采购数量(盒)",
                  "min-width": "110",
                  prop: "planTotalBottleNum",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  "min-width": "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("orderStatusText")(row.orderStatus))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoScan(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.current,
              limit: _vm.listQuery.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.detailShow,
                "before-close": _vm.detailClose,
                width: "1200px !important",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailShow = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购订单号:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.orderNo)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.purchaseComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("付款方式:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("payWayText")(_vm.addData.paymentStyle)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("订单日期:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("申请人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.applyUser)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.receiveComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveContacts)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人电话:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receivePhone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货地址:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveAddress)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("供货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("发货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.sendOutComName)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [
                    _vm._v("\n        产品信息\n        "),
                    _c("img", { attrs: { src: require("@/images/pro.png") } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "detailTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.productList,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: _vm.tableConfig.align,
                          prop: "productCode",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: _vm.tableConfig.align,
                          prop: "productName",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: _vm.tableConfig.align,
                          prop: "productModel",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: _vm.tableConfig.align,
                          prop: "packScaleExpression",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [_vm._v(_vm._s(row.chestNum))])
                                    : _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              value: row.chestNum,
                                              maxlength: "9",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (row.chestNum =
                                                  _vm.valENnum(e))
                                              },
                                              change: function ($event) {
                                                return _vm.changeChestNum(
                                                  _vm.$el,
                                                  row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          902634666
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [_vm._v(_vm._s(row.bottleNum))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2664111862
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元/盒)",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.productPrice)),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "only-number",
                                            rawName: "v-only-number",
                                            value: {
                                              min: 0,
                                              precision: 2,
                                              max: 99999999,
                                              set: row,
                                              name: "productPrice",
                                            },
                                            expression:
                                              "{min:0,precision:2,max:99999999,set: row, name:'productPrice'}",
                                          },
                                        ],
                                        staticStyle: { "text-align": "right" },
                                        on: { change: _vm.resetList },
                                        model: {
                                          value: row.productPrice,
                                          callback: function ($$v) {
                                            _vm.$set(row, "productPrice", $$v)
                                          },
                                          expression: "row.productPrice",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3422519173
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      row.totalPrice == 0 ? "-" : row.totalPrice
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3699155587
                        ),
                      }),
                      _vm._v(" "),
                      _vm.subType != "scan" && _vm.addData.orderStatus == 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoSc(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2104368588
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c(
                      "div",
                      { staticStyle: { "min-width": "250px" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "赠品产品及酒劵兑换产品不计金额",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "tipBtn",
                                attrs: { type: "text" },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info tipIcon",
                                }),
                                _vm._v(
                                  "\n              总金额：\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n          " + _vm._s(_vm.totalMoney) + "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "大写金额：" +
                          _vm._s(_vm._f("chnMoney")(_vm.totalMoney))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("备注")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _vm.subType == "scan" || _vm.addData.orderStatus != 1
                            ? _c("span", [_vm._v(_vm._s(_vm.addData.remark))])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                    _vm.subType == "scan"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderStatusText")(_vm.addData.orderStatus)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.detailClose } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              "can-change": _vm.factorycanChange,
              "company-type": _vm.factoryType,
              "is-enable": _vm.isEnable,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.productShow,
              data: _vm.productList,
            },
            on: {
              "update:data": function ($event) {
                _vm.productList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }