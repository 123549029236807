"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BCganged = require("@/api/terminal/BCganged");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import { formatDate } from '@/api/mark/basic'
var _default = {
  name: '',
  // components: {
  //   Pagination
  // },
  filters: {
    approval: function approval(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未审核',
        1: '已审核'
      };
      return statusMap[status];
    },
    activityDimension: function activityDimension(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品维度布奖',
        1: '订单维度布奖'
      };
      return statusMap[status];
    },
    timeControlType: function timeControlType(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品生产时间',
        1: '产品出库时间'
      };
      return statusMap[status];
    }
  },
  props: {},
  data: function data() {
    return {
      detailVisible: false,
      title: '活动详情',
      mloading: false,
      detailShow: false,
      temp: {},
      list: [{}, {}, {}, {}, {}],
      listLoading: false,
      reverse: true,
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      ruleForm: {},
      rules: {}
    };
  },
  mounted: function mounted() {},
  methods: {
    downFile: function downFile(excelType) {
      var _this = this;
      var str = {
        1: '产品分类白名单',
        2: '产品黑名单',
        3: '经销商白名单',
        4: '终端黑名单',
        5: '订单白名单'
      };
      (0, _BCganged.detailExportExcel)({
        excelType: excelType,
        id: this.actId
      }).then(function (res) {
        if (res.status !== 200) {
          _this.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = "".concat(str[excelType], ".xlsx");
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      });
    },
    show: function show(rows, settype) {
      console.log(rows, 'rows');
      this.detailVisible = true;
      if (settype == 1) {
        this.title = '活动查看';
        this.detailShow = true;
      } else if (settype == 2) {
        this.title = '活动审批';
        this.detailShow = false;
      }
      this.getList(rows.id);
      this.actId = rows.id;
    },
    handleClose: function handleClose() {
      this.temp = {};
      this.detailVisible = false;
    },
    getList: function getList(id) {
      var _this2 = this;
      this.mloading = true;
      (0, _BCganged.getDetail)({
        id: id
      }).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          setTimeout(function () {
            _this2.mloading = false;
            _this2.handleClose();
          }, 1000);
        } else {
          if (res.data.data.code == 200) {
            _this2.temp = JSON.parse(JSON.stringify(res.data.data.data));
            _this2.mloading = false;
          }
        }
      }).catch(function () {
        _this2.mloading = false;
      });
    }
  }
};
exports.default = _default;