var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logList
    ? _c(
        "div",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { id: "log-table-box" },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loglistLoading,
                  expression: "loglistLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.logList,
                height: "250",
                "tooltip-effect": "dark",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index", width: "120" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              scope.row.handlerResult == 0
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "warning" } },
                                    [_vm._v("提交申请")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.handlerResult == 12
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "primary", plain: "" } },
                                    [_vm._v("初审未通过")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.handlerResult == 22
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "primary" } },
                                    [_vm._v("终审未通过")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.handlerResult == 11
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "success", plain: "" } },
                                    [_vm._v("初审通过")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.handlerResult == 21
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "success" } },
                                    [_vm._v("终审通过")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  509586115
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "原因",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.handlerReason))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  502175162
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作人",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.handlerUserAccount)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  888913806
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "时间",
                  align: _vm.tableConfig.align,
                  "min-width": "155",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getDataTime(
                                  scope.row.handlerTime,
                                  "yyyy-MM-dd hh:mm:ss"
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2316547573
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-divider el-divider--horizontal" }, [
      _c("div", { staticClass: "el-divider__text is-left" }, [
        _vm._v("审批操作日日志"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }