var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tableDialog",
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "生产任务单选择",
            visible: _vm.visible,
            width: "1100px !important",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 24 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: { "padding-left": "0", "padding-right": "0" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.listQuery,
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "5px" },
                                      attrs: {
                                        prop: "orderNo",
                                        label: "任务单号:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          value: _vm.listQuery.orderNo,
                                          clearable: "",
                                          placeholder: "请输入",
                                          maxlength: "20",
                                        },
                                        on: {
                                          input: function (e) {
                                            return (_vm.listQuery.orderNo =
                                              _vm.validSe(e))
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "5px" },
                                      attrs: {
                                        prop: "batchNo",
                                        label: "批次号:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.listQuery.batchNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "batchNo",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.batchNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "创建时间" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "-",
                                          "start-placeholder": "开始",
                                          "end-placeholder": "结束",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        on: { change: _vm.datePickerFun },
                                        model: {
                                          value: _vm.dateVlaue,
                                          callback: function ($$v) {
                                            _vm.dateVlaue = $$v
                                          },
                                          expression: "dateVlaue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.listQuery.current = 1
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.restFun },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticClass: "multipleTable",
                      attrs: {
                        height: "400px",
                        data: _vm.list,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "row-key": function (row) {
                          return row.pid
                        },
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          "reserve-selection": true,
                          width: "55",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderNo",
                          label: "生产任务单号",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "batchNo",
                          label: "生产批次",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "materialCode",
                          label: "产品编码",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "materialName",
                          label: "产品名称",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total>0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.fetchData,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }