"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _poster = require("@/api/terminal/poster");
var _dealer = require("@/api/basic/dealer");
var _index = _interopRequireDefault(require("@/components/imageQRcode/index"));
var _qrcode = _interopRequireDefault(require("qrcode"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import QRCode from 'qrcodejs2'
var _default2 = {
  components: {
    imageQRcode: _index.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    data: {
      type: Object,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      imgloading: false,
      title: '海报创建',
      form: {
        posterName: '',
        // 海报名称
        relevancyTerminal: '',
        // 关联终端名称
        dataTimeRange: '',
        startTime: '',
        // 活动开始时间
        endTime: '',
        // 活动结束时间
        terminalId: '',
        // 终端编码
        backgroundUrl: '',
        // 海报背景图片
        posterUrl: '',
        // 海报活动链接
        commentInfo: '',
        // 备注信息
        activityStatus: '1' // 状态
      },

      formRules: {
        posterName: [{
          required: true,
          message: '海报名称不能为空',
          trigger: 'change'
        }],
        terminalId: [{
          required: true,
          message: '关联终端不能为空',
          trigger: 'change'
        }],
        dataTimeRange: [{
          required: true,
          message: '活动时间不能为空',
          trigger: 'change'
        }],
        backgroundUrl: [{
          required: true,
          message: '请上传海报图片',
          trigger: 'change'
        }],
        posterUrl: [{
          required: true,
          message: '海报链接不能为空',
          trigger: 'change'
        }]
      },
      terminalList: [],
      // 关联终端
      btnload: false,
      QRImgUrl: '',
      img: '',
      img2: ''
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.getTerminal();
    console.log(this.data, 1111);
    if (this.type == 'edit') {
      this.title = '海报编辑';
      this.form = {
        posterName: this.data.posterName,
        // 海报名称
        relevancyTerminal: this.data.relevancyTerminal,
        // 关联终端名称
        dataTimeRange: [this.data.startTime, this.data.endTime],
        startTime: this.data.startTime,
        // 活动开始时间
        endTime: this.data.endTime,
        // 活动结束时间
        terminalId: this.data.terminalId,
        // 终端编码
        backgroundUrl: this.data.backgroundUrl,
        // 海报背景图片
        posterUrl: this.data.posterUrl,
        // 海报活动链接
        commentInfo: this.data.commentInfo,
        // 备注信息
        activityStatus: this.data.activityStatus // 状态
      };

      this.getQrCode(this.data.posterUrl);
    } else if (this.type == 'add') {
      this.title = '海报创建';
      this.form = {
        posterName: '',
        // 海报名称
        relevancyTerminal: '',
        // 关联终端名称
        dataTimeRange: '',
        startTime: '',
        // 活动开始时间
        endTime: '',
        // 活动结束时间
        terminalId: '',
        // 终端编码
        backgroundUrl: '',
        // 海报背景图片
        posterUrl: '',
        // 海报活动链接
        commentInfo: '',
        // 备注信息
        activityStatus: '1' // 状态
      };
    }
  },

  methods: {
    // 关闭
    goBack: function goBack() {
      this.$refs['form'].resetFields();
      this.$emit('close');
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      if (this.form.dataTimeRange && this.form.dataTimeRange.length > 0) {
        this.form.startTime = this.form.dataTimeRange[0];
        this.form.endTime = this.form.dataTimeRange[1];
      } else {
        this.form.startTime = '';
        this.form.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.form));
      delete sdata.dataTimeRange;
      if (this.type == 'edit') {
        sdata.id = this.data.id;
        sdata.posterCode = this.data.posterCode;
        sdata.createTime = this.data.createTime;
        sdata.creator = this.data.creator;
      } else {
        if (sdata.activityStatus == '1') {
          sdata.activityStatus = '';
        }
      }
      console.log(sdata);
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this2.type == 'add') {
            (0, _poster.createPoster)(sdata).then(function (res) {
              console.log(res);
              if (res.data.data.errCode) {
                _this2.$message.error(res.data.data.errMsg);
              } else {
                if (res.data.data.code == 200) {
                  _this2.$message.success(res.data.data.msg);
                  _this2.goBack();
                }
              }
            }).catch(function (err) {
              console.log(err);
            });
          } else if (_this2.type == 'edit') {
            _this2.btnload = true;
            (0, _poster.updatePoster)(sdata).then(function (res) {
              console.log(res);
              if (res.data.data.errCode) {
                _this2.$message.error(res.data.data.errMsg + ',10秒后重试');
                setTimeout(function () {
                  _this2.btnload = false;
                  _this2.goBack();
                }, 10000);
              } else {
                if (res.data.data) {
                  _this2.$message.success('编辑成功!');
                  _this2.btnload = false;
                  _this2.goBack();
                }
              }
            }).catch(function (err) {
              console.log(err);
            });
          }
        }
      });
    },
    getTerminal: function getTerminal() {
      var _this3 = this;
      (0, _dealer.getActivityTerminal)({
        terminal: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        terminalStoreTypeId: '',
        current: 1,
        size: 100000
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.terminalList = res.data.data.records;
          } else {
            _this3.terminalList = [];
          }
        }
      }).catch(function () {});
    },
    terminal: function terminal(val) {
      var _this4 = this;
      this.terminalList.filter(function (item) {
        if (item.terminalCode == val) {
          _this4.form.relevancyTerminal = item.terminalName;
        }
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(param) {
      var _this5 = this;
      var file = param.file;
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 30;
      var _this = this;
      if (!isJPG && !isPNG) {
        _this.$message.error('上传图片只能是 PNG/JPG 格式!');
        return false;
      }
      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 30M!');
        return false;
      }
      _this.imgloading = true;
      var formData = new FormData();
      formData.append('file', file);
      (0, _user.imgupload)(formData).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this5.$message.error(data.msg);
          return true;
        }
        _this.form.backgroundUrl = data.data;
        _this.imgloading = false;
      }).catch(function () {
        _this.imgloading = false;
      });
      return isJPG && isLt2M;
    },
    upimageQR: function upimageQR() {
      this.img = this.form.backgroundUrl;
      this.img2 = this.form.posterUrl;
      this.$refs.imgCode.proSelVisible = true;
    },
    upCode: function upCode(val) {
      var _this6 = this;
      console.log(val);
      setTimeout(function () {
        _this6.getQrCode(val);
      }, 500);
    },
    getQrCode: function getQrCode(val) {
      // var qrcode
      this.$nextTick(function () {
        var _this7 = this;
        // if (!qrcode) {
        //   document.getElementById('qrcode').innerHTML = ''
        // }
        // qrcode = new QRCode(this.$refs.qrCodeUrl, {
        //   text: val, // 需要转换为二维码的内容
        //   width: 80,
        //   height: 80,
        //   colorDark: '#000000',
        //   colorLight: '#ffffff',
        //   correctLevel: QRCode.CorrectLevel.H
        // })

        _qrcode.default.toDataURL(val, {
          errorCorrectionLevel: 'L',
          margin: 0,
          width: 80
        }, function (err, url) {
          if (err) throw err;
          _this7.QRImgUrl = url;
        });
      });
    }
  }
};
exports.default = _default2;