"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _query = require("@/api/fake_expel/query/query");
var _dictionary = require("@/api/systems/dictionary");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'fakeExpelqueryManagetracingindex';
var typeName = [];
var _default = {
  name: 'FakeExpelqueryManagetracingindex',
  directives: {
    waves: _waves.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value);
      }
    },
    numStatus: function numStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未激活',
        1: '正常',
        2: '冻结',
        3: '废除',
        10: '不存在'
      };
      return statusMap[status];
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    aromaType: function aromaType(type, that) {
      if (!String(type)) return '';
      var statusMap = {};
      that.odorTypeList.forEach(function (item) {
        statusMap[item.figure] = item.name;
      });
      return statusMap[type];
    },
    billType: function billType(status) {
      var name = '';
      typeName.forEach(function (c) {
        if (c.figure == status) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内码',
        2: '码',
        3: '外码'
      };
      return statusPack[packLevel] + statusMark[markType];
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      hasInfo: false,
      title: '',
      url: '',
      srcList: [],
      activeNames: ['codeInfo', 'productInfo', 'changeLog', 'contectInfo'],
      numColor: {
        0: 'wjh',
        1: 'zc',
        2: 'dj',
        3: 'fc'
      },
      listQuery: {
        code: ''
      },
      tableKey: 0,
      list: [],
      listLoading: false,
      temp: {
        // 数码信息
        markVo: {},
        // 产品信息
        productInfoVo: {},
        // 数码替换记录
        oldMark: '',
        // 关联数码
        // siblingMarkVo: {},
        // 批次信息
        productionInfoVo: {},
        // 防伪查询信息
        firstFakeVo: {},
        // 物流信息
        markCodeBillVo: []
      },
      odorTypeList: []
    };
  },
  activated: function activated() {
    var hasCache1 = localStorage.getItem('code2');
    if (hasCache1) {
      this.listQuery.markCode = hasCache1;
      this.getList();
    }
    this.getDirSel('CCLX');
    this.getOdorType();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      localStorage.removeItem('code2');
      this.temp = {
        markVo: {},
        productInfoVo: {},
        oldMark: '',
        // siblingMarkVo: {},
        productionInfoVo: {},
        firstFakeVo: {},
        markCodeBillVo: []
      };
    }
  },
  methods: {
    // 获取香型
    getOdorType: function getOdorType() {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'ODORTYPE'
      }).then(function (res) {
        if (res.data.code === 200) {
          if (res.data.data) {
            _this.odorTypeList = res.data.data;
          }
        }
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var arr = [{
              figure: 1,
              name: '期初入库'
            }, {
              figure: 2,
              name: '采购入库'
            }, {
              figure: 3,
              name: '调拨入库'
            }, {
              figure: 4,
              name: '其他入库'
            }, {
              figure: 5,
              name: '销售出库'
            }, {
              figure: 6,
              name: '调拨出库'
            }, {
              figure: 7,
              name: '其他出库'
            }];
            typeName = [].concat(arr, (0, _toConsumableArray2.default)(res.data.data));
          } else {
            typeName = [];
          }
        }
      });
    },
    handleChange: function handleChange(val) {},
    getList: function getList() {
      var _this3 = this;
      var that = this;
      that.temp = {
        markVo: {},
        productInfoVo: {},
        oldMark: '',
        // siblingMarkVo: {},
        productionInfoVo: {},
        firstFakeVo: {},
        markCodeBillVo: []
      };
      this.activeNames = ['codeInfo', 'productInfo', 'changeLog', 'contectInfo'];
      if (!that.listQuery.code) {
        return;
      }
      // if (this.listQuery.code.length < 18) {
      //   this.$message.error('数码长度不正确，最少输入18位数码')
      //   return
      // }
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      (0, _query.synthesizesearch)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data) {
            var data = res.data.data;
            _this3.temp = data;
            if (data.markCodeBillVo && data.markCodeBillVo.length > 0) {
              data.markCodeBillVo.forEach(function (item) {
                if (item.billType > 200 || item.billType > 4 && item.billType < 8) {
                  item.topCode = item.sendComCode ? '(' + item.sendComCode + ')' : '';
                  item.topName = item.sendComName ? item.sendComName : '';
                } else {
                  item.topCode = item.receiveComCode ? '(' + item.receiveComCode + ')' : '';
                  item.topName = item.receiveComName ? item.receiveComName : '';
                }
              });
            }
            localStorage.setItem('code2', submitData.code);
          }
          // else {
          //   that.$message.error('查询结果不完整请重试！')
          // }
        }

        that.listLoading = false;
      }).catch(function () {
        that.listLoading = false;
      });
    },
    showImg: function showImg(title, src) {
      this.title = title;
      this.url = src;
      this.srcList = [src];
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.title = '';
      this.url = '';
      this.srcList = [];
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;