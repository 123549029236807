"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _basicConfig = require("@/api/terminal/basicConfig");
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
var _add = _interopRequireDefault(require("./add.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagebasicConfigpublicConfigindex';
var _default = {
  name: 'MarketingManagebasicConfigpublicConfigindex',
  components: {
    Tipdialog: _TipDialog.default,
    Content: _add.default
  },
  filters: {
    stateName: function stateName(index) {
      if (!index) return '';
      var comtype = {
        0: '未授权',
        1: '已授权'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      radio: '',
      newRadio: '',
      setUpDialogVisible: false,
      dialogVisible: false,
      title: '新增公众号配置',
      id: '',
      formInline: {
        time: '',
        startTime: '',
        endTime: '',
        name: '',
        region: '',
        state: '',
        officialName: '',
        status: ''
      },
      apis: []
    };
  },
  computed: {
    consumer: {
      get: function get() {
        return this.radio;
      },
      set: function set(val) {
        if (!this.radio) {
          this.newRadio = val;
          this.isSetUp();
        }
      }
    }
  },
  mounted: function mounted() {
    (0, _basicConfig.getIPS)().then(function (res) {
      console.log(res);
    });
  },
  activated: function activated() {
    var that = this;
    that.fetchData();
    return;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    /* const that = this
    var name = cachName
    var cache = that.getCaches(that, name)
    if (!cache) {
      that.list = []
      that.total = 0
    }*/
  },
  methods: {
    routerLinkFun: function routerLinkFun(type) {
      this.authId('logbtn', this.apis);
      this.$router.push({
        path: '/system/logmange/operatelog/index'
      });
    },
    outservice: function outservice(id, type) {
      var _this = this;
      if (type == 0) {
        this.authId('startbtn', this.apis);
        this.typenum = 1;
      } else if (type == 1) {
        this.authId('stopbtn', this.apis);
        this.typenum = 0;
      }
      (0, _basicConfig.delWechatOfficial)({
        id: id,
        status: this.typenum
      }).then(function (res) {
        res = res.data;
        if (res.state === '200') {
          _this.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this.fetchData();
        } else {
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      var obj = {
        loginId: 0
      };
      if (this.formInline.time == null) {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      } else {
        this.formInline.startTime = this.formInline.time[0];
        this.formInline.endTime = this.formInline.time[1];
      }
      Object.assign(this.formInline, obj);
      // console.log(this.formInline)
      (0, _basicConfig.getWcList)(this.formInline).then(function (res) {
        console.log(res);
        res = res.data;
        if (res.state === '200') {
          res.data.forEach(function (element) {
            if (element.isConsum * 1 === 1) {
              _this2.radio = element.id;
            }
          });
          _this2.list = res.data;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    handleAdd: function handleAdd(row) {
      this.authId('addbtn', this.apis);
      this.title = '新增公众号配置';
      this.dialogVisible = true;
      // this.$router.push({
      //   name: 'PublicConfigAdd',
      //   params: { state: '新增' }
      // })
    },
    handleUpdate: function handleUpdate(row, type) {
      var _this3 = this;
      console.log(row, type);
      if (typeof type == 'undefined') {
        this.authId('updatebtn', this.apis);
      } else {
        this.authId('scanbtn', this.apis);
      }
      this.title = '编辑公众号基础配置';
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs.content.getDetail(row.id, type);
      });
      // this.$router.push({
      //   name: 'PublicConfigUpdate',
      //   params: { state: '编辑', id: row.id }
      // })
    },
    close: function close() {
      this.dialogVisible = false;
      this.fetchData();
    },
    isSetUp: function isSetUp() {
      var _this4 = this;
      // this.setUpDialogVisible = true
      this.$confirm('<div style="line-height:21px;"><i class="el-icon-warning" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>设置后不能调整，请确认是否设置此公众号为消费者公众号。</div>', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(function () {
        _this4.handleSetUp();
      }).catch(function () {
        _this4.setUpHandleClose();
      });
    },
    handleSetUp: function handleSetUp() {
      var _this5 = this;
      // 接口
      (0, _basicConfig.setConsum)({
        id: this.newRadio
      }).then(function (res) {
        res = res.data;
        if (res.state * 1 === 200) {
          _this5.radio = _this5.newRadio;
          _this5.fetchData();
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    goSet: function goSet() {
      this.authId('authorize', this.apis);
      window.open(process.env.VUE_APP_BASE_API + '/api-wxservice/goAuthView');
    },
    setUpHandleClose: function setUpHandleClose() {
      this.radio = '';
    }
  }
};
exports.default = _default;