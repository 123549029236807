"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    actId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    showOnly: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    isBatch: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    currentData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    selectItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      isTimeSelect: true,
      codeForm: {
        activityId: '',
        id: '',
        prCode: '',
        prEndTime: '',
        prStartTime: '',
        prDateRange: '',
        prTimeType: '',
        timeRangeList: [''],
        prTimeItemsList: [{
          endHour: '',
          priority: '',
          startHour: '',
          timeRange: ''
        }],
        prType: '',
        prizeItemsList: [],
        seqNo: ''
      },
      prioritys: [{
        name: '一等奖',
        value: 1
      }, {
        name: '二等奖',
        value: 2
      }, {
        name: '三等奖',
        value: 3
      }, {
        name: '四等奖',
        value: 4
      }, {
        name: '五等奖',
        value: 5
      }, {
        name: '六等奖',
        value: 6
      }, {
        name: '七等奖',
        value: 7
      }, {
        name: '八等奖',
        value: 8
      }],
      prizeTypes: [{
        name: '红包',
        value: '100'
      }, {
        name: '实物奖品',
        value: '200'
      }, {
        name: '优惠券',
        value: '300'
      }, {
        name: '积分',
        value: '400'
      }, {
        name: '字卡',
        value: '500'
      }, {
        name: '礼包',
        value: '900'
      }
      // { name: '谢谢惠顾', value: '0' }
      ],

      prizeListByType: {
        '100': JSON.parse(sessionStorage.getItem('100')),
        // 红包
        '200': JSON.parse(sessionStorage.getItem('200')),
        // 实物
        '300': JSON.parse(sessionStorage.getItem('300')),
        // 优惠券
        '400': JSON.parse(sessionStorage.getItem('400')),
        // 积分
        '500': JSON.parse(sessionStorage.getItem('500')),
        // 字卡
        '900': JSON.parse(sessionStorage.getItem('900')) // 礼包
      },

      prTypes: [{
        name: '时间规则',
        value: '0'
      }
      // { name: '超区规则', value: '1' }
      ],

      thanksData: {
        prizePr: '0',
        priority: 99999,
        oldPriority: 99999,
        prizeType: '0'
      },
      listLoading: true,
      active: 0,
      productVisibleShow: false,
      h_batcheVisibleShow: false,
      orderVisible: false,
      subLoading: false,
      isDisable: false,
      limitLength: 0,
      codeFormRules: {
        prType: [{
          required: true,
          message: '请选择策略类型',
          trigger: 'change'
        }],
        prDateRange: [{
          required: true,
          message: '请选择时间范围',
          trigger: 'change'
        }],
        prTimeType: [{
          required: true,
          message: '请选择单双日',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    // 活动预算
    codeTotal: function codeTotal() {
      var total = 0;
      if (this.codeForm.prizeItemsList.length > 0) {
        this.codeForm.prizeItemsList.forEach(function (item) {
          if (item.prizeBudgetTotalAmount) {
            total += Number(item.prizeBudgetTotalAmount);
          }
        });
      }
      return total.toFixed(2) + '元';
    },
    itemData: function itemData() {
      return this.currentData;
    }
  },
  watch: {
    isTimeSelect: function isTimeSelect(val) {
      if (!val && this.codeForm.prTimeType == '0') {
        this.codeForm.prTimeType = '';
      }
      if (val) {
        this.codeForm.prDateRange = '';
      }
    }
  },
  created: function created() {
    this.type == 'edit' ? this.getActPrDetail(this.id) : this.getPrevDetail();
  },
  mounted: function mounted() {
    this.codeForm.activityId = this.actId;
  },
  methods: {
    // 切换选择奖品根据奖品类型
    getCurPrizeType: function getCurPrizeType($event, index) {
      var _this = this;
      this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = '';
      this.codeForm.prizeItemsList[index].prizeId = '';
      this.codeForm.prizeItemsList[index].prizePr = '';
      this.codeForm.prizeItemsList[index].prizeNum = '';
      this.$refs.codeForm.clearValidate();
      if ($event == '0') {
        this.codeForm.prizeItemsList[index].priority = 99999;
        this.codeForm.prizeItemsList[index].extraInfo.disable = false;
        return;
      }
      if ($event != '0') {
        if (this.codeForm.prizeItemsList[index].priority == 99999) {
          this.codeForm.prizeItemsList[index].priority = null;
        }
      }
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: $event
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this.codeForm.prizeItemsList[index].extraInfo.disable = false;
          _this.codeForm.prizeItemsList[index].plist = res.data.data;
        }
      });
    },
    // 根据奖品类型初始化奖品列表
    getInitPrizeList: function getInitPrizeList(type) {
      var _this2 = this;
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: type
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this2.prizeListByType[type] = res.data.data;
        }
      });
    },
    // 删除奖品设置
    delCodePrize: function delCodePrize(index) {
      delete this.codeForm.prizeItemsList.splice(index, 1);
      this.computedThankPrizePr();
    },
    // 增加奖项设置
    addCodePrize: function addCodePrize() {
      this.codeForm.prizeItemsList.push({
        priority: null,
        oldPriority: null,
        prizeBudgetTotalAmount: '',
        prizeId: '',
        prizeNum: '',
        prizePr: '',
        prizeType: '',
        extraInfo: {
          disable: false
        },
        plist: []
      });
    },
    // 添加时间区段
    addTimeRange: function addTimeRange() {
      this.codeForm.timeRangeList.push('');
    },
    // 删除时间段
    delTimeRange: function delTimeRange(index) {
      // delete this.codeForm.prTimeItemsList.splice(index, 1)
      delete this.codeForm.timeRangeList.splice(index, 1);
    },
    // 检验奖品等级是否重复
    checkLevel: function checkLevel(e, index) {
      if (this.codeForm.prizeItemsList.length > 1) {
        for (var i = 0; i < this.codeForm.prizeItemsList.length; i++) {
          if (i != index && this.codeForm.prizeItemsList[i].priority == e) {
            this.codeForm.prizeItemsList[index].priority = null;
            this.$message.error('奖品等级不能重复配置！');
            break;
          }
        }
      }
      // if(!this.codeForm.prizeItemsList[index].oldPriority) {
      //   this.codeForm.prizeItemsList[index].oldPriority = this.codeForm.prizeItemsList[index].priority
      // }
    },
    checkPrizePr: function checkPrizePr(val, index) {
      var value = val.target.value;
      var match = value.match(/\d*(\.\d{0,4})?/)[0];
      this.codeForm.prizeItemsList[index].prizePr = match;
      this.doSum(index);
      // this.computedThankPrizePr()
    },
    computedThankPrizePr: function computedThankPrizePr() {
      var allPrizePr = 0;
      var MaxRetainNumber = 1000000;
      this.codeForm.prizeItemsList.map(function (item) {
        allPrizePr += item.prizePr ? Number(item.prizePr) * MaxRetainNumber : 0;
      });
      this.thanksData.prizePr = (100 * MaxRetainNumber - allPrizePr) / MaxRetainNumber;
    },
    // 预算计算
    doSum: function doSum(index) {
      var data = this.codeForm.prizeItemsList[index];
      var prizePr = data.prizePr;
      var allPr = 0;
      this.codeForm.prizeItemsList.forEach(function (item) {
        allPr += Number(item.prizePr) * 10000;
      });
      if (Number(allPr) / 10000 > 100) {
        this.codeForm.prizeItemsList[index].prizePr = '';
        this.$message.error('总概率和应不大于100%，请调整概率！');
      }
      if (data.prizeType == '0') {
        // 谢谢惠顾
        return;
      }
      var prizeNum = data.prizeNum;
      var prizeId = data.prizeId;
      var baseValue = data.plist.filter(function (item) {
        return item.prizeId == prizeId;
      });
      var price = 0;
      if (data.prizeType == '100') {
        price = baseValue[0].redMaxValue ? baseValue[0].redMaxValue : baseValue[0].baseValue;
      } else {
        price = baseValue && baseValue[0] && baseValue[0].baseValue ? baseValue[0].baseValue : 0;
      }
      if (!prizeNum || !prizePr) {
        this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = '';
        return;
      }
      if (Number(allPr) / 10000 > 100) {
        this.codeForm.prizeItemsList[index].prizePr = '';
        this.$message.error('总概率和应不大于100%，请调整概率！');
        return;
      }
      this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = Number(price) * Number(prizeNum);
    },
    // 获取详情
    getActPrDetail: function getActPrDetail(id) {
      var _this3 = this;
      if (this.isBatch) {
        this.dataProcessForEdit(this.itemData);
        this.computedThankPrizePr();
        return;
      }
      (0, _activityManage.detailActPr)({
        id: id
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            var item = res.data.data;
            _this3.dataProcessForEdit(res.data.data);
            _this3.computedThankPrizePr();
          } else {
            _this3.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this3.$message.error(res.data.msg);
        }
      });
    },
    // 获取追加前详情配置
    getPrevDetail: function getPrevDetail() {
      var _this4 = this;
      (0, _activityManage.detailpage)({
        id: this.isBatch ? this.selectItem[0] : this.actId
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this4.dataProcessForAdd(res.data.data);
            _this4.computedThankPrizePr();
          } else {
            _this4.$message.error(res.data.data.errMsg);
            return;
          }
        }
      });
    },
    // 处理编辑活动概率数据
    dataProcessForEdit: function dataProcessForEdit(item) {
      var _this5 = this;
      this.codeForm = Object.assign(this.codeForm, item);
      this.codeForm.prDateRange = [item.prStartTime || '', item.prEndTime || ''];
      this.isTimeSelect = item.prTimeType == '0';
      var prizeItemsList = [];
      if (item.prizeItemsList && item.prizeItemsList.length > 0) {
        prizeItemsList = item.prizeItemsList.map(function (value) {
          if (value.prizeType != '0') {
            value.oldPriority = value.priority;
            value.plist = _this5.prizeListByType[value.prizeType] ? _this5.prizeListByType[value.prizeType] : '';
            if (value.prizeType == 500 && value.prizeStatus == 3) {
              value.copyPrizeId = value.prizeId;
              value.prizeId = value.prizeName + '(已过期)';
            }
          } else {
            value.plist = [];
          }
          if (value.extraInfo) {
            value.extraInfo = JSON.parse(value.extraInfo);
          }
          return value;
        });
      }
      this.codeForm.prizeItemsList = prizeItemsList.filter(function (item) {
        return Number(item.prizeType);
      });
      if (item.prTimeItemsList && item.prTimeItemsList.length > 0) {
        this.codeForm.timeRangeList = item.prTimeItemsList.map(function (itemTime) {
          return [itemTime.startHour || '', itemTime.endHour || ''];
        });
      }
    },
    // 处理添加活动概率数据
    dataProcessForAdd: function dataProcessForAdd(datas) {
      var _this6 = this;
      var prizeItemsList = [];
      if (datas.prizeItemsList && datas.prizeItemsList.length > 0) {
        prizeItemsList = datas.prizeItemsList.map(function (item) {
          var plist = _this6.prizeListByType[item.prizeType] ? _this6.prizeListByType[item.prizeType] : [];
          var returnData = {
            priority: item.priority,
            oldPriority: item.priority,
            prizeBudgetTotalAmount: null,
            prizeId: item.prizeId ? item.prizeId : '',
            prizeNum: null,
            prizePr: item.prizePr ? item.prizePr : '',
            prizeType: item.prizeType,
            extraInfo: {
              disable: true
            },
            plist: plist
          };
          if (item.prizeType == 500 && item.prizeStatus == 3) {
            returnData.copyPrizeId = item.prizeId;
            returnData.prizeId = item.prizeName + '(已过期)';
          }
          return returnData;
        });
      }
      this.limitLength = prizeItemsList.length;
      this.codeForm.prizeItemsList = prizeItemsList.concat(this.codeForm.prizeItemsList).filter(function (item) {
        return Number(item.prizeType);
      });
    },
    // 表单验证
    checkForm: function checkForm(val, text) {
      var _this7 = this;
      return new Promise(function (resolve, reject) {
        _this7.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this7.$message.closeAll();
            _this7.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit('close');
    },
    // 完成
    finish: function finish() {
      var _this8 = this;
      var eCheck = this.checkForm('codeForm', '请完善概率策略设置');
      eCheck.then(function (res) {
        var allPr = 0;
        var codeForm = JSON.parse(JSON.stringify(_this8.codeForm));
        codeForm.prizeItemsList.push(_this8.thanksData);
        codeForm.prizeItemsList.forEach(function (item) {
          allPr += Number(item.prizePr) * 10000;
        });
        if (Number(allPr) / 10000 != 100) {
          _this8.$message.error('总概率和必须为100%，请调整奖品概率！');
          return;
        }
        var sdata = JSON.parse(JSON.stringify(codeForm));
        sdata.prEndTime = sdata.prDateRange[1];
        sdata.prStartTime = sdata.prDateRange[0];
        sdata.prTimeType = _this8.isTimeSelect ? '0' : sdata.prTimeType;
        sdata.prTimeItemsList = sdata.timeRangeList.map(function (item, index) {
          return {
            startHour: item[0],
            endHour: item[1],
            priority: index + 1
          };
        });
        sdata.prizeItemsList = sdata.prizeItemsList.map(function (item, index) {
          return {
            priority: item.priority,
            oldPriority: item.oldPriority,
            prizeBudgetTotalAmount: item.prizeBudgetTotalAmount,
            prizeId: "".concat(item.prizeId).includes('(已过期)') && item.copyPrizeId ? item.copyPrizeId : item.prizeId,
            prizeNum: item.prizeNum,
            prizePr: item.prizePr,
            prizeType: item.prizeType,
            extraInfo: JSON.stringify(item.extraInfo)
          };
        });

        // 如果是批量，则直接返回数据至父组件
        if (_this8.isBatch) {
          _this8.$emit('getItemData', sdata);
          _this8.$message.success("".concat(_this8.type == 'add' ? '添加成功' : '修改成功'));
          return;
        }
        if (_this8.type == 'add') {
          var subData = sdata;
          _this8.subLoading = true;
          _this8.isDisable = true;
          (0, _activityManage.creatPrList)(subData).then(function (res) {
            if (res.data.code == 200) {
              if (res.data.data.errCode) {
                _this8.subLoading = false;
                _this8.isDisable = false;
                _this8.$message.error(res.data.data.errMsg);
                return;
              } else {
                _this8.$message({
                  message: '添加成功！',
                  type: 'success',
                  duration: 3 * 1000
                });
                _this8.goBack();
                _this8.subLoading = false;
                _this8.isDisable = false;
              }
            } else {
              _this8.subLoading = false;
              _this8.isDisable = false;
              _this8.$message.error(res.data.msg);
              return;
            }
          });
        } else if (_this8.type == 'edit') {
          var subData = sdata;
          _this8.subLoading = true;
          _this8.isDisable = true;
          (0, _activityManage.updateActPr)(subData).then(function (res) {
            if (res.data.code == 200) {
              if (res.data.data.errCode) {
                _this8.subLoading = false;
                _this8.isDisable = false;
                _this8.$message.error(res.data.data.errMsg);
                return;
              } else {
                _this8.$message({
                  message: '修改成功！',
                  type: 'success',
                  duration: 3 * 1000
                });
                _this8.goBack();
                _this8.subLoading = false;
                _this8.isDisable = false;
              }
            } else {
              _this8.subLoading = false;
              _this8.isDisable = false;
              _this8.$message.error(res.data.msg);
              return;
            }
          });
        }
      });
    },
    // 失去焦点
    getSetRangeTime: function getSetRangeTime(e, index) {
      var time = new Date();
      var curY = time.getFullYear();
      var curM = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      var curD = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate();
      var cur = curY + '-' + curM + '-' + curD;
      var range = e.value;
      var time1 = cur + '  ' + range[0];
      var time2 = cur + '  ' + range[1];
      var t1 = new Date(time1).getTime();
      var t2 = new Date(time2).getTime();
      if (t1 > t2) {
        this.codeForm.timeRangeList[index] = '';
        e.picker.handleClear();
        //  this.codeForm.prTimeItemsList[index].endHour = ''
        //  this.codeForm.prTimeItemsList[index].startHour = ''
        this.$message.error('开始时间不能大于结束时间，请重新设置时间段,设置成功后点确定！');
      }
    }
  }
};
exports.default = _default2;