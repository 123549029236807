var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("生产计划单")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "scheduleNo",
                                    label: "排产单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.scheduleNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "scheduleNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.scheduleNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "crmOrderNo", label: "PK:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.crmOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "crmOrderNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.crmOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "生产计划单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "orderNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderType",
                                    label: "订单类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.orderType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orderType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orderType",
                                      },
                                    },
                                    _vm._l(_vm.orderList, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.newName,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "custCode",
                                    label: "客户编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.custCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "custCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.custCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "custName",
                                    label: "客户名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.custName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "custName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.custName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productCode",
                                    label: "产品编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productName",
                                    label: "产品名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productSpec",
                                    label: "规格型号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productSpec,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productSpec",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productSpec",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "scheduleStatus",
                                    label: "认领状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.scheduleStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "scheduleStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.scheduleStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "待认领", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "部分认领", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "全部认领", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "auditStatus",
                                    label: "审核状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.auditStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "auditStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.auditStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "待审核", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "审核拒绝", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "审核通过", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "创建起始时间:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeData(true)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "endTime",
                                    label: "创建结束时间:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeData(false)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endTime", $$v)
                                      },
                                      expression: "searchForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "20px" },
                                      attrs: {
                                        type: _vm.btnConfig.downLoadBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.downLoadBtn.icon,
                                        loading: _vm.downLoading,
                                      },
                                      on: { click: _vm.download },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.downLoadBtn.text)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("reviewbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "20px" },
                                      attrs: {
                                        size: _vm.btnConfig.size,
                                        type: _vm.btnConfig.addBtn.type,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doArrange(
                                            _vm.multipleSelection,
                                            "Y"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("批量审核")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listtableLoading,
                      expression: "listtableLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "排产单编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "scheduleNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "PK",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "crmOrderNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产计划单编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "orderNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单类型",
                      prop: "orderTypeCode",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户编码",
                      prop: "custCode",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户名称",
                      prop: "channelName",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格型号",
                      prop: "productModel",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "系数",
                      prop: "coefficient",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "组数",
                      prop: "scheduleChestSum",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "排产数量（只）",
                      prop: "scheduleBottleSum",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "认领数量",
                      prop: "claimSum",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "未认领数量",
                      prop: "unClaimSum",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产回传数量",
                      prop: "productionSum",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道分类",
                      prop: "className",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "区域代码",
                      prop: "areaCode",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "认领状态",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.scheduleStatus == "0"
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("待认领"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.scheduleStatus == "1"
                                ? _c("el-tag", { attrs: { type: "" } }, [
                                    _vm._v("部分认领"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.scheduleStatus == "2"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("全部认领"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3044074874
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核状态",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.auditStatus == "0"
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("待审核"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.auditStatus == "1"
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("审核拒绝"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.auditStatus == 2
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("审核通过"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1945356159
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          "min-width": "220",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanDetail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doUpdate(row, "E")
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "reviewbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled:
                                              row.auditStatus == "2" &&
                                              (row.scheduleStatus == "0" ||
                                                row.scheduleStatus == "1" ||
                                                row.scheduleStatus == "2"),
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doArrange(row, "N")
                                            },
                                          },
                                        },
                                        [_vm._v("审核")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled:
                                              row.auditStatus == "2" &&
                                              (row.scheduleStatus == "0" ||
                                                row.scheduleStatus == "1" ||
                                                row.scheduleStatus == "2"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoDelete(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          360714502
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "编辑",
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            "custom-class": "customWidth",
            width: "60%!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanForm",
              staticClass: "info-items",
              attrs: {
                model: _vm.scanData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "150px",
              },
            },
            [
              _c("el-row", { staticClass: "title" }, [
                _vm._v("\n        —— 单据信息 ——\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品编码:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.productCode))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品名称:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.productName))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "规格型号:" } }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.detailinfo.productModel)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "系数:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.coefficient))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "组数:" } }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.detailinfo.backChestSum)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "销售数量:" } }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.detailinfo.backBottleSum)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "客户代码:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.custCode))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "客户名称:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.channelName))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "区域代码:" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.detailinfo.areaCode))]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-row", { staticClass: "title" }, [
                _vm._v("\n        —— 排产信息 ——\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: {
                    "border-bottom": "1px dashed #eff0f1",
                    "margin-bottom": "40px",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [_c("el-form-item", { attrs: { label: "计划生产:" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [_c("el-form-item", { attrs: { label: "已排产:" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [_c("el-form-item", { attrs: { label: "未排产:" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "政府追溯:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排产车间" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.scanData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "type", $$v)
                                },
                                expression: "scanData.type",
                              },
                            },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  label: "美食/餐厅线上活动",
                                  name: "type",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: { label: "地推活动", name: "type" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: { label: "线下主题活动", name: "type" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: { label: "单纯品牌曝光", name: "type" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排产数量:", prop: "billNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "20",
                              "show-word-limit": "",
                            },
                            on: {
                              input: function (e) {
                                return (_vm.scanData.billNo = _vm.validSe(e))
                              },
                            },
                            model: {
                              value: _vm.scanData.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "billNo", $$v)
                              },
                              expression: "scanData.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dialog-footer",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("el-button", { on: { click: _vm.dialogClose } }, [
                            _vm._v("取 消"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(1)
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "排产单-详情",
            visible: _vm.detailVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanData",
              staticClass: "info-items",
              attrs: {
                model: _vm.detailData,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "单据信息", column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "排产单编码" } },
                    [_vm._v(_vm._s(_vm.detailData.scheduleNo))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "PK" } }, [
                    _vm._v(_vm._s(_vm.detailData.crmOrderNo)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产计划单编码" } },
                    [_vm._v(_vm._s(_vm.detailData.orderNo))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "订单类型" } }, [
                    _vm._v(_vm._s(_vm.detailData.orderTypeCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "客户编码" } }, [
                    _vm._v(_vm._s(_vm.detailData.custCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "客户名称" } }, [
                    _vm._v(_vm._s(_vm.detailData.channelName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "渠道分类" } }, [
                    _vm._v(_vm._s(_vm.detailData.className)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "区域代码" } }, [
                    _vm._v(_vm._s(_vm.detailData.areaCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "省" } }, [
                    _vm._v(_vm._s(_vm.detailData.province)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "市" } }, [
                    _vm._v(_vm._s(_vm.detailData.city)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "区/县" } }, [
                    _vm._v(_vm._s(_vm.detailData.county)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "销售部门" } }, [
                    _vm._v(_vm._s(_vm.detailData.orgName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "销售大区" } }, [
                    _vm._v(_vm._s(_vm.detailData.regionName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品编码" } }, [
                    _vm._v(_vm._s(_vm.detailData.productCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(_vm._s(_vm.detailData.productName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "规格型号" } }, [
                    _vm._v(_vm._s(_vm.detailData.productModel)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "政府追溯" } }, [
                    _vm._v(_vm._s(_vm.detailData.traceCodeName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "采集箱码" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.boxCollect == 1
                          ? "是"
                          : _vm.detailData.boxCollect == 0
                          ? "否"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "系数" } }, [
                    _vm._v(_vm._s(_vm.detailData.coefficient)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "排产组数" } }, [
                    _vm._v(_vm._s(_vm.detailData.scheduleChestSum)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "排产数量" } }, [
                    _vm._v(_vm._s(_vm.detailData.scheduleBottleSum)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "质保政策" } }, [
                    _vm._v(_vm._s(_vm.detailData.policyName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "生产工厂" } }, [
                    _vm._v(_vm._s(_vm.detailData.factoryName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "审核状态" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.auditStatus == 0
                          ? "待审核"
                          : _vm.detailData.auditStatus == 1
                          ? "审核拒绝"
                          : _vm.detailData.auditStatus == 2
                          ? "审核通过"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "排产车间" } }, [
                    _vm._v(_vm._s(_vm.detailData.shopNames)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.detailData.operator)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建日期" } }, [
                    _vm._v(_vm._s(_vm.detailData.createTime)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        质保政策\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(
                  "\n        政策名称：" +
                    _vm._s(_vm.zhiInfo.policyName) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { title: "", column: 3, border: "" },
                },
                [
                  _vm._l(_vm.zhiInfo.itemList, function (item, index) {
                    return _vm.zhiInfo.itemList
                      ? _c(
                          "el-descriptions-item",
                          {
                            key: index,
                            attrs: { label: item.detailDesc + "(月)" },
                          },
                          [_vm._v(_vm._s(item.period))]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.zhiInfo.itemList.length > 0
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "过保期(月)" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.zhiInfo.itemList.length > 0
                                ? _vm.zhiInfo.itemList[
                                    _vm.zhiInfo.itemList.length - 1
                                  ].endMonth
                                : ""
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "生产数量生产信息", column: 2, border: "" },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "排产数量" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.scheduleBottleSum
                          ? _vm.detailData.scheduleBottleSum + "只"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产回传数量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.productionSum
                            ? _vm.detailData.productionSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "已认领数量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.claimSum
                            ? _vm.detailData.claimSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v("\n        v"),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "未认领数量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.unClaimSum
                            ? _vm.detailData.unClaimSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "有效生产量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.effectSum
                            ? _vm.detailData.effectSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "异常数码量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.exceptionSum
                            ? _vm.detailData.exceptionSum + "个"
                            : ""
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        任务单进度\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.detailtable,
                    fit: "",
                    "max-height": "400",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "任务单号",
                      prop: "taskNo",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "认领数量",
                      prop: "taskBottleSum",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "排产车间",
                      prop: "shopName",
                      align: _vm.tableConfig.align,
                      "min-width": "130",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      prop: "taskStatus",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.taskStatus == 0
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("待生产"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.taskStatus == 1
                              ? _c("el-tag", [_vm._v("进行中")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.taskStatus == 2
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("已结单"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        备注\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    background: "rgba(249, 249, 249, 1)",
                    width: "100%",
                    "max-height": "200px",
                    padding: "20px 20px",
                    overflow: "auto",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.detailData.remark ? _vm.detailData.remark : ""
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAudit
            ? _c(
                "el-form",
                {
                  ref: "auditForm",
                  attrs: {
                    model: _vm.auditForm,
                    "label-width": "85px",
                    "label-suffix": ":",
                    rules: _vm.auditRule,
                  },
                },
                [
                  _c("h3", { staticClass: "title" }, [_vm._v("审核")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果", prop: "handlerResult" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.auditForm.handlerResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.auditForm, "handlerResult", $$v)
                            },
                            expression: "auditForm.handlerResult",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.auditForm.auditReasons,
                          callback: function ($$v) {
                            _vm.$set(_vm.auditForm, "auditReasons", $$v)
                          },
                          expression: "auditForm.auditReasons",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isAudit
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.btnLoading, type: "primary" },
                      on: { click: _vm.audit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: _vm.tableConfig.align },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.inspectorGroupId
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkGroup(
                                          scope.row.inspectorGroupId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看质检组")]
                                )
                              : _c(
                                  "el-button",
                                  { attrs: { type: "text", disabled: true } },
                                  [_vm._v("查看质检组")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查询",
            visible: _vm.groupShow,
            top: "3%",
            width: "400px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupShow = $event
            },
          },
        },
        [
          _c("el-form", [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("质检组:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("groupName")(_vm.groupId))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.groupShow = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "异常数码明细",
            visible: _vm.failVisible,
            "before-close": _vm.failClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.failVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "400",
                    data: _vm.failList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "解析状态",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#E6A23C" } }, [
                              _vm._v(_vm._s(scope.row.failReason)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.failtotal > 0,
                    expression: "failtotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.failtotal,
                  page: _vm.failQuery.current,
                  limit: _vm.failQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.failQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.failQuery, "size", $event)
                  },
                  pagination: _vm.getFailList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.failClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _c("Arrange", { ref: "insertMain" }),
      _vm._v(" "),
      _c("Edit", { ref: "editMain" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }