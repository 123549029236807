"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _compyrole = require("@/api/systems/compyrole");
var _suplyrole = require("@/api/systems/suplyrole");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  props: {
    editData: Object,
    edit: Boolean
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      rules: {
        roleName: [{
          required: true,
          validator: _compyrole.checkRequiredWord,
          trigger: 'blur'
        }],
        clientCodes: [{
          required: true,
          message: '请选择系统',
          trigger: 'change'
        }],
        isEnable: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      options: [],
      options1: [],
      dstate: false
    };
  },
  watch: {
    edit: function edit(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.options = that.$parent.sysOptions;
        that.options1 = that.$parent.systemOptions;
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('editForm');
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var data = that.$parent.editData;
          var subData = {};
          // subData.PId = data.pid
          subData.roleName = data.roleName;
          subData.roleCode = data.roleCode;
          subData.supplierType = Number(data.roleTypeDetail);
          subData.remark = data.remark;
          subData.clientCodes = data.clientCodes.join(',').split(',');
          if (data.isEnable == true) {
            subData.isEnable = 1;
          } else {
            subData.isEnable = 0;
          }
          (0, _suplyrole.editGhRole)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.$parent.editShow = false;
              that.dialogVisible = false;
              that.loading = false;
              that.dsate = false;
              that.$parent.getList();
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.editShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;