"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _MapCharts = _interopRequireDefault(require("@/components/dataAnalyse/MapCharts"));
var _ProvinceChart = _interopRequireDefault(require("@/components/dataAnalyse/ProvinceChart"));
var _fw = require("@/api/dataAnalyse/fw.js");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MapChart: _MapCharts.default,
    ProvinceChart: _ProvinceChart.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      listLoading: false,
      dialogVisible: false,
      searchBtn: false,
      sweepBtn: false,
      count: 0,
      dateValue: [],
      formInline: {
        productCode: '',
        startTime: '',
        endTime: '',
        timeType: '',
        productName: ''
      },
      provincesText: ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门', '台湾'],
      provinceId: '',
      provinceName: '',
      provinceList: []
    };
  },
  created: function created() {},
  activated: function activated() {
    this.monthFun();
  },
  methods: {
    // 本月
    monthFun: function monthFun() {
      this.formInline.timeType = '1';
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getProvinceFun();
    },
    // 本年
    annualFun: function annualFun() {
      this.formInline.timeType = '2';
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getProvinceFun();
    },
    // 时间选择
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.timeType = '3';
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.getProvinceFun();
      } else {
        this.monthFun();
      }
    },
    // 产品选择
    inputFocusFun: function inputFocusFun() {
      this.$refs.selProduct.proSelVisible = true;
    },
    dataFun: function dataFun(res) {
      console.log(res);
      var mapData = [];
      res.forEach(function (item, index) {
        if (item.locationName === '臺灣省') {
          mapData.push({
            name: '台湾',
            value: item.checkNum,
            provinceId: item.locationCode
          });
        } else {
          mapData.push({
            name: item.locationName,
            value: item.checkNum,
            provinceId: item.locationCode
          });
        }
      });
      this.$refs.mapChart.mapData = mapData;
      this.$refs.mapChart.initChart();
      if (this.provinceId) {
        this.getCityFun();
      } else {
        this.$refs.provinceChart.initChart(this.provinceName.replace(/省|市/g, '') || '北京');
      }
    },
    // 产品选择
    setCurrent: function setCurrent(row) {
      this.formInline.productName = row.productName;
      this.formInline.productCode = row.productCode;
      this.getProvinceFun();
    },
    // 清除产品
    clearFun: function clearFun() {
      if (this.formInline.productCode) {
        this.formInline.productCode = '';
        this.formInline.productName = '';
        this.getProvinceFun();
      }
    },
    // 省份数据
    getProvinceFun: function getProvinceFun() {
      var _this2 = this;
      var formInline = JSON.parse(JSON.stringify(this.formInline));
      delete formInline.productName;
      (0, _fw.searchProvince)(formInline).then(function (res) {
        if (res.data.code === 200) {
          _this2.list = res.data.data;
          if (res.data.data.length > 0) {
            _this2.provinceId = res.data.data[0].locationCode;
            _this2.provinceName = res.data.data[0].locationName;
          }
          var data = [];
          _this2.provincesText.forEach(function (item, index) {
            var obj = {
              checkNum: '0',
              locationCode: '',
              locationName: item
            };
            res.data.data.forEach(function (val) {
              if (val.locationName.indexOf(item) != -1) {
                obj.checkNum = val.checkNum;
                obj.locationCode = val.locationCode;
              }
            });
            data.push(obj);
          });
          _this2.dataFun(data);
        }
      });
    },
    // 城市数据
    getCityFun: function getCityFun() {
      var _this3 = this;
      var formInline = JSON.parse(JSON.stringify(this.formInline));
      delete formInline.productName;
      (0, _fw.searchCity)((0, _objectSpread2.default)({
        provinceCode: this.provinceId
      }, formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this3.provinceList = res.data.data;
          var mapData = [];
          res.data.data.forEach(function (item, index) {
            mapData.push({
              name: item.locationName,
              value: item.checkNum,
              provinceId: item.locationCode
            });
          });
          _this3.$refs.provinceChart.mapData = mapData;
          _this3.$refs.provinceChart.initChart(_this3.provinceName.replace(/省|市/g, '') || '北京');
        }
      });
    },
    // 切换城市
    getCityData: function getCityData(data) {
      console.log(data);
      this.provinceName = data.name;
      this.provinceId = data.provinceId;
      if (this.provinceId) {
        this.getProvinceFun();
      } else {
        this.$message.error('当前省份无数据');
      }
    },
    // 省份下载
    provinceDown: function provinceDown() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#provinceTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '省份防伪数据.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    // 城市下载
    cityDown: function cityDown() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#cityTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '城市防伪数据.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;