"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _templateManage = require("@/api/terminal/templateManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadImg: _UploadImg.default
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (from.path === '/marketingManage/marketingActivities/templateConfig') {
  //       vm.from = '/marketingManage/marketingActivities/templateManage'
  //     } else {
  //       vm.from = from.path
  //     }
  //   })
  // },
  // beforeRouteLeave(to, from, next) {
  //   sessionStorage.removeItem('TemplateConfig')
  //   next()
  // },
  props: ['type', 'disabled', 'id'],
  data: function data() {
    return {
      from: '',
      listLoading: false,
      // id: '',
      activeName: 'basic',
      active: 0,
      activeMax: 5,
      checkName: '',
      // type: '',
      bgImg: '',
      info: [],
      // disabled: false,
      basicForm: {
        name: '',
        templateBak: '',
        templateState: null
      },
      guideForm: {
        infotype: 1,
        guideEnable: 0,
        bktotal: '',
        title: '',
        guideAutoJump: '0',
        guideWaitSeconds: '3',
        bkbutton: ''
      },
      zphdForm: {
        infotype: 2,
        bkcolor: '#ffffff',
        bktotal: '',
        bkpart: '',
        bkbutton: ''
      },
      smhdForm: {
        infotype: 2,
        bkcolor: '#ffffff',
        bktotal: '',
        bkbutton: ''
      },
      jzhdForm: {
        infotype: 2,
        bkcolor: '#ffffff',
        bktotal: '',
        bkpart: ''
      },
      followForm: {
        infotype: 6,
        guideAttentionEnable: 1,
        bktotal: '',
        content: ''
      },
      cardForm: {
        infotype: 7,
        bktotal: '',
        bkpart: '',
        title: ''
      },
      infoForm: {
        infotype: 4,
        bktotal: '',
        bkbutton: '',
        checkList: []
      },
      rules: {
        name: [{
          required: !this.disabled,
          message: '模版名称不能为空',
          trigger: 'change'
        }],
        templateState: [{
          required: !this.disabled,
          message: '请选择模板状态',
          trigger: 'change'
        }]
      },
      guideRules: {
        title: [{
          message: '标题不能为空',
          trigger: 'submit'
        }],
        bktotal: [{
          message: '请上传背景图片',
          trigger: 'submit'
        }],
        guideAutoJump: [{
          message: '请选择跳转形式',
          trigger: 'submit'
        }],
        guideWaitSeconds: [{
          message: '请选择等待时间',
          trigger: 'submit'
        }],
        bkbutton: [{
          message: '请上传按钮图片',
          trigger: 'submit'
        }]
      },
      cardRules: {
        title: [{
          required: !this.disabled,
          message: '标题不能为空',
          trigger: 'submit'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'submit'
        }],
        bkpart: [{
          required: !this.disabled,
          message: '请上传字牌背景图片',
          trigger: 'change'
        }]
      },
      zphdRules: {
        bkcolor: [{
          required: !this.disabled,
          message: '请选择背景颜色',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }]
      },
      smhdRules: {
        bkcolor: [{
          required: !this.disabled,
          message: '请选择背景颜色',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      jzhdRules: {
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkpart: [{
          required: !this.disabled,
          message: '请上传字牌图片',
          trigger: 'change'
        }]
      },
      resRules: {
        content: [{
          required: !this.disabled,
          message: '恭喜语不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      specialRules: {
        content: [{
          required: !this.disabled,
          message: '内容不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      followRules: {
        content: [{
          message: '文字信息不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          message: '请上传背景图片',
          trigger: 'change'
        }]
      },
      infoRules: {
        checkList: [{
          required: !this.disabled,
          message: '标题不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      succseeRules: {
        content: [{
          required: !this.disabled,
          message: '内容不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      failRules: {
        content: [{
          required: !this.disabled,
          message: '内容不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      beforeRules: {
        content: [{
          required: !this.disabled,
          message: '内容不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      keptRules: {
        content: [{
          required: !this.disabled,
          message: '内容不能为空',
          trigger: 'change'
        }],
        bktotal: [{
          required: !this.disabled,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bkbutton: [{
          required: !this.disabled,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    console.log(this.type);
    console.log(this.id);
    if (this.id) {
      this.getDetail();
    }
    this.getInfo();
    if (this.type === '1') {
      this.activeMax = 5;
    } else {
      this.activeMax = 4;
    }
  },
  methods: {
    // 取消
    goBack: function goBack() {
      this.active = 0;
      this.$refs.basicForm.resetFields();
      this.$refs.guideForm.resetFields();
      this.$refs.guidebktotal.fileList = [];
      this.$refs.guidebktotal.hideUpload = false;
      this.$refs.guidebkbutton.fileList = [];
      this.$refs.guidebkbutton.hideUpload = false;
      switch (this.type * 1) {
        case 1:
          this.$refs.jzhdForm.resetFields();
          this.$refs.jzhdbktotal.fileList = [];
          this.$refs.jzhdbktotal.hideUpload = false;
          this.$refs.jzhdbkpart.fileList = [];
          this.$refs.jzhdbkpart.hideUpload = false;
          this.$refs.cardForm.resetFields();
          this.$refs.cardbktotal.fileList = [];
          this.$refs.cardbktotal.hideUpload = false;
          this.$refs.cardbkpart.fileList = [];
          this.$refs.cardbkpart.hideUpload = false;
          break;
        case 2:
          this.$refs.smhdForm.resetFields();
          this.$refs.smhdbktotal.fileList = [];
          this.$refs.smhdbktotal.hideUpload = false;
          this.$refs.smhdbkbutton.fileList = [];
          this.$refs.smhdbkbutton.hideUpload = false;
          break;
        case 3:
          this.$refs.zphdForm.resetFields();
          this.$refs.zphdbktotal.fileList = [];
          this.$refs.zphdbktotal.hideUpload = false;
          break;
        case 4:
          this.$refs.smhdForm.resetFields();
          this.$refs.smhdbktotal.fileList = [];
          this.$refs.smhdbktotal.hideUpload = false;
          this.$refs.smhdbkbutton.fileList = [];
          this.$refs.smhdbkbutton.hideUpload = false;
          break;
      }
      this.$refs.followForm.resetFields();
      this.$refs.followbktotal.fileList = [];
      this.$refs.followbktotal.hideUpload = false;
      this.$refs.infoForm.resetFields();
      this.$refs.infobktotal.fileList = [];
      this.$refs.infobktotal.hideUpload = false;
      this.$refs.infobkbutton.fileList = [];
      this.$refs.infobkbutton.hideUpload = false;
      this.$emit('close');
    },
    // 获取模板详细
    getDetail: function getDetail() {
      var _this = this;
      this.listLoading = false;
      (0, _templateManage.templateGetById)(this.id).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this.basicForm = res.data;
          res.data.bkInfos.forEach(function (v, k) {
            var _this$infoForm$checkL;
            switch (v.infotype) {
              case '1':
                v.guideEnable = res.data.guideEnable;
                v.guideAutoJump = res.data.guideAutoJump;
                v.guideWaitSeconds = res.data.guideWaitSeconds;
                if (v.bktotal) {
                  _this.$refs.guidebktotal.fileList = [{
                    url: v.bktotal
                  }];
                  _this.$refs.guidebktotal.hideUpload = true;
                }
                if (v.bkbutton) {
                  _this.$refs.guidebkbutton.fileList = [{
                    url: v.bkbutton
                  }];
                  _this.$refs.guidebkbutton.hideUpload = true;
                }
                Object.assign(_this.guideForm, v);
                break;
              case '2':
                if (_this.type === '2') {
                  if (v.bktotal) {
                    _this.$refs.smhdbktotal.fileList = [{
                      url: v.bktotal
                    }];
                    _this.$refs.smhdbktotal.hideUpload = true;
                  }
                  if (v.bkbutton) {
                    _this.$refs.smhdbkbutton.fileList = [{
                      url: v.bkbutton
                    }];
                    _this.$refs.smhdbkbutton.hideUpload = true;
                  }
                  _this.smhdForm = v;
                  _this.bgImg = v.bktotal;
                } else if (_this.type === '3') {
                  if (v.bktotal) {
                    _this.$refs.zphdbktotal.fileList = [{
                      url: v.bktotal
                    }];
                    _this.$refs.zphdbktotal.hideUpload = true;
                  }
                  _this.zphdForm = v;
                  _this.bgImg = v.bktotal;
                } else {
                  if (v.bktotal) {
                    _this.$refs.jzhdbktotal.fileList = [{
                      url: v.bktotal
                    }];
                    _this.$refs.jzhdbktotal.hideUpload = true;
                  }
                  if (v.bkpart) {
                    _this.$refs.jzhdbkpart.fileList = [{
                      url: v.bkpart
                    }];
                    _this.$refs.jzhdbkpart.hideUpload = true;
                  }
                  _this.jzhdForm = v;
                  _this.bgImg = v.bktotal;
                }
                break;
              // case '3':
              //   this.$refs.resbktotal.fileList.push({ url: v.bktotal })
              //   this.$refs.resbkbutton.fileList.push({ url: v.bkbutton })
              //   this.$refs.resbktotal.hideUpload = true
              //   this.$refs.resbkbutton.hideUpload = true
              //   this.resForm = v
              //   break
              case '4':
                var arr = [];
                res.data.collectionInfo.forEach(function (val, key) {
                  if (val.isSelect === '1') {
                    arr.push(key);
                  }
                });
                if (v.bktotal) {
                  _this.$refs.infobktotal.fileList = [{
                    url: v.bktotal
                  }];
                  _this.$refs.infobktotal.hideUpload = true;
                }
                if (v.bkbutton) {
                  _this.$refs.infobkbutton.fileList = [{
                    url: v.bkbutton
                  }];
                  _this.$refs.infobkbutton.hideUpload = true;
                }
                Object.assign(_this.infoForm, v);
                (_this$infoForm$checkL = _this.infoForm.checkList).push.apply(_this$infoForm$checkL, arr);
                _this.infoForm.checkList = Array.from(new Set(_this.infoForm.checkList));
                console.log(_this.infoForm.checkList);
                break;
              // case '5':
              //   this.$refs.specialbktotal.fileList.push({ url: v.bktotal })
              //   this.$refs.specialbkbutton.fileList.push({ url: v.bkbutton })
              //   this.$refs.specialbktotal.hideUpload = true
              //   this.$refs.specialbkbutton.hideUpload = true
              //   this.specialForm = v
              //   break
              case '6':
                v.guideAttentionEnable = res.data.guideAttentionEnable;
                if (v.bktotal) {
                  _this.$refs.followbktotal.fileList = [{
                    url: v.bktotal
                  }];
                  _this.$refs.followbktotal.hideUpload = true;
                }
                Object.assign(_this.followForm, v);
                break;
              case '7':
                if (v.bktotal) {
                  _this.$refs.cardbktotal.fileList = [{
                    url: v.bktotal
                  }];
                  _this.$refs.cardbktotal.hideUpload = true;
                }
                if (v.bkpart) {
                  _this.$refs.cardbkpart.fileList = [{
                    url: v.bkpart
                  }];
                  _this.$refs.cardbkpart.hideUpload = true;
                }
                _this.cardForm = v;
                break;
            }
          });
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    // 获取收集信息
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _templateManage.getInfoList)().then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this2.info = res.data;
          res.data.forEach(function (val, key) {
            if (val.name === '姓名' || val.name === '手机号' || val.name === '地址') {
              // console.log(111, "222");
              _this2.infoForm.checkList.push(key);
            }
          });
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 设置背景图片
    setBgImg: function setBgImg(val) {
      this.bgImg = val;
    },
    // 无用...
    leaveTab: function leaveTab(activeName, oldActiveName) {
      var text;
      switch (oldActiveName) {
        case 'basic':
          this.checkName = 'basicForm';
          text = '请完善基础信息';
          break;
        case 'guide':
          this.checkName = 'guideForm';
          text = '请完善引导页信息';
          break;
        case 'active':
          if (this.type === '2') {
            this.checkName = 'smhdForm';
          } else if (this.type === '3') {
            this.checkName = 'zphdForm';
          } else {
            this.checkName = 'jzhdForm';
          }
          text = '请完善活动页信息';
          break;
        // case 'res':
        //   this.checkName = 'resForm'
        //   text = '请完善结果信息'
        //   break
        case 'info':
          this.checkName = 'infoForm';
          text = '请完信息收集页信息';
          break;
        // case 'special':
        //   this.checkName = 'specialForm'
        //   text = '请完善特殊提示页信息'
        //   break
        case 'follow':
          this.checkName = 'followForm';
          text = '请完善引导关注页信息';
          break;
        case 'card':
          this.checkName = 'cardForm';
          text = '请完善我的字牌页信息';
          break;
      }
      return this.checkForm(this.checkName, text);
    },
    // 按钮事件
    subForm: function subForm(text) {
      var _this3 = this;
      // 上一步
      if (text === 'up') {
        // if (this.active > 0) {
        if (this.active === 0) {
          this.$refs['basicForm'].clearValidate();
        } else if (this.active === 1) {
          this.$refs['guideForm'].clearValidate();
        } else if (this.active === 2) {
          if (this.type === '2') {
            this.$refs['smhdForm'].clearValidate();
          } else if (this.type === '3') {
            this.$refs['zphdForm'].clearValidate();
          } else if (this.type === '4') {
            this.$refs['zphdForm'].clearValidate();
          } else {
            this.$refs['jzhdForm'].clearValidate();
          }
        } else if (this.active === 3) {
          this.$refs['infoForm'].clearValidate();
        } else if (this.active === 4) {
          this.$refs['followForm'].clearValidate();
        } else if (this.active === 5) {
          this.$refs['cardForm'].clearValidate();
        }
        this.active--;
        // } else {
        //   this.active = 0
        // }
      } else if (text === 'down') {
        // 下一步
        if (this.active === 0) {
          var basic = this.checkForm('basicForm', '请完善基础信息');
        } else if (this.active === 1) {
          var guide = this.checkForm('guideForm', '请完善引导页信息');
        } else if (this.active === 2) {
          var active;
          if (this.type === '2') {
            active = this.checkForm('smhdForm', '请完善活动页信息');
          } else if (this.type === '3') {
            active = this.checkForm('zphdForm', '请完善活动页信息');
          } else if (this.type === '4') {
            active = this.checkForm('zphdForm', '请完善活动页信息');
          } else {
            active = this.checkForm('jzhdForm', '请完善活动页信息');
          }
        } else if (this.active === 3) {
          var info = this.checkForm('infoForm', '请完信息收集页信息');
        } else if (this.active === 4) {
          var follow = this.checkForm('followForm', '请完善引导关注页信息');
        } else if (this.active === 5) {
          var card = this.checkForm('cardForm', '请完善我的字牌页信息');
        }
        var arr = [basic, guide, active, info, follow];
        if (this.type === 1) arr.push(card);
        // console.log(this.infoForm);
        Promise.all(arr).then(function (res) {
          res = res.data;
          var bkInfos = [];
          bkInfos.push(_this3.guideForm);
          if (_this3.type === '4') bkInfos.push(_this3.zphdForm);
          if (_this3.type === '3') bkInfos.push(_this3.zphdForm);
          if (_this3.type === '2') bkInfos.push(_this3.smhdForm);
          if (_this3.type === '1') {
            bkInfos.push(_this3.jzhdForm);
            bkInfos.push(_this3.cardForm);
          }
          bkInfos.push(_this3.followForm);
          bkInfos.push(_this3.infoForm);
          var collectionInfo = [];
          _this3.infoForm.checkList.forEach(function (v, k) {
            _this3.info[v].isSelect = '1';
            collectionInfo.push(_this3.info[v]);
          });
          _this3.basicForm.guideEnable = _this3.guideForm.guideEnable;
          _this3.basicForm.guideAttentionEnable = _this3.followForm.guideAttentionEnable;
          _this3.basicForm.guideWaitSeconds = _this3.guideForm.guideWaitSeconds;
          _this3.basicForm.guideAutoJump = _this3.guideForm.guideAutoJump;
          _this3.basicForm.templateType = _this3.type;
          _this3.basicForm.bkInfos = bkInfos;
          _this3.basicForm.collectionInfo = collectionInfo;
          _this3.active++;
          if (!_this3.disabled && _this3.active > _this3.activeMax) {
            if (_this3.id) {
              (0, _templateManage.templateEdit)(_this3.basicForm).then(function (res) {
                res = res.data;
                if (res.status === 200) {
                  _this3.$message.closeAll();
                  _this3.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 3 * 1000
                  });
                  _this3.goBack();
                } else {
                  _this3.$message.closeAll();
                  _this3.$message({
                    message: res.msg,
                    type: 'error',
                    duration: 3 * 1000
                  });
                }
              });
            } else {
              (0, _templateManage.templateAdd)(_this3.basicForm).then(function (res) {
                res = res.data;
                if (res.status === 200) {
                  _this3.$message.closeAll();
                  _this3.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 3 * 1000
                  });
                  _this3.goBack();
                } else {
                  _this3.$message.closeAll();
                  _this3.$message({
                    message: res.msg,
                    type: 'error',
                    duration: 3 * 1000
                  });
                }
              });
            }
          }
          if (_this3.active > _this3.activeMax) {
            _this3.active = _this3.activeMax;
          }
        }).catch(function (err) {
          _this3.$message.closeAll();
          _this3.$message({
            message: err,
            type: 'error',
            duration: 3 * 1000
          });
        });
      }
      this.$nextTick(function () {
        _this3.$refs.box.scrollTop = 0;
      });
    },
    checkForm: function checkForm(val, text) {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.$refs[val].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            _this4.$message.closeAll();
            _this4.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
            reject(text);
          }
        });
      });
    }
  }
};
exports.default = _default;