var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "稽查出库-查看",
        visible: _vm.scanVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.scanVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "stepArea" },
        [
          _c("div", { staticClass: "billTitle" }, [
            _c("img", { attrs: { src: require("@/images/topimg.png") } }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.baseData.businessTypeStr) +
                " " +
                _vm._s(_vm.baseData.no) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-steps",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                active: _vm.active,
                "process-status": "success",
                "finish-status": "success",
              },
            },
            [
              _c("el-step", { attrs: { title: "创建" } }),
              _vm._v(" "),
              _vm.baseData.orderStatus != 0
                ? _c("el-step", { attrs: { title: "全部发货" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.baseData.orderStatus != 0
                ? _c("el-step", { attrs: { title: "收货" } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("单据信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货单位编码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.sellerCode)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货单位名称:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.sellerName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货单位分类:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.sellerClassName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货组织:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.sellerOrg)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货日期:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("formatTime")(_vm.baseData.outDate))),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货单位编码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.buyerCode)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货单位名称:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.buyerName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货单位分类:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.buyerClassName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货组织:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.buyerOrg)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货日期:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("formatTime")(_vm.baseData.inDate))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("来源单号:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.orderNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("异常状态:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.baseData.isError == 1 ? "正常" : "异常")),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "itemTitle" },
        [
          _c("span", { staticStyle: { "margin-right": "20px" } }, [
            _vm._v("产品明细"),
          ]),
          _vm._v(" "),
          (_vm.baseData.isCode == 1 ? true : false)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.scanCode },
                },
                [_vm._v("查看数码")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      (_vm.productlist.length > 0 ? true : false)
        ? _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%" },
              attrs: { "max-height": "400", data: _vm.productlist, fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  align: "center",
                  prop: "productCode",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: "center",
                  prop: "productName",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "品牌",
                  align: "center",
                  prop: "productBrand",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  align: "center",
                  prop: "packScale",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "应发数量(盒)",
                  align: "center",
                  prop: "orderNum",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实发数量(盒)",
                  align: "center",
                  prop: "outNum",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "countWrap" }, [
        _c("div", { staticStyle: { "min-width": "250px" } }, [
          _vm._v("应发总数量：" + _vm._s(_vm.baseData.outNumPlan)),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("实发总数量：" + _vm._s(_vm.baseData.outNum))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("整单备注")]),
      _vm._v(" "),
      _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
        _vm._v(_vm._s(_vm.remark)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("图片附件")]),
      _vm._v(" "),
      _c(
        "el-row",
        _vm._l(_vm.fileUrls, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 4 } },
            [
              _c("el-image", {
                staticStyle: { width: "100px", height: "100px" },
                attrs: { src: item, "preview-src-list": _vm.fileUrls },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "数码详情",
            visible: _vm.scanCodeVisible,
            "append-to-body": "",
            width: "1200px !important",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanCodeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.codeParam, "label-width": "80px" } },
            [
              _c(
                "el-row",
                { staticClass: "cols", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数码", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.codeParam.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeParam, "code", $$v)
                              },
                              expression: "codeParam.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "productName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                              readonly: true,
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.choseProName("codeScan")
                              },
                            },
                            model: {
                              value: _vm.codeParam.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeParam, "productName", $$v)
                              },
                              expression: "codeParam.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isError", label: "异常状态:" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.codeParam.isError,
                                callback: function ($$v) {
                                  _vm.$set(_vm.codeParam, "isError", $$v)
                                },
                                expression: "codeParam.isError",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "正常", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "异常", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "180px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchCode },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "default",
                            plain: "",
                            icon: "el-icon-refresh",
                          },
                          on: { click: _vm.searchReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.codeData, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "数码",
                  prop: "code",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编号",
                  prop: "productCode",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  "min-width": "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "productClass",
                  "min-width": "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "isError",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(row.isError == 1 ? "正常" : "异常")),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total2 > 0,
                expression: "total2 > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total2,
              page: _vm.codeParam.pageNo,
              limit: _vm.codeParam.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.codeParam, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.codeParam, "pageSize", $event)
              },
              pagination: _vm.getCodeDetails,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }