"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _task = _interopRequireDefault(require("./task"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    productDialog: _productSelectDialog.default,
    task: _task.default
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      row.sumPlanAto = num;
      return num;
    }
  },
  data: function data() {
    return {
      title: '添加费用酒入库',
      billType: 107,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        billNo: '',
        billType: 107,
        billSource: '',
        sumPlanAtoNum: '',
        sumPlanNum: '',
        inOrgId: '',
        inOrgType: '',
        inStoreId: '',
        outOrgId: '',
        outOrgType: '',
        relateNo: '',
        tradeBillDetailAOS: []
      },
      disabled: {
        num: false
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      proList: [],
      sourceList: [{
        name: '平台自动创建',
        id: 1
      }, {
        name: '平台用户创建',
        id: 2
      }, {
        name: 'PDA创建',
        id: 3
      }, {
        name: 'ERP同步',
        id: 4
      }],
      inOrgTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }, {
        name: '经销商',
        id: 3
      }],
      outOrgTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }],
      inOrg: {},
      taskShow: false,
      rules: {
        billNo: [{
          required: true,
          message: '入库单号不能为空，请维护',
          trigger: 'change'
        }],
        relateNo: [{
          required: true,
          message: '销售出库单号不能为空，请维护',
          trigger: 'change'
        }],
        inStoreId: [{
          required: true,
          message: '请选入库库房',
          trigger: 'change'
        }]
      },
      inOrgName: '',
      outOrgName: ''
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.submitType != 2) {
          this.getCreateNum();
          this.title = '添加费用酒入库';
        }
      }
    }
  },
  methods: {
    init: function init() {
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
    },
    handClose: function handClose() {
      var that = this;
      that.scanData = {
        billNo: '',
        billType: 107,
        billSource: '',
        sumPlanAtoNum: '',
        sumPlanNum: '',
        inOrgId: '',
        inOrgType: '',
        inStoreId: '',
        outOrgId: '',
        outOrgType: '',
        relateNo: '',
        tradeBillDetailAOS: []
      };
      that.dialogVisible = false;
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      this.inOrgName = '';
      this.outOrgName = '';
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = false;
    },
    upDateShow: function upDateShow(row) {
      var that = this;
      this.init();
      this.title = '修改费用酒入库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      this.inOrgName = '';
      this.outOrgName = '';
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      setTimeout(function () {
        that.dialogVisible = true;
      }, 500);

      // 获取详细信息
      var param = row.billNo;
      that.scanData.billNo = row.billNo;
      (0, _index.getPeoductionDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          var basic = res.data.data.tradeBillAllVO;
          var promsg = res.data.data.tradeProductVOS;
          that.proList = promsg;
          that.transFormData(promsg);
          that.scanData.billNo = basic.billNo;
          that.scanData.billSource = basic.billSource;
          that.scanData.relateNo = basic.relateNo;

          // 出入库企业id
          that.scanData.inOrgId = basic.inOrgId;
          that.scanData.outOrgId = basic.outOrgId;
          that.inOrgName = basic.inOrgName;
          that.outOrgName = basic.outOrgName;
          var inStoreName = basic.inStoreName;

          // 入库库房
          that.factoryList = [];
          that.getHouses(basic.inOrgId, inStoreName);
        } else {
          that.proList = [];
          that.transFormData(that.proList);
          that.scanData.billNo = '';
          that.scanData.billSource = '';
          that.scanData.billStatus = '';
          that.scanData.relateNo = '';
          that.scanData.outOrgId = '';
          that.scanData.inStoreId = '';
          that.scanData.inOrgId = '';
          that.inOrgName = '';
          that.outOrgName = '';
          that.$message.error(res.data.msg);
          return;
        }
      });
      this.mloading = false;
    },
    // 创建单号
    getCreateNum: function getCreateNum() {
      var _this = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        }
        _this.scanData.billNo = res.data.data;
        _this.disabled.num = true;
      });
    },
    // 获取销售出库单号
    getRelateNo: function getRelateNo() {
      var that = this;
      that.taskShow = true;
    },
    hideTask: function hideTask() {
      var that = this;
      that.taskShow = false;
    },
    getTask: function getTask(val) {
      var that = this;
      var value = JSON.parse(val);
      that.scanData.relateNo = value.billNo;
      that.scanData.billSource = value.billSource;
      that.$refs.taskInput.focus();
      that.$refs.taskInput.blur();
      // 带出入库企业
      that.scanData.outOrgId = value.outOrgId;
      that.inOrgName = value.inOrgName;
      that.$refs.shdw.focus();
      that.$refs.shdw.blur();
      that.scanData.inOrgId = value.inOrgId;
      that.outOrgName = value.outOrgName;
      that.$refs.fhdw.focus();
      that.$refs.fhdw.blur();

      // 带出入库库房
      that.scanData.inStoreId = '';
      that.factoryList = [];
      that.getHouses(value.inOrgId, value.inStoreName);

      // 根据任务单号获取产品信息
      var relateType = 2;
      that.getProMsg(relateType, value.billNo);
      that.taskShow = false;
    },
    // 获取入库库房
    getHouses: function getHouses(arg, storeName) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.factoryList = [];
          that.factoryList = res.data.data;
          return res.data.data;
        }
      }).then(function (res) {
        if (res.length > 0) {
          for (var i = 0; i < res.length; i++) {
            if (res[i].storeHouseName == storeName) {
              that.scanData.inStoreId = res[i].pId;
              break;
            }
          }
        }
      });
    },
    // 获取产品信息
    getProMsg: function getProMsg(relateType, relateNo) {
      var that = this;
      that.proList = [];
      (0, _select.getProDetail)(relateType, relateNo).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData.length) {
            for (var i = 0; i < resData.length; i++) {
              var item = resData[i];
              if (Number(item.realScanAtoNum) > 0) {
                var obj = {};
                obj.productCode = item.productCode;
                obj.productName = item.productName;
                obj.packRadioDesc = item.packRadioDesc;
                obj.packUnit = item.packUnit;
                obj.planScanAtoNum = Number(item.realScanAtoNum);
                obj.planScanNum = Number(item.realScanNum);
                obj.prodBatchNo = item.prodBatchNo;
                obj.productId = item.productId;
                that.proList.push(obj);
              }
            }
          }
          that.transFormData(that.proList);
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    transFormData: function transFormData(rsData) {
      var that = this;
      var planScanAtoNum = 0;
      var planScanNum = 0;
      var products = [];
      that.scanData.tradeBillDetailAOS = [];
      if (rsData.length > 0) {
        rsData.forEach(function (item) {
          var obj = {};
          planScanAtoNum += Number(item.planScanAtoNum);
          planScanNum += Number(item.planScanNum);
          obj.billNo = that.scanData.billNo;
          obj.packUnit = item.packUnit;
          obj.planScanAtoNum = Number(item.planScanAtoNum);
          obj.planScanNum = Number(item.planScanNum);
          obj.prodBatchNo = item.prodBatchNo;
          obj.productId = item.productId;
          products.push(obj);
        });
      }
      if (Number(planScanAtoNum) > 0) {
        that.scanData.tradeBillDetailAOS = products;
      }
      that.scanData.planScanAtoNum = planScanAtoNum;
      that.scanData.planScanNum = planScanNum;
    },
    submitForm: function submitForm(temp) {
      var _this2 = this;
      // this.setDetail()
      var that = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          var tradeBillAO = {};
          if (_this2.submitType == 2) {
            tradeBillAO.billInPurchaseAO = {};
            tradeBillAO.billInPurchaseAO.inOrgId = _this2.scanData.inOrgId;
            tradeBillAO.billInPurchaseAO.inStoreId = _this2.scanData.inStoreId;
            tradeBillAO.billInPurchaseAO.outOrgId = _this2.scanData.outOrgId;
            tradeBillAO.billInPurchaseAO.relateNo = _this2.scanData.relateNo;
            tradeBillAO.billInPurchaseAO.inOrgType = _this2.scanData.inOrgType;
            tradeBillAO.billInPurchaseAO.outOrgType = _this2.scanData.outOrgType;
            tradeBillAO.billNo = _this2.scanData.billNo;
            tradeBillAO.billType = _this2.scanData.billType;
            tradeBillAO.billSource = _this2.scanData.billSource;
            tradeBillAO.sumPlanAtoNum = that.scanData.planScanAtoNum;
            tradeBillAO.sumPlanNum = that.scanData.planScanNum;
            tradeBillAO.tradeBillDetailAOS = _this2.scanData.tradeBillDetailAOS;
            (0, _index.InfoUpdate)(tradeBillAO).then(function (res) {
              _this2.btn_loading = false;
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.$parent.getList();
                _this2.handleClose();
              }
            }).catch(function () {
              _this2.btn_loading = false;
            });
          } else {
            tradeBillAO.billInPurchaseAO = {};
            tradeBillAO.billInPurchaseAO.inOrgId = _this2.scanData.inOrgId;
            tradeBillAO.billInPurchaseAO.inStoreId = _this2.scanData.inStoreId;
            tradeBillAO.billInPurchaseAO.outOrgId = _this2.scanData.outOrgId;
            tradeBillAO.billInPurchaseAO.relateNo = _this2.scanData.relateNo;
            tradeBillAO.billInPurchaseAO.inOrgType = _this2.scanData.inOrgType;
            tradeBillAO.billInPurchaseAO.outOrgType = _this2.scanData.outOrgType;
            tradeBillAO.billNo = _this2.scanData.billNo;
            tradeBillAO.billType = _this2.scanData.billType;
            tradeBillAO.billSource = _this2.scanData.billSource;
            tradeBillAO.sumPlanAtoNum = that.scanData.planScanAtoNum;
            tradeBillAO.sumPlanNum = that.scanData.planScanNum;
            tradeBillAO.tradeBillDetailAOS = _this2.scanData.tradeBillDetailAOS;
            (0, _index.InfoInsert)(tradeBillAO).then(function (res) {
              _this2.btn_loading = false;
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.$parent.getList();
              }
            }).catch(function () {
              _this2.btn_loading = false;
            });
          }
        }
      });
    },
    handleClose: function handleClose() {
      this.handClose();
      this.dialogVisible = false;
      this.$parent.getList();
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.proList = [val];
    },
    setSum: function setSum(e) {
      // this.scanData = JSON.parse(JSON.stringify(this.scanData))
      // this.scanData.planNum = this.valENnum(e)
    },
    setScanFactory: function setScanFactory(val) {
      if (!val) return;
      for (var i in this.factoryList) {
        var items = this.factoryList[i];
        if (items.pId == val) {
          if (items.orgId != null) {
            // 获取企业数据
            this.scanData.inOrgId = items.orgId;
          }
        }
      }
      return false;
    }
  }
};
exports.default = _default;