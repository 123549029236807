"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _datacollection = require("@/api/generalSettings/datacollection");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagegeneralSettingsdatacollectionindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagegeneralSettingsdatacollectionindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var validateOrg = _validate.checkName;
    var checkLength = function checkLength(rule, value, callback) {
      var len = 0;
      if (value) {
        for (var i = 0; i < value.length; i++) {
          var c = value.charCodeAt(i);
          // 单字节加1
          if (c >= 0x0001 && c <= 0x007e || c >= 0xff60 && c <= 0xff9f) {
            len++;
          } else {
            len += 2;
          }
        }
        if (len > 20) {
          callback(new Error('最多为不超过20个字符，相当于10个汉字。'));
        }
        callback();
      }
      callback();
    };
    return {
      field: {
        name: '',
        type: ''
      },
      listLoadig: false,
      dialogVisible: false,
      // 字段列表信息
      tableData: [],
      officialAccountdialogVisible: false,
      title: '',
      // 新增字段列表
      officialAccountFrom: {
        fieldName: '',
        fieldType: '1',
        verifyType: '',
        verifyLength: '',
        isMust: '',
        fieldOptions: [],
        fieldExplain: ''
      },
      // 新增字段列表校验
      officialAccountRules: {
        fieldName: [{
          required: true,
          trigger: 'blur',
          validator: validateOrg
        }],
        fieldType: [{
          required: true,
          message: '请选择字段类型',
          trigger: 'change'
        }],
        verifyType: [{
          required: true,
          message: '请选择校验类型',
          trigger: 'change'
        }],
        verifyLength: [{
          required: true,
          message: '请填写长度校验',
          trigger: 'blur'
        }, {
          pattern: /^[\d-]+$/,
          message: '只支持数字和符号"-" '
        }],
        isMust: [{
          required: true,
          message: '请选择是否必输',
          trigger: 'change'
        }],
        fieldExplain: [{
          required: false,
          trigger: 'blur',
          validator: checkLength
        }],
        fieldOptions: [{
          required: false,
          message: '请选择字段',
          trigger: 'change'
        }]
      },
      // 校验类型数组
      checkTypes: [{
        id: '1',
        name: '无'
      }, {
        id: '2',
        name: '字段'
      }, {
        id: '3',
        name: '数字'
      }],
      isLengthCheck: false,
      // 字段选项
      ptions: [],
      inputVisible: false,
      inputValue: '',
      isOptions: false,
      showInput: true,
      isParityBit: true,
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        fieldName: '',
        status: null
      },
      total: 0,
      // 数据收集列表总条数
      dynamicTags: [],
      showStop: {
        id: '',
        status: null
      },
      ID: '',
      disabled: false,
      btnLoading: false
    };
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getDateCollectionInfoList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取数据收集列表
    getDateCollectionInfoList: function getDateCollectionInfoList() {
      var _this = this;
      this.listLoadig = true;
      (0, _datacollection.dateCollectionInfoList)(this.queryParams).then(function (res) {
        _this.listLoadig = false;
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data !== null) {
            _this.total = parseInt(res.data.data.total);
            _this.tableData = res.data.data.records;
          } else {
            _this.total = 0;
            _this.tableData = [];
          }
        }
      }).finally(function () {
        _this.listLoadig = false;
      });
    },
    addOfficialAccount: function addOfficialAccount() {
      var _this2 = this;
      this.disabled = false;
      this.btnLoading = false;
      this.isLengthCheck = false;
      this.title = '新增字段';
      this.$nextTick(function () {
        _this2.restOfficialAccountFrom();
        _this2.$refs.officialAccountForm.resetFields();
      });
      this.officialAccountdialogVisible = true;
    },
    // 查询字段信息
    onFieldSubmit: function onFieldSubmit() {},
    // 选择字段类型
    selectType: function selectType(value) {
      console.log(value);
      this.isLengthCheck = !!(value != 4 && value != 1);
      this.officialAccountFrom.verifyLength = '';
      console.log(this.officialAccountFrom);
    },
    handleClose: function handleClose(index) {
      //  this.dynamicTags.splice(index,1);
      this.officialAccountFrom.fieldOptions.splice(index, 1);
    },
    addTags: function addTags() {
      var _this3 = this;
      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      if (this.inputValue) {
        if (this.officialAccountFrom.fieldOptions.includes(this.inputValue)) {
          // this.officialAccountFrom.fieldOptions.push('')
          // this.officialAccountFrom.fieldOptions.pop()
          // this.inputValue = '';
          return this.$message.error('字段选项不能重复');
        }
        this.officialAccountFrom.fieldOptions.push(this.inputValue);
      }
      console.log(this.officialAccountFrom);
      this.inputValue = '';
      this.inputVisible = false;
      // this.dynamicTags = [ '10-20','20-30','30-40','40-50'];
    },
    // 选择字段类型事件
    selectField: function selectField(value) {
      // 选择单选或多选时 清空校验类型和长度检验字段
      if (value != 1) {
        this.officialAccountFrom.verifyType = '';
        this.officialAccountFrom.verifyLength = '';
        this.isLengthCheck = false;
      }
      this.isParityBit = !(value == 2 || value == 3);
      // this.isLengthCheck = value == 2 || value == 3 ? false : true
      this.isLengthCheck = false;
      this.officialAccountFrom.verifyType = '';
      this.isOptions = !!(value == 2 || value == 3);
      this.officialAccountFrom.fieldOptions = [];
      this.inputValue = '';
      this.inputVisible = false;
    },
    // 取消新增字段
    cancelField: function cancelField(ref) {
      this.restOfficialAccountFrom();
      this.isLengthCheck = false;
      this.isOptions = false;
      this.isParityBit = true;
      this.$refs.officialAccountForm.resetFields();
      this.officialAccountdialogVisible = false;
    },
    restOfficialAccountFrom: function restOfficialAccountFrom() {
      this.officialAccountFrom = {
        fieldName: '',
        fieldType: '1',
        verifyType: '',
        verifyLength: '',
        isMust: '',
        fieldOptions: [],
        fieldExplain: ''
      };
    },
    // 提交新增字段
    submitField: function submitField(ref) {
      var _this4 = this;
      this.$refs[ref].validate(function (valid) {
        // this.disabled = true
        // this.btnLoading = true
        if (valid) {
          if (_this4.title !== '新增字段') {
            (0, _datacollection.editDateCollectionInfoList)(_this4.officialAccountFrom).then(function (res) {
              if (res.data.data.errCode) {
                _this4.disabled = false;
                _this4.btnLoading = false;
                return _this4.$message.error(res.data.data.errMsg);
              } else {
                _this4.$message.success('修改成功');
                _this4.getDateCollectionInfoList();
                _this4.isParityBit = true;
                _this4.isOptions = false;
                _this4.officialAccountdialogVisible = false;
                _this4.disabled = true;
                _this4.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          } else {
            (0, _datacollection.addDateCollectionInfoList)(_this4.officialAccountFrom).then(function (res) {
              if (res.data.data.errCode) {
                _this4.disabled = false;
                _this4.btnLoading = false;
                return _this4.$message.error(res.data.data.errMsg);
              } else {
                _this4.$message.success('新增成功');
                _this4.getDateCollectionInfoList();
                _this4.isParityBit = true;
                _this4.isOptions = false;
                _this4.officialAccountdialogVisible = false;
                _this4.disabled = true;
                _this4.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          }
        } else {
          _this4.disabled = false;
          _this4.btnLoading = false;
        }
      });
    },
    // 点击编辑出现弹框
    editOfficialAccount: function editOfficialAccount(row) {
      this.disabled = false;
      this.btnLoading = false;
      console.log(row);
      this.isParityBit = !(row.fieldType == 2 || row.fieldType == 3);
      // this.isLengthCheck = row.fieldType == 2 || row.fieldType == 3 ? false : true
      // 根据校验类型判断是否显示长度校验
      if ((row.verifyType == 2 || row.verifyType == 3) && row.fieldType == 1) {
        this.isLengthCheck = true;
      } else {
        this.isLengthCheck = false;
      }
      this.isOptions = !!(row.fieldType == 2 || row.fieldType == 3);
      this.officialAccountFrom.fieldName = row.fieldName;
      this.officialAccountFrom.fieldType = row.fieldType;
      this.officialAccountFrom.verifyType = row.verifyType;
      this.officialAccountFrom.verifyLength = row.verifyLength;
      this.officialAccountFrom.isMust = row.isMust;
      if (row.fieldType == 2 || row.fieldType == 3) {
        this.officialAccountFrom.fieldOptions = JSON.parse(row.fieldOptions);
      }
      // this.officialAccountFrom.fieldOptions = row.fieldOptions
      // const arr = this.officialAccountFrom.fieldOptions
      this.officialAccountFrom.fieldExplain = row.fieldExplain;
      this.officialAccountFrom.id = row.id;
      this.title = '编辑字段';
      this.officialAccountdialogVisible = true;
    },
    // 数据信息的启用停用
    isStop: function isStop() {
      var _this5 = this;
      (0, _datacollection.isStop)(this.showStop).then(function (res) {
        if (res.data.data.errCode) {
          _this5.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this5.getDateCollectionInfoList();
          _this5.$message.success('修改成功');
        }
      });
    },
    switchTop: function switchTop(row) {
      var _this6 = this;
      // console.log(row)
      this.showStop.id = row.id;
      this.showStop.status = row.status == 2 ? 1 : 2;
      var str = row.status == 1 ? '停用' : '启用';
      this.$confirm("\u662F\u5426 ".concat(str, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        _this6.isStop();
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 删除改数据
    // dateCollection () {
    //   dateCollection ({id: this.ID}).then( res => {
    //     this.getDateCollectionInfoList()
    //     this.$message.success('删除成功')
    //   })
    // },
    dateCollection: function dateCollection(index, row) {
      var _this7 = this;
      console.log(row);
      // this.ID = row.id
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        // this.tableData.splice(index,1)
        (0, _datacollection.dateCollection)({
          id: row.id
        }).then(function (res) {
          if (res.data.data.errCode) {
            _this7.$message.success(res.data.data.errMsg);
          } else {
            _this7.getDateCollectionInfoList();
            _this7.$message.success('删除成功');
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    restDateCollectionInfoList: function restDateCollectionInfoList() {
      this.queryParams.fieldName = '';
      this.queryParams.status = '';
      this.getDateCollectionInfoList();
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getDateCollectionInfoList();
    },
    showFieldType: function showFieldType(row) {
      if (row.fieldType == 1) {
        return '输入';
      } else if (row.fieldType == 2) {
        return '单选';
      } else {
        return '多选';
      }
    },
    showVerifyType: function showVerifyType(row) {
      console.log(row);
      if (!row.verifyType) {
        return '无';
      }
      if (row.verifyType == 2) {
        return '字段';
      } else if (row.verifyType == 3) {
        return '数字';
      } else {
        return '无';
      }
    },
    showStatus: function showStatus(row) {
      if (row.status == 1) return '启用';
      return '停用';
    },
    showIsMust: function showIsMust(row) {
      if (row.isMust == 1) return '是';
      return '否';
    },
    lengthCheck: function lengthCheck(row) {
      //  debugger
      //   if (row.fieldType == 2 || row.fieldType == 3) {
      //     return '无';
      //   }
    }
  }
};
exports.default = _default;