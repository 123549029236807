var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.textMap[_vm.dialogStatus],
        width: "75% !important",
        visible: _vm.dialogApplyVisible,
        top: "3%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogApplyVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "form-box" } },
        [
          _c(
            "el-steps",
            {
              attrs: {
                "finish-status": "success",
                active: _vm.active,
                "align-center": "",
              },
            },
            [
              _c("el-step", { attrs: { title: "制码申请" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "审核" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "制码生成" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "制码下载" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dataForm",
              staticStyle: { width: "100%", "margin-top": "30px" },
              attrs: {
                model: _vm.temp,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "supplierName",
                                "label-width": "120px",
                                label: "供应商名称:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.temp.supplierName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "markOrderNo",
                                "label-width": "120px",
                                label: "制码订单号:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                attrs: {
                                  disabled:
                                    _vm.dialogStatus != "create" ||
                                    _vm.dataFormdisabled,
                                  value: _vm.temp.markOrderNo,
                                  placeholder: "制码订单号",
                                  maxlength: "20",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.temp.markOrderNo =
                                      _vm.validSe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.showOrderNum
                        ? _c(
                            "el-col",
                            { attrs: { lg: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "postInfo-container-item",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: {
                                    prop: "orderNum",
                                    "label-width": "120px",
                                    label: "订单数量:",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled: _vm.dataFormdisabled,
                                      max: 500,
                                      precision: 2,
                                      min: 0.0,
                                      step: 0.01,
                                      "step-strictly": "",
                                      size: "small",
                                    },
                                    on: { change: _vm.setNum },
                                    model: {
                                      value: _vm.temp.orderNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, "orderNum", $$v)
                                      },
                                      expression: "temp.orderNum",
                                    },
                                  }),
                                  _vm._v("（万套）\n            "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "applicationType",
                                "label-width": "120px",
                                label: " 应用场景:",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.dataFormdisabled },
                                  model: {
                                    value: _vm.temp.applicationType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.temp, "applicationType", $$v)
                                    },
                                    expression: "temp.applicationType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("测试"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("正常生产"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "expireDate",
                                "label-width": "120px",
                                label: "数码过期时间:",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: _vm.dataFormdisabled,
                                  type: "date",
                                  placeholder: "选择日期",
                                  "picker-options": _vm.pickerOptions1,
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.temp.expireDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "expireDate", $$v)
                                  },
                                  expression: "temp.expireDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass:
                                "postInfo-container-item hiddenInputWrap",
                              staticStyle: {
                                "margin-bottom": "20px",
                                height: "40px",
                              },
                              attrs: {
                                prop: "flagGroupId",
                                "label-width": "120px",
                                label: "标识组:",
                              },
                            },
                            [
                              !_vm.dataFormdisabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.showLabelCode },
                                    },
                                    [_vm._v("添加标识组")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-input", {
                                staticStyle: { height: "0" },
                                attrs: { type: "hidden" },
                                model: {
                                  value: _vm.temp.flagGroupId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "flagGroupId", $$v)
                                  },
                                  expression: "temp.flagGroupId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.temp.flagGroupCode != ""
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            "margin-left": "120px",
                          },
                          attrs: { id: "table-box" },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.codelistLoading,
                                  expression: "codelistLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.flagelist,
                                "max-height": "350",
                                "tooltip-effect": "dark",
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "120",
                                  align: _vm.tableConfig.align,
                                  label: "序号",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "码类型",
                                  align: _vm.tableConfig.align,
                                  width: "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("codeType")(
                                                  scope.row.markType
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3427812999
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装层级",
                                  align: _vm.tableConfig.align,
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("levelType")(
                                                  scope.row.packLevel,
                                                  scope.row.markType
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3914337215
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "URL",
                                  align: _vm.tableConfig.align,
                                  prop: "urlAddress",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "图片",
                                  align: _vm.tableConfig.align,
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "80px",
                                              height: "80px",
                                            },
                                            attrs: {
                                              src: scope.row.imgAddress
                                                ? scope.row.imgAddress
                                                : _vm.imgUrl,
                                              "preview-src-list": [
                                                scope.row.imgAddress
                                                  ? scope.row.imgAddress
                                                  : _vm.imgUrl,
                                              ],
                                              fit: "contain",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1805687109
                                ),
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "实际数量",
                                    fixed: "right",
                                    align: _vm.tableConfig.align,
                                    width: "100",
                                  },
                                },
                                [
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.temp.orderNum)),
                                    ]),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total>0",
                              },
                            ],
                            attrs: {
                              total: _vm.total,
                              page: _vm.listQuery.current,
                              limit: _vm.listQuery.size,
                              "auto-scroll": false,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.listQuery,
                                  "current",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(_vm.listQuery, "size", $event)
                              },
                              pagination: _vm.getModelList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.active > 1
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass:
                                    "postInfo-container-item hiddenInputWrap",
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    height: "40px",
                                  },
                                  attrs: {
                                    "label-width": "120px",
                                    label: "生成状态:",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.generateColor[
                                          _vm.temp.generateStatus
                                        ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("generateStatus")(
                                            _vm.temp.generateStatus
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass:
                                    "postInfo-container-item hiddenInputWrap",
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    height: "40px",
                                  },
                                  attrs: {
                                    "label-width": "120px",
                                    label: "生成时间:",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.temp.generateTime
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.active > 2
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass:
                                    "postInfo-container-item hiddenInputWrap",
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    height: "40px",
                                  },
                                  attrs: {
                                    "label-width": "130px",
                                    label: "最后一次下载时间:",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.temp.downloadTime
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("audit_list", { attrs: { "log-list": _vm.logList } }),
          _vm._v(" "),
          !_vm.dataFormdisabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { padding: "7px 12px" },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        loading: _vm.btn_loading,
                      },
                      on: {
                        click: function ($event) {
                          _vm.temp.PId == ""
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("提交审核")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.CloseMain } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataFormdisabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.CloseMain } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "80%",
            title: "标签组",
            visible: _vm.groupInnerVisible,
            "before-close": _vm.labelClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupInnerVisible = $event
            },
          },
        },
        [
          _c("label_tree", {
            ref: "labelGroupMain",
            attrs: { "supplier-name": _vm.temp.supplierName },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getSelectCode({})
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }