"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _protocol = require("@/api/generalSettings2/protocol");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _config = _interopRequireDefault(require("./components/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    TinyMce: _tinyMce.default,
    Pagination: _Pagination.default,
    config: _config.default
  },
  filters: {
    type: function type(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '登录协议',
        2: '开箱奖协议',
        3: 'BC联动协议'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      queryParams: {
        agreementName: '',
        type: '',
        creator: '',
        updater: '',
        status: '',
        updateBeginDate: '',
        updateEndDate: '',
        pageNo: 1,
        pageSize: 10,
        rangeTime: ''
      },
      total: 0,
      listLoading: false,
      tabHeight: 'calc(100vh - 350px)',
      wechatList: [],
      title: '',
      dialogVisible: false,
      disabled: false,
      btnLoading: false,
      downLoading: false,
      detailVisible: false,
      mloading: false,
      temp: {},
      List: [],
      temp2: {},
      version: '',
      content: ''
    };
  },
  activated: function activated() {
    var hasCache2 = this.wechatList.length > 0;
    if (!hasCache2) {
      this.getwechatQueryList();
    }
  },
  deactivated: function deactivated() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 获取列表
    getwechatQueryList: function getwechatQueryList() {
      var _this = this;
      this.listLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.updateBeginDate = this.queryParams.rangeTime[0];
        this.queryParams.updateEndDate = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.updateBeginDate = '';
        this.queryParams.updateEndDate = '';
      }
      var data = JSON.parse(JSON.stringify(this.queryParams));
      delete data.rangeTime;
      (0, _protocol.postPage)(data).then(function (res) {
        console.log(res);
        if (res.data.data.errCode) {
          _this.listLoading = false;
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data.data !== null) {
            _this.wechatList = res.data.data.data.records;
            _this.total = res.data.data.data.total;
            _this.listLoading = false;
          } else {
            _this.wechatList = [];
            // this.total = res.data.data.total
            _this.listLoading = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    restCapitalList: function restCapitalList() {
      this.queryParams = {};
      this.getwechatQueryList();
    },
    // 新增
    handleaddWechatAccount: function handleaddWechatAccount() {
      this.$refs.configMain.upDateShow('add', '');
    },
    // 编辑
    protocolEdit: function protocolEdit(row) {
      console.log(row);
      this.$refs.configMain.upDateShow('edit', row);
    },
    // 查看
    look: function look(row) {
      this.detailVisible = true;
      // this.temp = row
      this.postAgreement(row.agreementId);
    },
    versionChange: function versionChange(val) {
      var _this2 = this;
      console.log(val);
      this.List.forEach(function (item) {
        if (item.version == val) {
          _this2.temp2 = item;
        }
      });
      this.content = this.temp2.content;
      console.log(this.temp2);
    },
    versionClear: function versionClear() {
      this.version = this.temp.version;
      this.content = this.temp.content;
      this.temp2 = {};
    },
    handleClose: function handleClose() {
      this.temp = {};
      this.detailVisible = false;
    },
    postAgreement: function postAgreement(id) {
      var _this3 = this;
      (0, _protocol.postAgreement)(id).then(function (res) {
        console.log(res.data);
        _this3.temp = res.data.data.data;
        _this3.List = res.data.data.data.historyList;
        _this3.content = res.data.data.data.content;
        _this3.version = res.data.data.data.version;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 启用/停用
    handleDelItem: function handleDelItem(row) {
      var _this4 = this;
      var txt = '';
      if (row.status == '0') {
        txt = '启用';
      } else if (row.status == '1') {
        txt = '停用';
      }
      this.$confirm('是否' + txt + '该数据项?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        if (row.status == '1') {
          (0, _protocol.postDisable)(row.agreementId).then(function (res) {
            console.log(res.data);
            if (res.data.data.code == '1') {
              _this4.$message.error(res.data.data.msg);
              return;
            } else if (res.data.data.code == '200') {
              _this4.$message.success(res.data.data.msg);
              _this4.getwechatQueryList();
            }
          }).catch(function (err) {
            console.log(err);
          });
        } else if (row.status == '0') {
          (0, _protocol.postEnable)(row.agreementId).then(function (res) {
            console.log(res.data);
            if (res.data.data.code == '1') {
              _this4.$message.error(res.data.data.msg);
            } else if (res.data.data.code == '200') {
              _this4.$message.success(res.data.data.msg);
              _this4.getwechatQueryList();
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    exportExcel: function exportExcel() {
      var _this5 = this;
      this.downLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.updateBeginDate = this.queryParams.rangeTime[0];
        this.queryParams.updateEndDate = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.updateBeginDate = '';
        this.queryParams.updateEndDate = '';
      }
      var data = JSON.parse(JSON.stringify(this.queryParams));
      delete data.rangeTime;
      (0, _protocol.posExport)(data).then(function (res) {
        _this5.downLoading = false;
        if (res.status !== 200) {
          _this5.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this5.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '终端协议.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this5.downLoading = false;
      });
    }
  }
};
exports.default = _default;