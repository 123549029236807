"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.function.name");
var _prizeMange = require("@/api/market/prizeMange");
var _templateManage = require("@/api/market/templateManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageawardManageprizeManageindex';
var _default = {
  name: 'MarketingManageawardManageprizeManageindex',
  components: {
    Pagination: _Pagination.default,
    Tipdialog: _TipDialog.default,
    UploadImg: _UploadImg.default
  },
  filters: {
    redpacktype: function redpacktype(val) {
      var obj = {
        // '0': '新建',
        1: '普通固定红包',
        2: '裂变红包',
        3: '随机红包',
        4: '转账'
      };
      return obj[val] || '';
    },
    stateName: function stateName(index) {
      var comtype = {
        // '0': '新建',
        1: '启用',
        2: '禁用'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    var _this = this;
    var checkAlias = function checkAlias(rule, value, callback) {
      if (!value) {
        return callback(new Error('奖项名称不能为空'));
      }
      (0, _prizeMange.checkCompareName)({
        name: value
      }).then(function (res) {
        res = res.data;
        if (res.state === '200') {
          return callback();
        } else {
          if (_this.aliasBackup = value) {
            return callback();
          }
          return callback(new Error(res.msg));
        }
      });
    };
    var maxValue = function maxValue(rule, value, callback) {
      if (_this.hongbaoForm.redpacktype == '1') {
        if (!_this.hongbaoForm.minValue) {
          // return callback(new Error("红包最小值不能为空"));
          return callback(new Error('红包金额不能为空'));
          // }
          // else if (!this.hongbaoForm.maxValue) {
          //   return callback(new Error("红包最大值不能为空"));
          // }
          // else if (
          //   Number.parseFloat(this.hongbaoForm.minValue) >
          //   Number.parseFloat(this.hongbaoForm.maxValue)
          // ) {
          //   return callback(new Error("红包最大值不能小于最小值"));
        } else {
          return callback();
        }
      } else if (_this.hongbaoForm.redpacktype == '4') {
        if (!_this.hongbaoForm.minValue) {
          return callback(new Error('红包值不能为空'));
        } else {
          return callback();
        }
      }
    };
    return {
      handleShow: false,
      tabHeight: '100%',
      list: null,
      listLoading: true,
      downloadLoading: false,
      dialogName: 'index',
      total: 0,
      disabled: false,
      hideUpload: false,
      dialogTitle: '',
      selectId: '',
      limitCount: 1,
      tipContent: '',
      fileList: [],
      imgBackup: '',
      aliasBackup: '',
      formInline: {
        pageindex: 1,
        pagesize: 20,
        startTime: '',
        endTime: '',
        name: '',
        type: '',
        state: '',
        title: ''
      },
      info: [
        // { baseinfoid: "1000000000000001", isSelect: "0", name: "姓名" },
        // { baseinfoid: "1000000000000002", isSelect: "0", name: "手机" },
        // { baseinfoid: "1000000000000003", isSelect: "0", name: "地址" }
      ],
      prisList: [{
        title: '红包',
        img: require('@/assets/images/jphongbao.png')
      }
      // { title: "实物", img: require("@/assets/images/jpshiwu.png") },
      // { title: "第三方资源", img: require("@/assets/images/jpdisanfang.png") }
      ],

      dialogVisible: false,
      hongbaoForm: {
        // id: null,
        alias: '',
        blessing: '',
        getuserinfo: '1',
        imgUrl: '',
        minValue: '',
        maxValue: '',
        name: '',
        redcount: '',
        redpacktype: '1',
        sender: '',
        collectionInfo: [],
        checkList: [],
        remark: '华益营销系统'
      },
      shiwuForm: {
        // id: null,
        alias: '',
        blessing: '',
        getuserinfo: '1',
        imgUrl: '',
        minValue: '',
        maxValue: '',
        name: '',
        redcount: '',
        redpacktype: '',
        sender: '',
        collectionInfo: [],
        costPrize: ''
      },
      thirdForm: {
        // id: null,
        alias: '',
        blessing: '',
        getuserinfo: '1',
        imgUrl: '',
        minValue: '',
        maxValue: '',
        name: '',
        redcount: '',
        redpacktype: '',
        sender: '',
        collectionInfo: [],
        costPrize: '',
        thirdUrl: ''
      },
      hongbaoRules: {
        alias: [{
          required: true,
          message: '奖项名称不能为空',
          trigger: 'change'
        }, {
          validator: checkAlias,
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '红包名称不能为空',
          trigger: 'change'
        }],
        sender: [{
          required: true,
          message: '发送者不能为空',
          trigger: 'change'
        }],
        blessing: [{
          required: true,
          message: '红包祝福语不能为空',
          trigger: 'change'
        }],
        redpacktype: [{
          required: true,
          message: '请选择红包类型',
          trigger: 'change'
        }],
        // checkList: [
        //   { required: true, message: "请选择信息", trigger: "change" }
        // ],
        value: [{
          required: true,
          validator: maxValue,
          trigger: 'blur'
        }],
        imgUrl: [{
          required: true,
          message: '请上传奖品图片',
          trigger: 'change'
        }]
      },
      shiwuRules: {
        alias: [{
          required: true,
          message: '奖项名称不能为空',
          trigger: 'change'
        }, {
          validator: checkAlias,
          trigger: 'blur'
        }],
        collectionInfo: [{
          required: true,
          message: '请选择信息',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '展示名称不能为空',
          trigger: 'change'
        }],
        imgUrl: [{
          required: true,
          message: '请上传奖品图片',
          trigger: 'change'
        }]
      },
      thirdRules: {
        alias: [{
          required: true,
          message: '奖项名称不能为空',
          trigger: 'change'
        }, {
          validator: checkAlias,
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '展示名称不能为空',
          trigger: 'change'
        }],
        collectionInfo: [{
          required: true,
          message: '请选择信息',
          trigger: 'change'
        }],
        thirdUrl: [{
          required: true,
          message: '奖品链接不能为空',
          trigger: 'change'
        }],
        imgUrl: [{
          required: true,
          message: '请上传奖品图片',
          trigger: 'change'
        }]
      },
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    console.log(authList);
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
        that.getInfo();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    jumpLink: function jumpLink(row) {
      this.authId('jumpbtn', this.apis);
      this.$router.push({
        path: '/marketingManage/awardManage/winningRecord/index',
        query: {
          name: row.name,
          id: row.id
        }
      });
    },
    routerLinkFun: function routerLinkFun(type) {
      this.authId('logbtn', this.apis);
      this.$router.push({
        path: '/system/logmange/operatelog/index'
      });
    },
    download: function download() {
      var _this2 = this;
      this.authId('importbtn', this.apis);
      this.downloadLoading = true;
      var submitData = (0, _utils.filterKeyNull)(this.formInline);
      submitData.size = 2147483647;
      (0, _prizeMange.prizeExcel)(submitData).then(function (res) {
        _this2.downloadLoading = false;
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '奖品管理.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this2.downloadLoading = false;
      });
    },
    getInfo: function getInfo() {
      var _this3 = this;
      this.authId('searchbtn', this.apis);
      (0, _templateManage.getInfoList)().then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this3.info = res.data;
          // res.data.forEach((val, key) => {
          //   if (
          //     val.name === "姓名" ||
          //     val.name === "手机号" ||
          //     val.name === "地址"
          //   ) {
          //     // console.log(key);
          //     this.hongbaoForm.checkList.push(key);
          //   }
          // });
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    fixedOnInputChange: function fixedOnInputChange(val, len) {
      while (this.fixedStrLength(val) > len) {
        val = val.substr(0, val.length - 1);
      }
      return val;
    },
    fixedStrLength: function fixedStrLength(value) {
      var cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
      var mat = value.match(cnReg);
      if (mat) {
        return mat.length * 3 + (value.length - mat.length);
      }
      return value.length;
    },
    fetchData: function fetchData() {
      var _this4 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      var obj = {
        loginId: 0
      };
      Object.assign(this.formInline, obj);
      (0, _prizeMange.getPList)(this.formInline).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.state === '200') {
          _this4.list = res.data.list;
          _this4.total = res.data.total;
        } else {
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this4.listLoading = false;
      });
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    getDetail: function getDetail(val, type, id) {
      var _this5 = this;
      this.getInfo();
      if (id) {
        if (val === 1) {
          this.authId('scanbtn', this.apis);
          this.disabled = true;
          this.dialogTitle = '奖品详情';
        } else {
          this.authId('updatebtn', this.apis);
          this.disabled = false;
          this.dialogTitle = '奖品编辑';
        }
        (0, _prizeMange.prizeDetail)({
          pid: id
        }).then(function (res) {
          res = res.data;
          if (res.state === '200') {
            if (type == '1101') {
              _this5.dialogName = '红包';
              _this5.dialogVisible = true;
              // this.hongbaoForm = res.data;
              _this5.hongbaoForm.alias = res.data.alias;
              _this5.hongbaoForm.blessing = res.data.blessing;
              _this5.hongbaoForm.costPrize = res.data.costPrize;
              _this5.hongbaoForm.createTime = res.data.createTime;
              _this5.hongbaoForm.creator = res.data.creator;
              _this5.hongbaoForm.creatorId = res.data.creatorId;
              _this5.hongbaoForm.id = res.data.id;
              _this5.hongbaoForm.imgUrl = res.data.imgUrl;
              _this5.hongbaoForm.isdelete = res.data.isdelete;
              _this5.hongbaoForm.minValue = res.data.minValue;
              _this5.hongbaoForm.name = res.data.name;
              _this5.hongbaoForm.qyorgId = res.data.qyorgId;
              _this5.hongbaoForm.redpacktype = res.data.redpacktype;
              _this5.hongbaoForm.sender = res.data.sender;
              _this5.hongbaoForm.shippingOption = res.data.shippingOption;
              _this5.hongbaoForm.shippingOption = res.data.shippingOption;
              _this5.hongbaoForm.type = res.data.type;
              _this5.hongbaoForm.updateTime = res.data.updateTime;
              _this5.hongbaoForm.updator = res.data.updator;
              _this5.hongbaoForm.updateTime = res.data.updateTime;
              _this5.hongbaoForm.updatorId = res.data.updatorId;
              _this5.hongbaoForm.remark = res.data.remark;
              _this5.hongbaoForm.getuserinfo = res.data.getuserinfo.toString();
              _this5.$nextTick(function () {
                _this5.$refs.hongbaoUpload.fileList = [{
                  url: res.data.imgUrl
                }];
                _this5.$refs.hongbaoUpload.hideUpload = true;
              });
              _this5.hongbaoForm.checkList = [];
              var checkList = [];
              res.data.collectInfo.forEach(function (val, key) {
                if (val.name === '姓名' || val.name === '手机号' || val.name === '地址' || val.name === '年龄' || val.name === '性别' || val.name === '生日' || val.name === '血型' || val.name === '公司' || val.name === '爱好' || val.name === '邮箱' || val.name === '婚姻状态') {
                  // console.log(111, "222");
                  var baseinfoid = val.baseinfoid.toString();
                  checkList.push(baseinfoid.substr(14, 2) * 1);
                  _this5.hongbaoForm.checkList = checkList;
                  // console.log(checkList);
                }
              });
            }
            // switch (type) {
            //   case "1101":
            //     this.dialogName = "红包";
            //     this.hongbaoForm = res.data;
            //     this.dialogVisible = true;
            //     this.$nextTick(() => {
            //       this.$refs.hongbaoUpload.fileList = [
            //         { url: res.data.imgUrl }
            //       ];
            //       this.$refs.hongbaoUpload.hideUpload = true;
            //     });

            //     break;
            //   case "2000":
            //     this.dialogName = "实物";
            //     this.shiwuForm = res.data;
            //     this.dialogVisible = true;
            //     this.$nextTick(() => {
            //       this.$refs.shiwuUpload.fileList = [{ url: res.data.imgUrl }];
            //       this.$refs.shiwuUpload.hideUpload = true;
            //     });
            //     break;
            //   case "4000":
            //     this.dialogName = "第三方资源";
            //     this.thirdForm = res.data;
            //     this.dialogVisible = true;
            //     this.$nextTick(() => {
            //       this.$refs.thirdUpload.fileList = [{ url: res.data.imgUrl }];
            //       this.$refs.thirdUpload.hideUpload = true;
            //     });

            //     break;
            // }
            _this5.aliasBackup = res.data.alias;
            _this5.imgBackup = res.data.imgUrl;
          } else {
            _this5.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      } else {
        this.dialogName = 'index';
        this.dialogTitle = '新增红包';
        this.disabled = false;
        this.dialogVisible = true;
      }
    },
    checkType: function checkType() {
      if (this.disabled) {
        return false;
      }
    },
    // redPacketsTypeFun(val) {
    //   this.hongbaoForm.redPacketsType = val;
    //   if (val === "1") {
    //     this.hongbaoForm.maxValue = "";
    //   } else if (val === "0") {
    //     this.hongbaoForm.maxValue = "";
    //   }
    // },
    subHongbao: function subHongbao() {
      var _this6 = this;
      if (this.hongbaoForm.imgUrl === '') {
        this.hongbaoForm.imgUrl = this.imgBackup;
      }
      var collectionInfo = [];
      // var checkList = this.hongbaoForm.checkList
      var getuserinfo = this.hongbaoForm.getuserinfo;
      this.hongbaoForm.checkList = Array.from(new Set(this.hongbaoForm.checkList));
      if (getuserinfo == '1') {
        this.hongbaoForm.checkList.forEach(function (v, k) {
          _this6.info[v].isSelect = '1';
          collectionInfo.push(_this6.info[v]);
          // console.log(v, k);
          // checkList.push(this.hongbaoForm.checkList);
        });
      } else if (getuserinfo == '2') {
        collectionInfo = [];
        // checkList = [];
      }

      this.hongbaoForm.collectionInfo = collectionInfo;
      // console.log(collectionInfo);
      // console.log(this.hongbaoForm.collectionInfo);
      // return false;
      this.$refs['hongbaoForm'].validate(function (valid) {
        if (valid) {
          if (_this6.hongbaoForm.getuserinfo == '1') {
            if (_this6.hongbaoForm.checkList.length == 0) {
              _this6.$message({
                message: '请选择至少一项显示内容',
                type: 'error',
                duration: 3 * 1000
              });
              return false;
            }
          }
          (0, _prizeMange.updateHongbao)(_this6.hongbaoForm).then(function (res) {
            res = res.data;
            if (res.state === '200') {
              // var arr = [];
              // res.data.hongbaoForm.forEach((val, key) => {
              //   if (val.isSelect === "1") {
              //     arr.push(key);
              //   }
              // });
              // Object.assign(this.infoForm, v);
              // this.hongbaoForm.checkList.push(...arr);
              // this.hongbaoForm.checkList = Array.from(
              //   new Set(this.hongbaoForm.checkList)
              // );
              _this6.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this6.cancel();
              _this6.fetchData();
            } else {
              _this6.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    subShiwu: function subShiwu() {
      var _this7 = this;
      if (this.shiwuForm.imgUrl === '') {
        this.shiwuForm.imgUrl = this.imgBackup;
      }
      this.$refs['shiwuForm'].validate(function (valid) {
        if (valid) {
          (0, _prizeMange.updateShiwu)(_this7.shiwuForm).then(function (res) {
            res = res.data;
            if (res.state === '200') {
              _this7.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this7.cancel();
              _this7.fetchData();
            } else {
              _this7.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    subthird: function subthird() {
      var _this8 = this;
      if (this.thirdForm.imgUrl === '') {
        this.thirdForm.imgUrl = this.imgBackup;
      }
      this.$refs['thirdForm'].validate(function (valid) {
        if (valid) {
          (0, _prizeMange.updateThird)(_this8.thirdForm).then(function (res) {
            res = res.data;
            if (res.state === '200') {
              _this8.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this8.cancel();
              _this8.fetchData();
            } else {
              _this8.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    cancel: function cancel() {
      this.hongbaoForm = {
        alias: '',
        blessing: '',
        getuserinfo: '1',
        imgUrl: '',
        minValue: '',
        maxValue: '',
        name: '',
        redcount: '',
        redpacktype: '1',
        sender: '',
        collectionInfo: [],
        checkList: [],
        remark: '华益营销系统'
      };
      this.shiwuForm = {
        // id: null,
        alias: '',
        name: '',
        costPrize: '',
        imgUrl: ''
      };
      this.thirdForm = {
        // id: null,
        alias: '',
        name: '',
        costPrize: '',
        thirdUrl: '',
        imgUrl: ''
      };
      this.restForm('hongbaoForm');
      this.restForm('shiwuForm');
      this.restForm('thirdForm');
      this.$refs.hongbaoUpload.fileList = [];
      this.$refs.hongbaoUpload.hideUpload = false;
      this.$refs.shiwuUpload.fileList = [];
      this.$refs.shiwuUpload.hideUpload = false;
      this.$refs.thirdUpload.fileList = [];
      this.$refs.thirdUpload.hideUpload = false;
      // this.hideUpload = false
      this.dialogVisible = false;
      // this.$nextTick(() => {
      //   // this.dialogName = 'index'
      // })
      this.fileList = [];
      this.imgBackup = '';
      this.aliasBackup = '';
    },
    del: function del(val) {
      var _this9 = this;
      this.selectId = val;
      this.$confirm('<div style="line-height:21px;"><i class="el-icon-question" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>请确认是否删除。</div>', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(function () {
        _this9.deleteItem();
      }).catch(function () {});
    },
    deleteItem: function deleteItem() {
      var _this10 = this;
      this.authId('deletebtn', this.apis);
      (0, _prizeMange.deletePrize)({
        pid: this.selectId
      }).then(function (res) {
        res = res.data;
        if (res.state * 1 === 200) {
          _this10.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this10.fetchData();
        } else {
          _this10.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    enableItem: function enableItem(val) {
      var _this11 = this;
      this.authId('startbtn', this.apis);
      (0, _prizeMange.enablePrize)({
        pid: val
      }).then(function (res) {
        res = res.data;
        if (res.state === '200') {
          _this11.hongbaoForm.imgUrl = res.data;
          _this11.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this11.fetchData();
        } else {
          _this11.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    disableItem: function disableItem(val) {
      var _this12 = this;
      this.authId('stopbtn', this.apis);
      (0, _prizeMange.disablePrize)({
        pid: val
      }).then(function (res) {
        res = res.data;
        if (res.state === '200') {
          _this12.hongbaoForm.imgUrl = res.data;
          _this12.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this12.fetchData();
        } else if (res.code === 1) {
          _this12.tipContent = res.msg;
          _this12.$refs.TipVisible.tipAlert();
        } else {
          _this12.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    }
  }
};
exports.default = _default;