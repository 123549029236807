"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _checkout = require("@/api/warehouse/checkout");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  status: [],
  source: [],
  factory: [],
  djzt: [],
  djly: [],
  djlx: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    // 操作类型
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '创建单据',
          2: '修改单据',
          3: '删除单据',
          4: '拆单',
          5: '审核不通过',
          6: '审核通过',
          7: '单据下载',
          8: '单据扫描',
          9: '正常结单',
          10: '强制结单',
          11: '撤销下载',
          12: '撤销结单',
          14: '已签收'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'primary',
          5: 'primary',
          6: 'primary',
          7: 'success',
          8: 'warning',
          9: 'danger',
          10: 'info',
          11: 'info',
          13: 'success'
        };
        return statusMap[value];
      }
    },
    scanStatus: function scanStatus(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '待扫描',
          2: '部分扫描',
          3: '扫描完成'
        };
        return statusMap[value];
      }
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.figure == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    // 订单用途
    billApplication: function billApplication(val) {
      // 1:测试1 2：测试2 3：测试3
      if (val == 1) {
        return '测试1';
      } else if (val == 2) {
        return '测试2';
      } else if (val == 3) {
        return '测试3';
      }
    },
    // 出库方式
    billOutType: function billOutType(val) {
      // 1:扫码出库 2：生产任务单处理 3：生产批次号出库"
      if (val == 1) {
        return '扫码出库';
      } else if (val == 2) {
        return '生产任务单处理';
      } else if (val == 3) {
        return '生产批次号出库';
      }
    },
    // 单据来源
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    // 单据类型
    djlxText: function djlxText(val) {
      var name = '';
      TypeDataComs.djlx.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1];
      } else if (arr.length == 3) {
        name = arr[2];
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0];
      }
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      mloading: false,
      activeName: '',
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      num: '',
      active: 0,
      codetotal: 0,
      detailVisible: false,
      codeVisible: false,
      listLoading: false,
      codeListLoading: false,
      scanData: {},
      detailForm: {
        billNo: '',
        productId: '',
        ncProductId: '',
        current: 1,
        size: 10
      },
      detailForm2: {
        billNo: '',
        pageNo: 1,
        pageSize: 10,
        productId: ''
      },
      codeList: [],
      showpage: true,
      temp: {
        tradeBillAllVO: {},
        tradeReceiveBillVO: {},
        tradeOperateLogDTOS: [],
        tradeProductVOS: [],
        errorMarkCodeList: []
      },
      computeNum: {
        num1: 0,
        num2: 0,
        num3: 0
      },
      flowList: [{
        name: '创建',
        userName: '',
        dateCreate: ''
      }, {
        name: '下载',
        userName: '',
        dateCreate: ''
      }, {
        name: '扫描',
        userName: '',
        dateCreate: ''
      }, {
        name: '结单',
        userName: '',
        dateCreate: ''
      }],
      settype: 2,
      showflow: false,
      setinfo: false,
      // 查看详情显示字段
      djly: [],
      codeExportLoading: false
    };
  },
  computed: {
    formattedDjzt: function formattedDjzt() {
      return function (val) {
        var name = '';
        TypeDataComs.djzt.forEach(function (c) {
          if (c.figure === val) {
            name = c.name;
          }
        });
        return name;
      };
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // 单据类型
    this.getDirSel('XSCKDJLX', 'djlx');
    this.getDirSel('DJZT', 'djzt');
    // 单据来源
    this.getDirSel('DJLY', 'djly');
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      this.settype = settype;
      this.showflow = false;
      if (settype == 3) {
        this.showflow = true;
      }
      this.detailVisible = true;
      this.mloading = true;
      this.num = rows.num;
      this.mloading = false;
      this.getDetail(rows);
    },
    getDetail: function getDetail(row) {
      var _this = this;
      console.log(row, 'row');
      (0, _checkout.getPeoductionDetail)({
        billNo: row.billNo,
        ncProductId: row.ncproductId ? row.ncproductId : '',
        productId: row.productId ? row.productId : ''
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          res.data.data.tradeProductVOS.forEach(function (e) {
            var radio;
            var packscal = 1;
            if (e.productModel.indexOf('*') >= 0) {
              radio = e.productModel.split('*');
            } else {
              var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
              radio = e.productModel.match(regex)[0].match(/\d+/)[0];
            }
            packscal = packscal * Number(radio[radio.length - 1]);
            e.realScanBottleNum = e.realScanAtoNum % packscal;
          });
          _this.temp = res.data.data;
          if (_this.temp.tradeReceiveBillVO && _this.temp.tradeReceiveBillVO.imgUrl) {
            _this.fileUrls = _this.temp.tradeReceiveBillVO.imgUrl.split(',');
          }
          switch (res.data.data.tradeBillAllVO.billStatus) {
            case 101:
              _this.active = 1;
              break;
            case 102:
              _this.active = 1;
              break;
            case 103:
              _this.active = 2;
              break;
            case 104:
              _this.active = 3;
              break;
            case 105:
              _this.active = 4;
              break;
            case 106:
              _this.active = 4;
              break;
            case 108:
              _this.active = 4;
              break;
            default:
              _this.active = 0;
          }
          // 新增的计算总和
          _this.temp.tradeProductVOS.forEach(function (res) {
            _this.computeNum.num1 = res.planScanAtoNum + _this.computeNum.num1;
            _this.computeNum.num2 = res.realScanAtoNum + _this.computeNum.num2;
            _this.computeNum.num3 = res.errorScanNum + res.errorScanAtoNum + _this.computeNum.num3;
          });
        }
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            TypeDataComs[list] = res.data.data;
          } else {
            TypeDataComs[list] = [];
          }
        }
      });
      // storeTypeInfoList(type)
      //   .then(res => {
      //     if (res.data.code != 200) {
      //       that.TypeDataComs[keyName] = []
      //       return
      //     } else {
      //       that.TypeDataComs[keyName] = res.data.data
      //     }
      //   })
      //   .catch(() => {
      //     that.TypeDataComs[keyName] = []
      //   })
    },
    handleClose: function handleClose() {
      this.activeName = '';
      this.computeNum = {
        num1: 0,
        num2: 0,
        num3: 0
      };
      this.authId('searchbtn', this.$route.meta.authList);
      this.showpage = true;
      this.detailVisible = false;
    },
    codeClose: function codeClose() {
      this.detailForm = {
        billNo: '',
        productId: '',
        ncProductId: '',
        current: 1,
        size: 10
      };
      this.detailForm2 = {
        billNo: '',
        pageNo: 1,
        pageSize: 10,
        productId: ''
      };
      this.codeList = [];
      this.codetotal = 0;
      this.showpage = true;
      this.codeVisible = false;
    },
    codeDetail: function codeDetail(row) {
      console.log(row);
      this.codeVisible = true;
      this.codeListLoading = true;
      this.showpage = true;
      this.detailForm.billNo = row.billNo;
      this.detailForm.productId = row.productId;
      this.detailForm.ncProductId = row.ncProductId || '';
      this.getCodeList();
    },
    codeExport: function codeExport(row) {
      var _this3 = this;
      console.log(row, 'row');
      this.codeExportLoading = true;
      (0, _checkout.exportProductMarkCode)({
        billNo: row.billNo,
        ncProductId: row.ncProductId,
        productId: row.productId
      }).then(function (res) {
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "".concat(row.billNo, ".xls");
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.codeExportLoading = false;
      }).catch(function () {
        _this3.codeExportLoading = false;
      });
    },
    getCodeList: function getCodeList() {
      var _this4 = this;
      (0, _checkout.queryScanMark)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this4.codeList = res.data.data.records;
            _this4.codetotal = parseInt(res.data.data.total);
          } else {
            _this4.codeList = [];
            _this4.codetotal = 0;
          }
        }
        _this4.codeListLoading = false;
      }).catch(function () {
        _this4.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this5 = this;
      (0, _checkout.queryChildMark)({
        billNo: this.showpage ? this.detailForm.billNo : this.detailForm2.billNo,
        parentMarkCode: tree.markCode,
        productId: this.showpage ? this.detailForm.productId : this.detailForm2.productId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    anomaly: function anomaly(row) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.showpage = false;
      this.detailForm2.billNo = row.billNo;
      this.detailForm2.productId = row.productId;
      this.geterrorCodeList();
    },
    geterrorCodeList: function geterrorCodeList() {
      var _this6 = this;
      (0, _checkout.errorCodeListark)(this.detailForm2).then(function (res) {
        console.log(res.data.data);
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item, index) {
                item.seqNo = index + 1;
              });
            }
            _this6.codeList = res.data.data.records;
            _this6.codetotal = parseInt(res.data.data.total);
          } else {
            _this6.codeList = [];
            _this6.codetotal = 0;
          }
        }
        _this6.codeListLoading = false;
      }).catch(function () {
        _this6.codeListLoading = false;
      });
    }
  }
};
exports.default = _default;