var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "page-section",
        },
        [
          _c(
            "div",
            { staticStyle: { width: "690px", margin: "10px auto 5px" } },
            [
              _vm.addData.orderType == 2
                ? _c("div", { staticClass: "detailStepWrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 0 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("订单信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detiaoStepSpace",
                      class: { active: _vm.active >= 1 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 1 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("产品信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detiaoStepSpace",
                      class: { active: _vm.active >= 2 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 2 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("酒券信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detiaoStepSpace",
                      class: { active: _vm.active >= 3 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 3 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("成功提示"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "detailStepWrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 0 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("订单信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detiaoStepSpace",
                      class: { active: _vm.active >= 1 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 1 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("产品信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "detiaoStepSpace",
                      class: { active: _vm.active >= 2 },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detailStepItem",
                        class: { active: _vm.active >= 2 },
                      },
                      [
                        _c("div", { staticClass: "detailStepItemTitle" }, [
                          _vm._v("成功提示"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }),
                      ]
                    ),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.active == 0
            ? _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: {
                    width: "700px",
                    margin: "20px auto 0",
                    "min-height": "calc(100vh - 328px)",
                  },
                  attrs: {
                    model: _vm.addData,
                    "label-width": "120px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售订单号:", prop: "orderNo" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.addData.orderNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "orderNo", $$v)
                                },
                                expression: "addData.orderNo",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.getNo },
                                  slot: "append",
                                },
                                [_vm._v("获取")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "销售订单类型:", prop: "orderType" },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right-start",
                                width: "190",
                                trigger: "manual",
                              },
                              model: {
                                value: _vm.tipShow,
                                callback: function ($$v) {
                                  _vm.tipShow = $$v
                                },
                                expression: "tipShow",
                              },
                            },
                            [
                              _c("div", { staticClass: "tipContent" }, [
                                _vm._v(
                                  "销售订单类型，包括买赠订单、酒券订单、团购订单：买赠订单为厂商向经销商以买赠方案为销售策略的销售订单类型；酒券订单为厂商向经销商以买赠方案为销售策略的销售订单类型；团购订单为厂商向团购客户销售的订单类型。"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    slot: "reference",
                                    placeholder: "请选择",
                                  },
                                  on: {
                                    change: _vm.pTypeChange,
                                    "visible-change": _vm.showTip,
                                  },
                                  slot: "reference",
                                  model: {
                                    value: _vm.addData.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addData, "orderType", $$v)
                                    },
                                    expression: "addData.orderType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "买赠订单", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "酒券订单", value: 2 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "团购订单", value: 3 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单日期:", prop: "tradeDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择日期",
                            },
                            model: {
                              value: _vm.addData.tradeDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "tradeDate", $$v)
                              },
                              expression: "addData.tradeDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售单位:", prop: "saleOrgId" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.saleOrgName,
                                callback: function ($$v) {
                                  _vm.saleOrgName = $$v
                                },
                                expression: "saleOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany("saleOrgName", 3, 12)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货单位:", prop: "sendOrgId" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.sendOrgName,
                                callback: function ($$v) {
                                  _vm.sendOrgName = $$v
                                },
                                expression: "sendOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany("sendOrgName", 2, 12)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "采购单位:", prop: "purchaseOrgId" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: true },
                              model: {
                                value: _vm.purchaseOrgName,
                                callback: function ($$v) {
                                  _vm.purchaseOrgName = $$v
                                },
                                expression: "purchaseOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany(
                                      "purchaseOrgName",
                                      1,
                                      12
                                    )
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货单位:", prop: "receiveOrgId" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.receiveOrgName,
                              callback: function ($$v) {
                                _vm.receiveOrgName = $$v
                              },
                              expression: "receiveOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { prop: "receiveUser" } }, [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(" "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "receiveUser",
                            style: {
                              borderColor: _vm.receiveOrgName
                                ? "#c0c4cc"
                                : "#e4e7ed",
                            },
                          },
                          [
                            _c("div", { staticClass: "receiveAddress" }, [
                              _vm._v(_vm._s(_vm.addData.receiveAddress)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rUser" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.addData.receiveUser
                                    ? _vm.addData.receiveUser + "："
                                    : ""
                                ) + _vm._s(_vm.addData.receivePhone)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { label: "备注:", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "100",
                              "show-word-limit": "",
                              placeholder: "请输入",
                              autosize: { minRows: 2, maxRows: 2 },
                            },
                            model: {
                              value: _vm.addData.remark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addData,
                                  "remark",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.active == 1
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.openPro(1)
                            },
                          },
                        },
                        [_vm._v("添加产品")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      ref: "addTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: "calc(100vh - 385px)",
                        data: _vm.productList,
                        fit: "",
                        "row-class-name": "iconNone",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "80",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: "center",
                          prop: "productCode",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: "center",
                          prop: "productName",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "productModel",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: "center",
                          prop: "packScaleExpression",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: 99999999,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.chestNum,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "chestNum", $$v)
                                      },
                                      expression: "scope.row.chestNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3189359088
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: _vm.mixNumP(scope.row),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.bottleNum,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "bottleNum", $$v)
                                      },
                                      expression: "scope.row.bottleNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2160667976
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          prop: "totalBottleNum",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.addData.orderType == 1 && _vm.addData.saleOrgType != 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "买赠方案",
                              align: "center",
                              prop: "rebatePolicyId",
                              "min-width": "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      (scope.row.giftProductAOS &&
                                        scope.row.giftProductAOS.length > 0) ||
                                      scope.row.activityName
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toogleExpand(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.activityName
                                                    ? scope.row.activityName
                                                    : "查看赠品"
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openMz(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择方案")]
                                          ),
                                      _vm._v(" "),
                                      !(
                                        scope.row.activityId == "" ||
                                        scope.row.activityId == null
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clearZp(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消方案")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.activityId == "" ||
                                      scope.row.activityId == null
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openPro(
                                                    3,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("添加赠品")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2543456451
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "80",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoSc(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3740979374
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { type: "expand", width: "1" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "1px",
                                      },
                                      attrs: {
                                        size: "mini",
                                        data: props.row.giftProductAOS,
                                        fit: "",
                                        "highlight-current-row": "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "赠品行",
                                          type: "index",
                                          align: "center",
                                          width: "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "zpindex" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            "赠品" +
                                                              (scope.$index *
                                                                1 +
                                                                1)
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _c("img", {
                                                        staticClass: "zpimg",
                                                        attrs: {
                                                          src: require("@/images/zpimg.png"),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "赠品编码",
                                          prop: "productCode",
                                          "min-width": "140",
                                          align: "center",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "赠品名称",
                                          prop: "productName",
                                          "min-width": "140",
                                          align: "center",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "规格",
                                          align: "center",
                                          prop: "productModel",
                                          "min-width": "140",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "包装比例",
                                          align: "center",
                                          prop: "packScaleExpression",
                                          "min-width": "140",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "箱数",
                                          align: "center",
                                          "min-width": "140",
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  !(
                                                    props.row.activityId ==
                                                      "" ||
                                                    props.row.activityId ==
                                                      null ||
                                                    props.row.activityId ==
                                                      undefined
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(row.chestNum)
                                                        ),
                                                      ])
                                                    : _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "110px",
                                                        },
                                                        attrs: {
                                                          controls: false,
                                                          precision: 0,
                                                          min: 0,
                                                          max: 99999999,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeChestNum(
                                                              row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: row.chestNum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "chestNum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.chestNum",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "盒数",
                                          align: "center",
                                          "min-width": "140",
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  !(
                                                    props.row.activityId ==
                                                      "" ||
                                                    props.row.activityId ==
                                                      null ||
                                                    props.row.activityId ==
                                                      undefined
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(row.bottleNum)
                                                        ),
                                                      ])
                                                    : _c("el-input-number", {
                                                        staticStyle: {
                                                          width: "110px",
                                                        },
                                                        attrs: {
                                                          controls: false,
                                                          precision: 0,
                                                          min: 0,
                                                          max: _vm.mixNumP(row),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeChestNum(
                                                              row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: row.bottleNum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "bottleNum",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.bottleNum",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "总盒数",
                                          prop: "totalBottleNum",
                                          align: "center",
                                          "min-width": "140",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            align: "center",
                                            prop: "rebatePolicyId",
                                            "min-width": "160",
                                            "show-overflow-tooltip": "",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "label" }, [
                                            _vm._v(" "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      props.row.activityId == "" ||
                                      props.row.activityId == null ||
                                      props.row.activityId == undefined
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              align: "center",
                                              width: "80",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DelZP(
                                                                row,
                                                                props.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                align: "center",
                                                prop: "rebatePolicyId",
                                                width: "80",
                                                "show-overflow-tooltip": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [_vm._v(" ")]
                                              ),
                                            ],
                                            2
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3567062141
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active == 2 && _vm.addData.orderType == 2,
                  expression: "active==2&&addData.orderType==2",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-refresh" },
                      on: {
                        click: function ($event) {
                          return _vm.openPro(2)
                        },
                      },
                    },
                    [_vm._v("兑换产品")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c("div", { staticStyle: { "min-width": "250px" } }, [
                      _vm._v("可使用酒券金额(元)：" + _vm._s(_vm.totalZp)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("本次使用酒券金额(元)：" + _vm._s(_vm.totalInUse)),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "calc(100vh - 385px)",
                    data: _vm.zpList,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      align: "center",
                      prop: "productCode",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      align: "center",
                      prop: "productModel",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      align: "center",
                      prop: "packScaleExpression",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "箱数",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                controls: false,
                                precision: 0,
                                min: 0,
                                max: 99999999,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeChestNum(row)
                                },
                              },
                              model: {
                                value: row.chestNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "chestNum", $$v)
                                },
                                expression: "row.chestNum",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盒数",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                controls: false,
                                precision: 0,
                                min: 0,
                                max: _vm.mixNumP(row),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeChestNum(row)
                                },
                              },
                              model: {
                                value: row.bottleNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "bottleNum", $$v)
                                },
                                expression: "row.bottleNum",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总盒数",
                      prop: "totalBottleNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "酒券金额",
                      align: "center",
                      prop: "packScaleDesc",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(_vm.totalWine(row)))]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      prop: "packScaleDesc",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoScZp(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.active == _vm.activeTotal
            ? _c("div", { staticClass: "successWrap" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "orderWrap" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "orderItem", attrs: { gutter: 40 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("订单编号:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "topContent" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.addData.orderNo) +
                                    "\n                "
                                ),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: _vm.addData.orderNo,
                                        expression: "addData.orderNo",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    staticClass: "link",
                                    attrs: { type: "primary" },
                                  },
                                  [_vm._v("复制单号")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("订单类型:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComOwnerType")(
                                    _vm.addData.orderType
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("销售单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.saleOrgName)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "orderItem", attrs: { gutter: 40 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("发货单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.sendOrgName)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("采购单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.purchaseOrgName)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("收货单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.receiveOrgName)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.active != _vm.activeTotal
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active >= 1 && _vm.active != _vm.activeTotal
                ? _c(
                    "el-button",
                    { attrs: { type: "default" }, on: { click: _vm.pro } },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active < _vm.activeTotal - 1
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.next } },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active == _vm.activeTotal - 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("2")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active == _vm.activeTotal
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("返回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": _vm.proType == 3 ? "" : "1",
              "pro-sel-visible": _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tgShow
        ? _c("tgSelectDialog", {
            attrs: { visible: _vm.tgShow, type: "0" },
            on: { change: _vm.setTg, close: _vm.tgClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mzShow
        ? _c("mz-dialog", {
            ref: "selMz",
            attrs: {
              visible: _vm.mzShow,
              data: _vm.productList[_vm.selProIdx],
              "buyer-id": _vm.addData.purchaseOrgId,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.productList, _vm.selProIdx, $event)
              },
              change: _vm.setMz,
              close: _vm.mzClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "topWrap" }, [
      _c("img", { attrs: { src: require("@/images/success.png") } }),
      _vm._v(" "),
      _c("div", { staticClass: "status" }, [_vm._v("操作成功")]),
      _vm._v(" "),
      _c("div", { staticClass: "successContent" }, [
        _vm._v("销售订单创建成功"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }