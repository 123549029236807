"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { packScaleLeveAlllList } from "@/api/basic/pack";
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      listQuery: {
        current: 1,
        size: 20
      },
      param: {
        loginAccount: '',
        userName: '',
        userType: 0,
        phoneNum: '',
        isEnable: '1',
        orgList: [],
        roleList: []
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  mounted: function mounted() {
    // this.getList()
    this.setsel();
  },
  methods: {
    submitForm: function submitForm() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.data.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.getList();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        _this.selLoading = false;
      }, 500);
    },
    submit: function submit(row) {
      console.log(row);
      this.$emit('change', row);
      this.selCancel();
    },
    // clear() {
    //   this.$refs.multipleTable.clearSelection();
    // },
    getList: function getList() {
      var _this2 = this;
      var that = this;
      this.listLoading = true;
      (0, _compyuser.getCompanyList)(that.listQuery, that.param).then(function (res) {
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.param.loginAccount = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.orgList = [];
      that.param.roleList = [];
      that.listQuery.current = 1;
      that.listQuery.current = 1;
      // that.$refs[formName].resetFields();
      that.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      // this.clear();
      this.$emit('close');
      // this.proSelVisible = false
    },
    // submit() {
    //   console.log()
    //   this.$emit("change", this.multipleSelection);
    //   this.selCancel();
    // },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;