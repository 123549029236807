"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _basic = require("@/api/fake_expel/basic");
var _utils = require("@/utils");
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      value1: '',
      value2: '',
      option1: {},
      option2: {},
      chart1: '',
      chart2: '',
      activeIndex: '1',
      list: [{
        channelName: '渠道A',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道B',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道C',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道D',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道E',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道F',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }],
      customColor: 'linear-gradient(180deg, #98B6FF 0%, #5F7FFF 100%)'
    };
  },
  activated: function activated() {
    var that = this;
    that.chart1 = _echarts.default.init(this.$refs.scan);
    that.chart2 = _echarts.default.init(this.$refs.static);
    that.loadChart1();
    that.loadChart2();
  },
  methods: {
    loadChart1: function loadChart1() {
      var that = this;
      that.chart1 = _echarts.default.init(this.$refs.scan);
      var option = {
        backgroundColor: "#fff",
        "tooltip": {
          "trigger": "axis",
          "axisPointer": {
            "type": "shadow",
            shadowStyle: {
              color: 'rgba(152,182,255,0.2)'
            },
            textStyle: {
              color: "#252525FF"
            }
          },
          backgroundColor: "#fff",
          textStyle: {
            color: '#252525FF',
            fontSize: 13
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 2px 2px 2px 0 rgba(163,163,163,0.2)',
          formatter: function formatter(param) {
            return '<div style="width:150px;margin-bottom:5px;position:relative;">' + '<div>' + param[0].axisValue + '</div>' + '<div style="position:absolute;right:10px;top:0;">' + '<img style="width:14px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAACCklEQVRYR+2Xz2sTURDHZ7bWWkwQLRTTfR5k80Kl1JK38e6hPah4ERSP/qj9K/TifyGp1psHRaGF9mDFP8BNWktF7Ro9mO2iQdEiVGjyvrIJLTWsUchuzSHv+N7bmc8bZmbny0REALjg+reZMQVgMNhrXsz0jQ1jQllDTtj59p7jeooJiwAdDrfDFSLkVdq8yczg4FLhXfkKNM20MhwVwLYPg3lSSfNuA2DNewDCZTaMKTs9lG8F0u7ZzmOZHuWkuNgAcL1ZAOeNHp5QlrnYrpNW3y+5/ukaas+ZacGW4mwowKuP349s/vxxIUqQg/14ckKIL38BoHFliWeF959Oorr1MkqAnl7KZo+L5S5AZ0bAcb08AZO9xr7RsfTR1ThzwPmwPkxb+jUT37cz5tV6FbypVJKbG9XhrJV6US/LGJMwsF8sreeSum9NyoGNOkDzihtgt78uABfc8jxAZ5rCsmJnxFiUjchxyysEGv3ND9PCfwUgovluDnRGBPa6ES2V/FMJvf/tTiPay1a87PojVdRWmXnGlua10IEkzk5YLHnjuoanQQXkMuJcF6AzIxDHUNp/IPF45Nihr/+UA1H+A5pthQPsEibKSk0HkqldiEDuhdkolvwb0PoOMT3MSXEpcmkWDJ0aeg6ERKtHGMzXlTTv1QGiFKeNqVfPEiH5B3H6mcB5JVO3gkj/AgENJL4W7X8wAAAAAElFTkSuQmCC" />' + '</div>' + '</div>' + '<div style="position:relative;margin-bottom:5px">' + '<div>' + param[0].seriesName + '</div>' + '<div>' + param[0].value + '</div>' + '<div style="position:absolute;right:10px;top:0">' + param[0].marker + '</div>' + '</div>' + '<div style="position:relative;margin-bottom:5px">' + '<div>' + param[1].seriesName + '</div>' + '<div>' + param[1].value + '</div>' + '<div style="position:absolute;right:10px;top:0">' + param[1].marker + '</div>' + '</div>' + '<div style="position:relative;">' + '<div>' + param[2].seriesName + '</div>' + '<div>' + param[2].value + '</div>' + '<div style="position:absolute;right:10px;top:0;">' + param[2].marker + '</div>' + '</div>';
          }
        },
        grid: {
          top: '14%',
          left: 0,
          right: 20,
          bottom: 20,
          containLabel: true
        },
        "calculable": true,
        "xAxis": [{
          "type": "category",
          "axisLine": {
            lineStyle: {
              color: '#E5ECF1FF'
            }
          },
          "splitLine": {
            "show": false
          },
          "axisTick": {
            "show": false
          },
          "splitArea": {
            "show": false
          },
          "axisLabel": {
            "interval": 0,
            color: '#252525FF',
            fontSize: 13
          },
          "data": ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        }],
        "yAxis": [{
          type: 'value',
          name: '次',
          nameTextStyle: {
            color: '#252525FF',
            fontSize: 13,
            left: 0
          },
          axisLabel: {
            formatter: '{value}',
            color: '#252525FF'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E5ECF1FF'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#E5ECF1FF'
            }
          }
        }],
        "series": [{
          "name": "防伪码",
          "type": "bar",
          barWidth: '20px',
          "stack": "总量",
          "barGap": "10%",
          "itemStyle": {
            "normal": {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(139,255,184, 1)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(139,255,184, 1)' // 100% 处的颜色
                }],

                global: false // 缺省为 false
              },

              barBorderRadius: [0, 0, 3, 3]
            }
          },
          tooltip: {
            show: true
          },
          "data": [709, 1917, 2455, 2610, 1719, 1433, 1233, 2100, 1800, 1500, 800, 2500]
        }, {
          "name": "营销码",
          "type": "bar",
          barWidth: '20px',
          "stack": "总量",
          "itemStyle": {
            "normal": {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(25,212,253, 1)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(25,212,253, 1)' // 100% 处的颜色
                }],

                global: false // 缺省为 false
              },

              barBorderRadius: [3, 3, 0, 0]
            }
          },
          tooltip: {
            show: true
          },
          "data": [327, 1776, 507, 1200, 800, 482, 1200, 327, 597, 327, 1776, 507]
        }, {
          "name": "累计扫码量",
          "type": "line",
          showSymbol: false,
          symbolSize: 34,
          symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJaADAAQAAAABAAAAJQAAAACQyAEPAAADzElEQVRYCc1Yv2sUQRT+Zvcud94Zk4tJRCEKCtqKAYnEgDZWgpUWFkE0ELCw8A+xEBEDiUoKC62EVDYWGkgTsbVQiIIQE3L54Z33a2+cb5N3bjZ7m90jZ7LN23nvm/k+3s68mVmFmI/WWl19iVSxgKxWSFoOEnYGdrUCO9kBxynCqduoKY1qJovC+zsoK2VaMR4VFTs4oZOZMrqdDDIUELUfhdpFFIsprM6Pq2qUfruKuvla29/yyJmMHIkyYBjGZHD9dA75N7eUE4YLFXVtWmdX19CvbYTiwgj8MSNHd3fh17tRVfDHpN2U7PJTnSsDOQHutU0B+Y/3VT5oXCvIOfJc97VTEDk5PnmC+Hdkqt0Z8osIyti2THEOtTtDflHkI6/X3xDFVcZJ7Q3+r3fykl/4GqK47PdylQlBFEte8gvWFcXCuBd1SAZtxZKfOtjXFcVK3cpA0qenhN7jazhLK75WrOhIcC+79AxZxNqdNinPLON2so4xS+OU6ykB/etYqFqY/NqLV3GFVZNmP9V6WV15odOFPzgRZwBmpG8Dj+w6hpv1cyzMLnXi4Uoay80wQf7sIfy0uNsHBcN8OwQpVOwkfpjNqCL9KJg4aUe11JPg8SNqB+L4ySRDSqE6dB7zY9dxwbYxUHNQmZrB3NxnDGqNJHHEx/mU7nGI56E4ojiHBE9B4zcwZAR10Jcwlm36BePFiy/MUo/FA1oYyBvjXGpMavOpmCFvXN5d/9anJD7OqqQeK86BLVVGjxDbCSxKhsQnln7Gpe3tJ75mlnosngybAfz+cgor4nNqOOaYOSRtr6WfcfF5+4mvmaUei2fqZgC/n8u7rrDg+jU6JmfwyY9h2/WbON+Jj1MWqMfiIZ+doz4sjILlKpt4iznJGC3b9AvGixdfmHUvHRef6KPaQlcY0B87t4RpKQtujHXKzCH3k21liH4W0C99GPX3D2urOtYsXoPCQEExVmoSNmJGiFPFgNmq3E9Gv1T0BibiC/Uo7n3DUzgZZxXK+Dv2PhPgHGp17+Mkn72H7+5xeOSx7isl0ClkcS3rEJc9V1mcSe3nSdew8eGBWnKrOS+KppK2LMoVEnPj9Qtimzpo3fMUb668KNKxXw/55QbdOA7z5sqL4n6IIi/5hXvbFYu3ivzvf5VYQO22ucNY9N6YG5kiMQO8h7VbhHd88nkFMbYtUwLmzbVUan3iyzi72XTarLa7asmP25YpCRLY7oxx/CBB1BCYKRF34P66iLAD939KhNHKnzxegxy9Wd+88WbvtkI9WUVhT//k+cn+xz/Pv4LioBm84q/tAAAAAElFTkSuQmCC',
          "itemStyle": {
            "normal": {
              "color": '#98B6FFFF',
              "barBorderRadius": 17
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: '#5F7FFFFF'
              }, {
                offset: 1,
                color: '#5F7FFFFF'
              }]),
              shadowColor: 'rgba(95,127,255, 0.7)',
              shadowBlur: 20,
              shadowOffsetY: 1
            }
          },
          tooltip: {
            show: true
          },
          "data": [1036, 3693, 2962, 3810, 2519, 1915, 2433, 2427, 2397, 1820, 2576, 3007]
        }]
      };
      that.chart1.setOption(option);
      that.chart1.resize();
    },
    loadChart2: function loadChart2() {
      var that = this;
      that.chart2 = _echarts.default.init(this.$refs.static);
      var option = {
        backgroundColor: '#fff',
        "title": {
          "text": "红包发放金额排行榜",
          x: "center",
          y: "4%",
          textStyle: {
            color: '#252525FF',
            fontSize: '22'
          },
          subtextStyle: {
            color: '#252525FF',
            fontSize: '16'
          },
          show: false
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(152,182,255,0.2)'
            }
          },
          padding: [10, 10],
          extraCssText: 'box-shadow: 2px 2px 2px 0 rgba(163,163,163,0.2)',
          textStyle: {
            color: '#252525FF',
            fontSize: 13
          },
          formatter: function formatter(param) {
            return '<div style="width:150px;">' + '<div class="cell">' + '<div class="cell-primary">' + '<div>' + param[0].name + '</div>' + '<div style="padding:10px 0 0 0">' + param[0].value + '元</div>' + '</div>' + '<div class="cell-ft" style="position:absolute;top:10px;right:20px;">' + '<img style="width:14px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAiCAYAAABMfblJAAADMklEQVRYR8WWT2wUZRjGn+cbt7Ra2hBTsbstEOk0MWiwu5twMHAwHFEO8udATAgXkx5IiDGepOtBE28aDnghIVxEExJuaBAlVIzG7jaRA6SzQGh3to0NSv1Ta+vOY76trVsZdmfLFOc0mbzv83v/zft9HJESLPo5CIcFJBH3Q04A+Lyttf2tbb2dP7Hg+e8E0vG4Offr8Xb7hpbtHPH8O5A2rT0QAPk+855fkWQeCRD47pECCZQt8GUAQ5Iya5klwWFDc5xLkLUCWxCMk8v0Pf2lZbFwq7wr/UzyStzg/4KsvmVVewho2MDJDbjdlx8ezK+No1x6a8+lZa2x0h6Qtm2pFUND8IqMyWX7ur+qBQvIQUrX73EI6P75mAyd0rBy2B6HgsmrxiCX3pr6Yimggue/EgBDIUGGA//NJDxqQfsALhiDs7WgUc/fW1kEDTygGo2A/7iFZLEkKIn5m5N7Ydfjg0FL5hGBy1x+Q+AUTWJYQSUBVXYF4OuAXoj4DzcHjChaz+z/AQa2D3WjJ6daWpwX5+crLwH4CJJT3RrAPGiOOCb4vhLgWwkbGlQhWoYEr6fd5DaSynvlQ4DOSAhguD/blzwvyckXy7ciHHPRgNWoaU5m3eSgfc0XS4chzGbcnk/t0VYo+qclvBahx9GBBHwR72bdnpO1wiPF0hsQ34S0MSqwcQ/BjzNu8pAtaZioLWnBK38maHc8PSQuPNWaerW3l3+ECd6Ynl7/289/XgSwIxbg8kSCCwImsv2pZ+23kbHSXYLrBLUASEQtaeM7DTlliPcAM4NAv6f7k+eqw+OVbZmdAJUuCG9D6IwpQ57I9qeO1hPLe/4nkg7EBbxrjD4AOCPo10xfz+lqST1/0BBOAHYx0DFB7TEBa2TI8ayb2rxY0tKchHURere602JxAXC2E63ds0DHX5gbb7gWV0YT/cev9bP3S5AJSV1NZGdNJ5kfK5UEpJp0XJ05WbB3mrOSDq5OoTkvGnzI0duTWyoLwQ+A1jfn3qQ1OfVEm56rnoOjN390g2DhhICdkB5vUqq+OfkLhUuPsfXodvfJ0oqD1y7ha+MzHXECn9/Uea926f8NuboDy3byE5wAAAAASUVORK5CYII=" />' + '</div>' + '</div></div>';
          }
        },
        grid: {
          top: '14%',
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['北京', '上海', '广东', '江苏', '四川'],
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#252525FF',
            textStyle: {
              fontSize: 13
            }
          }
        }],
        yAxis: [{
          type: 'value',
          name: '元',
          nameTextStyle: {
            color: '#252525FF',
            fontSize: 13,
            left: 0
          },
          axisLabel: {
            formatter: '{value}',
            color: '#252525FF'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E5ECF1FF'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#E5ECF1FF'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: [300, 450, 479, 229, 210],
          barWidth: '20px',
          itemStyle: {
            normal: {
              color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(152,182,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(95,127,255,1)' // 100% 处的颜色
              }], false),
              barBorderRadius: [3, 3, 3, 3],
              shadowColor: 'rgba(152,182,255,1)',
              shadowBlur: 2
            }
          },
          label: {
            normal: {
              show: false
            }
          }
        }]
      };
      that.chart2.setOption(option);
      that.chart2.resize();
    },
    handleSelect: function handleSelect() {}
  }
};
exports.default = _default;