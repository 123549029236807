"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
// import router from '@/router'
var _default = {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 导航条, 布局风格, defalut(默认) / inverse(反向)
    navbarLayoutType: 'default',
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: 'dark',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    menuList: [],
    menuActiveName: '',
    // 主入口标签页
    mainTabs: [],
    mainTabsActiveName: ''
  },
  mutations: {
    updateDocumentClientHeight: function updateDocumentClientHeight(state, height) {
      state.documentClientHeight = height;
    },
    updateSidebarFold: function updateSidebarFold(state, fold) {
      state.sidebarFold = fold;
    },
    updateMenuList: function updateMenuList(state, list) {
      state.menuList = list;
    },
    updateMenuActiveName: function updateMenuActiveName(state, name) {
      state.menuActiveName = name;
    },
    updateMainTabs: function updateMainTabs(state, tabs) {
      state.mainTabs = tabs;
    },
    updateMainTabsActiveName: function updateMainTabsActiveName(state, name) {
      state.mainTabsActiveName = name;
    },
    removeMainActiveTab: function removeMainActiveTab(state) {
      state.mainTabs = state.mainTabs.filter(function (item) {
        return item.name !== state.mainTabsActiveName;
      });
      if (state.mainTabs.length >= 1) {
        // 当前选中tab被删除
        // router.push({ name: state.mainTabs[state.mainTabs.length - 1].name }, () => {
        //   state.mainTabsActiveName = state.mainTabs[state.mainTabs.length - 1].name
        // })
      } else {
        state.menuActiveName = '';
        // router.push({ name: 'home' })
      }
    }
  }
};
exports.default = _default;