"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    selectItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      subLoading: false,
      isDisable: false,
      sourceList: [],
      strategyList: [],
      templateList: [],
      formData: {
        dataTimeRange: '',
        strategyGroupId: '',
        financeSourceId: ''
        // flowId: "",
      },

      formRules: {
        dataTimeRange: [{
          required: true,
          message: '请选择活动日期',
          trigger: 'change'
        }],
        strategyGroupId: [{
          required: true,
          message: '请选择策略组',
          trigger: 'change'
        }],
        financeSourceId: [{
          required: true,
          message: '请选择活动资金来源',
          trigger: 'change'
        }]
        // flowId: [
        //   { required: true, message: "请选择活动模板", trigger: "change" },
        // ],
      }
    };
  },
  mounted: function mounted() {
    this.getClv();
    this.getZjSourceList();
    // this.getTemplateList();
  },

  methods: {
    getClv: function getClv() {
      var _this = this;
      (0, _activityManage.getStrategyGroup)().then(function (res) {
        if (res.data.code == 200) {
          _this.strategyList = res.data.data;
          var resdata = res.data.data;
          for (var m = 0; m < resdata.length; m++) {
            if (resdata[m].isDefault == '0') {
              _this.formData.strategyGroupId = resdata[m].id;
              break;
            }
          }
        }
      });
    },
    // 获取资金来源
    getZjSourceList: function getZjSourceList() {
      var _this2 = this;
      (0, _activityManage.getMoneySource)().then(function (res) {
        if (res.data.code == 200) {
          _this2.sourceList = res.data.data;
          var resdata = res.data.data;
          for (var m = 0; m < resdata.length; m++) {
            if (resdata[m].isDefault == '1') {
              _this2.formData.financeSourceId = resdata[m].id;
              break;
            }
          }
        }
      });
    },
    // 获取活动模板
    getTemplateList: function getTemplateList() {
      var _this3 = this;
      (0, _activityManage.getComboTemplateStoreroom)().then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this3.templateList = res.data.data.filter(function (item) {
              return item.templateStatus == '1';
            });
          } else {
            _this3.$message.error(res.data.data.errMsg);
          }
        } else {
          _this3.$message.error(res.data.msg);
        }
      });
    },
    // 表单验证
    checkForm: function checkForm(val, text) {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this4.$message.closeAll();
            _this4.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    finish: function finish() {
      var _this5 = this;
      var eCheck = this.checkForm('formData', '请正确填写批量修改活动设置');
      eCheck.then(function () {
        _this5.subLoading = true;
        _this5.isDisable = true;
        var queryData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this5.formData), {}, {
          startTime: _this5.formData.dataTimeRange[0],
          endTime: _this5.formData.dataTimeRange[1],
          activityIdList: _this5.selectItem
        });
        delete queryData.dataTimeRange;
        (0, _activityManage.batchUpdateBase)(queryData).then(function (res) {
          if (res.data.code == 200) {
            if (res.data.data.errCode) {
              _this5.subLoading = false;
              _this5.isDisable = false;
              return _this5.$message.error(res.data.data.errMsg);
            } else {
              _this5.$message({
                message: '修改成功！',
                type: 'success',
                duration: 3 * 1000
              });
              _this5.goBack();
              _this5.subLoading = false;
              _this5.isDisable = false;
            }
          } else {
            _this5.subLoading = false;
            _this5.isDisable = false;
            _this5.$message.error(res.data.msg);
            return;
          }
        });
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit('close');
    }
  }
};
exports.default = _default2;