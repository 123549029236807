"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _writeOffFlow = require("@/api/writeOffModule/writeOffFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagewriteOffModulewriteOffFlowindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagewriteOffModulewriteOffFlowindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      createTime: '',
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        awardName: '',
        endTime: '',
        keywords: '',
        startTime: ''
        // id: this.$route.query.id || null
      },

      total: 0,
      listLoadig: false
    };
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getSalesmanScanRecordsList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  mounted: function mounted() {
    console.log('mounted');
  },
  beforeRouteEnter: function beforeRouteEnter(to, form, next) {
    next(function (vm) {
      if (form.path == '/marketingManage/writeOffModule/salesman/index') {
        var id = to.query.id;
        vm.queryParams.keywords = id;
        vm.getSalesmanScanRecordsList();
      }
    });
  },
  methods: {
    // 业务员核销记录列表
    getSalesmanScanRecordsList: function getSalesmanScanRecordsList() {
      var _this = this;
      this.listLoadig = true;
      // if (Array.isArray(this.createTime) && this.createTime.length) {
      //   // 重置时 要把 createTime清空
      //   this.queryParams.startTime = this.createTime[0];
      //   this.queryParams.endTime = this.createTime[1];
      // }
      (0, _writeOffFlow.getSalesmanScanRecordsList)(this.queryParams).then(function (res) {
        if (res.data.data.errCode) {
          _this.$message(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this.total = parseInt(res.data.data.total);
            _this.tableData = res.data.data.records;
          } else {
            _this.total = 0;
            _this.tableData = [];
          }
        }
      }).finally(function () {
        _this.listLoadig = false;
      });
    },
    reset: function reset() {
      this.queryParams.pageNo = 1;
      this.queryParams.pageSize = 10;
      this.queryParams.awardName = '';
      this.queryParams.endTime = '';
      this.queryParams.keywords = '';
      this.queryParams.startTime = '';
      this.createTime = '';
    },
    // 重置
    restSalesmanScanRecordsList: function restSalesmanScanRecordsList() {
      // 清空queryParams
      this.reset();
      this.getSalesmanScanRecordsList();
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1, this.getSalesmanScanRecordsList();
    },
    // 导出
    exportSalesman: function exportSalesman() {
      var _this2 = this;
      console.log(this.queryParams, 123);
      (0, _writeOffFlow.exportSalesmanScanRecordsList)(this.queryParams).then(function (res) {
        console.log(res.data, 'res');
        _this2.$message.success('导出成功！');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '业务员列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  }
};
exports.default = _default;