"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExportExcel = getExportExcel;
exports.getPage = getPage;
exports.postCheck = postCheck;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

//生产订单核查表格
function getPage(params) {
  return (0, _request.default)({
    url: base + '/market-config/productOrderCheckData/page',
    method: 'get',
    params: params
  });
}
//生产订单核查导出
function getExportExcel(params) {
  return (0, _request.default)({
    url: base + '/market-config/productOrderCheckData/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
//检测
function postCheck() {
  return (0, _request.default)({
    url: base + '/market-config/productOrderCheckData/check',
    method: 'post'
  });
}