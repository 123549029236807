"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TinyMce: _tinyMce.default
  },
  data: function data() {
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: null,
        title: null,
        content: '',
        url: null,
        status: 1,
        isTop: 0
      },
      dataRule: {
        title: [{
          required: true,
          message: '公告标题不能为空',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '公告内容不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    init: function init(id) {
      var _this = this;
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(function () {
        if (_this.dataForm.id) {
          (0, _integralMall.noticeInfo)(_this.dataForm.id).then(function (res) {
            if (res.status === 200) {
              _this.dataForm = res.data;
            }
          });
        } else {
          _this.$refs['dataForm'].resetFields();
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _integralMall.upNotice)(_this2.dataForm).then(function (res) {
            if (res.status === 200) {
              _this2.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: function onClose() {
                  _this2.$emit('refreshDataList');
                  _this2.cancle();
                }
              });
            }
          });
        }
      });
    },
    cancle: function cancle() {
      this.$refs['dataForm'].resetFields();
      this.dataForm.content = '';
      this.visible = false;
      this.$emit('close');
    }
  }
};
exports.default = _default;