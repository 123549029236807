"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
var _select = require("@/api/warehouse/select");
var _index = require("@/api/warehouse/checkout/index");
var _codeDetails = _interopRequireDefault(require("@/components/codeDetails"));
var _channelDialogChoose = _interopRequireDefault(require("@/components/channelDialogChoose"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: '',
  components: {
    codeDetails: _codeDetails.default,
    channelDialog: _channelDialogChoose.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'primary',
          5: 'primary',
          6: 'primary',
          7: 'success',
          8: 'warning',
          9: 'danger',
          10: 'info',
          11: 'info'
        };
        return statusMap[value];
      }
    },
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '创建单据',
          2: '修改单据',
          3: '删除单据',
          4: '拆单',
          5: '审核不通过',
          6: '审核通过',
          7: '单据下载',
          8: '单据扫描',
          9: '正常结单',
          10: '强制结单',
          11: '撤销下载',
          12: '撤销结单',
          14: '已签收'
        };
        return statusMap[value];
      }
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1];
      } else if (arr.length == 3) {
        name = arr[2];
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0];
      }
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      num: '',
      active: 0,
      detailVisible: false,
      mloading: false,
      listLoading: false,
      scanData: {},
      temp: {},
      flowList: [],
      settype: 2,
      showflow: false,
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      detailData: {
        tradeBillAllVO: {
          billNo: ''
        }
      },
      proList: [],
      tradeOperateLogDTOS: [],
      autoOutList: [],
      codeVisible: false,
      billNo: '',
      billNo1: '',
      // 自动发货传参
      productId: '',
      ncProductId: '',
      activeName: '',
      inStore: '',
      btn_loading: false,
      channelShow: false,
      dealerData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    this.getstoreTypeInfoList('DJZT');
    this.getstoreTypeInfoList('DJLY');
    TypeDataComs = this.TypeDataComs;
    that.mloading = true;
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      var _this = this;
      var that = this;
      this.billNo1 = rows.billNo;
      (0, _index.getPeoductionDetail)({
        billNo: rows.billNo,
        ncProductId: '',
        productId: ''
      }).then(function (res) {
        that.mloading = false;
        if (res.data.code == 200) {
          console.log(rows);
          that.inStore = rows.inStoreName;
          that.detailData = res.data.data;
          that.proList = res.data.data.tradeProductVOS;
          that.autoOutList = res.data.data.autoOutList;
          that.tradeOperateLogDTOS = res.data.data.tradeOperateLogDTOS;
          switch (res.data.data.tradeBillAllVO.billStatus) {
            case 1:
              _this.active = 0;
              break;
            case 2:
              _this.active = 0;
              break;
            case 3:
              _this.active = 1;
              break;
            case 4:
              _this.active = 2;
              break;
            case 5:
              _this.active = 2;
              break;
            case 6:
              _this.active = 3;
              break;
            case 7:
              _this.active = 4;
              break;
            case 8:
              _this.active = 4;
              break;
            default:
              _this.active = 0;
          }
        } else {
          that.$message.error(res.data.msg);
          that.detailData = {
            tradeBillAllVO: {
              billNo: ''
            }
          };
          that.proList = [];
          return;
        }
      });
      this.detailVisible = true;
      this.mloading = true;
      this.mloading = false;
    },
    setChannel: function setChannel(val) {
      if (val) {
        this.dealerData = [];
        this.dealerData.push({
          dealerCode: val.channelCode,
          dealerName: val.channelName,
          dealerClass: val.channelClassName,
          dealerLevel: val.channelLevelName,
          pid: val.pid
        });
      }
    },
    channelChoose: function channelChoose() {
      this.channelShow = true;
    },
    channelClose: function channelClose() {
      this.channelShow = false;
    },
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this2 = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == 'DJZT') {
            console.log('DJZT');
            _this2.TypeDataComs.status = res.data.data;
          }
          if (param.code == 'DJLY') {
            _this2.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this2.TypeDataComs;
        }
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      var auth = this.authId('searchbtn', this.$route.meta.authList);
      this.detailVisible = false;
      this.dealerData = [];
    },
    submitForm: function submitForm() {
      var _this3 = this;
      if (!this.dealerData.length) {
        return this.$message.error('请添加经销商');
      }
      this.btn_loading = true;
      (0, _index.billAutoOut)({
        billNo: this.billNo1,
        custId: this.dealerData[0].pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          _this3.$message({
            message: '保存成功',
            type: 'success'
          });
          _this3.handleClose();
          _this3.$emit('handleRefresh');
        }
        _this3.btn_loading = false;
      }).catch(function () {
        _this3.btn_loading = false;
      });
    },
    // 获取数码详情
    getCodesDetail: function getCodesDetail(value) {
      var that = this;
      that.billNo = value.billNo;
      that.productId = value.productId;
      that.ncProductId = value.ncProductId || '';
      that.codeVisible = true;
    },
    codeHide: function codeHide() {
      var that = this;
      that.codeVisible = false;
    }
  }
};
exports.default = _default;