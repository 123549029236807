var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "detailWrap" },
            [
              _c("div", { staticClass: "billTitle" }, [
                _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                _vm._v(" "),
                _c("span", { staticClass: "billTitleText" }, [
                  _vm._v("费用酒订单 " + _vm._s(_vm.addData.orderNo)),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "billType",
                    class: _vm._f("typeClass")(_vm.addData.tradeStatus),
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("orderStatusText")(_vm.addData.tradeStatus))
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "topRight" }, [
                  _c("img", { attrs: { src: require("@/images/avatar.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.addData.creatorUser))]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(_vm._s(_vm._f("textDate")(_vm.addData.createTime))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "topWrap", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [
                        _vm._v("订单日期:"),
                      ]),
                      _vm._v(" "),
                      !_vm.placeOrderDateEdit
                        ? _c("div", { staticClass: "topContent" }, [
                            _vm._v(
                              _vm._s(
                                _vm.addData.tradeDate
                                  ? _vm.addData.tradeDate.split(" ")[0]
                                  : " "
                              )
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                  clearable: false,
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.placeOrderDateEdit = false
                                  },
                                },
                                model: {
                                  value: _vm.addData.tradeDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "tradeDate", $$v)
                                  },
                                  expression: "addData.tradeDate",
                                },
                              }),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [
                        _vm._v("订单类型:"),
                      ]),
                      _vm._v(" "),
                      !_vm.typeEdit
                        ? _c("div", { staticClass: "topContent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("orderType")(_vm.addData.orderType)
                                ) +
                                "\n              "
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { "visible-change": _vm.orderTypeClose },
                                  model: {
                                    value: _vm.addData.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addData, "orderType", $$v)
                                    },
                                    expression: "addData.orderType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "费用酒订单", value: "4" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "样品酒订单", value: "5" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [_vm._v("备注:")]),
                      _vm._v(" "),
                      !_vm.remarkEdit
                        ? _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _vm.addData.remark
                                ? _c(
                                    "el-popover",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placement: "bottom-start",
                                        width: "500",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "popoverremark" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.addData.remark
                                                ? _vm.addData.remark
                                                : " "
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "remark",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.addData.remark
                                                ? _vm.addData.remark
                                                : " "
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("div", { staticClass: "remark" }, [
                                    _vm._v(_vm._s(" ")),
                                  ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.remarkEdit = false
                                  },
                                },
                                model: {
                                  value: _vm.addData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "remark", $$v)
                                  },
                                  expression: "addData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "hover" } },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("订单相关方信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("销售单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.saleOrgName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.sendOrgName ? _vm.sendOrgName : " ") +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("采购单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.purchaseOrgName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.receiveOrgName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货联系人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveUser)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货联系电话:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receivePhone)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货地址:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveAddress)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "12px" },
              attrs: { shadow: "hover", "body-style": _vm.cardPadding },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle titleBtn",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/cpxx.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("产品信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "addTable",
                  staticStyle: { width: "100%", margin: "-1px 0 0" },
                  attrs: {
                    "max-height": "600",
                    data: _vm.productList,
                    fit: "",
                    "row-class-name": "iconNone",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "65",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      align: "center",
                      prop: "productCode",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      align: "center",
                      prop: "productModel",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      align: "center",
                      prop: "packScaleExpression",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "箱数",
                      prop: "chestNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盒数",
                      prop: "bottleNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总盒数",
                      prop: "totalBottleNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "12px" },
              attrs: { shadow: "hover", "body-style": _vm.btncardPadding },
            },
            [
              _c(
                "div",
                { staticClass: "btnWrap" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "auditForm",
                      attrs: {
                        model: _vm.auditForm,
                        "label-width": "75px",
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核意见", prop: "auditReason" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "100", "show-word-limit": "" },
                            model: {
                              value: _vm.auditForm.auditReason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.auditForm,
                                  "auditReason",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "auditForm.auditReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "auto" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", plain: "" },
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.audit(2)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.audit(1)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }