"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
var _sceneMarketing = require("@/api/terminal/sceneMarketing");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _actDialog = _interopRequireDefault(require("@/components/actDialog"));
var _UploadMusic = _interopRequireDefault(require("@/components/UploadMusic"));
var _org = require("@/api/basic/org");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagesceneMarketingactivityListindex';
var _default = {
  name: 'MarketingManagesceneMarketingactivityListindex',
  components: {
    Pagination: _Pagination.default,
    UploadImg: _UploadImg.default,
    UploadMusic: _UploadMusic.default,
    actDialog: _actDialog.default
  },
  filters: {
    stateFilter: function stateFilter(status) {
      var statusMap = {
        1: '未开始',
        2: '进行中',
        3: '已结束'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      total: 0,
      datalist: [],
      srcList: [],
      tabHeight: '100%',
      Prizename: '',
      typed: false,
      listLoading: false,
      loading: false,
      dialogTableVisible: false,
      productVisibleShow: false,
      presentDate: new Date().getTime(),
      dialogTitle: '活动制作',
      choseData: true,
      fileList: [],
      disabled: false,
      timeData: [],
      hideUpload: false,
      orgList: [],
      qyorgId: '',
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      searchForm: {
        pageNow: 1,
        pageSize: 20,
        qyorgId: '',
        actName: ''
      },
      formInline: {
        pageNow: 1,
        pageSize: 20,
        actName: '',
        bgImageAdderss: '',
        bgImageName: '',
        bgMusicAddress: '',
        bgMusicName: '',
        props: '1',
        issignin: '1',
        lotteryNum: '',
        endTime: '',
        remark: '',
        description: '',
        yxCjActPrizeList: [],
        qrCode: ''
      },
      formInlineRules: {
        actName: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }],
        bgImageAdderss: [{
          required: true,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bgMusicAddress: [{
          required: true,
          message: '请上传背景音乐',
          trigger: 'change'
        }],
        props: [{
          required: true,
          message: '请输入奖项说明',
          trigger: 'blur'
        }],
        issignin: [{
          required: true,
          message: '请选择签到是否得抽奖券',
          trigger: 'change'
        }],
        lotteryNum: [{
          required: true,
          message: '请输入奖券数量',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择日期时间',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: '请输入活动说明',
          trigger: 'blur'
        }],
        yxCjActPrizeList: [{
          required: true,
          message: '请选择奖项',
          trigger: 'blur'
        }]
      },
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
        that.getOrgListAll();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  methods: {
    addActive: function addActive() {
      this.authId('addbtn', this.apis);
      this.dialogTableVisible = true;
      this.disabled = false;
    },
    changeval: function changeval() {
      this.formInline.lotteryNum = '';
    },
    templatehtml: function templatehtml() {
      this.template = '活动名称：*****\n活动产品：*****\n活动时间：2017年5月2日-2017年10月31日\n中奖概率\n一等奖：0.5%\n二等奖：4.5%\n三等奖：45%\n四等奖：50%\n活动说明：\n活动参与产品为2017年5月2日以后生产的产品，生产日期见产品袋盖或袋身。\n通过微信扫描产品上的活动二维码，参与活动，可以获得微信红包，微信卡券等奖品。\n红包会直接转账到零钱，请在零钱中查看。卡券将发放到微信卡包，请在卡包查看。\n卡券如果是实物，则需要您到附近的兑换点进行兑换。兑换时，请展示您卡券的兑奖二维码，协助店主兑换。\n最终解释权，归兆信股份版权所有。';
      this.formInline.description = this.template;
    },
    clearListShow: function clearListShow() {
      this.formInline.yxCjActPrizeList = [];
      this.Prizename = '';
    },
    getTimeFun: function getTimeFun(val) {
      var num = new Date(val).getTime();
      return num;
    },
    getOrgListAll: function getOrgListAll() {
      var _this = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: ''
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      var that = this;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      if (that.qyorgId) {
        submitData.qyorgId = that.qyorgId[that.qyorgId.length - 1];
      }
      this.searchForm.serchstartTime = this.timeData[0];
      this.searchForm.serchendTime = this.timeData[1];
      (0, _sceneMarketing.cjActInfoList)(this.searchForm).then(function (res) {
        res = res.data;
        // console.log(res);
        if (res.status == 200) {
          _this2.datalist = res.data.list;
          _this2.total = res.data.total;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    // 结束活动
    setEnd: function setEnd(val) {
      var _this3 = this;
      this.authId('endbtn', this.apis);
      (0, _sceneMarketing.endexecute)({
        id: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          setTimeout(function () {
            _this3.fetchData();
          }, 1000);
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.loading = true;
      var data = new Date(this.formInline.endTime).getTime();
      if (data < this.presentDate) {
        this.$message({
          message: '过期时间不能小于当前时间',
          type: 'error',
          duration: 3 * 1000
        });
        this.loading = false;
        return false;
      }
      if (this.formInline.issignin == 1) {
        if (this.formInline.lotteryNum == '' || this.formInline.lotteryNum == null) {
          this.$message({
            message: '请填写奖券数量',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        }
      }
      var PrizeList = this.formInline.yxCjActPrizeList;
      for (var i = 0; i < PrizeList.length; i++) {
        if (PrizeList[i].totalNum == null || PrizeList[i].totalNum === '') {
          this.$message({
            message: '请填写奖品数量',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        } else if ((PrizeList[i].bottleNum == null || PrizeList[i].bottleNum === '') && (PrizeList[i].type * 1 == 2 || PrizeList[i].type * 1 == 3)) {
          this.$message({
            message: '请填写奖品袋数',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        } else if ((PrizeList[i].boxNum == null || PrizeList[i].boxNum === '') && PrizeList[i].type * 1 == 2) {
          this.$message({
            message: '请填写奖品箱数',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        }
      }
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          (0, _sceneMarketing.cjActInfoAdd)(_this4.formInline).then(function (res) {
            _this4.loading = false;
            res = res.data;
            if (res.status == 200) {
              _this4.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this4.cancel();
              _this4.fetchData();
            } else {
              _this4.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          _this4.loading = false;
          return false;
        }
      });
    },
    getDetail: function getDetail(val, type, id) {
      var _this5 = this;
      if (val === 2) {
        this.authId('scanbtn', this.apis);
        this.disabled = true;
        this.dialogTitle = '活动详情';
      } else {
        this.authId('updatebtn', this.apis);
        this.disabled = false;
        this.dialogTitle = '活动编辑';
      }
      (0, _sceneMarketing.detailes)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this5.choseData = false;
          _this5.dialogTableVisible = true;
          _this5.formInline = res.data;
          _this5.$nextTick(function () {
            _this5.$refs.bgImageUpload.fileList = [{
              url: res.data.bgImageAdderss
            }];
            _this5.$refs.bgImageUpload.hideUpload = true;
            _this5.$refs.bgMusicUpload.fileList = [{
              url: res.data.bgMusicAddress,
              name: res.data.bgMusicName
            }];
            _this5.$refs.bgMusicUpload.hideUpload = true;
          });
          _this5.srcList = [];
          _this5.srcList.push(res.data.qrCode);
          var Case = ['一', '二', '三', '四', '五'];
          _this5.formInline.yxCjActPrizeList.forEach(function (item, index) {
            item.orders = Case[index] + '等奖';
            item.prizeOrder = (index + 1).toString();
          });
        } else {
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    updateData: function updateData(id) {
      var _this6 = this;
      this.loading = true;
      var data = new Date(this.formInline.endTime).getTime();
      if (data < this.presentDate) {
        this.$message({
          message: '过期时间不能小于当前时间',
          type: 'error',
          duration: 3 * 1000
        });
        this.loading = false;
        return false;
      }
      if (this.formInline.issignin == 1) {
        if (this.formInline.lotteryNum == '' || this.formInline.lotteryNum == null) {
          this.$message({
            message: '请填写奖券数量',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        }
      }
      var PrizeList = this.formInline.yxCjActPrizeList;
      for (var i = 0; i < PrizeList.length; i++) {
        if (PrizeList[i].totalNum == null || PrizeList[i].totalNum === '') {
          this.$message({
            message: '请填写奖品数量',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        } else if ((PrizeList[i].bottleNum == null || PrizeList[i].bottleNum === '') && (PrizeList[i].type * 1 == 2 || PrizeList[i].type * 1 == 3)) {
          this.$message({
            message: '请填写奖品袋数',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        } else if ((PrizeList[i].boxNum == null || PrizeList[i].boxNum === '') && PrizeList[i].type * 1 == 2) {
          this.$message({
            message: '请填写奖品箱数',
            type: 'error',
            duration: 3 * 1000
          });
          this.loading = false;
          return false;
        }
      }
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          (0, _sceneMarketing.update)(_this6.formInline).then(function (res) {
            _this6.loading = false;
            res = res.data;
            if (res.status == 200) {
              _this6.dialogTableVisible = false;
              _this6.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this6.cancel();
              _this6.fetchData();
            } else {
              _this6.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          }).catch(function () {
            _this6.loading = false;
          });
        } else {
          _this6.loading = false;
          return false;
        }
      });
    },
    getExecute: function getExecute(id, type, num) {
      var _this7 = this;
      // console.log(id, type);
      (0, _sceneMarketing.getActSinCount)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.data == 0 || res.data == null) {
          _this7.$message({
            message: '该活动参与人为空',
            type: 'error',
            duration: 3 * 1000
          });
          return false;
        } else {
          if (type == 1) {
            window.open(window.location.origin + '/wxh5/lottery/index.html?actId=' + id + '&type=' + type + '&num=' + num, '_blank');
          }
          if (type == 2) {
            window.open(window.location.origin + '/wxh5/lottery/index.html?actId=' + id + '&type=' + type + '&num=' + num, '_blank');
          }
        }
      });
    },
    getExecute_h5: function getExecute_h5(id, type, num) {
      if (type == 1) {
        window.open(window.location.origin + '/wxh5/lottery/index.html?actId=' + id + '&type=' + type + '&num=' + num, '_blank');
      }
      if (type == 2) {
        window.open(window.location.origin + '/wxh5/lottery/index.html?actId=' + id + '&type=' + type + '&num=' + num, '_blank');
      }
    },
    removeTable: function removeTable() {
      this.formInline.yxCjActPrizeList.splice(this.formInline.yxCjActPrizeList.length - 1, 1);
    },
    productListShow: function productListShow() {
      this.productVisibleShow = true;
    },
    // 产品选择
    setProduct: function setProduct(data) {
      var _this8 = this;
      // console.log(data);
      this.productVisibleShow = false;
      data.forEach(function (item, index) {
        var obj = {
          prizeImgUrl: item.productImages,
          productBrand: item.brandName ? item.brandName : '',
          // 产品品牌
          prizeId: item.pId ? item.pId : '',
          // 产品id
          name: item.productName ? item.productName : item.alias,
          // 产品名称
          productNo: item.productCode ? item.productCode : '',
          // 产品编号
          productNorm: item.productModel ? item.productModel : '',
          // 产品规格
          productType: item.productClassName ? item.productClassName : '',
          // 产品分类
          isscan: item.pId ? '1' : '2',
          source: item.pId ? '2' : '1',
          redType: item.type ? item.type : '',
          redAmount: item.costprize ? item.costprize : '',
          type: item.type == '1101' ? '1' : item.isCode == 1 ? '2' : '3',
          totalNum: '',
          bottleNum: '',
          boxNum: '',
          remark: item.remark ? item.remark : '',
          wishing: item.blessing ? item.blessing : '',
          sendName: item.name ? item.name : ''
        };
        _this8.Prizename = item.productName;
        if (_this8.formInline.yxCjActPrizeList.length !== 5) {
          _this8.formInline.yxCjActPrizeList.push(obj);
        } else {
          _this8.$message.closeAll();
          _this8.$message({
            message: '只能添加五个奖项',
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
      var Case = ['一', '二', '三', '四', '五'];
      this.formInline.yxCjActPrizeList.forEach(function (item, index) {
        item.orders = Case[index] + '等奖';
        item.prizeOrder = (index + 1).toString();
      });
    },
    // 弹窗取消
    cancel: function cancel() {
      this.formInline = {
        pageNow: 1,
        pageSize: 20,
        actName: '',
        bgImageAdderss: '',
        bgImageName: '',
        bgMusicAddress: '',
        bgMusicName: '',
        props: '1',
        issignin: '1',
        lotteryNum: '',
        endTime: '',
        remark: '',
        description: '',
        yxCjActPrizeList: [],
        qrCode: ''
      };
      this.restForm('formInline');
      this.$refs.bgImageUpload.fileList = [];
      this.$refs.bgImageUpload.hideUpload = false;
      this.$refs.bgMusicUpload.fileList = [];
      this.$refs.bgMusicUpload.hideUpload = false;
      this.dialogTableVisible = false;
      this.disabled = false;
      this.choseData = true;
    },
    // 弹窗取消
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.searchForm.qyorgId = '';
      this.qyorgId = '';
      this.timeData = [];
    }
  }
};
exports.default = _default;