"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'table-suply',
  props: ['orderNo', 'detailVisible'],
  filters: {
    labelText: function labelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        '1': '前关联',
        '2': '后关联'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        '0': 'ERP',
        '1': '平台'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      proList: [],
      scanData: {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        remarks: ''
      },
      mloading: false
    };
  },
  mounted: function mounted() {
    this.scanDetail(this.orderNo);
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('close');
    },
    scanDetail: function scanDetail(orderNo) {
      var _this = this;
      // this.submitType = 2
      this.mloading = true;
      (0, _store.getOrderdetail)({
        orderNo: orderNo
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          var data = res.data.data;
          _this.scanData = Object.assign(_this.scanData, data);
          // this.scanData.orderNo = data.orderNo
          // this.scanData.factoryCode = data.factoryCode
          // this.scanData.shopCode = data.shopCode
          // this.scanData.productLineCode = data.productLineCode
          // this.scanData.teamCode = data.teamCode
          // this.scanData.storehouseCode = data.storehouseCode
          // this.scanData.traychestScaleCode = data.traychestScaleCode
          // this.scanData.planNum = data.planNum
          // this.scanData.miniUnit = data.miniUnit
          // this.scanData.batchNo = data.batchNo
          // this.scanData.labelType = data.labelType
          // this.scanData.remarks = data.remarks
          // this.scanData.materialCode = data.materialCode

          _this.proList = [{
            productClassName: data.productClassName,
            productName: data.materialName,
            packScaleExpression: data.packScale,
            planNum: data.planNum,
            packScaleDesc: data.packScaleDesc,
            productCode: data.materialCode
          }];
        }
        _this.mloading = false;
      }).catch(function () {
        _this.mloading = false;
      });
    }
  }
};
exports.default = _default;