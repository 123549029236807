var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c("section", [
      _c("div", { staticClass: "page-section" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "imgList" },
          [
            _vm._l(_vm.dataList, function (item, index) {
              return _c(
                "el-row",
                {
                  key: index,
                  staticClass: "p15",
                  attrs: {
                    type: "flex",
                    align: "middle",
                    gutter: 20,
                    index: index,
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "",
                            "show-file-list": false,
                            "auto-upload": false,
                            limit: 1,
                            "on-change": _vm.handleAvatarSuccess.bind(null, {
                              index: index,
                            }),
                          },
                        },
                        [
                          item.images
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: item.images },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _vm._v("\n            链接：\n            "),
                      _c("el-input", {
                        staticClass: "link-input",
                        attrs: { placeholder: "http://" },
                        model: {
                          value: item.srcHref,
                          callback: function ($$v) {
                            _vm.$set(item, "srcHref", $$v)
                          },
                          expression: "item.srcHref",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _vm.hasAuth("deletebtn", _vm.$route.meta.authList)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "medium",
                                icon: "el-icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoDelete(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.sortUp(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-sort-up",
                            staticStyle: { "font-size": "20px" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      index < 4
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.sortDown(index)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-sort-down",
                                staticStyle: { "font-size": "20px" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "square-btn", on: { click: _vm.DoAdd } }, [
              _c("i", { staticClass: "el-icon-plus" }),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-group align-center" },
        [
          _vm.hasAuth("updatebtn", _vm.apis)
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    plain: "",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.DoSave },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page2,
            expression: "page2",
          },
        ],
      },
      [_vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phead" }, [
      _c("img", { attrs: { src: require("@/images/icon-2.jpg") } }),
      _vm._v(
        "\n        最多可配置5张图，建议尺寸：750*514，小于1M,格式只限jpg/png\n      "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }