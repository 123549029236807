"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _batchUpload = _interopRequireDefault(require("@/components/batchUpload"));
var _org = require("@/api/basic/org");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _product = require("../../../../api/basic/product");
var _salesArea = require("../../../../api/basic/salesArea");
var _dealer = require("@/api/basic/dealer");
var _channel = require("../../../../api/basic/channel");
var _checkUser = _interopRequireDefault(require("./checkUser.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Storehousefile",
  components: {
    Pagination: _Pagination.default,
    BatchUpload: _batchUpload.default,
    checkUser: _checkUser.default
  },
  data: function data() {
    return {
      currentNode: null,
      row: {},
      handleShow: false,
      props: {
        id: "pId",
        label: "name",
        // children: 'children'
        isLeaf: "leaf"
      },
      dialogVisible: false,
      dialogVisible1: false,
      title: "",
      daterange: [],
      uploadVisible: false,
      uploadData: [],
      packList: [],
      uploadForm: {
        uploadFile: ""
      },
      uploadRules: {},
      fileList: [],
      multipleSelection: [],
      total: 0,
      activeClass: "",
      searchForm: {
        current: 1,
        regionCode: "",
        regionName: "",
        regionStatus: 0,
        regionType: 0,
        size: 20
      },
      editForm: [],
      depList: [],
      ruleForm: {
        checkAddress: "",
        checkPhone: "",
        checkUserId: "",
        checkUserName: "",
        largeRegionDealerParamList: [{
          cityCode: "",
          cityName: "",
          provinceCode: "",
          provinceName: ""
        }],
        pid: 0,
        regionCode: "",
        regionName: "",
        regionStatus: 0
      },
      regionList: [],
      radio: "自定义",
      rules: {
        regionName: [{
          required: true,
          message: "大区名称不能为空，请维护",
          trigger: "submit"
        }],
        checkUserName: [{
          required: true,
          message: "所属部门不能为空，请维护",
          trigger: "submit"
        }],
        checkAddress: [{
          required: true,
          message: "地址不能为空，请维护",
          trigger: "submit"
        }],
        phone: [{
          pattern: /^0{0,1}(13[0-9]|145|147|149|15[5-9]|15[0-3]|166|17[1-3]|17[5-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          message: "手机号格式不对",
          trigger: "blur"
        }],
        provinceCode: [{
          required: true,
          message: "销售范围不能为空，请维护",
          trigger: "change"
        }]
      },
      showTable: false,
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: 800,
      tableData: [],
      page2: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      orgList: [],
      orgSelList: [],
      factoryList: [],
      normalList: [{
        pId: 0,
        regionName: "所有组织",
        leaf: true
      }],
      status: "",
      filterText: "",
      RegionData: {
        // provinceCode: "",
        // provinceName: "",
        // cityCode: "",
        // cityName: "",
      },
      ProvinceList: [],
      cityCode: "",
      provinceCode: "",
      CityList: [],
      saleArea: [],
      checkDialogVisible: false,
      user: {}
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 200 + "px";
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    // this.getList();
    // this.getOrgListAll();
  },
  methods: {
    changetab: function changetab() {
      this.$forceUpdate();
    },
    changeorg: function changeorg(orgId) {
      var _this = this;
      this.ruleForm.departId = "";
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    // getRegionList() {
    //   findAllSalesRegion({})
    //     .then(response => {
    //       if (response.data.code != 200) {
    //         return;
    //       } else {
    //         if (response.data.data != null) {
    //           this.regionList = response.data.data;
    //         } else {
    //           this.regionList = [];
    //         }
    //       }
    //     })
    //     .catch(res => {});
    // },
    getFactoryAll: function getFactoryAll(orgId) {
      var _this2 = this;
      (0, _factory.factoryInfoAllList)({
        type: 1,
        isEnable: 1,
        orgId: orgId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.factoryList = res.data.data;
          } else {
            _this2.factoryList = [];
          }
          sessionStorage.setItem("factoryList", JSON.stringify(_this2.factoryList));
        }
      });
    },
    openFactory: function openFactory() {
      if (!this.ruleForm.orgId) {
        this.$message.error("请先选择所属公司");
        return;
      }
      this.getFactoryAll(this.ruleForm.orgId);
    },
    getOrgListAll: function getOrgListAll() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: "",
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgList = response.data.data;
        }
      });
    },
    getOrgListSel: function getOrgListSel() {
      var that = this;
      that.orgSelList = [];
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgSelList = response.data.data;
        }
      });
    },
    getList: function getList(pId, node) {
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      this.listLoading = true;
      // var submitData = filterKeyNull(that.searchForm)
      // largeRegionlist(this.searchForm)
      //   .then(response => {
      //     if (response.data.code != 200) {
      //       that.$message.error(response.data.msg);
      //     } else {
      //       if (response.data.data != null) {
      //         that.total = parseInt(response.data.data.total)
      //         that.tableData = response.data.data.records
      //       } else {
      //         that.total = 0;
      //         that.tableData = [];
      //         // that.normalList = [];
      //       }
      //       // that.listLoading = false;
      //     }
      //   })
      //   .catch(res => {
      //     // that.listLoading = false;
      //   })
    },
    getNode: function getNode(data, node, el) {
      this.showTable = node.level == 3;
      this.lastNode = node;
      this.getList(node.data.pId, node);
      this.loadNode(node, function () {});
    },
    refreshLazyTree: function refreshLazyTree(node, children) {
      var theChildren = node.childNodes;
      theChildren.splice(0, theChildren.length);
      node.doCreateChildren(children);
    },
    loadNode: function loadNode(node, resolve) {
      var _this3 = this;
      this.currentNode = node;
      this.showTable = node.level == 3;
      this.lastNode = node;
      this.getList(node.data.pId, node);
      if (node.level == 0) {
        resolve(this.normalList);
      } else if (node.level == 1) {
        (0, _salesArea.selectLargeRegionByType)({
          regionType: 1
        }).then(function (res) {
          _this3.listLoading = false;
          _this3.tableData = res.data.data;
          resolve(res.data.data);
        });
      } else {
        (0, _salesArea.selectLargeRegionByParentId)({
          pId: node.data.pId
        }).then(function (res) {
          _this3.listLoading = false;
          _this3.tableData = res.data.data;
          resolve(res.data.data);
          if (_this3.showTable) {
            _this3.tableData = _this3.tableData.map(function (i) {
              if (i.largeRegionDealerVoList && i.largeRegionDealerVoList.length > 0) {
                i.area = i.largeRegionDealerVoList[0].provinceName + i.largeRegionDealerVoList[0].cityName;
              } else {
                i.area = '';
              }
              return i;
            });
          }
        });
      }
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      if (data.regionCode) {
        return data.regionCode.indexOf(value) !== -1;
      }
      return data.regionName.indexOf(value) !== -1;
    },
    resetForm: function resetForm() {
      this.searchForm = {};
      this.daterange = [];
      this.getList();
      this.handleFilter();
    },
    add: function add() {
      this.authId("addbtn", this.$route.meta.authList);
      this.title = "添加销售大区";
      this.status = "";
      this.getOrgListSel();
      // this.getFactoryAll()
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.status = '';
      this.authId('updatebtn', this.$route.meta.authList);
      if (row.regionType == 3) {
        this.title = '编辑市场';
        this.dialogVisible1 = true;
        this.newlistFun();
        this.ruleForm.pid = row.pId;
        this.ruleForm.regionName = row.regionName;
        this.ruleForm.regionCode = row.regionCode;
        this.ruleForm.regionStatus = row.regionStatus;
        if (row.largeRegionDealerVoList) {
          row.provinceName = row.largeRegionDealerVoList[0].provinceName;
          row.cityName = row.largeRegionDealerVoList[0].cityName;
          this.editForm = [row];
        } else {
          this.editForm = [];
        }
      } else {
        this.title = '编辑部门信息';
        this.dialogVisible = true;
        this.ruleForm.checkUserName = row.checkUserName && row.checkUserName + '' + row.checkPhone;
        this.ruleForm.checkAddress = row.checkAddress;
        this.ruleForm.regionName = row.regionName;
        this.ruleForm.regionCode = row.regionCode;
        this.ruleForm.regionStatus = row.regionStatus;
        this.ruleForm.pid = row.pId;
      }
    },
    del: function del(id) {
      var _this4 = this;
      this.$confirm("此操作将永久删除该数据,是否继续?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.authId("deletebtn", _this4.$route.meta.authList);
        (0, _storeHouse.storeHouseInfoDel)({
          isDelete: 2,
          pId: id
        }).then(function (res) {
          _this4.authId("searchbtn", _this4.$route.meta.authList);
          if (res.data.code != 200) {
            _this4.$message.error(res.data.msg);
          } else {
            _this4.$message({
              message: "操作成功",
              type: "success"
            });
            _this4.getList();
          }
        });
      }).catch(function () {
        _this4.authId("searchbtn", _this4.$route.meta.authList);
      });
    },
    updateStatus: function updateStatus(row, type) {
      var _this5 = this;
      var params = {
        orgId: row.orgId,
        pId: row.pId,
        regionCode: row.regionCode,
        regionName: row.regionName,
        regionStatus: null
      };
      if (type == "start") {
        params.regionStatus = 1;
      } else if (type == "stop") {
        params.regionStatus = 0;
      }
      (0, _salesArea.largeRegionUpdate)(params).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          _this5.$message({
            message: "操作成功",
            type: "success"
          });
          _this5.getList();
        }
      }).catch(function () {});
    },
    // 删除区域选择
    deleteRegionFun: function deleteRegionFun(id) {
      this.editForm = this.editForm.filter(function (item) {
        return item.id !== id;
      });
    },
    // 添加
    addRegionList: function addRegionList() {
      var _this6 = this;
      if (!this.RegionData.provinceCode) {
        this.$message.closeAll();
        this.$message.error("请选择区域");
        return;
      }
      var arr = this.editForm.filter(function (item) {
        if (_this6.RegionData.cityName) {
          if (item.provinceName === _this6.RegionData.provinceName && item.cityName === "") {
            return item.provinceName === _this6.RegionData.provinceName;
          } else if (item.provinceName + item.cityName === _this6.RegionData.provinceName + _this6.RegionData.cityName) {
            return item.provinceName + item.cityName === _this6.RegionData.provinceName + _this6.RegionData.cityName;
          }
        } else if (_this6.RegionData.provinceName) {
          if (item.provinceName === _this6.RegionData.provinceName && (item.cityName === "" || _this6.RegionData.cityName === "")) {
            return item.provinceName === _this6.RegionData.provinceName;
          }
        }
      });
      if (arr.length < 1) {
        this.RegionData.id = new Date().getTime();
        console.log(this.RegionData, 123);
        this.editForm.push(JSON.parse(JSON.stringify(this.RegionData)));
        return;
      }
      this.$message.closeAll();
      this.$message.error("地区重复或有包含关系");
    },
    newlistFun: function newlistFun() {
      var _this7 = this;
      (0, _dealer.newlist)({
        areaCode: "",
        areaName: "",
        areaType: "1",
        isDelete: 1,
        parentId: ""
      }).then(function (res) {
        if (res.data.code === 200) {
          _this7.ProvinceList = res.data.data;
        }
      });
    },
    nextAreaFun: function nextAreaFun(parentId) {
      var _this8 = this;
      (0, _dealer.nextArea)({
        isDelete: "",
        parentId: parentId
      }).then(function (res) {
        if (res.data.code === 200) {
          _this8.CityList = res.data.data;
        }
      });
    },
    // 省份
    ProvinceFun: function ProvinceFun(val) {
      this.ruleForm.cityCode = '';
      if (val) {
        var arr = val.split(",");
        this.nextAreaFun(arr[1]);
        this.RegionData.provinceName = arr[0];
        this.RegionData.provinceCode = arr[1];
      } else {
        this.RegionData.provinceName = "";
        this.RegionData.provinceCode = "";
      }
      this.RegionData.cityName = "";
      this.RegionData.cityCode = "";
      this.CityList = [];
    },
    // 城市
    CityFun: function CityFun(val) {
      this.$forceUpdate();
      if (val) {
        var arr = val.split(",");
        this.RegionData.cityName = arr[0];
        this.RegionData.cityCode = arr[1];
      } else {
        this.RegionData.cityName = "";
        this.RegionData.cityCode = "";
      }
    },
    start: function start() {},
    stop: function stop() {},
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.authId("searchbtn", this.$route.meta.authList);
      this.$refs.ruleForm.resetFields();
    },
    submitForm: function submitForm() {
      var _this9 = this;
      if (this.editForm.length > 0) {
        this.ruleForm.editForm = [];
        this.editForm.map(function (item) {
          _this9.ruleForm.editForm.push({
            provinceCode: item.provinceCode,
            cityCode: item.cityCode
          });
        });
      }
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          _this9.btnLoading = true;
          var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this9.ruleForm)));
          console.log(_this9.ruleForm, 456);
          // submitData.pid = this.ruleForm.parentId;
          delete submitData.pId;
          delete submitData.parentId;
          submitData.largeRegionDealerParamList = submitData.editForm;
          delete submitData.editForm;
          delete submitData.provinceCode;
          delete submitData.cityCode;
          delete submitData.checkUserName;
          console.log(submitData, 123);
          if (_this9.ruleForm.pId) {
            (0, _salesArea.largeRegionUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this9.$message.error(res.data.msg);
              } else {
                _this9.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this9.handleClose();
                _this9.loadNode(_this9.currentNode, function () {});
              }
              _this9.btnLoading = false;
            }).catch(function () {
              _this9.btnLoading = false;
            });
          } else {
            (0, _salesArea.largeRegionUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this9.$message.error(res.data.msg);
              } else {
                _this9.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this9.handleClose();
                _this9.loadNode(_this9.currentNode, function () {});
              }
              _this9.btnLoading = false;
            }).catch(function () {
              _this9.btnLoading = false;
            });
          }
        }
      });
    },
    // setStatus(authName) {
    //   if (!this.multipleSelection.length) {
    //     return this.$message.error("请至少选中一条数据");
    //   }
    //   const params = {
    //     idList: [],
    //     regionStatus: null
    //   };
    //   console.log(this.multipleSelection, "vvv");
    //   if (this.multipleSelection.length) {
    //     if (authName == "allstart") {
    //       params.regionStatus = 1;
    //     } else if (authName == "allstop") {
    //       params.regionStatus = 0;
    //     }
    //     this.multipleSelection.map(i => {
    //       params.idList.push(i.pId);
    //     });
    //     batchUpdateStatus(params).then(res => {
    //       this.authId("searchbtn", this.$route.meta.authList);
    //       if (res.data.code != 200) {
    //         this.$message.error(res.data.msg);
    //       } else {
    //         this.$message({
    //           message: "操作成功",
    //           type: "success"
    //         });
    //         this.getList();
    //       }
    //     });
    //   }
    // },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    upload: function upload() {
      this.$refs.batchUpload.upload();
    },
    syncData: function syncData() {},
    handleFilter: function handleFilter() {
      this.$refs.tree.filter(this.filterText);
    },
    scan: function scan(data) {
      var id = data.pId;
      this.activeClass = id;
      if (id == 0) {
        this.resetForm();
        this.ruleForm.parentId = "";
      } else {
        this.type = data.type;
        this.searchForm.type = "";
        this.searchForm.parentId = id;
        this.ruleForm.parentId = id;
        // this.getList(id);
      }
    },
    openCheck: function openCheck() {
      this.checkDialogVisible = true;
    },
    // 设置活动方案
    changeUser: function changeUser(a) {
      console.log(a, 232323);
      this.ruleForm.checkUserId = a.checkUserId;
      this.ruleForm.checkPhone = a.checkPhone;
      this.ruleForm.checkUserName = a.checkUser + '' + a.checkPhone;
    }
  }
};
exports.default = _default;