"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _product = require("@/api/basic/product");
var _basic = require("@/api/fake_expel/basic");
var _index = require("@/api/fake_expel/brand/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const defaultSeachForm = {
//   display_time: undefined, // 前台展示时间
//   chosedate: []
// }
var _default = {
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      title: '',
      vurlCode: '',
      supplierList: null,
      PackScale: null,
      loading: false,
      showSelectBrand: null,
      brandData: [],
      brand: null,
      optBrand: '',
      SelectBrand: [{
        pid: -1,
        brandName: '全部'
      }],
      brandList: [],
      proList: [],
      formData: {
        pageNo: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        ppwlList: []
      },
      shortcuts: []
      // pickerOptions2: {
      //   shortcuts: [
      //     {
      //       text: '最近一周',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //         picker.$emit('pick', [start, end])
      //       }
      //     },
      //     {
      //       text: '最近一个月',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      //         picker.$emit('pick', [start, end])
      //       }
      //     },
      //     {
      //       text: '最近三个月',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      //         picker.$emit('pick', [start, end])
      //       }
      //     }
      //   ]
      // }
    };
  },

  computed: {
    displayTime: {
      get: function get() {
        return +new Date(this.defaultSeachForm.display_time);
      },
      set: function set(val) {
        this.defaultSeachForm.display_time = new Date(val);
      }
    },
    chosedate: {
      get: function get() {
        var e = this.shortcuts;
        if (e == null || e == '') {
          e = ['', ''];
        }
        return [new Date(e[0]), new Date(e[1])];
      },
      set: function set(val) {
        if (val == null || val == '') {
          val = ['', ''];
        }
        this.shortcuts = val;
      }
    }
  },
  created: function created() {
    var time = (0, _basic.getSysSearchDefDate)(7);
    this.formData.startTime = time[0].replace(/-/g, '');
    this.formData.endTime = time[1].replace(/-/g, '');
    this.authId('searchbtn', this.$route.meta.authList);
    this.getBrand();
  },
  methods: {
    getBrand: function getBrand() {
      var _this = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this.brandList = res.data.data;
          } else {
            _this.brandList = [];
          }
        }
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        var time = (0, _basic.getSysSearchDefDate)(7);
        this.formData.startTime = time[0].replace(/-/g, '');
        this.formData.endTime = time[1].replace(/-/g, '');
        this.$refs.searchform.resetFields();
        this.optBrand = '';
        this.brandData = '';
      }
      // this.formData.startTime = this.shortcuts[0].replace(/-/g, '')
      // this.formData.endTime = this.shortcuts[1].replace(/-/g, '')
      var lists = [];
      if ((this.optBrand == '' || this.optBrand == null) && this.brandData != '') {
        for (var i in this.proList) {
          lists.push(this.proList[i].materialCode);
        }
      } else {
        for (var obj in this.optBrand) {
          lists.push(this.optBrand[obj]);
        }
      }
      if (this.brandData == '-1') {
        this.optBrand = '';
        lists = [];
      }
      this.formData.pageNo = 1;
      this.formData.ppwlList = lists;
      this.$parent.searchList(this.formData);
    },
    getqueryBrand: function getqueryBrand() {
      var _this2 = this;
      this.loading = true;
      (0, _index.queryBrand)({}).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.loading = false;
        for (var i in data.data) {
          _this2.SelectBrand.push({
            pid: data.data[i].pid,
            brandName: data.data[i].brandName
          });
        }
      });
    },
    getqueryMaterial: function getqueryMaterial() {
      var _this3 = this;
      this.loading = true;
      this.selProList = [];
      if (this.brandData == '-1' || this.brandData == '' || this.brandData == null) {
        this.proList = [];
        return false;
      }
      (0, _index.queryMaterial)({
        pId: this.brandData
      }).then(function (response) {
        var data = response.data;
        _this3.proList = [];
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return true;
        }
        _this3.loading = false;
        if (data.data.length <= 0) {
          _this3.$message.error('当前选择品牌下不存在产品信息');
          return true;
        }
        _this3.proList = data.data;
      }).catch(function () {
        _this3.proList = [];
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;