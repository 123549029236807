"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    seasonValue: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      showSeason: false,
      // 是否显示选择季度面板
      year: new Date().getFullYear(),
      // 默认当前年
      // seasonValue: '', // input中显示的内容
      time: {
        stTime: '',
        edTime: '',
        seasonValue: ''
      }
    };
  },
  created: function created() {},
  methods: {
    prev: function prev() {
      this.year = +this.year - 1;
    },
    next: function next() {
      this.year = +this.year + 1;
    },
    selectSeason: function selectSeason(quarter) {
      this.seasonValue = this.year.toString() + '-' + quarter.toString();
      this.time.seasonValue = this.year.toString() + '-' + quarter.toString();
      this.showSeason = false;
      switch (quarter) {
        case '第一季度':
          this.time.stTime = (this.year - 1).toString() + '-12-21';
          this.time.edTime = this.year.toString() + '-03-20';
          break;
        case '第二季度':
          this.time.stTime = this.year.toString() + '-03-21';
          this.time.edTime = this.year.toString() + '-06-20';
          break;
        case '第三季度':
          this.time.stTime = this.year.toString() + '-06-21';
          this.time.edTime = this.year.toString() + '-09-20';
          break;
        case '第四季度':
          this.time.stTime = this.year.toString() + '-09-21';
          this.time.edTime = this.year.toString() + '-12-20';
          break;
      }
      this.$emit('getQuarter', this.time); // 传值给父组件
    }
  }
};
exports.default = _default;