var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "出库单信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1300px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "stepArea" },
        [
          _c("div", { staticClass: "billTitle" }, [
            _c("img", { attrs: { src: require("@/images/topimg.png") } }),
            _vm._v(
              "\n      物流单号 " +
                _vm._s(_vm.temp.tradeBillAllVO.billNo) +
                "\n      "
            ),
            _c(
              "span",
              {
                staticClass: "billType",
                class: _vm._f("typeClass")(_vm.temp.tradeBillAllVO.billStatus),
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("TypeDataCom")(
                      _vm.temp.tradeBillAllVO.billStatus,
                      "djzt"
                    )
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "topRight" }, [
              _c("img", { attrs: { src: require("@/images/avatar.png") } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.temp.tradeBillAllVO.createUser))]),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(
                  _vm._s(_vm._f("textDate")(_vm.temp.tradeBillAllVO.createTime))
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-steps",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { active: _vm.active, "finish-status": "success" },
            },
            [
              _c("el-step", { attrs: { title: "创建" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "下载" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "扫码" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "结单" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { title: "基本信息", column: 3, border: "" },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "单据类型:" } }, [
            _vm._v(
              _vm._s(
                _vm._f("djlxText")(_vm.temp.tradeBillAllVO.outSaleBillType)
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            {
              attrs: {
                label: _vm.temp.tradeBillAllVO.NCNO ? "NC单号" : "物流单号",
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.temp.tradeBillAllVO.NCNO
                    ? _vm.temp.tradeBillAllVO.NCNO
                    : _vm.temp.tradeBillAllVO.billNo
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "创建人:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.createUser)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "创建时间:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.createTime)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "发货库房:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.outStoreName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "主户头编码:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.accountCode)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "主户头名称:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.accountName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "联系电话:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.telePhone)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "所属大区:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.regionName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "所属经销商编码:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.dealerCode)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "所属经销商名称:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.dealerName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "收货地址:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.receiptAddress)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "NC单据日期:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.billDate)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "允许多次下载:" } }, [
            _vm._v(
              _vm._s(_vm.temp.tradeBillAllVO.pdaMoreDown == 1 ? "是" : "否")
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "状态:" } },
            [
              _c(
                "el-tag",
                {
                  attrs: {
                    type: _vm.formattedDjzt(_vm.temp.tradeBillAllVO.billStatus),
                  },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.formattedDjzt(_vm.temp.tradeBillAllVO.billStatus)
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "是否启用:" } },
            [
              _vm.temp.tradeBillAllVO.isEnable == 1
                ? _c("el-tag", { attrs: { type: "success" } }, [_vm._v("是")])
                : _vm._e(),
              _vm._v(" "),
              _vm.temp.tradeBillAllVO.isEnable == 0
                ? _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("否")])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "单据备注:" } }, [
            _vm._v(_vm._s(_vm.temp.tradeBillAllVO.remark)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "itemTitle",
          staticStyle: {
            border: "1px solid #e8e8e8",
            padding: "10px",
            "margin-bottom": "-1px",
            background: "#ececec",
          },
        },
        [
          _vm._v("\n    产品信息\n    "),
          _c("img", { attrs: { src: require("@/images/pro.png") } }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            size: "mini",
            data: _vm.temp.tradeProductVOS,
            fit: "",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: _vm.tableConfig.align,
              width: "80",
              label: "序号",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品品牌",
              prop: "prodBrandName",
              align: _vm.tableConfig.align,
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品分类",
              prop: "prodClassName",
              align: _vm.tableConfig.align,
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              prop: "productCode",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "productName",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "定制产品",
              prop: "productType",
              "min-width": "80",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.productType == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("是"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.productType == 0
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("否"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "NC产品名称",
              prop: "ncproductName",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "包装比例",
              prop: "packRadioDesc",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划箱",
              prop: "planScanNum",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.planScanNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划瓶",
              prop: "planScanAtoNum",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.planScanAtoNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫箱",
              prop: "realScanNum",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { disabled: !row.realScanNum, type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫总瓶数",
              prop: "realScanAtoNum",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { disabled: !row.realScanAtoNum, type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanAtoNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫零瓶数",
              prop: "realScanBottleNum",
              align: _vm.tableConfig.align,
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: {
                          disabled: !row.realScanBottleNum,
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanBottleNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "发货方式",
              prop: "sendStyle",
              "min-width": "100",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          row.sendStyle == 1
                            ? "扫码发货"
                            : row.sendStyle == 2
                            ? "自动发货"
                            : "未知"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "调入数量",
              prop: "transInNum",
              "min-width": "100",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { disabled: !row.transInNum, type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.transInNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "发货总量",
              prop: "sendTotalNum",
              "min-width": "100",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: _vm.tableConfig.align,
              width: "120",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled:
                            !row.realScanAtoNum &&
                            !row.transInNum &&
                            !row.sendTotalNum,
                          loading: _vm.codeExportLoading,
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.codeExport(row)
                          },
                        },
                      },
                      [_vm._v("箱码明细导出")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.temp.tradeBillAllVO.outSaleBillType == 4
        ? _c(
            "div",
            [
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [
                  _vm._v("\n      异常数码\n      "),
                  _c("img", { attrs: { src: require("@/images/pro.png") } }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    size: "mini",
                    data: _vm.temp.errorMarkCodeList,
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "80",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      prop: "markCode",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "错误原因",
                      prop: "errorReason",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          staticClass: "detailCollapse",
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "操作记录", name: "1" } },
            [
              _c("template", { slot: "title" }, [_vm._v("操作记录")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.temp.tradeOperateLogDTOS,
                    fit: "",
                    "highlight-current-row": "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      width: "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作类型",
                      prop: "operateType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("operateType")(row.operateType))
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作描述",
                      prop: "operateDesc",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      prop: "operateUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作备注",
                      prop: "remark",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "120",
                      prop: "seqNo",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "packLevel",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("packLevel")(scope.row.packLevel))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: _vm.tableConfig.align,
                      prop: "markCode",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  !_vm.showpage
                    ? _c("el-table-column", {
                        attrs: {
                          label: "错误原因",
                          align: _vm.tableConfig.align,
                          prop: "errorMsg ",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.errorMsg)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          833577223
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showpage
                ? _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.codetotal > 0,
                        expression: "codetotal > 0",
                      },
                    ],
                    attrs: {
                      layout: "total,sizes,prev, pager, next, jumper",
                      total: _vm.codetotal,
                      page: _vm.detailForm.current,
                      limit: _vm.detailForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.detailForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.detailForm, "size", $event)
                      },
                      pagination: _vm.getCodeList,
                    },
                  })
                : _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.codetotal > 0,
                        expression: "codetotal > 0",
                      },
                    ],
                    attrs: {
                      layout: "total,sizes,prev, pager, next, jumper",
                      total: _vm.codetotal,
                      page: _vm.detailForm2.pageNo,
                      limit: _vm.detailForm.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.detailForm2, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.detailForm, "pageSize", $event)
                      },
                      pagination: _vm.getCodeList,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }