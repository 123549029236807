var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image: url('" + _vm.list.background + "');",
      },
      [
        _c("el-image", {
          staticClass: "title",
          attrs: { lazy: "", src: _vm.list.title },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "sudoku-container",
              style: "background-image: url('" + _vm.list.sudokuImg + "');",
            },
            _vm._l(_vm.list.squares, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "sudoku-item",
                  class: "sudoku-item-" + (index + 1),
                  style:
                    "background-image: url('" +
                    (index !== 4 ? _vm.list.squareItemsImg : "") +
                    "');",
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-image", {
                        attrs: { lazy: "", fit: "fill", src: item.backImg },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }