"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.to-string");
var _word = require("@/api/prizeSetting/word");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Added',
  props: {
    prizeId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var checkWord = function checkWord(rule, value, callback) {
      if (value.toString().charCodeAt(0) > 255) {
        callback();
      } else {
        callback(new Error('请输入一位中文字符'));
      }
    };
    return {
      form: {
        prizeName: '',
        explain: '',
        word: '',
        rangeTime: ''
      },
      rules: {
        prizeName: [{
          required: true,
          message: '请输入奖品名称',
          trigger: 'blur'
        }],
        explain: [{
          required: false,
          trigger: 'blur'
        }],
        word: [{
          required: true,
          trigger: 'blur',
          validator: checkWord
        }],
        rangeTime: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }]
      },
      btnLoading: false
    };
  },
  computed: {
    isDisable: function isDisable() {
      return !!(this.title == '详情' || this.isDisabled);
    }
  },
  mounted: function mounted() {
    if (this.prizeId) {
      this.getDetail();
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      (0, _word.getPrize_Word_Detail)({
        prizeId: this.prizeId
      }).then(function (res) {
        if (res.data.data.code || res.data.data.code == 0) {
          _this.$message.error(res.data.data.msg);
          return;
        } else {
          var _res$data$data = res.data.data,
            prizeName = _res$data$data.prizeName,
            explain = _res$data$data.explain,
            word = _res$data$data.word,
            wordEndTimeStr = _res$data$data.wordEndTimeStr,
            wordStartTimeStr = _res$data$data.wordStartTimeStr;
          _this.form = {
            prizeName: prizeName,
            explain: explain,
            word: word,
            rangeTime: [wordStartTimeStr, wordEndTimeStr]
          };
        }
      });
    },
    handleSave: function handleSave() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var sumbitData = (0, _objectSpread2.default)({}, _this2.form);
          if (_this2.prizeId) sumbitData.prizeId = _this2.prizeId;
          if (_this2.form.rangeTime && _this2.form.rangeTime.length) {
            sumbitData.wordStartTime = _this2.form.rangeTime[0];
            sumbitData.wordEndTime = _this2.form.rangeTime[1];
          }
          (0, _word.savePrize_Word)(sumbitData).then(function (res) {
            if (res.data.data.code || res.data.data.code == 0) {
              _this2.btnLoading = false;
              return _this2.$message.error(res.data.data.msg);
            } else {
              _this2.btnLoading = false;
              var messageTip = _this2.prizeId ? '编辑' : '添加';
              _this2.$message.success(messageTip);
              _this2.$emit('handleCancel');
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$emit('handleCancel');
    }
  }
};
exports.default = _default;