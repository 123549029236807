var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: _vm.title,
        visible: _vm.visible,
        "before-close": _vm.selCancel,
        width: "900px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c(
                "el-col",
                { staticClass: "page-section" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.searchForm,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "keyword" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "编码、名称、联系人、手机号",
                                        prop: "keyWord",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.searchForm.keyword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "keyword",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.keyword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formConfig.btnFollow
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.searchForm.current = 1
                                                  _vm.getList()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.resetForm },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          ref: "multipleTable",
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.tableData,
                            height: "400px",
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "row-key": "pId",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              align: _vm.tableConfig.align,
                              width: "80",
                              label: "序号",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商编码",
                              prop: "channelCode",
                              align: _vm.tableConfig.align,
                              width: "150",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商名称",
                              prop: "channelName",
                              "min-width": "200",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商分类",
                              prop: "channelClassName",
                              width: "100",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商等级",
                              prop: "channelGradeName",
                              width: "100",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              "min-width": "100",
                              "class-name": "small-padding fixed-width",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submit(row)
                                          },
                                        },
                                      },
                                      [_vm._v("选择")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total,
                          page: _vm.searchForm.current,
                          limit: _vm.searchForm.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.searchForm, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.searchForm, "size", $event)
                          },
                          pagination: _vm.getList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }