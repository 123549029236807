var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            headers: _vm.myHeaders,
            action: _vm.uploadUrl,
            "file-list": _vm.fileList,
            "list-type": "picture",
            accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
            "on-error": _vm.handleError,
            "on-change": _vm.selectImg,
            "on-remove": _vm.handleRemove,
            "before-upload": _vm.beforeAvatarUpload,
            "on-success": _vm.handleAvatarSuccess,
            "on-preview": _vm.handlePictureCardPreview,
            "before-remove": _vm.checkType,
            name: "file",
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("请上传图片，图片大小不超过10m")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value
        ? _c(
            "div",
            {
              staticClass: "wrap el-upload-list__item-actions",
              staticStyle: {
                width: "200px",
                height: "200px",
                position: "relative",
              },
            },
            [
              _c("img", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.value, alt: "" },
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-zoom-in",
                staticStyle: {
                  position: "absolute",
                  left: "30%",
                  top: "50%",
                  "font-size": "25px",
                  display: "none",
                },
                on: { click: _vm.handlePreview },
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-delete",
                staticStyle: {
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  "font-size": "25px",
                  display: "none",
                },
                on: { click: _vm.handleRemoveItem },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "img",
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: "0%",
                  top: "0%",
                  transition: "all 0.3",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { attrs: { width: "100%", src: _vm.value, alt: "" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }