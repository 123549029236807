"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _outstock = require("@/api/chanelmange/outstock");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Tgdan',
  components: {
    Pagination: _Pagination.default,
    productChose: _productSelectDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  props: {
    no: {
      type: [Number, String],
      default: ''
    },
    orderId: {
      type: [Number, String],
      default: ''
    },
    scanVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      active: 0,
      sigleProduct: {
        productName: '',
        productCode: ''
      },
      productStyle: '',
      productShow: false,
      baseData: {},
      checklist: [],
      productlist: [],
      remark: '',
      fileUrls: [],
      // orderId: '',
      codeData: [],
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      },
      total2: 0,
      scanCodeVisible: false
    };
  },
  mounted: function mounted() {
    this.DoScan();
  },
  methods: {
    // 获取产品名称
    choseProName: function choseProName(val) {
      this.productStyle = val;
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      var that = this;
      if (that.productStyle == 'search') {
        that.listQuery.productId = val.pId;
        that.listQuery.productStr = val.productName;
        that.sigleProduct.productCode = val.productCode;
        that.sigleProduct.productName = val.productName;
      }
      if (that.productStyle == 'codeScan') {
        that.codeParam.productName = val.productName;
        that.codeParam.productId = val.pId;
      }
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      var param = {};
      param.billNo = this.no;
      // that.orderId = data.pid
      (0, _outstock.getQtDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.base != null) {
            that.baseData = res.data.data.base;
            that.remark = res.data.data.base.remark;
            if (res.data.data.base.fileUrls != null) {
              that.fileUrls = res.data.data.base.fileUrls.split(',');
            }
            switch (res.data.data.base.orderStatus * 1) {
              case 1:
                that.active = 1;
                break;
              case 4:
                that.active = 2;
                break;
              case 5:
                that.active = 3;
                break;
            }
          }
          if (res.data.data.product != null) {
            that.productlist = res.data.data.product;
          }
        } else {
          that.baseData = {};
          that.checklist = {};
          that.productlist = {};
          that.$message.error('获取详情失败，请稍后再试！');
        }
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetails();
    },
    codeClose: function codeClose() {
      this.scanCodeVisible = false;
      this.codeParam = {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      };
      this.codeData = [];
      this.total2 = 0;
    },
    getCodeDetails: function getCodeDetails() {
      var that = this;
      this.codeParam.id = that.orderId;
      var param = JSON.parse(JSON.stringify(this.codeParam));
      delete param.productName;
      (0, _outstock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    searchReset: function searchReset() {
      // this.codeParam.id = ''
      this.codeParam.code = '';
      this.codeParam.productId = '';
      this.codeParam.productName = '';
      this.codeParam.isError = '';
      this.codeParam.pageNo = 1;
      this.getCodeDetails();
    }
  }
};
exports.default = _default;