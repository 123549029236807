var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "协议名称", prop: "agreementName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.agreementName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "agreementName", $$v)
                  },
                  expression: "ruleForm.agreementName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "协议类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("登录协议"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "签署场景", prop: "signScenario" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.signScenario,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "signScenario", $$v)
                    },
                    expression: "ruleForm.signScenario",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("终端登录"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "签署次数", prop: "signNum" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.signNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "signNum", $$v)
                    },
                    expression: "ruleForm.signNum",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("每次更新重新签署"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "协议备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "登录时提示", prop: "loginNotify" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-value": "1",
                  "inactive-value": "0",
                },
                model: {
                  value: _vm.ruleForm.loginNotify,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "loginNotify", $$v)
                  },
                  expression: "ruleForm.loginNotify",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "协议正文", prop: "content" } },
            [
              _c("tiny-mce", {
                attrs: { "tinymce-height": 320 },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确认")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }