"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _supplier = require("@/api/basic/supplier");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Supplierfile',
  filters: {
    supplierType: function supplierType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '标签厂',
        2: '制盖厂'
      };
      return statusMap[status];
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      handleShow: false,
      dialogVisible: false,
      title: '',
      daterange: [],
      total: 0,
      searchForm: {
        current: 1,
        endTime: '',
        size: 20,
        startTime: '',
        supplierCode: '',
        supplierName: '',
        supplierType: ''
      },
      ruleForm: {
        personLiable: '',
        personLiablePhone: '',
        remarks: '',
        supplierName: '',
        supplierType: ''
      },
      rules: {
        supplierName: [{
          required: true,
          message: '供应商名称不能为空，请维护',
          trigger: 'change'
        }],
        supplierType: [{
          required: true,
          message: '供应商类型不能为空，请维护',
          trigger: 'change'
        }],
        personLiable: [
          // {
          //   required: true,
          //   message: '负责人不能为空，请维护',
          //   trigger: 'change'
          // }
        ],
        personLiablePhone: [
        // {
        //   required: true,
        //   message: '负责人电话不能为空，请维护',
        //   trigger: 'change'
        // },
        {
          pattern: /^0{0,1}(13[0-9]|145|147|149|15[5-9]|15[0-3]|166|17[1-3]|17[5-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          message: '负责人电话格式不对',
          trigger: 'blur'
        }]
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      page2: false,
      options: [],
      value: [],
      list: [],
      loading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      // submitData.startTime = that.daterange ? that.daterange[0] : ''
      // submitData.endTime = that.daterange ? that.daterange[1] : ''
      (0, _supplier.packSupplierInfoList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        endTime: '',
        size: 20,
        startTime: '',
        supplierCode: '',
        supplierName: '',
        supplierType: ''
      };
      this.daterange = [];
      this.getList();
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '添加供应商';
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.authId('updatebtn', this.$route.meta.authList);
      this.title = '编辑供应商';
      this.ruleForm.pId = row.pId;
      this.ruleForm.isDelete = row.isDelete;
      this.ruleForm.personLiable = row.personLiable;
      this.ruleForm.personLiablePhone = row.personLiablePhone;
      this.ruleForm.remarks = row.remarks;
      this.ruleForm.supplierCode = row.supplierCode;
      this.ruleForm.supplierName = row.supplierName;
      this.ruleForm.supplierType = Number(row.supplierType);
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        isDelete: 1,
        personLiable: '',
        personLiablePhone: '',
        remarks: '',
        supplierName: '',
        supplierType: ''
      };
      this.$refs['ruleForm'].resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this.btnLoading = true;
          var submitData = (0, _utils.filterKeyNull)(_this.ruleForm);
          if (_this.ruleForm.pId) {
            (0, _supplier.packSupplierInfoUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
              _this.btnLoading = false;
            }).catch(function () {
              _this.btnLoading = false;
            });
          } else {
            (0, _supplier.packSupplierInfoInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
              _this.btnLoading = false;
            }).catch(function () {
              _this.btnLoading = false;
            });
          }
        }
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('此操作将永久删除该数据,是否继续?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('deletebtn', _this2.$route.meta.authList);
        (0, _supplier.packSupplierInfoDel)({
          brandStatus: 2,
          pId: id
        }).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          if (res.data.code != 200) {
            _this2.$message.error(res.data.msg);
          } else {
            _this2.$message({
              message: '操作成功',
              type: 'success'
            });
            _this2.getList();
          }
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;