import { render, staticRenderFns } from "./label_group_manage_con.vue?vue&type=template&id=4f24df96&"
import script from "./label_group_manage_con.vue?vue&type=script&lang=js&"
export * from "./label_group_manage_con.vue?vue&type=script&lang=js&"
import style0 from "./label_group_manage_con.vue?vue&type=style&index=0&id=4f24df96&rel=stylesheet%2Fscss&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f24df96')) {
      api.createRecord('4f24df96', component.options)
    } else {
      api.reload('4f24df96', component.options)
    }
    module.hot.accept("./label_group_manage_con.vue?vue&type=template&id=4f24df96&", function () {
      api.rerender('4f24df96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mark/coding_service/coding_apply_mange/components/label_group_manage_con.vue"
export default component.exports