var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "label-suffix": ":",
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "name", $$v)
                                  },
                                  expression: "searchForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "phone", $$v)
                                  },
                                  expression: "searchForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                _vm._l(_vm.dispenseList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchFun(1)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchFun(0)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                height: _vm.tabHeight,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "姓名",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "roleName",
                  label: "所属角色",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "手机号",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "channelName",
                  label: "门店名称",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == "0"
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "3"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("冻结"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "4"
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("删除"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "province",
                  label: "省/市",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "city",
                  label: "市/区",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "district",
                  label: "区/县",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "详细地址",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "280",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editFun("1", scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editFun("2", scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.status == 2 || scope.row.status == 4
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchFun("1", scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchFun("2", scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("禁用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchFun("3", scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("冻结")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchFun("4", scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("解冻")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status != 4
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchFun("0", scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              "auto-scroll": false,
              page: _vm.searchForm.pageNo,
              limit: _vm.searchForm.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "pageSize", $event)
              },
              pagination: _vm.pagingFun,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核",
            center: "",
            visible: _vm.dialogVisible,
            width: "30%",
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "center",
                display: "flex",
                padding: "20px 0",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.auditFun(false)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.auditFun(true)
                    },
                  },
                },
                [_vm._v("通过")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.roleForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.lookdisabled, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "userName", $$v)
                      },
                      expression: "roleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "phone", $$v)
                      },
                      expression: "roleForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "address2" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.address2,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "address2", $$v)
                      },
                      expression: "roleForm.address2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.lookdisabled, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "address", $$v)
                      },
                      expression: "roleForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门店名称", prop: "channelName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.lookdisabled, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "channelName", $$v)
                      },
                      expression: "roleForm.channelName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "营销角色", prop: "roleName", required: "" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "roleName", $$v)
                      },
                      expression: "roleForm.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              !_vm.lookdisabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.btnLoading,
                        disabled: _vm.btnLoading,
                      },
                      on: { click: _vm.submitFun },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }