var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productCode",
                                    label: "产品编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.productCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "productCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.productCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productName",
                                    label: "产品名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelCode",
                                    label: "渠道编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.channelCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "channelCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.channelCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelName",
                                    label: "渠道名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.channelName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "channelName",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.channelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "异常预警类型",
                                    prop: "ycyjlx",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择异常预警类型",
                                      },
                                      model: {
                                        value: _vm.listQuery.ycyjlx,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "ycyjlx", $$v)
                                        },
                                        expression: "listQuery.ycyjlx",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: -1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "重复查询", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "数码不存在",
                                          value: 2,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "数码未激活",
                                          value: 3,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "未出库扫码",
                                          value: 4,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "归属地不符",
                                          value: 7,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "productBrandId" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productBrandId",
                                    label: "产品品牌:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.productBrandId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "productBrandId",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.productBrandId",
                                      },
                                    },
                                    _vm._l(_vm.brandList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.brandName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "userAccount",
                                    label: "稽查用户:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.userAccount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "userAccount",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.userAccount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "userName",
                                        label: "用户姓名:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.listQuery.userName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "userName",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.userName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "status",
                                        label: "稽查结果:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.status",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "正常", value: 1 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "疑似窜货",
                                              value: 2,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "地理信息无法匹配",
                                              value: 3,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "没有权限",
                                              value: 4,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "startTime",
                                        label: "稽查时间:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression: "showCol||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "inspectionCode",
                                    label: "查询编号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.listQuery.inspectionCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "inspectionCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.inspectionCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.DoReset },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c("el-divider", { staticClass: "btnDivider" })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.downLoadBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.downLoadBtn.icon,
                                    loading: _vm.excelOut_loading,
                                  },
                                  on: { click: _vm.excelOut },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "查询编号",
                          "min-width": "160",
                          prop: "inspectionCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "数码",
                          prop: "code",
                          "min-width": "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "渠道类型",
                          prop: "channelType",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("typeName")(row.channelType))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3642663394
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "渠道编码",
                          prop: "channelCode",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "渠道名称",
                          prop: "channelName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属组织",
                          prop: "channelOrg",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "稽查用户",
                          prop: "userAccount",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "用户姓名",
                          prop: "userName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "稽查时间",
                          prop: "createTime",
                          "min-width": "170",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.createTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2280372158
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "稽查结果",
                          prop: "status",
                          "min-width": "160",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("statusName")(row.status))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          561970881
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "80",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.detail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1278982913
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "稽查日志-详情",
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "1000px!important",
            "before-close": _vm.showClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogloading,
                  expression: "dialogloading",
                },
              ],
            },
            [
              _c("div", { staticClass: "stepArea" }, [
                _c("div", { staticClass: "billTitle" }, [
                  _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                  _vm._v(
                    "\n          查询编号 " +
                      _vm._s(_vm.scanData.inspectionCode) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "稽查信息", column: 3, border: "" },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "数码:" } }, [
                    _vm._v(_vm._s(_vm.scanData.code)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "上报时间:" } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("textDate")(_vm.scanData.updateTime))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "渠道类型:" } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("typeName")(_vm.scanData.channelType))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "渠道编码:" } },
                    [_vm._v(_vm._s(_vm.scanData.channelCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "渠道名称:" } },
                    [_vm._v(_vm._s(_vm.scanData.channelName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "稽查用户:" } },
                    [_vm._v(_vm._s(_vm.scanData.userAccount))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "用户姓名:" } },
                    [_vm._v(_vm._s(_vm.scanData.userName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "稽查时间:" } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("textDate")(_vm.scanData.createTime))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "稽查位置:" } },
                    [_vm._v(_vm._s(_vm.scanData.inspectionAddress))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("备注")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                _vm._v(_vm._s(_vm.scanData.remark)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("图片附件")]
              ),
              _vm._v(" "),
              _vm.uploadImgs.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.uploadImgs, function (item, index) {
                      return _c("el-image", {
                        key: index,
                        staticStyle: {
                          width: "80px",
                          height: "80px",
                          margin: "0 10px",
                        },
                        attrs: {
                          src: item,
                          "z-index": 2099,
                          "preview-src-list": _vm.uploadImgs,
                        },
                      })
                    }),
                    1
                  )
                : _c("div", [_vm._v("无")]),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-collapse",
                {
                  staticClass: "detailCollapse",
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [_vm._v("物流信息")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "600px",
                            "overflow-y": "auto",
                          },
                        },
                        [
                          _c(
                            "el-timeline",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                "margin-right": "10px",
                              },
                            },
                            _vm._l(
                              _vm.scanData.logiscList,
                              function (activity, index) {
                                return _c(
                                  "el-timeline-item",
                                  { key: index, attrs: { placement: "top" } },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "dot" }, slot: "dot" },
                                      [
                                        activity.billClass == 2
                                          ? _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: require("@/assets/images/fahuo.png"),
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "icon",
                                              attrs: {
                                                src: require("@/assets/images/shouhuo.png"),
                                              },
                                            }),
                                        _vm._v(" "),
                                        activity.billClass == 2
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "25px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(activity.sendComCode) +
                                                    " " +
                                                    _vm._s(activity.sendComName)
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "25px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    activity.receiveComCode
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      activity.receiveComName
                                                    )
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    activity.billClass == 2
                                      ? _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "20px",
                                            },
                                          },
                                          [
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("出库单号:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.billNo
                                                            ? activity.billNo
                                                            : ""
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("出库时间:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.billDate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("出库类型:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("billType")(
                                                            activity.billType
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            activity.receiveComCode
                                              ? _c("el-col", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vue-from-block",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-div",
                                                        },
                                                        [_vm._v("收货单位:")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              activity.receiveComCode
                                                                ? "(" +
                                                                    activity.receiveComCode +
                                                                    ")"
                                                                : ""
                                                            ) +
                                                              _vm._s(
                                                                activity.receiveComName
                                                                  ? activity.receiveComName
                                                                  : ""
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "20px",
                                            },
                                          },
                                          [
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("入库单号:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.billNo
                                                            ? activity.billNo
                                                            : ""
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("入库时间:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.billDate
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("入库类型:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("billType")(
                                                            activity.billType
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            activity.sendComName
                                              ? _c("el-col", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vue-from-block",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-div",
                                                        },
                                                        [_vm._v("发货单位:")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              activity.sendComCode
                                                                ? "(" +
                                                                    activity.sendComCode +
                                                                    ")"
                                                                : ""
                                                            ) +
                                                              _vm._s(
                                                                activity.sendComName
                                                                  ? activity.sendComName
                                                                  : ""
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.showClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("instockUnit", { ref: "channelSelect", on: { change: _vm.setCSel } }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.setPro } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }