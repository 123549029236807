"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _indexImgAddOrUpdate = _interopRequireDefault(require("./indexImg-add-or-update"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable vue/no-unused-components */
var _default = {
  filters: {
    typeFilter: function typeFilter(status) {
      // if (!status) return ''
      var statusMap = {
        '-1': '无',
        '0': '商品',
        '1': '自定义链接',
        '2': '小程序'
      };
      return statusMap[status];
    }
  },
  components: {
    AddOrUpdate: _indexImgAddOrUpdate.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      dataForm: {
        indexImg: ''
      },
      dataList: [],
      dataListSelections: [],
      addOrUpdateVisible: false,
      // 修改
      formInline: {
        status: null,
        current: 1,
        // 当前页数
        size: 10 // 每页显示多少条
      }
    };
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _wXintegralMall.indexImgList)(this.formInline).then(function (res) {
        var res = res.data;
        _this.dataList = res.records;
        _this.total = res.total;
        _this.listLoading = false;
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(id) {
      var _this2 = this;
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this3 = this;
      var ids = id ? [id] : this.dataListSelections.map(function (item) {
        return item.imgId;
      });
      this.$confirm("\u786E\u5B9A".concat(id ? '删除' : '批量删除', "?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wXintegralMall.delIndexImg)(ids).then(function (data) {
          _this3.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this3.fetchData();
            }
          });
        });
      });
    },
    // 条件查询
    searchChange: function searchChange(params) {
      this.fetchData();
    },
    // 多选变化
    selectionChange: function selectionChange(val) {
      this.dataListSelections = val;
    }
  }
};
exports.default = _default;