var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "1000px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售出库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "15",
                          "show-word-limit": "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产批次号:", prop: "orderNo" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请选择批次号",
                            "remote-method": _vm.finishedOrder,
                            loading: _vm.loading,
                          },
                          on: { change: _vm.orderNoFun },
                          model: {
                            value: _vm.scanData.orderNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "orderNo", $$v)
                            },
                            expression: "scanData.orderNo",
                          },
                        },
                        _vm._l(_vm.orderNoList, function (item) {
                          return _c("el-option", {
                            key: item.batchNo,
                            attrs: { label: item.batchNo, value: item.batchNo },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型:", prop: "billOutType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择",
                            disabled: "",
                          },
                          model: {
                            value: _vm.scanData.billOutType,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "billOutType", $$v)
                            },
                            expression: "scanData.billOutType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "扫码出库", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "生产任务单出库", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "批次号出库", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "订单用途:",
                            prop: "billApplication",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.billApplication,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "billApplication", $$v)
                                },
                                expression: "scanData.billApplication",
                              },
                            },
                            _vm._l(_vm.ddyt, function (item) {
                              return _c("el-option", {
                                key: item.pid,
                                attrs: { label: item.name, value: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货单位:", prop: "outOrgName" } },
                    [
                      _c("el-input", {
                        ref: "fhdw",
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", clearable: "" },
                        model: {
                          value: _vm.scanData.outOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "outOrgName", $$v)
                          },
                          expression: "scanData.outOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货库房:", prop: "outStoreName" } },
                    [
                      _c("el-input", {
                        ref: "ywType",
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", clearable: "" },
                        model: {
                          value: _vm.scanData.outStoreName,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "outStoreName", $$v)
                          },
                          expression: "scanData.outStoreName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货单位:", prop: "inOrgName" } },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "shdw",
                          staticStyle: { width: "350px" },
                          attrs: { readonly: "", clearable: "" },
                          model: {
                            value: _vm.scanData.inOrgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inOrgName", $$v)
                            },
                            expression: "scanData.inOrgName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              disabled: _vm.disType,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getInUnit("in")
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c("el-form-item", {
                    staticClass: "is-required",
                    attrs: { label: "产品明细:" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        data: _vm.plist,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "120",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          prop: "productCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          prop: "packScaleExpression",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装单位",
                          prop: "packUnit",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.planScanNum > 0
                                        ? row.planScanNum
                                        : "0"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.planScanAtoNum > 0
                                        ? row.planScanAtoNum
                                        : "0"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c("el-form-item", { staticClass: "dialog-footer" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "20px",
                          "text-align": "right",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.btn_loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm(2)
                              },
                            },
                          },
                          [_vm._v("提 交")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.handleClose } }, [
                          _vm._v("关 闭"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "in-out-type": _vm.inOutType,
          "shou-fa-type": _vm.shouFaType,
          "supplier-ids": _vm.supplierIds,
          "purchase-type": _vm.purchaseType,
          "product-id": _vm.productId,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }