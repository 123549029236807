var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        visible: _vm.isShowImageDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShowImageDialog = $event
        },
        closed: _vm.clearImg,
      },
    },
    [
      _c(
        "el-carousel",
        { attrs: { "indicator-position": "outside", height: "600px" } },
        _vm._l(_vm.imgs, function (src) {
          return _c("el-carousel-item", { key: src }, [
            _c("img", {
              staticStyle: {
                "max-width": "100%",
                "max-height": "100%",
                display: "block",
                margin: "0 auto",
              },
              attrs: { src: src },
            }),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }