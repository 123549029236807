"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _awardFailureList = require("@/api/operationalTools2/awardFailureList2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsawardFailureListindex';
var _default = {
  name: 'MarketingManageoperationalToolsawardFailureListindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 10,
      formInline: {
        activityId: '',
        // 活动id
        joinStartDate: '',
        // 时间
        joinEndDate: '',
        sendStartDate: '',
        // 发送时间
        sendEndDate: '',
        searchField: '',
        // 数码/id
        wechatUserId: '',
        // 用户id
        mchBillNo: '',
        ruleType: ''
      },
      rangeTime1: '',
      rangeTime2: '',
      strategyIds: '',
      listLoading: false,
      tableData: [],
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    console.log(this.$route.params);
    if (this.$route.params && this.$route.params.mchBillNo) {
      this.formInline.mchBillNo = this.$route.params.mchBillNo;
    }
    this.formInline.status = '';
    this.activityPrizeRecordPage();
    // var hasCache1 = this.tableData.length > 0 ? true : false;
    // if (!hasCache1) {
    //   this.activityPrizeRecordPage();
    // }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 表格
    activityPrizeRecordPage: function activityPrizeRecordPage() {
      var that = this;
      that.listLoading = true;
      (0, _awardFailureList.activityPrizeRecordPage)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 重发
    restSendPrize: function restSendPrize(id) {
      var that = this;
      (0, _awardFailureList.restSendPrize)(JSON.stringify(id ? id.split(',') : [])).then(function (res) {
        if (res.data.data.errCode) {
          return that.$message.error(res.data.data.errMsg);
        }
        that.$message.success('您选择的记录已成功加入补发队列，请稍后查看补发结果');
        setTimeout(function () {
          that.activityPrizeRecordPage();
        }, 1 * 800);
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 时间选择
    sj1: function sj1(res) {
      if (res) {
        this.formInline.joinStartDate = res[0];
        this.formInline.joinEndDate = res[1];
      } else {
        this.formInline.joinStartDate = '';
        this.formInline.joinEndDate = '';
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.formInline.sendStartDate = res[0];
        this.formInline.sendEndDate = res[1];
      } else {
        this.formInline.sendStartDate = '';
        this.formInline.sendEndDate = '';
      }
    },
    // 搜索
    search: function search() {
      this.params.pageNo = 1;
      this.activityPrizeRecordPage();
    },
    // 重置搜索框
    reset: function reset() {
      this.params.pageNo = 1;
      this.$refs['formInline'].resetFields();
      this.rangeTime1 = '';
      this.rangeTime2 = '';
      this.formInline.joinStartDate = '';
      this.formInline.joinEndDate = '';
      this.formInline.sendStartDate = '';
      this.formInline.sendEndDate = '';
      this.formInline.mchBillNo = '';
      this.formInline.ruleType = '';
      this.activityPrizeRecordPage();
    },
    exportLs: function exportLs() {
      var _this = this;
      (0, _awardFailureList.getExprotPrize)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        setTimeout(function () {
          _this.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '奖品发放失败列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      var arr = data.map(function (item) {
        return item.recordId;
      });
      this.strategyIds = arr.toString();
    },
    // 全部补发
    allReplacement: function allReplacement() {
      var _this2 = this;
      if (this.strategyIds == '') {
        return this.$message.info('请选择补发项');
      }
      this.$confirm("\u662F\u5426".concat(this.strategyIds == '' ? '全部补发' : '补发选中', "?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.restSendPrize(_this2.strategyIds);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 重发
    handleClick: function handleClick(row) {
      var _this3 = this;
      this.$confirm("\u662F\u5426\u91CD\u53D1?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.restSendPrize(row.recordId);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};
exports.default = _default;