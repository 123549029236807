"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _index = require("@/api/mark/code_status/new_api/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var temp = {
  pid: 0,
  Reason: ''
};
var _default = {
  name: 'ComplexTable',
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      activeabolishName: 'abolish_on',
      form_on: {
        descr: '',
        target: ''
      },
      form_ons: {
        bacthNum: '',
        cause: '',
        numStatus: 2
      },
      form_abolish: {
        bacthNum: '',
        cause: '',
        numStatus: 2
      },
      form_warehouse_receipt: {
        descr: '',
        target: ''
      },
      warehouse_receipt: {
        orderNum: ''
      },
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: false,
      btn_loading: false,
      abolish_show: false,
      temp: temp,
      DialogWarehouseReceiptVisible: false,
      rules: {
        target: [{
          required: true,
          message: '请输入数码',
          trigger: 'blur'
        }],
        descr: [{
          required: true,
          message: '输入废码原因',
          trigger: 'blur'
        }]
      },
      abolishruless: {
        target: [{
          required: true,
          message: '请输入单号',
          trigger: 'blur'
        }],
        descr: [{
          required: true,
          message: '输入废码原因',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    Rest: function Rest() {
      this.DialogWarehouseReceiptVisible = false;
      this.$refs.ListForm.resetFields();
    },
    handleabolishClick: function handleabolishClick(tab, event) {
      if (tab.name == 'abolish_on') {
        this.$refs.codeForm.resetFields();
        this.$refs['codeForm'].clearValidate();
      }
      if (tab.name == 'warehouse_receipt') {
        this.$refs.ListForm.resetFields();
        this.$refs['ListForm'].clearValidate();
      }
    },
    handleFilter: function handleFilter(type) {},
    handleUpdate: function handleUpdate(row, type) {},
    onSubmitabolish: function onSubmitabolish(row, _type) {
      var _this = this;
      this.$refs[_type].validate(function (valid) {
        if (valid) {
          var tips = '确定要废除此数码吗？';
          if (_type == 'ListForm') {
            tips = '确定要废除此制码订单号吗？';
          }
          _this.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this.authId('addbtn', _this.$route.meta.authList);
            if (valid) {
              if (_type == 'codeForm') {
                // 单码废除 ,
                _this.discardedByMark(row);
              }
              if (_type == 'ListForm') {
                // 制码订单号废除
                _this.discardedByPack(row);
              }
            }
          }).catch(function () {});
        }
      });
    },
    discardedByMark: function discardedByMark(row) {
      var _this2 = this;
      // 单码废除
      this.btn_loading = true;
      (0, _index.discardedByMark)(row).then(function (response) {
        _this2.btn_loading = false;
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.$message({
          message: '废除成功',
          type: 'success'
        });
        _this2.$refs.codeForm.resetFields();
      }).catch(function () {
        _this2.btn_loading = false;
      });
    },
    discardedByPack: function discardedByPack(row) {
      var _this3 = this;
      // 出入库单废除
      this.btn_loading = true;
      (0, _index.discardedByPack)(row).then(function (response) {
        _this3.btn_loading = false;
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return true;
        }
        _this3.$message({
          message: '废除成功',
          type: 'success'
        });
        _this3.$refs.ListForm.resetFields();
      }).catch(function () {
        _this3.btn_loading = false;
      });
    }
  }
};
exports.default = _default;