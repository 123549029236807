"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _index = require("@/api/warehouse/checkout/index");
var _ordergl = require("@/api/warehouse/ordergl");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import productDialog from '@/components/productSelectDialog'
// ???
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default
  },
  filters: {
    getRowNumber: function getRowNumber(index) {
      index = (Number(index) + 1) * 10;
      return (Array(4).join('0') + index).slice(-4);
    },
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = (sum * arr[le - 1]).toFixed(0);
      // miniUnit = num
      row.sumPlanAto = num;
      return num;
      /*
        var num = sum / arr[0]
        for (var i = 1; i < le; i++) {
          num = arr[i] * num
        }
        miniUnit = num
        row.sumPlanAto = num
        return num
      */
    }
  },
  data: function data() {
    return {
      title: '添加销售出库',
      normalList: [],
      normalFacList: [],
      // ???
      inOrgName: '',
      outOrgName: '',
      saleOrgName: '',
      billType: '',
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      isUpdate: true,
      orgTypeDisabled: false,
      proShow: false,
      billShow: false,
      orgTypev: 1,
      orgType: [{
        id: 1,
        name: '销售公司'
      }, {
        id: 2,
        name: '经销商'
      }],
      delertForm: {
        current: 1,
        channelType: 1,
        archivesStatus: 1,
        size: 211111111,
        ccOrgId: ''
      },
      submitType: 1,
      rowError: [],
      scanData: {
        billoutSaleAO: {}
      },
      scanData1: {
        billNo: '',
        billSource: '',
        billType: '203',
        sumPlanNum: '',
        sumPlanAtoNum: '',
        billoutSaleAO: {
          inOrgId: '',
          outOrgId: '',
          outOrgType: '',
          outStoreId: '',
          receiptAddress: '',
          receiptUser: '',
          relateNo: '',
          saleOrgId: ''
        },
        tradeBillDetailAOS: []
      },
      disabled: {
        num: false
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      productList: [],
      buyerOrg: [],
      salerOrgList: [],
      inOrg: {},
      rules: {
        billNo: [{
          required: true,
          message: '销售出库单号不能为空，请维护',
          trigger: 'change'
        }],
        relateNo: [{
          required: false,
          message: '订单号不能为空，请维护',
          trigger: 'change'
        }],
        'billoutSaleAO.outStoreId': [{
          required: true,
          message: '请选择出库库房',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getstoreHouseInfo();
  },
  methods: {
    setStore: function setStore(val, sendComId) {
      var _this = this;
      if (!this.productList.length || !val || !sendComId) {
        return;
      }
      var _loop = function _loop(i) {
        (0, _ordergl.getCurrentInventory)({
          productId: _this.productList[i].pId,
          sendStoreId: val,
          sendComId: sendComId
        }).then(function (res) {
          _this.productList[i].storeInventory = res.data.data || 0;
          _this.resetList();
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    init: function init() {
      this.productList = [];
      this.inOrgList = [];
      this.outOrgList = [];
      this.scanData = JSON.parse(JSON.stringify(this.scanData1));
      this.getFacList();
      this.getCompanyList();
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      this.isUpdate = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;

      // this.getfactoryInfoList()
      // this.getSalesCompany()
      this.mloading = false;
    },
    getFacList: function getFacList() {
      var _this2 = this;
      (0, _select.getXgOrgInfo)({
        orgTypeList: ['2', '3']
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.normalFacList = res.data.data;
          } else {
            _this2.normalFacList = [];
          }
        }
      });
    },
    getCompanyList: function getCompanyList() {
      var _this3 = this;
      (0, _ordergl.companyList)({
        current: 1,
        size: 2147483647
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.normalList = res.data.data.records;
          } else {
            _this3.normalList = [];
          }
        }
      });
    },
    getStoreList: function getStoreList(pid) {
      var _this4 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this4.storeList = res.data.data;
          } else {
            _this4.storeList = [];
          }
        }
      });
    },
    openBill: function openBill() {
      this.billShow = true;
    },
    billClose: function billClose() {
      this.billShow = false;
    },
    setBill: function setBill(val) {
      console.log(val);
      this.billShow = false;
      this.scanData.billoutSaleAO.relateNo = val.orderNo;
      this.getDetailData(val.pid);
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this5 = this;
      (0, _ordergl.queryById)(id).then(function (res) {
        console.log(res, 987);
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderDetailDTOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              prodUnit: item.prodUnit,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isGift: item.isGift
            };
            arr.push(obj);
          });
          _this5.productList = arr;
          _this5.addData = res.data.data;
          _this5.normalList.forEach(function (item) {
            if (item.companyId == data.purchaseComId) {
              _this5.purchaseComName = item.companyName;
            }
            if (item.companyId == data.receiveComId) {
              _this5.receiveComName = item.companyName;
            }
          });
          _this5.normalFacList.forEach(function (item) {
            if (item.companyId == data.sendOutComId) {
              _this5.sendOutComName = item.companyName;
            }
          });
          _this5.getStoreList(data.sendOutComId);
          if (data.sendOutComType == 1) {
            (0, _ordergl.factoryInfo)({
              pId: data.sendOutComId
            }).then(function (response) {
              if (response.data) {
                _this5.sendComId = response.data.orgId;
              } else {
                _this5.$message.error('获取信息失败');
              }
            });
          } else {
            _this5.sendComId = data.sendOutComId;
          }
        } else {
          _this5.$message.error(res.data.msg);
        }
      }).then(function () {
        // if (visibleType == 'edit') {
        _this5.dialogVisible = true;
        // this.$nextTick(() => {
        //   this.$refs.addForm.clearValidate()
        // })
      });
    },
    upDateShow: function upDateShow(row) {
      var _this6 = this;
      this.init();
      this.title = '修改销售出库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      // this.getstoreHouseInfo()
      // this.getfactoryInfoList()
      // this.getSalesCompany()
      // this.getNumInfo(row.num)
      // this.getProductDetail(row.num)
      (0, _index.getPeoductionDetail)(row.billNo).then(function (res) {
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
        } else {
          _this6.justShow = true;
          var data = res.data.data;
          _this6.scanData.billNo = data.tradeBillAllVO.billNo;
          _this6.scanData.billSource = data.tradeBillAllVO.billSource;
          _this6.scanData.billType = data.tradeBillAllVO.billType;
          _this6.scanData.billoutSaleAO.relateNo = data.tradeBillAllVO.relateNo;
          if (data.tradeBillAllVO.outStoreName) {
            _this6.factoryList.filter(function (item) {
              console.log(item, 'item');
              if (item.storeHouseName == data.tradeBillAllVO.outStoreName) {
                _this6.scanData.billoutSaleAO.outStoreId = item.pId;
                // this.setoutOrg(item.pId)
              }
            });
          }

          if (data.tradeBillAllVO.saleOrgName) {
            _this6.normalFacList.filter(function (item) {
              if (item.orgName == data.tradeBillAllVO.saleOrgName) {
                _this6.scanData.billoutSaleAO.saleOrgId = item.pId;
                _this6.saleOrgName = item.storeHouseName;
              }
            });
          }
          if (data.tradeBillAllVO.outOrgName) {
            _this6.normalFacList.filter(function (item) {
              if (item.orgName == data.tradeBillAllVO.outOrgName) {
                _this6.scanData.billoutSaleAO.outOrgId = item.pId;
                _this6.outOrgName = data.tradeBillAllVO.outOrgName;
              }
            });
          }
          if (data.tradeBillAllVO.inOrgName) {
            _this6.scanData.billoutSaleAO.inOrgId = data.tradeBillAllVO.inOrgId || '';
            _this6.scanData.billoutSaleAO.receiptUser = data.tradeBillAllVO.receiptUser || '';
            _this6.scanData.billoutSaleAO.receiptAddress = data.tradeBillAllVO.receiptAddress || '';
            _this6.inOrgName = data.tradeBillAllVO.inOrgName;
          }
          if (data.tradeProductVOS && data.tradeProductVOS.length) {
            var arr = [];
            data.tradeProductVOS.forEach(function (item) {
              var obj = {
                // billNo: 'string',
                // packUnit: 'string',
                // planScanAtoNum: 0,
                // planScanNum: 0,
                // prodBatchNo: 'string',
                // productId: 'string'
                pId: item.productId,
                productCode: item.productCode,
                productName: item.productName,
                packScaleExpression: item.packRadioDesc,
                packUnit: item.packUnit,
                planScanNum: item.planScanNum,
                planScanAtoNum: item.planScanAtoNum
              };
              arr.push(obj);
            });
            _this6.productList = arr;
          } else {
            _this6.productList = [];
          }
          _this6.setStore(_this6.scanData.billoutSaleAO.outStoreId, _this6.scanData.billoutSaleAO.outOrgId);
        }
        _this6.mloading = false;
      }).catch(function () {
        _this6.mloading = false;
      });
    },
    // ???
    getNumInfo: function getNumInfo(num) {
      var _this7 = this;
      (0, _index.NumInfo)(num).then(function (res) {
        _this7.listLoading = false;
        if (res.data.code != 200) {
          _this7.$message.error(res.data.msg);
          return;
        } else {
          _this7.scanData = JSON.parse(JSON.stringify(_this7.scanData1));
          for (var i in _this7.scanData) {
            _this7.scanData[i] = res.data.data[i];
          }
        }
      }).catch(function () {});
    },
    // 获取产品详情
    getProductDetail: function getProductDetail(num) {
      var _this8 = this;
      this.listLoading = true;
      var that = this;
      that.productList = [];
      (0, _index.getProductDetail)(num).then(function (res) {
        _this8.listLoading = false;
        if (res.data.code != 200) {
          _this8.productList = [];
          return;
        } else {
          var list = [];
          res.data.data.forEach(function (v, index) {
            if (v.numSplit == null) {
              v['children'] = [];
              list['id_' + v.numRow] = v;
            }
          });
          res.data.data.forEach(function (v, index) {
            if (v.numSplit != null) {
              list['id_' + v.numRow]['children'].push(v);
            }
          });
          for (var i in list) {
            _this8.getDetailArray(list[i]);
          }
        }
      }).catch(function () {
        _this8.factoryList = [];
      });
    },
    // 已有产品转移数组定义
    getDetailArray: function getDetailArray(v) {
      this.productList.push({
        num: v.num,
        numRow: v.numRow,
        numSplit: v.numSplit,
        pId: v.prodCode,
        pid: v.prodCode,
        productCode: v.prodCodeCode,
        productName: v.prodCodeName,
        productClassId: v.prodClass,
        productClassName: v.prodClassName,
        productBrandId: v.prodBrand,
        packScaleExpression: v.packCodeName,
        packScaleDesc: v.packCodeDescr,
        packScaleId: v.packCode,
        prodBatch: v.prodBatch,
        sumPlan: v.sumPlan,
        sumPlanAto: v.sumPlanAto
      });
    },
    // 创建单号
    getCreateNum: function getCreateNum() {
      var _this9 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this9.$message.error(res.data.msg);
          return;
        }
        _this9.scanData.billNo = res.data.data;
        _this9.disabled.num = true;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    // 关联单号
    // getRelationNum() {
    //   var taht = this
    //   this.productList = []
    //   if (!this.scanData.billoutSaleAO.relateNo) {
    //     this.$message.error('请输入关联单号')
    //     return false
    //   }
    //   this.listLoading = true
    //   queryProdByTaskNo({
    //     relateNo: this.scanData.billoutSaleAO.relateNo,
    //     relateType: 4
    //   })
    //     .then(res => {
    //       this.listLoading = false
    //       if (res.data.code != 200) {
    //         that.$message.error(res.data.msg)
    //         return
    //       } else {
    //         var productList = res.data.data.productList
    //         var infos = res.data.data.info
    //         this.salerOrgList = [
    //           { pid: infos.supplierId, orgName: infos.supplierName }
    //         ]
    //         this.scanData.salerOrg = infos.supplierId
    //         this.scanData.salerType = infos.harvestType == 1 ? 2 : 1
    //         this.setorgType(this.scanData.salerType)
    //         this.scanData.buyerOrg = infos.harvestId
    //         this.scanData.buyerAddr = infos.address
    //         taht.productList = []
    //         productList.forEach(v => {
    //           v['sumPlan'] = v.purchaseSum
    //           v['sumPlanAto'] = v.minScaleSum
    //           v['productClassName'] = v.brandName
    //           v['packScaleExpression'] = '1*' + v.minScaleSum
    //           taht.productList.push(v)
    //         })
    //       }
    //     })
    //     .catch(() => {
    //       this.listLoading = false
    //     })
    // },
    proDel: function proDel(row, index) {
      this.productList.splice(row, 1);
      return true;
      // if (this.submitType == 3) {
      //   this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     setProductDelete(row.num, row.numRow)
      //       .then((res) => {
      //         if (res.data.code != 200) {
      //           that.$message.error(res.data.msg)
      //           return
      //         } else {
      //           that.productList.splice(row, 1)
      //         }
      //       })
      //       .catch(() => {})
      //   })
      // } else {
      //   this.productList.splice(row, 1)
      // }
    },
    submitForm: function submitForm(temp) {
      var _this10 = this;
      this.setDetail();
      if (this.rowError.length > 0) {
        this.$message.error('产品列表第' + this.rowError.join(',') + '的 计划产量大于0箱，或者库存不足');
        return false;
      }
      // console.log('SUB_scanData', JSON.stringify(this.scanData))
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this10.btn_loading = true;
          if (_this10.submitType == 2) {
            // delete this.scanData.listDetail
            (0, _index.InfoUpdate)(_this10.scanData).then(function (res) {
              _this10.btn_loading = false;
              if (res.data.code != 200) {
                _this10.$message.error(res.data.msg);
              } else {
                _this10.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this10.handleClose();
              }
            }).catch(function () {
              _this10.btn_loading = false;
            });
          } else {
            (0, _index.InfoInsert)(_this10.billType, _this10.scanData).then(function (res) {
              _this10.btn_loading = false;
              if (res.data.code != 200) {
                _this10.$message.error(res.data.msg);
              } else {
                _this10.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this10.handleClose();
              }
            }).catch(function () {
              _this10.btn_loading = false;
            });
          }
        }
      });
    },
    // 详情组合
    setDetail: function setDetail() {
      var that = this;
      that.rowError = [];
      that.scanData.listDetail = [];
      if (this.productList.length > 0) {
        this.productList.forEach(function (v, index) {
          var row = 1 + index;
          if (v.planScanNum <= 0 || v.planScanNum > v.storeInventory) {
            that.rowError.push(row);
          }
          that.scanData.listDetail.push({
            num: that.scanData.num,
            numRow: row,
            numSplit: '',
            packCode: v.packScaleId,
            // 包装比例id
            packUnit: '箱',
            // 单位：箱
            prodBatch: v.prodBatch,
            // 生产批次号
            prodBrand: v.productBrandId,
            // 品牌id
            prodClass: v.productClassId,
            // 品牌分类id
            prodCode: v.pid,
            // 产品id
            sumPlan: v.sumPlan,
            // 计划数量
            sumPlanAto: v.sumPlanAto,
            // 计划数量最小单位
            sumSettle: '',
            // 实际数量
            sumSettleAto: '' // 实际数量最小单位
          });
        });
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    // 获取库房下拉信信
    getstoreHouseInfo: function getstoreHouseInfo() {
      var _this11 = this;
      this.factoryList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          _this11.$message.error(res.data.msg);
          return;
        } else {
          _this11.factoryList = res.data.data;
          console.log('this.factoryList', _this11.factoryList);
        }
      }).catch(function () {
        _this11.factoryList = [];
      });
    },
    // 获取企业下拉信息
    getfactoryInfoList: function getfactoryInfoList() {
      var _this12 = this;
      (0, _select.factoryInfoList)({}).then(function (res) {
        _this12.inOrgList = [];
        _this12.outOrgList = [];
        if (res.data.code != 200) {
          _this12.$message.error(res.data.msg);
          return;
        } else {
          _this12.inOrgList = res.data.data.records;
          _this12.outOrgList = res.data.data.records;
        }
      }).catch(function () {
        _this12.factoryList = [];
      });
    },
    openSel: function openSel() {
      this.proShow = true;
      // this.$refs.selProduct.proSelVisible = true
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSel: function setSel(val) {
      this.proShow = false;
      var listchat = [];
      if (this.productList.length > 0) {
        this.productList.forEach(function (v, index) {
          listchat[v.pId] = index;
        });
        for (var i in val) {
          if (typeof listchat[val[i].pId] == 'undefined') {
            this.productList.push(val[i]);
          } else {
            delete listchat[val[i].pId];
          }
        }
        for (var obj in listchat) {
          this.productList.splice(listchat[obj], 1);
        }
        return true;
      }
      this.productList = val;
    },
    setSum: function setSum(e) {
      console.log('----', e);
      // this.scanData = JSON.parse(JSON.stringify(this.scanData))
      // this.scanData.planNum = this.valENnum(e)
    },
    setScanFactory: function setScanFactory(val) {
      if (!val) return;
      this.setStore(val, this.scanData.billoutSaleAO.outOrgId);
    },
    setoutOrg: function setoutOrg(val) {
      this.outOrgList = [];
      this.scanData.outOrg = '';
      if (!val) return;
      for (var i in this.factoryList) {
        var items = this.factoryList[i];
        if (items.pId == val) {
          if (items.factoryOrgId != null) {
            this.scanData.outOrg = items.factoryOrgId;
            this.outOrgList.push({
              pId: items.factoryOrgId,
              factoryName: items.orgName
            });
            break;
          }
        }
      }
    },
    setorgType: function setorgType(val) {
      if (!val) {
        this.buyerOrg = [];
        return;
      }
      if (val == 1) {
        this.getSalesCompany();
        return true;
      }
      this.getDelear();
    },
    getSalesCompany1: function getSalesCompany1() {
      var _this13 = this;
      // 获取销售公司下啦数据
      this.buyerOrg = [];
      this.scanData.buyerOrg = '';
      (0, _select.getXgOrgInfo)(2).then(function (res) {
        if (res.data.code != 200) {
          _this13.$message.error(res.data.msg);
          return;
        } else {
          _this13.buyerOrg = res.data.data;
        }
      }).catch(function () {
        _this13.buyerOrg = [];
      });
    },
    // getSalesCompany(orgId,orgCodeAndName){  //获取销售公司下啦数据
    //   this.salerOrgList =[]
    //   this.scanData.salerOrg=''
    //   var data = {orgStatus:1,orgType:2}
    //   if(orgId!=undefined){
    //     data['pId'] = orgId
    //     data['orgCodeAndName'] = orgCodeAndName
    //   }
    //   ccOrgInfos(data).then(res => {
    //       if (res.data.code != 200) {
    //         this.$message.error(res.data.msg)
    //         return
    //       } else {
    //         this.salerOrgList = res.data.data
    //       }
    //     })
    //     .catch(() => {
    //       this.salerOrgList=[]
    //     })
    // },
    getDelear: function getDelear() {
      var _this14 = this;
      // 获取经销商下啦数据
      this.buyerOrg = [];
      this.scanData.buyerOrg = '';
      (0, _select.getDelearList)(this.delertForm).then(function (res) {
        if (res.data.code != 200) {
          _this14.$message.error(res.data.msg);
          return;
        } else {
          for (var i in res.data.data.records) {
            var v = res.data.data.records[i];
            _this14.buyerOrg.push({
              pid: v.pId,
              orgName: v.channelName
            });
          }
        }
      }).catch(function () {
        _this14.buyerOrg = [];
      });
    }
  }
};
exports.default = _default;