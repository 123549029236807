"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/mark/basic");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectOptions = {
  supplierType: [{
    id: 1,
    name: '标签厂'
  }, {
    id: 2,
    name: '制盖厂'
  }
  // { id: 3, name: '膜厂' },
  // { id: 4, name: '仓储商' }
  ],

  ApprovalType: 1
};
var listQuery = {
  pageNo: 1,
  pageSize: 20,
  clientAuthStatus: ''
};
var temp = {
  pid: 0
};
var set_temp = {
  supplierName: '',
  supplierType: '',
  macAddress: '',
  ipAddress: '',
  pid: 0,
  packageId: '',
  supplierCode: ''
};
var _default = {
  name: 'MarkclientclientMacLicensingApprovalindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    getsupplierType: function getsupplierType(v) {
      var t = '';
      for (var i in selectOptions.supplierType) {
        if (selectOptions.supplierType[i].id == v) {
          t = selectOptions.supplierType[i].name;
          break;
        }
      }
      return t;
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      btn_loading: false,
      listQuery: listQuery,
      temp: temp,
      set_temp: set_temp,
      fromData: {
        auditReasons: ''
      },
      dynamicValidateForm: {
        domains: [{
          ip: '',
          ip_key: 1,
          mac: '',
          mac_key: 1
        }]
      },
      selectOptions: selectOptions,
      dialogFormVisible: false,
      showReason: false,
      dialogStatus: '',
      textMap: {
        approval: '审核详情'
      },
      dialogPvVisible: false,
      rules: {
        auditReasons: [{
          required: true,
          message: '审核未通过原因',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      btnPowers: null
    };
  },
  activated: function activated() {
    console.log(222);
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      console.log(444);
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _client_licensing.clientAuthAuditSearch)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          console.log(_this.list, ' this.list');
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.$refs.searchForm.resetFields();
      }
      this.listQuery.pageNo = 1;
      this.getList();
    },
    changeApprovalTypeHandler: function changeApprovalTypeHandler(v) {
      if (v == 0) {
        this.showReason = true;
        return true;
      }
      this.showReason = false;
      this.temp.Reason = '';
      return true;
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this2 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      this.set_temp = Object.assign({}, row);
      this.dialogStatus = data_type;
      this.btn_loading = false;
      this.selectOptions.ApprovalType = 1;
      this.changeApprovalTypeHandler(this.selectOptions.ApprovalType);
      // auditDetails(row.pid).then(response => {
      //   var data = response.data
      //   if (data.code != 200) {
      //     this.$message.error(data.msg)
      //     return true
      //   }
      var macAddress = this.set_temp.macAddress.split(',');
      var ipAddress = this.set_temp.ipAddress ? this.set_temp.ipAddress.split(',') : '';
      this.dynamicValidateForm.domains = [];
      for (var j in macAddress) {
        this.dynamicValidateForm.domains.push({
          ip: ipAddress[j] || '',
          mac: macAddress[j]
        });
      }
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    setReviewNoPass: function setReviewNoPass() {
      var _this3 = this;
      // 未通过
      // if (this.btnPowers.MACSHBTG.view == false) {
      //   this.$message.error('权限不足')
      //   return false
      // }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          _this3.fromData.supplierCode = _this3.set_temp.supplierCode;
          (0, _client_licensing.auditReviewNoPass)(_this3.fromData).then(function (res) {
            _this3.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this3.$message.error(data.msg);
              return false;
            }
            _this3.dialogFormVisible = false;
            _this3.fromData = {
              auditReasons: ''
            };
            _this3.getList();
            _this3.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this3.btn_loading = false;
          });
        }
      });
    },
    setReviewPass: function setReviewPass() {
      var _this4 = this;
      // 通过
      // if (this.btnPowers.MACSHTG.view == false) {
      //   this.$message.error('权限不足')
      //   return false
      // }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.btn_loading = true;
          _this4.fromData.auditReasons = '';
          _this4.fromData.supplierCode = _this4.set_temp.supplierCode;
          console.log(_this4.fromData, 987);
          (0, _client_licensing.auditReviewPass)(_this4.fromData).then(function (res) {
            _this4.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this4.$message.error(data.msg);
              return false;
            }
            _this4.dialogFormVisible = false;
            _this4.fromData = {
              auditReasons: ''
            };
            _this4.getList();
            _this4.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this4.btn_loading = false;
          });
        }
      });
    },
    close: function close() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogFormVisible = false;
    }
  }
};
exports.default = _default;