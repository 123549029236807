var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  staticStyle: { padding: "20px 0 0 20px" },
                  attrs: { inline: true, model: _vm.queryParams },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动流程名称:" } },
                    [
                      _c("el-input", {
                        staticClass: "input-with-select",
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.queryParams.processName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "processName", $$v)
                          },
                          expression: "queryParams.processName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "btnArea", staticStyle: { padding: "0" } },
                      [
                        _c(
                          "div",
                          { staticClass: "head-btn-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.querylist },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.restProcessList },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.addProcessList },
                  },
                  [_vm._v("添加")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "processName",
                  label: "活动流程名称",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "actCount",
                  label: "关联进行活动数",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "生成时间",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "pageCount",
                  label: "活动页面数",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: {
                              type: "text",
                              disabled: scope.row.actCount > 0 ? true : false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editProcess(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(scope.row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSee(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getProcessList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleFormParams",
              staticClass: "demo-form-inline",
              staticStyle: { padding: "20px 0 0 20px" },
              attrs: { inline: true, model: _vm.params, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动流程名称:", prop: "processName" } },
                [
                  _c("el-input", {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.params.processName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "processName", $$v)
                      },
                      expression: "params.processName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.activityData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "120px",
                  type: "index",
                  label: "流程序号",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "templateType",
                  label: "节点类型",
                  align: _vm.tableConfig.align,
                  formatter: _vm.nameTemplateType,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "pageChoose",
                  label: "页面选择",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.chookes(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.pageChoose))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "220px",
                  prop: "describe",
                  label: "描述",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleStop(scope.row, scope.$index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "停用" : "启用")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("ruleFormParams")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联活动详情",
            visible: _vm.dialogSet,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSet = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.setForm },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-form-item", { attrs: { label: "活动流程名称:" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.setForm.processName))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.concentList, function (item, index) {
                return _c(
                  "el-row",
                  { key: index },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.filterTemplateType(item.templateType),
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(item.templateName))])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "状态:" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("filterStatus")(item.status))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogSet = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogChoose, width: "800px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogChoose = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.activity },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "页面类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.activity.templateCategory,
                            callback: function ($$v) {
                              _vm.$set(_vm.activity, "templateCategory", $$v)
                            },
                            expression: "activity.templateCategory",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "抽奖类", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "集字类", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "竞猜类", value: "3" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "引导关注类", value: "4" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "btnArea", staticStyle: { padding: "0" } },
                      [
                        _c(
                          "div",
                          { staticClass: "head-btn-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addActivity()
                                  },
                                },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.restActivity },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "row-key": "id",
                    "header-cell-class-name": _vm.cellClass,
                    data: _vm.formChoose.tableChoose,
                    "tooltip-effect": "dark",
                  },
                  on: { select: _vm.select, "select-all": _vm.selectAll },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      "reserve-selection": true,
                      width: "55",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "模板名称",
                      prop: "templateName",
                      align: "center",
                      width: "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "templateType",
                      label: "模板类型",
                      formatter: _vm.templateType,
                      align: "center",
                      width: "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "page", label: "页面类型", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.templateType
                              ? _c("span", [_vm._v("活动页面")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveTemplateType },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalActivity > 0,
                expression: "totalActivity > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalActivity,
              page: _vm.activity.pageNo,
              limit: _vm.activity.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.activity, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.activity, "pageSize", $event)
              },
              pagination: _vm.addActivity,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }