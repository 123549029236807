var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cardBox" },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formInline",
                          staticClass: "searchForm demo-form-inline",
                          attrs: {
                            model: _vm.formInline,
                            inline: true,
                            gutter: 10,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "标签名称：",
                                            prop: "title",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "filter-item",
                                            attrs: { placeholder: "标签名称" },
                                            model: {
                                              value: _vm.formInline.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formInline,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "formInline.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "状态：",
                                            prop: "status",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value: _vm.formInline.status,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formInline,
                                                    "status",
                                                    $$v
                                                  )
                                                },
                                                expression: "formInline.status",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "全部",
                                                  value: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "禁用",
                                                  value: "0",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "正常",
                                                  value: "1",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { staticClass: "hyyy-head-btn" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "normalBtn",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.formInline.current = 1
                                                  _vm.fetchData()
                                                },
                                              },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "normalBtn",
                                              on: {
                                                click: function ($event) {
                                                  _vm.restForm("formInline")
                                                  _vm.fetchData()
                                                },
                                              },
                                            },
                                            [_vm._v("重置")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c(
                        "div",
                        { staticClass: "btnWrap" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addOrUpdateHandle()
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "formTip" }, [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                        _vm._v(
                          "提示：1、请在新增的分组里添加产品，若正常状态下的分组里没有产品时首页将不展示\n            2、为保证首页页面美观每个分组尽量添加6个以上产品\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          attrs: {
                            data: _vm.dataList,
                            "element-loading-text": "Loading",
                            fit: "",
                            "header-cell-style": {
                              "background-color": "#fafafa",
                              color: "rgba(0, 0, 0,0.85)",
                              "font-weight": "500",
                            },
                            "row-style": {
                              color: "rgba(0, 0, 0,0.65)",
                              "font-weight": "400",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "分组名称", prop: "title" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "状态" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.status == 0
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "danger",
                                            },
                                          },
                                          [_vm._v("禁用")]
                                        )
                                      : _c(
                                          "el-tag",
                                          { attrs: { size: "small" } },
                                          [_vm._v("正常")]
                                        ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "默认类型" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.isDefault === 0
                                      ? _c(
                                          "el-tag",
                                          { attrs: { size: "small" } },
                                          [_vm._v("自定义类型")]
                                        )
                                      : _c(
                                          "el-tag",
                                          { attrs: { size: "small" } },
                                          [_vm._v("默认类型")]
                                        ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "排序", prop: "seq" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addOrUpdateHandle(
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText red",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteHandle(
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.formInline.current,
                          limit: _vm.formInline.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.formInline, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.formInline, "size", $event)
                          },
                          pagination: _vm.fetchData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.addOrUpdateVisible
        ? _c("add-or-update", {
            ref: "addOrUpdate",
            on: { refreshDataList: _vm.refreshChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }