"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.number.constructor");
var _org = require("@/api/basic/org");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _channel = require("@/api/basic/channel");
var _factory = require("@/api/basic/factory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    id: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    },
    multipleList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    },
    salesManUserId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        isCode: this.isCode
      },
      firmList: [],
      depList: [],
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      },
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        salesManName: '',
        channelOrgId: ''
      },
      salesmanList: [],
      visible: true,
      orgList: [],
      channelOrgId: '',
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      // salesManId: '',
      querysalesman: {
        orgId: '',
        phoneNo: '',
        departmentId: '',
        salesManAccount: '',
        salesManName: ''
      },
      size: 10,
      current: 1,
      total: 0,
      multipleListS: []
    };
  },
  mounted: function mounted() {
    this.getOrgListAll();
    this.getOrgList();
    this.getComBoxSalesMan();
    this.getQuerySalesManById();
  },
  methods: {
    changeorg: function changeorg(orgId, type) {
      var _this = this;
      if (type == 'N') {
        this.searchForm.departmentId = '';
      }
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    getOrgList: function getOrgList() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.firmList = response.data.data;
        }
      });
    },
    // 获取业务员列表
    getComBoxSalesMan: function getComBoxSalesMan() {
      var _this2 = this;
      var submitData = (0, _utils.deepClone)((0, _utils.filterKeyNull)(this.querysalesman));
      submitData.orgId = submitData.orgId || submitData.departmentId;
      delete submitData.departmentId;
      (0, _channel.comBoxSalesMan)(this.current, this.size, submitData).then(function (res) {
        if (res.data.code !== 200) {
          return _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data !== null) {
            _this2.total = Number(res.data.data.total);
            _this2.current = Number(res.data.data.current);
            _this2.salesmanList = res.data.data.records;
          } else {
            _this2.total = 0;
            _this2.salesmanList = [];
          }
        }
      });
    },
    // 批量修改业务员
    getQuerySalesManById: function getQuerySalesManById() {
      this.salesManId = this.id.join(',');
      (0, _channel.querySalesManById)({
        salesManId: this.salesManId
      }).then(function (res) {
        console.log(res);
      });
    },
    show: function show(data) {},
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    resetForm: function resetForm(formName) {
      this.queryParams = {
        pageSize: 10,
        pageNo: 1,
        goodsHxTarget: null,
        goodsHxType: null,
        goodsType: null,
        prizeName: null,
        prizeType: '',
        redSendType: null,
        status: null
      };
      this.filterText = '';
      this.clear();
      // this.getList()
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      // this.resetForm()
      this.salesmanList = [];
      this.visible = false;
      // this.clear()
      // this.$emit('change')
      this.$emit('close');
      this.$emit('getData');
      // this.$parent.$parent.isShow = false;
    },
    // 回显
    // select() {
    //   this.multipleSelection = this.multipleList
    //   this.$nextTick(() => {
    //     this.multipleList.forEach(item => {
    //       this.$refs.multipleTable.toggleRowSelection(item, true)
    //     })
    //   })
    //   this.getPrizeList()
    //   setTimeout(() => {
    //     this.$refs.multipleTable.toggleRowSelection(this.salesmanList[0])
    //     this.$refs.multipleTable.toggleRowSelection(this.salesmanList[0])
    //     // this.selLoading = false
    //   }, 500)
    // },
    submit: function submit() {
      var _this3 = this;
      if (!this.multipleListS.length) {
        return this.$message.error('请选择业务员');
      }
      var arr = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.id),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          var itemObj = {
            channelId: item,
            modSalesManId: this.multipleListS[0].pid
          };
          this.salesManUserId && (itemObj.currentSalesManId = this.salesManUserId);
          arr.push(itemObj);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      (0, _channel.batchUpdateSalesMan)(arr).then(function (res) {
        console.log(res);
        if (res.data.code !== 200) {
          return _this3.$message.error('更改失败!');
        } else {
          // this.$message.success('更改成功!')
          _this3.$emit('getData');
          _this3.$emit('close', res.data.data, _this3.multipleListS[0].salesManName, true);
        }
      });
    },
    // 搜索
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getComBoxSalesMan();
    },
    getOrgListAll: function getOrgListAll() {
      var _this4 = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: ''
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    getOrgInUseList: function getOrgInUseList() {
      var _this5 = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this5;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgInUseList = JSON.parse(JSON.stringify(Data));
          if (datas.length > 0) {
            var arr = [];
            datas.forEach(function (item) {
              var obj = {
                id: item.pId,
                label: item.orgName,
                pId: item.pId,
                parentId: item.parentPid
              };
              arr.push(obj);
            });
            that.orgTreeList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(arr)), 'pId', 'parentId', 'children');
          }
        }
      });
    },
    resetQuerylist: function resetQuerylist() {
      this.querysalesman = {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        departmentId: '',
        salesManName: ''
      };
      this.size = 10;
      this.current = 1;
      this.channelOrgId = '';
      this.getComBoxSalesMan();
    },
    select: function select(selection, row) {
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      this.multipleListS = selection;
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    }
  }
};
exports.default = _default2;