"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _LineChart = _interopRequireDefault(require("@/components/dataAnalyse/LineChart"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _fc = require("@/api/dataAnalyse/fc.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _LineChart.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      listLoading: false,
      sweepBtn: false,
      downLoading: false,
      dateValue: [],
      formInline: {
        timeType: '1',
        endTime: '',
        startTime: '',
        productCode: '',
        productName: ''
      }
    };
  },
  created: function created() {
    this.getScurrystatFun();
  },
  methods: {
    lineData: function lineData(res, name) {
      var xData = [];
      var sData = [];
      res.data.forEach(function (item) {
        xData.push(item.date);
        sData.push(item.count);
      });
      this.list = res.data;
      this.$refs.lineChart.xData = xData;
      this.$refs.lineChart.sData = [{
        name: name,
        type: 'line',
        data: sData,
        symbol: 'circle',
        smooth: false,
        symbolSize: 6,
        lineStyle: {
          width: 3,
          color: '#1E9FFF'
        },
        itemStyle: {
          color: '#1E9FFF'
        },
        areaStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 1,
                color: 'rgba(255,255,255,0)' // 0% 处的颜色
              }, {
                offset: 0,
                color: 'rgba(30,159,255,.5)' // 100% 处的颜色
              }],

              global: false // 缺省为 false
            }
          }
        }
      }];

      this.$refs.lineChart.initChart();
    },
    // 获取数据
    getScurrystatFun: function getScurrystatFun(type) {
      var _this2 = this;
      if (type) {
        this.formInline.timeType = type;
      }
      if (type !== '2' && type) {
        this.dateValue = [];
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      (0, _fc.antiChanTrendChart)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this2.lineData(res.data, '窜货');
        }
      });
    },
    // 时间选择
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.getScurrystatFun('3');
      } else {
        this.getScurrystatFun('1');
      }
    },
    // 产品选择
    inputFocusFun: function inputFocusFun() {
      this.$refs.selProduct.proSelVisible = true;
    },
    // 产品选择
    setCurrent: function setCurrent(row) {
      this.formInline.productName = row.productName;
      this.formInline.productCode = row.productCode;
      this.getScurrystatFun();
    },
    clearFun: function clearFun() {
      if (this.formInline.productCode) {
        this.formInline.productCode = '';
        this.formInline.productName = '';
      }
      this.getScurrystatFun();
    },
    // 导出
    downFun: function downFun() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outoTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      var fileName;
      if (this.formInline.timeType === '1') {
        fileName = '本月疑似窜货趋势.xlsx';
      } else if (this.formInline.timeType === '2') {
        fileName = '本年疑似窜货趋势.xlsx';
      } else if (this.formInline.timeType === '3') {
        fileName = this.formInline.startTime + '至' + this.formInline.endTime + '疑似窜货趋势.xlsx';
      }

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        fileName);
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;