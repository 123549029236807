var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "name", label: "手册名称:" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "name", $$v)
                                      },
                                      expression: "listQuery.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "type", label: "手册类型:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.listQuery.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "type", $$v)
                                        },
                                        expression: "listQuery.type",
                                      },
                                    },
                                    _vm._l(_vm.bookOption, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "module", label: "所属模块:" },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "clear-value-text": "",
                                      "flatten-search-results": true,
                                      placeholder: "请选择模块",
                                      options: _vm.orgOptions,
                                    },
                                    model: {
                                      value: _vm.listQuery.module,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "module", $$v)
                                      },
                                      expression: "listQuery.module",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(_vm.listQuery)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset(_vm.listQuery)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.add },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "手册名称",
                          prop: "name",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                          "min-width": "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "手册类型",
                          prop: "typeDesc",
                          align: _vm.tableConfig.align,
                          "min-width": "180",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属模块",
                          prop: "moduleDesc",
                          align: _vm.tableConfig.align,
                          "min-width": "180",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "min-width": "180",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "180",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth("deletebtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoDel(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth("downloadbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downLoad(row)
                                            },
                                          },
                                        },
                                        [_vm._v("下载")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3892777387
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.subLoading,
              expression: "subLoading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            title: "操作手册添加",
            visible: _vm.addVisible,
            "before-close": _vm.docancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addData,
                "label-width": "90px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手册名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: "",
                    },
                    model: {
                      value: _vm.addData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "name", $$v)
                      },
                      expression: "addData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手册类型:", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      on: { change: _vm.chose },
                      model: {
                        value: _vm.addData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "type", $$v)
                        },
                        expression: "addData.type",
                      },
                    },
                    _vm._l(_vm.bookOption, function (item) {
                      return _c("el-option", {
                        key: item.figure,
                        attrs: {
                          label: item.name,
                          value: item.figure,
                          placeholder: "请选择",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属模块:", prop: "module" } },
                [
                  _c("treeselect", {
                    ref: "treeSlect",
                    attrs: {
                      multiple: false,
                      "clear-value-text": "",
                      "flatten-search-results": true,
                      placeholder: "请选择",
                      options: _vm.orgOptions,
                    },
                    on: { select: _vm.selectModule },
                    model: {
                      value: _vm.addData.module,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "module", $$v)
                      },
                      expression: "addData.module",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手册描述", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.addData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remark", $$v)
                      },
                      expression: "addData.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手册文件", prop: "filePath" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "",
                        "show-file-list": false,
                        "on-change": _vm.handleChange,
                        "auto-upload": false,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "trigger",
                            loading: _vm.upLoading,
                            size: "mini",
                            type: "primary",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("选择文件上传")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "el-upload__tip" }, [
                        _vm._v("(只能上传pdf文件)"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "file",
                    attrs: { placeholder: "请上传", readonly: "" },
                    model: {
                      value: _vm.addData.filePath,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "filePath", $$v)
                      },
                      expression: "addData.filePath",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.save("addForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.docancel } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("pdfScan", {
        attrs: {
          "file-path": _vm.filePath,
          page: _vm.page,
          "pdf-visible": _vm.pdfVisible,
        },
        on: { close: _vm.hidePDF },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }