"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/basic/product");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        containsPackScale: 1
      },
      tableData: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      classList: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  mounted: function mounted() {
    this.getClass();
    this.getBrand();
    this.getPack();
    this.getList(111);
  },
  methods: {
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.productClassName.indexOf(value) !== -1;
    },
    setsel: function setsel() {
      var _this = this;
      this.$nextTick(function () {
        _this.data.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.getList();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
      }, 500);
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getClass: function getClass() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
            that.normalList = res.data.data;
            that.classList = data;
          } else {
            that.normalList = [];
            that.classList = [];
          }
        }
      });
    },
    handleFilter: function handleFilter() {
      this.$refs.tree.filter(this.filterText);
    },
    scan: function scan(data) {
      if (this.activtyId != data.pId && (!data.children || data.pId)) {
        this.searchForm = {
          current: 1,
          size: 10,
          archivesStatus: 1,
          productBrandId: '',
          productClassId: data.pId == 1 ? '' : data.pId,
          productCode: '',
          productName: '',
          containsPackScale: 1
        };
        this.getList();
      }
    },
    getBrand: function getBrand() {
      var _this2 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.brandList = res.data.data;
          } else {
            _this2.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this3 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.packList = res.data.data;
          } else {
            _this3.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      var le = that.searchForm.productClassId.length - 1 || '';
      submitData.productClassId = that.searchForm.productClassId[le] || '';
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this4.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        containsPackScale: 1
      };
      this.filterText = '';
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.proSelVisible = false;
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;