"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserRecordPage = UserRecordPage;
exports.downRecord = downRecord;
exports.downUserRecord = downUserRecord;
exports.recordPage = recordPage;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-mark';

//查询用户浙品码记录列表
function UserRecordPage(data) {
  var current = data.current;
  var size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: modelUrl + '/pinMarkRecord/selectUserRecordPage?current=' + current + '&size=' + size,
    method: 'POST',
    data: data
  });
}

//导出用户浙品码记录列表
function downUserRecord(data) {
  return (0, _request.default)({
    url: modelUrl + '/pinMarkRecord/exportUserRecord',
    method: 'POST',
    responseType: 'blob',
    data: data
  });
}

// 查询浙品码记录列表
function recordPage(data) {
  var current = data.current;
  var size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: modelUrl + '/pinMarkRecord/selectRecordPage?current=' + current + '&size=' + size,
    method: 'POST',
    data: data
  });
}

//导出浙品码记录列表
function downRecord(data) {
  return (0, _request.default)({
    url: modelUrl + '/pinMarkRecord/exportRecord',
    method: 'POST',
    responseType: 'blob',
    data: data
  });
}