"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActSignIndetailes = ActSignIndetailes;
exports.ActSignInlist = ActSignInlist;
exports.ActWriteOffdetailes = ActWriteOffdetailes;
exports.ActWriteOfflist = ActWriteOfflist;
exports.Actlist = Actlist;
exports.actInfoDownload = actInfoDownload;
exports.addInvitation = addInvitation;
exports.cjActInfoAdd = cjActInfoAdd;
exports.cjActInfoList = cjActInfoList;
exports.detailes = detailes;
exports.downloaddetailes = downloaddetailes;
exports.endexecute = endexecute;
exports.execute = execute;
exports.getActSinCount = getActSinCount;
exports.getCodeByOrderId = getCodeByOrderId;
exports.getLottery = getLottery;
exports.getSystemTime = getSystemTime;
exports.getproduct = getproduct;
exports.jkActInfodetailes = jkActInfodetailes;
exports.jkActInfolist = jkActInfolist;
exports.jkInfoadd = jkInfoadd;
exports.listInvitation = listInvitation;
exports.listdetailes = listdetailes;
exports.listupdate = listupdate;
exports.queryForPage = queryForPage;
exports.resendCard = resendCard;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/api'
var baseUrl = '';
//  增加活动
function cjActInfoAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/add',
    method: 'POST',
    data: data
  });
}
//  获取活动列表
function cjActInfoList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/list',
    method: 'POST',
    data: data
  });
}
//  活动详情
function detailes(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/detailes',
    method: 'POST',
    data: data
  });
}
//  活动编辑
function update(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/update',
    method: 'POST',
    data: data
  });
}
//  活动执行
function execute(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/execute',
    method: 'POST',
    data: data
  });
}
//  活动结束
function endexecute(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/end',
    method: 'POST',
    data: data
  });
}
//  添加邀请函
function addInvitation(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInvitation/add',
    method: 'POST',
    data: data
  });
}
//  邀请函列表
function listInvitation(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInvitation/list',
    method: 'POST',
    data: data
  });
}
//  邀请函详情
function listdetailes(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInvitation/detailes',
    method: 'POST',
    data: data
  });
}
//  邀请函更新
function listupdate(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInvitation/update',
    method: 'POST',
    data: data
  });
}
//  活动列表
function Actlist(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/listInvitation',
    method: 'POST',
    data: data
  });
}
//  活动人数
function getActSinCount(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActInfo/getActSinCount',
    method: 'POST',
    data: data
  });
}
// 签到列表
function ActSignInlist(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActSignIn/list',
    method: 'POST',
    data: data
  });
}
// 签到详情
function ActSignIndetailes(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActSignIn/detailes',
    method: 'POST',
    data: data
  });
}

// 扫描核销
function ActWriteOfflist(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActWriteOff/list',
    method: 'POST',
    data: data
  });
}

// 扫描核销
function ActWriteOffdetailes(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActWriteOff/detailes',
    method: 'POST',
    data: data
  });
}

// 抽奖券详情
function getLottery(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActSignIn/getLottery',
    method: 'POST',
    data: data
  });
}

// 产品详情
function getproduct(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActWriteOff/getproduct',
    method: 'POST',
    data: data
  });
}

// 添加酒卡
function jkInfoadd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/add',
    method: 'POST',
    data: data
  });
}

// 酒卡列表
function jkActInfolist(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/list',
    method: 'POST',
    data: data
  });
}

// 获取当前系统时间
function getSystemTime(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/getSystemTime',
    method: 'get',
    params: params
  });
}

// 酒卡详情
function jkActInfodetailes(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/detailes',
    method: 'POST',
    data: data
  });
}

// 酒卡明细导出
function downloaddetailes(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/download',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

// 酒卡核销
function queryForPage(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/jkActInfo/queryForPage',
    method: 'get',
    params: params
  });
}

// 酒卡列表导出
function actInfoDownload(data) {
  return (0, _request.default)({
    url: '/api-uspyx/jkActInfo/actInfoDownload',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 酒卡核销详情
function getCodeByOrderId(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-channel/qd/orderInfoCode/getCodeByOrderId',
    method: 'POST',
    data: data
  });
}

// 酒卡核销详情
function resendCard(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/cjActSignIn/resendCard',
    method: 'POST',
    data: data
  });
}