"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _productDialog = _interopRequireDefault(require("./components/productDialog"));
var _channel = require("@/api/basic/channel");
var _product = require("@/api/basic/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PurchasingsupplyDetail',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productDialog.default
  },
  filters: {
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'success'
        };
        return statusMap[value];
      }
    }
  },
  data: function data() {
    return {
      handleShow: false,
      normalList: [],
      searchForm: {
        current: 1,
        size: 20,
        productCode: '',
        productClassId: '',
        productName: '',
        productCodeOrName: '',
        collectionSupplyId: ''
      },
      // 产品弹窗
      productVisible: false,
      channelId: '',
      products: [],
      // 表单
      tableData: [],
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      total: 0,
      // 详情
      detailsData: {},
      insertProduct: {
        collectionSupplyId: '',
        productIds: ''
      }
    };
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.tabHeight = (0, _utils.tableHeight)();
    });
    this.getClass();
    this.searchForm.collectionSupplyId = this.$route.query.id;
    this.getDetailFun();
  },
  activated: function activated() {
    this.searchForm.collectionSupplyId = this.$route.query.id;
    this.getDetailFun();
  },
  methods: {
    // 获取分类
    getClass: function getClass() {
      var _this = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this.normalList = data;
          } else {
            _this.normalList = [];
          }
        }
      });
    },
    // 获取详情
    getDetailFun: function getDetailFun() {
      var _this2 = this;
      var searchForm = JSON.parse(JSON.stringify(this.searchForm));
      searchForm.productClassId = this.searchForm.productClassId ? this.searchForm.productClassId[this.searchForm.productClassId.length - 1] : '';
      (0, _channel.SupplDetail)(searchForm).then(function (res) {
        if (res.data.code === 200) {
          console.log(res);
          _this2.detailsData = res.data.data;
          _this2.tableData = res.data.data.products.records;
          _this2.total = res.data.data.products.total * 1;
          _this2.insertProduct.collectionSupplyId = res.data.data.pId;
          _this2.insertProduct.productIds = [];
        } else {
          _this2.$message.error(res.data.msg);
        }
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        size: 20,
        productCode: '',
        productClassId: '',
        productName: '',
        collectionSupplyId: this.$route.query.id
      };
      this.getDetailFun();
    },
    // 打开产品
    addProductFun: function addProductFun() {
      if (this.detailsData.supplierlType === 1) {
        this.channelId = this.detailsData.supplierId;
      } else {
        this.channelId = '';
      }
      this.productVisible = true;
    },
    setProduct: function setProduct(data) {
      console.log(data);
      if (data.length > 0) {
        var arr = [];
        data.map(function (item) {
          arr.push(item.pId);
        });
        this.insertProduct.productIds = arr;
        this.insertProductFun();
      }
    },
    closeProduct: function closeProduct() {
      this.productVisible = false;
    },
    // 添加产品
    insertProductFun: function insertProductFun() {
      var _this3 = this;
      (0, _channel.insertProduct)(this.insertProduct).then(function (res) {
        if (res.data.code === 200) {
          console.log(res);
          _this3.getDetailFun();
        } else {
          _this3.$message.error(res.data.msg);
        }
      });
    },
    // 暂停
    stopFun: function stopFun(id) {
      var _this4 = this;
      this.$confirm('确认停用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('deletebtn', _this4.$route.meta.authList);
        (0, _channel.disableProduct)({
          pId: id
        }).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          if (res.data.code == 200) {
            _this4.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this4.getDetailFun();
          } else {
            _this4.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    // 启用
    startFun: function startFun(id) {
      var _this5 = this;
      this.$confirm('确认启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.authId('deletebtn', _this5.$route.meta.authList);
        (0, _channel.enableProduct)({
          pId: id
        }).then(function (res) {
          _this5.authId('searchbtn', _this5.$route.meta.authList);
          if (res.data.code == 200) {
            _this5.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this5.getDetailFun();
          } else {
            _this5.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;