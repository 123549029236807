"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.forceFinish = forceFinish;
exports.markReplaceLog = markReplaceLog;
exports.queryDownBillForPage = queryDownBillForPage;
exports.revertDown = revertDown;
exports.revertFinish = revertFinish;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 数码替换操作记录分页查询
 * @param {*} params 
 */
function markReplaceLog(params) {
  return (0, _request.default)({
    url: '/storage-center/markReplaceLog/queryForPage',
    method: 'get',
    params: params
  });
}

/**
 * 单据下载记录分页查询
 * @param {*} params
 */
function queryDownBillForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryDownBillForPage',
    method: 'get',
    params: params
  });
}

/**
 * 强制结单
 * @param {*} pId
 */
function forceFinish(pId) {
  return (0, _request.default)({
    url: '/storage-center/bill/detail/forceFinish/' + pId,
    method: 'put'
  });
}

/**
 * 撤销下载
 * @param {*} pId
 */
function revertDown(pId) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/revertDown/' + pId,
    method: 'put'
  });
}

/**
 * 撤销结单
 * @param {*} pId
 */
function revertFinish(pId) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/revertFinish/' + pId,
    method: 'put'
  });
}