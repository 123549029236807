var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "限量版本名称",
                                prop: "limitedEditionName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.limitedEditionName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "limitedEditionName",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.limitedEditionName",
                                  },
                                },
                                _vm._l(_vm.limitedList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.limitedEditionName,
                                      value: item.limitedEditionName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人", prop: "creatorName" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.creatorName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "creatorName", $$v)
                                  },
                                  expression: "ruleForm.creatorName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "签收批次", prop: "batchNoList" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    "collapse-tags": "",
                                    multiple: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.bNchange },
                                  model: {
                                    value: _vm.ruleForm.batchNoList,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "batchNoList", $$v)
                                    },
                                    expression: "ruleForm.batchNoList",
                                  },
                                },
                                _vm._l(_vm.bcList2, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单号", prop: "relateNo" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.bNchange },
                                  model: {
                                    value: _vm.ruleForm.relateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "relateNo", $$v)
                                    },
                                    expression: "ruleForm.relateNo",
                                  },
                                },
                                _vm._l(_vm.bcList, function (item) {
                                  return _c("el-option", {
                                    key: item.orderNo,
                                    attrs: {
                                      label: item.orderNo,
                                      value: item.orderNo,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.current = 1), _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.addE },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.multipleSelection.length == 0,
                      type: _vm.btnConfig.delBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.delBtn.icon,
                    },
                    on: { click: _vm.getdelete },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                  on: { select: _vm.select, "select-all": _vm.selectAll },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      "min-width": "60",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "limitedEditionName",
                      label: "限量版本名称",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "limitedNum",
                      label: "限量数",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "200",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorName",
                      label: "创建人",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建日期",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("textDate")(row.createTime)) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNo",
                      label: "关联批次号",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "relateNo",
                      label: "订单号",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productTime",
                      label: "生产日期",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("textDate")(row.productTime)) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scanCodeNum",
                      label: "扫码量",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remainingNum",
                      label: "剩余码量",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "140",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.look(row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.current,
                  limit: _vm.size,
                },
                on: {
                  "update:page": function ($event) {
                    _vm.current = $event
                  },
                  "update:limit": function ($event) {
                    _vm.size = $event
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm2("ruleForm2")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm2",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm2,
                rules: _vm.rules,
                "label-width": "110px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "限量版名称", prop: "limitedEditionName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "25" },
                    model: {
                      value: _vm.ruleForm2.limitedEditionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "limitedEditionName", $$v)
                      },
                      expression: "ruleForm2.limitedEditionName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次号", prop: "batchNo" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: _vm.disabled },
                      on: { change: _vm.batchNochange },
                      model: {
                        value: _vm.ruleForm2.batchNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "batchNo", $$v)
                        },
                        expression: "ruleForm2.batchNo",
                      },
                    },
                    _vm._l(_vm.bcList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.batchNo, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次数量", prop: "limitedNum" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm2.limitedNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "limitedNum", $$v)
                      },
                      expression: "ruleForm2.limitedNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID编码规则", prop: "codeRulePrefix" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "前缀(必须输入大写字母)",
                          maxlength: "15",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            _vm.ruleForm2.codeRulePrefix =
                              _vm.ruleForm2.codeRulePrefix.replace(
                                /[^A-Z]/g,
                                ""
                              )
                          },
                        },
                        model: {
                          value: _vm.ruleForm2.codeRulePrefix,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm2, "codeRulePrefix", $$v)
                          },
                          expression: "ruleForm2.codeRulePrefix",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "rightbox",
                      attrs: { prop: "codeRuleFirst" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "15",
                          disabled: _vm.disabled,
                          placeholder: "起始数字",
                        },
                        model: {
                          value: _vm.ruleForm2.codeRuleFirst,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm2, "codeRuleFirst", $$v)
                          },
                          expression: "ruleForm2.codeRuleFirst",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查看详情",
            visible: _vm.scanVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("限量版名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.limitedEditionName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("关联批次:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.batchNo)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("数量:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.limitedNum)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("创建人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.creatorName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("起始数码:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.startCode)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("创建日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(_vm._f("textDate")(_vm.lookList.createTime))
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.download },
                },
                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading2,
                          expression: "listLoading2",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData2,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序列",
                          width: "80",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "limitedMarkcode",
                          label: "限量ID编码",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "markcode",
                          label: "关联数码",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "codeScanningStatus",
                          label: "扫码状态",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.codeScanningStatus == 1 ||
                                row.codeScanningStatus == "已扫"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("已扫")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.codeScanningStatus == 0 ||
                                row.codeScanningStatus == "未扫"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("未扫")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total2 > _vm.size2,
                        expression: "total2 > size2",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total2,
                      page: _vm.current2,
                      limit: _vm.size2,
                    },
                    on: {
                      "update:page": function ($event) {
                        _vm.current2 = $event
                      },
                      "update:limit": function ($event) {
                        _vm.size2 = $event
                      },
                      pagination: _vm.getList2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }