"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/basic/product");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'table-packset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      default: false
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../../../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        isCode: '',
        // 产品是否支持扫码:1支持，2不支持
        archivesStatus: 1,
        productClassId: '',
        productCode: '',
        productBrandId: '',
        productName: ''
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        console.log('ewbi');
        this.getClass();
        this.getBrand();
        this.getPack();
        this.setsel();
      }
    }
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = false;
      this.data.forEach(function (row) {
        _this.$nextTick(function () {
          _this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
      this.getList();
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getClass: function getClass() {
      var _this2 = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this2.normalList = data;
          } else {
            _this2.normalList = [];
          }
        }
      });
    },
    getBrand: function getBrand() {
      var _this3 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.brandList = res.data.data;
          } else {
            _this3.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this4 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this4.packList = res.data.data;
          } else {
            _this4.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this5 = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      var le = that.searchForm.productClassId.length - 1 || '';
      submitData.productClassId = that.searchForm.productClassId[le] || '';
      console.log(submitData, 'submitDara');
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        isCode: '',
        // 产品是否支持扫码:1支持，2不支持
        archivesStatus: 1,
        productClassId: '',
        productCode: '',
        productBrandId: '',
        productName: ''
      };
      this.filterText = '';
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
      // this.proSelVisible = false
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      var _this6 = this;
      this.$nextTick(function () {
        _this6.$refs.multipleTable.toggleRowSelection(row, false);
      });
    }
  }
};
exports.default = _default2;