"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _checkout = require("@/api/warehouse/checkout");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  djlx: []
};
var _default = {
  name: 'TablePackset',
  filters: {
    djlxText: function djlxText(val) {
      var name = '';
      TypeDataComs.djlx.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name + '单';
    }
  },
  props: {
    type: {
      // 1：出库  2 ：入库
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    billNo: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      storeTxt: '发货仓库',
      listLoading: false,
      scanData: {},
      tableData: [],
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCLX', 'djlx');
    this.getQrCode();
    if (this.type == '2') {
      this.storeTxt = '收货仓库';
    } else {
      this.storeTxt = '发货仓库';
    }
    // this.setsel()
  },

  methods: {
    getQrCode: function getQrCode() {
      this.codeVisible = true;
      var qrcode;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: this.billNo,
          // 需要转换为二维码的内容
          width: 50,
          height: 50,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      }).then(function () {
        _this.getList();
      });
    },
    print: function print() {
      //  找到需要隐藏的DOM节点
      // let head = document.getElementsByClassName('gd-biz-header')[0]
      // let printBtn = document.getElementsByClassName('right-side')[0]
      // let from1 = document.getElementById('menu2')
      // let from2 = document.getElementsByClassName('two')[0]
      // let fclbz1 = document.getElementById('menu3')
      // let fclbz2 = document.getElementsByClassName('tables')[0]
      // let footer = document.getElementsByClassName('footer')[0]
      // let table = document.getElementsByClassName('table')[0]

      // //  给对应DOM添加class
      // // head.classList.add('printHideCss')
      // // printBtn.classList.add('printHideCss')
      // // from1.classList.add('printHideCss')
      // // from2.classList.add('printHideCss')
      // // fclbz1.classList.add('printHideCss')
      // // fclbz2.classList.add('printHideCss')
      // // footer.classList.add('printHideCss')
      // let subOutputRankPrint = document.getElementById('subOutputRank-print')
      // let newContent = subOutputRankPrint.innerHTML
      // let oldContent = document.body.innerHTML
      // document.body.innerHTML = newContent
      // window.print()
      // window.location.reload()
      // document.body.innerHTML = oldContent
      this.$print(this.$refs.printItem);
      // window.print() //  调用打印功能
      // window.location.reload() //  点击取消打印后刷新页面，恢复点击打印按钮之前的原始数据
    },
    selCancel: function selCancel() {
      this.$emit('close');
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      var le = this.tableData.length;
      if (rowIndex > le - 3) {
        return 'text-align:left;';
      }
      return '';
    },
    arraySpanMethod: function arraySpanMethod(_ref2) {
      var row = _ref2.row,
        column = _ref2.column,
        rowIndex = _ref2.rowIndex,
        columnIndex = _ref2.columnIndex;
      var le = this.tableData.length;
      if (rowIndex == le - 2) {
        if (columnIndex === 0) {
          return [1, 2];
        }
        if (columnIndex === 1) {
          return [1, 3];
        }
        if (columnIndex === 2) {
          return [1, 4];
        }
      } else if (rowIndex == le - 1) {
        if (columnIndex === 0) {
          return [1, 2];
        }
        if (columnIndex === 1) {
          return [1, 2];
        }
        if (columnIndex === 2) {
          return [1, 2];
        }
        if (columnIndex === 3) {
          return [1, 3];
        }
      }
    },
    getList: function getList() {
      var _this2 = this;
      var that = this;
      this.listLoading = true;
      (0, _checkout.queryPrintByBillNo)(this.billNo).then(function (res) {
        console.log(res, '列表数据');
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            console.log(res.data.data, 'data');
            var arr = res.data.data.tradePrintProductVOS;
            var data = res.data.data.tradePrintBillVO;
            that.tableData = arr;
            that.scanData = data;
            console.log(arr, '表格数据');
          } else {
            that.tableData = [];
            that.scanData = '';
          }
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;