var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "detailWrap" },
          [
            _c("div", { staticClass: "billTitle" }, [
              _c("img", { attrs: { src: require("@/images/topimg.png") } }),
              _vm._v(" "),
              _c("span", { staticClass: "billTitleText" }, [
                _vm._v("销售订单 " + _vm._s(_vm.addData.orderNo)),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "billType",
                  class: _vm._f("typeClass")(_vm.addData.tradeStatus),
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("orderStatusText")(_vm.addData.tradeStatus))
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "topRight" }, [
                _c("img", { attrs: { src: require("@/images/avatar.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.addData.creatorUser))]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v(_vm._s(_vm._f("textDate")(_vm.addData.createTime))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "topWrap", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [
                      _vm._v("订单日期:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "topContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.addData.tradeDate
                            ? _vm.addData.tradeDate.split(" ")[0]
                            : " "
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [
                      _vm._v("订单类型:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "topContent" }, [
                      _vm._v(
                        _vm._s(_vm._f("orderType")(_vm.addData.orderType))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [_vm._v("备注:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "topContent" },
                      [
                        _vm.addData.remark
                          ? _c(
                              "el-popover",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placement: "bottom-start",
                                  width: "500",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("div", { staticClass: "popoverremark" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.addData.remark
                                        ? _vm.addData.remark
                                        : " "
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "remark",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.addData.remark
                                          ? _vm.addData.remark
                                          : " "
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "remark" }, [
                              _vm._v(_vm._s(" ")),
                            ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "box-card", attrs: { shadow: "hover" } },
          [
            _c(
              "div",
              {
                staticClass: "clearfix detailCardTitle",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v("订单相关方信息")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "detailRow", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("销售单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.saleOrgName)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("发货单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.sendOrgName)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("采购单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.purchaseOrgName)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receiveOrgName)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "detailRow", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货联系人:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receiveUser)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货联系电话:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receivePhone)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货地址:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receiveAddress)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "vue-from-block",
                      staticStyle: { "padding-bottom": "0", border: "none" },
                    },
                    [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货库房:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-top": "-10px" },
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.setStore },
                              model: {
                                value: _vm.addData.sendOutStoreId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "sendOutStoreId", $$v)
                                },
                                expression: "addData.sendOutStoreId",
                              },
                            },
                            _vm._l(_vm.storeList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.storeHouseName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "margin-top": "12px" },
            attrs: { shadow: "hover", "body-style": _vm.cardPadding },
          },
          [
            _c(
              "div",
              {
                staticClass: "clearfix detailCardTitle",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", { attrs: { src: require("@/images/cpxx.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v("产品信息")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "addTable",
                staticStyle: { width: "100%", margin: "-1px 0 0" },
                attrs: {
                  "max-height": "600",
                  data: _vm.productList,
                  fit: "",
                  "row-class-name": _vm.tableRowClassName,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    align: "center",
                    width: "65",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    align: "center",
                    prop: "productCode",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    align: "center",
                    prop: "productName",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装比例",
                    align: "center",
                    prop: "packScaleExpression",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "箱数",
                    align: "center",
                    "min-width": "80",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("span", [_vm._v(_vm._s(row.chestNum))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "盒数",
                    align: "center",
                    "min-width": "80",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [_vm._v(_vm._s(row.totalBottleNum))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "已提数量(箱)",
                    align: "center",
                    prop: "extractNum",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_vm._v(_vm._s(row.pickedChestNum))]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "已提数量(盒)",
                    align: "center",
                    prop: "extractNum",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_vm._v(_vm._s(row.pickedBottleNum))]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提货数量(箱)",
                    align: "center",
                    prop: "packScaleDesc",
                    "min-width": "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "130px" },
                            attrs: {
                              disabled: row.pickedChestNum == row.chestNum,
                              controls: false,
                              precision: 0,
                              min: 0,
                              max: _vm.maxNumX(row),
                            },
                            on: {
                              change: function (currentValue, oldValue) {
                                _vm.takeGoodNumChange(
                                  currentValue,
                                  oldValue,
                                  row
                                )
                              },
                            },
                            model: {
                              value: row.takeChestNum,
                              callback: function ($$v) {
                                _vm.$set(row, "takeChestNum", $$v)
                              },
                              expression: "row.takeChestNum",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提货数量(盒)",
                    align: "center",
                    prop: "packScaleDesc",
                    "min-width": "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "130px" },
                            attrs: {
                              disabled:
                                row.pickedBottleNum == row.totalBottleNum,
                              controls: false,
                              precision: 0,
                              min: _vm.mixNumP(row),
                              max: _vm.maxNumP(row),
                            },
                            model: {
                              value: row.takeBottleNum,
                              callback: function ($$v) {
                                _vm.$set(row, "takeBottleNum", $$v)
                              },
                              expression: "row.takeBottleNum",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库存(箱)",
                    align: "center",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            _vm._s(
                              row.isComProd == 2 ? "-" : row.storeInventory
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "margin-top": "12px" },
            attrs: { shadow: "hover", "body-style": _vm.btncardPadding },
          },
          [
            _c(
              "div",
              { staticClass: "btnWrap" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "default", plain: "" },
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.take(1)
                      },
                    },
                  },
                  [_vm._v("生成提货单")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }