"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _salesman = require("@/api/writeOffModule/salesman2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagewriteOffModulesalesmanindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagewriteOffModulesalesmanindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var _ref;
    return _ref = {
      queryParams: {
        status: '',
        keyWord: ''
      },
      tableData: [],
      dialogNumber: false,
      formInline: {
        awardName: '',
        name: '',
        addTimeStr: ''
      },
      dialogEdit: false,
      salesmanFrom: {
        'addTime': '',
        'companyId': 0,
        'dealerId': 0,
        'id': 0,
        'name': '',
        'openId': '',
        'productBrand': '',
        'remark': '',
        'status': '',
        'tel': '',
        'unionid': '',
        'updateTime': ''
      },
      listLoadig: false,
      salesmanRules: {
        tel: [{
          required: true,
          pattern: /^1[34578]\d{9}$/,
          message: '目前只支持中国大陆的手机号码'
        }]
      }
    }, (0, _defineProperty2.default)(_ref, "queryParams", {
      pageSize: 10,
      pageNo: 1,
      keywords: '',
      status: ''
    }), (0, _defineProperty2.default)(_ref, "total", 0), _ref;
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getSalesmanList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取业务员列表列表
    getSalesmanList: function getSalesmanList() {
      var _this = this;
      (0, _salesman.getSalesmanList)(this.queryParams).then(function (res) {
        _this.listLoadig = false;
        if (res.data.data.errCode) {
          _this.$message(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this.total = parseInt(res.data.data.total);
            _this.tableData = res.data.data.records;
          } else {
            _this.total = 0;
            _this.tableData = [];
          }
        }
      }).finally(function () {
        _this.listLoadig = false;
      });
    },
    // 导出业务员列表列表
    expSalesmanList: function expSalesmanList() {
      var _this2 = this;
      console.log(this.queryParams, 123);
      (0, _salesman.expSalesmanList)(this.queryParams).then(function (res) {
        console.log(res.data, 'res');
        _this2.$message.success('导出成功！');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '业务员列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 启用停用切换
    stop: function stop(row) {
      var _this3 = this;
      var id = row.status == 1 ? 2 : 1;
      var str = row.status == 1 ? '冻结' : '解冻';
      this.$confirm("\u662F\u5426".concat(str, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        if (row.status == 1) {
          (0, _salesman.endSalesman)({
            salesmanId: row.id
          }).then(function (res) {
            _this3.getSalesmanList();
            _this3.$message.success('' + str + '成功');
          });
        } else {
          (0, _salesman.startSalesman)({
            salesmanId: row.id
          }).then(function (res) {
            _this3.getSalesmanList();
            _this3.$message.success('' + str + '成功');
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleNumber: function handleNumber(row) {
      console.log(row);
      this.dialogNumber = true;
      this.$router.push({
        path: '/marketingManage/writeOffModule/writeOffFlow/index',
        query: {
          id: row.id
        }
      });
    },
    handleEdit: function handleEdit(row) {
      console.log(row);
      this.salesmanFrom.id = row.id;
      this.salesmanFrom.companyId = row.companyId;
      this.salesmanFrom.name = row.name;
      this.salesmanFrom.tel = row.tel;
      this.salesmanFrom.addTimeStr = row.addTimeStr;
      this.salesmanFrom.openId = row.openId;
      this.salesmanFrom.status = row.status;
      this.dialogEdit = true;
    },
    // 保存编辑
    handleSave: function handleSave(salesmanRef) {
      var _this4 = this;
      this.$refs[salesmanRef].validate(function (valid) {
        if (valid) {
          (0, _salesman.saveSalesman)(_this4.salesmanFrom).then(function (res) {
            if (res.data.code !== 200) {
              _this4.$message.error('修改失败！');
              return;
            } else {
              _this4.$message.success('修改成功！');
              _this4.getSalesmanList();
              _this4.dialogEdit = false;
            }
          });
        }
      });
    },
    // 重置搜索
    restSalesmanList: function restSalesmanList() {
      this.queryParams.status = '';
      this.queryParams.keywords = '';
      this.getSalesmanList();
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1, this.getSalesmanList();
    },
    showStatus: function showStatus(row) {
      if (row.status == 1) return '正常';
      return '冻结';
    }
  }
};
exports.default = _default;