"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExchangeRecordPage = getExchangeRecordPage;
exports.getExprotExchangeExcel = getExprotExchangeExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
function getExchangeRecordPage(current, size, data) {
  return (0, _request.default)({
    url: base + "/market-center/wordUsedRecord/exchangeRecordPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
function getExprotExchangeExcel(params) {
  return (0, _request.default)({
    url: base + '/market-center/wordUsedRecord/exprotExchangeExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}