"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _work = require("@/api/systems/work");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _user = _interopRequireDefault(require("../component/user"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
var _detail = _interopRequireDefault(require("../component/detail"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemsplcmangeunspindex';
var _default = {
  name: 'systemsplcmangeunspindex',
  components: {
    Pagination: _Pagination.default,
    user: _user.default,
    detail: _detail.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      listQuery: {
        billCode: '',
        billNo: '',
        moduleCode: '',
        orgCode: null,
        dataUserId: '',
        dataUserName: '',
        startTime: '',
        status: '',
        pageNo: 1,
        pageSize: 20
      },
      list: [],
      tableKey: 'spwc',
      total: 0,
      listLoading: false,
      loading: false,
      mloading: false,
      moduleData: [],
      pageData: [],
      orgOptions: [],
      states: [{
        name: '审批中',
        id: '0'
      }, {
        name: '审批结束',
        id: '1'
      }, {
        name: '审批驳回',
        id: '2'
      }],
      userShow: false,
      userType: '',
      showCol: false,
      expandTxt: '展开',
      apis: [],
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      billId: '',
      billNo: '',
      workId: '',
      taskId: '',
      url: '',
      check: false,
      detailShow: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list && that.list.length > 0 ? true : false;
    that.apis = that.$route.meta.authList;
    if (!hasCache) {
      that.getList();
      that.getMoudle();
      that.getPages();
      that.getOrg();
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    rangeTime: function rangeTime(value) {
      if (!value) {
        return '';
      }
      var dateBegin = new Date(value);
      var dateEnd = new Date();
      var dateDiff = dateEnd.getTime() - dateBegin.getTime();
      return (dateDiff / 1000 / 60 / 60).toFixed(1);
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var subData = (0, _utils.deepClone)(that.listQuery);
      delete subData.dataUserName;
      (0, _work.getCheckedExecutionInfoList)(subData).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    getMoudle: function getMoudle() {
      var that = this;
      (0, _work.getMoudleMsg)().then(function (res) {
        if (res.data.code == 200) {
          that.moduleData = res.data.data;
        }
      });
    },
    getPages: function getPages() {
      var that = this;
      var param = {};
      param.code = 'SPLDJLX';
      (0, _work.getPageMsg)(param).then(function (res) {
        if (res.data.code == 200) {
          that.pageData = res.data.data;
        }
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.moduleCode = '';
      that.listQuery.billCode = '';
      that.listQuery.billNo = '';
      that.listQuery.orgCode = null;
      that.listQuery.dataUserId = '';
      that.listQuery.dataUserName = '';
      that.listQuery.startTime = '';
      that.listQuery.status = '';
      that.$refs[formName].resetFields();
      that.listQuery.pageNo = 1;
      that.getList();
    },
    link: function link(data, bool) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var workId = data.pidString;
      var taskId = data.taskId;
      var billId = data.billId;
      var billNo = data.billNo;
      var callbackArgs = data.callbackArgs;
      var check = bool;
      var url = data.detailUrl;
      that.$router.push({
        path: url,
        query: {
          billId: billId,
          workId: workId,
          taskId: taskId,
          billNo: billNo,
          callbackArgs: callbackArgs,
          check: check
        }
      });
    },
    detailClose: function detailClose() {
      var that = this;
      that.detailShow = false;
    },
    //选择发起人，上审批人
    selectUser: function selectUser(arg) {
      var that = this;
      that.userType = arg;
      that.userShow = true;
    },
    hideUser: function hideUser() {
      var that = this;
      that.userShow = false;
    },
    getUser: function getUser(data) {
      var that = this;
      that.listQuery.dataUserName = data.userName;
      that.listQuery.dataUserId = data.pid;
    },
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;