"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _specAddOrUpdate = _interopRequireDefault(require("./spec-add-or-update"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _integralMall = require("@/api/integral/integralMall");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'integralMallprodspec';
var _default = {
  name: 'IntegralMallprodspec',
  components: {
    AddOrUpdate: _specAddOrUpdate.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tabHeight: '100%',
      listLoading: false,
      dataForm: {
        prodProp: ''
      },
      dataList: [],
      current: 1,
      size: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
      total: 0,
      // 总页数
      formInline: {
        propName: null,
        current: 1,
        // 当前页数
        size: 10 // 每页显示多少条
      },

      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.dataList && that.dataList.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    if (!hasCache) {
      that.fetchData();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.dataList = [];
      that.total = 0;
    }
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    fetchData: function fetchData() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      (0, _integralMall.specList)(this.formInline).then(function (data) {
        if (data.status === 200) {
          _this.dataList = data.data.records;
          _this.total = data.data.total;
          _this.listLoading = false;
        }
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(val) {
      var _this2 = this;
      if (typeof id == 'undefined') {
        this.authId('addbtn', this.apis);
      } else {
        this.authId('updatebtn', this.apis);
      }
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(val);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this3 = this;
      this.$confirm("\u786E\u5B9A\u5220\u9664\u8BE5\u89C4\u683C?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('deletebtn', _this3.apis);
        (0, _integralMall.delSpec)(id).then(function (data) {
          _this3.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this3.fetchData(_this3.page);
            }
          });
        });
      }).catch(function () {});
    },
    searchChange: function searchChange(params) {
      this.fetchData(this.formInline);
    }
  }
};
exports.default = _default;