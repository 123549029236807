"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFangweiList = addFangweiList;
exports.checkMark = checkMark;
exports.continueBill = continueBill;
exports.deleteBillByPid = deleteBillByPid;
exports.deleteWarningByPid = deleteWarningByPid;
exports.deriveBillInfo = deriveBillInfo;
exports.editBill = editBill;
exports.exportData = exportData;
exports.getBillByPid = getBillByPid;
exports.getBillList = getBillList;
exports.getBillType = getBillType;
exports.getDealerList = getDealerList;
exports.getDetail = getDetail;
exports.getErrors = getErrors;
exports.getFangweiList = getFangweiList;
exports.getMarkReplace = getMarkReplace;
exports.getMaterial = getMaterial;
exports.getProductList = getProductList;
exports.getSettle = getSettle;
exports.getSupplierList = getSupplierList;
exports.getWainingByPid = getWainingByPid;
exports.getWareList = getWareList;
exports.getWlDec = getWlDec;
exports.getWuLiaoList = getWuLiaoList;
exports.insertBill = insertBill;
exports.replaceAllMark = replaceAllMark;
exports.updateErrors = updateErrors;
exports.updateFangweiList = updateFangweiList;
var _request = _interopRequireDefault(require("@/utils/request"));
//分页查询数码替换列表
function getMarkReplace(param) {
  return (0, _request.default)({
    url: '/storage-center/markReplaceOperate/queryMarkReplaceForPage',
    method: 'get',
    params: param
  });
}
//下账单查询
function getSettle(data) {
  return (0, _request.default)({
    url: '/storage-center/billLogSettle/getBillLogSettle',
    method: 'post',
    data: data
  });
}
//下账单详情
function getDetail(param) {
  return (0, _request.default)({
    url: '/storage-center/bill/getByBillNum',
    method: 'get',
    params: param
  });
}
//数码校验
function checkMark(param) {
  return (0, _request.default)({
    url: '/storage-center/markReplaceOperate/checkMark',
    method: 'get',
    params: param
  });
}
//数码替换
function replaceAllMark(data) {
  return (0, _request.default)({
    url: '/storage-center/markReplaceOperate/replaceMark',
    method: 'put',
    data: data
  });
}
//分页查询防伪防窜预警设置
function getFangweiList(data) {
  return (0, _request.default)({
    url: '/api-basic/basic/antiWarningConfig/getList',
    method: 'post',
    data: data
  });
}
//添加防窜预警
function addFangweiList(data) {
  return (0, _request.default)({
    url: '/api-basic/basic/antiWarningConfig/insert',
    method: 'post',
    data: data
  });
}
//修改防窜预警
function updateFangweiList(data) {
  return (0, _request.default)({
    url: '/api-basic/basic/antiWarningConfig/update',
    method: 'post',
    data: data
  });
}
//删除防伪防窜预警
function deleteWarningByPid(param) {
  return (0, _request.default)({
    url: '/api-basic/basic/antiWarningConfig/delete',
    method: 'post',
    params: param
  });
}
//获取防伪防窜预警详情
function getWainingByPid(param) {
  return (0, _request.default)({
    url: '/api-basic/basic/antiWarningConfig/getDetailById',
    method: 'post',
    params: param
  });
}

//生产订单详情
function getProductList(param) {
  return (0, _request.default)({
    url: '/storage-center/productOrder/queryForList',
    method: 'get',
    params: param
  });
}

//其他出入库单查询
function getBillList(param) {
  return (0, _request.default)({
    url: '/storage-center/otherOutInPutWareHouse/search',
    method: 'get',
    params: param
  });
}
//错误消息配置
function getErrors() {
  return (0, _request.default)({
    url: '/api-basic/sysConfigMsg/getConfig',
    method: 'get'
  });
}
//修改错误消息
function updateErrors(data) {
  return (0, _request.default)({
    url: '/api-basic/sysConfigMsg/updateConfig',
    method: 'post',
    data: data
  });
}
//其他出入库单获取所有库房
function getWareList() {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/getWarehouseList',
    method: 'get'
  });
}
//物料弹窗
function getWuLiaoList(param) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/searchMaterialInfo',
    method: 'get',
    params: param
  });
}
// 继续下账
function continueBill(param) {
  return (0, _request.default)({
    url: '/storage-center/billLogSettle/settleBill',
    method: 'get',
    params: param
  });
}
function getWlDec() {
  return (0, _request.default)({
    url: '/storage-center/bill/args/getAliveMatterDescr',
    method: 'get'
  });
}
function getMaterial() {
  return (0, _request.default)({
    url: '/storage-center/bill/args/getAliveMatterDescr',
    method: 'get'
  });
}

// 创建出入库单 获取操作类型
function getBillType(sType) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/getOrderDefinedList',
    method: 'get',
    params: {
      orderBelong: sType
    }
  });
}
// 创建出入库单 获取供应商信息
function getSupplierList(param) {
  return (0, _request.default)({
    url: '/api-basic/supplierInfo/list',
    method: 'get',
    params: param
  });
}
// 创建出入库单 获取经销商信息
function getDealerList(param) {
  return (0, _request.default)({
    url: '/api-basic/dealerInfo/list',
    method: 'get',
    params: param
  });
}
/**
 * 指定id查看出入库明细
 * @param {*} id
 */
function getBillByPid(pId) {
  return (0, _request.default)({
    url: '/storage-center/otherOutInPutWareHouse/searchDetail',
    method: 'get',
    params: {
      pId: pId
    }
  });
}
/**
 * 指定pid删除出入库
 * @param {*} pid
 */
function deleteBillByPid(pId) {
  return (0, _request.default)({
    url: '/storage-center/otherOutInPutWareHouse/delete',
    method: 'delete',
    params: {
      pId: pId
    }
  });
}
//更新出入库订单
function editBill(data) {
  return (0, _request.default)({
    url: '/storage-center/otherOutInPutWareHouse/update',
    method: 'PUT',
    data: data
  });
}
//插入出入库订单
function insertBill(data) {
  return (0, _request.default)({
    url: '/storage-center/otherOutInPutWareHouse/insert',
    method: 'post',
    data: data
  });
}

//导出
function exportData(data) {
  //console.log(data);
  return (0, _request.default)({
    url: '/storage-center/billLogSettle/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

//销售出库数码导出
function deriveBillInfo(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/deriveBillInfo',
    method: 'post',
    params: params
  });
}