"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delQcData = delQcData;
exports.exportCgRkList = exportCgRkList;
exports.exportDbRkList = exportDbRkList;
exports.exportQcData = exportQcData;
exports.exportQtRkList = exportQtRkList;
exports.getCgDetail = getCgDetail;
exports.getCgRkList = getCgRkList;
exports.getCodeDetail = getCodeDetail;
exports.getDbDetail = getDbDetail;
exports.getDbRkList = getDbRkList;
exports.getPackUnits = getPackUnits;
exports.getQcDetail = getQcDetail;
exports.getQcRkList = getQcRkList;
exports.getQtDetail = getQtDetail;
exports.getQtRkList = getQtRkList;
exports.getQueryOrg = getQueryOrg;
exports.getType = getType;
exports.inOrOutStockExport = inOrOutStockExport;
exports.instockfyj = instockfyj;
exports.instockfyjExport = instockfyjExport;
exports.instockfyjdetail = instockfyjdetail;
exports.upLoadImg = upLoadImg;
exports.updateQcData = updateQcData;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取期初列表
function getQcRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockqc',
    method: 'get',
    params: param
  });
}

// 期初入库导出
function exportQcData(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockqcExport',
    responseType: 'blob',
    method: 'post',
    params: param
  });
}

// 获取期初入库详情
function getQcDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/instockdetail/instockqcdetail',
    method: 'get',
    params: param
  });
}

// 期初入库新增/修改
function updateQcData(data) {
  return (0, _request.default)({
    url: '/api-channel/initialWarehouse/saveOrSubmit',
    method: 'post',
    data: data
  });
}

// 期初入库删除
function delQcData(param) {
  return (0, _request.default)({
    url: '/api-channel/initialWarehouse/delInitialWarehouse',
    method: 'delete',
    params: param
  });
}

// 图片上传
function upLoadImg(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadImg',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

// 获取采购入库列表
function getCgRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockcg',
    method: 'get',
    params: param
  });
}

// 采购入库导出
function exportCgRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockcgExport',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

// 获取入库业务类型
function getType(param) {
  return (0, _request.default)({
    url: '/api-channel/common/businesstype',
    method: 'get',
    params: param
  });
}

// 获取采购入库详情
function getCgDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/instockdetail/instockcgdetail',
    method: 'get',
    params: param
  });
}

// 获取数码查看详情
function getCodeDetail(data) {
  return (0, _request.default)({
    url: '/api-channel/qd/orderInfoCode/getCodeByOrderId',
    method: 'post',
    data: data
  });
}

// 获取调拨入库列表
function getDbRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockdb',
    method: 'get',
    params: param
  });
}

// 调拨入库导出
function exportDbRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockdbExport',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

// 获取调拨入库详情
function getDbDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/instockdetail/instockdbdetail',
    method: 'get',
    params: param
  });
}

// 获取其他入库列表
function getQtRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockqt',
    method: 'get',
    params: param
  });
}

// 其他入库导出
function exportQtRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockqtExport',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

// 获取其他入库详情
function getQtDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/instockdetail/instockqtdetail',
    method: 'get',
    params: param
  });
}

// 包装比例单位详情
function getPackUnits(param) {
  return (0, _request.default)({
    url: '/api-basic/packScaleLevel/findScaleItem',
    method: 'get',
    params: param
  });
}

// 获取费用酒入库列表
function instockfyj(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockfyj',
    method: 'get',
    params: param
  });
}

// 采购入库导出
function instockfyjExport(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/instockfyjExport',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

// 获取采购入库详情
function instockfyjdetail(param) {
  return (0, _request.default)({
    url: '/api-channel/instockdetail/instockfyjdetail',
    method: 'get',
    params: param
  });
}

/**
 * @description 出入库导出
 * @param {String} param.billType Must - 101：生产入库 102：调货入库 103：退货入库 104：采购入库 105：其它（材料）入库 201：返工出库 202：调货出库 203：销售出库 204：退货出库 205：其它（材料）出库
 */
function inOrOutStockExport(param) {
  return (0, _request.default)({
    url: '/api-channel/instock/exportForExcel',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}