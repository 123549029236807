"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeHouseInfoDel = storeHouseInfoDel;
exports.storeHouseInfoInsert = storeHouseInfoInsert;
exports.storeHouseInfoList = storeHouseInfoList;
exports.storeHouseInfoUpdate = storeHouseInfoUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''
function storeHouseInfoList(data) {
  return (0, _request.default)({
    url: modelUrl + '/storeHouseInfo/list',
    method: 'post',
    data: data
  });
}
function storeHouseInfoInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/storeHouseInfo/insert',
    method: 'post',
    data: data
  });
}
function storeHouseInfoUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/storeHouseInfo/update',
    method: 'post',
    data: data
  });
}
function storeHouseInfoDel(params) {
  return (0, _request.default)({
    url: modelUrl + '/storeHouseInfo/enableProductBrand',
    method: 'get',
    params: params
  });
}