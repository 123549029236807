"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addStrategy = addStrategy;
exports.deleteStrategy = deleteStrategy;
exports.getStrategy = getStrategy;
exports.getStrategyType = getStrategyType;
exports.remarks = remarks;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 策略列表
function getStrategy(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/strategy/strategyInfoQuery',
    method: 'post',
    data: data
  });
}

// 新增策略
function addStrategy(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/strategy/strategyInfoSave',
    method: 'post',
    data: data
  });
}

// 点击备注
function remarks(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/strategy/strategyInfoRemark',
    method: 'get',
    params: params
  });
}

// 删除策略
function deleteStrategy(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/strategy/strategyInfoDelete',
    method: 'get',
    params: params
  });
}

// 获取策略类型
function getStrategyType(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/strategy/getStrategyType',
    method: 'get',
    params: params
  });
}