"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportExcel = ImportExcel;
exports.actAddReserve = actAddReserve;
exports.actDisable = actDisable;
exports.actconfigAdd = actconfigAdd;
exports.actconfigCopy = actconfigCopy;
exports.activityUserpage = activityUserpage;
exports.addWechatActConfig = addWechatActConfig;
exports.appendCreate = appendCreate;
exports.batchUpdateBase = batchUpdateBase;
exports.batchUpdatePr = batchUpdatePr;
exports.batchUpdatePrValid = batchUpdatePrValid;
exports.batchUpdatePrize = batchUpdatePrize;
exports.checkCodeSegment = checkCodeSegment;
exports.creatPrList = creatPrList;
exports.createUser = createUser;
exports.del = del;
exports.delActPr = delActPr;
exports.detailActPr = detailActPr;
exports.detailExportExcel = detailExportExcel;
exports.detailpage = detailpage;
exports.endAct = endAct;
exports.exportErrorChannelUser = exportErrorChannelUser;
exports.exportExcel = exportExcel;
exports.exportmarketList = exportmarketList;
exports.geActList = geActList;
exports.geActPrList = geActPrList;
exports.getActFeeType = getActFeeType;
exports.getActPlan = getActPlan;
exports.getActPromotionType = getActPromotionType;
exports.getActType = getActType;
exports.getAudit = getAudit;
exports.getBatchUpdatePrize = getBatchUpdatePrize;
exports.getChosenProduct = getChosenProduct;
exports.getComboTemplateStoreroom = getComboTemplateStoreroom;
exports.getConnectedActivities = getConnectedActivities;
exports.getMerchantListAll = getMerchantListAll;
exports.getMoneySource = getMoneySource;
exports.getOrderActList = getOrderActList;
exports.getPreview = getPreview;
exports.getPrizeLists = getPrizeLists;
exports.getProcess = getProcess;
exports.getProduct = getProduct;
exports.getProductBatch = getProductBatch;
exports.getStrategyGroup = getStrategyGroup;
exports.getTemplateUrl = getTemplateUrl;
exports.getorderNum = getorderNum;
exports.getsoldExcelin = getsoldExcelin;
exports.initiateAudit = initiateAudit;
exports.isExistConsumeWechat = isExistConsumeWechat;
exports.postAudit = postAudit;
exports.prizeManage = prizeManage;
exports.rankDown = rankDown;
exports.rankUp = rankUp;
exports.soldExcelin = soldExcelin;
exports.switchStatus = switchStatus;
exports.update = update;
exports.updateActPr = updateActPr;
exports.updateUser = updateUser;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
//  获取活动列表
function geActList(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/page',
    method: 'post',
    data: data
  });
}

// 策略组
function getStrategyGroup() {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/strategyGroup/comboBox',
    method: 'post'
  });
}
// 策略组与活动页相关联
function getConnectedActivities(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/strategyGroup/activityPage',
    method: 'get',
    params: params
  });
}
// 资金来源
function getMoneySource() {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/officialFundConfiguration/fundConfigurationComboBox',
    method: 'get'
  });
}

// 活动流程
function getProcess() {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/process/comboBox',
    method: 'post'
  });
}

// 活动模板
function getComboTemplateStoreroom(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/getComboTemplateStoreroom',
    method: 'get',
    params: param
  });
}
// 商户号列表
function getMerchantListAll() {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/merchantCodeInfo/merchantListAll',
    method: 'get'
  });
}
// 奖品列表
function getPrizeLists(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/prize/getPrizeListAll',
    method: 'post',
    data: data
  });
}

// 验证--批量编辑概率规则
function batchUpdatePrValid(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/batchUpdatePr/valid',
    method: 'post',
    data: data
  });
}

// 批量编辑概率规则
function batchUpdatePr(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/batchUpdatePr',
    method: 'post',
    data: data
  });
}
function getBatchUpdatePrize(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/batchUpdatePrize/valid',
    method: 'post',
    data: data
  });
}

// 更新批量编辑活动基础设置
function batchUpdateBase(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/batchUpdateBase',
    method: 'post',
    data: data
  });
}
function batchUpdatePrize(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/batchUpdatePrize',
    method: 'post',
    data: data
  });
}

// 导出活动
function exportExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

// 修改活动配置
function update(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/update',
    method: 'POST',
    data: data
  });
}
// 添加活动
function actconfigAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/create',
    method: 'POST',
    data: data
  });
}

// 复制活动
function actconfigCopy(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/copyCreate',
    method: 'POST',
    data: data
  });
}
// 追加
function appendCreate(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/appendCreate',
    method: 'POST',
    data: data
  });
}
// 活动详情(原平台)
function detailpage(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/detail',
    method: 'get',
    params: params
  });
}
// 启用-禁用操作
function switchStatus(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/switchStatus',
    method: 'get',
    params: params
  });
}

// 启用停用活动(原平台)
function actDisable(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/onOrOff',
    method: 'get',
    params: params
  });
}

// 概率分页查询
function geActPrList(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/page',
    method: 'get',
    params: params
  });
}

// 新建概率
function creatPrList(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/create',
    method: 'post',
    data: data
  });
}

// 删除活动概率
function delActPr(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/delete',
    method: 'delete',
    params: param
  });
}

// 活动概率升序
function rankUp(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/up',
    method: 'get',
    params: param
  });
}

// 活动概率降序
function rankDown(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/down',
    method: 'get',
    params: param
  });
}

// 活动概率详情
function detailActPr(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/detail',
    method: 'get',
    params: param
  });
}

// 活动概率编辑
function updateActPr(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityPrConfig/update',
    method: 'post',
    data: data
  });
}

// 获取概览数据
function getPreview(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/preview',
    method: 'get',
    params: param
  });
}

// 活动删除(原平台)
function del(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/del',
    method: 'delete',
    params: params
  });
}

//  结束活动 (原平台)
function endAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/endAct',
    method: 'PUT',
    params: params
  });
}
//  获取产品列表 (原平台)
function getProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actactivityRange/getProduct',
    method: 'get',
    params: params
  });
}
// 获取活动已选择产品(原平台)
function getChosenProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actactivityRange/getChosenProduct',
    method: 'get',
    params: params
  });
}
// 获活动产品批次(原平台)
function getProductBatch(params) {
  return (0, _request.default)({
    url: baseUrl + '/storage-center/ccProductOrder/marketing/batchForPage',
    method: 'post',
    params: {
      current: params.current,
      size: params.size
    },
    data: {
      batchNo: params.batchNo,
      productIdList: params.productIdList
    }
  });
}
// /actconfig/prizeManage 奖品管理 (原平台)
function prizeManage(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/prizeManage?actNo=' + data,
    method: 'POST',
    data: data
  });
}
// 增加库存 (原平台)
function actAddReserve(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/actAddReserve?actConfigItemId=' + data.actConfigItemId + '&num=' + data.num,
    method: 'POST',
    data: data
  });
}

// 是否存在消费者公众号(原平台)
function isExistConsumeWechat(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/wechat/official/isExistConsumeWechat',
    method: 'get',
    params: params
  });
}

// 创建一物一码活动(原平台)
function addWechatActConfig(data) {
  return (0, _request.default)({
    url: baseUrl + '/WechatActConfig/addConfig',
    method: 'POST',
    data: data
  });
}

// 获取订单列表(原平台)
function getOrderActList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getOrderActList',
    method: 'POST',
    data: data
  });
}

// 获取订单已使用数码数量(原平台)
function getorderNum(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getorderNum',
    method: 'get',
    params: params
  });
}

// 发起审核(原平台)
function initiateAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/initiate',
    method: 'post',
    data: params
  });
}

// 活动审核(原平台)
function postAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/audit',
    method: 'post',
    data: params
  });
}

// 获取活动审核记录(原平台)
function getAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getAudit',
    method: 'post',
    data: params
  });
}

// 导出活动记录(原平台)
function soldExcelin(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/actlistExport',
    method: 'post',
    data: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}

// 导出订单记录(原平台)
function getsoldExcelin(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getOrderActListExport',
    method: 'post',
    data: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}
// 活动创建导入 1产品分类 2产品黑名单 3经销商 4终端 5订单
function ImportExcel(file, params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/importExcel',
    method: 'post',
    file: file,
    params: params
  });
}
// 主计划
// 获取活动类型
function getActType(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/main-plan/act-type',
    method: 'get',
    params: params
  });
}
// 获取费用类型
function getActFeeType(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/main-plan/fee-type',
    method: 'get',
    params: params
  });
}
// 获取促销形式
function getActPromotionType(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/main-plan/promotion-type',
    method: 'get',
    params: params
  });
}
function getActPlan(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/main-plan/list',
    method: 'get',
    params: params
  });
}

// 查看导出
function detailExportExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/detailExportExcel',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

// 终端用户列表
// 终端用户分页查询
function activityUserpage(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/page',
    method: 'POST',
    data: data
  });
}

// 终端用户导出
function exportmarketList(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/exportList',
    method: 'post',
    data: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}

// 查询导入模板链接
function getTemplateUrl(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/getTemplateUrl',
    method: 'get',
    params: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}
// 创建终端用户
function createUser(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/create',
    method: 'POST',
    data: data
  });
}

// 修改终端用户
function updateUser(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/update',
    method: 'POST',
    data: data
  });
}

// 导出失败明细
function exportErrorChannelUser(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityUser/exportErrorChannelUser',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

// 校验套标号段
function checkCodeSegment(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/checkCodeSegment',
    method: 'post',
    data: data
  });
}