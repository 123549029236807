"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _label_group_manage_con = _interopRequireDefault(require("./label_group_manage_con"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const labelTemp = {
//   pid: 0,
//   flagCode: '',
//   flagName: '',
//   creatorId: 0,
//   isDelete: 0,
//   flagType: 1 // 标签组织类型 1：标签，2：盖
// }
var _default = {
  name: 'ComplexTable',
  components: {
    labelcon: _label_group_manage_con.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  props: ['supplierName', 'supplierType', 'supplierCode'],
  data: function data() {
    return {
      tableKey: 0,
      LabelList: null,
      listLoading: true,
      // labelTemp: labelTemp,
      flagGroupId: '',
      // flagName: '',
      // dialogLabeFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'flagName'
      },
      listQuery: {
        supplierName: this.supplierName,
        supplierCode: this.supplierCode,
        supplierType: this.supplierType,
        relationType: ''
      }
    };
  },
  mounted: function mounted() {
    // this.getList();
    // this.getLabelList()
  },
  methods: {
    getLabelList: function getLabelList(type) {
      var _this = this;
      // 获取所有标签列表
      this.listLoading = true;
      if (type == 3) {
        type = '';
      }
      (0, _supplierRFlag.supplierRFlagList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        relationType: type
      })).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null && data.data.records && data.data.records.length > 0 && data.data.records[0].flagCode) {
          _this.LabelList = data.data.records[0].flagCode;
          if (type == 3) {
            _this.handleNodeClick();
          } else {
            _this.handleNodeClick(data.data.records[0].flagCode[0]);
          }
        } else {
          _this.LabelList = null;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleNodeClick: function handleNodeClick(data, node) {
      // 标签组点击事件
      // this.flagCode = data.flagCode
      // this.flagName = data.flagName
      this.$refs.list_con.listQuery.flagId = data.pid;
      this.flagGroupId = data.pid;
      this.$refs.list_con.listQuery.supplierType = this.listQuery.supplierType;
      this.$refs.list_con.getModelList();
    }
  }
};
exports.default = _default;