"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _winningLottery = require("@/api/operationalTools/winningLottery");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolswinningLotteryindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolswinningLotteryindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    // 奖品形式
    prizeType: function prizeType(msg) {
      if (msg == 100) {
        return '红包';
      } else if (msg == 200) {
        return '实物';
      } else if (msg == 300) {
        return '优惠券';
      } else if (msg == 400) {
        return '积分';
      } else if (msg == 500) {
        return '字卡';
      } else if (msg == 900) {
        return '礼包';
      } else if (msg == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    // 领取状态
    status: function status(msg) {
      if (msg == 1) {
        return '未发放';
      } else if (msg == 2) {
        return '已发放';
      } else if (msg == 3) {
        return '未核销';
      } else if (msg == 4) {
        return '已核销';
      } else if (msg == 5) {
        return '失败';
      } else if (msg === 0) {
        return '未领取';
      } else {
        return '';
      }
    },
    // 状态
    wxStatus: function wxStatus(msg) {
      if (msg == 0) {
        return '正常';
      } else if (msg == 1) {
        return '限制';
      } else if (msg == 2) {
        return '黑名单';
      } else {
        return '';
      }
    }
  },
  data: function data() {
    return {
      actList: [],
      agentList: [],
      prizeList: [],
      productList: [],
      queryParams: {
        activityId: '',
        agentId: '',
        ipAddress: '',
        joinEndDate: '',
        joinStartDate: '',
        markCode: '',
        openIdOrUserId: '',
        prizeId: '',
        productId: '',
        sendEndDate: '',
        sendStartDate: '',
        status: '',
        roleId: '',
        winStatus: ''
      },
      current: 1,
      size: 10,
      total: 0,
      expandTxt: '展开',
      showCol: false,
      rangeTime: '',
      rangeTime2: '',
      // 表格
      listLoading: false,
      tableData: [],
      // 详情
      detailsList: {
        activityInfo: {
          activityName: '',
          joinDateTime: '',
          prizeName: '',
          prizeType: '',
          sendDateTime: '',
          status: ''
        },
        markInfo: {
          agentName: '',
          markCode: '',
          productName: ''
        },
        wechatUserInfo: {
          headImgUrl: '',
          ipAddress: '',
          memberId: '',
          nickName: '',
          openId: '',
          unionId: '',
          wxStatus: ''
        }
      },
      collectInfo: [],
      show: true,
      look: false,
      popupExportDetail: false,
      exportParams: {
        total: 0,
        current: 1,
        size: 10
      },
      exportDataList: [],
      exportDataListLoading: false
    };
  },
  activated: function activated() {
    // this.getActivityList()
    this.getActivity();
    this.getAgentList();
    this.getPrizeList();
    this.getProductList();
    var hasCache1 = this.tableData.length > 0;
    // 其他页面体跳转到本页
    this.queryParams.activityId = this.$route.params.actid ? this.$route.params.actid : '';
    this.queryParams.openIdOrUserId = this.$route.params.userId ? this.$route.params.userId : '';
    if (this.queryParams.activityId || this.queryParams.openIdOrUserId || !hasCache1) {
      this.queryParams.agentId = '';
      this.queryParams.ipAddress = '';
      this.queryParams.joinEndDate = '';
      this.queryParams.markCode = '';
      this.queryParams.prizeId = '';
      this.queryParams.sendEndDate = '';
      this.queryParams.sendStartDate = '';
      this.queryParams.status = '';
      this.queryParams.winStatus = '';
      this.getCurrentData();
      this.getWinningLottery();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    openExportDetail: function openExportDetail() {
      this.queryExprotActivityForPage(this.current, this.size);
      this.popupExportDetail = true;
    },
    hadnleCloseExportDetail: function hadnleCloseExportDetail() {
      this.popupExportDetail = false;
    },
    queryExprotActivityForPage: function queryExprotActivityForPage(current, size) {
      var _this = this;
      this.exportDataListLoading = true;
      (0, _winningLottery.queryExprotActivityForPage)({
        current: current,
        size: size
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.code || res.data.data.code == '0') {
            _this.exportDataListLoading = false;
            return _this.$message.error(res.data.data.msg);
          } else {
            _this.exportDataListLoading = false;
            _this.exportParams.total = parseInt(res.data.data.total);
            _this.exportParams.current = parseInt(res.data.data.current);
            _this.exportParams.size = parseInt(res.data.data.size);
            _this.exportDataList = res.data.data.records;
          }
        } else {
          return _this.$message.error(res.data.msg);
        }
      });
    },
    routerErrorPrize: function routerErrorPrize(mchBillNo, isto) {
      if (isto) {
        this.$message.error('该数据未查询到失败记录');
        return;
      }
      this.$router.push({
        name: 'marketingManageoperationalToolsawardFailureListindex',
        params: {
          mchBillNo: mchBillNo
        }
      });
    },
    getCurrentData: function getCurrentData() {
      var startDate = (0, _utils.parseTime)(new Date(new Date(new Date()).getTime() - 24 * 60 * 60 * 1000 * 1));
      var endDate = (0, _utils.parseTime)(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1));
      this.rangeTime = [startDate, endDate];
      this.sj(this.rangeTime);
    },
    // 活动名称
    getActivityList: function getActivityList() {
      var _this2 = this;
      (0, _winningLottery.getActivityList)().then(function (res) {
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this2.actList = res.data.data;
          }
        }
      });
    },
    // 新活动名称下拉
    getActivity: function getActivity() {
      var _this3 = this;
      (0, _winningLottery.getActivity)().then(function (res) {
        if (res.data.data.errCode) {
          _this3.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this3.actList = res.data.data;
          }
        }
      });
    },
    // 经销商
    getAgentList: function getAgentList() {
      var _this4 = this;
      (0, _winningLottery.getAgentList)().then(function (res) {
        if (res.data.data.errCode) {
          _this4.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this4.agentList = res.data.data;
          }
        }
      });
    },
    // 奖项
    getPrizeList: function getPrizeList() {
      var _this5 = this;
      (0, _winningLottery.getPrizeList)().then(function (res) {
        if (res.data.data.errCode) {
          _this5.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this5.prizeList = res.data.data;
          }
        }
      });
    },
    // 活动产品
    getProductList: function getProductList() {
      var _this6 = this;
      (0, _winningLottery.getProductList)().then(function (res) {
        if (res.data.data.errCode) {
          _this6.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this6.productList = res.data.data;
          }
        }
      });
    },
    // 获取中奖流水列表
    getWinningLottery: function getWinningLottery() {
      this.listLoading = true;
      var that = this;
      (0, _winningLottery.getWinningLottery)(this.current, this.size, this.queryParams).then(function (res) {
        if (res.data.data.errCode == 500) {
          that.$message.error(res.data.data.errMsg);
          that.total = 0;
          that.tableData = [];
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 获取详情
    QueryActivityUserDetail: function QueryActivityUserDetail(id) {
      var _this7 = this;
      (0, _winningLottery.QueryActivityUserDetail)({
        id: id
      }).then(function (res) {
        // console.log(res.data);
        if (res.data.data.errCode == 500) {
          _this7.$message.error(res.data.data.errMsg);
          _this7.look = false;
          return;
        } else {
          _this7.detailsList = res.data.data;
          _this7.collectInfo = JSON.parse(res.data.data.collectInfo);
          if (!_this7.collectInfo == []) {
            _this7.show = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    showPrizeType: function showPrizeType(row) {
      if (row.prizeType == 100) {
        return '红包';
      } else if (row.prizeType == 200) {
        return '实物';
      } else if (row.prizeType == 300) {
        return '优惠券';
      } else if (row.prizeType == 400) {
        return '积分';
      } else if (row.prizeType == 500) {
        return '字卡';
      } else if (row.prizeType == 900) {
        return '礼包';
      } else if (row.prizeType == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 重置搜索框
    resetWinningLottery: function resetWinningLottery() {
      this.current = 1;
      this.$refs['queryParams'].resetFields();
      this.queryParams.activityId = '';
      this.queryParams.openIdOrUserId = '';
      this.rangeTime = '';
      this.rangeTime2 = '';
      this.queryParams.joinStartDate = '';
      this.queryParams.joinEndDate = '';
      this.queryParams.sendStartDate = '';
      this.queryParams.sendEndDate = '';
      console.log(this.queryParams);
      this.getCurrentData();
      this.getWinningLottery();
    },
    // 搜索
    querylist: function querylist() {
      this.current = 1;
      this.getWinningLottery();
    },
    sj: function sj(res) {
      console.log(res);
      if (res) {
        this.queryParams.joinStartDate = res[0];
        this.queryParams.joinEndDate = res[1];
      } else {
        this.queryParams.joinStartDate = '';
        this.queryParams.joinEndDate = '';
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.queryParams.sendStartDate = res[0];
        this.queryParams.sendEndDate = res[1];
      } else {
        this.queryParams.sendStartDate = '';
        this.queryParams.sendEndDate = '';
      }
    },
    // 详情
    handleDetail: function handleDetail(res) {
      this.look = true;
      this.QueryActivityUserDetail(res.id);
    },
    handleClose: function handleClose() {
      this.look = false;
      this.detailsList = {
        activityInfo: {
          activityName: '',
          joinDateTime: '',
          prizeName: '',
          prizeType: '',
          sendDateTime: '',
          status: ''
        },
        markInfo: {
          agentName: '',
          markCode: '',
          productName: ''
        },
        wechatUserInfo: {
          headImgUrl: '',
          ipAddress: '',
          memberId: '',
          nickName: '',
          openId: '',
          unionId: '',
          wxStatus: ''
        }
      };
      this.collectInfo = [];
      this.show = true;
    },
    // 导出中奖流水
    exportLs: function exportLs() {
      var _this8 = this;
      // sendExprotRequest getExprotActivity
      (0, _winningLottery.sendExprotRequest)(this.queryParams).then(function (res) {
        // setTimeout(() => {
        //   this.$message.success('导出成功！')
        // }, 2 * 800)
        console.log(res);
        if (res.status == 200) {
          if (res.data && !res.data.errCode) {
            return _this8.$message.success('已加入导出队列，请在导出明细中查看');
          } else {
            return _this8.$message.error(res.data.errMsg);
          }
        } else {
          _this8.$message.error(res.data.msg || res.data.errMsg);
        }

        // var blob = new Blob([res.data], {
        //   type: 'application/vnd.ms-excel;charset=utf-8'
        // })
        // var downloadElement = document.createElement('a')
        // var href = window.URL.createObjectURL(blob)
        // downloadElement.href = href
        // downloadElement.download = '活动参与记录.xlsx'
        // document.body.appendChild(downloadElement)
        // downloadElement.click()
        // document.body.removeChild(downloadElement)
        // window.URL.revokeObjectURL(href)
      });
    }
  }
};
exports.default = _default;