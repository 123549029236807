"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/mark/basic");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _config = require("@/api/fake_expel/config");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Configindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    fakeType: function fakeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '防伪预警',
        2: '防窜预警'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    var validatewarningDesc = function validatewarningDesc(rule, value, callback) {
      var reg = /^[\s]|[ ]$/gi;
      if (reg.test(value)) {
        callback(new Error("预警描述不能以' '开头或结尾"));
      } else {
        callback();
      }
    };
    return {
      tableKey: '',
      tabHeight: '100%',
      listLoading: false,
      btn_loading: false,
      dataForm: {
        type: '',
        warningDesc: '',
        warningThreshold: ''
      },
      listQuery: {
        current: 1,
        size: 20
      },
      list: [],
      total: 0,
      rules: {
        type: [{
          required: true,
          message: '预警类型不能为空，请维护',
          trigger: 'change'
        }],
        warningDesc: [{
          required: true,
          message: '预警描述不能为空，请维护',
          trigger: 'change'
        }, {
          validator: validatewarningDesc,
          trigger: 'change'
        }],
        warningThreshold: [{
          required: true,
          message: '阈值不能为空，请维护',
          trigger: 'change'
        }]
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 340 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _config.getList)(this.listQuery).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this.total = parseInt(response.data.data.total);
            _this.list = response.data.data.records;
          } else {
            _this.total = 0;
            _this.list = [];
          }
          _this.listLoading = false;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    submit: function submit() {},
    add: function add() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          _this2.authId('addbtn', _this2.$route.meta.authList);
          (0, _config.insert)(_this2.dataForm).then(function (res) {
            _this2.authId('searchbtn', _this2.$route.meta.authList);
            _this2.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this2.$message.error(data.msg);
              return false;
            }
            _this2.dialogFormVisible = false;
            _this2.$refs.dataForm.resetFields();
            _this2.getList();
            _this2.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this2.authId('searchbtn', _this2.$route.meta.authList);
            _this2.btn_loading = false;
          });
        }
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('deletebtn', _this3.$route.meta.authList);
        (0, _config.del)({
          pId: id
        }).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          if (res.data.code == 200) {
            _this3.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this3.getList();
          } else {
            _this3.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;