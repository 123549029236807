"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stock = require("@/api/warehouse/stock/stock");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _getPurchSelect = _interopRequireDefault(require("@/components/getPurchSelect"));
var _scanJkStock = _interopRequireDefault(require("@/components/scanJkStock"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: "TableSuply",
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    Treeselect: _vueTreeselect.default,
    getPurchSelect: _getPurchSelect.default,
    scanJkStock: _scanJkStock.default
  },
  data: function data() {
    return {
      searchForm: {
        current: 1,
        size: 20,
        orgId: null,
        productId: "",
        purchaseComId: ""
      },
      tableData: [],
      tableKey: "rn",
      total: 0,
      listLoading: false,
      tabHeight: "100%",
      storeList: [],
      totalNum: 0,
      productName: "",
      orgOptions: [],
      purchaseComer: "",
      purVisible: false,
      sid: "",
      svisible: false,
      receiveComId: "",
      sendOutComId: "",
      productId: "",
      downLoading: false,
      JkVisible: false,
      listQuery: {
        current: 1,
        productId: "",
        receiveComId: "",
        sendOutComId: "",
        size: 10
      },
      DetailData: [],
      mtotal: 10,
      totalBottle: "",
      purchaseOrgId: "",
      saleOrgId: ""
    };
  },
  activated: function activated() {
    var that = this;
    this.authId("searchbtn", this.$route.meta.authList);
    that.getFactoryInfoList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    handleFilter: function handleFilter(temp) {
      var that = this;
      if (temp == "rest") {
        that.searchForm.current = 1;
        that.searchForm.size = 20;
        that.searchForm.productId = "";
        that.searchForm.orgId = that.orgOptions[0].id;
        that.purchaseComId = "";
        that.productName = "";
        that.purchaseComer = "";
      }
      that.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _stock.queryJcForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.listLoading = false;
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records[0].depositDetailVOList;
            that.totalNum = response.data.data.records[0].depositTotalNum;
            that.totalBottle = response.data.data.records[0].depositAtoTotalNum;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
            that.listLoading = false;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 选择产品
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.searchForm.productId = "";
      this.productName = "";
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.searchForm.productId = val.pId;
    },
    // 获取组织机构
    getFactoryInfoList: function getFactoryInfoList() {
      var _this2 = this;
      var that = this;
      var data = {
        orgStatus: 1,
        orgCodeAndName: "",
        orgTypeList: ["2", "3"]
      };
      (0, _select.getXgOrgInfo)(data).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, "pid", "parentPid", "children");
            that.searchForm.orgId = that.orgOptions[0].id;
            _this2.getList();
          }
        }
      }).catch(function () {});
    },
    // 获取客户
    showPurchse: function showPurchse() {
      var that = this;
      var id = that.searchForm.orgId;
      if (id != null && id != "") {
        that.sid = id;
        that.purVisible = true;
      } else {
        that.$message.error("请选择所属企业");
      }
    },
    closeW: function closeW() {
      var that = this;
      that.sid = "";
      that.purVisible = false;
    },
    getcgs: function getcgs(val) {
      var that = this;
      that.purchaseComer = val.companyName;
      that.searchForm.purchaseComId = val.companyId;
    },
    emptyStores: function emptyStores(val) {
      var that = this;
      if (typeof val == "undefined") {
        that.purchaseComer = "";
        that.searchForm.purchaseComId = "";
      }
    },
    clearPur: function clearPur() {
      var that = this;
      that.purchaseComer = "";
      that.searchForm.purchaseComId = "";
    },
    // 导出功能
    downLoad: function downLoad() {
      var _this3 = this;
      this.authId("exportbtn", this.$route.meta.authList);
      var that = this;
      this.downLoading = true;
      var param = {};
      param.productId = that.searchForm.productId;
      param.orgId = that.searchForm.orgId;
      param.purchaseComId = that.purchaseComId;
      (0, _stock.exportData)(param).then(function (res) {
        _this3.authId("searchbtn", _this3.$route.meta.authList);
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "寄库库存.xls";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.authId("searchbtn", _this3.$route.meta.authList);
        _this3.downLoading = false;
      });
    },
    // 获取详情
    getDetail: function getDetail(val) {
      var that = this;
      that.productId = val.productId;
      that.purchaseOrgId = val.purchaseComId;
      that.saleOrgId = that.searchForm.orgId;
      this.authId("detailbtn", this.$route.meta.authList);
      that.svisible = true;
    },
    closeStock: function closeStock() {
      var that = this;
      that.svisible = false;
      this.authId("searchbtn", this.$route.meta.authList);
    }
  }
};
exports.default = _default;