"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _org = require("@/api/basic/org");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        2: '销售公司',
        3: '生产公司'
      };
      return statusMap[status];
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    canChange: {
      type: Boolean,
      default: true
    },
    companyType: {
      type: String,
      default: ''
    },
    orgId: {
      type: String,
      default: ''
    },
    isEnable: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    parentSchemeCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        pageSize: 10,
        pageNo: 1,
        schemeCode: '',
        schemeName: '',
        parentSchemeCode: '',
        type: 1,
        status: '1'
      },
      tableData: [],
      total: 0,
      title: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(val) {
      var _this = this;
      this.parentSchemeCode ? this.title = '添加增补方案' : this.title = '添加活动方案';
      var that = this;
      this.listLoading = true;
      var data = JSON.parse(JSON.stringify(this.searchForm));
      (0, _activityFlow.getwayList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        parentSchemeCode: this.parentSchemeCode ? this.parentSchemeCode : '',
        type: this.parentSchemeCode ? 2 : 1
      })).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            // that.total = Number(res.data.data.total)
          } else {
            that.tableData = [];
            // that.total = Number(res.data.data.total)
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        pageSize: 10,
        pageNo: 1,
        schemeCode: '',
        schemeName: '',
        status: '1'
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;