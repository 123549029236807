"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExprotActivity = getExprotActivity;
exports.getExprotPrize = getExprotPrize;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
function getExprotActivity(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityConfig/activityRecordCollect/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
function getExprotPrize(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityConfig/activityPrizeCollect/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}