"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _basicConfig = require("@/api/market/basicConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('dialogStatus');
    next();
  },
  components: {
    UploadImg: _UploadImg.default
  },
  data: function data() {
    var _this = this;
    var chekeName = function chekeName(rule, value, callback) {
      if (value && _this.oldNmae !== value) {
        (0, _basicConfig.isAvailable)({
          param: value,
          sign: 1
        }).then(function (res) {
          res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('公众号名称已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    var chekeId = function chekeId(rule, value, callback) {
      if (value && _this.oldId !== value) {
        (0, _basicConfig.isAvailable)({
          param: value,
          sign: 2
        }).then(function (res) {
          res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('公众号原始ID已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    var chekeAppid = function chekeAppid(rule, value, callback) {
      if (value && _this.oldApp !== value) {
        (0, _basicConfig.isAvailable)({
          param: value,
          sign: 2
        }).then(function (res) {
          res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('APPid(公众号)已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    return {
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      listLoading: true,
      disabled: false,
      dialogStatus: '',
      oldNmae: null,
      oldId: null,
      oldApp: null,
      fileList: [],
      temp: {
        id: '',
        officialName: '',
        officialId: '',
        wechatName: '',
        appid: '',
        appsecret: '',
        imageUrl: '',
        payWay: 1,
        mchId: '',
        paykey: '',
        certPath: '',
        certificate: '',
        appToken: '',
        appEncodingAeskey: '',
        disabled: false
      },
      rules: {
        officialName: [{
          required: true,
          message: '公众号名不能为空',
          trigger: 'change'
        }, {
          validator: chekeName,
          trigger: 'blur'
        }],
        officialId: [{
          required: true,
          message: '原始ID不能为空',
          trigger: 'change'
        }, {
          validator: chekeId,
          trigger: 'blur'
        }],
        appid: [{
          required: true,
          message: 'APPid不能为空',
          trigger: 'change'
        }, {
          validator: chekeAppid,
          trigger: 'blur'
        }],
        imageUrl: [{
          required: true,
          message: '请上传公众号二维码',
          trigger: 'change'
        }]
        // appToken: [
        //   { required: true, message: '令牌不能为空', trigger: 'blur' }
        // ],
        // appEncodingAeskey: [
        //   {
        //     required: true,
        //     message: '消息加解密密钥不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      }
    };
  },

  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadFile';
    }
  },
  methods: {
    cancel: function cancel() {
      this.$refs.dataForm.resetFields();
      this.$refs.imageUrl.fileList = [];
      this.$refs.imageUrl.hideUpload = false;
      this.fileList = [];
      this.$emit('close');
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      if (res.code * 1 === 200) {
        this.temp.certPath = res.data;
        this.fileList = [file];
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
      } else {
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    getDetail: function getDetail(val, type) {
      var _this2 = this;
      (0, _basicConfig.detail)({
        id: val
      }).then(function (res) {
        res = res.data;
        if (type == 2) {
          _this2.disabled = true;
        }
        if (res.state * 1 === 200) {
          res.data.wechatConfig.payWay = res.data.wechatConfig.payMethod * 1;
          Object.assign(_this2.temp, res.data.wechatConfig, res.data.payInfo);
          _this2.$refs.imageUrl.fileList = [{
            url: _this2.temp.imageUrl
          }];
          _this2.$refs.imageUrl.hideUpload = true;
          if (_this2.temp.certPath) {
            _this2.fileList = [{
              name: _this2.temp.certPath
            }];
          }
          _this2.oldNmae = _this2.temp.officialName;
          _this2.oldId = _this2.temp.officialId;
          _this2.oldApp = _this2.temp.appid;
        }
      });
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.temp.isOpen = _this3.temp.payWay === 0 ? 0 : 1;
          _this3.temp.payMethod = _this3.temp.payWay;
          (0, _basicConfig.addWechat)(_this3.temp).then(function (res) {
            res = res.data;
            if (res.state * 1 === 200) {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'success',
                duration: 3 * 1000
              });
              _this3.cancel();
            } else {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.temp = Object.assign({}, row); // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this5.temp.isOpen = _this5.temp.payWay === 0 ? 0 : 1;
          _this5.temp.payMethod = _this5.temp.payWay;
          (0, _basicConfig.edit)(_this5.temp).then(function (res) {
            res = res.data;
            if (res.state * 1 === 200) {
              _this5.$message.closeAll();
              _this5.$message({
                message: res.msg,
                type: 'success',
                duration: 3 * 1000
              });
              _this5.cancel();
            } else {
              _this5.$message.closeAll();
              _this5.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;