"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Workbasic',
  components: {},
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    fiterStatus: function fiterStatus(val) {
      var status = {
        0: '审批中',
        1: '审批结束',
        2: '审批驳回'
      };
      return status[val];
    }
  },
  props: {
    pId: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      basic: {}
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var that = this;
      var param = {
        pId: that.pId
      };
      (0, _work.getWDetail)(param).then(function (res) {
        console.log(res);
        if (res.data.code == 200) {
          that.basic = (0, _utils.deepClone)(res.data.data);
        }
      });
    }
  }
};
exports.default = _default2;