var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateName",
                                label: "模板名称",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入模板名称" },
                                model: {
                                  value: _vm.formInline.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "templateName",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateCategory",
                                label: "模板类别",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formInline.templateCategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "templateCategory",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.templateCategory",
                                  },
                                },
                                _vm._l(
                                  _vm.templateCategorys,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateStatus",
                                label: "模板状态",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formInline.templateStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "templateStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.templateStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.templateStatus,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "startTime", label: "起止日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.formInline.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "rangeTime", $$v)
                                  },
                                  expression: "formInline.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: {
                                width: _vm.formConfig.btnAreaHasShowMore,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.searchForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restForm("formInline")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: {
                      click: function ($event) {
                        _vm.actDialogVisible = true
                      },
                    },
                  },
                  [_vm._v("添加模板")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-button-group",
                  [
                    _c("el-button", {
                      attrs: {
                        type: _vm.listShow ? "primary" : "",
                        icon: "el-icon-s-fold",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeList(1)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("el-button", {
                      attrs: {
                        type: !_vm.listShow ? "primary" : "",
                        icon: "el-icon-menu",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeList(2)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.listShow
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "table-container",
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      width: "80",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "模板名称",
                      prop: "templateName",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "templateType",
                      label: "模板类型",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateType")(scope.row.templateType)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3590294340
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "templateStatus",
                      label: "模板状态",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.templateStatus == "2"
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("templateState")(
                                          scope.row.templateStatus
                                        )
                                      )
                                    ),
                                  ])
                                : _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("templateState")(
                                          scope.row.templateStatus
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      284429010
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "templateCategory",
                      label: "模板类别",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateCategory")(
                                    scope.row.templateCategory
                                  )
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1825663300
                    ),
                  }),
                  _vm._v("activityNum\n      "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联活动数量",
                      prop: "activityNum",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "creatorName",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      label: "创建时间",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.createTime)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2774630583
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: _vm.tableConfig.align,
                      label: "操作",
                      fixed: "right",
                      width: "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button configText",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.preView(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("预览")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "button configText",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAct(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              scope.row.templateStatus == "1"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enable(scope.row, 2)
                                        },
                                      },
                                    },
                                    [_vm._v("停用")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.templateStatus == "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enable(scope.row, 1)
                                        },
                                      },
                                    },
                                    [_vm._v("启用")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3280427368
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.listShow
            ? _c(
                "el-row",
                { staticStyle: { padding: "20px" }, attrs: { gutter: 20 } },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticClass: "creatItemWrap",
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            "body-style": { padding: "0px" },
                            shadow: "hover",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { padding: "8px" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text1",
                                      attrs: { span: 16 },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.templateName)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type:
                                              item.templateStatus == 1
                                                ? "success"
                                                : "danger",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("templateState")(
                                                item.templateStatus
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text2",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("templateType")(
                                              item.templateType
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "text2",
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 16 },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.createTime)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "HoverMask",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-row",
                                          { staticClass: "maskBtn" },
                                          [
                                            _c(
                                              "el-col",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.preView(item)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("预览")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("div", { staticClass: "bkImg" }, [
                                item.templateType == "1"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("stard", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "2"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("rotaryTable", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "4"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("word", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "5"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("sudoku", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "6"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("scratchCard", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "7"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("eggTwistMachine", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "8"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                            "object-fit": "contain",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hongbao2.png"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.templateType == "0"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "460px",
                                          overflow: "hidden",
                                        },
                                      },
                                      [
                                        _c("focus", {
                                          attrs: {
                                            data: item.templateElements,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "button",
                              staticStyle: { width: "100%" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAct(item)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.formInline.pageNo,
              limit: _vm.formInline.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.formInline, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.formInline, "pageSize", $event)
              },
              pagination: _vm.getDataList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "模板类型",
            visible: _vm.actDialogVisible,
            "before-close": _vm.cancel,
            width: "1000px !important",
          },
        },
        [
          _c(
            "div",
            { staticClass: "itemWrap" },
            _vm._l(_vm.actlist, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.creatAct({
                        templateType: item.type,
                        templateId: "",
                      })
                    },
                  },
                },
                [
                  _c("div", { staticClass: "imgWrap" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: item.img },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }, [
                    _c("span", {
                      staticClass: "cardDialogTitle",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: "模板基本设置",
                visible: _vm.dialogVisible,
                "append-to-body": true,
                width: "1000px !important",
                "before-close": _vm.close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.type == "1"
                ? _c("Content", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "2"
                ? _c("Content-rotary-table", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "4"
                ? _c("Content2", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "5"
                ? _c("Content-sudoku", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "6"
                ? _c("Content-scratch-card", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "7"
                ? _c("Content-egg-twist-machine", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "8"
                ? _c("Content-rotary-table2", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }