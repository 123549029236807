var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData1,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _vm.arrangetype == "N" || _vm.arrangetype == "E"
            ? _c(
                "div",
                [
                  _c("el-row", { staticClass: "title" }, [
                    _vm._v("\n        —— 单据信息 ——\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品编码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品名称:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格型号:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productModel)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "系数:" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.detailinfo.coefficient)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "组数:" } }, [
                            _vm.arrangetype == "N"
                              ? _c("div", [
                                  _vm._v(_vm._s(_vm.detailinfo.backChestSum)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.arrangetype == "E"
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.detailinfo.scheduleChestSum)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "销售数量:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.backBottleSum)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户代码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.custCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户名称:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.channelName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "区域代码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.areaCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", { staticClass: "title" }, [
                    _vm._v("\n        —— 排产信息 ——\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "border-bottom": "1px dashed #eff0f1",
                        "margin-bottom": "40px",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划生产:" } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.detailinfo.backChestSum) +
                                    "组(" +
                                    _vm._s(_vm.detailinfo.backBottleSum) +
                                    "只)"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "已排产:" } }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.detailinfo.scheduleSum /
                                    _vm.detailinfo.coefficient
                                ) +
                                  "组(" +
                                  _vm._s(_vm.detailinfo.scheduleSum) +
                                  "只)"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "未排产:" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#409eff",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.detailinfo.noarrange /
                                      _vm.detailinfo.coefficient
                                  ) +
                                    "组(" +
                                    _vm._s(_vm.detailinfo.noarrange) +
                                    "只)"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.arrangetype == "Y"
            ? _c(
                "div",
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-left": "18px",
                        color: "red",
                        "font-size": "15px",
                      },
                    },
                    [
                      _vm._v(
                        "即将对以下" +
                          _vm._s(_vm.piList.length) +
                          "个单据批量排产"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.piList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "18px",
                            "margin-bottom": "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "40px" } },
                            [
                              _vm._v(
                                "\n            单据编号：" +
                                  _vm._s(item.orderNo) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "40px" } },
                            [
                              _vm._v(
                                "\n            产品名称：" +
                                  _vm._s(item.productName) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.noarrange == 0
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "\n            未排产：" +
                                    _vm._s(item.noarrange) +
                                    "只\n          "
                                ),
                              ])
                            : _c("div", { staticStyle: { color: "#409eff" } }, [
                                _vm._v(
                                  "\n            未排产：" +
                                    _vm._s(item.noarrange) +
                                    "只\n          "
                                ),
                              ]),
                        ]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "政府追溯:", prop: "traceType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "650px" },
                          attrs: {
                            clearable: "",
                            disabled:
                              _vm.detailinfo.auditStatus == "2" &&
                              (_vm.detailinfo.scheduleStatus == "2" ||
                                _vm.detailinfo.scheduleStatus == "1"),
                            placeholder: "请选择",
                          },
                          on: { change: _vm.forceupdate },
                          model: {
                            value: _vm.scanData1.traceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData1, "traceType", $$v)
                            },
                            expression: "scanData1.traceType",
                          },
                        },
                        _vm._l(_vm.traceList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: { label: item.name, value: item.figure },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "采集箱码:", prop: "boxCollect" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "650px" },
                          attrs: {
                            clearable: "",
                            disabled:
                              _vm.detailinfo.auditStatus == "2" &&
                              (_vm.detailinfo.scheduleStatus == "2" ||
                                _vm.detailinfo.scheduleStatus == "1"),
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.scanData1.boxCollect,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData1, "boxCollect", $$v)
                            },
                            expression: "scanData1.boxCollect",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "否", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.detailinfo.auditStatus == "2" &&
              (_vm.detailinfo.scheduleStatus == "2" ||
                _vm.detailinfo.scheduleStatus == "1")
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排产车间:", prop: "shopCodes" } },
                        [
                          _vm.storeList
                            ? _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.scanData1.shopCodes,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData1, "shopCodes", $$v)
                                    },
                                    expression: "scanData1.shopCodes",
                                  },
                                },
                                _vm._l(_vm.storeList, function (el, i) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: i,
                                      attrs: {
                                        label: el.factoryCode,
                                        disabled:
                                          _vm.detailinfo.shopCodes.includes(
                                            el.factoryCode
                                          ),
                                      },
                                    },
                                    [_vm._v(_vm._s(el.factoryName))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排产车间:", prop: "shopCodes" } },
                        [
                          _vm.storeList
                            ? _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.scanData1.shopCodes,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData1, "shopCodes", $$v)
                                    },
                                    expression: "scanData1.shopCodes",
                                  },
                                },
                                _vm._l(_vm.storeList, function (el, i) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: i,
                                      attrs: { label: el.factoryCode },
                                    },
                                    [_vm._v(_vm._s(el.factoryName))]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.arrangetype == "N" || _vm.arrangetype == "E"
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "排产组数:",
                            prop: "scheduleChestSum",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "650px" },
                            attrs: {
                              placeholder: "请输入",
                              type: "number",
                              disabled:
                                _vm.detailinfo.auditStatus == "2" &&
                                (_vm.detailinfo.scheduleStatus == "2" ||
                                  _vm.detailinfo.scheduleStatus == "1"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.changezushu(
                                  _vm.scanData1.scheduleChestSum
                                )
                              },
                              input: function (e) {
                                return (_vm.scanData1.scheduleChestSum =
                                  _vm.validSe(e))
                              },
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.number($event)
                              },
                            },
                            model: {
                              value: _vm.scanData1.scheduleChestSum,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData1, "scheduleChestSum", $$v)
                              },
                              expression: "scanData1.scheduleChestSum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.arrangetype == "N" || _vm.arrangetype == "E"
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排产数量:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "650px" },
                            attrs: {
                              clearable: "",
                              "show-word-limit": "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.scanData1.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData1, "number", $$v)
                              },
                              expression: "scanData1.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.arrangetype == "N" || _vm.arrangetype == "E"
                ? _c(
                    "el-col",
                    { staticStyle: { "margin-left": "62%", color: "#c1c1c1" } },
                    [
                      _vm._v("剩余排产数量: "),
                      _c("span", { staticStyle: { color: "#409eff" } }, [
                        _vm._v(_vm._s(_vm.scanData1.shengnumber)),
                      ]),
                      _vm._v("/" + _vm._s(_vm.detailinfo.backBottleSum)),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("取 消"),
                      ]),
                      _vm._v(" "),
                      _vm.arrangetype == "Y"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm()
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm1()
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.warningdialog,
            width: "30%",
            "append-to-body": "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.warningdialog = $event
            },
          },
        },
        [
          _vm.successCount > 0
            ? _c(
                "div",
                { staticStyle: { color: "green", "margin-bottom": "20px" } },
                [_vm._v("创建" + _vm._s(_vm.successCount) + "个排产单成功")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.doneCount > 0
            ? _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "创建" +
                    _vm._s(_vm.doneCount) +
                    "个排产单失败，因为排产数量不能为0"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.closedCount > 0
            ? _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "创建" +
                    _vm._s(_vm.closedCount) +
                    "个排产单失败，因为单据已关闭"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.failCount > 0
            ? _c("div", { staticStyle: { color: "red" } }, [
                _vm._v("创建" + _vm._s(_vm.failCount) + "个排产单失败"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closewarning } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }