"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Workflow',
  components: {},
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  props: {
    pId: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      list: [],
      progress: []
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      var param = {
        pId: that.pId
      };
      (0, _work.getSplcDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.approvedLog;
          that.progress = res.data.data.nodeDefinition;
        } else {
          that.list = [];
          that.progress = [];
        }
      });
    }
  }
};
exports.default = _default2;