var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "查看信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "stepArea" },
        [
          _c("div", { staticClass: "billTitle" }, [
            _c("img", { attrs: { src: require("@/images/topimg.png") } }),
            _vm._v(
              "\n      采购入库单 " + _vm._s(_vm.detailData.billNo) + "\n      "
            ),
            _c(
              "span",
              {
                staticClass: "billType",
                class: _vm._f("typeClass")(_vm.detailData.billStatus),
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("filterStatus")(
                      _vm.detailData.billStatus,
                      _vm.detailData.billStatus
                    )
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "topRight" }, [
              _c("img", { attrs: { src: require("@/images/avatar.png") } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.detailData.createUser))]),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(_vm._s(_vm.detailData.createTime)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-steps",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { active: _vm.active, "finish-status": "success" },
            },
            [
              _c("el-step", { attrs: { title: "创建" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "待下载" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "待入库" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "全部入库" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          false
            ? _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("单号:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.billNo)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("销售出库单号:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.relateNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货单位:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.outOrgName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货单位:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.inOrgName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货库房:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.inStoreName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          false
            ? _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("单据状态:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("filterStatus")(
                          _vm.detailData.billStatus,
                          _vm.detailData.billStatus
                        )
                      )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("单据来源:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm.detailData.billSource == 1
                  ? _c("span", [_vm._v("平台自动创建")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.billSource == 2
                  ? _c("span", [_vm._v("平台用户创建")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.billSource == 3
                  ? _c("span", [_vm._v("PDA创建")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.billSource == 4
                  ? _c("span", [_vm._v("ERP同步")])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("拆单状态:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm.detailData.splitStatus == 0
                  ? _c("span", [_vm._v("未拆单")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.splitStatus == 1
                  ? _c("span", [_vm._v("部分拆但")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.splitStatus == 2
                  ? _c("span", [_vm._v("已拆单")])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          false
            ? _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("创建人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.createUser)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("创建时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.createTime)),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _vm._v("\n    产品信息\n    "),
        _c("img", { attrs: { src: require("@/images/pro.png") } }),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            data: _vm.proList,
            fit: "",
            "max-height": "300",
            "highlight-current-row": "",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              align: _vm.tableConfig.align,
              width: "120",
            },
          }),
          _vm._v(" "),
          false
            ? _c("el-table-column", {
                attrs: {
                  label: "生产批次",
                  prop: "prodBatchNo",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "prodClassName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              prop: "productCode",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "productName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "包装比例",
              prop: "packRadioDesc",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          false
            ? _c("el-table-column", {
                attrs: {
                  label: "单位",
                  prop: "packUnit",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划箱",
              prop: "planScanNum",
              align: _vm.tableConfig.align,
              width: "160",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划数量(盒)",
              prop: "planScanAtoNum",
              align: _vm.tableConfig.align,
              width: "160",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫箱",
              prop: "realScanNum",
              align: _vm.tableConfig.align,
              width: "160",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.getCodesDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫盒",
              prop: "realScanAtoNum",
              align: _vm.tableConfig.align,
              width: "160",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.getCodesDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanAtoNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          staticClass: "detailCollapse",
          staticStyle: { "margin-bottom": "20px" },
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "操作记录", name: "1" } },
            [
              _c("template", { slot: "title" }, [_vm._v("操作记录")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tradeOperateLogDTOS,
                    fit: "",
                    "highlight-current-row": "",
                    "max-height": "300",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      width: "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作类型",
                      prop: "operateType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("operateType")(row.operateType))
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作描述",
                      prop: "operateDesc",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      prop: "operateUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作备注",
                      prop: "remark",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("codeDetails", {
        attrs: {
          "bill-no": _vm.billNo,
          "product-id": _vm.productId,
          "code-visible": _vm.codeVisible,
        },
        on: { close: _vm.codeHide },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }