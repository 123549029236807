var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-suffix": ":",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "storageId",
                                    label: "库房名称",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.storageId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "storageId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.storageId",
                                      },
                                    },
                                    _vm._l(_vm.storeList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.storeHouseName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "ncNo", label: "NC单号" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.ncNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "ncNo", $$v)
                                      },
                                      expression: "searchForm.ncNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billType",
                                    label: "单据类型",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.billType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "billType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.billType",
                                      },
                                    },
                                    _vm._l(_vm.djlx, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "billNum", label: "产品名称" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billNum", label: "单据号" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.billNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "billNum", $$v)
                                      },
                                      expression: "searchForm.billNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "downUser",
                                    label: "下载用户",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      focus: _vm.openUser,
                                      clear: _vm.clearUser,
                                    },
                                    model: {
                                      value: _vm.pdaUser,
                                      callback: function ($$v) {
                                        _vm.pdaUser = $$v
                                      },
                                      expression: "pdaUser",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billStatus",
                                    label: "单据状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.billStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "billStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.billStatus",
                                      },
                                    },
                                    _vm._l(_vm.djzt, function (item) {
                                      return _c("el-option", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.figure > 3,
                                            expression: "item.figure>3",
                                          },
                                        ],
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNum",
                                    label: "经销商名称/主户头名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.inOrgOrAccountName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "inOrgOrAccountName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.inOrgOrAccountName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "beginDate",
                                        label: "下载起始时间",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "选择日期",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.searchForm.beginDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "beginDate",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.beginDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6, prop: "endTime" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "endDate",
                                        label: "下载结束时间",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "选择日期",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.searchForm.endDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "endDate",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.endDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.DoReset },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", [
                            _c(
                              "div",
                              [
                                _c("span", [_vm._v("隐藏不能操作单据:")]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#C0C4CC",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "active-text": "是",
                                    "inactive-text": "否",
                                  },
                                  on: { change: _vm.getList },
                                  model: {
                                    value: _vm.searchForm.isHideCanceledBill,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "isHideCanceledBill",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.isHideCanceledBill",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "80",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据类型",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(_vm._s(_vm._f("djlxText")(row.billType))),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      310851858
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "NC单号",
                      prop: "ncNo",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据号",
                      prop: "billNo",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "库房名称",
                      prop: "storageName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "inOrgName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "主户头名称",
                      prop: "accountName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("TypeDataCom")(
                                      row.billStatus,
                                      "djzt"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      294952944
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下载用户",
                      prop: "pdaUser",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下载时间",
                      prop: "downTime",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          "min-width": "140",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !(
                                          row.billStatus == 102 ||
                                          row.billStatus == 103 ||
                                          row.billStatus == 104
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.scanDetail(
                                            row,
                                            "revertDown"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("撤销下载")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1861086138
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("personDialog", { ref: "personDialog", on: { change: _vm.setUser } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }