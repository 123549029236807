"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableCodeDetail',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    codeVisible: {
      type: [String, Boolean],
      default: false
    },
    billNo: {
      type: String,
      default: ''
    },
    productId: {
      type: String,
      default: ''
    },
    ncProductId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: 'codeTable',
      tabHeight: '100%',
      searchForm: {
        billNo: '',
        productId: '',
        ncProductId: '',
        current: 1,
        size: 10
      },
      tableData: [],
      total: 0
    };
  },
  watch: {
    codeVisible: function codeVisible(val) {
      if (val) {
        this.getList();
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      that.searchForm.billNo = that.billNo;
      that.searchForm.productId = that.productId;
      that.searchForm.ncProductId = that.ncProductId || '';
      (0, _select.getCodeContent)(this.searchForm).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var rData = res.data.data.records;
            if (rData.length > 0) {
              rData.forEach(function (item) {
                if (item.packLevel == 4 || item.packLevel == 3 || item.packLevel == 5) {
                  item.hasChildren = true;
                }
              });
            }
            that.tableData = rData;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this.listLoading = false;
      }).catch(function () {
        that.total = 0;
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm.billNo = '';
      this.searchForm.productId = '';
      this.searchForm.current = 1;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    load: function load(tree, treeNode, resolve) {
      var param = {};
      param.billNo = tree.billNo;
      param.parentMarkCode = tree.markCode;
      param.productId = tree.productId;
      (0, _select.getChildCodes)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            if (res.data.data.length == 0) {
              tree.hasChildren = false;
            } else {
              res.data.data.forEach(function (item) {
                if (item.packLevel == 4 || item.packLevel == 3 || item.packLevel == 6) {
                  item.hasChildren = true;
                }
              });
              setTimeout(function () {
                resolve(res.data.data);
              }, 100);
            }
          } else {
            tree.hasChildren = false;
          }
        } else {
          tree.hasChildren = false;
        }
      });
    }
  }
};
exports.default = _default;