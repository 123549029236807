var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "seach-box" } },
    [
      _c(
        "el-form",
        {
          ref: "searchForm",
          staticClass: "search-condition",
          attrs: {
            model: _vm.formData,
            "label-suffix": ":",
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth,
          },
        },
        [
          _c(
            "div",
            { staticClass: "cols" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "物流码", prop: "wlm" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "物流码",
                              maxlength: "100",
                            },
                            model: {
                              value: _vm.formData.wlm,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "wlm", $$v)
                              },
                              expression: "formData.wlm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startTime", label: "开始日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyyMMdd",
                              align: "right",
                              clearable: false,
                            },
                            model: {
                              value: _vm.formData.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startTime", $$v)
                              },
                              expression: "formData.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endTime", label: "结束日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyyMMdd",
                              align: "right",
                              clearable: false,
                            },
                            model: {
                              value: _vm.formData.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "endTime", $$v)
                              },
                              expression: "formData.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "经销商/终端", prop: "yjjxs" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              value: _vm.formData.yjjxs,
                              maxlength: "40",
                              placeholder: "经销商名称",
                            },
                            on: {
                              input: function (e) {
                                return (_vm.formData.yjjxs = _vm.validTe(e))
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品品牌", prop: "ppwlList" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择/输入品牌",
                              },
                              on: { change: _vm.getqueryMaterial },
                              model: {
                                value: _vm.brandData,
                                callback: function ($$v) {
                                  _vm.brandData = $$v
                                },
                                expression: "brandData",
                              },
                            },
                            _vm._l(_vm.brandList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.brandName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "name" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                "collapse-tags": "",
                                placeholder: "请选择/输入产品名称",
                              },
                              model: {
                                value: _vm.selProList,
                                callback: function ($$v) {
                                  _vm.selProList = $$v
                                },
                                expression: "selProList",
                              },
                            },
                            _vm._l(_vm.proList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.materialName,
                                  value: item.materialCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "疑似窜出区域", prop: "yschqy" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择/输入区域",
                              },
                              model: {
                                value: _vm.formData.yschqy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "yschqy", $$v)
                                },
                                expression: "formData.yschqy",
                              },
                            },
                            _vm._l(_vm.yschqy, function (item) {
                              return _c("el-option", {
                                key: item.areaCode,
                                attrs: {
                                  label: item.areaName,
                                  value: item.areaCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "疑似窜出个数", prop: "yschNum" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                clearable: "",
                                type: "text",
                                title: "次数",
                                oninput:
                                  "this.value=this.value.replace(/\\D+/g,'');if(this.value.length>3) this.value=this.value.slice(0,3);;if(this.value==0) this.value=''",
                              },
                              model: {
                                value: _vm.formData.yschNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "yschNum", $$v)
                                },
                                expression: "formData.yschNum",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "全部",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.formData.yschgsType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "yschgsType", $$v)
                                    },
                                    expression: "formData.yschgsType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: ">=", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "<=", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "查询类型", prop: "queryType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.formData.queryType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "queryType", $$v)
                                },
                                expression: "formData.queryType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "0" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "防伪码查询", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "物流码查询", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          style: { width: _vm.formConfig.btnAreaHasShowMore },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFilter("search")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFilter("rest")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$parent.handleShow =
                                        !_vm.$parent.handleShow
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.handleShow ? "收起" : "展开") +
                                      "\n              "
                                  ),
                                  _c("i", {
                                    class: _vm.handleShow
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }