"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.to-string");
var _index = require("@/utils/index.js");
var _BarChartLeft = _interopRequireDefault(require("../yhfx/yhData/components/BarChartLeft"));
var _PieChart = _interopRequireDefault(require("../yhfx/yhData/components/PieChart"));
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _analyze = require("@/api/wxhyyy/analyze.js");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LineChart: _lineChart.default,
    PieChart: _PieChart.default,
    BarChartLeft: _BarChartLeft.default,
    admincut: _admincut.default
  },
  data: function data() {
    return {
      imgTextDate: [],
      // 图文时间
      compareDate: [],
      // 图文对比时间
      isCompare: true,
      // 是否显示对比
      topDate: [],
      // 前十名时间
      topOption: '',
      // 前十名选项
      newsAnalysisDataVO: {},
      typeList: [{
        id: 'newsReadingCount',
        name: '图文阅读次数'
      }, {
        id: 'ewsReadingNumber',
        name: '图文阅读人数'
      }, {
        id: 'originalCount',
        name: '原文阅读次数'
      }, {
        id: 'shareCount',
        name: '分享转发次数'
      }, {
        id: 'collectionNumber',
        name: '图文收藏人数'
      }],
      typeNum: 'newsReadingCount',
      tableArr: [],
      groupDOList: [],
      topRanking: '1',
      lineListData: {},
      lineChartData: {
        expectedData: [],
        actualData: [],
        xData: [],
        xxData: []
      },
      newsByDate: [],
      contrastNewsByDate: []
    };
  },
  watch: {
    imgTextDate: function imgTextDate(newValue, oldValue) {
      if (newValue === null) {
        this.imgTextDate = [];
      } else if (newValue !== null && this.isCompare && this.imgTextDate.length === 2 && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        this.getSearchMenuData(1);
      } else if (newValue !== null && !this.isCompare && this.compareDate.length === 2) {
        this.getSearchMenuData(2);
      }
    },
    compareDate: function compareDate(newValue, oldValue) {
      if (newValue !== null && this.imgTextDate.length === 2 && newValue.length === 2) {
        this.getSearchMenuData(2);
      } else if (!this.isCompare) {
        this.$message.error('请输入或选择时间对比起始时间');
      } else {
        this.getSearchMenuData(1);
      }
    },
    topDate: function topDate(newValue, oldValue) {
      if (newValue !== null && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        this.getSearchMenuDataDetail();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.imgTextDate = this.getDate(30);
    this.topDate = this.getDate(30);
  },
  mounted: function mounted() {
    if (undefined !== 0) {
      console.log(undefined !== 0);
    }
  },
  methods: {
    // 最近30天
    latelyFun: function latelyFun(val) {
      this.imgTextDate = this.getDate(val);
    },
    getDate: function getDate(val) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      var arr = [(0, _index.parseTime)(start, '{y}-{m}-{d}'), (0, _index.parseTime)(end, '{y}-{m}-{d}')];
      return arr;
    },
    // 图文对比
    isCompareFun: function isCompareFun(v) {
      if (v) {
        this.isCompare = false;
      } else {
        this.isCompare = true;
        this.compareDate = [];
      }
    },
    // top10类型选择
    topOptionFun: function topOptionFun(val) {
      if (this.topDate !== null && this.topDate.length === 2) {
        if (this.topRanking !== (this.typeList.findIndex(function (item) {
          return item.name === val;
        }) + 1).toString()) {
          this.topRanking = (this.typeList.findIndex(function (item) {
            return item.name === val;
          }) + 1).toString();
          this.getSearchMenuDataDetail();
        }
      } else {
        this.$message.error('请选择时间');
      }
    },
    countLineFun: function countLineFun(val) {
      if (this.typeNum !== val) {
        this.typeNum = val;
        this.lineChartData = this.lineListData[val];
        console.log(this.lineChartData);
        // const xdad = this.lineChartData.xData
        // const xxdad = this.lineChartData.xxData
        // if (xdad.length !== xxdad.length) {
        //   if (xdad.length > xxdad.length) {
        //     console.log(xdad)
        //     const a = xdad.length - xxdad.length
        //     for (let i = 0; i < a; i++) {
        //       let c = Math.ceil(a / (2 + i))
        //       if (c === (xdad.length - 1)) {
        //         c = Math.ceil(a / (3 + i))
        //       }
        //       console.log(c)
        //       xdad.splice(c, 1)
        //     }
        //     this.lineChartData.xData = xdad
        //   } else {
        //     console.log(xxdad)
        //     const b = xxdad.length - xdad.length
        //     for (let i = 0; i < b; i++) {
        //       let d = Math.ceil(b / (2 + i))
        //       if (d === (xxdad.length - 1)) {
        //         d = Math.ceil(b / (3 + i))
        //       }
        //       console.log(d)
        //       xxdad.splice(d, 1)
        //     }
        //     this.lineChartData.xxData = xxdad
        //   }
        // }
      }
    },
    getSearchMenuData: function getSearchMenuData(type) {
      var _this = this;
      (0, _analyze.searchMenuData)({
        appId: sessionStorage.getItem('appId'),
        beginDate: this.imgTextDate[0],
        endDate: this.imgTextDate[1],
        contrastBeginDate: this.compareDate[0],
        contrastEndDate: this.compareDate[1]
      }).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        var _resp = resp,
          code = _resp.code,
          data = _resp.data,
          msg = _resp.msg;
        if (code * 1 === 200) {
          var groupDOList = data.groupDOList,
            newsAnalysisDataVO = data.newsAnalysisDataVO,
            contrastNewsAnalysisDataVO = data.contrastNewsAnalysisDataVO,
            newsByDate = data.newsByDate,
            contrastNewsByDate = data.contrastNewsByDate;
          // 获取数据
          newsAnalysisDataVO.date = _this.imgTextDate.join('~');
          _this.newsAnalysisDataVO = newsAnalysisDataVO;
          if (contrastNewsAnalysisDataVO) {
            contrastNewsAnalysisDataVO.date = _this.compareDate.join('~');
            _this.tableArr = [newsAnalysisDataVO, contrastNewsAnalysisDataVO];
          } else {
            _this.tableArr = [newsAnalysisDataVO];
          }
          if (type === 1) {
            _this.DoFilterData(newsByDate, 'single');
          } else {
            if (contrastNewsByDate !== '' && contrastNewsByDate.length > 0 && contrastNewsByDate !== null) {
              var obj = {
                newsByDate: newsByDate,
                contrastNewsByDate: contrastNewsByDate
              };
              _this.DoFilterData(obj, 'double');
            } else {
              _this.DoFilterData(newsByDate, 'single');
            }
          }
          _this.groupDOList = groupDOList;
          groupDOList.forEach(function (item) {
            item.value = item.clikeCount | 0;
          });
          _this.$refs.pieChart.legendData = [{
            orient: 'vertical',
            left: '40%',
            top: '10%'
          }];
          _this.$refs.pieChart.seriesData = [{
            type: 'pie',
            radius: '60%',
            center: ['20%', '50%'],
            data: groupDOList,
            label: {
              normal: {
                formatter: '{d}%'
              }
            }
          }];
          _this.$refs.pieChart.initChart();
        } else {
          _this.$message(msg);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getSearchMenuDataDetail: function getSearchMenuDataDetail() {
      var _this2 = this;
      (0, _analyze.searchMenuDataDetail)({
        appId: sessionStorage.getItem('appId'),
        beginDate: this.topDate[0],
        endDate: this.topDate[1],
        ranking: this.topRanking
      }).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        var _resp2 = resp,
          code = _resp2.code,
          data = _resp2.data,
          msg = _resp2.msg;
        if (code * 1 === 200) {
          var groupDataVOList = data.groupDataVOList;
          var yAxisData = [];
          console.log(resp);
          groupDataVOList.forEach(function (item) {
            item.value = item.clikeCount | 0;
            item.clikeRate = item.clikeRate | 0;
            yAxisData.push(item.name);
          });
          _this2.$refs.barchart.yAxisData = yAxisData.reverse();
          _this2.$refs.barchart.seriesData = groupDataVOList.reverse();
          _this2.$refs.barchart.initChart();
        } else {
          _this2.$message(msg);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 过滤折线数据
    DoFilterData: function DoFilterData(data, type) {
      var _this3 = this;
      if (type === 'single') {
        if (data.length === 0) {
          this.typeList.forEach(function (item) {
            _this3.lineListData[item.id] = {
              expectedData: [],
              actualData: [],
              xData: [],
              xxData: []
            };
          });
        } else {
          this.typeList.forEach(function (item) {
            _this3.lineListData[item.id] = {
              expectedData: [],
              actualData: [],
              xData: [],
              xxData: []
            };
          });
          console.log(data);
          data.forEach(function (item, index) {
            Object.keys(_this3.lineListData).forEach(function (key) {
              _this3.lineListData[key].expectedData.push(item[key]);
              // this.lineListData[key].xData.push(
              //   parseTime(item.createDateStr, '{y}-{m}-{d}')
              // )
              _this3.lineListData[key].xData.push(item.createDateStr);
            });
          });
        }
        this.lineChartData = this.lineListData[this.typeList[0].id];
        // const xdad = this.lineChartData.xData
        // const xxdad = this.lineChartData.xxData
        // if (xdad.length !== xxdad.length) {
        //   if (xdad.length > xxdad.length) {
        //     console.log(xdad)
        //     const a = xdad.length - xxdad.length
        //     for (let i = 0; i < a; i++) {
        //       let c = Math.ceil(a / (2 + i))
        //       if (c === (xdad.length - 1)) {
        //         c = Math.ceil(a / (3 + i))
        //       }
        //       console.log(c)
        //       xdad.splice(c, 1)
        //     }
        //     this.lineChartData.xData = xdad
        //   } else {
        //     console.log(xxdad)
        //     const b = xxdad.length - xdad.length
        //     for (let i = 0; i < b; i++) {
        //       let d = Math.ceil(b / (2 + i))
        //       if (d === (xxdad.length - 1)) {
        //         d = Math.ceil(b / (3 + i))
        //       }
        //       console.log(d)
        //       xxdad.splice(d, 1)
        //     }
        //     this.lineChartData.xxData = xxdad
        //   }
        // }
      } else {
        if (data.newsByDate.length === 0) {
          this.typeList.forEach(function (item) {
            _this3.lineListData[item.id] = {
              expectedData: [],
              actualData: [],
              xData: [],
              xxData: []
            };
          });
        } else {
          var lineListData = {};
          this.typeList.forEach(function (item) {
            lineListData[item.id] = {
              expectedData: [],
              actualData: [],
              xData: [],
              xxData: []
            };
          });
          if (data.newsByDate === '' || data.newsByDate === null) {
            this.typeList.forEach(function (item) {
              lineListData[item.id].expectedData = [];
              lineListData[item.id].xData = [];
            });
          } else {
            data.newsByDate.forEach(function (item, index) {
              Object.keys(lineListData).forEach(function (key) {
                lineListData[key].expectedData.push(item[key]);
                lineListData[key].xData.push((0, _index.parseTime)(item.createDateStr, '{y}-{m}-{d}'));
              });
            });
          }
          if (data.contrastNewsByDate === '' || data.contrastNewsByDate === null) {
            this.lineList.forEach(function (item) {
              lineListData[item.id].actualData = [];
              lineListData[item.id].xxData = [];
            });
          } else {
            data.contrastNewsByDate.forEach(function (item, index) {
              Object.keys(lineListData).forEach(function (key) {
                lineListData[key].actualData.push(item[key]);
                lineListData[key].xxData.push((0, _index.parseTime)(item.createDateStr, '{y}-{m}-{d}'));
              });
            });
          }
          this.lineListData = lineListData;
        }
        this.lineChartData = this.lineListData[this.typeList[0].id];
        console.log(this.lineChartData);
        // const xdad = this.lineChartData.xData
        // const xxdad = this.lineChartData.xxData
        // if (xdad.length !== xxdad.length) {
        //   if (xdad.length > xxdad.length) {
        //     console.log(xdad)
        //     const a = xdad.length - xxdad.length
        //     for (let i = 0; i < a; i++) {
        //       let c = Math.ceil(a / (2 + i))
        //       if (c === (xdad.length - 1)) {
        //         c = Math.ceil(a / (3 + i))
        //       }
        //       console.log(c)
        //       xdad.splice(c, 1)
        //     }
        //     this.lineChartData.xData = xdad
        //   } else {
        //     console.log(xxdad)
        //     const b = xxdad.length - xdad.length
        //     for (let i = 0; i < b; i++) {
        //       let d = Math.ceil(b / (2 + i))
        //       if (d === (xxdad.length - 1)) {
        //         d = Math.ceil(b / (3 + i))
        //       }
        //       console.log(d)
        //       xxdad.splice(d, 1)
        //     }
        //     this.lineChartData.xxData = xxdad
        //   }
        // }
      }
    }
  }
};
exports.default = _default;