"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _lithService = require("@/api/lithService");
var _basic = require("@/api/mark/basic");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FakeExpelqueryManagecodeindex',
  directives: {
    waves: _waves.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    numStatus: function numStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未激活',
        1: '正常',
        2: '冻结',
        3: '废除',
        10: '不存在'
      };
      return statusMap[status];
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd');
    },
    billType: function billType(status) {
      if (!String(status)) return '';
      var statusMap = {
        101: '【生产入库】',
        201: '【返工出库】',
        102: '【调货入库】',
        202: '【调货出库】',
        203: '【销售出库】',
        103: '【退货入库】',
        204: '【退货出库】',
        105: '【其他入库】',
        205: '【其他出库】',
        104: '【采购入库】'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内',
        2: '',
        3: '外'
      };
      return statusPack[packLevel] + statusMark[markType];
    }
  },
  data: function data() {
    return {
      hasDetail: false,
      numColor: {
        0: 'wjh',
        1: 'zc',
        2: 'dj',
        3: 'fc'
      },
      codeFrom: {},
      chosedate: [],
      downLoading: false,
      listQuery: {
        markCode: ''
      },
      tableKey: 0,
      list: [],
      tableData: [],
      listLoading: false,
      btn_loading: false,
      loading: false,
      dialogFormVisible: false,
      temp: {
        // 数码信息
        markInfoVO: {},
        //  物流信息
        // 生产信息
        productionVo: {}
      },
      rules: {},
      searchForm: {
        current: 1,
        size: 20
      }
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      // if (this.listQuery.markCode.length < 18) {
      //   this.$message.error('数码长度不正确，最少输入18位数码')
      //   return
      // }
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      (0, _lithService.selectDetailByCode)(submitData).then(function (res) {
        that.codeFrom = {};
        that.list = [];
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          _this.hasDetail = false;
          _this.loading = false;
          return;
        } else {
          if (res.data.data) {
            that.codeFrom = res.data.data;
            that.tableData = res.data.data.levelTemplateVOS || [];
            res.data.data.levelTemplateVOS.forEach(function (el) {
              el.markLevel = el.markLevel.toString();
            });
            _this.list = res.data.data.mapList || [];
            _this.hasDetail = true;
          }
          _this.loading = false;
        }
      }).catch(function (res) {
        that.loading = false;
      });
    },
    // 导出功能
    download: function download() {
      var _this2 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _lithService.exportMarkCodeDetail)({
        markCode: this.listQuery.markCode
      }).then(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '锂电数码详情.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this2.downLoading = false;
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        _this2.downLoading = false;
      });
    },
    reset: function reset() {
      this.listQuery.current = 1;
      this.listQuery.markType = '';
      this.getList();
    } // resetData() {
    //   this.$refs.dataForm.resetFields()
    //   this.dialogFormVisible = false
    // }
    // detail(row) {
    //   this.dialogFormVisible = true
    //   this.loading = true
    //   queryMarkDetail(row.markCode)
    //     .then(res => {
    //       if (res.data.code == 200) {
    //         this.temp = Object.assign(this.temp, res.data.data)
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //       this.loading = false
    //     })
    //     .catch(() => {
    //       this.loading = false
    //     })
    // }
  }
};
exports.default = _default;