var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "活动方案详情",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "el-descriptions",
        { attrs: { title: "基本信息", column: 2, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "方案编码" } }, [
            _vm._v(_vm._s(_vm.ruleForm.schemeCode)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "方案名称" } }, [
            _vm._v(_vm._s(_vm.ruleForm.schemeName)),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "开始时间" } }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.startTime))),
          ]),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "截止时间" } }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.endTime))),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions-item",
            { attrs: { label: "状态" } },
            [
              _vm.ruleForm.status == 1
                ? _c("el-tag", { attrs: { type: "success" } }, [_vm._v("启用")])
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.status == 2
                ? _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("禁用")])
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.status == 3
                ? _c("el-tag", { attrs: { type: "info" } }, [_vm._v("删除")])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.createTime))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          attrs: { id: "picbox", column: 1, border: "", direction: "vertical" },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "附件" } }, [
            _c(
              "a",
              {
                staticStyle: {
                  "text-decoration": "underline !important",
                  color: "#409eff",
                },
                attrs: { href: _vm.ruleForm.attachment },
              },
              [_vm._v(_vm._s(_vm.ruleForm.attachment))]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-descriptions",
        {
          attrs: { id: "picbox", column: 1, border: "", direction: "vertical" },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "备注" } }, [
            _vm._v(_vm._s(_vm.ruleForm.remarks)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }