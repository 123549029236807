"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _auth = require("@/utils/auth");
var _wxintegral = require("@/api/wxhyyy/wxintegral");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */

var baseUrl = '/wh';
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      pageForm: {
        pageNo: 0,
        pageSize: 5
      },
      total: 0
    };
  },
  created: function created() {
    this.getIntegralFun();
  },
  methods: {
    getIntegralFun: function getIntegralFun() {
      var _this = this;
      (0, _wxintegral.getIntegralAct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageForm), {}, {
        cid: this.cid
      })).then(function (resp) {
        var resp = resp.data;
        if (resp.code === 0) {
          _this.list = resp.data.records;
          // this.list = resp.data.lstResult
          // this.total = resp.data.pageTotal
          _this.total = resp.data.total;
        }
      });
    },
    routeTo: function routeTo(type, data) {
      var obj = JSON.stringify({
        type: type,
        id: data
      });
      this.$router.push({
        name: 'wxAccountswxIntegralMallintegralSetindex',
        query: {
          data: obj
        }
      });
    },
    deleteFun: function deleteFun(id) {
      var _this2 = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(function () {
        deleteIntegralAct({
          cid: _this2.cid,
          id: id
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this2.$message({
              type: "success",
              message: "删除成功!"
            });
            _this2.list = _this2.list.filter(function (item, i) {
              return item.id !== id;
            });
          } else {
            _this2.$message({
              type: "error",
              message: "删除失败！"
            });
          }
        });
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    switchFun: function switchFun(statuse, id) {
      var _this3 = this;
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(statuse === "1" ? "开启" : "关闭", "\u8BE5\u6761\u79EF\u5206\u89C4\u5219, \u662F\u5426\u7EE7\u7EED?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(function () {
        (0, _wxintegral.integralActOpenOrOlose)({
          cid: _this3.cid,
          id: id,
          statuse: statuse
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this3.list.forEach(function (item) {
              if (item.id === id) {
                item.statuse = statuse;
              }
            });
            _this3.$message({
              type: "success",
              message: "".concat(statuse === "1" ? "开启" : "关闭", "\u6210\u529F!")
            });
          } else {
            _this3.$message({
              type: "info",
              message: "".concat(statuse === "1" ? "开启" : "关闭", "\u5931\u8D25!")
            });
          }
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "\u5DF2\u53D6\u6D88".concat(statuse === "1" ? "开启" : "关闭")
        });
      });
    },
    pagingFun: function pagingFun() {
      this.getIntegralFun();
    }
  }
};
exports.default = _default;