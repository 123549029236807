"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _select = require("@/api/warehouse/select");
var _storeTypeSelect = _interopRequireDefault(require("@/components/storeTypeSelect"));
var _print = _interopRequireDefault(require("./components/print"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _index = require("@/api/warehouse/checkout/index");
var _dictionary = require("@/api/systems/dictionary");
var _factory = require("@/api/basic/factory");
var _basic = require("@/api/mark/basic");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _record = require("@/api/warehouse/record/record");
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var qrcode = '';
var TypeDataComs = {
  djly: [],
  djzt: [],
  status: [],
  source: [],
  factory: [],
  typeList: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    factorySelectDialog: _factorySelectDialog.default,
    printDialog: _print.default,
    storeTypeSelect: _storeTypeSelect.default,
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productSelectDialog.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    TypeDataCom1: function TypeDataCom1(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.id == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djztText: function djztText(val) {
      var name = '';
      TypeDataComs.djzt.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: 'success',
        8: 'warning',
        9: 'warning',
        10: 'warning',
        11: 'warning',
        12: 'danger'
      };
      return obj[val];
    }
  },
  data: function data() {
    return {
      typeNum: 1,
      otherTypeName: '',
      otherTypeList: [],
      typeVisible: false,
      handleShow: false,
      printShow: false,
      factoryShow: false,
      companyShow: false,
      factorycanChange: true,
      companyType: '',
      inOutType: '',
      billNo: '',
      factoryType: '',
      searchInOrgName: '',
      searchOutOrgName: '',
      //  ？？？
      billType: 205,
      tableData: [],
      storeList: [],
      djly: [],
      djzt: [],
      searchForm: {
        billOutOthersQuery: {
          otherTypeId: '',
          outOrgId: '',
          outStoreId: '',
          inOrgId: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '205',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20
      },
      productName: '',
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: [],
        typeList: []
      },
      code_title: '二维码查看',
      codeVisible: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    // 单据来源
    this.getDirSel('DJLY', 'djly');
    // 单据状态
    this.getDirSel('DJZT', 'djzt');
    this.getStoreList();
    // this.getTypeList()
    if (this.$route.query.type) {
      this.searchForm.billOutOthersQuery.otherTypeId = this.$route.query.type;
      this.otherTypeName = '盘亏出库';
    } else {
      this.searchForm.billOutOthersQuery.otherTypeId = '';
      this.otherTypeName = '';
    }
    this.getList();
    this.getYwTypes();
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    // 获取业务类型
    getTypes: function getTypes(e) {
      var that = this;
      that.typeVisible = true;
    },
    // 获取业务类型
    getYwTypes: function getYwTypes() {
      var that = this;
      var param = {
        orderType: 2
      };
      (0, _select.queryByOrderType)(param).then(function (res) {
        console.log(res.data);
        if (res.data.code == 200) {
          that.otherTypeList = res.data.data;
        }
      });
    },
    closeType: function closeType() {
      var that = this;
      that.typeVisible = false;
    },
    getType: function getType(val) {
      var that = this;
      that.searchForm.billOutOthersQuery.otherTypeId = val.pid;
      that.otherTypeName = val.billName;
    },
    goPrint: function goPrint(row) {
      this.authId('printbtn', this.$route.meta.authList);
      this.billNo = row.billNo;
      this.printShow = true;
    },
    printClose: function printClose() {
      this.billNo = '';
      this.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // getTypeList() {
    //   // 获取单据类型
    //   this.TypeDataComs.typeList = []
    //   getYwTypes({ current: 1, size: 2222222, orderType: 2 }).then((res) => {
    //     if (res.data.code != 200) {
    //       return
    //     } else {
    //       this.TypeDataComs.typeList = res.data.data.records
    //       TypeDataComs.typeList = res.data.data.records
    //     }
    //   })
    // },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
            if (list == 'djzt') {
              TypeDataComs[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString()) && item.figure != 111 && item.figure != 109;
              });
              _this[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString()) && item.figure != 111 && item.figure != 109;
              });
            }
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    // /**
    //  * 工厂选择
    //  * @param {*} name 需要回传修改的名字
    //  * @param {*} status 是否允许修改 单位类型
    //  * @param {*} companyType 单位类型 1 ：企业, 3：工厂,  "" : 全部
    //  *
    //  */
    // openFactory(name, status, companyType) {
    //   this.inUseName = name
    //   this.factorycanChange = status
    //   this.factoryType = companyType
    //   this.factoryShow = true
    // },
    // setFactory(val) {
    //   this[this.inUseName] = val.orgName
    //   switch (this.inUseName) {
    //     case 'searchSendOutComName':
    //       // this.searchForm.billOutOthersQuery.outOrgId = val.pid
    //       this.searchForm.billOutOthersQuery.inOrgId = val.pid
    //       break
    //   }
    // },
    // factoryClose() {
    //   this.inUseName = ''
    //   this.factoryShow = false
    // },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchInOrgName':
          this.searchForm.billOutOthersQuery.inOrgId = val.companyId;
          break;
        case 'searchOutOrgName':
          this.searchForm.billOutOthersQuery.outOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.inOutType = '';
      this.companyShow = false;
    },
    handleFilter: function handleFilter(_type) {
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.productName = '';
      this.searchInOrgName = '';
      this.searchOutOrgName = '';
      this.otherTypeName = '';
      this.searchForm = {
        billOutOthersQuery: {
          inOrgId: '',
          otherTypeId: '',
          outOrgId: '',
          outStoreId: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '205',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20
      };
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryPage)(JSON.parse(JSON.stringify(this.searchForm))).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        if (!this.searchForm.factoryCode) {
          this.sellineList = JSON.parse(JSON.stringify(this.lineList));
          this.selteamList = JSON.parse(JSON.stringify(this.teamList));
        } else {
          this.sellineList = [];
          this.selteamList = [];
        }
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })

      this.sellineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.selteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })
      this.scanlineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.scanteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.$refs.insertMain.show(1, this.billType);
    },
    doUpdate: function doUpdate(row) {
      this.authId('updatebtn', this.$route.meta.authList);
      this.$refs.insertMain.upDateShow(row);
    },
    del: function del(row) {
      var _this3 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('deletebtn', _this3.$route.meta.authList);
        (0, _index.InfoDel)(row.pid).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this3.$message.error(data.msg);
            return false;
          }
          _this3.$message({
            message: '保存成功',
            type: 'success'
          });
          _this3.getList();
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this4 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var data = JSON.parse(JSON.stringify(this.searchForm));
      delete data.current;
      delete data.size;
      (0, _index.excelOut)(data).then(function (res) {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '材料出库.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 178,
          height: 178,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 获取产品
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    // 撤销结单
    cancelOr: function cancelOr(row) {
      var _this5 = this;
      this.$confirm('撤销完成后可继续原订单进行发货<br/>注意：撤销结单时涉及相关业务处理，可能用时较长，具体可在详情中进行查询。', '是否撤销结单', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _record.revertFinish)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this5.$message.error(data.msg);
            return false;
          }
          _this5.$message({
            message: '操作成功',
            type: 'success'
          });
          _this5.getList();
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;