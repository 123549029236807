"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _invest = require("@/api/market/invest");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _creatInvest = _interopRequireDefault(require("./creatInvest"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    creatInvest: _creatInvest.default
  },
  filters: {
    prizeType: function prizeType(type) {
      var data = {
        100: '红包',
        200: '实物',
        300: '优惠券',
        400: '积分',
        500: '字卡',
        900: '礼包'
      };
      return data[type] || '谢谢惠顾';
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    actId: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    selectItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dataActivityStatus: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: false,
      total: 0,
      editType: "add",
      creatVisible: false,
      actIdCurrent: '',
      investId: '',
      searchForm: {
        current: 1,
        size: 20,
        activityId: ""
      }
    };
  },
  computed: {
    isActivityOverdue: function isActivityOverdue() {
      return this.dataActivityStatus == '3';
    }
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    getDataList: function getDataList() {
      var _this = this;
      this.listLoading = true;
      this.searchForm.activityId = this.actId;
      console.log(this.searchForm);
      (0, _invest.getInvestList)(this.searchForm).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this.list = res.data.data.records;
            _this.total = res.data.data.total;
          } else {
            _this.$message.error(res.data.data.errMsg);
          }
          _this.listLoading = false;
        } else {
          _this.listLoading = false;
          _this.$message.error(res.data.msg);
          return;
        }
      });
    },
    edit: function edit(data) {
      this.editType = "edit";
      this.investId = data.id;
      this.actIdCurrent = this.actId;
      this.creatVisible = true;
      console.log(data);
    },
    up: function up(data, index) {
      var _this2 = this;
      if (!index && this.searchForm.current == 1) {
        this.$message.error('已处于最顶层不能再上移');
        return;
      }
      (0, _invest.upInvest)({
        id: data.id
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this2.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this2.$message.success('上移成功');
            _this2.getDataList();
          }
        } else {
          _this2.$message.error(res.data.msg);
          return;
        }
      });
    },
    down: function down(data, index) {
      var _this3 = this;
      // if(index + 1 === this.list.length) {
      //   this.$message.error('已处于最底层不能再下移')
      //   return
      // }
      (0, _invest.downInvest)({
        id: data.id
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this3.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this3.$message.success('下移成功');
            _this3.getDataList();
            return;
          }
        } else {
          _this3.$message.error(res.data.msg);
          return;
        }
      });
    },
    deleteItem: function deleteItem(data) {
      var _this4 = this;
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _invest.deleteInvest)({
          id: data.id
        }).then(function (res) {
          if (res.data.code == 200) {
            if (res.data.data.code) {
              _this4.$message.error(res.data.data.msg);
              return;
            } else {
              _this4.getDataList();
              _this4.$message.success('删除成功');
            }
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    creat: function creat() {
      this.editType = "add";
      this.actIdCurrent = this.actId;
      this.creatVisible = true;
    },
    confirmTip: function confirmTip(title, content, callback) {
      var showCancelButton = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      this.$confirm(content, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: showCancelButton
      }).then(function () {
        callback();
      }).catch(function (err) {
        console.log(err);
      });
    },
    closeCreat: function closeCreat() {
      this.editType = '';
      this.investId = '';
      this.actIdCurrent = '';
      this.creatVisible = false;
      this.getDataList();
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      // this.actId = "";
      this.type = "add";
    },
    goBack: function goBack() {
      this.$emit("close");
    }
  }
};
exports.default = _default2;