"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errMarkLogUser = errMarkLogUser;
exports.exportExcel = exportExcel;
exports.getLogList = getLogList;
exports.getQueryRecordForPage = getQueryRecordForPage;
exports.loglist = loglist;
exports.queryForPage = queryForPage;
exports.queryMarkVlid = queryMarkVlid;
exports.restValidCode = restValidCode;
var _request = _interopRequireDefault(require("@/utils/request"));
var mode = '';
var base = '';
//
function getLogList(data) {
  return (0, _request.default)({
    url: mode + '/api-basic/sysLog/getLogs',
    method: 'post',
    data: data
  });
}

// 重置验证码日志
function getQueryRecordForPage(params) {
  return (0, _request.default)({
    url: mode + '/api-mark/validCodeRecord/queryRecordForPage',
    method: 'get',
    params: params
  });
}

// 查询
function queryMarkVlid(params) {
  return (0, _request.default)({
    url: mode + '/api-mark/markValid/queryMarkVlid',
    method: 'post',
    params: params
  });
}
// 修改验证码
function restValidCode(params) {
  return (0, _request.default)({
    url: mode + '/api-mark/markValid/restValidCode',
    method: 'post',
    params: params
  });
}
// ///////////////////////////////////////
// 解封日志查询
function queryForPage(current, size, data) {
  return (0, _request.default)({
    url: base + "/market-center/drawErrMarkEnableLog/queryForPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
// 解封日志导出
function exportExcel(params) {
  return (0, _request.default)({
    url: base + '/market-center/drawErrMarkEnableLog/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 解封日志-操作人
function errMarkLogUser() {
  return (0, _request.default)({
    url: base + '/market-center/activityComBox/errMarkLogUser',
    method: 'get'
  });
}
// //////////////////////////////////////////////
// 补发日志
function loglist(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityPrizeSentagainLog/loglist',
    method: 'post',
    data: data
  });
}