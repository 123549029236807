"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isReload: true
    };
  },
  mounted: function mounted() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=I4OBZ-T3FKN-TREFF-SUZJX-2JQUQ-DVBLH&libraries=visualization';
    document.head.appendChild(script);
  },
  methods: {
    reload: function reload() {
      var _this = this;
      this.isReload = false;
      this.$nextTick(function () {
        _this.isReload = true;
      });
    }
  }
};
exports.default = _default;