"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _dealer = require("@/api/basic/dealer");
var _channel = require("@/api/basic/channel");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        terminal: '',
        provinceCode: '',
        areaSel: [],
        cityCode: '',
        countyCode: '',
        channelName: '',
        terminalStoreTypeId: '',
        current: 1,
        size: 20
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      channel: '',
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      },
      isShowSelectedTable: true,
      terminalStoreList: [],
      // 终端类型
      areaInUseList: [] // 省市区县
    };
  },
  mounted: function mounted() {
    this.getTerminalStore();
    this.getAreaInUse();
    this.setsel();
  },
  methods: {
    setViewData: function setViewData(key, data) {
      this.$emit('change', data);
    },
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.getList(true);
      this.$nextTick(function () {
        _this.data.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      setTimeout(function () {
        if (_this.tableData.length) {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        }
        _this.selLoading = false;
      }, 500);
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getTerminalStore: function getTerminalStore() {
      var _this2 = this;
      (0, _channel.terminalStore)().then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            _this2.terminalStoreList = res.data.data;
          } else {
            _this2.terminalStoreList = [];
          }
        } else {
          _this2.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {});
    },
    getAreaInUse: function getAreaInUse() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaInUseList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaInUseList = [];
          }
        }
      });
    },
    getList: function getList(isOverView) {
      var _this3 = this;
      var overView = isOverView == true ? true : false;
      var that = this;
      this.listLoading = true;
      if (that.searchForm.areaSel.length > 0) {
        that.searchForm.provinceCode = that.searchForm.areaSel[0];
        that.searchForm.cityCode = that.searchForm.areaSel[1];
        that.searchForm.countyCode = that.searchForm.areaSel[2];
      }
      var submitData = JSON.parse(JSON.stringify(that.searchForm));
      delete submitData.areaSel;
      (0, _dealer.getActivityTerminal)(submitData).then(function (res) {
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this3.listLoading = false;
      }).then(function () {
        _this3.data.length && _this3.tableData.length && _this3.data.map(function (row) {
          var rowItem = _this3.tableData.find(function (item) {
            return row.pid == item.pid;
          });
          overView && _this3.$refs.multipleTable.toggleRowSelection(rowItem || row, true);
        });
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        terminal: '',
        areaSel: [],
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        channelName: '',
        terminalStoreTypeId: '',
        current: 1,
        size: 20
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
      // this.proSelVisible = false
    },
    submit: function submit() {
      this.isShowSelectedTable = false;
      // console.log(this.multipleSelection)
      // this.$emit('change', this.multipleSelection)
      // this.selCancel()
    },
    tag1Close: function tag1Close(row) {
      var _this4 = this;
      this.$nextTick(function () {
        var closeItem = _this4.tableData.find(function (item) {
          return row.pid == item.pid;
        });
        _this4.$refs.multipleTable.toggleRowSelection(closeItem || row, false);
      });
    }
  }
};
exports.default = _default2;