"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    tipName: {
      type: String,
      default: ''
    },
    isClick: {
      type: Boolean,
      default: false
    },
    clickFun: {
      type: Function,
      default: function _default() {}
    },
    type: {
      type: String,
      default: '1'
    }
  },
  data: function data() {
    return {
      loading: true,
      chart: null,
      xData: [],
      sData: [],
      mapData: []
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      this.chart = _echarts.default.init(this.$el);
      // this.loading = false
      var yData = [];
      this.sData = [];
      for (var i = 0; i < this.mapData.length; i++) {
        if (this.type === '1') {
          if (i <= this.mapData.length / 2) {
            if (i < 10) {
              yData.push('0' + i + this.mapData[i].name);
            } else {
              yData.push(i + this.mapData[i].name);
            }
            this.sData.push(this.mapData[i]);
          }
        } else {
          if (i > this.mapData.length / 2) {
            yData.push(i + this.mapData[i].name);
            this.sData.push(this.mapData[i]);
          }
        }
      }
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        grid: {
          top: 100,
          left: 0,
          bottom: 100,
          containLabel: true
        },
        xAxis: [{
          show: false
        }],
        yAxis: [{
          type: 'category',
          inverse: true,
          nameGap: 16,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#CCCCCC'
            }
          },
          nameTextStyle: {
            color: '#CCCCCC'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            margin: 80,
            textStyle: {
              color: '#333333',
              align: 'left',
              fontSize: 14
            },
            lineHeight: 18,
            rich: {
              a: {
                color: '#fff',
                backgroundColor: '#FF4905',
                width: 18,
                height: 18,
                align: 'center',
                borderRadius: 2
              },
              b: {
                color: '#fff',
                backgroundColor: '#1E9FFF',
                width: 18,
                height: 18,
                align: 'center',
                borderRadius: 2
              }
            },
            formatter: function formatter(params) {
              if (parseInt(params.slice(0, 2)) < 3) {
                return ['{a|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)].join('\n');
              } else {
                return ['{b|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)].join('\n');
              }
            }
          },
          data: yData
        }],
        series: [{
          type: 'bar',
          roam: true,
          zlevel: 2,
          barMaxWidth: 20,
          itemStyle: {
            normal: {
              color: function color(params) {
                var colorList = [{
                  colorStops: [{
                    offset: 0,
                    color: '#FF4905'
                  }, {
                    offset: 1,
                    color: '#FF864B'
                  }]
                }, {
                  colorStops: [{
                    offset: 0,
                    color: '#1E9FFF'
                  }, {
                    offset: 1,
                    color: '#80C9FF'
                  }]
                }];
                if (params.dataIndex < 3 && _this.type === '1') {
                  return colorList[0];
                } else {
                  return colorList[1];
                }
              },
              barBorderRadius: [0, 15, 15, 0]
            }
          },
          // label: {
          //   normal: {
          //     show: false,
          //     position: 'inside',
          //     offset: [0, 10]
          //   },
          //   emphasis: {
          //     show: false,
          //     position: 'inside',
          //     offset: [10, 0]
          //   }
          // },
          data: this.sData
        }]
      });
      this.chart.on('click', function (params) {
        if (_this.isClick) {
          _this.clickFun();
        }
      });
    }
  }
};
exports.default = _default2;