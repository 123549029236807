var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择所属经销商",
        visible: _vm.proSelVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.proSelVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "calc(100% - 300px)",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.searchForm,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "选择方式" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "23%" },
                                          attrs: {
                                            placeholder: "请选择活动区域",
                                          },
                                          on: { change: _vm.regionqh },
                                          model: {
                                            value: _vm.region,
                                            callback: function ($$v) {
                                              _vm.region = $$v
                                            },
                                            expression: "region",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "按地区选择",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "按组织架构选择",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.region == "1"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "省",
                                            prop: "provinceName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value:
                                                _vm.searchForm.provinceName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "provinceName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.provinceName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "1"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "市",
                                            prop: "cityName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.cityName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "cityName",
                                                  $$v
                                                )
                                              },
                                              expression: "searchForm.cityName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "1"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "区县",
                                            prop: "countyName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.countyName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "countyName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.countyName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "1"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "经销商名称/编码",
                                            prop: "channelName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.channelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "channelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.channelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "2"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "铁军编码/名称",
                                            prop: "saleOrg1Name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value:
                                                _vm.searchForm.saleOrg1Name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "saleOrg1Name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.saleOrg1Name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "2"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "战区编码/名称",
                                            prop: "saleOrg2Name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value:
                                                _vm.searchForm.saleOrg2Name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "saleOrg2Name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.saleOrg2Name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "2"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "战队编码/名称",
                                            prop: "saleOrg3Name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value:
                                                _vm.searchForm.saleOrg3Name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "saleOrg3Name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.saleOrg3Name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.region == "2"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "经销商编码/名称",
                                            prop: "channelName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.channelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "channelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.channelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formConfig.btnFollow
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getList()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.resetForm },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "div",
                        { staticClass: "btnArea" },
                        [
                          _c(
                            "el-col",
                            { staticClass: "head-btn-group" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "section",
                    [
                      _vm.region == "1"
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              ref: "multipleTable",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                data: _vm.tableData,
                                "data-size": _vm.tableData.length,
                                height: "400px",
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                fit: "",
                                "row-key": "pid",
                                "highlight-current-row": "",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  "reserve-selection": true,
                                  width: "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "经销商编码",
                                  "min-width": "100",
                                  prop: "channelCode",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "经销商名称",
                                  "min-width": "100",
                                  prop: "channelName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "省",
                                  "min-width": "100",
                                  prop: "provinceName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "市",
                                  "min-width": "100",
                                  prop: "cityName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "区县",
                                  "min-width": "100",
                                  prop: "countyName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.region == "2"
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              ref: "multipleTable",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                data: _vm.tableData,
                                "data-size": _vm.tableData.length,
                                height: "400px",
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                fit: "",
                                "row-key": "pid",
                                "highlight-current-row": "",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  "reserve-selection": true,
                                  width: "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "经销商编码",
                                  "min-width": "100",
                                  prop: "channelCode",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "经销商名称",
                                  "min-width": "100",
                                  prop: "channelName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "铁军编码",
                                  "min-width": "100",
                                  prop: "saleOrg1Code",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "铁军名称",
                                  "min-width": "100",
                                  prop: "saleOrg1Name",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "战区编码",
                                  "min-width": "100",
                                  prop: "saleOrg2Code",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "战区名称",
                                  "min-width": "100",
                                  prop: "saleOrg2Name",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "战队编码",
                                  "min-width": "100",
                                  prop: "saleOrg3Code",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "战队名称",
                                  "min-width": "100",
                                  prop: "saleOrg3Name",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total,
                          page: _vm.searchForm.current,
                          limit: _vm.searchForm.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.searchForm, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.searchForm, "size", $event)
                          },
                          pagination: _vm.getList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page-section leftSide" }, [
                _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.selLoading,
                            expression: "selLoading",
                          },
                        ],
                        staticStyle: { height: "700px" },
                        attrs: { gutter: 10 },
                      },
                      [
                        _vm.isShowSelectedTable
                          ? _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "el-load-more-table",
                                    rawName: "v-el-load-more-table",
                                    value: {
                                      data: _vm.multipleSelection,
                                      setData: function (data) {
                                        return _vm.setViewData(
                                          "multipleSelection",
                                          data
                                        )
                                      },
                                    },
                                    expression:
                                      "{data: multipleSelection, setData: (data) => setViewData('multipleSelection', data)}",
                                  },
                                ],
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "1px",
                                },
                                attrs: {
                                  data: _vm.multipleSelection,
                                  fit: "",
                                  height: "100%",
                                },
                                on: {
                                  "update:data": function ($event) {
                                    _vm.multipleSelection = $event
                                  },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "已选经销商",
                                    "min-width": "100",
                                    prop: "channelName",
                                    align: "left",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.channelName) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    "(" +
                                                      scope.row.channelCode +
                                                      ")"
                                                  )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3469308498
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    width: "60",
                                    prop: "channelName",
                                    align: "left",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-button", {
                                              staticStyle: { color: "#99a3ad" },
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-delete-solid",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tag1Close(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1075083868
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.selCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }