"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _suplyuser = require("@/api/systems/suplyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _pwdconfig = require("@/api/systems/pwdconfig");
var _scan = _interopRequireDefault(require("./components/scan"));
var _scanRole = _interopRequireDefault(require("./components/scanRole"));
var _assignRole = _interopRequireDefault(require("./components/assignRole"));
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemrolesmangesuplyuserindex';
var _default = {
  name: 'Systemrolesmangesuplyuserindex',
  components: {
    Pagination: _Pagination.default,
    scan: _scan.default,
    scanRole: _scanRole.default,
    assignRole: _assignRole.default
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        size: 20
      },
      param: {
        loginAccount: '',
        userName: '',
        phoneNum: '',
        isEnable: '',
        supplierList: [],
        roleList: [],
        supplierStr: ''
      },
      list: [],
      tableKey: 'suplyTable',
      listLoading: false,
      tableHeight: '100%',
      total: 0,
      roleOption: [],
      multipleSelection: [],
      sparam: {
        current: 1,
        size: 10,
        supplierName: ''
      },
      argument: '',
      sloading: false,
      slist: [],
      total2: 0,
      dlogVisible: false,
      suplySelectData: {},
      addData: {
        loginAccount: '',
        userName: '',
        supplierId: '',
        supplierName: '',
        loginPwd: '',
        reloginPwd: '',
        phoneNum: '',
        email: '',
        isEnable: true,
        remark: '',
        id: ''
      },
      rules: {
        loginAccount: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        supplierName: [{
          required: true,
          message: '请输入供应商名称',
          trigger: 'focus'
        }],
        loginPwd: [{
          required: true,
          validator: _suplyuser.checkPwd,
          trigger: 'blur'
        }],
        reloginPwd: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }],
        phoneNum: [{
          required: true,
          validator: _suplyuser.checkPhone,
          trigger: 'blur'
        }],
        email: [{
          validator: _suplyuser.checkEmail,
          trigger: 'blur'
        }]
      },
      title: '供货用户-添加',
      dialogVisible: false,
      dstate: false,
      loading: false,
      apis: [],
      page2: false,
      endShow: true,
      operate: false,
      scanData: {},
      scanShow: false,
      roleData: [],
      roleShow: false,
      asroleShow: false,
      id: '',
      expandTxt: '展开',
      showCol: false,
      safeRules: {}
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tableHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getUserRloes();
      that.getList();
      that.getRuleDetail();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = {};
      param.loginAccount = that.param.loginAccount;
      param.userName = that.param.userName;
      param.phoneNum = that.param.phoneNum;
      param.isEnable = that.param.isEnable;
      param.supplierList = that.param.supplierList;
      param.roleList = that.param.roleList;
      (0, _suplyuser.getCompanyList)(that.listQuery, param).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 1000);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.param.loginAccount = '';
      that.param.supplierStr = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.supplierList = [];
      that.param.roleList = [];
      that.listQuery.current = 1;
      that.$refs[formName].resetFields();
      that.getList();
    },
    handleSelectionChange: function handleSelectionChange(data) {
      var that = this;
      that.multipleSelection = data;
    },
    // 获取所属角色
    getUserRloes: function getUserRloes() {
      var that = this;
      (0, _suplyuser.getAllQdRoles)().then(function (res) {
        if (res.data.code == 200) {
          that.roleOption = res.data.data;
        } else {
          that.roleOption = [];
        }
      });
    },
    // 获取供应商
    getQdTable: function getQdTable(str) {
      var that = this;
      that.argument = str;
      that.dlogVisible = true;
      that.getChanelDatas();
    },
    getChanelDatas: function getChanelDatas() {
      var that = this;
      that.sloading = true;
      (0, _suplyuser.getSpulyInfo)(that.sparam).then(function (res) {
        if (res.data.code == 200) {
          that.slist = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
          that.sloading = false;
        } else {
          that.sloading = false;
        }
      }).catch(function () {
        that.sloading = false;
      });
    },
    searchSply: function searchSply() {
      var that = this;
      that.sparam.current = 1;
      that.getChanelDatas();
    },
    DoSelect: function DoSelect(data) {
      var that = this;
      that.sparam.supplierName = '';
      var arg = that.argument;
      if (arg == 'search') {
        that.param.supplierStr = data.supplierName;
        that.param.supplierList[0] = data.pId;
      }
      if (arg == 'add') {
        that.addData.supplierName = data.supplierName;
        that.addData.supplierId = data.pId;
      }
      if (arg == 'edit') {
        that.addData.supplierName = data.supplierName;
        that.addData.supplierId = data.pId;
      }
      that.dlogVisible = false;
    },
    DoClose: function DoClose() {
      var that = this;
      that.sparam.supplierName = '';
      that.dlogVisible = false;
    },
    handleCloseWin: function handleCloseWin() {
      var that = this;
      that.sparam.supplierName = '';
      that.dlogVisible = false;
    },
    // 添加
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.argument = 'add';
      that.title = '供货用户-添加';
      that.addData.id = '';
      that.addData.loginAccount = '';
      that.addData.userName = '';
      that.addData.supplierId = '';
      that.addData.supplierName = '';
      that.addData.loginPwd = '';
      that.addData.reloginPwd = '';
      that.addData.phoneNum = '';
      that.addData.email = '';
      that.addData.isEnable = true;
      that.addData.remark = '';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      that.argument = 'edit';
      that.title = '供货用户-修改';
      var param = {};
      param.id = data.pid;
      that.addData.id = data.pid;
      that.addData.loginPwd = '';
      that.addData.reloginPwd = '';
      (0, _suplyuser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.addData.loginAccount = res.data.data.loginAccount;
          that.addData.userName = res.data.data.userName;
          that.addData.supplierId = res.data.data.supplierId;
          that.addData.supplierName = res.data.data.supplierName;
          that.addData.phoneNum = res.data.data.phoneNum;
          that.addData.email = res.data.data.email;
          that.addData.remark = res.data.data.remark;
          if (res.data.data.isEnable == 1) {
            that.addData.isEnable = true;
          } else {
            that.addData.isEnable = false;
          }
        } else {
          that.addData = {};
        }
        that.$nextTick(function () {
          that.dialogVisible = true;
        });
      });
    },
    getSingleCode: function getSingleCode() {
      var that = this;
      var arg = that.argument;
      that.getQdTable(arg);
    },
    handleClose: function handleClose() {
      var that = this;
      that.dialogVisible = false;
      that.resetForm('addForm');
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      var that = this;
      var type = that.argument;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var param = {};
          var addData = (0, _utils.deepClone)(that.addData);
          var loginAccount = addData.loginAccount;
          var pwd = "".concat(loginAccount.trim(), "+").concat(addData.loginPwd);
          param.loginAccount = addData.loginAccount;
          param.userName = addData.userName;
          param.supplierId = addData.supplierId;
          param.loginPwd = (0, _jsMd.default)(pwd);
          param.phoneNum = addData.phoneNum;
          param.email = addData.email;
          param.isEnable = addData.isEnable;
          param.remark = addData.remark;
          param.id = addData.id;
          param.groupCode = '4';
          param.postCode = '-1';
          if (param.isEnable == false) {
            param.isEnable = 0;
          } else {
            param.isEnable = 1;
          }
          if (that.addData.loginPwd != that.addData.reloginPwd) {
            that.$message.error('确认密码与输入密码不一致，请重新输入！');
            return;
          }
          if (param.supplierId == '') {
            that.$message.error('请输入供应商名称！');
            return;
          }
          // 密码安全等级校验
          var weekCheck = that.safeRules.weakCheck.split(',');
          if (weekCheck.includes(that.addData.loginPwd)) {
            _this.$confirm('密码安全等级太低,建议立即修改', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showClose: false,
              showCancelButton: false,
              type: 'warning',
              center: true
            }).then(function () {
              that.addData.loginPwd = '';
              that.addData.reloginPwd = '';
            }).catch(function () {});
            return;
          }
          that.loading = true;
          that.dsate = true;
          if (type == 'add') {
            (0, _suplyuser.addCompyUser)(param).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                that.loading = false;
                that.dsate = false;
                that.resetForm('addForm');
                that.getList();
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (type == 'edit') {
            (0, _suplyuser.editUserInfo)(param).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                that.loading = false;
                that.dsate = false;
                that.resetForm('addForm');
                that.getList();
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    },
    // 查看
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _suplyuser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
        } else {
          that.scanData = {};
        }
      });
      that.$nextTick(function () {
        that.scanShow = true;
      });
    },
    // 重置密码
    setPwd: function setPwd(data) {
      var that = this;
      var setHeaderId = that.authId('setbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _suplyuser.setUserPwd)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$alert(res.data.msg, '提示', {
            confirmButtonText: '我知道啦',
            callback: function callback(action) {
              console.log('已修改');
            }
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    // 查看角色
    scanRole: function scanRole(data) {
      var that = this;
      var setHeaderId = that.authId('scanrole', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _suplyuser.scanUserRole)(param).then(function (res) {
        if (res.data.code == 200) {
          that.roleData = res.data.data;
          that.roleShow = true;
        } else {
          that.roleData = [];
          that.roleShow = true;
        }
      });
    },
    // 禁用
    DoStop: function DoStop(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _suplyuser.stopUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.getList();
          } else {
            that.$message.error('禁用失败');
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    // 启用
    DoStart: function DoStart(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _suplyuser.startUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.getList();
          } else {
            that.$message.error('启用失败');
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    // 批量禁用
    AllStop: function AllStop() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要禁用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _suplyuser.stopItems)(sdata).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.multipleSelection = [];
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('已取消');
      });
    },
    // 批量启用
    AllStart: function AllStart() {
      var that = this;
      var setHeaderId = that.authId('allstart', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要启用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _suplyuser.startItems)(sdata).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.multipleSelection = [];
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('已取消');
      });
    },
    // 分配角色
    rankRole: function rankRole() {
      var that = this;
      var setHeaderId = that.authId('roleassign', that.apis);
      if (that.multipleSelection.length == 0) {
        that.$message.error('请选择需要分配的项');
        return;
      }
      that.$nextTick(function () {
        that.asroleShow = true;
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取安全规则
    getRuleDetail: function getRuleDetail() {
      var _this2 = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.data.code == 200) {
          if (res.data.data.data && res.data.data.data.length > 0) {
            _this2.safeRules = res.data.data.data[0];
            if (res.data.data.data[0].strongCheck == 1) {
              _this2.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                    }
                  }
                }
              }];
            }
            if (res.data.data.data[0].strongCheck == 0) {
              _this2.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^[A-Za-z\d$@!%*#?&]{7,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度7-20位'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度7-20位'));
                    }
                  }
                }
              }];
            }
          } else {
            _this2.safeRules = {};
          }
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;