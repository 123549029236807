var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "tradeBillAO",
            staticClass: "info-items",
            staticStyle: { width: "550px" },
            attrs: {
              model: _vm.tradeBillAO,
              rules: _vm.rules,
              "label-position": "right",
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "剔除数码:", prop: "oldMark" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "请输入要剔除的数码",
                            clearable: "",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.tradeBillAO.oldMark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tradeBillAO,
                                "oldMark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "tradeBillAO.oldMark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "新数码:", prop: "newMark" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "请输入新数码",
                            clearable: "",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.tradeBillAO.newMark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tradeBillAO,
                                "newMark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "tradeBillAO.newMark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          "margin-top": "2%",
                          "text-align": "right",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              loading: _vm.btn_loading,
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm()
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }