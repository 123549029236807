var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.mloading,
            expression: "mloading",
          },
        ],
        staticClass: "page-section",
      },
      [
        _c(
          "div",
          { staticClass: "steps-container" },
          [
            _c(
              "el-steps",
              [
                _c("el-step", {
                  attrs: { title: "基础配置", status: "success" },
                }),
                _vm._v(" "),
                _c("el-step", {
                  attrs: {
                    title: "返利配置",
                    status: _vm.isExectued == 2 ? "success" : "wait",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.mloading,
                expression: "mloading",
              },
            ],
            ref: "submitData",
            staticClass: "reset-form-label-top",
            attrs: {
              rules: _vm.rules,
              model: _vm.submitData,
              "label-suffix": ":",
              "label-position": "left",
              "label-width": "0",
            },
          },
          [
            _vm.isExectued == 1
              ? _c("div", { staticClass: "form-step-1" }, [
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-9.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("基本信息")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 16 } },
                        [
                          _vm.subType == "edit"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "方案编号",
                                        prop: "schemeCode",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.submitData.schemeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.submitData,
                                              "schemeCode",
                                              $$v
                                            )
                                          },
                                          expression: "submitData.schemeCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "方案名称",
                                    prop: "schemeName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.submitData.schemeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "schemeName",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.schemeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起始时间",
                                    prop: "dateTimeRange",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetimerange",
                                      placeholder: "选择日期",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": ["00:00:00", "23:59:59"],
                                    },
                                    on: { change: _vm.getTime },
                                    model: {
                                      value: _vm.submitData.dateTimeRange,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "dateTimeRange",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.dateTimeRange",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.subType == "edit"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "is-required",
                                      attrs: { label: "状态" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "disabled" },
                                        model: {
                                          value: _vm.statusName,
                                          callback: function ($$v) {
                                            _vm.statusName = $$v
                                          },
                                          expression: "statusName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "方案描述" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                      "show-word-limit": "",
                                      clearable: "",
                                      rows: 2,
                                    },
                                    model: {
                                      value: _vm.submitData.schemeDescribe,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "schemeDescribe",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.schemeDescribe",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-10.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("关联政策")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 16 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联政策",
                                    prop: "policyName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "zcbm",
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.submitData.policyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "policyName",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.policyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticStyle: { height: "28px" } }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { height: "38px" },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.chosePolice },
                                },
                                [_vm._v("选择")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.scanPolicy },
                                },
                                [_vm._v("查看政策")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-6.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("参与产品")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: { click: _vm.addProducts },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticClass: "detailTable",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            data: _vm.proList,
                            fit: "",
                            "max-height": "300",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClass",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "返利计量单位",
                              prop: "measurementUnit",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "返利倍数",
                              prop: "rebateProportion",
                              align: "center",
                              width: "150",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "输入1-10整数",
                                          type: "number",
                                          number: "true",
                                          min: 1,
                                        },
                                        model: {
                                          value: row.rebateProportion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "rebateProportion",
                                              $$v
                                            )
                                          },
                                          expression: "row.rebateProportion",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2446798967
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delPro(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2033395591
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-group-right" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "default", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCloseStep("submitData")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.checkStep1("submitData")
                            },
                          },
                        },
                        [_vm._v("下一步")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isExectued == 2
              ? _c("div", { staticClass: "form-step-2" }, [
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-5.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("返利范围")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "basic-form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cell",
                        staticStyle: { "padding-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "cell-label required" }, [
                          _vm._v("\n\t\t\t\t\t\t\t范围\n\t\t\t\t\t\t"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cell-primary" },
                          [
                            _c("treeselect", {
                              attrs: {
                                multiple: true,
                                limit: 6,
                                "flatten-search-results": true,
                                placeholder: "请选择...",
                                options: _vm.zzOptions,
                                clearAllText: "",
                              },
                              model: {
                                value: _vm.orgList,
                                callback: function ($$v) {
                                  _vm.orgList = $$v
                                },
                                expression: "orgList",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-11.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("返利配置")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "basic-form" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", { staticClass: "cell-label required" }, [
                        _vm._v("返利触发条件"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cell-primary" },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.changeSameAction },
                              model: {
                                value: _vm.triggerAction,
                                callback: function ($$v) {
                                  _vm.triggerAction = $$v
                                },
                                expression: "triggerAction",
                              },
                            },
                            _vm._l(_vm.triggerActions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "config-bg" },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { type: "flex" },
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { width: "134px" },
                                attrs: { span: 3 },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "chanelList" },
                                  _vm._l(_vm.channelTypes, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.isActive === item.order,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeStatus(
                                              item.type,
                                              item.order
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "border-left": "1px solid #EBECEEFF",
                                },
                                attrs: { span: 21 },
                              },
                              [
                                _vm._l(_vm.levelOneData, function (row, index) {
                                  return _vm.isActive == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "config-datas" },
                                        [
                                          _c("div", { staticClass: "cell" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-label",
                                                staticStyle: {
                                                  "margin-right": "20px",
                                                },
                                              },
                                              [_vm._v("范围选择")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-primary",
                                                staticStyle: {
                                                  "margin-right": "15px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道分类",
                                                    },
                                                    model: {
                                                      value: row.classId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "classId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.classId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelClassList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.className,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "cell-primary" },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道等级",
                                                    },
                                                    model: {
                                                      value: row.gradeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "gradeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.gradeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelLeveList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.gradeName,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-ft",
                                                staticStyle: {
                                                  "margin-left": "16px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      height: "36px",
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.DoDel(
                                                          index,
                                                          row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cell-start",
                                              staticStyle: {
                                                "margin-top": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cell-label",
                                                  staticStyle: {
                                                    "margin-right": "20px",
                                                  },
                                                },
                                                [_vm._v("返利奖品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cell-primary" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              model: {
                                                                value:
                                                                  row.checked,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "checked",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.checked",
                                                              },
                                                            },
                                                            [_vm._v("酒券")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "返利数量(元/个)"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("买")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value: row.buyNum,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "buyNum",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.buyNum",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("返")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              min: 1,
                                                              max: 999999999,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.checkNum(
                                                                  row.presentNum,
                                                                  row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                row.presentNum,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "presentNum",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.presentNum",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.triggerAction == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "cell",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "15px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-checkbox",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "101",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      row.rebateProductId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row,
                                                                          "rebateProductId",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "row.rebateProductId",
                                                                  },
                                                                },
                                                                [_vm._v("现金")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "返利数量(元/个)"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("买")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.buyNum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "buyNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.buyNum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("返")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  min: 1,
                                                                  max: 999999999,
                                                                  disabled:
                                                                    row.rebateProductId !=
                                                                    "101",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkNum(
                                                                        row.presentNum,
                                                                        row
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.presentNum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "presentNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.presentNum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          false
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "191px",
                                                                        },
                                                                      attrs: {
                                                                        disabled:
                                                                          "disabled",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.levelTwoData, function (row, index) {
                                  return _vm.isActive == 2
                                    ? _c(
                                        "div",
                                        { staticClass: "config-datas" },
                                        [
                                          _c("div", { staticClass: "cell" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-label",
                                                staticStyle: {
                                                  "margin-right": "20px",
                                                },
                                              },
                                              [_vm._v("范围选择")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-primary",
                                                staticStyle: {
                                                  "margin-right": "15px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道分类",
                                                    },
                                                    model: {
                                                      value: row.classId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "classId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.classId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelClassList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.className,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "cell-primary" },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道等级",
                                                    },
                                                    model: {
                                                      value: row.gradeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "gradeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.gradeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelLeveList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.gradeName,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-ft",
                                                staticStyle: {
                                                  "margin-left": "16px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      height: "36px",
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.DoDel(
                                                          index,
                                                          row,
                                                          11
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cell-start",
                                              staticStyle: {
                                                "margin-top": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cell-label",
                                                  staticStyle: {
                                                    "margin-right": "20px",
                                                  },
                                                },
                                                [_vm._v("返利奖品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cell-primary" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              model: {
                                                                value:
                                                                  row.checked,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "checked",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.checked",
                                                              },
                                                            },
                                                            [_vm._v("酒券")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "返利数量(元/个)"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("买")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value: row.buyNum,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "buyNum",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.buyNum",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("返")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              min: 1,
                                                              max: 999999999,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.checkNum(
                                                                  row.presentNum,
                                                                  row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                row.presentNum,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "presentNum",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.presentNum",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.triggerAction == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "cell",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "15px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-checkbox",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "101",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      row.rebateProductId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row,
                                                                          "rebateProductId",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "row.rebateProductId",
                                                                  },
                                                                },
                                                                [_vm._v("现金")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "返利数量(元/个)"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("买")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.buyNum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "buyNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.buyNum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("返")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  min: 1,
                                                                  max: 999999999,
                                                                  disabled:
                                                                    row.rebateProductId !=
                                                                    "101",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkNum(
                                                                        row.presentNum,
                                                                        row
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.presentNum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "presentNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.presentNum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.zdData, function (row, index) {
                                  return _vm.isActive == 3
                                    ? _c(
                                        "div",
                                        { staticClass: "config-datas" },
                                        [
                                          _c("div", { staticClass: "cell" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-label",
                                                staticStyle: {
                                                  "margin-right": "20px",
                                                },
                                              },
                                              [_vm._v("范围选择")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-primary",
                                                staticStyle: {
                                                  "margin-right": "15px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道分类",
                                                    },
                                                    model: {
                                                      value: row.classId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "classId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.classId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelClassList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.className,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "cell-primary" },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "请选择渠道等级",
                                                    },
                                                    model: {
                                                      value: row.gradeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "gradeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.gradeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.chanelLeveList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.pId,
                                                        attrs: {
                                                          label: item.gradeName,
                                                          value: item.pId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell-ft",
                                                staticStyle: {
                                                  "margin-left": "16px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      height: "36px",
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.DoDel(
                                                          index,
                                                          row,
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cell-start",
                                              staticStyle: {
                                                "margin-top": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cell-label",
                                                  staticStyle: {
                                                    "margin-right": "20px",
                                                  },
                                                },
                                                [_vm._v("返利奖品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cell-primary" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              model: {
                                                                value:
                                                                  row.checked1,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "checked1",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.checked1",
                                                              },
                                                            },
                                                            [_vm._v("酒券")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "返利数量(元/个)"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("买")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value: row.buyNum,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "buyNum",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.buyNum",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-label",
                                                        },
                                                        [_vm._v("返")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "191px",
                                                            },
                                                            attrs: {
                                                              type: "number",
                                                              min: 1,
                                                              max: 999999999,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.checkNum(
                                                                  row.presentNum,
                                                                  row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                row.presentNum1,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "presentNum1",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.presentNum1",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.triggerAction == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "cell",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "15px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-checkbox",
                                                                {
                                                                  model: {
                                                                    value:
                                                                      row.checked2,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row,
                                                                          "checked2",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "row.checked2",
                                                                  },
                                                                },
                                                                [_vm._v("现金")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "返利数量(元/个)"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("买")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.buyNum,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "buyNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.buyNum",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cell-label",
                                                            },
                                                            [_vm._v("返")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width:
                                                                    "191px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  min: 1,
                                                                  max: 999999999,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkNum(
                                                                        row.presentNum,
                                                                        row
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    row.presentNum2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        "presentNum2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "row.presentNum2",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "operation",
                                    staticStyle: { padding: "10px 20px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "align-right" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { height: "36px" },
                                            attrs: {
                                              type: "primary",
                                              icon: "el-icon-plus",
                                            },
                                            on: { click: _vm.addRow },
                                          },
                                          [_vm._v("添加")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-group-right" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "default", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCloseStep("submitData")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.prevStep },
                        },
                        [_vm._v("上一步")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit("0", "submitData")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit("1", "submitData")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("productMultiSelect", {
          attrs: { proSelVisible: _vm.proVisible, data: _vm.returnData },
          on: { close: _vm.closePro, change: _vm.fetchPros },
        }),
        _vm._v(" "),
        _c("policySelect", { ref: "policy", on: { change: _vm.getPolice } }),
        _vm._v(" "),
        _c("scanZc", {
          attrs: {
            policyCode: _vm.submitData.policyCode,
            detailVisible: _vm.policyVisible,
          },
          on: { close: _vm.closeScan },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }