var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "1200px !important",
            "before-close": _vm.goBack,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.goBack,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "header" }, [_vm._v("基础选项")]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticStyle: { margin: "10 auto" },
                      attrs: {
                        model: _vm.form,
                        rules: _vm.formRules,
                        "label-position": "right",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "海报名称", prop: "posterName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", maxlength: "25" },
                            model: {
                              value: _vm.form.posterName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "posterName", $$v)
                              },
                              expression: "form.posterName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联终端", prop: "terminalId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择关联终端",
                              },
                              on: { change: _vm.terminal },
                              model: {
                                value: _vm.form.terminalId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "terminalId", $$v)
                                },
                                expression: "form.terminalId",
                              },
                            },
                            _vm._l(_vm.terminalList, function (item) {
                              return _c("el-option", {
                                key: item.pid,
                                attrs: {
                                  label: item.terminalName,
                                  value: item.terminalCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效时间", prop: "dataTimeRange" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.form.dataTimeRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataTimeRange", $$v)
                              },
                              expression: "form.dataTimeRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "背景图片", prop: "backgroundUrl" } },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.imgloading,
                                  expression: "imgloading",
                                },
                              ],
                              attrs: {
                                "list-type": "picture-card",
                                action: "",
                                accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                                "show-file-list": false,
                                "http-request": _vm.beforeAvatarUpload,
                              },
                            },
                            [
                              _vm.form.backgroundUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.form.backgroundUrl },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  staticStyle: { color: "red" },
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                图片要求:最大不超过666*888,JPG或PNG格式的图片,大小不超过30M\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "海报活动链接", prop: "posterUrl" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            on: { change: _vm.upCode },
                            model: {
                              value: _vm.form.posterUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "posterUrl", $$v)
                              },
                              expression: "form.posterUrl",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("备注:第三方链接")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "commentInfo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "200",
                              rows: 3,
                              placeholder: "请输入",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.commentInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "commentInfo", $$v)
                              },
                              expression: "form.commentInfo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "activityStatus" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "3",
                              "active-text": "启用",
                              "inactive-text": "停用",
                            },
                            model: {
                              value: _vm.form.activityStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "activityStatus", $$v)
                              },
                              expression: "form.activityStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c("el-button", { on: { click: _vm.goBack } }, [
                            _vm._v("取 消"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.btnload },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "header cen" }, [_vm._v("效果预览")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "poster" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.form.backgroundUrl },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "qrcode",
                      attrs: { src: _vm.QRImgUrl },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.type == "edit"
                  ? _c(
                      "div",
                      { staticClass: "upbtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.upimageQR },
                          },
                          [_vm._v("合成并导出")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("imageQRcode", {
        ref: "imgCode",
        attrs: { resultimg: _vm.img, finalimg: _vm.img2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }