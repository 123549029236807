"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/fake_expel/basic");
var _expel = require("@/api/fake_expel/expel/expel");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _seach = _interopRequireDefault(require("../components/expel/seach"));
var _details = _interopRequireDefault(require("../components/expel/details"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FakeExpelwarningManageexpelindex',
  components: {
    Pagination: _Pagination.default,
    seach: _seach.default,
    detailsInfo: _details.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '防伪码查询',
        2: '物流码查询'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: [],
      total: 0,
      handleShow: false,
      listLoading: false,
      excelOut_loading: false,
      month: '',
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        ppwlList: [],
        wlm: '',
        queryType: 0,
        yschgsType: '',
        yschNum: '',
        yschqy: '',
        yjjxs: '',
        startTime: '',
        endTime: ''
      },
      dialogTitle: '',
      textMap: {
        setDetails: '查询次数详情'
      }
    };
  },
  activated: function activated() {
    var month = parseInt(new Date().getMonth()) + 1;
    this.month = month < 10 ? '0' + month + '' : month;
    var d = new Date().getFullYear() + '' + this.month;
    var lastDay = this.getLastDay(new Date().getFullYear(), this.month);
    this.listQuery.startTime = d + '01';
    this.listQuery.endTime = d + lastDay;
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 370 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 获得某月的最后一天
    getLastDay: function getLastDay(year, month) {
      // 取当前的年份
      var new_year = year;
      // 取下一个月的第一天，方便计算（最后一天不固定）
      var new_month = month++;
      if (month > 12) {
        // 月份减
        new_month -= 12;
        // 年份增
        new_year++;
      }
      // 取当年当月中的第一天
      var new_date = new Date(new_year, new_month, 1);
      // 获取当月最后一天日期
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate();
    },
    excelOut: function excelOut() {
      var _this = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.excelOut_loading = true;
      (0, _expel.excelOut)(this.listQuery).then(function (res) {
        _this.authId('searchbtn', _this.$route.meta.authList);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '防窜预警列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this.excelOut_loading = false;
      }).catch(function () {
        _this.authId('searchbtn', _this.$route.meta.authList);
        _this.excelOut_loading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _expel.fetchList)(this.listQuery).then(function (response) {
        var data = response.data.data;
        console.log(data, 'data');
        _this2.list = null;
        _this2.total = 0;
        _this2.listLoading = false;
        if (response.data.code != 200) {
          _this2.$message.error(data.msg);
          return;
        }
        if (data.records == '' || data.records == null) {
          _this2.list = null;
          _this2.total = 0;
          return;
        }
        _this2.list = data.records;
        _this2.total = Number(data.total);
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    searchList: function searchList(data) {
      this.listQuery = JSON.parse(JSON.stringify(data));
      this.getList();
    },
    setDetails: function setDetails(row) {
      this.dialogTitle = this.textMap.setDetails;
      this.$refs.detailsDialog.listQuery = (0, _basic.getKeysetVal)(this.$refs.detailsDialog.listQuery, this.listQuery);
      this.$refs.detailsDialog.listQuery.yschqy = row.yschqybm;
      this.$refs.detailsDialog.listQuery.pageNo = 1;
      this.$refs.detailsDialog.listQuery.yjjxs = row.yjjxs;
      this.$refs.detailsDialog.listQuery.ppwlList = row.wldm.split(',');
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.detailsDialog.dialogDetailsVisible = true;
    }
  }
};
exports.default = _default;