"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarketingManageprizeSettingsetWordindex',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return '/marketingManage/prizeSetting/setWord';
    }
  }
};
exports.default = _default;