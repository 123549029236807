var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image:url('" + _vm.list.background + "');",
      },
      [
        _c("el-image", {
          staticClass: "title",
          attrs: { src: _vm.list.title, lazy: "" },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "rotary-table",
              style:
                "background-image:url('" +
                _vm.list.rotaryImg +
                "');transform: rotate(-" +
                (360 / _vm.list.pies.length || 360) +
                "deg);",
            },
            [
              _c("div", { staticClass: "canvas-container" }, [
                _c("canvas", { ref: "canvas", staticClass: "canvas" }),
              ]),
              _vm._v(" "),
              _vm._l(_vm.list.pies, function (item, index) {
                return _c("div", { key: index, staticClass: "pie-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pie-container-content",
                      style: {
                        width: 240 / _vm.list.pies.length + "px",
                        transform:
                          "rotate(" +
                          index * (360 / _vm.list.pies.length) +
                          "deg) translateX(40%)",
                      },
                    },
                    [
                      _c("el-image", {
                        staticClass: "pie-img",
                        style: {
                          transform:
                            "rotate(" +
                            360 / (2 * _vm.list.pies.length) +
                            "deg)",
                        },
                        attrs: { fit: "contain", lazy: "", src: item.backImg },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "rotary-start-btn",
                  style:
                    "transform: translate(-50%, -50%) rotate(" +
                    (360 / _vm.list.pies.length || 360) +
                    "deg)",
                },
                [
                  _c("el-image", {
                    staticClass: "rotary-start-img",
                    attrs: { fit: "fill", src: _vm.list.startBtnImg, lazy: "" },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }