"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editWechat = editWechat;
exports.queryWechat = queryWechat;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 微信开放平台信息查询
function queryWechat() {
  return (0, _request.default)({
    url: base + '/market-config/wechatDisparkInfo/wechatDispark',
    // headers:{
    //   'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    // },
    method: 'get'
  });
}

// 微信开放平台信息修改
function editWechat(data) {
  return (0, _request.default)({
    url: base + '/market-config/wechatDisparkInfo/wechatDisparkSave',
    // headers:{
    //   'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    // },
    method: 'post',
    data: data
  });
}