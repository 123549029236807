"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _enterpriseUsers = _interopRequireDefault(require("./components/enterpriseUsers"));
var _dealer = require("@/api/basic/dealer");
var _salesArea = require("@/api/basic/salesArea");
var _product = require("@/api/basic/product");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _factory = require("@/api/basic/factory");
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _personnelCheckboxDialog = _interopRequireDefault(require("@/components/personnelCheckboxDialog"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
var _dictionary = require("@/api/systems/dictionary");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _channel2 = require("../../../../api/basic/channel");
var _utils2 = require("../../../../utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Treeselect from '@riophae/vue-treeselect'

var TypeDataComs = {
  ghList: []
};
var _default = {
  name: 'BasicDatachannelmangedealerprofileindex',
  components: {
    Pagination: _Pagination.default,
    PersonnelCheckboxDialog: _personnelCheckboxDialog.default,
    ChannelSelectDialog: _channelSelectDialog.default,
    // Treeselect,
    productDialog: _productDialog.default,
    enterpriseUsers: _enterpriseUsers.default
  },
  filters: {
    channelLevelName: function channelLevelName(value) {
      var name = '';
      TypeDataComs.ghList.forEach(function (c) {
        if (c.pid == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    var _this = this;
    var validateOrg = function validateOrg(rule, value, callback) {
      if (_this.orgselList.length == 0) {
        callback(new Error('所属公司不能为空，请维护'));
      } else {
        callback();
      }
    };
    var validateRegion = function validateRegion(rule, value, callback) {
      if (_this.ruleForm.regionName !== '' && _this.ruleForm.marketName == '') {
        callback(new Error('请完善所属组织'));
      } else {
        callback();
      }
    };
    // const validateDetailedAddress = (rule, value, callback) => {
    //   if (this.ruleForm.saleAreaType > 1) {
    //     callback()
    //   } else {
    //     callback()
    //   }
    // }
    var validatelongitude = function validatelongitude(rule, value, callback) {
      var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{6})|180\.0{6})$/;
      if (value) {
        if (!longreg.test(value)) {
          callback(new Error('经度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatelatitude = function validatelatitude(rule, value, callback) {
      var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{6}|90\.0{6}|[0-8]?\d{6})$/;
      if (value) {
        if (!latreg.test(value)) {
          callback(new Error('纬度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatepostalCode = function validatepostalCode(rule, value, callback) {
      if (value) {
        if (value.length != 6) {
          callback(new Error('邮政编码格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatemaster = function validatemaster(rule, value, callback) {
      if (value) {
        if (_this.ruleForm.masterChannelId == _this.ruleForm.pid) {
          callback(new Error('所属主体不能是自己'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatelasaleArea = function validatelasaleArea(rule, value, callback) {
      console.log(value);
      if (value.length < 1) {
        callback(new Error('自定义最少添加一个区域'));
      } else {
        callback();
      }
    };
    return {
      isSourceEnum2: false,
      hideUploadBtn: '',
      handleShow: false,
      centerDialogVisible: false,
      pId: false,
      checkStrong: 1,
      strongMsgList: [],
      searchClassList: [],
      // 客户组
      value: '',
      channelSelectType: '',
      masterName: '',
      masterChannelName: '',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      listLoading: false,
      btnLoading: false,
      downLoading: false,
      areaList: [],
      areaInUseList: [],
      classList: [],
      gradeList: [],
      page2: false,
      disabled: false,
      tableKey: '',
      tabHeight: '100%',
      channelOrgId: '',
      area: [],
      // areaSel: [],
      receiveAddress: '',
      searchForm: {
        archivesStatus: '',
        channelClassId: '',
        channelGradeId: '',
        custGroupId: '',
        channelOrgId: '',
        createTime: '',
        creatorId: '',
        masterChannelId: '',
        salesManId: '',
        channelType: 1,
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        current: 1,
        size: 20,
        marketName: '',
        // k3ChannelCode: '',
        channelCode: '',
        regionId: null
      },
      salesManUserId: '',
      // 存储业务员id,
      oldList: [],
      salesManName: '',
      dialogSalesmanList: false,
      querysalesman: {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      },
      ids: [],
      size: 10,
      current: 1,
      salesmanTotal: 0,
      salesmanSize: 10,
      salesmanList: [],
      isShow: false,
      tableData: [],
      total: 0,
      orgList: [],
      orgInUseList: [],
      archivesList: [],
      multipleSelection: [],
      normalList: [],
      // proClassList: [],
      proNormal: [],
      dialogVisible: false,
      scanVisible: false,
      title: '经销商档案',
      mloading: false,
      orgselList: [],
      // porList: [],
      salesmanIds: [],
      scanData: {},
      regionList: [],
      teamList: [],
      marketList: [],
      ruleForm: {
        sourceEnum: '',
        email: '',
        archivesStatus: 1,
        businessLicense: '',
        taxpayerNumber: '',
        channelName: '',
        channelCode: '',
        channelType: 1,
        // cityCode: "",
        classId: '',
        contacts: '',
        // countyCode: "",
        countyName: '',
        cityName: '',
        createTime: '',
        creatorId: '',
        detailedAddress: '',
        faxNumber: '',
        gradeId: '',
        latitude: '',
        longitude: '',
        openTime: null,
        phone: '',
        postalCode: '',
        // provinceCode: "",
        remarks: '',
        salesmanIds: '',
        channelLevelId: '1580160847425306626',
        saleAreaType: 2,
        saleArea: [],
        receiveAddress: '',
        regionId: '',
        teamId: '',
        marketId: '',
        areaCode: '',
        isCode: 1,
        pId: '',
        updateTime: '',
        updaterId: '',
        regionName: '',
        teamName: '',
        marketName: ''
      },
      ghList: [],
      rules: {
        // taxpayerNumber: [
        //   {
        //     required: true,
        //     message: '纳税人识别号不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        channelCode: [{
          required: true,
          message: '经销商编码不能为空，请维护',
          trigger: 'change'
        }],
        channelName: [{
          required: true,
          message: '经销商名称不能为空，请维护',
          trigger: 'change'
        }],
        orgselList: [{
          required: true,
          validator: validateOrg,
          trigger: 'change'
        }],
        marketName: [{
          required: false,
          validator: validateRegion,
          trigger: 'change'
        }],
        detailedAddress: [{
          required: true,
          // validator: validateDetailedAddress,
          message: '地址不能为空，请维护',
          trigger: 'change'
        }],
        latitude: [{
          validator: validatelatitude,
          trigger: 'change'
        }],
        longitude: [{
          validator: validatelongitude,
          trigger: 'change'
        }],
        postalCode: [{
          validator: validatepostalCode,
          trigger: 'change'
        }],
        masterChannelId: [{
          validator: validatemaster,
          trigger: 'change'
        }],
        email: [{
          type: 'email',
          message: '电子邮箱格式不正确',
          trigger: ['blur', 'change']
        }],
        contacts: [{
          required: false,
          message: '联系人不能为空，请维护',
          trigger: 'change'
        }],
        phone: [{
          required: false,
          message: '手机号不能为空，请维护',
          trigger: 'change'
        }, {
          pattern: /^0{0,1}(13[0-9]|145|147|149|15[5-9]|15[0-3]|166|17[1-3]|17[5-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          message: '手机号格式不对',
          trigger: 'blur'
        }],
        classId: [{
          required: true,
          message: '经销商分类不能为空，请维护',
          trigger: 'change'
        }],
        gradeId: [{
          required: true,
          message: '经销商等级不能为空，请维护',
          trigger: 'change'
        }],
        orgId: [{
          required: true,
          message: '所属公司不能为空，请维护',
          trigger: 'change'
        }],
        // regionId: [
        //   {
        //     required: true,
        //     message: "所属组织不能为空，请维护",
        //     trigger: "change"
        //   }
        // ],
        channelLevelId: [{
          required: true,
          message: '供货层级不能为空，请维护',
          trigger: 'change'
        }],
        saleArea: [{
          required: true,
          validator: validatelasaleArea,
          trigger: 'change'
        }],
        isCode: [{
          required: true,
          message: '扫托入库不能为空，请维护',
          trigger: 'change'
        }]
      },
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        channelType: 1,
        uploadFile: ''
      },
      uploadForm2: {
        uploadFile: ''
      },
      uploadVisible2: false,
      uploadRules: {},
      fileList: [],
      fileList2: [],
      imgList: [],
      aloading: false,
      ProvinceList: [],
      // 省份列表
      cityCode: '',
      // 城市
      provinceCode: '',
      // 省
      CountyList: [],
      // 区县列表
      countyCode: '',
      // 区县
      CityList: [],
      // 城市列表
      RegionData: {
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: ''
      },
      saleArea: [],
      excludeList: [],
      proShow: false,
      productList: [],
      productName: '',
      products: [],
      mome: {},
      dialogSalesman: false,
      SyncVisible: false,
      // 同步
      Syncform: {
        time: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        startRow: 0
      },
      Syncrules: {
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      sloading: false,
      depList: [],
      firmList: []
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jcChannelArchives/importExcel';
    },
    uploadUrl2: function uploadUrl2() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jcChannelArchives/importChannelSalesMan';
    },
    uploadImg: function uploadImg() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadImg';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getDirSel('JXSLEVEL', 'ghList');
    this.getList();
    // this.getAreaAll();
    // this.getOrgListAll();
    this.getProClassAll();
    this.getClassAll();
    this.getGradeAll();
    this.getAllList();
    // this.getOrgInUseList();
    this.getAreaInUse();
    // this.getStrong();
    this.newlistFun();
    // this.findAllProductFun();
    // this.getcomBoxSalesManlist();
    this.querylist();
    // this.getSearchClassAll()
    // this.getRegionList();
    this.getRegionAll();
  },
  // created() {
  //   if (this.ruleForm.sourceEnum === 2) {
  //     this.isSourceEnum2 = true
  //   }
  // },
  methods: {
    inpChange: function inpChange(e) {
      this.$forceUpdate();
    },
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    // 获取客户组
    getSearchClassAll: function getSearchClassAll() {
      var _this2 = this;
      (0, _channel.grouplist)({
        pageNo: 1,
        pageSize: 999999
      }).then(function (response) {
        if (response.data.code != 200) {
          _this2.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this2.searchClassList = response.data.data.records;
          } else {
            _this2.searchClassList = [];
          }
        }
      }).catch(function (res) {});
    },
    changeorg: function changeorg(orgId, type) {
      var _this3 = this;
      if (type == 'N') {
        this.ruleForm.departmentId = '';
      }
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this3.$message.error(response.data.msg);
        } else {
          _this3.depList = response.data.data;
        }
      });
    },
    changetab: function changetab(tab) {
      if (tab == 0) {
        this.ruleForm.list = [];
      }
    },
    getOrgList: function getOrgList() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.firmList = response.data.data;
        }
      });
    },
    validateRegion: function validateRegion(rule, value, callback) {
      if (this.ruleForm.regionName !== '' && this.ruleForm.marketName == '') {
        callback(new Error('请完善所属组织'));
      } else {
        callback();
      }
    },
    // findAllProductFun() {
    //   findAllProduct().then(res => {
    //     console.log(res);
    //     if (res.status === 200 && res.data.length > 0) {
    //       res.data.forEach(item => {
    //         this.products.push(item.pid);
    //       });
    //     }
    //   });
    // },
    newlistFun: function newlistFun() {
      var _this4 = this;
      (0, _dealer.newlist)({
        areaCode: '',
        areaName: '',
        areaType: '1',
        isDelete: 1,
        parentId: ''
      }).then(function (res) {
        if (res.data.code === 200) {
          _this4.ProvinceList = res.data.data;
        }
      });
    },
    nextAreaFun: function nextAreaFun(parentId) {
      var _this5 = this;
      (0, _dealer.nextArea)({
        isDelete: '',
        parentId: parentId
      }).then(function (res) {
        if (res.data.code === 200) {
          _this5.CityList = res.data.data;
          _this5.CountyList = res.data.data;
        }
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this6 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this6[list] = res.data.data;
            console.log(_this6[list], '000');
            TypeDataComs[list] = res.data.data;
          } else {
            _this6[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    clearMaster: function clearMaster() {
      // this.masterChannelName = ''
      // this.ruleForm.masterChannelId = ''
    },
    getStrong: function getStrong() {
      var _this7 = this;
      (0, _channel.detailList)().then(function (res) {
        if (res.data.code == 200) {
          _this7.strongMsgList = res.data.data;
          console.log(_this7.strongMsgList.length > 0);
        } else {
          _this7.$message.error(res.data.msg);
        }
      });
    },
    setCheckStrong: function setCheckStrong(row) {
      this.authId('checkbtn', this.$route.meta.authList);
      this.pId = row.pId;
      this.checkStrong = row.strongCheck;
      this.centerDialogVisible = true;
    },
    setStrong: function setStrong() {
      var _this8 = this;
      this.btnLoading = true;
      (0, _channel.checkStrong)({
        pId: this.pId,
        checkStrong: this.checkStrong
      }).then(function (response) {
        _this8.btnLoading = false;
        if (response.data.code != 200) {
          _this8.$message.error(response.data.msg);
          return;
        } else {
          _this8.$message({
            type: 'success',
            message: '修改成功'
          });
          _this8.getList();
          _this8.centerDialogVisible = false;
        }
      }).catch(function () {
        _this8.btnLoading = false;
      });
    },
    col: function col(val) {
      console.log(val, 111);
    },
    openTSel: function openTSel() {
      this.channelSelectType = 1;
      this.$refs.channelSelect.proSelVisible = true;
    },
    openCSel: function openCSel() {
      this.channelSelectType = 2;
      this.$refs.channelSelect.proSelVisible = true;
    },
    setCSel: function setCSel(val) {
      if (this.channelSelectType == 1) {
        this.searchForm.masterChannelId = val.pId;
        this.masterName = val.channelName;
      }
      if (this.channelSelectType == 2) {
        this.ruleForm.masterChannelId = val.pId;
        this.masterChannelName = val.channelName;
      }
    },
    getAllList: function getAllList() {
      var _this9 = this;
      (0, _channel.channelArchivesList)({
        channelType: 1,
        current: 1,
        size: 2147483647
      }).then(function (response) {
        if (response.data.code != 200) {
          _this9.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this9.archivesList = response.data.data.records;
          } else {
            _this9.archivesList = [];
          }
        }
      });
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils2.deepClone)(that.searchForm);
      if (that.channelOrgId) {
        submitData.channelOrgId = that.channelOrgId[that.channelOrgId.length - 1];
      }
      if (that.area) {
        submitData.provinceCode = that.area[0];
        submitData.cityCode = that.area[1] || '';
        submitData.countyCode = that.area[2] || '';
      }
      (0, _channel.channelArchivesList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    ordSel: function ordSel(val) {
      var that = this;
      if (val && val.length) {
        that.$refs.perCheckbox.searchForm.orgId = val[val.length - 1];
      } else {
        that.$refs.perCheckbox.searchForm.orgId = '';
      }
    },
    getGradeAll: function getGradeAll() {
      var that = this;
      (0, _channel.channelGradeList)({
        pageNo: 0,
        pageSize: 0,
        channelType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.gradeList = response.data.data;
          } else {
            that.gradeList = [];
          }
        }
      }).catch(function (res) {});
    },
    getRegionAll: function getRegionAll() {
      var that = this;
      (0, _salesArea.selectLargeRegionByType)({
        regionType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.regionList = response.data.data;
          } else {
            that.regionList = [];
          }
        }
      }).catch(function (res) {});
    },
    getClassAll: function getClassAll() {
      var that = this;
      (0, _channel.channelClassList)({
        pageNo: 0,
        pageSize: 0,
        channelType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.classList = response.data.data;
          } else {
            that.classList = [];
          }
        }
      }).catch(function (res) {});
    },
    getOrgListAll: function getOrgListAll() {
      var _this10 = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: '',
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this10;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    // loadRegion(node, resolve) {
    //   console.log(node, "node");
    //   if (node.level == 0) {
    //     selectLargeRegionByType({
    //       regionType: 1
    //     }).then(res => {
    //       resolve(res.data.data);
    //     });
    //   } else {
    //   selectLargeRegionByParentId({ pId: node.data.pId }).then(res => {
    //       resolve(res.data.data);
    //     });
    //   }
    // },
    getOrgInUseList: function getOrgInUseList() {
      var _this11 = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this11;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgInUseList = JSON.parse(JSON.stringify(Data));
        }
      });
    },
    getProClassAll: function getProClassAll() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var arr = [];
            res.data.data.forEach(function (item) {
              var obj = {
                id: item.pId,
                label: item.productClassName,
                pId: item.pId,
                parentId: item.parentId
              };
              arr.push(obj);
            });
            var data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(arr)), 'pId', 'parentId', 'children');
            that.proClassList = data;
            that.proNormal = arr;
          } else {
            that.proClassList = [];
            that.proNormal = [];
          }
        }
      });
    },
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: ''
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaList = [];
          }
        }
      });
    },
    loadArea: function loadArea(node, resolve) {
      if (node.value != null) {
        (0, _dealer.nextArea)({
          isDelete: '',
          parentId: node.value
        }).then(function (res) {
          resolve(res.data.data);
        });
      }
    },
    getAreaInUse: function getAreaInUse() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaInUseList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaInUseList = [];
          }
        }
      });
    },
    openSalesManId: function openSalesManId() {
      this.dialogSalesmanList = true;
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        // 关键字,
        archivesStatus: '',
        channelClassId: '',
        channelGradeId: '',
        channelOrgId: '',
        custGroupId: '',
        // masterChannelId: '',
        channelType: 1,
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        current: 1,
        size: 20,
        // k3ChannelCode: '',
        channelCode: '',
        regionId: null
      };
      this.masterName = '';
      this.channelOrgId = '';
      this.salesManName = '';
      this.area = [];
      this.getList();
    },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    //   if (this.multipleSelection.length) {
    //     this.ids = this.multipleSelection.map(item => item.pId);
    //   }
    // },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.orgselList.unshift(val);
          if (number.parentPid) {
            that.filterparentList(number.parentPid);
          }
        }
      });
    },
    // filterPorList(val, item) {
    //   var that = this;
    //   var arr = item || [];
    //   this.proNormal.forEach(function(number) {
    //     if (number.pId == val) {
    //       arr.unshift(val);
    //       if (number.parentId) {
    //         that.filterPorList(number.parentId, arr);
    //       }
    //     }
    //   });
    //   return arr;
    // },
    add: function add() {
      this.title = '添加经销商档案';
      this.myHeaders.btnId = this.authId('addbtn', this.$route.meta.authList);
      // this.getOrgList();
      this.dialogVisible = true;
    },
    scanClose: function scanClose() {
      this.scanData = {};
      this.imgList = [];
      this.scanVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    detail: function detail(row) {
      var _this12 = this;
      this.authId('detailbtn', this.$route.meta.authList);
      this.title = '经销商档案';
      this.scanVisible = true;
      this.mloading = true;
      (0, _channel.channelArchivesDetail)({
        pId: row.pId
      }).then(function (res) {
        _this12.mloading = false;
        if (res.data.code == 200) {
          _this12.scanData = res.data.data;
          var provinceName = _this12.scanData.provinceName || '';
          var cityName = _this12.scanData.cityName || '';
          var countyName = _this12.scanData.countyName || '';
          var detailedAddress = _this12.scanData.detailedAddress || '';
          _this12.scanData.add = provinceName + cityName + countyName + detailedAddress;
          var longitude = _this12.scanData.longitude || '';
          var latitude = _this12.scanData.latitude || '';
          _this12.scanData.jwd = longitude + '\xa0\xa0\xa0' + latitude;
          if (res.data.data.businessLicense) {
            _this12.scanData.imgList = res.data.data.businessLicense.split(',');
          }
          // var porList = [];
          // if (res.data.data.productClasses) {
          //   res.data.data.productClasses.forEach(item => {
          //     var arr = this.filterPorList(item.pid);
          //     porList.push(arr);
          //   });
          // }
          // this.porList = porList;
          if (res.data.data.salesmans) {
            _this12.salesmanIds = [];
            res.data.data.salesmans.forEach(function (item) {
              _this12.salesmanIds.push({
                pid: item.pid,
                loginAccount: item.loginAccount
              });
            });
          }
        } else {
          _this12.$message.error(res.data.msg);
        }
        _this12.mloading = false;
      }).catch(function () {
        _this12.mloading = false;
      });
    },
    DoEdit: function DoEdit(row) {
      var _this13 = this;
      if (row.sourceEnum === 2) {
        this.isSourceEnum2 = true;
      } else {
        this.isSourceEnum2 = false;
      }
      console.log(row, 21123);
      var that = this;
      that.title = '修改经销商档案';
      this.mloading = true;
      this.getOrgList();
      this.imgList = [];
      this.myHeaders.btnId = this.authId('updatebtn', this.$route.meta.authList);
      (0, _channel.channelArchivesDetail)({
        pId: row.pId
      }).then(function (res) {
        if (res.data.code == 200) {
          _this13.ruleForm = res.data.data;
          // delete this.ruleForm.regionName;
          _this13.ruleForm.channelLevelId = res.data.data.channelLevelId;
          if (_this13.ruleForm.provinceCode) {
            _this13.aloading = true;
            (0, _dealer.nextArea)({
              isDelete: '',
              parentId: _this13.ruleForm.provinceCode
            }).then(function (res) {
              setTimeout(function () {
                _this13.aloading = false;
              }, 1000);
              if (res.data.code === 200) {
                _this13.CityList = res.data.data;
              }
            });
          }
          if (_this13.ruleForm.cityCode) {
            _this13.aloading = true;
            (0, _dealer.nextArea)({
              isDelete: '',
              parentId: _this13.ruleForm.cityCode
            }).then(function (res) {
              setTimeout(function () {
                _this13.aloading = false;
              }, 1000);
              if (res.data.code === 200) {
                _this13.CountyList = res.data.data;
              }
            });
          }

          // 设置城市的名称
          if (_this13.ruleForm.provinceCode && _this13.ruleForm.cityCode) {
            var city = _this13.CityList.find(function (item) {
              return item.code === _this13.ruleForm.cityCode;
            });
            if (city) {
              _this13.$set(_this13.ruleForm, 'cityName', city.name);
            }
          }

          // 设置区县的名称
          if (_this13.ruleForm.cityCode && _this13.ruleForm.countyCode) {
            var county = _this13.CountyList.find(function (item) {
              return item.code === _this13.ruleForm.countyCode;
            });
            if (county) {
              _this13.$set(_this13.ruleForm, 'countyName', county.name);
            }
          }
          if (res.data.data.businessLicense) {
            var imgArr = res.data.data.businessLicense.split(',');
            imgArr.forEach(function (item) {
              _this13.imgList.push({
                url: item,
                response: {
                  data: item
                }
              });
            });
          }
          if (res.data.data.salesmans) {
            _this13.salesmanIds = [];
            res.data.data.salesmans.forEach(function (item) {
              _this13.salesmanIds.push({
                pid: item.pid,
                loginAccount: item.loginAccount
              });
            });
          }
          res.data.data.saleArea.forEach(function (item, index) {
            item.id = index;
          });
          _this13.saleArea = res.data.data.saleArea;
        } else {
          _this13.$message.error(res.data.msg);
        }
        _this13.mloading = false;
      }).catch(function () {
        _this13.mloading = false;
      });
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.salesmanIds = [];
      this.receiveAddress = [];
      // this.porList = [];
      this.orgselList = [];
      this.imgList = [];
      this.masterChannelName = '';
      this.ruleForm = {
        sourceEnum: '',
        email: '',
        archivesStatus: 1,
        businessLicense: '',
        taxpayerNumber: '',
        channelCode: '',
        channelName: '',
        channelType: 1,
        cityCode: '',
        departmentId: '',
        classId: '',
        contacts: '',
        countyCode: '',
        detailedAddress: '',
        faxNumber: '',
        // fileUrls: '',
        gradeId: '',
        latitude: '',
        longitude: '',
        // masterChannelId: '',
        openTime: null,
        // orgId: '',
        phone: '',
        postalCode: '',
        // productClassIds: '',
        // productIds: '',
        provinceCode: '',
        remarks: '',
        salesmanIds: '',
        channelLevelId: '1580160847425306626',
        saleAreaType: 2,
        saleArea: [],
        // areaSel: [],
        receiveAddress: '',
        isCode: 1
      };
      this.disabled = false;
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
      this.productName = '';
    },
    submitForm: function submitForm() {
      var _this14 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this14.btnLoading = true;
          var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this14.ruleForm)));
          submitData.openTime = _this14.ruleForm.openTime ? (0, _utils.parseTime)(_this14.ruleForm.openTime, '{y}-{m}-{d}') : '';
          delete submitData.regionName;
          // delete submitData.updateTime;
          delete submitData.productClasses;
          delete submitData.masterChannelArchives;
          // delete submitData.updaterId;
          delete submitData.salesmans;
          delete submitData.strongCheck;
          delete submitData.cityName;
          delete submitData.className;
          delete submitData.countyName;
          delete submitData.gradeName;
          delete submitData.orgName;
          delete submitData.provinceName;
          delete submitData.products;
          delete submitData.receiveAddress;
          delete submitData.groupCode;
          delete submitData.groupName;
          delete submitData.isUsed;
          delete submitData.minTypeName;
          delete submitData.minType;
          delete submitData.departmentId;
          delete submitData.regionList;
          delete submitData.marketName;
          delete submitData.marketId;
          delete submitData.teamName;
          delete submitData.teamId;
          if (_this14.imgList.length > 0) {
            var imgArr = [];
            _this14.imgList.forEach(function (item) {
              imgArr.push(item.response.data);
            });
            submitData.businessLicense = imgArr.join(',');
          }
          if (_this14.salesmanIds.length > 0) {
            var salesArr = [];
            _this14.salesmanIds.forEach(function (item) {
              salesArr.push(item.pid);
            });
            submitData.salesmanIds = salesArr.join(',');
          }
          submitData.cityCode = _this14.ruleForm.cityCode;
          submitData.countyCode = _this14.ruleForm.countyCode;
          if (_this14.ruleForm.pid) {
            _this14.authId('updatebtn', _this14.$route.meta.authList);
            delete submitData.departmentName;
            delete submitData.createUserName;
            delete submitData.pid;
            delete submitData.userName;
            submitData.pId = _this14.ruleForm.pid;
            if (_this14.ruleForm.regionId == '') {
              submitData.regionId = _this14.ruleForm.marketName;
            }
            if (_this14.ruleForm.regionId !== '' && !isNaN(_this14.ruleForm.marketName)) {
              submitData.regionId = _this14.ruleForm.marketName;
            }
            console.log(_this14.ruleForm, 'updateruleForm121212');
            console.log(submitData, 'submitData');
            (0, _channel.channelArchivesUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this14.$message.error(res.data.msg);
              } else {
                _this14.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this14.handleClose();
                _this14.getList();
              }
              _this14.btnLoading = false;
            }).catch(function () {
              _this14.btnLoading = false;
            });
          } else {
            _this14.authId('addbtn', _this14.$route.meta.authList);
            submitData.regionId = _this14.ruleForm.marketName;
            // submitData.cityCode = this.ruleForm.cityName
            // submitData.countyCode = this.ruleForm.countyName
            console.log(_this14.ruleForm, 'insertruleForm121212');
            console.log(submitData, 'insertsubmitdata343434');
            (0, _channel.channelArchivesInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this14.$message.error(res.data.msg);
              } else {
                _this14.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this14.handleClose();
                _this14.getList();
              }
              _this14.btnLoading = false;
            }).catch(function () {
              _this14.btnLoading = false;
            });
          }
        }
      });
    },
    // 解除业务
    handleRelieveALLs: function handleRelieveALLs(row) {
      var _this15 = this;
      this.$confirm('是否解除所选经销商所有业务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var arr = [];
        arr.push({
          channelId: row.pid
        });
        (0, _channel.batcRemoveAllSalesMan)(arr).then(function (res) {
          if (res.data.code != 200) {
            _this15.$message.error(res.data.msg);
          } else {
            _this15.$message({
              message: '操作成功',
              type: 'success'
            });
            _this15.getList();
          }
        });
      }).catch(function () {});
    },
    setInUse: function setInUse(id) {
      var _this16 = this;
      this.$confirm('请确认是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this16.authId('startbtn', _this16.$route.meta.authList);
        (0, _channel.channelArchivesEnable)({
          archivesStatus: 1,
          pId: id
        }).then(function (res) {
          _this16.authId('searchbtn', _this16.$route.meta.authList);
          if (res.data.code != 200) {
            _this16.$message.error(res.data.msg);
          } else {
            _this16.$message({
              message: '操作成功',
              type: 'success'
            });
            _this16.getList();
          }
        });
      }).catch(function () {
        _this16.authId('searchbtn', _this16.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(id) {
      var _this17 = this;
      this.$confirm('请确认是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this17.authId('stopbtn', _this17.$route.meta.authList);
        (0, _channel.channelArchivesEnable)({
          archivesStatus: 0,
          pId: id
        }).then(function (res) {
          _this17.authId('searchbtn', _this17.$route.meta.authList);
          if (res.data.code != 200) {
            _this17.$message.error(res.data.msg);
          } else {
            _this17.$message({
              message: '操作成功',
              type: 'success'
            });
            _this17.getList();
          }
        });
      }).catch(function () {
        _this17.authId('searchbtn', _this17.$route.meta.authList);
      });
    },
    DoDel: function DoDel(id) {
      var _this18 = this;
      var that = this;
      that.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this18.authId('deletebtn', _this18.$route.meta.authList);
        (0, _channel.channelArchivesDelete)({
          pId: id,
          isDelete: 2
        }).then(function (res) {
          _this18.authId('searchbtn', _this18.$route.meta.authList);
          if (res.data.code == 200) {
            _this18.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            _this18.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this18.authId('searchbtn', _this18.$route.meta.authList);
      });
    },
    selectFile: function selectFile(file, fileList) {
      this.uploadForm.uploadFile = file.name;
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.fileList = [];
      this.resetForm('uploadForm');
      this.uploadVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    tag1Close: function tag1Close(tag) {
      if (!this.disabled) {
        this.salesmanIds.splice(this.salesmanIds.indexOf(tag), 1);
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      this.imgList = fileList;
      this.hideUploadBtn = fileList.length >= 5;
    },
    beforeAvatarUpload: function beforeAvatarUpload(file, fileList) {
      // const file = param.file
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.2;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 PNG/JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 200kb!');
      }
      // if ((isJPG || isPNG) && isLt2M) {
      //   const formData = new FormData()
      //   formData.append('file', file)
      //   imgupload(formData).then(response => {
      //     var data = response.data
      //     if (data.code != 200) {
      //       this.$message.error(data.msg)
      //     } else {
      //       this.imgList.push({ url: data.data })
      //     }
      //   })
      // }
      return (isJPG || isPNG) && isLt2M;
    },
    openSel: function openSel() {
      // if (this.orgselList.length == 0) {
      //   this.$message.error('请先选择组织')
      // } else {
      //   this.$refs.perCheckbox.searchForm.orgId = this.orgselList[
      //     this.orgselList.length - 1
      //   ]
      //   this.$refs.perCheckbox.proSelVisible = true
      //   this.$refs.perCheckbox.setsel()
      // }
      // if (!this.ruleForm.orgId) {
      //   this.$message.error('请先选择所属公司')
      // } else {
      // this.$refs.perCheckbox.searchForm.orgId = this.ruleForm.departmentId || this.ruleForm.orgId
      this.$refs.perCheckbox.proSelVisible = true;
      this.$refs.perCheckbox.setsel();
      // }
    },
    setSel: function setSel(val) {
      this.salesmanIds = val;
    },
    download: function download() {
      var _this19 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)(this.searchForm);
      submitData = JSON.parse(JSON.stringify(submitData));
      delete submitData.size;
      (0, _channel.channelArchivesExportExcel)(submitData).then(function (res) {
        _this19.authId('searchbtn', _this19.$route.meta.authList);
        _this19.downLoading = false;
        if (res.status != 200) {
          _this19.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '经销商档案.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this19.authId('searchbtn', _this19.$route.meta.authList);
        _this19.downLoading = false;
      });
    },
    uploadShow: function uploadShow() {
      this.myHeaders.btnId = this.authId('allinport', this.$route.meta.authList);
      this.uploadForm.uploadFile = '';
      this.uploadVisible = true;
    },
    selectFile2: function selectFile2(file, fileList) {
      this.uploadForm2.uploadFile = file.name;
    },
    uploadShow2: function uploadShow2() {
      this.myHeaders.btnId = this.authId('allinport', this.$route.meta.authList);
      this.uploadForm2.uploadFile = '';
      this.uploadVisible2 = true;
    },
    uploadClose2: function uploadClose2() {
      this.uploadForm2.uploadFile = '';
      this.fileList2 = [];
      this.resetForm('uploadForm2');
      this.uploadVisible2 = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    uploadFileRes2: function uploadFileRes2(res, file, fileList2) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
      } else if (res.code != 200) {
        this.uploadForm2.uploadFile = '';
        this.fileList2 = [];
        this.$message.error(res.msg);
        this.uploadClose2();
      } else {
        this.mloading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.uploadClose2();
        this.getList();
      }
      this.mloading = false;
      this.uploadForm2.uploadFile = '';
      this.fileList2 = [];
      this.$refs.upload.clearFiles();
    },
    uploadFileFail2: function uploadFileFail2(res, file, fileList2) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
      this.uploadForm2.uploadFile = '';
      this.$refs.upload.clearFiles();
    },
    batchAddSalesman: function batchAddSalesman() {
      if (!this.multipleSelection.length) {
        return this.$message.error('请选择经销商!');
      }
      this.salesManUserId = '';
      this.isShow = true;
    },
    batchUpdateSalesman: function batchUpdateSalesman() {
      if (!this.searchForm.salesManId) {
        return this.$message.error('请选择业务员!');
      }
      if (!this.multipleSelection.length) {
        return this.$message.error('请选择经销商!');
      }
      this.salesManUserId = this.searchForm.salesManId;
      this.isShow = true;
    },
    batchDeleteSalesman: function batchDeleteSalesman() {
      var _this20 = this;
      if (this.multipleSelection.length > 0) {
        this.$confirm('是否解除所选经销商所有业务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var pList = [];
          _this20.multipleSelection.forEach(function (item) {
            pList.push({
              channelId: item.pId
            });
          });
          (0, _channel.batcRemoveAllSalesMan)(pList).then(function (res) {
            if (res.data.code != 200) {
              _this20.$message.error(res.data.msg);
              _this20.getList();
            } else {
              _this20.$message({
                message: '操作成功',
                type: 'success'
              });
              _this20.searchForm.salesManId = '';
              _this20.salesManName = '';
              _this20.getList();
            }
          });
        }).catch(function () {
          _this20.searchForm.salesManId = '';
          _this20.salesManName = '';
          _this20.getList();
        });
      } else {
        this.$message.error('请选择经销商');
      }
    },
    submitUpload: function submitUpload() {
      if (this.$refs.upload.uploadFiles.length == 0) {
        this.$message.error('请选择上传文件！');
        return;
      }
      this.mloading = true;
      this.uploadData = [];
      this.$refs.upload.submit();
    },
    submitUpload2: function submitUpload2() {
      if (this.$refs.upload.uploadFiles.length == 0) {
        this.$message.error('请选择上传文件！');
        return;
      }
      this.mloading = true;
      this.$refs.upload.submit();
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.uploadForm.uploadFile = '';
        this.fileList = [];
        this.$message.error(res.msg);
        this.uploadClose();
      } else {
        this.mloading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.uploadClose();
        this.getList();
      }
      this.mloading = false;
      this.uploadForm.uploadFile = '';
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    selectImg: function selectImg(file, fileList) {
      this.imgList = fileList;
      this.hideUploadBtn = fileList.length >= 5;
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
      this.uploadForm.uploadFile = '';
      this.$refs.upload.clearFiles();
    },
    // 省份
    ProvinceFun: function ProvinceFun(parentId) {
      var _this21 = this;
      this.ruleForm.cityName = '';
      this.ruleForm.countyName = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.countyCode = '';
      this.ruleForm.detailedAddress = '';
      if (!parentId) {
        return;
      }
      this.aloading = true;
      (0, _dealer.nextArea)({
        isDelete: '',
        parentId: parentId
      }).then(function (res) {
        setTimeout(function () {
          _this21.aloading = false;
        }, 1000);
        if (res.data.code === 200) {
          _this21.CityList = res.data.data;
        }
      });
    },
    // 城市
    CityFun: function CityFun(parentId) {
      var _this22 = this;
      this.ruleForm.countyName = '';
      this.ruleForm.countyCode = '';
      this.ruleForm.detailedAddress = '';
      if (!parentId) {
        return;
      }
      this.aloading = true;
      (0, _dealer.nextArea)({
        isDelete: '',
        parentId: parentId
      }).then(function (res) {
        setTimeout(function () {
          _this22.aloading = false;
        }, 1000);
        if (res.data.code === 200) {
          _this22.CountyList = res.data.data;
        }
      });
    },
    CountyFun: function CountyFun() {
      this.ruleForm.detailedAddress = '';
      this.$forceUpdate();
    },
    regionFun: function regionFun(pId) {
      var _this23 = this;
      this.ruleForm.teamName = '';
      this.ruleForm.marketName = '';
      if (!pId) {
        return;
      }
      this.aloading = true;
      (0, _salesArea.selectLargeRegionByParentId)({
        pId: pId
      }).then(function (res) {
        setTimeout(function () {
          _this23.aloading = false;
        }, 2000);
        _this23.teamList = res.data.data;
      });
    },
    teamFun: function teamFun(pId) {
      var _this24 = this;
      this.ruleForm.marketName = '';
      if (!pId) {
        return;
      }
      this.aloading = true;
      (0, _salesArea.selectLargeRegionByParentId)({
        pId: pId
      }).then(function (res) {
        setTimeout(function () {
          _this24.aloading = false;
        }, 2000);
        _this24.marketList = res.data.data;
      });
    },
    marketFun: function marketFun() {
      this.$forceUpdate();
    },
    // 产品选择弹窗关闭
    // openProduct() {
    //   this.proShow = true;
    //   // this.$refs.selProduct.proSelVisible = true
    // },
    // proClose() {
    //   this.proShow = false;
    // },
    // 产品选择
    // setPro(val) {
    //   this.proShow = false;
    //   if (val.length > 0) {
    //     var arrId = [];
    //     var arrName = [];
    //     val.forEach((v, index) => {
    //       arrId.push(v.pid);
    //       arrName.push(v.productName);
    //     });
    //     this.productName = arrName.join(",");
    //     // this.ruleForm.productIds = arrId.join(',')
    //   } else {
    //     // this.ruleForm.productIds = ''
    //     this.productName = "";
    //   }
    //   // var listchat = []
    //   this.productList = val;
    // },
    saleAreaRadioFun: function saleAreaRadioFun(val) {
      if (val < 2) {
        this.$refs.ruleForm.clearValidate('receiveAddress');
      }
    },
    receiveAddressFun: function receiveAddressFun(val) {
      console.log(val);
      if (val.length > 0) {
        this.ruleForm.receiveAddress = val;
        // this.areaSel = val
      } else {
        this.ruleForm.receiveAddress = [];
        // this.areaSel.splice(0, 3)
      }

      console.log(this.ruleForm.receiveAddress);
    },
    // 同步
    SyncSubmit: function SyncSubmit() {
      var _this25 = this;
      this.$refs['Syncform'].validate(function (valid) {
        if (valid) {
          _this25.sloading = true;
          (0, _dealer.getManualTest)(_this25.Syncform).then(function (res) {
            if (res.data.code == 200) {
              _this25.$message.success(res.data.msg);
            } else {
              _this25.$message.error(res.data.msg);
            }
            _this25.getList();
            _this25.sloading = false;
            _this25.SyncClose();
          }).catch(function (err) {
            console.log(err);
            _this25.sloading = false;
            _this25.SyncClose;
          });
        } else {
          return false;
        }
      });
    },
    SyncClose: function SyncClose() {
      this.SyncVisible = false;
      this.$refs['Syncform'].resetFields();
    },
    // 取消业务员批量修改
    restSalesManName: function restSalesManName() {
      this.dialogSalesman = false;
      this.salesManName = '';
      // this.searchForm.salesManId = ''
      this.getList();
    },
    // 批量解除提交按钮
    submitSalesManName: function submitSalesManName() {
      var _this26 = this;
      var arr = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.ids),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          arr.push({
            channelId: item,
            currentSalesManId: this.searchForm.salesManId
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      (0, _dealer.batcRemoveSalesMan)(arr).then(function (res) {
        if (res.data.code !== 200) {
          return _this26.$message.error('解除失败!');
        } else {
          _this26.$message.success('解除成功!');
        }
        _this26.dialogSalesman = false;
        _this26.searchForm.salesManId = '';
        _this26.salesManName = '';
        _this26.getList();
      });
    },
    selCancel: function selCancel() {
      this.querysalesman = {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      };
      this.size = 10;
      this.current = 1;
      this.channelOrgId = '';
      this.getcomBoxSalesManlist();
      this.dialogSalesmanList = false;
      this.searchForm.current = 1;
      this.getList();
    },
    submitSalesmanList: function submitSalesmanList(row) {
      console.log(row);
      this.salesManName = row.salesManName;
      this.searchForm.salesManId = row.pid;
      this.querysalesman = {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      };
      this.size = 10;
      // this.current = 1
      this.channelOrgId = '';
      this.searchForm.current = 1;
      this.getList();
      // this.getcomBoxSalesManlist()
      this.dialogSalesmanList = false;
    },
    querylist: function querylist() {
      this.current = 1;
      // this.getcomBoxSalesManlist();
    },
    resetQuerylist: function resetQuerylist() {
      this.querysalesman = {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      };
      this.salesmanSize = 10;
      this.current = 1;
      this.channelOrgId = '';
      this.getcomBoxSalesManlist();
    },
    getcomBoxSalesManlist: function getcomBoxSalesManlist() {
      var _this27 = this;
      (0, _dealer.comBoxSalesManlist)(this.current, this.salesmanSize, this.querysalesman).then(function (res) {
        if (res.data.code !== 200) {
          return _this27.$message.error(res.data.msg);
        } else {
          if (res.data.data !== null) {
            _this27.salesmanTotal = res.data.data.total * 1;
            _this27.salesmanList = res.data.data.records;
            _this27.salesmanSize = res.data.data.size * 1;
            // var hash = {}
            // this.salesmanList = this.salesmanList.reduce(function(item, next) {
            //   hash[next.pid] ? '' : hash[next.pid] = true && item.push(next)
            //   return item
            // }, [])
            // console.log(this.salesmanList, 'this.salesmanList')
          } else {
            _this27.salesmanTotal = 0;
            _this27.salesmanList = [];
          }
        }
      });
    },
    closePriseUsers: function closePriseUsers(data, errorSalesManName, isTips) {
      this.isShow = false;
      if (data && data.length) {
        this.isShowErrorPrise = true;
        this.errorPriseUsers = data;
        this.errorSalesManName = errorSalesManName;
      } else {
        isTips && this.$message.success('操作成功');
      }
    },
    getMultipleSelection: function getMultipleSelection() {},
    getData: function getData() {
      this.searchForm.salesManId = '';
      this.salesManName = '';
      this.getList();
    }
  }
};
exports.default = _default;