"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce1"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _auth = require("@/utils/auth");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/no-unused-components */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

var baseUrl = '/wh';
var _default = {
  components: {
    TinyMce: _tinyMce.default,
    admincut: _admincut.default
  },
  data: function data() {
    return {
      issave: false,
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'appId': sessionStorage.getItem('appId')
      },
      // toolbar: `emoticons link`,
      // plugins: `emoticons link`,

      ert: '',
      indexx: '',
      form: [{
        author: '',
        // 作者
        groupId: '',
        // 分组id
        url: '',
        // 图文消息原文链接
        thumbMediaId: '',
        // 封面图片id
        brief: '',
        // 摘要
        showPic: true,
        // 是否显示图片
        title: '',
        // 标题
        description: '' // 内容]
      }],

      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: '请输入正文',
          trigger: 'blur'
        }],
        groupId: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }]
      },
      groups: [],
      imageUrl: [{
        url: ''
      }
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' }
      ],

      disabled: false,
      textarea2: '',
      value1: [{
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }],
      // value1: '',
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      fstpUrl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single',
      twImgurl: 'http://dev20gw.panpass.cn/images/',
      // 左侧
      tw: [{
        img: '',
        title: ''
      }],
      ele: 0,
      i: 0,
      tpurl: null,
      imgIndex: '',
      info: '',
      // 图文查询
      twsear: {
        groupId: '',
        keyWord: '',
        pageNo: 1,
        pageSize: 10
      },
      twlist: '',
      alltw: false,
      qbtw: '',
      twshow: false,
      iSres: ''
    };
  },
  watch: {
    value1: function value1(newval, oldval) {
      // console.log(newval)
    },
    ele: function ele(newval, oldval) {
      // console.log(newval + '---' + oldval)
    }
  },
  created: function created() {
    var that = this;
    that.twImgurl = _wxHy.base;
    // // console.log(this.$route.query.upId)
    // var that = this
    (0, _wxgzh.getGroup)({
      appId: sessionStorage.getItem('appId')
    }).then(function (res) {
      // var that = this
      // // console.log(res)
      var res = res.data;
      if (res.code * 1 === 200) {
        that.groups = res.data.data.groupDOList;
        //  // console.log(that.$route.query.upId)
        if (that.$route.query.upId) {
          var a = {
            type: 2,
            id: that.$route.query.upId
          };
          (0, _wxgzh.selectNews)(a).then(function (res) {
            // console.log(res)
            var res = res.data;
            if (res.code === 200) {
              if (res.data.length === 0) {
                return that.$message.warning('数据错误');
              }
              console.log('3233333333333333333333333333333333333333333');
              that.form = res.data;
              that.tw = [];
              that.imageUrl = [];
              that.form.forEach(function (v, i) {
                var t = {
                  img: that.twImgurl + v.fileUrl,
                  title: v.title
                };
                var y = {
                  url: that.twImgurl + v.fileUrl
                };
                that.tw.push(t);
                that.imageUrl.push(y);
              });
              // console.log(that.imageUrl)
            } else {
              that.$message(res.msg);
            }
          });
        } else {
          if (that.$route.query.opd) {
            return;
          } else {
            that.getcook();
          }
        }
      } else {
        that.$message({
          type: 'info',
          message: res.msg
        });
        return;
      }
    });
    that.twsear.appId = sessionStorage.getItem('appId');
    (0, _wxgzh.tWsearch)(that.twsear).then(function (res) {
      var res = res.data;
      if (res.code * 1 === 200) {
        that.twlist = res.data.records;
        that.qbtw = res.data.total;
      } else {
        return that.$message.warning('获取图文失败');
      }
    });
  },
  // mounted() {
  //   var that = this
  // },
  methods: {
    changewe: function changewe(val) {},
    // 右侧标题赋值给左侧小窗口
    changee: function changee(val) {
      var that = this;
      that.tw[that.ele].title = val;
    },
    // 点击左侧加号
    showtw: function showtw() {
      this.twshow = !this.twshow;
    },
    // 选择图文
    twclick: function twclick(val) {
      var that = this;
      if (that.tw.length === 8) {
        return that.$message.warning('最多只能添加8条图文');
      }
      if (that.tw.length === 1 && that.tw[0].img === '') {
        that.form[0] = val;
        that.form[that.ele] = val;
        that.tw[0].img = that.twImgurl + that.form[0].fileUrl;
        that.tw[0].title = that.form[0].title;
        that.imageUrl[0].url = that.twImgurl + that.form[0].fileUrl;
      } else {
        that.ele++;
        var n = {
          img: null,
          title: null
        };
        var y = {
          url: ''
        };
        that.tw.push(n);
        that.imageUrl.push(y);
        that.form[that.ele] = val;
        that.tw[that.ele].img = that.twImgurl + that.form[that.ele].fileUrl;
        that.tw[that.ele].title = that.form[that.ele].title;
        that.imageUrl[that.ele].url = that.twImgurl + that.form[that.ele].fileUrl;
      }
      that.alltw = false;
    },
    // 图文分页
    twhandleCurrentChange: function twhandleCurrentChange(val) {
      var _this = this;
      this.twsear.pageNo = val;
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        var res = res.data;
        _this.twlist = res.data.records;
      });
    },
    handleClose: function handleClose(done) {
      done();
    },
    // 获取图文
    getTw: function getTw() {
      this.alltw = true;
      this.twshow = false;
    },
    // 获取修改数据
    getcook: function getcook() {
      var that = this;
      if (sessionStorage.getItem('amsc') !== 'undefined' && sessionStorage.getItem('amsc') !== null) {
        that.info = JSON.parse(sessionStorage.getItem('amsc'));
        var reg = /<[^>]+>/g;
        if (!(that.info instanceof Array)) {
          that.form[0] = that.info;
          if (reg.test(that.form[0].brief)) {
            that.form[0].brief = '';
          }
          that.tw[0].img = that.twImgurl + that.form[0].fileUrl;
          that.tw[0].title = that.form[0].title;
          that.imageUrl[0].url = that.twImgurl + that.form[0].fileUrl;
          that.form[0].brief = '';
        } else {
          that.info.forEach(function (val, i) {
            that.form[i] = val;
            if (reg.test(that.form[i].brief)) {
              that.form[i].brief = '';
            }
            that.tw[i].img = that.twImgurl + that.form[i].fileUrl;
            that.tw[i].title = that.form[i].title;
            that.imageUrl[i].url = that.twImgurl + that.form[i].fileUrl;
          });
        }
      } else {
        return;
      }
    },
    setform: function setform(index) {
      this.ele = index;
    },
    beforeunloadFn: function beforeunloadFn(e) {
      // console.log(e)
    },
    // 添加图文
    addEle: function addEle() {
      this.twshow = false;
      // if (this.info == '') {
      if (this.tw.length * 1 === 8) {
        this.$message.warning('最多只能创建8条图文消息');
        return;
      } else {
        var n = {
          img: '',
          title: ''
        };
        var m = {
          author: '',
          // 作者
          groupId: '',
          // 分组id
          url: '',
          // 图文消息原文链接
          thumbMediaId: '',
          // 封面图片id
          brief: '',
          // 摘要
          showPic: true,
          // 是否显示图片
          title: '',
          // 标题
          description: '' // 内容]
        };

        var t = {
          url: ''
        };
        var y = this.ele;
        y++;
        this.ele = y;
        this.tw.push(n);
        this.form.push(m);
        this.imageUrl.push(t);
        // // console.log(this.tw)
        // // console.log(this.tw.length)
        // // console.log(this.ele)
      }
      // } else {
      //   return this.$message.warning('修改图文状态，不能添加新图文')
      // }
      // // console.log(this.info)
    },
    // 提交表单
    onSubmit: function onSubmit() {
      var _this2 = this;
      for (var i = 0; i < this.tw.length; i++) {
        if (!this.form[i].title) {
          return this.$message.warning('请填写第' + (i + 1) + '组标题');
        }
        if (!this.form[i].description) {
          return this.$message.warning('请填写第' + (i + 1) + '组内容');
        }
        if (!this.imageUrl[i].url) {
          return this.$message.warning('请选择第' + (i + 1) + '组封面');
        }
      }
      var a;
      this.form.forEach(function (v, i) {
        if (v.hasOwnProperty('sendsId')) {
          v.sendsId = _this2.$route.query.upId;
        }
        if (v.hasOwnProperty('id')) {
          a = true;
          return a;
        }
      });

      // this.form.forEach((v, i) => {
      //   if (v.hasOwnProperty('sendid')) {
      //     v.sendsId = this.$route.query.upId
      //   }
      // })
      // console.log(a)
      if (!a) {
        // if (this.form[this.ele].brief === '') {
        //   this.form[this.ele].brief = this.form[this.ele].description.substring(0, 50)
        // }
        if (this.tw.length > 1) {
          var c = this.form.slice(0, this.tw.length);
          c.appId = sessionStorage.getItem('appId');
          this.issave = true;
          (0, _wxgzh.createNewsList)(c).then(function (res) {
            var res = res.data;
            if (res.code * 1 === 200) {
              _this2.$message.success('保存成功');
              _this2.issave = false;
              _this2.$router.push({
                name: 'wxAccountswxgzhwxFunctionfodderindex',
                query: {}
              });
            } else {
              _this2.$message.warning(res.msg);
            }
          });
        } else {
          this.form.appId = sessionStorage.getItem('appId');
          this.issave = true;
          (0, _wxgzh.createNews)(this.form).then(function (res) {
            var res = res.data;
            if (res.code * 1 === 200) {
              _this2.$message.success('保存成功');
              _this2.issave = false;
              _this2.$router.push({
                name: 'wxAccountswxgzhwxFunctionfodderindex',
                query: {}
              });
            } else {
              _this2.$message.warning(res.msg);
            }
          });
        }
      } else {
        // if (this.form[this.ele].brief === '') {
        //   this.form[this.ele].brief = this.form[
        //     this.ele
        //   ].description.substring(0, 50)
        // }
        this.form.appId = sessionStorage.getItem('appId');
        this.issave = true;
        (0, _wxgzh.updateNewsNew)(this.form).then(function (res) {
          var res = res.data;
          if (res.code * 1 === 200) {
            _this2.$message.success('修改成功');
            _this2.issave = false;
            _this2.$router.push({
              name: 'wxAccountswxgzhwxFunctionfodderindex',
              query: {}
            });
          } else {
            _this2.$message.warning(res.msg);
          }
        });
      }
    },
    // 取消操作
    cancel: function cancel() {
      this.issave = true;
      this.$message.warning('取消编辑');
      // this.$router.push({
      //   name: 'fodder',
      //   query: {}
      // })
      // 取消返回上一级
      this.$router.go(-1);
      this.issave = false;
    },
    // 提交并群发
    onSubmitmsg: function onSubmitmsg() {
      var _this3 = this;
      // this.$message.warning('暂未开发')
      for (var i = 0; i < this.tw.length; i++) {
        if (!this.form[i].title) {
          return this.$message.warning('请填写第' + (i + 1) + '组标题');
        }
        if (!this.form[i].description) {
          return this.$message.warning('请填写第' + (i + 1) + '组内容');
        }
        if (!this.imageUrl[i].url) {
          return this.$message.warning('请选择第' + (i + 1) + '组封面');
        }
        // if (this.form[i].brief === '') {
        //   this.form[i].brief = this.form[i].description.substring(0, 50)
        // }
      }

      var c = this.form.slice(0, this.tw.length);
      c.appId = sessionStorage.getItem('appId');
      this.issave = true;
      (0, _wxgzh.createNewsList)(c).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          _this3.$message.success('保存成功');
          _this3.issave = false;
          var arr = res.data;
          var ac = [];
          arr.forEach(function (val) {
            ac.push(val['id']);
          });
          sessionStorage.setItem('id', JSON.stringify(ac));
          if (_this3.$route.query.upId) {
            _this3.$router.push({
              name: 'wxAccountswxgzhwxManagecreatMessindex',
              query: {
                ids: 1,
                upId: _this3.$route.query.upId
              }
            });
          } else {
            _this3.$router.push({
              // name: 'creatMess',
              name: 'wxAccountswxgzhwxManagecreatMessindex',
              query: {
                ids: 1
              }
            });
          }
        } else {
          _this3.$message.warning(res.msg);
        }
      });
      // this.$router.push({
      //   path: '/wxAccounts/wxManage/creatMess',
      //   query: {}
      // })
    },
    // 上下移动
    up: function up(item, index) {
      var that = this;
      console.log(item);
      // if (this.info !== '') {
      //   return this.$message.warning('修改图文状态不能移动')
      // }
      if (index == 0) {
        return that.$message.warning('已经到顶了');
      }
      that.tw.splice(index - 1, 0, item);
      that.tw.splice(index + 1, 1);
      that.imageUrl.splice(index - 1, 0, that.imageUrl[index]);
      that.imageUrl.splice(index + 1, 1);
      that.form.splice(index - 1, 0, that.form[index]);
      that.form.splice(index + 1, 1);
    },
    dw: function dw(item, index) {
      var that = this;
      // if (that.info !== '') {
      //   return that.$message.warning('修改图文状态不能移动')
      // }
      var a = index + 1;
      if (a == that.tw.length) {
        return that.$message.warning('已经到底了');
      }
      that.tw.splice(index + 2, 0, item);
      that.tw.splice(index, 1);
      that.imageUrl.splice(index + 2, 0, that.imageUrl[index]);
      that.imageUrl.splice(index, 1);
      that.form.splice(index + 2, 0, that.form[index]);
      that.form.splice(index, 1);
    },
    dlitem: function dlitem(item, index) {
      var that = this;
      var c = that.ele;
      that.$confirm('此操作将删除该图文, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // if (that.info !== '') {
        //   return that.$message.warning('修改图文状态不能删除')
        // }
        if (that.tw.length > 1) {
          that.tw.splice(index, 1);
          that.form.splice(index, 1);
          that.imageUrl.splice(index, 1);
          c--;
          that.ele = c;
        } else {
          that.form = [{
            author: '',
            // 作者
            groupId: '',
            // 分组id
            url: '',
            // 图文消息原文链接
            thumbMediaId: '',
            // 封面图片id
            brief: '',
            // 摘要
            showPic: true,
            // 是否显示图片
            title: '',
            // 标题
            description: '' // 内容]
          }];

          that.tw = [{
            img: '',
            title: ''
          }];
          that.imageUrl = [{
            url: ''
          }];
        }
        that.$message({
          type: 'success',
          message: '删除成功!'
        });
        // // console.log(that.tw)
        // // console.log(that.form)
        // // console.log(that.ele)
      }).catch(function () {
        that.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //
    handlePreview: function handlePreview(index, event, file, fileList) {
      // // console.log(index)
    },
    sert: function sert(index) {
      // // console.log(index)
    },
    // 上传封面成功
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      // // console.log(response)
      if (response.code * 1 === 200) {
        this.form[this.ele].thumbMediaId = response.data.mediaId;
      } else {
        this.$message({
          message: '请重新选取封面',
          type: 'error',
          duration: 3 * 1000
        });
      }

      // 上传图片url给左侧
      // // console.log(this.imgIndex)
      this.imageUrl[this.ele].url = URL.createObjectURL(file.raw);
      // 给tw添加url地址
      this.tw[this.ele].img = this.imageUrl[this.ele].url;
    },
    imgUploaderr: function imgUploaderr() {
      this.$message.closeAll();
      this.$message({
        message: '上传失败，请重试！',
        type: 'error',
        duration: 3 * 1000
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isImg = /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name);
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) {
        this.$message.error('上传图片只能是 gif/jpg/jpeg/png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      // var index = this.i++
      // this.imageUrl[index].url = URL.createObjectURL(file)
      // this.tpurl = URL.createObjectURL(file);

      return isImg && isLt2M;
    }
  }
};
exports.default = _default;