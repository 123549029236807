var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "政策-详情",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c("el-form", [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticStyle: { padding: "8px 0" } }, [
                        _vm._v("政策名称：" + _vm._s(_vm.list.policyName)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticStyle: { padding: "8px 0" } }, [
                        _vm._v("政策编号：" + _vm._s(_vm.list.policyCode)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticStyle: { padding: "8px 0" } }, [
                        _vm._v("政策类型：" + _vm._s(_vm.list.policyTypeName)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticStyle: { padding: "8px 0" } }, [
                        _vm._v("开始日期：" + _vm._s(_vm.list.startTime)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticStyle: { padding: "8px 0" } }, [
                        _vm._v("结束日期：" + _vm._s(_vm.list.endTime)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }