"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _redEnvelopePrize = require("@/api/prizeSetting/redEnvelopePrize");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import {
//   productArchivesList,
//   productClassList,
//   productBrandAllListList,
// } from '@/api/basic/product'
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    },
    multipleList: {
      type: Array,
      default: []
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        isCode: this.isCode
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      },
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        goodsHxTarget: null,
        goodsHxType: null,
        goodsType: null,
        prizeName: null,
        prizeType: '',
        redSendType: null,
        status: 1
      },
      redEnvelopesList: [],
      // 红包列表
      redEnvelopesTotal: 0,
      // 红包列表总数
      visible: false
    };
  },
  mounted: function mounted() {
    // this.getClass()
    // this.getBrand()
    // this.getPack()
    // this.getList()
    // this.setsel()
    this.getPrizeList();
    // debugger
    console.log('mounted');
  },
  methods: {
    // 获取红包列表
    getPrizeList: function getPrizeList() {
      var _this = this;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      if (!params.prizeType) {
        params.prizeType = '100,200,400';
      }
      console.log(params);
      // return
      // params.prizeType = '100,200';
      (0, _redEnvelopePrize.getPrizeList)(params).then(function (res) {
        // const List = res.data.data.records.filter(item => {
        //   return item.status == 1
        // })
        // console.log(List, 'redList')
        // console.log(res.data)
        _this.selLoading = true;
        if (res.data.code !== 200) {
          _this.$message.error('获取失败！');
          return;
        } else {
          if (res.data.data !== null) {
            _this.redEnvelopesList = res.data.data.records;
            _this.redEnvelopesTotal = res.data.data.total;
          } else {
            _this.redEnvelopesTotal = 0;
            _this.redEnvelopesList = [];
          }
        }
      }).finally(function () {
        _this.selLoading = false;
      });
    },
    typeFormatter: function typeFormatter(row) {
      if (row.prizeType == 100) {
        return '红包 ';
      } else if (row.prizeType == 200) {
        return '实物奖品';
      } else if (row.prizeType == 900) {
        return '礼包';
      } else if (row.prizeType == 400) {
        return '积分';
      } else {
        return '第三方';
      }
    },
    // setsel() {
    //   this.selLoading = true
    //   this.$nextTick(() => {
    //     this.data.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row, true) // 回显
    //     })
    //   })
    //   this.getList()
    //   setTimeout(() => {
    //     if (this.tableData.length) {
    //       this.$refs.multipleTable.toggleRowSelection(this.tableData[0])
    //       this.$refs.multipleTable.toggleRowSelection(this.tableData[0])
    //     }
    //     this.selLoading = false
    //   }, 500)
    // },
    show: function show(data) {},
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getClass: function getClass() {
      var _this2 = this;
      productClassList({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this2.normalList = data;
          } else {
            _this2.normalList = [];
          }
        }
      });
    },
    getBrand: function getBrand() {
      var _this3 = this;
      productBrandAllListList().then(function (res) {
        if (res.data.code !== 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.brandList = res.data.data;
          } else {
            _this3.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this4 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code !== 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this4.packList = res.data.data;
          } else {
            _this4.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this5 = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      var le = that.searchForm.productClassId.length - 1 || '';
      submitData.productClassId = that.searchForm.productClassId[le] || '';
      productArchivesList(submitData).then(function (res) {
        _this5.listLoadig = false;
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.queryParams = {
        pageSize: 10,
        pageNo: 1,
        goodsHxTarget: null,
        goodsHxType: null,
        goodsType: null,
        prizeName: null,
        prizeType: '',
        redSendType: null,
        status: 1
      };
      this.filterText = '';
      this.getPrizeList();
      this.clear();
      // this.getList()
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      // this.resetForm()
      this.redEnvelopesList = [];
      this.visible = false;
      // this.clear()
      // this.$emit('change')
      this.$emit('close');
      // this.$parent.$parent.isShow = false;
    },
    // 回显
    select: function select() {
      var _this6 = this;
      this.multipleSelection = this.multipleList;
      this.$nextTick(function () {
        _this6.multipleList.forEach(function (item) {
          _this6.$refs.multipleTable.toggleRowSelection(item, true);
        });
      });
      this.getPrizeList();
      setTimeout(function () {
        _this6.$refs.multipleTable.toggleRowSelection(_this6.redEnvelopesList[0]);
        _this6.$refs.multipleTable.toggleRowSelection(_this6.redEnvelopesList[0]);
        // this.selLoading = false
      }, 500);
    },
    submit: function submit() {
      var flag;
      var flag1;
      var flag2;
      if (!this.multipleSelection.length) {
        return this.$message.error('请选择奖品');
      }
      if (this.multipleSelection.length == 1) {
        return this.$message.error('必须选择两种类型的奖品');
      }
      if (this.multipleSelection.length > 1) {
        flag = this.multipleSelection.every(function (item) {
          return item.prizeType == '100';
        });
        flag1 = this.multipleSelection.every(function (item) {
          return item.prizeType == '200';
        });
        flag2 = this.multipleSelection.every(function (item) {
          return item.prizeType == '400';
        });
        if (flag || flag1 || flag2) {
          return this.$message.error('每种类型的奖品只能添加一个');
        }
      }
      if (this.multipleSelection.length > 3) {
        return this.$message.error('每种类型的奖品只能添加一个');
      }
      // this.$parent.$parent.isShow = false;

      this.$emit('change', this.multipleSelection);
      // this.resetForm()
      // this.clear();
      this.visible = false;
    },
    tag1Close: function tag1Close(row, index) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getPrizeList();
    }
  }
};
exports.default = _default2;