"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stockscan = require("@/api/chanelmange/stockscan");
var _rebate = require("@/api/chanelmange/rebate");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _fljymx = _interopRequireDefault(require("./component/fljymx"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangerebatereportStaticzdJy';
var _default = {
  name: 'chanelmangerebatereportStaticzdJy',
  components: {
    Pagination: _Pagination.default,
    instockUnit: _purchaseDialog.default,
    fljymx: _fljymx.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      listQuery: {
        channelId: null,
        current: 1,
        endTime: null,
        moneyType: null,
        orgId: null,
        size: 20,
        startTime: null
      },
      dateValue: [],
      channelName: '',
      orgOptions: [],
      tableKey: 'fljy',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      moneyTypes: [{
        name: '现金',
        id: 101
      }, {
        name: '酒券',
        id: 102
      }],
      beggingTotal: '',
      incomeTotal: '',
      expenditureTotal: '',
      surplusTotal: '',
      unitShow: false,
      detailVisible: false,
      channelId: '',
      moneyType: '',
      showCol: false,
      expandTxt: '展开',
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _rebate.getRebateSurplus)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).then(function (res) {
        that.getTotal();
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      if (that.dateValue.length > 0) {
        that.listQuery.startTime = that.dateValue[0];
        that.listQuery.endTime = that.dateValue[1];
      }
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        channelId: null,
        current: 1,
        endTime: null,
        moneyType: null,
        orgId: null,
        size: 20,
        startTime: null
      };
      that.dateValue = [];
      that.channelName = '';
      that.getList();
    },
    getTotal: function getTotal() {
      var that = this;
      var param = {};
      param.channelId = that.listQuery.channelId;
      param.endTime = that.listQuery.endTime;
      param.moneyType = that.listQuery.moneyType;
      param.orgId = that.listQuery.orgId;
      param.startTime = that.listQuery.startTime;
      (0, _rebate.getRebateSurplusTotal)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            that.beggingTotal = res.data.data.beggingTotal;
            that.expenditureTotal = res.data.data.expenditureTotal;
            that.incomeTotal = res.data.data.incomeTotal;
            that.surplusTotal = res.data.data.surplusTotal;
          }
        }
      });
    },
    //获取单位
    clearJxs: function clearJxs() {
      var that = this;
      that.listQuery.channelId = '';
      that.channelName = '';
    },
    getUnit: function getUnit(val) {
      var _this = this;
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.listQuery.channelId = val.pId;
      that.channelName = val.channelName;
      that.unitShow = false;
    },
    //收支明细
    doScan: function doScan(row) {
      var that = this;
      that.channelId = row.channelId;
      that.moneyType = row.moneyType;
      that.detailVisible = true;
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;