var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "审核信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c("h3", [_vm._v("退货入库单 " + _vm._s(_vm.num))]),
      _vm._v(" "),
      _c("flow-head", { ref: "flowHeadMain", attrs: { num: _vm.num } }),
      _vm._v(" "),
      _c("h3", [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("退货入库单号:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.num)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("入库库房:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.inStoreName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("退货单位:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.returnOrg)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("退货原因:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.returnDescr)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("单据状态:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(_vm._f("TypeDataCom")(_vm.temp.status, "status"))
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("创建人:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.userName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8, md: 12 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("创建时间:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.temp.dateCreate))),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-list", {
        ref: "productListMain",
        attrs: { num: _vm.num, settype: _vm.settype },
        on: { handleClose: _vm.handleClose },
      }),
      _vm._v(" "),
      _c("flow-log", {
        ref: "flowLogMain",
        attrs: { num: _vm.num, showflow: _vm.showflow },
        on: { handleClose: _vm.handleClose },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }