"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _integralMall = require("@/api/integral/integralMall");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _prodsSelect = _interopRequireDefault(require("@/components/prods-select"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    UploadImg: _UploadImg.default,
    ProdsSelect: _prodsSelect.default
  },
  data: function data() {
    var _this = this;
    var checkPro = function checkPro(rule, value, callback) {
      if (_this.dataForm.type * 1 === 0 && _this.dataForm.relation == null) {
        return callback(new Error('请选择商品'));
      } else {
        return callback();
      }
    };
    return {
      dataForm: {
        status: 1,
        des: '',
        imgUrl: '',
        seq: 0,
        imgId: 0,
        type: -1,
        relation: null
      },
      // 关联数据
      card: {
        id: 0,
        pic: '',
        name: '',
        realData: {
          prod: [],
          shop: [],
          activity: []
        }
      },
      page: {
        total: 0,
        // 总页数
        currentPage: 1,
        // 当前页数
        pageSize: 10 // 每页显示多少条
      },

      prodsSelectVisible: false,
      visible: false,
      rules: {
        type: [{
          validator: checkPro,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init: function init(id) {
      var _this2 = this;
      this.visible = true;
      this.dataForm.imgId = id || 0;
      if (this.dataForm.imgId) {
        // 获取产品数据
        (0, _integralMall.indexImgInfo)(this.dataForm.imgId).then(function (res) {
          _this2.dataForm = res.data;
          _this2.$refs.imgs.fileList = [{
            url: res.data.imgUrl
          }];
          _this2.$refs.imgs.hideUpload = true;
          if (res.data.relation) {
            _this2.card.pic = res.data.pic;
            _this2.card.name = res.data.prodName;
            _this2.card.id = res.data.relation;
          }
        });
      } else {
        this.$nextTick(function () {
          _this2.$refs['dataForm'].resetFields();
          _this2.dataForm.imgUrl = '';
          _this2.dataForm.relation = null;
        });
      }
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this3 = this;
      // if (this.dataForm.type == 0 && this.dataForm.relation == null) {
      //   this.$message({
      //     message: '请选择商品',
      //     type: 'error',
      //     duration: 1500
      //   })
      //   return false
      // }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var param = _this3.dataForm;
          (0, _integralMall.indexImg)(param).then(function (data) {
            _this3.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this3.handleClose();
                _this3.$emit('refreshDataList', _this3.page);
              }
            });
          });
        }
      });
    },
    // 删除关联数据
    deleteRelation: function deleteRelation() {
      this.dataForm.relation = null;
    },
    // 打开选择商品
    addProd: function addProd() {
      var _this4 = this;
      this.prodsSelectVisible = true;
      this.$nextTick(function () {
        _this4.$refs.prodsSelect.init(_this4.card.realData.prod);
      });
    },
    // 添加指定商品
    selectCouponProds: function selectCouponProds(prods) {
      this.card.realData.prods = prods;
      if (prods.length) {
        var selectProd = prods[0];
        this.dataForm.relation = selectProd.prodId;
        this.card.pic = selectProd.pic;
        this.card.name = selectProd.prodName;
        this.card.id = selectProd.prodId;
      } else {
        this.card = {
          id: 0,
          pic: '',
          name: '',
          realData: {
            prod: [],
            shop: [],
            activity: []
          }
        };
        this.dataForm.relation = null;
      }
    },
    handleClose: function handleClose() {
      this.$refs['dataForm'].resetFields();
      this.$refs.imgs.fileList = [];
      this.$refs.imgs.hideUpload = false;
      this.dataForm.type = -1;
      this.card = {
        id: 0,
        pic: '',
        name: '',
        realData: {
          prod: [],
          shop: [],
          activity: []
        }
      };
      this.dataForm.relation = null;
      this.visible = false;
    },
    addShop: function addShop() {
      alert('选择店铺');
    },
    addActivity: function addActivity() {
      alert('选择活动');
    }
  }
};
exports.default = _default;