"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ManualActive',
  components: {
    productDialog: _productSelectDialog.default
  },
  data: function data() {
    return {
      btnLoading: false,
      scanData: {
        markCode: '',
        productId: '',
        productTime: '',
        productName: ''
      },
      rules: {
        markCode: [{
          required: true,
          message: '数码不能为空',
          trigger: 'change'
        }],
        productName: [{
          required: true,
          message: '请选择激活产品',
          trigger: 'change'
        }],
        productTime: [{
          required: false,
          message: '请选择生产时间',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    validSe: function validSe(val) {
      val = val.replace(/[^0-9]/g, '');
      return val;
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this.btnLoading = true;
          var data = JSON.parse(JSON.stringify(_this.scanData));
          delete data.productName;
          (0, _store.manualActivate)(data).then(function (res) {
            _this.btnLoading = false;
            if (res.data.code === 200) {
              _this.$message.success(res.data.msg);
              _this.scanData = {
                markCode: '',
                productId: '',
                productTime: '',
                productName: ''
              };
            } else {
              _this.$message.error(res.data.msg);
            }
          });
        } else {
          _this.$message.error('请完善信息！');
        }
      });
    },
    openProduct: function openProduct() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.scanData.productId = val.pId;
      this.scanData.productName = val.productName;
    }
  }
};
exports.default = _default;