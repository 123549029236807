var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "main-content" },
        [
          _c(
            "el-col",
            { staticClass: "main" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  { staticClass: "content-top" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.routeTo(0)
                          },
                        },
                      },
                      [_vm._v("添 加 规 则")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content-table",
                    staticStyle: { height: "calc(100% - 150px)" },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.list,
                          border: "",
                          height: "100%",
                          width: "100%",
                          "header-cell-style": {
                            "background-color": "#fafafa",
                            color: "rgba(0, 0, 0,0.85)",
                            "font-weight": "500",
                            "text-align": "center",
                          },
                          "cell-style": { "text-align": "center" },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "name", label: "规则名称" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "amount", label: "扫码得积分值" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "statuse", label: "状态" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          scope.row.statuse === "1"
                                            ? "开启"
                                            : " 关闭"
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "address",
                            label: "操作",
                            align: "center",
                            width: "340",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeTo(2, scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeTo(1, scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.statuse === "2"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.switchFun(
                                                "1",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("开启")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "5px" },
                                          attrs: {
                                            type: "warning",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.switchFun(
                                                "2",
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("关闭")]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFun(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content-pages1" },
                  [
                    _c("Pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total>0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        "auto-scroll": false,
                        page: _vm.pageForm.pageNo,
                        limit: _vm.pageForm.pageSize,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.pageForm, "pageNo", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.pageForm, "pageSize", $event)
                        },
                        pagination: _vm.pagingFun,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }