"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _memberManage = require("@/api/terminal/memberManage");
var _city = require("@/api/terminal/city");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    birthday: function birthday(index) {
      if (!index) return '';
      return index.split(' ')[0];
    },
    // <!-- 1-扫码 2-商品兑换 3-活动获得 4-企业操作 5-完善个人信息 -->
    typeFilter: function typeFilter(status) {
      if (!status) return '';
      var statusMap = {
        1: '扫码',
        2: '商品兑换',
        3: '活动获得',
        4: '企业操作',
        5: '完善个人信息',
        10: '积分过期 '
      };
      return statusMap[status];
    },
    memberMaritalStatus: function memberMaritalStatus(status) {
      if (!status) return '';
      var statusMap = {
        1: '已婚',
        0: '未婚'
      };
      return statusMap[status];
    },
    sex: function sex(status) {
      if (!status) return '';
      var statusMap = {
        1: '男',
        2: '女'
      };
      return statusMap[status];
    }
  },
  props: {
    dialogVisible: Boolean,
    type: null,
    memberId: null
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      activeName: 'first',
      integral: '',
      typeName: '收入/支出',
      detailForm: {
        nickname: '',
        memberId: '',
        realname: '',
        provinceId: '',
        province: '',
        cityId: '',
        city: '',
        countyId: '',
        county: '',
        memberAddress: '',
        memberAge: '',
        sex: '',
        phone: '',
        memberBirthday: '',
        memberBloodType: '',
        memberCompany: '',
        memberHobby: '',
        mail: '',
        memberMaritalStatus: ''
      },
      detailRules: {
        phone: [{
          pattern: /^0{0,1}(13[0-9]|145|147|149|15[5-9]|15[0-3]|166|17[1-3]|17[5-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          message: '手机号格式不对',
          trigger: 'change'
        }]
      },
      total: 0,
      formInline: {
        integralDesc: null,
        integralType: null,
        memberInfoId: null,
        pageNo: 1,
        pageSize: 10
      },
      provinceList: [],
      cityList: [],
      countyList: []
    };
  },
  mounted: function mounted() {
    this.getProvinceList();
    this.getDetail(this.memberId);
    // this.integral = this.$route.params.integral
    this.formInline.memberInfoId = this.memberId;
  },
  methods: {
    goBack: function goBack() {
      this.$emit('close');
    },
    handleClick: function handleClick(tab, event) {
      if (this.activeName == 'second') {
        this.getIntegralList();
      }
    },
    filterChange: function filterChange(filters) {
      if (!filters.type || filters.type.length != 1) {
        this.formInline.integralDesc = null;
        this.typeName = '收入/支出';
      } else {
        this.formInline.integralDesc = filters.type[0];
        this.typeName = filters.type[0] == 1 ? '收入' : '支出';
      }
      if (!filters.state || filters.state.length != 1) {
        this.formInline.integralType = null;
      } else {
        this.formInline.integralDesc = filters.state[0];
      }
      this.getIntegralList();
    },
    // 获取积分流水
    getIntegralList: function getIntegralList() {
      var _this = this;
      this.listLoading = true;
      (0, _memberManage.getIntegralByMemberId)(this.formInline).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this.list = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    // 获取详情
    getDetail: function getDetail(val) {
      var _this2 = this;
      this.listLoading = true;
      (0, _memberManage.detailMember)({
        memberId: val
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          if (res.data.memberBirthday) {
            res.data.memberAge = _this2.getAge(res.data.memberBirthday.split(' ')[0]);
          }
          _this2.detailForm = res.data;
        }
      }).then(function () {
        if (_this2.detailForm.provinceId) {
          _this2.getCityList(_this2.detailForm.provinceId);
        }
        if (_this2.detailForm.provinceId && _this2.detailForm.cityId) {
          _this2.getCountyList(_this2.detailForm.provinceId, _this2.detailForm.cityId);
        }
        _this2.listLoading = false;
      });
    },
    subMember: function subMember() {
      var _this3 = this;
      this.$refs['detailForm'].validate(function (valid) {
        if (valid) {
          (0, _memberManage.detailMemberUpdate)(_this3.detailForm).then(function (res) {
            res = res.data;
            if (res.status == 200) {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'success',
                duration: 3 * 1000
              });
              _this3.goBack();
            } else {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    getProvinceList: function getProvinceList() {
      var _this4 = this;
      (0, _city.getProvinceList)().then(function (res) {
        if (res.status === 200) {
          _this4.provinceList = res.data;
        } else {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    getCityList: function getCityList(val) {
      var _this5 = this;
      (0, _city.getCityList)({
        areaCode: '',
        areaName: '',
        areaType: 2,
        isDelete: 1,
        parentId: val
      }).then(function (res) {
        res = res.data;
        if (res.code == 200) {
          _this5.cityList = res.data;
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    getCountyList: function getCountyList(val) {
      var _this6 = this;
      (0, _city.getCityList)({
        areaCode: '',
        areaName: '',
        areaType: 3,
        isDelete: 1,
        parentId: val
      }).then(function (res) {
        res = res.data;
        if (res.code == 200) {
          _this6.countyList = res.data;
        } else {
          _this6.$message.closeAll();
          _this6.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    selectProvince: function selectProvince(val) {
      this.getCityList(val);
      this.detailForm.cityId = '';
      this.detailForm.countyId = '';
      this.detailForm.memberAddress = '';
    },
    selectCity: function selectCity(val) {
      this.getCountyList(val);
      this.detailForm.countyId = '';
      this.detailForm.memberAddress = '';
    },
    getAge: function getAge(strAge) {
      var birArr = strAge.split('-');
      var birYear = birArr[0];
      var birMonth = birArr[1];
      var birDay = birArr[2];
      d = new Date();
      var nowYear = d.getFullYear();
      var nowMonth = d.getMonth() + 1; // 记得加1
      var nowDay = d.getDate();
      var returnAge;
      if (birArr == null) {
        return false;
      }
      var d = new Date(birYear, birMonth - 1, birDay);
      if (d.getFullYear() == birYear && d.getMonth() + 1 == birMonth && d.getDate() == birDay) {
        if (nowYear == birYear) {
          returnAge = 0; //
        } else {
          var ageDiff = nowYear - birYear; //
          if (ageDiff > 0) {
            if (nowMonth == birMonth) {
              var dayDiff = nowDay - birDay; //
              if (dayDiff < 0) {
                returnAge = ageDiff - 1;
              } else {
                returnAge = ageDiff;
              }
            } else {
              var monthDiff = nowMonth - birMonth; //
              if (monthDiff < 0) {
                returnAge = ageDiff - 1;
              } else {
                returnAge = ageDiff;
              }
            }
          } else {
            return '出生日期晚于今天，数据有误'; // 返回-1 表示出生日期输入错误 晚于今天
          }
        }

        return returnAge;
      } else {
        return '输入的日期格式错误！';
      }
    }
  }
};
exports.default = _default;