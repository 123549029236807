"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addYwData = addYwData;
exports.chageState = chageState;
exports.editYwData = editYwData;
exports.getDetail = getDetail;
exports.getYwList = getYwList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取业务类型列表
function getYwList(data) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/list',
    method: 'post',
    data: data
  });
}

// 添加
function addYwData(data) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/add',
    method: 'post',
    data: data
  });
}

// 编辑业务类型
function editYwData(data) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/update',
    method: 'post',
    data: data
  });
}

// 启用，停用，删除
function chageState(param) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/enable',
    method: 'post',
    params: param
  });
}

// 获取详情
function getDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/get',
    method: 'post',
    params: param
  });
}