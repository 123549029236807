"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _channel = require("@/api/basic/channel");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _insert = _interopRequireDefault(require("./components/insert.vue"));
var _uploading = _interopRequireDefault(require("./components/uploading.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { selectChannelAndOrg } from '@/api/basic/channel'
var _default = {
  name: 'BasicDataPurchasingsupplyIndex',
  components: {
    Pagination: _Pagination.default,
    Insert: _insert.default,
    productSelectDialog: _productSelectDialog.default,
    uploading: _uploading.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '公司',
        1: '经销商',
        2: '终端',
        3: '团购'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      // 搜素
      handleShow: false,
      searchForm: {
        current: 1,
        size: 20,
        purchaseType: '',
        purchaseCodeAndName: '',
        supplierType: '',
        supplierCodeAndName: '',
        productName: '',
        //   busiManagerId: '',
        productId: ''
      },
      // 表单
      tableData: [],
      listLoading: false,
      tableKey: '1101',
      tabHeight: '100%',
      classList: [],
      total: 0,
      dialogVisible: false,
      title: '',
      downLoading: false,
      uploadVisible: false,
      pid: '',
      deleteArr: []
    };
  },
  watch: {
    $route: function $route(newVal, oldVal) {
      console.log(newVal);
      if (newVal.path === '/basic-data/channelmange/purchasingsupply/index') {
        if (this.$route.query.cName) {
          this.searchForm.purchaseCodeAndName = this.$route.query.cName;
        } else {
          this.searchForm.purchaseCodeAndName = '';
        }
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.tabHeight = (0, _utils.tableHeight)();
    });
  },
  activated: function activated() {
    var _this = this;
    // this.getTableList()
    this.searchForm.purchaseCodeAndName = this.$route.query.cName || '';
    this.getList();
    this.$nextTick(function () {
      _this.$refs.table.doLayout();
    });
  },
  methods: {
    handleShowFun: function handleShowFun() {
      this.handleShow = !this.handleShow;
      this.$nextTick(function () {
        this.tabHeight = (0, _utils.tableHeight)();
      });
    },
    uploadingCloseFun: function uploadingCloseFun(type) {
      if (type) {
        this.getList();
      }
      this.uploadVisible = false;
    },
    // 获取数据
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(that.searchForm)));
      delete submitData.productName;
      (0, _channel.channelCollectionSupplyList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        purchaseType: '',
        purchaseCodeAndName: '',
        supplierType: '',
        supplierCodeAndName: '',
        // busiManagerId: '',
        productName: '',
        productId: ''
      };
      if (this.$route.query.cName) {
        this.searchForm.purchaseCodeAndName = this.$route.query.cName;
      }
      this.getList();
    },
    // 导出列表
    dowmLoad: function dowmLoad() {
      var _this2 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)(this.searchForm);
      (0, _channel.channelCollectionSupplyexportExcel)(submitData).then(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        _this2.downLoading = false;
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '采供关系.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this2.downLoading = false;
      });
    },
    // 添加
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '采供关系添加';
      this.dialogVisible = true;
    },
    // 采供关系关闭
    closeInsertFun: function closeInsertFun(type) {
      this.dialogVisible = false;
      if (type === 1) {
        this.ruleForm = {
          name: '',
          productCode: '',
          productName: '',
          busiMangeLoginAccout: '',
          purchaseId: '',
          supplierIds: []
        };
        this.getList();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.deleteArr = val;
    },
    // 关闭批量导入
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.uploadData = [];
      this.uploadVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 打开上传弹窗
    uploadShow: function uploadShow() {
      this.authId('allinport', this.$route.meta.authList);
      this.uploadVisible = true;
    },
    // chkan
    openLookFun: function openLookFun(row) {
      console.log(this.$router);
      this.$router.push({
        path: '/basic-data/channelmange/purchasingsupply/details',
        query: {
          id: row.pId
        }
      });
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      console.log(val);
      this.searchForm.productId = val.pId;
      this.searchForm.productName = val.productName;
    },
    // 暂停
    stopFun: function stopFun(id) {
      var _this3 = this;
      this.$confirm('确认停止?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(22222222);
        // this.authId('deletebtn', this.$route.meta.authList)
        (0, _channel.SupplDisable)({
          pId: id
        }).then(function (res) {
          // this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this3.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this3.getList();
          } else {
            _this3.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    // 删除
    deleteFun: function deleteFun(id) {
      var _this4 = this;
      var that = this;
      that.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('deletebtn', _this4.$route.meta.authList);
        (0, _channel.channelCollectionSupplyenableProductBrand)({
          pId: id,
          isDelete: 2
        }).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          if (res.data.code == 200) {
            _this4.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            _this4.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    // 批量删除
    delAll: function delAll() {
      var _this5 = this;
      if (this.deleteArr.length == 0) {
        this.$message.error('请选择要删除的项！');
        return;
      }
      var pIds = [];
      this.deleteArr.forEach(function (item) {
        pIds.push(item.pId);
      });
      // var pids = pIds.join(',')
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _channel.deleteChannelCollectionSupplyBatch)({
          pids: pIds
        }).then(function (res) {
          if (res.data.code == 200) {
            _this5.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this5.multipleSelection = [];
            _this5.getList();
          } else {
            _this5.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {});
    },
    // 启用
    startFun: function startFun(id) {
      var _this6 = this;
      this.$confirm('确认启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.authId('deletebtn', _this6.$route.meta.authList);
        (0, _channel.SupplEnable)({
          pId: id
        }).then(function (res) {
          _this6.authId('searchbtn', _this6.$route.meta.authList);
          if (res.data.code == 200) {
            _this6.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this6.getList();
          } else {
            _this6.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this6.authId('searchbtn', _this6.$route.meta.authList);
      });
    },
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    }
  }
};
exports.default = _default;