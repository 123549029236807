var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "企业用户-分配角色",
        model: _vm.asrole,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名称", prop: "userName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入", readonly: "" },
                model: {
                  value: _vm.names,
                  callback: function ($$v) {
                    _vm.names = $$v
                  },
                  expression: "names",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色：" } },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "50",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roleName",
                      label: "角色名称",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: "角色描述",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "clientNames",
                      label: "所属系统",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userCount",
                      label: "用户量",
                      align: _vm.tableConfig.align,
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isEnable",
                      label: "状态",
                      align: _vm.tableConfig.align,
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.isEnable == 1
                              ? _c("span", [_vm._v("启用")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.isEnable == 2
                              ? _c("span", [_vm._v("禁用")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorName",
                      label: "创建人",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("formatTime")(row.createTime))
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updaterName",
                      label: "更新人",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "更新时间",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("formatTime")(row.updateTime))
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "100",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.scanRoleAuth(row)
                                  },
                                },
                              },
                              [_vm._v("查看权限")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getUserRole,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.dstate },
              on: { click: _vm.saveForm },
            },
            [_vm._v("确定")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.resetForm } }, [_vm._v("取消")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("scanAuth", {
        attrs: {
          scanAuth: _vm.scanAuthShow,
          name: _vm.scanRoleName,
          scanAuthData: _vm.scanAuthData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }