var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "异常数码",
        "append-to-body": "",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            size: "mini",
            data: _vm.tableData,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: _vm.tableConfig.align,
              width: "120",
              label: "序号",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "数码",
              prop: "ddddd",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "层级",
              prop: "ddddd",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "异常原因",
              prop: "ddddd",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          layout: "total,sizes,prev, pager, next, jumper",
          total: _vm.total,
          page: _vm.searchForm.pageNo,
          limit: _vm.searchForm.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.searchForm, "pageNo", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.searchForm, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }