var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section", attrs: { id: "seach-box" } },
            [
              _c(
                "el-form",
                {
                  ref: "searchform",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "head-btn-group",
                          attrs: { type: "flex", gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticStyle: { width: "460px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "450px" },
                                  attrs: { label: "数码", prop: "markCode" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入数码",
                                      clearable: "",
                                      maxlength: "40",
                                    },
                                    model: {
                                      value: _vm.listQuery.markCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "markCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "listQuery.markCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.getList },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasDetail,
              expression: "hasDetail",
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              {
                staticStyle: { "margin-bottom": "50px" },
                attrs: { id: "table-box" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "codeFrom",
                    staticStyle: { margin: "15px" },
                    attrs: {
                      model: _vm.codeFrom,
                      "label-width": "100px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c("h3", [_vm._v("数码信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "当前数码:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.currentCode
                                        ? _vm.codeFrom.currentCode
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "数码层级/类型:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.levelName
                                        ? _vm.codeFrom.levelName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "上传人:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.createName
                                        ? _vm.codeFrom.createName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: " 上传时间:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.createTime
                                        ? _vm.codeFrom.createTime
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("数码信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "模板名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.templateName
                                        ? _vm.codeFrom.templateName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "比例层级:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.templateLevel
                                        ? _vm.codeFrom.templateLevel
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.codeFrom.levelTemplateVOS,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "postInfo-container-item",
                                        attrs: {
                                          label: "层级名称:",
                                          prop: "wlm",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.levelName
                                                ? item.levelName
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "postInfo-container-item",
                                        attrs: {
                                          label: "规格串数:",
                                          prop: "wlm",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.levelCount
                                                ? item.levelCount
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "postInfo-container-item",
                                        attrs: { label: "位数:", prop: "wlm" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.markLength
                                                ? item.markLength
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c("h3", [_vm._v("数码详情")]),
                _vm._v(" "),
                _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: {
                          type: _vm.btnConfig.downLoadBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.downLoadBtn.icon,
                          loading: _vm.downLoading,
                        },
                        on: { click: _vm.download },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    key: _vm.tableKey,
                    attrs: {
                      data: _vm.list,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "default-expand-all": "",
                      "highlight-current-row": "",
                      "max-height": "600",
                    },
                  },
                  [
                    _vm._l(_vm.tableData, function (col) {
                      return [
                        _c("el-table-column", {
                          key: col.markLevel,
                          attrs: {
                            "show-overflow-tooltip": true,
                            prop: col.markLevel,
                            label: col.levelName,
                            "min-width": "150",
                          },
                        }),
                      ]
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "原辅料备注",
                        prop: "remark",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "批次号",
                        prop: "batchNo",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建日期",
                        align: _vm.tableConfig.align,
                        "min-width": "165",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.markTime
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(scope.row.markTime)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "电池型号",
                        prop: "markModel",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "蓄电池唯一编码",
                        prop: "storageBatteryCode",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "浙品码链接",
                        prop: "pinMarkCode",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "激光码",
                        prop: "laserCode",
                        width: "180",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "箱唛条码",
                        prop: "boxBarCode",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "电池条码",
                        prop: "batteryBoardCode",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "粤品码",
                        prop: "cantoneseCode",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "备注",
                        prop: "endRemark",
                        "min-width": "140",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段1",
                        prop: "reservedFieldOne",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段2",
                        prop: "reservedFieldTwo",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段3",
                        prop: "reservedFieldThree",
                        "min-width": "140",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段4",
                        prop: "reservedFieldFour",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段5",
                        prop: "reservedFieldFive",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段6",
                        prop: "reservedFieldSix",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段7",
                        prop: "reservedFieldSeven",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段8",
                        prop: "reservedFieldEight",
                        "min-width": "140",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段9",
                        prop: "reservedFieldNine",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "预留字段10",
                        prop: "reservedFieldTen",
                        "min-width": "160",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        prop: "endCreateName",
                        "min-width": "140",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "上传时间",
                        align: _vm.tableConfig.align,
                        "min-width": "180",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.createTime
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          scope.row.createTime
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }