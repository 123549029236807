var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "label-suffix": ":",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _vm.handleShow
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "activityNameOrActivityCode",
                                    label: "活动编码/名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "活动编码/名称" },
                                    model: {
                                      value:
                                        _vm.searchForm
                                          .activityNameOrActivityCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "activityNameOrActivityCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.activityNameOrActivityCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "activityStatus",
                                label: "活动状态",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.activityStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "activityStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.activityStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.activityStatus,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "rangeTime", label: "起止时间" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.searchForm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "rangeTime", $$v)
                                  },
                                  expression: "searchForm.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "fundId", label: "资金配置" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.fundId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "fundId", $$v)
                                    },
                                    expression: "searchForm.fundId",
                                  },
                                },
                                _vm._l(_vm.sourceList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.fundConfigurationName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.handleShow
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productName",
                                    label: "活动产品",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchForm.productName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.productName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.choseProduct },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.handleShow
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "mchBillNo",
                                    label: "订单编号",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "订单编号" },
                                    model: {
                                      value: _vm.searchForm.mchBillNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "mchBillNo",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.mchBillNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.getList },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.restForm("searchForm")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleShow = !_vm.handleShow
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.handleShow ? "收起" : "展开") +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class: _vm.handleShow
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.creatAct },
                  },
                  [_vm._v("创建活动")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.list,
                height: _vm.tabHeight,
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "活动编码",
                  prop: "activityCode",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "活动名称",
                  prop: "activityName",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "扫码类型",
                  prop: "scanTypeName",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "方案编码",
                  prop: "schemeCode",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "方案名称",
                  prop: "schemeName",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "资金配置",
                  prop: "financeName",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建方式",
                  prop: "type",
                  width: "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.createWay == "0"
                          ? _c("span", [_vm._v("普通活动")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.createWay == "1"
                          ? _c("span", [_vm._v("追加活动")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "开始时间-结束时间",
                  prop: "startTime",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [_vm._v(_vm._s(row.startTime))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(row.endTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "活动状态",
                  prop: "activityStatus",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityStatus == "0"
                          ? _c("el-tag", [_vm._v("未开始")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "1"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "2"
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "3"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已过期"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "createUserName",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  "min-width": "160",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "解锁规则计数器(人次)",
                      prop: "currentLockCount",
                      "show-overflow-tooltip": "",
                      "min-width": "85",
                      align: _vm.tableConfig.align,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  "min-width": "300",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.activityStatus == "3" ||
                                    scope.row.activityStatus == "2",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n              编辑\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { disabled: scope.row.createWay == "1" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(scope.row)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { disabled: scope.row.createWay == "1" },
                                on: {
                                  click: function ($event) {
                                    return _vm.zhuijia(scope.row)
                                  },
                                },
                              },
                              [_vm._v("追加")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: {
                                  disabled: scope.row.activityStatus == "3",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setDisable(scope.row)
                                  },
                                },
                              },
                              [
                                scope.row.activityStatus == "0" ||
                                scope.row.activityStatus == "2" ||
                                scope.row.activityStatus == "3"
                                  ? _c("span", [_vm._v("停用")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.activityStatus == "1"
                                  ? _c("span", [_vm._v("启用")])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.scan(scope.row)
                                  },
                                },
                              },
                              [_vm._v("概览")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                class: {
                                  modifyBtn: scope.row.prConfigCount > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openActPr(scope.row)
                                  },
                                },
                              },
                              [_vm._v("概率组")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                class: {
                                  modifyBtn: scope.row.unlockRuleCount > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openRelatUnlockPrize(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    Number(scope.row.unlockRuleCount) > 0
                                      ? "编辑解锁奖品"
                                      : "关联解锁奖品"
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.searchForm.pageNo,
              limit: _vm.searchForm.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("actConfig", {
            ref: "content",
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              type: _vm.type,
              "act-id": _vm.actId,
              "show-only": _vm.readonly,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.actPrVisible
        ? _c("actPr", {
            attrs: {
              "dialog-visible": _vm.actPrVisible,
              "act-id": _vm.activityId,
              "show-only": _vm.showOnly,
              "data-activity-status": _vm.currentDataActivityStatus,
              "select-item": _vm.tableMultipleSelectionId,
              "batch-pr-data": _vm.batchPrData,
            },
            on: { close: _vm.closeActPr },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.scanVisible
        ? _c("actPreview", {
            attrs: { id: _vm.scanId, "dialog-visible": _vm.scanVisible },
            on: { close: _vm.closeScan },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.relatUnlockPrizeVisible
        ? _c("relatUnlockPrize", {
            attrs: {
              "act-id": _vm.activityId,
              "dialog-visible": _vm.relatUnlockPrizeVisible,
              "select-item": _vm.tableMultipleSelectionId,
              "data-activity-status": _vm.currentDataActivityStatus,
            },
            on: { close: _vm.closeRelatUnlockPrize },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.batchUpdActPriVisible
        ? _c("batchUpdateActPri", {
            attrs: {
              "act-id": _vm.activityId,
              "show-only": _vm.showOnly,
              "batch-update-prize-data": _vm.currentBatchUpdatePrizeData,
              "activity-flow-id": _vm.activityFlowId,
              "select-item": _vm.tableMultipleSelectionId,
              "dialog-visible": _vm.batchUpdActPriVisible,
            },
            on: { close: _vm.closeBatchUpdateActPri },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.batchUpdActSetVisible
        ? _c("batchUpdateActSet", {
            attrs: {
              "act-id": _vm.activityId,
              "select-item": _vm.tableMultipleSelectionId,
              "dialog-visible": _vm.batchUpdActSetVisible,
            },
            on: { close: _vm.closeBatchUpdateActSet },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("productSelectDialog", {
        ref: "proSelect",
        on: { change: _vm.selectPro },
      }),
      _vm._v(" "),
      _c("distributorSelect", {
        ref: "dealerSelect",
        attrs: { "pro-sel-visible": _vm.isShowDistributor },
        on: { distributorName: _vm.selectDealer },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }