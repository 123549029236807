"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostorderCcfinancial',
  filters: {
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '\xa0';
      } else {
        var statusMap = {
          1: '创建订单',
          2: '预审通过',
          3: '预审不通过',
          4: '确款审核通过',
          5: '确款审核不通过',
          6: '修改订单'
        };
        return statusMap[value];
      }
    },
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '陈列酒订单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      // 销售单位
      saleOrgName: '',
      // 采购单位名称
      purchaseOrgName: '',
      // 收货单位名称
      receiveOrgName: '',
      // 发货单位名称
      sendOrgName: '',
      orderStatus: [],
      proType: '',
      listIndex: '',
      totalZp: 0.0,
      placeOrderDateEdit: false,
      remarkEdit: false,
      typeEdit: false,
      cardPadding: {
        padding: '0 0 20px'
      },
      btncardPadding: {
        padding: '0'
      },
      loading: false,
      proSelectList: [],
      productList: [],
      zpList: [],
      companyShow: false,
      tgShow: false,
      auditLoading: false,
      productShow: false,
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        orderType: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        sendOrgId: '',
        tradeDate: '',
        tradeType: 2
      },
      auditForm: {
        orderNo: '',
        auditResult: '',
        auditReason: ''
      }
    };
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    },
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this3.addData = res.data.data;
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              parentDetailId: item.parentDetailId,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum,
              productType: item.productType
            };
            if (item.productType == 3) {
              obj.winePrice = item.productAmount;
              obj.wineTotalPrice = item.productPrice || 0;
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this3.productList = (0, _utils.transTreeData)(proArr, 'pid', 'parentDetailId', 'giftProductAOS');
          _this3.zpList = zpArr;
          _this3.saleOrgName = data.saleOrgName;
          _this3.purchaseOrgName = data.purchaseOrgName;
          _this3.receiveOrgName = data.receiveOrgName;
          _this3.sendOrgName = data.sendOrgName;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      }).then(function () {
        if (_this3.addData.tradeStatus > 1) {
          _this3.auditLoading = true;
          (0, _order.getAuditLog)({
            orderNo: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this3.auditList = res.data.data;
            } else {
              _this3.$message.error('审核日志获取失败');
            }
            _this3.auditLoading = false;
          }).catch(function () {});
        }
      });
    },
    toogleExpand: function toogleExpand(idx) {
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        if (idx != i) {
          $table.toggleRowExpansion(this.productList[i], false);
        }
      }
      // this.setZPnum(this.productList[idx])
      $table.toggleRowExpansion(this.productList[idx]);
    },
    totalWine: function totalWine(row) {
      var num = row.totalBottleNum * 1;
      num = num || 0;
      var prize = row.winePrice || 0;
      var total = num * (prize * 1);
      row.wineTotalPrice = total.toFixed(2);
      return row.wineTotalPrice;
    },
    handleClose: function handleClose() {
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    },
    audit: function audit(val) {
      var _this4 = this;
      var that = this;
      if (val == 2 && (this.auditForm.auditReason == null || this.auditForm.auditReason == '')) {
        this.$message.error('审核意见不能为空');
        return;
      }
      this.auditForm.auditResult = val;
      this.auditForm.orderNo = this.addData.orderNo;
      (0, _order.sureAuditOrder)(this.auditForm).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '操作成功'
          });
          _this4.handleClose();
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    }
  }
};
exports.default = _default;