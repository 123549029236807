var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "echart-items" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 15 } }, [
            _c("div", { staticClass: "echart-container" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "timg",
                          attrs: { src: require("../../../../images/t5.png") },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("扫码量趋势"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "eicon-small",
                          attrs: {
                            src: require("../../../../images/eicon-2.png"),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              size: "small",
                            },
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "range-txt" }, [_vm._v("至")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              size: "small",
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cell-ft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("近7日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("近30日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("近12月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-body" }, [
                _c("div", { staticClass: "cell" }, [
                  _c(
                    "div",
                    { staticClass: "cell-hd", staticStyle: { width: "220px" } },
                    [
                      _c("div", { staticClass: "legends" }, [
                        _c("div", { staticClass: "legend mb20" }, [
                          _c("div", { staticClass: "sub-title" }, [
                            _vm._v("累计扫码量"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "count" }, [
                            _vm._v("123456"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell mb10" }, [
                            _c("div", { staticClass: "square-1" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("营销码：1234"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _c("div", { staticClass: "square-2" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("防伪码：1234"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "legend" }, [
                          _c("div", { staticClass: "sub-title" }, [
                            _vm._v("今日扫码量"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell count " }, [
                            _c("div", { staticClass: "cell-primary" }, [
                              _vm._v("323"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "cell-ft label" }, [
                              _c("img", {
                                staticClass: "arrowimg",
                                attrs: {
                                  src: require("../../../../images/arrowUp1.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "default-color" }, [
                                _vm._v("8%"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v("同昨日")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell mb10" }, [
                            _c("div", { staticClass: "square-1" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("营销码：1234"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _c("div", { staticClass: "square-2" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("防伪码：1234"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cell-primary",
                      staticStyle: { "margin-left": "20px" },
                    },
                    [_c("div", { ref: "scan", staticClass: "chart" })]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "echart-container" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "timg",
                          attrs: { src: require("../../../../images/t6.png") },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("红包发放金额排行榜"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "eicon-small",
                          attrs: {
                            src: require("../../../../images/eicon-2.png"),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cell-ft",
                      staticStyle: { "padding-top": "6px" },
                    },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("近7日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("近30日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("近12月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "static",
                staticClass: "chart",
                attrs: { id: "echarts_id" },
              }),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }