"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _storeHouse = require("@/api/basic/storeHouse");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _insert = _interopRequireDefault(require("./components/insert"));
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _printDialog = _interopRequireDefault(require("@/components/printDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { factoryInfoAllList } from '@/api/basic/factory'

// import { getLevelById, supportBoxLevelList } from '@/api/basic/pack'

// import { formatDate, getKeysetVal } from '@/api/mark/basic'

// var miniUnit = ''
var qrcode = '';
var TypeDataComs = {
  status: [],
  source: []
};
var _default = {
  name: 'WarehouseServicewarehousingpurchasepurchase',
  components: {
    Pagination: _Pagination.default,
    // QRCode,
    productDialog: _productSelectDialog.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    printDialog: _printDialog.default,
    allotUnitSelect: _allotUnitSelect.default
  },
  filters: {
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    tagStatus: function tagStatus(val) {
      var status = {
        1: '',
        2: '',
        3: '',
        4: 'success',
        5: 'success',
        6: 'success',
        7: 'success',
        8: 'warning',
        9: 'danger',
        10: 'warning',
        11: 'warning',
        12: 'danger',
        13: 'success'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      dateValue: [],
      productName: '',
      expandTxt: '展开',
      showCol: false,
      inOrgName: '',
      outOrgName: '',
      unitShow: false,
      unitType: '',
      inOutType: '6',
      shouFaType: '1',
      productCode: '',
      TypeDataComs1: {
        statusList: [],
        StoreList: [],
        OrgList: [],
        sourceList: []
      },
      billType: 104,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      params: {
        billPurchaseQuery: {
          inOrgId: '',
          inStoreId: '',
          outOrgId: '',
          relateNo: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: 104,
        endTime: '',
        productId: '',
        startTime: ''
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      billNo: '',
      typeNum: 2,
      printShow: false,
      pShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.getList();
    this.getstoreTypeInfoList('DJZT');
    this.getstoreTypeInfoList('DJLY');
    this.getFactoryInfoList();
    this.getStoreList();
    this.getStatusList();
    this.getSourceList();
    TypeDataComs = this.TypeDataComs;
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    // 重置
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.params.productId = '';
        this.productName = '';
        this.params.billNo = '';
        this.params.billPurchaseQuery.inOrgId = '';
        this.params.billPurchaseQuery.inStoreId = '';
        this.params.billPurchaseQuery.outOrgId = '';
        this.params.billPurchaseQuery.relateNo = '';
        this.params.billSource = '';
        this.params.billStatus = '';
        this.params.billType = 104;
        this.params.endTime = '';
        this.params.startTime = '';
        this.inOrgName = '';
        this.outOrgName = '';
        this.dateValue = [];
        this.$refs.searchForm.resetFields();
      }
      this.searchForm.current = 1;
      if (this.dateValue.length > 0) {
        this.params.endTime = this.dateValue[1];
        this.params.startTime = this.dateValue[0];
      } else {
        this.params.endTime = '';
        this.params.startTime = '';
      }
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryForPage)(this.searchForm.current, this.searchForm.size, this.params).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.listLoading = false;
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.$refs.insertMain.show(1, this.billType);
    },
    doUpdate: function doUpdate(row) {
      this.$refs.insertMain.upDateShow(row);
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _index.InfoDel)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: res.data.msg,
            type: 'success'
          });
          _this2.getList();
        });
      }).catch(function () {});
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this3 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _index.excelOut)(this.params).then(function (res) {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '采购入库.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        _this3.downLoading = false;
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 打印
    printTb: function printTb(row) {
      this.authId('printbtn', this.$route.meta.authList);
      var that = this;
      that.billNo = row.billNo;
      that.typeNum = 2;
      that.pShow = true;
      that.printShow = true;
    },
    // 关闭打印
    printClose: function printClose() {
      var that = this;
      that.billNum = '';
      that.pShow = false;
      that.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this4 = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == 'DJZT') {
            _this4.TypeDataComs.status = res.data.data;
          }
          if (param.code == 'DJLY') {
            _this4.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this4.TypeDataComs;
        }
      }).catch(function () {});
    },
    // 获取产品
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.params.productId = '';
      this.productName = '';
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.params.productId = val.pId;
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this5 = this;
      // 获取工厂下啦列表数据
      this.TypeDataComs1.OrgList = [];
      (0, _select.ccOrgInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          console.log(res);
          _this5.TypeDataComs1.OrgList = res.data.data;
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this6 = this;
      // 获取库房下拉列表数据
      this.TypeDataComs1.StoreList = [];
      (0, _storeHouse.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this6.TypeDataComs1.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    getStatusList: function getStatusList() {
      var _this7 = this;
      // 获取单据状态
      this.TypeDataComs1.statusList = [];
      var param = {};
      param.code = 'DJZT';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this7.TypeDataComs1.statusList = res.data.data;
        }
      }).catch(function () {});
    },
    getSourceList: function getSourceList() {
      var _this8 = this;
      // 获取单据来源
      this.TypeDataComs1.sourceList = [];
      var param = {};
      param.code = 'DJLY';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this8.TypeDataComs1.sourceList = res.data.data;
        }
      }).catch(function () {});
    },
    setFactory: function setFactory(v) {},
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取收货单位
    clearUnitIn: function clearUnitIn() {
      var that = this;
      that.inOrgName = '';
      that.params.billPurchaseQuery.inOrgId = '';
      that.params.billPurchaseQuery.inStoreId = '';
      that.TypeDataComs1.StoreList = [];
    },
    clearUnitOut: function clearUnitOut() {
      var that = this;
      that.outOrgName = '';
      that.params.billPurchaseQuery.outOrgId = '';
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        that.shouFaType = '1';
      }
      if (arg == 'out') {
        that.shouFaType = '2';
      }
      that.unitShow = true;
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        that.inOrgName = val.companyName;
        that.params.billPurchaseQuery.inOrgId = val.companyId;
        that.params.billPurchaseQuery.inStoreId = '';
        that.getHouses(val.companyId);
      }
      if (type == 'out') {
        that.outOrgName = val.companyName;
        that.params.billPurchaseQuery.outOrgId = val.companyId;
      }
    },
    // 获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataComs1.StoreList = [];
          that.TypeDataComs1.StoreList = res.data.data;
        }
      });
    },
    // 获取焦点关联库房
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.params.billPurchaseQuery.inOrgId == '') {
        that.TypeDataComs1.StoreList = [];
        that.$message.error('请选择收货单位');
        return;
      }
    }
  }
};
exports.default = _default;