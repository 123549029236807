var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("div", { staticClass: "page-section" }, [
            _c("h3", { staticClass: "h-title" }, [_vm._v("包装比例")]),
            _vm._v(" "),
            _c(
              "section",
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.searchForm,
                      "status-icon": "",
                      "label-suffix": ":",
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "packCode" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "包装编码",
                                      prop: "packCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.packCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "packCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.packCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "packScaleDesc" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "包装比例描述",
                                      "label-width": "100px",
                                      prop: "packScaleDesc",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.packScaleDesc,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "packScaleDesc",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.packScaleDesc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "isEnable" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { prop: "isEnable", label: "状态" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.searchForm.isEnable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "isEnable",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.isEnable",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: "", label: "全部" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "启用" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 0, label: "禁用" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    style: { width: _vm.formConfig.btnArea },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchForm.current = 1
                                                _vm.getList()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                            },
                                            on: { click: _vm.resetForm },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c("el-divider", { staticClass: "btnDivider" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btnArea" },
                  [
                    _c("el-col", { staticClass: "head-btn-group" }, [
                      _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    type: _vm.btnConfig.addBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.addBtn.icon,
                                    plain: _vm.btnConfig.addBtn.plain,
                                  },
                                  on: { click: _vm.add },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.tabHeight,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        align: _vm.tableConfig.align,
                        width: "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "packCode",
                        label: "包装编码",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "packScaleDesc",
                        label: "包装比例描述",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "packScaleExpression",
                        label: "比例表达式",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "是否启用",
                        width: "200",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    disabled: !_vm.hasAuth(
                                      "startbtn",
                                      _vm.$route.meta.authList
                                    ),
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ff4949",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.del($event, row)
                                    },
                                  },
                                  model: {
                                    value: row.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(row, "isEnable", $$v)
                                    },
                                    expression: "row.isEnable",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3815788271
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.searchForm.current,
                    limit: _vm.searchForm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.ruleForm, rules: _vm.rules },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "settingtable",
                    "header-cell-style": { padding: "12px 0" },
                    data: _vm.ruleForm.levelItemParams,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-page-no-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "包装层级",
                      align: _vm.tableConfig.align,
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "tableItem",
                                attrs: {
                                  prop:
                                    "levelItemParams." +
                                    scope.$index +
                                    ".topClass",
                                  rules: _vm.rules.topClass,
                                  "label-width": "0",
                                  size: "medium",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择包装层级" },
                                    model: {
                                      value: scope.row.topClass,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "topClass", $$v)
                                      },
                                      expression: "scope.row.topClass",
                                    },
                                  },
                                  _vm._l(scope.row.levelList, function (item) {
                                    return _c("el-option", {
                                      key: item.pid,
                                      attrs: {
                                        label: item.name,
                                        value: item.figure,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "比例数量",
                      align: "center",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "tableItem",
                                attrs: {
                                  prop:
                                    "levelItemParams." +
                                    scope.$index +
                                    ".packNum",
                                  rules: _vm.rules.packNum,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    id: "baoinput",
                                    placeholder: "请输入",
                                    type: "number",
                                    maxlength: "20",
                                    min: 1,
                                    oninput:
                                      "value=value.replace(/^0|[^0-9]/g, '')",
                                  },
                                  model: {
                                    value: scope.row.packNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "packNum", $$v)
                                    },
                                    expression: "scope.row.packNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "计量单位",
                      align: "center",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "tableItem",
                                attrs: {
                                  "label-width": "0",
                                  size: "medium",
                                  prop:
                                    "levelItemParams." +
                                    scope.$index +
                                    ".unitName",
                                  rules: _vm.rules.unitName,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择单位" },
                                    on: { change: _vm.unit },
                                    model: {
                                      value: scope.row.unitName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "unitName", $$v)
                                      },
                                      expression: "scope.row.unitName",
                                    },
                                  },
                                  _vm._l(_vm.bzbl, function (item) {
                                    return _c("el-option", {
                                      key: item.pid,
                                      attrs: {
                                        label: item.name,
                                        value: item.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "说明",
                      align: "center",
                      "min-width": "150",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.packNum) +
                                  " " +
                                  _vm._s(scope.row.unitName)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      width: "240",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-circle-plus\n",
                              staticStyle: {
                                "font-size": "30px",
                                color: "rgb(64, 158, 255)",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addlevel(
                                    scope.row.topClass,
                                    scope.$index
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            scope.$index > 0
                              ? _c("i", {
                                  staticClass: "el-icon-remove\n",
                                  staticStyle: {
                                    "font-size": "30px",
                                    color: "rgb(64, 158, 255)",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.minuslevel(scope.$index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包装比例详情：" } },
                _vm._l(_vm.ruleForm.levelItemParams, function (item, index) {
                  return _c("span", { key: index }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(item.packNum) +
                        _vm._s(item.unitName) +
                        "\n          "
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }