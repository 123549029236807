var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tableDialog",
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "组织机构选择",
            visible: _vm.visible,
            width: "900px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section",
                      staticStyle: {
                        width: "calc(100% - 0px)",
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            height: 400,
                            data: _vm.list,
                            fit: "",
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            "row-key": "pid",
                            "highlight-current-row": "",
                          },
                          on: { "row-click": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "单选", width: "80" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "radio",
                                        attrs: { label: scope.$index },
                                        model: {
                                          value: _vm.radio,
                                          callback: function ($$v) {
                                            _vm.radio = $$v
                                          },
                                          expression: "radio",
                                        },
                                      },
                                      [_vm._v(" ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "orgCode",
                              label: "经销商编号",
                              width: "120",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "orgName",
                              label: "经销商名称",
                              "min-width": "120",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "detailedAddress",
                              label: "地址",
                              "min-width": "120",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }