"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _param2 = require("@/api/chanelmange/param");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'order',
  components: {
    productDialog: _productDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  filters: {
    purchaseComOwnerType: function purchaseComOwnerType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '渠道客户',
          2: '团购客户',
          3: '销售公司'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分',
        str = '';
      n = String((n * 1).toFixed(2));
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      totalZp: 0.0,
      activeName: '',
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      // 发货单位所属组织id
      sendComId: '',
      auditLoading: false,
      productShow: false,
      productList: [],
      zpList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      auditRule: {
        auditResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      loading: false,
      auditForm: {
        orderNo: '',
        auditResult: '',
        auditReason: ''
      }
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCXSDDZT', 'orderStatus');
    this.getDetailData(this.id);
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    toogleExpand: function toogleExpand(idx) {
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        if (idx != i) {
          $table.toggleRowExpansion(this.productList[i], false);
        }
      }
      $table.toggleRowExpansion(this.productList[idx]);
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    totalWine: function totalWine(row) {
      var num = row.bottleNum * 1;
      num = num ? num : 0;
      var prize = row.winePrice || 0;
      var total = num * (prize * 1);
      row.wineTotalPrice = total.toFixed(2);
      return row.wineTotalPrice;
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.bottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNumOver || 0);
      this.setZPnum(row);
    },
    //获取详情数据
    getDetailData: function getDetailData(id) {
      var _this2 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this2.addData = res.data.data;
          data.orderDetailDTOS.forEach(function (item) {
            var aNum = item.packRaidoExpress.split('*');
            var le = aNum.length - 1;
            var obj = {
              pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              prodUnit: item.prodUnit,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              bottleNumOver: item.bottleNum % aNum[le],
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isGift: item.isGift,
              isUseCoupon: item.isUseCoupon,
              extractNum: _this2.subType == 'take' ? '' : item.extractNum,
              hasTakeNum: item.extractNum,
              rebatePolicyId: item.rebatePolicyId,
              rebatePolicyName: item.rebatePolicyName,
              orderGiftDetailDTOS: item.orderGiftDetailDTOS,
              winePrice: item.winePrice,
              wineTotalPrice: item.wineTotalPrice
            };
            if (item.isUseCoupon) {
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this2.productList = proArr;
          _this2.zpList = zpArr;
          console.log(proArr, 'proArr');
          console.log(zpArr, 'zpArr');
          if (_this2.subType == 'take') {
            _this2.addData.sendOutStoreId = '';
          }
          _this2.purchaseComName = data.purchaseComName;
          _this2.receiveComName = data.receiveComName;
          _this2.sendOutComName = data.sendOutComName;
          // this.getStoreList(data.sendOutComId)
          if (_this2.addData.receiveComType == 3) {
            _this2.getFL(1);
            _this2.getWine();
          }
          _this2.sendComId = data.sendOutComId;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      }).then(function () {
        _this2.$nextTick(function () {
          if (_this2.subType == 'edit') {
            _this2.$nextTick(function () {
              _this2.$refs.addForm.clearValidate();
            });
          }
        });
        if (_this2.addData.orderStatus > 1) {
          _this2.auditLoading = true;
          (0, _ordergl.getAuditLog)({
            pId: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this2.auditList = res.data.data;
            } else {
              that.$message.error('审核日志获取失败');
            }
            _this2.auditLoading = false;
          }).catch(function () {
            _this2.auditLoading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    change: function change() {
      console.log(111);
      this.$emit('change');
    },
    openPro: function openPro(val) {
      this.proType = val;
      if (!this.addData.sendOutComId || !this.addData.purchaseComId || !this.addData.receiveComId) {
        this.$message.error('请先选择采购单位、收货单位、供货单位');
        return;
      }
      this.proSelectList = val == 1 ? this.productList : this.zpList;
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var _this3 = this;
      var list, arr;
      if (this.proType == 1) {
        arr = JSON.parse(JSON.stringify(this.productList));
        list = 'productList';
      } else {
        arr = JSON.parse(JSON.stringify(this.zpList));
        list = 'zpList';
      }
      if (val.length) {
        if (this[list].length == 0) {
          var obj = {
            prodUnit: 1,
            chestNum: 0,
            bottleNum: 0,
            bottleNumOver: 0,
            rebatePolicyId: '',
            isGift: this.proType == 1 ? 1 : 0,
            isUseCoupon: this.proType == 1 ? 0 : 1,
            orderGiftDetailDTOS: []
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
          });
        } else {
          val.forEach(function (item) {
            item.prodUnit = 1;
            item.isGift = _this3.proType == 1 ? 1 : 0;
            item.isUseCoupon = _this3.proType == 1 ? 0 : 1;
            item.rebatePolicyId = '';
            item.chestNum = 0;
            item.bottleNum = 0;
            item.bottleNumOver = 0;
            item.orderGiftDetailDTOS = [];
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.prodUnit = el.prodUnit;
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.bottleNumOver = el.bottleNumOver;
                item.isGift = el.isGift;
                item.isUseCoupon = el.isUseCoupon;
                item.orderGiftDetailDTOS = el.orderGiftDetailDTOS;
                item.rebatePolicyId = el.rebatePolicyId;
                item.rebatePolicyName = el.rebatePolicyName;
                item.winePrice = el.winePrice;
                item.wineTotalPrice = el.wineTotalPrice;
              }
            });
          });
        }
        console.log(val, '987');
        this[list] = JSON.parse(JSON.stringify(val));
        if (this.addData.receiveComType == 3 && this.proType == 1) {
          this.getFL(1);
        }
      } else {
        this[list] = [];
      }
    },
    proClose: function proClose() {
      this.proSelectList = '';
      this.proType = '';
      this.productShow = false;
    },
    //删除赠品明细列表
    DoScZp: function DoScZp(row) {
      var _this4 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.zpList.splice(_this4.zpList.indexOf(row), 1);
      });
    },
    getFL: function getFL(val) {
      var _this5 = this;
      if (this.addData.purchaseComId && this.addData.sendOutComId && this.addData.receiveComId && this.productList.length) {
        console.log(111);
        var _loop = function _loop(i) {
          param = (0, _defineProperty2.default)({
            buyNum: 0,
            harvestId: _this5.addData.receiveComId,
            harvestType: _this5.addData.receiveComType,
            productId: '',
            purchaseId: _this5.addData.purchaseComId,
            purchaseType: _this5.addData.purchaseComType,
            supplierId: _this5.addData.sendOutComId,
            supplierType: _this5.addData.sendOutComType
          }, "productId", _this5.productList[i].pId);
          (0, _ordergl.matchChannelBuyGift)(param).then(function (res) {
            if (res.data.code == 200) {
              _this5.productList[i].flList = res.data.data || [];
              if (!val) _this5.productList[i].rebatePolicyId = '';
            } else {
              _this5.$message.error(res.data.msg);
              _this5.productList[i].flList = [];
            }
            if (val) {
              _this5.flChange('', _this5.productList[i]);
            }
            _this5.resetList();
          });
        };
        for (var i = 0; i < this.productList.length; i++) {
          var param;
          _loop(i);
        }
      }
    },
    getWine: function getWine() {
      var _this6 = this;
      if (this.addData.receiveComId && this.addData.sendOutComId) {
        (0, _ordergl.buyerMoneyWine)({
          harvestId: this.addData.receiveComId,
          harvestType: 1,
          supplierId: this.addData.sendOutComId,
          supplierType: this.addData.sendOutComType == 3 ? 1 : 0
        }).then(function (res) {
          if (res.data.code == 200) {
            _this6.totalZp = res.data.data;
          } else {
            _this6.$message.error(res.data.msg);
          }
        });
      }
    },
    flChange: function flChange($el, row) {
      var _this7 = this;
      var arr = [];
      row.flList.forEach(function (item) {
        if (item.rebateId == row.rebatePolicyId) {
          if (!item.detail || item.detail.length == 0) {
            _this7.$message.error('无匹配的返利方案');
            return;
          }
          row.rebatePolicyName = item.desc;
          item.detail.forEach(function (val) {
            var obj = {
              buyNum: val.buyNum,
              giftNum: val.giftNum,
              bottleNum: 0,
              chestNum: 0,
              isComProd: val.product.isCode,
              prodPrice: val.product.productPrice || 0,
              productCode: val.product.productCode,
              productId: val.product.pId,
              productName: val.product.productName,
              rpzRebateSchemeConfigId: val.rpzRebateSchemeConfigId,
              rpzRebateSchemeId: val.rpzRebateSchemeId,
              rpzRebateSchemeOrgId: val.rpzRebateSchemeOrgId,
              rpzRebateSchemeProId: val.rpzRebateSchemeProId,
              totalPrice: '',
              orderDetailId: row.pid
            };
            if (row.orderGiftDetailDTOS.length) {
              row.orderGiftDetailDTOS.forEach(function (el) {
                if (el.productId == val.product.pId) {
                  obj.pid = el.pid;
                  obj.orderDetailId = el.orderDetailId;
                }
              });
            }
            arr.push(obj);
          });
        }
      });
      row.orderGiftDetailDTOS = arr;
      this.setZPnum(row);
      this.resetList();
    },
    flClear: function flClear($el, row) {
      row.orderGiftDetailDTOS = [];
      this.resetList();
    },
    setZPnum: function setZPnum(row) {
      if (row.orderGiftDetailDTOS.length) {
        row.orderGiftDetailDTOS.forEach(function (item) {
          if (row.bottleNum) {
            item.bottleNum = Math.floor(row.bottleNum / item.buyNum) * item.giftNum;
            item.totalPrice = item.bottleNum * item.prodPrice;
          } else {
            item.bottleNum = 0;
            item.totalPrice = 0;
          }
        });
      }
      this.resetZpList();
      row.orderGiftDetailDTOS = JSON.parse(JSON.stringify(row.orderGiftDetailDTOS));
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    resetZpList: function resetZpList() {
      this.zpList = JSON.parse(JSON.stringify(this.zpList));
    },
    audit: function audit() {
      var _this8 = this;
      var that = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          if (that.zpList.length > 0 && that.totalInUse > _this8.totalZp) {
            that.$message.error('使用酒劵金额不能超过可用酒劵金额');
            return;
          }
          var plist = JSON.parse(JSON.stringify([].concat((0, _toConsumableArray2.default)(that.productList), (0, _toConsumableArray2.default)(that.zpList))));
          var oldList = JSON.parse(JSON.stringify(that.addData.orderDetailDTOS));
          var newList = [];
          if (plist.length > 0) {
            for (var i = 0; i < plist.length; i++) {
              if (!plist[i].bottleNum || plist[i].bottleNum == 0) {
                if (i > that.productList.length - 1) {
                  that.$message.error('在酒劵兑换列表第' + (i - that.productList.length + 1) + '行请输入采购数量');
                } else {
                  that.$message.error('在产品列表第' + (i + 1) + '行请输入采购数量');
                }
                return;
              }
              var obj = {
                pid: plist[i].pid,
                bottleNum: plist[i].bottleNum,
                chestNum: plist[i].chestNum,
                orderNo: that.addData.orderNo,
                packRaidoExpress: plist[i].packScaleExpression,
                prodPrice: plist[i].productPrice,
                prodUnit: plist[i].prodUnit,
                productCode: plist[i].productCode,
                productId: plist[i].pId,
                productName: plist[i].productName,
                productSpec: plist[i].productModel,
                totalPrice: plist[i].totalPrice,
                isGift: plist[i].isGift,
                isUseCoupon: plist[i].isUseCoupon,
                orderGiftDetailDTOS: plist[i].orderGiftDetailDTOS,
                rebatePolicyId: plist[i].rebatePolicyId,
                winePrice: plist[i].winePrice,
                wineTotalPrice: plist[i].wineTotalPrice * 1
              };
              oldList.forEach(function (item) {
                if (item.pid == obj.pid) {
                  obj = Object.assign(item, obj);
                }
              });
              var orderGiftDetailDTOS = [];
              var le = plist[i].orderGiftDetailDTOS.length;
              if (le) {
                for (var j = 0; j < le; j++) {
                  delete plist[i].orderGiftDetailDTOS[j].buyNum;
                  delete plist[i].orderGiftDetailDTOS[j].giftNum;
                  delete plist[i].orderGiftDetailDTOS[j].productCode;
                  delete plist[i].orderGiftDetailDTOS[j].productName;
                  if (plist[i].orderGiftDetailDTOS[j].bottleNum > 0) {
                    orderGiftDetailDTOS.push(plist[i].orderGiftDetailDTOS[j]);
                  }
                }
              }
              obj.orderGiftDetailDTOS = orderGiftDetailDTOS;
              newList.push(obj);
            }
          }
          _this8.loading = true;
          _this8.dsate = true;
          _this8.auditForm.orderNo = _this8.addData.orderNo;
          _this8.auditForm.orderDetailDTOS = newList;
          (0, _ordergl.orderAudit)(_this8.auditForm).then(function (res) {
            if (res.data.code == 200) {
              _this8.$message({
                type: 'success',
                message: '审核完成'
              });
              _this8.change();
            } else {
              _this8.$message.error(res.data.msg);
            }
            _this8.loading = false;
            _this8.dsate = false;
          }).catch(function () {
            _this8.loading = false;
            _this8.dsate = false;
          });
        } else {
          _this8.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;