"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _index = require("@/utils/index.js");
var _analyze = require("@/api/wxhyyy/analyze.js");
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _wxmake = require("@/api/wxhyyy/wxmake");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LineChart: _lineChart.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        return y + '-' + m + '-' + d;
      }
      return value;
    },
    typeFilter: function typeFilter(status) {
      var statusMap = {
        '0': '未开始',
        '1': '进行中',
        '2': '已暂停',
        '3': '已结束'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      restaurants: [],
      statem: '',
      levdata: [],
      planEndTime: '',
      planStartTime: '',
      planStatus: '',
      fansDate: [],
      // 粉丝数时间
      fansContrastDate: [],
      // 对比时间
      isFansContrastDate: true,
      // 是否对比
      fansTableDate: [],
      // 表单时间
      total: 0,
      // 总数
      current: 1,
      // 当前页
      pageSizes: [10, 20, 30, 40],
      // 每页显示的数量
      pageSize: 10,
      // 默认显示的数量
      records: [],
      // 粉丝表单列表
      ContrastData: {},
      totalObj: {},
      // 粉丝数
      totalList: [],
      // 粉丝数表单
      planId: '',
      btnClass: 'fansCount',
      lineList: [{
        name: '目标发送积分数',
        id: 'fansCount'
      }, {
        name: '成功发送积分数',
        id: 'newFansCount'
      }, {
        name: '目标发送红包人数',
        id: 'cancelFansCount'
      }, {
        name: '成功发送红包金额',
        id: 'netIncreaseFansCount'
      }, {
        name: '目标发送短信数',
        id: 'activeUser'
      }, {
        name: '成功发送短信数',
        id: 'activeRate'
      }],
      lineListData: {},
      lineChartData: {
        expectedData: [],
        actualData: [],
        xData: [],
        xxData: []
      },
      details: {},
      contrasDetails: {},
      aHref: '',
      sjdis: true
    };
  },
  watch: {
    // 监听用户分析时间
    fansDate: function fansDate(newValue, oldValue) {
      if (newValue === null) {
        this.fansDate = [];
      } else if (newValue !== null && this.fansDate.length === 2 && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        // this.getUserDataIncludeTotal(1,this.fansDate)
        // selectByMarketId({
        //   planId: this.planId,
        //   startTime: this.fansDate[0],
        //   endTime: this.fansDate[1]
        // }).then(resp => {
        //   console.log(resp)
        //   const { code, data, msg } = resp
        //   if (code === 200) {
        //     // isFansContrastDate为真 无对比状态
        //     if (data) {
        //       // const { total, details } = data
        //       // total.date = this.fansDate.join('~')
        //       // this.totalObj = total
        //       this.totalObj = [data.val1,data.val2,data.val3,data.val4,data.val5,data.val6,]
        //       this.details = [data.valMap1,data.valMap2,data.valMap3,data.valMap4,data.valMap5,data.valMap6,]
        //       // this.details = details
        //     } else {
        //       this.totalObj = { date: this.fansDate.join('~') }
        //       this.details = []
        //     }
        //     if (this.fansContrastDate.length === 2) {
        //       this.totalList = [this.totalObj, this.ContrastData]
        //     } else {
        //       this.totalList = [this.totalObj]
        //     }
        //     if (this.isFansContrastDate) {
        //       this.DoFilterData(this.details, 'single')
        //     } else {
        //       const Ldetails = {
        //         newsByDate: this.details,
        //         contrastNewsByDate: this.contrasDetails
        //       }
        //       this.DoFilterData(Ldetails, 'double')
        //     }
        //   } else {
        //     this.$message(msg)
        //   }
        // })
        this.fanData();
      }
    },
    // 监听用户分析对比时间
    fansContrastDate: function fansContrastDate(newValue, oldValue) {
      var _this = this;
      if (newValue !== null) {
        if (newValue !== '') {
          (0, _wxmake.selectByMarketId)({
            planId: this.planId,
            startTime: this.fansContrastDate[0],
            endTime: this.fansContrastDate[1]
          }).then(function (resp) {
            var resp = resp.data;
            console.log(resp);
            var _resp = resp,
              code = _resp.code,
              data = _resp.data,
              msg = _resp.msg;
            if (code === 200) {
              if (data) {
                var total = data.total,
                  details = data.details;
                total.date = _this.fansContrastDate.join('~');
                _this.contrasDetails = details;
                _this.ContrastData = total;
                if (_this.fansDate.length === 2) {
                  _this.totalList.splice(1, 1, total);
                  var obj = {
                    newsByDate: _this.details,
                    contrastNewsByDate: _this.contrasDetails
                  };
                  _this.DoFilterData(obj, 'double');
                } else {
                  _this.$message('请输入或选择时间对比起始时间');
                }
              } else {
                var _total = {
                  date: _this.fansContrastDate.join('~')
                };
                _this.contrasDetails = [];
                _this.ContrastData = _total;
                if (_this.fansDate.length === 2) {
                  _this.totalList.splice(1, 1, _total);
                  var _obj = {
                    newsByDate: _this.details,
                    contrastNewsByDate: _this.contrasDetails
                  };
                  _this.DoFilterData(_obj, 'double');
                } else {
                  _this.$message('请输入或选择时间对比起始时间');
                }
              }
            } else {
              _this.$message(msg);
            }
          });
        } else {
          this.totalList.splice(1, 1);
          this.DoFilterData(this.details, 'single');
          this.ContrastData = {};
        }
      }
    },
    // 监听用户表单时间
    fansTableDate: function fansTableDate(newValue, oldValue) {
      if (newValue !== null) {
        this.userDataFun({
          planId: this.planId,
          endTime: this.fansTableDate[1],
          pageNo: 1,
          pageSize: 10,
          startTime: this.fansTableDate[0]
        });
      }
    }
  },
  created: function created() {
    var _this2 = this;
    var that = this;
    var id = that.$route.query.id;
    console.log(id);
    (0, _wxmake.selectDataAll)().then(function (res) {
      var res = res.data;
      console.log(res);
      if (res.code === 200) {
        that.levdata = res.data || [];
        if (id) {
          console.log('lkllll', (0, _typeof2.default)(id));
          if (that.levdata.length > 0) {
            that.levdata.forEach(function (v) {
              if (v.id === Number(id)) {
                that.planId = v.id;
                that.statem = v.planName;
                that.fansDate = [v.planStartTime, v.planEndTime];
                that.fansTableDate = [v.planStartTime, v.planEndTime];
              }
            });
          }
        }
        console.log(_this2.levdata);
      } else {
        _this2.$message(res.msg);
      }
    });
  },
  methods: {
    fanData: function fanData() {
      var _this3 = this;
      (0, _wxmake.selectByMarketId)({
        planId: this.planId,
        startTime: this.fansDate[0],
        endTime: this.fansDate[1]
      }).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        var _resp2 = resp,
          code = _resp2.code,
          data = _resp2.data,
          msg = _resp2.msg;
        if (code === 200) {
          // isFansContrastDate为真 无对比状态
          if (data) {
            // const { total, details } = data
            // total.date = this.fansDate.join('~')
            // this.totalObj = total
            _this3.totalObj = [];
            _this3.totalObj = [data.val1, data.val2, data.val3, data.val4, data.val5, data.val6];
            console.log('totalObj', _this3.totalObj);
            console.log(_this3.totalObj);
            _this3.details = [data.valMap1, data.valMap2, data.valMap3, data.valMap4, data.valMap5, data.valMap6];
            // this.details = details
          } else {
            _this3.totalObj = {
              date: _this3.fansDate.join('~')
            };
            _this3.details = [];
          }
          if (_this3.fansContrastDate.length === 2) {
            _this3.totalList = [_this3.totalObj, _this3.ContrastData];
          } else {
            _this3.totalList = [_this3.totalObj];
          }
          if (_this3.isFansContrastDate) {
            _this3.DoFilterData(_this3.details, 'single');
          } else {
            var Ldetails = {
              newsByDate: _this3.details,
              contrastNewsByDate: _this3.contrasDetails
            };
            _this3.DoFilterData(Ldetails, 'double');
          }
        } else {
          _this3.$message(msg);
        }
      });
    },
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var _this4 = this;
      (0, _wxmake.selectDataAll)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          // this.levdata = res.data || []
          _this4.restaurants = res.data || [];
          var restaurants = _this4.restaurants;
          var list = [];
          restaurants.forEach(function (v) {
            var n = {
              value: v.planName,
              id: v.id,
              planStatus: v.planStatus,
              planEndTime: v.planEndTime,
              planStartTime: v.planStartTime
            };
            list.push(n);
          });
          restaurants = list;
          var results = queryString ? restaurants.filter(_this4.createStateFilter(queryString)) : restaurants;
          cb(results);
        } else {
          _this4.$message(res.msg);
        }
      });
    },
    createStateFilter: function createStateFilter(queryString) {
      return function (state) {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect: function handleSelect(item) {
      this.planId = item.id;
      this.sjdis = false;
      console.log(item);
      // this.levdata.forEach(v=>{
      //   if(v.id === val){
      this.planStatus = item.planStatus;
      this.planEndTime = item.planEndTime;
      this.planStartTime = item.planStartTime;
      this.fansDate = [item.planStartTime, item.planEndTime];
      this.fanData();
      this.fansTableDate = [item.planStartTime, item.planEndTime];
      //   }
      // })
    },
    cgjhmc: function cgjhmc(val) {
      var _this5 = this;
      console.log(val);
      this.sjdis = false;
      this.levdata.forEach(function (v) {
        if (v.id === val) {
          _this5.planStatus = v.planStatus;
          _this5.planEndTime = v.planEndTime;
          _this5.planStartTime = v.planStartTime;
          _this5.fansDate = [v.planStartTime, v.planEndTime];
          _this5.fansTableDate = [v.planStartTime, v.planEndTime];
        }
      });
    },
    // 每页显示多少条
    handleSizeChange: function handleSizeChange(val) {
      if (this.total > this.pageSize || this.records.length > val) {
        this.pageSize = val;
        this.userDataFun({
          planId: this.planId,
          endTime: this.fansTableDate[1],
          pageNo: 1,
          pageSize: this.pageSize,
          startTime: this.fansTableDate[0]
        });
      }
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.current = val;
      this.userDataFun({
        planId: this.planId,
        endTime: this.fansTableDate[1],
        pageNo: this.current,
        pageSize: this.pageSize,
        startTime: this.fansTableDate[0]
      });
    },
    // 最近30天
    latelyFun: function latelyFun() {
      this.fansDate = this.getDate(30);
    },
    // 获取时间
    getDate: function getDate(val) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      var arr = [(0, _index.parseTime)(start, '{y}-{m}-{d}'), (0, _index.parseTime)(end, '{y}-{m}-{d}')];
      return arr;
    },
    // 获取用户表单
    userDataFun: function userDataFun(data) {
      var _this6 = this;
      (0, _wxmake.selectMarketByCount)({
        planId: this.planId,
        endTime: data.endTime,
        pageNo: data.pageNo,
        pageSize: data.pageSize,
        startTime: data.startTime
      }).then(function (resp) {
        var resp = resp.data;
        var _resp3 = resp,
          code = _resp3.code,
          data = _resp3.data,
          msg = _resp3.msg;
        console.log(resp);
        if (code === 200) {
          if (data) {
            _this6.records = data;
            // this.total = data.total
            _this6.aHref = "/api-memberscrm/member/admin/userAnalysis/userDataDown?appId=".concat(sessionStorage.getItem('appId'), "&startTime=").concat(_this6.fansTableDate[0], "&endTime=").concat(_this6.fansTableDate[1]);
          } else {
            _this6.records = [];
          }
        } else {
          _this6.$message(msg);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 切换 对比
    isFansContrastFun: function isFansContrastFun(e) {
      if (e) {
        this.isFansContrastDate = false;
      } else {
        this.isFansContrastDate = true;
        this.fansContrastDate = '';
      }
    },
    // 切换数据种类
    linefun: function linefun(val) {
      var that = this;
      that.btnClass = val;
      this.datasplice(that.lineListData[val]);
    },
    getUserDataIncludeTotal: function getUserDataIncludeTotal(type, time) {},
    // 过滤折线数据
    DoFilterData: function DoFilterData(data, type) {
      var _this7 = this;
      console.log(data);
      data.forEach(function (item, index) {
        item.sort(function (a, b) {
          return Date.parse(a.giveTime) - Date.parse(b.giveTime);
        });
      });
      console.log('datasro', data);
      if (type === 'single') {
        this.lineList.forEach(function (item, index) {
          _this7.lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        if (data.length > 0) {
          /** */
          var arr3 = [];
          var arr4 = [];
          data.forEach(function (item, index) {
            var arr1 = [];
            var arr2 = [];
            if (item.length > 0) {
              item.forEach(function (v) {
                arr1.push(v.count);
                arr2.push((0, _index.parseTime)(v.giveTime, '{y}-{m}-{d}'));
              });
            } else {
              arr1 = [];
              arr2 = [];
            }
            arr3.push(arr1);
            arr4.push(arr2);
          });
          console.log(arr3);
          console.log(arr4);
          Object.keys(this.lineListData).forEach(function (key, i) {
            _this7.lineListData[key].expectedData = arr3[i];
            _this7.lineListData[key].xData = arr4[i];
          });
        }
        console.log(this.lineListData);
        this.lineChartData = this.lineListData[this.lineList[0].id];
        this.datasplice(this.lineListData[this.lineList[0].id]);
        console.log(this.lineChartData);
      } else {
        this.lineList.forEach(function (item, index) {
          _this7.lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        var lineListData = {};
        this.lineList.forEach(function (item, index) {
          lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        if (data.newsByDate === '' || data.newsByDate === null) {
          this.lineList.forEach(function (item) {
            lineListData[item.id].expectedData = [];
            lineListData[item.id].xData = [];
          });
        } else {
          data.newsByDate.forEach(function (item, index) {
            Object.keys(_this7.lineListData).forEach(function (key) {
              lineListData[key].expectedData.push(item[key]);
              lineListData[key].xData.push((0, _index.parseTime)(item.createDate, '{y}-{m}-{d}'));
            });
          });
        }
        if (data.contrastNewsByDate === '' || data.contrastNewsByDate === null) {
          this.lineList.forEach(function (item) {
            lineListData[item.id].actualData = [];
            lineListData[item.id].xxData = [];
          });
        } else {
          data.contrastNewsByDate.forEach(function (item, index) {
            Object.keys(_this7.lineListData).forEach(function (key) {
              lineListData[key].actualData.push(item[key]);
              lineListData[key].xxData.push((0, _index.parseTime)(item.createDate, '{y}-{m}-{d}'));
            });
          });
        }
        this.lineListData = lineListData;
        this.datasplice(lineListData[this.lineList[0].id]);
        console.log(this.lineChartData);
      }
    },
    userDataDownFun: function userDataDownFun() {
      this.$message.closeAll();
      this.$message.error('暂无数据可下载，切换日期看看');
    },
    datasplice: function datasplice(val) {
      console.log(val);
      var that = this;
      var xdad = val.xData;
      var xxdad = val.xxData;
      if (xdad.length !== xxdad.length && xxdad.length !== 0 && xdad.length !== 0) {
        if (xdad.length > xxdad.length) {
          var a = xdad.length - xxdad.length;
          for (var i = 0; i < a; i++) {
            var c = Math.ceil(a / (2 + i));
            if (c === xdad.length - 1) {
              c = Math.ceil(a / (3 + i));
            }
            xdad.splice(c, 1);
          }
          val.xData = xdad;
        } else {
          var b = xxdad.length - xdad.length;
          for (var _i = 0; _i < b; _i++) {
            var d = Math.ceil(b / (2 + _i));
            if (d === xxdad.length - 1) {
              d = Math.ceil(b / (3 + _i));
            }
            console.log(d);
            xxdad.splice(d, 1);
          }
          val.xxData = xxdad;
        }
      }
      that.lineChartData = val;
    },
    downLoad: function downLoad() {
      var that = this;
      // appId=${sessionStorage.getItem('appId')}&startTime=${
      //           this.fansTableDate[0]
      //         }&endTime=${this.fansTableDate[1]}`
      var appId = sessionStorage.getItem('appId');
      console.log(appId);
      (0, _wxmake.selectMarketByCountExcel)({
        planId: this.planId,
        startTime: this.fansTableDate[0],
        endTime: this.fansTableDate[1]
      }).then(function (res) {
        var res = res.data;
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '效果统计.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function () {
        that.downLoading = false;
      });
    }
  }
};
exports.default = _default;