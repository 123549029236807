"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.del = del;
exports.getList = getList;
exports.insert = insert;
exports.queryMaterial = queryMaterial;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-prevent';
// var modelUrl = ''

function getList(params) {
  return (0, _request.default)({
    url: modelUrl + '/basic/antiWarningConfig/getList',
    method: 'get',
    params: params
  });
}
function insert(data) {
  return (0, _request.default)({
    url: modelUrl + '/basic/antiWarningConfig/insert',
    method: 'post',
    data: data
  });
}
function del(params) {
  return (0, _request.default)({
    url: modelUrl + '/basic/antiWarningConfig/delete',
    method: 'delete',
    params: params
  });
}
function queryMaterial(params) {
  return (0, _request.default)({
    url: modelUrl + '/brandMaterlal/queryMaterial',
    method: 'delete',
    params: params
  });
}