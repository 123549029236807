"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stock = require("@/api/warehouse/stock/stock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _warnings_search = _interopRequireDefault(require("./components/warnings_search"));
var _warnings_insert = _interopRequireDefault(require("./components/warnings_insert"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import productDialog from '@/components/productSelectDialog'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    // productDialog,
    search: _warnings_search.default,
    insert: _warnings_insert.default
  },
  filters: {
    // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.code == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  data: function data() {
    return {
      tableData: [],
      form: {},
      searchForm: {
        current: 1,
        size: 10
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      dialogFormVisible: false,
      btn_loading: false,
      multipleSelection: [],
      tabHeight: '100%'
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    ShowModifyById: function ShowModifyById(row) {
      this.form = {};
      this.form = {
        pid: row.pid,
        prodCode: row.prodCode,
        storeCode: row.storeCode,
        vmax: row.vmax,
        vmin: row.vmin
      };
      this.dialogFormVisible = true;
    },
    SaveModifyById: function SaveModifyById() {
      var _this = this;
      this.btn_loading = true;
      (0, _stock.modifyById)(this.form).then(function (res) {
        _this.btn_loading = false;
        var data = res.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return false;
        }
        _this.dialogFormVisible = false;
        _this.$message({
          message: res.data.msg,
          type: 'success'
        });
        _this.getList();
      }).catch(function () {});
    },
    CloseModifyById: function CloseModifyById() {
      this.form = {};
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogFormVisible = false;
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('deletebtn', _this2.$route.meta.authList);
        (0, _stock.deleteById)(row.pid).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: res.data.msg,
            type: 'success'
          });
          _this2.getList();
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.$refs.insertMain.show(1, '');
    },
    searchList: function searchList(data) {
      this.searchForm = data;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      var that = this;
      that.listLoading = true;
      this.authId('searchbtn', this.$route.meta.authList);
      (0, _stock.queryForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    }
  }
};
exports.default = _default;