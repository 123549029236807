var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mod-prod-prod-transport" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: "运费设置",
            rules: [{ required: true, message: "运费模板不能为空" }],
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.changeTransport },
              model: {
                value: _vm.transportId,
                callback: function ($$v) {
                  _vm.transportId = $$v
                },
                expression: "transportId",
              },
            },
            _vm._l(_vm.transportList, function (transport) {
              return _c("el-option", {
                key: transport.transportId,
                attrs: {
                  label: transport.transName,
                  value: transport.transportId,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _vm.transportInfo.transfees
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.transportInfo.transfees },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "配送区域", width: "350" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              !scope.row.cityList.length
                                ? _c("span", [_vm._v("所有地区")])
                                : _vm._l(scope.row.cityList, function (city) {
                                    return _c("el-tag", { key: city.areaId }, [
                                      _vm._v(_vm._s(city.areaName)),
                                    ])
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2372662209
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "firstPiece", label: _vm.tableTitle[0] },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "firstFee", label: _vm.tableTitle[1] },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "continuousPiece",
                      label: _vm.tableTitle[2],
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "continuousFee", label: _vm.tableTitle[3] },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.transportInfo.hasFreeCondition === 1
        ? _c(
            "el-form-item",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.transportInfo.transfeeFrees },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "指定区域", width: "350" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(
                              scope.row.freeCityList,
                              function (city) {
                                return _c("el-tag", { key: city.areaId }, [
                                  _vm._v(_vm._s(city.areaName)),
                                ])
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      569599057
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "freeType", label: "包邮条件" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.freeType === 0
                                ? _c("span", [_vm._v("满件/重量/体积包邮")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.freeType === 1
                                ? _c("span", [_vm._v("满金额包邮")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.freeType === 2
                                ? _c("span", [
                                    _vm._v("满件/重量/体积且满金额包邮"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2799028297
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "amount" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.freeType === 1
                                ? _c("span", [
                                    _vm._v(
                                      "满" +
                                        _vm._s(scope.row.amount) +
                                        "元金额包邮"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.freeType === 0
                                ? _c("span", [
                                    _vm._v(
                                      "满" +
                                        _vm._s(scope.row.piece) +
                                        "件/重量/体积包邮"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.freeType === 2
                                ? _c("span", [
                                    _vm._v(
                                      "满" +
                                        _vm._s(scope.row.piece) +
                                        "件/重量/体积且满" +
                                        _vm._s(scope.row.amount) +
                                        "元金额包邮"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1427603017
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }