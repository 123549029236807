"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'details-hdfl',
  components: {},
  props: {
    shVisible: {
      type: Boolean,
      default: false
    },
    pid: {
      type: String,
      default: ''
    }
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    })
  },
  data: function data() {
    return {
      detailData: {},
      mloading: false,
      proList: [],
      treeData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      configData: [],
      radio: '1'
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
  },
  watch: {
    shVisible: function shVisible(val) {
      if (val) {
        this.getBasic(this.pid);
        this.getPro(this.pid);
        this.getOrg(this.pid);
        this.getCofig(this.pid);
      }
    }
  },
  methods: {
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.detailData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取基本信息');
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.proList = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取产品信息');
        }
      });
    },
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getRebateRangeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var arr = [];
          var rData = res.data.data;
          if (rData.length > 0) {
            rData.forEach(function (item) {
              arr.push(item.orgId);
            });
          }
          that.checkedKeys = arr;
          that.treeData = that.$parent.orgOptions;
        }
      });
    },
    getCofig: function getCofig(arg) {
      var that = this;
      (0, _param.getRebateSchemeConfig)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.configData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    DoShResult: function DoShResult() {
      var that = this;
      var param = {};
      param.id = that.pid;
      if (that.radio == '1') {
        (0, _param.passFlSh)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '审核成功'
            });
            that.handleClose();
            that.$parent.getList();
          } else {
            that.$message.error(res.data.msg);
            return;
          }
        });
      }
      if (that.radio == '2') {
        rejectFlSh(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '已拒审'
            });
            that.handleClose();
            that.$parent.getList();
          } else {
            that.$message.error(res.data.msg);
            return;
          }
        });
      }
    }
  }
};
exports.default = _default;