"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'systemrolesmangecompyuserindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    dialogVisible: Boolean
  },
  data: function data() {
    return {
      groupList: [],
      postList: [],
      listQuery: {
        current: 1,
        size: 10
      },
      param: {
        loginAccount: '',
        userName: '',
        userType: 0,
        phoneNum: '',
        isEnable: '',
        orgList: [],
        roleList: []
      },
      list: [],
      multipleSelection: [],
      tableKey: '010099',
      total: 0,
      listLoading: false,
      loading: false,
      roleOption: [],
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      orgOriginData: [],
      options: [],
      uname: {},
      names: {},
      ids: '',
      allEditShow: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        var that = this;
        that.getList();
        that.getUserRloes();
        that.getOrg();
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _compyuser.getCompanyList)(that.listQuery, that.param).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 1000);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.param.loginAccount = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.orgList = [];
      that.param.roleList = [];
      that.listQuery.current = 1;
      that.$refs[formName].resetFields();
      that.getList();
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.options = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getUserRloes: function getUserRloes() {
      var that = this;
      (0, _compyuser.getAllQyRoles)().then(function (res) {
        if (res.data.code == 200) {
          that.roleOption = res.data.data;
        } else {
          that.roleOption = [];
        }
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      that.$emit('change', data);
      that.handleClose();
    },
    handleClose: function handleClose() {
      var that = this;
      that.param.loginAccount = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.orgList = [];
      that.param.roleList = [];
      that.listQuery.current = 1;
      that.$emit('close');
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;