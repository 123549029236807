"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    actionType: {
      type: Number,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../../../images/origin.png"),
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 10,
        archivesStatus: 1,
        productBrandId: '',
        containsPackScale: 1,
        productClassId: '',
        packScaleId: '',
        productCode: '',
        productName: '',
        isCode: 1
      },
      tableData: [],
      normalList: [],
      brandList: [],
      packList: [],
      total: 0,
      appendToBody: true,
      clearable: true,
      allClassIds: []
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        var that = this;
        this.getClass();
        this.getBrand();
        this.getPack();
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getClass: function getClass() {
      var _this = this;
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        var ids = [];
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var resData = res.data.data;
            var type = that.actionType;
            var mapData = [];
            var mapDatas = resData.map(function (item) {
              return {
                productClassName: item.productClassName,
                id: item.pId,
                pId: item.pId,
                parentId: item.parentId
              };
            });
            resData.forEach(function (item) {
              if (item.productClassCode != '102' && item.productClassCode != '101') {
                mapData.push(item);
                ids.push(item.pId);
                that.searchForm.isCode = null;
              }
            });
            that.allClassIds = ids;
            that.normalList = (0, _utils.transTreeData)(mapDatas, 'pId', 'parentId', 'children');
          } else {
            _this.normalList = [];
          }
        }
        return ids;
      }).then(function (res) {
        that.getList();
      });
    },
    getBrand: function getBrand() {
      var _this2 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.brandList = res.data.data;
          } else {
            _this2.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this3 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.packList = res.data.data;
          } else {
            _this3.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.deepClone)(that.searchForm);
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this4.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      var type = that.actionType;
      var isCode = 1;
      if (type == 3) {
        isCode = 1;
      } else {
        isCode = 2;
      }
      that.searchForm = {
        current: 1,
        size: 10,
        archivesStatus: 1,
        productBrandId: '',
        containsPackScale: 1,
        productClassId: '',
        packScaleId: '',
        productCode: '',
        productName: '',
        isCode: 1
      };
      this.filterText = '';
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default2;