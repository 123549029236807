"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _fake = require("@/api/fake_expel/fake/fake");
var _basic = require("@/api/fake_expel/basic");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _seach = _interopRequireDefault(require("../components/fake/seach"));
var _echarts = _interopRequireDefault(require("echarts"));
require("../../../../../node_modules/echarts/map/js/china.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 引入中国地图数据
var clickTimeId = '';
document.oncontextmenu = function (event) {
  event.preventDefault();
};
var _default = {
  components: {
    seach: _seach.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      brandList: [],
      tableKey: 0,
      total: 0,
      listLoading: false,
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        ppwlList: '',
        timeType: '1',
        provinceName: ''
      },
      listMapQuery: {
        ppwlList: '',
        timeType: '1'
      },
      exoutQuery: {
        ppwlList: '',
        timeType: '1',
        provinceName: '',
        ppName: ''
      },
      ProvinceList: null,
      CityList: null,
      chart: null,
      chartMapList: null,
      ProvinceShowList: true,
      CityShowList: false,
      excelOut_loading: false,
      WlList: null,
      WlListlistLoading: false,
      provinces: ['shanghai', 'hebei', 'shanxi', 'neimenggu', 'liaoning', 'jilin', 'heilongjiang', 'jiangsu', 'zhejiang', 'anhui', 'fujian', 'jiangxi', 'shandong', 'henan', 'hubei', 'hunan', 'guangdong', 'guangxi', 'hainan', 'sichuan', 'guizhou', 'yunnan', 'xizang', 'shanxi1', 'gansu', 'qinghai', 'ningxia', 'xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen', 'taiwan'],
      provincesText: ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门', '台湾'],
      map: '',
      loadMapData: [],
      exoutQuery_rn: '',
      showfh: false,
      tabHeight: 'calc(100vh - 250px)',
      option: {
        backgroundColor: '#02AFDB',
        // height: '65%',
        tooltip: {},
        // 鼠标移到图里面的浮动提示框
        // scaleLimit: { min: 1, max: 1.9 }, // 缩放
        dataRange: {
          show: false,
          x: 'left',
          y: 'bottom',
          splitList: [{
            start: 500000
          }, {
            start: 100000,
            end: 500000
          }, {
            start: 10000,
            end: 100000
          }, {
            start: 5000,
            end: 10000
          }, {
            start: 1000,
            end: 5000
          }, {
            start: 500,
            end: 1000
          }, {
            start: 1,
            end: 500
          }, {
            start: 0,
            end: 0,
            label: '无查询记录'
          }],
          color: ['#600030', '#820041', '#9F0050', '#BF0060', '#D9006C', '#F00078', '#FF0080', '#FF359A', '#FF60AF', '#FF79BC', '#FF95CA', '#ffaad5', '#FFC1E0', '#FFD9EC', '#FFECF5', '#F0F0F0']
        },
        geo: {
          map: '',
          zoom: 1,
          roam: true,
          scaleLimit: {
            // 滚轮缩放的极限控制
            min: 1,
            max: 6
          },
          label: {
            normal: {
              show: true,
              // 是否显示对应地名
              textStyle: {
                color: 'rgba(0,0,0,0.4)'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [{
          type: 'scatter',
          coordinateSystem: 'geo' // 对应上方配置
        }, {
          name: '查询个数',
          // 浮动框的标题
          type: 'map',
          geoIndex: 0,
          data: []
        }]
      }
    };
  },
  activated: function activated() {
    // var that = this
    // var h = tableHeight()
    // that.$nextTick(function() {
    //   that.tabHeight = h
    // })
    this.map = _echarts.default.init(this.$refs.myEchart);
    this.initMap();
    // var m = parseInt(new Date().getMonth()) + 1
    this.getMapList();
    // this.$refs.MapChart.initChart()
  },

  methods: {
    initMap: function initMap() {
      var _this2 = this;
      var _this = this;
      var selected = 'china';
      for (var i in this.provincesText) {
        var v = {
          name: this.provincesText[i],
          value: 0,
          cityList: [],
          sourc_name: ''
        };
        this.loadMapData.push(v);
      }
      this.loadMap(selected, this.loadMapData);
      var isProvince = function isProvince(name) {
        return _this2.provincesText.some(function (province) {
          return province === name;
        });
      };
      // 定义方法加载某个省的地图文件
      var loadScriptMap = function loadScriptMap(name, callback) {
        var pinyinName = _this2.provinces[_this2.provincesText.indexOf(name)];
        // const currentMap = require(`echarts/map/js/province/${pinyinName}`)
        var currentMap = require("@/assets/map/js/province/".concat(pinyinName));
        var option = JSON.parse(JSON.stringify(_this2.map.getOption()));
        console.log(pinyinName, currentMap, '切换地图');
        callback(name, '', option.geo[0].zoom);
      };
      this.map.on('contextmenu', function (ev) {
        // clearTimeout(clickTimeId);
        _this.map.hideLoading(); // 提示关闭
        _this.map.showLoading({
          text: '正在加载数据...'
        });
        _this.CityList = null;
        if (isProvince(ev.name)) {
          if (ev.data.value == 0 || ev.data.value == null) {
            _this.$alert('此省市并未存在防伪数据', '提示信息', {
              confirmButtonText: '确定',
              callback: function callback(action) {
                _this.map.hideLoading(); // 提示关闭
              }
            });

            return false;
          }
          _this.queryMapCityList(ev);
        } else {
          // 否则切换中国地图
          selected = 'china';
          _this.loadMap(selected, _this.loadMapData);
        }
        _this.map.hideLoading(); // 提示关闭
      });
      // 监听地图点击事件
      this.map.on('click', function (ev) {
        if (ev.name == '南海诸岛') {
          return;
        }
        _this.map.showLoading({
          text: '正在加载数据...'
        });
        clearTimeout(clickTimeId);
        clickTimeId = setTimeout(function () {
          _this.ProvinceList = null;
          if (isProvince(ev.name)) {
            selected = ev.name;
            if (ev.data.value == 0 || ev.data.value == null) {
              _this.$alert('此省市并未存在防伪数据', '提示信息', {
                confirmButtonText: '确定',
                callback: function callback(action) {
                  _this.map.hideLoading(); // 提示关闭
                }
              });

              return false;
            }

            // 从外部加载这个省的地图文件
            loadScriptMap(ev.name, _this.loadMap);
            _this.showfh = true;
            _this.queryMapProList(ev);
          } else {
            // 否则切换中国地图
            _this.mapfh();
            /* selected = 'china'
            _this.showfh = false
            _this.loadMap(selected, _this.loadMapData)*/
            _this.map.hideLoading(); // 提示关闭
          }
        }, 250);
      });
    },
    loadMap: function loadMap(param, data, zoom) {
      // 参数为要显示地图区域的名字
      this.option.geo.map = param;
      this.option.geo.zoom = zoom || 1;
      this.option.series[1].data = data;
      this.map.setOption(this.option, true);
    },
    mapfh: function mapfh() {
      this.showfh = false;
      this.CityList = null;
      this.ProvinceList = null;
      this.ProvinceShowList = true;
      this.CityShowList = false;
      this.listQuery.provinceName = '';
      this.queryMapCityList({
        data: {
          sourc_name: ''
        }
      });
      var option = JSON.parse(JSON.stringify(this.map.getOption()));
      this.loadMap('china', this.loadMapData, option.geo[0].zoom);
    },
    tableHeaderColor: function tableHeaderColor(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (rowIndex === 0) {
        return 'background-color: lightblue;color: #fff!improrant;font-weight: 500;';
      }
    },
    excelOutMapProWlmsList: function excelOutMapProWlmsList() {
      var _this3 = this;
      if (!this.exoutQuery_rn) {
        this.$message({
          type: 'error',
          message: '请选择要导出品牌'
        });
        return;
      }
      this.exoutQuery = (0, _basic.getKeysetVal)(this.exoutQuery, this.listQuery);
      this.excelOut_loading = true;

      // for (var i in this.ProvinceList) {
      //   if (i + 1 == this.exoutQuery_rn) {
      this.exoutQuery.ppName = this.ProvinceList[this.exoutQuery_rn - 1].ppName;
      this.exoutQuery.provinceName = this.ProvinceList[this.exoutQuery_rn - 1].provinceDq;
      //     break
      //   }
      // }
      this.authId('exportbtn', this.$route.meta.authList);
      (0, _fake.excelOutMapProWlmsList)(this.exoutQuery).then(function (res) {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '防伪预警-' + _this3.exoutQuery.ppName + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this3.excelOut_loading = false;
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        _this3.excelOut_loading = false;
      });
    },
    getMapList: function getMapList() {
      var _this4 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.mapfh();
      this.loadMapData = [];
      this.listLoading = true;
      this.map.showLoading({
        text: '正在加载数据...'
      });
      this.queryMapCityList({
        data: {
          sourc_name: ''
        }
      });
      (0, _fake.queryMapPro)(this.listMapQuery).then(function (response) {
        var data = response.data;
        for (var i in _this4.provincesText) {
          var v = {
            name: _this4.provincesText[i],
            value: 0,
            sourc_name: ''
          };
          _this4.loadMapData.push(v);
        }
        var option = _this4.map.getOption();
        if (data.code != 200) {
          option.series[1].data = _this4.loadMapData;
          _this4.map.setOption(option, true);
          _this4.map.hideLoading(); // 提示关闭
          _this4.$message.error(data.msg);
          return true;
        }
        if (data.data.length <= 0) {
          _this4.listLoading = false;
          // var option = this.map.getOption()
          option.series[1].data = _this4.loadMapData;
          _this4.map.setOption(option, true);
          _this4.map.hideLoading(); // 提示关闭
          return true;
        }
        var getMapData = data.data;
        for (var n in getMapData) {
          for (var k in _this4.loadMapData) {
            if (getMapData[n].name.indexOf(_this4.loadMapData[k].name) > -1) {
              _this4.loadMapData[k].value = getMapData[n].value;
              _this4.loadMapData[k].sourc_name = getMapData[n].name;
            }
          }
        }
        // var option = this.map.getOption()
        option.series[1].data = _this4.loadMapData;
        _this4.map.setOption(option, true);
        _this4.map.hideLoading(); // 提示关闭
        _this4.listLoading = false;
      });
    },
    queryMapProList: function queryMapProList(obj) {
      var _this5 = this;
      // 获取省份品牌信息
      this.CityShowList = false;
      this.ProvinceShowList = true;
      this.listQuery.provinceName = obj.data.sourc_name;
      this.listLoading = true;
      (0, _fake.queryMapProList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this5.ProvinceList = [];
        if (data.code != 200) {
          _this5.$message.error(data.msg);
          return true;
        }
        _this5.ProvinceList = data.data;
        _this5.listLoading = false;
      });
      (0, _fake.queryMapCity)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this5.$message.error(data.msg);
          return true;
        }
        var v = '';
        var loadMapData = [];
        for (var n in data.data) {
          v = {
            name: data.data[n].name,
            value: data.data[n].value,
            sourc_name: ''
          };
          loadMapData.push(v);
        }
        var option = _this5.map.getOption();
        option.series[1].data = loadMapData;
        _this5.map.setOption(option, true);
        _this5.map.hideLoading(); // 提示关闭
      });
    },
    queryMapProWlList: function queryMapProWlList(row, expandedRows) {
      var _this6 = this;
      if (expandedRows.length <= 0) return false;
      if (expandedRows.length > 1) expandedRows.shift();
      this.WlListlistLoading = true;
      var q = {
        ppwlList: '',
        timeType: '1',
        provinceName: '',
        ppName: row.ppName
      };
      q = (0, _basic.getKeysetVal)(q, this.listQuery);
      q.provinceName = row.provinceDq;
      (0, _fake.queryMapProWlList)(q).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this6.$message.error(data.msg);
          return true;
        }
        row.WlList = data.data;
        _this6.WlListlistLoading = false;
      });
    },
    queryMapCityList: function queryMapCityList(obj) {
      var _this7 = this;
      // 获取城市下品牌物料信息
      this.ProvinceShowList = false;
      this.CityShowList = true;
      this.listQuery.provinceName = obj.data.sourc_name;
      this.listLoading = true;
      (0, _fake.queryMapCityList)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this7.$message.error(data.msg);
          return true;
        }
        _this7.CityList = data.data;
        _this7.listLoading = false;
      });
    },
    searchList: function searchList(data) {
      if (this.listQuery.provinceName != '' && this.listQuery.provinceName != null) {
        this.map.showLoading({
          text: '正在加载数据...'
        });
        this.listQuery.pageNo = 1;
        this.listQuery.pageSize = 10;
        this.listQuery.ppwlList = data.ppwlList;
        this.listQuery.timeType = data.timeType;
        this.queryMapProList({
          data: {
            sourc_name: this.listQuery.provinceName
          }
        });
      } else {
        this.listQuery = (0, _basic.getKeysetVal)(this.listMapQuery, data);
        this.getMapList();
      }
    }
  }
};
exports.default = _default;