"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _checkIn = require("@/api/fake_expel/checkIn");
var _factory = require("@/api/basic/factory");
var _org = require("@/api/basic/org");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getYwList } from '@/api/chanelmange/chanelyw'
var _default = {
  name: 'ChanelmangecheckManageindex',
  components: {
    Pagination: _Pagination.default,
    purchaseDialog: _purchaseDialog.default
  },
  filters: {
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        5: 'success'
      };
      return obj[val];
    },
    sellerType: function sellerType(val) {
      var obj = {
        0: '企业',
        1: '经销商',
        2: '终端'
      };
      return obj[val];
    },
    checkStatus: function checkStatus(val) {
      if (!val) return '';
      var obj = {
        1: '已发货待签收',
        2: '已发货签收中',
        5: '已发货已签收'
      };
      return obj[val];
    },
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      buyerName: '',
      sellerName: '',
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        buyerId: '',
        checkCode: '',
        checkStatus: '',
        startDate: '',
        endDate: '',
        sellerId: '',
        storeId: '',
        createName: '',
        sellerOrgId: ''
      },
      sellerOrgId: [],
      orgOptions: [],
      unitSelect: {
        channelName: ''
      },
      tableKey: 'cgrk',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      unitType: '',
      exportLoading: false,
      scanVisible: false,
      baseData: {},
      productlist: [],
      storeList: [],
      remark: '',
      orderId: '',
      codeData: [],
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      },
      total2: 0,
      scanCodeVisible: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  activated: function activated() {
    var that = this;
    that.getStoreList();
    that.getOrg();
    that.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      console.log(this.sellerOrgId);
      if (this.sellerOrgId.length) {
        console.log(111);
        this.listQuery.sellerOrgId = this.sellerOrgId[this.sellerOrgId.length - 1];
      } else {
        this.listQuery.sellerOrgId = '';
      }
      (0, _checkIn.jcList)(this.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var _this = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgOptions = JSON.parse(JSON.stringify(Data));
        }
      });
    },
    clearFh: function clearFh() {
      var that = this;
      that.listQuery.sellerId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      this.sellerName = '';
      this.buyerName = '';
      this.sellerOrgId = [];
      that.listQuery = {
        pageNo: 1,
        pageSize: 20,
        buyerId: '',
        checkCode: '',
        checkStatus: '',
        startDate: '',
        endDate: '',
        sellerId: '',
        storeId: '',
        createName: '',
        sellerOrgId: ''
      };
      that.getList();
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var _this2 = this;
      var that = this;
      that.unitType = val;
      // that.unitShow = true
      this.$nextTick(function () {
        _this2.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.unitSelect.channelName = val.channelName;
      if (that.unitType == 'in') {
        that.listQuery.buyerId = val.pId;
        that.buyerName = val.channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.sellerId = val.pId;
        that.sellerName = val.channelName;
      }
      that.unitShow = false;
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      that.listQuery.salesmanId = JSON.parse(val).pid;
      that.listQuery.salesmanStr = JSON.parse(val).userName;
      that.personShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = JSON.parse(JSON.stringify(this.listQuery));
      delete param.pageNo;
      delete param.pageSize;
      that.exportLoading = true;
      (0, _checkIn.jcListExport)(param).then(function (res) {
        console.log(res, 'res');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '稽查管理列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.baseData = {};
      this.activeName = '';
      this.remark = '';
      this.checklist = [];
      this.productlist = [];
      this.fileUrls = [];
      this.scanVisible = false;
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.orderId = data.pid;
      this.$router.push({
        name: 'chanelmangecheckManagedetail',
        query: {
          id: data.checkCode
        }
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetails();
    },
    codeClose: function codeClose() {
      this.scanCodeVisible = false;
      this.codeParam = {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      };
      this.codeData = [];
      this.total2 = 0;
    },
    getCodeDetails: function getCodeDetails() {
      var that = this;
      this.codeParam.id = that.orderId;
      var param = JSON.parse(JSON.stringify(this.codeParam));
      delete param.productName;
      (0, _checkIn.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    searchReset: function searchReset() {
      // this.codeParam.id = ''
      this.codeParam.code = '';
      this.codeParam.productId = '';
      this.codeParam.productName = '';
      this.codeParam.isError = '';
      this.codeParam.pageNo = 1;
      this.getCodeDetails();
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;