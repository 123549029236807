"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.number.constructor");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var temp = {
  id: 0
};
var listQuery = {
  pageNo: 1,
  pageSize: 20,
  packageOrderNum: '',
  supplierCode: ''
};
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  props: ['dialogTitle'],
  data: function data() {
    return {
      tableKey: 0,
      total: 0,
      downNumber: 0,
      list: null,
      listLoading: false,
      listQuery: listQuery,
      temp: temp,
      innerVisible: false,
      innercreateVisible: false,
      btn_loading: false,
      map: '',
      parentData: {
        supplierName: '',
        supplierType: '',
        supplierCode: '',
        pid: 0,
        clientAuthId: ''
      },
      formData: {
        clientAuthId: '',
        packageId: 0,
        supplierCode: ''
      },
      formApplyData: {
        causes: '',
        packageId: 0,
        supplierCode: '',
        clientAuthId: ''
      },
      dialogWarrantCodeVisible: false,
      rules: {
        causes: [{
          required: true,
          message: '输入再次生成原因',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    'parentData.pid': function parentDataPid(newVal, oldVal) {}
  },
  created: function created() {
    // this.getAuthCode();
    // this.getList();
  },
  handleFilter: function handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  },
  methods: {
    formApplycler: function formApplycler() {
      this.formApplyData.causes = '';
    },
    inits: function inits() {
      this.listQuery.packageOrderNum = '';
      this.formApplyData.causes = '';
      this.list = null;
      this.total = 0;
      // this.getList();
    },
    Rest: function Rest() {
      this.innerVisible = false;
      this.dialogWarrantCodeVisible = false;
      this.innercreateVisible = false;
      this.list = null;
      this.total = 0;
    },
    getList: function getList() {
      var _this = this;
      // 获取下载的码包列表
      this.listLoading = true;
      this.listQuery.supplierCode = this.parentData.supplierCode;
      (0, _client_licensing.markDownSearch)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        _this.list = data.data.records;
        _this.total = Number(data.data.total);
        _this.listLoading = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(row) {
      if (row == '' || row == null) {
        return false;
      }
      this.formData.clientAuthId = this.parentData.clientAuthId;
      this.formData.supplierCode = this.parentData.supplierCode;
      this.formData.packageId = row.pid;
      this.formApplyData.packageId = row.pid;
      this.formApplyData.supplierCode = this.parentData.supplierCode;
    },
    createData: function createData() {
      var _this2 = this;
      // 调取- 创建数据保存
      if (this.formData.packageId == 0) {
        this.$message.error('请选择一个包数据');
        return false;
      }
      this.btn_loading = true;
      (0, _client_licensing.saveAuthCode)(this.formData).then(function (res) {
        _this2.btn_loading = false;
        var data = res.data;
        if (data.code != 200 && data.code != 1007) {
          // 返回200表示下载次数<=0保存成功并返回授权码,返回1007表示下载次数>1提交申请
          _this2.$message.error(data.msg);
          return false;
        }
        if (data.code == 1007) {
          _this2.innerVisible = true;
          _this2.downNumber = data.data;
          return true;
        } else {
          _this2.formData.packageId = 0;
          _this2.$parent.getList();
          _this2.innercreateVisible = true;
          _this2.parentData.com_authCode = data.data;
          // this.dialogWarrantCodeVisible = false;
          _this2.$message({
            message: '保存成功',
            type: 'success'
          });
        }
      }).catch(function () {
        _this2.btn_loading = false;
      });
    },
    getapplyAuthCode: function getapplyAuthCode() {
      var _this3 = this;
      // 客户端授权码提交申请
      this.btn_loading = true;
      this.$refs['formApply'].validate(function (valid) {
        if (valid) {
          _this3.formApplyData.clientAuthId = _this3.formData.clientAuthId;
          (0, _client_licensing.applyAuthCode)(_this3.formApplyData).then(function (res) {
            _this3.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              // 返回200表示下载次数<=0保存成功并返回授权码,返回1007表示下载次数>1提交申请
              _this3.$message.error(data.msg);
              return false;
            }
            _this3.formData.packageId = 0;
            _this3.$parent.getList();
            _this3.innerVisible = false;
            _this3.dialogWarrantCodeVisible = false;
            _this3.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this3.btn_loading = false;
          });
        } else {
          _this3.btn_loading = false;
        }
      });
    },
    getKeysetVal: function getKeysetVal(old_v, new_v) {
      for (var i in old_v) {
        if ((0, _typeof2.default)(old_v[i]) === 'object') {
          for (var n in old_v[i]) {
            old_v[i][n] = new_v[i][n];
          }
        } else {
          old_v[i] = new_v[i];
        }
      }
      return old_v;
    },
    handleClose: function handleClose(done) {
      done();
      // this.parentData.pid=0;
    }
  }
};
exports.default = _default;