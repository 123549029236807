"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _search = _interopRequireDefault(require("./components/search"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _printDialog = _interopRequireDefault(require("@/components/printDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var qrcode = "";
var TypeDataComs = {
  status: [],
  source: []
};
var _default = {
  name: "WarehouseServicewarehousingpurchasepurchase",
  components: {
    Pagination: _Pagination.default,
    search: _search.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    printDialog: _printDialog.default
  },
  filters: {
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs["status"];
      var name = "";
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs["source"];
      var name = "";
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    }
  },
  data: function data() {
    return {
      billType: 107,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      params: {
        billPurchaseQuery: {},
        billNo: "",
        billSource: "",
        billStatus: "",
        billType: 107,
        endTime: "",
        productId: "",
        startTime: ""
      },
      tableKey: "rn",
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: "100%",
      submitType: "",
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: "二维码查看",
      codeVisible: false,
      billNo: "",
      typeNum: 2,
      printShow: false,
      pShow: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.authId("searchbtn", this.$route.meta.authList);
    this.getstoreTypeInfoList("DJZT");
    this.getstoreTypeInfoList("DJLY");
    TypeDataComs = this.TypeDataComs;
    this.getList();
  },
  methods: {
    // 重置
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs["searchForm"].resetFields();
      this.getList();
    },
    // 查询
    searchList: function searchList(data) {
      this.params.billPurchaseQuery = {
        inOrgId: data.inOrgId,
        inStoreId: data.inStoreId,
        outOrgId: data.outOrgId,
        relateNo: data.relateNo
      };
      this.params.billNo = data.billNo;
      this.params.billSource = data.billSource;
      this.params.billStatus = data.billStatus;
      this.params.billType = 107;
      this.params.endTime = data.endTime;
      this.params.productId = data.productId;
      this.params.startTime = data.startTime;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryForPage)(this.searchForm.current, this.searchForm.size, this.params).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    add: function add() {
      this.authId("addbtn", this.$route.meta.authList);
      this.$refs.insertMain.show(1, this.billType);
    },
    scanDetail: function scanDetail(row) {
      this.scanData = JSON.parse(JSON.stringify(row));
      this.detailVisible = true;
    },
    doUpdate: function doUpdate(row) {
      this.$refs.insertMain.upDateShow(row);
    },
    codeDetail: function codeDetail() {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.getCodeList();
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _index.InfoDel)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: res.data.msg,
            type: "success"
          });
          _this2.getList();
        });
      }).catch(function () {});
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      this.authId("detailbtn", this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this3 = this;
      this.authId("exportbtn", this.$route.meta.authList);
      this.downLoading = true;
      (0, _index.excelOut)(this.params).then(function (res) {
        _this3.authId("searchbtn", _this3.$route.meta.authList);
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "费用酒入库.xls";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.authId("searchbtn", _this3.$route.meta.authList);
        _this3.downLoading = false;
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById("qrcode").innerHTML = "";
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
    },
    // 打印
    printTb: function printTb(row) {
      this.authId("printbtn", this.$route.meta.authList);
      var that = this;
      that.billNo = row.billNo;
      that.typeNum = 2;
      that.pShow = true;
      that.printShow = true;
    },
    // 关闭打印
    printClose: function printClose() {
      var that = this;
      that.billNum = "";
      that.pShow = false;
      that.printShow = false;
      this.authId("searchbtn", this.$route.meta.authList);
    },
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this4 = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == "DJZT") {
            _this4.TypeDataComs.status = res.data.data;
          }
          if (param.code == "DJLY") {
            _this4.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this4.TypeDataComs;
        }
      }).catch(function () {});
    }
  }
};
exports.default = _default;