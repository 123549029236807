"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inventory = require("@/api/warehouse/inventory");
var _factory = require("@/api/basic/factory");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _audit = _interopRequireDefault(require("./components/audit"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    audit: _audit.default,
    productDialog: _productSelectDialog.default
  },
  filters: {
    djztText: function djztText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '待盘点',
          2: '已下载',
          3: '已盘点',
          4: '审核通过',
          5: '审核拒绝'
        };
        return statusMap[value];
      }
    } // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.figure == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  data: function data() {
    return {
      storeList: [],
      djzt: [{
        id: 1,
        name: '待盘点'
      }, {
        id: 2,
        name: '已下载'
      }, {
        id: 3,
        name: '已盘点'
      }, {
        id: 4,
        name: '审核通过'
      }, {
        id: 5,
        name: '审核拒绝'
      }],
      tableData: [],
      searchForm: {
        beginDate: null,
        endDate: null,
        productId: null,
        inventoryOrderNo: null,
        orderStatus: 3,
        storeId: null,
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      productName: '',
      total: 0,
      tabHeight: '100%',
      handleShow: false,
      listLoading: false,
      detailShow: false,
      orderId: ''
    };
  },
  activated: function activated() {
    this.getStoreList();
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getStoreList: function getStoreList(val) {
      var that = this;
      (0, _factory.ccGetHouseList)().then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    DoSearch: function DoSearch() {
      this.searchForm.current = 1;
      this.getList();
    },
    DoReset: function DoReset(formName) {
      this.pdaUser = '';
      this.productName = '';
      this.searchForm = {
        beginDate: null,
        endDate: null,
        productId: null,
        inventoryOrderNo: null,
        orderStatus: 3,
        storeId: null,
        current: 1,
        size: 20
      };
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      (0, _inventory.queryForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    close: function close() {
      this.orderId = '';
      this.detailShow = false;
    },
    inSertSub: function inSertSub() {
      this.orderId = '';
      this.detailShow = false;
      this.getList();
    },
    audit: function audit(row) {
      this.orderId = row.pid;
      this.detailShow = true;
    }
  }
};
exports.default = _default;