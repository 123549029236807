var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "企业角色-修改",
        model: _vm.edit,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "editForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.editData,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "roleName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.editData.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "roleName", $$v)
                  },
                  expression: "editData.roleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色编码", prop: "roleCode" } },
            [
              _c("el-input", {
                attrs: { maxlength: "50", disabled: "" },
                model: {
                  value: _vm.editData.roleCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "roleCode", $$v)
                  },
                  expression: "editData.roleCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属系统", prop: "clientCodes" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { multiple: "", placeholder: "请选择" },
                  model: {
                    value: _vm.editData.clientCodes,
                    callback: function ($$v) {
                      _vm.$set(_vm.editData, "clientCodes", $$v)
                    },
                    expression: "editData.clientCodes",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "组织机构", prop: "orgId" } },
            [
              _c("treeselect", {
                attrs: {
                  multiple: false,
                  "flatten-search-results": true,
                  placeholder: "请选择组织...",
                  options: _vm.orgOptions,
                },
                model: {
                  value: _vm.editData.orgId,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "orgId", $$v)
                  },
                  expression: "editData.orgId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色描述", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.editData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "remark", $$v)
                  },
                  expression: "editData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "isEnable" } },
            [
              _c("el-switch", {
                attrs: { "active-text": "启用", "inactive-text": "禁用" },
                model: {
                  value: _vm.editData.isEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "isEnable", $$v)
                  },
                  expression: "editData.isEnable",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "align-right",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.dstate },
              on: {
                click: function ($event) {
                  return _vm.submitForm("editForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("editForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }