"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _checkIn = require("@/api/fake_expel/checkIn");
var _outstock = require("@/api/chanelmange/outstock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _qtck = _interopRequireDefault(require("./component/qtck"));
var _details_flow = _interopRequireDefault(require("./component/details_flow"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getItemByDataDictionaryByCode } from '@/api/systems/dictionary'
// var TypeDataComs = { orderStatus: [] }
var _default = {
  name: 'ChanelmangecheckManagedetail',
  components: {
    Pagination: _Pagination.default,
    Qtck: _qtck.default,
    detailsflow: _details_flow.default
  },
  filters: {
    typeClass: function typeClass(value) {
      if (value === '' || value === null) {
        return '';
      } else {
        var statusMap = {
          0: '企业',
          1: '经销商',
          2: '终端'
        };
        return statusMap[value];
      }
    }
  },
  data: function data() {
    return {
      cardPadding: {
        padding: '0 0 20px'
      },
      loading: false,
      ckShow: false,
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: '',
        tradeType: ''
      },
      productList: [],
      scanCodeVisible: false,
      codeData: [],
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      },
      total2: 0,
      no: '',
      orderId: ''
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.getDetailData(this.$route.query.id);
    } else {
      this.$router.replace({
        name: 'chanelmangecheckManageindex'
      });
    }
  },
  methods: {
    getflow: function getflow(row, qx) {
      this.$refs.flowMain.show(row, qx);
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this = this;
      this.loading = true;
      (0, _checkIn.jcPcDetail)({
        checkCode: id
      }).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          _this.productList = data.checkOrderProductOutInVo || [];
          _this.addData = data.checkOrderInfoVo;
        } else {
          _this.$message.error(res.data.msg);
        }
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 查看数码
    scanCode: function scanCode(row) {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetails(row);
    },
    codeClose: function codeClose() {
      this.scanCodeVisible = false;
      this.codeParam = {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10
      };
      this.codeData = [];
      this.total2 = 0;
    },
    getCodeDetails: function getCodeDetails(row) {
      var that = this;
      this.codeParam.id = row.outId;
      var param = JSON.parse(JSON.stringify(this.codeParam));
      delete param.productName;
      (0, _outstock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    searchReset: function searchReset() {
      // this.codeParam.id = ''
      this.codeParam.code = '';
      this.codeParam.productId = '';
      this.codeParam.productName = '';
      this.codeParam.isError = '';
      this.codeParam.pageNo = 1;
      this.getCodeDetails();
    },
    ckck: function ckck(row) {
      this.no = row.outNo;
      this.orderId = row.outId;
      this.ckShow = true;
    },
    ckClose: function ckClose() {
      this.no = '';
      this.orderId = '';
      this.ckShow = false;
    }
  }
};
exports.default = _default;