var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mod-prod-info" }, [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c("div", [
          _c(
            "div",
            { staticClass: "jf-wrap" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "padding-top": "30px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radio",
                      model: {
                        value: _vm.dataForm.rtype,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "rtype", $$v)
                        },
                        expression: "dataForm.rtype",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            display: "block",
                            "margin-bottom": "30px",
                          },
                          attrs: { label: "0" },
                        },
                        [_vm._v("不清零")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            display: "block",
                            "margin-bottom": "10px",
                          },
                          attrs: { label: "1" },
                        },
                        [_vm._v("自然年底清零上一年获得的积分")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "formTip" }, [
                        _vm._v(
                          "(例：2019年最后一天对2018年当年及以前获得的但未使用的积分进行清零)"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-click-modal": false,
                    title: "规则设置",
                    visible: _vm.jfDalog,
                    width: "60%",
                    center: "",
                    top: "20vh",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.jfDalog = $event
                    },
                    close: _vm.closeFun,
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { padding: "30px 0 0 60px" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio",
                          model: {
                            value: _vm.rtype,
                            callback: function ($$v) {
                              _vm.rtype = $$v
                            },
                            expression: "rtype",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                display: "block",
                                "margin-bottom": "30px",
                              },
                              attrs: { label: "0" },
                            },
                            [_vm._v("不清零")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                display: "block",
                                "margin-bottom": "10px",
                              },
                              attrs: { label: "1" },
                            },
                            [_vm._v("自然年底清零上一年获得的积分")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "formTip" }, [
                            _vm._v(
                              "(例：2019年最后一天对2018年当年及以前获得的但未使用的积分进行清零)"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer head-btn-group",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.jfDalog = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            loading: _vm.addBtnIs,
                            type: "primary",
                          },
                          on: { click: _vm.dataFormSubmit },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "explain-wrap" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mod-prod-info" },
              [
                _c(
                  "el-form",
                  { ref: "dataForm", attrs: { model: _vm.dataForm } },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("tiny-mce", {
                          ref: "content",
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.dataForm.rexplain,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "rexplain", $$v)
                            },
                            expression: "dataForm.rexplain",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "head-btn-group",
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "padding-right": "20px",
              },
            },
            [
              _vm.hasAuth("savebtn", _vm.apis)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.dataFormSubmit },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(2)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "formTitle head-btn-group" }, [
      _c("i", { staticClass: "el-icon-s-tools link" }),
      _vm._v(" 积分过期规则设置\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "formTitle head-btn-group" }, [
      _c("i", { staticClass: "el-icon-info link" }),
      _vm._v(" 积分规则说明\n        "),
      _c("span", { staticClass: "formTip" }, [_vm._v("(前端展示用)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }