"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _index = require("@/utils/index.js");
var _PieChart = _interopRequireDefault(require("./components/PieChart"));
var _BarChartLeft = _interopRequireDefault(require("./components/BarChartLeft"));
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _barChart = _interopRequireDefault(require("./components/barChart"));
var _analyze = require("@/api/wxhyyy/analyze.js");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PieChart: _PieChart.default,
    BarChartLeft: _BarChartLeft.default,
    lineChart: _lineChart.default,
    barChart: _barChart.default,
    admincut: _admincut.default
  },
  data: function data() {
    return {
      userTagList: [],
      // 用户标签表单
      userSourceList: {
        ADD_SCENE_SEARCH: '公众号搜索',
        ADD_SCENE_ACCOUNT_MIGRATION: '公众号迁移',
        ADD_SCENE_PROFILE_CARD: '名片分享',
        ADD_SCENE_QR_CODE: '扫描二维码',
        ADD_SCENE_PROFILE_LINK: '图文页内名称点击',
        ADD_SCENE_PROFILE_ITEM: '图文页右上角菜单',
        ADD_SCENE_PAID: '支付后关注',
        ADD_SCENE_OTHERS: '其他'
      },
      // 渠道二维码名字
      sourceDate: [],
      // 用户来源时间
      livelyDate: [],
      // 活跃用户时间
      livelyContrastDate: [],
      // 活跃用户对比时间
      cancelDate: [],
      // 取消关注用户时间
      cancelContrastDate: [],
      // 取消关注用户对比时间
      isLivelyDate: true,
      // 活跃用户对比时间是否显示
      isCancelDate: true,
      // 取消关注用户时间是否显示
      legendData: [],
      // 取消关注用户时间区间
      seriesData: [],
      // 取消关注用户数
      LineChartData: {
        legendData: [],
        seriesData: []
      },
      barChartData: {
        legendData: [],
        seriesData: []
      },
      List: {
        oneDays: '1天',
        tenDays: '10天内',
        twentyDays: '20天内',
        thirtyDays: '1个月内',
        ninetyDays: '3个月内',
        exceedNinetyDays: '超过三个月'
      },
      livelyContrastData: [],
      livelyContrastStr: '',
      cancelLegendData: '',
      cancelSeriesData: []
    };
  },
  watch: {
    sourceDate: function sourceDate(newValue, oldValue) {
      var _this = this;
      if (newValue === null) {
        return;
      } else if (newValue !== null && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        (0, _analyze.userSource)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.sourceDate[0],
          endTime: this.sourceDate[1]
        }).then(function (resp) {
          var code = resp.code,
            data = resp.data,
            msg = resp.msg;
          console.log(resp);
          if (code === 200) {
            if (data) {
              var userCodeSource = data.userCodeSource,
                userSourceTotal = data.userSourceTotal;
              userSourceTotal.forEach(function (item) {
                item.value = item.count | 0;
                item.name = _this.userSourceList[item.source] + '  ' + (item.count | 0) + '人';
              });
              _this.$refs.userSourcePie.legendData = [{
                show: true,
                orient: 'vertical',
                left: '60%',
                top: '40%'
              }];
              _this.$refs.userSourcePie.seriesData = [{
                type: 'pie',
                radius: '40%',
                center: ['30%', '50%'],
                data: userSourceTotal,
                label: {
                  normal: {
                    formatter: '{d}%'
                  }
                }
              }];
              _this.$refs.userSourcePie.initChart();
              var yAxisData = [];
              userCodeSource.sort(function (a, b) {
                return a.count - b.count;
              });
              userCodeSource.forEach(function (item) {
                item.value = item.count | 0;
                item.name = item.source;
                yAxisData.push(item.name);
              });
              _this.$refs.userSourceBar.yAxisData = yAxisData;
              _this.$refs.userSourceBar.seriesData = userCodeSource;
              _this.$refs.userSourceBar.initChart();
            }
          } else {
            _this.$message.error(msg);
          }
        });
      }
    },
    livelyDate: function livelyDate(newValue, oldValue) {
      var _this2 = this;
      if (newValue === null) {
        return;
      } else if (newValue !== null && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        (0, _analyze.userTimeFrameActive)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.livelyDate[0],
          endTime: this.livelyDate[1]
        }).then(function (resp) {
          var code = resp.code,
            data = resp.data,
            msg = resp.msg;
          console.log(resp);
          if (code === 200) {
            var arr = [];
            for (var i = 0; i < 24; i++) {
              arr.push(0);
            }
            if (data && data.length > 0) {
              data.forEach(function (item) {
                arr.splice(Number(item.timeFrame), 1, item.ave);
              });
            }
            if (_this2.isLivelyDate) {
              _this2.LineChartData.legendData = [_this2.livelyDate.join('至') + '平均活跃用户数'];
              _this2.LineChartData.seriesData = [arr];
            } else if (_this2.livelyContrastDate.length === 2) {
              console.log(369);
              _this2.LineChartData.legendData = [_this2.livelyDate.join('至') + '平均活跃用户数', _this2.livelyContrastDate.join('至') + '平均活跃用户数'];
              _this2.LineChartData.seriesData = [arr, _this2.livelyContrastData];
            } else if (!_this2.isLivelyDate) {
              console.log(258);
              _this2.LineChartData.legendData = [_this2.livelyDate.join('至') + '平均活跃用户数'];
              _this2.LineChartData.seriesData = [arr];
            }
          } else {
            _this2.$message.error(msg);
          }
        });
      }
    },
    livelyContrastDate: function livelyContrastDate(newValue, oldValue) {
      var _this3 = this;
      if (newValue !== null && newValue !== '') {
        (0, _analyze.userTimeFrameActive)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.livelyContrastDate[0],
          endTime: this.livelyContrastDate[1]
        }).then(function (resp) {
          console.log(resp);
          var code = resp.code,
            data = resp.data,
            msg = resp.msg;
          if (code === 200) {
            var arr = [];
            for (var i = 0; i < 24; i++) {
              arr.push(0);
            }
            if (data && data.length > 0) {
              data.forEach(function (item) {
                arr.splice(Number(item.timeFrame), 1, item.ave);
              });
            }
            if (_this3.livelyDate.length === 2) {
              if (_this3.LineChartData.seriesData.length >= 2) {
                _this3.LineChartData.seriesData.splice(1, 1, arr);
              } else {
                _this3.LineChartData.seriesData.push(arr);
              }
              if (_this3.LineChartData.legendData.length >= 2) {
                _this3.LineChartData.legendData.splice(1, 1, _this3.livelyContrastDate.join('至') + '平均活跃用户数');
              } else {
                _this3.LineChartData.legendData.push(_this3.livelyContrastDate.join('至') + '平均活跃用户数');
              }
            }
            _this3.livelyContrastStr = _this3.livelyContrastDate.join('至') + '平均活跃用户数';
            _this3.livelyContrastData = arr;
          } else {
            _this3.$message.error(msg);
          }
        });
      } else if (newValue === '') {
        this.LineChartData.seriesData.splice(1, 1);
        this.LineChartData.legendData.splice(1, 1);
      }
    },
    cancelDate: function cancelDate(newValue, oldValue) {
      var _this4 = this;
      if (newValue === null) {
        return;
      } else if (newValue !== null && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        (0, _analyze.userUnSubscribe)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.cancelDate[0],
          endTime: this.cancelDate[1]
        }).then(function (resp) {
          console.log(resp);
          var code = resp.code,
            data = resp.data,
            msg = resp.msg;
          if (code === 200) {
            var arr = [];
            if (data) {
              Object.keys(data).forEach(function (key, index) {
                var obj = {};
                obj.value = Number(data[key]);
                obj.name = _this4.List[key];
                arr.push(obj);
              });
              arr.reverse();
            } else {
              var _arr = [];
              Object.keys(data).forEach(function (key, index) {
                var obj = {};
                obj.value = Number(data[key]);
                obj.name = _this4.List[key];
                _arr.push(obj);
              });
              _arr.reverse();
            }
            if (_this4.isCancelDate) {
              _this4.barChartData.legendData = [_this4.cancelDate.join('至') + '取关用户数'];
              _this4.barChartData.seriesData = [arr];
            } else {
              _this4.barChartData.legendData = [_this4.cancelDate.join('至') + '取关用户数', _this4.cancelLegendData];
              _this4.barChartData.seriesData = [arr, _this4.cancelSeriesData];
            }
          } else {
            _this4.$message(msg);
          }
        });
      }
    },
    cancelContrastDate: function cancelContrastDate(newValue, oldValue) {
      var _this5 = this;
      if (newValue === null) {
        this.barChartData.legendData.splice(1, 1);
        this.barChartData.seriesData.splice(1, 1);
        return;
      } else if (newValue !== null) {
        (0, _analyze.userUnSubscribe)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.cancelDate[0],
          endTime: this.cancelDate[1]
        }).then(function (resp) {
          var code = resp.code,
            data = resp.data,
            msg = resp.msg;
          if (code === 200) {
            var arr = [];
            if (data) {
              Object.keys(data).forEach(function (key, index) {
                arr.push(Number(data[key]));
              });
            } else {
              Object.keys(data).forEach(function (key, index) {
                arr.push(Number(data[key]));
              });
            }
            if (_this5.barChartData.seriesData.length === 2) {
              _this5.barChartData.seriesData.splice(1, 1, arr);
            } else {
              _this5.barChartData.seriesData.push(arr);
            }
            _this5.barChartData.legendData = [_this5.cancelDate.join('至') + '取关用户数', _this5.cancelContrastDate.join('至') + '取关用户数'];
            _this5.cancelLegendData = _this5.cancelContrastDate.join('至') + '取关用户数';
            _this5.cancelSeriesData = arr;
          } else {
            _this5.$message(msg);
          }
        });
      }
    }
  },
  mounted: function mounted() {
    var _this6 = this;
    this.sourceDate = this.getDate(30);
    this.livelyDate = this.getDate(30);
    this.cancelDate = this.getDate(30);
    (0, _analyze.userTag)({
      appId: sessionStorage.getItem('appId')
    }).then(function (resp) {
      var code = resp.code,
        data = resp.data,
        msg = resp.msg;
      console.log(resp);
      if (code === 200) {
        if (data) {
          data.forEach(function (item) {
            item.value = item.count | 0;
            item.name = item.tagName;
          });
          _this6.userTagList = data;
          _this6.$refs.userTag.legendData = [{
            show: false
          }];
          _this6.$refs.userTag.seriesData = [{
            type: 'pie',
            center: ['50%', '50%'],
            data: data,
            label: {
              normal: {
                formatter: '{b}'
              }
            }
          }];
          _this6.$refs.userTag.initChart();
        }
      } else {
        _this6.$message.error(msg);
      }
    });
  },
  beforeMount: function beforeMount() {},
  methods: {
    latelyFun: function latelyFun(type, date) {
      if (type === 'source') {
        this.sourceDate = this.getDate(date);
      } else if (type === 'lively') {
        this.livelyDate = this.getDate(date);
      } else if (type === 'cancel') {
        this.cancelDate = this.getDate(date);
      }
    },
    isContrastFun: function isContrastFun(e, type) {
      if (e) {
        if (type === 'lively') {
          this.isLivelyDate = false;
        } else if (type === 'cancel') {
          this.isCancelDate = false;
        }
      } else {
        if (type === 'lively') {
          this.isLivelyDate = true;
          this.livelyContrastDate = '';
        } else if (type === 'cancel') {
          this.isCancelDate = true;
          this.cancelContrastDate = null;
        }
      }
    },
    getDate: function getDate(val) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      var arr = [(0, _index.parseTime)(start, '{y}-{m}-{d}'), (0, _index.parseTime)(end, '{y}-{m}-{d}')];
      return arr;
    },
    getUserSource: function getUserSource() {}
  }
};
exports.default = _default;