"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _interface = require("@/api/systems/interface");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SysteminterfaceConfigtextmessageindex',
  data: function data() {
    return {
      tabPosition: 1,
      yxloading: false,
      dxloading: false,
      mloading: false,
      detailVisible: false,
      dialogVisible: false,
      dialogVisible1: false,
      yxData: {},
      dxData: {
        accessKeyId: '',
        accessKeySecret: '',
        companyName: '',
        pid: '',
        serviceProviderType: '',
        smsSign: '',
        token: '',
        onOff: ''
      },
      scanData: {
        consigneeAddress: '',
        content: '',
        outsideCode: '',
        title: ''
      },
      title: '发送测试',
      yxbtnLoading: false,
      dxbtnLoading: false,
      yxtestbtnLoading: false,
      dxtestbtnLoading: false,
      yxRules: {
        transportProtocol: [{
          required: true,
          message: '服务器类型不能为空，请维护',
          trigger: 'change'
        }],
        mailbox: [{
          required: true,
          message: '邮箱地址不能为空，请维护',
          trigger: 'change'
        }, {
          type: 'email',
          message: '电子邮箱格式不正确',
          trigger: ['blur', 'change']
        }],
        mailboxPwd: [{
          required: true,
          message: '密码不能为空，请维护',
          trigger: 'change'
        }],
        serverAddress: [{
          required: true,
          message: '服务器不能为空，请维护',
          trigger: 'change'
        }]
      },
      dxRules: {
        serviceProviderType: [{
          required: true,
          message: '短信平台不能为空，请维护',
          trigger: 'change'
        }],
        companyName: [{
          required: true,
          message: '企业名称不能为空，请维护',
          trigger: 'change'
        }],
        accessKeyId: [{
          required: true,
          message: 'AccessKey不能为空，请维护',
          trigger: 'change'
        }],
        accessKeySecret: [{
          required: true,
          message: 'SecretKey不能为空，请维护',
          trigger: 'change'
        }],
        smsSign: [{
          required: true,
          message: '短信签名不能为空，请维护',
          trigger: 'change'
        }]
      },
      yxTestRules: {
        consigneeAddress: [{
          required: true,
          message: '收件地址不能为空，请维护',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '标题不能为空，请维护',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '内容不能为空，请维护',
          trigger: 'change'
        }]
      },
      dxTestRules: {
        consigneeAddress: [{
          required: true,
          message: '接收手机号不能为空，请维护',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '消息模板不能为空，请维护',
          trigger: 'change'
        }]
      },
      typeList: [],
      cardStyle: {
        padding: '12px 20px'
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    submitForm: function submitForm() {
      this.list = [];
      this.total = 0;
      this.listQuery.current = 1;
      this.getList();
    },
    getList: function getList() {
      this.list = [];
      this.total = 0;
      //   this.getyx()
      this.getdx();
      this.gettemp();
    },
    getyx: function getyx() {
      var _this = this;
      this.yxloading = true;
      (0, _interface.mailDeploydetail)().then(function (res) {
        var data = res.data;
        _this.yxloading = false;
        if (data.code == 200) {
          if (data.data != null) {
            _this.yxData = data.data;
            _this.yxData.secureConnection = data.data.secureConnection == 1;
            var arr = data.data.serverAddress.split(':');
            _this.yxData.serverAddress = arr[0];
            _this.yxData.port = arr[1] || '';
          }
        } else {
          _this.$message.error(data.msg);
        }
      }).catch(function () {
        _this.yxloading = false;
      });
    },
    getdx: function getdx() {
      var _this2 = this;
      this.dxloading = true;
      (0, _interface.smsDeploydetail)().then(function (res) {
        var data = res.data;
        _this2.dxloading = false;
        if (data.code == 200) {
          if (data.data != null) {
            var obj = {
              accessKeyId: data.data.accessKeyId,
              accessKeySecret: data.data.accessKeySecret,
              companyName: data.data.companyName,
              pid: data.data.pid,
              serviceProviderType: data.data.serviceProviderType * 1 || '',
              smsSign: data.data.smsSign,
              token: data.data.token,
              onOff: data.data.onOff || '0'
            };
            _this2.dxData = Object.assign(_this2.dxData, obj);
          }
        } else {
          _this2.$message.error(data.msg);
        }
      }).catch(function () {
        _this2.dxloading = false;
      });
    },
    gettemp: function gettemp() {
      var _this3 = this;
      (0, _interface.getTemplateList)().then(function (res) {
        var data = res.data;
        if (data.code == 200) {
          _this3.typeList = data.data;
        } else {
          _this3.$message.error(data.msg);
        }
      });
    },
    yxChange: function yxChange(val) {
      this.yxData.serverAddress = '';
      this.yxData.port = '';
      this.yxData.secureConnection = false;
    },
    dxChange: function dxChange(val) {
      this.yxData.serverAddress = '';
      this.yxData.secureConnection = false;
    },
    submit: function submit(type) {
      var _this4 = this;
      if (type == 'yx') {
        this.$refs.yxData.validate(function (valid) {
          if (valid) {
            _this4.yxbtnLoading = true;
            var param = JSON.parse(JSON.stringify(_this4.yxData));
            param.secureConnection = _this4.yxData.secureConnection ? 1 : 0;
            if (_this4.yxData.port) {
              param.serverAddress = _this4.yxData.this.yxData.serverAddress = '' + ':' + _this4.yxData.port;
            }
            delete param.port;
            (0, _interface.mailDeploysubmit)(param).then(function (res) {
              _this4.yxbtnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this4.$message.success('提交成功!');
              } else {
                _this4.$message.error(data.msg);
              }
            }).catch(function () {
              _this4.yxbtnLoading = false;
            });
          } else {
            _this4.$message.error('请完善信息');
          }
        });
      } else {
        this.$refs.dxData.validate(function (valid) {
          if (valid) {
            _this4.dxbtnLoading = true;
            var param = JSON.parse(JSON.stringify(_this4.dxData));
            delete param.templateContent;
            delete param.templateName;
            (0, _interface.smsDeploySubmit)(param).then(function (res) {
              _this4.dxbtnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this4.$message.success('提交成功!');
                _this4.getList();
              } else {
                _this4.$message.error(data.msg);
              }
            }).catch(function () {
              _this4.dxbtnLoading = false;
            });
          } else {
            _this4.$message.error('请完善信息');
          }
        });
      }
    },
    clearScan: function clearScan() {
      this.scanData = {
        consigneeAddress: '',
        content: '',
        outsideCode: '',
        title: ''
      };
    },
    yxtest: function yxtest() {
      this.dialogVisible = true;
    },
    dxtest: function dxtest() {
      this.dialogVisible1 = true;
    },
    yxclose: function yxclose() {
      this.clearScan();
      this.$refs.yxTest.resetFields();
      this.dialogVisible = false;
    },
    dxclose: function dxclose() {
      this.clearScan();
      this.$refs.dxTest.resetFields();
      this.dialogVisible1 = false;
    },
    yxsub: function yxsub() {
      var _this5 = this;
      this.$refs.yxTest.validate(function (valid) {
        if (valid) {
          _this5.yxtestbtnLoading = true;
          (0, _interface.mailDeploysend)(_this5.scanData).then(function (res) {
            _this5.yxtestbtnLoading = false;
            var data = res.data;
            console.log(data, 'data');
            if (data.code == 200) {
              _this5.$message.success('发送成功!');
              _this5.yxclose();
            } else {
              _this5.$message.error(data.msg);
            }
          }).catch(function () {
            _this5.yxtestbtnLoading = false;
          });
        } else {
          _this5.$message.error('请完善信息');
        }
      });
    },
    typechange: function typechange(val) {
      this.scanData.content = val.templetContent;
      this.scanData.outsideCode = val.outsideCode;
    },
    dxsub: function dxsub() {
      var _this6 = this;
      this.$refs.dxTest.validate(function (valid) {
        if (valid) {
          _this6.dxtestbtnLoading = true;
          var param = JSON.parse(JSON.stringify(_this6.scanData));
          delete param.title;
          param.content = '';
          (0, _interface.smsDeploysend)(param).then(function (res) {
            _this6.dxtestbtnLoading = false;
            var data = res.data;
            console.log(data, 'data');
            if (data.code == 200) {
              _this6.$message.success('发送成功!');
              _this6.dxclose();
            } else {
              _this6.$message.error(data.msg);
            }
          }).catch(function () {
            _this6.dxtestbtnLoading = false;
          });
        } else {
          _this6.$message.error('请完善信息');
        }
      });
    }
  }
};
exports.default = _default;