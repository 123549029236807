var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("计量单位")]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "search-condition" }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("div", { staticClass: "phead" }, [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-plus" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: _vm.tabHeight,
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "120",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "unitName",
                    label: "计量单位",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "200",
                    align: _vm.tableConfig.align,
                    fixed: "right",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.del(row.pId)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.page2,
              expression: "page2",
            },
          ],
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "计量单位", prop: "unitName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", value: _vm.ruleForm.unitName },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.unitName = _vm.validTe(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }