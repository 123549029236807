"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _errorCodeCompatibilityInfo = require("@/api/operationalTools2/errorCodeCompatibilityInfo2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolswrongNumberindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolswrongNumberindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        printFactory: '',
        batchNo: '',
        errCode: ''
      },
      params: {
        pageNo: 1,
        pageSize: 10
      },
      // endTime: null,
      // operator: null,
      // startTime: null
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      // 导出按钮加载
      downLoading: false,
      // 批量导入
      mloading: false,
      uploadVisible: false,
      uploadForm: {
        uploadFile: ''
      },
      uploadRules: {},
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      imgList: [],
      fileList: [],
      uploadData: [],
      //

      dialogVisible: false,
      ruleForm: {
        errorCode: ''
      },
      rules: {
        errorCode: [{
          required: true,
          message: '请输入数码',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + process.env.VUE_APP_VERSION2 + '/zdConfig/errorCodeCompatibilityInfo/importCodeList';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.errorCodeListPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取错误数码兼容列表
    errorCodeListPage: function errorCodeListPage() {
      var that = this;
      that.listLoading = true;
      (0, _errorCodeCompatibilityInfo.errorCodeListPage)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function (err) {
        that.listLoading = false;
      });
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.errorCodeListPage();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.params.pageNo = 1;
      this.$refs[formName].resetFields();
      this.errorCodeListPage();
    },
    // 添加
    addCode: function addCode() {
      this.dialogVisible = true;
    },
    // 确定
    confirm: function confirm() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _errorCodeCompatibilityInfo.addOneCode)(_this.ruleForm).then(function (res) {
            console.log(res.data);
            if (res.data.data.errCode) {
              _this.$message.error(res.data.data.errMsg);
              return;
            } else {
              _this.$message.success('添加数码成功');
              _this.dialogVisible = false;
              _this.errorCodeListPage();
            }
            _this.$refs['ruleForm'].resetFields();
          }).catch(function (err) {
            _this.dialogVisible = false;
          });
        }
      });
    },
    // 关闭
    cancel: function cancel() {
      this.$refs['ruleForm'].resetFields();
      this.dialogVisible = false;
    },
    // 导出
    download: function download() {
      var _this2 = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      // console.log(submitData);
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _errorCodeCompatibilityInfo.postErrorCodeExport)(submitData).then(function (res) {
        _this2.downLoading = false;
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '错误数码兼容.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this2.downLoading = false;
      });
    },
    // 导入
    uploadShow: function uploadShow() {
      this.uploadForm.uploadFile = '';
      this.uploadVisible = true;
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.$refs.upload.clearFiles();
      this.uploadVisible = false;
    },
    selectFile: function selectFile(file, fileList) {
      this.uploadForm.uploadFile = file.name;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.imgList = fileList;
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      var that = this;
      if (res.data.errCode == 500) {
        this.$message.error(res.data.errMsg);
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        console.log(res.data);
        if (res.data) {
          res.data.filter(function (item) {
            setTimeout(function () {
              that.$message({
                type: 'error',
                message: "".concat(item.errorMsg)
              });
            }, 1 * 800);
          });
        } else {
          this.$message({
            type: 'success',
            message: '导入成功!'
          });
        }
        this.mloading = false;
      }
      this.mloading = false;
      this.uploadClose();
      this.errorCodeListPage();
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    beforeAvatarUpload: function beforeAvatarUpload(file, fileList) {
      // const file = param.file
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.2;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 PNG/JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 200kb!');
      }
      // if ((isJPG || isPNG) && isLt2M) {
      //   const formData = new FormData()
      //   formData.append('file', file)
      //   imgupload(formData).then(response => {
      //     var data = response.data
      //     if (data.code != 200) {
      //       this.$message.error(data.msg)
      //     } else {
      //       this.imgList.push({ url: data.data })
      //     }
      //   })
      // }
      return (isJPG || isPNG) && isLt2M;
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    submitUpload: function submitUpload() {
      if (!this.uploadForm.uploadFile) {
        this.$message.error('请导入文件');
        return;
      } else {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      }
    }
  }
};
exports.default = _default;