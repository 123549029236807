var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1000px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("返利方案名称:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.rebateSchemeName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动ID:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.id)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("状态:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm.temp.activityStatus == "0"
                    ? _c("el-tag", [_vm._v("未开始")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.temp.activityStatus == "1"
                    ? _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v("进行中"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.temp.activityStatus == "2"
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("已结束"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.temp.activityStatus == "3"
                    ? _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v("停用"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动对象类型:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [_vm._v("终端店主")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [
                _vm._v("消费者触发节点:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.consumerNode == 1 ? "袋/盒外码" : "")),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("资金来源:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.financeSourceName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("关联方案:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.schemeName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("布奖方式:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("activityDimension")(_vm.temp.activityDimension)
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("返利时间:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("p", { staticClass: "text2" }, [
                  _vm._v(_vm._s(_vm.temp.startTime)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text2" }, [
                  _vm._v(_vm._s(_vm.temp.endTime)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [
                _vm._v("参与活动产品数量:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.productNum)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [
                _vm._v("参与经销商数量:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.dealerNum)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动创建时间:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("textDate")(_vm.temp.createTime))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("红包返现:")]),
              _vm._v(" "),
              _vm.temp.awardConfig
                ? _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.temp.awardConfig.awardEntity
                          ? _vm.temp.awardConfig.awardEntity
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动规则:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.rebateExplain)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动备注:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.rebateRemark)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _c("span", [_vm._v("布奖范围")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [
          _vm._v(
            "活动SKU:" +
              _vm._s(_vm.temp.activitySKU ? _vm.temp.activitySKU : "0") +
              "个"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _vm.temp.activityDimension == 0
            ? _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "el-load-more-table",
                            rawName: "v-el-load-more-table",
                            value: { data: _vm.temp.productClassList },
                            expression: "{data: temp.productClassList}",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.temp.productClassList,
                          height: "195px",
                        },
                        on: {
                          "update:data": function ($event) {
                            return _vm.$set(
                              _vm.temp,
                              "productClassList",
                              $event
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "参与范围", align: "center" } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      type: "flex",
                                      align: "middle",
                                      justify: "space-between",
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("参与范围")]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-download down-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downFile(1)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品分类编码",
                                align: _vm.tableConfig.align,
                                prop: "productClassCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品分类名称",
                                align: _vm.tableConfig.align,
                                prop: "productClassName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.temp.activityDimension == 0 && _vm.temp.isOpenNotJoinProduct == 0
            ? _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "el-load-more-table",
                            rawName: "v-el-load-more-table",
                            value: { data: _vm.temp.productBlackList },
                            expression: "{data: temp.productBlackList}",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.temp.productBlackList,
                          height: "195px",
                        },
                        on: {
                          "update:data": function ($event) {
                            return _vm.$set(
                              _vm.temp,
                              "productBlackList",
                              $event
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "以下产品禁止参与",
                              align: "center",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      type: "flex",
                                      align: "middle",
                                      justify: "space-between",
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("以下产品禁止参与")]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-download down-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downFile(2)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品编码",
                                align: _vm.tableConfig.align,
                                prop: "productCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品名称",
                                align: _vm.tableConfig.align,
                                prop: "productName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.temp.activityDimension == 1
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "el-load-more-table",
                            rawName: "v-el-load-more-table",
                            value: { data: _vm.temp.orderList },
                            expression: "{data: temp.orderList}",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.temp.orderList, height: "195px" },
                        on: {
                          "update:data": function ($event) {
                            return _vm.$set(_vm.temp, "orderList", $event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "参与范围", align: "center" } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      type: "flex",
                                      align: "middle",
                                      justify: "space-between",
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("参与范围")]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-download down-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downFile(5)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "单据编号",
                                align: _vm.tableConfig.align,
                                prop: "orderCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "发货单位",
                                align: _vm.tableConfig.align,
                                prop: "seller",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "收货单位",
                                align: _vm.tableConfig.align,
                                prop: "buyer",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "发货时间",
                                align: _vm.tableConfig.align,
                                prop: "sellTime",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.temp.activityDimension == 1 && _vm.temp.isOpenJoinProduct == 0
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "el-load-more-table",
                            rawName: "v-el-load-more-table",
                            value: { data: _vm.temp.orderProductList },
                            expression: "{data: temp.orderProductList}",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.temp.orderProductList,
                          height: "195px",
                        },
                        on: {
                          "update:data": function ($event) {
                            return _vm.$set(
                              _vm.temp,
                              "orderProductList",
                              $event
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "产品范围", align: "center" } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      type: "flex",
                                      align: "middle",
                                      justify: "space-between",
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("产品范围")]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-download down-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downFile(2)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品所属分类编码",
                                align: _vm.tableConfig.align,
                                prop: "productClassCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品所属分类名称",
                                align: _vm.tableConfig.align,
                                prop: "productClassName",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品编码",
                                align: _vm.tableConfig.align,
                                prop: "productCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "产品名称",
                                align: _vm.tableConfig.align,
                                prop: "productName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.temp.activityDimension == 0
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产品时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("timeControlType")(_vm.temp.timeControlType)
                      ) +
                        _vm._s(_vm.temp.timeControlStartTime) +
                        "-" +
                        _vm._s(_vm.temp.timeControlEndTime)
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _c("span", [_vm._v("终端范围")]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [
          _vm._v(
            "活动终端共计:" +
              _vm._s(_vm.temp.storeNum ? _vm.temp.storeNum : "0") +
              "个"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("终端类型:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.temp.rebatePeopleList, function (item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.targetUserCode,
                      attrs: { disabled: "", checked: "" },
                    },
                    [_vm._v(_vm._s(item.targetUserName))]
                  )
                }),
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.temp.activityDimension == 0
            ? _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "el-load-more-table",
                            rawName: "v-el-load-more-table",
                            value: { data: _vm.temp.dealerList },
                            expression: "{data: temp.dealerList}",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.temp.dealerList, height: "195px" },
                        on: {
                          "update:data": function ($event) {
                            return _vm.$set(_vm.temp, "dealerList", $event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-table-column",
                          { attrs: { label: "参与范围", align: "center" } },
                          [
                            _c(
                              "template",
                              { slot: "header" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      type: "flex",
                                      align: "middle",
                                      justify: "space-between",
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("参与范围")]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-download down-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downFile(3)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "经销商编码",
                                align: _vm.tableConfig.align,
                                prop: "channelDealerCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "所属经销商名称",
                                align: _vm.tableConfig.align,
                                prop: "channelDealerName",
                              },
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.temp.isOpenNotJoinTerminal == 0
            ? _c(
                "el-col",
                { attrs: { span: _vm.temp.activityDimension == 0 ? 12 : 24 } },
                [
                  _c(
                    "div",
                    { staticClass: "vue-from-block" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "el-load-more-table",
                              rawName: "v-el-load-more-table",
                              value: { data: _vm.temp.storeBlackList },
                              expression: "{data: temp.storeBlackList}",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.temp.storeBlackList,
                            height: "195px",
                          },
                          on: {
                            "update:data": function ($event) {
                              return _vm.$set(
                                _vm.temp,
                                "storeBlackList",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "以下终端禁止参与",
                                align: "center",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "header" },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        type: "flex",
                                        align: "middle",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c("span"),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("以下终端禁止参与")]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-download down-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downFile(4)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "80",
                                  label: "序号",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  label: "终端编码",
                                  align: _vm.tableConfig.align,
                                  prop: "channelStoreCode",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  label: "终端名称",
                                  align: _vm.tableConfig.align,
                                  prop: "channelStoreName",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("活动奖品")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("奖励类型:")]),
              _vm._v(" "),
              _vm.temp.awardConfig
                ? _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.temp.awardConfig.awardType == "1" ? "红包" : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("奖励数量:")]),
              _vm._v(" "),
              _vm.temp.awardConfig
                ? _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.temp.awardConfig.awardEntity
                          ? _vm.temp.awardConfig.awardEntity
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("活动实际数据")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动开始天数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.activityStartTime)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("实际扫码数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(
                    _vm.temp.actualScanCodeNum
                      ? _vm.temp.actualScanCodeNum
                      : "0"
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("奖励总数量:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.awardNum ? _vm.temp.awardNum : "0")),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "vue-from-block" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.temp.practicalParams, height: "195px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "80",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "产品编码",
                        align: _vm.tableConfig.align,
                        prop: "productCode",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "产品名称",
                        align: _vm.tableConfig.align,
                        prop: "productName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "扫码数量",
                        align: _vm.tableConfig.align,
                        prop: "scanNum",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "奖励金额",
                        align: _vm.tableConfig.align,
                        prop: "bonus",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      false ? _c("div", { staticClass: "middleLine" }) : _vm._e(),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "itemTitle" }, [
            _vm._v(_vm._s(_vm.detailShow ? "流程" : "活动审批")),
          ])
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            [
              _vm.detailShow
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-timeline",
                            { attrs: { reverse: _vm.reverse } },
                            _vm._l(_vm.activities, function (activity, index) {
                              return _c(
                                "el-timeline-item",
                                { key: index },
                                [
                                  _c("el-card", { staticClass: "card" }, [
                                    _c("h4", [
                                      _vm._v(
                                        "【审批】" + _vm._s(activity.content)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "王小虎 " + _vm._s(activity.timestamp)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v("审批通过")]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("附件:"),
                                      _c("a", [_vm._v("活动.excel")]),
                                      _c("i", {
                                        staticClass: "el-icon-download",
                                      }),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "ruleForm",
                              attrs: {
                                model: _vm.ruleForm,
                                rules: _vm.rules,
                                "label-width": "100px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "审批结果:", prop: "region" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择活动区域" },
                                      model: {
                                        value: _vm.ruleForm.region,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "region", $$v)
                                        },
                                        expression: "ruleForm.region",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "区域一",
                                          value: "shanghai",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "区域二",
                                          value: "beijing",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注说明:", prop: "desc" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "200",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.desc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "desc", $$v)
                                      },
                                      expression: "ruleForm.desc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }