"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capDelete = capDelete;
exports.capInsert = capInsert;
exports.capUpdate = capUpdate;
exports.fetchList = fetchList;
exports.getSupplierByUser = getSupplierByUser;
var _request = _interopRequireDefault(require("@/utils/request"));
function getSupplierByUser() {
  return (0, _request.default)({
    url: '/api-user/supplierUser/getSupplierByUser',
    method: 'get'
  });
}
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/capNumSearch',
    method: 'get',
    params: params
  });
}
function capInsert(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/capInsert',
    method: 'post',
    params: params
  });
}
function capUpdate(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/capUpdate',
    method: 'post',
    params: params
  });
}
function capDelete(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/capDelete',
    method: 'delete',
    params: params
  });
}