"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _unlockPrizeRule = require("@/api/terminal/unlockPrizeRule");
var _creat = _interopRequireDefault(require("./components/creat"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = "marketingManageexternalGamesConfiggameLogindex";
var _default = {
  components: {
    Pagination: _Pagination.default,
    creat: _creat.default
  },
  name: "marketingManageexternalGamesConfiggameLogindex",
  filters: {
    filterButtonStatus: function filterButtonStatus(status) {
      return status === "1" ? "停用" : "启用";
    },
    filterUnlockType: function filterUnlockType(status) {
      return status === "1" ? "按次布奖" : "倍数布奖";
    }
  },
  data: function data() {
    return {
      handleShow: false,
      editType: "",
      creatVisible: false,
      ruleCode: "",
      searchForm: {
        pageNo: 1,
        pageSize: 20
      },
      rangeTime: "",
      total: 0,
      tableData: [],
      listLoading: false
    };
  },
  activated: function activated() {
    // var hasCache1 = this.tableData.length > 0 ? true : false
    // if (!hasCache1) {
    //   this.queryForPage();
    // }
    this.queryForPage();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    //获取表格
    queryForPage: function queryForPage() {
      var _this = this;
      this.listLoading = true;
      (0, _unlockPrizeRule.getUnlockPrzeRuleList)(this.searchForm).then(function (res) {
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this.listLoading = false;
          _this.tableData = res.data.data.records;
          _this.total = res.data.data.total;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 创建解锁奖品规则
    creat: function creat() {
      this.editType = "add";
      this.creatVisible = true;
    },
    // 编辑解锁奖品规则
    editUnlockPrize: function editUnlockPrize(row) {
      this.editType = "edit";
      this.ruleCode = row.unlockRuleCode;
      this.creatVisible = true;
    },
    // 打开关联奖品
    relationActivity: function relationActivity(row) {
      // if(!Number(row.relationActivityNum)) {
      //   this.$message.error('该项规则不存在关联活动')
      //   return
      // }
      this.ruleCode = row.unlockRuleCode;
    },
    // 关闭解锁奖品规则
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.queryForPage();
    },
    onOrOffOperation: function onOrOffOperation(data) {
      var _this2 = this;
      var isOn = data.status === "1" ? "停用" : "启用";
      var isOnFlag = data.status === "1" ? "2" : "1";
      this.$confirm("\u662F\u5426".concat(isOn, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.isOperateUnlockPrzeRule({
          code: data.unlockRuleCode,
          flag: isOnFlag
        }, isOn);
      }).catch(function (err) {
        console.log(err);
      });
    },
    isOperateUnlockPrzeRule: function isOperateUnlockPrzeRule(data, isOn) {
      var _this3 = this;
      if (isOn === "启用") {
        this.onOrOffUnlockPrzeRule(data, isOn);
        return;
      }
      (0, _unlockPrizeRule.getActCountByRuleCode)(data).then(function (res) {
        if (res.data.code == 200) {
          var actCount = Number(res.data.data);
          if (actCount !== 0) {
            var strMessage = "\u8BE5\u89C4\u5219\u5DF2\u7ECF\u5173\u8054".concat(actCount, "\u672A\u8FC7\u671F\u7684\u4E2A\u6D3B\u52A8\uFF0C\u4ECD\u8981\u505C\u7528\u8BF7\u5148\u53D6\u6D88\u5173\u8054");
            _this3.$confirm(strMessage, {
              confirmButtonText: "确定",
              showCancelButton: false
            });
            return;
          }
          _this3.onOrOffUnlockPrzeRule(data, isOn);
        } else {
          _this3.$message.error(res.data.msg);
          return;
        }
      });
    },
    onOrOffUnlockPrzeRule: function onOrOffUnlockPrzeRule(data, isOn) {
      var _this4 = this;
      (0, _unlockPrizeRule.onOrOffUnlockPrzeRule)(data).then(function (res) {
        if (res.data.code == 200) {
          _this4.queryForPage();
          _this4.$message.success("".concat(isOn, "\u6210\u529F"));
        } else {
          _this4.$message.error(res.data.msg);
          return;
        }
      });
    },
    //时间选择
    handleTimeChange: function handleTimeChange(res) {
      if (res) {
        this.searchForm.startTime = res[0];
        this.searchForm.endTime = res[1];
      } else {
        delete this.searchForm.startTime;
        delete this.searchForm.endTime;
      }
    },
    //搜索
    search: function search() {
      this.searchForm.pageNo = 1;
      this.queryForPage();
    },
    //重置
    reset: function reset() {
      this.$refs["searchForm"].resetFields();
      this.rangeTime = "";
      this.searchForm = {
        pageNo: 1,
        pageSize: 20
      };
      this.queryForPage();
    }
  }
};
exports.default = _default;