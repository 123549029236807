var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-analyze-wrap" }, [
    _c(
      "div",
      { staticClass: "user-analyze-content", staticStyle: { padding: "0" } },
      [
        _c("div", { staticClass: "hyyy-top-tit" }, [
          _c(
            "div",
            { staticClass: "hyyy-top-tit-lift" },
            [
              _c("span", {
                staticStyle: { "font-weight": "400", color: "#333" },
                domProps: { textContent: _vm._s(_vm.$route.meta.title) },
              }),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    transition: "zoom-in-top",
                    width: "400",
                    trigger: "hover",
                  },
                },
                [
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        "\n              粉丝总数：在时间范围内的粉丝总数，比如2019年9月20号到2019年9月25号时间范围内的粉丝数则为截止到9月25号的粉丝数。\n              新关注粉丝数：在时间范围内的每天新增的粉丝数之和。\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "取消关注粉丝数：在时间范围内的每天取消关注的粉丝数之和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "净增长粉丝数：在时间范围内的增加的粉丝数之和减去取消关注的粉丝数。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "活跃用户数：在时间范围内进入过该公众号的用户总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("活跃率：在时间范围内活跃用户数除去粉丝总数。"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "互动次数：在一定时间范围内所有用户的互动的次数总和。"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-question",
                    staticStyle: { "font-size": "20px", color: "#e6a23c" },
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hyyy-top-tit-right" },
            [_c("admincut", { staticStyle: { "margin-top": "7px" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("el-divider", { staticStyle: { margin: "0" } }),
        _vm._v(" "),
        _c("div", { staticClass: "user-analyze-top" }, [
          _c(
            "div",
            {
              staticClass: "user-analyze-nav",
              staticStyle: { "font-size": "17px", "margin-top": "10px" },
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/wxgzh/dataAlysis/yhfx" } },
                [_vm._v("用户分析")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/wxgzh/dataAlysis/yhData" } },
                [_vm._v("用户数据")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "100%", padding: "20px" } },
          [
            _c("div", { staticClass: "count-wrap" }, [
              _c("div", { staticClass: "time-wrap" }, [
                _vm.isFansContrastDate
                  ? _c(
                      "div",
                      { staticClass: "time-top" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "-",
                            size: "small",
                            "value-format": "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.fansDate,
                            callback: function ($$v) {
                              _vm.fansDate = $$v
                            },
                            expression: "fansDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: { click: _vm.latelyFun },
                          },
                          [_vm._v("最近30天")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "time-bottom" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            size: "small",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.fansDate,
                            callback: function ($$v) {
                              _vm.fansDate = $$v
                            },
                            expression: "fansDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("  —  ")]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            size: "small",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.fansContrastDate,
                            callback: function ($$v) {
                              _vm.fansContrastDate = $$v
                            },
                            expression: "fansContrastDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.isFansContrastFun(false)
                              },
                            },
                          },
                          [_vm._v("取消对比")]
                        ),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _vm.isFansContrastDate
                ? _c("div", { staticClass: "count-list" }, [
                    _c("ul", [
                      _c("li", [
                        _c("span", [_vm._v("粉丝总数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.fansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("新关注粉丝数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.newFansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("取消关注粉丝数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.cancelFansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("净增长粉丝数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.totalObj.netIncreaseFansCount || 0)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("活跃用户数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.activeUser || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("活跃率")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.activeRate || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("互动次数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.enableCount || 0)),
                        ]),
                      ]),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "count-table" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.totalList, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "日期",
                              width: "200",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "fansCount", label: "粉丝总数" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "newFansCount",
                              label: "新关注粉丝数",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cancelFansCount",
                              label: "取消关注粉丝数",
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "netIncreaseFansCount",
                              label: "净增粉丝数",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "activeUser", label: "活跃用户数" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "activeRate", label: "活跃率" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "enableCount", label: "互动次数" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line-chart-wrap" }, [
              _c(
                "ul",
                { staticClass: "line-chart-btns clearfix" },
                _vm._l(_vm.lineList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { active: _vm.btnClass === item.id },
                      on: {
                        click: function ($event) {
                          return _vm.linefun(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "line-chart" },
                [
                  _c("Line-chart", {
                    attrs: { "chart-data": _vm.lineChartData },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "down-table-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "fans-down" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "value-format": "yyyy-MM-dd",
                        "end-placeholder": "结束日期",
                        size: "small",
                      },
                      model: {
                        value: _vm.fansTableDate,
                        callback: function ($$v) {
                          _vm.fansTableDate = $$v
                        },
                        expression: "fansTableDate",
                      },
                    }),
                    _vm._v(" "),
                    _vm.records.length > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.downLoad },
                          },
                          [_vm._v("下载明细")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.userDataDownFun },
                          },
                          [_vm._v("下载明细")]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.records },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "日期" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("filterTime")(scope.row.createDate)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "fansCount", label: "粉丝总数" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "newFansCount", label: "新关注粉丝数" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelFansCount",
                        label: "取消关注粉丝数",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "netIncreaseFansCount",
                        label: "净增粉丝数",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "activeUser", label: "活跃用户数" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "activeRate", label: "活跃率" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "enableCount", label: "互动次数" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.records.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pages-wrap" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.current,
                            "page-sizes": _vm.pageSizes,
                            "page-size": _vm.pageSize,
                            layout: "prev, pager, next,sizes, total,jumper",
                            total: _vm.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }