var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-col",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.submitForm },
                      model: {
                        value: _vm.tabPosition,
                        callback: function ($$v) {
                          _vm.tabPosition = $$v
                        },
                        expression: "tabPosition",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("消息"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("公告"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "head-btn-group fun-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.addBtn.icon,
                        loading: _vm.btnLoading,
                      },
                      on: { click: _vm.checkAll },
                    },
                    [_vm._v("全部标记已读")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "标题内容",
                  prop: "loginAccount",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                  "min-width": "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "titleWrap" }, [
                          (
                            _vm.tabPosition == 1
                              ? row.isRead == 0
                              : row.readStatus == 0
                          )
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../../images/uncheck.png"),
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("../../../../images/checked.png"),
                                },
                              }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.tabPosition == 1
                                  ? row.msgContent
                                  : row.noticeTitle + ":" + row.noticeContent
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "提交时间",
                  prop: "startTime",
                  width: "165",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.scan(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.del(row.pid)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        (
                          _vm.tabPosition == 1
                            ? row.isRead == 0
                            : row.readStatus == 0
                        )
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.check(row)
                                  },
                                },
                              },
                              [_vm._v("标记已读")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.current,
              limit: _vm.listQuery.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailVisible
        ? _c("noticeDetail", {
            attrs: {
              id: _vm.id,
              visible: _vm.detailVisible,
              "org-name-show": true,
            },
            on: { close: _vm.ggClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fwVisible
        ? _c("fwDetail", {
            attrs: { id: _vm.id, visible: _vm.fwVisible },
            on: { close: _vm.fwClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }