"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DetailsHdfl',
  components: {},
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    pid: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      detailData: {},
      mloading: false,
      proList: [],
      treeData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      configData: []
    };
  },
  watch: {
    detailVisible: function detailVisible(val) {
      if (val) {
        this.checkedKeys = [];
        this.treeData = [];
        this.getBasic(this.pid);
        this.getPro(this.pid);
        this.getOrgTree();
        this.getOrg(this.pid);
        this.getCofig(this.pid);
      } else {
        this.checkedKeys = [];
        this.treeData = [];
      }
    }
  },
  methods: {
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.detailData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取基本信息');
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.proList = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取产品信息');
        }
      });
    },
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getRebateRangeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var arr = [];
          var rData = res.data.data;
          if (rData.length > 0) {
            rData.forEach(function (item) {
              arr.push(item.orgId);
            });
          }
          that.checkedKeys = arr;
        }
      });
    },
    getOrgTree: function getOrgTree() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.treeData = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getCofig: function getCofig(arg) {
      var that = this;
      (0, _param.getRebateSchemeConfig)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.configData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      });
    },
    handleClose: function handleClose() {
      this.checkedKeys = [];
      this.treeData = [];
      this.$emit('close');
    }
  }
};
exports.default = _default;