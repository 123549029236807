var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "概率策略",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1100px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "topLabelForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    model: _vm.codeForm,
                    "label-position": "top",
                    "label-width": "120px",
                    rules: _vm.codeFormRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "策略类型：", prop: "prType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.codeForm.prType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.codeForm, "prType", $$v)
                                    },
                                    expression: "codeForm.prType",
                                  },
                                },
                                _vm._l(_vm.prTypes, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "el-form-item__label",
                              staticStyle: { "padding-bottom": "10px" },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.isTimeSelect,
                                    callback: function ($$v) {
                                      _vm.isTimeSelect = $$v
                                    },
                                    expression: "isTimeSelect",
                                  },
                                },
                                [_vm._v("日期范围：")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.isTimeSelect,
                                    callback: function ($$v) {
                                      _vm.isTimeSelect = $$v
                                    },
                                    expression: "isTimeSelect",
                                  },
                                },
                                [_vm._v("单双日：")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isTimeSelect
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "prDateRange" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy - MM - dd",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.codeForm.prDateRange,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.codeForm,
                                          "prDateRange",
                                          $$v
                                        )
                                      },
                                      expression: "codeForm.prDateRange",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "prTimeType" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.codeForm.prTimeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm,
                                            "prTimeType",
                                            $$v
                                          )
                                        },
                                        expression: "codeForm.prTimeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "单日", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "双日", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.codeForm.prType == "0"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间区间：" } },
                        _vm._l(
                          _vm.codeForm.timeRangeList,
                          function (item, index) {
                            return _c(
                              "el-row",
                              {
                                key: index,
                                staticStyle: { "margin-bottom": "18px" },
                                attrs: { gutter: 20, type: "flex" },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          "label-width": "0",
                                          prop: "timeRangeList." + index,
                                          rules: {
                                            required: true,
                                            message: "请选择时间区间",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-time-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "is-range": "",
                                            "range-separator": "至",
                                            "start-placeholder": "开始时间",
                                            "end-placeholder": "结束时间",
                                            placeholder: "选择时间范围",
                                            format: "HH:mm:ss",
                                            "value-format": "HH:mm:ss",
                                            "popper-class": "tpc",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.getSetRangeTime(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.codeForm.timeRangeList[index],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.codeForm.timeRangeList,
                                                index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "codeForm.timeRangeList[index]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 2 } },
                                  [
                                    index == 0
                                      ? _c("el-button", {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addTimeRange(index)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index >= 1
                                      ? _c("el-button", {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-delete",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delTimeRange(index)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "奖品设置：" },
                    },
                    [
                      _vm._l(
                        _vm.codeForm.prizeItemsList,
                        function (item, index) {
                          return _c(
                            "el-row",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "18px" },
                              attrs: { gutter: 20, type: "flex" },
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".priority",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品等级",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled:
                                                  item.extraInfo.disable ||
                                                  item.prizeType == "0" ||
                                                  _vm.showOnly,
                                                placeholder: "奖品等级",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.checkLevel(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.priority,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "priority",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.priority",
                                              },
                                            },
                                            _vm._l(
                                              _vm.prioritys,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.extraInfo.disable ||
                                            item.prizeType == "0" ||
                                            _vm.showOnly,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizeType",
                                        rules: {
                                          required: true,
                                          message: "请选择奖品类型",
                                          trigger: "change",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.showOnly ||
                                              item.extraInfo.disable,
                                            placeholder: "请选择奖品类型",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.getCurPrizeType(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizeType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizeType", $$v)
                                            },
                                            expression: "item.prizeType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.prizeTypes,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeId",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择奖品",
                                                disabled:
                                                  item.extraInfo.disable ||
                                                  item.prizeType == "0" ||
                                                  _vm.showOnly,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.doSum(index)
                                                },
                                              },
                                              model: {
                                                value: item.prizeId,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "prizeId", $$v)
                                                },
                                                expression: "item.prizeId",
                                              },
                                            },
                                            _vm._l(
                                              item.plist,
                                              function (obj, p) {
                                                return _c("el-option", {
                                                  key: p,
                                                  attrs: {
                                                    label: obj.prizeName,
                                                    value: obj.prizeId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.extraInfo.disable ||
                                            item.prizeType == "0" ||
                                            _vm.showOnly ||
                                            index <= _vm.limitLength - 1,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizePr",
                                        rules: {
                                          required: true,
                                          message: "请输入概率",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number",
                                              rawName: "v-Number",
                                            },
                                          ],
                                          attrs: { placeholder: "概率" },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.checkPrizePr(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizePr,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizePr", $$v)
                                            },
                                            expression: "item.prizePr",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeNum",
                                            rules: {
                                              required: true,
                                              message: "请输入追加数量",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            directives: [
                                              { name: "Int", rawName: "v-Int" },
                                            ],
                                            attrs: {
                                              placeholder: "数量",
                                              disabled: item.prizeType == "0",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.doSum(index)
                                              },
                                            },
                                            model: {
                                              value: item.prizeNum,
                                              callback: function ($$v) {
                                                _vm.$set(item, "prizeNum", $$v)
                                              },
                                              expression: "item.prizeNum",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.extraInfo.disable ||
                                            item.prizeType == "0",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizeBudgetTotalAmount",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number",
                                              rawName: "v-Number",
                                            },
                                          ],
                                          attrs: {
                                            readonly: "",
                                            disabled:
                                              item.prizeType == "0" ||
                                              _vm.showOnly,
                                          },
                                          model: {
                                            value: item.prizeBudgetTotalAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "prizeBudgetTotalAmount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.prizeBudgetTotalAmount",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("预算"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "inlineItem",
                                      attrs: { span: 2 },
                                    },
                                    [
                                      index == 0
                                        ? _c("el-button", {
                                            staticStyle: { float: "right" },
                                            attrs: {
                                              disabled: _vm.showOnly || true,
                                              type: "primary",
                                              size: "mini",
                                              icon: "el-icon-plus",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addCodePrize(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      index >= 1
                                        ? _c("el-button", {
                                            staticStyle: { float: "right" },
                                            attrs: {
                                              disabled:
                                                _vm.showOnly ||
                                                index <= _vm.limitLength - 1,
                                              type: "primary",
                                              size: "mini",
                                              icon: "el-icon-delete",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delCodePrize(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "18px" },
                          attrs: { type: "flex", gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 5 } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "", value: "谢谢惠顾" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { placeholder: "概率", readonly: "" },
                                  model: {
                                    value: _vm.thanksData.prizePr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.thanksData, "prizePr", $$v)
                                    },
                                    expression: "thanksData.prizePr",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { readonly: "", disabled: false },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("预算"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          false
                            ? _c("el-col", {
                                staticClass: "inlineItem",
                                attrs: { span: 2 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "normalBtn",
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.subLoading,
              },
              on: { click: _vm.finish },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }