var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "600px",
        modal: false,
        title: "订单发货",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "快递公司:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.dataForm.dvyId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "dvyId", $$v)
                    },
                    expression: "dataForm.dvyId",
                  },
                },
                _vm._l(_vm.dataForm.dvyNames, function (item) {
                  return _c("el-option", {
                    key: item.dvyId,
                    attrs: { label: item.dvyName, value: item.dvyId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "快递单号:", prop: "dvyFlowId" } },
            [
              _c("el-input", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  label: "快递单号",
                },
                model: {
                  value: _vm.dataForm.dvyFlowId,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "dvyFlowId", $$v)
                  },
                  expression: "dataForm.dvyFlowId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }