var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderCode",
                                    label: "订单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.orderCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "orderCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.orderCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "purchaseOwnerOrgId",
                                    label: "采购单位组织:",
                                  },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织",
                                      options: _vm.orgOptions,
                                    },
                                    on: { open: _vm.getSearchOrg },
                                    model: {
                                      value: _vm.listQuery.purchaseOwnerOrgId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "purchaseOwnerOrgId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.purchaseOwnerOrgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "purchaseNames",
                                    label: "采购单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                      "suffix-icon": "el-icon-search",
                                    },
                                    on: {
                                      clear: _vm.clearPurId,
                                      focus: function ($event) {
                                        return _vm.getPurchase("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.purchaseNames,
                                      callback: function ($$v) {
                                        _vm.purchaseNames = $$v
                                      },
                                      expression: "purchaseNames",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "sendOutComNames",
                                    label: "发货单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                      "suffix-icon": "el-icon-search",
                                    },
                                    on: {
                                      clear: _vm.clearSupId,
                                      focus: function ($event) {
                                        return _vm.getPurchase("out")
                                      },
                                    },
                                    model: {
                                      value: _vm.sendOutComNames,
                                      callback: function ($$v) {
                                        _vm.sendOutComNames = $$v
                                      },
                                      expression: "sendOutComNames",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderTime",
                                        label: "订单日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.orderTime,
                                          callback: function ($$v) {
                                            _vm.orderTime = $$v
                                          },
                                          expression: "orderTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderStatus",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          on: { focus: _vm.getAuthStatus },
                                          model: {
                                            value: _vm.listQuery.orderStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orderStatus",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orderStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderStatus,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: item.figure,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  staticStyle: {
                                    width: "240px",
                                    "padding-left": "0",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.DoSearch },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoReset(
                                                    _vm.listQuery
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.expand },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.expandTxt) +
                                                  "\n                "
                                              ),
                                              _c("i", {
                                                class:
                                                  _vm.showCol == false
                                                    ? "el-icon-arrow-down"
                                                    : "el-icon-arrow-up",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.DoSearch },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n          "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group fun-btn-group" },
                    [
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.exportLoading,
                              },
                              on: { click: _vm.DoExportAll },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "订单编码",
                      width: "180",
                      prop: "orderNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位组织",
                      width: "180",
                      prop: "purchaseOwnerOrgName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位类型",
                      width: "180",
                      prop: "purchaseComType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.purchaseComType == 1
                                ? _c("span", [_vm._v("工厂企业")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.purchaseComType == 2
                                ? _c("span", [_vm._v("销售公司")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.purchaseComType == 3
                                ? _c("span", [_vm._v("经销商")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.purchaseComType == 4
                                ? _c("span", [_vm._v("终端")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4251198106
                    ),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "采购单位编码",
                          width: "180",
                          prop: "purchaseComCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位",
                      width: "180",
                      prop: "purchaseCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位类型",
                      width: "180",
                      prop: "sendOutComType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.sendOutComType == 1
                                ? _c("span", [_vm._v("工厂企业")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.sendOutComType == 2
                                ? _c("span", [_vm._v("销售公司")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.sendOutComType == 3
                                ? _c("span", [_vm._v("经销商")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.sendOutComType == 4
                                ? _c("span", [_vm._v("终端")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      122745882
                    ),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "发货单位编码",
                          width: "180",
                          prop: "sendOutComCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      width: "180",
                      prop: "sendOutCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单日期",
                      width: "180",
                      prop: "placeOrderDate",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      width: "180",
                      prop: "creatorUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人类型",
                      width: "180",
                      prop: "creatorType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.creatorType == 1
                                ? _c("span", [_vm._v("经销商")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.creatorType == 2
                                ? _c("span", [_vm._v("终端")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.creatorType == 3
                                ? _c("span", [_vm._v("业务员")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1445894479
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      width: "180",
                      prop: "orderStatusName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.apis)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  false
                                    ? _c(
                                        "el-dropdown",
                                        { staticClass: "table-dropdown" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n              更多\n              "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  row.orderStatus == 1
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoEdit(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("修改")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  row.orderStatus == 2
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoStop(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("审核")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  row.orderStatus == 1
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoDelete(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3211403677
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "采购订单-查看",
            visible: _vm.scanVisible,
            "append-to-body": "",
            width: "1200px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "stepArea" }, [
            _c("div", { staticClass: "billTitle" }, [
              _c("img", { attrs: { src: require("@/images/topimg.png") } }),
              _vm._v(
                "\n        采购订单号 " +
                  _vm._s(_vm.addData.orderNo) +
                  "\n        "
              ),
              _c("span", { staticClass: "billType primary" }, [
                _vm._v(_vm._s(_vm._f("filterStatus")(_vm.addData.orderStatus))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "topRight",
                  staticStyle: { "font-size": "14px" },
                },
                [
                  _vm._v("\n          订单日期：\n          "),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: { model: _vm.addData, "label-width": "120px" },
            },
            [
              _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("单位类型:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm.addData.purchaseComType == 1
                          ? _c("span", [_vm._v("工厂企业")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.purchaseComType == 2
                          ? _c("span", [_vm._v("销售公司")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.purchaseComType == 3
                          ? _c("span", [_vm._v("经销商")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.purchaseComType == 4
                          ? _c("span", [_vm._v("终端")])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("采购单位:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.purchaseComName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("单位类型:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm.addData.receiveComType == 1
                          ? _c("span", [_vm._v("工厂企业")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.receiveComType == 2
                          ? _c("span", [_vm._v("销售公司")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.receiveComType == 3
                          ? _c("span", [_vm._v("经销商")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.receiveComType == 4
                          ? _c("span", [_vm._v("终端")])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("收货单位:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveComName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 24, md: 24 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("收货地址:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveAddress)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("发货信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("单位类型:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm.addData.sendOutComType == 1
                          ? _c("span", [_vm._v("工厂企业")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.sendOutComType == 2
                          ? _c("span", [_vm._v("销售公司")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.sendOutComType == 3
                          ? _c("span", [_vm._v("经销商")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.addData.sendOutComType == 4
                          ? _c("span", [_vm._v("终端")])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "90px" },
                        },
                        [_vm._v("发货单位:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.sendOutComName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [
            _c("span", { staticStyle: { "margin-right": "20px" } }, [
              _vm._v("产品明细"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticStyle: { "margin-right": "30px" },
              attrs: { src: require("@/images/pro.png") },
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fr", staticStyle: { "font-size": "14px" } },
              [_c("strong", [_vm._v("采购总数量：" + _vm._s(_vm.totalSumAll))])]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.addData.orderDetailDTOS,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "max-height": "300",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packRaidoExpress",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱数",
                  prop: "chestNum",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "总盒数",
                  prop: "bottleNum",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "50px" } }),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "title" }, [_vm._v("整单备注")])
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                _vm._v(_vm._s(_vm.addData.remarks)),
              ])
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "title" }, [
                _vm._v("\n      图片附件\n      "),
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("最多5张，只能上传jpg/png文件，且不超过500kb"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "div",
                _vm._l(_vm.uploadImgs, function (item, idx) {
                  return _c("el-image", {
                    key: idx,
                    staticStyle: { width: "80px", height: "80px" },
                    attrs: {
                      src: item.src,
                      "preview-src-list": _vm.preViewList,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "title" }, [_vm._v("审核日志")])
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "300",
                    data: _vm.checkList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "审核结果",
                      prop: "type",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核人",
                      prop: "userName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核时间",
                      prop: "checkTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核意见",
                      prop: "auditmsg",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUnits },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }