var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "div",
        { staticClass: "hyyy-container" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    [
                      _c("div", { staticClass: "hyyy-top-tit" }, [
                        _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$route.meta.title),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "tipsa" }, [
                            _vm._v(
                              "\n                提示：等级会员卡和普通会员卡只能选择一种形式，不能兼容。如果出现普通会员卡停用而开通等级会员卡后，所有的普通会员将会\n\n                复制成为等级会员卡的一级会员中，任务值都为0进行展示。\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hyyy-top-tit-right" }),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { staticStyle: { margin: "0" } }),
                      _vm._v(" "),
                      _c("el-row", { staticClass: "hyyy-content" }, [
                        _c(
                          "div",
                          { staticClass: "hyyy-main" },
                          [
                            _c(
                              "el-main",
                              [
                                _c(
                                  "el-row",
                                  { attrs: { type: "flex", justify: "start" } },
                                  _vm._l(_vm.list, function (o, index) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: index,
                                        staticClass: "viptit",
                                        attrs: { span: 4 },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.togle(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-card",
                                          {
                                            class:
                                              _vm.listIndex == index
                                                ? "box-card active"
                                                : "box-card",
                                            staticStyle: {
                                              margin: "0 auto",
                                              "text-align": "center",
                                            },
                                          },
                                          [_c("div", [_vm._v(_vm._s(o.name))])]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.listIndex == 0,
                                        expression: "listIndex == 0",
                                      },
                                    ],
                                    staticClass: "maincnter",
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        attrs: {
                                          type: "flex",
                                          justify: "start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "panel-left",
                                            attrs: { span: 5 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mobilePanel" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "mobile-panel-title",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mobile-panel-centen",
                                                  },
                                                  [
                                                    _vm.form.backgroundType ==
                                                    "1"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mobile-panel-centen-top",
                                                            style: {
                                                              background:
                                                                _vm.form
                                                                  .backgroundImg,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wxtb",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "nick",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-top":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "wxtx",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "nickname",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "昵称"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "zxewm",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-menu",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "h3",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                  color: "#fff",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.form
                                                                        .title
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mobile-panel-centen-top",
                                                            style: {
                                                              backgroundImage:
                                                                "url(" +
                                                                _vm.bgimgurl +
                                                                ")",
                                                              backgroundRepeat:
                                                                "no-repeat",
                                                              backgroundPosition:
                                                                "center center",
                                                              backgroundSize:
                                                                "cover",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wxtb",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "nick",
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "wxtx",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "nickname",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "昵称"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "任务值记录"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "zxewm",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-menu",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "h3",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                  color: "#fff",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.form
                                                                        .title
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile-panel-centen-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-cen",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "\n                                会员 · 等级权益\n                              "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/icon.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          {
                                                            staticClass:
                                                              "row-bg",
                                                            attrs: {
                                                              type: "flex",
                                                              justify: "center",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.qylist,
                                                            function (tag) {
                                                              return _c(
                                                                "el-col",
                                                                {
                                                                  key: tag.index,
                                                                  staticClass:
                                                                    "qysty",
                                                                  attrs: {
                                                                    span: 4,
                                                                  },
                                                                },
                                                                [
                                                                  tag.url ===
                                                                  null
                                                                    ? _c("span")
                                                                    : _c(
                                                                        "img",
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                alt: "",
                                                                              },
                                                                          },
                                                                          "img",
                                                                          {
                                                                            src:
                                                                              _vm.twImgurl +
                                                                              tag.url,
                                                                          },
                                                                          false
                                                                        )
                                                                      ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "strsp"
                                                                        )(
                                                                          tag.equityName
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile-panel-centen-foot",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-cen",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "\n                                会员 · 使用须知\n                              "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/icon.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c("el-col", {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.syxz1
                                                                  ),
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.ordinary == 0,
                                                expression: "ordinary == 0",
                                              },
                                            ],
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("h5", [
                                              _vm._v(
                                                "\n                        基本信息\n                      "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-form",
                                              {
                                                ref: "form",
                                                attrs: {
                                                  model: _vm.form,
                                                  "label-width": "120px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "会员卡标题",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "200px",
                                                      },
                                                      attrs: {
                                                        maxlength: "8",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value: _vm.form.title,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "title",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.title",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "背景图片",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.bjimg(
                                                              _vm.form
                                                                .backgroundType,
                                                              "1"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .backgroundType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "backgroundType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.backgroundType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              背景色\n                              "
                                                            ),
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "el-color-picker",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.form
                                                                          .backgroundType !==
                                                                        "1",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.colorChang(
                                                                            _vm.color,
                                                                            "1"
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.color,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.color =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "color",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              本地添加\n                              "
                                                            ),
                                                            _c(
                                                              "el-upload",
                                                              {
                                                                staticClass:
                                                                  "avatar-uploader",
                                                                attrs: {
                                                                  id: "updata",
                                                                  disabled:
                                                                    _vm.form
                                                                      .backgroundType !==
                                                                    "2",
                                                                  accept:
                                                                    ".bmp, .png, .jpeg, .jpg, gif",
                                                                  action:
                                                                    _vm.fstpUrl,
                                                                  headers:
                                                                    _vm.myHeaders,
                                                                  "show-file-list": false,
                                                                  "on-success":
                                                                    _vm.handleAvatarSuccessbj,
                                                                  "before-upload":
                                                                    _vm.beforeAvatarUpload,
                                                                  "on-error":
                                                                    _vm.imgUploaderr,
                                                                },
                                                              },
                                                              [
                                                                _vm.bgimgurl
                                                                  ? _c("img", {
                                                                      staticClass:
                                                                        "avatar",
                                                                      attrs: {
                                                                        src: _vm.bgimgurl,
                                                                      },
                                                                    })
                                                                  : _c("i", {
                                                                      staticClass:
                                                                        "el-icon-plus avatar-uploader-icon",
                                                                    }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "使用须知",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "textarea",
                                                        rows: 8,
                                                        maxlength: "200",
                                                        "show-word-limit": "",
                                                        placeholder:
                                                          "请输入内容",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.strrep(
                                                            _vm.form
                                                              .noticeForUse,
                                                            "1"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.noticeForUse,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "noticeForUse",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.noticeForUse",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("el-divider"),
                                                _vm._v(" "),
                                                _c("h5", [_vm._v("会员权益")]),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticClass: "marb",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.electequitybtn(
                                                          "1"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("选择权益")]
                                                ),
                                                _vm._v(" "),
                                                [
                                                  _c(
                                                    "el-dialog",
                                                    {
                                                      attrs: {
                                                        title: "选择权益",
                                                        width: "70%",
                                                        visible:
                                                          _vm.outerVisible,
                                                      },
                                                      on: {
                                                        "update:visible":
                                                          function ($event) {
                                                            _vm.outerVisible =
                                                              $event
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-dialog",
                                                        {
                                                          attrs: {
                                                            width: "30%",
                                                            title: "添加权益",
                                                            visible:
                                                              _vm.innerVisible,
                                                            "append-to-body":
                                                              "",
                                                          },
                                                          on: {
                                                            "update:visible":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.innerVisible =
                                                                  $event
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form",
                                                            {
                                                              attrs: {
                                                                model:
                                                                  _vm.rightsform,
                                                                "label-width":
                                                                  "120px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益名称",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "200px",
                                                                        },
                                                                      attrs: {
                                                                        maxlength:
                                                                          "6",
                                                                        "show-word-limit":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .rightsform
                                                                            .equityName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.rightsform,
                                                                              "equityName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "rightsform.equityName",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益图标",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-upload",
                                                                    {
                                                                      staticClass:
                                                                        "avatar-uploader",
                                                                      attrs: {
                                                                        id: "updata",
                                                                        accept:
                                                                          ".bmp, .png, .jpeg, .jpg, gif",
                                                                        action:
                                                                          _vm.fstpUrl,
                                                                        headers:
                                                                          _vm.myHeaders,
                                                                        "show-file-list": false,
                                                                        "on-success":
                                                                          _vm.handleAvatarSuccessqy,
                                                                        "before-upload":
                                                                          _vm.beforeAvatarUpload,
                                                                        "on-error":
                                                                          _vm.imgUploaderr,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.qyiconurl
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "avatar",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.qyiconurl,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-plus avatar-uploader-icon",
                                                                            }
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益说明",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "textarea",
                                                                        rows: 8,
                                                                        maxlength:
                                                                          "50",
                                                                        "show-word-limit":
                                                                          "",
                                                                        placeholder:
                                                                          "请输入内容",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .rightsform
                                                                            .equityExpain,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.rightsform,
                                                                              "equityExpain",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "rightsform.equityExpain",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onSubmitqy(
                                                                              _vm.rightsform,
                                                                              "1"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "保存"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.innerVisible = false
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "取消"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-row",
                                                        {
                                                          attrs: {
                                                            type: "flex",
                                                            justify: "start",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addequitybtn(
                                                                          "1"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "添加权益"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "el-table",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "loading",
                                                                          rawName:
                                                                            "v-loading",
                                                                          value:
                                                                            _vm.rightload,
                                                                          expression:
                                                                            "rightload",
                                                                        },
                                                                      ],
                                                                    ref: "multipleTable",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      data: _vm.rightsData,
                                                                    },
                                                                    on: {
                                                                      "selection-change":
                                                                        _vm.handleSelectionChange,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          type: "selection",
                                                                          width:
                                                                            "55",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "equityName",
                                                                          label:
                                                                            "权益名称",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "权益图标",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  scope
                                                                                ) {
                                                                                  return [
                                                                                    scope
                                                                                      .row
                                                                                      .url ===
                                                                                    null
                                                                                      ? _c(
                                                                                          "span"
                                                                                        )
                                                                                      : _c(
                                                                                          "img",
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "30%",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  alt: "",
                                                                                                },
                                                                                            },
                                                                                            "img",
                                                                                            {
                                                                                              src:
                                                                                                _vm.twImgurl +
                                                                                                scope
                                                                                                  .row
                                                                                                  .url,
                                                                                            },
                                                                                            false
                                                                                          )
                                                                                        ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "equityExpain",
                                                                          label:
                                                                            "权益说明",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "状态",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  scope
                                                                                ) {
                                                                                  return [
                                                                                    scope
                                                                                      .row
                                                                                      .operation ==
                                                                                    2
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "已选中"
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "未选中"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dialog-footer",
                                                          attrs: {
                                                            slot: "footer",
                                                          },
                                                          slot: "footer",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.qybtn(
                                                                      "1"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("确定")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.outerVisible = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("取 消")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                _vm._v(" "),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      _vm._l(
                                                        _vm.qylist,
                                                        function (tag, index) {
                                                          return _c(
                                                            "el-tag",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                closable: "",
                                                                "disable-transitions": false,
                                                              },
                                                              on: {
                                                                close:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleCloseqy(
                                                                      tag
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                              " +
                                                                  _vm._s(
                                                                    tag.equityName
                                                                  ) +
                                                                  "\n                            "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("el-divider", {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.upSubmit(
                                                              "1"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onSubmit(
                                                              _vm.form,
                                                              "1"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("提交")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.ordinary == 1,
                                                expression: "ordinary == 1",
                                              },
                                            ],
                                            staticClass: "xcxon",
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.commonxcx,
                                                    expression: "commonxcx",
                                                  },
                                                ],
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onXcx("1")
                                                  },
                                                },
                                              },
                                              [_vm._v("启用小程序")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.commonxcx,
                                                    expression: "!commonxcx",
                                                  },
                                                ],
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.downxcx = true
                                                  },
                                                },
                                              },
                                              [_vm._v("禁用小程序")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                                attrs: { type: "primary" },
                                                on: { click: _vm.editvip },
                                              },
                                              [_vm._v("编辑会员卡")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: { click: _vm.vipinfobtn },
                                              },
                                              [_vm._v("会员卡信息")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: { click: _vm.getscenebtn },
                                              },
                                              [_vm._v("生成二维码")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.listIndex == 1,
                                        expression: "listIndex == 1",
                                      },
                                    ],
                                    staticClass: "mainright",
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.levelset == 1,
                                            expression: "levelset == 1",
                                          },
                                        ],
                                        attrs: {
                                          type: "flex",
                                          justify: "start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "panel-left",
                                            attrs: { span: 5 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mobilePanel" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "mobile-panel-title",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mobile-panel-centen",
                                                  },
                                                  [
                                                    _vm.form1.backgroundType ==
                                                    "1"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mobile-panel-centen-top",
                                                            style: {
                                                              background:
                                                                _vm.form1
                                                                  .backgroundImg,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wxtb",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "nick",
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "wxtx",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "nickname",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "昵称"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "任务值记录"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "zxewm",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-menu",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "h3",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                  color: "#fff",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.form1
                                                                        .title
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "tbfoot",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "yxq",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "有效期"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "rwz",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "任务值"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mobile-panel-centen-top",
                                                            style: {
                                                              backgroundImage:
                                                                "url(" +
                                                                _vm.bgimgurl1 +
                                                                ")",
                                                              backgroundRepeat:
                                                                "no-repeat",
                                                              backgroundPosition:
                                                                "center center",
                                                              backgroundSize:
                                                                "cover",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wxtb",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "nick",
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "wxtx",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "nickname",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "昵称"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm.mstext
                                                                                      ? false
                                                                                      : true,
                                                                                  expression:
                                                                                    "mstext?false:true",
                                                                                },
                                                                              ],
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "任务值记录"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "zxewm",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-menu",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "h3",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                  color: "#fff",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.form1
                                                                        .title
                                                                    ) +
                                                                    "\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "tbfoot",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "yxq",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "有效期"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "rwz",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "任务值"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile-panel-centen-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-cen",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "\n                                会员 · 等级权益\n                              "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/icon.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          {
                                                            staticClass:
                                                              "row-bg",
                                                            attrs: {
                                                              type: "flex",
                                                              justify: "center",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.qylist,
                                                            function (tag) {
                                                              return _c(
                                                                "el-col",
                                                                {
                                                                  key: tag.index,
                                                                  staticClass:
                                                                    "qysty",
                                                                  attrs: {
                                                                    span: 4,
                                                                  },
                                                                },
                                                                [
                                                                  tag.url ===
                                                                  null
                                                                    ? _c("span")
                                                                    : _c(
                                                                        "img",
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                alt: "",
                                                                              },
                                                                          },
                                                                          "img",
                                                                          {
                                                                            src:
                                                                              _vm.twImgurl +
                                                                              tag.url,
                                                                          },
                                                                          false
                                                                        )
                                                                      ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "strsp"
                                                                        )(
                                                                          tag.equityName
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-cen",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "\n                                如何提升任务值\n                              "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/icon.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.phoneList,
                                                          function (item) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.mstext
                                                                        ? false
                                                                        : true,
                                                                    expression:
                                                                      "mstext?false:true",
                                                                  },
                                                                ],
                                                                key: item.type,
                                                                staticClass:
                                                                  "phonetask",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-row",
                                                                  {
                                                                    attrs: {
                                                                      type: "flex",
                                                                      justify:
                                                                        "space-around",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-col",
                                                                      {
                                                                        attrs: {
                                                                          span: 12,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.remark
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-col",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "12px",
                                                                            "align-self":
                                                                              "center",
                                                                          },
                                                                        attrs: {
                                                                          span: 3,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    item.buttonName
                                                                                      ? true
                                                                                      : false,
                                                                                  expression:
                                                                                    "item.buttonName?true:false",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                size: "mini",
                                                                                round:
                                                                                  "",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                textContent:
                                                                                  _vm._s(
                                                                                    item.buttonName
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.mstext
                                                                    ? true
                                                                    : false,
                                                                expression:
                                                                  "mstext?true:false",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "phonetask",
                                                          },
                                                          [
                                                            _c(
                                                              "el-row",
                                                              [
                                                                _c("el-col", {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        _vm.mstext
                                                                      ),
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile-panel-centen-foot",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "icon-cen",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "\n                                会员 · 使用须知\n                              "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/icon.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c("el-col", {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.syxz2
                                                                  ),
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c("h5", [
                                              _vm._v(
                                                "\n                        基本信息\n                      "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-form",
                                              {
                                                ref: "form",
                                                attrs: {
                                                  model: _vm.form1,
                                                  "label-width": "120px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "会员卡标题",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "200px",
                                                      },
                                                      attrs: {
                                                        maxlength: "8",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value: _vm.form1.title,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form1,
                                                            "title",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form1.title",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "背景图片",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.bjimg(
                                                              _vm.form1
                                                                .backgroundType,
                                                              "2"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form1
                                                              .backgroundType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form1,
                                                              "backgroundType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form1.backgroundType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              背景色\n                              "
                                                            ),
                                                            _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "el-color-picker",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm
                                                                          .form1
                                                                          .backgroundType !==
                                                                        "1",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.colorChang(
                                                                            _vm.color,
                                                                            "2"
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.color,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.color =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "color",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              本地添加\n                              "
                                                            ),
                                                            _c(
                                                              "el-upload",
                                                              {
                                                                staticClass:
                                                                  "avatar-uploader",
                                                                attrs: {
                                                                  id: "updata",
                                                                  disabled:
                                                                    _vm.form1
                                                                      .backgroundType !==
                                                                    "2",
                                                                  accept:
                                                                    ".bmp, .png, .jpeg, .jpg, gif",
                                                                  action:
                                                                    _vm.fstpUrl,
                                                                  headers:
                                                                    _vm.myHeaders,
                                                                  "show-file-list": false,
                                                                  "on-success":
                                                                    _vm.handleAvatarSuccessbj1,
                                                                  "before-upload":
                                                                    _vm.beforeAvatarUpload,
                                                                  "on-error":
                                                                    _vm.imgUploaderr,
                                                                },
                                                              },
                                                              [
                                                                _vm.bgimgurl1
                                                                  ? _c("img", {
                                                                      staticClass:
                                                                        "avatar",
                                                                      attrs: {
                                                                        src: _vm.bgimgurl1,
                                                                      },
                                                                    })
                                                                  : _c("i", {
                                                                      staticClass:
                                                                        "el-icon-plus avatar-uploader-icon",
                                                                    }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "所需任务值",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "200px",
                                                      },
                                                      attrs: {
                                                        placeholder: "最少为0",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form1.needTask,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form1,
                                                            "needTask",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form1.needTask",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(
                                                          "为设置任务值？"
                                                        ),
                                                        _c(
                                                          "el-link",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.taskbtn(
                                                                  "2"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("立即设置")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "任务值系数",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "200px",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.coefficientnum,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form1
                                                            .missionCoefficient,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form1,
                                                            "missionCoefficient",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form1.missionCoefficient",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "使用须知",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        type: "textarea",
                                                        rows: 8,
                                                        maxlength: "200",
                                                        "show-word-limit": "",
                                                        placeholder:
                                                          "请输入内容",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.strrep(
                                                            _vm.form1
                                                              .noticeForUse,
                                                            "2"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form1
                                                            .noticeForUse,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form1,
                                                            "noticeForUse",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form1.noticeForUse",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("el-divider"),
                                                _vm._v(" "),
                                                _c("h5", [_vm._v("会员权益")]),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticClass: "marb",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.electequitybtn(
                                                          "2"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("选择权益")]
                                                ),
                                                _vm._v(" "),
                                                [
                                                  _c(
                                                    "el-dialog",
                                                    {
                                                      attrs: {
                                                        title: "选择权益",
                                                        width: "70%",
                                                        visible:
                                                          _vm.outerVisible1,
                                                      },
                                                      on: {
                                                        "update:visible":
                                                          function ($event) {
                                                            _vm.outerVisible1 =
                                                              $event
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-dialog",
                                                        {
                                                          attrs: {
                                                            width: "30%",
                                                            title: "添加权益",
                                                            visible:
                                                              _vm.innerVisible1,
                                                            "append-to-body":
                                                              "",
                                                          },
                                                          on: {
                                                            "update:visible":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.innerVisible1 =
                                                                  $event
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form",
                                                            {
                                                              attrs: {
                                                                model:
                                                                  _vm.rightsform,
                                                                "label-width":
                                                                  "120px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益名称",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .rightsform
                                                                            .equityName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.rightsform,
                                                                              "equityName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "rightsform.equityName",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益图标",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-upload",
                                                                    {
                                                                      staticClass:
                                                                        "avatar-uploader",
                                                                      attrs: {
                                                                        id: "updata",
                                                                        accept:
                                                                          ".bmp, .png, .jpeg, .jpg, gif",
                                                                        action:
                                                                          _vm.fstpUrl,
                                                                        headers:
                                                                          _vm.myHeaders,
                                                                        "show-file-list": false,
                                                                        "on-success":
                                                                          _vm.handleAvatarSuccessqy,
                                                                        "before-upload":
                                                                          _vm.beforeAvatarUpload,
                                                                        "on-error":
                                                                          _vm.imgUploaderr,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm.qyiconurl
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "avatar",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.qyiconurl,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-plus avatar-uploader-icon",
                                                                            }
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "权益说明",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "textarea",
                                                                        rows: 8,
                                                                        maxlength:
                                                                          "200",
                                                                        "show-word-limit":
                                                                          "",
                                                                        placeholder:
                                                                          "请输入内容",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .rightsform
                                                                            .equityExpain,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.rightsform,
                                                                              "equityExpain",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "rightsform.equityExpain",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onSubmitqy(
                                                                              _vm.rightsform,
                                                                              "2"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "保存"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.innerVisible1 = false
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "取消"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-row",
                                                        {
                                                          attrs: {
                                                            type: "flex",
                                                            justify: "start",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addequitybtn(
                                                                          "2"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "添加权益"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 24,
                                                              },
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "el-table",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "loading",
                                                                          rawName:
                                                                            "v-loading",
                                                                          value:
                                                                            _vm.rightload,
                                                                          expression:
                                                                            "rightload",
                                                                        },
                                                                      ],
                                                                    ref: "multipleTable",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      data: _vm.rightsData,
                                                                    },
                                                                    on: {
                                                                      "selection-change":
                                                                        _vm.handleSelectionChange,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          type: "selection",
                                                                          width:
                                                                            "55",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "equityName",
                                                                          label:
                                                                            "权益名称",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "权益图标",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  scope
                                                                                ) {
                                                                                  return [
                                                                                    scope
                                                                                      .row
                                                                                      .url ==
                                                                                    null
                                                                                      ? _c(
                                                                                          "span"
                                                                                        )
                                                                                      : _c(
                                                                                          "img",
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "30%",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  alt: "",
                                                                                                },
                                                                                            },
                                                                                            "img",
                                                                                            {
                                                                                              src:
                                                                                                _vm.twImgurl +
                                                                                                scope
                                                                                                  .row
                                                                                                  .url,
                                                                                            },
                                                                                            false
                                                                                          )
                                                                                        ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          prop: "equityExpain",
                                                                          label:
                                                                            "权益说明",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-table-column",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "状态",
                                                                          width:
                                                                            "180",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "default",
                                                                                fn: function (
                                                                                  scope
                                                                                ) {
                                                                                  return [
                                                                                    scope
                                                                                      .row
                                                                                      .operation ==
                                                                                    2
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "已选中"
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "未选中"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ]
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dialog-footer",
                                                          attrs: {
                                                            slot: "footer",
                                                          },
                                                          slot: "footer",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.qybtn(
                                                                      "2"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("确定")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.outerVisible1 = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("取 消")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                _vm._v(" "),
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      _vm._l(
                                                        _vm.qylist,
                                                        function (tag, index) {
                                                          return _c(
                                                            "el-tag",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                closable: "",
                                                                "disable-transitions": false,
                                                              },
                                                              on: {
                                                                close:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleCloseqy(
                                                                      tag
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                              " +
                                                                  _vm._s(
                                                                    tag.equityName
                                                                  ) +
                                                                  "\n                            "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("el-divider"),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.upSubmit(
                                                              "2"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onSubmit(
                                                              _vm.form1,
                                                              "2"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("提交")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.levelset == 0,
                                            expression: "levelset == 0",
                                          },
                                        ],
                                        staticClass: "rightmainct",
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _vm.taskindex == 0
                                                  ? _c(
                                                      "p",
                                                      [
                                                        _vm._v(
                                                          "\n                          当前未设置等级会员的任务值，\n                          "
                                                        ),
                                                        _c(
                                                          "el-link",
                                                          {
                                                            staticClass: "ljsz",
                                                            on: {
                                                              click:
                                                                _vm.taskbtn,
                                                            },
                                                          },
                                                          [_vm._v("立即设置")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "p",
                                                      [
                                                        _vm._v(
                                                          "\n                          当前已设置" +
                                                            _vm._s(
                                                              _vm.taskNum
                                                            ) +
                                                            "个任务值，\n                          "
                                                        ),
                                                        _c(
                                                          "el-link",
                                                          {
                                                            staticClass: "ljsz",
                                                            on: {
                                                              click:
                                                                _vm.taskbtn,
                                                            },
                                                          },
                                                          [_vm._v("查看或修改")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          {
                                            attrs: {
                                              type: "flex",
                                              justify: "space-between",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  color: "#333",
                                                },
                                                attrs: { span: 5 },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        强调：1级会员为最低等级会员\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "margin-top": "-24px",
                                                },
                                                attrs: { span: 12 },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.offxcx,
                                                        expression: "offxcx",
                                                      },
                                                    ],
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onXcx("2")
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "启用小程序\n\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.offxcx,
                                                        expression: "!offxcx",
                                                      },
                                                    ],
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.downxcx1 = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "禁用小程序\n\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: _vm.vipinfobtn,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "会员卡信息\n\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: _vm.getscenebtn,
                                                    },
                                                  },
                                                  [_vm._v("生成二维码")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value: _vm.tabloading,
                                                        expression:
                                                          "tabloading",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      data: _vm.tableData,
                                                      border: "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "memberStr",
                                                        label: "会员等级",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "backgroundType",
                                                        label: "背景图片",
                                                        align: "center",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              scope.row
                                                                .backgroundType ==
                                                              1
                                                                ? _c(
                                                                    "el-row",
                                                                    [
                                                                      _c(
                                                                        "el-col",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              style:
                                                                                {
                                                                                  width:
                                                                                    "80px",
                                                                                  height:
                                                                                    "30px",
                                                                                  margin:
                                                                                    "0 auto",
                                                                                  background:
                                                                                    scope
                                                                                      .row
                                                                                      .backgroundImg,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-row",
                                                                    [
                                                                      _c(
                                                                        "el-col",
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            _vm._b(
                                                                              {
                                                                                style:
                                                                                  {
                                                                                    width:
                                                                                      "80px",
                                                                                    height:
                                                                                      "30px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    alt: "",
                                                                                  },
                                                                              },
                                                                              "img",
                                                                              {
                                                                                src:
                                                                                  _vm.twImgurl +
                                                                                  scope
                                                                                    .row
                                                                                    .backgroundImg,
                                                                              },
                                                                              false
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "title",
                                                        label: "会员名称",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "needTask",
                                                        label: "所需任务值",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "memberEquitylist",
                                                        label: "会员权益",
                                                        align: "center",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "type",
                                                        label: "操作",
                                                        align: "center",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              scope.row.cz == 1
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.levelbtn(
                                                                              scope
                                                                                .row
                                                                                .memberCradNumber,
                                                                              "1"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        [
                                                                          _vm._v(
                                                                            "创建"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.levelbtn(
                                                                                  scope
                                                                                    .row
                                                                                    .id,
                                                                                  "2"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "编辑"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "任务中心",
                visible: _vm.taskconterdl,
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.taskconterdl = $event
                },
              },
            },
            [
              _c("h5", [_vm._v("基础信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c(
                      "span",
                      [
                        _vm._v("任务值名称:"),
                        _c("el-input", {
                          staticStyle: { width: "240px" },
                          attrs: {
                            maxlength: "6",
                            "show-word-limit": "",
                            placeholder: "类似淘气值，不超过6个字",
                          },
                          model: {
                            value: _vm.taskName,
                            callback: function ($$v) {
                              _vm.taskName = $$v
                            },
                            expression: "taskName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("任务内容")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.formr, "label-width": "10px" },
                },
                [
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "注册会员", name: "type" },
                        on: { "": function ($event) {} },
                        model: {
                          value: _vm.formr.isRegisterMember,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isRegisterMember", $$v)
                          },
                          expression: "formr.isRegisterMember",
                        },
                      },
                      [
                        _c("span", [_vm._v("注册会员")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v("新会员注册即可获得 "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: _vm.formr.valueRegisterMember,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formr,
                                    "valueRegisterMember",
                                    $$v
                                  )
                                },
                                expression: "formr.valueRegisterMember",
                              },
                            }),
                            _vm._v(" 任务值"),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "购买商品" },
                        model: {
                          value: _vm.formr.isPurchaseCommodity,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isPurchaseCommodity", $$v)
                          },
                          expression: "formr.isPurchaseCommodity",
                        },
                      },
                      [
                        _c("span", [_vm._v("购买商品")]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          _vm._l(
                            _vm.formr.taskCommodityList,
                            function (item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: 24 } },
                                [
                                  _c("span", [
                                    _c(
                                      "div",
                                      [
                                        _vm._v("每消费\n                    "),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCommodityList[index]
                                                .taskConsumption,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ],
                                                "taskConsumption",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCommodityList[index].taskConsumption",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value:
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ].taskCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formr.taskCommodityList[
                                                    index
                                                  ],
                                                  "taskCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formr.taskCommodityList[index].taskCompany",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "元",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "产品",
                                                value: "2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n                    ，获得\n                    "
                                        ),
                                        _c("el-input", {
                                          model: {
                                            value:
                                              _vm.formr.taskCommodityList[index]
                                                .taskValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ],
                                                "taskValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCommodityList[index].taskValue",
                                          },
                                        }),
                                        _vm._v("任务值\n                    "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.formr.taskCommodityList
                                                  .length > 1,
                                              expression:
                                                "formr.taskCommodityList.length > 1",
                                            },
                                          ],
                                          staticClass: "el-icon-remove-outline",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reduceshoplist(
                                                index,
                                                _vm.formr.taskCommodityList
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-link",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.addshoplist(
                                                  index,
                                                  _vm.formr.taskCommodityList
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("添加门槛")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("p", [_vm._v("如有商城可进行关联")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: { span: 24 },
                              },
                              [
                                _vm._v("\n                小程序APPID："),
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  model: {
                                    value: _vm.formr.programAppId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formr, "programAppId", $$v)
                                    },
                                    expression: "formr.programAppId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.fwyzshop,
                            expression: "fwyzshop",
                          },
                        ],
                        attrs: { label: "防伪验证" },
                        model: {
                          value: _vm.formr.isCounterfeiting,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isCounterfeiting", $$v)
                          },
                          expression: "formr.isCounterfeiting",
                        },
                      },
                      [
                        _c("span", [_vm._v("防伪验证")]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          _vm._l(
                            _vm.formr.taskCounterfeitingList,
                            function (item, index) {
                              return _c(
                                "el-col",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [
                                    _c(
                                      "span",
                                      [
                                        _vm._v("每扫码"),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCounterfeitingList[
                                                index
                                              ].taskConsumption,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr
                                                  .taskCounterfeitingList[
                                                  index
                                                ],
                                                "taskConsumption",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCounterfeitingList[index].taskConsumption",
                                          },
                                        }),
                                        _vm._v("次防伪验证码"),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _vm._v("，获得 "),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCounterfeitingList[
                                                index
                                              ].taskValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr
                                                  .taskCounterfeitingList[
                                                  index
                                                ],
                                                "taskValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCounterfeitingList[index].taskValue",
                                          },
                                        }),
                                        _vm._v(" 任务值\n                    "),
                                        _c(
                                          "span",
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formr
                                                      .taskCounterfeitingList
                                                      .length > 1,
                                                  expression:
                                                    "formr.taskCounterfeitingList.length > 1",
                                                },
                                              ],
                                              staticClass:
                                                "el-icon-remove-outline",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.reducefwlist(
                                                    index,
                                                    _vm.formr
                                                      .taskCounterfeitingList
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-link",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addfwlist(
                                                      index,
                                                      _vm.formr
                                                        .taskCounterfeitingList
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加门槛")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "分享好友" },
                        model: {
                          value: _vm.formr.isFriend,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isFriend", $$v)
                          },
                          expression: "formr.isFriend",
                        },
                      },
                      [
                        _c("span", [_vm._v("分享好友")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n              每分享1名好友获得\n              "
                            ),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: _vm.formr.taskFriend,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskFriend", $$v)
                                },
                                expression: "formr.taskFriend",
                              },
                            }),
                            _vm._v("任务值\n            "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "关注公众号" },
                        model: {
                          value: _vm.formr.isFollow,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isFollow", $$v)
                          },
                          expression: "formr.isFollow",
                        },
                      },
                      [
                        _c("span", [_vm._v("关注公众号")]),
                        _c(
                          "span",
                          [
                            _vm._v("首次关注微信公众号送 "),
                            _c("el-input", {
                              model: {
                                value: _vm.formr.taskFollow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskFollow", $$v)
                                },
                                expression: "formr.taskFollow",
                              },
                            }),
                            _vm._v(" 任务值"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _vm._v(
                                "\n                公众号绑定\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "upimg", attrs: { span: 24 } },
                              [
                                _c("span", [_vm._v("公众号二维码图片：")]),
                                _vm._v(" "),
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: _vm.fstpUrl,
                                      headers: _vm.myHeaders,
                                      "before-upload": _vm.imgSize,
                                      "on-success": _vm.handleSuccess,
                                      "on-error": _vm.handError,
                                      "show-file-list": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("上传图片")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "img",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.formr.invitationTitleId != "" ||
                                          _vm.fmurl != "",
                                        expression:
                                          "formr.invitationTitleId != '' || fmurl != ''",
                                      },
                                    ],
                                    staticStyle: {
                                      width: "10%",
                                      display: "block",
                                      margin: "10px 68px",
                                    },
                                    attrs: { alt: "" },
                                  },
                                  "img",
                                  { src: _vm.fmurl },
                                  false
                                )
                              ),
                              _vm._v(
                                "\n                尺寸要求：建议尺寸200:200像素，图片支持JPG、PNG格式，图片小于1MB\n                "
                              ),
                              _c("p", [
                                _vm._v(
                                  '注：通过该处设置的公众号成功关注的用户，添加自动标签为"会员卡渠道用户"'
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "生日礼包" },
                        model: {
                          value: _vm.formr.isBirthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isBirthday", $$v)
                          },
                          expression: "formr.isBirthday",
                        },
                      },
                      [
                        _c("span", [_vm._v("生日礼包")]),
                        _c(
                          "span",
                          [
                            _vm._v("会员生日当天可获得 "),
                            _c("el-input", {
                              model: {
                                value: _vm.formr.taskBirthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskBirthday", $$v)
                                },
                                expression: "formr.taskBirthday",
                              },
                            }),
                            _vm._v("任务值"),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.taskconterdl = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.savetask(_vm.formr)
                            },
                          },
                        },
                        [_vm._v("保 存")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "会员卡信息",
                visible: _vm.vipinfo,
                width: "30%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.vipinfo = $event
                },
              },
            },
            [
              _c("p", [_vm._v("小程序APPID：" + _vm._s(_vm.xcxinfo.appid))]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "注：可复制该信息进而与公众号内自定义菜单和模板消息，以及积分商城小程序进行跳转"
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "是否确定要启用会员卡？",
                visible: _vm.upxcx,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.upxcx = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("注：启用会员卡需要审核后才可以上线，请耐心等待"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.upxcx = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.upxcxbtn } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "是否确定要启用会员卡？",
                visible: _vm.upxcx1,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.upxcx1 = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("注：启用会员卡需要审核后才可以上线，请耐心等待"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.upxcx1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.upxcxbtn1 },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "是否确定要停用会员卡？",
                visible: _vm.downxcx,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.downxcx = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("注：停用后将不会再新增会员，且不影响已有会员。"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.downxcx = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.downxcxbtn },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "是否确定要停用会员卡？",
                visible: _vm.downxcx1,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.downxcx1 = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("注：停用后将不会再新增会员，且不影响已有会员。"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.downxcx1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.downxcxbtn1 },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }