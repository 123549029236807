var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c("div", { staticClass: "page-section" }, [
            _c("h3", { staticClass: "h-title" }, [_vm._v("内容管理")]),
            _vm._v(" "),
            _c(
              "section",
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.searchForm,
                      "status-icon": "",
                      "label-suffix": ":",
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "orgId" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "发布范围", prop: "orgId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          loading: _vm.loading,
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchForm.orgId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "orgId",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.orgId",
                                        },
                                      },
                                      _vm._l(_vm.orgList, function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.orgName,
                                            value: item.pId,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "contentName" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "名称",
                                      prop: "contentName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.contentName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "contentName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.contentName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "isEnable" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "分类",
                                      prop: "contentType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.contentType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "contentType",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.contentType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "培训", value: 1 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "政策", value: 2 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开始日期",
                                      prop: "startTime",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.searchForm.startTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "startTime",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.startTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "结束日期",
                                      prop: "endTime",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.searchForm.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "endTime",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.endTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.handleShow ||
                                      !_vm.formConfig.btnFollow,
                                    expression:
                                      "handleShow||!formConfig.btnFollow",
                                  },
                                ],
                                attrs: { span: 6, prop: "isEnable" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发布对象",
                                      prop: "scopeId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.roleType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "roleType",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.roleType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "企业用户",
                                            value: "000",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "经销商用户",
                                            value: "101",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "终端用户",
                                            value: "102",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "供应商用户",
                                            value: "200",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchForm.current = 1
                                                _vm.getList()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                            },
                                            on: { click: _vm.resetForm },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                _vm.handleShow = !_vm.handleShow
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.handleShow
                                                    ? "收起"
                                                    : "展开"
                                                ) +
                                                "\n                  "
                                            ),
                                            _c("i", {
                                              class: _vm.handleShow
                                                ? "el-icon-arrow-up"
                                                : "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c("el-divider", { staticClass: "btnDivider" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btnArea" },
                  [
                    !_vm.formConfig.btnFollow
                      ? _c(
                          "el-col",
                          {
                            staticClass: "head-btn-group",
                            staticStyle: { width: "230px" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.searchForm.current = 1
                                    _vm.getList()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.resetForm },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    _vm.handleShow = !_vm.handleShow
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.handleShow ? "收起" : "展开") +
                                    "\n            "
                                ),
                                _c("i", {
                                  class: _vm.handleShow
                                    ? "el-icon-arrow-up"
                                    : "el-icon-arrow-down",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _vm.hasAuth("addbtn", _vm.apis)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.addBtn.icon,
                                },
                                on: { click: _vm.add },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.tabHeight,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        align: _vm.tableConfig.align,
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "contentName",
                        label: "名称",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "contentType",
                        label: "分类",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("contentType")(row.contentType))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        155506374
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "startTime",
                        label: "开始时间",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("textDateOnlyDate")(row.startTime)
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3870383546
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "endTime",
                        label: "结束时间",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("textDateOnlyDate")(row.endTime)
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2380062549
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "创建人",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "创建日期",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("textDateOnlyDate")(row.createTime)
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        567553182
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "factoryName",
                        label: "发布范围",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("orgInfos")(row.orgInfos))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2578107974
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "factoryName",
                        label: "发布对象",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("roleType")(row.roleType))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        895950886
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "160",
                        align: _vm.tableConfig.align,
                        fixed: "right",
                        "class-name": "small-padding fixed-width",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm.hasAuth("detailbtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.scan(row)
                                          },
                                        },
                                      },
                                      [_vm._v("详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasAuth("updatebtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit(row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.isEnable != 2 &&
                                _vm.hasAuth("deletebtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(row.pid)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4194904149
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.searchForm.current,
                    limit: _vm.searchForm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasAuth("searchbtn", _vm.apis),
              expression: "!hasAuth('searchbtn',apis)",
            },
          ],
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                "append-to-body": true,
                title: _vm.title,
                visible: _vm.dialogVisible,
                "before-close": _vm.handleClose1,
                width: "1200px !important",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.mloading,
                      expression: "mloading",
                    },
                  ],
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "append-to-body": true,
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "培训名称", prop: "contentName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.ruleForm.contentName,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.ruleForm.contentName =
                                      _vm.validTe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "培训分类", prop: "scopeId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.ruleForm.scopeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "scopeId", $$v)
                                    },
                                    expression: "ruleForm.scopeId",
                                  },
                                },
                                _vm._l(_vm.scopeList, function (item) {
                                  return _c("el-option", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.contentType == 1,
                                        expression: "item.contentType==1",
                                      },
                                    ],
                                    key: item.scopeId,
                                    attrs: {
                                      label: item.describe,
                                      value: item.scopeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "培训范围", prop: "orgIds" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: true,
                                  "flatten-search-results": true,
                                  placeholder: "请选择...",
                                  options: _vm.orgOptions,
                                  limit: 1,
                                },
                                model: {
                                  value: _vm.ruleForm.orgIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "orgIds", $$v)
                                  },
                                  expression: "ruleForm.orgIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "培训机构类型",
                                prop: "roleType",
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: true,
                                  "flatten-search-results": true,
                                  placeholder: "请选择...",
                                  options: _vm.roleType,
                                  limit: 1,
                                },
                                model: {
                                  value: _vm.ruleForm.roleType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "roleType", $$v)
                                  },
                                  expression: "ruleForm.roleType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "培训日期", prop: "daterange" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  format: "yyyy-MM-dd",
                                  "value-format": "timestamp",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.ruleForm.daterange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "daterange", $$v)
                                  },
                                  expression: "ruleForm.daterange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "培训说明", prop: "contentInfo" },
                            },
                            [
                              _c("tiny-mce", {
                                ref: "contenta",
                                staticStyle: { width: "100%" },
                                attrs: { "tinymce-height": 200 },
                                model: {
                                  value: _vm.ruleForm.contentInfo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "contentInfo", $$v)
                                  },
                                  expression: "ruleForm.contentInfo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "附件", prop: "appendixUrl" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "",
                                    "auto-upload": false,
                                    "on-remove": _vm.handleRemove,
                                    "on-change": _vm.uploadfile,
                                    "file-list": _vm.fileList,
                                    "show-file-list": false,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        loading: _vm.uploading,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.ruleForm.appendixUrl
                                            ? "重新上传"
                                            : "点击上传"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.ruleForm.appendixUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "appendixUrl", $$v)
                                  },
                                  expression: "ruleForm.appendixUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开启状态", prop: "isEnable" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "启用",
                                  "inactive-text": "禁用",
                                },
                                model: {
                                  value: _vm.ruleForm.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                  },
                                  expression: "ruleForm.isEnable",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(1)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose1 } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.zcVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                "append-to-body": true,
                title: _vm.title,
                visible: _vm.zcVisible,
                "before-close": _vm.handleClose,
                width: "1200px !important",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zcVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.mloading,
                      expression: "mloading",
                    },
                  ],
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    "append-to-body": true,
                    model: _vm.ruleForm,
                    rules: _vm.rules1,
                    "label-width": "120px",
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "政策名称", prop: "contentName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.ruleForm.contentName,
                                  maxlength: "20",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.ruleForm.contentName =
                                      _vm.validTe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "政策类型", prop: "scopeId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    "collapse-tags": "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.ruleForm.scopeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "scopeId", $$v)
                                    },
                                    expression: "ruleForm.scopeId",
                                  },
                                },
                                _vm._l(_vm.scopeList, function (item) {
                                  return _c("el-option", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.contentType == 2,
                                        expression: "item.contentType==2",
                                      },
                                    ],
                                    key: item.scopeId,
                                    attrs: {
                                      label: item.describe,
                                      value: item.scopeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公开区域", prop: "orgIds" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: true,
                                  "flatten-search-results": true,
                                  placeholder: "请选择...",
                                  options: _vm.orgOptions,
                                  limit: 1,
                                },
                                model: {
                                  value: _vm.ruleForm.orgIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "orgIds", $$v)
                                  },
                                  expression: "ruleForm.orgIds",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公开范围", prop: "roleType" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: true,
                                  "flatten-search-results": true,
                                  placeholder: "请选择...",
                                  options: _vm.roleType,
                                  limit: 1,
                                },
                                model: {
                                  value: _vm.ruleForm.roleType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "roleType", $$v)
                                  },
                                  expression: "ruleForm.roleType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "政策日期", prop: "daterange" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  format: "yyyy-MM-dd",
                                  "value-format": "timestamp",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.ruleForm.daterange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "daterange", $$v)
                                  },
                                  expression: "ruleForm.daterange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "政策内容", prop: "contentInfo" },
                            },
                            [
                              _c("tiny-mce", {
                                ref: "content",
                                staticStyle: { width: "100%" },
                                attrs: { "tinymce-height": 200 },
                                model: {
                                  value: _vm.ruleForm.contentInfo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "contentInfo", $$v)
                                  },
                                  expression: "ruleForm.contentInfo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "附件", prop: "appendixUrl" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "",
                                    limit: 1,
                                    "auto-upload": false,
                                    "on-remove": _vm.handleRemove,
                                    "on-change": _vm.uploadfile,
                                    "file-list": _vm.fileList,
                                    "show-file-list": false,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "primary",
                                        loading: _vm.uploading,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.ruleForm.appendixUrl
                                            ? "重新上传"
                                            : "点击上传"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.ruleForm.appendixUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "appendixUrl", $$v)
                                  },
                                  expression: "ruleForm.appendixUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开启状态", prop: "isEnable" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "启用",
                                  "inactive-text": "禁用",
                                },
                                model: {
                                  value: _vm.ruleForm.isEnable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                  },
                                  expression: "ruleForm.isEnable",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(2)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "请选择添加类型",
            visible: _vm.centerDialogVisible,
            width: "400px !important",
            center: "",
            "before-close": _vm.centerClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPX } },
                [_vm._v("培训")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addZC } },
                [_vm._v("政策")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.scanVisible,
            "before-close": _vm.scanClose,
            width: "1200px !important",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v(
                          _vm._s(
                            _vm.scanData.contentType == 1
                              ? "培训名称"
                              : "政策名称"
                          ) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.scanData.contentName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v(
                          _vm._s(
                            _vm.scanData.contentType == 1
                              ? "培训分类"
                              : "政策类型"
                          ) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.scanData.scopeName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("开始日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("textDateOnlyDate")(_vm.scanData.startTime)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("结束日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("textDateOnlyDate")(_vm.scanData.endTime)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("开启状态:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm.scanData.isEnable == 0
                              ? "关闭"
                              : _vm.scanData.isEnable == 1
                              ? "开启"
                              : ""
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.scanData.contentType == 1 ? "培训范围" : "公开区域"
                  )
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.scanData.orgInfos, function (item) {
                return _c(
                  "el-tag",
                  {
                    key: item.orgId,
                    staticStyle: {
                      "margin-right": "10px",
                      "margin-bottom": "10px",
                    },
                    attrs: { effect: "plain" },
                  },
                  [_vm._v(_vm._s(item.orgName))]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.scanData.contentType == 1 ? "培训机构类型" : "公开范围"
                  )
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.scanData.roleList, function (item, idx) {
                return _c(
                  "el-tag",
                  {
                    key: idx,
                    staticStyle: {
                      "margin-right": "10px",
                      "margin-bottom": "10px",
                    },
                  },
                  [_vm._v(_vm._s(_vm._f("roleName")(item)))]
                )
              }),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.scanData.contentType == 1 ? "培训说明" : "政策内容"
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.scanData.contentInfo) },
                    }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("附件")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-link",
                    {
                      attrs: {
                        href: _vm.scanData.appendixUrl,
                        underline: false,
                      },
                    },
                    [_vm._v(_vm._s(_vm.scanData.appendixUrl))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.scanClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.PolicyDialog
        ? _c("policySelect", {
            attrs: { "pro-sel-visible": _vm.PolicyDialog },
            on: {
              change: _vm.getPolice,
              close: function ($event) {
                _vm.PolicyDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }