"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _pwdconfig = require("@/api/systems/pwdconfig");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systempwdConfigindex';
var _default = {
  name: cachName,
  data: function data() {
    return {
      ruleForm: {
        accountDisableMechanism: null,
        accountLockMechanism: '',
        forceChangePwdFirstLogin: null,
        pId: '',
        passwordExpiration: null,
        requireSmsVerification: 0,
        strongCheck: null,
        weakCheck: null,
        pwdErrCount: null,
        pwdLockCount: null
      },
      loading: false,
      rules: {
        accountDisableMechanism: [{
          required: true,
          message: '请输入锁定限制次数',
          trigger: 'change'
        }],
        pwdErrCount: [{
          required: true,
          message: '请输入密码错误限制次数',
          trigger: 'change'
        }],
        pwdLockCount: [{
          required: true,
          message: '请输入锁定时间',
          trigger: 'change'
        }],
        requireSmsVerification: [{
          required: false,
          message: '请选择短信验证码使用方式限制',
          trigger: 'change'
        }],
        passwordExpiration: [{
          required: true,
          message: '请输入密码有效期',
          trigger: 'change'
        }],
        forceChangePwdFirstLogin: [{
          required: true,
          message: '请选择首次登陆修改密码限制条件',
          trigger: 'change'
        }],
        weakCheck: [{
          required: true,
          message: '请输入强弱口令检查',
          trigger: 'change'
        }],
        strongCheck: [{
          required: true,
          message: '请选择轻度检查',
          trigger: 'change'
        }]
      },
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 122 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getDetail();
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.ruleForm = {
        accountDisableMechanism: null,
        accountLockMechanism: '',
        forceChangePwdFirstLogin: null,
        pId: '',
        passwordExpiration: null,
        requireSmsVerification: 0,
        strongCheck: null,
        weakCheck: null,
        pwdErrCount: null,
        pwdLockCount: null
      };
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.data.code != 200) {
          _this.loading = false;
          _this.$message.error(res.data.data.msg);
          return;
        }
        if (res.data.data.data && res.data.data.data.length > 0) {
          var item = res.data.data.data[0];
          _this.ruleForm = {
            accountDisableMechanism: item.accountDisableMechanism,
            accountLockMechanism: item.accountLockMechanism,
            forceChangePwdFirstLogin: item.forceChangePwdFirstLogin,
            pId: item.pid,
            passwordExpiration: item.passwordExpiration,
            requireSmsVerification: item.requireSmsVerification,
            strongCheck: item.strongCheck,
            weakCheck: item.weakCheck,
            pwdErrCount: item.accountLockMechanism.split(',')[0],
            pwdLockCount: item.accountLockMechanism.split(',')[1]
          };
        }
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 限制有效期
    getExpectionNum: function getExpectionNum(e) {
      // 最小值为1
      if (e) {
        if (e == 0 || e == '0') {
          this.ruleForm.passwordExpiration = 1;
        } else {
          this.ruleForm.passwordExpiration = Number(e);
        }
      } else {
        this.ruleForm.passwordExpiration = null;
      }
    },
    // 限制密码输入次数
    getInputErrNum: function getInputErrNum(e) {
      // 最小值为1
      if (e) {
        if (e == 0 || e == '0') {
          this.ruleForm.pwdErrCount = 1;
        } else {
          this.ruleForm.pwdErrCount = Number(e);
        }
      } else {
        this.ruleForm.pwdErrCount = null;
      }
    },
    // 限制锁定时间
    getLockTime: function getLockTime(e) {
      // 最小值为1
      if (e) {
        if (e == 0 || e == '0') {
          this.ruleForm.pwdLockCount = 1;
        } else {
          this.ruleForm.pwdLockCount = Number(e);
        }
      } else {
        this.ruleForm.pwdLockCount = null;
      }
    },
    // 限制锁定次数
    getLockNum: function getLockNum(e) {
      // 最小值为1
      if (e) {
        if (e == 0 || e == '0') {
          this.ruleForm.accountDisableMechanism = 1;
        } else {
          this.ruleForm.accountDisableMechanism = Number(e);
        }
      } else {
        this.ruleForm.accountDisableMechanism = null;
      }
    },
    saveSettings: function saveSettings(formName) {
      var _this2 = this;
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          var item = (0, _utils.deepClone)(that.ruleForm);
          var params = {
            accountDisableMechanism: item.accountDisableMechanism,
            accountLockMechanism: item.pwdErrCount + ',' + item.pwdLockCount,
            forceChangePwdFirstLogin: item.forceChangePwdFirstLogin,
            pId: item.pId,
            passwordExpiration: item.passwordExpiration,
            requireSmsVerification: item.requireSmsVerification,
            strongCheck: item.strongCheck,
            weakCheck: item.weakCheck
          };
          (0, _pwdconfig.updatePwdRule)(params).then(function (res) {
            _this2.loading = true;
            if (res.data.data.code != 200) {
              that.loading = false;
              return _this2.$message.error(res.data.data.msg);
            }
            _this2.$message({
              message: '更新配置成功',
              type: 'success'
            });
            _this2.getDetail();
          }).catch(function (res) {
            that.loading = false;
            return _this2.$message.error(res.data.data.msg);
          });
        }
      });
    }
  }
};
exports.default = _default;