"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _BarChart = _interopRequireDefault(require("@/components/dataAnalyse/BarChart"));
var _fc = require("@/api/dataAnalyse/fc.js");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _BarChart.default,
    Pagination: _Pagination.default,
    ChannelSelectDialog: _channelSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      listLoading: false,
      sweepBtn: false,
      downLoading: false,
      total: 0,
      dateValue: [],
      formInline: {
        timeType: '1',
        startTime: '',
        endTime: '',
        dealerId: '',
        dealerName: ''
      }
    };
  },
  created: function created() {
    this.antiChanDealerRankFun('1');
  },
  methods: {
    dataFun: function dataFun(res, page) {
      if (!page) {
        var xData = [];
        var sData = [];
        res.data.forEach(function (item) {
          xData.push(item.dealerName);
          sData.push(item.count);
        });
        this.$refs.barChart.xData = xData;
        this.$refs.barChart.sData = sData;
        this.$refs.barChart.initChart();
      }
      this.list = res.data || [];
      this.total = res.count || 0;
    },
    // 获取数据
    antiChanDealerRankFun: function antiChanDealerRankFun(type, page) {
      var _this2 = this;
      if (type) {
        this.formInline.timeType = type;
      }
      if (type !== '2' && type) {
        this.dateValue = [];
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      if (!page) {
        this.formInline.page = 1;
      }
      (0, _fc.antiChanDealerRank)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this2.dataFun(res.data, page);
        }
      });
    },
    // 时间选择
    dateFun: function dateFun(val, page) {
      if (val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.antiChanDealerRankFun('3');
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
        this.antiChanDealerRankFun('1');
      }
    },
    // 经销商选择
    inputFocusFun: function inputFocusFun() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    // 经销商选择
    setCurrent: function setCurrent(row) {
      this.formInline.dealerName = row.channelName;
      this.formInline.dealerId = row.pId;
      this.formInline.page = 1;
      this.antiChanDealerRankFun('', 'page');
    },
    clearFun: function clearFun() {
      if (this.formInline.dealerName) {
        this.formInline.dealerName = '';
        this.formInline.dealerId = '';
        this.formInline.page = 1;
        this.antiChanDealerRankFun('', 'page');
      }
    },
    fcPagingFun: function fcPagingFun() {
      this.antiChanDealerRankFun('', 'page');
    },
    // 下载
    downFun: function downFun() {
      // scurrystatDealerExport({ ...this.formInline }).then(res => {
      //   var blob = new Blob([res], {
      //     type:
      //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      //   })

      //   const elink = document.createElement('a')
      //   if ('download' in elink) {
      //     // 非IE下载
      //     elink.download = fileName
      //     elink.style.display = 'none'
      //     elink.href = URL.createObjectURL(blob)
      //     document.body.appendChild(elink)
      //     elink.click()
      //     URL.revokeObjectURL(elink.href) // 释放URL 对象
      //     document.body.removeChild(elink)
      //   } else {
      //     // IE10+下载
      //     navigator.msSaveBlob(blob, fileName)
      //   }
      // })
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outoTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      var fileName;
      if (this.formInline.timeType === '1') {
        fileName = '本月疑似窜货经销商排行.xlsx';
      } else if (this.formInline.timeType === '2') {
        fileName = '本年疑似窜货经销商排行.xlsx';
      } else if (this.formInline.timeType === '3') {
        fileName = this.formInline.startTime + '至' + this.formInline.endTime + '疑似窜货经销商排行.xlsx';
      }
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        fileName);
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;