var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "orgId", label: "所属组织:" },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织...",
                                      options: _vm.orgOptions,
                                    },
                                    model: {
                                      value: _vm.listQuery.orgId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orgId", $$v)
                                      },
                                      expression: "listQuery.orgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "startTime",
                                        label: "提交起始日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "选择日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.listQuery.startTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "startTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.startTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "endTime",
                                        label: "提交结束日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          placeholder: "选择日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.listQuery.endTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "endTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.endTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelArchiveName",
                                    label: "经销商:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      clear: _vm.clearJxs,
                                      focus: function ($event) {
                                        return _vm.getUnit("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.listQuery.channelArchiveName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "channelArchiveName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.channelArchiveName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "no", label: "核算申请单号:" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.no,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "no", $$v)
                                      },
                                      expression: "listQuery.no",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression: "showCol||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "moneyType", label: "状态:" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.listQuery.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "status", $$v)
                                        },
                                        expression: "listQuery.status",
                                      },
                                    },
                                    _vm._l(_vm.status, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(_vm.listQuery)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.formConfig.btnFollow
                ? _c(
                    "div",
                    { staticClass: "btnArea" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset(_vm.listQuery)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "核算申请单号",
                          prop: "no",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "组织",
                          prop: "orgName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商编号",
                          prop: "channelCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商名称",
                          prop: "channelArchiveName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "核算金额(元)",
                          prop: "money",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "核算时间",
                          prop: "updateTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          prop: "status",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.status == "0"
                                    ? _c("span", [_vm._v("待审")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status == "1"
                                    ? _c("span", [_vm._v("审核通过")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status == "2"
                                    ? _c("span", [_vm._v("审核拒绝")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status == "3"
                                    ? _c("span", [_vm._v("删除/撤回")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2011958577
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.apis) ||
                      _vm.hasAuth("verifybtn", _vm.apis)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "120",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm.hasAuth("detailbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getDetail(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("详情")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.hasAuth("verifybtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    disabled: row.status != 0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getSh(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("审核")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2965859050
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.pageNo,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("channel-select-dialog", {
        ref: "channelSelect",
        attrs: { channelType: 1, channelTypeCanChange: true },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("detailsflow", { ref: "detailflow" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }