var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "海报编码/名称",
                                prop: "posterCodeAndName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.posterCodeAndName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "posterCodeAndName",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.posterCodeAndName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "终端编码/名称",
                                prop: "relevancyTerminalAndId",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.relevancyTerminalAndId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "relevancyTerminalAndId",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.relevancyTerminalAndId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "状态", prop: "activityStatus" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择关联终端" },
                                  model: {
                                    value: _vm.ruleForm.activityStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "activityStatus",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.activityStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "未开始", value: "0" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "进行中", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "已结束", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "时间范围",
                                prop: "dataTimeRange",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.ruleForm.dataTimeRange,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "dataTimeRange", $$v)
                                  },
                                  expression: "ruleForm.dataTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          style: { width: _vm.formConfig.btnArea },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.ruleForm.pageNo = 1
                                      _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.addBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.addBtn.icon,
                  },
                  on: { click: _vm.creatAct },
                },
                [_vm._v("\n        创建海报\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "80",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "海报编码",
                      align: _vm.tableConfig.align,
                      prop: "posterCode",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "海报名称",
                      align: _vm.tableConfig.align,
                      prop: "posterName",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "海报照片",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-image", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: {
                                src: row.backgroundUrl,
                                "preview-src-list": [row.backgroundUrl],
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "终端编码",
                      align: _vm.tableConfig.align,
                      prop: "terminalId",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "终端名称",
                      align: _vm.tableConfig.align,
                      prop: "relevancyTerminal",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "活动状态",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.activityStatus == 0
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("未开始"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("进行中"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == 2
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("已结束"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == 3
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("停用"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "海报链接",
                      align: _vm.tableConfig.align,
                      prop: "posterUrl",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "创建人",
                      align: _vm.tableConfig.align,
                      prop: "creator",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "创建时间",
                      align: _vm.tableConfig.align,
                      prop: "createTime",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "备注",
                      align: _vm.tableConfig.align,
                      prop: "commentInfo",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: scope.row.activityStatus != 3,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: scope.row.activityStatus == 2,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setDisable(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.activityStatus == "3"
                                      ? "启用"
                                      : "禁用"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.upimageQR(scope.row)
                                  },
                                },
                              },
                              [_vm._v("导出")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.ruleForm.pageNo,
                  limit: _vm.ruleForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.ruleForm, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.ruleForm, "pageSize", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("actConfig", {
            ref: "content",
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              type: _vm.type,
              data: _vm.dataList,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("imageQRcode", {
        ref: "imgCode",
        attrs: { resultimg: _vm.img, finalimg: _vm.img2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }