var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "材料出库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          readonly: true,
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "NC单号:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "20",
                          "show-word-limit": "",
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.NCNO = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.NCNO,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "NCNO", $$v)
                          },
                          expression: "scanData.NCNO",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货单位:",
                        prop: "billoutOtherAO.outOrgId",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            disabled: _vm.title == "修改材料出库",
                            readonly: "",
                          },
                          model: {
                            value: _vm.outComName,
                            callback: function ($$v) {
                              _vm.outComName = $$v
                            },
                            expression: "outComName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              disabled: _vm.title == "修改材料出库",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getInUnit("out")
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货单位:",
                        prop: "billoutOtherAO.inOrgId",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            disabled: _vm.title == "修改材料出库",
                            readonly: "",
                          },
                          model: {
                            value: _vm.inComName,
                            callback: function ($$v) {
                              _vm.inComName = $$v
                            },
                            expression: "inComName",
                          },
                        },
                        [
                          _vm.billCode == "QLT_THCK"
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany(
                                      "inComName",
                                      33,
                                      _vm.scanData.billoutOtherAO.outOrgId
                                    )
                                  },
                                },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货库房:",
                        prop: "billoutOtherAO.outStoreId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            disabled: _vm.title == "修改材料出库",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.scanData.billoutOtherAO.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutOtherAO,
                                "outStoreId",
                                $$v
                              )
                            },
                            expression: "scanData.billoutOtherAO.outStoreId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据备注:", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "15",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.scanData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "remark", $$v)
                          },
                          expression: "scanData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _vm.title == "添加材料出库"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.openSel },
                            },
                            [_vm._v("添加产品")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.proList,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                            "max-height": "500",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: "center",
                              width: "65",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              align: "center",
                              prop: "productCode",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              align: "center",
                              prop: "productName",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              align: "center",
                              prop: "packScaleExpression",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.packScaleDesc
                                            ? row.packScaleDesc
                                            : row.packScaleExpression
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "总瓶数",
                              align: _vm.tableConfig.align,
                              prop: "planScanAtoNum",
                              width: "160",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "planScanAtoNum",
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            type: "number",
                                            controls: false,
                                            placeholder: "请输入",
                                            maxlength: "6",
                                            min: 0,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setSumNew(
                                                $event,
                                                row,
                                                "box"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.planScanAtoNum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                "planScanAtoNum",
                                                $$v
                                              )
                                            },
                                            expression: "row.planScanAtoNum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "箱数",
                              prop: "planScanNum",
                              align: "center",
                              "min-width": "160",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "零瓶数",
                              prop: "bottleNumOver",
                              align: "center",
                              "min-width": "160",
                            },
                          }),
                          _vm._v(" "),
                          _vm.title == "添加材料出库"
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  "min-width": "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm.title == "添加材料出库"
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.proDel(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3992345491
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.submitType != 3
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("提 交")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(3)
                    },
                  },
                },
                [_vm._v("提 交")]
              ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "",
              "request-enum": _vm.requestEnum,
              "pro-sel-visible": _vm.proShow,
              data: _vm.proList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              "can-change": _vm.factorycanChange,
              "company-type": _vm.factoryType,
              "is-enable": _vm.isEnable,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "in-out-type": _vm.inOutType,
          "shou-fa-type": _vm.shouFaType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "org-id": _vm.orgId,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.typeVisible
        ? _c("storeTypeSelect", {
            attrs: { "type-sel-visible": _vm.typeVisible, "order-type": 2 },
            on: { handerClose: _vm.closeType, change: _vm.getType },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }