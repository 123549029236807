"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _source = require("@/api/systems/source");
var _dictionary = require("@/api/systems/dictionary");
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  props: {
    formData: Object,
    dialogVisible: Boolean,
    type: String
  },
  data: function data() {
    var checkPageNo = function checkPageNo(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入定位页码'));
      } else if (!/^[0-9]*$/.test(Number(value))) {
        return callback(new Error('请输入数字值'));
      } else {
        callback();
      }
    };
    return {
      resourceTypes: [{
        name: '菜单',
        id: '1'
      }, {
        name: '按钮',
        id: '2'
      }
      // {
      //   name: '页面',
      //   id: '3'
      // }
      ],

      cilentTypes: [],
      rules: {
        resourceCode: [{
          required: true,
          message: '请输入资源编码',
          trigger: 'blur'
        }],
        resourceName: [{
          required: true,
          validator: _source.checkRequiredWord,
          trigger: 'blur'
        }],
        menuClass: [{
          required: true,
          message: '请选择资源分类',
          trigger: 'change'
        }],
        // resourcePath: [
        //   {
        //     required: true,
        //     message: '请输入资源URL',
        //     trigger: 'blur'
        //   }
        // ],
        // resourceUrl: [
        //   {
        //     required: true,
        //     message: '请输入页面URL',
        //     trigger: 'blur'
        //   }
        // ],
        resourceType: [{
          required: true,
          message: '请选择资源类型',
          trigger: 'change'
        }],
        resourceId: [{
          required: true,
          validator: _source.checkId,
          trigger: 'blur'
        }],
        // manualPage: [
        //   {
        //     required: true,
        //     validator: checkPageNo,
        //     trigger: 'blur'
        //   }
        // ],
        resourceSort: [{
          validator: _source.checkNum,
          required: true,
          trigger: 'blur'
        }]
      },
      dstate: false,
      mloading: false,
      key: '',
      level: null,
      options: [],
      // apiInfoIds: [],
      loading: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var that = this;
      if (val == true) {
        that.level = that.$parent.editData.resourceLevel;
        // if (that.$parent.editData.resourceType == '1') {
        //   that.rules.resourceUrl[0].required = true
        // } else if (that.$parent.editData.resourceType == '2') {
        //   that.rules.resourceUrl[0].required = false
        // }
        var param = {
          current: 1,
          keywords: '',
          size: 1000
        };
        that.getApiList(param);
        // that.getChecked(that.formData.pid)
        this.getsuoshu();
      }
    }
  },
  methods: {
    getsuoshu: function getsuoshu() {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CLIENT_CODE'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.cilentTypes = res.data.data;
          } else {
            _this.cilentTypes = [];
          }
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('formData');
    },
    // getChecked(id) {
    //   const that = this
    //   var param = { resourceId: id }
    //   getApis(param).then((res) => {
    //     console.log(res)
    //     if (res.data.code == 200) {
    //       if (res.data.data && res.data.data.length > 0) {
    //         that.apiInfoIds = res.data.data.map((item) => {
    //           return item.pId
    //         })
    //       }
    //     }
    //   })
    // },
    remoteMethod: function remoteMethod(query) {
      var that = this;
      var param = {
        current: 1,
        keywords: '',
        size: 1000
      };
      if (query !== '') {
        that.loading = true;
        param.keywords = query;
        that.getApiList(param);
      }
    },
    getApiList: function getApiList(param) {
      var that = this;
      (0, _work.getApiList)(param).then(function (res) {
        console.log(res.data);
        that.loading = false;
        if (res.data.code == 200) {
          that.options = res.data.data.records;
        } else {
          that.options = [];
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.mloading = true;
          that.dsate = true;
          var data = that.$parent.editData;
          var param = {};
          // param.apiInfoIds = that.apiInfoIds
          param.clientCode = data.clientCode;
          // param.manualPage = data.manualPage
          param.parentCode = data.parentCode;
          param.menuClass = data.menuClass;
          // param.pid = data.pid
          param.resourceCode = data.resourceCode;
          param.resourceName = data.resourceName;
          param.resourceType = data.resourceType;
          param.resourceId = data.resourceId;
          param.resourceUrl = data.resourceUrl;
          var arr = [];
          if (data.resourcePath.length > 0) {
            data.resourcePath.forEach(function (el) {
              arr.push(el.url);
            });
          }
          param.resourcePath = arr;
          // param.resourcePath = data.resourcePath
          param.resourceSort = data.resourceSort;
          param.remark = data.remark;
          param = (0, _utils.filterKeyNull)(param);
          (0, _source.editResource)(param).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.handleClose();
              that.$parent.getList();
              that.mloading = false;
              that.dsate = false;
            } else {
              that.$message.error(res.data.msg);
              that.mloading = false;
              that.dsate = false;
              return;
            }
          }).catch(function () {
            that.mloading = false;
            that.dsate = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      // that.apiInfoIds = []
      that.$refs[formName].resetFields();
      that.$emit('close');
    },
    getKey: function getKey(val) {
      var that = this;
      that.key = val;
      // if (that.key == '1') {
      //   that.rules.resourceUrl[0].required = true
      // } else if (that.key == '2') {
      //   that.rules.resourceUrl[0].required = false
      // }
    },
    addurl: function addurl() {
      this.formData.resourcePath.push({
        url: ''
      });
    },
    deleteurl: function deleteurl(index) {
      this.formData.resourcePath.splice(index, 1);
    }
  }
};
exports.default = _default;