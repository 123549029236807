"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _ordergl = require("@/api/warehouse/ordergl");
var _index = require("@/api/warehouse/checkout/index");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default,
    factorySelectDialog: _factorySelectDialog.default
  },
  filters: {
    getRowNumber: function getRowNumber(index) {
      index = (Number(index) + 1) * 10;
      return (Array(4).join('0') + index).slice(-4);
    },
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = (sum * arr[le - 1]).toFixed(0);
      // miniUnit = num
      row.planScanAtoNum = num * 1;
      return num * 1;
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1].unitName;
      } else if (arr.length == 3) {
        name = arr[2].unitName;
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0].unitName;
      }
      return name;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.productList.length == 0 || _this.productList == '' || _this.productList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '添加调拨出库',
      billType: '',
      outComName: '',
      inComName: '',
      inUseName: '',
      tableKey: 'pId',
      listLoading: false,
      factoryShow: false,
      justShow: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        billoutTransAO: {},
        receiptUser: ''
      },
      rowError: [],
      scanData1: {
        billNo: '',
        billType: 202,
        receiptUser: '',
        billoutTransAO: {
          inOrgId: '',
          inOrgType: '',
          inStoreId: '',
          outOrgId: '',
          outOrgType: '',
          outStoreId: ''
        },
        sumPlanAtoNum: 0,
        sumPlanNum: 0,
        tradeBillDetailAOS: []
      },
      disabled: {
        num: false
      },
      orgList: [],
      storeList: [],
      productList: [],
      rules: {
        billNo: [{
          required: true,
          message: '调拨出库单号不能为空，请维护',
          trigger: 'change'
        }],
        'billoutTransAO.outOrgId': [{
          required: true,
          message: '请选择发货单位',
          trigger: 'change'
        }],
        'billoutTransAO.inOrgId': [{
          required: true,
          message: '请选择收货单位',
          trigger: 'change'
        }],
        'billoutTransAO.outStoreId': [{
          required: true,
          message: '请选择发库库房',
          trigger: 'change'
        }],
        materialCode: [{
          required: true,
          trigger: 'change',
          validator: validatematerialCode
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    (0, _org.fetchOrgList)({
      orgTypeList: ['2', '3']
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          that.orgList = res.data.data;
        } else {
          that.orgList = [];
        }
      }
    });
  },
  methods: {
    init: function init() {
      this.title = '添加调拨出库';
      this.listLoading = false;
      this.btn_loading = false;
      this.isUpdate = true;
      this.storeList = [];
      this.productList = [];
      this.outComName = '';
      this.inComName = '';
      this.scanData = JSON.parse(JSON.stringify(this.scanData1));
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.getCreateNum();
      this.dialogVisible = true;
      this.mloading = false;
    },
    upDateShow: function upDateShow(row) {
      var _this2 = this;
      this.init();
      this.title = '修改调拨出库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      this.dialogVisible = true;
      (0, _index.getPeoductionDetail)(row.billNo).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var data = res.data.data;
          _this2.scanData.billNo = data.tradeBillAllVO.billNo;
          _this2.scanData.billSource = data.tradeBillAllVO.billSource;
          _this2.scanData.billType = data.tradeBillAllVO.billType;
          _this2.scanData.billoutTransAO.outOrgId = data.tradeBillAllVO.outOrgId;
          _this2.outComName = data.tradeBillAllVO.outOrgName;
          _this2.scanData.billoutTransAO.inOrgId = data.tradeBillAllVO.inOrgId;
          _this2.inComName = data.tradeBillAllVO.inOrgName;
          _this2.scanData.receiptUser = data.tradeBillAllVO.receiptUser;
          _this2.orgList.filter(function (item) {
            if (item.orgName == data.tradeBillAllVO.outOrgName) {
              _this2.scanData.billoutTransAO.outOrgType = item.orgType == 2 ? 2 : 1;
            }
            if (item.orgName == data.tradeBillAllVO.inOrgName) {
              _this2.scanData.billoutTransAO.inOrgType = item.orgType == 2 ? 2 : 1;
            }
          });
          (0, _ordergl.byOrgFactoryId)({
            orgOrFactoryId: data.tradeBillAllVO.outOrgId
          }).then(function (res) {
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              if (res.data.data != null) {
                _this2.storeList = res.data.data;
              } else {
                _this2.storeList = [];
              }
            }
          }).then(function () {
            _this2.storeList.filter(function (val) {
              if (val.storeHouseName == data.tradeBillAllVO.outStoreName) {
                _this2.scanData.billoutTransAO.outStoreId = val.pId;
                _this2.setStore(val.pId);
              }
            });
          });
          if (data.tradeProductVOS && data.tradeProductVOS.length) {
            var arr = [];
            data.tradeProductVOS.forEach(function (item) {
              var list = item.packList;
              var list2 = [];
              list.forEach(function (val) {
                list2.push({
                  unitName: val
                });
              });
              var obj = {
                justShow: true,
                pId: item.productId,
                productCode: item.productCode,
                productName: item.productName,
                packScaleExpression: item.packRadioDesc,
                packUnit: item.packUnit,
                planScanAtoNum: item.planScanAtoNum,
                planScanNum: item.planScanNum,
                minScaleName: item.packList[item.packList.length - 1],
                prodUnit: item.packList[0],
                packScaleLevelItemDTOList: list2
              };
              console.log(item, 'item');
              console.log(item.planScanNum, 'item.planScanNum');
              console.log(obj, 'obj');
              arr.push(obj);
            });
            _this2.productList = arr;
          } else {
            _this2.productList = [];
          }
          console.log(_this2.productList, 999);
          _this2.setStore(_this2.scanData.billoutTransAO.outStoreId);
        }
        _this2.mloading = false;
      }).catch(function () {
        _this2.mloading = false;
      });
    },
    /**
     * 收发货单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 2 ：销售公司,3: 生产公司,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType, isEnable) {
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      this.isEnable = isEnable;
      this.factoryShow = true;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'outComName':
          this.scanData.billoutTransAO.outOrgId = val.pId;
          this.scanData.billoutTransAO.outOrgType = val.orgType == 2 ? 2 : 1;
          this.scanData.billoutTransAO.outStoreId = '';
          this.getStoreList(val.pId);
          break;
        case 'inComName':
          this.scanData.billoutTransAO.inOrgId = val.pId;
          this.scanData.billoutTransAO.inOrgType = val.orgType == 2 ? 2 : 1;
          break;
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factorystatus = '';
      this.isEnable = '';
      this.factoryShow = false;
    },
    getStoreList: function getStoreList(pid) {
      var _this3 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.storeList = res.data.data;
          } else {
            _this3.storeList = [];
          }
        }
      });
    },
    maxNum: function maxNum(row) {
      console.log(row);
      if (row.justShow) {
        row.justShow = false;
        return row.planScanNum;
      }
      var outNum = row.storeInventory || 0;
      return outNum;
    },
    maxNum2: function maxNum2(row) {
      var scal = row.packScaleExpression.split('*');
      var sum1 = 1;
      if (scal.length > 0) {
        // scal.forEach(item => {
        //   // sum1 = Number(item) * sum1;
        // });
        sum1 = Number(scal[scal.length - 1]);
      }
      return sum1 * row.storeInventory;
    },
    setStore: function setStore(val) {
      var _this4 = this;
      var sendComId = this.scanData.billoutTransAO.outOrgId;
      if (!this.productList.length || !val || !sendComId) {
        return;
      }
      var _loop = function _loop(i) {
        (0, _ordergl.getCurrentInventory)({
          productId: _this4.productList[i].pId,
          sendStoreId: val,
          sendComId: sendComId
        }).then(function (res) {
          _this4.productList[i].storeInventory = res.data.data || 0;
          _this4.productList[i].planScanNum = _this4.productList[i].planScanNum < res.data.data ? _this4.productList[i].planScanNum : res.data.data || 0;
          _this4.resetList();
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    getCreateNum: function getCreateNum() {
      var _this5 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        }
        _this5.scanData.billNo = res.data.data;
        _this5.disabled.num = true;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    proDel: function proDel(row, index) {
      var _this6 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.productList.splice(_this6.productList.indexOf(row), 1);
      });
    },
    submitForm: function submitForm(temp) {
      var _this7 = this;
      this.setDetail();
      if (this.rowError.length > 0) {
        this.$message.error('产品列表第' + this.rowError.join(',') + '的 计划最小单位数为零!');
        return false;
      }
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this7.btn_loading = true;
          if (_this7.submitType == 2) {
            (0, _index.InfoUpdate)(_this7.scanData).then(function (res) {
              _this7.btn_loading = false;
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
              }
            }).catch(function () {
              _this7.btn_loading = false;
            });
          } else {
            (0, _index.insertTradeBill)(_this7.scanData).then(function (res) {
              _this7.btn_loading = false;
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
              }
            }).catch(function () {
              _this7.btn_loading = false;
            });
          }
        }
      });
    },
    // 详情组合
    setDetail: function setDetail() {
      var that = this;
      that.rowError = [];
      that.scanData.tradeBillDetailAOS = [];
      that.scanData.sumPlanAtoNum = 0;
      that.scanData.sumPlanNum = 0;
      if (this.productList.length > 0) {
        this.productList.forEach(function (v, index) {
          var row = 1 + index;
          if (v.planScanAtoNum <= 0 && v.planScanNum <= 0) {
            that.rowError.push(row);
          }
          that.scanData.sumPlanNum += v.planScanNum;
          that.scanData.sumPlanAtoNum += v.planScanAtoNum * 1;
          // if (v.minScaleName === "罐") {
          //   v.planScanAtoNum = 0;
          // }
          that.scanData.tradeBillDetailAOS.push({
            billNo: that.scanData.billNo,
            packUnit: v.minScaleName,
            // 单位：箱
            productId: v.pId,
            // 产品id
            planScanNum: v.planScanNum,
            // 计划数量
            planScanAtoNum: v.planScanAtoNum // 计划数量最小单位
          });
        });
      }
    },
    handleClose: function handleClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogVisible = false;
      this.$parent.getList();
    },
    openSel: function openSel() {
      this.proShow = true;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSel: function setSel(val) {
      console.log(val, '选择');
      var arr = JSON.parse(JSON.stringify(this.productList));
      if (val.length) {
        if (this.productList.length == 0) {
          var obj = {
            prodUnit: '',
            planScanAtoNum: '',
            planScanNum: ''
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.prodUnit = item.packScaleLevelItemDTOList[0].unitName;
            item.planScanAtoNum = '';
            item.planScanNum = '';
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.prodUnit = el.prodUnit;
                item.planScanAtoNum = el.planScanAtoNum;
                item.planScanNum = el.planScanNum;
              }
            });
          });
        }
        this.productList = JSON.parse(JSON.stringify(val));
        console.log(this.productList);
      } else {
        this.productList = [];
      }
      this.setStore(this.scanData.billoutTransAO.outStoreId);
    },
    changeChestNum: function changeChestNum(e, row) {
      console.log(row);
      var that = this;
      var num = 0;
      if (Number(e) < 0) {
        num = 0;
        that.$message.error('所填数应为不小于0的正整数');
      } else {
        num = parseInt(e);
        if (Number(e) > parseInt(e)) {
          that.$message.error('所填数应为不小于0的正整数');
          row.planScanAtoNum = parseInt(e);
        }
      }
      var scal = row.packScaleExpression.split('*');
      var sum1 = 1;
      if (scal.length > 0) {
        // scal.forEach(item => {
        //   // sum1 = Number(item) * sum1;
        // });
        sum1 = Number(scal[scal.length - 1]);
      }
      row.planScanNum = parseInt(num / sum1);
    } // setSel(val) {
    //   this.proShow = false
    //   var listchat = []
    //   if (this.productList.length > 0) {
    //     this.productList.forEach((v, index) => {
    //       listchat[v.pId] = index
    //     })
    //     for (var i in val) {
    //       if (typeof listchat[val[i].pId] == 'undefined') {
    //         val[i].planScanAtoNum = 0
    //         val[i].planScanNum = 0
    //         val[i].packUnit = '箱'
    //         this.productList.push(val[i])
    //       } else {
    //         delete listchat[val[i].pId]
    //       }
    //     }
    //     for (var i in listchat) {
    //       this.productList.splice(listchat[i], 1)
    //     }
    //     return true
    //   } else {
    //     for (var i in val) {
    //       val[i].planScanAtoNum = 0
    //       val[i].planScanNum = 0
    //       val[i].packUnit = '箱'
    //     }
    //   }
    //   this.productList = val
    //   this.setStore(this.scanData.billoutTransAO.outStoreId)
    // }
  }
};
exports.default = _default;