var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "tableDialog",
      attrs: {
        title: "选择产品",
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "900px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          staticClass: "searchForm",
          attrs: { model: _vm.listQuery, inline: true },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品编号：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.listQuery.productNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "productNum", $$v)
                  },
                  expression: "listQuery.productNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品名称：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.listQuery.productName,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "productName", $$v)
                  },
                  expression: "listQuery.productName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "  " } },
            [
              _c(
                "el-button",
                {
                  staticClass: "normalBtn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.listQuery.pageNow = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": {
              "background-color": "#fafafa",
              color: "rgba(0, 0, 0,0.85)",
              "font-weight": "500",
            },
            "row-style": { color: "rgba(0, 0, 0,0.65)", "font-weight": "400" },
          },
          on: { "selection-change": _vm.moveDownList },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "newpsid", label: "产品编号" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品名称" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "productNorms", label: "规格" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "enpname", label: "包装比例" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.pageNow,
          limit: _vm.listQuery.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "pageNow", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "text-align": "center", margin: "20px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "normalBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.moveDown },
                },
                [_vm._v("加入")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "normalBtn",
                  attrs: { type: "danger" },
                  on: { click: _vm.moveUp },
                },
                [_vm._v("移除")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list1,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
            "header-cell-style": {
              "background-color": "#fafafa",
              color: "rgba(0, 0, 0,0.85)",
              "font-weight": "500",
            },
            "row-style": { color: "rgba(0, 0, 0,0.65)", "font-weight": "400" },
          },
          on: { "selection-change": _vm.moveUpList },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "newpsid", label: "产品编号" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品名称" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "productNorms", label: "规格" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "enpname", label: "包装比例" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.productUpdate },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }