"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkPositiveNum = void 0;
exports.getAllParams = getAllParams;
exports.saveParams = saveParams;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询所有渠道参数
function getAllParams() {
  return (0, _request.default)({
    url: '/api-channel/qd/channel/parameters/getAll',
    method: 'post'
  });
}

// 保存参数列表数据
function saveParams(data) {
  return (0, _request.default)({
    url: '/api-channel/qd/channel/parameters/update',
    method: 'post',
    data: data
  });
}
var checkPositiveNum = function checkPositiveNum(rule, value, callback) {
  if (value) {
    var reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d{0,2})$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正数,且只能保留两位小数'));
    }
  } else {
    return callback(new Error('请输入正数,且只能保留两位小数'));
  }
};
exports.checkPositiveNum = checkPositiveNum;