"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _task = _interopRequireDefault(require("./task"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: '',
  components: {
    productDialog: _productSelectDialog.default,
    task: _task.default
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      miniUnit = num;
      row.sumPlanAto = num;
      return num;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      tradeBillAO: {
        billNo: '',
        inOrgId: '',
        inStoreId: '',
        outOrgId: '',
        relateNo: '',
        billType: 101,
        sumPlanAtoNum: '',
        sumPlanNum: '',
        tradeBillDetailAOS: [{
          billNo: '',
          packUnit: '',
          planScanAtoNum: '',
          planScanNum: '',
          prodBatchNo: '',
          productId: ''
        }]
      },
      inOrgName: '',
      outOrgName: '',
      taskShow: false,
      factoryName: '',
      farmCode: '',
      produceLine: '',
      prodGroup: '',
      TypeDataComs: {
        factoryList: [],
        StoreList: [],
        workshopList: [],
        Productionline: [],
        Teamline: []
      },
      title: '添加生产入库',
      billType: 101,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      isUpdate: true,
      submitType: 1,
      scanData: {},
      scanData1: {
        num: '',
        // 单号
        inOrg: '',
        // 企业 id
        inStore: '',
        // 仓库 id
        superNum: '',
        // 关联单号
        descr: '',
        editAble: 0,
        outOrg: '',
        outStore: '',
        prodNum: '',
        returnDescr: '',
        returnOrg: '',
        superType: 1,
        listDetail: [],
        prodFactory: '',
        // 工厂id
        prodShop: '',
        // 车间id
        prodLine: '',
        // 产线id
        prodGroup: '' // 班组id
      },

      disabled: {
        num: false
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      proList: [{
        billNo: '',
        packRadioDesc: '',
        packUnit: '',
        planScanAtoNum: '',
        planScanNum: '',
        prodBatchNo: '',
        prodClassName: '',
        productCode: '',
        productId: '',
        productName: ''
      }],
      inOrg: {},
      rules: {
        billNo: [{
          required: true,
          message: '请输入生产入库单号',
          trigger: 'blur'
        }, {
          min: 10,
          max: 30,
          message: '长度在 10 到 15 个字符',
          trigger: 'blur'
        }],
        relateNo: [{
          required: true,
          message: '请选择生产任务单号',
          trigger: 'blur'
        }, {
          min: 10,
          max: 30,
          message: '长度在 10 到 15 个字符',
          trigger: 'blur'
        }],
        inOrg: [{
          required: true,
          message: '请选择所属企业',
          trigger: 'change'
        }],
        inStoreId: [{
          required: true,
          message: '请选择入库库房',
          trigger: 'change'
        }],
        prodFactory: [{
          required: true,
          message: '请选择工厂名称',
          trigger: 'change'
        }],
        prodShop: [{
          required: true,
          message: '请选择车间名称',
          trigger: 'change'
        }],
        prodLine: [{
          required: true,
          message: '请选择产线名称',
          trigger: 'change'
        }],
        prodGroup: [{
          required: true,
          message: '请选择班组名称',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.submitType != 2) {
          this.getCreateNum();
          this.title = '添加生产入库';
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    init: function init() {
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
      this.inOrgList = [];
      this.TypeDataComs.factoryList = [];
      this.TypeDataComs.Teamline = [];
      this.TypeDataComs.Productionline = [];
      this.TypeDataComs.workshopList = [];
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      if (this.$refs['tradeBillAO'] !== undefined) {
        this.$refs['tradeBillAO'].resetFields();
      }
      this.dialogVisible = true;
      this.getstoreHouseInfo();
      this.getfactoryList();
      this.getOutStores();
      this.mloading = false;
    },
    // 关闭添加入库弹层
    handClose: function handClose() {
      var that = this;
      that.dialogVisible = false;
      that.tradeBillAO.billNo = '';
      that.tradeBillAO.inOrgId = '';
      that.tradeBillAO.inStoreId = '';
      that.tradeBillAO.outOrgId = '';
      that.tradeBillAO.relateNo = '';
      that.tradeBillAO.tradeBillDetailAOS = [];
      that.tradeBillDetailAOS = [];
    },
    // 选择生产任务单
    getRelateNo: function getRelateNo() {
      var that = this;
      that.taskShow = true;
    },
    hideTask: function hideTask() {
      var that = this;
      that.taskShow = false;
    },
    getTask: function getTask(val) {
      var that = this;
      var value = JSON.parse(val);
      console.log(value, '任务单号');
      that.tradeBillAO.relateNo = value.orderNo;
      that.tradeBillAO.inStoreId = value.storehouseCode;
      that.setScanFactory(that.tradeBillAO.inStoreId);
      that.$refs.taskInput.focus();
      that.$refs.taskInput.blur();
      // 根据任务单号获取产品信息
      var relateType = 1;
      that.getProMsg(relateType, value.orderNo);
      that.taskShow = false;
    },
    // 获取产品信息
    getProMsg: function getProMsg(relateType, relateNo) {
      var that = this;
      (0, _select.getProDetail)(relateType, relateNo).then(function (res) {
        if (res.data.code == 200) {
          that.proList = res.data.data;
          that.tradeBillAO.tradeBillDetailAOS[0] = {};
          that.tradeBillAO.tradeBillDetailAOS[0].billNo = that.tradeBillAO.billNo;
          that.tradeBillAO.tradeBillDetailAOS[0].packUnit = res.data.data[0].packUnit;
          that.tradeBillAO.tradeBillDetailAOS[0].planScanAtoNum = res.data.data[0].planScanAtoNum;
          that.tradeBillAO.tradeBillDetailAOS[0].planScanNum = res.data.data[0].planScanNum;
          that.tradeBillAO.tradeBillDetailAOS[0].prodBatchNo = res.data.data[0].prodBatchNo;
          that.tradeBillAO.tradeBillDetailAOS[0].productId = res.data.data[0].productId;
        }
      });
    },
    // 更新
    upDateShow: function upDateShow(row) {
      var that = this;
      this.init();
      this.title = '修改生产入库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      if (this.$refs['tradeBillAO'] !== undefined) {
        this.$refs['tradeBillAO'].resetFields();
      }
      this.getstoreHouseInfo();
      setTimeout(function () {
        that.dialogVisible = true;
      }, 500);
      // 获取详细信息
      var param = row.billNo;
      (0, _index.getPeoductionDetail)(param).then(function (res) {
        console.log(res, '详情');
        if (res.data.code == 200) {
          that.tradeBillAO.billNo = res.data.data.tradeBillAllVO.billNo;
          that.tradeBillAO.relateNo = res.data.data.tradeBillAllVO.relateNo;
          that.proList = res.data.data.tradeProductVOS;
          that.tradeBillAO.tradeBillDetailAOS[0] = {};
          that.tradeBillAO.tradeBillDetailAOS[0].billNo = that.proList[0].billNo;
          that.tradeBillAO.tradeBillDetailAOS[0].packUnit = that.proList[0].packUnit;
          that.tradeBillAO.tradeBillDetailAOS[0].planScanAtoNum = that.proList[0].planScanAtoNum;
          that.tradeBillAO.tradeBillDetailAOS[0].planScanNum = that.proList[0].planScanNum;
          that.tradeBillAO.tradeBillDetailAOS[0].prodBatchNo = that.proList[0].prodBatchNo;
          that.tradeBillAO.tradeBillDetailAOS[0].productId = that.proList[0].productId;
          var storeName = res.data.data.tradeBillAllVO.inStoreName;
          var origin = that.TypeDataComs.StoreList;
          console.log(origin);
          that.tradeBillAO.inStoreId = '';
          for (var i = 0; i < origin.length; i++) {
            if (origin[i].storeHouseName == storeName) {
              that.tradeBillAO.inStoreId = origin[i].pId;
              break;
            }
          }
          that.setScanFactory(that.tradeBillAO.inStoreId);
          var outStore = res.data.data.tradeBillAllVO.inOrgName;
          var list = that.inOrgList;
          for (var i = 0; i < list.length; i++) {
            if (list[i].factoryName == outStore) {
              that.tradeBillAO.inOrgId = list[i].pId;
              break;
            }
          }
        } else {
          that.tradeBillAO.billNo = '';
          that.tradeBillAO.relateNo = '';
          that.proList = [];
          that.tradeBillAO.tradeBillDetailAOS[0] = {};
          that.tradeBillAO.inStoreId = '';
          that.tradeBillAO.inOrgId = '';
          that.$message.error(res.data.msg);
          return;
        }
      });
      this.mloading = false;
    },
    getNumInfo: function getNumInfo(num) {
      var _this2 = this;
      (0, _index.NumInfo)(num).then(function (res) {
        _this2.listLoading = false;
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          _this2.scanData = JSON.parse(JSON.stringify(_this2.scanData1));
          for (var i in _this2.scanData) {
            _this2.scanData[i] = res.data.data[i];
          }
        }
      }).catch(function () {});
    },
    // 获取产品详情
    getProductDetail: function getProductDetail(num) {
      var _this3 = this;
      this.listLoading = true;
      var that = this;
      that.proList = [];
      (0, _index.getProductDetail)(num).then(function (res) {
        _this3.listLoading = false;
        if (res.data.code != 200) {
          _this3.proList = [];
          return;
        } else {
          var list = [];
          res.data.data.forEach(function (v, index) {
            if (v.numSplit == null) {
              v['children'] = [];
              list['id_' + v.numRow] = v;
            }
          });
          res.data.data.forEach(function (v, index) {
            if (v.numSplit != null) {
              list['id_' + v.numRow]['children'].push(v);
            }
          });
          for (var i in list) {
            _this3.getDetailArray(list[i]);
          }
        }
      }).catch(function () {
        _this3.factoryList = [];
      });
    },
    // 已有产品转移数组定义
    getDetailArray: function getDetailArray(v) {
      this.proList.push({
        num: v.num,
        numRow: v.numRow,
        numSplit: v.numSplit,
        pId: v.prodCode,
        pid: v.prodCode,
        productCode: v.prodCodeCode,
        productName: v.prodCodeName,
        productClassId: v.prodClass,
        productClassName: v.prodClassName,
        productBrandId: v.prodBrand,
        packScaleExpression: v.packCodeName,
        packScaleDesc: v.packCodeDescr,
        packScaleId: v.packCode,
        prodBatch: v.prodBatch,
        sumPlan: v.sumPlan,
        sumPlanAto: v.sumPlanAto
      });
    },
    // 创建单号
    getCreateNum: function getCreateNum() {
      var _this4 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        }
        _this4.tradeBillAO.billNo = res.data.data;
        _this4.disabled.num = true;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    // 获取出入库企业
    getOutStores: function getOutStores() {
      var that = this;
      (0, _select.ccOrgInfoList)().then(function (res) {
        console.log(res, '库房');
        if (res.data.code == 200) {
          that.outOrgList = res.data.data;
        }
      });
    },
    // 关联单号
    getRelationNum: function getRelationNum() {
      var _this5 = this;
      if (!this.scanData.superNum) {
        this.$message.error('请输入关联单号');
        return false;
      }
      (0, _index.planOrderDetail)({
        orderNo: this.scanData.superNum
      }).then(function (res) {
        console.log('resresresresres', res);
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          var v = res.data.data;
          v['sumPlan'] = v.planNum;
          v['pid'] = v.materialId;
          v['productName'] = v.materialName;
          v['productCode'] = v.materialCode;
          v['packScaleDesc'] = v.packScaleDesc;
          v['packScaleExpression'] = v.packScale;
          v['packScaleId'] = v.packId;
          v['productClassName'] = v.productClassName;
          _this5.setSel(v);
        }
      }).catch(function () {});
    },
    proDel: function proDel(row, index) {
      var that = this;
      this.proList.splice(row, 1);
      return true;
      if (this.submitType == 3) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _index.setProductDelete)(row.num, row.numRow).then(function (res) {
            if (res.data.code != 200) {
              that.$message.error(res.data.msg);
              return;
            } else {
              that.proList.splice(row, 1);
            }
          }).catch(function () {});
        });
      } else {
        this.proList.splice(row, 1);
      }
    },
    submitForm: function submitForm(temp) {
      var _this6 = this;
      var that = this;
      this.setDetail();
      this.$refs['tradeBillAO'].validate(function (valid) {
        if (valid) {
          _this6.btn_loading = true;
          if (_this6.submitType == 2) {
            var tradeBillAO = {};
            tradeBillAO.billInProdAO = {};
            tradeBillAO.billInProdAO.inOrgId = _this6.tradeBillAO.inOrgId;
            tradeBillAO.billInProdAO.inStoreId = _this6.tradeBillAO.inStoreId;
            tradeBillAO.billInProdAO.outOrgId = _this6.tradeBillAO.inStoreId;
            tradeBillAO.billInProdAO.relateNo = _this6.tradeBillAO.relateNo;
            tradeBillAO.billNo = _this6.tradeBillAO.billNo;
            tradeBillAO.billType = _this6.tradeBillAO.billType;
            tradeBillAO.sumPlanAtoNum = that.tradeBillAO.tradeBillDetailAOS[0].planScanAtoNum;
            tradeBillAO.sumPlanNum = that.tradeBillAO.tradeBillDetailAOS[0].planScanNum;
            tradeBillAO.tradeBillDetailAOS = _this6.tradeBillAO.tradeBillDetailAOS;
            (0, _index.InfoUpdate)(tradeBillAO).then(function (res) {
              _this6.btn_loading = false;
              if (res.data.code != 200) {
                _this6.$message.error(res.data.msg);
              } else {
                _this6.$message({
                  message: '修改成功',
                  type: 'success'
                });
                _this6.$parent.getList();
                _this6.handleClose();
              }
            }).catch(function () {
              _this6.btn_loading = false;
            });
          } else {
            var tradeBillAO = {};
            tradeBillAO.billInProdAO = {};
            tradeBillAO.billInProdAO.inOrgId = _this6.tradeBillAO.inOrgId;
            tradeBillAO.billInProdAO.inStoreId = _this6.tradeBillAO.inStoreId;
            tradeBillAO.billInProdAO.outOrgId = _this6.tradeBillAO.inStoreId;
            tradeBillAO.billInProdAO.relateNo = _this6.tradeBillAO.relateNo;
            tradeBillAO.billNo = _this6.tradeBillAO.billNo;
            tradeBillAO.billType = _this6.tradeBillAO.billType;
            tradeBillAO.sumPlanAtoNum = that.tradeBillAO.tradeBillDetailAOS[0].planScanAtoNum;
            tradeBillAO.sumPlanNum = that.tradeBillAO.tradeBillDetailAOS[0].planScanNum;
            tradeBillAO.tradeBillDetailAOS = _this6.tradeBillAO.tradeBillDetailAOS;
            (0, _index.InfoInsert)(tradeBillAO).then(function (res) {
              _this6.btn_loading = false;
              if (res.data.code != 200) {
                _this6.$message.error(res.data.msg);
              } else {
                _this6.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this6.handleClose();
                _this6.$parent.getList();
              }
            }).catch(function () {
              _this6.btn_loading = false;
            });
          }
        }
      });
    },
    // 详情组合
    setDetail: function setDetail() {
      var that = this;
      that.scanData.listDetail = [];
      if (this.proList.length > 0) {
        this.proList.forEach(function (v, index) {
          var row = (Array(4).join('0') + (1 + index) * 10).slice(-4);
          that.scanData.listDetail.push({
            num: that.scanData.num,
            numRow: row,
            numSplit: '',
            packCode: v.packScaleId,
            // 包装比例id
            packUnit: '箱',
            // 单位：箱
            prodBatch: v.prodBatch,
            // 生产批次号
            prodBrand: v.productBrandId,
            // 品牌id
            prodClass: v.productClassId,
            // 品牌分类id
            prodCode: v.pid,
            // 产品id
            sumPlan: v.sumPlan,
            // 计划数量
            sumPlanAto: v.sumPlanAto,
            // 计划数量最小单位
            sumSettle: '',
            // 实际数量
            sumSettleAto: '' // 实际数量最小单位
          });
        });
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.handClose();
    },
    // 获取库房下拉信信
    getstoreHouseInfo: function getstoreHouseInfo() {
      var _this7 = this;
      this.TypeDataComs.StoreList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          _this7.$message.error(res.data.msg);
          return;
        } else {
          _this7.TypeDataComs.StoreList = res.data.data;
        }
      }).catch(function () {
        _this7.TypeDataComs.StoreList = [];
      });
    },
    // 获取企业下拉信息
    getfactoryInfoList: function getfactoryInfoList(v) {
      var _this8 = this;
      // StoreList
      (0, _select.factoryInfoList)({}).then(function (res) {
        _this8.inOrgList = [];
        _this8.outOrgList = [];
        if (res.data.code != 200) {
          _this8.$message.error(res.data.msg);
          return;
        } else {
          _this8.inOrgList = res.data.data;
          _this8.outOrgList = res.data.data;
          console.log('this.inOrgList', _this8.inOrgList);
        }
      }).catch(function () {
        _this8.factoryList = [];
      });
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.proList = [];
      this.proList = [val];
    },
    setSum: function setSum(e) {
      console.log('----', e);
      // this.scanData = JSON.parse(JSON.stringify(this.scanData))
      // this.scanData.planNum = this.valENnum(e)
    },
    setScanFactory: function setScanFactory(val) {
      this.inOrgList = [];
      this.TypeDataComs.factoryList = [];
      this.tradeBillAO.inOrgId = '';
      if (!val) return;
      for (var i in this.TypeDataComs.StoreList) {
        var items = this.TypeDataComs.StoreList[i];
        if (items.pId == val) {
          if (items.orgId != null) {
            // 获取企业数据
            this.tradeBillAO.inOrgId = items.orgId;
            this.inOrgList.push({
              pId: items.orgId,
              factoryName: items.orgName
            });
          }
          if (items.factoryId != null) {
            // 获取工厂数据
            this.scanData.prodFactory = items.factoryId;
            this.getWorkshop(items.factoryId);
            this.TypeDataComs.factoryList.push({
              pId: items.factoryId,
              factoryName: items.factoryName
            });
          }
        }
      }
      return false;
      this.getfactoryInfoList(val);
      // if(!val) return
    },
    // 获取工厂数据
    getfactoryList: function getfactoryList() {
      var _this9 = this;
      return true;
      // type	1：工厂 2：车间 3： 产线 4班组
      // parentId
      this.TypeDataComs.factoryList = [];
      (0, _select.factoryInfoList)({
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          _this9.$message.error(response.data.msg);
          return;
        } else {
          _this9.TypeDataComs.factoryList = response.data.data.records;
        }
      }).catch(function () {});
    },
    // 获取车间数据
    getWorkshop: function getWorkshop(v) {
      var _this10 = this;
      // type	1：工厂 2：车间 3： 产线 4班组
      if (this.scanData.prodShop != undefined) {
        // 车间id
        this.$refs.prodShop.resetField();
      }
      if (this.scanData.prodLine != undefined) {
        // 产线id
        this.$refs.prodLine.resetField();
      }
      if (this.scanData.prodGroup != undefined) {
        // 班组id
        this.$refs.prodGroup.resetField();
      }
      this.TypeDataComs.workshopList = [];
      (0, _select.factoryInfoList)({
        parentId: v,
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          _this10.$message.error(response.data.msg);
          return;
        } else {
          _this10.TypeDataComs.workshopList = response.data.data.records;
        }
      }).catch(function () {});
    },
    getprodGroup: function getprodGroup(v) {
      this.scanData.prodGroup = v;
    },
    getprodLine: function getprodLine(v) {
      this.scanData.prodLine = v;
    },
    // 获取产线
    getProductionline: function getProductionline(v) {
      var _this11 = this;
      if (this.scanData.prodLine != undefined) {
        // 产线id
        this.$refs.prodLine.resetField();
      }
      if (this.scanData.prodGroup != undefined) {
        // 班组id
        this.$refs.prodGroup.resetField();
      }
      this.scanData.prodShop = v;
      this.getteam(v);
      // type	1：工厂 2：车间 3： 产线 4班组
      this.TypeDataComs.Productionline = [];
      (0, _select.factoryInfoList)({
        parentId: v,
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 3
      }).then(function (response) {
        if (response.data.code != 200) {
          _this11.$message.error(response.data.msg);
          return;
        } else {
          _this11.TypeDataComs.Productionline = response.data.data.records;
        }
      }).catch(function () {});
    },
    // 获取班组
    getteam: function getteam(v) {
      var _this12 = this;
      // type	1：工厂 2：车间 3： 产线 4班组
      this.TypeDataComs.Teamline = [];
      (0, _select.factoryInfoList)({
        parentId: v,
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 4
      }).then(function (response) {
        if (response.data.code != 200) {
          _this12.$message.error(response.data.msg);
          return;
        } else {
          _this12.TypeDataComs.Teamline = response.data.data.records;
        }
      }).catch(function () {});
    }
  }
};
exports.default = _default;