"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _store = require("@/api/store");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    creatorName: function creatorName(val) {
      var str = '';
      if (val) {
        str = val.join(',');
      }
      // console.log(val)
      return str;
    }
  },
  data: function data() {
    return {
      title: '原料添加',
      dialogVisible: false,
      downLoading: false,
      total: 0,
      current: 1,
      size: 10,
      tableData: [],
      listLoading: false,
      dialogFormVisible: false,
      formData: {
        materialName: '',
        // 原料名称
        materialWeight: '',
        // 原料重量(库存重量)
        batchNo: '',
        // 批次单号
        batchAmount: '',
        // 生产重量
        claimWeight: '',
        // 领取重量
        produceDate: '',
        // 散茶生产时间
        relateNo: '' // 生产任务单号
      },

      rules: {
        materialName: [{
          required: true,
          message: '原料名称不能为空，请维护',
          trigger: 'change'
        }],
        batchNo: [{
          required: true,
          message: '批次不能为空，请维护',
          trigger: 'change'
        }],
        materialWeight: [{
          required: true,
          message: '原料重量不能为空，请维护',
          trigger: 'blur'
        }],
        claimWeight: [{
          required: true,
          message: '本次领取不能为空，请维护',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字值'
        }]
      },
      dialogFormVisible2: false,
      formData2: {
        materialName: '',
        // 原料名称
        materialWeight: '',
        // 原料重量(库存重量)
        batchNo: '',
        // 批次单号
        batchAmount: '',
        // 生产重量
        operateType: '3',
        returnWeight: '',
        // 领料退回重量
        claimWeight: '',
        // 添加领料重量
        produceDate: '',
        // 散茶生产时间
        relateNo: '',
        // 生产任务单号
        pid: ''
      },
      rules2: {
        materialName: [{
          required: true,
          message: '原料名称不能为空，请维护',
          trigger: 'change'
        }],
        batchNo: [{
          required: true,
          message: '批次不能为空，请维护',
          trigger: 'change'
        }],
        batchAmount: [{
          required: true,
          message: '原料重量不能为空，请维护',
          trigger: 'blur'
        }],
        materialWeight: [{
          required: true,
          message: '有效库存不能为空，请维护',
          trigger: 'blur'
        }],
        operateType: [{
          required: true,
          message: '编辑为空，请维护',
          trigger: 'change'
        }],
        returnWeight: [{
          required: true,
          message: '重量不能为空，请维护',
          trigger: 'blur'
        }],
        claimWeight: [{
          required: true,
          message: '重量不能为空，请维护',
          trigger: 'blur'
        }]
      },
      // 编辑信息
      BatchNoData: {},
      // 原料名称下拉
      materialList: [],
      // 批次下拉
      batchNoList: [],
      // 生产任务单号
      orderNo: ''
    };
  },
  // 监听
  watch: {
    dialogVisible: function dialogVisible(val) {
      console.log(val);
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    show: function show(data) {
      console.log(data);
      this.dialogVisible = true;
      this.orderNo = data.orderNo;
      this.getList(data.orderNo);
      if (data.bulkTeaProductTime) {
        this.title = '查看详情';
      }
    },
    getList: function getList(id) {
      var that = this;
      that.listLoading = true;
      (0, _store.pickingList)({
        relateNo: id
      }, that.current, that.size).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getList2: function getList2() {
      var _this = this;
      (0, _store.queryMaterialName)().then(function (response) {
        console.log(response.data, '原料名称');
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this.materialList = response.data.data;
          } else {
            _this.materialList = [];
          }
        }
      }).catch(function () {});
    },
    getList3: function getList3(id) {
      var _this2 = this;
      (0, _store.queryBatchNOsByMaterialId)({
        materialId: id
      }).then(function (response) {
        console.log(response.data, '原料信息');
        if (response.data.code != 200) {
          _this2.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this2.batchNoList = response.data.data;
          } else {
            _this2.batchNoList = [];
          }
        }
      }).catch(function () {});
    },
    getBatchNo: function getBatchNo(id) {
      var _this3 = this;
      (0, _store.queryBatchNOsByBatchNo)({
        batchNo: id
      }).then(function (response) {
        console.log(response.data.data[0], '编辑信息');
        if (response.data.code != 200) {
          _this3.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this3.BatchNoData = response.data.data[0];
            _this3.formData2.materialName = _this3.BatchNoData.MaterialName;
            _this3.formData2.batchNo = _this3.BatchNoData.BatchNo;
            _this3.formData2.batchAmount = _this3.BatchNoData.BatchAmount;
            _this3.formData2.materialWeight = _this3.BatchNoData.Qty;
            _this3.formData2.produceDate = (0, _utils.parseTime)(_this3.BatchNoData.ProductTime, '{y}/{m}/{d} {h}:{i}:{s}');
          } else {
            _this3.BatchNoData = {};
            _this3.dialogFormVisible2 = false;
            _this3.$message.error('未查到信息,无法编辑,请稍后重试!');
          }
        }
      }).catch(function () {});
    },
    // 原料名称下拉
    materialChange: function materialChange(row) {
      this.formData.materialName = row.MaterialName;
      this.formData.relateNo = this.orderNo;
      this.formData.batchNo = '';
      this.formData.materialWeight = '';
      console.log(row, this.formData);
      this.getList3(row.MaterialId);
    },
    // 批次下拉
    batchNoChange: function batchNoChange(row) {
      this.formData.batchNo = row.BatchNo;
      this.formData.batchAmount = row.BatchAmount;
      this.formData.produceDate = (0, _utils.parseTime)(row.ProduceDate, '{y}/{m}/{d} {h}:{i}:{s}');
      this.formData.materialWeight = row.Qty;
      console.log(row, this.formData);
    },
    claim: function claim() {
      if (this.formData.claimWeight <= 0) {
        this.$message.error('领取重量不能小于0');
      }
    },
    //
    add: function add() {
      this.dialogFormVisible = true;
      this.getList2();
    },
    confirm: function confirm(form) {
      var _this4 = this;
      this.$refs[form].validate(function (valid) {
        if (valid) {
          if (_this4.formData.claimWeight <= 0) {
            _this4.$message.error('领取重量不能小于0');
            return;
          }
          (0, _store.addMaterial)(_this4.formData).then(function (result) {
            console.log(result.data);
            if (result.data.code != 200) {
              _this4.$message.error(result.data.msg);
              return;
            } else {
              if (result.data.data != null || result.data.data != '') {
                _this4.$message.success('添加成功');
                _this4.cancel();
              }
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    cancel: function cancel() {
      this.$refs['formData'].resetFields();
      this.formData = {
        materialName: '',
        // 原料名称
        materialWeight: '',
        // 原料重量(库存重量)
        batchNo: '',
        // 批次单号
        batchAmount: '',
        // 生产重量
        claimWeight: '',
        // 领取重量
        produceDate: '',
        // 散茶生产时间
        relateNo: '' // 生产任务单号
      };

      this.materialList = [];
      this.batchNoList = [];
      this.current = 1;
      this.getList(this.orderNo);
      this.dialogFormVisible = false;
    },
    edit: function edit(row) {
      console.log(row);
      this.getBatchNo(row.batchNo);
      // this.formData2.materialName = row.materialName
      // this.formData2.batchNo = row.batchNo
      // this.formData2.batchAmount = row.batchAmount
      // this.formData2.materialWeight = row.materialWeight
      this.formData2.relateNo = row.relateNo;
      // this.formData2.produceDate = row.bulkTeaProductTime
      this.formData2.pid = row.pid;
      this.dialogFormVisible2 = true;
    },
    claim2: function claim2() {
      if (this.formData2.operateType == 3) {
        if (this.formData2.returnWeight <= 0) {
          this.$message.error('领取退回不能小于0');
        }
      } else if (this.formData2.operateType == 1) {
        this.$message.error('添加领料不能小于0');
      }
    },
    confirm2: function confirm2(form) {
      var _this5 = this;
      this.$refs[form].validate(function (valid) {
        if (valid) {
          if (_this5.formData2.operateType == 3) {
            if (_this5.formData2.returnWeight <= 0) {
              _this5.$message.error('领取退回不能小于0');
              return;
            }
          } else if (_this5.formData2.operateType == 1) {
            if (_this5.formData2.claimWeight <= 0) {
              _this5.$message.error('添加领料不能小于0');
              return;
            }
          }
          (0, _store.operateMaterial)(_this5.formData2).then(function (result) {
            console.log(result.data);
            if (result.data.code != 200) {
              _this5.$message.error(result.data.msg);
              return;
            } else {
              if (result.data.data != null || result.data.data != '') {
                _this5.$message.success('操作成功');
                _this5.cancel2();
              }
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    cancel2: function cancel2() {
      this.$refs['formData2'].resetFields();
      this.formData2 = {
        materialName: '',
        // 原料名称
        materialWeight: '',
        // 原料重量(库存重量)
        batchNo: '',
        // 批次单号
        batchAmount: '',
        // 生产重量
        operateType: '3',
        returnWeight: '',
        // 领料退回重量
        claimWeight: '',
        // 添加领料重量
        produceDate: '',
        // 散茶生产时间
        relateNo: '',
        // 生产任务单号
        pid: ''
      };
      this.current = 1;
      this.getList(this.orderNo);
      this.dialogFormVisible2 = false;
    },
    // 导出功能
    download: function download() {
      var _this6 = this;
      this.downLoading = true;
      (0, _store.returnMaterial)().then(function (res) {
        if (res.status != 200) {
          _this6.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '领料清单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.bodyNaNpxoveChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this6.downLoading = false;
      }).catch(function () {
        _this6.downLoading = false;
      });
    },
    close: function close() {
      this.dialogVisible = false;
      this.$parent.getList();
    }
  }
};
exports.default = _default;