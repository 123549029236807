"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteItem = deleteItem;
exports.downLoadData = downLoadData;
exports.newAdd = newAdd;
exports.replycontent = replycontent;
exports.requestUrl = void 0;
exports.saveReply = saveReply;
exports.scanData = scanData;
exports.searchList = searchList;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/user'
var baseUrl = '';
var requestUrl = baseUrl + '/api-memberscrm/member/admin/wxUserCode/down/detail';
// 查询分组
exports.requestUrl = requestUrl;
function searchList(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: param
  });
}

// 删除
function deleteItem(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/remove',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'DELETE',
    params: param
  });
}

// 新增
function newAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/save',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}

// 查看
function scanData(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/analysis',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}

// 下载明细
function downLoadData(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/down/detail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    responseType: 'blob',
    params: param
  });
}
// 查看配置回复内容
function replycontent(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/replycontent',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: param
  });
}
// 配置回复内容
function saveReply(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/save/replycontent',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}