"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _org = require("@/api/basic/org");
var _dealer = require("@/api/basic/dealer");
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDataorgmangeorgindex',
  filters: {
    orgStatus: function orgStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '启用',
        0: '停用'
      };
      return statusMap[status];
    },
    orgType: function orgType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '公司',
        2: '部门'
      };
      return statusMap[status];
    } // dutystatus(status) {
    //   if (!String(status)) return ''
    //   const statusMap = {
    //     1: '销售',
    //     2: '生产',
    //     1,2: '销售，生产'
    //   }
    //   return statusMap[status]
    // }
  },
  data: function data() {
    var _this = this;
    var validateOrg = function validateOrg(rule, value, callback) {
      if (_this.parentList.length == 0 && _this.ruleForm.pId != 1) {
        callback(new Error('上级组织不能为空，请维护'));
      } else {
        callback();
      }
    };
    var validatewarningDesc = function validatewarningDesc(rule, value, callback) {
      var reg = /^[\s]|[ ]$/gi;
      if (reg.test(value)) {
        callback(new Error("联系电话不能以' '开头或结尾"));
      } else {
        callback();
      }
    };
    return {
      activeName: 'first',
      page2: false,
      listLoading: false,
      btnLoading: false,
      nodeContent: {},
      leixinglist: [],
      zhizelist: [],
      orgTypeList: [{
        id: 2,
        name: '部门'
      }, {
        id: 1,
        name: '公司'
      }],
      apis: [{
        authBtn: false,
        resourceCode: 'ZZJGLBCX'
      }, {
        authBtn: false,
        resourceCode: 'ZZJGMXCX'
      }, {
        authBtn: false,
        resourceCode: 'ZZJGGX'
      }, {
        authBtn: false,
        resourceCode: 'ZZJGTJ'
      }, {
        authBtn: false,
        resourceCode: 'ZZJGSC'
      }],
      listQuery: {
        orgCodeAndName: '',
        orgStatus: '',
        duty: '',
        orgType: ''
      },
      treeData: [],
      ruleForm: {
        orgCode: '',
        orgName: '',
        provinceId: '',
        cityId: '',
        duty: [],
        countyId: '',
        detailedAddress: '',
        contacts: '',
        phone: '',
        salePhone: '',
        supervisePhone: '',
        telephone: '',
        orgType: '',
        orgStatus: 1,
        isOrder: 0,
        remarks: ''
      },
      rules: {
        // orgCode: [
        //   {
        //     required: true,
        //     message: '组织编码不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        orgName: [{
          required: true,
          message: '组织名称不能为空，请维护',
          trigger: 'change'
        }],
        // parentList: [
        //   { required: true, validator: validateOrg, trigger: 'change' }
        // ],
        orgType: [{
          required: true,
          message: '机构类型不能为空，请维护',
          trigger: 'change'
        }],
        // orgStatus: [
        //   {
        //     required: true,
        //     message: '状态不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        duty: [{
          required: true,
          message: '机构职责不能为空，请维护',
          trigger: 'change'
        }],
        isOrder: [{
          required: true,
          message: '是否补单据不能为空，请维护',
          trigger: 'change'
        }],
        phone: [{
          pattern: /^0{0,1}(13[0-9]|145|147|149|15[5-9]|15[0-3]|166|17[1-3]|17[5-8]|18[0-9]|19[8-9])[0-9]{8}$/,
          message: '手机号格式不对',
          trigger: 'blur'
        }]
        // telephone: [{ validator: validatewarningDesc, trigger: 'change' }]
      },

      dialogVisible: false,
      title: '新增组织机构',
      mloading: false,
      areaList: [],
      orgList: [],
      parentList: [],
      areaSelectList: [],
      normalList: [],
      orgSelList: [],
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      }
    };
  },
  activated: function activated() {
    var that = this;
    that.getOrgList(111);
    this.getAreaList();
    this.getjigou();
    this.getzhize();
  },
  methods: {
    changecheck: function changecheck() {
      this.$forceUpdate();
    },
    reset: function reset() {
      this.listQuery = {
        orgCodeAndName: '',
        orgStatus: '',
        duty: '',
        orgType: ''
      };
      this.getOrgList();
    },
    getjigou: function getjigou() {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'ORG_TYPE'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.leixinglist = res.data.data;
          } else {
            _this2.leixinglist = [];
          }
        }
      });
    },
    getzhize: function getzhize() {
      var _this3 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'ORG_DUTY'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.zhizelist = res.data.data;
          } else {
            _this3.zhizelist = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
    },
    getAreaList: function getAreaList() {
      var _this4 = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this4.areaList = data;
          }
        }
      });
    },
    getOrgListAll: function getOrgListAll() {
      var _this5 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: ''
      }).then(function (response) {
        var that = _this5;
        var datas = response.data.data;
        var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentId', 'children');
        that.orgList = JSON.parse(JSON.stringify(Data));
        that.normalList = JSON.parse(JSON.stringify(datas));
        sessionStorage.setItem('orgList', JSON.stringify(Data));
        sessionStorage.setItem('orgNormalList', JSON.stringify(datas));
      });
    },
    getOrgList: function getOrgList(flag) {
      var _this6 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _org.fetchOrgList)(this.listQuery).then(function (response) {
        var that = _this6;
        var datas = response.data.data;
        datas.forEach(function (el) {
          if (el.duty == '1') {
            // el.dutynew == '销售'
            _this6.$set(el, 'dutynew', '销售');
          } else if (el.duty == '2') {
            _this6.$set(el, 'dutynew', '生产');
          } else if (el.duty == '1,2' || el.duty == '2,1') {
            _this6.$set(el, 'dutynew', '销售，生产');
          }
        });
        var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentId', 'children');
        that.treeData = JSON.parse(JSON.stringify(Data));
        that.nodeContent = Data[0] || {};
        if (flag == 111) {
          sessionStorage.setItem('orgList', JSON.stringify(Data));
          sessionStorage.setItem('orgNormalList', JSON.stringify(datas));
        }
        if (sessionStorage.getItem('orgList')) {
          that.orgList = JSON.parse(sessionStorage.getItem('orgList'));
          that.normalList = JSON.parse(sessionStorage.getItem('orgNormalList'));
        } else {
          that.getOrgListAll();
        }
        _this6.listLoading = false;
      }).catch(function () {
        _this6.listLoading = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.nodeContent = val;
    },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.parentList.unshift(val);
          if (number.parentId) {
            that.filterparentList(number.parentId);
          }
        }
      });
    },
    append: function append(row, type) {
      this.authId('addbtn', this.$route.meta.authList);
      if (type == 'N') {
        this.ruleForm.orgType = 1;
        this.title = '添加根节点组织';
      } else if (type == 'Y') {
        this.title = '添加下级组织';
        if (row.orgType == 1) {
          this.ruleForm.orgType = 2;
        } else if (row.orgType == 2) {
          this.ruleForm.orgType = 2;
        }
      }
      this.parentList = [row.pId];
      this.orgSelList = JSON.parse(JSON.stringify(this.orgList));
      if (row.parentId) {
        this.filterparentList(row.parentId);
      }
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      var _this7 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      this.getjigou();
      this.getzhize();
      this.title = '编辑组织机构';
      this.ruleForm.pId = row.pId;
      this.ruleForm.orgCode = row.orgCode;
      this.ruleForm.orgName = row.orgName;
      this.ruleForm.parentId = row.parentId;
      this.ruleForm.provinceId = row.provinceId;
      this.ruleForm.cityId = row.cityId;
      this.ruleForm.countyId = row.countyId;
      this.ruleForm.detailedAddress = row.detailedAddress;
      this.ruleForm.contacts = row.contacts;
      this.ruleForm.phone = row.phone;
      this.ruleForm.telephone = row.telephone;
      this.ruleForm.salePhone = row.salePhone;
      this.ruleForm.supervisePhone = row.supervisePhone;
      this.ruleForm.orgStatus = row.orgStatus;
      this.ruleForm.isOrder = row.isOrder || 0;
      this.ruleForm.remarks = row.remarks;
      if (row.duty) {
        this.ruleForm.duty = row.dutyList;
      }
      if (row.orgType) {
        this.ruleForm.orgType = Number(row.orgType);
      }
      var arr = [];
      if (row.provinceId) arr.push(row.provinceId);
      if (row.cityId) arr.push(row.cityId);
      if (row.countyId) arr.push(row.countyId);
      this.areaSelectList = arr;
      var list = JSON.parse(JSON.stringify(this.normalList));
      list.forEach(function (item) {
        item.children = [];
        if (item.pId == _this7.ruleForm.pId) {
          item.disabled = true;
        }
      });
      this.orgSelList = (0, _utils.transTreeData)(list, 'pId', 'parentId', 'children');
      if (row.parentId) {
        this.filterparentList(row.parentId);
      }
      this.dialogVisible = true;
    },
    handleClose: function handleClose(done) {
      this.ruleForm = {
        orgCode: '',
        orgName: '',
        provinceId: '',
        cityId: '',
        countyId: '',
        detailedAddress: '',
        contacts: '',
        phone: '',
        telephone: '',
        salePhone: '',
        supervisePhone: '',
        orgType: '',
        orgStatus: 1,
        isOrder: 0,
        remarks: ''
      };
      this.parentList = [];
      this.areaSelectList = [];
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    submitForm: function submitForm() {
      var _this8 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this8.btnLoading = true;
          var le = _this8.parentList.length - 1;
          _this8.ruleForm.parentId = _this8.parentList[le];
          _this8.ruleForm.provinceId = _this8.areaSelectList[0];
          _this8.ruleForm.cityId = _this8.areaSelectList[1];
          _this8.ruleForm.countyId = _this8.areaSelectList[2];
          var submitData = (0, _utils.filterKeyNull)(_this8.ruleForm);
          if (_this8.ruleForm.pId) {
            _this8.authId('updatebtn', _this8.$route.meta.authList);
            (0, _org.EdSubmit)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this8.$message.error(res.data.msg);
              } else {
                _this8.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this8.handleClose();
                _this8.getOrgList();
                sessionStorage.removeItem('orgList');
              }
              _this8.btnLoading = false;
            }).catch(function () {
              _this8.btnLoading = false;
            });
          } else {
            _this8.authId('addbtn', _this8.$route.meta.authList);
            (0, _org.fetchInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this8.$message.error(res.data.msg);
              } else {
                _this8.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this8.handleClose();
                _this8.getOrgList();
                sessionStorage.removeItem('orgList');
              }
              _this8.btnLoading = false;
            }).catch(function () {
              _this8.btnLoading = false;
            });
          }
        }
      });
    },
    setInUse: function setInUse(id) {
      var _this9 = this;
      this.$confirm('请确认是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this9.authId('startbtn', _this9.$route.meta.authList);
        (0, _org.updateStatus)({
          orgStatus: 1,
          pId: id
        }).then(function (res) {
          _this9.authId('searchbtn', _this9.$route.meta.authList);
          if (res.data.code != 200) {
            _this9.$message.error(res.data.msg);
          } else {
            _this9.$message({
              message: '操作成功',
              type: 'success'
            });
            sessionStorage.removeItem('orgList');
            _this9.getOrgList();
          }
        });
      }).catch(function () {
        _this9.authId('searchbtn', _this9.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(id) {
      var _this10 = this;
      this.$confirm('您确定要禁用所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this10.authId('stopbtn', _this10.$route.meta.authList);
        (0, _org.updateStatus)({
          orgStatus: 0,
          pId: id
        }).then(function (res) {
          _this10.authId('searchbtn', _this10.$route.meta.authList);
          if (res.data.code != 200) {
            _this10.$message.error(res.data.msg);
          } else {
            _this10.$message({
              message: '操作成功',
              type: 'success'
            });
            sessionStorage.removeItem('orgList');
            _this10.getOrgList();
          }
        });
      }).catch(function () {
        _this10.authId('searchbtn', _this10.$route.meta.authList);
      });
    },
    deleteId: function deleteId(row) {
      var _this11 = this;
      this.$confirm('您确定要删除所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _org.cancelUser)({
          pId: row
        }).then(function (res) {
          if (res.data.code != 200) {
            _this11.$message.error(res.data.msg);
          } else {
            _this11.$message({
              message: '操作成功',
              type: 'success'
            });
            _this11.getOrgList();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;