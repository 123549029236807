"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activityReport = require("@/api/marketingStatic/activityReport");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingStaticactivityReportlogoprizeStaticindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingStaticactivityReportlogoprizeStaticindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      activeList: [],
      formInline: {
        prizeName: null,
        // 奖品名称
        prizeType: null,
        // 奖品类型
        reportEndTime: null,
        // 结束时间
        reportStartTime: null,
        // 开始时间
        schemeCode: null // 方案名称
      },

      rangeTime: '',
      params: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      // 导出按钮加载
      downLoading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getactivelist();
  },
  activated: function activated() {
    console.log('跳转', this.$route.params);
    var hasCache1 = this.tableData.length > 0;
    //  其他页面体跳转到本页
    if (this.$route.params.schemeName) {
      this.formInline.schemeName = this.$route.params.schemeName;
      this.formInline.prizeType = this.$route.params.prizeType;
      this.formInline.reportStartTime = this.$route.params.reportStartTime;
      this.formInline.reportEndTime = this.$route.params.reportEndTime;
      this.rangeTime = [this.$route.params.reportStartTime, this.$route.params.reportEndTime];
    }
    if (this.formInline.schemeName || !hasCache1) {
      this.prizeReport();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityFlow.getwayList)({
        pageSize: 100,
        pageNo: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    prizeReport: function prizeReport() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.prizeReport)(submitData).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    sj: function sj(res) {
      if (res) {
        this.formInline.reportStartTime = res[0];
        this.formInline.reportEndTime = res[1];
      } else {
        this.formInline.reportStartTime = '';
        this.formInline.reportEndTime = '';
      }
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.prizeReport();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.rangeTime = '';
      this.params.pageNo = 1;
      this.formInline = {
        prizeName: null,
        // 奖品名称
        prizeType: null,
        // 奖品类型
        reportEndTime: null,
        // 结束时间
        reportStartTime: null,
        // 开始时间
        schemeCode: null // 方案名称
      };

      this.$refs[formName].resetFields();
      this.prizeReport();
    },
    // 导出
    download: function download() {
      var _this = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.exportPrizeReport)(submitData).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '活动奖品统计.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    },
    // 详情
    handleEdit: function handleEdit(row) {
      this.$router.push({
        name: 'marketingStaticactivityReportprizeRewardlogoRewardindex',
        params: {
          schemeCode: row.schemeCode,
          prizeName: row.prizeName,
          reportStartTime: row.reportStartTime,
          reportEndTime: row.reportEndTime
        }
      });
    }
  }
};
exports.default = _default;