"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrUpdate = addOrUpdate;
exports.configPanellist = configPanellist;
exports.configPanelstatus = configPanelstatus;
exports.detail = detail;
exports.refreshAllCache = refreshAllCache;
var _request = _interopRequireDefault(require("@/utils/request"));
// 列表
function configPanellist(params) {
  return (0, _request.default)({
    url: '/api-user/pc/configPanel/list',
    method: 'get',
    params: params
  });
}
// 开关配置项
function configPanelstatus(params) {
  return (0, _request.default)({
    url: '/api-user/pc/configPanel/status',
    method: 'get',
    params: params
  });
}
// 刷新缓存
function refreshAllCache(params) {
  return (0, _request.default)({
    url: '/api-user/pc/configPanel/refreshAllCache',
    method: 'get',
    params: params
  });
}

// 添加、修改
function addOrUpdate(data) {
  return (0, _request.default)({
    url: '/api-user/pc/configPanel/addOrUpdate',
    method: 'post',
    data: data
  });
}

// 详情
function detail(params) {
  return (0, _request.default)({
    url: '/api-user/pc/configPanel/detail',
    method: 'get',
    params: params
  });
}