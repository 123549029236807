var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        title: "订单详情",
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("el-form", { ref: "dataForm", attrs: { model: _vm.dataForm } }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "order-number" }, [
              _c(
                "div",
                { staticClass: "num-cont" },
                [
                  _c("el-form-item", { attrs: { label: "订单编号:" } }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.dataForm.orderNumber)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            active: _vm.stepsStatus - 1,
                            "align-center": "",
                            "finish-status": "process ",
                            "process-status":
                              _vm.dataForm.status == 6 ? "error" : "process ",
                          },
                        },
                        [
                          _c("el-step", {
                            attrs: {
                              title: "提交订单",
                              description: _vm.dataForm.orderTime,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-step", {
                            attrs: {
                              title: "买家已付款",
                              description: _vm.dataForm.payTime,
                            },
                          }),
                          _vm._v(" "),
                          _vm.dataForm.orderType !== 1
                            ? _c("el-step", {
                                attrs: {
                                  title: "卖家已发货",
                                  description: _vm.dataForm.dvyTime,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.dataForm.orderType !== 1
                            ? _c("el-step", {
                                attrs: {
                                  title: "买家已收货",
                                  description: _vm.dataForm.finallyTime,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-state" }, [
              _c(
                "div",
                { staticClass: "state-cont" },
                [
                  _c(
                    "div",
                    { staticClass: "state-title" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "line-height": "40px", width: "100%" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { "line-height": "40px" },
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [_vm._v("订单状态:")]
                          ),
                          _vm._v(" "),
                          [
                            _vm.dataForm.status === 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "warning" } },
                                  [_vm._v("待付款")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 2 &&
                            _vm.dataForm.orderType !== 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "warning" } },
                                  [_vm._v("待发货")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 3 &&
                            _vm.dataForm.orderType !== 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "warning" } },
                                  [_vm._v("已发货")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 4 &&
                            _vm.dataForm.orderType !== 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "warning" } },
                                  [_vm._v("待评价")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 5
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "success" } },
                                  [_vm._v("成功")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 6
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "danger" } },
                                  [_vm._v("失败")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dataForm.status === 2 &&
                            _vm.dataForm.orderType !== 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "30px" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeOrder(
                                          _vm.dataForm.orderNumber
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("发货")]
                                )
                              : _vm._e(),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.tableData,
                        "element-loading-text": "Loading",
                        border: "",
                        "header-:align": "tableConfig.align",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "收货人信息" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "收货人:" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        staticStyle: { "line-height": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              scope.row.userAddrOrder.receiver
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手机:" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        staticStyle: { "line-height": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              scope.row.userAddrOrder.mobile
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "收货地址:" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        staticStyle: { "line-height": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              scope.row.userAddrOrder.province
                                            ) +
                                            _vm._s(
                                              scope.row.userAddrOrder.city
                                            ) +
                                            _vm._s(
                                              scope.row.userAddrOrder.area
                                            ) +
                                            _vm._s(
                                              scope.row.userAddrOrder.addr
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        { attrs: { label: "发票信息" } },
                        [
                          [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("不开发票")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "买家信息", prop: "prodName" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "买家:" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        staticStyle: { "line-height": "20px" },
                                      },
                                      [_vm._v(_vm._s(scope.row.nickname))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "买家备注:" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        staticStyle: { "line-height": "20px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(scope.row.remarks) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item-list",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.dataForm.orderItems,
                            border: "",
                            "header-cell-style": {
                              "background-color": "#fafafa",
                              color: "rgba(0, 0, 0,0.85)",
                              "font-weight": "500",
                              "text-align": "center",
                            },
                            "row-style": {
                              color: "rgba(0, 0, 0,0.65)",
                              "font-weight": "400",
                              "text-align": "center",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "", label: "商品" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "spItem" }, [
                                      _c("img", {
                                        attrs: {
                                          src: scope.row.pic,
                                          width: "100",
                                          height: "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "15px",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.prodName))]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "单价",
                              width: "180",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.price)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "count",
                              label: "数量",
                              width: "180",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.prodCount)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "totalPrice",
                              label: "总价",
                              width: "180",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.price * scope.row.prodCount
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-info" },
                    [
                      _c("el-form-item", { attrs: { label: "商品总额:" } }, [
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.dataForm.total) + "积分"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-form-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "#CD0000" },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v("应付总额:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text",
                            staticStyle: { color: "#CD0000" },
                          },
                          [_vm._v(_vm._s(_vm.dataForm.actualTotal) + "积分")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-log" }, [
              _c("div", { staticClass: "log-title" }, [
                _c("span", [_vm._v("订单日志")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "log-cont" },
                [
                  _vm.dataForm.orderTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.orderTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 创建订单（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataForm.updateTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.updateTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 订单更新（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataForm.payTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.payTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 订单付款（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataForm.dvyTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.dvyTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 订单发货（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataForm.finallyTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.finallyTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 完成订单（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataForm.cancelTime
                    ? _c("el-form-item", { attrs: { "label-width": "10px" } }, [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dataForm.cancelTime) +
                              "\n                " +
                              _vm._s(_vm.dataForm.nickName) +
                              " 取消订单（成功）\n              "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.devyVisible
        ? _c("devy-add", {
            ref: "devyAdd",
            on: { refreshDataList: _vm.getDataList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }