"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _unlockPrizeRule = require("@/api/terminal/unlockPrizeRule");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    ruleCode: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    var validateAppointTime = function validateAppointTime(rule, value, callback) {
      var arr = value.split(',').filter(function (item) {
        return item;
      });
      if (!arr.length) {
        return callback(new Error('请正确输入指定次数'));
      }
      if (arr.length !== value.split(',').length) {
        return callback(new Error('不能输入为空的次数'));
      }
      if (arr.some(function (item) {
        return isNaN(Number(item)) || !isNaN(Number(item)) && Number(item) < 0;
      })) {
        return callback(new Error('请正确输入指定次数'));
      }
      if (new Set(arr).size != arr.length) {
        return callback(new Error('存在重复次数'));
      }
      if (arr.length > 50) {
        return callback(new Error('不允许超过50个次数'));
      }
      callback();
    };
    return {
      dialogLoading: false,
      codeForm: {
        unlockRuleScope: 0,
        details: [{
          preUpperLimit: 0
        }],
        replaceType: 0,
        unlockType: 0,
        preUpperLimit: 0,
        unlockByMultiple: ''
      },
      curPrizeList: [],
      subLoading: false,
      isDisable: false,
      codeFormRules: {
        unlockRuleScope: [{
          required: true,
          message: '请选择规则范围',
          trigger: 'change'
        }],
        unlockType: [{
          required: true,
          message: '请选择布奖类型',
          trigger: 'change'
        }],
        unlockRuleName: [{
          required: true,
          message: '请输入解锁规则名称',
          trigger: 'change'
        }],
        prizeType: [{
          required: true,
          message: '请选择奖品类型',
          trigger: 'change'
        }],
        accrualType: [{
          required: true,
          message: '请选择次数累计规则',
          trigger: 'change'
        }]
      },
      hongbaoOption: [],
      shiwuOption: []
    };
  },
  computed: {
    title: function title() {
      return this.type === 'add' ? '新建解锁奖品规则' : '编辑解锁奖品规则';
    },
    isDisableType: function isDisableType() {
      return this.type !== 'add';
    }
  },
  created: function created() {
    this.hongbaoOption = JSON.parse(sessionStorage.getItem('hongbaoOption'));
    this.shiwuOption = JSON.parse(sessionStorage.getItem('shiwuOption'));
  },
  mounted: function mounted() {
    var _this = this;
    if (this.type == 'edit') {
      this.dialogLoading = true;
      (0, _unlockPrizeRule.detailUnlockPrzeRule)({
        code: this.ruleCode
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            res.data.data.unlockType && (res.data.data.unlockType = Number(res.data.data.unlockType));
            res.data.data.unlockRuleScope = Number(res.data.data.unlockRuleScope);
            res.data.data.unlockRuleId = res.data.data.id;
            _this.codeForm = res.data.data;
            _this.codeForm.accrualType = _this.codeForm.accrualType.toString();
            // this.codeForm.replaceType = this.codeForm.replaceType
            // this.codeForm.details = res.data.data.prizeItems
            _this.$set(_this.codeForm, 'details', res.data.data.prizeItems);
            _this.dialogLoading = false;
            _this.codeForm.details.map(function (item, index) {
              item.prizeType && _this.getCurPrizeList(item.prizeType, index, false);
              // item.detailId = item.id
              _this.changeprize(item.prizeType, item.prizeId, index);
            });
            return;
          } else {
            _this.dialogLoading = false;
            _this.goBack();
            return _this.$message.error(res.data.data.errMsg);
          }
        } else {
          _this.dialogLoading = false;
          _this.goBack();
          return _this.$message.error(res.data.msg);
        }
      });
    }
    this.codeForm.details.map(function (item, index) {
      return item.prizeType && _this.getCurPrizeList(item.prizeType, index);
    });
  },
  methods: {
    changeprize: function changeprize(prizeType, id, index) {
      var obj = prizeType == 100 ? this.hongbaoOption.find(function (el) {
        return el.prizeId == id;
      }) : this.shiwuOption.find(function (el) {
        return el.prizeId == id;
      });
      // this.codeForm.details[index].rembursementPrice = obj.rembursementPrice
      this.$set(this.codeForm.details[index], 'rembursementPrice', obj.baseValue);
    },
    changeUnlockType: function changeUnlockType() {
      if (this.codeForm.unlockType == 0) {
        this.codeForm.details = [{
          preUpperLimit: 0
        }];
      }
      if (this.$refs.codeForm) {
        this.$refs.codeForm.clearValidate();
      }
    },
    addRowItem: function addRowItem() {
      if (this.codeForm.details.length >= 50) {
        return this.$message.error('最多添加50条');
      } else {
        this.codeForm.details.push({});
      }
    },
    deleteRowItem: function deleteRowItem(index) {
      this.codeForm.details.splice(index, 1);
    },
    getCurPrizeList: function getCurPrizeList(prizeType, index) {
      var isClear = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      console.log(prizeType, index);
      this.codeForm.details[index].plist = prizeType == 100 ? this.hongbaoOption : this.shiwuOption;
      isClear && this.codeForm.details[index].prizeId && (this.codeForm.details[index].prizeId = '');
    },
    // 表单验证
    checkForm: function checkForm(val, text) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        _this2.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this2.$message.closeAll();
            _this2.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit('close');
    },
    // 完成
    finish: function finish() {
      var _this3 = this;
      var finishFunc = this.type === 'add' ? _unlockPrizeRule.createUnlockPrzeRule : _unlockPrizeRule.updateUnlockPrzeRule;
      var eCheck = this.checkForm('codeForm', '请正确填写解锁奖品规则');
      if (this.codeForm.details) {
        this.codeForm.details.forEach(function (el) {
          delete el.rembursementPrice;
        });
      }
      eCheck.then(function () {
        _this3.subLoading = true;
        _this3.isDisable = true;
        finishFunc(_this3.codeForm).then(function (res) {
          if (res.data.code == 200) {
            if (res.data.data.errCode) {
              _this3.subLoading = false;
              _this3.isDisable = false;
              _this3.$message.error(res.data.data.errMsg);
            } else {
              _this3.$message({
                message: "".concat(_this3.type === 'add' ? '添加' : '编辑', "\u6210\u529F\uFF01"),
                type: 'success',
                duration: 3 * 1000
              });
              _this3.goBack();
              _this3.subLoading = false;
              _this3.isDisable = false;
            }
          } else {
            _this3.subLoading = false;
            _this3.isDisable = false;
            _this3.$message.error(res.data.msg);
            return;
          }
        });
      });
    }
  }
};
exports.default = _default2;