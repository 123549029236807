"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMaterial = addMaterial;
exports.arrangeorder = arrangeorder;
exports.auditno = auditno;
exports.backFailMarkCodeList = backFailMarkCodeList;
exports.baseWineList = baseWineList;
exports.checkParams = checkParams;
exports.checkStockOut = checkStockOut;
exports.deleteBatchSale = deleteBatchSale;
exports.deleteScheduleBillList = deleteScheduleBillList;
exports.disable = disable;
exports.enable = enable;
exports.exportErrLogData = exportErrLogData;
exports.exportExcel = exportExcel;
exports.exportScheduleExcel = exportScheduleExcel;
exports.exportorderExcel = exportorderExcel;
exports.failMarkCodeList = failMarkCodeList;
exports.fileList = fileList;
exports.findAllChannelClass = findAllChannelClass;
exports.finished = finished;
exports.generateOrderSequence = generateOrderSequence;
exports.generateQrcode = generateQrcode;
exports.getErrCode = getErrCode;
exports.getOrderList = getOrderList;
exports.getOrderadd = getOrderadd;
exports.getOrderdetail = getOrderdetail;
exports.getOrdersave = getOrdersave;
exports.getOrderup = getOrderup;
exports.getOrderupdate = getOrderupdate;
exports.getRelationList = getRelationList;
exports.getorder = getorder;
exports.getplanorderlist = getplanorderlist;
exports.gettaskList = gettaskList;
exports.imgupload = imgupload;
exports.insertBatchSale = insertBatchSale;
exports.listLog = listLog;
exports.manualActivate = manualActivate;
exports.markCodeList = markCodeList;
exports.operateMaterial = operateMaterial;
exports.orderdelete = orderdelete;
exports.orderexportExcel = orderexportExcel;
exports.orderfinished = orderfinished;
exports.pageParentMarkCode = pageParentMarkCode;
exports.pickingList = pickingList;
exports.planOrderupdate = planOrderupdate;
exports.policyDetail = policyDetail;
exports.postPageQuery = postPageQuery;
exports.postParticulars = postParticulars;
exports.postRevoke = postRevoke;
exports.productScheduleBillList = productScheduleBillList;
exports.quantityDetail = quantityDetail;
exports.queryBatchNOsByBatchNo = queryBatchNOsByBatchNo;
exports.queryBatchNOsByMaterialId = queryBatchNOsByMaterialId;
exports.queryBatchSaleList = queryBatchSaleList;
exports.queryBathNoList = queryBathNoList;
exports.queryMaterialName = queryMaterialName;
exports.relationexportExcel = relationexportExcel;
exports.returnMaterial = returnMaterial;
exports.reviewNoPass = reviewNoPass;
exports.reviewNoPassupdate = reviewNoPassupdate;
exports.reviewPass = reviewPass;
exports.subMarkCode = subMarkCode;
exports.taskOrderSchemeAudit = taskOrderSchemeAudit;
exports.taskOrderUpdateById = taskOrderUpdateById;
exports.taskexportExcel = taskexportExcel;
exports.updateBatchSale = updateBatchSale;
exports.updateShopCodes = updateShopCodes;
exports.updateorder = updateorder;
var _request = _interopRequireDefault(require("@/utils/request"));
// 计划单列表
function getOrderList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/search',
    method: 'get',
    params: params
  });
}
// 计划单列表
function getplanorderlist(params) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/search',
    method: 'get',
    params: params
  });
}
// 计划单添加
function getOrderadd(data) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/create',
    method: 'post',
    data: data
  });
}
// 计划单编辑
function getOrderup(data) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/update',
    method: 'post',
    data: data
  });
}
// 计划单列表-排单-批量排单
function arrangeorder(data) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/create',
    method: 'post',
    data: data
  });
}
// 排产单-编辑排单
function updateorder(data) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/update',
    method: 'post',
    data: data
  });
}
// 排产单-批量审核
function auditno(params) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/audit',
    method: 'get',
    params: params
  });
}
// 排产单-编辑排单-车间
function updateShopCodes(params) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/updateShopCodes',
    method: 'get',
    params: params
  });
}
// 排产单列表
function productScheduleBillList(params) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/search',
    method: 'get',
    params: params
  });
}
// 排产单删除
function deleteScheduleBillList(params) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/delete',
    method: 'get',
    params: params
  });
}
// 排产单导出
function exportScheduleExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/productScheduleBill/scheduleBill/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 启用
function enable(params) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/enable',
    method: 'get',
    params: params
  });
}
// 关闭
function disable(params) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/disable',
    method: 'get',
    params: params
  });
}
// 生产任务单分页查询
function getorder(params) {
  return (0, _request.default)({
    url: '/api-prod/productTaskBill/taskBill/search',
    method: 'get',
    params: params
  });
}
// 生产任务单导出
function exportorderExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/productTaskBill/taskBill/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 质保策略查询
function policyDetail(params) {
  return (0, _request.default)({
    url: '/api-prod/productTaskBill/taskBill/policyDetail',
    method: 'get',
    params: params
  });
}
// 获取编号
function generateOrderSequence(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/generateOrderSequence',
    method: 'get',
    params: params
  });
}
// 计划单详情
function getOrderdetail(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/detail',
    method: 'get',
    params: params
  });
}
// 计划单添加
function getOrdersave(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/save',
    method: 'post',
    data: data
  });
}
// 计划单编辑
function getOrderupdate(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/update',
    method: 'post',
    data: data
  });
}
// 计划单撤销
function postRevoke(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/revoke',
    method: 'post',
    params: params
  });
}
// 数码详情
function markCodeList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/taskOrder/markCodeList',
    method: 'get',
    params: params
  });
}
// 计划单删除
function orderdelete(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/delete',
    method: 'get',
    params: params
  });
}
// 计划单导出
function orderexportExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 计划单导出
function exportExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/productPlanOrder/planOrder/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 任务单列表
function gettaskList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/taskOrder/search',
    method: 'get',
    params: params
  });
}

// 生产任务单导出
function taskexportExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/taskOrder/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

// 生成二维码
function generateQrcode(params) {
  return (0, _request.default)({
    url: '/storage-center/ccProductOrder/generateQrcode',
    method: 'get',
    params: params
  });
}

// 生产任务单编辑
function taskOrderUpdateById(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/taskOrder/taskOrderUpdateById',
    method: 'post',
    data: data
  });
}
function planOrderupdate(params) {
  return (0, _request.default)({
    url: '/storage-center/ccProductOrder/planOrder/update',
    method: 'post',
    params: params
  });
}
function reviewNoPassupdate(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/reviewNoPass/update',
    method: 'post',
    data: data
  });
}
// 生产任务单审核
function taskOrderSchemeAudit(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/taskOrderSchemeAudit',
    method: 'post',
    data: data
  });
}

// 关联关系列表
function getRelationList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/orderRelation/search',
    method: 'get',
    params: params
  });
}

// 生产关联关系 - 异常数码明细分页查询
function failMarkCodeList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/orderRelation/failMarkCodeList',
    method: 'get',
    params: params
  });
}

// 生产关联关系 - 异常数码明细导出
function relationexportExcel(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/orderRelation/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

// 生产关联关系-码包文件查询
function fileList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/file/search',
    method: 'get',
    params: params
  });
}

//  分页查询单号下第一层级数码
function pageParentMarkCode(params) {
  return (0, _request.default)({
    url: '/api-mark/markRelation/pageParentMarkCode',
    method: 'get',
    params: params
  });
}

//  分页查询单号下第一层级数码
function subMarkCode(params) {
  return (0, _request.default)({
    url: '/api-mark/markRelation/subMarkCode',
    // url: '/api-mark/markRelation/subMarkCodeUnit',
    method: 'get',
    params: params
  });
}

// 单据生产量详情
function quantityDetail(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/quantity/detail',
    method: 'get',
    params: params
  });
}

// 生产计划单/任务单-异常数码量分页查询
function backFailMarkCodeList(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/backFailMarkCodeList',
    method: 'get',
    params: params
  });
}

// 生产计划单审核通过
function reviewPass(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/reviewPass',
    method: 'post',
    data: data
  });
}

// 生产计划单审核未通过
function reviewNoPass(data) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/reviewNoPass',
    method: 'post',
    data: data
  });
}

/**
 * 获取异常数码日志
 * **/
function getErrCode(param) {
  return (0, _request.default)({
    url: '/storage-center/markReplaceLog/queryForPage',
    method: 'get',
    params: param
  });
}
// 异常数码日志
function listLog(data) {
  return (0, _request.default)({
    url: '/api-prod/mark/operateManager/listLog',
    method: 'post',
    data: data
  });
}
/**
  * 导出异常数码日志
  * **/

function exportErrLogData(data) {
  return (0, _request.default)({
    url: '/api-prod/mark/operateManager/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

/**
 * 手动激活
 * **/
function manualActivate(data) {
  return (0, _request.default)({
    url: '/api-mark/markRelation/manualActivate',
    method: 'post',
    params: data
  });
}

// 基酒类型详情列表
function baseWineList(params) {
  return (0, _request.default)({
    url: 'api-basic/jcBaseWine/page',
    method: 'get',
    params: params
  });
}

// 生产任务单完结(已下载进行中->回传完成)
function orderfinished(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/orderfinished',
    method: 'post',
    params: params
  });
}
// 生产任务单结单
function finished(data) {
  return (0, _request.default)({
    url: '/api-prod/productOrderApi/order/finished',
    method: 'post',
    data: data
  });
}
// 限量发售产品名称
function queryBatchSaleList(params) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/queryBatchSaleList',
    method: 'get',
    params: params
  });
}
// 限量发售查询列表
function postPageQuery(data, current, size) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/pageQuery?' + 'current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}
// 限量发售添加
function insertBatchSale(data) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/insertBatchSale',
    method: 'post',
    data: data
  });
}
// 添加字段校验
function checkParams(data) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/checkParams',
    method: 'post',
    data: data
  });
}
// 限量发售删除
function deleteBatchSale(params) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/deleteBatchSale/' + params,
    method: 'get'
  });
}
// 批次号选择
function queryBathNoList(params) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/queryBathNoList',
    method: 'get',
    params: params
  });
}
// 详情表格列表
function postParticulars(data, current, size) {
  return (0, _request.default)({
    url: '/storage-center/batchSaleDetail/pageQuery?' + 'current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}
// 限量发售编辑
function updateBatchSale(data) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/updateBatchSale',
    method: 'post',
    data: data
  });
}
// 限量发售检查是否有修改权限
function checkStockOut(batchNo, relateNo) {
  return (0, _request.default)({
    url: '/storage-center/batchSale/checkStockOut/' + batchNo + '/' + relateNo,
    method: 'get'
  });
}

// 上传任务单 质检报告上传
function imgupload(data, pid) {
  return (0, _request.default)({
    url: '/storage-center/ccProductOrder/uploadInspectionFile?pId=' + pid,
    method: 'post',
    data: data
  });
}

// 领料清单表格查询
function pickingList(data, current, size) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/pageQuery?' + 'current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}
// 原料名称下拉框
function queryMaterialName(params) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/queryMaterialName',
    method: 'get',
    params: params
  });
}
// 编辑通过批次号去值
function queryBatchNOsByBatchNo(params) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/queryBatchNOsByBatchNo',
    method: 'get',
    params: params
  });
}
// 通过批次查原料信息
function queryBatchNOsByMaterialId(params) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/queryBatchNOsByMaterialId',
    method: 'get',
    params: params
  });
}
// 导出领料
function returnMaterial(data) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/returnMaterial',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
// 添加原料
function addMaterial(data) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/addMaterial',
    method: 'post',
    data: data
  });
}
// 编辑原料
function operateMaterial(data) {
  return (0, _request.default)({
    url: '/storage-center/receiveMaterial/operateMaterial',
    method: 'post',
    data: data
  });
}
// 查询渠道分类
function findAllChannelClass(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelClass/findAllChannelClass',
    method: 'get',
    params: params
  });
}