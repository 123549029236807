"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tgItem = require("@/api/chanelmange/tgItem");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangebasicsettgtypeindex';
var _default = {
  name: 'Chanelmangebasicsettgtypeindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        groupBuyName: '',
        groupStatus: '',
        pageNo: 1,
        pageSize: 20
      },
      states: [{
        name: '禁用',
        code: 0
      }, {
        name: '启用',
        code: 1
      }, {
        name: '删除',
        code: 2
      }],
      tableKey: 'ywtype',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      addData: {
        groupBuyCode: '',
        groupBuyName: '',
        groupStatus: 1,
        remarks: '',
        pId: ''
      },
      rules: {
        groupBuyCode: [{
          required: true,
          message: '请输入团购类型编码',
          trigger: 'blur'
        }],
        groupBuyName: [{
          required: true,
          message: '请输入团购类型名称',
          trigger: 'blur'
        }],
        groupStatus: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      title: '团购类型-添加',
      dialogVisible: false,
      scanVisible: false,
      loading: false,
      dstate: false,
      scanData: {},
      subType: '',
      apis: [],
      page2: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = that.listQuery;
      (0, _tgItem.getTgTypeList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.groupBuyName = '';
      that.listQuery.groupStatus = '';
      that.listQuery.pageNo = 1;
      that.listQuery.pageSize = 20;
      that.getList();
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      that.title = '团购类型-添加';
      that.addData.groupBuyCode = '';
      that.addData.groupBuyName = '';
      that.addData.groupStatus = 1;
      that.addData.remarks = '';
      that.addData.pId = '';
      that.subType = 'add';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      that.title = '团购类型-修改';
      that.addData.groupBuyCode = data.groupBuyCode;
      that.addData.groupBuyName = data.groupBuyName;
      that.addData.groupStatus = data.groupStatus;
      that.addData.remarks = data.remarks;
      that.addData.pId = data.pId;
      that.subType = 'edit';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('addForm');
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var subData = that.addData;
          if (that.subType == 'add') {
            var setHeaderId = that.authId('addbtn', that.apis);
            (0, _tgItem.tgTypeInsert)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                that.getList();
                that.dialogVisible = false;
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (that.subType == 'edit') {
            var setHeaderId = that.authId('updatebtn', that.apis);
            (0, _tgItem.updateTgTypeData)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                that.getList();
                that.dialogVisible = false;
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        }
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var param = {
        pId: data.pid
      };
      (0, _tgItem.getTgTypeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
          that.$nextTick(function () {
            that.scanVisible = true;
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      var param = {};
      param.pId = data.pId;
      param.groupStatus = 0;
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = that.authId('stopbtn', that.apis);
        (0, _tgItem.chageTypeState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.listQuery.pageNo = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      var that = this;
      var param = {};
      param.pId = data.pId;
      param.groupStatus = 1;
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = that.authId('startbtn', that.apis);
        (0, _tgItem.chageTypeState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.listQuery.pageNo = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    DoDelete: function DoDelete(data) {
      var that = this;
      var param = {};
      param.pId = data.pId;
      param.groupStatus = 2;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = that.authId('deletebtn', that.apis);
        (0, _tgItem.chageTypeState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功'
            });
            that.listQuery.pageNo = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('删除已取消');
      });
    }
  }
};
exports.default = _default;