"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/web.dom.iterable");
var _menuAlysis = require("@/api/wxhyyy/menuAlysis");
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */
var _default = {
  components: {
    lineChart: _lineChart.default,
    admincut: _admincut.default
  },
  data: function data() {
    return {
      listQuery: {
        beginDate: '',
        endDate: ''
      },
      listQuery2: {
        beginDate: '',
        endDate: '',
        contrastBeginDate: '',
        contrastEndDate: ''
      },
      listQuery3: {
        beginDate: '',
        endDate: ''
      },
      value1: '',
      value2: '',
      uncosData: '',
      menuValue: '',
      showSearch: true,
      showItems: true,
      tableData: [],
      menuData: [],
      activeIndex: '0',
      lineListData: [],
      lineChartData: {
        expectedData: [],
        actualData: [],
        xData: [],
        xxData: []
      },
      loading: false,
      downLoading: false,
      downLoadTxt: '下载'
    };
  },
  created: function created() {
    var that = this;
    // 初始化获取菜单数据
    that.getCurMonth();
    // 初始化获取菜单详情
    that.initMenuDetail();
  },
  methods: {
    getDate: function getDate(value) {
      var that = this;
      if (value == '' || value == null) {
        that.value1 = ['', ''];
        that.listQuery.beginDate = '';
        that.listQuery.endDate = '';
      } else {
        that.value1 = value;
        that.listQuery.beginDate = value[0];
        that.listQuery.endDate = value[1];
      }
      that.getList(that.listQuery, 'type1');
    },
    setDate1: function setDate1(value) {
      var that = this;
      if (value == '' || value == null) {
        that.value1 = ['', ''];
      } else {
        that.value1 = value;
      }
    },
    setDate2: function setDate2(value) {
      var that = this;
      if (value == '' || value == null) {
        that.value2 = ['', ''];
      } else {
        that.value2 = value;
      }
    },
    getCurMonth: function getCurMonth() {
      var that = this;
      var curDate = that.getThirtyDays();
      that.value1 = curDate;
      that.listQuery.beginDate = curDate[0];
      that.listQuery.endDate = curDate[1];
      that.getList(that.listQuery, 'type1');
    },
    DoSearch: function DoSearch() {
      var that = this;
      if (that.value1 == '') {
        that.$message.error('请输入或选择时间对比起始时间');
        return;
      } else {
        if (that.value1[0] == '' || that.value1[1] == '') {
          that.$message.error('请输入或选择时间对比起始时间');
          return;
        } else {
          that.listQuery2.beginDate = that.value1[0];
          that.listQuery2.endDate = that.value1[1];
        }
      }
      if (that.value2 == '') {
        that.$message.error('请输入或选择时间对比结束时间');
        return;
      } else {
        if (that.value2[0] == '' || that.value2[1] == '') {
          that.$message.error('请输入或选择时间对比结束时间');
          return;
        } else {
          that.listQuery2.contrastBeginDate = that.value2[0];
          that.listQuery2.contrastEndDate = that.value2[1];
        }
      }
      that.getList(that.listQuery2, 'type2');
      that.showItems = false;
    },
    getList: function getList(param, type) {
      var that = this;
      that.activeIndex = '0';
      param.appId = sessionStorage.getItem('appId');
      (0, _menuAlysis.searchMenuData)(param).then(function (res) {
        res = res.data;
        if (res.code == 200) {
          if (type == 'type1') {
            that.uncosData = res.data.data.data;
            var chartData = res.data.data.data.resultDate;
            that.DoFilterData(chartData, 'single');
          }
          if (type == 'type2') {
            var resData = res.data.data;
            var table = [];
            if (resData.data == '' || resData.data == null) {
              table[0] = {};
            } else {
              table[0] = resData.data;
            }
            if (resData.contrastData == '' || resData.contrastData == null) {
              table[1] = {};
            } else {
              table[1] = resData.contrastData;
            }
            table[0].dateTime = that.value1.join('~');
            table[1].dateTime = that.value2.join('~');
            that.tableData = table;
            that.DoFilterData(resData, 'double');
          }
        }
      });
    },
    getThirtyDays: function getThirtyDays() {
      var that = this;
      var end = new Date();
      var start = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      var beginDate = that.filterTime(start);
      var endDate = that.filterTime(end);
      return [beginDate, endDate];
    },
    initMenuDetail: function initMenuDetail() {
      var that = this;
      var curDate = that.getThirtyDays();
      that.menuValue = curDate;
      that.listQuery3.beginDate = curDate[0];
      that.listQuery3.endDate = curDate[1];
      that.getMenuDetail(that.listQuery3);
    },
    getChangeMenu: function getChangeMenu(val) {
      var that = this;
      if (val == null || val == '') {
        that.listQuery3.beginDate = '';
        that.listQuery3.endDate = '';
      } else {
        that.listQuery3.beginDate = val[0];
        that.listQuery3.endDate = val[1];
      }
      that.getMenuDetail(that.listQuery3);
    },
    // 获取菜单详情
    getMenuDetail: function getMenuDetail(param) {
      var that = this;
      (0, _menuAlysis.searchMenuDetail)(param).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          that.menuData = res.data.data;
        }
      });
    },
    // 时间格式
    filterTime: function filterTime(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
      var h = time.getHours();
      var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
      var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
      return y + '-' + m + '-' + d;
    },
    // 过滤折线数据
    DoFilterData: function DoFilterData(data, type) {
      var that = this;
      if (type == 'single') {
        if (data.length == 0) {
          var param = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
          that.lineChartData = param;
          that.lineListData = [param, param, param];
        } else {
          var allMenuClickCount = [];
          var allMenuClickUser = [];
          var allMenuClickAverage = [];
          var xData = [];
          data.forEach(function (item, index) {
            allMenuClickCount.push(item.allMenuClickCount);
            allMenuClickUser.push(item.allMenuClickUser);
            allMenuClickAverage.push(item.allMenuClickAverage);
            xData.push(item.createDate);
          });
          that.lineListData = [{
            expectedData: allMenuClickCount,
            actualData: [],
            xData: xData,
            xxData: []
          }, {
            expectedData: allMenuClickUser,
            actualData: [],
            xData: xData,
            xxData: []
          }, {
            expectedData: allMenuClickAverage,
            actualData: [],
            xData: xData,
            xxData: []
          }];
          that.lineChartData = {
            expectedData: allMenuClickCount,
            actualData: [],
            xData: xData,
            xxData: []
          };
        }
        console.log(that.lineChartData);
      } else {
        var lineListData = [{
          expectedData: [],
          actualData: [],
          xData: [],
          xxData: []
        }, {
          expectedData: [],
          actualData: [],
          xData: [],
          xxData: []
        }, {
          expectedData: [],
          actualData: [],
          xData: [],
          xxData: []
        }];
        if (data.data != '' && data.data != null) {
          var eData = data.data.resultDate;
          if (eData == '' || eData == null) {
            lineListData.forEach(function (item, index) {
              item.expectedData = [];
              item.xData = [];
            });
          } else {
            eData.forEach(function (item, index) {
              lineListData[0].expectedData.push(item.allMenuClickCount);
              lineListData[0].xData.push(item.createDate);
              lineListData[1].expectedData.push(item.allMenuClickUser);
              // lineListData[1].expectedData.push(item.allMenuClickAverage)
              lineListData[1].xData.push(item.createDate);
              lineListData[2].expectedData.push(item.allMenuClickAverage);
              // lineListData[2].expectedData.push(item.allMenuClickUser)
              lineListData[2].xData.push(item.createDate);
            });
          }
        }
        if (data.contrastData != '' && data.contrastData != null) {
          var cData = data.contrastData.resultDate;
          if (cData == '' || cData == null) {
            lineListData.forEach(function (item, index) {
              item.actualData = [];
              item.xxData = [];
            });
          } else {
            cData.forEach(function (item, index) {
              lineListData[0].actualData.push(item.allMenuClickCount);
              lineListData[0].xxData.push(item.createDate);
              lineListData[1].actualData.push(item.allMenuClickUser);
              lineListData[1].xxData.push(item.createDate);
              lineListData[2].actualData.push(item.allMenuClickAverage);
              lineListData[2].xxData.push(item.createDate);
            });
          }
        }
        that.lineListData = lineListData;
        that.lineChartData = lineListData[0];
        // that.datasplice(lineListData[0])
      }
    },
    // 开启对比
    complit: function complit() {
      var that = this;
      that.showSearch = false;
    },
    // 取消对比
    cancelComplit: function cancelComplit() {
      var that = this;
      that.listQuery.beginDate = that.value1[0];
      that.listQuery.endDate = that.value1[1];
      that.getList(that.listQuery, 'type1');
      that.showSearch = true;
      that.showItems = true;
    },
    datasplice: function datasplice(val) {
      var that = this;
      var xdad = val.xData;
      var xxdad = val.xxData;
      if (xdad.length !== xxdad.length && xxdad.length !== 0 && xdad.length !== 0) {
        if (xdad.length > xxdad.length) {
          var a = xdad.length - xxdad.length;
          for (var i = 0; i < a; i++) {
            var c = Math.ceil(a / (2 + i));
            if (c === xdad.length - 1) {
              c = Math.ceil(a / (3 + i));
            }
            xdad.splice(c, 1);
          }
          val.xData = xdad;
        } else {
          var b = xxdad.length - xdad.length;
          for (var _i = 0; _i < b; _i++) {
            var d = Math.ceil(b / (2 + _i));
            if (d === xxdad.length - 1) {
              d = Math.ceil(b / (3 + _i));
            }
            console.log(d);
            xxdad.splice(d, 1);
          }
          val.xxData = xxdad;
        }
      }
      that.lineChartData = val;
    },
    // 折线图切换
    handleSelect: function handleSelect(key) {
      var that = this;
      console.log(that.lineListData);
      that.lineChartData = that.lineListData[key];
      // console.log(that.lineListData[key])
      // that.datasplice(that.lineListData[key])
    },
    // 下载
    downLoad: function downLoad() {
      var that = this;
      that.downLoadTxt = '下载中...';
      that.downLoading = true;
      var tHeader = ['版本', '一级菜单', '二级菜单', '菜单点击次数', '菜单点击人数', '人均点击次数'];
      var filterVal = ['version', 'oneMenu', 'twoMenu', 'allMenuClickCount', 'allMenuClickUser', 'allMenuClickAverage'];
      var list = that.menuData;
      var data = this.formatJson(filterVal, list);
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        excel.export_json_to_excel({
          header: tHeader,
          // 表头 必填
          data: data,
          // 具体数据 必填
          autoWidth: true,
          // 非必填
          bookType: 'xlsx' // 非必填
        });
      });

      that.downLoading = false;
      that.downLoadTxt = '下载';
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          return v[j];
          // }
        });
      });
    }
  }
};
exports.default = _default;