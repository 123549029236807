"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */

var baseUrl = '/wh';
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      vodis: true,
      vidis: true,
      autoup: false,
      vord: false,
      vird: false,
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'appId': sessionStorage.getItem('appId')
      },
      fileList: [],
      // 图文查询
      twsear: {
        groupId: '',
        keyWord: '',
        pageNo: 1,
        pageSize: 10
      },
      // 图文展示列表
      twlist: [],
      twImgurl: 'http://dev20gw.panpass.cn/images/',
      scImgurl: '',
      // 分页设置
      formInline: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      // 分页类型
      mdtype: null,
      // 上传
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: '',
      // posturl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      posturl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single',
      posturl3: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single4',
      // posturl3: baseUrl + '/api-memberscrm/member/admin/file/upload/single4',
      Lists: [],
      // tab栏
      activeName: 'first',
      voiceUp: false,
      videoUp: false,
      voiceXg: false,
      videoXg: false,
      voiceForm: {
        // id: '',
        name: ''
      },
      videoForm: {
        // id: '',
        name: ''
      },
      qbtw: null,
      wfztw: null,
      input2: '',
      voiceCount: '',
      videoCount: '',
      voList: [],
      viList: [],
      // 分组
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      groups: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      // 图片
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      url: '',
      // 表格

      tableData: [],
      tableDatao: [],
      currentDate: new Date(),
      loading: true,
      // 预览视频
      viSrc: '',
      previ: false,
      // 预览语音
      voSrc: '',
      prevo: false,
      // loading
      viloaing: true,
      voloaing: true,
      imgloaing: true,
      delIndex: 0
    };
  },
  watch: {
    'videoForm.name': function videoFormName(newval, oldval) {
      var that = this;
      if (newval.replace(/(^\s*)|(\s*$)/g, '') !== '' && that.vird && newval.length > 0) {
        that.vidis = false;
      } else {
        that.vidis = true;
      }
    },
    'voiceForm.name': function voiceFormName(newval, oldval) {
      var that = this;
      if (newval.replace(/(^\s*)|(\s*$)/g, '') !== '' && that.vord && newval.length > 0) {
        that.vodis = false;
      } else {
        that.vodis = true;
      }
    }
  },
  created: function created() {
    var that = this;
    that.twImgurl = _wxHy.base;
    that.scImgurl = _wxHy.basesc;
    that.twsear.appId = sessionStorage.getItem('appId');
    (0, _wxgzh.getGroup)({
      appId: sessionStorage.getItem('appId')
    }).then(function (res) {
      res = res.data;
      console.log(res);
      // eslint-disable-next-line eqeqeq
      if (!res.code == 200) {
        that.$message({
          type: 'info',
          message: 'res.msg'
        });
        return;
      }
      that.groups = res.data.data.groupDOList;
      // console.log(that.groups)
      that.qbtw = res.data.data.allCount;
      that.wfztw = res.data.data.noCount;
      that.loading = false;
    }).catch(function () {
      that.loading = false;
    });
    that.getAll();
  },
  methods: {
    // 新建素材
    creatsc: function creatsc() {
      sessionStorage.setItem('amsc', 'undefined');
      this.$router.push({
        name: 'wxAccountswxgzhwxFunctionfoddersucai',
        query: {}
      });
    },
    // 修改素材
    amend: function amend(val) {
      sessionStorage.setItem('amsc', JSON.stringify(val));
      this.$router.push({
        name: 'wxAccountswxgzhwxFunctionfoddersucai',
        // name: 'creatSucai',
        query: {}
      });
    },
    // 顶部tab栏
    handleClick: function handleClick(tab, event) {
      var _this = this;
      console.log(tab.index);
      // 图片
      if (tab.index * 1 === 1) {
        this.imgloaing = true;
        this.mdtype = 3;
        var n = {
          mediaType: this.mdtype,
          pageNo: this.formInline.pageNo,
          pageSize: this.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(n).then(function (res) {
          res = res.data;
          if (res.code * 1 === 200) {
            _this.Lists = res.data.records;
            _this.total = res.data.total;
            _this.imgloaing = false;
          } else {
            _this.$message.warning(res.msg);
          }
        });
        return;
      }

      // 语音
      if (tab.index * 1 === 2) {
        this.voloaing = true;
        var _n = {
          mediaType: tab.index,
          pageNo: this.formInline.pageNo,
          pageSize: this.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(_n).then(function (res) {
          res = res.data;
          // eslint-disable-next-line eqeqeq
          if (!res.code == 200) {
            _this.$message.warning(res.msg);
          }
          console.log(res.data.records);
          _this.tableData = res.data.records;
          _this.total = res.data.total;
          _this.voloaing = false;
        });
        return;
      }
      // 视频
      if (tab.index == 3) {
        this.viloaing = true;
        this.mdtype = 1;
        var _n2 = {
          mediaType: this.mdtype,
          pageNo: this.formInline.pageNo,
          pageSize: this.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(_n2).then(function (res) {
          res = res.data;
          if (!res.code == 200) {
            _this.$message.warning(res.msg);
          }
          _this.tableDatao = res.data.records;
          _this.total = res.data.total;
          _this.viloaing = false;
        });
        return;
      }
    },
    // 去重
    setGround: function setGround(val) {
      var groArr = [];
      for (var i in this.groups) {
        groArr.push(this.groups[i].name);
      }
      var Arr = new Set(groArr);
      // console.log(Arr)
      return Arr.has(val);
    },
    // 获取分组数据
    handleClick1: function handleClick1(tab, event) {
      var _this2 = this;
      this.delIndex = tab.index;
      if (tab.index > 1) {
        var index = parseInt(tab.index) - 2;
        // console.log(index)
        // console.log(this.groups[index])
        this.twsear.groupId = this.groups[index].id;
        (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
          res = res.data;
          if (!res == 200) {
            return;
          }
          _this2.twlist = res.data.records;
        });
      } else {
        if (tab.index == 0) {
          this.twsear.groupId = '';
          (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
            res = res.data;
            _this2.twlist = res.data.records;
          });
        }
        if (tab.index == 1) {
          this.twsear.groupId = 0;
          (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
            res = res.data;
            // console.log(res.data)
            _this2.twlist = res.data.records;
          });
        }
      }
    },
    // 搜索图文
    searchtw: function searchtw() {
      var _this3 = this;
      this.twsear.keyWord = this.input2;
      if (this.twsear.keyWord.replace(/(^\s*)|(\s*$)/g, '') == '') {
        this.twsear.keyWord = '';
      }
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        res = res.data;
        _this3.twlist = res.data.records;
      });
    },
    // 删除图文
    dltw: function dltw(val) {
      var n = {
        id: val,
        appId: sessionStorage.getItem('appId')
      };
      var that = this;
      (0, _wxgzh.deleteNews)(n).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          that.$message.success('删除成功');
          setTimeout(function () {
            that.$router.go(0);
          }, 500);
        } else {
          that.$message.warning(res.msg);
        }
      });
    },
    // 默认获取全部图文
    getAll: function getAll() {
      var _this4 = this;
      this.twsear.groupId = '';
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        res = res.data;
        // console.log(res.data.records)
        _this4.twlist = res.data.records;
        _this4.total = res.data.total;
        console.log(_this4.twlist);
      });
    },
    getGrouplist: function getGrouplist(index) {
      console.log(index);
    },
    // 图文分页
    twhandleCurrentChange: function twhandleCurrentChange(val) {
      var _this5 = this;
      this.twsear.pageNo = val;
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        res = res.data;
        console.log(res.data.records);
        _this5.twlist = res.data.records;
        console.log(_this5.twlist);
      });
    },
    wfztwhandleCurrentChange: function wfztwhandleCurrentChange(val) {
      var _this6 = this;
      this.twsear.pageNo = val;
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        res = res.data;
        _this6.twlist = res.data.records;
      });
    },
    fztwhandleCurrentChange: function fztwhandleCurrentChange(val) {
      var _this7 = this;
      this.twsear.pageNo = val;
      (0, _wxgzh.tWsearch)(this.twsear).then(function (res) {
        res = res.data;
        console.log(res.data.records);
        _this7.twlist = res.data.records;
        console.log(_this7.twlist);
      });
    },
    // 图片分页
    imghandleCurrentChange: function imghandleCurrentChange(val) {
      var _this8 = this;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.imgloaing = true;
      this.formInline.pageNo = val;
      var n = {
        mediaType: 3,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        res = res.data;
        console.log(res);
        // eslint-disable-next-line no-empty
        if (!res.code == 200) {
          _this8.$message.warning(res.msg);
        }
        console.log(res.data.records);
        _this8.Lists = res.data.records;
        _this8.total = res.data.total;
        _this8.imgloaing = false;
      });
    },
    // 语音分页
    vohandleCurrentChange: function vohandleCurrentChange(val) {
      var _this9 = this;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.voloaing = true;
      this.formInline.pageNo = val;
      var n = {
        mediaType: 2,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        res = res.data;
        console.log(res);
        // eslint-disable-next-line no-empty
        if (!res.code == 200) {
          _this9.$message.warning(res.msg);
        }
        console.log(res.data.records);
        _this9.tableData = res.data.records;
        _this9.total = res.data.total;
        _this9.voloaing = false;
      });
    },
    // 视频分页
    vihandleCurrentChange: function vihandleCurrentChange(val) {
      var _this10 = this;
      this.viloaing = true;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.pageNo = val;
      var n = {
        mediaType: 1,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code !== 200) {
          _this10.$message.error('获取失败!');
        }
        console.log(res.data.records);
        _this10.tableDatao = res.data.records;
        _this10.total = res.data.total;
        _this10.viloaing = false;
      });
    },
    // 查看图文
    checkNews: function checkNews() {},
    // 上传图片
    handleSuccess: function handleSuccess(file, fileList) {
      var _this11 = this;
      // this.Lists.push(fileList)
      this.Lists.push({
        name: fileList.name,
        url: file.data.url
      });
      this.imgloaing = true;
      var n = {
        mediaType: 3,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        res = res.data;
        console.log(res);
        // eslint-disable-next-line no-empty
        if (!res.code == 200) {
          _this11.$message.warning(res.msg);
        }
        console.log(res.data.records);
        _this11.Lists = res.data.records;
        _this11.total = res.data.total;
        _this11.imgloaing = false;
      });
      console.log(file);
    },
    // 删除图片列表
    handleRemove: function handleRemove(index, Lists) {
      var _this12 = this;
      console.log(Lists);
      console.log(Lists[index].id);
      var n = {
        id: Lists[index].id,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.deleteFiles)(n).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          console.log(res);
          _this12.$message.success('删除成功');
          Lists.splice(index, 1);
          _this12.imgloaing = true;
          _this12.mdtype = 3;
          var _n3 = {
            mediaType: _this12.mdtype,
            pageNo: _this12.formInline.pageNo,
            pageSize: _this12.formInlinepageSize,
            appId: sessionStorage.getItem('appId')
          };
          (0, _wxgzh.searchResource)(_n3).then(function (res) {
            res = res.data;
            console.log(res);
            if (!res.code == 200) {
              _this12.$message.warning(res.msg);
            }
            _this12.Lists = res.data.records;
            _this12.total = res.data.total;
            _this12.imgloaing = false;
          });
        } else {
          _this12.$message.warning(res.msg);
        }
      });

      // console.log(file, fileList)
    },
    imgSize: function imgSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;

      // this.dialogImageUrl = URL.createObjectURL(file.raw);
      // this.dialogVisible = true;
    },
    handlePictureCardPreview: function handlePictureCardPreview(index) {
      //  console.log(file)
      this.dialogImageUrl = this.twImgurl + this.Lists[index].url;
      this.dialogVisible = true;
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 1 \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    beforeRemove: function beforeRemove(file, fileList) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    // 上传语音
    vohandleSuccess: function vohandleSuccess(res, file, fileList) {
      var that = this;
      console.log(file);
      if (res.code * 1 === 200) {
        that.$message.success(res.msg);
        that.voloaing = true;
        that.voiceUp = false;
        that.$refs.upload.clearFiles();
        var n = {
          mediaType: 2,
          pageNo: that.formInline.pageNo,
          pageSize: that.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(n).then(function (res) {
          res = res.data;
          console.log(res);
          // eslint-disable-next-line eqeqeq
          if (res.code * 1 === 200) {
            console.log(res.data.records);
            that.tableData = res.data.records;
            that.total = res.data.total;
            that.voloaing = false;
          } else {
            that.$message.warning(res.msg);
          }
        });
        // that.vodis = false
      } else {
        that.$refs.upload.clearFiles();
        that.$message.warning('系统超时');
      }
      // this.voiceForm.id = file.data.id
      // this.voiceForm.name =
    },
    vochange: function vochange(file, fileList) {
      // console.log(f)
      if (file.status === 'ready') {
        this.vord = true;
        if (this.voiceForm.name.replace(/(^\s*)|(\s*$)/g, '') !== '' && fileList.length > 0) {
          this.vodis = false;
        }
      }
    },
    vorem: function vorem(file, fileList) {
      this.vodis = true;
    },
    projes: function projes(event, file, fileList) {
      console.log(event);
      console.log(file);
      console.log(fileList);
    },
    voSize: function voSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      // console.log(file)
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 2MB!');
      }
      if (!(file.type === 'audio/mp3' || file.type === '')) {
        this.$message.closeAll();
        this.$message.error('上传正确的语音文件!');
        // this.voiceUp = true
        return false;
      }
      return isLt2M;
    },
    // 上传文件失败
    voerr: function voerr() {
      this.$message.warning('系统超时');
    },
    vierr: function vierr() {
      this.$message.warning('系统超时');
    },
    // 打开上传语音清空列表
    voiceUpopen: function voiceUpopen() {
      this.voiceUp = true;
      this.$refs.upload.clearFiles();
      this.voiceForm.name = '';
    },
    // 上传语音
    upLoadvo: function upLoadvo() {
      if (this.voiceForm.name.replace(/(^\s*)|(\s*$)/g, '') == '' || this.voiceForm.name.length > 14) {
        return this.$message.warning('请输入有效名称');
      }
      if (!this.vord) {
        return this.$message.warning('请上传文件');
      }
      this.$refs.upload.submit();

      // this.voiceForm.appId = sessionStorage.getItem('appId')
      // updateFiles(this.voiceForm).then(res => {
      //   if (res.code * 1 === 200) {
      //     this.$message.success('上传成功')
      //     this.voloaing = true
      //     this.voiceUp = false
      //     this.$refs.upload.clearFiles()
      //     const n = {
      //       mediaType: 2,
      //       pageNo: this.formInline.pageNo,
      //       pageSize: this.formInlinepageSize,
      //       appId: sessionStorage.getItem('appId')
      //     }
      //     searchResource(n).then(res => {
      //       console.log(res)
      //       // eslint-disable-next-line eqeqeq
      //       if (res.code * 1 == 200) {
      //         console.log(res.data.records)
      //         this.tableData = res.data.records
      //         this.total = res.data.total
      //         this.voloaing = false
      //       } else {
      //         this.$message.warning(res.msg)
      //       }
      //     })
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    // 打开修改语音
    vohandleEdit: function vohandleEdit(index, row) {
      this.voiceXg = true;
      this.voiceForm.name = row[index].name;
      this.voiceForm.id = row[index].id;
    },
    // 修改语音名称
    xgLoadvo: function xgLoadvo() {
      var _this13 = this;
      if (this.voiceForm.name.replace(/(^\s*)|(\s*$)/g, '') == '' || this.voiceForm.name.length > 14) {
        return this.$message.warning('请输入有效名称');
      }
      this.voiceForm.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.updateFiles)(this.voiceForm).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this13.$message.success('修改成功');
          _this13.voiceXg = false;
          _this13.voloaing = true;
          _this13.mdtype = 1;
          var n = {
            mediaType: 2,
            pageNo: _this13.formInline.pageNo,
            pageSize: _this13.formInlinepageSize,
            appId: sessionStorage.getItem('appId')
          };
          (0, _wxgzh.searchResource)(n).then(function (res) {
            res = res.data;
            console.log(res);
            if (!res.code == 200) {
              _this13.$message.warning(res.msg);
            }
            _this13.tableData = res.data.records;
            _this13.total = res.data.total;
            _this13.voloaing = false;
          });
        } else {
          _this13.$message.error(res.msg);
        }
      });
    },
    // 删除语音列表
    vohandleDelete: function vohandleDelete(index, row) {
      var _this14 = this;
      console.log(row[index].id);
      var n = {
        id: row[index].id,
        appId: sessionStorage.getItem('appId')
      };
      row.splice(index, 1);
      console.log(n);
      (0, _wxgzh.deleteFiles)(n).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this14.$message.success('删除成功');
          _this14.voloaing = true;
          _this14.mdtype = 2;
          var _n4 = {
            mediaType: _this14.mdtype,
            pageNo: _this14.formInline.pageNo,
            pageSize: _this14.formInlinepageSize,
            appId: sessionStorage.getItem('appId')
          };
          (0, _wxgzh.searchResource)(_n4).then(function (res) {
            res = res.data;
            console.log(res);
            if (res.code * 1 === 200) {
              _this14.tableDatao = res.data.records;
              _this14.total = res.data.total;
              _this14.voloaing = false;
            } else {
              _this14.$message.warning(res.msg);
            }
          });
        } else {
          _this14.$message.warning(res.msg);
        }
      });
    },
    // 播放语音
    voplayer: function voplayer(index, rows, row) {
      // this.prevo = true
      (0, _wxgzh.materialVoiceDownload)({
        mediaId: rows[index].mediaId,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/attachment;filename='
        });
        console.log(blob);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        // downloadElement.download = (new Date().getTime()) + '.amr'
        downloadElement.download = row.trueName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 上传视频
    vichange: function vichange(file, fileList) {
      if (file.status === 'ready') {
        this.vird = true;
        if (this.videoForm.name.replace(/(^\s*)|(\s*$)/g, '') !== '') {
          this.vidis = false;
        }
      }
    },
    virem: function virem(file, fileList) {
      this.vidis = true;
    },
    vihandleSuccess: function vihandleSuccess(response, file, fileList) {
      var that = this;
      if (response.code * 1 === 200) {
        // that.videoForm.id = response.data.id
        // that.vidis = false
        that.$message.success(response.msg);
        that.videoUp = false;
        that.viloaing = true;
        that.mdtype = 1;
        var n = {
          mediaType: that.mdtype,
          pageNo: that.formInline.pageNo,
          pageSize: that.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(n).then(function (res) {
          res = res.data;
          console.log(res);
          if (!res.code == 200) {
            that.$message.warning(res.msg);
          }
          that.tableDatao = res.data.records;
          that.total = res.data.total;
          that.viloaing = false;
        });
      } else {
        that.$message.error('系统超时');
        that.$refs.uploadvi.clearFiles();
      }
    },
    viSize: function viSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 10MB!');
      }
      return isLt2M;
    },
    // 上传成功
    upLoadvi: function upLoadvi() {
      if (this.videoForm.name.replace(/(^\s*)|(\s*$)/g, '') == '' || this.videoForm.name.length > 10) {
        return this.$message.warning('请输入有效名称');
      }
      if (!this.vird) {
        return this.$message.warning('请上传文件');
      }
      this.$refs.uploadvi.submit();
      // this.videoForm.appId = sessionStorage.getItem('appId')
      // updateFiles(this.videoForm).then(res => {
      //   if (res.code !== 200) {
      //     this.$message.error(res.msg)
      //   }
      //   this.$message.success('上传成功')
      //   this.$refs.uploadvi.clearFiles()
      //   this.videoUp = false
      //   this.viloaing = true
      //   this.mdtype = 1
      //   const n = {
      //     mediaType: this.mdtype,
      //     pageNo: this.formInline.pageNo,
      //     pageSize: this.formInlinepageSize,
      //     appId: sessionStorage.getItem('appId')
      //   }
      //   searchResource(n).then(res => {
      //     console.log(res)
      //     if (!res.code == 200) {
      //       this.$message.warning(res.msg)
      //     }
      //     this.tableDatao = res.data.records
      //     this.total = res.data.total
      //     this.viloaing = false
      //   })
      // })
    },
    videoUpopen: function videoUpopen() {
      this.videoUp = true;
      this.$refs.uploadvi.clearFiles();
      this.videoForm.name = '';
    },
    // 修改视频打开
    xgLoadviopen: function xgLoadviopen(index, row) {
      this.videoXg = true;
      this.videoForm.name = row[index].name;
      this.videoForm.id = row[index].id;
    },
    xgLoadvi: function xgLoadvi(index, row) {
      var _this15 = this;
      // console.log(index)
      if (this.videoForm.name.replace(/(^\s*)|(\s*$)/g, '') == '' || this.videoForm.name.length > 10) {
        return this.$message.warning('请输入有效名称');
      }
      console.log(this.videoForm);
      this.videoForm.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.updateFiles)(this.videoForm).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this15.$message.success('修改成功');
          _this15.videoXg = false;
          _this15.viloaing = true;
          _this15.mdtype = 1;
          var n = {
            mediaType: _this15.mdtype,
            pageNo: _this15.formInline.pageNo,
            pageSize: _this15.formInlinepageSize,
            appId: sessionStorage.getItem('appId')
          };
          (0, _wxgzh.searchResource)(n).then(function (res) {
            res = res.data;
            console.log(res);
            if (res.code * 1 === 200) {
              _this15.tableDatao = res.data.records;
              _this15.total = res.data.total;
              _this15.viloaing = false;
            } else {
              _this15.$message.warning(res.msg);
            }
          });
        } else {
          _this15.$message.error(res.msg);
        }
      });
    },
    // 删除视频列表
    deleteRow: function deleteRow(index, row) {
      var _this16 = this;
      var n = {
        id: row[index].id,
        appId: sessionStorage.getItem('appId')
      };
      row.splice(index, 1);
      console.log(index);
      (0, _wxgzh.deleteFiles)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (!res.code == 200) {
          _this16.$message.warning(res.msg);
        }
        _this16.$message.success('删除成功');
        _this16.viloaing = true;
        _this16.mdtype = 1;
        var n = {
          mediaType: _this16.mdtype,
          pageNo: _this16.formInline.pageNo,
          pageSize: _this16.formInlinepageSize,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchResource)(n).then(function (res) {
          res = res.data;
          console.log(res);
          if (!res.code == 200) {
            _this16.$message.warning(res.msg);
          }
          _this16.tableDatao = res.data.records;
          _this16.total = res.data.total;
          _this16.viloaing = false;
        });
      });
    },
    // 预览视频
    previewvi: function previewvi(index, row) {
      var _this17 = this;
      (0, _wxgzh.materialVideoInfo)({
        mediaId: row[index].mediaId,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        res = res.data;
        console.log(res);
        _this17.previ = true;
        _this17.viSrc = res.data;
      });
    },
    // 修改分组
    open1: function open1(index, groups) {
      var _this18 = this;
      this.$prompt('分组名称', '修改分组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this18.$message({
            type: 'warning',
            message: '请输入有效文字'
          });
          return;
        }
        if (value === _this18.groups[index].name) {
          return _this18.$message({
            type: 'success',
            message: '修改成功'
          });
        }
        if (_this18.setGround(value)) {
          _this18.$message({
            type: 'warning',
            message: '已存在的分组'
          });
          return;
        }
        (0, _wxgzh.getGroup)({
          appId: sessionStorage.getItem('appId')
        }).then(function (res) {
          res = res.data;
          if (res.code !== 200) {
            _this18.$message({
              type: 'info',
              message: res.msg
            });
            return;
          }
          var p = {
            id: res.data.data.groupDOList[index].id,
            name: value
          };
          console.log(p);
          (0, _wxgzh.updateGroup)(p).then(function (res) {
            res = res.data;
            if (!res.code == 200) {
              _this18.$message({
                type: 'warning',
                message: res.msg
              });
              return;
            }
            _this18.$message({
              type: 'success',
              message: '修改分组是: ' + value
            });
          });
        });
        _this18.groups[index].name = value;
      }).catch(function () {
        _this18.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 删除分组
    open3: function open3(groups) {
      var _this19 = this;
      if (this.delIndex < 2) {
        this.$message({
          type: 'warning',
          message: '系统分组不允许删除'
        });
        return;
      } else {
        var adIndex = this.delIndex - 2;
        this.$confirm('此操作将删除该分组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _wxgzh.getGroup)({
            appId: sessionStorage.getItem('appId')
          }).then(function (res) {
            res = res.data;
            if (!res.code == 200) {
              _this19.$message({
                type: 'warning',
                message: res.msg
              });
              return;
            }
            var p = {
              id: res.data.data.groupDOList[adIndex].id,
              appId: sessionStorage.getItem('appId')
            };
            console.log(p);
            (0, _wxgzh.deleteGroup)(p).then(function (res) {
              res = res.data;
              console.log(res);
              if (!res == 200) {
                _this19.$message({
                  type: 'warning',
                  message: res.msg
                });
                return;
              }
              _this19.$message({
                type: 'success',
                message: '删除成功'
              });
            });
          });
          _this19.groups.splice(adIndex, 1);
        }).catch(function () {
          _this19.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 新建分组
    open: function open() {
      var _this20 = this;
      this.$prompt('分组名称', '新建分组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref2) {
        var value = _ref2.value;
        if (value.length > 8) {
          _this20.$message({
            type: 'warning',
            message: '最多输入8位'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this20.$message({
            type: 'warning',
            message: '请输入有效文字'
          });
          return;
        }
        if (_this20.setGround(value)) {
          _this20.$message({
            type: 'warning',
            message: '已存在的分组'
          });
          return;
        }
        // console.log(Arr.has(value))

        var p = {
          name: value.trim(),
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.createGroup)(p).then(function (res) {
          res = res.data;
          if (!res.code == 200) {
            _this20.$message({
              type: 'error',
              message: res.msg
            });
            return;
          }
          // console.log(res)

          console.log(p);
          _this20.groups.push(p);
          _this20.$message({
            type: 'success',
            message: '创建成功'
          });
        });
        console.log(_this20.groups);
      }).catch(function () {
        _this20.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
};
exports.default = _default;