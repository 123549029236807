var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "box", staticClass: "cardBox tabBox" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
          },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-steps",
                  {
                    attrs: {
                      active: _vm.active,
                      space: 800,
                      "align-center": "",
                      "finish-status": "success",
                    },
                  },
                  [
                    _c("el-step", { attrs: { title: "基础信息" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "引导页设置" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "活动页设置" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "信息收集页" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "引导关注页" } }),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("el-step", { attrs: { title: "我的字牌" } })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 0,
                          expression: "active===0",
                        },
                      ],
                      staticStyle: { padding: "40px" },
                      attrs: { label: "基础信息", name: "basic" },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "row-bg",
                          attrs: { type: "flex", justify: "space-around" },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 12, md: 16, sm: 18 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "basicForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.rules,
                                    model: _vm.basicForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模版名称：",
                                        prop: "name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入不能超过25个汉字",
                                          type: "text",
                                          maxlength: "25",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.basicForm.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.basicForm, "name", $$v)
                                          },
                                          expression: "basicForm.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "备注：",
                                        prop: "templateBak",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.disabled
                                            ? ""
                                            : "请输入不能超过25个汉字",
                                          type: "textarea",
                                          maxlength: "25",
                                          autosize: { minRows: 4, maxRows: 6 },
                                          "show-word-limit": "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.basicForm.templateBak,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.basicForm,
                                              "templateBak",
                                              $$v
                                            )
                                          },
                                          expression: "basicForm.templateBak",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "状态：",
                                        prop: "templateState",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "radio",
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: _vm.basicForm.templateState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.basicForm,
                                                "templateState",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "basicForm.templateState",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("启用")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("停用")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 1,
                          expression: "active===1",
                        },
                      ],
                      attrs: { label: "引导页", name: "guide" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 20 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.guideForm.bktotal +
                                        ");",
                                    },
                                    [
                                      _c("div", { staticClass: "phoneTitle" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.guideForm.title
                                              ? _vm.guideForm.title
                                              : "标题"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "defaultBG" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.guideForm.bktotal
                                              ? ""
                                              : "背景图"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.guideForm.guideAutoJump !==
                                                1,
                                              expression:
                                                "guideForm.guideAutoJump!==1",
                                            },
                                          ],
                                          staticClass: "phoneBtn",
                                          style:
                                            "background-image: url(" +
                                            _vm.guideForm.bkbutton +
                                            ");",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.guideForm.bkbutton
                                                ? ""
                                                : "引导按钮"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 10, sm: 20 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "guideForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.guideRules,
                                    model: _vm.guideForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "line-height": "40px" },
                                      attrs: {
                                        label: "页面状态：",
                                        prop: "guideEnable",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": "1",
                                          "inactive-value": "0",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.guideForm.guideEnable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.guideForm,
                                              "guideEnable",
                                              $$v
                                            )
                                          },
                                          expression: "guideForm.guideEnable",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "formTip" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          "\n                      开启后，将在进入活动页之前显示此引导页，可用于企业宣传或新品推广等。\n                    "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "标题：",
                                        prop: "title",
                                        rules: [
                                          {
                                            required:
                                              _vm.guideForm.guideEnable * 1 !==
                                                0 && !_vm.disabled,
                                            message: "请输入标题",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.disabled
                                            ? ""
                                            : "请输入不能超过8个汉字",
                                          type: "text",
                                          maxlength: "8",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.guideForm.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.guideForm,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "guideForm.title ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "背景：",
                                        prop: "title",
                                        rules: [
                                          {
                                            required:
                                              _vm.guideForm.guideEnable * 1 !==
                                                0 && !_vm.disabled,
                                            message: "请上传图片",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.guideForm.bktotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.guideForm,
                                              "bktotal",
                                              $$v
                                            )
                                          },
                                          expression: "guideForm.bktotal",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "guidebktotal",
                                        attrs: {
                                          data: _vm.guideForm.bktotal,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（750px*1334px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.guideForm,
                                              "bktotal",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "跳转形式：",
                                        prop: "guideAutoJump",
                                        rules: [
                                          {
                                            required:
                                              _vm.guideForm.guideEnable * 1 !==
                                                0 && !_vm.disabled,
                                            message: "请选择跳转形式",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: _vm.guideForm.guideAutoJump,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.guideForm,
                                                "guideAutoJump",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "guideForm.guideAutoJump",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "自动",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "手动",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "等待时间：",
                                        prop: "guideWaitSeconds",
                                        rules: [
                                          {
                                            required:
                                              _vm.guideForm.guideEnable * 1 !==
                                                0 && !_vm.disabled,
                                            message: "请选择等待时间",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value:
                                              _vm.guideForm.guideWaitSeconds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.guideForm,
                                                "guideWaitSeconds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "guideForm.guideWaitSeconds",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "1秒", value: "1" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "2秒", value: "2" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "3秒", value: "3" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.guideForm.guideAutoJump !== 1,
                                          expression:
                                            "guideForm.guideAutoJump!==1",
                                        },
                                      ],
                                      attrs: {
                                        label: "引导按钮：",
                                        prop: "bkbutton",
                                        rules: [
                                          {
                                            required:
                                              _vm.guideForm.guideEnable * 1 !==
                                                0 &&
                                              _vm.guideForm.guideAutoJump !==
                                                "1" &&
                                              !_vm.disabled,
                                            message: "请上传图片",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.guideForm.bkbutton,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.guideForm,
                                              "bkbutton",
                                              $$v
                                            )
                                          },
                                          expression: "guideForm.bkbutton",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "guidebkbutton",
                                        attrs: {
                                          data: _vm.guideForm.bkbutton,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（690px*94px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.guideForm,
                                              "bkbutton",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 2,
                          expression: "active===2",
                        },
                      ],
                      attrs: { label: "活动页", name: "active" },
                    },
                    [
                      _vm.type == 3
                        ? _c(
                            "el-row",
                            { staticStyle: { padding: "30px" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "padding-bottom": "20px" },
                                  attrs: { md: 10, sm: 20 },
                                },
                                [
                                  _c("div", { staticClass: "phoneWrap" }, [
                                    _c("div", { staticClass: "phoneOuter" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneInner",
                                          style:
                                            "background-image: url(" +
                                            _vm.zphdForm.bktotal +
                                            ");",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "phoneZp",
                                            style:
                                              "background-image: url(" +
                                              _vm.zphdForm.bkpart +
                                              ");",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "defaultBG" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.zphdForm.bktotal
                                                    ? ""
                                                    : "背景图"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { md: 10, sm: 20 } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "zphdForm",
                                      staticStyle: { margin: "10 auto" },
                                      attrs: {
                                        rules: _vm.zphdRules,
                                        model: _vm.zphdForm,
                                        "label-position": "right",
                                        "label-width": "120px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "背景：",
                                            prop: "bktotal",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.zphdForm.bktotal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.zphdForm,
                                                  "bktotal",
                                                  $$v
                                                )
                                              },
                                              expression: "zphdForm.bktotal",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "zphdbktotal",
                                            attrs: {
                                              data: _vm.zphdForm.bktotal,
                                              disabled: _vm.disabled,
                                              "limit-count": 1,
                                              text: "建议尺寸：（750px*1334px)",
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.zphdForm,
                                                  "bktotal",
                                                  $event
                                                )
                                              },
                                              getdata: _vm.setBgImg,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 2
                        ? _c(
                            "el-row",
                            { staticStyle: { padding: "30px" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "padding-bottom": "20px" },
                                  attrs: { md: 10, sm: 20 },
                                },
                                [
                                  _c("div", { staticClass: "phoneWrap" }, [
                                    _c("div", { staticClass: "phoneOuter" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneInner",
                                          style:
                                            "background-image: url(" +
                                            _vm.smhdForm.bktotal +
                                            ");",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "defaultBG" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.smhdForm.bktotal
                                                    ? ""
                                                    : "背景图"
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cjBtn",
                                              style:
                                                "background-image: url(" +
                                                _vm.smhdForm.bkbutton +
                                                ");",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.smhdForm.bkbutton
                                                    ? ""
                                                    : "抽奖按钮"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { md: 10, sm: 20 } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "smhdForm",
                                      staticStyle: { margin: "10 auto" },
                                      attrs: {
                                        rules: _vm.smhdRules,
                                        model: _vm.smhdForm,
                                        "label-position": "right",
                                        "label-width": "120px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "背景：",
                                            prop: "bktotal",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.smhdForm.bktotal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.smhdForm,
                                                  "bktotal",
                                                  $$v
                                                )
                                              },
                                              expression: "smhdForm.bktotal",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "smhdbktotal",
                                            attrs: {
                                              data: _vm.smhdForm.bktotal,
                                              disabled: _vm.disabled,
                                              "limit-count": 1,
                                              text: "建议尺寸：（750px*1334px)",
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.smhdForm,
                                                  "bktotal",
                                                  $event
                                                )
                                              },
                                              getdata: _vm.setBgImg,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "抽奖按钮:",
                                            prop: "bkbutton",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.smhdForm.bkbutton,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.smhdForm,
                                                  "bkbutton",
                                                  $$v
                                                )
                                              },
                                              expression: "smhdForm.bkbutton",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "smhdbkbutton",
                                            attrs: {
                                              data: _vm.smhdForm.bkbutton,
                                              disabled: _vm.disabled,
                                              "limit-count": 1,
                                              text: "建议尺寸：（100px*40px)",
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.smhdForm,
                                                  "bkbutton",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 1
                        ? _c(
                            "el-row",
                            { staticStyle: { padding: "30px" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "padding-bottom": "20px" },
                                  attrs: { md: 10, sm: 20 },
                                },
                                [
                                  _c("div", { staticClass: "phoneWrap" }, [
                                    _c("div", { staticClass: "phoneOuter" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneInner",
                                          style:
                                            "background-image: url(" +
                                            _vm.jzhdForm.bktotal +
                                            ");",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "phonejz",
                                            style:
                                              "background-image: url(" +
                                              _vm.jzhdForm.bkpart +
                                              ");",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "defaultBG" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.jzhdForm.bktotal
                                                    ? ""
                                                    : "背景图"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { md: 10, sm: 20 } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "jzhdForm",
                                      staticStyle: { margin: "10 auto" },
                                      attrs: {
                                        rules: _vm.jzhdRules,
                                        model: _vm.jzhdForm,
                                        "label-position": "right",
                                        "label-width": "120px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "背景：",
                                            prop: "bktotal",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.jzhdForm.bktotal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jzhdForm,
                                                  "bktotal",
                                                  $$v
                                                )
                                              },
                                              expression: "jzhdForm.bktotal",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "jzhdbktotal",
                                            attrs: {
                                              data: _vm.jzhdForm.bktotal,
                                              disabled: _vm.disabled,
                                              text: "建议尺寸：（750px*1334px)",
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.jzhdForm,
                                                  "bktotal",
                                                  $event
                                                )
                                              },
                                              getdata: _vm.setBgImg,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "字牌背景图片：",
                                            prop: "bkpart",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.jzhdForm.bkpart,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jzhdForm,
                                                  "bkpart",
                                                  $$v
                                                )
                                              },
                                              expression: "jzhdForm.bkpart",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "jzhdbkpart",
                                            attrs: {
                                              data: _vm.jzhdForm.bkpart,
                                              disabled: _vm.disabled,
                                              "limit-count": 1,
                                              text: "建议尺寸：（130px*200px)",
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.jzhdForm,
                                                  "bkpart",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 4
                        ? _c(
                            "el-row",
                            { staticStyle: { padding: "30px" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "padding-bottom": "20px" },
                                  attrs: { md: 10, sm: 20 },
                                },
                                [
                                  _c("div", { staticClass: "phoneWrap" }, [
                                    _c("div", { staticClass: "phoneOuter" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneInner",
                                          style:
                                            "background-image: url(" +
                                            _vm.zphdForm.bktotal +
                                            ");",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "phoneZp",
                                            style:
                                              "background-image: url(" +
                                              _vm.zphdForm.bkpart +
                                              ");",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "defaultBG" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.zphdForm.bktotal
                                                    ? ""
                                                    : "背景图"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { md: 10, sm: 20 } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "zphdForm",
                                      staticStyle: { margin: "10 auto" },
                                      attrs: {
                                        rules: _vm.zphdRules,
                                        model: _vm.zphdForm,
                                        "label-position": "right",
                                        "label-width": "120px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "背景：",
                                            prop: "bktotal",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { type: "hidden" },
                                            model: {
                                              value: _vm.zphdForm.bktotal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.zphdForm,
                                                  "bktotal",
                                                  $$v
                                                )
                                              },
                                              expression: "zphdForm.bktotal",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("Upload-img", {
                                            ref: "zphdbktotal",
                                            attrs: {
                                              data: _vm.zphdForm.bktotal,
                                              disabled: _vm.disabled,
                                              text: "建议尺寸：（750px*1334px)",
                                              "limit-count": 1,
                                            },
                                            on: {
                                              "update:data": function ($event) {
                                                return _vm.$set(
                                                  _vm.zphdForm,
                                                  "bktotal",
                                                  $event
                                                )
                                              },
                                              getdata: _vm.setBgImg,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 3,
                          expression: "active===3",
                        },
                      ],
                      attrs: { label: "信息收集页", name: "info" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 20 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.infoForm.bktotal +
                                        ");",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "phoneForm" },
                                        [
                                          _c(
                                            "el-form",
                                            { attrs: { "label-width": "0" } },
                                            _vm._l(
                                              _vm.infoForm.checkList,
                                              function (item, i) {
                                                return _c(
                                                  "el-form-item",
                                                  { key: i },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.info[item].name,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "defaultBG" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.infoForm.bktotal ? "" : "背景图"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneBtn",
                                          style:
                                            "background-image: url(" +
                                            _vm.infoForm.bkbutton +
                                            ");",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.infoForm.bkbutton
                                                ? ""
                                                : "按钮"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 10, sm: 20 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "infoForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.infoRules,
                                    model: _vm.infoForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "背景：",
                                        prop: "bktotal",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.infoForm.bktotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.infoForm,
                                              "bktotal",
                                              $$v
                                            )
                                          },
                                          expression: "infoForm.bktotal",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "infobktotal",
                                        attrs: {
                                          data: _vm.infoForm.bktotal,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（750px*1334px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.infoForm,
                                              "bktotal",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "按钮：",
                                        prop: "bkbutton",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.infoForm.bkbutton,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.infoForm,
                                              "bkbutton",
                                              $$v
                                            )
                                          },
                                          expression: "infoForm.bkbutton",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "infobkbutton",
                                        attrs: {
                                          data: _vm.infoForm.bkbutton,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（690px*94px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.infoForm,
                                              "bkbutton",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 4,
                          expression: "active===4",
                        },
                      ],
                      attrs: { label: "引导关注", name: "follow" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 20 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.followForm.bktotal +
                                        ");",
                                    },
                                    [
                                      _c("div", { staticClass: "defaultBG" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.followForm.bktotal
                                              ? ""
                                              : "背景图"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "phoneTxA" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.followForm.content
                                              ? _vm.followForm.content
                                              : "文字信息"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 10, sm: 20 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "followForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.followRules,
                                    model: _vm.followForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "背景：",
                                        prop: "bktotal",
                                        required: _vm.followForm.guideEnable,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.followForm.bktotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.followForm,
                                              "bktotal",
                                              $$v
                                            )
                                          },
                                          expression: "followForm.bktotal",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "followbktotal",
                                        attrs: {
                                          data: _vm.followForm.bktotal,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（750px*1334px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.followForm,
                                              "bktotal",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "文字信息：",
                                        prop: "content",
                                        required: _vm.followForm.guideEnable,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.disabled
                                            ? ""
                                            : "请输入不能超过200个汉字",
                                          type: "textarea",
                                          maxlength: "200",
                                          autosize: { minRows: 2, maxRows: 6 },
                                          "show-word-limit": "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.followForm.content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.followForm,
                                              "content",
                                              $$v
                                            )
                                          },
                                          expression: "followForm.content",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type == 1 && _vm.active === 5,
                          expression: "type==1&& active===5",
                        },
                      ],
                      attrs: { label: "我的字牌", name: "card" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 20 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.cardForm.bktotal +
                                        ");",
                                    },
                                    [
                                      _c("div", { staticClass: "phoneTitle" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.cardForm.title
                                              ? _vm.cardForm.title
                                              : "标题"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "row-bg",
                                          staticStyle: { "margin-top": "30px" },
                                          attrs: {
                                            type: "flex",
                                            justify: "space-around",
                                          },
                                        },
                                        [
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "row-bg",
                                          staticStyle: { "margin-top": "30px" },
                                          attrs: {
                                            type: "flex",
                                            justify: "space-around",
                                          },
                                        },
                                        [
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-col", {
                                            staticClass: "card",
                                            style:
                                              "background-image: url(" +
                                              _vm.cardForm.bkpart +
                                              ");",
                                            attrs: { span: 6 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 10, sm: 20 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "cardForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.cardRules,
                                    model: _vm.cardForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "标题：", prop: "title" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "不能超过8个汉字",
                                          type: "text",
                                          maxlength: "8",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.cardForm.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.cardForm, "title", $$v)
                                          },
                                          expression: "cardForm.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "背景：",
                                        prop: "bktotal",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.cardForm.bktotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cardForm,
                                              "bktotal",
                                              $$v
                                            )
                                          },
                                          expression: "cardForm.bktotal",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "cardbktotal",
                                        attrs: {
                                          data: _vm.cardForm.bktotal,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（200px*170px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.cardForm,
                                              "bktotal",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "字牌背景：",
                                        prop: "bkpart",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "hidden" },
                                        model: {
                                          value: _vm.cardForm.bkpart,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.cardForm,
                                              "bkpart",
                                              $$v
                                            )
                                          },
                                          expression: "cardForm.bkpart",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("Upload-img", {
                                        ref: "cardbkpart",
                                        attrs: {
                                          data: _vm.cardForm.bkpart,
                                          disabled: _vm.disabled,
                                          "limit-count": 1,
                                          text: "建议尺寸：（130px*200px)",
                                        },
                                        on: {
                                          "update:data": function ($event) {
                                            return _vm.$set(
                                              _vm.cardForm,
                                              "bkpart",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "20px",
                              "margin-bottom": "30px",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "normalBtn",
                                on: { click: _vm.goBack },
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _vm.active !== 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("up")
                                      },
                                    },
                                  },
                                  [_vm._v("上一步")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.id && _vm.disabled
                              ? [
                                  _vm.active < _vm.activeMax
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "normalBtn",
                                          attrs: {
                                            disabled:
                                              _vm.active === _vm.activeMax,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.subForm("down")
                                            },
                                          },
                                        },
                                        [_vm._v("下一步")]
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "normalBtn",
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.subForm("down")
                                        },
                                      },
                                    },
                                    [
                                      _vm.active < _vm.activeMax
                                        ? _c("span", [_vm._v("下一步")])
                                        : _c("span", [_vm._v("保存")]),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }