"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _ImgPreview = _interopRequireDefault(require("./ImgPreview"));
var ImgPreview = {};
ImgPreview.install = function (Vue) {
  var ImgConstructor = Vue.extend(_ImgPreview.default);
  var instance = new ImgConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$imgPreview = function (e, url) {
    instance.target = e.currentTarget;
    var imgArr = instance.target.getAttribute('data-img').split(',');
    for (var i = 0; i < imgArr.length; i++) {
      if (imgArr[i] == url) {
        imgArr.splice(i, 1);
      }
    }
    instance.imgs = [url].concat(imgArr);
    instance.isShowImageDialog = true;
  };
};
var _default = ImgPreview;
exports.default = _default;