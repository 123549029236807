var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "queryParams",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.queryParams,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "参与时间:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  clearable: "",
                                },
                                on: { change: _vm.sj },
                                model: {
                                  value: _vm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.rangeTime = $$v
                                  },
                                  expression: "rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发奖时间:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  clearable: "",
                                },
                                on: { change: _vm.sj2 },
                                model: {
                                  value: _vm.rangeTime2,
                                  callback: function ($$v) {
                                    _vm.rangeTime2 = $$v
                                  },
                                  expression: "rangeTime2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "中奖状态:", prop: "winStatus" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择中奖状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryParams.winStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "winStatus",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.winStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "未中奖", value: "0" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "已中奖", value: "1" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "openIdOrUserId",
                                "label-width": "0",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "openid/用户id" },
                                model: {
                                  value: _vm.queryParams.openIdOrUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "openIdOrUserId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "queryParams.openIdOrUserId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "数码经销商:",
                                    prop: "agentId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择数码经销商",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryParams.agentId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "agentId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.agentId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.agentList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.comBoxName,
                                            value: item.comBoxId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动产品:",
                                    prop: "productId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择活动产品",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryParams.productId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "productId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.productId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.productList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.comBoxName,
                                            value: item.comBoxId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动名称:",
                                    prop: "activityId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择活动名称",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryParams.activityId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "activityId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.activityId",
                                      },
                                    },
                                    _vm._l(_vm.actList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.activityName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "数码", prop: "markCode" } },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { placeholder: "请输入数码" },
                                    model: {
                                      value: _vm.queryParams.markCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "markCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryParams.markCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "IP查询", prop: "ipAddress" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { placeholder: "请输入IP" },
                                    model: {
                                      value: _vm.queryParams.ipAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "ipAddress",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryParams.ipAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "奖项:", prop: "prizeId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择奖项",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryParams.prizeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "prizeId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.prizeId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.prizeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.comBoxName,
                                            value: item.comBoxId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "领取状态:", prop: "status" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择领取状态",
                                      },
                                      model: {
                                        value: _vm.queryParams.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.status",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "1",
                                        attrs: { label: "未发放", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: { label: "已发放", value: "2" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "5",
                                        attrs: { label: "失败", value: "5" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "扫码用户:",
                                    prop: "nickName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { placeholder: "请输入扫码用户" },
                                    model: {
                                      value: _vm.queryParams.nickName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "nickName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryParams.nickName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "用户角色:", prop: "roleId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择领取状态",
                                      },
                                      model: {
                                        value: _vm.queryParams.roleId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "roleId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.roleId",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "0",
                                        attrs: { label: "消费者", value: "0" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: {
                                          label: "门店用户",
                                          value: "2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "奖品形式:",
                                    prop: "prizeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择领取状态",
                                      },
                                      model: {
                                        value: _vm.queryParams.prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "prizeType",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.prizeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "1",
                                        attrs: { label: "红包", value: "100" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: { label: "实物", value: "200" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "3",
                                        attrs: {
                                          label: "优惠券",
                                          value: "300",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "4",
                                        attrs: { label: "积分", value: "400" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "5",
                                        attrs: { label: "字卡", value: "500" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        key: "6",
                                        attrs: {
                                          label: "谢谢惠顾",
                                          value: "0",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.querylist },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetWinningLottery },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                    },
                    on: { click: _vm.exportLs },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                    },
                    on: { click: _vm.openExportDetail },
                  },
                  [_vm._v("导出明细")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "markCode",
                  label: "参与数码",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "参与活动",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "openId",
                  label: "openid",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "openid",
                  label: "商务订单号",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "disableStyle",
                            attrs: {
                              type: "text",
                              disabled: scope.row.status != 5,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.routerErrorPrize(
                                  scope.row.mchBillNo,
                                  scope.row.status != 5
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.mchBillNo))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "活动产品",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "agentName",
                  label: "数码经销商",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "roleName",
                  label: "用户角色",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "扫码用户",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberId",
                  label: "用户ID",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "winStatus",
                  label: "中奖结果",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.winStatus == 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未中奖"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.winStatus == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已中奖"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeType",
                  label: "奖品形式",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showPrizeType,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖项",
                  "min-width": "150",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "奖项来源",
                  prop: "prizeSource",
                  "min-width": "120",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.prizeSource == 1
                          ? _c("div", [_vm._v("解锁奖项")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.prizeSource == 2
                          ? _c("div", [_vm._v("活动奖项")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "领取状态",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未领取"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c("el-tag", [_vm._v("未发放")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已发放"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 3
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("未核销"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 4
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已核销"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 5
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("失败"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "winMoney",
                  label: "中奖数量(元)",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ipAddress",
                  label: "用户IP",
                  "min-width": "150",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "joinDateTime",
                  label: "参与活动时间",
                  "min-width": "200",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sendDateTime",
                  label: "发奖时间",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.current,
              limit: _vm.size,
            },
            on: {
              "update:page": function ($event) {
                _vm.current = $event
              },
              "update:limit": function ($event) {
                _vm.size = $event
              },
              pagination: _vm.getWinningLottery,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.look,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.look = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "90px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "物码信息", column: 2, border: "" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "物码" } }, [
                        _vm._v(_vm._s(_vm.detailsList.markInfo.markCode) + " "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属经销商" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.markInfo.agentName) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属产品" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.markInfo.productName) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "120px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "活动信息", column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属活动" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.activityName) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "扫码时间" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.joinDateTime) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "奖品名称" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.prizeName) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发奖时间" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.sendDateTime) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "领取状态" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("status")(
                                _vm.detailsList.activityInfo.status
                              )
                            ) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "奖品形式" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("prizeType")(
                                _vm.detailsList.activityInfo.prizeType
                              )
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticStyle: { "margin-right": "15px" } },
              [
                _c("el-image", {
                  staticStyle: { width: "100px", height: "120px" },
                  attrs: {
                    src: _vm.detailsList.wechatUserInfo.headImgUrl,
                    fit: "cover",
                  },
                }),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "text" }, [_vm._v("头像")])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "min-height": "100px" },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { title: "用户信息", column: 2, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "微信昵称" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.nickName) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "用户ID" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.memberId) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "unionID" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.unionId) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "最近IP" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.ipAddress) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "关联openid" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.openId) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "150px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: { title: "扩展信息(收集)", column: 2, border: "" },
                    },
                    _vm._l(_vm.collectInfo, function (item, index) {
                      return _c(
                        "el-descriptions-item",
                        { key: index, attrs: { label: item.fieldName } },
                        [_vm._v(_vm._s(item.value) + " ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.show
                    ? _c(
                        "el-col",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "line-height": "150px",
                          },
                          attrs: { span: 24 },
                        },
                        [_vm._v("\n          暂无数据\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出明细列表",
            visible: _vm.popupExportDetail,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupExportDetail = $event
            },
            close: _vm.hadnleCloseExportDetail,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.exportDataListLoading,
                      expression: "exportDataListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.exportDataList,
                    border: "",
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": true,
                    height: "550px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileName",
                      label: "文件名称",
                      "min-width": "20%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      "min-width": "35%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileUrl",
                      label: "下载地址",
                      "min-width": "30%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  href: scope.row.fileUrl,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.fileUrl))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "15%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c("el-tag", [_vm._v("进行中")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("完成"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.exportParams.total > 0,
                    expression: "exportParams.total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.exportParams.total,
                  page: _vm.exportParams.current,
                  limit: _vm.exportParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.exportParams, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.exportParams, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.queryExprotActivityForPage(
                      _vm.exportParams.current,
                      _vm.exportParams.size
                    )
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.hadnleCloseExportDetail },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }