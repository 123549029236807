"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRole = addRole;
exports.checkRequiredWord = void 0;
exports.editRole = editRole;
exports.getQdList = getQdList;
exports.getQdType = getQdType;
exports.getRoleAuths = getRoleAuths;
exports.getRoleDetail = getRoleDetail;
exports.getRoleList = getRoleList;
exports.getSystem = getSystem;
exports.rankRoleAuths = rankRoleAuths;
exports.scanRoleAuth = scanRoleAuth;
exports.startItems = startItems;
exports.startRole = startRole;
exports.stopItems = stopItems;
exports.stopRole = stopRole;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取渠道所属系统下拉
function getSystem(param) {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getAllQdClient',
    method: 'get',
    params: param
  });
}

// 获取渠道类型
function getQdType() {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getQdType',
    method: 'get'
  });
}

// 获取渠道
function getQdList(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/list',
    method: 'post',
    params: param
  });
}

// 获取渠道角色列表
function getRoleList(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/getQdForList',
    method: 'get',
    params: param
  });
}

// 添加渠道角色
function addRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/saveQd',
    method: 'post',
    params: param
  });
}

// 批量启用
function startItems(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/enableBatchQd',
    method: 'put',
    params: param
  });
}

// 批量禁用
function stopItems(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/disableBatchQd',
    method: 'put',
    params: param
  });
}

// 启用
function startRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/enableQd',
    method: 'put',
    params: param
  });
}

// 禁用
function stopRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/disableQd',
    method: 'put',
    params: param
  });
}

// 获取渠道角色详情
function getRoleDetail(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/queryQdDetail',
    method: 'get',
    params: param
  });
}

// 渠道角色修改
function editRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/updateQd',
    method: 'put',
    params: param
  });
}

// 获取全部的角色权限
function getRoleAuths(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryQdResourceList',
    method: 'get',
    params: param
  });
}

// 查看角色权限
function scanRoleAuth(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryQdResourceListByRoleCode',
    method: 'get',
    params: param
  });
}

// 分配权限
function rankRoleAuths(data) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/giveResourceQd',
    method: 'post',
    data: data
  });
}
var checkRequiredWord = function checkRequiredWord(rule, value, callback) {
  if (value) {
    var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('角色名称只能输入如汉字、字母、数字'));
    }
  } else {
    return callback(new Error('请输入角色名称'));
  }
};
exports.checkRequiredWord = checkRequiredWord;