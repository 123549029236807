"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.number.constructor");
var _supplierDialog = _interopRequireDefault(require("./supplierDialog"));
var _purchaseDialog = _interopRequireDefault(require("./purchaseDialog"));
var _productDialog = _interopRequireDefault(require("./productDialog"));
var _channel = require("@/api/basic/channel");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 供货
// 采购单位
// 产品
var _default = {
  name: '',
  components: {
    supplierDialog: _supplierDialog.default,
    purchaseDialog: _purchaseDialog.default,
    // personDialog,
    productDialog: _productDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '添加采供关系'
    },
    pid: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      // validator: validate,
      btnLoading: false,
      rules: {
        purchaseId: [{
          type: 'array',
          required: true,
          message: '采购商不能为空，请维护',
          trigger: 'change'
        }],
        supplierIds: [{
          type: 'array',
          required: true,
          message: '供货商不能为空，请维护',
          trigger: 'change'
        }]
      },
      formData: {
        purchaseId: [],
        supplierIds: [],
        pId: ''
      },
      tags: [],
      loading: false,
      // 产品弹窗属性
      productVisible: false,
      channelId: '',
      // 渠道ID
      products: [],
      // 产品列表
      // 采购属性
      purchaseVisible: false,
      purchases: [],
      //  已选择采购单位
      purchaseId: [],
      // 采购单位Id组
      purchasesType: 1,
      // 供货属性
      supplierIndex: '',
      // 供货单位下表
      supplierArr: [],
      suppliers: [],
      // 供货单位
      supplierIds: [],
      // 供货单位ID组
      supplierType: ''
    };
  },
  mounted: function mounted() {
    console.log(this);
  },
  methods: {
    // 打开供货商
    openCheck: function openCheck() {
      this.$refs.channelCheckbox.proSelVisible = true;
      this.$refs.channelCheckbox.setsel();
    },
    // 选择供货商
    setSupplier: function setSupplier(val) {
      var _this = this;
      if (val) {
        var pids = [];
        var arr = val.filter(function (item) {
          pids.push(item.pId);
          if (!_this.supplierIds.includes(item.pId)) {
            _this.$set(item, 'products', []);
            return item;
          }
        });
        if (arr.length > 0) {
          this.suppliers = [].concat((0, _toConsumableArray2.default)(this.suppliers), (0, _toConsumableArray2.default)(arr));
        }
        this.suppliers = this.suppliers.filter(function (item) {
          return pids.includes(item.pId);
        });
        this.supplierIds = pids;
        this.supplierType = this.purchasesType;
      } else {
        this.suppliers = [];
        this.supplierIds = [];
        this.supplierType = '';
      }
      this.supplierArr = val;
      this.formData.supplierIds = this.supplierIds;
      this.$refs.formData.clearValidate('supplierIds');
    },
    deleteSupplie: function deleteSupplie(id) {
      this.suppliers = this.suppliers.filter(function (item) {
        return item.pid !== id;
      });
      this.supplierIds = this.supplierIds.filter(function (item) {
        return item !== id;
      });
      this.supplierArr = this.supplierArr.filter(function (item) {
        return item.pId !== id;
      });
      if (this.suppliers.length < 1) {
        this.supplierType = '';
      }
    },
    // 打开采购商
    openPurchase: function openPurchase() {
      if (this.suppliers.length > 0) {
        this.purchaseVisible = true;
      } else {
        this.$message({
          message: '请选择供货(上游)单位',
          type: 'error'
        });
      }
    },
    // 采购商选择
    setPurchase: function setPurchase(val, purchasesType) {
      var _this2 = this;
      console.log(purchasesType);
      this.purchases = val;
      this.purchasesType = purchasesType;
      val.map(function (item) {
        _this2.formData.purchaseId.push(item.pId);
      });
      this.$refs.formData.clearValidate('purchaseId');
    },
    // 删除单个采购单位
    deletePurchaseItem: function deletePurchaseItem(pid) {
      this.purchases = this.purchases.filter(function (item) {
        return pid !== item.pId;
      });
    },
    // 打开产品
    openProduct: function openProduct(index, suppliersItem) {
      this.supplierIndex = index;
      this.productVisible = true;
      if ([1].includes(suppliersItem.channelType)) {
        this.channelId = suppliersItem.pId;
      } else {
        this.channelId = '';
      }
      this.products = suppliersItem.products;
    },
    // 设置产品
    setProduct: function setProduct(val) {
      var index = this.supplierIndex;
      this.$set(this.suppliers[index], 'products', val);
    },
    // 删除产品
    deleteProducteItem: function deleteProducteItem(index, id) {
      this.suppliers[index].products = this.suppliers[index].products.filter(function (item) {
        return item.pId !== id;
      });
    },
    closeProduct: function closeProduct() {
      this.productVisible = false;
    },
    // 关闭
    handleClose: function handleClose(type) {
      this.$emit('close', type);
    },
    // 采供关系提交
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var submitData = {};
          submitData.purchaseIds = [];
          _this3.purchases.map(function (item) {
            submitData.purchaseIds.push(item.pId);
          });
          var arr = [];
          var status = false;
          var index = '';
          for (var i = 0; i < _this3.suppliers.length; i++) {
            if (_this3.suppliers[i].products.length > 0) {
              var obj = {};
              obj.supplierId = _this3.suppliers[i].pId;
              obj.productIds = [];
              _this3.suppliers[i].products.map(function (product) {
                obj.productIds.push(product.pId);
              });
              arr.push(obj);
            } else {
              index = i;
              status = true;
              break;
            }
          }
          if (status) {
            _this3.$message({
              type: 'error',
              message: "\u7B2C".concat(index + 1, "\u6392\u4F9B\u8D27\u5355\u4F4D\u4EA7\u54C1\u4E3A\u7A7A\uFF0C\u8BF7\u6DFB\u52A0\u4EA7\u54C1\u540E\u63D0\u4EA4\u3002")
            });
            return;
          }
          submitData.supplierIds = arr;
          _this3.btnLoading = true;
          if (_this3.formData.pId) {
            submitData.pId = _this3.formData.pId;
            (0, _channel.channelCollectionSupplyupdate)(submitData).then(function (res) {
              _this3.btnLoading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose(1);
              }
            });
          } else {
            (0, _channel.channelCollectionSupplyinsert)(submitData).then(function (res) {
              _this3.btnLoading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose(1);
              }
            });
          }
        }
      });
    }
  }
};
exports.default = _default;