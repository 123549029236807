"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.getKeysetVal = getKeysetVal;
exports.getSysSearchDefDate = getSysSearchDefDate;
exports.setPermission = setPermission;
exports.tableHeight = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 格式化时间戳到指定格式日期
 * @param {*} date
 * @param {*} fmt
 */
function formatDate(date, fmt) {
  if (!(date instanceof Date) || isNaN(date)) {
    return '';
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    var str = o[k] + '';
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
}

/**
 * 获取指定天数起止日期
 * @param {*} com_days
 */
function getSysSearchDefDate(com_days) {
  var end = new Date();
  var start = new Date();
  var nYear = start.getFullYear();
  var days = 365;
  if (nYear % 4 == 0 && nYear % 100 != 0 || nYear % 400 == 0) {
    days = 366;
  }
  if (com_days != '' && com_days != undefined && com_days != null) {
    days = com_days;
  }
  start.setTime(start.getTime() - 3600 * 1000 * 24 * days);
  return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}

/**
 * 新对象改变旧对象值
 * @param {*} old_v
 * @param {*} new_v
 */
function getKeysetVal(old_v, new_v) {
  for (var i in old_v) {
    if ((0, _typeof2.default)(old_v[i]) == 'object') {
      for (var n in old_v[i]) {
        old_v[i][n] = new_v[i][n];
      }
    } else {
      if (typeof new_v[i] != 'undefined') {
        if (new_v[i] == null) {
          new_v[i] = '';
        }
        old_v[i] = new_v[i];
      }
    }
  }
  return old_v;
}
var tableHeight = function tableHeight() {
  var offTop = document.getElementsByClassName('search-condition')[0].getBoundingClientRect().top;
  var winH = document.documentElement.clientHeight || document.body.clientHeight;
  console.log(winH, offTop);
  return winH - offTop - 134;
};
exports.tableHeight = tableHeight;
var setPowers = {
  GYSBSZPZ: [
  //供应商标识组配置
  {
    resourceCode: "GYSBSZXQ",
    view: true,
    resourceUrl: "/api/v1/api-mark/flagGroup/getSupplierInfoById",
    resourceName: "供应商标识组详情"
  }, {
    resourceCode: "GYSBSZGX",
    view: true,
    resourceUrl: "/api/v1/api-mark/flagGroup/saveSupplierRFlag",
    resourceName: "供应商标识组更新"
  }, {
    resourceCode: "GYSBSZJH",
    view: true,
    resourceUrl: "/api/v1/api-mark/flagGroup/isEnable",
    resourceName: "供应商标识组激活"
  }, {
    resourceCode: "GYSBSZLBCX",
    view: true,
    resourceUrl: "/api/v1/api-mark/flagGroup/queryForSup",
    resourceName: "供应商标识组列表查询"
  }]
};

/**
 * 根据当前用户设置操作权限
 */
function setPermission(that, setName) {
  var name = that.$route.path.split('=');
  name = setName;
  var powers = typeof setPowers[setName] == 'undefined' ? false : setPowers[setName];
  if (powers == false) return powers;
  var permission = JSON.parse(sessionStorage.getItem('authList'));
  permission.find(function (e) {
    if (e.name == name) {
      e.value.find(function (v, index) {
        powers.find(function (pv, pindex) {
          if (pv.resourceCode == v.resourceCode) {
            powers[pindex].view = true;
          }
        });
      });
    }
  });
  var new_powers = {};
  for (var i in powers) {
    new_powers[powers[i].resourceCode] = {
      view: powers[i].view,
      resourceUrl: powers[i].resourceUrl
    };
  }
  powers = '';
  return new_powers;
}