"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stockscan = require("@/api/chanelmange/stockscan");
var _param = require("@/api/chanelmange/param");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        endTime: null,
        orgId: null,
        policyCode: null,
        policyName: null,
        size: 20,
        startTime: null
      },
      tableKey: 'flzc',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      orgOptions: [],
      showCol: false,
      expandTxt: '展开'
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getOrg();
    // this.DoSearch();
    that.getList();
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      (0, _param.getFlZcList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          // console.log(res);
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          that.listLoading = false;
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      var param = {};
      param.orgType = '2';
      param.orgTypeList = ['2'];
      (0, _stockscan.getTjQueryOrg)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        isCode: this.isCode
      };
      this.valueCode = '';
      this.filterText = '';
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.listQuery.size = 20;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        current: 1,
        endTime: '',
        orgId: null,
        policyCode: '',
        policyName: '',
        size: 20,
        startTime: ''
      };
      that.getList();
    },
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    selCancel: function selCancel() {
      this.resetForm();
      //   this.clear();
      this.$emit('close');
      // this.proSelVisible = false
    }
  }
};
exports.default = _default2;