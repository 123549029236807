"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _activityManage = require("@/api/terminal/activityManage");
var _channel = require("@/api/basic/channel");
var _config = _interopRequireDefault(require("@/views/terminalManagement/listofunpackedActivities/components/config"));
var _details_flow = _interopRequireDefault(require("@/views/terminalManagement/listofunpackedActivities/components/details_flow"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    actConfig: _config.default,
    detailsflow: _details_flow.default
  },
  filters: {
    approval: function approval(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未审核',
        1: '已审核'
      };
      return statusMap[status];
    },
    activityDimension: function activityDimension(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品布奖',
        1: '订单布奖'
      };
      return statusMap[status];
    },
    templateType: function templateType(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '引导关注类',
        1: '扫码抽奖',
        2: '大转盘',
        3: '集字',
        4: '翻牌',
        5: '九宫格',
        6: '刮刮乐',
        7: '扭蛋机',
        8: '扫码即抽奖'
      };
      return statusMap[status];
    },
    timeControlType: function timeControlType(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品生产时间',
        1: '产品出库时间'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      h_batcheVisibleShow: false,
      eVisibleShow: false,
      handleShow: false,
      ruleForm: {
        activityNameOrActivityCode: '',
        // activityCode: '',
        // activityName: '',
        activityStatus: '',
        endTime: '',
        schemeId: '',
        startTime: '',
        storeTypeCode: '',
        strategyGroupId: '',
        templateName: '',
        templateType: '',
        timeControlEndTime: '',
        timeControlStartTime: '',
        timeControlType: '',
        activityDimension: '',
        pageNo: 1,
        pageSize: 10,
        rangeTime: '',
        rangeTime2: ''
      },
      activityStatus: [{
        name: '未开始',
        value: '0'
      }, {
        name: '停用',
        value: '1'
      }, {
        name: '进行中',
        value: '2'
      }, {
        name: '已过期',
        value: '3'
      }],
      timeControlType: [{
        name: '产品生产时间',
        value: '0'
      }, {
        name: '产品出库时间',
        value: '1'
      }],
      templateTypes: [
      // { name: '引导关注类', id: '0' },
      {
        name: '扫码抽奖',
        id: '1'
      }, {
        name: '大转盘',
        id: '2'
      },
      // { name: '集字', id: '3' },
      // { name: '翻牌', id: '4' },
      // { name: '九宫格', id: '5' },
      // { name: '刮刮乐', id: '6' },
      // { name: '扭蛋机', id: '7' },
      {
        name: '扫码即抽奖',
        id: '8'
      }],
      terminalStoreList: [],
      tabHeight: 'calc(100vh - 350px)',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      downLoading: false,
      dialogVisible: false,
      actId: '',
      type: 'add',
      readonly: false
    };
  },
  created: function created() {},
  activated: function activated() {
    var that = this;
    that.getList();
    that.getTerminalStore();
    that.getInitPrizeList('100');
    that.getInitPrizeList('200');
    that.getInitPrizeList('300');
    that.getInitPrizeList('400');
    that.getInitPrizeList('500');
    that.getInitPrizeList('900');
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    Number('0');
  },
  methods: {
    // 测试
    h_batchListShow: function h_batchListShow() {
      var _this = this;
      this.h_batcheVisibleShow = true;
      setTimeout(function () {
        _this.$refs.h_batchVisible.visible = true;
        _this.$refs.h_batchVisible.setsel();
      }, 0);
    },
    h_setBatch: function h_setBatch(data) {
      this.h_batcheVisibleShow = false;
      console.log(data);
    },
    ListShow: function ListShow() {
      var _this2 = this;
      this.eVisibleShow = true;
      setTimeout(function () {
        _this2.$refs.Visible.visible = true;
        _this2.$refs.Visible.setsel();
      }, 0);
    },
    Batch: function Batch(data) {
      this.eVisibleShow = false;
      console.log(data);
    },
    // 根据奖品类型初始化奖品列表
    getInitPrizeList: function getInitPrizeList(type) {
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: type
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          sessionStorage.setItem('zd' + type, JSON.stringify(res.data.data));
        }
      });
    },
    // 测试
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      if (this.ruleForm.rangeTime && this.ruleForm.rangeTime.length > 0) {
        this.ruleForm.startTime = this.ruleForm.rangeTime[0];
        this.ruleForm.endTime = this.ruleForm.rangeTime[1];
      } else {
        this.ruleForm.startTime = '';
        this.ruleForm.endTime = '';
      }
      if (this.ruleForm.rangeTime2 && this.ruleForm.rangeTime2.length > 0) {
        this.ruleForm.timeControlStartTime = this.ruleForm.rangeTime2[0];
        this.ruleForm.timeControlEndTime = this.ruleForm.rangeTime2[1];
      } else {
        this.ruleForm.timeControlStartTime = '';
        this.ruleForm.timeControlEndTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.ruleForm));
      sdata.storeTypeCode = this.ruleForm.storeTypeCode.toString();
      delete sdata.rangeTime;
      delete sdata.rangeTime2;
      (0, _activityManage.geActList)(sdata).then(function (res) {
        if (res.data.code == '200') {
          if (!res.data.data.errCode) {
            _this3.list = res.data.data.records;
            _this3.total = res.data.data.total;
            _this3.listLoading = false;
          } else {
            _this3.listLoading = false;
            _this3.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this3.listLoading = false;
          _this3.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    resetForm: function resetForm(refname) {
      this.$refs[refname].resetFields();
      this.ruleForm.startTime = '';
      this.ruleForm.endTime = '';
      this.ruleForm.timeControlStartTime = '';
      this.ruleForm.timeControlEndTime = '';
      this.ruleForm.pageNo = 1;
      this.ruleForm.rangeTime = '';
      this.ruleForm.rangeTime2 = '';
      this.getList();
    },
    // 新增活动
    creatAct: function creatAct() {
      // this.authId('addbtn', this.apis)
      this.type = 'add';
      this.readonly = false;
      this.dialogVisible = true;
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      this.getList();
      this.actId = '';
      this.readonly = false;
      this.type = 'add';
    },
    // 编辑
    edit: function edit(data) {
      if (data.activityStatus == '2' || data.activityStatus == '3') {
        this.readonly = true;
      } else {
        this.readonly = false;
      }
      this.type = 'edit';
      this.actId = data.id;
      this.dialogVisible = true;
    },
    // 查看
    look: function look(row, qx) {
      // this.readonly = true
      // this.type = 'look'
      // this.actId = data.id
      // this.dialogVisible = true
      this.$refs.flowMain.show(row, qx);
    },
    // 审批
    approve: function approve(row, qx) {
      this.$refs.flowMain.show(row, qx);
    },
    // 复制活动
    copy: function copy(data) {
      // this.authId('copyBtn', this.apis)
      this.type = 'copy';
      this.actId = data.id;
      this.readonly = false;
      this.dialogVisible = true;
    },
    // 活动停止开始
    setDisable: function setDisable(val) {
      var _this4 = this;
      // this.authId('stopbtn', this.apis)
      this.$confirm('是否' + (val.activityStatus == '1' ? '启用' : '停用') + '活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 点击确定的操作(调用接口)
        (0, _activityManage.actDisable)({
          id: val.id
        }).then(function (res) {
          console.log(res);
          if (res.data.code == 200) {
            if (!res.data.data.errCode) {
              _this4.getList();
            } else {
              _this4.$message.error(res.data.data.errMsg);
            }
          } else {
            _this4.$message.error(res.data.msg);
            return;
          }
        });
      }).catch(function () {
        // 点取消的提示
        return;
      });
    },
    //
    inform: function inform(row) {
      this.$router.push({
        name: 'terminalManagementlistofunpackedActivitiesactivitiesListindex',
        params: {
          activityCode: row.activityCode
        }
      });
    },
    // 导出奖品
    exportExcel: function exportExcel() {
      var _this5 = this;
      this.downLoading = true;
      if (this.ruleForm.rangeTime && this.ruleForm.rangeTime.length > 0) {
        this.ruleForm.startTime = this.ruleForm.rangeTime[0];
        this.ruleForm.endTime = this.ruleForm.rangeTime[1];
      } else {
        this.ruleForm.startTime = '';
        this.ruleForm.endTime = '';
      }
      if (this.ruleForm.rangeTime2 && this.ruleForm.rangeTime2.length > 0) {
        this.ruleForm.timeControlStartTime = this.ruleForm.rangeTime2[0];
        this.ruleForm.timeControlEndTime = this.ruleForm.rangeTime2[1];
      } else {
        this.ruleForm.timeControlStartTime = '';
        this.ruleForm.timeControlEndTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.ruleForm));
      // sdata.seqNo = this.multipleSelection.map(item => item.seqNo).join(',')
      delete sdata.rangeTime;
      delete sdata.rangeTime2;
      console.log(sdata);
      (0, _activityManage.exportExcel)(sdata).then(function (res) {
        _this5.downLoading = false;
        if (res.status !== 200) {
          _this5.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this5.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '活动列表.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this5.downLoading = false;
      });
    },
    getTerminalStore: function getTerminalStore() {
      var _this6 = this;
      (0, _channel.terminalStore)().then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            // var arr = []
            // res.data.data.forEach(item => {
            //   arr.push({
            //     targetType: '0',
            //     targetUserCode: item.terminalTypeCode,
            //     targetUserName: item.terminalTypeName
            //   })
            // })
            _this6.terminalStoreList = res.data.data;
          } else {
            _this6.terminalStoreList = [];
          }
        } else {
          _this6.$message.error(res.data.msg);
          return;
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
exports.default = _default;