"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _scanWhiteListing = require("@/api/operationalTools/scanWhiteListing");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsscanWhiteListingindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsscanWhiteListingindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        operator: '',
        scanCode: '',
        endTime: '',
        startTime: ''
      },
      params: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      // 导出按钮加载
      downLoading: false,
      // 批量导入
      mloading: false,
      uploadVisible: false,
      uploadForm: {
        uploadFile: ''
      },
      uploadRules: {},
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      imgList: [],
      fileList: [],
      uploadData: [],
      ruleForm: {
        code: ''
      },
      rules: {
        code: [{
          required: true,
          message: '请输入数码',
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      rangeTime: ''
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/market-config/scanWhiteListing/importCodeList';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.codeListPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取列表
    codeListPage: function codeListPage() {
      var that = this;
      that.listLoading = true;
      (0, _scanWhiteListing.codeListPage)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        console.log(res.data.data);
        if (res.data.data.code || res.data.data.code == 0) {
          that.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 时间选择
    sj: function sj(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.codeListPage();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.params.pageNo = 1;
      this.rangeTime = '';
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.$refs[formName].resetFields();
      this.codeListPage();
    },
    // 添加
    addCode: function addCode() {
      this.dialogVisible = true;
    },
    // 确定
    confirm: function confirm() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _scanWhiteListing.addScanWhite)(_this.ruleForm).then(function (res) {
            console.log(res.data);
            if (res.data.data.code != 200) {
              _this.$message.error(res.data.data.msg);
              return;
            } else {
              _this.$message.success('添加数码成功');
              _this.dialogVisible = false;
              _this.codeListPage();
            }
            _this.$refs['ruleForm'].resetFields();
          }).catch(function () {
            _this.dialogVisible = false;
          });
        }
      });
    },
    // 关闭
    cancel: function cancel() {
      this.$refs['ruleForm'].resetFields();
      this.dialogVisible = false;
    },
    // 移除
    handleEdit: function handleEdit(row) {
      var _this2 = this;
      this.$confirm('是否移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _scanWhiteListing.remove)({
          code: row.scanCode
        }).then(function (res) {
          if (res.data.data.code != 200) {
            _this2.$message.error(res.data.data.msg);
            return;
          } else {
            _this2.$message.success('移除成功');
            _this2.codeListPage();
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 导出
    download: function download() {
      var _this3 = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      // console.log(submitData);
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _scanWhiteListing.getCodeExport)(submitData).then(function (res) {
        _this3.downLoading = false;
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '二次扫码白名单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this3.downLoading = false;
      });
    },
    // 导入
    uploadShow: function uploadShow() {
      this.uploadForm.uploadFile = '';
      this.uploadVisible = true;
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.$refs.upload.clearFiles();
      this.uploadVisible = false;
    },
    selectFile: function selectFile(file, fileList) {
      console.log('选择', file, fileList);
      this.uploadForm.uploadFile = file.name;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.imgList = fileList;
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      var that = this;
      console.log(res.data);
      that.mloading = false;
      if (res.data.code == 500) {
        that.$message.error(res.data.msg);
      } else if (res.code != 200) {
        that.$message.error(res.msg);
      } else {
        that.fileList = [];
        that.$refs.upload.clearFiles();
        if (res.data && res.data.length > 0) {
          that.uploadData = res.data;
          return;
          // res.data.filter(item => {
          //   setTimeout(() => {
          //     that.$message({
          //       type: 'error',
          //       message: `${item.errorMsg}`
          //     })
          //   }, 1 * 800)
          // })
        } else {
          that.mloading = false;
          that.$message({
            type: 'success',
            message: '导入成功!'
          });
        }
        that.uploadClose();
        that.codeListPage();
      }

      //
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    submitUpload: function submitUpload() {
      if (!this.uploadForm.uploadFile) {
        this.$message.error('请导入文件');
        return;
      } else {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      }
    }
  }
};
exports.default = _default;