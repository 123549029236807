"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      dataList: [{
        propId: 0,
        propName: '',
        prodPropValues: [{
          valueId: 0
        }]
      }],
      dataRule: {
        propName: [{
          required: true,
          message: '菜单名称不能为空',
          trigger: 'blur'
        }]
      },
      page: {
        total: 0,
        // 总页数
        currentPage: 1,
        // 当前页数
        pageSize: 10 // 每页显示多少条
      }
    };
  },

  methods: {
    init: function init(val) {
      if (val) {
        this.dataList = [JSON.parse(JSON.stringify(val))];
      } else {
        this.dataList = [{
          propId: 0,
          propName: '',
          prodPropValues: [{
            valueId: 0
          }]
        }];
      }
      this.visible = true;
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this = this;
      if (this.dataList[0].prodPropValues) {
        var temp = [];
        for (var key in this.dataList[0].prodPropValues) {
          if (this.dataList[0].prodPropValues.hasOwnProperty(key)) {
            var element = this.dataList[0].prodPropValues[key];
            if (element.propValue) {
              temp.push(this.dataList[0].prodPropValues[key]);
            }
          }
        }
        if (temp && temp.length * 1 === 0) {
          this.$message.closeAll();
          this.$message({
            message: '属性值不能为空',
            type: 'error',
            duration: 1500
          });
          return false;
        }
        this.dataList[0].prodPropValues = temp;
      }
      (0, _integralMall.specAdd)({
        propId: this.dataList[0].propId || undefined,
        propName: this.dataList[0].propName,
        prodPropValues: this.dataList[0].prodPropValues
      }).then(function (data) {
        _this.$message({
          message: '操作成功',
          type: 'success',
          duration: 1500,
          onClose: function onClose() {
            _this.visible = false;
            _this.$emit('refreshDataList', _this.page);
          }
        });
      }).catch(function (err) {
        if (err.response.status * 1 === 400) {
          _this.$message.closeAll();
          _this.$message({
            message: err.response.data,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    // 限制数字字符
    clearProdPropValues: function clearProdPropValues() {
      if (this.dataList[0].prodPropValues.length === 1) {
        return;
      }
      for (var i = 0; i < this.dataList[0].prodPropValues.length; i++) {
        var element = this.dataList[0].prodPropValues[i];
        if (!element.propValue) {
          this.dataList[0].prodPropValues.splice(i, 1);
        }
      }
    },
    // 添加属性
    addInput: function addInput() {
      var temp = this.dataList[0].prodPropValues;
      if (temp[temp.length - 1].propValue) {
        temp.push({});
      }
    }
  }
};
exports.default = _default;