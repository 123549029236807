"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _unit = require("@/api/basic/unit");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDataunitmangeunitindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      title: '添加计量单位',
      total: 0,
      searchForm: {
        current: 1,
        isDelete: 1,
        size: 20,
        unitName: ''
      },
      ruleForm: {
        pId: '',
        unitName: ''
      },
      rules: {
        unitName: [{
          required: true,
          message: '计量单位不能为空，请维护',
          trigger: 'submit'
        }]
      },
      listLoading: false,
      mloading: false,
      tabHeight: '100%',
      tableData: [],
      page2: false
    };
  },
  created: function created() {
    // var that = this
    // var param = that.$route.path.split('=')[1]
    // var arry = getAuthBtn(param)
    // var url = that.apis
    // if (arry.length == 0) {
    //   that.apis[0].authBtn = false
    //   that.page2 = true
    //   return
    // } else {
    //   for (var i = 0; i < url.length; i++) {
    //     var api = url[i].resourceCode
    //     for (var j = 0; j < arry.length; j++) {
    //       if (arry[j].resourceCode == api) {
    //         if (i == 0) {
    //           that.getList()
    //         }
    //         url[i].authBtn = true
    //         that.page2 = false
    //         break
    //       } else {
    //         url[i].authBtn = false
    //       }
    //     }
    //   }
    //   if (url[0].authBtn == false) {
    //     that.page2 = true
    //   } else {
    //     that.page2 = false
    //   }
    // }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _unit.packScaleDictionaryList)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    add: function add() {
      this.title = '添加计量单位';
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.title = '编辑计量单位';
      this.ruleForm.pId = row.pId;
      this.ruleForm.unitName = row.unitName;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.resetForm('ruleForm');
      this.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (_this.ruleForm.pId) {
            (0, _unit.packScaleDictionaryUpdate)(_this.ruleForm).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
            });
          } else {
            (0, _unit.packScaleDictionaryInsert)(_this.ruleForm).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
            });
          }
        }
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _unit.packScaleDictionaryDelete)({
          pId: id,
          isDelete: '0'
        }).then(function (res) {
          if (res.data.code != 200) {
            _this2.$message.error(res.data.msg);
          } else {
            _this2.$message({
              message: '删除成功',
              type: 'success'
            });
            _this2.getList();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;