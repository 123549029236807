"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _ordergl = require("@/api/warehouse/ordergl");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '销售公司',
        1: '一级经销商'
      };
      return statusMap[status];
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    companyType: {
      type: String,
      default: ''
    },
    // inOutType: {
    //   default: ''
    // },
    orgId: {
      type: String,
      default: ''
    }
    // isEnable: {
    //   default: ''
    // }
  },
  data: function data() {
    return {
      listLoading: false,
      companyTypeOption: [{
        name: '销售公司',
        code: 0
      }, {
        name: '一级经销商',
        code: 1
      }],
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        companyCode: '',
        companyName: '',
        collecTionType: this.companyType,
        purchaseIds: [this.orgId],
        // inOutType: this.inOutType,
        // shouFaType: this.companyType,
        size: 10,
        current: 1
      },
      tableData: [],
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(val) {
      var _this = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _ordergl.byPurchaseIdList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        companyCode: '',
        companyName: '',
        collecTionType: this.companyType,
        purchaseIds: [this.orgId],
        // inOutType: this.inOutType,
        // shouFaType: this.companyType,
        size: 10,
        current: 1
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;