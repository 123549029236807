"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DetailsDialogs',
  filters: {
    prizeType: function prizeType(value) {
      var statusMap = {
        0: '谢谢惠顾',
        100: '红包',
        200: '实物',
        300: '优惠券',
        400: '积分',
        500: '字卡'
      };
      return statusMap[value] || '';
    },
    receivingStatus: function receivingStatus(value) {
      var statusMap = {
        0: '未领取',
        1: '已领取',
        2: '已收货'
      };
      return statusMap[value] || '';
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    infoRow: {
      type: [Object],
      required: true
    }
  },
  data: function data() {
    return {
      markInfo: {},
      activityInfo: {},
      wechatUserInfo: {},
      baseData: {}
    };
  },
  mounted: function mounted() {
    this.getDetailFun(this.infoRow);
  },
  methods: {
    closeFun: function closeFun() {
      this.$emit('close');
    },
    getDetailFun: function getDetailFun(infoRow) {
      this.activityInfo = infoRow;
      this.markInfo = infoRow;
      this.wechatUserInfo = infoRow;
      this.baseData = infoRow;
    }
  }
};
exports.default = _default;