var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: { "status-icon": "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: _vm.changeSel,
                                    clear: _vm.clear,
                                  },
                                  model: {
                                    value: _vm.queryList.templateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryList,
                                        "templateType",
                                        $$v
                                      )
                                    },
                                    expression: "queryList.templateType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "扫码抽奖", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { padding: "20px" }, attrs: { gutter: 20 } },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticClass: "creatItemWrap",
                      attrs: { sm: 12, md: 8, lg: 4 },
                    },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            "body-style": { padding: "0px" },
                            shadow: "hover",
                          },
                        },
                        [
                          _c(
                            "HoverMask",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-row",
                                          { staticClass: "maskBtn" },
                                          [
                                            _c(
                                              "el-col",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.creatAct(
                                                          item,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("预览")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.seltMy(
                                                          item.templateId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("加入我的模板")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("div", {
                                staticClass: "bkImg",
                                style:
                                  "background-image: url(" + item.bkImg + ");",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button",
                                      staticStyle: { color: "#000" },
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryList.pageNo,
                  limit: _vm.queryList.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryList, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryList, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: "模板基本设置",
                visible: _vm.dialogVisible,
                "append-to-body": true,
                width: "80% !important",
                "before-close": _vm.close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("Content", {
                ref: "content",
                attrs: { id: _vm.id, type: _vm.type, disabled: true },
                on: { close: _vm.close },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }