"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _protocol = require("@/api/generalSettings2/protocol");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    type: function type(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '登录协议',
        2: '开箱奖协议',
        3: 'BC联动协议'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      queryParams: {
        nickName: '',
        userId: '',
        openId: '',
        agreementName: '',
        agreementType: '',
        signBeginDate: '',
        signEndDate: '',
        pageNo: 1,
        pageSize: 10,
        rangeTime: ''
      },
      total: 0,
      listLoading: false,
      tabHeight: 'calc(100vh - 350px)',
      wechatList: [],
      downLoading: false
    };
  },
  activated: function activated() {
    var hasCache2 = this.wechatList.length > 0;
    if (!hasCache2) {
      this.getwechatQueryList();
    }
  },
  deactivated: function deactivated() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 获取列表
    getwechatQueryList: function getwechatQueryList() {
      var _this = this;
      this.listLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.signBeginDate = this.queryParams.rangeTime[0];
        this.queryParams.signEndDate = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.signBeginDate = '';
        this.queryParams.signEndDate = '';
      }
      var data = JSON.parse(JSON.stringify(this.queryParams));
      delete data.rangeTime;
      (0, _protocol.loglist)(data).then(function (res) {
        console.log(res);
        if (res.data.data.errCode) {
          _this.listLoading = false;
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data.data !== null) {
            _this.wechatList = res.data.data.data.records;
            _this.total = res.data.data.data.total;
            _this.listLoading = false;
          } else {
            _this.wechatList = [];
            // this.total = res.data.data.total
            _this.listLoading = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    restCapitalList: function restCapitalList() {
      this.queryParams = {};
      this.getwechatQueryList();
    },
    exportExcel: function exportExcel() {
      var _this2 = this;
      this.downLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.signBeginDate = this.queryParams.rangeTime[0];
        this.queryParams.signEndDate = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.signBeginDate = '';
        this.queryParams.signEndDate = '';
      }
      var data = JSON.parse(JSON.stringify(this.queryParams));
      delete data.rangeTime;
      delete data.pageNo;
      delete data.pageSize;
      (0, _protocol.posLogExport)(data).then(function (res) {
        _this2.downLoading = false;
        if (res.status !== 200) {
          _this2.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this2.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '终端协议日志.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this2.downLoading = false;
      });
    }
  }
};
exports.default = _default;