var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "返利累计统计明细",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      [
        _c(
          "div",
          { staticClass: "page-container" },
          [
            _c(
              "el-row",
              { attrs: { type: "flex", gutter: 10 } },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "page-section",
                    staticStyle: { "padding-left": "0", "padding-right": "0" },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "listQuery",
                        staticClass: "search-condition",
                        attrs: {
                          model: _vm.listQuery,
                          "status-icon": "",
                          "label-position": _vm.formConfig.labelPosition,
                          "label-width": _vm.formConfig.labelWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "cols" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "supplierName",
                                          label: "供货商:",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            "suffix-icon": "el-icon-search",
                                            clearable: "",
                                            placeholder: "请选择供货商",
                                          },
                                          on: {
                                            clear: _vm.clearGhs,
                                            focus: function ($event) {
                                              return _vm.getUnit("in")
                                            },
                                          },
                                          model: {
                                            value: _vm.supplierName,
                                            callback: function ($$v) {
                                              _vm.supplierName = $$v
                                            },
                                            expression: "supplierName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "orgId",
                                          label: "所属组织:",
                                        },
                                      },
                                      [
                                        _c("treeselect", {
                                          attrs: {
                                            multiple: false,
                                            "flatten-search-results": true,
                                            placeholder: "请选择所属组织",
                                            options: _vm.orgOptions,
                                          },
                                          model: {
                                            value: _vm.listQuery.orgId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orgId",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orgId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "rebateType",
                                          label: "收支来源:",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请选择收支来源",
                                            },
                                            model: {
                                              value: _vm.listQuery.rebateType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "rebateType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "listQuery.rebateType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.rebateTypes,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.showCol ||
                                          !_vm.formConfig.btnFollow,
                                        expression:
                                          "showCol||!formConfig.btnFollow",
                                      },
                                    ],
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "inOrOut",
                                          label: "收入/支出:",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请选择收入/支出",
                                            },
                                            model: {
                                              value: _vm.listQuery.inOrOut,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "inOrOut",
                                                  $$v
                                                )
                                              },
                                              expression: "listQuery.inOrOut",
                                            },
                                          },
                                          _vm._l(_vm.inOrOuts, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.showCol
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "起始时间:",
                                              prop: "startTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "date",
                                                placeholder:
                                                  "请选择收支起始时间",
                                                format: "yyyy-MM-dd",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              model: {
                                                value: _vm.listQuery.startTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.listQuery,
                                                    "startTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "listQuery.startTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showCol
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "终止时间:",
                                              prop: "endTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "date",
                                                placeholder:
                                                  "请选择收支终止时间",
                                                format: "yyyy-MM-dd",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              model: {
                                                value: _vm.listQuery.endTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.listQuery,
                                                    "endTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "listQuery.endTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showCol
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "rebateConfigName",
                                              label: "方案名称:",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                "suffix-icon": "el-icon-search",
                                                clearable: "",
                                                placeholder: "请选择方案名称",
                                              },
                                              on: {
                                                clear: _vm.clearConfig,
                                                focus: _vm.showConfig,
                                              },
                                              model: {
                                                value: _vm.rebateConfigName,
                                                callback: function ($$v) {
                                                  _vm.rebateConfigName = $$v
                                                },
                                                expression: "rebateConfigName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formConfig.btnFollow
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "head-btn-group",
                                        staticStyle: {
                                          width: "240px",
                                          "padding-left": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "label" },
                                                slot: "label",
                                              },
                                              [_vm._v(" ")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: _vm.btnConfig.searchBtn
                                                    .type,
                                                  size: _vm.btnConfig.size,
                                                  icon: _vm.btnConfig.searchBtn
                                                    .icon,
                                                },
                                                on: { click: _vm.DoSearch },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.btnConfig.searchBtn.text
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("el-divider", {
                                              attrs: { direction: "vertical" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: _vm.btnConfig.resetBtn
                                                    .type,
                                                  size: _vm.btnConfig.size,
                                                  icon: _vm.btnConfig.resetBtn
                                                    .icon,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.DoReset(
                                                      "listQuery"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.btnConfig.resetBtn.text
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("el-divider", {
                                              attrs: { direction: "vertical" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: { click: _vm.expand },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(_vm.expandTxt) +
                                                    "\n                      "
                                                ),
                                                _c("i", {
                                                  class:
                                                    _vm.showCol == false
                                                      ? "el-icon-arrow-down"
                                                      : "el-icon-arrow-up",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btnArea" },
                      [
                        !_vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                staticStyle: { width: "230px" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.searchBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.searchBtn.icon,
                                    },
                                    on: { click: _vm.DoSearch },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                                ),
                                _vm._v(" "),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.resetBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.resetBtn.icon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DoReset("listQuery")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                                ),
                                _vm._v(" "),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.expand },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.expandTxt) +
                                        "\n                "
                                    ),
                                    _c("i", {
                                      class:
                                        _vm.showCol == false
                                          ? "el-icon-arrow-down"
                                          : "el-icon-arrow-up",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        key: _vm.tableKey,
                        staticStyle: { width: "100%" },
                        attrs: {
                          height: 400,
                          data: _vm.list,
                          border: _vm.tableConfig.border,
                          stripe: _vm.tableConfig.stripe,
                          fit: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "渠道编码",
                            prop: "channelCode",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "渠道名称",
                            prop: "channelName",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "供货商编码",
                            prop: "supplierCode",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "供货商名称",
                            prop: "supplierName",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "账户类型",
                            prop: "moneyType",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "所属组织",
                            prop: "channelOrg",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "收支",
                            prop: "inOrOutName",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "收支来源",
                            prop: "rebateType",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "收支金额",
                            prop: "moneyValue",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "收支时间",
                            prop: "rebateTime",
                            align: _vm.tableConfig.align,
                            width: "170",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "返利方案编码",
                            prop: "rebateConfigCode",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "返利方案名称",
                            prop: "rebateConfigName",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            prop: "remark",
                            align: _vm.tableConfig.align,
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0",
                        },
                      ],
                      attrs: {
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total,
                        page: _vm.listQuery.current,
                        limit: _vm.listQuery.size,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.listQuery, "current", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.listQuery, "size", $event)
                        },
                        pagination: _vm.getList,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("instockUnit", {
              ref: "channelSelect",
              attrs: { title: "选择单位" },
              on: { change: _vm.getUalue },
            }),
            _vm._v(" "),
            _c("selectHdFn", { ref: "hdfn", on: { select: _vm.getConfig } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "default", plain: "" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }