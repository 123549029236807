"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _param2 = require("@/api/chanelmange/param");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'order',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          console.log(item, 999);
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  filters: {
    purchaseComOwnerType: function purchaseComOwnerType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '渠道客户',
          2: '团购客户',
          3: '销售公司'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分',
        str = '';
      n = String((n * 1).toFixed(2));
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      activeName: '',
      sendComId: '',
      totalZp: 0.0,
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      productList: [],
      zpList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      loading: false,
      auditLoading: false
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCXSDDZT', 'orderStatus');
    this.getDetailData(this.id);
  },
  methods: {
    //获取数据字典信息
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    //获取仓库下拉
    getStoreList: function getStoreList(pid) {
      var _this2 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        console.log(res, 999);
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.storeList = res.data.data;
          } else {
            _this2.storeList = [];
          }
        }
      });
    },
    storeName: function storeName(id) {
      var name = '';
      if (id) {
        this.storeList.filter(function (item) {
          if (item.pId = id) {
            name = item.storeHouseName;
          }
        });
      }
      return name;
    },
    toogleExpand: function toogleExpand(idx) {
      if (this.productList[idx].orderGiftDetailDTOS.length) {
        var $table = this.$refs.addTable;
        for (var i = 0; i < this.productList.length; i++) {
          if (idx != i) {
            $table.toggleRowExpansion(this.productList[i], false);
          }
        }
        $table.toggleRowExpansion(this.productList[idx]);
      } else {
        this.$message.info('当前产品无赠品');
      }
    },
    getFL: function getFL(val) {
      var _this3 = this;
      if (this.addData.purchaseComId && this.addData.sendOutComId && this.addData.receiveComId && this.productList.length) {
        var _loop = function _loop(i) {
          param = (0, _defineProperty2.default)({
            buyNum: 0,
            harvestId: _this3.addData.receiveComId,
            harvestType: _this3.addData.receiveComType,
            productId: '',
            purchaseId: _this3.addData.purchaseComId,
            purchaseType: _this3.addData.purchaseComType,
            supplierId: _this3.addData.sendOutComId,
            supplierType: _this3.addData.sendOutComType
          }, "productId", _this3.productList[i].pId);
          (0, _ordergl.matchChannelBuyGift)(param).then(function (res) {
            if (res.data.code == 200) {
              _this3.productList[i].flList = res.data.data || [];
              if (!val) _this3.productList[i].rebatePolicyId = '';
            } else {
              _this3.$message.error(res.data.msg);
              _this3.productList[i].flList = [];
            }
            if (val) {
              _this3.flChange('', _this3.productList[i]);
            }
            _this3.resetList();
          });
        };
        // var param = {
        //   buyNum: 1,
        //   harvestId: '1278155864498208769',
        //   harvestType: 3,
        //   productId: '1265813287266222082'
        // }
        for (var i = 0; i < this.productList.length; i++) {
          var param;
          _loop(i);
        }
      }
    },
    getWine: function getWine() {
      var _this4 = this;
      if (this.addData.receiveComId && this.addData.sendOutComId) {
        (0, _ordergl.buyerMoneyWine)({
          harvestId: this.addData.receiveComId,
          harvestType: 1,
          supplierId: this.addData.sendOutComId,
          supplierType: this.addData.sendOutComType == 3 ? 1 : 0
        }).then(function (res) {
          if (res.data.code == 200) {
            _this4.totalZp = res.data.data;
          } else {
            _this4.$message.error(res.data.msg);
          }
        });
      }
    },
    flChange: function flChange($el, row) {
      var _this5 = this;
      var arr = [];
      row.flList.forEach(function (item) {
        if (item.rebateId == row.rebatePolicyId) {
          if (!item.detail || item.detail.length == 0) {
            _this5.$message.error('无匹配的返利方案');
            return;
          }
          row.flName = item.desc;
          item.detail.forEach(function (val) {
            var obj = {
              buyNum: val.buyNum,
              giftNum: val.giftNum,
              bottleNum: '',
              chestNum: 0,
              isComProd: val.product.isCode == 1 ? 2 : 1,
              prodPrice: val.product.productPrice || 0,
              productCode: val.product.productCode,
              productId: val.product.pId,
              productName: val.product.productName,
              rpzRebateSchemeConfigId: val.rpzRebateSchemeConfigId,
              rpzRebateSchemeId: val.rpzRebateSchemeId,
              rpzRebateSchemeOrgId: val.rpzRebateSchemeOrgId,
              rpzRebateSchemeProId: val.rpzRebateSchemeProId,
              totalPrice: ''
            };
            if (row.orderGiftDetailDTOS.length) {
              row.orderGiftDetailDTOS.forEach(function (el) {
                if (el.productId == val.product.pId) {
                  obj.pid = el.pid;
                  obj.orderDetailId = el.orderDetailId;
                }
              });
            }
            arr.push(obj);
          });
        }
      });
      row.orderGiftDetailDTOS = arr;
      this.setZPnum(row);
      this.resetList();
    },
    setZPnum: function setZPnum(row) {
      if (row.orderGiftDetailDTOS.length) {
        row.orderGiftDetailDTOS.forEach(function (item) {
          if (row.bottleNum) {
            item.bottleNum = Math.floor(row.bottleNum / item.buyNum) * item.giftNum;
            item.totalPrice = item.bottleNum * item.prodPrice;
          } else {
            item.bottleNum = 0;
            item.totalPrice = 0;
          }
        });
      }
      this.resetZpList();
      row.orderGiftDetailDTOS = JSON.parse(JSON.stringify(row.orderGiftDetailDTOS));
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    resetZpList: function resetZpList() {
      this.zpList = JSON.parse(JSON.stringify(this.zpList));
    },
    //获取详情数据
    getDetailData: function getDetailData(id) {
      var _this6 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this6.addData = res.data.data;
          data.orderDetailDTOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              prodUnit: item.prodUnit,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isGift: item.isGift,
              extractNum: item.extractNum,
              hasTakeNum: item.extractNum,
              rebatePolicyId: item.rebatePolicyId,
              rebatePolicyName: item.rebatePolicyName,
              orderGiftDetailDTOS: item.orderGiftDetailDTOS,
              winePrice: item.winePrice,
              wineTotalPrice: item.wineTotalPrice
            };
            // if (obj.rebatePolicyId) {
            //   var buyNum = 0
            //   getRebateSchemeConfig({ pid: obj.rebatePolicyId }).then(res => {
            //     if (res.data.code == 200) {
            //       var data = res.data.data
            //       if (data && data.length) {
            //         buyNum = data[0].buyNum
            //         for (let i = 0; i < obj.orderGiftDetailDTOS.length; i++) {
            //           obj.orderGiftDetailDTOS[i].buyNum = buyNum
            //         }
            //       }
            //     } else {
            //       that.$message.error('获取返利配置失败，请重试！')
            //     }
            //   })
            // }
            if (item.isUseCoupon) {
              obj.rebatePrice = item.prodPrice;
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this6.productList = proArr;
          _this6.zpList = zpArr;
          _this6.getStoreList(data.sendOutComId);
          // if (this.addData.receiveComType == 3) {
          //   this.getFL(1)
          // }
          _this6.sendComId = data.sendOutComId;
        } else {
          _this6.$message.error(res.data.msg);
        }
        _this6.loading = false;
      }).catch(function () {
        _this6.loading = false;
      }).then(function () {
        if (_this6.addData.orderStatus > 1) {
          _this6.auditLoading = true;
          (0, _ordergl.getAuditLog)({
            pId: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this6.auditList = res.data.data;
            } else {
              that.$message.error('审核日志获取失败');
            }
            _this6.auditLoading = false;
          }).catch(function () {
            _this6.auditLoading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    }
  }
};
exports.default = _default;