var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c("h2", [_vm._v("业务员注册二维码")]),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-content" },
          [
            _c("el-row", [_vm._v("1、业务员扫描下方二维码注册")]),
            _vm._v(" "),
            _c(
              "el-row",
              { attrs: { type: "flex" } },
              [
                _vm._v("2、复制以下链接并点击 "),
                _c(
                  "el-link",
                  {
                    staticClass: "link-text",
                    attrs: {
                      href: "https://cli.im/",
                      target: "_blank",
                      type: "primary",
                    },
                  },
                  [_vm._v("去生成二维码")]
                ),
                _vm._v(" 后，业务员扫码注册"),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-row", [
              _vm._v(
                "https://yx.hysp.cn/e/ywy/index.html?c_id=1397753354437734402"
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { ref: "qrCodeUrl", staticClass: "qrcode" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }