"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _stockmange = require("@/api/chanelmange/stockmange");
var _utils = require("@/utils");
var _workBasic = _interopRequireDefault(require("@/components/workBasic"));
var _workFlow = _interopRequireDefault(require("@/components/workFlow"));
var _workVerify = _interopRequireDefault(require("@/components/workVerify"));
var _workYw = _interopRequireDefault(require("@/components/workYw"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Xq',
  components: {
    workBasic: _workBasic.default,
    workFlow: _workFlow.default,
    workVerify: _workVerify.default,
    workYw: _workYw.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  props: {
    dialogVisible: Boolean,
    billId: String,
    billNo: String,
    workId: String,
    taskId: String,
    url: String,
    check: Boolean
  },
  data: function data() {
    return {
      deliverGoodsSum: '',
      receivingGoodsSum: '',
      remark: '',
      mtStockAdjustmentProductParams: [],
      uploadImgs: [],
      channelName: '',
      channelCode: '',
      channelClassName: '',
      orgName: ''
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      // const that = this
      if (val) {
        // var pId = that.billId
        /* var billNo = params.billNo
        var workId = params.workId
        var taskId = params.taskId
        var check = params.check
        that.workId = workId
        that.taskId = taskId
        that.check= check*/
        // that.DoScan(pId)
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    DoScan: function DoScan(pId) {
      var that = this;
      var param = {};
      param.pId = pId;
      that.getTDetails(param);
    },
    // 获得详情数据
    getTDetails: function getTDetails(param) {
      console.log(param);
      var that = this;
      // var type = that.submitType
      (0, _stockmange.getKcTzDetail)(param).then(function (res) {
        console.log(res);
        if (res.data.code == 200) {
          if (res.data.data != null) {
            if (res.data.data.MtStockAdjustmentOrderOneVo != null) {
              var base = res.data.data.MtStockAdjustmentOrderOneVo;
              that.deliverGoodsSum = base.deliverGoodsSum;
              that.receivingGoodsSum = base.receivingGoodsSum;
              that.remark = base.remark;
              that.channelName = base.channelName;
              that.channelCode = base.channelCode;
              that.channelClassName = base.channelClassName;
              that.orgName = base.orgName;
              that.uploadImgs = [];
              if (base.imagesFiles != '' && base.imagesFiles != null) {
                var imgs = base.imagesFiles.split(',');
                for (var i = 0; i < imgs.length; i++) {
                  var obj = {};
                  obj.name = i;
                  obj.src = imgs[i];
                  obj.url = imgs[i];
                  that.preViewList.push(imgs[i]);
                  that.uploadImgs.push(obj);
                }
              }
            }
            if (res.data.data.MtStockAdjustmentProductVo != null) {
              that.mtStockAdjustmentProductParams = res.data.data.MtStockAdjustmentProductVo;
            }
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getParam: function getParam(param) {
      var url = document.location.href;
      var str = url.substr(url.indexOf('?') + 1);
      var arr = str.split('&');
      var id = '';
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(param) >= 0) {
          id = arr[i].split('=')[1];
          break;
        }
      }
      return id;
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('close');
    }
  }
};
exports.default = _default;