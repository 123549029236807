"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jcbase = require("@/api/basic/jcbase");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatabaseliquormanagementbaseliquorindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      tabHeight: '100%',
      ruleForm: {
        baseWineCode: '',
        // baseWineName: '',
        isEnable: ''
      },
      ruleForm2: {
        // baseWineName: '',
        id: '',
        isEnable: '0',
        remarks: ''
      },
      rules: {
        baseWineName: [{
          required: true,
          message: '请输入基地名称',
          trigger: 'blur'
        }
        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      },

      total: 0,
      current: 1,
      size: 10,
      title: '',
      dialogFormVisible: false
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 列表
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var searchData = (0, _utils.filterKeyNull)(that.ruleForm);
      (0, _jcbase.postPage)(searchData, this.current, this.size).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          setTimeout(function () {
            that.listLoading = false;
          }, 1000);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        setTimeout(function () {
          that.listLoading = false;
        }, 1000);
      }).catch(function (err) {
        console.log(err);
        that.listLoading = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm() {
      this.$refs['ruleForm'].resetFields();
      this.current = 1;
      this.getList();
    },
    // 弹窗确认
    submitForm2: function submitForm2(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var submitData = (0, _utils.filterKeyNull)(_this.ruleForm2);
          if (_this.title == '添加基酒名称') {
            (0, _jcbase.insert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                _this.resetForm2('ruleForm2');
                _this.getList();
              }
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            (0, _jcbase.update)(submitData).then(function (res) {
              console.log(res);
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '编辑成功',
                  type: 'success'
                });
                _this.resetForm2('ruleForm2');
                _this.getList();
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 弹窗重置
    resetForm2: function resetForm2(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    // 添加
    addE: function addE() {
      this.title = '添加基酒名称';
      this.dialogFormVisible = true;
    },
    // 编辑
    DoEdit: function DoEdit(row) {
      var _this2 = this;
      this.title = '编辑基酒名称';
      console.log(row);
      this.$nextTick(function () {
        // this.ruleForm2.baseWineName = row.baseWineName
        _this2.ruleForm2.id = row.id;
        _this2.ruleForm2.isEnable = row.isEnable;
        _this2.ruleForm2.remarks = row.remarks;
      });
      this.dialogFormVisible = true;
    },
    // 修改
    DoDel: function DoDel(row) {
      var _this3 = this;
      this.$confirm("\u662F\u5426\u66F4\u6539\u72B6\u6001\u4E3A".concat(row.isEnable == 0 ? '禁用' : '启用'), '更改提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _jcbase.update)({
          // baseWineName: row.baseWineName,
          id: row.id,
          isEnable: row.isEnable == 0 ? 1 : 0,
          remarks: row.remarks
        }).then(function (res) {
          console.log(res);
          if (res.data.code != 200) {
            _this3.$message.error(res.data.msg);
          } else {
            _this3.$message({
              message: '修改成功',
              type: 'success'
            });
            _this3.getList();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;