"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audit = audit;
exports.create = create;
exports.deleteInfo = deleteInfo;
exports.fetchList = fetchList;
exports.getLevelById = getLevelById;
exports.getMarkApplyDetail = getMarkApplyDetail;
exports.getSupplierByUser = getSupplierByUser;
exports.platformSearch = platformSearch;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function getSupplierByUser() {
  return (0, _request.default)({
    url: '/api-user/supplierUser/getSupplierByUser',
    method: 'get'
  });
}
function platformSearch(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/platformSearch',
    method: 'get',
    params: params
  });
}
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/flagNumSearch',
    method: 'get',
    params: params
  });
}
function getLevelById(params) {
  return (0, _request.default)({
    url: '/api-mark/packScaleLevel/getLevelById',
    method: 'get',
    params: params
  });
}
function create(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/flagInsert',
    method: 'post',
    params: params
  });
}
function update(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/flagUpdate',
    method: 'post',
    params: params
  });
}
function deleteInfo(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/flagDelete',
    method: 'delete',
    params: params
  });
}

//  制码申请详情页
function getMarkApplyDetail(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/getMarkApplyDetail',
    method: 'get',
    params: params
  });
}

// 制码申请审核
function audit(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/audit',
    method: 'put',
    params: params
  });
}