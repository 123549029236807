"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty */

var baseUrl = '/wh';
var _default = {
  data: function data() {
    return {
      // loading
      loading1: true,
      loading2: true,
      loading3: true,
      // 上传地址
      posturl: baseUrl + '/file/upload/single',
      // 图片列表
      Lists: [],
      // 语音列表
      voLists: [],
      // 视频列表
      viLists: [],
      // 视频上传
      videoForm: {
        id: '',
        name: ''
      },
      videoUp: false,
      // 语音上传
      voiceForm: {
        id: '',
        name: ''
      },
      voiceUp: false,
      circleUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      activeName: 'first',
      // 被关注回复输入框
      bzghf: {
        content: '',
        contentType: 1,
        status: '',
        type: 1
      },
      bzgclj: {
        content: '',
        url: ''
      },
      bzgdialogVisible: false,
      // 收到消息回复
      sdhf: {
        content: '',
        contentType: 1,
        status: '',
        type: 2
      },
      sdclj: {
        content: '',
        url: ''
      },
      sddialogVisible: false,
      value1: false,
      value2: false,
      isDsb: true,
      isDsb1: true,
      input2: '',
      // 素材库选择
      dialogVisibletp: false,
      dialogVisibleyy: false,
      dialogVisiblesp: false,
      //

      // 搜索
      options: [],
      value: [],
      list: [],
      loading: false,
      // states: ['Alabama', 'Alaska', 'Arizona',
      // 'Arkansas', 'California', 'Colorado',
      // 'Connecticut', 'Delaware', 'Florida',
      // 'Georgia', 'Hawaii', 'Idaho', 'Illinois',
      // 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
      // 'Louisiana', 'Maine', 'Maryland',
      // 'Massachusetts', 'Michigan', 'Minnesota',
      // 'Mississippi', 'Missouri', 'Montana',
      // 'Nebraska', 'Nevada', 'New Hampshire',
      // 'New Jersey', 'New Mexico', 'New York',
      // 'North Carolina', 'North Dakota', 'Ohio',
      // 'Oklahoma', 'Oregon', 'Pennsylvania',
      // 'Rhode Island', 'South Carolina',
      // 'South Dakota', 'Tennessee', 'Texas',
      // 'Utah', 'Vermont', 'Virginia',
      // 'Washington', 'West Virginia', 'Wisconsin',
      // 'Wyoming'],

      // 添加关键词
      centerDialogVisible: false,
      form: {
        hyType: 3,
        name: '',
        wxContentReplyDOList: [{
          type: '',
          content: ''
        }],
        wxKeywordDTOList: [{
          type: '',
          val: ''
        }]
      },
      options1: [{
        value: 1,
        label: '部分匹配'
      }, {
        value: 2,
        label: '完全匹配'
      }],
      keyWordtype: [{
        name: '1'
      }, {
        name: '2'
      }, {
        name: '3'
      }, {
        name: '4'
      }, {
        name: '1'
      }],
      formLabelWidth: '120px',
      formLabelWidth1: '50px',
      // 表格
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        addnum: 40
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄',
        addnum: 50
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄',
        addnum: 80
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        addnum: 100
      }],
      // 回复单选
      radio: '1',
      // 上传
      fileList: [],
      // 查询数据类型
      formInline: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      // 分页类型
      mdtype: null,
      currentRow: '',
      // 图片拼接地址
      twImgurl: 'http://testsaas.panpass.com/images/',
      // 展示地址
      proUrl: '',
      voUrl: '',
      viUrl: '',
      clickCheck: -1,
      // 关键词查询
      keycode: {
        keyword: '',
        pageNo: 1,
        pageSize: 10
      },
      keycodeList: [{
        data: 1,
        name: 2,
        address: 3,
        addnum: 4
      }],
      keyload: true
    };
  },
  watch: {
    'bzghf.content': function bzghfContent(newval, oldval) {
      // console.log(newval,oldval)
      if (this.bzghf.content == '') {
        this.isDsb = true;
      } else {
        this.isDsb = false;
      }
    },
    'sdhf.content': function sdhfContent(newval, oldval) {
      // console.log(newval,oldval)
      if (this.sdhf.content == '') {
        this.isDsb1 = true;
      } else {
        this.isDsb1 = false;
      }
    }
  },
  created: function created() {
    var _this = this;
    (0, _wxgzh.findKeyword)(this.keycode).then(function (res) {
      console.log(res);
      if (!res.code == 200) {}
      _this.keycodeList = res.data.records;
      _this.keyload = false;
    });
  },
  mounted: function mounted() {
    // this.list = this.states.map(item => {
    //   return { value: item, label: item }
    // })
  },
  methods: {
    // 获取内容类型
    handleClick1: function handleClick1(tab, event) {
      if (tab.index == 0) {
        this.form.wxContentReplyDOList;
      }
      // console.log(tab)
      // if(tab.index){

      // }
    },
    // 添加关键词
    addKeyws: function addKeyws() {
      this.centerDialogVisible = true;
      this.proUrl = '';
      this.voUrl = '';
      this.viUrl = '';
    },
    // 添加文本框
    addcontent: function addcontent(rows) {
      if (rows.length == 5) {
        this.$message.warning('最多创建5条回复');
        return;
      }
      var n = {
        type: '',
        content: ''
      };
      rows.push(n);
    },
    // 查询关键词
    searchkeycode: function searchkeycode() {
      var _this2 = this;
      this.keyload = true;
      console.log(this.keycode);
      (0, _wxgzh.findKeyword)(this.keycode).then(function (res) {
        console.log(res);
        if (!res.code == 200) {}
        if (res.data.total == 0) {
          _this2.keycodeList = '';
          _this2.$message.warning('未找到关键字');
        }
        _this2.keycodeList = res.data.records;
        _this2.keyload = false;
      });
    },
    // 提交关键词
    addKeyWordReply: function addKeyWordReply(val) {
      var _this3 = this;
      // console.log(val)
      (0, _wxgzh.insertKeyWordReply)(val).then(function (res) {
        if (!res.code == 200) {
          _this3.$message.warning(res.msg);
          return;
        }
        _this3.$message.success('提交成功');
      });
      this.centerDialogVisible = false;
    },
    // 增减关键词
    addKeyword: function addKeyword(index, rows) {
      if (rows.length == 10) {
        this.$message.warning('最多创建10条关键词');
        return;
      }
      var n = {
        type: '',
        val: ''
      };
      rows.push(n);
    },
    reduceKeyword: function reduceKeyword(index, rows) {
      rows.splice(index, 1);
    },
    // 选中语音列表
    handleCurrentChangevo: function handleCurrentChangevo(val) {
      this.currentRow = val.mediaId;
      this.voUrl = {
        name: val.name,
        url: this.twImgurl + val.url
      };
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.currentRow;
      }
      if (this.sdhf == 2) {
        this.sdhf.content = this.currentRow;
      }
    },
    pitchOn: function pitchOn(index, row) {
      console.log(row);
    },
    // 选中视频列表
    tabVi: function tabVi(index, val) {
      this.currentRow = val.mediaId;
      this.viUrl = {
        name: val.name,
        url: this.twImgurl + val.url
      };
      this.clickCheck = index;
      console.log(this.viUrl);
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.currentRow;
      }
      if (this.sdhf == 2) {
        this.sdhf.content = this.currentRow;
      }
    },
    // 选择图片
    tabImg: function tabImg(index, val) {
      this.currentRow = val.mediaId;
      this.proUrl = val.url;
      this.clickCheck = index;
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.currentRow;
      }
      if (this.sdhf == 2) {
        this.sdhf.content = this.currentRow;
      }
    },
    // 删除图片语音视频展示
    delProurl: function delProurl() {
      this.proUrl = '';
    },
    // 删除语音展示
    delvourl: function delvourl() {
      this.voUrl = '';
    },
    // 删除视频展示
    delviurl: function delviurl() {
      this.viUrl = '';
    },
    // 图片列表
    getProimg: function getProimg() {
      var _this4 = this;
      this.dialogVisibletp = true;
      this.mdtype = 3;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        if (!res.code == 200) {}
        _this4.Lists = res.data.records;
        _this4.loading1 = false;
        console.log(_this4.loading1);
        _this4.total = res.data.total;
        console.log(_this4.Lists);
        var s = _this4.Lists.length - 1;
        console.log(_this4.clickCheck);
      });
    },
    // 语音列表
    getProvoice: function getProvoice() {
      var _this5 = this;
      this.dialogVisibleyy = true;
      this.mdtype = 2;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        if (!res.code == 200) {}
        _this5.voLists = res.data.records;
        _this5.voLists.forEach(function (element) {
          console.log(element);
          element.name == null ? element.name = '未命名' : element.name;
        });
        _this5.total = res.data.total;
        // this.voLists.content = "http://testsaas.panpass.com/images/group1/M00/00/14/CghTnl3mOo2AYJV0AAAAAAAAAAA760.mp3"
        _this5.loading2 = false;
      });
    },
    // 视频列表
    getProvideo: function getProvideo() {
      var _this6 = this;
      this.clickCheck = -1;
      this.dialogVisiblesp = true;
      this.mdtype = 1;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        if (!res.code == 200) {
          return;
        }
        _this6.viLists = res.data.records;
        _this6.total = res.data.total;
        _this6.loading3 = false;
      });
    },
    // 上传语音
    vohandleSuccess: function vohandleSuccess(file, fileList) {
      this.voiceForm.id = file.data.id;
    },
    voSize: function voSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 2MB!');
      }
      return isLt2M;
    },
    upLoadvo: function upLoadvo() {
      var _this7 = this;
      (0, _wxgzh.updateFiles)(this.voiceForm).then(function (res) {
        if (!res.code == 200) {
          _this7.$message.error(res.msg);
        }
        _this7.$message.success('上传成功');
        _this7.voiceUp = false;
      });
    },
    // 上传视频
    vihandleSuccess: function vihandleSuccess(file, fileList) {
      // this.$message.success("上传成功")
      console.log(file);
      this.videoForm.id = file.data.id;
    },
    viSize: function viSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 10MB!');
      }
      return isLt2M;
    },
    upLoadvi: function upLoadvi() {
      var _this8 = this;
      (0, _wxgzh.updateFiles)(this.videoForm).then(function (res) {
        if (!res.code == 200) {
          _this8.$message.error(res.msg);
        }
        _this8.$message.success('上传成功');
        _this8.videoUp = false;
      });
    },
    // 上传图片
    handleSuccess: function handleSuccess(file, fileList) {
      // let o = URL.createObjectURL(file);
      // this.Lists.push(fileList)
      // this.Lists.push({name:fileList.name,url:URL.createObjectURL(fileList.raw)})
      this.$message.success('上传成功');
    },
    imgSize: function imgSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handError: function handError() {
      this.$message.error('上传失败');
    },
    remoteMethod: function remoteMethod(query) {
      var _this9 = this;
      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this9.loading = false;
          _this9.options = _this9.list.filter(function (item) {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    // 导航
    handleClick: function handleClick(tab, event) {
      if (tab.index == 0) {
        this.proUrl = '';
        this.clickCheck = -1;
        this.voUrl = '';
        this.viUrl = '';
      }
      if (tab.index == 1) {
        this.proUrl = '';
        this.clickCheck = -1;
        this.voUrl = '';
        this.viUrl = '';
      }
    },
    // 删除表格
    deleteRow: function deleteRow(index, rows) {
      console.log(index, rows);
      rows.splice(index, 1);
    },
    // 上传
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 3 \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    handleClose: function handleClose(done) {
      this.$confirm('确认关闭？').then(function (_) {
        done();
      }).catch(function (_) {});
    },
    // 回复文本类型
    handleClick2: function handleClick2(tab, event) {
      switch (tab.index) {
        case '0':
          this.bzghf.contentType = 1;
          break;
        case '1':
          this.bzghf.contentType = 2;
          break;
        case '2':
          this.bzghf.contentType = 3;
          break;
        case '3':
          this.bzghf.contentType = 4;
          break;
      }
    },
    handleClick3: function handleClick3(tab, event) {
      switch (tab.index) {
        case '0':
          this.sdhf.contentType = 1;
          break;
        case '1':
          this.sdhf.contentType = 2;
          break;
        case '2':
          this.sdhf.contentType = 3;
          break;
        case '3':
          this.sdhf.contentType = 4;
          break;
      }
    },
    // 被关注回复保存
    savedata: function savedata() {
      if (this.value1 == true) {
        this.bzghf.status = 1;
      } else {
        this.bzghf.status = 2;
      }
      console.log(this.bzghf);
      // saveReply(this.bzghf).then(res => {
      //     console.log(res);
      //     if(res.code == 200) {
      //      this.$message({
      //               message: '保存成功',
      //               type: 'success'
      //             });
      //     }else {
      //       this.$message.error('保存失败');
      //     }
      // })
    },
    // 收到消息回复保存
    savedata1: function savedata1() {
      if (this.value2 == true) {
        this.sdhf.status = 1;
      } else {
        this.sdhf.status = 2;
      }
      console.log(this.sdhf);
      // saveReply(this.sdhf).then(res => {
      //     console.log(res);
      //     if(res.code == 200) {
      //      this.$message({
      //               message: '保存成功',
      //               type: 'success'
      //             });
      //     }else {
      //       this.$message.error('保存失败');
      //     }
      // })
    }
  }
};
exports.default = _default;