var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.visible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.formData,
                "label-width": "100px",
                "label-suffix": ":",
                disabled: _vm.type === 2,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "picbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "商家资料" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openChannel },
                        },
                        [_vm._v("+ 商家信息")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        { attrs: { data: _vm.tableData, border: "" } },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "groupCode", label: "客户组编码" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "groupName",
                              label: "客户组名称",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelClassName",
                              label: "经销商分类",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商等级",
                              prop: "channelGradeName",
                              width: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "picbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "户头信息" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openChannel },
                        },
                        [_vm._v("添加户头经销商")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        { attrs: { data: _vm.tableData, border: "" } },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "groupCode", label: "户头编码" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "groupName",
                              label: "户头名称",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelClassId",
                              label: "经销商分类",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "经销商等级",
                              prop: "channelGradeId",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("移除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.channelDialogVisible
        ? _c("channelDialog", {
            attrs: {
              visible: _vm.channelDialogVisible,
              "selected-data": _vm.channels,
            },
            on: {
              change: _vm.setChannel,
              close: function ($event) {
                _vm.channelDialogVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }