"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _auth = require("@/utils/auth");
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */

var baseUrl = '/wh';
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      tjTags: [],
      addcustomdia: false,
      customdata: {
        name: '',
        type: ''
      },
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      gqgz: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime();
        }
      },
      sendTime: '',
      fftxt: '',
      input: '',
      input1: '',
      form: {
        ruleName: '',
        programName: '',
        isRule: '',
        invalidDay: '',
        invalidDateStr: '',
        imgId: '',
        url: '',
        appId: '',
        appSecret: '',
        appUrl: '',
        moWxMemberConditionDOList: []
      },
      imageUrl: {
        url: ''
      },
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      fstpUrl: '/api-memberscrm/member/admin/file/upload/single',
      twImgurl: 'http://testsaas.panpass.com/images/'
    };
  },
  created: function created() {
    var _this = this;
    this.twImgurl = _wxHy.base;
    (0, _wxHy.getrulesearch)().then(function (res) {
      res = res.data;
      console.log(res);
      if (res.code === 200) {
        if (res.data === null) {
          return;
        } else {
          _this.form = res.data;
          _this.imageUrl.url = _this.form.url;
          _this.form.moWxMemberConditionDOList.forEach(function (e) {
            _this.tjTags.push(e.name);
          });
          // this.form.moWxMemberConditionDOList = []
        }
      } else {
        _this.$message.warning(res.msg);
      }
    });
  },
  methods: {
    addbtn: function addbtn() {
      if (this.tjTags.length > 2) {
        this.$message.warning('最多添加三组信息');
        return;
      }
      this.addcustomdia = true;
      this.customdata = {
        name: '',
        type: ''
      };
    },
    handleClose: function handleClose(done) {
      done();
    },
    // 添加条件
    addtj: function addtj(val) {
      var that = this;
      if (that.customdata.name.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请填写名称');
        return;
      }
      if (that.customdata.type === '') {
        that.$message.warning('请选择类型');
        return;
      }
      that.tjTags.push(that.customdata.name);
      // createMemberCondition(that.customdata).then(res => {
      //   console.log(res)
      // })
      that.form.moWxMemberConditionDOList.push(that.customdata);
      console.log(that.form.moWxMemberConditionDOList);
      that.addcustomdia = false;
    },
    handleClosetj: function handleClosetj(tag, index) {
      var that = this;
      that.tjTags.splice(that.tjTags.indexOf(tag), 1);
      that.form.moWxMemberConditionDOList.splice(index, 1);
      console.log(that.form.moWxMemberConditionDOList);
    },
    upbtn: function upbtn() {
      console.log(this.form);
    },
    onbtn: function onbtn(val) {
      var that = this;
      // if (val.ruleName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写名称')
      //   return
      // }
      // if (val.programName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写小程序名称')
      //   return
      // }
      // if (val.imgId === '') {
      //   that.$message.warning('请上传封面')
      //   return
      // }
      if (val.appId.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请上填写小程序appid');
        return;
      }
      // if (val.appUrl.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写小程序url')
      //   return
      // }
      if (val.appSecret.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请填写小程序secret');
        return;
      }
      // if(val.moWxMemberConditionDOList.length < 1 ) {
      //   that.$message.warning('请添加自定义条件')
      //   return
      // }
      if (val.isRule === '') {
        that.$message.warning('请选择有效期');
        return;
      }
      if (val.isRule === '2') {
        if (val.invalidDay < 1) {
          that.$message.warning('请正确填写天数');
          return;
        }
      }
      if (val.isRule === '3') {
        if (val.invalidDateStr === null || val.invalidDateStr === '') {
          that.$message.warning('请选择时间');
          return;
        }
      }
      (0, _wxHy.updateRule)(val).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          that.$message.success('提交成功');
        } else {
          that.$message.warning(res.msg);
        }
      });
    },
    // 上传封面成功
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code * 1 === 200) {
        this.$message.success('上传成功');
        this.form.url = '';
        this.form.imgId = response.data.mediaId;
      } else {
        this.$message({
          message: response.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }

      // 上传图片url给左侧
      // console.log(this.imgIndex)
      this.imageUrl.url = URL.createObjectURL(file.raw);
      // 给tw添加url地址
      // this.tw[this.ele].img = this.imageUrl[this.ele].url
    },
    imgUploaderr: function imgUploaderr() {
      this.$message.closeAll();
      this.$message({
        message: '上传失败，请重试！',
        type: 'error',
        duration: 3 * 1000
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isImg = /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name);
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) {
        this.$message.error('上传图片只能是 gif/jpg/jpeg/png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isImg && isLt2M;
    },
    handleInput: function handleInput(e) {
      console.log(e);
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.form.invalidDay = e.target.value;
      } else if (e.target.value.length < this.form.invalidDay.length) {
        this.form.invalidDay = e.target.value;
      }
      // if (reg.test(e.target.value)) {
      //   this.sendObj.hdsjNum = e.target.value
      // } else if (e.target.value.length < this.sendObj.hdsjNum.length) {
      //   this.sendObj.hdsjNum = e.target.value
      // }
      this.$refs.inputss.value = this.form.invalidDay;
      console.log(this.form.invalidDay);
      console.log(this.$refs.inputss.value);
    }
  }
};
exports.default = _default;