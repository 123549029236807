var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mod-index-img" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: !_vm.dataForm.imgId ? "新增" : "修改",
            visible: _vm.visible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.dataForm,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "轮播图片：" } },
                [
                  _c("Upload-img", {
                    ref: "imgs",
                    attrs: { data: _vm.dataForm.imgUrl, text: "" },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.dataForm, "imgUrl", $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(" 建议:图片尺寸690*402\n        "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "顺序：", prop: "seq" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "only-number",
                        rawName: "v-only-number",
                        value: {
                          min: 0,
                          precision: 0,
                          set: _vm.dataForm,
                          name: "seq",
                        },
                        expression:
                          "{ min:0,precision:0,set: dataForm, name:'seq' }",
                      },
                    ],
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.dataForm.seq,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "seq", $$v)
                      },
                      expression: "dataForm.seq",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radio",
                      on: { change: _vm.deleteRelation },
                      model: {
                        value: _vm.dataForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "type", $$v)
                        },
                        expression: "dataForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: -1 } }, [_vm._v("无")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("商品")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dataForm.relation != null
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: { height: "160px", width: "120px" },
                              attrs: { "body-style": { padding: "0px" } },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "104px", width: "100%" },
                                attrs: { src: _vm.card.pic },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "card-prod-bottom" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "card-prod-name" },
                                    [_vm._v(_vm._s(_vm.card.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "card-prod-name-button button configText",
                                      attrs: { type: "text" },
                                      on: { click: _vm.deleteRelation },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _vm.dataForm.type == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.addProd },
                                },
                                [_vm._v("选择商品")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "head-btn-group",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.prodsSelectVisible
        ? _c("prods-select", {
            ref: "prodsSelect",
            attrs: { "is-single": true },
            on: {
              refreshSelectProds: _vm.selectCouponProds,
              close: function ($event) {
                _vm.prodsSelectVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }