"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _unlockPrizeRule = require("@/api/terminal/unlockPrizeRule");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    ruleCode: {
      type: [Number, String],
      default: function _default() {
        return "";
      }
    }
  },
  data: function data() {
    return {
      listLoading: false,
      list: [],
      total: 0,
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        unlockRuleCode: ''
      }
    };
  },
  mounted: function mounted() {
    this.searchForm.unlockRuleCode = this.ruleCode;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _unlockPrizeRule.activityQueryPage)(this.searchForm).then(function (res) {
        if (res.data.code == 200) {
          _this.list = res.data.data.records;
          _this.total = res.data.data.total;
        } else {
          _this.$message.error(res.data.msg);
        }
        _this.listLoading = false;
      });
    },
    cancelRelationOperation: function cancelRelationOperation(row) {
      var _this2 = this;
      this.$confirm("取消关联规则会在活动中失效", "取消关联", {
        confirmButtonText: "确定取消关联",
        showCancelButton: false
      }).then(function () {
        _this2.unRelationActivity(row);
      }).catch(function (err) {
        console.log(err);
      });
    },
    unRelationActivity: function unRelationActivity(row) {
      var _this3 = this;
      var queryData = {
        activityId: row.id,
        code: this.ruleCode
      };
      (0, _unlockPrizeRule.unRelationActivity)(queryData).then(function (res) {
        if (res.data.data.code == 0 || res.data.data.code == 500) {
          _this3.$message.error(res.data.data.msg);
        } else {
          _this3.$message({
            message: "取消关联成功",
            type: "success"
          });
          _this3.getList();
        }
      });
    },
    // 表单验证
    checkForm: function checkForm(val, text) {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this4.$message.closeAll();
            _this4.$message({
              message: text,
              type: "error",
              duration: 3 * 1000
            });
          }
        });
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit("close");
    }
  }
};
exports.default = _default2;