"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.object.keys");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _prolog = _interopRequireDefault(require("./prolog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _auth = require("@/utils/auth");
var _wxintegral = require("@/api/wxhyyy/wxintegral.js");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable eqeqeq */
var _default = {
  components: {
    Pagination: _Pagination.default,
    ProDialog: _prolog.default
  },
  data: function data() {
    return {
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      // 上传图片头部
      hideUpload: false,
      btnIs: false,
      type: JSON.parse(this.$route.query.data).type * 1,
      productVisibleShow: false,
      // 是否显示弹窗
      dataForm: {
        name: '',
        // 名字
        amount: '',
        // 积分数
        type: '100',
        // 范围类型
        range: '2',
        // 产品范围
        products: [],
        // 部分产品列表
        isattention: '2',
        // 是否关注公众号
        backgroundUrl: '',
        // 背景图片
        actSectionList: [],
        // 数码段
        wechat: '',
        // 公众号id
        wechatName: ''
      },
      list: [],
      PageForm: {
        pageNo: 0,
        pageSize: 999
      },
      fileList: [],
      // 上传图片列表
      total: 0,
      // 页码数
      limitCount: 1,
      // 上传图片数
      disabled: false,
      wcList: [],
      // 公众号
      isAll: false,
      rules: {
        name: [{
          required: true,
          message: '请输入菜单名',
          trigger: 'change'
        }],
        amount: [{
          required: true,
          message: '请输入积分数',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择范围类型',
          trigger: 'change'
        }],
        range: [{
          required: true,
          message: '请选择产品范围类型',
          trigger: 'change'
        }],
        products: [{
          required: true,
          message: '请选择产品范围',
          trigger: 'change'
        }],
        isattention: [{
          required: true,
          message: '请选择是否关注公众号',
          trigger: 'change'
        }],
        actSectionList: [{
          required: true,
          message: '请添加区段',
          trigger: 'blur'
        }],
        wechat: [{
          required: true,
          message: '请选择要关注的公众号',
          trigger: 'change'
        }],
        backgroundUrl: [{
          required: true,
          message: '请上传有公众号二维码的背景图片',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/uspyx/pc/template/upload';
    }
  },
  created: function created() {
    this.cid = localStorage.getItem('cid');
    // getWcList({}).then(res => {
    //   if (res.state === '200') {
    //     this.wcList = res.data
    //   } else {
    //     this.$message.closeAll()
    //     this.$message({
    //       message: res.msg,
    //       type: 'error',
    //       duration: 3 * 1000
    //     })
    //   }
    // })
    console.log(this.type);
    if (this.type === 2) {
      this.disabled = true;
      this.getgzh();
      this.integralActDetailsFun();
    } else if (this.type !== 0) {
      this.getgzh();
      this.integralActDetailsFun();
    } else {
      this.getgzh();
    }
  },
  methods: {
    getgzh: function getgzh() {
      var _this = this;
      (0, _wxgzh.getgzhlist)({
        appId: 1,
        qyorgId: '9999'
      }).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          _this.wcList = res.data;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 规则详情
    // async
    integralActDetailsFun: function integralActDetailsFun() {
      var _this2 = this;
      var id = JSON.parse(this.$route.query.data).id;
      (0, _wxintegral.integralActDetails)({
        id: id
      }).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        if (resp.code * 1 === 0) {
          _this2.dataForm = Object.assign(_this2.dataForm, resp.data);
          // const datafrom = this.dataForm
          if (_this2.dataForm.isattention === 2) {
            _this2.dataForm.isattention = '2';
          } else if (_this2.dataForm.isattention === 1) {
            _this2.dataForm.isattention = '1';
          }
          // if (this.dataForm.type === '100' && this.dataForm.range === 2) {
          if (_this2.dataForm.type === '100') {
            _this2.getJFChosenProductFun();
          }
          var datafrom = _this2.dataForm;
          if (datafrom.wechat) {
            _this2.wcList.forEach(function (item) {
              if (item.appId === datafrom.wechat) {
                datafrom.wechatName = item.appName;
              } else {
                datafrom.wechatName = '';
              }
            });
          }
        }
      });
    },
    getJFChosenProductFun: function getJFChosenProductFun() {
      var _this3 = this;
      var activiteId = JSON.parse(this.$route.query.data).id;
      (0, _wxintegral.getJFChosenProduct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.PageForm), {}, {
        activiteId: activiteId
      })).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        if (resp.code === 0) {
          _this3.setProduct(resp.data.records);
          // this.setProduct(resp.data.lstResult)
          _this3.total = resp.data.total;
          // this.total = resp.data.pageTotal
        }
      });
    },
    getIntegralActFun: function getIntegralActFun() {
      var _this4 = this;
      (0, _wxintegral.getIntegralAct)({
        pageNo: 0,
        pageSize: 5,
        cid: this.cid
      }).then(function (resp) {
        var resp = resp.data;
        if (resp.code === 0) {
          if (resp.data.lstResult.length > 0) {
            _this4.isAll = true;
          } else {
            _this4.isAll = false;
          }
        }
      });
    },
    pagingFun: function pagingFun() {
      this.getJFChosenProductFun();
    },
    // 获取公众号
    // 选中产品数组
    setProduct: function setProduct(data) {
      var _this5 = this;
      this.productVisibleShow = false;
      var ob = {
        // ID: 'id',
        // ACT_ID: 'actId',
        // PRODUCT_NAME: 'productName',
        // NEWPSID: 'productNo',
        // PRODUCT_NO: 'productId',
        // PRODUCT_ID: 'productId',
        // BATCH_NO: 'batchNo',
        // QYORG_ID: 'qyorgId',
        // QYORG_NAME: 'qyorgName',
        // PRODUCT_NORMS: 'productNorms',
        // PRODUCT_TYPE: 'productType',
        // TYPE: 'type',
        // ROW_ID: 'rowId'
        id: 'id',
        actId: 'actId',
        productName: 'productName',
        newpsid: 'productNo',
        productNo: 'productId',
        productId: 'productId',
        batchNo: 'batchNo',
        qyorgId: 'qyorgId',
        qyorgName: 'qyorgName',
        productNorms: 'productNorms',
        productType: 'productType',
        type: 'type',
        rowId: 'rowId',
        enpname: 'packScale'
      };
      this.list = data;
      console.log(this.list);
      this.dataForm.products = [];
      data.forEach(function (item, i) {
        var obj = {};
        if (item.packScale) {
          item['enpname'] = item.packScale;
        }
        // delete item.packScale
        Object.keys(item).forEach(function (key) {
          obj[ob[key]] = item[key];
        });
        _this5.dataForm.products.push(obj);
      });
    },
    typeFun: function typeFun(val) {
      if (val === '100') {
        this.dataForm.range = '1';
        this.dataForm.actSectionList = [];
      } else if (val === '101') {
        this.list = [];
        this.dataForm.products = [];
        this.dataForm.actSectionList = [{
          sectionStart: '',
          sectionEnd: ''
        }];
      }
    },
    rangeFun: function rangeFun(val) {
      this.list = [];
      this.dataForm.products = [];
    },
    // 添加 数码段
    addFun: function addFun() {
      this.dataForm.actSectionList.push({
        sectionStart: '',
        sectionEnd: ''
      });
    },
    // 删除数码段
    deleteFun: function deleteFun(index) {
      this.dataForm.actSectionList.splice(index, 1);
    },
    wechatFun: function wechatFun(val) {
      var _this6 = this;
      // this.dataForm.wechat = val
      this.wcList.forEach(function (item) {
        if (val === item.appId) {
          _this6.dataForm.wechat = item.appId;
        }
      });
    },
    // 上传成功
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      if (res.status === 200) {
        if (this.backgroundUrl) {
          this.dataForm.backgroundUrl = this.dataForm.backgroundUrl + ',' + res.data;
        } else {
          this.dataForm.backgroundUrl = res.data;
        }
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
      } else {
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    // 上传前
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    selectImg: function selectImg(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount;
      this.fileList = fileList;
    },
    checkType: function checkType() {
      if (this.disabled) {
        return false;
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      if (!this.disabled) {
        this.hideUpload = fileList.length >= this.limitCount;
        this.fileList = fileList;
        var src = '';
        if (this.fileList && this.fileList.length > 0) {
          this.fileList.forEach(function (v, k) {
            if (v.response) {
              v.url = v.response.data;
            }
            if (v.url) {
              if (k == 0) {
                src += v.url;
              } else {
                src += ',' + v.url;
              }
            }
          });
        }
        this.dataForm.backgroundUrl = src;
      }
    },
    // 删除图片
    Remove: function Remove() {
      this.$refs.upload.onRemove('', []);
    },
    // 选择产品
    productListShow: function productListShow() {
      var _this7 = this;
      this.productVisibleShow = true;
      setTimeout(function () {
        _this7.$refs.productVisible.visible = true;
      }, 0);
    },
    // 提交
    submitFun: function submitFun() {
      var _this8 = this;
      this.dataForm.name = this.dataForm.name.replace(/\s+/g, '');
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          _this8.btnIs = true;
          if (_this8.type === 0) {
            console.log(_this8.dataForm);
            (0, _wxintegral.addIntegralAct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this8.dataForm), {}, {
              cid: _this8.cid
            })).then(function (resp) {
              var resp = resp.data;
              _this8.btnIs = false;
              if (resp.code === 0) {
                _this8.$message({
                  type: 'success',
                  message: "\u6DFB\u52A0\u6210\u529F!"
                });
                _this8.$router.go(-1);
              } else {
                _this8.$message({
                  type: 'error',
                  message: resp.msg
                });
              }
            });
          } else if (_this8.type === 1) {
            (0, _wxintegral.updateIntegralAct)((0, _objectSpread2.default)({}, _this8.dataForm)).then(function (resp) {
              var resp = resp.data;
              _this8.btnIs = false;
              if (resp.code === 0) {
                _this8.$message({
                  type: 'success',
                  message: "\u7F16\u8F91\u6210\u529F!"
                });
                _this8.$router.go(-1);
              } else {
                _this8.$message({
                  type: 'error',
                  message: "\u7F16\u8F91\u5931\u8D25!"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    validTe: function validTe(value) {
      value = value.replace(/[^0-9]/g, '');
      return value;
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    }
  }
};
exports.default = _default;