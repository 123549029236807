"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _log = require("@/api/log/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagelogounsealedMasterCodeindex';
var _default = {
  name: 'MarketingManagelogounsealedMasterCodeindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        beginDate: '',
        creatorId: '',
        endDate: '',
        markCode: ''
      },
      rangeTime: '',
      total: 10,
      current: 1,
      size: 10,
      creatorList: [],
      tableData: [],
      listLoading: false,
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.errMarkLogUser();
    this.formInline.status = '';
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.queryForPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取表格
    queryForPage: function queryForPage() {
      var that = this;
      that.listLoading = true;
      (0, _log.queryForPage)(this.current, this.size, (0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 操作人
    errMarkLogUser: function errMarkLogUser() {
      var _this = this;
      (0, _log.errMarkLogUser)().then(function (res) {
        console.log(res);
        if (res.data.errCode) {
          _this.$message.error(res.data.errMsg);
          return;
        } else {
          if (res.data && !res.data.errCode) {
            _this.creatorList = res.data.data;
          }
        }
      });
    },
    // 时间选择
    sj: function sj(res) {
      if (res) {
        this.formInline.beginDate = res[0];
        this.formInline.endDate = res[1];
      } else {
        this.formInline.beginDate = '';
        this.formInline.endDate = '';
      }
    },
    // 搜索
    search: function search() {
      this.current = 1;
      this.queryForPage();
    },
    // 重置
    reset: function reset() {
      this.current = 1;
      this.$refs['formInline'].resetFields();
      this.rangeTime = '';
      this.formInline.beginDate = '';
      this.formInline.endDate = '';
      this.queryForPage();
    },
    // 导出
    exportLs: function exportLs() {
      var _this2 = this;
      // console.log(this.queryParams, 123);
      (0, _log.exportExcel)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        setTimeout(function () {
          _this2.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '解封日志码.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  }
};
exports.default = _default;