"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downLoadData = downLoadData;
exports.searchMenuData = searchMenuData;
exports.searchMenuDataDetail = searchMenuDataDetail;
exports.userData = userData;
exports.userDataIncludeTotal = userDataIncludeTotal;
exports.userSource = userSource;
exports.userTag = userTag;
exports.userTimeFrameActive = userTimeFrameActive;
exports.userUnSubscribe = userUnSubscribe;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/user'
var baseUrl = '';
// 图文数据
function searchMenuData(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxNewsAnalysis/searchMenuData',
    method: 'GET',
    params: params
  });
}
// 图文排名
function searchMenuDataDetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxNewsAnalysis/searchMenuDataDetail',
    method: 'GET',
    params: params
  });
}
// 用户数据分析
function userData(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userData',
    method: 'GET',
    params: params
  });
}
// 用户数据分析下载
function downLoadData(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userDataDown',
    method: 'get',
    responseType: 'blob',
    params: param
  });
}
// 用户数据分析总数据
function userDataIncludeTotal(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userDataIncludeTotal',
    method: 'GET',
    params: params
  });
}
// 用户来源
function userSource(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userSource',
    method: 'GET',
    params: params
  });
}
// 用户标签
function userTag(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userTag',
    method: 'GET',
    params: params
  });
}
// 用户活跃
function userTimeFrameActive(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userTimeFrameActive',
    method: 'GET',
    params: params
  });
}
// 取关用户分析
function userUnSubscribe(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userAnalysis/userUnSubscribe',
    method: 'GET',
    params: params
  });
}