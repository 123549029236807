"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cashUpperLimit = cashUpperLimit;
exports.changeTerminalAuditConfig = changeTerminalAuditConfig;
exports.getAllTerminalAuditConfig = getAllTerminalAuditConfig;
exports.getCashUpperLimit = getCashUpperLimit;
exports.getRuleDetail = getRuleDetail;
exports.saveGlobalRule = saveGlobalRule;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
var base2 = '';
function getRuleDetail(params) {
  return (0, _request.default)({
    url: base2 + '/market-zdConfig/globalRuleInfo/ruleDetail',
    method: 'get',
    params: params
  });
}
function saveGlobalRule(data) {
  return (0, _request.default)({
    url: base2 + '/market-zdConfig/globalRuleInfo/saveOrUpdate',
    method: 'post',
    data: data
  });
}

// 终端提现上限
// 查询
function getCashUpperLimit(params) {
  return (0, _request.default)({
    url: base2 + '/market-zdConfig/cashAccount/getCashUpperLimit',
    method: 'get',
    params: params
  });
}
// 修改
function cashUpperLimit(data) {
  return (0, _request.default)({
    url: base2 + '/market-zdConfig/cashAccount/cashUpperLimit',
    method: 'post',
    data: data
  });
}

// 终端审核方式设置
// 查询
function getAllTerminalAuditConfig(params) {
  return (0, _request.default)({
    url: base + '/api-basic/jcChannelArchives/getAllTerminalAuditConfig',
    method: 'get',
    params: params
  });
}
// 修改
function changeTerminalAuditConfig(data) {
  return (0, _request.default)({
    url: base + '/api-basic/jcChannelArchives/changeTerminalAuditConfig',
    method: 'post',
    data: data
  });
}