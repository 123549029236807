"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.constructor");
var _product = require("@/api/basic/product");
var _index = require("@/api/fake_expel/brand/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  directives: {
    waves: _waves.default
  },
  props: {
    listQuery: {
      default: {}
    },
    handleShow: {
      default: false
    }
  },
  data: function data() {
    return {
      // handleShow: false,
      formData: {
        pageNo: 1,
        pageSize: 20,
        antiNum: '',
        csType: '',
        ppwlList: [],
        queryCs: '',
        ycyjlx: -1,
        queryTime: ''
      },
      brand: null,
      month: '',
      code_type: [],
      brandList: [],
      proList: [],
      showSelectBrand: null,
      SelectBrand: [{
        pid: -1,
        brandName: '全部'
      }],
      brandData: '',
      selProList: [],
      changeNumberv: true
    };
  },
  computed: {},
  created: function created() {
    var month = parseInt(new Date().getMonth()) + 1;
    this.month = month < 10 ? '0' + month + '' : month;
    this.formData.queryTime = new Date().getFullYear() + '' + this.month;
    // this.getqueryBrand()
  },
  mounted: function mounted() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.getBrand();
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getBrand: function getBrand() {
      var _this = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this.brandList = res.data.data;
          } else {
            _this.brandList = [];
          }
        }
      });
    },
    changeNumber: function changeNumber(e) {
      var flag = new RegExp('^[1-9]([0-9])*$').test(e.target.value);
      if (!flag) {
        this.changeNumberv = false;
        return false;
      }
      this.changeNumberv = true;
      return true;
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        var month = parseInt(new Date().getMonth()) + 1;
        this.month = month < 10 ? '0' + month + '' : month + '';
        this.formData = {
          pageNo: 1,
          pageSize: 20,
          antiNum: '',
          csType: '',
          ppwlList: [],
          queryCs: '',
          ycyjlx: -1,
          queryTime: ''
        };
        this.formData.queryTime = new Date().getFullYear() + '' + this.month;
        this.formData.csType = '';
        this.selProList = [];
        this.brandData = '';
        this.proList = [];
      }
      var lists = [];
      if (!this.selProList.length && this.brandData.pId != '') {
        for (var i in this.proList) {
          lists.push(this.proList[i].materialCode);
        }
      } else {
        for (var obj in this.selProList) {
          lists.push(this.selProList[obj]);
        }
      }
      if (this.brandData.pId == '-1') {
        this.selProList = '';
        lists = [];
      }
      this.formData.pageNo = 1;
      if (this.formData.queryCs == 0) {
        this.formData.queryCs = '';
      }
      this.formData.ppwlList = lists;
      this.$parent.searchList(this.formData);
    },
    // getqueryBrand() {
    //   this.loading = true
    //   queryBrand({}).then((response) => {
    //     var data = response.data
    //     if (data.code != 200) {
    //       this.$message.error(data.msg)
    //       return true
    //     }
    //     this.loading = false
    //     this.SelectBrand = data.data
    //   })
    // },
    getqueryMaterial: function getqueryMaterial() {
      var _this2 = this;
      this.loading = true;
      this.selProList = [];
      if (!this.brandData) {
        this.proList = [];
        return false;
      }
      (0, _index.queryMaterial)({
        pId: this.brandData
      }).then(function (response) {
        var data = response.data;
        _this2.proList = [];
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.loading = false;
        if (data.data.length <= 0) {
          _this2.$message.error('当前选择品牌下不存在产品信息');
          return true;
        }
        _this2.proList = data.data;
      }).catch(function () {
        _this2.proList = [];
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;