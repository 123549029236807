"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base = void 0;
exports.createSend = createSend;
exports.deleteSendById = deleteSendById;
exports.downLoadData = downLoadData;
exports.getAllTag = getAllTag;
exports.getUserCode = getUserCode;
exports.newsById = newsById;
exports.search = search;
exports.selectNewsList = selectNewsList;
exports.selectSendById = selectSendById;
exports.sendForNumber = sendForNumber;
exports.updateSend = updateSend;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
var base = 'http://saas.panpass.com/images/';
// export const base = 'http://testsaas.panpass.com/images/'
// 图文数据
exports.base = base;
function search(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    params: params
  });
}

// 获取 标签
function getAllTag(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/userInfo/getAllTag',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    params: params
  });
}
function getUserCode(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxUserCode/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    params: params
  });
}
// 创建群发
function createSend(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/createSend',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    timeout: 20000,
    method: 'POST',
    data: data
  });
}

// 删除
function deleteSendById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/deleteSendById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'DELETE',
    params: data
  });
}

// 根据ID 查询

function selectSendById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/selectSendById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    params: data
  });
}
// 修改群发

function updateSend(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/updateSend',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 下载
function downLoadData(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/down/detail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    responseType: 'blob',
    params: param
  });
}

// 查询图文
function newsById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/selectNewsById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    params: data
  });
}
// 查询多图文
function selectNewsList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/selectNewsList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 查询发送条数
function sendForNumber(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxSends/sendForNumber',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    params: param
  });
}