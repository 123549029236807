var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.currentId ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "before-close": _vm.cancel,
        width: "600",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _vm.dataForm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "分类图片：", prop: "pic" } },
                [
                  _c("Upload-img", {
                    ref: "dataFormUpload",
                    attrs: { data: _vm.dataForm.pic, text: "" },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.dataForm, "pic", $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(" 建议: 一级分类图片尺寸：565*165\n        "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "45px" } }, [
                      _vm._v("二级分类图片尺寸：122*122"),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataForm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "分类名称：", prop: "categoryName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "204px" },
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      maxlength: "5",
                      label: "分类名称",
                    },
                    model: {
                      value: _vm.dataForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "categoryName", $$v)
                      },
                      expression: "dataForm.categoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(" 提示:最多填写5个字\n      "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上级分类：" } },
            [
              _c("el-cascader", {
                attrs: {
                  "expand-trigger": "hover",
                  options: _vm.categoryList,
                  props: _vm.categoryTreeProps,
                  "change-on-select": "",
                  clearable: true,
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.selectedCategory,
                  callback: function ($$v) {
                    _vm.selectedCategory = $$v
                  },
                  expression: "selectedCategory",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "formTip" }, [
                _c("i", { staticClass: "el-icon-warning-outline" }),
                _vm._v(" 提示:该项不选择时，默认为添加一级分类\n      "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dataForm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "排序号：", prop: "seq" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "only-number",
                        rawName: "v-only-number",
                        value: {
                          min: 0,
                          precision: 0,
                          set: _vm.dataForm,
                          name: "seq",
                        },
                        expression:
                          "{ min:0,precision:0,set: dataForm, name:'seq' }",
                      },
                    ],
                    attrs: { label: "排序号" },
                    model: {
                      value: _vm.dataForm.seq,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "seq", $$v)
                      },
                      expression: "dataForm.seq",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(" 提示:数值越小，小程序展示时越靠前\n      "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", size: "mini", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "checkbox",
                  model: {
                    value: _vm.dataForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "status", $$v)
                    },
                    expression: "dataForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("下线")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("正常")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }