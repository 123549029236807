var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orderNo", label: "单号:" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orderNo", $$v)
                                      },
                                      expression: "listQuery.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "purchaseOwnerOrgId",
                                        label: "采购单位组织:",
                                      },
                                    },
                                    [
                                      _c("treeselect", {
                                        attrs: {
                                          multiple: false,
                                          "flatten-search-results": true,
                                          placeholder: "请选择组织",
                                          options: _vm.orgOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.listQuery.purchaseOwnerOrgId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "purchaseOwnerOrgId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "listQuery.purchaseOwnerOrgId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "purchaseOrgName",
                                    label: "采购单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getUnit("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.purchaseOrgName,
                                      callback: function ($$v) {
                                        _vm.purchaseOrgName = $$v
                                      },
                                      expression: "purchaseOrgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "saleOrgName",
                                        label: "销售单位:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          readonly: "",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.getUnit("out")
                                          },
                                        },
                                        model: {
                                          value: _vm.saleOrgName,
                                          callback: function ($$v) {
                                            _vm.saleOrgName = $$v
                                          },
                                          expression: "saleOrgName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "businessUser",
                                        label: "创建人:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          readonly: "",
                                          placeholder: "请输入",
                                        },
                                        on: { focus: _vm.chosePerson },
                                        model: {
                                          value: _vm.businessUser,
                                          callback: function ($$v) {
                                            _vm.businessUser = $$v
                                          },
                                          expression: "businessUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderTime",
                                    label: "订单日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.orderTime,
                                      callback: function ($$v) {
                                        _vm.orderTime = $$v
                                      },
                                      expression: "orderTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "tradeStatus",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.tradeStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "tradeStatus",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.tradeStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderStatus,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: item.figure,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.DoSearch },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-refresh",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoReset(_vm.listQuery)
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.expand },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.expandTxt) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                      _c("i", {
                                        class: _vm.showCol
                                          ? "el-icon-arrow-up"
                                          : "el-icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.hasAuth("exportbtn", _vm.apis)
                ? _c("div", { staticClass: "phead operate-btns" }, [
                    _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { height: "38px" },
                            attrs: {
                              type: "primary",
                              icon: "el-icon-download",
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.DoExportAll },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: { height: _vm.tabHeight, data: _vm.list, fit: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "单号",
                      "min-width": "160",
                      prop: "orderNo",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位组织",
                      "min-width": "120",
                      prop: "purchaseOwnerOrgName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位类型",
                      "min-width": "120",
                      prop: "purchaseOrgType",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComType")(row.purchaseOrgType)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      382433533
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位",
                      "min-width": "120",
                      prop: "purchaseOrgName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "销售单位类型",
                      "min-width": "120",
                      prop: "saleOrgType",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComType")(row.saleOrgType)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      559215981
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "销售单位",
                      "min-width": "120",
                      prop: "saleOrgName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      "min-width": "110",
                      prop: "createTime",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      "min-width": "110",
                      prop: "creatorUser",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量(箱)",
                      "min-width": "110",
                      prop: "chestNum",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量(盒)",
                      "min-width": "120",
                      prop: "totalBottleNum",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "140",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm._f("orderStatusType")(
                                      row.tradeStatus
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderStatusText")(row.tradeStatus)
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3127034859
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.apis)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2193556678
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("personChose", {
        attrs: { personVisible: _vm.personShow },
        on: { dialogHide: _vm.hidePerson, select: _vm.getPerson },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }