var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              staticStyle: { padding: "20px 0 0 20px" },
              attrs: {
                model: _vm.queryParams,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "礼包名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入礼包名称" },
                                model: {
                                  value: _vm.queryParams.prizeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "prizeName", $$v)
                                  },
                                  expression: "queryParams.prizeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "状态" },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "padding-top": "5px" } },
                            [
                              _c("div", { staticClass: "btnArea" }, [
                                _c(
                                  "div",
                                  { staticClass: "head-btn-group" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.querylist },
                                      },
                                      [_vm._v("查询")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.restPrizeList },
                                      },
                                      [_vm._v("重置")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("添加")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                    },
                    on: { click: _vm.exportCombo },
                  },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: _vm.tableConfig.align,
                  width: "80px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "礼包名称",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "combocount",
                  label: "礼品数量",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "礼包状态",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showStatus,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c("el-tag", [_vm._v("启用")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "baseValue",
                  label: "参考金额",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "cerateTime",
                  label: "创建时间",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.stop(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "停用" : "启用")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getPrizeList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRules",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "礼包名称:", prop: "prizeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.statusStr == "启用",
                      placeholder: "请输入礼包名称",
                    },
                    model: {
                      value: _vm.form.prizeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeName", $$v)
                      },
                      expression: "form.prizeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "展示名称:", prop: "prizeAlias" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入展示名称" },
                    model: {
                      value: _vm.form.prizeAlias,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeAlias", $$v)
                      },
                      expression: "form.prizeAlias",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-row", [
                _c("div", { staticClass: "btnArea" }, [
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.addBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.addBtn.icon,
                            disabled: _vm.statusStr == "启用",
                          },
                          on: { click: _vm.addItem },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "wrap" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoadig,
                          expression: "listLoadig",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.multipleSelection,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "highlight-current-row": true,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "编号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "100px",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "prizeName",
                          align: _vm.tableConfig.align,
                          label: "奖品名",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "num",
                          align: _vm.tableConfig.align,
                          label: "数量",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "参考金额:" } },
                [
                  _c("el-input", {
                    directives: [{ name: "Number", rawName: "v-Number" }],
                    attrs: { readonly: "", disabled: _vm.statusStr == "启用" },
                    model: {
                      value: _vm.form.baseValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "baseValue", $$v)
                      },
                      expression: "form.baseValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品说明:", prop: "explain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入奖品说明" },
                    model: {
                      value: _vm.form.explain,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explain", $$v)
                      },
                      expression: "form.explain",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "信息收集:", prop: "openDataCollection" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "开启",
                      disabled: _vm.statusStr == "启用",
                    },
                    model: {
                      value: _vm.form.openDataCollection,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "openDataCollection", $$v)
                      },
                      expression: "form.openDataCollection",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.openDataCollection
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "relevanceDataId" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: _vm.statusStr == "启用" },
                          on: { change: _vm.handleCheckboxChange },
                          model: {
                            value: _vm.dateCollectionAll,
                            callback: function ($$v) {
                              _vm.dateCollectionAll = $$v
                            },
                            expression: "dateCollectionAll",
                          },
                        },
                        _vm._l(_vm.dateCollectionList, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.id,
                              attrs: { value: item.id, label: item.id },
                            },
                            [_vm._v(_vm._s(item.fieldName))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("formRules")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("formRules")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShow
        ? _c("giftBagDialog", {
            ref: "bagDialog",
            attrs: { "multiple-list": _vm.oldList },
            on: {
              close: function ($event) {
                _vm.isShow = false
              },
              change: _vm.getMultipleSelection,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }