"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _sceneMarketing = require("@/api/terminal/sceneMarketing");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _reciPient = _interopRequireDefault(require("@/components/reciPient"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagecardManagementCardManageindex';
var _default = {
  name: 'MarketingManagecardManagementCardManageindex',
  components: {
    Pagination: _Pagination.default,
    ProDialog: _productDialog.default,
    ReciPient: _reciPient.default
  },
  data: function data() {
    return {
      total: 0,
      valcode: '1',
      datalist: [],
      options: '',
      tabHeight: '100%',
      listLoading: false,
      downLoading: false,
      dialogTableVisible: false,
      presentDate: new Date().getTime(),
      dialogTitle: '酒卡添加',
      choseData: true,
      disabled: false,
      isDisable: false,
      productVisibleShow: false,
      reciPientShow: false,
      showform: false,
      timeData: [],
      pageForm: {
        product: '',
        productIdList: [],
        products: ''
      },
      searchForm: {
        pageNow: 1,
        pageSize: 20,
        receivePName: '',
        actNo: '',
        actName: ''
      },
      formInline: {
        pageNow: 1,
        pageSize: 10,
        actName: '',
        actNo: '',
        receiveNum: '',
        receivePName: '',
        endTime: '',
        prizes: [],
        description: '',
        product: ''
      },
      formInlineRules: {
        actName: [{
          required: true,
          message: '请输入酒卡名称',
          trigger: 'blur'
        }],
        // actNo: [{ required: true, message: "请输入酒卡编号", trigger: "blur" }],
        receiveNum: [{
          required: true,
          message: '请输入领取数量',
          trigger: 'blur'
        }],
        receivePName: [{
          required: true,
          message: '请选择领取人',
          trigger: 'change'
        }],
        endTime: [{
          required: true,
          message: '请选择日期时间',
          trigger: 'change'
        }],
        exchangeMode: [{
          required: true,
          message: '请选择兑换模式',
          trigger: 'change'
        }],
        // product: [
        //   {
        //     required: true,
        //     message: "请选择产品",
        //     trigger: "change",
        //   },
        // ],
        description: [{
          required: true,
          message: '请输入酒卡说明',
          trigger: 'change'
        }]
      },
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    this.user_Id = sessionStorage.getItem('userId');
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  methods: {
    jumpLink: function jumpLink(id) {
      this.authId('jumpbtn', this.apis);
      this.$router.push({
        path: '/marketingManage/cardManagement/cardWriteoff/index',
        query: {
          id: id
        }
      });
    },
    InfoDownload: function InfoDownload() {
      var _this = this;
      this.authId('importbtn', this.apis);
      this.downLoading = true;
      this.searchForm.serchstartTime = this.timeData[0];
      this.searchForm.serchendTime = this.timeData[1];
      var data = JSON.parse(JSON.stringify(this.searchForm));
      delete data.pageNow;
      delete data.pageSize;
      (0, _sceneMarketing.actInfoDownload)(data).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
          });
          var fileName = '酒卡列表.xlsx';
          var elink = document.createElement('a');
          if ('download' in elink) {
            // 非IE下载
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    },
    blurboxNum: function blurboxNum(num, val1, val2, prize, index) {
      num = Number(num.split('*')[1]);
      val1 = Number(val1);
      val2 = Number(val2);
      prize = Number(prize);
      var a = val1 * num;
      var b = a + val2;
      var c = b * prize;
      var list = this.formInline.prizes;
      for (var i = 0; i < list.length; i++) {
        list[index].amount = c;
      }
    },
    blurbottleNum: function blurbottleNum(num, val1, val2, prize, index) {
      num = Number(num.split('*')[1]);
      val1 = Number(val1);
      val2 = Number(val2);
      prize = Number(prize);
      var a = val1 * num;
      var b = a + val2;
      var c = b * prize;
      var list = this.formInline.prizes;
      for (var i = 0; i < list.length; i++) {
        list[index].amount = c;
      }
    },
    productListShow: function productListShow() {
      this.productVisibleShow = true;
      this.product = '';
      this.pageForm.productList = '';
    },
    removetable: function removetable(name) {
      var list = this.formInline.prizes;
      for (var i = 0; i < list.length; i++) {
        if (list[i].productNo == name) {
          list.splice(i, 1);
        }
      }
    },
    // 产品选择
    setProduct: function setProduct(data) {
      this.productVisibleShow = false;
      this.product = data;
      this.pageForm.productList = data;
      var aId = [];
      var aName = [];
      var arr = [];
      data.forEach(function (item) {
        aId.push(item.pId);
        aName.push(item.productName);
        var obj = {
          productBrand: item.productBrandId,
          // 产品品牌
          productNo: item.productCode,
          // 产品编号
          productNorm: item.productModel,
          // 产品规格
          productType: item.productClassId,
          // 产品分类
          prizeId: item.pId,
          // 产品id
          name: item.productName,
          // 产品名称
          price: item.productPrice,
          // 产品单价
          packScale: item.packScaleExpression,
          // 产品箱数
          amount: '',
          bottleNum: '0',
          boxNum: '0'
        };
        arr.push(obj);
      });
      //   this.formInline.product = aName.join(",");
      this.formInline.prizes = arr;
      this.pageForm.productIdList = aId;
      this.pageForm.products = arr;
      this.formInline.prizes.forEach(function (item, key) {
        item.key = key;
      });
    },
    PientListShow: function PientListShow() {
      this.reciPientShow = true;
    },
    // 领取人
    setreciPient: function setreciPient(data) {
      this.reciPientShow = false;
      this.formInline.receivePName = data.userName ? data.userName : '';
      this.formInline.receivePId = data.pid ? data.pid : '';
      this.formInline.receiveQyorgId = data.orgId ? data.orgId : '';
      this.searchForm.receivePName = data.userName ? data.userName : '';
      this.searchForm.receivePId = data.pid ? data.pid : '';
    },
    fetchData: function fetchData() {
      var _this2 = this;
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      this.searchForm.serchstartTime = this.timeData[0];
      this.searchForm.serchendTime = this.timeData[1];
      (0, _sceneMarketing.jkActInfolist)(this.searchForm).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.status == 200) {
          _this2.datalist = res.data.list;
          _this2.total = res.data.total;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    addjk: function addjk() {
      this.authId('addbtn', this.apis);
      this.dialogTableVisible = true;
      this.disabled = false;
      this.showform = false;
      this.formInline.receivePName = '';
      this.formInline.receivePId = '';
      this.formInline.receiveQyorgId = '';
      this.searchForm.receivePName = '';
      this.searchForm.receivePId = '';
      this.isDisable = false;
    },
    createData: function createData() {
      var _this3 = this;
      this.dialogTitle = '酒卡添加';
      this.showform = false;
      this.isDisable = false;
      var data = new Date(this.formInline.endTime).getTime();
      if (data < this.presentDate) {
        this.$message({
          message: '过期时间不能小于当前时间',
          type: 'error',
          duration: 3 * 1000
        });
        return false;
      }
      var prizes = this.formInline.prizes;
      for (var i = 0; i < prizes.length; i++) {
        if (prizes[i].boxNum == '' && prizes[i].bottleNum == '') {
          this.$message({
            message: '提交的产品数量不能为空',
            type: 'error',
            duration: 3 * 1000
          });
          return false;
        } else if (prizes[i].boxNum * 1 == 0 && prizes[i].bottleNum * 1 == 0) {
          this.$message({
            message: '提交的产品数量不能为0',
            type: 'error',
            duration: 3 * 1000
          });
          return false;
        }
      }
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          _this3.isDisable = true;
          (0, _sceneMarketing.jkInfoadd)(_this3.formInline).then(function (res) {
            res = res.data;
            if (res.status == 200) {
              _this3.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this3.cancel();
              _this3.fetchData();
            } else {
              _this3.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getDetail: function getDetail(val, type, id) {
      var _this4 = this;
      if (val === 2) {
        this.authId('scanbtn', this.apis);
        this.disabled = true;
        this.dialogTitle = '酒卡详情';
        this.showform = true;
      }
      (0, _sceneMarketing.jkActInfodetailes)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this4.choseData = false;
          _this4.dialogTableVisible = true;
          _this4.formInline = res.data;
        } else {
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    preview: function preview(id, name) {
      var _this5 = this;
      this.authId('obtainbtn', this.apis);
      var adminuser = this.user_Id.replace(/"+/g, '');
      //   console.log(name, adminuser);
      if (name == adminuser) {
        (0, _sceneMarketing.downloaddetailes)({
          id: id
        }).then(function (res) {
          _this5.$message({
            message: '领取成功',
            type: 'success',
            duration: 3 * 1000
          });
          var blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
          });
          var fileName = '酒卡明细.xlsx';
          var elink = document.createElement('a');
          if ('download' in elink) {
            // 非IE下载
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        });
      } else {
        this.$message({
          message: '登录账户与领取人不符',
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    // 弹窗取消
    cancel: function cancel() {
      this.formInline = {
        pageNow: 1,
        pageSize: 10,
        actName: '',
        actNo: '',
        description: '',
        endTime: '',
        prizes: [],
        receiveNum: '',
        receivePName: ''
      };
      this.restForm('formInline');
      this.dialogTableVisible = false;
      this.disabled = false;
      this.choseData = true;
    },
    // 弹窗取消
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.timeData = [];
      // this.searchForm.receivePName = "";
      this.searchForm.receivePId = '';
    }
  }
};
exports.default = _default;