"use strict";

// 搜索栏
var formConfig = {
  labelPosition: 'top',
  labelWidth: '120px',
  // 查询按钮位置是否在搜索条件后面
  btnFollow: true,
  // 带展开按钮 搜索区域宽度
  btnAreaHasShowMore: '273px',
  //  搜索区域宽度
  btnArea: '235px',
  // 是否显示割线
  showDvider: false
};

// 按钮
var btnConfig = {
  size: 'medium',
  plain: false,
  // 搜索按钮
  searchBtn: {
    type: 'success',
    plain: false,
    icon: 'el-icon-search',
    text: '搜索'
  },
  // 重置按钮
  resetBtn: {
    type: 'success',
    plain: false,
    icon: 'el-icon-refresh-right',
    text: '重置'
  },
  // 添加按钮
  addBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-plus',
    text: '添加'
  },
  // 批量启用
  setBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-video-play',
    text: '批量启用'
  },
  // 批量禁用
  unSetBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-video-pause',
    text: '批量禁用'
  },
  // 批量导入
  upLoadBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-download',
    text: '批量导入'
  },
  // 导出
  downLoadBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-upload2',
    text: '导出'
  },
  // 批量删除
  delBtn: {
    type: 'danger',
    plain: false,
    icon: 'el-icon-delete',
    text: '批量删除'
  },
  // 修改
  updateBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-user',
    text: '修改'
  },
  refreshBtn: {
    type: 'primary',
    plain: false,
    icon: 'el-icon-refresh',
    text: '手动同步数据'
  }
};
// 表格
var tableConfig = {
  // 对齐
  align: 'left',
  // 斑马纹
  stripe: false,
  // 边框
  border: false
};
module.exports = {
  formConfig: formConfig,
  tableConfig: tableConfig,
  btnConfig: btnConfig
};