var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("产品档案")]),
      _vm._v(" "),
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c("div", { staticClass: "page-section leftSide" }, [
                _c(
                  "div",
                  { staticClass: "search-condition" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-position": _vm.formConfig.labelPosition,
                          "label-width": _vm.formConfig.labelWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "cols" },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "head-btn-group",
                                attrs: { gutter: 10 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 16, prop: "materialCode" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "分类编码/名称:",
                                          prop: "isDelete",
                                          "label-width": "50px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请输入",
                                            maxlength: "30",
                                          },
                                          model: {
                                            value: _vm.filterText,
                                            callback: function ($$v) {
                                              _vm.filterText = $$v
                                            },
                                            expression: "filterText",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8, prop: "factoryCode" } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "orgStatus" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: { click: _vm.handleFilter },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        accordion: "",
                        data: _vm.classList,
                        props: _vm.props,
                        "highlight-current": true,
                        "filter-node-method": _vm.filterNode,
                        "node-key": "pId",
                        "default-expanded-keys": [1],
                        "expand-on-click-node": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var data = ref.data
                              return _c(
                                "div",
                                {
                                  staticStyle: { width: "100%" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.scan(data)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "custom-tree-node",
                                      class:
                                        _vm.activeClass == data.id
                                          ? "active"
                                          : "",
                                      attrs: { index: data.id },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all",
                                            "word-wrap": "break-word",
                                            "white-space": "normal",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                "(" +
                                                  data.productClassCode +
                                                  ")" +
                                                  data.productClassName
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1625522067
                      ),
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "calc(100% - 300px)",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.searchForm,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productCode" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productCode",
                                        label: "产品编码:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                          maxlength: "20",
                                        },
                                        on: {
                                          input: function (e) {
                                            return (_vm.searchForm.productCode =
                                              _vm.validCode(e))
                                          },
                                        },
                                        model: {
                                          value: _vm.searchForm.productCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "productCode",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.productCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productName" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productName",
                                        label: "产品名称:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                          maxlength: "30",
                                        },
                                        model: {
                                          value: _vm.searchForm.productName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "productName",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.productName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productBrandId" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productBrandId",
                                        label: "产品品牌:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.searchForm.productBrandId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "productBrandId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.productBrandId",
                                          },
                                        },
                                        _vm._l(_vm.brandList, function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.brandName,
                                              value: item.pId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "packScaleId" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "packScaleId",
                                        label: "包装比例:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            "value-key": "packCode",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.searchForm.packScaleId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "packScaleId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.packScaleId",
                                          },
                                        },
                                        _vm._l(_vm.packList, function (item) {
                                          return _c("el-option", {
                                            key: item.packCode,
                                            attrs: {
                                              label: item.packScaleDesc,
                                              value: item.pId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "sourceEnum" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "sourceEnum",
                                        label: "产品来源:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.searchForm.sourceEnum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "sourceEnum",
                                                $$v
                                              )
                                            },
                                            expression: "searchForm.sourceEnum",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "NC同步",
                                              value: 2,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "系统自建",
                                              value: 1,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "archivesStatus" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "archivesStatus",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.searchForm.archivesStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "archivesStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.archivesStatus",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "启用", value: 1 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "禁用", value: 0 },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "删除", value: 2 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formConfig.btnFollow
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                                plain:
                                                  _vm.btnConfig.searchBtn.plain,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.searchForm.current = 1
                                                  _vm.getList()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                                plain:
                                                  _vm.btnConfig.resetBtn.plain,
                                              },
                                              on: { click: _vm.resetForm },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formConfig.btnFollow
                    ? _c("el-divider", { staticClass: "btnDivider" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btnArea" },
                    [
                      _c("el-col", { staticClass: "head-btn-group" }, [
                        _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                        _vm.hasAuth("exportbtn", _vm.$route.meta.authList) ||
                        _vm.hasAuth("allstart", _vm.$route.meta.authList) ||
                        _vm.hasAuth("stopbtn", _vm.$route.meta.authList)
                          ? _c(
                              "div",
                              { staticClass: "head-btn-group" },
                              [
                                _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "20px" },
                                        attrs: {
                                          type: _vm.btnConfig.addBtn.type,
                                          plain: _vm.btnConfig.plain,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.addBtn.icon,
                                        },
                                        on: { click: _vm.add },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.addBtn.text)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasAuth(
                                  "exportbtn",
                                  _vm.$route.meta.authList
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "border-radius": "4px" },
                                        attrs: {
                                          type: _vm.btnConfig.downLoadBtn.type,
                                          size: _vm.btnConfig.size,
                                          plain: _vm.btnConfig.plain,
                                          icon: _vm.btnConfig.downLoadBtn.icon,
                                          loading: _vm.downLoading,
                                        },
                                        on: { click: _vm.download },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.downLoadBtn.text)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      false
                        ? _c("el-table-column", {
                            attrs: {
                              label: "产品图片",
                              prop: "configCode",
                              align: _vm.tableConfig.align,
                              width: "125",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "52px",
                                          height: "29px",
                                        },
                                        attrs: {
                                          fit: "contain",
                                          src: row.productImages
                                            ? row.productImages
                                            : _vm.imgUrl,
                                          "preview-src-list": [
                                            row.productImages
                                              ? row.productImages
                                              : _vm.imgUrl,
                                          ],
                                          "z-index": 9999,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2966605008
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          prop: "productCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          align: _vm.tableConfig.align,
                          "min-width": "200",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品分类",
                          prop: "productClassName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品品牌",
                          prop: "brandName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "内码",
                          prop: "internalCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          prop: "packScaleDesc",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          prop: "productModel",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "绑定产品",
                          prop: "relationProductCount",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "来源",
                          prop: "sourceEnum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          row.sourceEnum == 2
                                            ? "NC同步"
                                            : "系统自建"
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1135612314
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.archivesStatus == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.archivesStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.archivesStatus == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          445918578
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否支持扫码",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.isCode == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("是")]
                                      )
                                    : row.isCode == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("否")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("未设置")]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1727782245
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "定制产品",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.isCustom == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("是")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.isCustom == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("否")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3981881926
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("startbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("stopbtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "180",
                              "class-name": "small-padding fixed-width",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth(
                                        "detailbtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.detail(row)
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "updatebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  row.archivesStatus == 2,
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "deletebtn",
                                        _vm.$route.meta.authList
                                      ) ||
                                      _vm.hasAuth(
                                        "startbtn",
                                        _vm.$route.meta.authList
                                      ) ||
                                      _vm.hasAuth(
                                        "stopbtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-dropdown",
                                            { staticClass: "table-dropdown" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-dropdown-link",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                更多\n                "
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-down el-icon--right",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                [
                                                  _vm.hasAuth(
                                                    "deletebtn",
                                                    _vm.$route.meta.authList
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  row.archivesStatus ==
                                                                  2,
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.DoDel(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasAuth(
                                                    "startbtn",
                                                    _vm.$route.meta.authList
                                                  ) ||
                                                  _vm.hasAuth(
                                                    "stopbtn",
                                                    _vm.$route.meta.authList
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        [
                                                          _vm.hasAuth(
                                                            "stopbtn",
                                                            _vm.$route.meta
                                                              .authList
                                                          ) &&
                                                          row.archivesStatus ==
                                                            1
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setUnUse(
                                                                          row.pId,
                                                                          "stopbtn"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("禁用")]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.hasAuth(
                                                            "startbtn",
                                                            _vm.$route.meta
                                                              .authList
                                                          ) &&
                                                          row.archivesStatus ==
                                                            0
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setInUse(
                                                                          row.pId,
                                                                          "startbtn"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("启用")]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1990930872
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.searchForm.current,
                      limit: _vm.searchForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.searchForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.searchForm, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "sourceEnum", label: "产品来源" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.disabled || _vm.title == "编辑产品档案",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.ruleForm.sourceEnum,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "sourceEnum", $$v)
                        },
                        expression: "ruleForm.sourceEnum",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "NC同步", value: 2 } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "系统自建", value: 1 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品编码", prop: "productCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled || _vm.title == "编辑产品档案",
                      maxlength: "20",
                      placeholder: "请输入",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.productCode = _vm.validCode(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.productCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productCode", $$v)
                      },
                      expression: "ruleForm.productCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "productName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      maxlength: "40",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.ruleForm.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productName", $$v)
                      },
                      expression: "ruleForm.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品分类", prop: "productClassId" } },
                [
                  _c("el-cascader", {
                    ref: "productClass",
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.classList,
                      props: {
                        value: "pId",
                        label: "productClassName",
                        children: "children",
                      },
                      "show-all-levels": false,
                    },
                    on: { change: _vm.productClassFun },
                    model: {
                      value: _vm.ruleForm.productClassId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productClassId", $$v)
                      },
                      expression: "ruleForm.productClassId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品品牌", prop: "productBrandId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "请选择",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.productBrandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "productBrandId", $$v)
                        },
                        expression: "ruleForm.productBrandId",
                      },
                    },
                    _vm._l(_vm.brandList, function (item) {
                      return _c("el-option", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.brandStatus == 1,
                            expression: "item.brandStatus == 1",
                          },
                        ],
                        key: item.value,
                        attrs: { label: item.brandName, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内码", prop: "internalCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled || _vm.title == "编辑产品档案",
                      placeholder: "请输入",
                    },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.ruleForm.internalCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "internalCode", $$v)
                      },
                      expression: "ruleForm.internalCode",
                    },
                  }),
                  _vm._v(" "),
                  _vm.showError
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("请输入四位数字"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品规格", prop: "productModel" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      maxlength: "40",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.ruleForm.productModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productModel", $$v)
                      },
                      expression: "ruleForm.productModel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包装比例", prop: "packScaleId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.disabled || _vm.title == "编辑产品档案",
                        placeholder: "请选择",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.packScaleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "packScaleId", $$v)
                        },
                        expression: "ruleForm.packScaleId",
                      },
                    },
                    _vm._l(_vm.packList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.packScaleDesc, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.ruleForm.extendList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.attributeType == "1"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: item.attributeName } },
                          [
                            _c("el-input", {
                              attrs: { maxlength: item.attributeConstraint },
                              model: {
                                value: item.extendValue,
                                callback: function ($$v) {
                                  _vm.$set(item, "extendValue", $$v)
                                },
                                expression: "item.extendValue",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.attributeType == "2"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: item.attributeName } },
                          [
                            _c("Upload-img", {
                              ref: "imgupload" + index,
                              refInFor: true,
                              attrs: {
                                data: item.extendValue,
                                text: "",
                                "limit-count": Number(item.attributeConstraint),
                              },
                              on: {
                                "update:data": function ($event) {
                                  return _vm.$set(item, "extendValue", $event)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.attributeType == "3"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: item.attributeName } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  multiple:
                                    item.attributeConstraint == "1"
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: item.extendValue,
                                  callback: function ($$v) {
                                    _vm.$set(item, "extendValue", $$v)
                                  },
                                  expression: "item.extendValue",
                                },
                              },
                              _vm._l(item.dictionaryList, function (el, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: { label: el.name, value: el.pid },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "质检报告", prop: "inspectionReport" } },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.img1loading,
                          expression: "img1loading",
                        },
                      ],
                      attrs: {
                        "list-type": "picture-card",
                        disabled: _vm.disabled,
                        action: "",
                        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                        "show-file-list": false,
                        "http-request": _vm.beforeAvatarUpload1,
                      },
                    },
                    [
                      _vm.inspectionReport
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.inspectionReport },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            只能上传jpg/png文件，且不超过500kb\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "archivesStatus" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.disabled,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.ruleForm.archivesStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "archivesStatus", $$v)
                      },
                      expression: "ruleForm.archivesStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否支持扫码", prop: "isCode" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.disabled || _vm.title == "编辑产品档案",
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 2,
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.ruleForm.isCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isCode", $$v)
                      },
                      expression: "ruleForm.isCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "定制产品", prop: "isCustom" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "是",
                      "inactive-text": "否",
                    },
                    model: {
                      value: _vm.ruleForm.isCustom,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isCustom", $$v)
                      },
                      expression: "ruleForm.isCustom",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "说明", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品图片", prop: "productImages" } },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.imgloading,
                          expression: "imgloading",
                        },
                      ],
                      attrs: {
                        "list-type": "picture-card",
                        disabled: _vm.disabled,
                        action: "",
                        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                        "show-file-list": false,
                        "http-request": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            只能上传jpg/png文件，且不超过500kb\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关联产品" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.openProduct } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.sourceEnum == 1
                            ? "关联NC产品"
                            : "关联产品"
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.relationProductList,
                        border: "",
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品编码", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.productCode)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品名称", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.productName)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemove(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("移除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.scanVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 2, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "产品编码" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.productCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.productName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品分类" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.productClassName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品品牌" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.brandName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "内码" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.internalCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品规格" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.productModel)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "包装比例" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.packScaleDesc)),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.ruleForm.extendList, function (item, index) {
                    return _vm.ruleForm.sourceEnum == 1
                      ? _c(
                          "el-descriptions-item",
                          {
                            key: index,
                            staticStyle: { padding: "8px 0" },
                            attrs: { label: item.attributeName, column: 2 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vue-from-block" },
                              [
                                item.attributeType == "1"
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v(_vm._s(item.extendValue)),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(item.extendValue, function (el, i) {
                                  return item.attributeType == "2" &&
                                    typeof item.extendValue == "object"
                                    ? _c(
                                        "span",
                                        { key: i },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100px",
                                              "margin-right": "20px",
                                            },
                                            attrs: {
                                              src: el,
                                              alt: "",
                                              "preview-src-list":
                                                item.extendValue,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                item.attributeType == "3"
                                  ? _c("div", { staticClass: "content" }, [
                                      _vm._v(_vm._s(item.extendValueDesc)),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品来源" } }, [
                    _vm.ruleForm.sourceEnum == 2
                      ? _c("span", [_vm._v("NC同步")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.sourceEnum == 1
                      ? _c("span", [_vm._v("系统自建")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.createTime)),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { title: "状态信息", column: 2, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "状态" } }, [
                    _vm.ruleForm.archivesStatus == 0
                      ? _c("span", [_vm._v("禁用")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.archivesStatus == 1
                      ? _c("span", [_vm._v("启用")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "是否支持扫码" } },
                    [
                      _vm.ruleForm.isCode == 2
                        ? _c("span", [_vm._v("否")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.isCode == 1
                        ? _c("span", [_vm._v("是")])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "定制产品" } }, [
                    _vm.ruleForm.isCustom == 0
                      ? _c("span", [_vm._v("否")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ruleForm.isCustom == 1
                      ? _c("span", [_vm._v("是")])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "picbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "质检报告" } },
                    [
                      _vm.inspectionReport
                        ? _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: _vm.inspectionReport,
                              "preview-src-list": [_vm.inspectionReport],
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "picbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "产品图片" } },
                    [
                      _vm.imageUrl
                        ? _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: _vm.imageUrl,
                              "preview-src-list": [_vm.imageUrl],
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "picbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "产品说明" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.remarks)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.ruleForm.sourceEnum == 2
                    ? _c("span", { staticClass: "itemtitle" }, [
                        _vm._v("关联产品"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.sourceEnum == 1
                    ? _c("span", { staticClass: "itemtitle" }, [
                        _vm._v("关联NC产品"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.ruleForm.relationProductList,
                        border: "",
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品编码", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.productCode)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品名称", width: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.productName)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "批量导入——产品档案",
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: { model: _vm.uploadForm, rulse: _vm.uploadRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.downExcel } },
                    [_vm._v("产品档案导入Excel模板")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "orgName", label: "文件上传" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                limit: 1,
                                name: "file",
                                data: _vm.uploadForm,
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    disabled: _vm.submitShow,
                                  },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { staticStyle: { "font-size": "14px" } }, [
                _c("p", [_vm._v("备注：")]),
                _vm._v(" "),
                _c("p", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "\n          1.必录项：产品名称，产品编码，产品分类名称，产品品牌名称，产品规格，包装比例表达式，状态(1启用,0禁用)，\n          是否扫码(1是,2否)\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("2.产品编码如果匹配到已有产品是编辑，匹配不到是新增"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "1px" },
              attrs: {
                border: "",
                fit: "",
                data: _vm.uploadData,
                height: "350px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "promptCase",
                  label: "提示列",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "productCode",
                  label: "产品编码",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "productName",
                  label: "产品名称",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "productClassName",
                  label: "产品分类名字",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "productBrandName",
                  label: "产品品牌名称",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "productModel",
                  label: "产品规格",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "packScaleCode",
                  label: "包装比例",
                  "min-width": "110",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "isCode",
                  label: "是否扫码",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "archivesStatus",
                  label: "状态",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.productDialogVisible
        ? _c("productDialog", {
            attrs: {
              visible: _vm.productDialogVisible,
              "selected-data": _vm.product,
              sourceEnum: _vm.ruleForm.sourceEnum,
              requestEnum: _vm.requestEnum,
              relationProductList: _vm.ruleForm.relationProductList,
            },
            on: {
              close: function ($event) {
                _vm.productDialogVisible = false
              },
              changeP: _vm.changeProduct,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }