"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getLevelById, supportBoxLevelList } from '@/api/basic/pack'
// import { parseTime, getAuthBtn, tableHeight } from '@/utils'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {},
  props: {
    showCol: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      factoryName: '',
      farmCode: '',
      TypeDataComs: {
        factoryList: [],
        workshopList: [],
        Productionline: []
      },
      searchForm: {
        beginDate: '',
        current: 1,
        endDate: '',
        factoryName: '',
        farmCode: '',
        markCode: '',
        produceLine: '',
        replaceStatus: '',
        size: 20
      },
      expandTxt: '展开'
      //   showCol: false,
    };
  },
  created: function created() {
    this.getfactoryList();
  },
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.clearfactoryList();
        this.$refs.searchForm.resetFields();
      }
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.$parent.searchList(this.searchForm);
    },
    // 清除工厂数据
    clearfactoryList: function clearfactoryList() {
      this.TypeDataComs.factoryList = [];
      this.TypeDataComs.workshopList = [];
      this.TypeDataComs.Productionline = [];
      this.factoryName = '';
      this.farmCode = '';
      this.searchForm.factoryName = '';
      this.searchForm.farmCode = '';
      this.searchForm.produceLine = '';
    },
    // 获取工厂数据
    getfactoryList: function getfactoryList() {
      var _this = this;
      // type	1：工厂 2：车间 3： 产线 4班组
      // parentId
      this.TypeDataComs.factoryList = [];
      (0, _select.factoryInfoList)({
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
          return;
        } else {
          _this.TypeDataComs.factoryList = response.data.data.records;
        }
      }).catch(function () {});
    },
    // 清除车间
    clearworkshopList: function clearworkshopList() {
      this.TypeDataComs.workshopList = [];
      this.TypeDataComs.Productionline = [];
      this.farmCode = '';
      this.searchForm.farmCode = '';
      this.searchForm.produceLine = '';
    },
    // 获取车间数据
    getWorkshop: function getWorkshop(v) {
      var _this2 = this;
      var val = JSON.parse(v);
      // type	1：工厂 2：车间 3： 产线 4班组
      // parentId
      this.searchForm.factoryName = val.name;
      this.TypeDataComs.workshopList = [];
      (0, _select.factoryInfoList)({
        parentId: val.pId,
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 2
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          _this2.$message.error(response.data.msg);
          return;
        } else {
          _this2.TypeDataComs.workshopList = response.data.data;
        }
      }).catch(function () {});
    },
    // 清楚产线
    clearProductionline: function clearProductionline() {
      this.TypeDataComs.Productionline = [];
      this.searchForm.produceLine = '';
    },
    // 获取产线
    getProductionline: function getProductionline(v) {
      var _this3 = this;
      var val = JSON.parse(v);
      // type	1：工厂 2：车间 3： 产线 4班组
      // parentId
      this.searchForm.farmCode = val.factoryCode;
      this.TypeDataComs.Productionline = [];
      (0, _select.factoryInfoList)({
        parentId: val.pId,
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 3
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          _this3.$message.error(response.data.msg);
          return;
        } else {
          _this3.TypeDataComs.Productionline = response.data.data;
        }
      }).catch(function () {});
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;