"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sceneMarketing = require("@/api/terminal/sceneMarketing");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _org = require("@/api/basic/org");
var _utils = require("@/utils");
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagecardManagementcardWriteoffindex';
var _default = {
  name: 'MarketingManagecardManagementcardWriteoffindex',
  components: {
    Pagination: _Pagination.default,
    instockUnit: _channelSelectDialog.default
  },
  data: function data() {
    return {
      total: 0,
      handleShow: false,
      datalist: [],
      options: '',
      tabHeight: '100%',
      listLoading: false,
      dialogTableVisible: false,
      presentDate: new Date().getTime(),
      dialogTitle: '酒卡添加',
      choseData: true,
      disabled: false,
      productVisibleShow: false,
      reciPientShow: false,
      orgList: [],
      unitType: '',
      showform: false,
      timeData: [],
      tabledata: [],
      pageForm: {
        product: '',
        productIdList: [],
        products: ''
      },
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      channelId: '',
      searchForm: {
        id: '',
        size: 20,
        current: 1,
        alcoholCardFlowNo: '',
        channelId: '',
        alcoholCardCode: '',
        alcoholCardNo: '',
        alcoholCardName: '',
        alcoholCard: '',
        actName: ''
      },
      formInline: {
        size: 10,
        current: 1,
        alcoholCardCode: '',
        alcoholCardFlowNo: '',
        alcoholCardNo: '',
        alcoholCardName: '',
        channelName: '',
        createTime: '',
        userName: '',
        exchangeTime: '',
        alcoholCard: '',
        outStorageNo: ''
      },
      mloading: false,
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    if (this.$route.query.id) {
      this.searchForm.id = this.$route.query.id;
    }
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
        that.getOrgListAll();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  methods: {
    // 获取单位
    getUnit: function getUnit(val) {
      var that = this;
      that.unitType = val;
      this.$refs.channelSelect.proSelVisible = true;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'search') {
        that.searchForm.channelId = val.pid;
        that.channelId = val.channelName;
      }
      that.unitShow = false;
    },
    getOrgListAll: function getOrgListAll() {
      var _this = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: ''
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      // var that = this;
      // var submitData = filterKeyNull(that.searchForm);
      // if (this.channelId) {
      //   this.searchForm.channelId = this.channelId[this.channelId.length - 1]
      // }
      this.searchForm.beginDate = this.timeData[0];
      this.searchForm.endDate = this.timeData[1];
      (0, _sceneMarketing.queryForPage)(this.searchForm).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code == 200) {
          _this2.datalist = res.data.list;
          _this2.total = res.data.total;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    getDetail: function getDetail(data) {
      var _this3 = this;
      this.authId('scanbtn', this.apis);
      this.disabled = true;
      this.dialogTitle = '酒卡详情';
      this.dialogTableVisible = true;
      this.formInline = data;
      var obj = {
        code: '',
        isError: '',
        orderType: '1',
        pageNo: '1',
        pageSize: '10',
        productId: '',
        id: data.outStorageNo
      };
      (0, _sceneMarketing.getCodeByOrderId)(obj).then(function (res) {
        res = res.data;
        if (res.code == 200) {
          _this3.tabledata = res.data.records;
        } else {
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 弹窗取消
    cancel: function cancel() {
      this.formInline = {
        pageNow: 1,
        pageSize: 10,
        actName: '',
        actNo: '',
        description: '',
        endTime: '',
        prizes: [],
        receiveNum: '',
        receivePName: ''
      };

      // this.restForm('formInline')
      this.dialogTableVisible = false;
      this.disabled = false;
      this.choseData = true;
    },
    // 弹窗取消
    restForm: function restForm(refname) {
      this.searchForm.current = 1;
      this.$refs[refname].resetFields();
      this.timeData = [];
      this.channelId = '';
    }
  }
};
exports.default = _default;