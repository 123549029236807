var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tableDialog",
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "关联活动",
            visible: _vm.visible,
            width: "1200px !important",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "label-suffix": ":",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "activityStatus",
                                    label: "布奖方式",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: { change: _vm.reSearch },
                                      model: {
                                        value: _vm.searchForm.activityDimension,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "activityDimension",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.activityDimension",
                                      },
                                    },
                                    _vm._l(
                                      _vm.activityDimensions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "activityStatus",
                                    label: "活动状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.searchForm.activityStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "activityStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.activityStatus",
                                      },
                                    },
                                    _vm._l(
                                      _vm.activityStatus,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "rangeTime",
                                    label: "起止时间",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: false,
                                    },
                                    model: {
                                      value: _vm.searchForm.rangeTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "rangeTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.rangeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelName",
                                    label: "经销商",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "经销商" },
                                    model: {
                                      value: _vm.searchForm.channelName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "channelName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.channelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "financeId",
                                    label: "资金配置",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.searchForm.financeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "financeId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.financeId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.sourceList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.fundConfigurationName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "seqNo", label: "活动ID" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "支持多个，中间逗号隔开",
                                    },
                                    model: {
                                      value: _vm.searchForm.seqNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "seqNo", $$v)
                                      },
                                      expression: "searchForm.seqNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          (_vm.searchForm.activityDimension == "0" ||
                            _vm.searchForm.activityDimension == "") &&
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productName",
                                        label: "活动产品",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { readonly: "" },
                                          model: {
                                            value: _vm.searchForm.productName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "productName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.productName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.choseProduct },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "activityName",
                                        label: "活动名称",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "活动名称" },
                                        model: {
                                          value: _vm.searchForm.activityName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "activityName",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.activityName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.searchForm.activityDimension == "1" ||
                            _vm.searchForm.activityDimension == "") &&
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderNo",
                                        label: "订单编号",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "订单编号" },
                                        model: {
                                          value: _vm.searchForm.orderNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "orderNo",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.orderNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.searchForm.activityDimension == "1" ||
                            _vm.searchForm.activityDimension == "") &&
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "activityType",
                                        label: "活动类型",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择活动类型",
                                          },
                                          model: {
                                            value: _vm.searchForm.activityType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "activityType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.activityType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "普通活动",
                                              value: "0",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "秒杀活动",
                                              value: "1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.getList },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restForm("searchForm")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.handleShow = !_vm.handleShow
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.handleShow ? "收起" : "展开"
                                          ) +
                                          "\n                  "
                                      ),
                                      _c("i", {
                                        class: _vm.handleShow
                                          ? "el-icon-arrow-up"
                                          : "el-icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", { staticClass: "btnDivider" }),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.list,
                    height: _vm.tabHeight,
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      selectable: _vm.checkSelectable,
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "活动ID", prop: "seqNo", width: "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动名称",
                      prop: "activityName",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动类型",
                      prop: "activityType",
                      width: "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.activityType == "0"
                              ? _c("span", [_vm._v("普通活动")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityType == "1"
                              ? _c("span", [_vm._v("秒杀活动")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "布奖方式",
                      prop: "activityDimension",
                      width: "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.activityDimension == "0"
                              ? _c("span", [_vm._v("产品")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityDimension == "1"
                              ? _c("span", [_vm._v("订单")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.searchForm.activityDimension != "1"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "活动产品",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.productNameList
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            disabled:
                                              row.productNameList.length <= 2,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            _vm._l(
                                              row.productNameList,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            _vm._l(
                                              row.productNameList.slice(0, 2),
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "text-overflow",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1325449089
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchForm.activityDimension != "0"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "订单编号",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return _vm._l(
                                  row.orderNoList,
                                  function (item, index) {
                                    return _c("span", { key: index }, [
                                      _vm._v("\n              " + _vm._s(item)),
                                      index != row.orderNoList.length - 1
                                        ? _c("span", [_vm._v(",")])
                                        : _vm._e(),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          3136380420
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchForm.activityDimension != "1"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "经销商",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.channelNameList &&
                                  row.channelNameList.length
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            placement: "top",
                                            disabled:
                                              row.channelNameList.length <= 2,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            _vm._l(
                                              row.channelNameList,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            _vm._l(
                                              row.channelNameList.slice(0, 2),
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "text-overflow",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(item) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (!row.channelNameList ||
                                    !row.channelNameList.length) &&
                                  row.activityDimension == "0"
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              全部经销商\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3302783707
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "资金配置",
                      prop: "financeName",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动状态",
                      prop: "activityStatus",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.activityStatus == "0"
                              ? _c("el-tag", [_vm._v("未开始")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus == "1"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("停用"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus == "2"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("进行中"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus == "3"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("已过期"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "开始时间-结束时间",
                      prop: "startTime",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.startTime))]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(row.endTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动模板",
                      prop: "activityFlowName",
                      "show-overflow-tooltip": "",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "解锁规则计数器(人次)",
                      prop: "currentLockCount",
                      "show-overflow-tooltip": "",
                      "min-width": "85",
                      align: _vm.tableConfig.align,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }