"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actTemplatePage = actTemplatePage;
exports.addActivity = addActivity;
exports.addOrEditWay = addOrEditWay;
exports.addrPocess = addrPocess;
exports.checkSerno = checkSerno;
exports.editStatus = editStatus;
exports.editway = editway;
exports.endProcessDetail = endProcessDetail;
exports.getProcessDetail = getProcessDetail;
exports.getProcessList = getProcessList;
exports.getWayDetail = getWayDetail;
exports.getwayList = getwayList;
exports.startProcess = startProcess;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 活动流程列表
function getProcessList(data) {
  return (0, _request.default)({
    url: base + '/market-config/process/getProcessList',
    method: 'post',
    data: data
  });
}

// 添加活动模板列表
function addActivity(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityTemplateInfo/templateInfoVoList',
    method: 'get',
    params: params
  });
}

// 添加流程列表
function addrPocess(data) {
  return (0, _request.default)({
    url: base + '/market-config/process/saveProcess',
    method: 'post',
    data: data
  });
}

// 启用活动流程详情实物
function startProcess(params) {
  return (0, _request.default)({
    url: base + '/market-config/process/startProcessDetail',
    method: 'post',
    params: params
  });
}

// 停用活动流程详情实物
function endProcessDetail(params) {
  return (0, _request.default)({
    url: base + '/market-config/process/endProcessDetail',
    method: 'post',
    params: params
  });
}

// 查看流程中的活动页面
function actTemplatePage(params) {
  return (0, _request.default)({
    url: base + '/market-config/process/actTemplatePage',
    method: 'get',
    params: params
  });
}

// 获得活动流程详情
function getProcessDetail(params) {
  return (0, _request.default)({
    url: base + '/market-config/process/getProcessDetail',
    method: 'post',
    params: params
  });
}

// 获得活动方案列表
function getwayList(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityScheme/getList',
    method: 'post',
    data: data
  });
}

// 活动方案详情
function getWayDetail(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityScheme/details',
    method: 'post',
    data: data
  });
}

// 创建活动方案列表
function addOrEditWay(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityScheme/addOrEdit',
    method: 'post',
    data: data
  });
}
// 编辑活动方案列表
function editway(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityScheme/edit',
    method: 'post',
    data: data
  });
}
// 修改状态
function editStatus(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityScheme/editStatus',
    method: 'post',
    data: data
  });
}

// 检查套标号段
function checkSerno(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityConfig/checkSerno',
    method: 'post',
    data: data
  });
}