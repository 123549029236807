exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#515151",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#5B2832",
	"menuBg": "#fff",
	"menuHover": "#5B2832",
	"subMenuBg": "#FAFAFB",
	"subMenuActiveBg": "#5B2832",
	"subMenuHover": "#5B2832",
	"sideBarWidth": "210px",
	"bg": "#f1f2f6",
	"skin": "#5B2832",
	"menuIcon": "#A2A5AF"
};