var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              staticStyle: { padding: "20px 0 0 20px" },
              attrs: {
                model: _vm.queryParams,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖品名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入奖品名称" },
                                model: {
                                  value: _vm.queryParams.prizeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "prizeName", $$v)
                                  },
                                  expression: "queryParams.prizeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "状态" },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖品类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "全部" },
                                  model: {
                                    value: _vm.queryParams.goodsType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "goodsType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.goodsType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "线下核销", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "线上邮寄", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "padding-top": "5px" } },
                            [
                              _c("div", { staticClass: "btnArea" }, [
                                _c(
                                  "div",
                                  { staticClass: "head-btn-group" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.querylist },
                                      },
                                      [_vm._v("查询")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.restPrizeList },
                                      },
                                      [_vm._v("重置")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("添加")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                    },
                    on: { click: _vm.exportCombo },
                  },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: _vm.tableConfig.align,
                  width: "80px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖品名称",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                  width: "240px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsType",
                  label: "奖品类型",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showGoodsType,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsHxTarget",
                  label: "核销级别",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showGoodsHxTarget,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "奖品状态",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showStatus,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c("el-tag", [_vm._v("启用")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "baseValue",
                  label: "参考金额",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "cerateTime",
                  label: "创建时间",
                  align: _vm.tableConfig.align,
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "openDataCollection",
                  label: "信息收集",
                  align: _vm.tableConfig.information,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.openDataCollection == "1"
                          ? _c("el-tag", [_vm._v("是")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.openDataCollection == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "imposeNum",
                  label: "数量限制",
                  align: _vm.tableConfig.align,
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.openNumImpose == "1"
                          ? _c("span", [_vm._v(" " + _vm._s(row.imposeNum))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  fixed: "right",
                  width: "180px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.stop(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "停用" : "启用")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "forms",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品名称:", prop: "prizeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入奖品名称,不超过25个字",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.form.prizeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeName", $$v)
                      },
                      expression: "form.prizeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "展示名称:", prop: "prizeAlias" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入展示名称,不超过25个字" },
                    model: {
                      value: _vm.form.prizeAlias,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeAlias", $$v)
                      },
                      expression: "form.prizeAlias",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品类型:", prop: "goodsType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.form.goodsType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goodsType", $$v)
                        },
                        expression: "form.goodsType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("线下核销"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("线上邮寄"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "核销类型:", prop: "goodsHxType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.form.goodsHxType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goodsHxType", $$v)
                        },
                        expression: "form.goodsHxType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("门店核销"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("线下大奖"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "核销级别:", prop: "goodsHxTarget" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.form.goodsHxTarget,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goodsHxTarget", $$v)
                        },
                        expression: "form.goodsHxTarget",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("经销商核销"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("企业核销"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "参考金额:", prop: "baseValue" } },
                [
                  _c("el-input", {
                    directives: [{ name: "Number", rawName: "v-Number" }],
                    staticClass: "numrule",
                    attrs: { placeholder: "请输入金额", autocomplete: "off" },
                    model: {
                      value: _vm.form.baseValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "baseValue", $$v)
                      },
                      expression: "form.baseValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖项说明:", prop: "explain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入奖品说明,不超过25个字" },
                    model: {
                      value: _vm.form.explain,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explain", $$v)
                      },
                      expression: "form.explain",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "信息收集:", prop: "openDataCollection" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "开启" },
                    model: {
                      value: _vm.form.openDataCollection,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "openDataCollection", $$v)
                      },
                      expression: "form.openDataCollection",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.openDataCollection
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "relevanceDataId" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.handleCheckboxChange },
                          model: {
                            value: _vm.dateCollectionAll,
                            callback: function ($$v) {
                              _vm.dateCollectionAll = $$v
                            },
                            expression: "dateCollectionAll",
                          },
                        },
                        _vm._l(_vm.dateCollectionList, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.id,
                              attrs: { value: item.id, label: item.id },
                            },
                            [_vm._v(_vm._s(item.fieldName))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("forms")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("forms")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }