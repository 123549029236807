var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-analyze-wrap" }, [
    _c(
      "div",
      { staticClass: "user-analyze-content", staticStyle: { padding: "0" } },
      [
        _c("div", { staticClass: "hyyy-top-tit" }, [
          _c("div", { staticClass: "hyyy-top-tit-lift" }, [
            _c("span", {
              staticStyle: { "font-weight": "400", color: "#333" },
              domProps: { textContent: _vm._s(_vm.$route.meta.title) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hyyy-top-tit-right" },
            [_c("admincut", { staticStyle: { "margin-top": "7px" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("el-divider", { staticStyle: { margin: "0" } }),
        _vm._v(" "),
        _c("div", { staticClass: "user-analyze-top" }, [
          _c(
            "div",
            {
              staticClass: "user-analyze-nav",
              staticStyle: { "font-size": "17px", "margin-top": "10px" },
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/wxgzh/dataAlysis/yhfx" } },
                [_vm._v("用户分析")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/wxgzh/dataAlysis/yhData" } },
                [_vm._v("用户数据")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "100%", padding: "20px" } },
          [
            _c("div", { staticClass: "user-label user-analyze" }, [
              _c("div", { staticClass: "user-title-wrap" }, [
                _c(
                  "h5",
                  [
                    _vm._v("\n            用户标签分析\n            "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-start",
                          title: "",
                          width: "400",
                          trigger: "hover",
                          content:
                            "根据用户标签进行统计的数据，分别为用户标签名称、用户标签下粉丝数，用户标签占比（用户标签占比=当前该用户标签下的粉丝数/当前总粉丝数。",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: {
                            "font-size": "20px",
                            color: "#e6a23c",
                          },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h5", [_vm._v("用户标签粉丝数统计")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-analyze-chart clearfix" }, [
                _c(
                  "div",
                  { staticClass: "user-pic-wrap" },
                  [_c("PieChart", { ref: "userTag" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.userTagList,
                          "header-cell-style": {
                            height: "20px",
                            "text-align": "center",
                            " border-bottom": "0px",
                            padding: "0px",
                          },
                          "row-style": { " border-bottom": "0px" },
                          "cell-style": {
                            height: "20px",
                            "text-align": "center",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "tagName", label: "用户标签名称" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "percent", label: "占比" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "count", label: "粉丝数" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-analyze clearfix" }, [
              _c("h4", [_vm._v("用户来源分析")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "time-wrap" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      align: "right",
                      "unlink-panels": "",
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.sourceDate,
                      callback: function ($$v) {
                        _vm.sourceDate = $$v
                      },
                      expression: "sourceDate",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.latelyFun("source", 30)
                        },
                      },
                    },
                    [_vm._v("最近30天")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.latelyFun("source", 90)
                        },
                      },
                    },
                    [_vm._v("最近3个月")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.latelyFun("source", 182)
                        },
                      },
                    },
                    [_vm._v("最近半年")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "user-analyze-chart" }, [
                _c(
                  "div",
                  { staticClass: "user-pic-wrap" },
                  [_c("PieChart", { ref: "userSourcePie" })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-source-bar" },
                  [_c("BarChartLeft", { ref: "userSourceBar" })],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-analyze clearfix" }, [
              _c("h4", [_vm._v("活跃用户时段分析")]),
              _vm._v(" "),
              _c("div", { staticClass: "time-wrap" }, [
                _vm.isLivelyDate
                  ? _c(
                      "div",
                      { staticClass: "time-top" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            size: "small",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            clearable: false,
                          },
                          model: {
                            value: _vm.livelyDate,
                            callback: function ($$v) {
                              _vm.livelyDate = $$v
                            },
                            expression: "livelyDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("lively", 30)
                              },
                            },
                          },
                          [_vm._v("最近30天")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("lively", 91)
                              },
                            },
                          },
                          [_vm._v("最近3个月")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("lively", 182)
                              },
                            },
                          },
                          [_vm._v("最近半年")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "time-bottom" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            size: "small",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            clearable: false,
                          },
                          model: {
                            value: _vm.livelyDate,
                            callback: function ($$v) {
                              _vm.livelyDate = $$v
                            },
                            expression: "livelyDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("  至  ")]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            size: "small",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.livelyContrastDate,
                            callback: function ($$v) {
                              _vm.livelyContrastDate = $$v
                            },
                            expression: "livelyContrastDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.isContrastFun(false, "lively")
                              },
                            },
                          },
                          [_vm._v("取消对比")]
                        ),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-analyze-chart1" },
                [
                  _c("lineChart", {
                    attrs: { "chart-data": _vm.LineChartData },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-analyze clearfix" }, [
              _c("h4", [_vm._v("取关用户分析")]),
              _vm._v(" "),
              _c("div", { staticClass: "time-wrap" }, [
                _vm.isCancelDate
                  ? _c(
                      "div",
                      { staticClass: "time-top" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            size: "small",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            clearable: false,
                          },
                          model: {
                            value: _vm.cancelDate,
                            callback: function ($$v) {
                              _vm.cancelDate = $$v
                            },
                            expression: "cancelDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("cancel", 30)
                              },
                            },
                          },
                          [_vm._v("最近30天")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("cancel", 91)
                              },
                            },
                          },
                          [_vm._v("最近3个月")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun("cancel", 182)
                              },
                            },
                          },
                          [_vm._v("最近半年")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "time-bottom" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            size: "small",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            clearable: false,
                          },
                          model: {
                            value: _vm.cancelDate,
                            callback: function ($$v) {
                              _vm.cancelDate = $$v
                            },
                            expression: "cancelDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("  至  ")]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            size: "small",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.cancelContrastDate,
                            callback: function ($$v) {
                              _vm.cancelContrastDate = $$v
                            },
                            expression: "cancelContrastDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.isContrastFun(false, "cancel")
                              },
                            },
                          },
                          [_vm._v("取消对比")]
                        ),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-analyze-chart1" },
                [_c("barChart", { attrs: { "chart-data": _vm.barChartData } })],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-title-wrap" }, [
      _c("h5", [_vm._v("用户来源统计")]),
      _vm._v(" "),
      _c("h5", [_vm._v("渠道二维码top10累计扫码人数统计")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-title-wrap" }, [
      _c("h5", [_vm._v("按取关用户停留时间分析")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }