var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  [
                    _c("div", { staticClass: "hyyy-top-tit" }, [
                      _c(
                        "div",
                        { staticClass: "hyyy-top-tit-lift" },
                        [
                          _c("span", {
                            staticStyle: {
                              "font-weight": "400",
                              color: "#333",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$route.meta.title),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "提示",
                                width: "500",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "菜单点击次数：在时间范围内的自定义菜单被点击的总次数。"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "菜单点击人数：在时间范围内的点击自定义菜单的用户总人数。"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "人均点击次数：在时间范围内的菜单点击次数除以菜单点击人数的值。"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-question",
                                staticStyle: {
                                  "font-size": "20px",
                                  color: "#e6a23c",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "hyyy-top-tit-right" },
                        [
                          _c("admincut", {
                            staticStyle: { "margin-top": "7px" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "hyyy-content" },
                      [
                        _c(
                          "el-main",
                          [
                            _vm.showSearch
                              ? _c(
                                  "div",
                                  { staticClass: "search-content" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "daterange",
                                        "range-separator": "-",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: { change: _vm.getDate },
                                      model: {
                                        value: _vm.value1,
                                        callback: function ($$v) {
                                          _vm.value1 = $$v
                                        },
                                        expression: "value1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { type: "text" },
                                        on: { click: _vm.getCurMonth },
                                      },
                                      [_vm._v("最近30天")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.showSearch
                              ? _c(
                                  "div",
                                  { staticClass: "search-content" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "inline-block" },
                                      [_vm._v("时间对比区间：")]
                                    ),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "daterange",
                                        "range-separator": "-",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: { change: _vm.setDate1 },
                                      model: {
                                        value: _vm.value1,
                                        callback: function ($$v) {
                                          _vm.value1 = $$v
                                        },
                                        expression: "value1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "inline-block" },
                                      [_vm._v("—")]
                                    ),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "daterange",
                                        "range-separator": "-",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: { change: _vm.setDate2 },
                                      model: {
                                        value: _vm.value2,
                                        callback: function ($$v) {
                                          _vm.value2 = $$v
                                        },
                                        expression: "value2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: { click: _vm.DoSearch },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: {
                                          type: "default",
                                          plain: "",
                                          size: "small",
                                        },
                                        on: { click: _vm.cancelComplit },
                                      },
                                      [_vm._v("取消对比")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showItems
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "display-items",
                                    attrs: { model: _vm.uncosData },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bordered-rt",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c("div", { staticClass: "item" }, [
                                              _c("div", [
                                                _c("i", {
                                                  staticClass:
                                                    "icon el-icon-star-on",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                  },
                                                }),
                                                _vm._v(" 菜单点击次数"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-num" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uncosData
                                                        .allMenuClickCount
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "bordered-rt",
                                            attrs: { span: 8 },
                                          },
                                          [
                                            _c("div", { staticClass: "item" }, [
                                              _c("div", [
                                                _c("i", {
                                                  staticClass:
                                                    "icon el-icon-user-solid",
                                                  staticStyle: {
                                                    color: "#67c23a",
                                                  },
                                                }),
                                                _vm._v(" 菜单点击人数"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-num" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uncosData
                                                        .allMenuClickUser
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          _c("div", { staticClass: "item" }, [
                                            _c("div", [
                                              _c("i", {
                                                staticClass:
                                                  "icon el-icon-user",
                                                staticStyle: {
                                                  color: "#409eff",
                                                },
                                              }),
                                              _vm._v(" 人均点击次数"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "item-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.uncosData
                                                      .allMenuClickAverage
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.showItems
                              ? _c(
                                  "div",
                                  { staticClass: "display-tables" },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          data: _vm.tableData,
                                          border: "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "dateTime",
                                            label: "时间",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "allMenuClickCount",
                                            label: "菜单点击次数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "allMenuClickUser",
                                            label: "菜单点击人数",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "allMenuClickAverage",
                                            label: "人均点击次数",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-menu",
                              {
                                staticClass: "el-menu-demo",
                                attrs: {
                                  "default-active": _vm.activeIndex,
                                  mode: "horizontal",
                                },
                                on: { select: _vm.handleSelect },
                              },
                              [
                                _c("el-menu-item", { attrs: { index: "0" } }, [
                                  _vm._v("菜单点击次数"),
                                ]),
                                _vm._v(" "),
                                _c("el-menu-item", { attrs: { index: "1" } }, [
                                  _vm._v("菜单点击人数"),
                                ]),
                                _vm._v(" "),
                                _c("el-menu-item", { attrs: { index: "2" } }, [
                                  _vm._v("人均点击次数"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "echarts" },
                              [
                                _c("line-chart", {
                                  attrs: { "chart-data": _vm.lineChartData },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "menu-details" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("菜单数据详情"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search-content" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "value-format": "yyyy-MM-dd",
                                        type: "daterange",
                                        "range-separator": "-",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      on: { change: _vm.getChangeMenu },
                                      model: {
                                        value: _vm.menuValue,
                                        callback: function ($$v) {
                                          _vm.menuValue = $$v
                                        },
                                        expression: "menuValue",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.downLoading,
                                            expression: "downLoading",
                                          },
                                        ],
                                        staticStyle: { "font-size": "14px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: { click: _vm.downLoad },
                                      },
                                      [_vm._v(_vm._s(_vm.downLoadTxt))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.menuData,
                                      border: "",
                                      height: "400",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { prop: "version", label: "版本" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "oneMenu",
                                        label: "一级菜单",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "twoMenu",
                                        label: "二级菜单",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "allMenuClickCount",
                                        label: "菜单点击次数",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "allMenuClickUser",
                                        label: "菜单点击人数",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "allMenuClickAverage",
                                        label: "人均点击次数",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }