var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择产品分类",
        visible: _vm.proSelVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.proSelVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "calc(100% - 300px)",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.searchForm,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品分类名称",
                                        prop: "productClassName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value:
                                            _vm.searchForm.productClassName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "productClassName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "searchForm.productClassName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          ref: "multipleTable",
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.tableData,
                            height: "400px",
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "row-key": "pId",
                            "highlight-current-row": "",
                            "default-expand-all": "",
                            "tree-props": {
                              children: "children",
                              hasChildren: "hasChildren",
                            },
                          },
                          on: {
                            select: _vm.select,
                            "select-all": _vm.selectAll,
                            "selection-change": _vm.selectionChange,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              "reserve-selection": true,
                              width: "50",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClassName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类编码",
                              prop: "productClassCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page-section leftSide" }, [
                _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { height: "527px" },
                        attrs: { gutter: 10 },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.selLoading,
                                expression: "selLoading",
                              },
                            ],
                            staticStyle: { width: "100%", "margin-top": "1px" },
                            attrs: {
                              data: _vm.multipleSelection,
                              fit: "",
                              height: "100%",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "已选产品",
                                "min-width": "100",
                                prop: "productClassName",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.productClassName) +
                                            "\n                    " +
                                            _vm._s(
                                              "(" +
                                                scope.row.productClassCode +
                                                ")"
                                            )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                width: "60",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-button", {
                                        staticStyle: { color: "#99a3ad" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-delete-solid",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.tag1Close(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.selCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }