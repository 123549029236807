"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _memberManage = require("@/api/market/memberManage");
var _dealer = require("@/api/basic/dealer");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _detail = _interopRequireDefault(require("@/views/marketingManage/memberManage/detail"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageBlackListindex';
var _default = {
  name: 'MarketingManageBlackListindex',
  components: {
    Pagination: _Pagination.default,
    Detail: _detail.default
  },
  filters: {
    status: function status(index) {
      var comtype = {
        0: '正常',
        1: '冻结'
      };
      var result = comtype[index];
      return result;
    },
    birthday: function birthday(index) {
      if (!index) return '';
      return index.split(' ')[0];
    }
  },
  data: function data() {
    return {
      areaList: [],
      areaInUseList: [],
      tabHeight: '100%',
      list: null,
      integral: '',
      memberId: '',
      type: '',
      listLoading: true,
      total: 0,
      searchForm: {
        nickName: '',
        appid: '',
        phone: '',
        pageNum: 1,
        pageSize: 20,
        status: '',
        startTime: '',
        endTime: '',
        isBlacklist: 1,
        area: []
      },
      dialogVisible: false,
      detailVisible: false,
      ruleForm: {
        type: '1',
        num: ''
      },
      rules: {
        num: [{
          required: true,
          message: '积分不能为空',
          trigger: 'blur'
        }]
      },
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
        that.getAreaAll();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getSoldExcel: function getSoldExcel() {
      this.authId('importbtn', this.apis);
      var submitData = (0, _utils.filterKeyNull)(this.searchForm);
      submitData.size = 2147483647;
      (0, _memberManage.memberExcel)(submitData).then(function (data) {
        var blob = new Blob([data.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        var fileName = '黑名单信息列表.xlsx';
        var elink = document.createElement('a');
        if ('download' in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    jumpLink: function jumpLink(name) {
      this.authId('prizerecord', this.apis);
      this.$router.push({
        path: '/marketingManage/awardManage/winningRecord/index',
        query: {
          userName: name
        }
      });
    },
    todelBlacklist: function todelBlacklist(id, type) {
      var _this = this;
      this.authId('deletebtn', this.apis);
      (0, _memberManage.delBlacklist)({
        memberId: id
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this.fetchData();
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: ''
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
            console.log(that.areaList, 999);
          } else {
            that.areaList = [];
          }
        }
      });
    },
    getAreaInUse: function getAreaInUse() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaInUseList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaInUseList = [];
          }
        }
      });
    },
    close: function close() {
      this.type = '';
      this.memberId = '';
      this.detailVisible = false;
    },
    // 查询
    fetchData: function fetchData() {
      var _this2 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      // console.log(that.area);
      if (this.searchForm.area) {
        this.searchForm.provinceId = this.searchForm.area[0];
        this.searchForm.cityId = this.searchForm.area[1] || '';
        this.searchForm.countyId = this.searchForm.area[2] || '';
      }
      // console.log(this.searchForm);
      (0, _memberManage.getList)(this.searchForm).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this2.list = res.data.list;
          _this2.total = res.data.total;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    changeIntegral: function changeIntegral(val) {
      this.dialogVisible = true;
    },
    subIntegral: function subIntegral() {
      //  接口
      this.restForm('ruleForm');
      this.dialogVisible = false;
    },
    cancel: function cancel() {
      this.restForm('ruleForm');
      this.dialogVisible = false;
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    changeStatus: function changeStatus(id, type) {
      var _this3 = this;
      (0, _memberManage.freezeMember)({
        memberId: id,
        status: type,
        moduleId: this.$route.meta.moduleId,
        proId: this.$route.meta.proId
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this3.fetchData();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    detail: function detail(row, status) {
      if (status == 'show') {
        this.authId('scanbtn', this.apis);
      }
      if (status == 'updata') {
        this.authId('updatebtn', this.apis);
      }
      this.memberId = row.memberId;
      this.integral = row.integral;
      this.type = status;
      this.detailVisible = true;
    }
  }
};
exports.default = _default;