var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "企业角色-权限分配",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: { "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.authData.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.authData, "roleName", $$v)
                  },
                  expression: "authData.roleName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("tree-transfer", {
        ref: "js-tree-transfer",
        attrs: {
          title: _vm.title,
          from_data: _vm.fromData,
          to_data: _vm.toData,
          "default-props": _vm.defaultProps,
          "default-checked-keys": _vm.authData.defaultCheckedKeys,
          "default-transfer": "",
          "transfer-open-node": false,
          mode: _vm.mode,
          height: "400px",
          filter: "",
          node_key: "id",
        },
        on: {
          "add-btn": _vm.add,
          "remove-btn": _vm.remove,
          "right-check-change": _vm.rightCheckChange,
          "left-check-change": _vm.leftCheckChange,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.dstate },
              on: { click: _vm.submitForm },
            },
            [_vm._v("确定")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.resetForm } }, [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }