"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _org = require("@/api/basic/org");
var _factory = require("@/api/basic/factory");
var _stock = require("@/api/warehouse/stock/stock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "TableSuply",
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    Treeselect: _vueTreeselect.default
  },
  // filters: {
  //   TypeDataCom(code, keyName) {
  //     var vars = TypeDataComs[keyName]
  //     var name = ''
  //     vars.forEach(function(c) {
  //       if (c.code == code) {
  //         name = c.name
  //         return
  //       }
  //     })
  //     return name
  //   }
  // },
  data: function data() {
    return {
      productName: "",
      showCol: false,
      TypeDataComs: {
        factoryList: [],
        OrgList: [],
        StoreList: []
      },
      orgOptions: [],
      tableData: [],
      searchForm: {
        orgId: null,
        createDate: "",
        productName: "",
        storeId: "",
        current: 1,
        size: 20
      },
      tableKey: "rn",
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      tabHeight: "100%"
    };
  },
  activated: function activated() {
    this.authId("searchbtn", this.$route.meta.authList);
    this.getorgList();
    this.getStoreList();
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    reset: function reset() {
      this.searchForm = {
        orgId: null,
        createDate: "",
        productName: "",
        storeId: "",
        current: 1,
        size: 20
      };
      this.getList();
    },
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.searchForm.productCode = "";
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.searchForm.productName = val.pId;
    },
    getorgList: function getorgList() {
      var _this = this;
      var that = this;
      this.TypeDataComs.OrgList = [];
      (0, _org.fetchOrgList)({
        orgCodeAndName: "",
        orgStatus: ""
      }).then(function (res) {
        if (res.data.code != 200) {
          // return
        } else {
          console.log(res, "res");
          var rData = res.data.data;
          console.log(rData, "rData");
          var arr = [];
          _this.TypeDataComs.OrgList = res.data.data;
          if (rData.length >= 0) {
            console.log(12346);
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, "pid", "parentPid", "children");
            console.log(_this.orgOptions, "orgOptions");
          }
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this2 = this;
      // 获取库房下拉列表数据
      this.TypeDataComs.StoreList = [];
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this2.TypeDataComs.StoreList = res.data.data;
          console.log(_this2.TypeDataComs.StoreList, 987);
        }
      }).catch(function () {});
    },
    handleFilter: function handleFilter(data) {
      this.searchForm.current = 1;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _stock.queryearlyEnd)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    // 导出功能
    download: function download() {
      var _this4 = this;
      this.authId("exportbtn", this.$route.meta.authList);
      this.downLoading = true;
      this.downLoading = true;
      var param = {};
      param.orgId = this.searchForm.orgId;
      param.createDate = this.searchForm.createDate;
      param.productName = this.searchForm.productName;
      param.storeId = this.searchForm.storeId;
      (0, _stock.exportQcQmData)(param).then(function (res) {
        _this4.authId("searchbtn", _this4.$route.meta.authList);
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "期初期末库存清单.xls";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId("searchbtn", _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    }
  }
};
exports.default = _default;