var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "添加领料",
            visible: _vm.dialogVisible,
            width: "1000px !important",
            "before-close": _vm.close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.add },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.addBtn.text) + "原料")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                      loading: _vm.downLoading,
                    },
                    on: { click: _vm.download },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text) + "领料单")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "300px",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "60",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "原料名称",
                  prop: "materialName",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "批次号",
                  prop: "batchNo",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "投产重量(kg)",
                  prop: "productionWeight",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "领用重量(kg)",
                  prop: "claimWeight",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "退回重量(kg)",
                  prop: "returnWeight",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "领取人",
                  prop: "creatorName",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("creatorName")(row.creatorName)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "时间",
                  prop: "createTime",
                  align: _vm.tableConfig.align,
                  "min-width": "160",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("textDate")(row.createTime)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total >= 0,
                expression: "total >= 0",
              },
            ],
            attrs: {
              layout: "total,sizes,prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.current,
              limit: _vm.size,
            },
            on: {
              "update:page": function ($event) {
                _vm.current = $event
              },
              "update:limit": function ($event) {
                _vm.size = $event
              },
              pagination: _vm.getList,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "原料添加",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.cancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原料名称", prop: "materialName" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择原料名称" },
                      on: { change: _vm.materialChange },
                      model: {
                        value: _vm.formData.materialName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "materialName", $$v)
                        },
                        expression: "formData.materialName",
                      },
                    },
                    _vm._l(_vm.materialList, function (item) {
                      return _c("el-option", {
                        key: item.MaterialId,
                        attrs: { label: item.MaterialName, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次", prop: "batchNo" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择批次" },
                      on: { change: _vm.batchNoChange },
                      model: {
                        value: _vm.formData.batchNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "batchNo", $$v)
                        },
                        expression: "formData.batchNo",
                      },
                    },
                    _vm._l(_vm.batchNoList, function (item) {
                      return _c("el-option", {
                        key: item.BatchNo,
                        attrs: { label: item.BatchNo, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "原料重量", prop: "materialWeight" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData.materialWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "materialWeight", $$v)
                        },
                        expression: "formData.materialWeight",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("kg")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "本次领取", prop: "claimWeight" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "age" },
                      on: { input: _vm.claim },
                      model: {
                        value: _vm.formData.claimWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "claimWeight", _vm._n($$v))
                        },
                        expression: "formData.claimWeight",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("kg")])],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("formData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.dialogFormVisible2,
            "before-close": _vm.cancel2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData2",
              attrs: {
                model: _vm.formData2,
                rules: _vm.rules2,
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原料名称", prop: "materialName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formData2.materialName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData2, "materialName", $$v)
                      },
                      expression: "formData2.materialName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "批次", prop: "batchNo" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formData2.batchNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData2, "batchNo", $$v)
                      },
                      expression: "formData2.batchNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "原料重量", prop: "batchAmount" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData2.batchAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData2, "batchAmount", $$v)
                        },
                        expression: "formData2.batchAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("kg")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效库存", prop: "materialWeight" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData2.materialWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData2, "materialWeight", $$v)
                        },
                        expression: "formData2.materialWeight",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("kg")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编辑", prop: "operateType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData2.operateType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData2, "operateType", $$v)
                        },
                        expression: "formData2.operateType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "领料退回", value: "3" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "添加领料", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData2.operateType == "3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "重量", prop: "returnWeight" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: "age" },
                          on: { input: _vm.claim2 },
                          model: {
                            value: _vm.formData2.returnWeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData2,
                                "returnWeight",
                                _vm._n($$v)
                              )
                            },
                            expression: "formData2.returnWeight",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("kg")])],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formData2.operateType == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "重量", prop: "claimWeight" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: "age" },
                          on: { input: _vm.claim2 },
                          model: {
                            value: _vm.formData2.claimWeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData2,
                                "claimWeight",
                                _vm._n($$v)
                              )
                            },
                            expression: "formData2.claimWeight",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("kg")])],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel2 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm2("formData2")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }