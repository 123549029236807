"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
var _wxmake = require("@/api/wxhyyy/wxmake");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */
// import admincut from '@/components/admincut'

var baseUrl = '/wh';
var _default = {
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    },
    typemarke1: function typemarke1(status) {
      var statusMap = {
        0: '计划开始后立即发送',
        1: '定时发送',
        2: '计划开始后每天',
        3: '计划开始后每周',
        4: '计划开始后每月'
      };
      return statusMap[status];
    },
    typemarke12: function typemarke12(status) {
      var statusMap = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(status) {
      var statusMap = {
        0: '未开始',
        1: '进行中',
        2: '已暂停',
        3: '已结束'
      };
      return statusMap[status];
    },
    typemarke: function typemarke(status) {
      var statusMap = {
        0: '发红包',
        1: '送积分',
        2: '发短信'
      };
      return statusMap[status];
    },
    typeFilterr: function typeFilterr(status) {
      var statusMap = {
        0: '指定标签',
        1: '所有客户',
        2: '指定会员',
        3: '所有积分用户',
        4: '所有公众号用户',
        5: '导入用户'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      condlist: [],
      tablehead: [],
      total: 0,
      xgtask: false,
      tableData1: [],
      imdiavip: false,
      infodia: false,
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      fstpUrl: '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      levdata: [{
        name: '未开始',
        value: '0'
      }, {
        name: '进行中',
        value: '1'
      }, {
        name: '已暂停',
        value: '2'
      }, {
        name: '已结束',
        value: '3'
      }],
      vipload: false,
      tableData: [],
      formInline: {
        planName: '',
        planStatus: '',
        current: 1,
        size: 10
      },
      value: '',
      value2: '',
      selectlist: '',
      infopro: '',
      xglist: [],
      msglist: [],
      mblist: [],
      mbname: '',
      prostatus: []
    };
  },
  created: function created() {
    var that = this;
    that.getquery(that.formInline);
    that.getmsgcode();
  },
  methods: {
    exChang: function exChang(row, rows) {
      var _this = this;
      var that = this;
      console.log(row);
      console.log(rows);
      var a;
      var b;
      var itemList = row.itemList;
      itemList.forEach(function (v) {
        if (v.smsSource) {
          a = v.smsSource;
          b = v.templateCode * 1;
        }
      });
      if (a) {
        (0, _wxyyKhlb.getmsgmbList)(a).then(function (res) {
          var res = res.data;
          console.log(res);
          if (res.code === 200) {
            if (res.data && res.data.length > 0) {
              that.mblist = res.data;
              that.mblist.forEach(function (v) {
                if (v.id === b) {
                  that.mbname = v.name;
                }
              });
            }
          } else {
            _this.$message.warning(res.msg);
          }
        });
      }
    },
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      this.$confirm('您确定要删除吗？', '删除操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wxmake.plandelete)({
          id: id
        }).then(function (res) {
          var res = res.data;
          _this2.$message.success(res.msg);
        });
        clearTimeout(tmo);
        var tmo = setTimeout(function () {
          _this2.getquery(_this2.formInline);
        }, 1000);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    getmsgcode: function getmsgcode() {
      var _this3 = this;
      (0, _wxyyKhlb.getmsgList)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.length > 0 && res.data) {
            _this3.msglist = res.data;
          } else {
            return false;
          }
        } else {
          return _this3.$message.info(res.msg);
        }
      });
    },
    getonOrOff: function getonOrOff(rows, val, index) {
      var _this4 = this;
      if (rows.planStatus === '0' || rows.planStatus === '3') {
        this.$message.error('未开始/已结束的活动不能进行上下架操作！');
        // this.prostatus[index].dis = true
      } else {
        (0, _wxmake.onOrOff)({
          id: val
        }).then(function (res) {
          var res = res.data;
          _this4.$message(res.msg);
          _this4.getquery(_this4.formInline);
          // this.$router.go(0)
        });
      }
    },
    getquery: function getquery(val) {
      var _this5 = this;
      this.vipload = true;
      (0, _wxmake.queryForPage)(val).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records && res.data.records.length > 0) {
            _this5.tableData = res.data.records;
            _this5.prostatus = [];
            _this5.tableData.forEach(function (v) {
              if (v.planStatus === '1') {
                _this5.prostatus.push({
                  status: true,
                  dis: false
                });
              } else if (v.planStatus === '2') {
                _this5.prostatus.push({
                  status: false,
                  dis: false
                });
              } else {
                _this5.prostatus.push({
                  status: false,
                  dis: true
                });
              }
            });
          }
          _this5.total = res.data.total;
        } else {
          _this5.$message(res.msg);
        }
        _this5.vipload = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.vipload = true;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.current = val;
      this.getquery(this.formInline);
    },
    // 上传图片
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code === 200) {
        this.$message.info(response.data);
        this.getlistcreat();
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    imgUploaderr: function imgUploaderr(err, file, fileList) {
      // if(file.code)
      this.$message.error(err.msg);
    },
    // 下载模板
    downmuban: function downmuban() {
      var that = this;
      (0, _wxHy.downtemplate)().then(function (res) {
        var res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '模板.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 列表详情
    handleinfo: function handleinfo(val, type) {
      if (type === '0') {
        this.$router.push({
          name: 'wxAccountsmarketingActivitymkPlanplanListmkedit',
          query: {
            id: '',
            type: type
          }
        });
      } else if (type === '1') {
        this.$router.push({
          name: 'wxAccountsmarketingActivitymkPlanetStatisindex',
          query: {
            id: val
          }
        });
      } else if (type === '2') {
        // 编辑
        this.$router.push({
          name: 'wxAccountsmarketingActivitymkPlanplanListmkedit',
          query: {
            id: val,
            type: type
          }
        });
      } else {
        // 复制
        this.$router.push({
          name: 'wxAccountsmarketingActivitymkPlanplanListmkedit',
          query: {
            id: val,
            type: type
          }
        });
      }
    },
    // 导入会员
    imvip: function imvip() {
      this.imdiavip = true;
      console.log('1');
    },
    // 下载明细
    download: function download() {
      var that = this;
      (0, _wxHy.downdetail)().then(function (res) {
        var res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 修改任务值
    onSubmitt: function onSubmitt() {
      if (this.selectlist.length === 0) {
        this.$message.warning('请选择一行数据进行修改');
        return;
      }
      this.selectlist.forEach(function (i) {
        i.flag = 2;
        i.createTime = i.createTime + '.';
      });
      console.log(this.selectlist);
      this.xgtask = true;
    },
    // 修改任务值提交
    xgbtn: function xgbtn() {
      var _this6 = this;
      var n = [];
      this.selectlist.forEach(function (el) {
        var a = {
          id: el.id,
          taskValue: el.taskValue
        };
        n.push(a);
      });
      (0, _wxgzh.updateTaskById)(n).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          _this6.$message.success('修改成功');
        } else {
          _this6.$message.warning(res.msg);
          return false;
        }
      });
      this.$refs.multipleTable.clearSelection();
      this.xgtask = false;
      this.getlistcreat();
      this.$router.go(0);
    },
    qxbtn: function qxbtn() {
      var that = this;
      that.selectlist.forEach(function (i) {
        i.flag = 1;
        i.createTime = i.createTime.substring(0, i.createTime.length - 1);
      });
      that.$refs.multipleTable.clearSelection();
      that.xgtask = false;
      that.getlistcreat();
    },
    // 重置
    onReset: function onReset() {
      this.formInline = {
        planName: '',
        planStatus: '',
        current: 1,
        size: 10
      };
    },
    onSubmit: function onSubmit(val) {
      var that = this;
      that.vipload = true;
      var a = {};
      Object.assign(a, val);
      a.current = 1;
      if (that.value2 !== '') {
        a.startTime = that.value2[0];
        a.endTime = that.value2[1];
      }
      that.getquery(a);
    },
    getlistcreat: function getlistcreat(val) {
      var _this7 = this;
      var a = val || '';
      this.vipload = true;
      this.formInline.pageNo = 1;
      (0, _wxHy.getlistsearch)(a).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this7.tableData = res.data.records;
          _this7.tablehead = [{
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }];
          _this7.total = res.data.total;
          _this7.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this7.total = res.data.total;
          console.log(_this7.tableData);
        }
        _this7.vipload = false;
      });
    },
    // 表格选中操作
    // handleSelection(selection, row) {
    //   console.log(selection)
    // },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectlist = val;
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
    }
  }
};
exports.default = _default;