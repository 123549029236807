"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditReviewNoPass = auditReviewNoPass;
exports.auditReviewPass = auditReviewPass;
exports.bindingMac = bindingMac;
exports.changedList = changedList;
exports.clientAuthAuditSearch = clientAuthAuditSearch;
exports.clientAuthCodeSerach = clientAuthCodeSerach;
exports.detail = detail;
exports.generateAuthCode = generateAuthCode;
exports.modDownFlag = modDownFlag;
exports.resetMac = resetMac;
exports.search = search;
var _request = _interopRequireDefault(require("@/utils/request"));
function search(query) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/search',
    method: 'get',
    params: query
  });
}
function bindingMac(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/bindingMac',
    method: 'post',
    data: data
  });
}
function resetMac(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/resetMac',
    method: 'post',
    data: data
  });
}
function detail(params) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/detail',
    method: 'GET',
    params: params
  });
}
function changedList(params) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/changedList',
    method: 'GET',
    params: params
  });
}
function clientAuthAuditSearch(query) {
  return (0, _request.default)({
    url: '/api-mark/clientAuthAudit/search',
    method: 'get',
    params: query
  });
}
function auditReviewPass(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuthAudit/reviewPass',
    method: 'post',
    data: data
  });
}
function auditReviewNoPass(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuthAudit/reviewNoPass',
    method: 'post',
    data: data
  });
}
function clientAuthCodeSerach(params) {
  return (0, _request.default)({
    url: '/api-mark/clientAuthCode/serach',
    method: 'GET',
    params: params
  });
}
function generateAuthCode(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuthCode/generateAuthCode',
    method: 'post',
    data: data
  });
}
function modDownFlag(data) {
  return (0, _request.default)({
    url: '/api-mark/clientAuth/modDownFlag',
    method: 'post',
    params: data
  });
}