"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _channel = require("@/api/basic/channel");
var _outstock = require("@/api/chanelmange/outstock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    personVisible: Boolean
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      orgOriginData: [],
      eachTxt: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 10,
        keyWord: '',
        orgId: ''
      },
      tableData: [],
      total: 0,
      props: {
        label: 'label',
        children: 'children',
        isLeaf: 'leaf'
      },
      classList: [],
      normalList: [],
      brandList: [],
      packList: [],
      uploadForm: {
        isFuGai: '',
        uploadFile: ''
      },
      uploadRules: {},
      fileList: [],
      multipleSelection: [],
      activtyId: '',
      normalId: ''
    };
  },
  watch: {
    personVisible: function personVisible(val) {
      var that = this;
      if (val == true) {
        var param = {
          orgCodeAndName: '',
          orgStatus: 1
        };
        that.getClass(param);
      }
    }
  },
  methods: {
    // 获取组织机构列表
    //
    getClass: function getClass(param) {
      var that = this;
      (0, _outstock.getQueryOrg)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.classList = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
            that.normalId = that.classList[0].pid;
            that.searchForm.orgId = that.classList[0].pid;
          }
        }
        return true;
      }).then(function (res) {
        that.getList();
      });
    },
    // 过滤
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleFilter: function handleFilter() {
      var that = this;
      var param = {
        orgCodeAndName: that.filterText,
        orgStatus: 1
      };
      that.getClass(param);
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      this.listLoading = true;
      that.searchForm.orgId = that.searchForm.orgId || that.normalId;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _channel.getCompanyUserList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this.listLoading = false;
      });
    },
    QueryForm: function QueryForm() {
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchForm.keyWord = '';
      this.searchForm.current = 1;
      this.getList();
    },
    scan: function scan(data) {
      var that = this;
      that.searchForm.orgId = data.id;
      that.getList();
    },
    handleClose: function handleClose() {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.keyWord = '';
      that.searchForm.orgId = '';
      that.filterText = '';
      that.$emit('dialogHide');
    },
    DoSelect: function DoSelect(row) {
      var that = this;
      that.$emit('select', row);
    }
  }
};
exports.default = _default;