"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PurchaseDialog',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '公司',
        1: '经销商',
        2: '终端',
        3: '团购'
      };
      return statusMap[status];
    }
  },
  props: {
    selectedData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    amount: {
      type: Number,
      default: 0
    },
    type: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        current: 1,
        selectChannelType: '',
        scope: 1,
        size: 40,
        archivesStatus: 1,
        channelType: '',
        keyword: ''
      },
      tableData: [],
      multipleSelection: [],
      total: 0,
      channelType: 1
    };
  },
  mounted: function mounted() {
    console.log(Boolean(this.type));
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.selectedData.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      if (this.selectedData.length > 0) {
        this.searchForm.channelType = this.selectedData[0].channelType;
        this.channelType = this.selectedData[0].channelType;
      }
      this.getList();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        _this.selLoading = false;
      }, 500);
    },
    // 获取收取
    getList: function getList() {
      var _this2 = this;
      var that = this;
      this.listLoading = true;
      // if (this.type) {
      //   this.searchForm.channelType = this.type
      //   this.channelType = this.type
      // }
      (0, _channel.selectChannelAndOrg)((0, _objectSpread2.default)({}, this.searchForm)).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (_this2.channelType !== _this2.searchForm.channelType) {
            _this2.clearFun();
          }
          _this2.channelType = _this2.searchForm.channelType;
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
          if (_this2.selectedData.length) {
            _this2.multipleSelection = _this2.data;
          }
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    // 清空
    clearFun: function clearFun() {
      this.$refs.multipleTable.clearSelection();
    },
    // 重置
    resetForm: function resetForm(formName) {
      this.channelType = 1;
      this.searchForm = {
        current: 1,
        selectChannelType: '',
        scope: 1,
        size: 40,
        archivesStatus: 1,
        channelType: 1,
        keyword: ''
      };
      this.getList();
    },
    // 选择
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 取消
    selCancel: function selCancel() {
      // this.resetForm()
      this.clearFun();
      this.$emit('close');
    },
    // 提交
    submit: function submit() {
      if (this.amount < 2) {
        this.$emit('change', this.multipleSelection, this.channelType);
        this.selCancel();
      } else {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: 'error',
            message: '供货单位已选择多个，采购单位只能选择一个'
          });
        } else {
          this.$emit('change', this.multipleSelection, this.channelType);
          this.selCancel();
        }
      }
    },
    // 删除已选
    deleteItem: function deleteItem(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;