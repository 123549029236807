var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "echart-items" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 15 } }, [
            _c("div", { staticClass: "echart-container" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "timg",
                          attrs: { src: require("../../../../images/t3.png") },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("销售额趋势"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "eicon-small",
                          attrs: {
                            src: require("../../../../images/eicon-2.png"),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              size: "small",
                            },
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "range-txt" }, [_vm._v("至")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              size: "small",
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cell-ft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("近7日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("近30日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("近12月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "trend",
                staticClass: "chart",
                attrs: { id: "echarts_id" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "echart-container" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "timg",
                          attrs: { src: require("../../../../images/t4.png") },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("终端销售排行榜"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("img", {
                          staticClass: "eicon-small",
                          attrs: {
                            src: require("../../../../images/eicon-2.png"),
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cell-ft",
                      staticStyle: { "padding-top": "6px" },
                    },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("近7日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("近30日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("近12月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c(
                    "el-table",
                    { staticStyle: {}, attrs: { data: _vm.list, stripe: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "排名",
                          width: "80",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticStyle: { height: "39px" } }, [
                                  scope.$index == 0
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "rimg",
                                          attrs: {
                                            src: require("../../../../images/r1.png"),
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]),
                                      ])
                                    : scope.$index == 1
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "rimg",
                                          attrs: {
                                            src: require("../../../../images/r2.png"),
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]),
                                      ])
                                    : scope.$index == 2
                                    ? _c("div", [
                                        _c("img", {
                                          staticClass: "rimg",
                                          attrs: {
                                            src: require("../../../../images/r3.png"),
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]),
                                      ])
                                    : _c("div", [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]),
                                      ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "channelName",
                          label: "渠道",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "percent1",
                          label: "占比",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cell",
                                    staticStyle: {
                                      width: "130px",
                                      padding: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "2px" },
                                      },
                                      [_vm._v(_vm._s(row.percent1) + "%")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "80px" } },
                                      [
                                        _c("el-progress", {
                                          attrs: {
                                            "show-text": false,
                                            "stroke-width": 12,
                                            percentage: Number(row.percent1),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "percent2",
                          label: "环比",
                          width: "80",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.isAdd == 1
                                  ? _c("div", [
                                      _c("span", { staticClass: "percent" }, [
                                        _vm._v(
                                          _vm._s(Number(row.percent2)) + "% "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "rimg",
                                        attrs: {
                                          src: require("../../../../images/arrowUp1.png"),
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.isAdd == 0
                                  ? _c("div", [
                                      _c("span", { staticClass: "percent" }, [
                                        _vm._v(
                                          _vm._s(parseInt(row.percent2)) + "% "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "rimg",
                                        attrs: {
                                          src: require("../../../../images/arrowDn1.png"),
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }