var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      class: { hide: _vm.fileList.length >= _vm.limitCount || _vm.disabled },
      attrs: {
        headers: _vm.myHeaders,
        action: _vm.uploadUrl,
        accept: ".mp3",
        "on-success": _vm.handleAvatarSuccess,
        "on-error": _vm.handleError,
        "on-change": _vm.selectImg,
        "on-remove": _vm.handleRemove,
        "before-upload": _vm.beforeAvatarUpload,
        "before-remove": _vm.checkType,
        "file-list": _vm.fileList,
        name: "file",
        limit: _vm.limitCount,
      },
    },
    [
      _c("el-button", { attrs: { size: "small", type: "primary" } }, [
        _vm._v("点击上传"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }