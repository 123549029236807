"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _index = require("@/api/warehouse/checkout/index");
var _store = require("@/api/store");
var _dictionary = require("@/api/systems/dictionary");
var _factory = require("@/api/basic/factory");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {},
  filters: {},
  data: function data() {
    return {
      title: '',
      failList: [],
      successList: [],
      successCount: '',
      failCount: '',
      closedCount: '',
      doneCount: '',
      billType: '',
      arrangetype: '',
      outComName: '',
      inComName: '',
      inUseName: '',
      tableKey: 'pId',
      listLoading: false,
      factoryShow: false,
      justShow: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      scanData1: {
        boxCollect: '',
        orderNos: '',
        scheduleChestSum: '',
        shopCodes: [],
        traceType: '',
        number: '',
        shengnumber: ''
      },
      disabled: {
        num: false
      },
      orgList: [],
      orderList: [],
      piList: [],
      storeList: [],
      productList: [],
      warningdialog: false,
      detailinfo: {},
      traceList: [],
      rules: {
        boxCollect: [{
          required: true,
          message: '采集箱码不能为空，请维护',
          trigger: 'change'
        }],
        traceType: [{
          required: true,
          message: '政府追溯不能为空，请维护',
          trigger: 'change'
        }],
        shopCodes: [{
          required: true,
          message: '排产车间不能为空，请维护',
          trigger: 'change'
        }],
        scheduleChestSum: [{
          required: true,
          message: '排产组数不能为空，请维护',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    forceupdate: function forceupdate() {
      this.$forceUpdate();
    },
    number: function number(e) {
      var _value = e.target.value; // 获取到输入框的value值
      var reg = isNaN(_value); // 判断拿到的value是否为数字
      if (reg) {
        // 若为非数字键，则全部替换为空
        e.target.value = _value.replace(/[^1-9]/g, '');
      }
      if (_value === '0') {
        // 若为0，则替换为空
        e.target.value = _value.replace(/^[^1-9]/g, '');
      }
    },
    show: function show(row, type) {
      var _this = this;
      this.arrangetype = type;
      if (this.arrangetype == 'N') {
        this.detailinfo = JSON.parse(JSON.stringify(row));
        this.title = '排产';
        this.scanData1.orderNos = row.orderNo;
      } else if (this.arrangetype == 'Y') {
        this.piList = JSON.parse(JSON.stringify(row));
        this.title = '批量排产';
        var arr = [];
        this.scanData1.orderNos = row.forEach(function (el) {
          arr.push(el.orderNo);
        });
        this.scanData1.orderNos = arr.toString();
      } else if (this.arrangetype == 'E') {
        this.title = '编辑排产';
        this.detailinfo = JSON.parse(JSON.stringify(row));
        this.storeList = [];
        this.scanData1 = JSON.parse(JSON.stringify(row));
        if (row.shopCodes) {
          this.scanData1.shopCodes = row.shopCodes.split(',');
        }
        this.scanData1.traceType = Number(row.traceCode);
        this.$set(this.scanData1, 'number', Number(row.scheduleChestSum) * Number(this.detailinfo.coefficient));
      }
      (0, _factory.getWorkshopByFactoryCode)({
        factoryCode: row instanceof Array == true ? row[0].factoryCode : row.factoryCode
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this.storeList = response.data.data || [];
          } else {
            _this.storeList = [];
          }
        }
      });
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'ENTERPRISE_TYPE'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.traceList = res.data.data;
          } else {
            _this.traceList = [];
          }
        }
      });
      this.detailinfo.noarragezushu = Number(this.detailinfo.noarrange) / Number(this.detailinfo.coefficient);
      this.scanData1.shengnumber = this.detailinfo.noarrange;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = false;
    },
    changezushu: function changezushu(e) {
      if (this.arrangetype == 'E') {
        if (Number(e) > Number(this.detailinfo.noarragezushu) + Number(this.detailinfo.scheduleChestSum)) {
          this.$message.warning('排产组数不能大于未排产组数');
          this.scanData1.scheduleChestSum = '';
          return false;
        }
        this.$set(this.scanData1, 'number', e * this.detailinfo.coefficient);
        this.scanData1.shengnumber = Number(this.detailinfo.backBottleSum) - Number(this.scanData1.number);
      } else {
        if (Number(e) > Number(this.detailinfo.noarragezushu)) {
          this.$message.warning('排产组数不能大于未排产组数');
          this.scanData1.scheduleChestSum = '';
          return false;
        }
        this.$set(this.scanData1, 'number', e * this.detailinfo.coefficient);
        this.scanData1.shengnumber = Number(this.detailinfo.backBottleSum) - (Number(this.detailinfo.scheduleSum) + Number(this.scanData1.number));
      }
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this2.warningdialog = true;
          _this2.failList = _this2.piList.filter(function (el) {
            return el.noarrange == 0;
          });
          _this2.orderList = _this2.piList.filter(function (el) {
            return el.orderStatus == '3';
          });
          _this2.successList = _this2.piList.filter(function (el) {
            return el.noarrange !== 0;
          });
        }
      });
    },
    submitForm1: function submitForm1() {
      var _this3 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          delete _this3.scanData1.shengnumber;
          delete _this3.scanData1.number;
          _this3.scanData1.scheduleChestSum = Number(_this3.scanData1.scheduleChestSum);
          _this3.scanData1.shopCodes = _this3.scanData1.shopCodes.toString();
          if (_this3.arrangetype == 'Y') {
            _this3.scanData1.scheduleChestSum = 0;
          }
          if (_this3.arrangetype == 'E') {
            if (_this3.detailinfo.auditStatus == '2' && (_this3.detailinfo.scheduleStatus == '2' || _this3.detailinfo.scheduleStatus == '1')) {
              (0, _store.updateShopCodes)({
                scheduleNo: _this3.detailinfo.scheduleNo,
                shopCodes: _this3.scanData1.shopCodes
              }).then(function (res) {
                _this3.btn_loading = false;
                if (res.data.code != 200) {
                  _this3.$message.error(res.data.msg);
                } else {
                  _this3.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this3.handleClose();
                }
              }).catch(function () {
                _this3.btn_loading = false;
              });
            } else {
              (0, _store.updateorder)(_this3.scanData1).then(function (res) {
                _this3.btn_loading = false;
                if (res.data.code != 200) {
                  _this3.$message.error(res.data.msg);
                } else {
                  _this3.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this3.handleClose();
                }
              }).catch(function () {
                _this3.btn_loading = false;
              });
            }
          } else {
            (0, _store.arrangeorder)(_this3.scanData1).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message.success(res.data.msg);
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }
        }
      });
    },
    closewarning: function closewarning() {
      this.handleClose();
      this.scanData1.traceType = '';
      this.scanData1.boxCollect = '';
      this.scanData1.shopCodes = '';
    },
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this4.btn_loading = true;
          delete _this4.scanData1.shengnumber;
          delete _this4.scanData1.number;
          _this4.scanData1.scheduleChestSum = Number(_this4.scanData1.scheduleChestSum);
          _this4.scanData1.shopCodes = _this4.scanData1.shopCodes.toString();
          if (_this4.arrangetype == 'Y') {
            _this4.scanData1.scheduleChestSum = 0;
          }
          if (_this4.arrangetype == 'E') {
            if (_this4.detailinfo.auditStatus == '2' && (_this4.detailinfo.scheduleStatus == '2' || _this4.detailinfo.scheduleStatus == '1')) {
              (0, _store.updateShopCodes)({
                scheduleNo: _this4.detailinfo.scheduleNo,
                shopCodes: _this4.scanData1.shopCodes
              }).then(function (res) {
                _this4.btn_loading = false;
                if (res.data.code != 200) {
                  _this4.$message.error(res.data.msg);
                } else {
                  _this4.$message.success(res.data.msg);
                  _this4.handleClose();
                }
              }).catch(function () {
                _this4.btn_loading = false;
              });
            } else {
              (0, _store.updateorder)(_this4.scanData1).then(function (res) {
                _this4.btn_loading = false;
                if (res.data.code != 200) {
                  _this4.$message.error(res.data.msg);
                } else {
                  _this4.$message.success(res.data.msg);
                  _this4.handleClose();
                }
              }).catch(function () {
                _this4.btn_loading = false;
              });
            }
          } else {
            (0, _store.arrangeorder)(_this4.scanData1).then(function (res) {
              _this4.btn_loading = false;
              if (res.data.code != 200) {
                _this4.$message.error(res.data.msg);
              } else {
                // this.$message.success(res.data.msg)
                _this4.warningdialog = true;
                _this4.successCount = res.data.data.successCount;
                _this4.failCount = res.data.data.failCount;
                _this4.closedCount = res.data.data.closedCount;
                _this4.doneCount = res.data.data.doneCount;
                // this.handleClose()
              }
            }).catch(function () {
              _this4.btn_loading = false;
            });
          }
        }
      });
    },
    handleClose: function handleClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.warningdialog = false;
      this.dialogVisible = false;
      this.$parent.getList();
    }
  }
};
exports.default = _default;