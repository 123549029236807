"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { number } from 'echarts/lib/export'
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '公司',
        1: '经销商',
        2: '终端',
        3: '团购'
      };
      return statusMap[status];
    }
  },
  props: {
    selectedData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    purchasesType: {
      type: [String, Number],
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      areaList: [],
      area: [],
      selLoading: false,
      proSelVisible: false,
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        current: 1,
        scope: 2,
        size: 20,
        selectChannelType: '',
        archivesStatus: 1,
        channelType: '',
        keyword: ''
      },
      tableData: [],
      multipleSelection: [],
      total: 0
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        // this.getAreaAll()
      }
    }
  },
  mounted: function mounted() {
    // this.getList()
    console.log(this.purchasesType);
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.getList();
      this.selLoading = true;
      this.$nextTick(function () {
        _this.selectedData.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });

        setTimeout(function () {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.selLoading = false;
        }, 500);
      });
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      submitData.selectChannelType = this.purchasesType;
      (0, _channel.selectChannelAndOrg)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        scope: 2,
        size: 20,
        selectChannelType: '',
        archivesStatus: 1,
        channelType: '',
        keyword: ''
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.proSelVisible = false;
    },
    submit: function submit() {
      if (this.amount < 2) {
        this.$emit('change', this.multipleSelection);
        this.selCancel();
      } else {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: 'error',
            message: '采购单位选择了多个，供货单位只能选择一个'
          });
        } else {
          this.$emit('change', this.multipleSelection);
          this.selCancel();
        }
      }
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;