"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _winningLottery = require("@/api/operationalTools2/winningLottery2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  // zdInfo, integralExchangeList, jd
  filters: {
    // 奖品形式
    prizeType: function prizeType(msg) {
      if (msg == 100) {
        return '红包';
      } else if (msg == 200) {
        return '实物';
      } else if (msg == 300) {
        return '优惠券';
      } else if (msg == 400) {
        return '积分';
      } else if (msg == 500) {
        return '字卡';
      } else if (msg == 900) {
        return '礼包';
      } else if (msg == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    }
  },
  data: function data() {
    return {
      queryParams: {
        markCode: '',
        activityName: '',
        prizeName: '',
        prizeType: '',
        rangeTime: [],
        sendEndDate: '',
        status: '',
        sendStartDate: ''
      },
      current: 1,
      size: 10,
      total: 0,
      prizeTypes: [{
        name: '红包',
        value: '100'
      }, {
        name: '实物奖品',
        value: '200'
      }
      // { name: '优惠券', value: '300' },
      // { name: '积分', value: '400' },
      // { name: '字卡', value: '500' },
      // { name: '礼包', value: '900' },
      // { name: '谢谢惠顾', value: '0' }
      ],

      listLoading: false,
      tableData: [],
      tabHeight: 'calc(100vh - 350px)',
      popupExportDetail: false,
      exportParams: {
        total: 0,
        current: 1,
        size: 10
      },
      exportDataList: [],
      exportDataListLoading: false
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.tabHeight = (0, _utils.tableHeight)();
    });
  },
  activated: function activated() {
    // if (!hasCache1) {
    //   this.getList()
    // }
    console.log(this.$route.params);
    var hasCache1 = this.tableData.length > 0;
    // 其他页面体跳转到本页
    this.queryParams.activityCodeOrActivityName = this.$route.params.activityCode ? this.$route.params.activityCode : '';
    this.queryParams.openIdOrUserId = this.$route.params.userId ? this.$route.params.userId : '';
    if (this.queryParams.activityCodeOrActivityName || this.queryParams.openIdOrUserId || !hasCache1) {
      this.getList();
    }
  },
  methods: {
    showPrizeType: function showPrizeType(row) {
      if (row.prizeType == 100) {
        return '红包';
      } else if (row.prizeType == 200) {
        return '实物';
      } else if (row.prizeType == 300) {
        return '优惠券';
      } else if (row.prizeType == 400) {
        return '积分';
      } else if (row.prizeType == 500) {
        return '字卡';
      } else if (row.prizeType == 900) {
        return '礼包';
      } else if (row.prizeType == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    showLinqutype: function showLinqutype(row) {
      if (row.status == 1) {
        return '未发放';
      } else if (row.status == 2) {
        return '已发放';
      } else if (row.status == 5) {
        return '失败';
      } else {
        return '';
      }
    },
    getList: function getList() {
      this.listLoading = true;
      var that = this;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.sendStartDate = this.queryParams.rangeTime[0];
        this.queryParams.sendEndDate = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.sendStartDate = '';
        this.queryParams.sendEndDate = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.queryParams));
      delete sdata.rangeTime;
      (0, _winningLottery.getWinningLottery)(this.current, this.size, sdata).then(function (res) {
        console.log(res.data.data.records);
        if (res.data.data.errCode == 500) {
          that.$message.error(res.data.data.errMsg);
          that.total = 0;
          that.tableData = [];
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.queryParams = {
        markCode: '',
        activityName: '',
        prizeName: '',
        prizeType: '',
        rangeTime: [],
        sendEndDate: '',
        status: '',
        sendStartDate: ''
      };
      this.current = 1;
      this.getList();
    },
    handleShowFun: function handleShowFun() {},
    handleclick: function handleclick() {},
    // 导出中奖流水
    exportLs: function exportLs() {
      var _this2 = this;
      // sendExprotRequest getExprotActivity
      (0, _winningLottery.sendExprotRequest)(this.queryParams).then(function (res) {
        // setTimeout(() => {
        //   this.$message.success('导出成功！')
        // }, 2 * 800)
        console.log(res);
        if (res.status == 200) {
          if (res.data && !res.data.errCode) {
            return _this2.$message.success('已加入导出队列，请在导出明细中查看');
          } else {
            return _this2.$message.error(res.data.errMsg);
          }
        } else {
          _this2.$message.error(res.data.msg || res.data.errMsg);
        }

        // var blob = new Blob([res.data], {
        //   type: 'application/vnd.ms-excel;charset=utf-8'
        // })
        // var downloadElement = document.createElement('a')
        // var href = window.URL.createObjectURL(blob)
        // downloadElement.href = href
        // downloadElement.download = '活动参与记录.xlsx'
        // document.body.appendChild(downloadElement)
        // downloadElement.click()
        // document.body.removeChild(downloadElement)
        // window.URL.revokeObjectURL(href)
      });
    },
    openExportDetail: function openExportDetail() {
      this.queryExprotActivityForPage(this.current, this.size);
      this.popupExportDetail = true;
    },
    hadnleCloseExportDetail: function hadnleCloseExportDetail() {
      this.popupExportDetail = false;
    },
    queryExprotActivityForPage: function queryExprotActivityForPage(current, size) {
      var _this3 = this;
      this.exportDataListLoading = true;
      (0, _winningLottery.queryExprotActivityForPage)({
        current: current,
        size: size
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this3.exportDataListLoading = false;
            return _this3.$message.error(res.data.data.errMsg);
          } else {
            _this3.exportDataListLoading = false;
            _this3.exportParams.total = parseInt(res.data.data.total);
            _this3.exportParams.current = parseInt(res.data.data.current);
            _this3.exportParams.size = parseInt(res.data.data.size);
            _this3.exportDataList = res.data.data.records;
          }
        } else {
          return _this3.$message.error(res.data.msg);
        }
      });
    } // openExportDetail() {
    //   if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
    //     this.queryParams.sendStartDate = this.queryParams.rangeTime[0]
    //     this.queryParams.sendEndDate = this.queryParams.rangeTime[1]
    //   } else {
    //     this.queryParams.sendStartDate = ''
    //     this.queryParams.sendEndDate = ''
    //   }
    //   var sdata = JSON.parse(JSON.stringify(this.queryParams))
    //   delete sdata.rangeTime
    //   getExprotActivity(sdata).then(res => {
    //     this.downLoading = false
    //     if (res.status !== 200) {
    //       this.$message.error(res.statusText)
    //       return
    //     } else {
    //       if (res.data) {
    //         this.$message.success('导出成功！')
    //         var blob = new Blob([res.data], {
    //           type: 'application/vnd.ms-excel;charset=utf-8'
    //         })
    //         var downloadElement = document.createElement('a')
    //         var href = window.URL.createObjectURL(blob)
    //         downloadElement.href = href
    //         downloadElement.download = '活动列表.xlsx'
    //         document.body.appendChild(downloadElement)
    //         downloadElement.click()
    //         document.body.removeChild(downloadElement)
    //         window.URL.revokeObjectURL(href)
    //       }
    //     }
    //   }).catch(() => {
    //     this.downLoading = false
    //   })
    // }
  }
};
exports.default = _default;