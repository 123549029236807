var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-width": "80px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orgId", label: "所属组织:" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  placeholder: "请选择组织...",
                                  options: _vm.orgOptions,
                                },
                                model: {
                                  value: _vm.listQuery.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orgId", $$v)
                                  },
                                  expression: "listQuery.orgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "channelStr", label: "经销商:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  clear: _vm.clearJxs,
                                  focus: function ($event) {
                                    return _vm.getUnit("search")
                                  },
                                },
                                model: {
                                  value: _vm.channelStr,
                                  callback: function ($$v) {
                                    _vm.channelStr = $$v
                                  },
                                  expression: "channelStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "productName", label: "产品:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  clear: _vm.clearPro,
                                  focus: _vm.getProductId,
                                },
                                model: {
                                  value: _vm.productName,
                                  callback: function ($$v) {
                                    _vm.productName = $$v
                                  },
                                  expression: "productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productTypeId",
                                    label: "产品分类:",
                                  },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择...",
                                      options: _vm.classList,
                                    },
                                    model: {
                                      value: _vm.listQuery.productTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "productTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.productTypeId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "salesmanStr",
                                    label: "显示0现存量产品:",
                                    "label-width": "150px",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": _vm.activeVal,
                                      "inactive-value": _vm.inactiveVal,
                                    },
                                    model: {
                                      value:
                                        _vm.listQuery.isDisplayZeroExistnum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "isDisplayZeroExistnum",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.isDisplayZeroExistnum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "20px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.DoSearch },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-search" }),
                                  _vm._v(" 查询\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-download", size: "mini" },
                  on: { click: _vm.DoExportAll },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticStyle: { "border-top": "1px solid #eee" } },
            [
              _c(
                "el-row",
                { staticClass: "head-item", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v("现存量合计：" + _vm._s(_vm.sum1)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v("可用量合计：" + _vm._s(_vm.sum2)),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _vm._v("在途库存合计：" + _vm._s(_vm.sum3)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.listQuery.pageNo - 1) *
                                    _vm.listQuery.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商编码",
                      prop: "dealerCode",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "dealerName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商分类",
                      prop: "clazzName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商等级",
                      prop: "gradeName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "现存量",
                      prop: "existingSum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "可用量",
                      prop: "availableSum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "在途库存",
                      prop: "beingSum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "80",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoScan(row)
                                  },
                                },
                              },
                              [_vm._v("明细")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "库存明细",
            visible: _vm.scanVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { data: _vm.scanData } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "经销商编码:" } }, [
                        _vm._v(_vm._s(_vm.scanData.dealerCode)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "经销商名称:" } }, [
                        _vm._v(_vm._s(_vm.scanData.dealerName)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品编码:" } }, [
                        _vm._v(_vm._s(_vm.scanData.productCode)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品名称:" } }, [
                        _vm._v(_vm._s(_vm.scanData.productName)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "现存量:" } }, [
                        _vm._v(_vm._s(_vm.scanData.existingSum)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "可用量:" } }, [
                        _vm._v(_vm._s(_vm.scanData.availableSum)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "在途库存:" } }, [
                        _vm._v(_vm._s(_vm.scanData.beingSum)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [_c("el-form-item")], 1),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "search",
                  attrs: { model: _vm.search, "status-icon": "" },
                },
                [
                  _c("div", { staticClass: "phead" }, [
                    _vm._v("\n          数据筛选\n          "),
                    _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.searchList },
                          },
                          [
                            _c("i", { staticClass: "el-icon-search" }),
                            _vm._v(" 查询\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.resetList(_vm.search)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-refresh" }),
                            _vm._v(" 重置\n            "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "outStartDate" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("发货起始日期："),
                              ]),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.outStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "outStartDate", $$v)
                                  },
                                  expression: "search.outStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "outEndDate" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("发货结束日期："),
                              ]),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.outEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "outEndDate", $$v)
                                  },
                                  expression: "search.outEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "fhStr" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("发货单位："),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.getUnit("sendOrgId")
                                  },
                                },
                                model: {
                                  value: _vm.fhStr,
                                  callback: function ($$v) {
                                    _vm.fhStr = $$v
                                  },
                                  expression: "fhStr",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "outStartDate" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("收货起始日期："),
                              ]),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.outStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "outStartDate", $$v)
                                  },
                                  expression: "search.outStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "outEndDate" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("收货结束日期："),
                              ]),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.outEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "outEndDate", $$v)
                                  },
                                  expression: "search.outEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "shStr" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("收货单位："),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.getUnit("recOrgId")
                                  },
                                },
                                model: {
                                  value: _vm.shStr,
                                  callback: function ($$v) {
                                    _vm.shStr = $$v
                                  },
                                  expression: "shStr",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "outgoType" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("出入库类型："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.outgoType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "outgoType", $$v)
                                    },
                                    expression: "search.outgoType",
                                  },
                                },
                                _vm._l(_vm.outgoTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "billType" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("单据类型："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.billType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "billType", $$v)
                                    },
                                    expression: "search.billType",
                                  },
                                },
                                _vm._l(_vm.billTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "billStatus" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("单据状态："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.billStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "billStatus", $$v)
                                    },
                                    expression: "search.billStatus",
                                  },
                                },
                                _vm._l(_vm.billStats, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "isCode" } },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v("是否有码："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.isCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "isCode", $$v)
                                    },
                                    expression: "search.isCode",
                                  },
                                },
                                _vm._l(_vm.isCodes, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading2,
                          expression: "listLoading2",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.list2, border: "", fit: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "单号",
                          prop: "orderNum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "出入库类型",
                          prop: "outgoType",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.outgoType == "1"
                                  ? _c("span", [_vm._v("出库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.outgoType == "2"
                                  ? _c("span", [_vm._v("入库")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "业务类型",
                          prop: "businessTypeName",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据类型",
                          prop: "billEnum",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.billEnum == "1"
                                  ? _c("span", [_vm._v("期初入库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "2"
                                  ? _c("span", [_vm._v("采购入库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "3"
                                  ? _c("span", [_vm._v("调拨入库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "4"
                                  ? _c("span", [_vm._v("其他入库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "5"
                                  ? _c("span", [_vm._v("销售出库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "6"
                                  ? _c("span", [_vm._v("调拨出库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.billEnum == "7"
                                  ? _c("span", [_vm._v("其他出库")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据状态",
                          prop: "orderStatus",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.orderStatus == "0"
                                  ? _c("span", [_vm._v("删除")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.orderStatus == "1"
                                  ? _c("span", [_vm._v("待提交")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.orderStatus == "2"
                                  ? _c("span", [_vm._v("待审核")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.orderStatus == "3"
                                  ? _c("span", [_vm._v("部分入库")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.orderStatus == "4"
                                  ? _c("span", [_vm._v("全部入库")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "应收发数量",
                          prop: "planNum",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "实收发数量",
                          prop: "actNum",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货单位编码",
                          prop: "sendOrgCode",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货单位",
                          prop: "sendOrgName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货日期",
                          prop: "sendDate",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货单位编码",
                          prop: "recOrgCode",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货单位",
                          prop: "recOrgName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货日期",
                          prop: "recDate",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total2,
                      page: _vm.search.pageNo,
                      limit: _vm.search.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.search, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.search, "pageSize", $event)
                      },
                      pagination: _vm.getList2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }