var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.id ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "before-close": _vm.cancle,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公告标题：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { maxlength: "30" },
                model: {
                  value: _vm.dataForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "title", $$v)
                  },
                  expression: "dataForm.title",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "formTip" }, [
                _c("i", { staticClass: "el-icon-warning-outline" }),
                _vm._v(" 提示:最多填写30个字\n      "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  model: {
                    value: _vm.dataForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "status", $$v)
                    },
                    expression: "dataForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("公布")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("撤销")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "置顶：", prop: "isTop" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  model: {
                    value: _vm.dataForm.isTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "isTop", $$v)
                    },
                    expression: "dataForm.isTop",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "公告内容：", prop: "content" } },
            [
              _c("tiny-mce", {
                attrs: { "tinymce-height": 320 },
                model: {
                  value: _vm.dataForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "content", $$v)
                  },
                  expression: "dataForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancle } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }