var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "calc(100% - 5px)" } }, [
    _c(
      "div",
      { staticClass: "el-row page-section", staticStyle: { height: "100%" } },
      [
        _c(
          "div",
          { staticStyle: { height: "100%" }, attrs: { id: "table-box" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.modelList,
                  "tooltip-effect": "dark",
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                  height: "calc(100% - 62px)",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "120",
                    label: "序号",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "码类型",
                    align: _vm.tableConfig.align,
                    "min-width": "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("codeType")(scope.row.markType))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装层级",
                    align: _vm.tableConfig.align,
                    "min-width": "120",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("levelType")(
                                  scope.row.packLevel,
                                  scope.row.markType
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "URL",
                    align: _vm.tableConfig.align,
                    prop: "urlAddress",
                    "min-width": "220",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "图片",
                    align: _vm.tableConfig.align,
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-image", {
                            staticStyle: { width: "80px", height: "80px" },
                            attrs: {
                              src: scope.row.imgAddress
                                ? scope.row.imgAddress
                                : _vm.imgUrl,
                              "preview-src-list": [
                                scope.row.imgAddress
                                  ? scope.row.imgAddress
                                  : _vm.imgUrl,
                              ],
                              fit: "contain",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.current,
                "limit.sync": "10",
                layout: "total, prev, pager, next",
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "current", $event)
                },
                pagination: _vm.getModelList,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }