"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/basic/product");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productDialogProductFile = _interopRequireDefault(require("@/components/productDialogProductFile"));
var _utils = require("@/utils");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _dictionary = require("@/api/systems/dictionary");
var _treasury = _interopRequireDefault(require("../../../warehouseService/stock/treasury.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BasicDataproductmangeproductfileindex",
  components: {
    Pagination: _Pagination.default,
    UploadImg: _UploadImg.default,
    productDialog: _productDialogProductFile.default
  },
  filters: {
    odorType: function odorType(type, that) {
      if (!String(type)) return "";
      var statusMap = {};
      that.odorTypeList.forEach(function (item) {
        statusMap[item.figure] = item.name;
      });
      return statusMap[type];
    }
  },
  // inject: ['reload'],
  data: function data() {
    return {
      showError: false,
      that: this,
      imgUrl: require("../../../../images/origin.png"),
      myHeaders: {
        Authorization: "Bearer " + (0, _auth.getToken)(),
        btnId: ""
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      imgloading: false,
      img1loading: false,
      page2: false,
      tableKey: '111',
      tabHeight: "100%",
      filterText: "",
      activeClass: "",
      sourceEnum: "",
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: '',
        productBrandId: '',
        // productClassId: '',
        productCode: '',
        // productModel: '',
        // isCode: '',
        productName: '',
        packScaleId: '',
        // containsPackScale: 1,
        sourceEnum: ''
      },
      tableData: [],
      total: 0,
      ruleForm: {
        isCustom: 0,
        sourceEnum: "",
        archivesStatus: 1,
        isCode: 1,
        packScaleId: "",
        productBrandId: "",
        productClassId: "",
        // commonProductName: '',
        productCode: "",
        productImages: "",
        productModel: "",
        productName: "",
        internalCode: "",
        // productPrice: '',
        // rebatePrice: '',
        inspectionReport: '',
        // netContent: "",
        // alcoholContent: "",
        // productSource: "",
        // aromaType: 2, // 1 浓香 2 酱香
        // executiveStandard: '',
        // storageConditions: "",
        // productionLicenseNo: "",
        extendList: [{
          "extendKey": "",
          "extendValue": "",
          "pid": 0,
          "productId": 0
        }],
        relationProductIds: [],
        relationProductList: [],
        remarks: ""
      },
      rules: {
        productCode: [{
          required: true,
          message: "产品编码不能为空，请维护",
          trigger: "submit"
        }],
        isCustom: [{
          required: true,
          message: "",
          trigger: "change"
        }],
        sourceEnum: [{
          required: true,
          message: "产品来源不能为空，请维护",
          trigger: "change"
        }],
        productName: [{
          required: true,
          message: "产品名称不能为空，请维护",
          trigger: "change"
        }],
        internalCode: [{
          required: true,
          message: "内码不能为空，请维护",
          trigger: "change"
        }],
        productClassId: [{
          required: true,
          message: "产品分类不能为空，请维护",
          trigger: "change"
        }],
        productBrandId: [{
          required: true,
          message: "产品品牌不能为空，请维护",
          trigger: "change"
        }],
        packScaleId: [{
          required: true,
          message: "包装比例不能为空，请维护",
          trigger: "change"
        }],
        productModel: [{
          required: true,
          message: "规格不能为空，请维护",
          trigger: "change"
        }],
        // productPrice: [
        //   {
        //     required: false,
        //     message: '价格不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // rebatePrice: [
        //   {
        //     required: false,
        //     message: '酒券核算价格不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // netContent: [
        //   {
        //     required: true,
        //     message: '净含量不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // alcoholContent: [
        //   {
        //     required: true,
        //     message: '酒精度不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // productSource: [
        //   {
        //     required: false,
        //     message: '产品配料不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // aromaType: [
        //   {
        //     required: true,
        //     message: '香型不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // executiveStandard: [
        //   {
        //     required: false,
        //     message: '执行标准不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // storageConditions: [
        //   {
        //     required: false,
        //     message: '贮存条件不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // productionLicenseNo: [
        //   {
        //     required: false,
        //     message: '食品生产许可证不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        archivesStatus: [{
          required: true,
          message: "",
          trigger: "change"
        }],
        isCode: [{
          required: true,
          message: "",
          trigger: "change"
        }],
        productImages: [{
          required: false,
          message: "请上传产品图片",
          trigger: "change"
        }]
      },
      props: {
        label: "name",
        children: "children",
        isLeaf: "leaf"
      },
      title: "添加产品档案",
      imageUrl: "",
      inspectionReport: '',
      dialogVisible: false,
      scanVisible: false,
      uploadVisible: false,
      disabled: false,
      uploadData: [],
      classList: [],
      normalList: [],
      brandList: [],
      packList: [],
      sourceList: [],
      uploadForm: {
        // isFuGai: '',
        uploadFile: ""
      },
      uploadRules: {},
      fileList: [],
      multipleSelection: [],
      activtyId: "",
      odorTypeList: [],
      submitShow: true,
      attributeStatus: "",
      productDialogVisible: false,
      product: {},
      downLoading: false
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return (
        // process.env.VUE_APP_BASE_API + '/api-basic/productArchives/importExcel'
        process.env.VUE_APP_BASE_API + "/api-basic/productArchives/importExcelTemplate"
      );
    },
    requestEnum: function requestEnum() {
      // 根据 sourceEnum 计算对应的请求参数
      if (this.ruleForm.sourceEnum == 1) {
        return 2;
      } else if (this.ruleForm.sourceEnum == 2) {
        return 1;
      } else {
        return null;
      }
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 460 + "px";
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    this.getClass();
    this.getBrand();
    this.getPack();
    this.getItemByDataDictionaryByCodeFun();
  },
  methods: {
    handleInput: function handleInput() {
      // 只保留输入值中的数字部分
      this.ruleForm.internalCode = this.ruleForm.internalCode.replace(/\D/g, '');

      // 根据输入的数字个数设置 showError 变量
      this.showError = this.ruleForm.internalCode.length !== 4;
    },
    getdictionary: function getdictionary(id, index) {
      var _this2 = this;
      (0, _dictionary.getDataDictionaryItemList)({
        current: 1,
        size: 20,
        name: "",
        isEnable: 1,
        pid: id
      }).then(function (response) {
        if (response.data.code != 200) {
          _this2.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this2.ruleForm.extendList[index].dictionaryList = response.data.data.records || [];
          } else {
            _this2.tableData = [];
          }
        }
      }).catch(function (res) {});
    },
    // 获取香型
    getItemByDataDictionaryByCodeFun: function getItemByDataDictionaryByCodeFun() {
      var _this3 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: "ODORTYPE"
      }).then(function (res) {
        if (res.data.code === 200) {
          // console.log(res)
          if (res.data.data) {
            console.log(res.data.data, 11111);
            _this3.odorTypeList = res.data.data;
          }
        }
      });
    },
    getClass: function getClass() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), "pId", "parentId", "children");
            that.normalList = res.data.data;
            that.classList = data;
          } else {
            that.normalList = [];
            that.classList = [];
          }
        }
      });
    },
    getBrand: function getBrand() {
      var _this4 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this4.brandList = res.data.data;
          } else {
            _this4.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this5 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this5.packList = res.data.data;
          } else {
            _this5.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this6 = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this6.listLoading = false;
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.productClassName.indexOf(value) !== -1 || data.productClassCode.indexOf(value) !== -1;
    },
    handleFilter: function handleFilter() {
      this.$refs.tree.filter(this.filterText);
    },
    scan: function scan(data) {
      if (this.activtyId != data.pId && (!data.children || data.pId)) {
        this.searchForm = {
          // current: 1,
          // size: 20,
          // archivesStatus: "",
          // productBrandId: "",
          // productClassId: data.pId == 1 ? "" : data.pId,
          // productCode: "",
          // productModel: "",
          // packScaleId: "",
          // productName: "",
          // containsPackScale: 1
          current: 1,
          size: 20,
          archivesStatus: '',
          productBrandId: '',
          productCode: '',
          productName: '',
          packScaleId: '',
          sourceEnum: ''
        };
        this.getList();
      }
    },
    submitForm: function submitForm() {
      var _this7 = this;
      this.ruleForm.productImages = this.imageUrl;
      this.ruleForm.inspectionReport = this.inspectionReport;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          var submitData = (0, _utils.filterKeyNull)(_this7.ruleForm);
          var le = _this7.ruleForm.productClassId.length - 1;
          submitData.productImages = _this7.imageUrl;
          submitData.inspectionReport = _this7.inspectionReport;
          if ((0, _typeof2.default)(_this7.ruleForm.productClassId) == "object") {
            submitData.productClassId = _this7.ruleForm.productClassId[le];
          } else {
            submitData.productClassId = _this7.ruleForm.productClassId;
          }
          var arr = [];
          submitData.extendList.forEach(function (el) {
            var a = {};
            a.extendValue = el.extendValue;
            a.extendKey = el.attributeCode;
            a.attributeType = el.attributeType;
            arr.push(a);
          });
          submitData.relationProductIds = [];
          if (_this7.ruleForm.relationProductList && _this7.ruleForm.relationProductList.length !== 0) {
            // 遍历 relationProductList 数组
            _this7.ruleForm.relationProductList.forEach(function (item) {
              // 将每个对象的 pId 属性值添加到 relationProductIds 数组中
              submitData.relationProductIds.push(item.pId);
            });
          }
          delete submitData.relationProductList;
          delete submitData.k3ProductCode;
          console.log(submitData, 123123123);
          if (_this7.ruleForm.pId) {
            _this7.authId("updatebtn", _this7.$route.meta.authList);
            if (arr) {
              arr.forEach(function (el) {
                if (el.attributeType == "2") {
                  if (el.extendValue) {
                    el.extendValue = el.extendValue.toString();
                  }
                }
                if (el.extendValue) {
                  if ((0, _typeof2.default)(el.extendValue) == "object") {
                    el.extendValue = el.extendValue.toString();
                  }
                }
                delete el.attributeType;
              });
            }
            (0, _product.productArchivesUpdate)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, submitData), {}, {
              extendList: arr
            })).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
                _this7.handleClose();
              } else {
                _this7.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          } else {
            _this7.authId("addbtn", _this7.$route.meta.authList);
            if (arr) {
              arr.forEach(function (el) {
                if (el.attributeType == "3") {
                  if (el.extendValue) {
                    if ((0, _typeof2.default)(el.extendValue) == "object") {
                      el.extendValue = el.extendValue.toString();
                    }
                  }
                }
                delete el.attributeType;
              });
            }
            (0, _product.productArchivesInsert)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, submitData), {}, {
              extendList: arr
            })).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: '',
        productBrandId: '',
        productCode: '',
        productName: '',
        packScaleId: '',
        sourceEnum: ''
      };
      this.filterText = "";
      this.handleFilter();
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getactiveproduct: function getactiveproduct() {
      var _this8 = this;
      (0, _product.getproductstatus)({
        attributeStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          _this8.$message.error(res.data.msg);
          return;
        } else {
          _this8.ruleForm.extendList = res.data.data || [];
          _this8.ruleForm.extendList.forEach(function (el, i) {
            if (el.attributeType == "3") {
              _this8.$set(el, "dictionaryList", []);
              _this8.getdictionary(el.attributeDictionaryId, i);
            }
          });
        }
      }).catch(function () {});
    },
    add: function add() {
      this.title = "添加产品档案";
      this.myHeaders.btnId = this.authId("addbtn", this.$route.meta.authList);
      this.getClass();
      this.getactiveproduct();
      this.getBrand();
      this.getPack();
      this.dialogVisible = true;
    },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.ruleForm.productClassId.unshift(val);
          if (number.parentId) {
            that.filterparentList(number.parentId);
          }
        }
      });
    },
    setDetail: function setDetail(row) {
      var _this9 = this;
      this.getList();
      this.ruleForm.isCustom = row.isCustom;
      this.ruleForm.productCode = row.productCode;
      this.ruleForm.pId = row.pId;
      this.ruleForm.archivesStatus = row.archivesStatus;
      this.ruleForm.isCode = row.isCode;
      this.ruleForm.packScaleId = row.packScaleId;
      this.ruleForm.productBrandId = row.productBrandId;
      this.ruleForm.productImages = row.productImages;
      this.ruleForm.productModel = row.productModel;
      this.ruleForm.productName = row.productName;
      this.ruleForm.extendList = row.extendList;
      this.ruleForm.sourceEnum = row.sourceEnum;
      this.ruleForm.internalCode = row.internalCode;
      this.ruleForm.inspectionReport = row.inspectionReport;
      if (row.relationProductList == null) {
        row.relationProductList = [];
      } else {
        this.ruleForm.relationProductList = row.relationProductList;
      }
      if (row.extendList) {
        row.extendList.forEach(function (el, i) {
          if (el.attributeType == "2") {
            if (el.extendValue) {
              if (typeof el.extendValue == "string") {
                el.extendValue = el.extendValue.split(",");
              }
              el.extendValue.forEach(function (e) {
                var name = "imgupload" + i;
                if (e) {
                  _this9.$nextTick(function () {
                    if (_this9.$refs[name]) {
                      _this9.$refs[name][0]._data.fileList.push({
                        url: e
                      });
                      _this9.$refs[name][0]._data.hideUpload = true;
                    }
                  });
                }
              });
            }
          } else if (el.attributeType == "3") {
            _this9.$set(el, "dictionaryList", []);
            _this9.getdictionary(el.attributeDictionaryId, i);
            if (el.attributeConstraint == "1") {
              if (el.extendValue) {
                if (typeof el.extendValue == "string") {
                  el.extendValue = el.extendValue.split(",");
                }
              }
            }
          }
        });
      }
      this.ruleForm.remarks = row.remarks;
      this.imageUrl = row.productImages || "";
      this.inspectionReport = row.inspectionReport || '';
      this.ruleForm.productClassId = [];
      if (row.productClassId) {
        this.filterparentList(row.productClassId);
      }
    },
    detail: function detail(row) {
      this.title = "查看产品档案";
      this.disabled = true;
      this.setDetail(row);
      this.ruleForm.productClassName = row.productClassName;
      this.ruleForm.packScaleDesc = row.packScaleDesc;
      this.ruleForm.brandName = row.brandName;
      this.ruleForm.extendList = row.extendList;
      this.ruleForm.sourceEnum = row.sourceEnum;
      this.ruleForm.createTime = row.createTime;
      this.ruleForm.relationProductList = row.relationProductList;
      this.scanVisible = true;
    },
    DoEdit: function DoEdit(row) {
      var _this10 = this;
      this.title = "编辑产品档案";
      this.myHeaders.btnId = this.authId("updatebtn", this.$route.meta.authList);
      this.getClass();
      this.getBrand();
      this.getPack();
      // this.getactiveproduct()
      this.setDetail(row);
      console.log(row, 565656);
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this10.$refs["ruleForm"].clearValidate();
      });
    },
    setInUse: function setInUse(id, authName) {
      var _this11 = this;
      if (id || this.multipleSelection.length > 0) {
        this.$confirm("请确认是否启用?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this11.authId(authName, _this11.$route.meta.authList);
          var pList = [];
          if (id) {
            pList.push({
              archivesStatus: 1,
              pId: id
            });
          } else {
            _this11.multipleSelection.forEach(function (item) {
              pList.push({
                archivesStatus: 1,
                pId: item.pId
              });
            });
          }
          (0, _product.productArchivesEnableProductArchives)(pList).then(function (res) {
            _this11.authId("searchbtn", _this11.$route.meta.authList);
            if (res.data.code != 200) {
              _this11.$message.error(res.data.msg);
            } else {
              _this11.$message({
                message: "操作成功",
                type: "success"
              });
              _this11.getList();
            }
          });
        }).catch(function () {
          _this11.authId("searchbtn", _this11.$route.meta.authList);
        });
      }
    },
    setUnUse: function setUnUse(id, authName) {
      var _this12 = this;
      if (id || this.multipleSelection.length > 0) {
        this.$confirm("请确认是否禁用?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this12.authId(authName, _this12.$route.meta.authList);
          var pList = [];
          if (id) {
            pList.push({
              archivesStatus: 0,
              pId: id
            });
          } else {
            _this12.multipleSelection.forEach(function (item) {
              pList.push({
                archivesStatus: 0,
                pId: item.pId
              });
            });
          }
          (0, _product.productArchivesEnableProductArchives)(pList).then(function (res) {
            _this12.authId("searchbtn", _this12.$route.meta.authList);
            if (res.data.code != 200) {
              _this12.$message.error(res.data.msg);
            } else {
              _this12.$message({
                message: "操作成功",
                type: "success"
              });
              _this12.getList();
            }
          });
        }).catch(function () {
          _this12.authId("searchbtn", _this12.$route.meta.authList);
        });
      }
    },
    handleClose: function handleClose() {
      this.imageUrl = "";
      // this.reload()
      this.inspectionReport = '';
      this.ruleForm = {
        isCustom: 0,
        archivesStatus: 1,
        isCode: 1,
        packScaleId: "",
        productBrandId: "",
        productClassId: "",
        productCode: "",
        inspectionReport: '',
        productImages: "",
        productModel: "",
        productName: "",
        remarks: "",
        sourceEnum: ""
        // createTime: "",
      };
      // this.ruleForm.extendList = [];
      this.$set(this.ruleForm, 'extendList', []);
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      this.disabled = false;
      this.dialogVisible = false;
      this.scanVisible = false;
      this.authId("searchbtn", this.$route.meta.authList);
    },
    DoDel: function DoDel(id) {
      var _this13 = this;
      this.$confirm("确认删除?将不可撤销", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this13.authId("deletebtn", _this13.$route.meta.authList);
        (0, _product.productArchivesDel)({
          pId: id
        }).then(function (res) {
          _this13.authId("searchbtn", _this13.$route.meta.authList);
          if (res.data.code == 200) {
            _this13.$message({
              type: "success",
              message: "删除成功!"
            });
            _this13.getList();
          } else {
            _this13.$message({
              type: "info",
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this13.authId("searchbtn", _this13.$route.meta.authList);
      });
    },
    download: function download() {
      var _this14 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)(this.searchForm);
      submitData = JSON.parse(JSON.stringify(submitData));
      delete submitData.size;
      console.log(submitData, 1111);
      (0, _product.productArchivesExportExcel)(submitData).then(function (res) {
        _this14.authId('searchbtn', _this14.$route.meta.authList);
        _this14.downLoading = false;
        if (res.status != 200) {
          _this14.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '产品档案.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this14.authId('searchbtn', _this14.$route.meta.authList);
        _this14.downLoading = false;
      });
    },
    // 下载模板
    downExcel: function downExcel() {
      var _this15 = this;
      (0, _pack.getExcelTemplate)().then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "产品档案导入模板.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this15.$message.success("导出成功!");
      }).catch(function (err) {
        console.log(err);
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(param) {
      var _this16 = this;
      var file = param.file;
      var isJPG = file.type === "image/jpeg";
      var isPNG = file.type === "image/png";
      var isLt2M = file.size / 1024 / 1024 < 0.5;
      var _this = this;
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 PNG/JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500kb!");
        return false;
      }
      this.imgloading = true;
      var formData = new FormData();
      formData.append("file", file);
      (0, _user.imgupload)(formData).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this16.$message.error(data.msg);
          return true;
        }
        _this.imageUrl = data.data;
        _this16.imgloading = false;
      }).catch(function () {
        _this16.imgloading = false;
      });
      return isJPG && isLt2M;
    },
    beforeAvatarUpload1: function beforeAvatarUpload1(param) {
      var _this17 = this;
      var file = param.file;
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;
      var _this = this;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 PNG/JPG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 500kb!');
        return false;
      }
      this.img1loading = true;
      var formData = new FormData();
      formData.append('file', file);
      (0, _user.imgupload)(formData).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this17.$message.error(data.msg);
          return true;
        }
        _this.inspectionReport = data.data;
        _this17.img1loading = false;
      }).catch(function () {
        _this17.img1loading = false;
      });
      return isJPG && isLt2M;
    },
    upload: function upload() {
      this.uploadVisible = true;
      this.uploadForm.uploadFile = "";
    },
    syncData: function syncData() {},
    selecteChange: function selecteChange(val) {
      this.fileList = JSON.parse(JSON.stringify(this.fileList));
    },
    selectFile: function selectFile(file, fileList) {
      console.log(file, fileList);
      if (file.response) {
        this.submitShow = true;
      } else {
        this.submitShow = false;
      }
      this.uploadForm.uploadFile = file.name;
    },
    uploadClose: function uploadClose() {
      var that = this;
      that.uploadForm.uploadFile = "";
      that.$refs.upload.clearFiles();
      that.uploadVisible = false;
      that.uploadData = [];
    },
    submitUpload: function submitUpload() {
      this.mloading = true;
      this.uploadData = [];
      this.$refs.upload.submit();
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else {
        if (!res.msg) {
          this.$message({
            type: "success",
            message: "导入成功!"
          });
        } else if (res.code == 200) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.uploadClose();
        this.getList();
      }
      this.mloading = false;
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.uploadForm.uploadFile = "";
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error("上传失败请重试");
      this.mloading = false;
      this.uploadForm.uploadFile = "";
      this.$refs.upload.clearFiles();
    },
    productClassFun: function productClassFun(val) {
      var data = this.$refs.productClass.getCheckedNodes()[0].data;
      console.log(data.remarks);
      this.ruleForm.remarks = data.remarks ? data.remarks : "";
    },
    handleRemove: function handleRemove(index, row) {
      this.ruleForm.relationProductList.splice(index, 1);
    },
    openProduct: function openProduct() {
      console.log(11111);
      this.productDialogVisible = true;
    },
    changeProduct: function changeProduct(products) {
      var _this$ruleForm$relati;
      this.ruleForm.relationProductList = [];
      (_this$ruleForm$relati = this.ruleForm.relationProductList).push.apply(_this$ruleForm$relati, (0, _toConsumableArray2.default)(products));
    }
  }
};
exports.default = _default;