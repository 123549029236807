"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkCompareName = checkCompareName;
exports.deletePrize = deletePrize;
exports.disablePrize = disablePrize;
exports.enablePrize = enablePrize;
exports.excelZdRecordOut = excelZdRecordOut;
exports.getExpress = getExpress;
exports.getPList = getPList;
exports.getRecordList = getRecordList;
exports.payRedPack = payRedPack;
exports.prizeDetail = prizeDetail;
exports.prizeExcel = prizeExcel;
exports.recevingDetail = recevingDetail;
exports.updateExpress = updateExpress;
exports.updateHongbao = updateHongbao;
exports.updateShiwu = updateShiwu;
exports.updateThird = updateThird;
exports.wordMateriaList = wordMateriaList;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseURL = '/api'
var baseURL = process.env.VUE_APP_VERSION1;
function getPList(data) {
  return (0, _request.default)({
    // url: baseURL + '/prize/list',
    url: baseURL + '/api-uspyx/prize/list',
    method: 'POST',
    data: data
  });
}
// 删除奖项
function deletePrize(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/del',
    method: 'GET',
    params: params
  });
}
// 启用用奖项
function enablePrize(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/enable',
    method: 'GET',
    params: params
  });
}
// 禁用奖项
function disablePrize(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/disable',
    method: 'GET',
    params: params
  });
}
// 检验奖项名称是否唯一
function checkCompareName(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/compareName',
    method: 'GET',
    params: params
  });
}

// 添加 修改红包
function updateHongbao(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/saveredpack',
    method: 'POST',
    data: data
  });
}
// 添加 修改实物
function updateShiwu(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/savematerial',
    method: 'POST',
    data: data
  });
}

// 添加 修改第三方
function updateThird(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/saveThird',
    method: 'POST',
    data: data
  });
}

// 奖项详情
function prizeDetail(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/detail',
    method: 'GET',
    params: params
  });
}

// 中奖记录
function wordMateriaList(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/record/wordMateriaList',
    method: 'POST',
    data: data
  });
}

// 所有快递公司
function getExpress(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/record/getExpress',
    method: 'GET',
    params: params
  });
}

// 中奖记录详情
function recevingDetail(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/record/recevingDetail',
    method: 'GET',
    params: params
  });
}
// 提交快递信息
function updateExpress(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/record/updateExpress',
    method: 'POST',
    data: data
  });
}

// 补发红包
function payRedPack(params) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/record/payRedPack',
    method: 'GET',
    params: params
  });
}

// 根据奖品列表导出
function prizeExcel(data) {
  return (0, _request.default)({
    url: baseURL + '/api-uspyx/prize/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 终端中奖记录
function getRecordList(data) {
  return (0, _request.default)({
    url: '/api-uspyx/record/getUnpackRecordList',
    method: 'post',
    data: data
  });
}

// 导出终端中奖记录
function excelZdRecordOut(data) {
  return (0, _request.default)({
    url: '/api-uspyx/record/getUnpackRecordListUplod',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}