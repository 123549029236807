"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteById = deleteById;
exports.excelOut = excelOut;
exports.excelStorageOut = excelStorageOut;
exports.exportData = exportData;
exports.exportQcQmData = exportQcQmData;
exports.getDetial = getDetial;
exports.modifyById = modifyById;
exports.queryById = queryById;
exports.queryForPage = queryForPage;
exports.queryJcForPage = queryJcForPage;
exports.queryStoreProdForPage = queryStoreProdForPage;
exports.queryStoreSreamForPage = queryStoreSreamForPage;
exports.queryearlyEnd = queryearlyEnd;
exports.queryteaAge = queryteaAge;
exports.saveConfig = saveConfig;
exports.teaAgeInventoryExportExcel = teaAgeInventoryExportExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 库存清单分页查询
 * @param {*} params
 */
function queryStoreProdForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/store/product/queryStoreProdForPage',
    method: 'get',
    params: params
  });
}
/**
 * 出入库流水分页查询
 * @param {*} params
 */
function queryStoreSreamForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/store/product/queryStoreSreamForPage',
    method: 'get',
    params: params
  });
}

/**
 * 库存预警分页查询
 * @param {*} params
 */
function queryForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/configStore/queryForPage',
    method: 'get',
    params: params
  });
}

/**
 * 根据ID查询库存预警信息
 * @param {*} pId
 */
function queryById(pId) {
  return (0, _request.default)({
    url: '/storage-center/configStore/queryById/' + pId,
    method: 'get'
  });
}

/**
 * 添加保存库存预警
 * @param {*} data
 */
function saveConfig(data) {
  return (0, _request.default)({
    url: '/storage-center/configStore/saveConfig',
    method: 'post',
    data: data
  });
}

/**
 * 根据ID编辑库存预警信息
 * @param {*} data
 */
function modifyById(data) {
  return (0, _request.default)({
    url: '/storage-center/configStore/modifyById',
    method: 'put',
    data: data
  });
}

/**
 * 删除库存预警
 * @param {*} pId
 */
function deleteById(pId) {
  return (0, _request.default)({
    url: '/storage-center/configStore/deleteById/' + pId,
    method: 'delete'
  });
}

/**
 * 导出服务
 * @param {*} query
 */
function excelOut(param) {
  return (0, _request.default)({
    url: '/storage-center/store/product/exportStoreStream',
    method: 'get',
    params: param,
    responseType: 'blob'
  });
}

/**
 *库存清单导出
 **/

function excelStorageOut(param) {
  return (0, _request.default)({
    url: '/storage-center/store/product/exportStoreProd',
    method: 'get',
    params: param,
    responseType: 'blob'
  });
}

/**
 * 获取预警产品详情
 * **/
function getDetial(param) {
  return (0, _request.default)({
    url: '/storage-center/configStore/queryById/' + param.pId,
    method: 'get'
  });
}

/**
 * 获取寄存库存分页查询
 * **/
function queryJcForPage(param) {
  return (0, _request.default)({
    url: '/storage-center/depositManager/queryForPage',
    method: 'get',
    params: param
  });
}

// 期初期末分页查询

function queryearlyEnd(param) {
  return (0, _request.default)({
    url: '/storage-center/earlyEnd/queryForPage',
    method: 'get',
    params: param
  });
}

/**
 *  导出寄库库存数据
 * **/
function exportData(param) {
  return (0, _request.default)({
    url: '/storage-center/depositManager/exportDeposit',
    method: 'get',
    params: param,
    responseType: 'blob'
  });
}

/**
 * 导出期初期末数据
 * **/
function exportQcQmData(param) {
  return (0, _request.default)({
    url: '/storage-center/earlyEnd/exportEarlyEnd',
    method: 'get',
    params: param,
    responseType: 'blob'
  });
}
// 库存茶龄清单列表查询
function queryteaAge(param) {
  return (0, _request.default)({
    url: '/storage-center/teaAgeInventory/queryForPage',
    method: 'get',
    params: param
  });
}
/**
 *  库存茶龄清单导出
 * **/
function teaAgeInventoryExportExcel(param) {
  return (0, _request.default)({
    url: '/storage-center/teaAgeInventory/teaAgeInventoryExportExcel',
    method: 'get',
    params: param,
    responseType: 'blob'
  });
}