var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          !_vm.$route.meta.noCache
            ? _c(
                "keep-alive",
                { attrs: { max: 8 } },
                [_c("router-view", { key: _vm.key })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$route.meta.noCache
            ? _c("router-view", { key: _vm.key })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }