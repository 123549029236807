"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportKcList = exportKcList;
exports.getKcList = getKcList;
exports.getKcMxList = getKcMxList;
exports.getQueryOrg = getQueryOrg;
exports.getTjQueryOrg = getTjQueryOrg;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取条件组织机构
function getTjQueryOrg(data) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取经销商库存列表
function getKcList(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/list',
    method: 'post',
    data: data
  });
}

// 获取经销商库存明细列表
function getKcMxList(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/stockDetail',
    method: 'post',
    data: data
  });
}

// 经销商库存导出
function exportKcList(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}