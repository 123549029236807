"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _winningLottery = require("@/api/operationalTools/winningLottery.js");
var _detailDialogs = _interopRequireDefault(require("./components/detailDialogs.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DailyPlan',
  components: {
    Pagination: _Pagination.default,
    detail: _detailDialogs.default
  },
  filters: {
    statusType: function statusType(type) {
      var obj = {
        0: 'danger',
        7: 'danger',
        6: 'success',
        1: '',
        2: 'success'
      };
      return obj[type];
    },
    statusStr: function statusStr(type) {
      var obj = {
        0: '未领取',
        7: '未领取',
        6: '已领取',
        1: '已领取',
        2: '已收货'
      };
      return obj[type];
    }
  },
  data: function data() {
    return {
      handleShow: false,
      deleteLoading: false,
      exportLoading: false,
      // 列表
      total: 0,
      tabHeight: '100%',
      listLoading: false,
      tableData: [],
      activityOptions: [],
      // 搜索类
      daterange: [],
      statusArr: [],
      orgselList: [],
      orgId: '',
      channelType: '',
      pageObj: {
        'pageNo': 1,
        // 页码
        'pageSize': 20 // 页数
      },

      searchForm: {
        'activityName': '',
        'activityId': '',
        'end': '',
        // 结束时间
        'begin': '' // 开始时间
      },

      selectionList: [],
      // 添加
      insertVisible: false,
      logisticsRules: {
        expressCompany: [{
          required: true,
          message: '请选择物流',
          trigger: 'blur'
        }, {
          min: 0,
          max: 30,
          message: '长度在 0 到 30 个字符',
          trigger: 'blur'
        }],
        expressOrder: [{
          required: true,
          message: '请输入快递单号',
          trigger: 'blur'
        }, {
          min: 5,
          max: 30,
          message: '长度在 5 到 30 个字符',
          trigger: 'blur'
        }]
      },
      logisticsOptions: [{
        label: '顺丰快递',
        id: '1'
      }, {
        label: '中通快递',
        id: '2'
      }, {
        label: '圆通快递',
        id: '3'
      }, {
        label: '德邦快递',
        id: '4'
      }, {
        label: '京东快递',
        id: '5'
      }, {
        label: '邮政包裹',
        id: '6'
      }, {
        label: '极兔快递',
        id: '7'
      }, {
        label: '申通快递',
        id: '8'
      }, {
        label: '韵达快递',
        id: '9'
      }, {
        label: '百世快递',
        id: '10'
      }, {
        label: '优速快递',
        id: '11'
      }, {
        label: '申通快递',
        id: '12'
      }, {
        label: '天天快递',
        id: '13'
      }],
      logisticsForm: {
        'expressCompany': '',
        'expressOrder': '',
        'receiveId ': ''
      },
      insertType: '',
      // 查看详情
      detailVisible: false,
      pId: '',
      channelCode: '',
      updeteVisible: false,
      updateForm: {
        'receiveId ': '',
        'status': ''
      },
      infoRow: {}
    };
  },
  mounted: function mounted() {},
  activated: function activated() {
    var _this = this;
    this.$nextTick(function () {
      this.tabHeight = (0, _utils.tableHeight)();
    });
    this.channelCode = window.sessionStorage.getItem('channelCode');
    this.getList();
    this.getActivityFun();
    this.$nextTick(function () {
      _this.$refs.table.doLayout();
    });
  },
  methods: {
    handleShowFun: function handleShowFun() {
      this.handleShow = !this.handleShow;
      this.$nextTick(function () {
        this.tabHeight = (0, _utils.tableHeight)();
      });
    },
    getActivityFun: function getActivityFun() {
      var _this2 = this;
      (0, _winningLottery.getActivity)().then(function (res) {
        if (res.data.code === 200) {
          _this2.activityOptions = res.data.data;
        }
      });
    },
    // 搜索
    searchFun: function searchFun() {
      this.pageObj.pageNo = 1;
      this.getList();
    },
    // 获取数据
    getList: function getList() {
      var _this3 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var searchForm = JSON.parse(JSON.stringify(this.searchForm));
      this.listLoading = true;
      (0, _winningLottery.getLogisticsList)(searchForm, this.pageObj).then(function (response) {
        _this3.listLoading = false;
        if (response.data.code != 200) {
          _this3.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this3.total = parseInt(response.data.data.total);
            _this3.tableData = response.data.data.records;
          } else {
            _this3.total = 0;
            _this3.tableData = [];
          }
        }
      }).catch(function (res) {
        _this3.listLoading = false;
      });
    },
    // 重置搜索表单
    resetForm: function resetForm() {
      this.daterange = [];
      this.pageObj = {
        'pageNo': 1,
        // 页码
        'pageSize': 20 // 页数
      };

      this.searchForm = {
        'activityId': '',
        'end': '',
        // 结束时间
        'begin': '' // 开始时间
      };

      this.getList();
    },
    // 时间搜索
    dateChangeFun: function dateChangeFun(val) {
      if (!val) {
        val = [];
      }
      this.searchForm.begin = val[0] || '';
      this.searchForm.end = val[1] || '';
    },
    detailFun: function detailFun() {},
    // 查看审核打开弹窗
    showDialogFun: function showDialogFun(type, row) {
      this.insertType = type;
      if (type === 'look' || type === 'verify') {
        this.detailVisible = true;
        this.infoRow = row;
      } else {
        this.insertVisible = true;
        this.infoLoading = true;
      }
    },
    exportExcelFun: function exportExcelFun() {
      var _this4 = this;
      this.exportLoading = true;
      var searchForm = JSON.parse(JSON.stringify(this.searchForm));
      (0, _winningLottery.ExportLogisticsList)(searchForm).then(function (res) {
        console.log(res);
        _this4.exportLoading = false;
        if (res.status !== 200) {
          _this4.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            console.log(res.data);
            _this4.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: res.data.type
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            if (res.data.type === 'application/octet-stream') {
              downloadElement.download = '实物发货记录.csv';
            } else {
              downloadElement.download = '实物发货记录.xlsx';
            }
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      });
    },
    // 关闭发货
    logisticsDialogClose: function logisticsDialogClose() {
      this.insertVisible = false;
      this.logisticsForm = {
        'expressCompany': '',
        'expressOrder': '',
        'receiveId ': ''
      };
    },
    openLogistics: function openLogistics(id) {
      this.insertVisible = true;
      this.logisticsForm.receiveId = id;
    },
    logisticsSubmit: function logisticsSubmit() {
      var _this5 = this;
      this.$refs['logisticsForm'].validate(function (valid) {
        if (valid) {
          (0, _winningLottery.logisticsSend)(_this5.logisticsForm).then(function (res) {
            console.log(res);
            if (res.data.code === 200) {
              _this5.$message({
                type: 'success',
                message: '发货成功'
              });
              _this5.getList();
              _this5.logisticsDialogClose();
            } else {
              _this5.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    updeteDialogClose: function updeteDialogClose() {
      this.updeteVisible = false;
      this.updateForm = {
        'status': '',
        'receiveId ': ''
      };
    },
    openUpdate: function openUpdate(id) {
      this.updateForm.receiveId = id;
      this.updeteVisible = true;
    },
    updeteSubmit: function updeteSubmit() {
      var _this6 = this;
      this.$refs['updateForm'].validate(function (valid) {
        if (valid) {
          (0, _winningLottery.logisticsStatusUpdate)(_this6.updateForm).then(function (res) {
            console.log(res);
            if (res.data.code === 200) {
              _this6.getList();
              _this6.updeteDialogClose();
              _this6.$message({
                type: 'success',
                message: '操作成功'
              });
            } else {
              _this6.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;