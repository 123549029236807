"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
var _elTreeTransfer = _interopRequireDefault(require("el-tree-transfer"));
var _chanelrole = require("@/api/systems/chanelrole");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Auth',
  components: {
    treeTransfer: _elTreeTransfer.default
  },
  props: {
    dialogVisible: Boolean,
    authData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      title: ['待选', '已选'],
      mode: 'transfer',
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      },
      dstate: false,
      fromData: [],
      toData: [],
      selectedData: [],
      mulitArr: [],
      checkedKeys: [],
      mloading: false,
      checkall: false,
      selectedBtn: false,
      defaultCheckedKeys: [],
      roleCode: ''
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var that = this;
      if (val == true) {
        console.log(that.authData);
        that.fromData = that.authData.fromData;
        that.toData = that.authData.toData;
        that.roleCode = that.authData.roleCode;
        that.selectedData = [];
        that.checkall = false;
        var origin = that.authData.originData;
        if (origin != null && origin.length > 0) {
          origin.forEach(function (item) {
            that.selectedData.push(item.resourceCode);
          });
        } else {
          that.selectedData = [];
        }
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.clearCheck();
      that.$emit('close');
    },
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode: function changeMode() {
      if (this.mode == 'transfer') {
        this.mode = 'addressList';
      } else {
        this.mode = 'transfer';
      }
    },
    // 监听穿梭框组件添加
    add: function add(fromData, toData, obj) {
      var that = this;
      that.fromData = fromData;
      that.toData = toData;
      var arr = obj.harfKeys.concat(obj.keys);
      arr.forEach(function (item) {
        that.selectedData.push(item);
      });
      that.selectedData = that.unique(that.selectedData);
    },
    // 监听穿梭框组件移除
    remove: function remove(fromData, toData, obj) {
      var that = this;
      var removeData = obj.keys;
      var hasData = that.selectedData;
      that.fromData = fromData;
      that.toData = toData;
      for (var i = 0; i < removeData.length; i++) {
        for (var j = 0; j < hasData.length; j++) {
          if (removeData[i] == hasData[j]) {
            hasData.splice(j, 1);
            break;
          }
        }
      }
    },
    // 数组去重
    unique: function unique(arr) {
      return Array.from(new Set(arr));
    },
    // 提交
    submitForm: function submitForm() {
      var that = this;
      var param = {};
      param.roleCode = that.roleCode;
      param.resouceList = that.unique(that.selectedData);
      that.dstate = true;
      that.mloading = true;
      (0, _chanelrole.rankRoleAuths)(param).then(function (res) {
        if (res.data.code == 200) {
          that.dstate = false;
          that.mloading = false;
          that.$message({
            type: 'success',
            message: '提交成功'
          });
          that.$parent.getList();
          that.resetForm();
        } else {
          that.dstate = false;
          that.mloading = false;
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.dstate = false;
        that.mloading = false;
      });
    },
    resetForm: function resetForm() {
      var that = this;
      that.handleClose();
      that.clearCheck();
    },
    clearCheck: function clearCheck() {
      var that = this;
      that.$refs['js-tree-transfer'].clearChecked();
    },
    // 左侧源数据选中事件,暂保留根据需求变更启用
    leftCheckChange: function leftCheckChange(nodeObj, treeObj, checkAll) {
      console.log(nodeObj);
      console.log(treeObj);
      console.log(checkAll);
    },
    // 右侧目标数据选中事件，,暂保留根据需求变更启用
    rightCheckChange: function rightCheckChange(nodeObj, treeObj, checkAll) {
      console.log(nodeObj);
      console.log(treeObj);
      console.log(checkAll);
    }
  }
};
exports.default = _default2;