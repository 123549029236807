"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: true
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    },
    sourceEnum: {
      type: Number,
      default: null
    },
    requestEnum: {
      type: Number,
      default: null
    },
    relationProductList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        isCode: this.isCode,
        sourceEnum: ''
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.setsel();
      }
    }
  },
  mounted: function mounted() {
    this.getClass();
    this.getBrand();
    this.getPack();
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var that = this;
      // that.selLoading = true
      that.$nextTick(function () {
        if (that.relationProductList && that.relationProductList.length) {
          that.relationProductList.forEach(function (row) {
            that.$refs.multipleTable.toggleRowSelection(row);
          });
        }
        // that.selLoading = false
      });

      console.log(909090);
      that.getList();
      // setTimeout(() => {
      //   if (that.relationProductList.length) {
      //     that.$refs.multipleTable.toggleRowSelection(this.tableData[0])
      //   }
      // }, 500)
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getClass: function getClass() {
      var _this = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this.normalList = data;
          } else {
            _this.normalList = [];
          }
        }
      });
    },
    getBrand: function getBrand() {
      var _this2 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code !== 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.brandList = res.data.data;
          } else {
            _this2.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this3 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code !== 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.packList = res.data.data;
          } else {
            _this3.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      var that = this;
      this.listLoading = true;
      var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(that.searchForm)));
      var le = that.searchForm.productClassId.length - 1 || '';
      submitData.productClassId = that.searchForm.productClassId[le] || '';
      submitData.sourceEnum = that.requestEnum;
      (0, _product.productArchivesList)(submitData).then(function (res) {
        console.log(res, '返回列表数据');
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        isCode: this.isCode
      };
      this.filterText = '';
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      var index = this.tableData.findIndex(function (item) {
        return item.pId == row.pId;
      });
      this.$refs.multipleTable.toggleRowSelection(this.tableData[index], false);
    }
  }
};
exports.default = _default2;