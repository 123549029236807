var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      class: { hide: _vm.fileList.length >= _vm.limitCount || _vm.disabled },
      attrs: {
        headers: _vm.myHeaders,
        "list-type": "picture-card",
        action: _vm.uploadUrl,
        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
        "on-success": _vm.handleAvatarSuccess,
        "on-error": _vm.handleError,
        "on-change": _vm.selectImg,
        "on-remove": _vm.handleRemove,
        "before-upload": _vm.beforeAvatarUpload,
        "before-remove": _vm.checkType,
        "file-list": _vm.fileList,
        name: "file",
        limit: _vm.limitCount,
      },
    },
    [
      !_vm.disabled
        ? _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("i", {
        staticClass: "el-icon-plus",
        attrs: { slot: "default" },
        slot: "default",
        scopedSlots: _vm._u([
          {
            key: "file",
            fn: function (ref) {
              var file = ref.file
              return _c("div", {}, [
                _c("img", {
                  staticClass: "el-upload-list__item-thumbnail",
                  staticStyle: { width: "200px", height: "450px" },
                  attrs: { src: file.url },
                }),
                _vm._v(" "),
                !_vm.disabled
                  ? _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-delete",
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(file)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-delete" })]
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }