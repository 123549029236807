"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _dirDialog = _interopRequireDefault(require("@/components/dirDialog"));
var _recDialog = _interopRequireDefault(require("@/components/recDialog"));
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemdictionaryindex';
var _default = {
  name: 'Systemdictionaryindex',
  components: {
    Pagination: _Pagination.default,
    dirDialog: _dirDialog.default,
    recDialog: _recDialog.default
  },
  filters: {
    levelText: function levelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '1',
        1: '2',
        2: '3'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      dirVisible: false,
      recVisible: false,
      title: '添加包装比例',
      total: 0,
      searchForm: {
        current: 1,
        dataDictionaryCode: '',
        dataDictionaryName: '',
        isDelete: 1,
        size: 20
      },
      isShow: '',
      ruleForm: {
        bewrite: '',
        dictionaryCode: '',
        dictionaryName: '',
        isDelete: 1
      },
      rules: {
        dictionaryName: [{
          required: true,
          message: '字典名称不能为空，请维护',
          trigger: 'change'
        }],
        dictionaryCode: [{
          required: true,
          message: '字典编码不能为空，请维护',
          trigger: 'change'
        }]
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      id: '',
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.tableData && that.tableData.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.tableData = [];
      that.total = 0;
    }
  },
  methods: {
    recClose: function recClose() {
      this.getList();
      this.recVisible = false;
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _dictionary.getDataDictionaryList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        dataDictionaryCode: '',
        dataDictionaryName: '',
        isDelete: 1,
        size: 20
      };
      this.getList();
    },
    add: function add() {
      var setHeaderId = this.authId('addbtn', this.apis);
      this.title = '添加数据字典';
      this.dialogVisible = true;
    },
    rec: function rec() {
      var setHeaderId = this.authId('rubishbtn', this.apis);
      this.recVisible = true;
    },
    edit: function edit(row) {
      var setHeaderId = this.authId('updatebtn', this.apis);
      this.title = '编辑数据字典';
      this.ruleForm = {
        pid: row.pid,
        bewrite: row.bewrite,
        dictionaryCode: row.dictionaryCode,
        dictionaryName: row.dictionaryName,
        isDelete: row.isDelete
      };
      this.dialogVisible = true;
    },
    del: function del(row) {
      var _this = this;
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = _this.authId('deletebtn', _this.apis);
        (0, _dictionary.delDataDictionary)({
          pid: row.pid
        }).then(function (res) {
          if (res.data.code != 200) {
            _this.$message.error(res.data.msg);
          } else {
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.getList();
          }
        });
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        bewrite: '',
        dictionaryCode: '',
        dictionaryName: '',
        isDelete: 1
      };
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var submitData = (0, _utils.filterKeyNull)(_this2.ruleForm);
          if (_this2.ruleForm.pid) {
            (0, _dictionary.updateDataDictionary)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          } else {
            (0, _dictionary.addDataDictionary)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          }
        }
      });
    },
    dirOption: function dirOption(row) {
      var setHeaderId = this.authId('setbtn', this.apis);
      this.id = row.pid;
      this.isShow = row.dictType;
      this.dirVisible = true;
    }
  }
};
exports.default = _default;