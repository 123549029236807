var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "orgId", label: "所属企业:" } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    multiple: false,
                                    "flatten-search-results": true,
                                    placeholder: "请选择组织...",
                                    options: _vm.orgOptions,
                                  },
                                  model: {
                                    value: _vm.searchForm.orgId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "orgId", $$v)
                                    },
                                    expression: "searchForm.orgId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "storeId", label: "库房名称:" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.searchForm.storeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "storeId", $$v)
                                      },
                                      expression: "searchForm.storeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.TypeDataComs.StoreList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.storeHouseName,
                                          value: item.pId,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "productName",
                                  label: "产品名称:",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "createDate", label: "月份:" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "month",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM",
                                  },
                                  model: {
                                    value: _vm.searchForm.createDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "createDate",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.createDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("product-dialog", {
                  ref: "selProduct",
                  on: { change: _vm.setSel },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "head-btn-group",
                    staticStyle: { width: "230px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.searchBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.searchBtn.icon,
                        },
                        on: { click: _vm.handleFilter },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.resetBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.resetBtn.icon,
                        },
                        on: { click: _vm.reset },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group fun-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.tableData,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: _vm.tableConfig.align,
                    width: "120",
                    label: "序号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "月份",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(row.createDate))]
                        },
                      },
                    ],
                    null,
                    false,
                    3122602159
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库房名称",
                    width: "180",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(row.storeName))]
                        },
                      },
                    ],
                    null,
                    false,
                    3858420455
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    prop: "productCode",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "productName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装比例",
                    prop: "productSpec",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "期初库存",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "净库存(箱)",
                        prop: "earlyNetInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "寄存库存(箱)",
                        prop: "earlyDepositeInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "实际库存(箱)",
                        prop: "earlyActualInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "期末库存",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "净库存(箱)",
                        prop: "endNetInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "寄存库存(箱)",
                        prop: "endDepositeInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "实际库存(箱)",
                        prop: "endActualInventory",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total,sizes,prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }