var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tableDialog",
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "布奖订单选择",
            visible: _vm.visible,
            width: "1200px !important",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section",
                      staticStyle: {
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.listQuery,
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                            "label-suffix": ":",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            prop: "billNo",
                                            label: "单据编号",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { placeholder: "单据编号" },
                                            model: {
                                              value: _vm.listQuery.billNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "billNo",
                                                  $$v
                                                )
                                              },
                                              expression: "listQuery.billNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            prop: "sellerId",
                                            label: "发货单位",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.listQuery.outOrgName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "outOrgName",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "listQuery.outOrgName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            prop: "buyerId",
                                            label: "收货单位",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.listQuery.inOrgName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "inOrgName",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "listQuery.inOrgName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { label: "发货开始时间" },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                            },
                                            model: {
                                              value: _vm.listQuery.startTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "startTime",
                                                  $$v
                                                )
                                              },
                                              expression: "listQuery.startTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { label: "发货结束时间" },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                            },
                                            model: {
                                              value: _vm.listQuery.endTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "endTime",
                                                  $$v
                                                )
                                              },
                                              expression: "listQuery.endTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.formConfig.btnFollow
                                    ? _c(
                                        "el-col",
                                        {
                                          staticClass: "head-btn-group",
                                          style: {
                                            width: _vm.formConfig.btnArea,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [_vm._v(" ")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig
                                                      .searchBtn.type,
                                                    size: _vm.btnConfig.size,
                                                    icon: _vm.btnConfig
                                                      .searchBtn.icon,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.fetchData()
                                                      _vm.listQuery.pageNo = 1
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.btnConfig.searchBtn
                                                        .text
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig.resetBtn
                                                      .type,
                                                    size: _vm.btnConfig.size,
                                                    icon: _vm.btnConfig.resetBtn
                                                      .icon,
                                                  },
                                                  on: { click: _vm.restFun },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.btnConfig.resetBtn
                                                        .text
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            height: "400px",
                            data: _vm.list,
                            fit: "",
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            "row-key": "pid",
                            "highlight-current-row": "",
                          },
                          on: { "row-click": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "billNo",
                              label: "单据编号",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "outOrgName",
                              label: "发货单位",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "inOrgName",
                              label: "收货单位",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "createTime",
                              label: "发货时间",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "单选", width: "55" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "radio",
                                        attrs: { label: scope.row.billNo },
                                        model: {
                                          value: _vm.radio,
                                          callback: function ($$v) {
                                            _vm.radio = $$v
                                          },
                                          expression: "radio",
                                        },
                                      },
                                      [_vm._v(" ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total>0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.listQuery.pageNo,
                          limit: _vm.listQuery.pageSize,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.listQuery, "pageNo", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.listQuery, "pageSize", $event)
                          },
                          pagination: _vm.fetchData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择发货单位" },
        on: { change: _vm.setSel },
      }),
      _vm._v(" "),
      _c("purchaseDialo", {
        ref: "channelSelectt",
        attrs: { title: "选择收货单位" },
        on: { change: _vm.setSell },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }