"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billDeriveRecord = billDeriveRecord;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 数码替换操作记录分页查询
 * @param {*} params 
 */
function billDeriveRecord(params) {
  return (0, _request.default)({
    url: '/storage-center/ccBillDeriveRecord/billDeriveRecord',
    method: 'get',
    params: params
  });
}