"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompyUser = addCompyUser;
exports.checkPwd = exports.checkPhone = exports.checkEmail = void 0;
exports.distributionUserRole = distributionUserRole;
exports.editUserInfo = editUserInfo;
exports.getAllQdRoles = getAllQdRoles;
exports.getCompanyList = getCompanyList;
exports.getRoleUser = getRoleUser;
exports.getSpulyInfo = getSpulyInfo;
exports.getUserInfo = getUserInfo;
exports.scanUserRole = scanUserRole;
exports.setUserPwd = setUserPwd;
exports.startItems = startItems;
exports.startUser = startUser;
exports.stopItems = stopItems;
exports.stopUser = stopUser;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取供货角色下拉列表
function getAllQdRoles() {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getAllGhRoles',
    method: 'get'
  });
}

// 获取供货用户列表
function getCompanyList(param, data) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/supplierUserlist?current=' + param.current + '&size=' + param.size,
    method: 'post',
    data: data
  });
}

// 启用
function startUser(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/updateSupplierEnableStart',
    method: 'put',
    params: param
  });
}

// 禁用
function stopUser(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/updateSupplierEnableEnd',
    method: 'put',
    params: param
  });
}

// 批量禁用
function stopItems(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/updateSupplierBatchEnableEnd',
    method: 'put',
    params: param
  });
}

// 批量启用
function startItems(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/updateSupplierBatchEnableStart',
    method: 'put',
    params: param
  });
}

// 查看供货用户角色
function scanUserRole(param) {
  return (0, _request.default)({
    url: '/api-user/userRRole/getSupplierUserRole',
    method: 'get',
    params: param
  });
}

// 添加供货用户
function addCompyUser(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/addSupplierUserInfo',
    method: 'post',
    params: param
  });
}

// 获取供货用户可分配角色
function getRoleUser(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/getGhForList',
    method: 'get',
    params: param
  });
}

// 重置供货用户密码
function setUserPwd(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/resettingSupplierPwd',
    method: 'put',
    params: param
  });
}

// 获取供货用户详情
function getUserInfo(param) {
  return (0, _request.default)({
    url: '/api-user/supplierUser/getSupplierUserDetail',
    method: 'get',
    params: param
  });
}

// 获取供应商列表
function getSpulyInfo(data) {
  return (0, _request.default)({
    url: '/api-basic/packSupplierInfo/list',
    method: 'post',
    data: data
  });
}

// 修改供货商用户
function editUserInfo(param) {
  return (0, _request.default)({
    url: 'api-user/supplierUser/updateSupplierUserInfo',
    method: 'put',
    params: param
  });
}

// 供货用户分配角色
function distributionUserRole(data) {
  return (0, _request.default)({
    url: '/api-user/userRRole/distributionSupplierUserRole',
    method: 'post',
    data: data
  });
}

// 验证规则
var checkPwd = function checkPwd(rule, value, callback) {
  if (value) {
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,16}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('密码必须为字母、数字组合，且大于6位'));
    }
  } else {
    return callback(new Error('请输入密码'));
  }
};
exports.checkPwd = checkPwd;
var checkPhone = function checkPhone(rule, value, callback) {
  if (value) {
    var reg = /^1[23456789]\d{9}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  } else {
    return callback(new Error('请输入手机号'));
  }
};
exports.checkPhone = checkPhone;
var checkEmail = function checkEmail(rule, value, callback) {
  if (value) {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确格式邮箱'));
    }
  } else {
    callback();
  }
};
exports.checkEmail = checkEmail;