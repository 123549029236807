"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _word = require("@/api/prizeSetting2/word");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SelectWord',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: '',
      listLoading: true,
      total: 0,
      buyer: '',
      seller: '',
      dateVal: [],
      queryParams: {
        prizeType: 500,
        pageSize: 10,
        pageNo: 1,
        prizeName: '',
        word: '',
        status: '1'
      },
      list: [],
      listCopy: [],
      list1: [],
      list1Copy: this.oldList,
      upList: [],
      downList: [],
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getInitData();
  },
  methods: {
    getInitData: function getInitData() {
      var _this = this;
      this.listLoading = true;
      (0, _word.getPrizeList)(this.queryParams).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this.total = Number(res.data.total);
          _this.list = res.data.records;
        } else {
          _this.$message.closeAll();
          _this.$message(res.msg);
        }
        _this.listLoading = false;
      }).then(function () {
        // this.data.length && this.list.length &&
        // this.data.map(row => {
        //   const rowItem = this.list.find(item => {
        //     return row.prizeId == item.prizeId
        //   })
        //   this.$refs.multipleTable.toggleRowSelection(rowItem || row, true)
        // })
        _this.multipleSelection = _this.data;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    clear: function clear() {
      // this.$refs.multipleTable.clearSelection()
      this.multipleSelection = [];
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      (0, _word.getPrizeList)(this.queryParams).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this2.total = Number(res.data.total);
          _this2.list = res.data.records;
        } else {
          _this2.$message.closeAll();
          _this2.$message.error(res.msg);
        }
        _this2.listLoading = false;
      }).then(function () {}).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.list = [];
      this.$emit('close');
    },
    update: function update() {
      if (this.multipleSelection.length > 8) {
        this.$message.error('最多选择8个字');
        return;
      }
      var sumbitData = this.multipleSelection.map(function (item, index) {
        return {
          prizeId: item.prizeId,
          prizeName: item.prizeName,
          word: item.word,
          prizeRank: index + 1
        };
      });
      this.$emit('change', sumbitData);
      this.handleClose();
    },
    tagClose: function tagClose(row, index) {
      // this.$nextTick(() => {
      //   const closeItem = this.list.find((item) => { return row.prizeId == item.prizeId })
      //   this.$refs.multipleTable.toggleRowSelection(closeItem || row, false)
      // })
      this.multipleSelection.splice(index, 1);
    },
    upWord: function upWord(data, index) {
      var temp = this.multipleSelection[index - 1];
      this.multipleSelection[index - 1] = data;
      this.multipleSelection[index] = temp;
      this.multipleSelection = this.multipleSelection.slice();
    },
    downWord: function downWord(data, index) {
      var temp = this.multipleSelection[index + 1];
      this.multipleSelection[index + 1] = data;
      this.multipleSelection[index] = temp;
      this.multipleSelection = this.multipleSelection.slice();
    },
    addWrod: function addWrod(data) {
      if (this.multipleSelection.length >= 8) {
        this.$message.error('最多添加8个字');
        return;
      }
      this.multipleSelection.push(data);
    },
    restFun: function restFun() {
      this.queryParams.prizeName = '';
      this.queryParams.word = '';
      // this.queryParams.status = ''
      this.getPrizeList();
    }
  }
};
exports.default = _default2;