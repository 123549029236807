import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=543547be&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=543547be&lang=scss&"
import style1 from "./detail.vue?vue&type=style&index=1&id=543547be&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543547be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('543547be')) {
      api.createRecord('543547be', component.options)
    } else {
      api.reload('543547be', component.options)
    }
    module.hot.accept("./detail.vue?vue&type=template&id=543547be&scoped=true&", function () {
      api.rerender('543547be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orderCenter/costorder-cc/detail.vue"
export default component.exports