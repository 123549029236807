var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "h5",
        { staticClass: "title" },
        [
          _c("div", [
            _c("img", {
              attrs: { src: require("@/images/icon-3.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("经销商采供关系")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "billType",
                class: _vm._f("typeClass")(_vm.detailsData.status),
              },
              [_vm._v(_vm._s(_vm.detailsData.status === 1 ? "启用" : "停用"))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "details-wrap" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item-wrap" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("供货单位编码")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.detailsData.supplierCode))]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item-wrap" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("供货单位名称")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.detailsData.supplierName))]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item-wrap" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("采购单位编码")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.detailsData.purchaseCode))]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item-wrap" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("采购单位名称")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.detailsData.purchaseName)),
                    _c("span"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "status-icon": "",
                "label-suffix": ":",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品编码", prop: "productCode" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.searchForm.productCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "productCode", $$v)
                              },
                              expression: "searchForm.productCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "productName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "50",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.searchForm.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "productName", $$v)
                              },
                              expression: "searchForm.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属分类",
                            "label-width": "90px",
                            prop: "productClassId",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择",
                              options: _vm.normalList,
                              props: {
                                value: "pId",
                                label: "productClassName",
                                children: "children",
                              },
                              filterable: "",
                            },
                            model: {
                              value: _vm.searchForm.productClassId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "productClassId", $$v)
                              },
                              expression: "searchForm.productClassId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "260px" },
                      attrs: { label: "" },
                    },
                    [
                      _c("el-form-item", [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(" "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button-group",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-left": "20px",
                    "margin-bottom": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.searchBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.searchBtn.icon,
                        plain: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchForm.current = 1
                          _vm.getDetailFun()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.resetBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.resetBtn.icon,
                        plain: "",
                      },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: _vm.tabHeight,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 1 || row.status == null
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "最后更新时间", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.updateTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 1 || row.status == null
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.stopFun(row.pId)
                                  },
                                },
                              },
                              [_vm._v("停用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.startFun(row.pId)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-wrap" },
            [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        plain: "",
                        icon: _vm.btnConfig.addBtn.icon,
                      },
                      on: { click: _vm.addProductFun },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text + "产品"))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getDetailFun,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.productVisible
        ? _c("productDialog", {
            ref: "selProduct",
            attrs: {
              visible: _vm.productVisible,
              products: _vm.products,
              "channel-id": _vm.channelId,
              "channel-collection-id": _vm.searchForm.collectionSupplyId,
            },
            on: { close: _vm.closeProduct, change: _vm.setProduct },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phead" }, [
      _c("div", { staticClass: "head-btn-group" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }