"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _typeConfig = require("@/api/mark/configure/typeConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { labelList, modelList } from '@/api/mark/configure/labelGroupManage'

// import { isNull } from 'util'

var codeTypeList = [];
var listQuery = {
  current: 1,
  size: 20,
  flagId: '',
  supplierType: ''
};
var _default = {
  name: 'ComplexTable',
  directives: {
    waves: _waves.default
  },
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '瓶',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内层级',
        2: '层级',
        3: '外层级'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      if (item.length > 0) {
        return statusPack[packLevel] + statusMark[markType] + '-' + item[0].markLen + '位';
      }
    }
  },
  props: ['flagCode'],
  data: function data() {
    return {
      imgUrl: require("../../../../../images/origin.png"),
      modelList: [],
      tableKey: 0,
      labelList: null,
      total: 0,
      listLoading: false,
      listQuery: listQuery
    };
  },
  mounted: function mounted() {
    this.getCodeType();
  },
  methods: {
    getCodeType: function getCodeType() {
      var _this = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getModelList: function getModelList() {
      var that = this;
      that.modeLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      (0, _supplierRFlag.flagTemplatePage)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.modelList = response.data.data.records;
          } else {
            that.total = 0;
            that.modelList = [];
          }
          that.modeLoading = false;
        }
      }).catch(function (res) {
        that.modeLoading = false;
      });
    }
  }
};
exports.default = _default;