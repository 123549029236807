"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _wxactivityManage = require("@/api/wxhyyy/wxactivityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
var _default2 = {
  name: 'Tips',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    oldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    businessType: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      listLoading: true,
      visible: false,
      total: 0,
      listQuery: {
        pageNow: 1,
        pageSize: 5,
        productNum: undefined,
        productName: undefined
      },
      list: [],
      list1: [],
      listCopy: [],
      list1Copy: this.oldList,
      upList: [],
      downList: []
    };
  },
  created: function created() {
    this.fetchData();
    this.list1 = this.list1Copy;
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _wxactivityManage.getProduct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        businessType: this.businessType
      })).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.status * 1 === 200) {
          _this.list = res.data.records;
          // this.list = res.data.lstResult
          _this.listCopy = JSON.parse(JSON.stringify(res.data.records));
          // this.listCopy = JSON.parse(JSON.stringify(res.data.lstResult))
          _this.total = res.data.total;
          // this.total = res.data.pageTotal
        }

        _this.listLoading = false;
      });
      // getChosenProduct(this.listQuery).then(res => {
      //   // this.list = response.data.items
      //   // this.total = response.data.total
      //   this.listLoading = false
      // })
    },
    handleClose: function handleClose() {
      this.list = this.listCopy;
      this.list1 = this.list1Copy;
      this.visible = false;
      this.$emit('close');
    },
    moveDownList: function moveDownList(val) {
      // this.downList = val
      this.downList = this.newObj(val, this.list1);
    },
    moveUpList: function moveUpList(val) {
      this.upList = val;
    },
    moveDown: function moveDown() {
      this.list1 = (0, _toConsumableArray2.default)(new Set([].concat((0, _toConsumableArray2.default)(this.list1), (0, _toConsumableArray2.default)(this.downList))));
    },
    moveUp: function moveUp() {
      this.list1 = this.newObj(this.list1, this.upList);
    },
    newObj: function newObj(arr1, arr2) {
      var arr = [];
      arr1.forEach(function (element) {
        if (JSON.stringify(arr2).indexOf(JSON.stringify(element)) === -1) {
          arr.push(element);
        }
      });
      return arr;
    },
    productUpdate: function productUpdate() {
      // var aProduct = []
      // var aId = []
      // this.list1.forEach(item => {
      //   aProduct.push(item.PRODUCT_NAME)
      //   aId.push(item.PRODUCT_NO)
      // })
      this.$emit('fn', this.list1);
      this.visible = false;
    },
    search: function search() {}
  }
};
exports.default = _default2;