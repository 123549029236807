"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = "marketingManageoperationalToolsactivityParticipationRecordindex";
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  name: "marketingManageoperationalToolsactivityParticipationRecordindex",
  data: function data() {
    return {
      formInline: {},
      rangeTime1: "",
      tableData: [{
        code: 123456,
        user: "小明",
        hd: "活动1",
        openid: "asdshvbdhsv22ac103s0c",
        zjsl: 15.0,
        jpxs: "红包",
        ljzt: "未领取",
        id: "123456",
        zjjg: "再来一次",
        iphone: 12345678912,
        ip: 123456,
        address: "北京市天安门人民广场",
        time: "2021-12-12 12:12:12"
      }],
      listLoading: false,
      downLoading: false,
      tabHeight: "100%",
      expandTxt: "展开",
      showCol: false,
      total: 10,
      params: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.formInline.status = "";
    var hasCache1 = this.tableData.length > 0 ? true : false;
    if (!hasCache1) {}
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    sj: function sj() {},
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = "收起";
      } else {
        that.expandTxt = "展开";
      }
    }
  }
};
exports.default = _default;