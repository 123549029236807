var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "系统用户-批量修改组织",
        model: _vm.allEdit,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.mloading,
                expression: "mloading",
              },
            ],
            attrs: { "label-width": "100px" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "调整到组织:" } },
              [
                _c("treeselect", {
                  attrs: {
                    multiple: false,
                    "flatten-search-results": true,
                    placeholder: "请选择组织...",
                    options: _vm.options,
                  },
                  model: {
                    value: _vm.orgId,
                    callback: function ($$v) {
                      _vm.orgId = $$v
                    },
                    expression: "orgId ",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "align-right", staticStyle: { padding: "20px 0" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", disabled: _vm.dstate },
                on: { click: _vm.submitForm },
              },
              [_vm._v("确定")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.resetForm } }, [_vm._v("取消")]),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }