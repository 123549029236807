var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "label-suffix": ":",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "订单号" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.orderno,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "orderno", $$v)
                                      },
                                      expression: "formInline.orderno",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "活动名称" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "name", $$v)
                                      },
                                      expression: "formInline.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "创建时间" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    on: { change: _vm.dateFun },
                                    model: {
                                      value: _vm.dateVlaue,
                                      callback: function ($$v) {
                                        _vm.dateVlaue = $$v
                                      },
                                      expression: "dateVlaue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态", prop: "type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.formInline.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formInline, "state", $$v)
                                        },
                                        expression: "formInline.state",
                                      },
                                    },
                                    _vm._l(
                                      [
                                        { label: "全部", value: "" },
                                        { value: 100, label: "未开始" },
                                        { value: 200, label: "进行中" },
                                        { value: 300, label: "已结束" },
                                        { value: 600, label: "暂停中" },
                                      ],
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.value,
                                            label: item.label,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "布奖形式",
                                    prop: "calculation",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        "padding-left": "5px",
                                        "padding-right": "5px",
                                      },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.formInline.calculation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "calculation",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.calculation",
                                      },
                                    },
                                    _vm._l(_vm.awardsoptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.formInline.pageNo = 1
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.restFun },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.formInline.pageNo = 1
                                  _vm.fetchData()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: { click: _vm.restFun },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.handleShow = !_vm.handleShow
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.handleShow ? "收起" : "展开") +
                                  "\n          "
                              ),
                              _c("i", {
                                class: _vm.handleShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.creatAct },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.downLoading,
                              },
                              on: { click: _vm.getSoldExcel },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    height: _vm.tabHeight,
                    "element-loading-text": "Loading",
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      label: "活动名称",
                      prop: "name",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单号",
                      prop: "orderno",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      "min-width": "130",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "布奖形式",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.calculation * 1 == 1
                                ? _c("span", [_vm._v("随机布奖")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.calculation * 1 == 2
                                ? _c("span", [_vm._v("均匀布奖")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2934534913
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      label: "开始时间",
                      prop: "startTime",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      label: "结束时间",
                      prop: "endTime",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created_at",
                      label: "创建者",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.creator))]
                          },
                        },
                      ],
                      null,
                      false,
                      1455984449
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.createTime))]
                          },
                        },
                      ],
                      null,
                      false,
                      1993746252
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created_at",
                      label: "更新人",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.updator || "无"))]
                          },
                        },
                      ],
                      null,
                      false,
                      3661507572
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新时间",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(_vm._s(scope.row.updateTime || "无")),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4200208121
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.state == 100
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.state)
                                      )
                                    ),
                                  ])
                                : scope.row.state == 200 &&
                                  _vm.getTimeFun(scope.row.startTime) <
                                    scope.row.currentTime &&
                                  _vm.getTimeFun(scope.row.endTime) >
                                    scope.row.currentTime
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s("进行中")),
                                  ])
                                : scope.row.state == 200 &&
                                  _vm.getTimeFun(scope.row.startTime) >
                                    scope.row.currentTime
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s("审核通过")),
                                  ])
                                : scope.row.state == 300
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.state)
                                      )
                                    ),
                                  ])
                                : scope.row.state == 400
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.state)
                                      )
                                    ),
                                  ])
                                : scope.row.state == 500
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.state)
                                      )
                                    ),
                                  ])
                                : scope.row.state == 600 &&
                                  _vm.getTimeFun(scope.row.endTime) >
                                    scope.row.currentTime
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.state)
                                      )
                                    ),
                                  ])
                                : _vm.getTimeFun(scope.row.endTime) <
                                  scope.row.currentTime
                                ? _c("el-tag", [_vm._v(_vm._s("结束"))])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1757931763
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: _vm.tableConfig.align,
                      label: "操作",
                      fixed: "right",
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.hasAuth("detailbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.detail(
                                            scope.row.actNo,
                                            scope.row.type,
                                            true,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("logbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.routerLinkFun(0)
                                        },
                                      },
                                    },
                                    [_vm._v("日志")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { staticClass: "table-dropdown" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n              更多\n              "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      (scope.row.state === "500" ||
                                        scope.row.state === "300") &&
                                      scope.row.userId == scope.row.creatorId &&
                                      _vm.hasAuth("updatebtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.detail(
                                                    scope.row.actNo,
                                                    scope.row.type,
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("copyBtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copy(
                                                    scope.row.actNo,
                                                    scope.row.type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("复制活动")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      (scope.row.state === "200" ||
                                        scope.row.state === "400" ||
                                        scope.row.state === "600") &&
                                      (_vm.getTimeFun(scope.row.startTime) <
                                        scope.row.currentTime ||
                                        _vm.getTimeFun(scope.row.endTime) <
                                          scope.row.currentTime) &&
                                      _vm.hasAuth("prizerecord", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.linkpage(
                                                    scope.row.actId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("中奖记录")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.state === "200" &&
                                      scope.row.userId == scope.row.creatorId &&
                                      _vm.getTimeFun(scope.row.startTime) <
                                        scope.row.currentTime &&
                                      _vm.getTimeFun(scope.row.endTime) >
                                        scope.row.currentTime &&
                                      _vm.hasAuth("stopbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setDisable(
                                                    scope.row.actNo
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("暂停")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.state === "600" &&
                                      scope.row.userId == scope.row.creatorId &&
                                      _vm.getTimeFun(scope.row.endTime) >
                                        scope.row.currentTime &&
                                      _vm.hasAuth("startbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setEnable(
                                                    scope.row.actNo
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("重启")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.state === "600" &&
                                      scope.row.userId == scope.row.creatorId &&
                                      _vm.getTimeFun(scope.row.startTime) <
                                        scope.row.currentTime &&
                                      _vm.getTimeFun(scope.row.endTime) >
                                        scope.row.currentTime &&
                                      _vm.hasAuth("endbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setEnd(
                                                    scope.row.actNo
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("结束")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      (scope.row.state === "500" ||
                                        scope.row.state === "300") &&
                                      scope.row.userId == scope.row.creatorId &&
                                      _vm.hasAuth("startverify", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.startCheckFun(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("发起审核")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("verifylog", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkFun(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("审核日志")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.state === "100" &&
                                      scope.row &&
                                      scope.row.userId !==
                                        scope.row.creatorId &&
                                      _vm.hasAuth("verifybtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "a-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkOperationFun(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("审核操作")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1290938273
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.formInline.pageNo,
                  limit: _vm.formInline.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.formInline, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.formInline, "pageSize", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "活动类型",
            visible: _vm.actDialogVisible,
            width: "800px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.actDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "itemWrap" },
            _vm._l(_vm.actlist, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.detail("", item.type, false)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "imgWrap" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: item.img },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }, [
                    _c("span", {
                      staticClass: "cardDialogTitle",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("Content", {
            ref: "content",
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              type: _vm.type,
              "act-no": _vm.actNo,
              num: _vm.num,
              copy: _vm.isCopy,
              "show-only": _vm.showOnly,
              "disabledshow-only": _vm.disabledshowOnly,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.prizeVisible
        ? _c("Prize", {
            ref: "content",
            attrs: { "prize-visible": _vm.prizeVisible, "act-no": _vm.actNo },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("Tipdialog", {
        ref: "hasConsumerTipVisible",
        attrs: {
          width: "350px",
          content: "请先在【公众号基础配置】中选择“消费者公众号”。",
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "审核日志",
            visible: _vm.checkVisible,
            width: "800px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.AuditList,
                    "element-loading-text": "Loading",
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "审核时间",
                      prop: "auditTime",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核结果",
                      prop: "",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.auditStatus === 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(_vm._s("发起审核")),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.auditStatus === 300
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(_vm._s("驳回")),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.auditStatus === 200
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(_vm._s("通过")),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核人",
                      prop: "auditname",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核意见",
                      prop: "auditRemark",
                      align: _vm.tableConfig.align,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.checkVisible = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "审核操作",
            visible: _vm.visibleCheck,
            width: "800px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleCheck = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.checkForm, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核操作" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.checkForm.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkForm, "auditStatus", $$v)
                            },
                            expression: "checkForm.auditStatus",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { value: 300, label: 300 } },
                            [_vm._v("驳回")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { value: 200, label: 200 } },
                            [_vm._v("通过")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.checkForm.auditRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.checkForm, "auditRemark", $$v)
                          },
                          expression: "checkForm.auditRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visibleCheck = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.shloading,
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.visibleCheckFun },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }