var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "供货用户-查看角色",
        model: _vm.role,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px", data: _vm.roleData } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色：" } },
            _vm._l(_vm.roleData, function (item) {
              return _c(
                "el-tag",
                {
                  key: item.roleNmae,
                  staticStyle: {
                    "margin-right": "10px",
                    "margin-bottom": "10px",
                  },
                  attrs: { type: "info", effect: "plain" },
                },
                [_vm._v(_vm._s(item.roleNmae))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }