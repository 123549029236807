"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileStoredetail = fileStoredetail;
exports.fileStoresubmit = fileStoresubmit;
exports.getTemplateList = getTemplateList;
exports.mailDeploydetail = mailDeploydetail;
exports.mailDeploysend = mailDeploysend;
exports.mailDeploysubmit = mailDeploysubmit;
exports.smsDeploySubmit = smsDeploySubmit;
exports.smsDeploydetail = smsDeploydetail;
exports.smsDeploysend = smsDeploysend;
var _request = _interopRequireDefault(require("@/utils/request"));
// 邮箱接口配置详情
function mailDeploydetail(params) {
  return (0, _request.default)({
    url: '/api-basic/mailDeploy/detail',
    method: 'get',
    params: params
  });
}

// 短信接口配置详情
function smsDeploydetail(params) {
  return (0, _request.default)({
    url: '/api-basic/smsDeploy/detail',
    method: 'get',
    params: params
  });
}

// 邮箱接口配置提交
function mailDeploysubmit(data) {
  return (0, _request.default)({
    url: '/api-basic/mailDeploy/submit',
    method: 'post',
    data: data
  });
}
// 短信接口配置提交
function smsDeploySubmit(data) {
  return (0, _request.default)({
    url: '/api-basic/smsDeploy/submit',
    method: 'post',
    data: data
  });
}

// 邮箱接口测试发送
function mailDeploysend(data) {
  return (0, _request.default)({
    url: '/api-basic/mailDeploy/send',
    method: 'post',
    data: data
  });
}

// 短信接口测试发送
function smsDeploysend(data) {
  return (0, _request.default)({
    url: '/api-basic/smsDeploy/send',
    method: 'post',
    data: data
  });
}

// 测试发送模板列表
function getTemplateList(params) {
  return (0, _request.default)({
    url: '/api-basic/messageTemplate/getTemplateList',
    method: 'get',
    params: params
  });
}

// 存储接口配置详情
function fileStoredetail(params) {
  return (0, _request.default)({
    url: '/api-user/fileStoreConfig/detail',
    method: 'get',
    params: params
  });
}

// 存储接口配置提交
function fileStoresubmit(data) {
  return (0, _request.default)({
    url: '/api-user/fileStoreConfig/addFileStoreConfig',
    method: 'post',
    data: data
  });
}