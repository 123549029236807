"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.parse-int");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _utils = require("@/utils");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _prodTransport = _interopRequireDefault(require("./prod-transport"));
var _skuTag = _interopRequireDefault(require("./sku-tag"));
var _skuTable = _interopRequireDefault(require("./sku-table"));
var _wxHy = require("@/api/wxhyyy/wxHy");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce1"));
var _massSend = require("@/api/wxhyyy/massSend.js");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */
var _default = {
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.$store.commit('prod/resetSkuTag');
    next();
  },
  components: {
    UploadImg: _UploadImg.default,
    ProdTransport: _prodTransport.default,
    TinyMce: _tinyMce.default,
    SkuTag: _skuTag.default,
    SkuTable: _skuTable.default
  },
  data: function data() {
    return {
      vipload: false,
      bqload: false,
      vipDialog: false,
      // 渠道二维码
      labelDialog: false,
      labelOption: '',
      labelOptions: [],
      // 会员等级
      viplist: [],
      // 有效期
      overtime: '',
      validity: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 分类树展示与回显
      category: {
        list: [],
        selected: [],
        props: {
          value: 'categoryId',
          label: 'categoryName'
        }
      },
      // 规格列表
      dataForm: {
        prodName: '',
        brief: '',
        pic: '',
        imgs: '',
        pcategoryId: '',
        categoryId: '',
        prodId: 0,
        skuList: [],
        tagList: [],
        content: '',
        status: 1,
        yd: 0,
        // 初始兑换量
        deliveryMode: {
          hasShopDelivery: true,
          hasUserPickUp: false
        },
        deliveryTemplateId: null,
        isYhsfxz: 1,
        // 用户身份
        dehyList: [],
        // 会员等级list
        isYhdhzg: 1,
        // 用户资格
        yhbqList: [],
        // 用户标签List
        isDhsltj: 1,
        // 兑换数量条件
        zdsx: '',
        // 指定上限
        isYf: 1,
        // 运费
        szkg: '',
        // 首重
        szje: '',
        // 首重金额
        xzkg: '',
        // 续重
        xzje: '' // 续重金额
      },

      tags: [],
      disabled: false,
      vipdata: '1'
    };
  },
  computed: {
    // 设置上传地址
    resourcesUrl: function resourcesUrl() {
      // return process.env.VUE_APP_BASE_API + '/user/uspyx/pc/template/upload'
      return process.env.VUE_APP_BASE_API + '/uspyx/pc/template/upload';
    },
    defalutSku: function defalutSku() {
      return this.$store.state.prod.defalutSku;
    }
  },
  // created() {
  // },
  mounted: function mounted() {
    this.dataForm.prodId = this.$route.query.prodId;
    this.disabled = JSON.parse(this.$route.query.disabled);
    this.getviplist();
    this.gettag();
    this.getDataList();
    // console.log(this.viplist)
    // this.getviplist()
    // this.gettag()
  },

  methods: {
    getvipdata: function getvipdata() {
      var _this = this;
      (0, _wxHy.getDataByMember)().then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          if (res.data.rule === '1') {
            _this.vipdata = '1';
          } else {
            _this.vipdata = '2';
          }
        }
      });
    },
    zdsxx: function zdsxx(e) {
      // console.log(e)
      this.dataForm.zdsx = this.numFun(e);
      // console.log(this.dataForm.zdsx)
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    // 获取标签
    labelFun: function labelFun(val) {
      if (this.dataForm.yhbqList) {
        // return
      } else {
        this.dataForm.yhbqList = [];
      }
      // if (val === '2') {
      //   this.dataForm.isYhsfxz = '2'
      //   this.vipDialog = true
      // }
      // console.log(this.dataForm.yhbqList)
      if (val === '2') {
        this.dataForm.isYhdhzg = '2';
        this.labelDialog = true;
      }
    },
    vipFun: function vipFun(val) {
      //  // console.log(this.dataForm.dehyList)
      if (this.dataForm.dehyList) {
        // return
      } else {
        this.dataForm.dehyList = [];
      }
      // console.log(this.dataForm.dehyList)
      // console.log(this.viplist)
      if (val === '2') {
        this.dataForm.isYhsfxz = '2';
        this.vipDialog = true;
      } else {
        this.dataForm.dehyList = [];
      }
    },
    // isvip() {
    //   if (!this.dataForm.dehyList) {
    //     this.dataForm.isYhsfxz = '1'
    //   }
    // },
    // isqd() {
    //   if (!this.dataForm.yhbqList) {
    //     this.dataForm.isYhdhzg = '1'
    //   }
    // },
    yfFun: function yfFun() {},
    // numFun() {
    // },
    gettag: function gettag() {
      var _this2 = this;
      this.bqload = true;
      (0, _massSend.getAllTag)({
        appId: sessionStorage.getItem('appId')
      }).then(function (resp) {
        var resp = resp.data;
        var _resp = resp,
          code = _resp.code,
          data = _resp.data,
          msg = _resp.msg;
        if (code === 200) {
          // if (data) {
          //   this.labelOptions = data
          //    // console.log(this.labelOptions)
          // }
          data === null ? _this2.labelOptions = [] : _this2.labelOptions = data;
        } else {
          _this2.$message(msg);
        }
        _this2.bqload = false;
      });
    },
    labelCheckFun: function labelCheckFun(val) {
      // if (!this.dataForm.yhbqList) {
      //   this.dataForm.isYhdhzg = '1'
      // }
      // var arr = []
      // val.map((key, index) => {
      //   this.labelOptions.map(item => {
      //      // console.log(key)
      //      // console.log(item.tagName)
      //     if (key === item.tagName) {
      //       arr.push(item.tagId)
      //     }
      //   })
      // })
      // this.dataForm.yhbqList = arr
    },
    closedDialog: function closedDialog(name, type) {
      // const param = Object.assign({}, this.dataForm)
      // this.paramSetPriceAndStocks(param)
      // if (!this.paramSetPriceAndStocks(param)) {
      //   return false
      // }
      //  // console.log(param)
      if (type === 1) {
        if (name === 'no') {
          // this.sendObj.bqList = []
          // this.dataForm.yhbqList = []
          this.labelDialog = false;
          this.dataForm.isYhdhzg = 1;
          // this.sendObj.bq = '0'
        } else if (name === 'yes') {
          if (this.dataForm.yhbqList.length === 0) {
            return this.$message.info('请选择标签');
          } else {
            this.labelDialog = false;
          }
          // } else {
          // this.$message('请选择')
          // }
        }
      } else {
        // console.log(this.dataForm.yhbqList)

        if (name === 'no') {
          // this.sendObj.bqList = []
          // this.dataForm.dehyList = []
          this.vipDialog = false;
          this.dataForm.isYhsfxz = 1;
          // this.sendObj.bq = '0'
        } else if (name === 'yes') {
          // if (this.sendObj.bqList.length > 0) {
          if (this.dataForm.dehyList.length === 0) {
            return this.$message.info('请选择会员等级');
          } else {
            this.vipDialog = false;
          }
          // } else {
          // this.$message('请选择')
          // }
        }
      }
    },
    // 获取会员等级
    getviplist: function getviplist() {
      var _this3 = this;
      this.vipload = true;
      (0, _wxHy.searchSelectBySends)().then(function (res) {
        // console.log(res)
        // console.log(this.viplist)
        var res = res.data;
        if (res.code === 200) {
          // if(res.data === null) {
          //   this.viplist = []
          // }else {}
          if (res.data && res.data.length > 0) {
            _this3.viplist = res.data;
          } else {
            _this3.viplist = [];
          }
        } else {
          _this3.$message(res.msg);
        }
        _this3.vipload = false;
        // console.log(this.viplist)
      });
    },
    // 获取分类数据
    getDataList: function getDataList() {
      var _this4 = this;
      this.getTagList();
      this.getCategoryList().then(function () {
        if (_this4.dataForm.prodId) {
          // 获取产品数据
          (0, _wXintegralMall.prodInfo)(_this4.dataForm.prodId).then(function (data) {
            var data = data.data;
            _this4.dataForm = data;
            _this4.dataForm.isDhsltj = data.isDhsltj * 1;
            // console.log(this.dataForm)
            var arryh = _this4.dataForm.yhbqList;
            var arrde = _this4.dataForm.dehyList;
            if (arryh) {
              _this4.dataForm.yhbqList = arryh.split(',');
            }
            if (arrde) {
              _this4.dataForm.dehyList = arrde.split(',');
            }
            _this4.dataForm.deliveryMode = JSON.parse(data.deliveryMode);
            _this4.$refs.skuTag.init(data.skuList);
            _this4.$refs.skuTable.init();
            var arr = data.imgs.split(',');
            arr.forEach(function (item) {
              if (item) {
                _this4.$refs.imgs.fileList.push({
                  url: item
                });
              }
            });
            // this.$refs.imgs.fileList.push({
            //   url: data.imgs
            // })
            _this4.category.selected = (0, _utils.idList)(_this4.category.list, _this4.dataForm.categoryId, 'categoryId', 'children').reverse();
            _this4.dataForm.tagList = data.tagList;
          });
        } else {
          _this4.$nextTick(function () {
            _this4.$refs['dataForm'].resetFields();
            _this4.$refs.skuTag.init();
            _this4.dataForm.pic = '';
            _this4.dataForm.imgs = '';
          });
        }
      });
    },
    // 获取分类信息
    getCategoryList: function getCategoryList() {
      var _this5 = this;
      return (0, _wXintegralMall.listCategory)().then(function (data) {
        // console.log(data)
        var data = data.data;
        var arr = [];
        var list = (0, _utils.treeDataTranslate)(data, 'categoryId', 'parentId');
        if (list && list.length > 0) {
          list.forEach(function (item) {
            if (item.parentId != 0 || item.children && item.children.length > 0) {
              arr.push(item);
            }
          });
        }
        _this5.category.list = arr;
      });
    },
    // 选择分类改变事件
    handleCategoryChange: function handleCategoryChange(val) {
      // console.log(val)
      this.dataForm.categoryId = val[val.length - 1];
      this.dataForm.pcategoryId = val[0];
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this6 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (!valid) {
          return;
        }
        if (!_this6.dataForm.imgs) {
          _this6.errorMsg('请选择图片上传');
          return;
        }
        if (!_this6.dataForm.deliveryMode) {
          _this6.errorMsg('请选择配送方式');
          return;
        }
        if (!_this6.dataForm.categoryId) {
          _this6.errorMsg('请选择产品分类');
          return;
        }
        if (!_this6.dataForm.content) {
          _this6.errorMsg('请填写产品详情');
          return;
        }
        if (_this6.dataForm.isYf === 2) {
          if (!_this6.dataForm.szkg && !_this6.dataForm.szje && !_this6.dataForm.xzkg && !_this6.dataForm.xzje) {
            _this6.errorMsg('请填写运费信息');
            return;
          }
        }
        _this6.dataForm.yhbqList = _this6.dataForm.yhbqList.toString();
        _this6.dataForm.dehyList = _this6.dataForm.dehyList.toString();
        // if (
        //   this.dataForm.deliveryMode.hasShopDelivery &&
        //   !this.dataForm.deliveryTemplateId
        // ) {
        //   this.errorMsg('请选择运费模板')
        //   return
        // }
        var param = Object.assign({}, _this6.dataForm);
        // 设置价格和库存
        // this.paramSetPriceAndStocks(param)
        if (!_this6.paramSetPriceAndStocks(param)) {
          return false;
        }
        // alert('oiopipipipiipo')
        param.deliveryMode = undefined;
        param.deliveryModeVo = _this6.dataForm.deliveryMode;
        // 商品主图
        param.pic = _this6.dataForm.imgs.split(',')[0];
        (0, _wXintegralMall.prodAdd)(param).then(function (data) {
          _this6.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this6.visible = false;
              _this6.$store.commit('common/removeMainActiveTab');
              _this6.$router.push({
                name: 'wxAccountswxIntegralMallprodprodList'
              });
              _this6.$emit('refreshDataList');
            }
          });
        }).catch(function (err) {
          if (err.response.status == 400) {
            _this6.$message.closeAll();
            _this6.$message({
              message: err.response.data,
              type: 'error',
              duration: 2000
            });
          }
        });
      });
    },
    paramSetPriceAndStocks: function paramSetPriceAndStocks(param) {
      // 获取规格属性信息
      // param.skuList = this.$refs.prodSpec.getTableSpecData()
      // 商品库存
      param.totalStocks = 0;
      // 商品价格
      param.price = 0;
      // 商品原价
      param.oriPrice = 0;
      // 商品实际价格
      param.je = 0;
      // 商品实际库存
      for (var i = 0; i < param.skuList.length; i++) {
        var element = param.skuList[i];
        if (element.status !== 1) {
          continue;
        }
        if (param.price === 0) {
          param.price = element.price ? Number.parseFloat(element.price) : 0;
        }
        if (param.je === 0) {
          param.je = element.jejg ? Number.parseFloat(element.jejg) : 0;
        }
        // 商品价格为最低价的那件商品的价格
        param.price = Math.min(param.price, element.price);
        if (param.price === element.price) {
          param.oriPrice = element.oriPrice ? Number.parseFloat(element.oriPrice) : 0;
        }
        param.je = Math.min(param.je, element.jejg);
        param.totalStocks += element.stocks ? Number.parseInt(element.stocks) : 0;
      }
      // 如果sku没有商品名称，则使用商品的商品名称
      if (param.skuList.length === 1) {
        param.skuList[0].prodName = this.dataForm.prodName;
      }
      var sklist = param.skuList;
      var isSku;
      for (var _i = 0; _i < sklist.length; _i++) {
        if (sklist[_i].jejg === 0 && sklist[_i].price === 0 || sklist[_i].jejg === 0 && sklist[_i].price === '0') {
          this.$message('积分和金额不能同时为0');
          isSku = false;
          return false;
        } else {
          isSku = true;
        }
      }
      return isSku;
      // sklist.forEach((item, i) => {
      //    // console.log(item)
      //   if ((item.jejg === 0 && item.price === 0) || (item.jejg === 0 && item.price === '0')) {
      //     this.$message('积分和金额不能同时为0')
      //     return false
      //   }
      // })
      //  // console.log(param)
    },
    skuTagChangeSkuHandler: function skuTagChangeSkuHandler(skuList) {
      var prodName = this.dataForm.prodName;
      skuList.forEach(function (sku) {
        if (sku.properties) {
          sku.skuName = '';
          var properties = sku.properties.split(';');
          for (var propertiesKey in properties) {
            sku.skuName += properties[propertiesKey].split(':')[1] + ' ';
          }
          sku.prodName = prodName + ' ' + sku.skuName;
        }
      });
      for (var i = 0; i < skuList.length; i++) {
        skuList[i].jejg = 0;
        for (var j = 0; j < this.dataForm.skuList.length; j++) {
          this.dataForm.skuList[j];
          if (skuList[i].properties == this.dataForm.skuList[j].properties) {
            skuList[i] = this.dataForm.skuList[j];
          }
        }
      }
      this.dataForm.skuList = skuList;
      // console.log('skulist--', this.dataForm.skuList)
    },
    errorMsg: function errorMsg(message) {
      this.$message({
        message: message,
        type: 'error',
        duration: 1500
      });
    },
    // 获取所有的分组信息
    getTagList: function getTagList() {
      var _this7 = this;
      (0, _wXintegralMall.listTagList)().then(function (data) {
        // console.log(data)
        var data = data.data;
        _this7.tags = data;
      });
    }
  }
};
exports.default = _default;