"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _gonghuoDialog = _interopRequireDefault(require("@/components/gonghuoDialog"));
var _storeTypeSelect = _interopRequireDefault(require("@/components/storeTypeSelect"));
var _ordergl = require("@/api/warehouse/ordergl");
var _select = require("@/api/warehouse/select");
var _index = require("@/api/warehouse/checkout/index");
var _org = require("@/api/basic/org");
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default,
    factorySelectDialog: _factorySelectDialog.default,
    companySelectDialog: _gonghuoDialog.default,
    storeTypeSelect: _storeTypeSelect.default,
    allotUnitSelect: _allotUnitSelect.default
  },
  filters: {
    getRowNumber: function getRowNumber(index) {
      index = (Number(index) + 1) * 10;
      return (Array(4).join('0') + index).slice(-4);
    },
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      miniUnit = num;
      row.planScanAtoNum = num;
      return num;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '添加材料出库',
      outComName: '',
      inComName: '',
      otherTypeName: '',
      billCode: '',
      inUseName: '',
      billType: '',
      tableKey: 'pId',
      companyShow: false,
      companyType: '',
      orgId: '',
      listLoading: false,
      factoryShow: false,
      mloading: false,
      dialogVisible: false,
      typeVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        billoutOtherAO: {
          otherTypeId: '',
          inOrgId: '',
          outOrgId: '',
          outOrgType: '',
          outStoreId: '',
          isSyncOther: ''
        },
        remark: '',
        NCNO: ''
      },
      otherTypeList: [],
      rowError: [],
      scanData1: {
        billNo: '',
        billType: 205,
        billoutOtherAO: {
          otherTypeId: '',
          outOrgId: '',
          inOrgId: '',
          isSyncOther: '',
          outOrgType: '',
          outStoreId: ''
        },
        tradeBillDetailAOS: [{
          billNo: '',
          packUnit: '',
          planScanAtoNum: 0,
          planScanNum: 0,
          productId: ''
        }]
      },
      disabled: {
        num: false
      },
      orgList: [],
      storeList: [],
      proList: [],
      requestEnum: 1,
      typeList: [],
      unitType: '',
      unitShow: false,
      inOutType: '7',
      shouFaType: '',
      rules: {
        billNo: [{
          required: true,
          message: '单号不能为空，请维护',
          trigger: 'change'
        }],
        'billoutOtherAO.otherTypeId': [{
          required: true,
          message: '请选择业务类型',
          trigger: 'change'
        }],
        'billoutOtherAO.outOrgId': [{
          required: true,
          message: '请选择发货单位',
          trigger: 'change'
        }],
        'billoutOtherAO.inOrgId': [{
          required: true,
          message: '请选择收货单位',
          trigger: 'change'
        }],
        'billoutOtherAO.outStoreId': [{
          required: true,
          message: '请选择发库库房',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    this.proShow = false;
    this.getYwTypes();
    this.getTypeList();
    (0, _org.fetchOrgList)({
      orgTypeList: ['2', '3']
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          that.orgList = res.data.data;
        } else {
          that.orgList = [];
        }
      }
    });
  },
  methods: {
    init: function init() {
      this.proList = [];
      this.storeList = [];
      this.inComName = '';
      this.outComName = '';
      this.otherTypeName = '';
      this.billCode = '';
      this.scanData = JSON.parse(JSON.stringify(this.scanData1));
    },
    show: function show(submitType, billType) {
      this.init();
      this.title = '添加材料出库';
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.isUpdate = true;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.getCreateNum();
      this.dialogVisible = true;
      this.mloading = false;
    },
    upDateShow: function upDateShow(row) {
      var _this2 = this;
      this.init();
      this.title = '修改材料出库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      this.dialogVisible = true;
      (0, _index.getPeoductionDetail)({
        billNo: row.billNo,
        ncProductId: '',
        productId: ''
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var data = res.data.data;
          // this.scanData.remark = data.tradeBillAllVO.remark
          _this2.$set(_this2.scanData, 'remark', data.tradeBillAllVO.remark);
          _this2.scanData.billNo = data.tradeBillAllVO.billNo;
          _this2.scanData.billSource = data.tradeBillAllVO.billSource;
          _this2.scanData.billType = data.tradeBillAllVO.billType;
          _this2.scanData.NCNO = data.tradeBillAllVO.NCNO;
          // this.scanData.otherTypeId = data.tradeBillAllVO.otherTypeId

          // if (data.tradeBillAllVO.otherTypeName) {
          //   this.otherTypeName = data.tradeBillAllVO.otherTypeName
          //   this.typeList.filter((item) => {
          //     if (item.billName == data.tradeBillAllVO.otherTypeName) {
          //       this.scanData.billoutOtherAO.otherTypeId = item.pid
          //     }
          //   })
          // }
          _this2.scanData.billoutOtherAO.outOrgId = data.tradeBillAllVO.outOrgId;
          _this2.outComName = data.tradeBillAllVO.outOrgName;
          _this2.scanData.billoutOtherAO.inOrgId = data.tradeBillAllVO.inOrgId;
          _this2.inComName = data.tradeBillAllVO.inOrgName;
          _this2.billCode = data.tradeBillAllVO.otherTypeCode;
          _this2.orgList.filter(function (item) {
            if (item.orgName == data.tradeBillAllVO.outOrgName) {
              _this2.scanData.billoutOtherAO.outOrgType = item.orgType == 2 ? 2 : 1;
            }
          });
          (0, _ordergl.byOrgFactoryId)({
            orgOrFactoryId: data.tradeBillAllVO.outOrgId
          }).then(function (res) {
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              if (res.data.data != null) {
                _this2.storeList = res.data.data;
              } else {
                _this2.storeList = [];
              }
            }
          }).catch(function () {
            _this2.mloading = false;
          }).then(function () {
            _this2.storeList.filter(function (val) {
              if (val.storeHouseName == data.tradeBillAllVO.outStoreName) {
                _this2.scanData.billoutOtherAO.outStoreId = val.pId;
              }
              _this2.mloading = false;
            });
          });
          if (data.tradeProductVOS && data.tradeProductVOS.length) {
            var arr = [];
            data.tradeProductVOS.forEach(function (item) {
              var aNum;
              if (item.packRadioDesc.indexOf('*') >= 0) {
                aNum = item.packRadioDesc.split('*');
              } else {
                var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
                aNum = item.packRadioDesc.match(regex)[0].match(/\d+/)[0];
              }
              var le = aNum.length - 1;
              var obj = {
                pId: item.productId,
                productCode: item.productCode,
                productName: item.productName,
                packScaleExpression: item.packRadioDesc,
                packUnit: item.packUnit,
                planScanNum: item.planScanNum,
                bottleNumOver: item.planScanAtoNum % aNum[le],
                planScanAtoNum: item.planScanAtoNum
              };
              arr.push(obj);
            });
            _this2.proList = arr;
          } else {
            _this2.proList = [];
          }
        }
      }).catch(function () {
        _this2.mloading = false;
      });
    },
    // 获取业务类型
    getYwTypes: function getYwTypes() {
      var that = this;
      var param = {
        orderType: 2
      };
      (0, _select.queryByOrderType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.otherTypeList = res.data.data;
        }
      });
    },
    // 获取业务类型
    getTypes: function getTypes(e) {
      var that = this;
      that.typeVisible = true;
    },
    closeType: function closeType() {
      var that = this;
      that.typeVisible = false;
    },
    getType: function getType(val) {
      var that = this;
      // that.scanData.billoutOtherAO.otherTypeId = val.pid
      // that.otherTypeName = val.billName

      this.otherTypeList.forEach(function (item) {
        if (val == item.pid) that.billCode = item.billCode;
      });
      this.changeType();
    },
    changeChestNum: function changeChestNum(row) {
      var scal;
      if (row.packScaleExpression.indexOf('*') >= 0) {
        scal = row.packScaleExpression.split('*');
      } else {
        var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
        scal = row.packScaleExpression.match(regex)[0].match(/\d+/)[0];
      }
      var le = scal.length - 1;
      row.planScanAtoNum = (row.planScanNum || 0) * scal[le] + (row.bottleNumOver || 0);
      // this.resetList()
      this.proList = JSON.parse(JSON.stringify(this.proList));
    },
    setSumNew: function setSumNew(e, row, type) {
      var radio;
      var packscal = 1;
      if (row.packScaleExpression.indexOf('*') >= 0) {
        radio = row.packScaleExpression.split('*');
      } else {
        var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
        radio = row.packScaleExpression.match(regex)[0].match(/\d+/)[0];
      }
      packscal = packscal * Number(radio[radio.length - 1]);
      row.planScanNum = Math.floor(e / packscal);
      row.bottleNumOver = e % packscal;
      this.proList = JSON.parse(JSON.stringify(this.proList));
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    getCreateNum: function getCreateNum() {
      var _this3 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        }
        _this3.scanData.billNo = res.data.data;
        _this3.disabled.num = true;
      }).catch(function (err) {});
    },
    changeType: function changeType() {
      console.log(this.billCode, 'billCode');
      this.inComName = '';
      this.outComName = '';
      this.storeList = [];
      this.scanData.billoutOtherAO.inOrgId = '';
      this.scanData.billoutOtherAO.outOrgId = '';
      this.scanData.billoutOtherAO.outOrgType = '';
      this.scanData.billoutOtherAO.outStoreId = '';
    },
    proDel: function proDel(row, index) {
      var _this4 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.proList.splice(_this4.proList.indexOf(row), 1);
      });
    },
    submitForm: function submitForm(temp) {
      var _this5 = this;
      this.setDetail();
      if (this.rowError.length > 0) {
        this.$message.error('产品列表第' + this.rowError.join(',') + '的总瓶数必须大于0');
        return false;
      }
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this5.btn_loading = true;
          if (_this5.submitType == 2) {
            (0, _index.InfoUpdate)(_this5.scanData).then(function (res) {
              _this5.btn_loading = false;
              if (res.data.code != 200) {
                _this5.$message.error(res.data.msg);
              } else {
                _this5.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this5.handleClose();
                _this5.$parent.getList();
              }
            }).catch(function () {
              _this5.btn_loading = false;
            });
          } else {
            (0, _index.insertTradeBill)(_this5.scanData).then(function (res) {
              _this5.btn_loading = false;
              if (res.data.code != 200) {
                _this5.$message.error(res.data.msg);
              } else {
                _this5.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this5.handleClose();
                _this5.$parent.getList();
              }
            }).catch(function () {
              _this5.btn_loading = false;
            });
          }
        }
      });
    },
    // 详情组合
    setDetail: function setDetail() {
      var that = this;
      that.rowError = [];
      that.scanData.tradeBillDetailAOS = [];
      that.scanData.sumPlanAtoNum = 0;
      that.scanData.sumPlanNum = 0;
      if (this.proList.length > 0) {
        this.proList.forEach(function (v, index) {
          var row = 1 + index;
          if (v.planScanAtoNum <= 0) {
            that.rowError.push(row);
          }
          that.scanData.sumPlanNum += v.planScanNum;
          that.scanData.sumPlanAtoNum += v.planScanAtoNum * 1;
          that.scanData.tradeBillDetailAOS.push({
            billNo: that.scanData.billNo,
            packUnit: '瓶',
            // 单位：箱
            productId: v.pId,
            // 产品id
            planScanNum: v.planScanNum,
            // 计划数量
            planScanAtoNum: v.planScanAtoNum // 计划数量最小单位
          });
        });
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    getTypeList: function getTypeList() {
      var _this6 = this;
      // 获取单据类型
      this.typeList = [];
      (0, _select.getYwTypes)({
        current: 1,
        size: 2222222,
        orderType: 2
      }).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this6.typeList = res.data.data.records;
        }
      });
    },
    openSel: function openSel() {
      this.proShow = true;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSel: function setSel(val) {
      var arr = JSON.parse(JSON.stringify(this.proList));
      if (val.length) {
        if (this.proList.length == 0) {
          var obj = {
            prodUnit: '箱',
            planScanAtoNum: 0,
            planScanNum: 0
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.prodUnit = '箱';
            item.planScanAtoNum = 0;
            item.planScanNum = 0;
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.prodUnit = el.prodUnit;
                item.planScanAtoNum = el.planScanAtoNum;
                item.planScanNum = el.planScanNum;
              }
            });
          });
        }
        this.proList = JSON.parse(JSON.stringify(val));
      } else {
        this.proList = [];
      }
      this.proList = val;
    },
    /**
     * 收发货单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 2 ：销售公司,3: 生产公司,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType, isEnable) {
      // if (!this.scanData.billoutOtherAO.otherTypeId) {
      //   this.$message.error('请先选择业务类型')
      //   return
      // }
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      if (this.billCode == 'QLT_THCK') {
        this.factoryType = 2;
      }
      this.isEnable = isEnable;
      this.factoryShow = true;
    },
    // 获取收货单位
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        that.shouFaType = '1';
      }
      if (arg == 'out') {
        // if (that.scanData.otherTypeName != '退货入库') {
        //   that.shouFaType = '1'
        // } else {
        //   that.shouFaType = '2'
        // }
        that.shouFaType = '2';
      }
      that.unitShow = true;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'out') {
        if (that.scanData.otherTypeName != '销售退货') {
          that.inComName = val.companyName;
          this.scanData.billoutOtherAO.inOrgId = val.companyId;
          this.outComName = val.companyName;
          this.scanData.billoutOtherAO.outOrgId = val.companyId;
          this.getStoreList(val.companyId);
        } else {
          that.scanData.outOrgName = val.companyName;
          that.scanData.outOrgId = val.companyId;
        }
      }
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'outComName':
          this.scanData.billoutOtherAO.outOrgId = val.pId;
          this.scanData.billoutOtherAO.outOrgType = val.orgType == 2 ? 2 : 1;
          this.getStoreList(val.pId);
          this.scanData.billoutOtherAO.outStoreId = '';
          if (this.billCode != 'QLT_THCK') {
            this.scanData.billoutOtherAO.inOrgId = val.pId;
            this.inComName = val.orgName;
          } else {
            this.scanData.billoutOtherAO.inOrgId = '';
            this.inComName = '';
          }
          break;
        // case 'inComName':
        //   this.scanData.billoutOtherAO.inOrgId = val.pId
        //   this.scanData.billoutOtherAO.inOrgType = val.orgType == 2 ? 2 : 1
        //   break
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factorystatus = '';
      this.isEnable = '';
      this.factoryShow = false;
    },
    getStoreList: function getStoreList(pid) {
      var _this7 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this7.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this7.storeList = res.data.data;
          } else {
            _this7.storeList = [];
          }
        }
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 供货商类型 1 一级经销商 22 销售公司 33 生产公司
     * @param {*} orgId 采购商ID
     *
     */
    openCompany: function openCompany(name, companyType, orgId) {
      if (!orgId) {
        this.$message.error('请选择发货单位');
        return;
      }
      this.inUseName = name;
      this.companyType = companyType;
      this.orgId = orgId;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'inComName':
          this.scanData.billoutOtherAO.inOrgId = val.companyId;
          // this.scanData.billoutOtherAO.inOrgId = val.pId
          // this.inComName = val.orgName
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.companyShow = false;
    },
    setSum: function setSum(val) {
      this.proList = JSON.parse(JSON.stringify(this.proList));
    }
  }
};
exports.default = _default;