"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    option: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      legendData: {
        orient: 'vertical',
        left: '50%',
        top: '50%'
      },
      seriesData: [{
        type: 'pie',
        center: ['50%', '50%'],
        data: [],
        label: {
          normal: {
            // position: 'inside',
            formatter: ' {d}%'
          }
        }
      }]
      // series: {
      //   center: ['50%', '50%']
      // }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        legend: this.legendData,
        series: this.seriesData
      });
    }
  }
};
exports.default = _default2;