"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/api/log/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagelogocaptchaResetindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagelogocaptchaResetindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      listLoadig: false,
      rangeTime: '',
      queryParams: {
        current: 1,
        size: 10,
        creatorName: null,
        markCode: null,
        beginTime: null,
        endTime: null
      },
      total: 0
    };
  },
  activated: function activated() {
    console.log(this.queryParams);
    var hasCache1 = this.tableData.length > 0;
    // 其他页面体跳转到本页
    if (!hasCache1) {
      this.getQueryRecordForPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getQueryRecordForPage: function getQueryRecordForPage() {
      var _this = this;
      this.listLoadig = true;
      (0, _log.getQueryRecordForPage)(this.queryParams).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this.total = parseInt(res.data.data.total);
            _this.tableData = res.data.data.records;
            setTimeout(function () {
              _this.listLoadig = false;
            }, 1 * 800);
          } else {
            _this.total = 0;
            _this.tableData = [];
            _this.listLoadig = false;
          }
        }
      }).catch(function (_err) {
        _this.listLoadig = false;
      });
    },
    sj: function sj(res) {
      if (res) {
        this.queryParams.beginTime = res[0];
        this.queryParams.endTime = res[1];
      } else {
        this.queryParams.beginTime = '';
        this.queryParams.endTime = '';
      }
    },
    // 重置搜索框
    resetWinningLottery: function resetWinningLottery() {
      this.rangeTime = '';
      this.queryParams = {
        current: 1,
        size: 10,
        creatorName: null,
        markCode: null,
        beginTime: null,
        endTime: null
      };
      this.getQueryRecordForPage();
    },
    querylist: function querylist() {
      this.queryParams.current = 1;
      this.getQueryRecordForPage();
    }
  }
};
exports.default = _default;