var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image: url('" + _vm.list.background + "');",
      },
      [
        _c("el-image", {
          staticClass: "title",
          attrs: { lazy: "", src: _vm.list.title },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass: "egg-twist-machine",
              style:
                "background-image: url('" + _vm.list.eggTwistMachineBg + "')",
            },
            [
              _c(
                "div",
                { staticClass: "egg-container" },
                _vm._l(_vm.getEggList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "eggs-item-container",
                      style: {
                        bottom: _vm.eggPosition[index].bottom,
                        left: _vm.eggPosition[index].left,
                      },
                    },
                    [
                      _c("el-image", {
                        attrs: { lazy: "", fit: "fill", src: item.backImg },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "start-btn" },
                [
                  _c("el-image", {
                    attrs: { lazy: "", fit: "fill", src: _vm.list.startBtnImg },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }