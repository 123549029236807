var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardBox" }, [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c(
          "el-form",
          {
            ref: "formInline",
            staticClass: "page-section search-condition",
            attrs: {
              model: _vm.formInline,
              rules: _vm.rules,
              "label-suffix": ":",
              "label-width": "90px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "actNo", label: "活动选择" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.selectAct },
                                model: {
                                  value: _vm.formInline.actNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "actNo", $$v)
                                  },
                                  expression: "formInline.actNo",
                                },
                              },
                              _vm._l(_vm.activeList, function (item, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: {
                                    label: item.name,
                                    value: item.actNo,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "time", label: "日期选择" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions1,
                              },
                              model: {
                                value: _vm.formInline.time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "time", $$v)
                                },
                                expression: "formInline.time",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticClass: "head-btn-group",
                        staticStyle: { width: "100px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.fetchData()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-search" }),
                            _vm._v(" 查询\n            "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "el-zoom-in-top" } },
          [
            _vm.show
              ? _c(
                  "el-row",
                  {
                    staticStyle: {
                      margin: "20px 0 0 0",
                      padding: "20px 34px",
                      border: "1px solid #EBEEF5",
                      "background-color": "#FFF",
                      "border-radius": "4px",
                    },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("el-row", { staticClass: "totalWrap" }, [
                          _c("div", { staticClass: "totalTitleWrap" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "totalContent" }, [
                            _c("div", { staticClass: "contentTitle" }, [
                              _vm._v("活动参与量"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "totalContent" }, [
                            _c("div", { staticClass: "contentTitle" }, [
                              _vm._v("活动参与人数"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "totalContent" }, [
                            _c("div", { staticClass: "contentTitle" }, [
                              _vm._v("新增会员"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "totalContent" }, [
                            _c("div", { staticClass: "contentTitle" }, [
                              _vm._v("奖品发放数量"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            staticClass: "totalWrap",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _c("div", { staticClass: "totalTitleWrap" }, [
                              _c("div", { staticClass: "totalTitle" }, [
                                _vm._v("累计"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "totalContent" }, [
                              _c("div", [
                                _c("div", { staticClass: "contentText" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.list && _vm.list.activitySum
                                          ? _vm.list.activitySum
                                          : 0
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    staticClass: "dataImg",
                                    attrs: {
                                      src: require("@/assets/images/pro.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "btmWrap pro" }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "totalContent" }, [
                              _c("div", { staticClass: "contentText" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.list && _vm.list.activityNumber
                                        ? _vm.list.activityNumber
                                        : 0
                                    ) +
                                    "\n                "
                                ),
                                _c("img", {
                                  staticClass: "dataImg",
                                  attrs: {
                                    src: require("@/assets/images/peo.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "btmWrap peo" }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "totalContent" }, [
                              _c("div", { staticClass: "contentText" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.list && _vm.list.addMember
                                        ? _vm.list.addMember
                                        : 0
                                    ) +
                                    "\n                "
                                ),
                                _c("img", {
                                  staticClass: "dataImg",
                                  attrs: {
                                    src: require("@/assets/images/vip.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "btmWrap vip" }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "totalContent" }, [
                              _c("div", { staticClass: "contentText" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.list && _vm.list.grantSum
                                        ? _vm.list.grantSum
                                        : 0
                                    ) +
                                    "\n                "
                                ),
                                _c("img", {
                                  staticClass: "dataImg",
                                  attrs: {
                                    src: require("@/assets/images/pai.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "btmWrap pai" }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isAnticipate * 1 === 1
                          ? _c(
                              "el-row",
                              {
                                staticClass: "totalWrap",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [
                                _c("div", { staticClass: "totalTitleWrap" }, [
                                  _c("div", { staticClass: "totalTitle" }, [
                                    _vm._v("预期"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "totalContent" }, [
                                  _c("div", [
                                    _c("div", { staticClass: "contentText" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.listExpect &&
                                              _vm.listExpect.activitySumExpect
                                              ? _vm.listExpect.activitySumExpect
                                              : 0
                                          ) +
                                          "\n                  "
                                      ),
                                      _c("img", {
                                        staticClass: "dataImg",
                                        attrs: {
                                          src: require("@/assets/images/pro.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "btmWrap pro" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "totalContent" }, [
                                  _c("div", { staticClass: "contentText" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.listExpect &&
                                            _vm.listExpect.activityNumberExpect
                                            ? _vm.listExpect
                                                .activityNumberExpect
                                            : 0
                                        ) +
                                        "\n                "
                                    ),
                                    _c("img", {
                                      staticClass: "dataImg",
                                      attrs: {
                                        src: require("@/assets/images/peo.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "btmWrap peo" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "totalContent" }, [
                                  _c("div", { staticClass: "contentText" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.listExpect &&
                                            _vm.listExpect.addMemberExpect
                                            ? _vm.listExpect.addMemberExpect
                                            : 0
                                        ) +
                                        "\n                "
                                    ),
                                    _c("img", {
                                      staticClass: "dataImg",
                                      attrs: {
                                        src: require("@/assets/images/vip.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "btmWrap vip" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "totalContent" }, [
                                  _c("div", { staticClass: "contentText" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.listExpect &&
                                            _vm.listExpect.grantSumExpect
                                            ? _vm.listExpect.grantSumExpect
                                            : 0
                                        ) +
                                        "\n                "
                                    ),
                                    _c("img", {
                                      staticClass: "dataImg",
                                      attrs: {
                                        src: require("@/assets/images/pai.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "btmWrap pai" }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "el-zoom-in-top" } },
          [
            _vm.show
              ? _c(
                  "el-row",
                  { staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c("span", { staticClass: "cardTitle" }, [
                              _vm._v("总奖池、总数码"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { md: 12 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("div", { staticClass: "circleWrap" }, [
                                _c("div", { staticClass: "circle" }, [
                                  _vm._v(
                                    "\n                  奖池剩余情况\n                  "
                                  ),
                                  _c("p", {
                                    staticClass: "percent",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.list1.jackpotSum &&
                                          _vm.list1.surplusSum
                                          ? (
                                              ((_vm.list1.surplusSum * 1) /
                                                _vm.list1.jackpotSum) *
                                              1 *
                                              100
                                            ).toFixed(2) + "%"
                                          : 0 + "%"
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticStyle: { height: "200px" },
                                attrs: { span: 12 },
                              },
                              [
                                _c("div", { staticClass: "ulWrap" }, [
                                  _c("ul", { staticClass: "textUl" }, [
                                    _c("li", [
                                      _vm._v(
                                        "\n                    总奖池：\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.list1.jackpotSum
                                              ? _vm.list1.jackpotSum
                                              : 0
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "\n                    剩余：\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.list1.surplusSum
                                              ? _vm.list1.surplusSum
                                              : 0
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { md: 12 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("div", { staticClass: "circleWrap" }, [
                                _c("div", { staticClass: "circle" }, [
                                  _vm._v(
                                    "\n                  数码使用情况\n                  "
                                  ),
                                  _c("p", {
                                    staticClass: "percent",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.list1.digitalSum &&
                                          _vm.list1.surplusDigital
                                          ? (
                                              ((_vm.list1.surplusDigital * 1) /
                                                _vm.list1.digitalSum) *
                                              1 *
                                              100
                                            ).toFixed(2) + "%"
                                          : 0 + "%"
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticStyle: { height: "200px" },
                                attrs: { span: 12 },
                              },
                              [
                                _c("div", { staticClass: "ulWrap" }, [
                                  _c("ul", { staticClass: "textUl" }, [
                                    _c("li", [
                                      _vm._v(
                                        "\n                    总数码：\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.list1.digitalSum
                                              ? _vm.list1.digitalSum
                                              : 0
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "\n                    剩余：\n                    "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.list1.surplusDigital
                                              ? _vm.list1.surplusDigital
                                              : 0
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "el-zoom-in-top" } },
          [
            _vm.show
              ? _c(
                  "el-row",
                  { staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-card",
                          { staticClass: "box-card" },
                          [
                            _c("div", { staticClass: "clearfix" }, [
                              _c("span", { staticClass: "cardTitle" }, [
                                _vm._v("消费者活跃区间"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { staticStyle: { "margin-top": "30px" } },
                              [
                                _c(
                                  "el-col",
                                  [
                                    _vm.isAnticipate * 1 === 0
                                      ? _c("line-chart", {
                                          ref: "barChart",
                                          attrs: {
                                            "tip-name": ["实际参与量"],
                                            "title-name": "单位：次",
                                          },
                                        })
                                      : _c("line-chart", {
                                          ref: "barChart",
                                          attrs: {
                                            "tip-name": [
                                              "实际参与量",
                                              "预期参与量",
                                            ],
                                            "title-name": "单位：次",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }