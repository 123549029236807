"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _axios = _interopRequireDefault(require("axios"));
var _qs = _interopRequireDefault(require("qs"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _router = _interopRequireDefault(require("@/router"));
var _auth = require("@/utils/auth");
var _merge = _interopRequireDefault(require("lodash/merge"));
var _user = require("@/api/user");
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

// 是否正在刷新标记
var isRefreshing = false;
var requsets = [];
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000
});
// request interceptor
service.interceptors.request.use(function (config) {
  if (!config.type) {
    var headers = {};
    if ((0, _auth.getToken)() && (0, _auth.getToken)() != 'undefined') {
      config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
    } else {
      var session = sessionStorage.getItem('refresh_token');
      if (session && session != 'undefined') {
        headers = {
          'Authorization': 'Bearer ' + session,
          'client-id': '5def87b2a61d4a5ea92b6ba44b9682cd'
        };
      }
    }
    config.headers = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, headers), config.headers);
  }
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  if (response.data.code === 4000003) {
    if (!isRefreshing) {
      isRefreshing = true;
      // 调用接口刷新token,更换请求头
      (0, _auth.removeToken)();
      return (0, _user.refreshToken)().then(function (res) {
        if (res.data.code == 200) {
          var token = res.data.data.access_token;
          var refreshToken = res.data.data.refresh_token;
          sessionStorage.setItem('refresh_token', refreshToken);
          // 替换token
          (0, _auth.setToken)(token);
          response.headers.Authorization = 'Bearer ' + token;
          // token刷新后将数组方法重新执行
          requsets.forEach(function (cb) {
            return cb(token);
          });
          requsets = [];
          return service(response.config);
        } else {
          (0, _auth.removeToken)();
          sessionStorage.removeItem('refresh_token');
          _router.default.push('/login');
          _store.default.dispatch('user/logout');
          _store.default.dispatch('tagsView/delAllViews', null, {
            root: true
          });
          return Promise.reject(response.config);
        }
      }).catch(function (err) {
        (0, _auth.removeToken)();
        sessionStorage.removeItem('refresh_token');
        _router.default.push('/login');
        _store.default.dispatch('user/logout');
        _store.default.dispatch('tagsView/delAllViews', null, {
          root: true
        });
        return Promise.reject(err);
      }).finally(function () {
        isRefreshing = false;
      });
    } else {
      // 返回未执行resolve的promise
      return new Promise(function (resolve) {
        // 用函数形式奖resolve存入，等待刷新后执行
        requsets.push(function (token) {
          response.headers.Authorization = 'Bearer ' + "".concat(token);
          resolve(service(response.config));
        });
      });
    }
  } else if (response.data.code === 4000002) {
    (0, _auth.removeToken)();
    sessionStorage.removeItem('refresh_token');
    _router.default.push('/login');
    _store.default.dispatch('user/logout');
    _store.default.dispatch('tagsView/delAllViews', null, {
      root: true
    });
    return Promise.reject(response.config);
  } else if (response.data.code === 401) {
    (0, _auth.removeToken)();
    sessionStorage.removeItem('refresh_token');
    _router.default.push('/login');
    // store.dispatch('user/logout')
    _store.default.dispatch('tagsView/delAllViews', null, {
      root: true
    });
    return Promise.reject(response.config);
  }
  return response;
}, function (error) {
  if (error.response.status == 500) {
    if (error.response.data.msg != '' && error.response.data.msg != null && typeof error.response.data.msg !== 'undefined') {
      (0, _elementUi.Message)({
        message: error.response.data.msg,
        type: 'error',
        duration: 2 * 1000
      });
      return Promise.reject(error);
    } else {
      (0, _elementUi.Message)({
        message: '服务器异常,请稍后再试',
        type: 'error',
        duration: 2 * 1000
      });
      return Promise.reject(error);
    }
  } else if (error.response.status == 504) {
    (0, _elementUi.Message)({
      message: '请求超时',
      type: 'error',
      duration: 2 * 1000
    });
    return Promise.reject(error);
  } else if (error.response.status == 401) {
    // 没有token失效的情况下
    if (error.response.data.resp_code == '401') {
      var str = window.location.hash;
      if (str.indexOf('#/login?') != -1) {
        return;
      }
      (0, _elementUi.MessageBox)({
        title: '提示',
        dangerouslyUseHTMLString: true,
        message: '登录超时,请您点击确定,重新登录!',
        showClose: false,
        showCancelButton: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: function beforeClose(action, instance, done) {
          if (action === 'confirm') {
            // router.push(`/login?redirect=/dashboard`)
            _router.default.push('/');
            _store.default.dispatch('user/logout');
            _store.default.dispatch('tagsView/delAllViews', null, {
              root: true
            });
            window.location.reload();
            done();
          } else {
            done();
          }
        }
      });
      return false;
    }
    return Promise.reject(error);
  } else {
    (0, _elementUi.Message)({
      message: error.response.data.msg,
      type: 'error',
      duration: 2 * 1000
    });
    return Promise.reject(error);
  }
});
service.adornParams = function () {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var openDefultParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var defaults = {
    t: new Date().getTime()
  };
  return openDefultParams ? (0, _merge.default)(defaults, params) : params;
};
service.adornData = function () {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var openDefultdata = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var contentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'json';
  var defaults = {
    t: new Date().getTime()
  };
  data = openDefultdata ? (0, _merge.default)(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : _qs.default.stringify(data);
};
var _default = service;
exports.default = _default;