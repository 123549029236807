"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatachannelmangechannellevelindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '经销商',
        2: '终端'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      listLoading: false,
      btnLoading: false,
      page2: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        gradeName: '',
        channelType: '',
        gradeStatus: '',
        pageNo: 1,
        pageSize: 20
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      dialogVisible: false,
      title: '新增产品分类',
      mloading: false,
      ruleForm: {
        channelType: '',
        gradeCode: '',
        gradeName: '',
        gradeStatus: 1,
        remarks: ''
      },
      rules: {
        channelType: [{
          required: true,
          message: '渠道类型不能为空，请维护',
          trigger: 'submit'
        }],
        gradeName: [{
          required: true,
          message: '渠道等级不能为空，请维护',
          trigger: 'submit'
        }],
        gradeStatus: [{
          required: true,
          message: '状态不能为空，请维护',
          trigger: 'submit'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 390 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _channel.channelGradeList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this.btnLoading = true;
          if (_this.ruleForm.pId) {
            _this.authId('updatebtn', _this.$route.meta.authList);
            (0, _channel.channelGradeUpdate)(_this.ruleForm).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
              _this.btnLoading = false;
            }).catch(function () {
              _this.btnLoading = false;
            });
          } else {
            _this.authId('addbtn', _this.$route.meta.authList);
            (0, _channel.channelGradeInsert)(_this.ruleForm).then(function (res) {
              if (res.data.code != 200) {
                _this.$message.error(res.data.msg);
              } else {
                _this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this.handleClose();
                _this.getList();
              }
              _this.btnLoading = false;
            }).catch(function () {
              _this.btnLoading = false;
            });
          }
        }
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        gradeName: '',
        channelType: '',
        gradeStatus: '',
        pageNo: 1,
        pageSize: 20
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    add: function add() {
      this.title = '添加渠道等级';
      this.dialogVisible = true;
    },
    DoEdit: function DoEdit(row) {
      this.title = '编辑渠道等级';
      this.ruleForm.pId = row.pId;
      this.ruleForm.channelType = row.channelType;
      this.ruleForm.gradeCode = row.gradeCode;
      this.ruleForm.gradeName = row.gradeName;
      this.ruleForm.gradeStatus = row.gradeStatus;
      this.ruleForm.remarks = row.remarks;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        channelType: '',
        gradeCode: '',
        gradeName: '',
        gradeStatus: 1,
        remarks: ''
      };
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    setInUse: function setInUse(id) {
      var _this2 = this;
      this.$confirm('请确认是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('startbtn', _this2.$route.meta.authList);
        (0, _channel.channelGradeStatus)({
          channelStatus: 1,
          pId: id
        }).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          if (res.data.code != 200) {
            _this2.$message.error(res.data.msg);
          } else {
            _this2.$message({
              message: '操作成功',
              type: 'success'
            });
            _this2.getList();
          }
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(id) {
      var _this3 = this;
      this.$confirm('请确认是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('stopbtn', _this3.$route.meta.authList);
        (0, _channel.channelGradeStatus)({
          channelStatus: 0,
          pId: id
        }).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          if (res.data.code != 200) {
            _this3.$message.error(res.data.msg);
          } else {
            _this3.$message({
              message: '操作成功',
              type: 'success'
            });
            _this3.getList();
          }
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    DoDel: function DoDel(id) {
      var _this4 = this;
      var that = this;
      that.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('deletebtn', _this4.$route.meta.authList);
        (0, _channel.channelGradeStatus)({
          channelStatus: 2,
          pId: id
        }).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          if (res.data.code == 200) {
            _this4.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            _this4.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;