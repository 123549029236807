var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-suffix": ":",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "billNo", label: "物流单号" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "billNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billNo", label: "NC单号" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.ncno,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "ncno",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.ncno",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "NC单据日期",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.billSaleQuery.billDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.billSaleQuery,
                                          "billDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.billSaleQuery.billDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNo",
                                    label: "经销商编码",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.billSaleQuery.dealerCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.billSaleQuery,
                                          "dealerCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.billSaleQuery.dealerCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNo",
                                    label: "经销商名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.billSaleQuery.dealerName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.billSaleQuery,
                                          "dealerName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.billSaleQuery.dealerName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNo",
                                    label: "主户头编码",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.billSaleQuery
                                          .accountCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.billSaleQuery,
                                          "accountCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.billSaleQuery.accountCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNo",
                                    label: "主户头名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.billSaleQuery
                                          .accountName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.billSaleQuery,
                                          "accountName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.billSaleQuery.accountName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billStatus",
                                    label: "单据类型",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.setFactory },
                                      model: {
                                        value:
                                          _vm.searchForm.billSaleQuery
                                            .outSaleBillType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm.billSaleQuery,
                                            "outSaleBillType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.billSaleQuery.outSaleBillType",
                                      },
                                    },
                                    _vm._l(_vm.djlx, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billStatus",
                                    label: "单据状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.setFactory },
                                      model: {
                                        value: _vm.searchForm.billStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "billStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.billStatus",
                                      },
                                    },
                                    _vm._l(_vm.djzt, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "起始时间",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "endTime", label: "结束时间" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endTime", $$v)
                                      },
                                      expression: "searchForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("search")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", [
                        _c(
                          "div",
                          [
                            _c("span", [_vm._v("显示调出单据:")]),
                            _vm._v(" "),
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#C0C4CC",
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "是",
                                "inactive-text": "否",
                              },
                              on: { change: _vm.getList },
                              model: {
                                value:
                                  _vm.searchForm.billSaleQuery.displayTransOut,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchForm.billSaleQuery,
                                    "displayTransOut",
                                    $$v
                                  )
                                },
                                expression:
                                  "searchForm.billSaleQuery.displayTransOut",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "btnArea" }, [
                _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.addBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: { click: _vm.addInsertFun },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "80",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "NC单号",
                      prop: "NCNO",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      label: "物流单号",
                      prop: "billNo",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.billNo) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth(
                                            "zdupdatebtn",
                                            _vm.$route.meta.authList
                                          )
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.amendInsertFun(
                                                        row,
                                                        "whole"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth(
                                            "zddeletebtn",
                                            _vm.$route.meta.authList
                                          )
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      !(
                                                        row.billStatus == 101
                                                      ) || !row.isEnable,
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.zdDelete(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth(
                                            "zdallotbtn",
                                            _vm.$route.meta.authList
                                          )
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      row.billStatus == 101 ||
                                                      row.billStatus == 102 ||
                                                      row.billStatus == 103 ||
                                                      !row.isEnable ||
                                                      row.billStatus == 111 ||
                                                      row.billStatus == 110 ||
                                                      row.isErrorBill == 0,
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoAllot(
                                                        row,
                                                        "whole"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("调拨")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3335878210
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "140",
                      fixed: "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.formattedDjzt(row.billStatus),
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formattedDjzt(row.billStatus)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1286034291
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "NC单据日期",
                      prop: "billDate",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(row.billDate) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1844859168
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商编码",
                      prop: "dealerCode",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "dealerName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "主户头编码",
                      prop: "accountCode",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "主户头名称",
                      prop: "accountName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "NC产品名称",
                      prop: "ncproductName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "计划数量",
                      prop: "planScanAtoNum",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "实发数量",
                      prop: "realScanAtoNum",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "定制产品",
                      prop: "productType",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.productType == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("是"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.productType == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("否"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1759310982
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货方式",
                      prop: "sendStyle",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.sendStyle == 1
                                      ? "扫码发货"
                                      : row.sendStyle == 2
                                      ? "自动发货"
                                      : "未知"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2320034270
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "productModel",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据类型",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.formattedDjlx(row.outSaleBillType))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2607776440
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位名称",
                      prop: "outOrgName",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货库房",
                      prop: "outStoreName",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "调入数量",
                      prop: "transInNum",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货总量",
                      prop: "sendTotalNum",
                      "min-width": "160",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据来源",
                      width: "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("djlyText")(row.billSource))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      715201722
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createUser",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "结单发货日期",
                      prop: "statementTime",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否启用",
                      prop: "createTime",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.isEnable == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("是"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.isEnable == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("否"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2042184646
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("qrcodebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("printbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("cxjdbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          "min-width": "240",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getflow(row, 10)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.amendInsertFun(
                                                row,
                                                "single"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "allotbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled:
                                              row.billStatus == 101 ||
                                              row.billStatus == 102 ||
                                              row.billStatus == 103 ||
                                              !row.isEnable ||
                                              row.billStatus == 111 ||
                                              row.billStatus == 110 ||
                                              row.isErrorBill == 0,
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoAllot(row, "single")
                                            },
                                          },
                                        },
                                        [_vm._v("调拨")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "stopbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled:
                                              !(row.billStatus == 101) ||
                                              !row.isEnable,
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.stopItem(row, 10)
                                            },
                                          },
                                        },
                                        [_vm._v("禁用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "printbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goPrint(row)
                                            },
                                          },
                                        },
                                        [_vm._v("打印")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2911373506
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.code_title,
            visible: _vm.codeVisible,
            width: "312px !important",
            "before-close": _vm.handleCodeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("div", {
              ref: "qrCodeUrl",
              staticClass: "qrcode",
              attrs: { id: "qrcode" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleCodeClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.sloading,
              expression: "sloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "销售出库手动同步",
            visible: _vm.SyncVisible,
            "before-close": _vm.SyncClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.SyncVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "Syncform",
              staticStyle: { height: "100px" },
              attrs: { model: _vm.Syncform, rules: _vm.Syncrules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间选择：", prop: "time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.Syncform.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.Syncform, "time", $$v)
                      },
                      expression: "Syncform.time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.SyncClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.SyncSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("insert", { ref: "insertMain" }),
      _vm._v(" "),
      _c("insertbatch", { ref: "insertbatch" }),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
      _vm._v(" "),
      _c("addInsert", { ref: "addInsert" }),
      _vm._v(" "),
      _c("amendInsert", { ref: "amendInsert" }),
      _vm._v(" "),
      _c("allot", { ref: "allot" }),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              "can-change": _vm.factorycanChange,
              "company-type": _vm.factoryType,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("productDialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _vm.apartShow
        ? _c("apartDialog", {
            attrs: { visible: _vm.apartShow, "bill-no": _vm.billNo },
            on: { close: _vm.apartClose, change: _vm.apartChange },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.printShow
        ? _c("printDialog", {
            attrs: {
              type: _vm.typeNum,
              visible: _vm.printShow,
              "bill-no": _vm.billNo,
              ncno: _vm.NCNO,
            },
            on: { close: _vm.printClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }