var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "策略类型:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "全部" },
                              on: { change: _vm.relevance },
                              model: {
                                value: _vm.formInline.strategyTypeOne,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "strategyTypeOne",
                                    $$v
                                  )
                                },
                                expression: "formInline.strategyTypeOne",
                              },
                            },
                            _vm._l(_vm.strategyTypeListOne, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.strategyTypeName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "label" }, slot: "label" },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "全部" },
                              on: { change: _vm.relevance2 },
                              model: {
                                value: _vm.formInline.strategyTypeTwo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "strategyTypeTwo",
                                    $$v
                                  )
                                },
                                expression: "formInline.strategyTypeTwo",
                              },
                            },
                            _vm._l(_vm.strategyTypeListTwo, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.strategyTypeName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "策略名称:" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formInline.strategyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "strategyName", $$v)
                              },
                              expression: "formInline.strategyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "label" }, slot: "label" },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _vm.formConfig.showDvider
                            ? _c("el-divider", {
                                attrs: { direction: "vertical" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: { click: _vm.reset },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.addStrategy },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.addBtn.text) + "策略")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "strategyName",
                      align: _vm.tableConfig.align,
                      label: "策略名称",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "setupTime",
                      align: _vm.tableConfig.align,
                      label: "配置时间",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "strategyTypeOneName",
                      align: _vm.tableConfig.align,
                      label: "一级策略类型",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "strategyTypeTwoName",
                      align: _vm.tableConfig.align,
                      label: "二级策略类型",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "groupNum",
                      align: _vm.tableConfig.align,
                      label: "关联策略组数",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "240",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown",
                              { staticClass: "table-dropdown" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(
                                      "\n                更多\n                "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemarks(row)
                                              },
                                            },
                                          },
                                          [_vm._v("备注")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(row)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.queryParams.pageNo,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getStrategy,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "820px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.cancel("ruleForm")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "padding-right": "40px" },
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "auto",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配置名称:", prop: "strategyName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.strategyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "strategyName", $$v)
                      },
                      expression: "ruleForm.strategyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "boxflex" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "策略类型:", prop: "strategyTypeOne" } },
                    [
                      _c(
                        "el-select",
                        {
                          class: _vm.secondwidth2,
                          staticStyle: { width: "260px" },
                          attrs: { placeholder: "请选择一级策略类型" },
                          on: { change: _vm.strategyType },
                          model: {
                            value: _vm.ruleForm.strategyTypeOne,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "strategyTypeOne", $$v)
                            },
                            expression: "ruleForm.strategyTypeOne",
                          },
                        },
                        _vm._l(_vm.strategyTypeListOne, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.strategyTypeName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "boxleft",
                      attrs: { prop: "strategyTypeTwo" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          class: _vm.secondwidth,
                          staticStyle: { width: "260px" },
                          attrs: { placeholder: "请选择二级策略类型" },
                          on: { change: _vm.strategyTypeTwo },
                          model: {
                            value: _vm.ruleForm.strategyTypeTwo,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "strategyTypeTwo", $$v)
                            },
                            expression: "ruleForm.strategyTypeTwo",
                          },
                        },
                        _vm._l(_vm.strategyTypeListTwo, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.strategyTypeName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isTagName
                ? _c(
                    "el-form-item",
                    { attrs: { label: "标签名称:", prop: "name2" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.name2,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name2", $$v)
                          },
                          expression: "ruleForm.name2",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isQuality
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "保质期:",
                            prop: "strategyJsonExpandBo.awardBo.expirationDay",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.awardBo
                                  .expirationDay,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.awardBo,
                                  "expirationDay",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.awardBo.expirationDay",
                            },
                          }),
                          _vm._v("\n          天\n        "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "超期策略:",
                            prop: "strategyJsonExpandBo.awardBo.expirationRule",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "无法参与活动" },
                              model: {
                                value:
                                  _vm.ruleForm.strategyJsonExpandBo.awardBo
                                    .expirationRule,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.strategyJsonExpandBo.awardBo,
                                    "expirationRule",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.strategyJsonExpandBo.awardBo.expirationRule",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "无法参与活动", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShow
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人抽奖总次数:",
                            prop: "strategyJsonExpandBo.safeBo.totalNumLimit",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.safeBo
                                  .totalNumLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.safeBo,
                                  "totalNumLimit",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.safeBo.totalNumLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人每天抽奖次数:",
                            prop: "strategyJsonExpandBo.safeBo.dayLotteryNumLimit",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.safeBo
                                  .dayLotteryNumLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.safeBo,
                                  "dayLotteryNumLimit",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.safeBo.dayLotteryNumLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人每天最大中奖次数:",
                            prop: "strategyJsonExpandBo.safeBo.dayWinningNumLimit",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.safeBo
                                  .dayWinningNumLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.safeBo,
                                  "dayWinningNumLimit",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.safeBo.dayWinningNumLimit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每人抽奖间隔（秒）:",
                            prop: "strategyJsonExpandBo.safeBo.lotteryInterval",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.safeBo
                                  .lotteryInterval,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.safeBo,
                                  "lotteryInterval",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.safeBo.lotteryInterval",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单人活动抽总奖金额上限（元）:",
                            prop: "strategyJsonExpandBo.safeBo.totalMoneyLimit",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.safeBo
                                  .totalMoneyLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.safeBo,
                                  "totalMoneyLimit",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.safeBo.totalMoneyLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isCode
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "同数码连续错误次数:",
                            prop: "strategyJsonExpandBo.codeSafeBo.errorNum",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.codeSafeBo
                                  .errorNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.codeSafeBo,
                                  "errorNum",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.codeSafeBo.errorNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "封禁时长(分钟):",
                            prop: "strategyJsonExpandBo.codeSafeBo.bannedMinutes",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "260px" },
                            model: {
                              value:
                                _vm.ruleForm.strategyJsonExpandBo.codeSafeBo
                                  .bannedMinutes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.strategyJsonExpandBo.codeSafeBo,
                                  "bannedMinutes",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.strategyJsonExpandBo.codeSafeBo.bannedMinutes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value:
                                  _vm.ruleForm.strategyJsonExpandBo.codeSafeBo
                                    .dragIntoFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.strategyJsonExpandBo
                                      .codeSafeBo,
                                    "dragIntoFlag",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.strategyJsonExpandBo.codeSafeBo.dragIntoFlag",
                              },
                            },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  label: "连续触发2次，是否拉入数码黑名单",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.cancel("ruleForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.determine("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "策略备注", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "remark",
              staticStyle: { padding: "0 20px" },
              attrs: { model: _vm.remark },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注信息", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.remark.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.remark, "remark", $$v)
                      },
                      expression: "remark.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.remarkCancel("remark")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.remarkEnter("remark")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看策略配置", visible: _vm.look },
          on: {
            "update:visible": function ($event) {
              _vm.look = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "min-height": "150px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 2, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "配置名称" } }, [
                    _vm._v(_vm._s(_vm.lookdata.strategyName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "策略备注" } }, [
                    _vm._v(_vm._s(_vm.lookdata.remark)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "策略类型" } }, [
                    _vm._v(_vm._s(_vm.lookdata.strategyTypeOneName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "二级策略类型" } },
                    [_vm._v(_vm._s(_vm.lookdata.strategyTypeTwoName))]
                  ),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "保质期" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.awardBo
                                .expirationDay
                            ) + "天"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 3
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "超期策略" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.awardBo
                                .expirationRule == 0
                                ? "无法参与活动"
                                : ""
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "每人抽奖总次数" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .totalNumLimit
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "每人每天抽奖次数" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .dayLotteryNumLimit
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "每人每天最大中奖次数" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .dayWinningNumLimit
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "每人抽奖间隔（秒）:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .lotteryInterval
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "单人活动抽总奖金额上限（元）:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .totalMoneyLimit
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "每人抽奖间隔（秒）:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.safeBo
                                .lotteryInterval
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 5
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "封禁时长(分钟):" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.codeSafeBo
                                .bannedMinutes
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 5
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "同数码连续错误次数:" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.codeSafeBo
                                .errorNum
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lookdata.strategyTypeTwo == 5
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "连续触发2次，是否拉入数码黑名单" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.lookdata.strategyJsonExpandBo.codeSafeBo
                                .dragIntoFlag
                                ? "开启"
                                : "关闭"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }