"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.abnormalOverview = abnormalOverview;
exports.productOrderFailChart = productOrderFailChart;
var _request = _interopRequireDefault(require("@/utils/request"));
// 防伪统计api

//  异常数据总览
function abnormalOverview(params) {
  return (0, _request.default)({
    url: '/storage-center/ccUnusualChart/abnormalOverview',
    method: 'get',
    params: params
  });
}

// 数据异常概况图表
function productOrderFailChart(params) {
  return (0, _request.default)({
    url: '/storage-center/ccUnusualChart/productOrderFailChart',
    method: 'get',
    params: params
  });
}