"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic.js");
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _label = require("@/api/mark/coding_service/coding_apply_mange/label");
var _cover = require("@/api/mark/coding_service/cover_apply_mange/cover");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _cover_tree = _interopRequireDefault(require("./cover_tree"));
var _audit_log = _interopRequireDefault(require("../../components/audit_log"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// const listQuery = {
//   pageNo: 1,
//   pageSize: 20,
//   packageOrderNum: '',
//   applyType: ''
// }
var setTemp = {
  packScaleDesc: '',
  packScaleExpression: '',
  packCode: '',
  flagName: ''
};
var codeTypeList = [];
var _default = {
  name: 'ComplexTable',
  components: {
    label_tree: _cover_tree.default,
    audit_list: _audit_log.default,
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (!time) return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内层级',
        2: '层级',
        3: '外层级'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      return statusPack[packLevel] + statusMark[markType] + '-' + item[0].markLen + '位';
    },
    generateStatus: function generateStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '未生成',
        2: '生成成功',
        3: '生成失败'
      };
      return statusMap[status];
    }
  },
  props: ['applyType', 'id', 'dialogStatus', 'dialogApplyVisible'],
  data: function data() {
    var _this = this;
    var validateOrderNum = function validateOrderNum(rule, value, callback) {
      if (_this.temp.applyStyle == 1 && !value) {
        callback('请填写订单数量');
      } else {
        callback();
      }
    };
    return {
      imgUrl: require("../../../../../images/origin.png"),
      generateColor: {
        1: 'unset',
        2: 'success',
        3: 'fail'
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      active: 0,
      tableKey: 2,
      dataFormdisabled: false,
      // list: null,
      flagelist: null,
      logList: null,
      temp: {
        PId: '',
        isPlatform: 0,
        applicationType: '',
        applyMarkType: 1,
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      },
      setTemp: setTemp,
      codelistLoading: false,
      bottleNum: 1,
      boxNum: 1,
      chestNum: 1,
      loading: false,
      btn_loading: false,
      showPackCode: true,
      showOrderNum: true,
      showafterBox: false,
      showCareNum: false,
      showBoxNum: false,
      showBottleNum: false,
      // dialogStatus: '',
      textMap: {
        create: '制码申请',
        set: '查看详情',
        edit: '编辑详情'
      },
      // dialogApplyVisible: false,
      groupInnerVisible: false,
      rules: {
        applyStyle: [{
          required: true,
          message: '请选择申请方式',
          trigger: 'change'
        }],
        applicationType: [{
          required: true,
          message: '请选择应用场景',
          trigger: 'change'
        }],
        markOrderNo: [{
          required: true,
          message: '请填写制码订单号',
          trigger: 'change'
        }],
        orderNum: [{
          required: true,
          trigger: 'blur',
          validator: validateOrderNum
        }],
        expireDate: [{
          required: true,
          message: '请选择数码过期时间',
          trigger: 'change'
        }],
        packRatioCode: [{
          required: true,
          message: '请选择包装比例',
          trigger: 'change'
        }],
        flagGroupId: [{
          required: true,
          message: '请选择标识组',
          trigger: 'change'
        }]
      },
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        flagId: '',
        supplierType: 2
      }
    };
  },
  watch: {
    'temp.PId': function tempPId(newVal, oldVal) {
      if (Number(newVal) == 0) {
        this.Reset();
      } else {
        this.Details();
      }
    },
    'temp.flagGroupCode': function tempFlagGroupCode(newVal, oldVal) {
      this.getSelectCode(newVal);
    } // dialogStatus(newVal, oldVal) {
    //   if (newVal == 'set' || newVal == null) {
    //     this.dataFormdisabled = true
    //   }
    //   if (newVal == 'edit' || newVal == 'create') {
    //     this.dataFormdisabled = false
    //   }
    // }
  },
  mounted: function mounted() {
    this.getUserInfo();
    this.getCodeType();
    if (this.dialogStatus == 'set') {
      this.dataFormdisabled = true;
    } else if (this.dialogStatus == 'edit' || this.dialogStatus == 'create') {
      this.dataFormdisabled = false;
    }
    if (this.dialogStatus == 'set' || this.dialogStatus == 'edit') {
      this.loading = true;
      this.getDetail();
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      (0, _label.getMarkApplyDetail)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this2.temp = Object.assign(_this2.temp, res.data.data);
          _this2.temp.PId = _this2.temp.pid;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    setNum: function setNum(val) {
      this.flagelist = JSON.parse(JSON.stringify(this.flagelist));
    },
    getCodeType: function getCodeType() {
      var _this3 = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getUserInfo: function getUserInfo() {
      var _this4 = this;
      (0, _cover.getSupplierByUser)().then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this4.temp.supplierCode = res.data.data.supplierCode;
            _this4.temp.supplierName = res.data.data.supplierName;
          } else {
            _this4.$message.error('获取供应商信息失败，请使用供应商用户登录！');
          }
        }
      });
    },
    showLabelCode: function showLabelCode() {
      // 显示标签组页面
      this.groupInnerVisible = true;
    },
    getModelList: function getModelList() {
      var _this5 = this;
      var that = this;
      that.modeLoading = true;
      (0, _supplierRFlag.flagTemplatePage)(that.listQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.flagelist = response.data.data.records;
          } else {
            that.total = 0;
            that.flagelist = [];
          }
          that.modeLoading = false;
          _this5.groupInnerVisible = false;
        }
      }).catch(function (res) {
        that.modeLoading = false;
      });
    },
    getSelectCode: function getSelectCode($code) {
      var code = this.$refs.labelGroupMain.flagGroupId;
      this.temp.flagGroupId = code;
      this.listQuery.flagId = code;
      if (code) {
        this.getModelList();
      } else {
        this.flagelist = [];
        this.total = 0;
      }
      this.$refs.dataForm.validateField('flagGroupId');
      this.labelClose();
    },
    Reset: function Reset() {
      if (this.$refs.dataForm != undefined) {
        this.$refs.dataForm.resetFields();
      }
    },
    CloseMain: function CloseMain() {
      this.Reset();
      // this.dialogStatus = ''
      this.setTemp.packScaleDesc = '';
      this.temp = {
        PId: '',
        isPlatform: 0,
        applicationType: '',
        applyMarkType: 1,
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      };
      this.flagelist = [];
      // this.dialogApplyVisible = false
      this.groupInnerVisible = false;
      this.logList = null;
      this.showPackCode = true;
      this.showOrderNum = true;
      this.showafterBox = false;
      this.showCareNum = false;
      this.showBoxNum = false;
      this.showBottleNum = false;
      this.authId('searchbtn', this.$route.meta.authList);
      this.$emit('close');
    },
    handleClose: function handleClose(done) {
      this.CloseMain();
    },
    labelClose: function labelClose() {
      this.$refs.labelGroupMain.flagGroupId = '';
      this.$refs.labelGroupMain.$refs.list_con.modelList = [];
      this.$refs.labelGroupMain.$refs.list_con.total = 0;
      this.total = 0;
      this.groupInnerVisible = false;
    },
    createData: function createData() {
      var _this6 = this;
      /* if(this.list.length!=this.setTemp.packScaleExpression.split('*').length && this.temp.applyType==1){
        this.$message('包装层级与标识组无法对应');
        return false;
      }
      */
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this6.btn_loading = true;
          (0, _cover.capInsert)(_this6.temp).then(function (res) {
            _this6.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this6.$message.error(data.msg);
              return false;
            }
            _this6.$message({
              message: '操作成功',
              type: 'success'
            });
            _this6.$parent.getList();
            _this6.CloseMain();
          }).catch(function () {
            _this6.btn_loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this7 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this7.btn_loading = true;
          _this7.temp.PId = _this7.temp.pid;
          delete _this7.temp.createTime;
          delete _this7.temp.creatorId;
          delete _this7.temp.updateTime;
          delete _this7.temp.updaterId;
          delete _this7.temp.pid;
          (0, _cover.capUpdate)(_this7.temp).then(function (res) {
            _this7.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this7.$message.error(data.msg);
              return false;
            }
            _this7.$parent.getList();
            _this7.$message({
              message: '操作成功',
              type: 'success'
            });
            _this7.CloseMain();
          }).catch(function () {
            _this7.btn_loading = false;
          });
        }
      });
    },
    Details: function Details() {
      this.listQuery.flagId = this.temp.flagGroupId;
      if (this.temp.applyStatus != 2) {
        this.active = 1;
      } else if (this.temp.generateStatus != 2) {
        this.active = 2;
      } else if (this.temp.downloadStatus != 2) {
        this.active = 3;
      } else {
        this.active = 4;
      }
      this.getModelList();
    }
  }
};
exports.default = _default;