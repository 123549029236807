var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("生产入库设置")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "page-section search-condition",
          attrs: { model: _vm.form, "status-icon": "" },
        },
        [
          _c("div", { staticClass: "phead" }, [_vm._v("生产入库方式")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.form.inStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inStyle", $$v)
                            },
                            expression: "form.inStyle",
                          },
                        },
                        [_vm._v("自动")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.form.inStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inStyle", $$v)
                            },
                            expression: "form.inStyle",
                          },
                        },
                        [_vm._v("手动")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }