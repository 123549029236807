var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchform",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.listQuery,
                  "label-suffix": ":",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "markOrderNo",
                                  label: "制码订单号",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    value: _vm.listQuery.markOrderNo,
                                    placeholder: "制码订单号",
                                    maxlength: "20",
                                  },
                                  on: {
                                    input: function (e) {
                                      return (_vm.listQuery.markOrderNo =
                                        _vm.validSe(e))
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "applyStyle",
                                  label: "申请方式",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择申请方式",
                                    },
                                    model: {
                                      value: _vm.listQuery.applyStyle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "applyStyle",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.applyStyle",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectOptions.applyType,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "applyStatus",
                                  label: "申请状态",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择申请状态",
                                    },
                                    model: {
                                      value: _vm.listQuery.applyStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "applyStatus",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.applyStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: "" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "待审核", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "审核通过", value: 2 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "审核未通过", value: 3 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.handleShow || !_vm.formConfig.btnFollow,
                                expression: "handleShow||!formConfig.btnFollow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "generateStatus",
                                  label: "制码状态",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择制码状态",
                                    },
                                    model: {
                                      value: _vm.listQuery.generateStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "generateStatus",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.generateStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: "" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "未生成", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "生成成功", value: 2 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "生成失败", value: 3 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "downloadStatus",
                                  label: "下载状态",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择下载状态",
                                    },
                                    model: {
                                      value: _vm.listQuery.downloadStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "downloadStatus",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.downloadStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: "" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "已下载", value: 2 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "未下载", value: 1 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "startTime", label: "开始日期" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.listQuery.startTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "startTime", $$v)
                                    },
                                    expression: "listQuery.startTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "endTime", label: "结束日期" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.listQuery.endTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "endTime", $$v)
                                    },
                                    expression: "listQuery.endTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleFilter("search")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleFilter("rest")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            _vm.handleShow = !_vm.handleShow
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.handleShow ? "收起" : "展开"
                                            ) +
                                            "\n                "
                                        ),
                                        _c("i", {
                                          class: _vm.handleShow
                                            ? "el-icon-arrow-up"
                                            : "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                !_vm.formConfig.btnFollow
                  ? _c(
                      "el-col",
                      {
                        staticClass: "head-btn-group",
                        staticStyle: { width: "230px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.searchBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.searchBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleFilter("search")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.resetBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.resetBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleFilter("rest")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.handleShow = !_vm.handleShow
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.handleShow ? "收起" : "展开") +
                                "\n          "
                            ),
                            _c("i", {
                              class: _vm.handleShow
                                ? "el-icon-arrow-up"
                                : "el-icon-arrow-down",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.addBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(false, "create")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "table-box" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "100",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "制码订单号",
                        "min-width": "200",
                        align: _vm.tableConfig.align,
                        prop: "markOrderNo",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请方式",
                        width: "150",
                        "show-overflow-tooltip": "",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("applyType")(scope.row.applyStyle)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        328912848
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量(万)",
                        width: "100",
                        align: _vm.tableConfig.align,
                        prop: "orderNum",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "包装比例描述",
                        "min-width": "180",
                        align: _vm.tableConfig.align,
                        prop: "packScaleExpression",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请状态",
                        width: "120",
                        "show-overflow-tooltip": "",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applyStatus == 3
                                  ? _c("span", { staticClass: "danger" }, [
                                      _vm._v("审核未通过"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyStatus == 2
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("审核通过"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyStatus == 1
                                  ? _c("span", { staticClass: "info" }, [
                                      _vm._v("待审核"),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1555047842
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "制码状态",
                        width: "120",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.generateStatus == 3
                                  ? _c("span", { staticClass: "danger" }, [
                                      _vm._v("生成失败"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.generateStatus == 2
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("生成成功"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.generateStatus == 1
                                  ? _c("span", { staticClass: "info" }, [
                                      _vm._v("未生成"),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4213037301
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "下载状态",
                        width: "120",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.downloadStatus == 2
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("已下载"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.downloadStatus == 1
                                  ? _c("span", { staticClass: "info" }, [
                                      _vm._v("未下载"),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1049606342
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请时间",
                        width: "165",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.createTime)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        921844889
                      ),
                    }),
                    _vm._v(" "),
                    _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                    _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                    _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: _vm.tableConfig.align,
                            width: "230",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm.hasAuth(
                                      "detailbtn",
                                      _vm.$route.meta.authList
                                    )
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "set"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("详情")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasAuth(
                                      "updatebtn",
                                      _vm.$route.meta.authList
                                    ) && row.applyStatus == 3
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "edit"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasAuth(
                                      "deletebtn",
                                      _vm.$route.meta.authList
                                    ) && row.applyStatus == 3
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "del"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3053374460
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNo,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNo", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
                _vm._v(" "),
                _vm.isShow
                  ? _c("apply", {
                      ref: "applyMain",
                      attrs: {
                        id: _vm.temp.pid,
                        "dialog-status": _vm.temp.dialogStatus,
                        "dialog-apply-visible": _vm.isShow,
                        "apply-type": _vm.selectOptions.applyType,
                      },
                      on: {
                        close: function ($event) {
                          _vm.isShow = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }