"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteByCustomerIdAndLableId = deleteByCustomerIdAndLableId;
exports.deleteById = deleteById;
exports.downloadmsgmb = downloadmsgmb;
exports.getExportFailExcel = getExportFailExcel;
exports.getmerberVip = getmerberVip;
exports.getmoWxCustomerdetail = getmoWxCustomerdetail;
exports.getmoWxCustomersearch = getmoWxCustomersearch;
exports.getmockSendSms = getmockSendSms;
exports.getmsgBq = getmsgBq;
exports.getmsgImport = getmsgImport;
exports.getmsgList = getmsgList;
exports.getmsgPage = getmsgPage;
exports.getmsgdetail = getmsgdetail;
exports.getmsgmbList = getmsgmbList;
exports.getmsgnum = getmsgnum;
exports.getmsgsave = getmsgsave;
exports.getselectActList = getselectActList;
exports.queryFailForPage = queryFailForPage;
exports.savemoWxLable = savemoWxLable;
exports.searchByLable = searchByLable;
exports.searchByType = searchByType;
exports.searchDy = searchDy;
exports.searchmoWxLable = searchmoWxLable;
exports.selectActList = selectActList;
exports.selectProductList = selectProductList;
exports.selectProuctList = selectProuctList;
exports.sendSms = sendSms;
exports.sendstatus = sendstatus;
exports.updateUserLable = updateUserLable;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
var baseUrl = '';

// 提交短信规则
function getmsgsave(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsSupplier/save',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 短信规则详情
function getmsgdetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsSupplier/detail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    params: params
  });
}

// 查询短信平台下拉
function getmsgList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsSupplier/platform/select',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 查询短信模板下拉
function getmsgmbList(argument, data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsSupplier/template/select/v2?smsSource=' + argument,
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 短信列表
function getmsgPage(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/queryForPage',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}

// 查询标签
function getmsgBq(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/label/select',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 查询会员等级
function getmerberVip(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/memberLevel/select',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 导入群发短信
function getmsgImport(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/mobile/import',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 测试群发短信
function getmockSendSms(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/mockSendSms',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 查询群发人群数量
function getmsgnum(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/sendNum/select',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 群发短信
function sendSms(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/sendSms',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 查询发送状态
function sendstatus(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/sendstatus/select',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 手机号码导入模板下载
function downloadmsgmb(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroup/template/download',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'GET',
    responseType: 'blob',
    params: params
  });
}

// 异常短信分页导出
function getExportFailExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroupRecord/exportFailExcel',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    responseType: 'blob',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 异常短信分页查询
function queryFailForPage(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/smsGroupRecord/queryFailForPage',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}

/* 群发短信 end */

/* 客户列表 标签 start*/
// 活动查询
function selectActList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/selectActList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 产品查询
function selectProductList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/selectProductList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 下载明细
function getmoWxCustomerdetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxCustomer/down/detail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    responseType: 'blob',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 用户查询
function getmoWxCustomersearch(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxCustomer/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 地区查询
function searchDy(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxCustomer/searchDy',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 用户打标签
function updateUserLable(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxCustomer/updateUserLable',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}

// 删除用户标签
function deleteByCustomerIdAndLableId(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/deleteByCustomerIdAndLableId',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 根据id删除标签
function deleteById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/deleteById',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 添加标签
function savemoWxLable(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/save',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'POST',
    data: data
  });
}
// 标签查询
function searchmoWxLable(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 查询所有手动标签
function searchByType(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/searchByType',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 查询所有标签
function searchByLable(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxLable/searchByLable',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 产品查询
function selectProuctList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/currency/selectProuctList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 活动查询
function getselectActList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/currency/selectActList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}