"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _PieChart = _interopRequireDefault(require("@/components/dataAnalyse/PieChart"));
var _anomaly = require("@/api/dataAnalyse/anomaly.js");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PieChart: _PieChart.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      listLoading: false,
      sweepBtn: false,
      allColor: ['#fcc248', '#008371', '#459af0', '#38c3b0', '#86ca5a', '#bfd44f'],
      itemColor: ['#ff999a', '#ffcc67', '#af89d6', '#59adf3', '#99adf3'],
      count: 0,
      dateValue: [],
      type: '1',
      formInline: {
        productId: '',
        type: '1',
        productName: '',
        startTime: '',
        endTime: ''
      },
      itemData: [],
      abnormalType: '',
      list: [],
      provinceList: []
    };
  },
  created: function created() {
    // this.abnormalOverviewFun()
    // this.monthFun()
  },
  activated: function activated() {
    this.searchFun('1');
  },
  methods: {
    abnormalOverviewFun: function abnormalOverviewFun() {
      var _this2 = this;
      var formInline = JSON.parse(JSON.stringify(this.formInline));
      delete formInline.productName;
      (0, _anomaly.abnormalOverview)(formInline).then(function (res) {
        console.log(res);
        if (res.data.code === 200) {
          _this2.dataFun(res.data.data, 'all');
        }
      });
    },
    productOrderFailChartFun: function productOrderFailChartFun() {
      var _this3 = this;
      var formInline = JSON.parse(JSON.stringify(this.formInline));
      delete formInline.productName;
      (0, _anomaly.productOrderFailChart)((0, _objectSpread2.default)({
        checkoutType: this.abnormalType
      }, formInline)).then(function (res) {
        if (res.data.code === 200) {
          var titleArr = JSON.parse(JSON.stringify(res.data.data.titleData));
          titleArr.unshift({
            titleName: '日期',
            titleKey: 'createTime'
          });
          _this3.itemData = titleArr;
          _this3.provinceList = res.data.data.sheetData;
          _this3.dataFun(res.data.data);
        }
      });
    },
    searchFun: function searchFun(type) {
      this.formInline.type = type;
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.abnormalOverviewFun();
    },
    // 本月
    monthFun: function monthFun() {
      this.formInline.type = '1';
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.abnormalOverviewFun();

      // getMonthlyWlexceptionstatList({ productId: this.formInline.productId })
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.dataFun(res, 'all')
      //     }
      //   })
      //   .then((res) => {
      //     this.getMonthlyExceptypetListFun()
      //   })
    },
    // 时间选择
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.type = '';
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
        this.formInline.type = '1';
      }
      this.abnormalOverviewFun();
    },
    // 产品选择
    inputFocusFun: function inputFocusFun() {
      this.$refs.selProduct.proSelVisible = true;
    },
    dataFun: function dataFun(res, type) {
      var _this4 = this;
      var sData = [];
      if (type === 'all') {
        res.chartData.forEach(function (item, index) {
          if (index === 0) {
            _this4.abnormalType = item.abnormalType;
          }
          sData.push({
            value: item.abnormalNum,
            name: item.abnormalName,
            excepCategory: item.abnormalType
          });
        });
        this.list = res.sheetData;
        this.$refs.allError.sData = sData;
        this.$refs.allError.initChart();
        this.productOrderFailChartFun();
      } else {
        res.titleData.forEach(function (item, index) {
          sData.push({
            value: res.chartData[item.titleKey],
            name: item.titleName
          });
        });
        this.provinceList = res.sheetData;
        this.$refs.itemError.sData = sData;
        this.$refs.itemError.initChart();
      }
    },
    // 产品选择
    setCurrent: function setCurrent(row) {
      this.formInline.productName = row.productName;
      this.formInline.productId = row.pid;
      this.abnormalOverviewFun();
    },
    // 清除产品
    clearFun: function clearFun() {
      if (this.formInline.productId) {
        this.formInline.productId = '';
        this.formInline.productName = '';
        this.abnormalOverviewFun();
      }
    },
    // 切换类型
    getExceptypetData: function getExceptypetData(data) {
      this.excepCategoryName = data.excepCategoryName;
      this.abnormalType = data.excepCategory;
      this.productOrderFailChartFun();
    },
    // 异常下载
    provinceDown: function provinceDown() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#provinceTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '异常数据.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    // 异常分类下载
    cityDown: function cityDown() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#cityTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '异常数据.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;