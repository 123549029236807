var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-form",
              {
                ref: "formInline",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.formInline,
                  "status-icon": "",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "箱外码:", prop: "markCode" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.formInline.markCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "markCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formInline.markCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否有箱内码:",
                                  prop: "validStatus",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.formInline.validStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "validStatus",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.validStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "是", value: "0" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "否", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品编码/名称:",
                                  prop: "productCodeOrName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.formInline.productCodeOrName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "productCodeOrName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formInline.productCodeOrName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "用户ID/openid:",
                                  prop: "wechatIdOrOpenId",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.formInline.wechatIdOrOpenId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "wechatIdOrOpenId",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formInline.wechatIdOrOpenId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注册:", prop: "register" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.formInline.register,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "register",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.register",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "注册", value: "1" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "未注册", value: "2" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "终端编码/名称:",
                                  prop: "terminalCodeOrName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.formInline.terminalCodeOrName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "terminalCodeOrName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formInline.terminalCodeOrName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "认证:", prop: "authStatus" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.formInline.authStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "authStatus",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.authStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "认证", value: "1" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "未认证", value: "2" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "扫码时间:" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formInline.rangeTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "rangeTime", $$v)
                                    },
                                    expression: "formInline.rangeTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(" ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.searchBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.searchBtn.icon,
                                    },
                                    on: { click: _vm.onSearch },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.resetBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.resetBtn.icon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onReset("formInline")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.formConfig.btnFollow
          ? _c("el-divider", { staticClass: "btnDivider" })
          : _vm._e(),
        _vm._v(" "),
        _c("el-row", [
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                      loading: _vm.downLoading,
                    },
                    on: { click: _vm.download },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              height: _vm.tabHeight,
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              fit: "",
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "80",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "markCode",
                label: "箱外码",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "是否有箱内码",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.validStatus == 0
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("是"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.validStatus == 1
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("否"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "productCode",
                label: "产品编码",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "微信头像", align: _vm.tableConfig.align },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-image", {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: { src: scope.row.headimgurl, fit: "cover" },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "wechatName",
                label: "微信名称",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "wechatId",
                label: "用户ID",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "openId",
                label: "openid",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "是否注册",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.register == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("已注册"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.register == 2
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("未注册"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "terminalCode",
                label: "终端编码",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "terminalName",
                label: "终端名称",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "userName",
                label: "联系人",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "phoneNo",
                label: "手机号",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "是否认证",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.authStatus == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("已认证"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.authStatus == 2
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("未认证"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "activityName",
                label: "活动名称",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizePrice",
                label: "中奖结果",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizeType",
                label: "奖品名称",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "开始时间",
                align: _vm.tableConfig.align,
                "min-width": "140",
                "show-overflow-tooltip": "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("Pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.formInline.pageNo,
                limit: _vm.formInline.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.formInline, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.formInline, "pageSize", $event)
                },
                pagination: _vm.errorCodeListPage,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }