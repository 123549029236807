"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      uploadData: [],
      uploadForm: {
        uploadFile: ''
      },
      uploadRules: {},
      fileList: [],
      mloading: false
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/channelCollectionSupply/importExcel';
    }
  },
  mounted: function mounted() {},
  methods: {
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.$emit('close', false);
    },
    // 提交上传
    submitUpload: function submitUpload() {
      if (this.fileList.length > 0) {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      } else {
        this.$message.error('请导入上传文件');
      }
    },
    // 上传成功
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.$emit('close', true);
      }
      this.mloading = false;
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    // 上传失败
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    // 文件发生改变
    selectFile: function selectFile(file, fileList) {
      if (!file.response) {
        this.fileList = fileList;
        this.uploadForm.uploadFile = file.name;
      } else {
        this.fileList = [];
        this.uploadForm.uploadFile = '';
      }
      // this.uploadForm.uploadFile = file.name
    }
  }
};
exports.default = _default;