var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c("search", { ref: "searchMain" }),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _vm.formConfig.btnFollow
                ? _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "177px" },
                    },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                plain: "",
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.searchMain.handleFilter(
                                    "search"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                plain: "",
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.searchMain.handleFilter(
                                    "rest"
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        plain: "",
                        icon: _vm.btnConfig.addBtn.icon,
                      },
                      on: { click: _vm.add },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        type: _vm.btnConfig.downLoadBtn.type,
                        size: _vm.btnConfig.size,
                        plain: "",
                        icon: _vm.btnConfig.downLoadBtn.icon,
                        loading: _vm.downLoading,
                      },
                      on: { click: _vm.download },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "退货入库单号",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.num))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "退货单位",
                  prop: "returnOrgName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "入库企业",
                  prop: "inOrgName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "入库库房",
                  prop: "inStoreName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "完成进度",
                  width: "140",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-progress", {
                          attrs: {
                            "text-inside": true,
                            "stroke-linecap": "square",
                            "stroke-width": 16,
                            percentage: Number(row.progress),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据状态",
                  width: "140",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("TypeDataCom")(row.status, "status"))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据来源",
                  width: "140",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("TypeDataCom")(row.source, "source"))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "userName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "dateCreate",
                  width: "180",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("formatDate")(row.dateCreate))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "330",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getflow(row, 2)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        row.editAble == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doUpdate(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.del(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        row.source < 20
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getflow(row, 3)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getQrCode(row)
                              },
                            },
                          },
                          [_vm._v("生成二维码")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total,sizes,prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.code_title,
            visible: _vm.codeVisible,
            width: "230px !important",
            "before-close": _vm.handleCodeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c("div", {
            ref: "qrCodeUrl",
            staticClass: "qrcode",
            attrs: { id: "qrcode" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("insert", { ref: "insertMain" }),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(0)]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("\n      抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }