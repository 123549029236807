"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activeDataStatisticsExport = require("@/api/operationalTools/activeDataStatisticsExport");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsactiveDataStatisticsExportindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsactiveDataStatisticsExportindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      exportData: {
        rangeTime: ''
      }
    };
  },
  activated: function activated() {
    this.getCurrentData();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {}
  },
  methods: {
    getCurrentData: function getCurrentData() {
      var startDate = (0, _utils.parseTime)(new Date(new Date(new Date().toLocaleDateString()).getTime()));
      var endDate = (0, _utils.parseTime)(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1));
      this.exportData.rangeTime = [startDate, endDate];
      this.exportData.startTime = startDate;
      this.exportData.endTime = endDate;
    },
    getExprotActivity: function getExprotActivity() {
      var _this = this;
      var that = this;
      this.exportData.startTime = this.exportData.rangeTime[0];
      this.exportData.endTime = this.exportData.rangeTime[1];
      (0, _activeDataStatisticsExport.getExprotActivity)((0, _objectSpread2.default)({}, this.exportData)).then(function (res) {
        setTimeout(function () {
          _this.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "\u6D3B\u52A8\u7EDF\u8BA1\u6570\u636E_".concat(that.exportData.startTime, "-").concat(that.exportData.endTime, ".xlsx");
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    getExprotPrize: function getExprotPrize() {
      var _this2 = this;
      var that = this;
      this.exportData.startTime = this.exportData.rangeTime[0];
      this.exportData.endTime = this.exportData.rangeTime[1];
      (0, _activeDataStatisticsExport.getExprotPrize)((0, _objectSpread2.default)({}, this.exportData)).then(function (res) {
        setTimeout(function () {
          _this2.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "\u6D3B\u52A8\u5956\u54C1\u7EDF\u8BA1\u6570\u636E_".concat(that.exportData.startTime, "-").concat(that.exportData.endTime, ".xlsx");
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  }
};
exports.default = _default;