"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activityReport = require("@/api/marketingStatic/activityReport");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _quarter = _interopRequireDefault(require("@/components/quarter"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingStaticactivityReportlogoquaterStaticindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingStaticactivityReportlogoquaterStaticindex',
  components: {
    Pagination: _Pagination.default,
    quarter: _quarter.default
  },
  data: function data() {
    return {
      activeList: [],
      formInline: {
        auditStatus: null,
        // 审核状态 1待审核,2审核中,3已审核
        prizeType: null,
        // 奖品类型 100红包, 200实物, 999返利核销
        prizeLevel: null,
        // 奖品级别 1普通奖2大奖
        reportEndTime: null,
        // 结束时间
        reportStartTime: null,
        // 开始时间
        reportType: 2,
        // 报表类型 1月报表, 2季度报表
        schemeCode: null // 方案名称
      },

      rangeTime: '',
      params: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      downLoading: false,
      idList: [],
      // 复选
      title: '审核',
      dialogFormVisible: false,
      ruleForm: {
        remark: '',
        idList: []
      },
      rules: {
        remark: [{
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getactivelist();
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.reportList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityFlow.getwayList)({
        pageSize: 100,
        pageNo: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    // 获取错误数码兼容列表
    reportList: function reportList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.reportList)(submitData).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 获取季度子组件传回的数据
    getQuarter: function getQuarter(val) {
      this.formInline.reportStartTime = val['stTime'];
      this.formInline.reportEndTime = val['edTime'];
      this.rangeTime = val['seasonValue'];
    },
    sj: function sj(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.reportList();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.rangeTime = '';
      this.params.pageNo = 1;
      this.formInline = {
        auditStatus: null,
        // 审核状态 1待审核,2审核中,3已审核
        prizeType: null,
        // 奖品类型 100红包, 200实物, 999返利核销
        prizeLevel: null,
        // 奖品级别 1普通奖2大奖
        reportEndTime: null,
        // 结束时间
        reportStartTime: null,
        // 开始时间
        reportType: 2,
        // 报表类型 1月报表, 2季度报表
        schemeCode: null // 方案名称
      };

      this.$refs[formName].resetFields();
      this.reportList();
    },
    // 导出
    download: function download() {
      var _this = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      // console.log(submitData);
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.exportReportList)(submitData).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '季度报表.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    },
    setDisabled: function setDisabled(row) {
      if (row.auditStatus == 2) {
        return true;
      }
      return false;
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      var arr = data.map(function (item) {
        return item.id;
      });
      this.idList = arr;
      console.log(this.idList);
    },
    handleEdit: function handleEdit(row) {
      this.$router.push({
        name: 'marketingStaticactivityReportlogoprizeStaticindex',
        params: {
          schemeName: row.schemeName,
          prizeType: row.prizeType,
          reportStartTime: row.reportStartTime,
          reportEndTime: row.reportEndTime
        }
      });
    },
    submitBtn: function submitBtn(row) {
      console.log(row);
      var that = this;
      this.$confirm('是否要提交此条审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activityReport.submitReport)({
          idList: [row.id]
        }).then(function (res) {
          if (res.data.code != 200) {
            that.$message.error(res.data.msg);
          }
          that.$message.success('提交成功!');
          that.reportList();
        });
      }).catch(function () {
        that.$message({
          type: 'info',
          message: '已取消提交'
        });
      });
    },
    auditBtn: function auditBtn(row) {
      console.log(row);
      this.dialogFormVisible = true;
      if (row) {
        this.title = '审核';
        this.ruleForm.idList = [row.id];
      } else {
        this.title = '批量审核';
        this.ruleForm.idList = this.idList;
      }
      console.log(this.ruleForm);
    },
    confirmBtn: function confirmBtn() {
      var _this2 = this;
      var that = this;
      that.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _activityReport.auditReport)(_this2.ruleForm).then(function (res) {
            if (res.data.code != 200) {
              that.$message.error(res.data.msg);
            }
            that.$message.success('审核成功!');
            that.cancelBtn();
            that.reportList();
          });
        }
      });
    },
    cancelBtn: function cancelBtn() {
      this.dialogFormVisible = false;
      this.ruleForm = {
        remark: '',
        idList: []
      };
    }
  }
};
exports.default = _default;