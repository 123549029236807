"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modelUploadImg = _interopRequireDefault(require("@/components/modelUploadImg"));
var _templateManage = require("@/api/market/templateManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    UploadImg: _modelUploadImg.default
  },
  props: {
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    editType: {
      type: String,
      default: function _default() {
        return 'add';
      }
    },
    editData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      from: '',
      listLoading: false,
      activeName: 'basic',
      active: 0,
      activeMax: 1,
      checkName: '',
      bgImg: '',
      info: [],
      checkBoxList: [],
      checkList: [],
      renderList: [],
      basicForm: {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '5',
        templateType: '9'
      },
      contentForm: {
        title: '',
        background: '',
        bkbutton: '',
        guideAutoJump: '1',
        guideWaitSeconds: '3'
      },
      rules: {
        templateName: [{
          required: !this.disabled,
          message: '模版名称不能为空',
          trigger: 'change'
        }],
        templateStatus: [{
          required: !this.disabled,
          message: '请选择模板状态',
          trigger: 'change'
        }]
      },
      contentRules: {
        background: [{
          required: true,
          message: '请上传背景',
          trigger: 'change'
        }],
        title: [{
          required: false,
          message: '请输入标题',
          trigger: 'change'
        }],
        guideAutoJump: [{
          required: true,
          message: '请选择跳转方式',
          trigger: 'change'
        }],
        guideWaitSeconds: [{
          required: false,
          message: '请选择等待时常',
          trigger: 'change'
        }],
        bkbutton: [{
          required: true,
          message: '请上传按钮图片',
          trigger: 'change'
        }]
      },
      infoRules: {},
      errObj: {},
      disabledLoading: false
    };
  },
  mounted: function mounted() {
    this.basicForm.id = this.id;
    if (this.id) {
      this.getDetail();
    }
    console.log(22222);
    this.getInfo();
  },
  methods: {
    // 获取收集信息
    getInfo: function getInfo() {
      var _this = this;
      (0, _templateManage.getCollectionInfo)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this.checkBoxList = res.data;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    handleError: function handleError(url, filed) {
      if (/err$/.test(this.contentForm[filed])) {
        this.$set(this.contentForm, filed, url);
      } else {
        if (!this.errObj[url]) {
          this.errObj[url] = 1;
        } else {
          if (this.errObj[url] > 2) {
            return;
          } else {
            this.errObj[url] += 1;
          }
        }
        if (!/err$/.test(this.contentForm[filed])) {
          this.$set(this.contentForm, filed, url + '?err');
        }
      }
    },
    // 监听收集项选择
    handleCheckedInfoChange: function handleCheckedInfoChange(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].fieldName) {
              this.renderList.push(this.checkBoxList[j]);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 设置背景图片
    setBgImg: function setBgImg(val) {
      this.bgImg = val;
    },
    // 取消
    goBack: function goBack() {
      this.active = 0;
      this.basicForm = {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '5',
        templateType: '9'
      };
      this.contentForm = {
        title: '',
        background: '',
        ermImg: ''
      };
      this.$emit('close');
    },
    // 获取模板详细
    getDetail: function getDetail() {
      var item = this.editData;
      var paraseData = JSON.parse(this.editData.templateElements);
      this.bgImg = paraseData.background;
      this.basicForm = {
        id: item.id,
        templateName: item.templateName,
        templateRemark: item.templateRemark,
        templateStatus: item.templateStatus,
        templateCategory: item.templateCategory,
        templateType: '9'
      };
      this.contentForm = {
        title: paraseData.title,
        background: paraseData.background,
        bkbutton: paraseData.bkbutton,
        guideAutoJump: paraseData.guideAutoJump,
        guideWaitSeconds: paraseData.guideWaitSeconds
      };
    },
    // 数据转化
    transChangeData: function transChangeData(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        this.checkList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].id) {
              this.renderList.push(this.checkBoxList[j]);
              this.checkList.push(this.checkBoxList[j].fieldName);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 保存
    save: function save() {
      var _this2 = this;
      var content = this.checkForm('contentForm', '请完善活动信息');
      content.then(function (res) {
        _this2.saveReal();
      });
    },
    saveReal: function saveReal() {
      var _this3 = this;
      var cForm = {
        templateElements: JSON.stringify(this.contentForm)
      };
      var object = Object.assign(this.basicForm, cForm);
      this.disabledLoading = true;
      if (this.editType == 'add') {
        (0, _templateManage.templateAdd)(object).then(function (res) {
          _this3.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this3.$message({
                message: '添加成功！',
                type: 'success'
              });
              _this3.goBack();
            } else {
              _this3.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this3.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
      if (this.editType == 'edit') {
        (0, _templateManage.templateEdit)(object).then(function (res) {
          _this3.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this3.$message({
                message: '修改成功！',
                type: 'success'
              });
              _this3.goBack();
            } else {
              _this3.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this3.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
    },
    // 按钮事件
    subForm: function subForm(text) {
      var that = this;
      // 上一步
      if (text === 'up') {
        that.active--;
      } else if (text === 'down') {
        // 下一步
        if (this.active === 0) {
          var basic = this.checkForm('basicForm', '请完善基础信息');
          basic.then(function (res) {
            that.active++;
          });
        } else if (this.active === 1) {
          var content = this.checkForm('contentForm', '请完善活动信息');
          content.then(function (res) {
            that.active++;
          });
        }
      }
    },
    checkForm: function checkForm(val, text) {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.$refs[val].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            _this4.$message.closeAll();
            _this4.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
            reject(text);
          }
        });
      });
    }
  }
};
exports.default = _default2;