"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.search");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/mark/basic");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _client_bind_mac = _interopRequireDefault(require("../components/client_licensing/client_bind_mac"));
var _warrant_history = _interopRequireDefault(require("../components/client_licensing/warrant_history"));
var _warrant_code = _interopRequireDefault(require("../components/client_licensing/warrant_code"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectOptions = {
  supplierType: [{
    id: 1,
    name: '标签厂'
  }, {
    id: 2,
    name: '制盖厂'
  }
  // { id: 3, name: '膜厂' },
  // { id: 4, name: '仓储商' }
  ]
};
var _default = {
  name: 'MarkclientclientLicensingindex',
  components: {
    Pagination: _Pagination.default,
    client_bind_mac: _client_bind_mac.default,
    history: _warrant_history.default,
    warrant_code: _warrant_code.default
  },
  // labelcon
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (time == 0 || time == '' || time == null) {
        return '';
      }
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    getSupplierType: function getSupplierType(v) {
      var t = '';
      for (var i in selectOptions.supplierType) {
        if (selectOptions.supplierType[i].id == v) {
          t = selectOptions.supplierType[i].name;
          break;
        }
      }
      return t;
    },
    statueText: function statueText(status) {
      if (!status) return '';
      var statusMap = {
        0: '未通过',
        1: '通过',
        2: '待审'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      supplierType: selectOptions.supplierType,
      tabHeight: '100%',
      tableKey: 0,
      list: [],
      total: 0,
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        supplierName: '',
        supplierType: ''
      },
      listLoading: false,
      parentData: {
        supplierName: '',
        supplierType: '',
        supplierCode: '',
        btn_type: '',
        pid: ''
      },
      dialogBindMacFormVisible: false,
      dialog_title: null,
      textMap: {
        rest_mac: '重置绑定MAC地址',
        bind_mac: '绑定MAC地址',
        history_list: '历史记录',
        create_code: '生成授权码',
        del_warrant: '解除授权',
        update_warrant: '恢复授权'
      },
      btnPowers: null
    };
  },
  activated: function activated() {
    console.log(111);
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      console.log(this.listQuery, 'this.listQuery');
      (0, _client_licensing.search)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          console.log(_this.list, ' this.list');
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.$refs.searchform.resetFields();
      }
      this.listQuery.pageNo = 1;
      this.getList();
    },
    handleUpdate: function handleUpdate(row, _type) {
      this.dialog_title = this.textMap[_type];
      this.dialogBindMacFormVisible = true;
      switch (_type) {
        case 'bind_mac':
          this.authId('updatebtn', this.$route.meta.authList);
          this.parentData = {
            supplierName: row.supplierName,
            supplierType: row.supplierType,
            supplierCode: row.supplierCode,
            btn_type: '',
            pid: ''
          };
          break;
        case 'rest_mac':
          this.authId('startbtn', this.$route.meta.authList);
          this.parentData = JSON.parse(JSON.stringify(row));
          this.parentData.btn_type = _type;
          break;
        case 'history_list':
          this.authId('searchbtn', this.$route.meta.authList);
          this.dialogBindMacFormVisible = false;
          this.$refs.history_list.parentData = JSON.parse(JSON.stringify(row));
          this.$refs.history_list.dialogHistoryListVisible = true;
          break;
        case 'create_code':
          this.getAuthCode(row);
          break;
        case 'del_warrant':
          this.getremoveMac(row);
          break;
        case 'update_warrant':
          this.getreturnMac(row);
      }
    },
    getremoveMac: function getremoveMac(row) {
      // 解除授权
      this.$confirm('是否要解除授权?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // removeMac({ id: row.pid }).then((response) => {
        //   var data = response.data
        //   if (data.code != 200) {
        //     this.$message.error(data.msg)
        //     return true
        //   }
        //   this.$message.success('成功解除授权')
        // })
      }).catch(function () {});
    },
    getreturnMac: function getreturnMac(row) {
      // 恢复授权
      // returnMac({ id: row.pid }).then((response) => {
      //   var data = response.data
      //   if (data.code != 200) {
      //     this.$message.error(data.msg)
      //     return true
      //   }
      //   this.$message.success('授权已恢复')
      // })
    },
    getAuthCode: function getAuthCode(row) {
      // 创建生成授权码
      if (row.clientAuthId == null || row.clientAuthId == '') {
        this.$message.error('请先绑定MAC地址');
        return false;
      }
      this.$refs.warrant_code.parentData = row;
      this.$refs.warrant_code.dialogWarrantCodeVisible = true;
    },
    changeFlag: function changeFlag(val, row) {
      var _this2 = this;
      console.log(row, 'row');
      var supplierCode = row.supplierCode;
      if (val == 0) {
        row.firstDownFlag = 1;
      } else {
        row.firstDownFlag = 0;
      }
      this.$confirm(val == 0 ? '确认关闭' : '确认开启', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('startbtn', _this2.$route.meta.authList);
        (0, _client_licensing.modDownFlag)({
          supplierCode: supplierCode,
          firstDownFlag: val
        }).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          if (res.data.code != 200) {
            _this2.$message.error(res.data.msg);
          } else {
            _this2.$message({
              message: '操作成功',
              type: 'success'
            });
            _this2.getList();
          }
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;