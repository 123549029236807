"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _amapJsapiLoader = _interopRequireDefault(require("@amap/amap-jsapi-loader"));
var _salesArea = require("@/api/basic/salesArea");
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      maxValue: null,
      currentMap: null,
      regionList: [],
      activeList: [],
      listLoading: true,
      ruleForm: {
        regionCode: '',
        type: 1,
        configId: ''
      },
      relipoints: [],
      map: null,
      relimap: null,
      points: [],
      gridSize: 120,
      upperRightXLatitude: null,
      upperRightYLongitude: null,
      lowerLeftXLatitude: null,
      lowerLeftYLongitude: null,
      upperRightXLatitude1: null,
      upperRightYLongitude1: null,
      lowerLeftXLatitude1: null,
      lowerLeftYLongitude1: null
    };
  },
  mounted: function mounted() {
    var that = this;
    that.initMap();
    this.getregionList();
    this.getactivelist();
  },
  methods: {
    getregionList: function getregionList() {
      var that = this;
      (0, _salesArea.selectLargeRegionByType)({
        regionType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.regionList = response.data.data;
        }
      });
    },
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityManage.geActList)({
        current: 1,
        size: 100
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    search: function search() {
      var that = this;
      if (that.ruleForm.type == 1) {
        that.initMap();
      } else if (that.ruleForm.type == 2) {
        that.initReliMap();
      }
    },
    reset: function reset() {
      var that = this;
      if (that.ruleForm.type == 1) {
        that.ruleForm = {
          regionCode: '',
          configId: '',
          type: 1
        };
        that.upperRightXLatitude = null;
        that.upperRightYLongitude = null;
        that.lowerLeftXLatitude = null;
        that.lowerLeftYLongitude = null;
        that.initMap();
      } else if (that.ruleForm.type == 2) {
        that.ruleForm = {
          regionCode: '',
          configId: '',
          type: 2
        };
        that.upperRightXLatitude1 = null;
        that.upperRightYLongitude1 = null;
        that.lowerLeftXLatitude1 = null;
        that.lowerLeftYLongitude1 = null;
        that.initReliMap();
      }
    },
    changeType: function changeType(type) {
      if (type == 1) {
        this.initMap();
      } else if (type == 2) {
        this.initReliMap();
      }
    },
    initMarker: function initMarker(upperRightXLatitude, upperRightYLongitude, lowerLeftXLatitude, lowerLeftYLongitude) {
      var _this = this;
      var that = this;
      delete that.ruleForm.ruleType;
      (0, _activityManage.getshapanList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, that.ruleForm), {}, {
        upperRightXLatitude: upperRightXLatitude,
        upperRightYLongitude: upperRightYLongitude,
        lowerLeftXLatitude: lowerLeftXLatitude,
        lowerLeftYLongitude: lowerLeftYLongitude
      })).then(function (res) {
        that.listLoading = false;
        that.points = [];
        if (res.data.data) {
          var newpoints = res.data.data.map(function (el) {
            return {
              lnglat: [Number(el.longitude).toFixed(6), Number(el.latitude).toFixed(6)]
            };
          });
          that.points = JSON.parse(JSON.stringify(newpoints));
        }
        // 添加聚合组件
        _this.map.plugin(['AMap.MarkerClusterer'], function () {
          new window.AMap.MarkerClusterer(_this.map,
          // 地图实例
          that.points,
          // 海量点数据，数据中需包含经纬度信息字段 lnglat
          {
            gridSize: 120 // 设置网格像素大小
          });
        });
      });
    },
    initReliMarker: function initReliMarker(upperRightXLatitude, upperRightYLongitude, lowerLeftXLatitude, lowerLeftYLongitude) {
      var that = this;
      (0, _activityManage.getshapanList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, that.ruleForm), {}, {
        upperRightXLatitude: upperRightXLatitude,
        upperRightYLongitude: upperRightYLongitude,
        lowerLeftXLatitude: lowerLeftXLatitude,
        lowerLeftYLongitude: lowerLeftYLongitude
      })).then(function (res) {
        that.listLoading = false;
        that.relipoints = [];
        if (res.data.data) {
          var newpoints = res.data.data.map(function (el) {
            that.maxValue = null;
            var currentValue = el.con;
            if (that.maxValue === null || currentValue > that.maxValue) {
              that.maxValue = currentValue;
            }
            return {
              lng: Number(Number(el.longitude).toFixed(6)),
              lat: Number(Number(el.latitude).toFixed(6)),
              count: el.con
            };
          });
          that.relipoints = JSON.parse(JSON.stringify(newpoints));
        }
        // 添加聚合组件
        // var heatmap
        that.relimap.plugin(['AMap.HeatMap'], function () {
          // 初始化heatmap对象
          var heatmap = new window.AMap.HeatMap(that.relimap, {
            radius: 25,
            // 给定半径
            opacity: [0, 0.8]
            /*,
              gradient:{
                  0.5: 'blue',
                  0.65: 'rgb(117,211,248)',
                  0.7: 'rgb(0, 255, 0)',
                  0.9: '#ffea00',
                  1.0: 'red'
              }
               */
          });
          // 设置数据集：该数据为北京部分“公园”数据
          heatmap.setDataSet({
            data: that.relipoints,
            max: that.maxValue
          });
        });
      });
    },
    // 自定义非聚合点样式
    renderMarker: function renderMarker(context) {
      var content = '<div style="background-color: hsla(180, 100%, 50%, 0.3); height: 18px; width: 18px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 3px;"></div>';
      var offset = new this.mapModule.Pixel(-9, -9);
      context.marker.setContent(content);
      context.marker.setOffset(offset);
    },
    renderClusterMarker: function renderClusterMarker(context) {
      var that = this;
      var count = that.points.length;
      var factor = Math.pow(context.count / count, 1 / 18);
      var div = document.createElement('div');
      var Hue = 180 - factor * 180;
      var bgColor = 'hsla(' + Hue + ',100%,40%,0.7)';
      var fontColor = 'hsla(' + Hue + ',100%,90%,1)';
      var borderColor = 'hsla(' + Hue + ',100%,40%,1)';
      var shadowColor = 'hsla(' + Hue + ',100%,90%,1)';
      div.style.backgroundColor = bgColor;
      var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
      div.style.width = div.style.height = size + 'px';
      div.style.border = 'solid 1px ' + borderColor;
      div.style.borderRadius = size / 2 + 'px';
      div.style.boxShadow = '0 0 5px ' + shadowColor;
      div.innerHTML = context.count;
      div.style.lineHeight = size + 'px';
      div.style.color = fontColor;
      div.style.fontSize = '14px';
      div.style.textAlign = 'center';
      context.marker.setOffset(new that.mapModule.Pixel(-size / 2, -size / 2));
      context.marker.setContent(div);

      // 自定义点击事件，这边做的是点击聚合点，以聚合点中心，放大一级
      // context.marker.on('click', function(e) {
      //   console.log(e)
      //   let curZoom = that.map.getZoom()
      //   if (curZoom < 20) {
      //     curZoom += 1
      //   }
      //   that.map.setZoomAndCenter(curZoom, e.lnglat)
      // })
    },
    initMap: function initMap() {
      var _this2 = this;
      _amapJsapiLoader.default.load({
        key: '4f6d7befbf6546770efd98f00879e8c7',
        // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0',
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Driving', 'AMap.MarkerClusterer']
        // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(function (AMap) {
        _this2.map = new AMap.Map('map', {
          // 设置地图容器id
          zoom: 6,
          // 初始化地图级别
          center: [114.304569, 30.598658] // 初始化地图中心点位置
        });

        var southwest = _this2.map.getBounds().getSouthWest();
        var eastnorth = _this2.map.getBounds().getNorthEast();
        _this2.upperRightXLatitude = eastnorth.lat;
        _this2.upperRightYLongitude = eastnorth.lng;
        _this2.lowerLeftXLatitude = southwest.lat;
        _this2.lowerLeftYLongitude = southwest.lng;
        _this2.initMarker(_this2.upperRightXLatitude, _this2.upperRightYLongitude, _this2.lowerLeftXLatitude, _this2.lowerLeftYLongitude);
        _this2.map.on('zoomend', function () {
          var southwest = _this2.map.getBounds().getSouthWest();
          var eastnorth = _this2.map.getBounds().getNorthEast();
          _this2.upperRightXLatitude = eastnorth.lat;
          _this2.upperRightYLongitude = eastnorth.lng;
          _this2.lowerLeftXLatitude = southwest.lat;
          _this2.lowerLeftYLongitude = southwest.lng;
          _this2.initMarker(_this2.upperRightXLatitude, _this2.upperRightYLongitude, _this2.lowerLeftXLatitude, _this2.lowerLeftYLongitude);
        });
        _this2.map.on('dragend', function () {
          var southwest = _this2.map.getBounds().getSouthWest();
          var eastnorth = _this2.map.getBounds().getNorthEast();
          _this2.upperRightXLatitude = eastnorth.lat;
          _this2.upperRightYLongitude = eastnorth.lng;
          _this2.lowerLeftXLatitude = southwest.lat;
          _this2.lowerLeftYLongitude = southwest.lng;
          _this2.initMarker(_this2.upperRightXLatitude, _this2.upperRightYLongitude, _this2.lowerLeftXLatitude, _this2.lowerLeftYLongitude);
        });
      }).catch(function (e) {
        console.log(e);
      });
    },
    initReliMap: function initReliMap() {
      var _this3 = this;
      _amapJsapiLoader.default.load({
        key: '4f6d7befbf6546770efd98f00879e8c7',
        // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0',
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.HeatMap']
        // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(function (AMap) {
        _this3.relimap = new AMap.Map('map1', {
          // 设置地图容器id
          zoom: 6,
          // 初始化地图级别
          center: [114.304569, 30.598658] // 初始化地图中心点位置
        });

        var southwest = _this3.relimap.getBounds().getSouthWest();
        var northeast = _this3.relimap.getBounds().getNorthEast();
        _this3.upperRightXLatitude1 = northeast.lat;
        _this3.upperRightYLongitude1 = northeast.lng;
        _this3.lowerLeftXLatitude1 = southwest.lat;
        _this3.lowerLeftYLongitude1 = southwest.lng;
        _this3.initReliMarker(_this3.upperRightXLatitude, _this3.upperRightYLongitude, _this3.lowerLeftXLatitude, _this3.lowerLeftYLongitude);
        _this3.relimap.on('zoomend', function () {
          var southwest = _this3.relimap.getBounds().getSouthWest();
          var northeast = _this3.relimap.getBounds().getNorthEast();
          _this3.upperRightXLatitude1 = northeast.lat;
          _this3.upperRightYLongitude1 = northeast.lng;
          _this3.lowerLeftXLatitude1 = southwest.lat;
          _this3.lowerLeftYLongitude1 = southwest.lng;
          _this3.initReliMarker(_this3.upperRightXLatitude1, _this3.upperRightYLongitude1, _this3.lowerLeftXLatitude1, _this3.lowerLeftYLongitude1);
        });
        _this3.relimap.on('dragend', function () {
          var southwest = _this3.relimap.getBounds().getSouthWest();
          var northeast = _this3.relimap.getBounds().getNorthEast();
          _this3.upperRightXLatitude1 = northeast.lat;
          _this3.upperRightYLongitude1 = northeast.lng;
          _this3.lowerLeftXLatitude1 = southwest.lat;
          _this3.lowerLeftYLongitude1 = southwest.lng;
          _this3.initReliMarker(_this3.upperRightXLatitude1, _this3.upperRightYLongitude1, _this3.lowerLeftXLatitude1, _this3.lowerLeftYLongitude1);
        });
      }).catch(function (e) {
        console.log(e);
      });
    }
  }
};
exports.default = _default;