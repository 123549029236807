"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audit = audit;
exports.generateOrderSequence = generateOrderSequence;
exports.getAuditLog = getAuditLog;
exports.insertOrder = insertOrder;
exports.inventoryDelete = inventoryDelete;
exports.queryById = queryById;
exports.queryForPage = queryForPage;
exports.queryProductDetail = queryProductDetail;
exports.revocation = revocation;
exports.updateOrder = updateOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 盘点分页查询
 * @export
 * @param {*} params
 * @returns
 */
function queryForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/queryForPage',
    method: 'get',
    params: params
  });
}

/**
 * 生成盘点单号
 * @export
 * @param {*} params
 * @returns
 */
function generateOrderSequence(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/generateOrderSequence',
    method: 'get',
    params: params
  });
}

/**
 *新增订单
 *
 * @export
 * @param {*} data
 * @returns
 */
function insertOrder(data) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/insertOrder',
    method: 'post',
    data: data
  });
}

/**
 * 修改订单
 *
 * @export
 * @param {*} data
 * @returns
 */
function updateOrder(data) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/updateOrder',
    method: 'post',
    data: data
  });
}

/**
 *获取详情
 * @export
 * @param {*} params
 * @returns
 */
function queryById(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/queryById',
    method: 'get',
    params: params
  });
}

/**
 *盘点订单删除
 * @export
 * @param {*} params
 * @returns
 */
function inventoryDelete(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/delete',
    method: 'delete',
    params: params
  });
}

/**
 *撤销下载
 * @export
 * @param {*} params
 * @returns
 */
function revocation(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/revocation',
    method: 'put',
    params: params
  });
}

/**
 *获取审核日志
 * @export
 * @param {*} params
 * @returns
 */
function getAuditLog(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/getAuditLog',
    method: 'get',
    params: params
  });
}

/**
 *审核
 * @export
 * @param {*} params
 * @returns
 */
function audit(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/audit',
    method: 'put',
    params: params
  });
}

/**
 * 产品扫码数量分页查询
 * @export
 * @param {*} params
 * @returns
 */
function queryProductDetail(params) {
  return (0, _request.default)({
    url: '/storage-center/inventoryOrder/queryProductDetail',
    method: 'get',
    params: params
  });
}