"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _integralMall = require("@/api/integral/integralMall");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'integralMallintegralFlowindex';
var _default = {
  name: 'IntegralMallintegralFlowindex',
  filters: {
    typeFilter: function typeFilter(status) {
      if (!status) return '';
      var statusMap = {
        1: '扫码',
        2: '商品兑换',
        3: '活动获得',
        4: '企业操作',
        5: '完善个人信息',
        10: '积分过期 '
      };
      return statusMap[status];
    },
    stateFilter: function stateFilter(status) {
      var statusMap = {
        100: '未开始',
        200: '进行中',
        300: '已结束',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tabHeight: '100%',
      timer: null,
      list: null,
      listLoading: false,
      total: 0,
      typeName: '收入/支出',
      hdType: 1,
      formInline: {
        dataTimeRange: '',
        integralDesc: null,
        integralType: null,
        orgId: '',
        pageNo: 1,
        pageSize: 20
      },
      currentRow: '',
      radio: '',
      dialogVisible: false,
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.fetchData();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    filterChange: function filterChange(filters) {
      if (!filters.type || filters.type.length !== 1) {
        this.formInline.integralDesc = null;
        this.typeName = '收入/支出';
      } else {
        this.formInline.integralDesc = filters.type[0];
        this.typeName = filters.type[0] === 1 ? '收入' : '支出';
      }
      this.fetchData();
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.formInline.dateEnd = '';
      this.formInline.dateStart = '';
      this.fetchData();
    },
    searchFun: function searchFun() {
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _integralMall.getByOrgId)(this.formInline).then(function (res) {
        console.log(res);
        if (res.status * 1 === 200) {
          if (res.data.code === 0) {
            _this.total = res.data.data.total;
            _this.list = res.data.data.list;
          }
        }
        _this.listLoading = false;
      });
    },
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.dateEnd = val[1];
        this.formInline.dateStart = val[0];
      } else {
        this.formInline.dateEnd = '';
        this.formInline.dateStart = '';
      }
    }
  }
};
exports.default = _default;