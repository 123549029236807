"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
var _labelGroupManage = require("@/api/mark/configure/labelGroupManage");
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var supplierType = [{
  id: 1,
  name: '标签厂'
}, {
  id: 2,
  name: '制盖厂'
}
// { id: 3, name: '膜厂' },
// { id: 4, name: '仓储商' }
];

var codeTypeList = [];
var _default = {
  name: 'MarkconfiguresupplierIdentificationindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    supplierTypeFilter: function supplierTypeFilter(v) {
      var t = '';
      for (var i in supplierType) {
        if (supplierType[i].id == v) {
          t = supplierType[i].name;
          break;
        }
      }
      return t;
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '瓶',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内层级',
        2: '层级',
        3: '外层级'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      return statusPack[packLevel] + statusMark[markType] + '-' + item[0].markLen + '位';
    }
  },
  data: function data() {
    var _this = this;
    var validate = function validate(rule, value, callback) {
      var arr = _this.$refs.tree.getCheckedKeys();
      if (arr.length > 0) {
        callback();
      } else {
        callback(new Error('请选择标识组'));
      }
    };
    return {
      imgUrl: require("../../../../images/origin.png"),
      defaultProps: {
        children: 'children',
        label: 'flagName',
        disabled: this.disabledFn
      },
      tabHeight: '100%',
      chosedate: [],
      tableKey: 0,
      tableLabelKey: 0,
      list: [],
      labelList: [],
      modelList: [],
      supplierType: supplierType,
      total: 0,
      modelTotal: 0,
      // codelistLoading: false,
      listLoading: false,
      modeLoading: false,
      btn_loading: false,
      loaidng: false,
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        supplierName: '',
        supplierType: '',
        startTime: '',
        endTime: ''
      },
      labelQuery: {
        current: 1,
        size: 20,
        flagId: '',
        supplierType: ''
      },
      temp: {
        supplierCode: '',
        supplierName: '',
        supplierType: ''
      },
      dialogLabelVisible: false,
      dialogStatus: '',
      textMap: {
        update: '配置标识组',
        detail: '详情'
      },
      labelRules: {
        flagCodes: [{
          required: true,
          validator: validate,
          trigger: 'change'
        }]
      },
      btnPowers: null
    };
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.btnPowers = {};
    this.getLabelList();
    this.getList();
    this.getCodeType();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.flagType == value;
    },
    disabledFn: function disabledFn() {
      return this.dialogStatus == 'detail';
    },
    getLabelList: function getLabelList() {
      var _this2 = this;
      // 获取所有标签列表
      // if (this.btnPowers.BSZLBCX.view == false) {
      //   this.$message.error('权限不足')
      //   return false
      // }
      (0, _labelGroupManage.labelList)().then(function (res) {
        var data = res.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
        }
        _this2.labelList = data.data;
      });
    },
    getModelList: function getModelList() {
      var that = this;
      that.modeLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.labelQuery);
      (0, _supplierRFlag.flagTemplatePage)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.modelTotal = parseInt(response.data.data.total);
            that.modelList = response.data.data.records;
          } else {
            that.modelTotal = 0;
            that.modelList = [];
          }
          that.modeLoading = false;
        }
      }).catch(function (res) {
        that.modeLoading = false;
      });
    },
    getCodeType: function getCodeType() {
      var _this3 = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this4 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _supplierRFlag.supplierRFlagList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this4.list = null;
        _this4.total = 0;
        _this4.listLoading = false;
        if (data.code != 200) {
          _this4.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this4.list = data.data.records;
          _this4.total = Number(data.data.total);
        } else {
          _this4.list = null;
          _this4.total = 0;
        }
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    handleNodeClick: function handleNodeClick(data, node) {
      this.labelQuery.flagId = data.pid;
      this.labelQuery.supplierType = this.temp.supplierType;
      this.getModelList();
    },
    handleCheckChange: function handleCheckChange(data, checked, indeterminate) {},
    settingLabel: function settingLabel(row, state) {
      var _this5 = this;
      this.dialogStatus = state;
      this.loaidng = true;
      this.temp.supplierCode = row.supplierCode;
      this.temp.supplierName = row.supplierName;
      this.temp.supplierType = row.supplierType;
      this.authId('detailbtn', this.$route.meta.authList);
      if (state == 'update') {
        this.authId('updatebtn', this.$route.meta.authList);
      }
      this.dialogLabelVisible = true;
      this.$nextTick(function () {
        _this5.$refs.tree.filter(row.supplierType);
        _this5.$refs.tree.setCheckedNodes(row.flagCode);
        _this5.loaidng = false;
        _this5.$refs['labelDataForm'].clearValidate();
      });
      // })
    },
    saveLabelCode: function saveLabelCode() {
      var _this6 = this;
      // 保存标识组配置
      this.$refs['labelDataForm'].validate(function (valid) {
        if (valid) {
          _this6.temp.flagPidList = _this6.$refs.tree.getCheckedKeys();
          // this.temp.flagCode = this.$refs.tree.getCheckedNodes()
          var data = {
            flagPidList: _this6.$refs.tree.getCheckedKeys(),
            supplierCode: _this6.temp.supplierCode
          };
          _this6.btn_loading = true;
          (0, _supplierRFlag.supplierRFlagSave)(data).then(function (res) {
            _this6.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this6.$message.error(data.msg);
              return false;
            }
            _this6.$message({
              message: '保存成功',
              type: 'success'
            });
            _this6.getList();
            _this6.$refs.labelDataForm.resetFields();
            _this6.dialogLabelVisible = false;
          }).catch(function () {
            _this6.btn_loading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.modelList = [];
      this.$refs.tree.setCheckedKeys([]);
      this.$refs.labelDataForm.resetFields();
      this.dialogLabelVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    reset: function reset() {
      this.listQuery = {
        pageNo: 1,
        pageSize: 20,
        supplierName: '',
        supplierType: '',
        startTime: '',
        endTime: ''
      };
      this.chosedate = [];
      this.getList();
    }
  }
};
exports.default = _default;