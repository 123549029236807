var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "公告详情",
        visible: _vm.visible,
        "before-close": _vm.close,
        width: "714px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mLoading,
              expression: "mLoading",
            },
          ],
          staticClass: "detailWrap",
        },
        [
          _c(
            "el-row",
            { staticClass: "detailRow", attrs: { gutter: 46 } },
            [
              _c("el-col", { attrs: { md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("公告类型:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(_vm._f("noticeType")(_vm.scanForm.noticeType))
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("公告标题:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanForm.noticeTitle
                          ? _vm.scanForm.noticeTitle
                          : " "
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "detailRow", attrs: { gutter: 46 } },
            [
              _c("el-col", { attrs: { md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("发布时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanForm.startTime ? _vm.scanForm.startTime : " "
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "detailRow", attrs: { gutter: 46 } },
            [
              _c("el-col", { attrs: { md: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("公告内容:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanForm.noticeContent
                          ? _vm.scanForm.noticeContent
                          : " "
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "detailRow", attrs: { gutter: 46 } },
            [
              _c("el-col", { attrs: { md: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("公告范围:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(_vm.scanForm.orgName ? _vm.scanForm.orgName : " ")
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }