"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actAddReserve = actAddReserve;
exports.actDisable = actDisable;
exports.actEnable = actEnable;
exports.actconfigAdd = actconfigAdd;
exports.addWechatActConfig = addWechatActConfig;
exports.del = del;
exports.detailpage = detailpage;
exports.endAct = endAct;
exports.getChosenProduct = getChosenProduct;
exports.getList = getList;
exports.getProduct = getProduct;
exports.getProductBatch = getProductBatch;
exports.isExistConsumeWechat = isExistConsumeWechat;
exports.prizeManage = prizeManage;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
// var baseUrl = '/user'
var baseUrl = '';

//  获取活动列表
function getList(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/actlist',
    method: 'POST',
    data: data
  });
}
//  停用活动
function actDisable(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/disable',
    method: 'PUT',
    params: params
  });
}
// 活动删除
function del(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/del',
    method: 'delete',
    params: params
  });
}
//  启用活动
function actEnable(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/enable',
    method: 'PUT',
    params: params
  });
}
//  结束活动
function endAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/endAct',
    method: 'PUT',
    params: params
  });
}
//  获取产品列表
function getProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/getProduct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
// 获取活动已选择产品
function getChosenProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actactivityRange/getChosenProduct',
    method: 'get',
    params: params
  });
}
// 获活动产品批次
function getProductBatch(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actactivityRange/getProductBatch',
    method: 'get',
    params: params
  });
}
// /actconfig/prizeManage 奖品管理
function prizeManage(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/prizeManage?actNo=' + data,
    method: 'POST',
    data: data
  });
}
// 增加库存
function actAddReserve(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/actAddReserve?actConfigItemId=' + data.actConfigItemId + '&num=' + data.num,
    method: 'POST',
    data: data
  });
}
// 添加活动
function actconfigAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/add',
    method: 'POST',
    data: data
  });
}
// 活动详情
function detailpage(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/detailpage',
    method: 'get',
    params: params
  });
}

// 是否存在消费者公众号
function isExistConsumeWechat(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/isExistConsumeWechat',
    method: 'get',
    params: params
  });
}
// 修改活动配置
function update(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/actconfig/update',
    method: 'POST',
    data: data
  });
}
// 创建一物一码活动
function addWechatActConfig(data) {
  return (0, _request.default)({
    // url: baseUrl + '/uspyx/apply/list',
    url: baseUrl + '/WechatActConfig/addConfig',
    method: 'POST',
    data: data
  });
}