"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPoster = createPoster;
exports.getChangeStatus = getChangeStatus;
exports.getPosterExcel = getPosterExcel;
exports.postQueryPosterList = postQueryPosterList;
exports.updatePoster = updatePoster;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = process.env.VUE_APP_VERSION2;
// 终端海报列表
function postQueryPosterList(data) {
  return (0, _request.default)({
    url: baseUrl + '/zdConfig/terminalManger/queryPosterList',
    method: 'post',
    data: data
  });
}
// 更改状态
function getChangeStatus(params) {
  return (0, _request.default)({
    url: baseUrl + '/zdConfig/terminalManger/changeStatus',
    method: 'get',
    params: params
  });
}
// 创建终端海报
function createPoster(data) {
  return (0, _request.default)({
    url: baseUrl + '/zdConfig/terminalManger/createPoster',
    method: 'post',
    data: data
  });
}
function updatePoster(data) {
  return (0, _request.default)({
    url: baseUrl + '/zdConfig/terminalManger/update',
    method: 'post',
    data: data
  });
}
// 导出终端海报
function getPosterExcel(data) {
  return (0, _request.default)({
    url: baseUrl + '/zdConfig/terminalManger/getPosterExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}