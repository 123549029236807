var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-dialog",
          {
            staticClass: "dialog",
            attrs: {
              title: _vm.title,
              visible: _vm.dialogVisible,
              "close-on-click-modal": false,
              "show-close": false,
              width: "700px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "120px",
                  model: _vm.form,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "规则名称:", prop: "prizeName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入规则名称" },
                      model: {
                        value: _vm.form.prizeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "prizeName", $$v)
                        },
                        expression: "form.prizeName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "展示名称:", prop: "prizeAlias" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入展示名称" },
                      model: {
                        value: _vm.form.prizeAlias,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "prizeAlias", $$v)
                        },
                        expression: "form.prizeAlias",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "奖品说明:", prop: "remark" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入奖品说明" },
                      model: {
                        value: _vm.form.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "remark", $$v)
                        },
                        expression: "form.remark",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "收集方式:", prop: "coll" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.handleValueFont },
                        model: {
                          value: _vm.form.coll,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "coll", $$v)
                          },
                          expression: "form.coll",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("单活动集字"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("跨活动集字"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.modeFont
                  ? _c(
                      "div",
                      _vm._l(_vm.form.domains, function (domain, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            staticClass: "concent",
                            attrs: {
                              label: "第" + _vm.arr[index] + "个字",
                              prop: "domains." + index + ".value",
                              rules: {
                                required: true,
                                message: "字符不能为空",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "455px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                placeholder: "请输入字符，最多支持6个字符",
                              },
                              model: {
                                value: domain.value,
                                callback: function ($$v) {
                                  _vm.$set(domain, "value", $$v)
                                },
                                expression: "domain.value",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-button", {
                              attrs: { icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDomain(domain)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.modeFont
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isDisabled,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: { click: _vm.addDomain },
                          },
                          [_vm._v("添加字符")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.spanFont
                  ? _c(
                      "div",
                      _vm._l(_vm.form.domains, function (domain, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            staticClass: "concent",
                            attrs: {
                              label: "第" + _vm.arr[index] + "个字",
                              prop: "domains." + index + ".value",
                              rules: {
                                required: true,
                                message: "字符不能为空",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "455px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                placeholder: "请输入字符，最多支持6个字符",
                              },
                              model: {
                                value: domain.value,
                                callback: function ($$v) {
                                  _vm.$set(domain, "value", $$v)
                                },
                                expression: "domain.value",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入数量" },
                                model: {
                                  value: _vm.form.num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "num", $$v)
                                  },
                                  expression: "form.num",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("数量"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-button", {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDomain(domain)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.spanFont
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isDisabled,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: { click: _vm.addDomain },
                          },
                          [_vm._v("添加字符")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "集字规则:", prop: "ruleFont" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.handleValueChange },
                        model: {
                          value: _vm.form.ruleFont,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleFont", $$v)
                          },
                          expression: "form.ruleFont",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("集全字兑奖"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("任意字兑奖"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isFontAll
                  ? _c(
                      "el-form-item",
                      _vm._l(_vm.form.domains, function (item) {
                        return _c(
                          "el-checkbox-group",
                          { key: item.index },
                          [
                            _c("el-checkbox", {
                              attrs: { label: item.value, disabled: "" },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFont
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.form.checkList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "checkList", $$v)
                              },
                              expression: "form.checkList",
                            },
                          },
                          _vm._l(_vm.form.domains, function (item) {
                            return _c("el-checkbox", {
                              key: item.index,
                              attrs: { label: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "concent",
                    attrs: { label: "奖品:", prop: "ruleFont" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "region", $$v)
                          },
                          expression: "form.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "红包奖", value: "1" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "实物奖", value: "2" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "积分奖", value: "3" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "优惠卷", value: "4" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "region", $$v)
                          },
                          expression: "form.region",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "关联奖品", value: "1" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "58元红包奖", value: "2" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "50积分", value: "3" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "信息收集:", prop: "openDataCollection" } },
                  [
                    _c("el-switch", {
                      attrs: { "active-text": "开启" },
                      model: {
                        value: _vm.form.openDataCollection,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "openDataCollection", $$v)
                        },
                        expression: "form.openDataCollection",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.openDataCollection
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "relevanceDataId" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.handleCheckboxChange },
                            model: {
                              value: _vm.dateCollectionAll,
                              callback: function ($$v) {
                                _vm.dateCollectionAll = $$v
                              },
                              expression: "dateCollectionAll",
                            },
                          },
                          _vm._l(_vm.dateCollectionList, function (item) {
                            return _c(
                              "el-checkbox",
                              {
                                key: item.id,
                                attrs: { value: item.id, label: item.id },
                              },
                              [_vm._v(_vm._s(item.fieldName))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: _vm.disabled,
                      loading: _vm.btnLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSave("form")
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleCancel("form")
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }