"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _replacementrules = require("@/api/generalSettings2/replacementrules");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarketingManagegeneralSettingsreplacementrulesindex',
  data: function data() {
    return {
      openFirstReissue: false,
      openSecondReissue: false,
      reissueType: {
        openFirstReissue: false,
        firstReissueType: '',
        firstReissueContent: null,
        secondReissueType: '',
        id: '',
        secondReissueContent: null,
        openSecondReissue: false
      },
      rulesReissueType: {
        firstReissueType: [{
          required: true,
          message: '请选择方式',
          trigger: 'blur'
        }],
        firstReissueContent: [{
          required: true,
          message: '请输入补发时间',
          trigger: 'blur'
        }],
        secondReissueType: [{
          required: true,
          message: '请选择方式',
          trigger: 'blur'
        }],
        secondReissueContent: [{
          required: true,
          message: '请输入补发时间',
          trigger: 'blur'
        }, {
          required: true,
          message: '输入格式不正确',
          trigger: 'blur',
          pattern: /^([0-2][0-9]):([0-5][0-9]):([0-5][0-9])$/
        }]
      },
      form: {
        reissueType: '1',
        reissueFrom: '',
        reissueContent: '',
        id: ''
      }
    };
  },
  activated: function activated() {
    this.queryRedEnvelope();
  },
  methods: {
    // 红包补发信息查询
    queryRedEnvelope: function queryRedEnvelope() {
      var _this = this;
      (0, _replacementrules.queryRedEnvelope)().then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data !== null) {
            console.log(res.data.data);
            _this.form.reissueType = res.data.data.reissueType;
            _this.form.reissueContent = res.data.data.reissueContent;
            _this.form.reissueFrom = res.data.data.reissueFrom;
            _this.form.id = res.data.data.id;
          }
        }
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;
      // this.$refs[ruleFormReissueType].validate((valid) => {
      //   if (valid) {
      //     this.reissueType.openFirstReissue = this.reissueType.openFirstReissue ? '1' : '2'
      //     this.reissueType.openSecondReissue = this.reissueType.openSecondReissue ? '1' : '2'
      //     addRedEnvelope(this.reissueType).then(res => {
      //       if (res.data.data.errCode) {
      //         this.$message.error(res.data.data.errMsg)
      //         return
      //       } else {
      //         this.$message.success('修改成功')
      //         this.queryRedEnvelope()
      //       }
      //     })
      //   } else {
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
      this.$refs['form'].validate(function (valid) {
        console.log(_this2.form);
        if (valid) {
          console.log(_this2.form);
          (0, _replacementrules.addRedEnvelope)(_this2.form).then(function (res) {
            if (res.data.data.errCode) {
              _this2.$message.error(res.data.data.errMsg);
              return;
            } else {
              _this2.$message.success('修改成功');
              _this2.queryRedEnvelope();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      // this.$refs[ruleFormReissueType].resetFields()
      this.$refs['form'].resetFields();
    }
  }
};
exports.default = _default;