var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "企业用户-添加",
        model: _vm.add,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "addForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.addData,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "loginAccount" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.addData.loginAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "loginAccount", $$v)
                  },
                  expression: "addData.loginAccount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名", prop: "userName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.addData.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "userName", $$v)
                  },
                  expression: "addData.userName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "is-required",
              attrs: { label: "所属组织", prop: "orgId" },
            },
            [
              _c("treeselect", {
                attrs: {
                  multiple: false,
                  placeholder: "请选择组织...",
                  options: _vm.orgs,
                },
                model: {
                  value: _vm.addData.orgId,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "orgId", $$v)
                  },
                  expression: "addData.orgId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "销售组织", prop: "regionId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addData.regionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.addData, "regionId", $$v)
                    },
                    expression: "addData.regionId",
                  },
                },
                _vm._l(_vm.regionList, function (item) {
                  return _c("el-option", {
                    key: item.pId,
                    attrs: { label: item.regionName, value: item.pId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "loginPwd" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容", maxlength: "16" },
                model: {
                  value: _vm.addData.loginPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "loginPwd", $$v)
                  },
                  expression: "addData.loginPwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "reloginPwd" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容", maxlength: "16" },
                model: {
                  value: _vm.addData.reloginPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "reloginPwd", $$v)
                  },
                  expression: "addData.reloginPwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "phoneNum" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容", maxlength: "11" },
                model: {
                  value: _vm.addData.phoneNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "phoneNum", $$v)
                  },
                  expression: "addData.phoneNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容", maxlength: "40" },
                model: {
                  value: _vm.addData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "email", $$v)
                  },
                  expression: "addData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "岗位", prop: "postCode" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addData.postCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.addData, "postCode", $$v)
                    },
                    expression: "addData.postCode",
                  },
                },
                _vm._l(_vm.postList, function (item) {
                  return _c("el-option", {
                    key: item.figure,
                    attrs: { label: item.name, value: item.figure },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分组", prop: "groupCode" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addData.groupCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.addData, "groupCode", $$v)
                    },
                    expression: "addData.groupCode",
                  },
                },
                _vm._l(_vm.groupList, function (item) {
                  return _c("el-option", {
                    key: item.figure,
                    attrs: { label: item.name, value: item.figure },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "is-required",
              attrs: { label: "状态", prop: "isEnable" },
            },
            [
              _c("el-switch", {
                attrs: { "active-text": "启用", "inactive-text": "禁用" },
                model: {
                  value: _vm.addData.isEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "isEnable", $$v)
                  },
                  expression: "addData.isEnable",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.addData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "remark", $$v)
                  },
                  expression: "addData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "align-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.dstate },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("addForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.resetForm("addForm")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }