"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _unlockPrizeRule = require("@/api/market/unlockPrizeRule");
var _activityManage = require("@/api/market/activityManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _creatUnlockPrize = _interopRequireDefault(require("./creatUnlockPrize"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    creatUnlockPrize: _creatUnlockPrize.default
  },
  filters: {
    filterStatus: function filterStatus(status) {
      return !status || status == '0' ? '关联' : '取消关联';
    },
    filterUnlockType: function filterUnlockType(status) {
      return status === '1' ? '按次布奖' : '倍数布奖';
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    actId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    selectItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dataActivityStatus: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: false,
      total: 0,
      editType: 'add',
      creatVisible: false,
      activityisRelation: false,
      currentRelationCode: '',
      searchForm: {
        status: 1,
        pageNo: 1,
        pageSize: 20,
        activityId: ''
      }
    };
  },
  computed: {
    isActivityOverdue: function isActivityOverdue() {
      return this.dataActivityStatus == '3';
    },
    isBatch: function isBatch() {
      return !this.actId;
    }
  },
  mounted: function mounted() {
    this.getDataList();
    this.getActivityisRelation();
    this.getPrizeLists(100);
    this.getPrizeLists(200);
  },
  methods: {
    getPrizeLists: function getPrizeLists(prizeType) {
      var _this = this;
      var data = {
        status: 1,
        prizeType: prizeType,
        pageNo: 0,
        pageSize: 0
      };
      (0, _activityManage.getPrizeLists)(data).then(function (res) {
        if (res.data.code == 200) {
          var keyValue = prizeType == 100 ? 'hongbaoOption' : 'shiwuOption';
          sessionStorage.setItem(keyValue, JSON.stringify(res.data.data));
        } else {
          _this.$message.error(res.data.msg);
        }
      });
    },
    // 获取解锁奖品规则列表
    getDataList: function getDataList() {
      var _this2 = this;
      this.listLoading = true;
      this.searchForm.activityId = this.actId;
      (0, _unlockPrizeRule.getUnlockPrzeRuleList)(this.searchForm).then(function (res) {
        if (res.data.code == 200) {
          _this2.listLoading = false;
          _this2.list = res.data.data.records;
          _this2.total = res.data.data.total;
        } else {
          _this2.listLoading = false;
          _this2.$message.error(res.data.msg);
          return;
        }
      });
    },
    getBatchOperationState: function getBatchOperationState(unlockRuleCode) {
      return unlockRuleCode === this.currentRelationCode ? '已关联' : '关联';
    },
    // 新增解锁奖品规则
    creatUnlockPrize: function creatUnlockPrize() {
      this.editType = 'add';
      this.creatVisible = true;
    },
    cleanUnlockPrize: function cleanUnlockPrize() {
      var _this3 = this;
      this.$confirm('继续操作将清空全部解锁奖品关联规则，是否继续？', '清空已关联规则', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _unlockPrizeRule.batchUnRelationActivity)({
          activityIds: _this3.selectItem.join()
        }).then(function (res) {
          if (res.data.code == 200) {
            _this3.$message.success('已清空解锁奖品关联规则');
            _this3.currentRelationCode = '';
            _this3.activityisRelation = 1;
          } else {
            _this3.$message.error(res.data.msg);
            return;
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    getActivityisRelation: function getActivityisRelation() {
      var _this4 = this;
      if (this.isBatch) {
        (0, _unlockPrizeRule.ruleRelation)({
          activityIds: this.selectItem.join()
        }).then(function (res) {
          if (res.data.code == 200) {
            _this4.activityisRelation = Number(res.data.data);
          } else {
            _this4.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
      (0, _unlockPrizeRule.isRelation)({
        activityId: this.actId
      }).then(function (res) {
        if (res.data.code == 200) {
          _this4.activityisRelation = res.data.data;
        } else {
          _this4.$message.error(res.data.msg);
          return;
        }
      });
    },
    batchRelationOperation: function batchRelationOperation(newRuleCode) {
      var _this5 = this;
      (0, _unlockPrizeRule.batchUpdateRuleCode)({
        activityIds: this.selectItem.join(),
        newRuleCode: newRuleCode
      }).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          _this5.$message({
            message: '批量关联成功',
            type: 'success'
          });
          _this5.activityisRelation = 4;
          _this5.currentRelationCode = newRuleCode;
          _this5.getDataList();
        }
      });
    },
    confirmTip: function confirmTip(title, content, callback) {
      var showCancelButton = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      this.$confirm(content, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: showCancelButton
      }).then(function () {
        callback();
      }).catch(function (err) {
        console.log(err);
      });
    },
    relationOperation: function relationOperation(data) {
      var _this6 = this;
      if (this.isBatch) {
        if (data.unlockRuleCode === this.currentRelationCode) return;
        // 批量
        if (this.activityisRelation === 1) {
          this.confirmTip('关联解锁奖品规则', '在活动中启用所选解锁规则奖品?', function () {
            _this6.batchRelationOperation(data.unlockRuleCode);
          });
        } else if (this.activityisRelation === 2 || this.activityisRelation === 3) {
          this.confirmTip('关联解锁奖品规则', '继续操作会覆盖之前关联的解锁奖品,是否继续?', function () {
            _this6.confirmTip('关联解锁奖品规则', '所选活动将全部替换为当前"解锁奖品规则",是否继续?', function () {
              _this6.batchRelationOperation(data.unlockRuleCode);
            });
          });
        } else {
          this.confirmTip('关联解锁奖品规则', '所选活动将全部替换为当前"解锁奖品规则",是否继续?', function () {
            _this6.batchRelationOperation(data.unlockRuleCode);
          });
        }
        return;
      }
      var queryData = {
        activityId: this.actId,
        code: data.unlockRuleCode
      };
      // 关联
      if (!data.isRelation || data.isRelation === '0') {
        // 已经关联
        if (this.activityisRelation) {
          var htmlstr = "\n            <div style=\"text-align: center;\">\n              <div style=\"font-size: 18px;color: red;\">\u5173\u8054\u5931\u8D25</div>\n              <p>\u6700\u591A\u53EA\u80FD\u5173\u8054\u4E00\u4E2A\u89C4\u5219</p>\n              <p>\u5982\u9700\u66F4\u6539\u8BF7\u5148\u53D6\u6D88\u539F\u6709\u89E3\u9501\u89C4\u5219</p>\n            </div>\n          ";
          this.$alert(htmlstr, '操作提示', {
            dangerouslyUseHTMLString: true
          });
          return;
        }
        this.confirmTip('关联解锁奖品规则', '在活动中启用所选解锁奖品规则?', function () {
          _this6.relationActivity(queryData);
        });
      } else {
        // 取消关联
        this.confirmTip('取消关联', '取消关联规则会在活动中失效,是否继续?', function () {
          _this6.unRelationActivity(queryData);
        });
      }
    },
    relationActivity: function relationActivity(queryData) {
      var _this7 = this;
      (0, _unlockPrizeRule.relationActivity)(queryData).then(function (res) {
        if (res.data.data.code == 0 || res.data.data.code == 500) {
          _this7.$message.error(res.data.data.msg);
        } else {
          _this7.$message({
            message: '关联成功',
            type: 'success'
          });
          _this7.activityisRelation = true;
          _this7.getDataList();
        }
      });
    },
    unRelationActivity: function unRelationActivity(queryData) {
      var _this8 = this;
      (0, _unlockPrizeRule.unRelationActivity)(queryData).then(function (res) {
        if (res.data.data.code == 500 || res.data.data.code == 0) {
          _this8.$message.error(res.data.data.msg);
        } else {
          _this8.$message({
            message: '取消关联成功',
            type: 'success'
          });
          _this8.activityisRelation = false;
          _this8.getDataList();
        }
      });
    },
    // 关闭解锁奖品规则
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.getDataList();
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      this.actId = '';
      this.type = 'add';
    },
    goBack: function goBack() {
      this.$emit('close');
    }
  }
};
exports.default = _default2;