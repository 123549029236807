"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryBrand = queryBrand;
exports.queryMaterial = queryMaterial;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 品牌物料查询 查询所有产品
 * @param {*} query
 */
function queryBrand(query) {
  return (0, _request.default)({
    url: '/api-prevent/brandMaterlal/queryBrand',
    method: 'get',
    params: query
  });
}

/**
 * 品牌物料查询 根据品牌pId查询下面对应的物料信息
 * @param {*} query
 */
function queryMaterial(params) {
  return (0, _request.default)({
    url: '/api-prevent/brandMaterlal/queryMaterial',
    method: 'get',
    params: params
  });
}