"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _log = require("@/api/log/log");
var _activityReport = require("@/api/marketingStatic/activityReport");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagelogoreplacementLogindex';
var _default = {
  name: 'MarketingManagelogoreplacementLogindex',
  components: {
    Pagination: _Pagination.default,
    productSelectDialog: _productSelectDialog.default
  },
  data: function data() {
    return {
      activeList: [],
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 10,
      formInline: {
        schemeCode: null,
        // 方案名称
        createUserName: null,
        // 创建人
        drawEndTime: null,
        // 抽奖结束时间
        drawStartTime: null,
        // 抽奖开始时间
        markCode: null,
        // 数码
        productName: null,
        productId: null,
        // 产品id
        reissueEndTime: null,
        // 补发结束时间
        reissueStartTime: null // 补发开始时间
      },

      rangeTime1: '',
      rangeTime2: '',
      listLoading: false,
      tableData: [],
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getactivelist();
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.loglist();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityFlow.getwayList)({
        pageSize: 100,
        pageNo: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    loglist: function loglist() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      delete submitData.productName;
      (0, _activityReport.reissueRecord)(submitData).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 时间选择
    sj1: function sj1(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.drawEndTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.drawEndTime = '';
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.formInline.reissueStartTime = res[0];
        this.formInline.reissueEndTime = res[1];
      } else {
        this.formInline.reissueStartTime = '';
        this.formInline.reissueEndTime = '';
      }
    },
    // 选择产品
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    selectPro: function selectPro(val) {
      this.formInline.productId = val.pId;
      this.formInline.productName = val.productName;
    },
    // 搜索
    search: function search() {
      this.params.pageNo = 1;
      this.loglist();
    },
    // 重置搜索框
    reset: function reset() {
      this.rangeTime1 = '';
      this.rangeTime2 = '';
      this.params.pageNo = 1;
      this.formInline = {
        schemeCode: null,
        // 方案名称
        createUserName: null,
        // 创建人
        drawEndTime: null,
        // 抽奖结束时间
        drawStartTime: null,
        // 抽奖开始时间
        markCode: null,
        // 数码
        productName: null,
        productId: null,
        // 产品id
        reissueEndTime: null,
        // 补发结束时间
        reissueStartTime: null // 补发开始时间
      };

      this.$refs['formInline'].resetFields();
      this.loglist();
    }
  }
};
exports.default = _default;