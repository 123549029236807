var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "800px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "codeForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    model: _vm.codeForm,
                    "label-width": "120px",
                    rules: _vm.codeFormRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "布奖范围:", prop: "awardPrizeRange" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDisableType },
                          on: { change: _vm.changeType },
                          model: {
                            value: _vm.codeForm.awardPrizeRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.codeForm, "awardPrizeRange", $$v)
                            },
                            expression: "codeForm.awardPrizeRange",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("经纬度定投"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("用户ID定投"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放时间:", prop: "putTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.codeForm.putTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.codeForm, "putTime", $$v)
                          },
                          expression: "codeForm.putTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.codeForm.awardPrizeRange == 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "位置:", prop: "extraLocationItems" },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20, type: "flex" } },
                            [
                              _c("el-col", { attrs: { span: 10 } }, [
                                _vm._v("经纬度"),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _vm._v("有效半径(米)"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-plus",
                                      circle: "",
                                    },
                                    on: { click: _vm.addAddress },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.codeForm.extraLocationItems,
                            function (item, index) {
                              return _c(
                                "el-row",
                                {
                                  key: index,
                                  attrs: { type: "flex", gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "extraLocationItems." +
                                              index +
                                              ".latitude",
                                            rules: {
                                              required: true,
                                              message: "请选择经纬度",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              readonly: "",
                                              value:
                                                item.latitude +
                                                " - " +
                                                item.longitude,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop:
                                              "extraLocationItems." +
                                              index +
                                              ".effectiveRange",
                                            rules: {
                                              required: true,
                                              message: "请输入有效半径(米)",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: item.effectiveRange,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "effectiveRange",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.effectiveRange",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("米")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-delete",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteAddress(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.codeForm.awardPrizeRange == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "参与数码:",
                            prop: "digitalCodeList",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tagWrapInput" },
                            [
                              _vm._l(
                                _vm.codeForm.digitalCodeList,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleClose(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tag) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.inputVisibleTag
                                ? _c("el-input", {
                                    ref: "saveTagInput",
                                    staticClass: "input-new-tag",
                                    attrs: { size: "small" },
                                    on: { blur: _vm.handleInputConfirm },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleInputConfirm($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.inputValueTag,
                                      callback: function ($$v) {
                                        _vm.inputValueTag = $$v
                                      },
                                      expression: "inputValueTag",
                                    },
                                  })
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "button-new-tag",
                                      attrs: { size: "small" },
                                      on: { click: _vm.showInputTag },
                                    },
                                    [_vm._v("+ 添加数码")]
                                  ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.codeForm.awardPrizeRange == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "用户ID:", prop: "userIdList" } },
                        [
                          _c(
                            "div",
                            { staticClass: "tagWrapInput" },
                            [
                              _vm._l(
                                _vm.codeForm.userIdList,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleClose1(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tag) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.inputVisibleTag
                                ? _c("el-input", {
                                    ref: "saveTagInput",
                                    staticClass: "input-new-tag",
                                    attrs: { size: "small" },
                                    on: { blur: _vm.handleInputConfirm1 },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleInputConfirm1($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.inputValueTag,
                                      callback: function ($$v) {
                                        _vm.inputValueTag = $$v
                                      },
                                      expression: "inputValueTag",
                                    },
                                  })
                                : _c(
                                    "el-button",
                                    {
                                      staticClass: "button-new-tag",
                                      attrs: { size: "small" },
                                      on: { click: _vm.showInputTag },
                                    },
                                    [_vm._v("+ 添加用户ID")]
                                  ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖品设置:", required: "" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20, type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    prop: "prizeItemsList." + 0 + ".prizeType",
                                    rules: {
                                      required: true,
                                      message: "请选择奖品类型",
                                      trigger: "change",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: Boolean(
                                          _vm.isDisableType &&
                                            _vm.codeForm.prizeItemsList[0].id
                                        ),
                                      },
                                      on: {
                                        change: function (prizeType) {
                                          return _vm.getCurPrizeList(
                                            prizeType,
                                            0
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.codeForm.prizeItemsList[0]
                                            .prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm.prizeItemsList[0],
                                            "prizeType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "codeForm.prizeItemsList[0].prizeType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.prizeTypes,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    prop: "prizeItemsList." + 0 + ".prizeId",
                                    rules: {
                                      required: true,
                                      message: "请选择奖品",
                                      trigger: "change",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: Boolean(
                                          (!_vm.codeForm.prizeItemsList[0]
                                            .prizeType ||
                                            _vm.isDisableType) &&
                                            _vm.codeForm.prizeItemsList[0].id
                                        ),
                                      },
                                      on: { change: _vm.getPriName },
                                      model: {
                                        value:
                                          _vm.codeForm.prizeItemsList[0]
                                            .prizeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm.prizeItemsList[0],
                                            "prizeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "codeForm.prizeItemsList[0].prizeId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.codeForm.prizeItemsList[0].plist,
                                      function (obj, indexObj) {
                                        return _c("el-option", {
                                          key: indexObj,
                                          attrs: {
                                            label: obj.prizeName,
                                            value: obj.prizeId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "",
                                    prop: "prizeItemsList." + 0 + ".prizeNum",
                                    rules: {
                                      required: true,
                                      message: "请输入数量",
                                      trigger: "change",
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      { name: "Number", rawName: "v-Number" },
                                    ],
                                    attrs: {
                                      maxlength: "10",
                                      placeholder: "请输入数量",
                                    },
                                    model: {
                                      value:
                                        _vm.codeForm.prizeItemsList[0].prizeNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.codeForm.prizeItemsList[0],
                                          "prizeNum",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "codeForm.prizeItemsList[0].prizeNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.codeForm.awardPrizeRange == 0
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop:
                                          "prizeItemsList." + 0 + ".prizePr",
                                        rules: {
                                          required: true,
                                          message: "请输入概率",
                                          trigger: "change",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number",
                                              rawName: "v-Number",
                                            },
                                          ],
                                          attrs: {
                                            maxlength: "10",
                                            placeholder: "请输入概率",
                                          },
                                          model: {
                                            value:
                                              _vm.codeForm.prizeItemsList[0]
                                                .prizePr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.codeForm.prizeItemsList[0],
                                                "prizePr",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "codeForm.prizeItemsList[0].prizePr",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注:", prop: "explain" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 3 },
                        model: {
                          value: _vm.codeForm.explain,
                          callback: function ($$v) {
                            _vm.$set(_vm.codeForm, "explain", $$v)
                          },
                          expression: "codeForm.explain",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.mapV
            ? _c("TMap", {
                on: { close: _vm.closeMap, confirm: _vm.confirmFun },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "normalBtn",
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.subLoading,
              },
              on: { click: _vm.finish },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }