"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _instock = require("@/api/chanelmange/instock");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangeinstockcgrkindex';
var _default = {
  name: 'Chanelmangeinstockcgrkindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    purchaseDialog: _purchaseDialog.default,
    productChose: _productSelectDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'success'
        };
        return statusMap[value];
      }
    },
    filterStatus: function filterStatus(val) {
      var status = {
        1: '',
        4: 'success'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      listQuery: {
        inStartDate: '',
        inEndDate: '',
        orgId: null,
        buyerId: '',
        buyerStr: '',
        sellerId: '',
        sellerStr: '',
        productId: '',
        productStr: '',
        businessType: '',
        billNo: '',
        orderNo: '',
        outStartDate: '',
        outEndDate: '',
        isCode: '',
        isError: '',
        status: '',
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      outDate: [],
      inDate: [],
      states: [],
      isErrors: [{
        name: '正常',
        figure: '1'
      }, {
        name: '异常',
        figure: '0'
      }],
      sigleProduct: {
        productName: '',
        productCode: ''
      },
      unitSelect: {
        channelName: ''
      },
      businessOption: [],
      tableKey: 'cgrk',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      unitType: '',
      productStyle: '',
      productShow: false,
      exportLoading: false,
      scanVisible: false,
      baseData: {},
      checklist: [],
      productlist: [],
      orderId: '',
      billNo: '',
      codeData: [],
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        orderType: 2,
        pageNo: 1,
        pageSize: 10,
        billNo: ''
      },
      total2: 0,
      scanCodeVisible: false,
      active: 1,
      activeName: '',
      showCol: false,
      expandTxt: '展开',
      statuslist: [],
      statusStart: 'process',
      statusEnd: 'success',
      inNumAllPlan: 0,
      inAllNum: 0,
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getBusType();
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  created: function created() {
    this.getStatusList();
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getStatusList: function getStatusList() {
      var _this = this;
      // 获取单据状态
      this.states = [];
      var param = {};
      param.code = 'DJZT';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          /*
          *figure 1开头为企业单据状态 ；2开头为渠道单据状态 ；
          */
          _this.states = res.data.data.filter(function (item) {
            return /^2/.test(item.figure.toString());
          });
        }
      }).catch(function () {});
    },
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = {};
      /*
      * 单据类型： 101：生产入库 102：调货入库 103：退货入库 104：采购入库 105：其它（材料）入库 201：返工出库 202：调货出库 203：销售出库 204：退货出库 205：其它（材料）出库
      * */
      param.billType = '103';
      param.inStartDate = that.listQuery.inStartDate;
      param.inEndDate = that.listQuery.inEndDate;
      param.orgId = that.listQuery.orgId;
      param.buyerId = that.listQuery.buyerId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.businessType = that.listQuery.businessType;
      param.billNo = that.listQuery.billNo;
      param.orderNo = that.listQuery.orderNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.status = that.listQuery.status;
      param.isError = that.listQuery.isError;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      param.outNo = that.listQuery.outNo;
      (0, _instock.getCgRkList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      if (that.outDate.length > 0) {
        that.listQuery.outStartDate = that.outDate[0];
        that.listQuery.outEndDate = that.outDate[1];
      } else {
        that.listQuery.outStartDate = '';
        that.listQuery.outEndDate = '';
      }
      if (that.inDate.length > 0) {
        that.listQuery.inStartDate = that.inDate[0];
        that.listQuery.inEndDate = that.inDate[1];
      } else {
        that.listQuery.inStartDate = '';
        that.listQuery.inEndDate = '';
      }
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.inStartDate = '';
      that.listQuery.inEndDate = '';
      that.listQuery.orgId = null;
      that.listQuery.buyerId = '';
      that.listQuery.buyerStr = '';
      that.listQuery.sellerId = '';
      that.listQuery.sellerStr = '';
      that.listQuery.productId = '';
      that.listQuery.productStr = '';
      that.listQuery.businessType = '';
      that.listQuery.billNo = '';
      that.listQuery.orderNo = '';
      that.listQuery.outStartDate = '';
      that.listQuery.outEndDate = '';
      that.listQuery.isCode = '';
      that.listQuery.status = '';
      that.listQuery.isError = '';
      that.listQuery.pageNo = 1;
      that.listQuery.pageSize = 20;
      this.listQuery.outNo = '';
      that.outDate = [];
      that.inDate = [];
      that.getList();
    },
    // 获取组织
    getOrg: function getOrg() {
      var that = this;
      (0, _instock.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearSh: function clearSh() {
      var that = this;
      that.listQuery.buyerId = '';
    },
    clearFh: function clearFh() {
      var that = this;
      that.listQuery.sellerId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    // 获取产品名称
    choseProName: function choseProName(val) {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.productStyle = val;
      this.$refs.selProduct.proSelVisible = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      if (that.productStyle == 'search') {
        that.listQuery.productId = val.pId;
        that.listQuery.productStr = val.productName;
        that.sigleProduct.productCode = val.productCode;
        that.sigleProduct.productName = val.productName;
      }
      if (that.productStyle == 'codeScan') {
        that.codeParam.productName = val.productName;
        that.codeParam.productId = val.pId;
      }
      that.productShow = false;
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var _this2 = this;
      var that = this;
      that.authId('searchbtn', that.apis);
      that.unitType = val;
      this.$nextTick(function () {
        _this2.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.unitSelect.channelName = val.channelName;
      if (that.unitType == 'in') {
        that.listQuery.buyerId = val.pId;
        that.listQuery.buyerStr = val.channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.sellerId = val.pId;
        that.listQuery.sellerStr = val.channelName;
      }
      that.unitShow = false;
    },
    // 获取业务类型
    getBusType: function getBusType() {
      var that = this;
      that.authId('searchbtn', that.apis);
      var param = {};
      param.billEnum = 2;
      param.outgoType = '2';
      param.bussinessStatus = '1';
      (0, _instock.getType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.businessOption = res.data.data;
        }
      });
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = {};
      param.billType = '103';
      param.inStartDate = that.listQuery.inStartDate;
      param.inEndDate = that.listQuery.inEndDate;
      param.orgId = that.listQuery.orgId;
      param.buyerId = that.listQuery.buyerId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.businessType = that.listQuery.businessType;
      param.billNo = that.listQuery.billNo;
      param.orderNo = that.listQuery.orderNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.status = that.listQuery.status;
      param.isError = that.listQuery.isError;
      param.pageNo = 1;
      param.pageSize = that.total;
      that.exportLoading = true;
      (0, _instock.inOrOutStockExport)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '退货入库列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.authId('detailbtn', that.apis);
      var param = {};
      param.billNo = data.no;
      that.orderId = data.pid;
      that.billNo = data.no;
      (0, _instock.getCgDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.base != null) {
            that.baseData = res.data.data.base;
            that.active = Number(that.baseData.orderStatus);
          }
          if (res.data.data.checklist != null) {
            that.checklist = res.data.data.checklist;
          }
          if (res.data.data.productlist != null) {
            that.productlist = res.data.data.productlist;
            that.inNumAllPlan = 0;
            that.inAllNum = 0;
            if (that.productlist.length > 0) {
              that.productlist.forEach(function (item) {
                that.inNumAllPlan += Number(item.orderNum);
                that.inAllNum += Number(item.inNum);
              });
            }
          }
          if (res.data.data.statuslist != null) {
            var asc = function asc(x, y) {
              return Number(x['type']) > Number(y['type']) ? 1 : -1;
            };
            var len = res.data.data.statuslist.length;
            if (len > 0) {
              that.statuslist = res.data.data.statuslist.sort(asc);
              if (len >= 2) {
                that.statusEnd = 'success';
                that.statusStart = 'success';
              }
              if (len <= 1) {
                that.statusEnd = 'wait';
                that.statusStart = 'process';
              }
            } else {
              that.statuslist = [];
              that.statusEnd = 'wait';
              that.statusStart = 'process';
            }
          }
          that.scanVisible = true;
        } else {
          that.baseData = {};
          that.checklist = [];
          that.productlist = [];
          that.$message.error('获取去详情失败，请稍后再试！');
        }
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetail();
    },
    codeClose: function codeClose() {
      this.scanCodeVisible = false;
      this.codeParam = {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 2,
        pageNo: 1,
        pageSize: 10,
        billNo: ''
      };
      this.codeData = [];
      this.total2 = 0;
    },
    getCodeDetail: function getCodeDetail() {
      var that = this;
      this.codeParam.id = that.orderId;
      this.codeParam.billNo = that.billNo;
      var param = JSON.parse(JSON.stringify(this.codeParam));
      delete param.productName;
      (0, _instock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        } else {
          that.codeData = [];
          that.total2 = 0;
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetail();
    },
    searchReset: function searchReset() {
      // this.codeParam.id = ''
      this.codeParam.code = '';
      this.codeParam.productId = '';
      this.codeParam.productName = '';
      this.codeParam.isError = '';
      this.codeParam.pageNo = 1;
      this.codeParam.billNo = '';
      this.getCodeDetail();
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;