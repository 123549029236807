"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tw: [{
        fileUrl: ''
      }],
      twImgurl: 'http://dev20gw.panpass.cn/images/'
    };
  },
  created: function created() {
    var that = this;
    that.selectNewsid();
    that.twImgurl = _wxHy.base;
  },
  methods: {
    // 查看图文
    selectNewsid: function selectNewsid() {
      var _this = this;
      if (this.$route.query.id) {
        var i = {
          id: this.$route.query.id,
          type: 1
        };
        console.log(i);
        (0, _wxgzh.selectNews)(i).then(function (res) {
          var res = res.data;
          console.log(res);
          if (res.code * 1 === 200) {
            _this.tw = res.data;
          } else {
            _this.$message.warning(res.msg);
          }
        });
      } else {
        this.$router.push({
          name: 'wxAccountswxgzhwxFunctionfodderindex',
          params: {}
        });
      }
    }
  }
};
exports.default = _default;