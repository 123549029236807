"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _poster = require("@/api/terminal/poster");
var _config = _interopRequireDefault(require("@/views/terminalManagement/eventPoster/terminalPosters/config"));
var _index = _interopRequireDefault(require("@/components/imageQRcode/index"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    actConfig: _config.default,
    imageQRcode: _index.default
  },
  data: function data() {
    return {
      tabHeight: 'calc(100vh - 350px)',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      ruleForm: {
        posterCodeAndName: '',
        relevancyTerminalAndId: '',
        activityStatus: '',
        dataTimeRange: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      downLoading: false,
      dialogVisible: false,
      dataList: '',
      type: 'add',
      img: '',
      img2: ''
    };
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      if (this.ruleForm.dataTimeRange && this.ruleForm.dataTimeRange.length > 0) {
        this.ruleForm.startTime = this.ruleForm.dataTimeRange[0];
        this.ruleForm.endTime = this.ruleForm.dataTimeRange[1];
      } else {
        this.ruleForm.startTime = '';
        this.ruleForm.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.ruleForm));
      delete sdata.dataTimeRange;
      (0, _poster.postQueryPosterList)(sdata).then(function (res) {
        if (res.data.code == '200') {
          if (!res.data.data.errCode) {
            _this.list = res.data.data.data.records;
            _this.total = res.data.data.data.total;
            _this.listLoading = false;
          } else {
            _this.listLoading = false;
            _this.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this.listLoading = false;
          _this.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.ruleForm = {
        posterCodeAndName: '',
        relevancyTerminalAndId: '',
        activityStatus: '',
        dataTimeRange: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    // 新增活动
    creatAct: function creatAct() {
      // this.authId('addbtn', this.apis)
      this.type = 'add';
      this.dataList = {};
      this.dialogVisible = true;
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      this.getList();
      this.dataList = {};
      this.type = 'add';
    },
    // 编辑
    edit: function edit(data) {
      this.type = 'edit';
      this.dataList = data;
      this.dialogVisible = true;
    },
    // 禁用启用
    setDisable: function setDisable(val) {
      var _this2 = this;
      // this.authId('stopbtn', this.apis)
      this.$confirm('是否' + (val.activityStatus == '3' ? '启用' : '停用') + '终端海报?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 点击确定的操作(调用接口)
        (0, _poster.getChangeStatus)({
          id: val.id
        }).then(function (res) {
          console.log(res);
          if (res.data.code == 200) {
            if (!res.data.data.errCode) {
              _this2.getList();
              _this2.$message.success(res.data.data.msg);
            } else {
              _this2.$message.error(res.data.data.errMsg);
            }
          } else {
            _this2.$message.error(res.data.msg);
            return;
          }
        });
      }).catch(function () {
        // 点取消的提示
        return;
      });
    },
    exportExcel: function exportExcel() {
      var _this3 = this;
      this.downLoading = true;
      if (this.ruleForm.dataTimeRange && this.ruleForm.dataTimeRange.length > 0) {
        this.ruleForm.startTime = this.ruleForm.dataTimeRange[0];
        this.ruleForm.endTime = this.ruleForm.dataTimeRange[1];
      } else {
        this.ruleForm.startTime = '';
        this.ruleForm.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.ruleForm));
      delete sdata.dataTimeRange;
      (0, _poster.getPosterExcel)(sdata).then(function (res) {
        _this3.downLoading = false;
        if (res.status !== 200) {
          _this3.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this3.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '海报列表.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this3.downLoading = false;
      });
    },
    upimageQR: function upimageQR(row) {
      this.img = row.backgroundUrl;
      this.img2 = row.posterUrl;
      this.$refs.imgCode.proSelVisible = true;
    }
  }
};
exports.default = _default;