var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "800px", padding: "20px" },
            attrs: { model: _vm.form, "label-width": "200px" },
          },
          [
            _c(
              "el-radio",
              {
                attrs: { label: "1" },
                model: {
                  value: _vm.form.reissueType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "reissueType", $$v)
                  },
                  expression: "form.reissueType",
                },
              },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "循环补发：",
                  prop: "reissueFrom",
                  rules: [
                    {
                      required: _vm.form.reissueType == 1 ? true : false,
                      message: "请选择方式",
                      trigger: "blur",
                    },
                  ],
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.reissueFrom,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reissueFrom", $$v)
                      },
                      expression: "form.reissueFrom",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "固定间隔", value: "1" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "固定时间", value: "2" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "间隔时间：",
                  prop: "reissueContent",
                  rules: [
                    {
                      required: _vm.form.reissueType == 1 ? true : false,
                      message: "请输入补发时间",
                      trigger: "blur",
                    },
                    {
                      required: _vm.form.reissueType == 1 ? true : false,
                      message: "输入格式不正确",
                      trigger: "blur",
                      pattern: /^([0-2][0-9]):([0-5][0-9]):([0-5][0-9])$/,
                    },
                  ],
                },
              },
              [
                _vm.form.reissueFrom == "1"
                  ? _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.reissueContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reissueContent", $$v)
                          },
                          expression: "form.reissueContent",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("小时")])],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.reissueFrom == "2"
                  ? _c("el-input", {
                      model: {
                        value: _vm.form.reissueContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reissueContent", $$v)
                        },
                        expression: "form.reissueContent",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-radio",
              {
                attrs: { label: "0" },
                model: {
                  value: _vm.form.reissueType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "reissueType", $$v)
                  },
                  expression: "form.reissueType",
                },
              },
              [_vm._v(" ")]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "即时补发：",
                  prop: "reissueContent",
                  rules: [
                    {
                      required: _vm.form.reissueType == 0 ? true : false,
                      message: "请输入即时补发时间",
                      trigger: "blur",
                    },
                  ],
                },
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.reissueContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reissueContent", $$v)
                      },
                      expression: "form.reissueContent",
                    },
                  },
                  [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleFormReissueType")
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.resetForm("ruleFormReissueType")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }