var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "page-section",
        },
        [
          _c(
            "div",
            { staticStyle: { width: "690px", margin: "10px auto 5px" } },
            [
              _c("div", { staticClass: "detailStepWrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "detailStepItem",
                    class: { active: _vm.active >= 0 },
                  },
                  [
                    _c("div", { staticClass: "detailStepItemTitle" }, [
                      _vm._v("订单信息"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "detiaoStepSpace",
                  class: { active: _vm.active >= 1 },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detailStepItem",
                    class: { active: _vm.active >= 1 },
                  },
                  [
                    _c("div", { staticClass: "detailStepItemTitle" }, [
                      _vm._v("产品信息"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "detiaoStepSpace",
                  class: { active: _vm.active >= 2 },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detailStepItem",
                    class: { active: _vm.active >= 2 },
                  },
                  [
                    _c("div", { staticClass: "detailStepItemTitle" }, [
                      _vm._v("成功提示"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _vm.active == 0
            ? _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: {
                    width: "700px",
                    margin: "20px auto 0",
                    "min-height": "calc(100vh - 328px)",
                  },
                  attrs: {
                    model: _vm.addData,
                    "label-width": "130px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用酒订单号:", prop: "orderNo" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.addData.orderNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "orderNo", $$v)
                                },
                                expression: "addData.orderNo",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.getNo },
                                  slot: "append",
                                },
                                [_vm._v("获取")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "费用酒订单类型:",
                            prop: "orderType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.addData.orderType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "orderType", $$v)
                                },
                                expression: "addData.orderType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "费用酒订单", value: "4" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "样品酒订单", value: "5" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单日期:", prop: "tradeDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择日期",
                            },
                            model: {
                              value: _vm.addData.tradeDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "tradeDate", $$v)
                              },
                              expression: "addData.tradeDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售单位:", prop: "saleOrgId" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.saleOrgName,
                                callback: function ($$v) {
                                  _vm.saleOrgName = $$v
                                },
                                expression: "saleOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany("saleOrgName", 3, 12)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货单位:", prop: "sendOrgId" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.sendOrgName,
                                callback: function ($$v) {
                                  _vm.sendOrgName = $$v
                                },
                                expression: "sendOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany("sendOrgName", 2, 12)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "采购单位:", prop: "purchaseOrgId" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: true },
                              model: {
                                value: _vm.purchaseOrgName,
                                callback: function ($$v) {
                                  _vm.purchaseOrgName = $$v
                                },
                                expression: "purchaseOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany(
                                      "purchaseOrgName",
                                      1,
                                      12
                                    )
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货单位:", prop: "receiveOrgId" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.receiveOrgName,
                              callback: function ($$v) {
                                _vm.receiveOrgName = $$v
                              },
                              expression: "receiveOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { prop: "receiveUser" } }, [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(" "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "receiveUser",
                            style: {
                              borderColor: _vm.receiveOrgName
                                ? "#c0c4cc"
                                : "#e4e7ed",
                            },
                          },
                          [
                            _c("div", { staticClass: "receiveAddress" }, [
                              _vm._v(_vm._s(_vm.addData.receiveAddress)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rUser" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.addData.receiveUser
                                    ? _vm.addData.receiveUser + "："
                                    : ""
                                ) + _vm._s(_vm.addData.receivePhone)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { label: "备注:", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "100",
                              "show-word-limit": "",
                              placeholder: "请输入",
                              autosize: { minRows: 2, maxRows: 2 },
                            },
                            model: {
                              value: _vm.addData.remark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addData,
                                  "remark",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.active == 1
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.openPro(1)
                            },
                          },
                        },
                        [_vm._v("添加产品")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      ref: "addTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: "calc(100vh - 385px)",
                        data: _vm.productList,
                        fit: "",
                        "row-class-name": "iconNone",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "80",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: "center",
                          prop: "productCode",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: "center",
                          prop: "productName",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "productModel",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: "center",
                          prop: "packScaleExpression",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: 99999999,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.chestNum,
                                      callback: function ($$v) {
                                        _vm.$set(row, "chestNum", $$v)
                                      },
                                      expression: "row.chestNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3987246532
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: _vm.mixNumP(row),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.bottleNum,
                                      callback: function ($$v) {
                                        _vm.$set(row, "bottleNum", $$v)
                                      },
                                      expression: "row.bottleNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1960900472
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          prop: "totalBottleNum",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "80",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoSc(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3740979374
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.active == _vm.activeTotal
            ? _c("div", { staticClass: "successWrap" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "orderWrap" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "orderItem", attrs: { gutter: 40 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("订单编号:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "topContent" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.addData.orderNo) +
                                    "\n                "
                                ),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: _vm.addData.orderNo,
                                        expression: "addData.orderNo",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    staticClass: "link",
                                    attrs: { type: "primary" },
                                  },
                                  [_vm._v("复制单号")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("订单类型:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComOwnerType")(
                                    _vm.addData.orderType
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("销售单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.saleOrgName)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "orderItem", attrs: { gutter: 40 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("发货单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.sendOrgName)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("采购单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.purchaseOrgName)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "topItem" }, [
                            _c("div", { staticClass: "topLabel" }, [
                              _vm._v("收货单位:"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "topContent" }, [
                              _vm._v(_vm._s(_vm.receiveOrgName)),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _vm.active != _vm.activeTotal
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active >= 1 && _vm.active != _vm.activeTotal
                ? _c(
                    "el-button",
                    { attrs: { type: "default" }, on: { click: _vm.pro } },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active < _vm.activeTotal - 1
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.next } },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active == _vm.activeTotal - 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("2")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.active == _vm.activeTotal
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("返回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "topWrap" }, [
      _c("img", { attrs: { src: require("@/images/success.png") } }),
      _vm._v(" "),
      _c("div", { staticClass: "status" }, [_vm._v("操作成功")]),
      _vm._v(" "),
      _c("div", { staticClass: "successContent" }, [
        _vm._v("费用酒订单创建成功"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }