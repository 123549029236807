"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excelQueryForPage = excelQueryForPage;
exports.getDetailById = getDetailById;
exports.queryForPage = queryForPage;
exports.yqQueryForPage = yqQueryForPage;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/storage-center';
//  稽查分页查询
function queryForPage(params) {
  return (0, _request.default)({
    url: modelUrl + '/inspection/queryForPage',
    method: 'get',
    params: params
  });
}

//  稽查分页查询
function yqQueryForPage(params) {
  return (0, _request.default)({
    url: modelUrl + '/inspection/yqQueryForPage',
    method: 'get',
    params: params
  });
}

// 获取详情

function getDetailById(params) {
  return (0, _request.default)({
    url: modelUrl + '/inspection/getDetailById',
    method: 'get',
    params: params
  });
}

// 导出
function excelQueryForPage(params) {
  return (0, _request.default)({
    url: modelUrl + '/inspection/excelQueryForPage',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}