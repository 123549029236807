var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateType",
                                label: "模板类型",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formInline.templateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "templateType",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.templateType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "扫码抽奖", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.handleShow || !_vm.formConfig.btnFollow,
                              expression: "handleShow||!formConfig.btnFollow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "state", label: "模板状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formInline.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "state", $$v)
                                    },
                                    expression: "formInline.state",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name", label: "模板名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入模板名称" },
                                model: {
                                  value: _vm.formInline.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "name", $$v)
                                  },
                                  expression: "formInline.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "startTime", label: "开始日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.formInline.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "startTime", $$v)
                                  },
                                  expression: "formInline.startTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "endTime", label: "结束日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.formInline.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "endTime", $$v)
                                  },
                                  expression: "formInline.endTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: {
                                width: _vm.formConfig.btnAreaHasShowMore,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.formInline.pageNo = 1
                                          _vm.fetchData()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.restForm("formInline")
                                          _vm.fetchData()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.handleShow = !_vm.handleShow
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.handleShow ? "收起" : "展开"
                                          ) +
                                          "\n                "
                                      ),
                                      _c("i", {
                                        class: _vm.handleShow
                                          ? "el-icon-arrow-up"
                                          : "el-icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              !_vm.formConfig.btnFollow
                ? _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              _vm.formInline.pageNo = 1
                              _vm.fetchData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              _vm.restForm("formInline")
                              _vm.fetchData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.handleShow = !_vm.handleShow
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.handleShow ? "收起" : "展开") +
                              "\n          "
                          ),
                          _c("i", {
                            class: _vm.handleShow
                              ? "el-icon-arrow-up"
                              : "el-icon-arrow-down",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.addBtn.icon,
                      },
                      on: {
                        click: function ($event) {
                          _vm.actDialogVisible = true
                        },
                      },
                    },
                    [_vm._v("系统预置模板")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                "element-loading-text": "Loading",
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "模板名称",
                  "show-overflow-tooltip": "",
                  "min-width": "150",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.name))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "模板类型",
                  width: "110",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("templateType")(scope.row.templateType))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "预置",
                  width: "80",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("issysbuild")(scope.row.issysbuild))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  "show-overflow-tooltip": "",
                  "min-width": "100",
                  label: "创建人",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.creator))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  width: "180",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "更新人",
                  "show-overflow-tooltip": "",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.updator))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  width: "180",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.updateTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  width: "70",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.templateState == 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateState")(
                                    scope.row.templateState
                                  )
                                )
                              ),
                            ])
                          : _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateState")(
                                    scope.row.templateState
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: _vm.tableConfig.align,
                  label: "操作",
                  fixed: "right",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "button configText",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.creatAct(scope.row, true)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        ),
                        _vm._v(" "),
                        scope.row.templateState * 1 === 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.creatAct(scope.row, false)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.templateState !== "2"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.disable(scope.row.templateId)
                                  },
                                },
                              },
                              [_vm._v("停用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.templateState !== "1"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.enable(scope.row.templateId)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.formInline.pageNo,
              limit: _vm.formInline.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.formInline, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.formInline, "pageSize", $event)
              },
              pagination: _vm.fetchData,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "模板类型",
            visible: _vm.actDialogVisible,
            "before-close": _vm.cancel,
            width: "800px !important",
          },
        },
        [
          _c(
            "div",
            { staticClass: "itemWrap" },
            _vm._l(_vm.actlist, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.creatAct(
                        { templateType: item.type, templateId: "" },
                        false
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "imgWrap" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: item.img },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }, [
                    _c("span", {
                      staticClass: "cardDialogTitle",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: "模板基本设置",
                visible: _vm.dialogVisible,
                "append-to-body": true,
                width: "80% !important",
                "before-close": _vm.close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("Content", {
                ref: "content",
                attrs: { id: _vm.id, type: _vm.type, disabled: _vm.disabled },
                on: { close: _vm.close },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }