var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("工厂档案")]),
      _vm._v(" "),
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c(
                "div",
                { staticClass: "page-section leftSide" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "search-condition",
                      attrs: {
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "head-btn-group",
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 16, prop: "materialCode" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "工厂编码/名称:",
                                        prop: "isDelete",
                                        "label-width": "50px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                          maxlength: "30",
                                        },
                                        model: {
                                          value: _vm.filterText,
                                          callback: function ($$v) {
                                            _vm.filterText = $$v
                                          },
                                          expression: "filterText",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 8, prop: "factoryCode" } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "orgStatus" } },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.handleFilter },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.normalList,
                          props: _vm.props,
                          "highlight-current": true,
                          load: _vm.loadNode,
                          "filter-node-method": _vm.filterNode,
                          lazy: "",
                          accordion: "",
                          "node-key": "pId",
                          "default-expanded-keys": [0],
                        },
                        on: { "node-click": _vm.getNode },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var data = ref.data
                                return _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "custom-tree-node",
                                        class:
                                          _vm.activeClass == data.pId
                                            ? "active"
                                            : "",
                                        attrs: { index: data.pId },
                                        on: {
                                          click: function ($event) {
                                            return _vm.scan(data)
                                          },
                                        },
                                      },
                                      [
                                        data.factoryName == "所有工厂"
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "240px",
                                                  display: "inlin-block",
                                                  "word-break": "break-all",
                                                  "word-wrap": "break-word",
                                                  "white-space": "normal",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(data.factoryName) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "240px",
                                                  display: "inlin-block",
                                                  "word-break": "break-all",
                                                  "word-wrap": "break-word",
                                                  "white-space": "normal",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                (" +
                                                    _vm._s(data.factoryCode) +
                                                    ")" +
                                                    _vm._s(data.factoryName) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          824890343
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "calc(100% - 300px)",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.searchForm,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "cols", attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "factoryCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "基地编码",
                                    prop: "factoryCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    on: {
                                      input: function (e) {
                                        return (_vm.searchForm.factoryCode =
                                          _vm.validCode(e))
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.factoryCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "factoryCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.factoryCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "factoryName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "基地名称",
                                    prop: "factoryName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "40",
                                    },
                                    model: {
                                      value: _vm.searchForm.factoryName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "factoryName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.factoryName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "isEnable" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态", prop: "isEnable" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.isEnable,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "isEnable",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.isEnable",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "启用", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: { width: _vm.formConfig.btnArea },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                            plain:
                                              _vm.btnConfig.searchBtn.plain,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                            plain: _vm.btnConfig.resetBtn.plain,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formConfig.btnFollow
                    ? _c("el-divider", { staticClass: "btnDivider" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formConfig.btnFollow
                    ? _c(
                        "div",
                        { staticClass: "btnArea" },
                        [
                          _c("el-col", { staticClass: "head-btn-group" }, [
                            _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                              ? _c(
                                  "div",
                                  { staticClass: "head-btn-group" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.addBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.addBtn.icon,
                                          plain: _vm.btnConfig.addBtn.plain,
                                        },
                                        on: { click: _vm.add },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.addBtn.text) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "section",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            height: _vm.tabHeight,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "基地编码",
                              prop: "factoryCode",
                              align: _vm.tableConfig.align,
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "基地名称",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                              prop: "factoryName",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "类型",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(_vm._f("typeName")(row.type))
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              961025281
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "所属组织",
                              prop: "orgName",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.searchForm.parentId == ""
                                            ? row.orgName
                                            : "-"
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              627337837
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "状态",
                              align: _vm.tableConfig.align,
                              "min-width": "80",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      row.isEnable == 1
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "success" } },
                                            [_vm._v("启用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.isEnable == 0
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "danger" } },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.isEnable == 2
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "info" } },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2959942508
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "关联产品数量",
                              prop: "productCount",
                              align: _vm.tableConfig.align,
                              "min-width": "120",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  align: _vm.tableConfig.align,
                                  width: "150",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          row.archivesStatus != 2
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.edit(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled: row.isUse == 1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteID(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4062653735
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total,
                          page: _vm.searchForm.current,
                          limit: _vm.searchForm.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.searchForm, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.searchForm, "size", $event)
                          },
                          pagination: _vm.getList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _vm.type == 0 || _vm.type == 1
                    ? _c("span", [
                        _vm._v(_vm._s(_vm._f("typeName")(_vm.ruleForm.type))),
                      ])
                    : _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "产线", value: 3 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "班组", value: 4 },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.title.indexOf("工厂") !== -1
                        ? "工厂编码"
                        : _vm.title.indexOf("车间") !== -1
                        ? "车间编码"
                        : _vm.title.indexOf("产线/班组") !== -1
                        ? "产线/班组编码"
                        : "",
                    prop: "factoryCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      disabled:
                        _vm.title == "编辑工厂" ||
                        _vm.title == "编辑车间" ||
                        _vm.title == "编辑产线/班组",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.factoryCode = _vm.validCode(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.factoryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "factoryCode", $$v)
                      },
                      expression: "ruleForm.factoryCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.title.indexOf("工厂") !== -1
                        ? "工厂名称"
                        : _vm.title.indexOf("车间") !== -1
                        ? "车间名称"
                        : _vm.title.indexOf("产线/班组") !== -1
                        ? "产线/班组名称"
                        : "",
                    prop: "factoryName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "40" },
                    model: {
                      value: _vm.ruleForm.factoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "factoryName", $$v)
                      },
                      expression: "ruleForm.factoryName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.type == 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属组织",
                        prop: "orgId",
                        rules: {
                          required: true,
                          message: "请选择",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "" },
                          on: {
                            change: function ($event) {
                              return _vm.changeorg(_vm.ruleForm.orgId)
                            },
                          },
                          model: {
                            value: _vm.ruleForm.orgId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "orgId", $$v)
                            },
                            expression: "ruleForm.orgId",
                          },
                        },
                        _vm._l(_vm.orgList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: { label: item.orgName, value: item.pId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属部门", prop: "depId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "" },
                          model: {
                            value: _vm.ruleForm.depId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "depId", $$v)
                            },
                            expression: "ruleForm.depId",
                          },
                        },
                        _vm._l(_vm.depList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: { label: item.orgName, value: item.pId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "添加工厂" || _vm.title == "编辑工厂"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "生产产品范围",
                        prop: "allSelect",
                        "label-width": "100",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.changetab(_vm.ruleForm.allSelect)
                            },
                          },
                          model: {
                            value: _vm.ruleForm.allSelect,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "allSelect", $$v)
                            },
                            expression: "ruleForm.allSelect",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("所有"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("指定"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所在区域" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { prop: "areaList" } },
                            [
                              _c("el-cascader", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  options: _vm.areaList,
                                  props: {
                                    value: "pId",
                                    label: "areaName",
                                    children: "children",
                                  },
                                },
                                model: {
                                  value: _vm.areaSelectList,
                                  callback: function ($$v) {
                                    _vm.areaSelectList = $$v
                                  },
                                  expression: "areaSelectList",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { prop: "detailedAddress" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "请输入详细地址  例如：XX街道 35号",
                                  maxlength: "40",
                                },
                                model: {
                                  value: _vm.ruleForm.detailedAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "detailedAddress",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.detailedAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.ruleForm.phone,
                          placeholder: "请输入",
                        },
                        on: {
                          input: function (e) {
                            return (_vm.ruleForm.phone = _vm.valTel(e))
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "isEnable" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.ruleForm.isEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isEnable", $$v)
                      },
                      expression: "ruleForm.isEnable",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "工厂档案详情",
            visible: _vm.scanVisible,
            "before-close": _vm.handleCloseScan,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "产品信息", column: 2, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "类型" } }, [
                    _vm._v(_vm._s(_vm._f("typeName")(_vm.ruleForm.type))),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "工厂编码" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.factoryCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "工厂名称" } }, [
                    _vm._v(_vm._s(_vm.ruleForm.factoryName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产许可证" } },
                    [_vm._v(_vm._s(_vm.ruleForm.productionLicense))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "所属组织" } },
                    _vm._l(_vm.orgList, function (item) {
                      return _c("div", [
                        item.pId == _vm.ruleForm.orgId
                          ? _c("span", [_vm._v(_vm._s(item.orgName))])
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "状态" } },
                    [
                      _vm.ruleForm.isEnable == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("启用"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.isEnable == 0
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("禁用"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.isEnable == 2
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("删除"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        生产分公司\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.salesmanIds,
                    fit: "",
                    "max-height": "250",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      align: _vm.tableConfig.align,
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "分公司编码",
                      prop: "factoryCode",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "分公司名称",
                      prop: "factoryName",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        指定产品\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.ruleForm.list,
                    fit: "",
                    "max-height": "250",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      align: _vm.tableConfig.align,
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品分类",
                      prop: "productClassName",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        企业信用代码\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.ruleForm.factoryEnterPriseCodeList,
                    fit: "",
                    "max-height": "250",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      align: _vm.tableConfig.align,
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "平台类型",
                      prop: "enterpriseType",
                      align: _vm.tableConfig.align,
                      "min-width": "80",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(_vm.USCITypeList, function (i) {
                            return _c("div", [
                              i.dictVal == row.enterpriseType
                                ? _c("span", [_vm._v(_vm._s(i.dictDesc))])
                                : _vm._e(),
                            ])
                          })
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "信用代码",
                      prop: "enterpriseCreditCode",
                      align: _vm.tableConfig.align,
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleCloseScan },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.proShow,
              data: _vm.ruleForm.list,
              "all-select": _vm.ruleForm.allSelect,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.ruleForm, "list", $event)
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("factory-dialog", {
        ref: "facSelBox",
        attrs: { data: _vm.salesmanIds, "current-fac-id": _vm.currentFacId },
        on: {
          "update:data": function ($event) {
            _vm.salesmanIds = $event
          },
          change: _vm.setSelFac,
          close: _vm.facClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }