var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        visible: _vm.visible,
        "before-close": _vm.selCancel,
        "show-close": false,
        title: "单据打印",
        width: "1000px!important",
        top: "20px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "printItem",
          staticClass: "page-container",
          attrs: { id: "subOutputRank-print" },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#fafafb",
                padding: "6px 0",
                position: "relative",
              },
            },
            [
              _c(
                "h2",
                {
                  staticStyle: { "text-align": "center", "margin-bottom": "0" },
                },
                [_vm._v(_vm._s(_vm._f("djlxText")(_vm.scanData.billType)))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right", "margin-top": "40px" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.scanData.billNo ? "单据号:" + _vm.scanData.billNo : ""
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                ref: "qrCodeUrl",
                staticClass: "qrcode",
                attrs: { id: "qrcode" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "print" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: { "status-icon": "", "label-suffix": ":" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8, prop: "productCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productClassId",
                                    label: "制单日期",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.scanData.createTime
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8, prop: "productCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productClassId",
                                    label: "发货库房",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.scanData.outStoreName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8, prop: "productCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productClassId",
                                    label: "发货日期",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.scanData.statementTime)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticClass: "table",
                          attrs: {
                            data: _vm.tableData,
                            border: "",
                            "row-key": "pId",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "60",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编号",
                              prop: "productCode",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.productCode)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.productName)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              prop: "packRadioDesc",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.packRadioDesc) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "计划出库数量",
                                prop: "brandName",
                                align: "center",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "planScanAtoNum",
                                  align: "center",
                                  label: "总瓶数",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "planScanNum",
                                  align: "center",
                                  label: "箱数",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "bottleNumOver",
                                  align: "center",
                                  label: "零瓶数",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { margin: "2px 5px" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 9, prop: "remark" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { prop: "remark", label: "备注" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.scanData.remark)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 9, prop: "createUser" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "createUser",
                                        label: "制单",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.scanData.createUser)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer no-print",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.selCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "print",
                  rawName: "v-print",
                  value: "#subOutputRank-print",
                  expression: "'#subOutputRank-print'",
                },
              ],
              attrs: { type: "primary" },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }