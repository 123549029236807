var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "产品疑似窜货统计个数明细",
        width: "75% !important",
        visible: _vm.dialogDigitalVisible,
        top: "3%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogDigitalVisible = $event
        },
        open: function ($event) {
          return _vm.getList()
        },
      },
    },
    [
      _c("div", { attrs: { id: "form-box" } }, [
        _c(
          "div",
          { attrs: { id: "table-box" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: "300",
                  data: _vm.list,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "120",
                    label: "序号",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "物流码",
                    align: _vm.tableConfig.align,
                    width: "180",
                    prop: "wlm",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "品牌",
                    align: _vm.tableConfig.align,
                    width: "180",
                    prop: "ppName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    align: _vm.tableConfig.align,
                    width: "180",
                    prop: "wldm",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    align: _vm.tableConfig.align,
                    width: "220",
                    prop: "wlms",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "发货时间",
                    align: _vm.tableConfig.align,
                    width: "180",
                    prop: "fhsj",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "经销商/终端",
                    align: _vm.tableConfig.align,
                    width: "220",
                    prop: "yjjxs",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "收货地区",
                    align: _vm.tableConfig.align,
                    width: "220",
                    prop: "shsf",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查询地区",
                    align: _vm.tableConfig.align,
                    width: "220",
                    prop: "cxdq",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查询时间",
                    align: _vm.tableConfig.align,
                    width: "180",
                    prop: "queryTime",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查询类型",
                    align: _vm.tableConfig.align,
                    "min-width": "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.queryLx == "物流查询"
                            ? _c("span", { staticClass: "blue" }, [
                                _vm._v(_vm._s(scope.row.queryLx)),
                              ])
                            : scope.row.queryLx == "防伪查询"
                            ? _c("span", { staticClass: "success" }, [
                                _vm._v(_vm._s(scope.row.queryLx)),
                              ])
                            : _c("span", [_vm._v(_vm._s(scope.row.queryLx))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查询者ID",
                    align: _vm.tableConfig.align,
                    "min-width": "180",
                    prop: "cxzId",
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.pageNo,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            staticStyle: { "text-align": "center" },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  disabled: _vm.excelOut_loading,
                  loading: _vm.excelOut_loading,
                },
                on: { click: _vm.excelQueryMapWlChanList },
              },
              [_vm._v("导出")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.Reset } }, [_vm._v("关 闭")]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }