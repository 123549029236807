var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-flow" },
    [
      _c(
        "div",
        { staticStyle: { padding: "10px 0" } },
        [
          _c(
            "el-steps",
            _vm._l(_vm.progress, function (item, index) {
              return _c("el-step", {
                key: index,
                attrs: {
                  title: item.nodeName,
                  status: item.isExectued == "1" ? "success" : "wait",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.list,
            fit: "",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
            "row-key": "pid",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "节点名称",
              width: "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: "审批人",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "userRole",
              label: "审批人角色",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "userOrg",
              label: "审批人组织",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "checkTime",
              label: "审批时间",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatTime")(row.checkTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "审批结果",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "auditmsg",
              label: "审批意见",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }