var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "活动创建",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "950px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "cardBox", staticStyle: { "margin-bottom": "0" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-steps",
                    { attrs: { active: _vm.active, "align-center": "" } },
                    [
                      _c("el-step", { attrs: { title: "基本设置" } }),
                      _vm._v(" "),
                      _c("el-step", { attrs: { title: "活动项设置" } }),
                      _vm._v(" "),
                      _c("el-step", { attrs: { title: "奖品设置" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "5px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: { "margin-bottom": "0" },
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _vm.active == 0
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "pageOneForm",
                          staticStyle: { margin: "10 auto" },
                          attrs: {
                            rules: _vm.pageOneRules,
                            model: _vm.pageOneForm,
                            "label-position": "right",
                            "label-width": "110px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称：", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "不能超过16个汉字",
                                  maxlength: "16",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageOneForm, "name", $$v)
                                  },
                                  expression: "pageOneForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动说明：",
                                prop: "actExplain",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: 3,
                                  "show-word-limit": "",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.actExplain,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageOneForm, "actExplain", $$v)
                                  },
                                  expression: "pageOneForm.actExplain",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "活动规则：", prop: "actRules" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "800",
                                  rows: 3,
                                  "show-word-limit": "",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.actRules,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageOneForm, "actRules", $$v)
                                  },
                                  expression: "pageOneForm.actRules",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 14 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动时间：",
                                        prop: "dataTimeRange",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "datetimerange",
                                          "range-separator": "-",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          format: "yyyy-MM-dd HH:mm:ss",
                                          disabled: _vm.showOnly,
                                          "picker-options": _vm.pickerOptions1,
                                        },
                                        model: {
                                          value: _vm.pageOneForm.dataTimeRange,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pageOneForm,
                                              "dataTimeRange",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageOneForm.dataTimeRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-divider", { staticClass: "middleDivider" }),
                          _vm._v(" "),
                          _c("h3", { staticClass: "elh3" }, [
                            _vm._v("活动范围设置"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动产品：", prop: "product" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tagWrapInput",
                                  class: { disabled: _vm.showOnly },
                                },
                                [
                                  _vm._l(
                                    _vm.pageOneForm.product,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: index <= 4,
                                              expression: "index<=4",
                                            },
                                          ],
                                          key: index,
                                          attrs: {
                                            type: "info",
                                            effect: "plain",
                                          },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.pageOneForm.product &&
                                  _vm.pageOneForm.product.length > 5
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "info",
                                            effect: "plain",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "+" +
                                                String(
                                                  _vm.pageOneForm.product
                                                    .length - 5
                                                )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-button", {
                                    staticStyle: { "min-width": "50px" },
                                    attrs: {
                                      icon: "el-icon-plus",
                                      disabled: _vm.showOnly,
                                    },
                                    on: { click: _vm.productListShow },
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.pageOneForm.v_batch &&
                          _vm.pageOneForm.v_batch.length > 0 &&
                          _vm.pageOneForm.actRConfigOrgan &&
                          _vm.pageOneForm.actRConfigOrgan[0].organType != 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动经销商：",
                                    prop: "h_batch",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tagWrapInput",
                                      class: { disabled: _vm.showOnly },
                                    },
                                    [
                                      _vm._l(
                                        _vm.pageOneForm.h_batch,
                                        function (item, index) {
                                          return _c(
                                            "el-tag",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: index <= 4,
                                                  expression: "index<=4",
                                                },
                                              ],
                                              key: index,
                                              attrs: {
                                                type: "info",
                                                effect: "plain",
                                              },
                                            },
                                            [_vm._v(_vm._s(item))]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.pageOneForm.h_batch &&
                                      _vm.pageOneForm.h_batch.length > 5
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                type: "info",
                                                effect: "plain",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "+" +
                                                    String(
                                                      _vm.pageOneForm.h_batch
                                                        .length - 5
                                                    )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-button", {
                                        staticStyle: { "min-width": "50px" },
                                        attrs: {
                                          icon: "el-icon-plus",
                                          disabled: _vm.showOnly,
                                        },
                                        on: { click: _vm.h_batchListShow },
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { gutter: 20 },
                            },
                            [
                              false
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动公众号：",
                                            prop: "wxOfficialId",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择",
                                                disabled: _vm.showOnly,
                                              },
                                              model: {
                                                value:
                                                  _vm.pageOneForm.wxOfficialId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.pageOneForm,
                                                    "wxOfficialId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pageOneForm.wxOfficialId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.wcList,
                                              function (item, wc) {
                                                return _c("el-option", {
                                                  key: wc,
                                                  attrs: {
                                                    label: item.officialName,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模板选择：",
                                        prop: "templateId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value: _vm.pageOneForm.templateId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pageOneForm,
                                                "templateId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pageOneForm.templateId",
                                          },
                                        },
                                        _vm._l(_vm.mbList, function (item, mb) {
                                          return _c("el-option", {
                                            key: mb,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                              disabled: _vm.showOnly,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 1,
                      expression: "active==1",
                    },
                  ],
                },
                [
                  _c(
                    "p",
                    { staticClass: "elh3", staticStyle: { "margin-top": "0" } },
                    [_vm._v("消费者限制")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "pageTwoForm",
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        rules: _vm.pageTwoFormRules,
                        model: _vm.pageTwoForm,
                        "label-position": "right",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "每人参与次数:",
                                    prop: "perPersonJoinTimes",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "only-number",
                                        rawName: "v-only-number",
                                        value: {
                                          max: 999999999,
                                          min: 0,
                                          precision: 0,
                                          set: _vm.pageTwoForm,
                                          name: "perPersonJoinTimes",
                                        },
                                        expression:
                                          "{ max:999999999,min:0,precision:0,set: pageTwoForm, name:'perPersonJoinTimes' }",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "9",
                                      placeholder: "请输入每人参与次数",
                                      disabled: _vm.showOnly,
                                    },
                                    model: {
                                      value: _vm.pageTwoForm.perPersonJoinTimes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageTwoForm,
                                          "perPersonJoinTimes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pageTwoForm.perPersonJoinTimes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "每人每天参与次数:",
                                    prop: "perPersonDayJoinTimes",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "only-number",
                                        rawName: "v-only-number",
                                        value: {
                                          max: 999999999,
                                          min: 0,
                                          precision: 0,
                                          set: _vm.pageTwoForm,
                                          name: "perPersonDayJoinTimes",
                                        },
                                        expression:
                                          "{ max:999999999,min:0,precision:0,set: pageTwoForm, name:'perPersonDayJoinTimes' }",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "9",
                                      placeholder: "请输入每人每天参与次数",
                                      disabled: _vm.showOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.pageTwoForm.perPersonDayJoinTimes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageTwoForm,
                                          "perPersonDayJoinTimes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pageTwoForm.perPersonDayJoinTimes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "每人中奖次数:",
                                    prop: "perPersonHitTimes",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "only-number",
                                        rawName: "v-only-number",
                                        value: {
                                          max: 999999999,
                                          min: 0,
                                          precision: 0,
                                          set: _vm.pageTwoForm,
                                          name: "perPersonHitTimes",
                                        },
                                        expression:
                                          "{ max:999999999,min:0,precision:0,set: pageTwoForm, name:'perPersonHitTimes' }",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "9",
                                      placeholder: "请输入每人中奖次数",
                                      disabled: _vm.showOnly,
                                    },
                                    model: {
                                      value: _vm.pageTwoForm.perPersonHitTimes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageTwoForm,
                                          "perPersonHitTimes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pageTwoForm.perPersonHitTimes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "每人每天中奖次数:",
                                    prop: "perPersonDayHitTimes",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "only-number",
                                        rawName: "v-only-number",
                                        value: {
                                          max: 999999999,
                                          min: 0,
                                          precision: 0,
                                          set: _vm.pageTwoForm,
                                          name: "perPersonDayHitTimes",
                                        },
                                        expression:
                                          "{ max:999999999,min:0,precision:0,set: pageTwoForm, name:'perPersonDayHitTimes' }",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "9",
                                      placeholder: "请输入每人每天中奖次数",
                                      disabled: _vm.showOnly,
                                    },
                                    model: {
                                      value:
                                        _vm.pageTwoForm.perPersonDayHitTimes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageTwoForm,
                                          "perPersonDayHitTimes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pageTwoForm.perPersonDayHitTimes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c("p", { staticClass: "elh3" }, [_vm._v("预警设置")]),
                      _vm._v(" "),
                      _vm.pageTwoForm.actHasWarning * 1 === 1 ||
                      _vm.pageTwoForm.actEndtimeWarning * 1 === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "接收邮箱：",
                                prop: "actWarningEmail",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "请输入接收预警邮箱，多个邮箱用,隔开",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageTwoForm.actWarningEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageTwoForm,
                                      "actWarningEmail",
                                      $$v
                                    )
                                  },
                                  expression: "pageTwoForm.actWarningEmail",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "labelCenter formRadio",
                          attrs: { prop: "actHasWarning" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "formTitle",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont iconyuandianzhong link",
                              }),
                              _vm._v(" 预警阀值\n            "),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "0",
                              disabled: _vm.showOnly,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.switchFun(1)
                              },
                            },
                            model: {
                              value: _vm.pageTwoForm.actHasWarning,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageTwoForm, "actHasWarning", $$v)
                              },
                              expression: "pageTwoForm.actHasWarning",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.pageTwoForm.actHasWarning,
                                  expression: "pageTwoForm.actHasWarning",
                                },
                              ],
                              staticClass: "formTip",
                              staticStyle: { "margin-left": "20px!important" },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                              _vm._v(
                                " 提示：开启后，当消费者已领取兑换的奖品数量/奖品总库存量<=预警阀值，将会给预\n              置邮箱发预警邮件\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.pageTwoForm.actHasWarning * 1 === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预警阀值：",
                                        prop: "actWarningThreshold",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "only-number",
                                              rawName: "v-only-number",
                                              value: {
                                                max: 99,
                                                min: 1,
                                                precision: 0,
                                                set: _vm.pageTwoForm,
                                                name: "actWarningThreshold",
                                              },
                                              expression:
                                                "{ max:99,min:1,precision:0,set: pageTwoForm, name:'actWarningThreshold' }",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.pageTwoForm
                                                .actWarningThreshold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pageTwoForm,
                                                "actWarningThreshold",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pageTwoForm.actWarningThreshold",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.pageTwoForm.actHasWarning * 1 === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预警频率：",
                                        prop: "actWarningRate",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.pageTwoForm.actWarningRate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pageTwoForm,
                                                "actWarningRate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pageTwoForm.actWarningRate",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "6小时",
                                              value: 360,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "12小时",
                                              value: 720,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "24小时",
                                              value: 1440,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "72小时",
                                              value: 2160,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "labelCenter formRadio",
                          attrs: { prop: "actHasWarning" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "formTitle",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont iconyuandianzhong link",
                              }),
                              _vm._v(" 预警频率\n            "),
                            ]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "0",
                              disabled: _vm.showOnly,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.switchFun(2)
                              },
                            },
                            model: {
                              value: _vm.pageTwoForm.actEndtimeWarning,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageTwoForm,
                                  "actEndtimeWarning",
                                  $$v
                                )
                              },
                              expression: "pageTwoForm.actEndtimeWarning",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "formTip",
                              staticStyle: { "margin-left": "20px!important" },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                              _vm._v(
                                " 提示：开启后，活动结束前N天发送邮件进行预警，将会给预\n              置邮箱发预警邮件\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.pageTwoForm.actEndtimeWarning * 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预警阀值：",
                                        prop: "actEndtimeWarningDay",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "only-number",
                                              rawName: "v-only-number",
                                              value: {
                                                max: 99,
                                                min: 1,
                                                precision: 0,
                                                set: _vm.pageTwoForm,
                                                name: "actEndtimeWarningDay",
                                              },
                                              expression:
                                                "{ max:99,min:1,precision:0,set: pageTwoForm, name:'actEndtimeWarningDay' }",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.pageTwoForm
                                                .actEndtimeWarningDay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pageTwoForm,
                                                "actEndtimeWarningDay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pageTwoForm.actEndtimeWarningDay",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("天"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.pageTwoForm.actEndtimeWarning * 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预警频率：",
                                        prop: "actEndtimeWarningRate",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.pageTwoForm
                                                .actEndtimeWarningRate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.pageTwoForm,
                                                "actEndtimeWarningRate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "pageTwoForm.actEndtimeWarningRate",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "6小时",
                                              value: 360,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "12小时",
                                              value: 720,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "24小时",
                                              value: 1440,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "72小时",
                                              value: 2160,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              [
                _vm.active == 2 && _vm.type * 1 === 1
                  ? _c(
                      "el-col",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "characterForm",
                            staticStyle: { margin: "10 auto" },
                            attrs: {
                              model: _vm.characterForm,
                              "label-position": "right",
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "集字玩法：",
                                  prop: "characterGathering",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "radio",
                                    attrs: { disabled: _vm.showOnly },
                                    on: { change: _vm.changeCharType },
                                    model: {
                                      value:
                                        _vm.characterForm.characterGathering,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.characterForm,
                                          "characterGathering",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "characterForm.characterGathering",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("集全兑奖"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("任意组合字兑奖"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-form-item", [
                              _c(
                                "span",
                                {
                                  staticClass: "formTitle",
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont iconyuandianzhong link",
                                  }),
                                  _vm._v(" 集字配置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 至少2个字，最多可设置6个字\n              "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.characterForm.wordList,
                              function (item, index) {
                                return _c(
                                  "el-row",
                                  { key: index, attrs: { gutter: 10 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 4, md: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "wordList." +
                                                index +
                                                ".wordcontent",
                                              "label-width": "0",
                                              rules: {
                                                required: true,
                                                message: "请输入",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "第" + (index + 1) + "个字",
                                                maxlength: "1",
                                                disabled: _vm.showOnly,
                                              },
                                              model: {
                                                value: item.wordcontent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "wordcontent",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.wordcontent",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 6, md: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "wordList." +
                                                index +
                                                ".probability",
                                              "label-width": "0",
                                              rules: {
                                                required: true,
                                                message: "请输入",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "出现概率",
                                                  disabled: _vm.showOnly,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.checCharacterPercent(
                                                      "probability",
                                                      item.probability,
                                                      index,
                                                      100
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  input: function ($event) {
                                                    return _vm.checCharacterPercent(
                                                      "probability",
                                                      item.probability,
                                                      index,
                                                      100
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.probability,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "probability",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.probability",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("%")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 6, md: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "wordList." +
                                                index +
                                                ".wordnum",
                                              "label-width": "0",
                                              rules: {
                                                required: true,
                                                message: "请输入",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "数量",
                                                disabled: _vm.showOnly,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.checkCharactertNum(
                                                    "wordnum",
                                                    item.wordnum,
                                                    index
                                                  )
                                                },
                                              },
                                              nativeOn: {
                                                input: function ($event) {
                                                  return _vm.checkCharactertNum(
                                                    "wordnum",
                                                    item.wordnum,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.wordnum,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "wordnum", $$v)
                                                },
                                                expression: "item.wordnum",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 6, md: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "formRadio",
                                            attrs: {
                                              prop: "isRare",
                                              "label-width": "0",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: index,
                                                  disabled: _vm.showOnly,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.clickRadio(index)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.characterForm.isRare,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.characterForm,
                                                      "isRare",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "characterForm.isRare",
                                                },
                                              },
                                              [_vm._v("稀缺字")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-button", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.characterForm.wordList
                                                      .length > 2 &&
                                                    !_vm.showOnly,
                                                  expression:
                                                    "characterForm.wordList.length>2&&!showOnly",
                                                },
                                              ],
                                              staticStyle: { float: "right" },
                                              attrs: {
                                                type: "danger",
                                                size: "mini",
                                                icon: "el-icon-delete",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delWord(index)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { attrs: { gutter: 10 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "未抽中",
                                          prop: "wordUsual",
                                          "label-width": "70px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            value: "谢谢惠顾",
                                            placeholder: "请输入",
                                            type: "text",
                                            disabled: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "出现概率",
                                          prop: "probabilityUsual",
                                          "label-width": "70px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.probabilityZ,
                                              callback: function ($$v) {
                                                _vm.probabilityZ = $$v
                                              },
                                              expression: "probabilityZ",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "isRare",
                                          "label-width": "0",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.characterForm.wordList
                                                  .length < 6 && !_vm.showOnly,
                                              expression:
                                                "characterForm.wordList.length<6&&!showOnly",
                                            },
                                          ],
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: { click: _vm.addWord },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "" } },
                                      [
                                        _c("div", { staticClass: "formTip" }, [
                                          _c("p", [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                            }),
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(_vm.showOnly) +
                                                " 最多可录入6个汉字\n                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "当字的数量为0时，用户再抽中这个字将转为谢谢惠顾"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.characterForm.isRare !== "",
                                    expression: "characterForm.isRare!==''",
                                  },
                                ],
                                attrs: { "label-width": "140px" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "formTitle",
                                    attrs: { slot: "label" },
                                    slot: "label",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont iconyuandianzhong link",
                                    }),
                                    _vm._v(" 稀缺字配置\n              "),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.characterForm.isRare !== "",
                                    expression: "characterForm.isRare!==''",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { lg: 18, md: 20, sm: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "每天获得限制：",
                                          prop: "toplimitDay",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "only-number",
                                              rawName: "v-only-number",
                                              value: {
                                                max: 9999999,
                                                min: 0,
                                                precision: 0,
                                                set: _vm.characterForm,
                                                name: "toplimitDay",
                                              },
                                              expression:
                                                "{max:9999999, min:0,precision:0,set: characterForm, name:'toplimitDay' }",
                                            },
                                          ],
                                          attrs: {
                                            maxlength: "7",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.characterForm.toplimitDay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.characterForm,
                                                "toplimitDay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "characterForm.toplimitDay",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.characterForm.isRare !== "",
                                    expression: "characterForm.isRare!==''",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { lg: 18, md: 20, sm: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "每人获得限制：",
                                          prop: "toplimitPerson",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "only-number",
                                              rawName: "v-only-number",
                                              value: {
                                                max: 9999999,
                                                min: 0,
                                                precision: 0,
                                                set: _vm.characterForm,
                                                name: "toplimitPerson",
                                              },
                                              expression:
                                                "{max:9999999, min:0,precision:0,set: characterForm, name:'toplimitPerson' }",
                                            },
                                          ],
                                          attrs: {
                                            maxlength: "7",
                                            disabled: _vm.showOnly,
                                          },
                                          model: {
                                            value:
                                              _vm.characterForm.toplimitPerson,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.characterForm,
                                                "toplimitPerson",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "characterForm.toplimitPerson",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "140px" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "formTitle",
                                    attrs: { slot: "label" },
                                    slot: "label",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont iconyuandianzhong link",
                                    }),
                                    _vm._v(" 奖品配置\n              "),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.characterForm.prizeList,
                              function (item, index) {
                                return _c(
                                  "el-row",
                                  { key: "a" + index },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                _vm._f("intToChinese")(index),
                                              prop:
                                                "prizeList[" +
                                                index +
                                                "].words",
                                              rules: {
                                                required: true,
                                                message: "请选择",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                staticClass: "checkbox",
                                                attrs: {
                                                  disabled:
                                                    _vm.showOnly ||
                                                    _vm.characterForm
                                                      .characterGathering === 1,
                                                },
                                                model: {
                                                  value: item.words,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "words", $$v)
                                                  },
                                                  expression: "item.words",
                                                },
                                              },
                                              _vm._l(
                                                _vm.characterForm.wordList,
                                                function (n, num1) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: "b" + num1,
                                                      attrs: { label: num1 },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(n.wordcontent)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "奖品：" } },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 10 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "0",
                                                    },
                                                    attrs: {
                                                      lg: 7,
                                                      md: 8,
                                                      sm: 11,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "prizeList[" +
                                                            index +
                                                            "].type",
                                                          rules: {
                                                            required: true,
                                                            message: "请选择",
                                                            trigger: "blur",
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                "请选择",
                                                              disabled:
                                                                _vm.showOnly,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.jChangeType(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value: item.type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.type",
                                                            },
                                                          },
                                                          [
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: "奖品",
                                                                value: "1",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("el-option", {
                                                              attrs: {
                                                                label: "积分",
                                                                value: "2",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    attrs: {
                                                      lg: 10,
                                                      md: 8,
                                                      sm: 12,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.type != "2",
                                                            expression:
                                                              "item.type!='2'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "prizeList[" +
                                                            index +
                                                            "].prizeId",
                                                          rules: {
                                                            required:
                                                              item.type != "2",
                                                            message:
                                                              "请选择奖品",
                                                            trigger: "blur",
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                "请选择奖品",
                                                              disabled:
                                                                _vm.showOnly,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.jzChange(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.prizeId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "prizeId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.prizeId",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.pList,
                                                            function (obj, p) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: p,
                                                                  attrs: {
                                                                    label:
                                                                      obj.alias,
                                                                    value:
                                                                      obj.id,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              item.type == "2",
                                                            expression:
                                                              "item.type=='2'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "prizeList[" +
                                                            index +
                                                            "].integral",
                                                          rules: {
                                                            required:
                                                              item.type == "2",
                                                            message:
                                                              "请输入积分",
                                                            trigger: "blur",
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.type ==
                                                                "2",
                                                              expression:
                                                                "item.type=='2'",
                                                            },
                                                            {
                                                              name: "only-number",
                                                              rawName:
                                                                "v-only-number",
                                                              value: {
                                                                min: 0,
                                                                precision: 0,
                                                                set: item,
                                                                name: "integral",
                                                              },
                                                              expression:
                                                                "{ min:0,precision:0,set: item, name:'integral' }",
                                                            },
                                                          ],
                                                          attrs: {
                                                            disabled:
                                                              _vm.showOnly,
                                                          },
                                                          model: {
                                                            value:
                                                              item.integral,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "integral",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.integral",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "奖品数量：",
                                              prop:
                                                "prizeList[" +
                                                index +
                                                "].amount",
                                              rules: {
                                                required: true,
                                                message: "请输入",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "only-number",
                                                  rawName: "v-only-number",
                                                  value: {
                                                    min: 0,
                                                    precision: 0,
                                                    set: item,
                                                    name: "amount",
                                                  },
                                                  expression:
                                                    "{ min:0,precision:0,set: item, name:'amount' }",
                                                },
                                              ],
                                              attrs: { disabled: _vm.showOnly },
                                              model: {
                                                value: item.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "amount", $$v)
                                                },
                                                expression: "item.amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "兑换有效期：",
                                              prop:
                                                "prizeList[" +
                                                index +
                                                "].dataRange",
                                              rules: {
                                                required: true,
                                                message: "请输入",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "-",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                disabled: _vm.showOnly,
                                                format: "yyyy-MM-dd HH:mm:ss",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                "picker-options":
                                                  _vm.pickerOptions1,
                                              },
                                              model: {
                                                value: item.dataRange,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "dataRange",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.dataRange",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "奖品预算：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.showOnly,
                                                value:
                                                  item.costprize *
                                                  (item.amount * 1),
                                                readonly: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 18, md: 20, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "奖品说明：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                maxlength: "50",
                                                "show-word-limit": "",
                                                rows: 3,
                                                disabled: _vm.showOnly,
                                              },
                                              model: {
                                                value: item.prizeExplain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "prizeExplain",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.prizeExplain",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { lg: 6, md: 4, sm: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "isRare",
                                              "label-width": "0",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.characterForm.prizeList
                                                      .length > 1 &&
                                                    !_vm.showOnly,
                                                  expression:
                                                    "characterForm.prizeList.length>1&&!showOnly",
                                                },
                                              ],
                                              staticStyle: { float: "right" },
                                              attrs: {
                                                type: "danger",
                                                size: "mini",
                                                icon: "el-icon-delete",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delPrize(index)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "isRare",
                                              "label-width": "0",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.characterForm.prizeList
                                                      .length < 10 &&
                                                    _vm.characterForm.prizeList
                                                      .length ==
                                                      index + 1 &&
                                                    _vm.characterForm
                                                      .characterGathering !=
                                                      1 &&
                                                    !_vm.showOnly,
                                                  expression:
                                                    "characterForm.prizeList.length<10&&characterForm.prizeList.length == index+1&&characterForm.characterGathering!=1&&!showOnly",
                                                },
                                              ],
                                              staticStyle: { float: "right" },
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                icon: "el-icon-plus",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addPrize(index)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动预算：" } },
                              [
                                _c("el-col", {
                                  staticClass: "link checkbox",
                                  attrs: { disabled: _vm.showOnly },
                                  domProps: {
                                    textContent: _vm._s(_vm.wordTotal),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.active == 2 && _vm.type * 1 === 2,
                        expression: "active==2&&type*1===2",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "codeForm",
                        staticClass: "topLabelForm",
                        staticStyle: { margin: "10 auto" },
                        attrs: {
                          model: _vm.codeForm,
                          "label-position": "top",
                          "label-width": "120px",
                          rules: _vm.codeFormRules,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "奖品设置：",
                              prop: "isFocusPublic",
                            },
                          },
                          _vm._l(_vm.codeForm.prizeList, function (item, n) {
                            return _c(
                              "el-row",
                              {
                                key: n,
                                staticStyle: { "margin-bottom": "18px" },
                                attrs: { gutter: 20, type: "flex" },
                              },
                              [
                                item.prizeType != 5000
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 5 },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              disabled: _vm.showOnly,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.cChangeType(
                                                  $event,
                                                  n
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.type,
                                              callback: function ($$v) {
                                                _vm.$set(item, "type", $$v)
                                              },
                                              expression: "item.type",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "奖品",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "积分",
                                                value: "2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 5 },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "其他",
                                            readonly: "",
                                            disabled: _vm.showOnly,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                item.prizeType != 5000
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.type != "2",
                                                expression: "item.type!='2'",
                                              },
                                            ],
                                            attrs: {
                                              "label-width": "0",
                                              prop:
                                                "prizeList." + n + ".prizeId",
                                              rules: {
                                                required: item.type != "2",
                                                message: "请选择奖品",
                                                trigger: "change",
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.type != "2",
                                                    expression:
                                                      "item.type!='2'",
                                                  },
                                                ],
                                                attrs: {
                                                  placeholder: "请选择奖品",
                                                  disabled: _vm.showOnly,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.cPChange(
                                                      $event,
                                                      n
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.prizeId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "prizeId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.prizeId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.pList,
                                                function (obj, p) {
                                                  return _c("el-option", {
                                                    key: p,
                                                    attrs: {
                                                      label: obj.alias,
                                                      value: obj.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.type == "2",
                                                expression: "item.type=='2'",
                                              },
                                            ],
                                            attrs: {
                                              "label-width": "0",
                                              prop:
                                                "prizeList." + n + ".integral",
                                              rules: {
                                                required: item.type == "2",
                                                message: "请输入积分",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "only-number",
                                                  rawName: "v-only-number",
                                                  value: {
                                                    min: 0,
                                                    precision: 0,
                                                    set: item,
                                                    name: "integral",
                                                  },
                                                  expression:
                                                    "{ min:0,precision:0,set: item, name:'integral' }",
                                                },
                                              ],
                                              attrs: { disabled: _vm.showOnly },
                                              model: {
                                                value: item.integral,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "integral",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.integral",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 6 },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: _vm.showOnly },
                                          model: {
                                            value: _vm.codeForm.codeUsual,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.codeForm,
                                                "codeUsual",
                                                $$v
                                              )
                                            },
                                            expression: "codeForm.codeUsual",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                item.prizeType != 5000
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "0",
                                              prop: "prizeList." + n + ".scale",
                                              rules: {
                                                required: true,
                                                message: "请输入概率",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "概率",
                                                  disabled: _vm.showOnly,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.checCodePercent(
                                                      "scale",
                                                      item.scale,
                                                      n,
                                                      100
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  input: function ($event) {
                                                    return _vm.checCodePercent(
                                                      "scale",
                                                      item.scale,
                                                      n,
                                                      100
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.scale,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "scale", $$v)
                                                  },
                                                  expression: "item.scale",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("%")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 6 },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.probabilityC,
                                              callback: function ($$v) {
                                                _vm.probabilityC = $$v
                                              },
                                              expression: "probabilityC",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                item.prizeType != 5000
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 5 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "0",
                                              prop:
                                                "prizeList." + n + ".amount",
                                              rules: {
                                                required: true,
                                                message: "请输入数量",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "数量",
                                                disabled: _vm.showOnly,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.checkCodetNum(
                                                    "amount",
                                                    item.amount,
                                                    n
                                                  )
                                                },
                                              },
                                              nativeOn: {
                                                input: function ($event) {
                                                  return _vm.checkCodetNum(
                                                    "amount",
                                                    item.amount,
                                                    n
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "amount", $$v)
                                                },
                                                expression: "item.amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        attrs: { md: 5 },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "only-number",
                                              rawName: "v-only-number",
                                              value: {
                                                min: 0,
                                                precision: 0,
                                                set: _vm.codeForm,
                                                name: "num",
                                              },
                                              expression:
                                                "{ min:0,precision:0,set: codeForm, name:'num' }",
                                            },
                                          ],
                                          attrs: { disabled: true },
                                          model: {
                                            value: _vm.codeForm.num,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.codeForm, "num", $$v)
                                            },
                                            expression: "codeForm.num",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                item.prizeType != 5000
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        staticStyle: {
                                          width: "35px",
                                          "margin-left": "20px",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.codeForm.prizeList.length >
                                                  2 && !_vm.showOnly,
                                              expression:
                                                "codeForm.prizeList.length>2&&!showOnly",
                                            },
                                          ],
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                            icon: "el-icon-delete",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delCodePrize(n)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "inlineItem",
                                        staticStyle: {
                                          width: "35px",
                                          "margin-left": "20px",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.showOnly,
                                              expression: "!showOnly",
                                            },
                                          ],
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-plus",
                                            circle: "",
                                          },
                                          on: { click: _vm.addCodePrize },
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { label: "积分设置：" },
                          },
                          [
                            _c(
                              "el-row",
                              {
                                staticStyle: { "margin-bottom": "18px" },
                                attrs: { gutter: 10 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "inlineItem",
                                    attrs: { md: 5 },
                                  },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "only-number",
                                          rawName: "v-only-number",
                                          value: {
                                            min: 0,
                                            precision: 0,
                                            name: "actJf",
                                          },
                                          expression:
                                            "{ min:0,precision:0, name:'actJf' }",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "",
                                        disabled: _vm.showOnly,
                                      },
                                      model: {
                                        value: _vm.codeForm.actJf,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.codeForm, "actJf", $$v)
                                        },
                                        expression: "codeForm.actJf",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { color: "#969FAA" },
                                    attrs: { md: 15 },
                                  },
                                  [_vm._v("消费者扫码即获得对应积分")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        staticStyle: { "margin-top": "-10px" },
                        attrs: { "label-position": "left" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { label: "活动预算:" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "hdysWrap",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _c("img", {
                                  staticClass: "hdys",
                                  attrs: { src: require("@/images/hdys.png") },
                                }),
                                _vm._v(" 活动预算:\n              "),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#337ab7" } }, [
                              _vm._v(_vm._s(_vm.codeTotal)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.active == 2 && _vm.type * 1 === 3
                  ? _c(
                      "el-col",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "panForm",
                            staticStyle: { margin: "10 auto" },
                            attrs: {
                              model: _vm.panForm,
                              "label-position": "right",
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "转盘样式：", prop: "size" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "radio",
                                    attrs: { disabled: _vm.showOnly },
                                    on: { change: _vm.changePan },
                                    model: {
                                      value: _vm.panForm.size,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.panForm, "size", $$v)
                                      },
                                      expression: "panForm.size",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 4 } }, [
                                      _vm._v("4等分"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 6 } }, [
                                      _vm._v("6等分"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 8 } }, [
                                      _vm._v("8等分"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "size" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "panWrap" },
                                  [
                                    _vm._l(
                                      _vm.panForm.tableData.length,
                                      function (i, index) {
                                        return _c("div", {
                                          key: "pan1" + index,
                                          staticClass: "panPart",
                                        })
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.panForm.tableData,
                                      function (i, index) {
                                        return _c(
                                          "p",
                                          {
                                            key: "text" + index,
                                            staticClass: "panText",
                                          },
                                          [_vm._v(" " + _vm._s(index + 1))]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "panBtn" }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.panForm.tableData },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "prizeLevel",
                                    label: "转盘位置",
                                    width: "90",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "奖品类型" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".type",
                                                  rules: {
                                                    required: true,
                                                    message: "请选择",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      disabled:
                                                        scope.row.prizeLevel ==
                                                          1 || _vm.showOnly,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.pChangeType(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.type",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "奖品",
                                                        value: "1",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "谢谢惠顾",
                                                        value: "3",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "积分",
                                                        value: "2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3260169077
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "奖品" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.type != "2",
                                                    expression:
                                                      "scope.row.type!='2'",
                                                  },
                                                ],
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".prizeId",
                                                  rules: {
                                                    required:
                                                      scope.row.type == "1",
                                                    message: "请选择",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        scope.row.type == "3",
                                                      expression:
                                                        "scope.row.type=='3'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    readonly: "",
                                                    disabled: _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.name",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          scope.row.type == "1",
                                                        expression:
                                                          "scope.row.type=='1'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      placeholder: "请选择奖品",
                                                      disabled: _vm.showOnly,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.pPChange(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.prizeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "prizeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.prizeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.pList,
                                                    function (obj, p) {
                                                      return _c("el-option", {
                                                        key: p,
                                                        attrs: {
                                                          label: obj.alias,
                                                          value: obj.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.type == "2",
                                                    expression:
                                                      "scope.row.type=='2'",
                                                  },
                                                ],
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".integral",
                                                  rules: {
                                                    required:
                                                      scope.row.type == "2",
                                                    message: "请输入积分",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "only-number",
                                                      rawName: "v-only-number",
                                                      value: {
                                                        min: 0,
                                                        precision: 0,
                                                        set: scope.row,
                                                        name: "integral",
                                                      },
                                                      expression:
                                                        "{ min:0,precision:0,set: scope.row, name:'integral' }",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.integral,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "integral",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.integral",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1041029093
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "转盘图片", width: "110" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("img", {
                                              staticClass: "tableImg",
                                              attrs: {
                                                src: scope.row.img,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3470219417
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "概率" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".scale",
                                                  rules: {
                                                    required:
                                                      scope.$index !== 0,
                                                    message: "请输入",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                scope.row.prizeLevel == 1
                                                  ? _c(
                                                      "el-input",
                                                      {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            _vm.probabilityP,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.probabilityP =
                                                              $$v
                                                          },
                                                          expression:
                                                            "probabilityP",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v("%")]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          placeholder: "",
                                                          disabled:
                                                            _vm.showOnly,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkPanPercent(
                                                              "scale",
                                                              _vm.panForm
                                                                .tableData[
                                                                scope.$index
                                                              ].scale,
                                                              scope.$index,
                                                              100
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkPanPercent(
                                                              "scale",
                                                              _vm.panForm
                                                                .tableData[
                                                                scope.$index
                                                              ].scale,
                                                              scope.$index,
                                                              100
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.scale,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "scale",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.scale",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v("%")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3911624977
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "数量" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".amount",
                                                  rules: {
                                                    required: true,
                                                    message: "请输入",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "only-number",
                                                      rawName: "v-only-number",
                                                      value: {
                                                        min: 0,
                                                        precision: 0,
                                                        set: scope.row,
                                                        name: "amount",
                                                      },
                                                      expression:
                                                        "{ min:0,precision:0,set: scope.row, name:'amount' }",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "",
                                                    disabled:
                                                      scope.row.type == 3 ||
                                                      _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.amount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1805927377
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "预算(元)" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.type != 1
                                                    ? 0
                                                    : scope.row.costprize *
                                                        scope.row.amount
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1264856895
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动预算：" } },
                              [
                                _c("el-col", {
                                  staticClass: "link checkbox",
                                  domProps: {
                                    textContent: _vm._s(_vm.panTotal),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.active == 2 && _vm.type * 1 === 4
                  ? _c(
                      "el-col",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "panForm",
                            staticStyle: { margin: "10 auto" },
                            attrs: {
                              model: _vm.panForm,
                              "label-position": "right",
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "size" } },
                              [
                                _c("div", { staticClass: "jiuWrap" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.panForm.tableData,
                                      function (i, index) {
                                        return _c(
                                          "li",
                                          { key: "text" + index },
                                          [_vm._v(" " + _vm._s(index + 1))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.panForm.tableData },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "prizeLevel",
                                    label: "转盘位置",
                                    width: "90",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "奖品类型" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".type",
                                                  rules: {
                                                    required: true,
                                                    message: "请选择",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      disabled: _vm.showOnly,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.pChangeType(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.type",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "奖品",
                                                        value: "1",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "谢谢惠顾",
                                                        value: "3",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "积分",
                                                        value: "2",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3947427078
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "奖品" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.type != "2",
                                                    expression:
                                                      "scope.row.type!='2'",
                                                  },
                                                ],
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".prizeId",
                                                  rules: {
                                                    required:
                                                      scope.row.type == "1",
                                                    message: "请选择",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        scope.row.type == "3",
                                                      expression:
                                                        "scope.row.type=='3'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    readonly: "",
                                                    disabled: _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.name",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          scope.row.type == "1",
                                                        expression:
                                                          "scope.row.type=='1'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      placeholder: "请选择奖品",
                                                      disabled: _vm.showOnly,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.pPChange(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.prizeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "prizeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.prizeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.pList,
                                                    function (obj, p) {
                                                      return _c("el-option", {
                                                        key: p,
                                                        attrs: {
                                                          label: obj.alias,
                                                          value: obj.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.type == "2",
                                                    expression:
                                                      "scope.row.type=='2'",
                                                  },
                                                ],
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".integral",
                                                  rules: {
                                                    required:
                                                      scope.row.type == "2",
                                                    message: "请输入积分",
                                                    trigger: "change",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "only-number",
                                                      rawName: "v-only-number",
                                                      value: {
                                                        min: 0,
                                                        precision: 0,
                                                        set: scope.row,
                                                        name: "integral",
                                                      },
                                                      expression:
                                                        "{ min:0,precision:0,set: scope.row, name:'integral' }",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.integral,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "integral",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.integral",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1041029093
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "转盘图片", width: "110" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("img", {
                                              staticClass: "tableImg",
                                              attrs: {
                                                src: scope.row.img,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3470219417
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "概率" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".scale",
                                                  rules: {
                                                    required:
                                                      scope.$index !== 0,
                                                    message: "请输入",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                scope.row.prizeLevel == 1
                                                  ? _c(
                                                      "el-input",
                                                      {
                                                        attrs: { disabled: "" },
                                                        model: {
                                                          value:
                                                            _vm.probabilityP,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.probabilityP =
                                                              $$v
                                                          },
                                                          expression:
                                                            "probabilityP",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v("%")]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          placeholder: "",
                                                          disabled:
                                                            _vm.showOnly,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkPanPercent(
                                                              "scale",
                                                              _vm.panForm
                                                                .tableData[
                                                                scope.$index
                                                              ].scale,
                                                              scope.$index,
                                                              100
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.checkPanPercent(
                                                              "scale",
                                                              _vm.panForm
                                                                .tableData[
                                                                scope.$index
                                                              ].scale,
                                                              scope.$index,
                                                              100
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.scale,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "scale",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.scale",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v("%")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3911624977
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "数量" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "tableItem",
                                                attrs: {
                                                  "label-width": "0",
                                                  prop:
                                                    "tableData." +
                                                    scope.$index +
                                                    ".amount",
                                                  rules: {
                                                    required: true,
                                                    message: "请输入",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "only-number",
                                                      rawName: "v-only-number",
                                                      value: {
                                                        min: 0,
                                                        precision: 0,
                                                        set: scope.row,
                                                        name: "amount",
                                                      },
                                                      expression:
                                                        "{ min:0,precision:0,set: scope.row, name:'amount' }",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "",
                                                    disabled:
                                                      scope.row.type == 3 ||
                                                      _vm.showOnly,
                                                  },
                                                  model: {
                                                    value: scope.row.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "amount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.amount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1805927377
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "type", label: "预算(元)" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.type != 1
                                                    ? 0
                                                    : scope.row.costprize *
                                                        scope.row.amount
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1264856895
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动预算：" } },
                              [
                                _c("el-col", {
                                  staticClass: "link checkbox",
                                  domProps: {
                                    textContent: _vm._s(_vm.panTotal),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 0,
                  expression: "active !==0",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.prev },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 2,
                  expression: "active !==2",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.next },
            },
            [_vm._v("下一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2 && !_vm.showOnly,
                  expression: "active ===2&&!showOnly",
                },
              ],
              staticClass: "normalBtn",
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.subLoading,
              },
              on: { click: _vm.finish },
            },
            [_vm._v("完成")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.v_batcheVisibleShow
        ? _c("Zorgan", {
            ref: "v_batchVisible",
            attrs: {
              "a-id": _vm.pageOneForm.v_productIdList,
              "old-list": _vm.pageOneForm.v_batchList,
            },
            on: {
              "update:aId": function ($event) {
                return _vm.$set(_vm.pageOneForm, "v_productIdList", $event)
              },
              "update:a-id": function ($event) {
                return _vm.$set(_vm.pageOneForm, "v_productIdList", $event)
              },
              fn: _vm.v_setBatch,
              close: function ($event) {
                _vm.v_batcheVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.h_batcheVisibleShow
        ? _c("Horgan", {
            ref: "h_batchVisible",
            attrs: {
              "a-id": _vm.pageOneForm.h_productIdList,
              "old-list": _vm.pageOneForm.h_batchList,
              newdata: _vm.pageOneForm.v_batcheNos,
            },
            on: {
              "update:aId": function ($event) {
                return _vm.$set(_vm.pageOneForm, "h_productIdList", $event)
              },
              "update:a-id": function ($event) {
                return _vm.$set(_vm.pageOneForm, "h_productIdList", $event)
              },
              fn: _vm.h_setBatch,
              close: function ($event) {
                _vm.h_batcheVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productVisibleShow
        ? _c("ProDialog", {
            ref: "productVisible",
            attrs: {
              "pro-sel-visible": _vm.productVisibleShow,
              data: _vm.pageOneForm.productList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageOneForm, "productList", $event)
              },
              change: _vm.setProduct,
              close: function ($event) {
                _vm.productVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.batcheVisibleShow
        ? _c("BatchDialog", {
            ref: "batchVisible",
            attrs: {
              "a-id": _vm.pageOneForm.productIdList,
              "old-list": _vm.pageOneForm.batcheNos,
            },
            on: {
              "update:aId": function ($event) {
                return _vm.$set(_vm.pageOneForm, "productIdList", $event)
              },
              "update:a-id": function ($event) {
                return _vm.$set(_vm.pageOneForm, "productIdList", $event)
              },
              fn: _vm.setBatch,
              close: function ($event) {
                _vm.batcheVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.PolicyDialog
        ? _c("PolicyDialog", {
            attrs: { "pro-sel-visible": _vm.PolicyDialog },
            on: {
              change: _vm.Policylist,
              close: function ($event) {
                _vm.PolicyDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }