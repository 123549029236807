var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _vm.auditType == "N"
            ? _c(
                "div",
                [
                  _c("el-row", { staticClass: "title" }, [
                    _vm._v("\n        —— 排产信息 ——\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "政府追溯:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.traceCodeName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "采集箱码:" } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.detailinfo.boxCollect == "1"
                                      ? "是"
                                      : _vm.detailinfo.boxCollect == "0"
                                      ? "否"
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排产车间:" } },
                            [
                              _vm.storeList.length > 0
                                ? _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.detailinfo.shopCodes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailinfo,
                                            "shopCodes",
                                            $$v
                                          )
                                        },
                                        expression: "detailinfo.shopCodes",
                                      },
                                    },
                                    _vm._l(_vm.storeList, function (el, i) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: i,
                                          attrs: { label: el.factoryCode },
                                        },
                                        [_vm._v(_vm._s(el.factoryName))]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排产数量:" } },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.detailinfo.scheduleBottleSum)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", { staticClass: "title" }, [
                    _vm._v("\n        —— 单据信息 ——\n      "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品编码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品名称:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规格型号:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.productModel)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "系数:" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.detailinfo.coefficient)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "组数:" } }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.detailinfo.scheduleChestSum)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "销售数量:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.backBottleSum)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户代码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.custCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户名称:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.channelName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "区域代码:" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.detailinfo.areaCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-row", { staticClass: "title" }, [
            _vm._v("\n      —— 审核 ——\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果:", prop: "state" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.scanData.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "state", $$v)
                            },
                            expression: "scanData.state",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "审核通过", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "审核拒绝", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "原因备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          placeholder: "请输入备注说明",
                        },
                        model: {
                          value: _vm.scanData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "remark", $$v)
                          },
                          expression: "scanData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("取 消"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }