var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: {
              model: _vm.queryParams,
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员昵称:", prop: "nickName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.nickName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "nickName", $$v)
                                },
                                expression: "queryParams.nickName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户ID:", prop: "userId" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.userId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "userId", $$v)
                                },
                                expression: "queryParams.userId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "openid:", prop: "openId" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.openId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "openId", $$v)
                                },
                                expression: "queryParams.openId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "协议名称:",
                              prop: "agreementName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.agreementName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "agreementName",
                                    $$v
                                  )
                                },
                                expression: "queryParams.agreementName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "协议类型:",
                              prop: "agreementType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "全部" },
                                model: {
                                  value: _vm.queryParams.agreementType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "agreementType",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.agreementType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "登录协议", value: "1" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "签署时间:" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                clearable: false,
                              },
                              model: {
                                value: _vm.queryParams.rangeTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "rangeTime", $$v)
                                },
                                expression: "queryParams.rangeTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { staticStyle: { "padding-top": "23px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.getwechatQueryList },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.restCapitalList },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.formConfig.btnFollow
          ? _c("el-divider", { staticClass: "btnDivider" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "btnArea" }, [
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.wechatList,
              height: "400",
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "会员头像",
                "min-width": "100",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("el-image", {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: { src: row.userPortraitUrl, fit: "cover" },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "会员昵称",
                prop: "userNickname",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "用户ID",
                prop: "userId",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "openid",
                prop: "wxOpenId",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "签署场景",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.signScenario == "1"
                        ? _c("el-tag", [_vm._v("终端登录")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "协议名称",
                prop: "agreementName",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "协议类型",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.agreementType == "1"
                        ? _c("el-tag", [_vm._v("登录协议")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.agreementType == "2"
                        ? _c("el-tag", [_vm._v("开箱奖协议")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.agreementType == "3"
                        ? _c("el-tag", [_vm._v("BC联动协议")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "协议版本号",
                prop: "agreementVersion",
                "min-width": "120",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "签署时间",
                "min-width": "180",
                align: _vm.tableConfig.align,
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("textDate")(row.signTime)) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.queryParams.pageNo,
            limit: _vm.queryParams.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryParams, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryParams, "pageSize", $event)
            },
            pagination: _vm.getwechatQueryList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }