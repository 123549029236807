"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      dataForm: {
        dvyId: '',
        dvyFlowId: 0,
        dvyNames: [],
        orderNumber: 0
      },
      dataRule: {
        dvyFlowId: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init: function init(orderNumber, dvyId, dvyFlowId, yf) {
      var _this = this;
      this.visible = true;
      this.dataForm.orderNumber = orderNumber || '';
      this.dataForm.dvyId = dvyId || '';
      this.dataForm.dvyFlowId = dvyFlowId || '';
      (0, _wXintegralMall.delivery)().then(function (data) {
        _this.dataForm.dvyNames = data;
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _wXintegralMall.addDelivery)({
            orderNumber: _this2.dataForm.orderNumber,
            dvyId: _this2.dataForm.dvyId,
            dvyFlowId: _this2.dataForm.dvyFlowId
          }).then(function (data) {
            _this2.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this2.visible = false;
                _this2.$emit('refreshDataList');
              }
            });
          });
        }
      });
    }
  }
};
exports.default = _default;