"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _productDialog = _interopRequireDefault(require("./productDialog"));
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
var _outcodeDialog = _interopRequireDefault(require("./outcodeDialog"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: '',
  components: {
    productDialog: _productDialog.default,
    allotUnitSelect: _allotUnitSelect.default,
    outcodeDialog: _outcodeDialog.default
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      // miniUnit = num
      row.sumPlanAto = num;
      return num;
    }
  },
  props: {
    dialogVisible: Boolean,
    submitType: Number,
    billType: Number,
    otherTypeName: String,
    billNo: String
  },
  data: function data() {
    return {
      title: '添加退货入库',
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      returnOrgType: 1,
      TypeDataComs: {
        returnOrgList: [],
        returnOrgType: [{
          id: 1,
          name: '销售公司'
        }, {
          id: 2,
          name: '渠道商'
        }]
      },
      scanData: {
        inOrgId: '',
        inOrgType: '',
        inStoreId: '',
        otherTypeId: '',
        outOrgId: '',
        billNo: '',
        billSource: '',
        billType: 103,
        sumPlanAtoNum: '',
        sumPlanNum: '',
        tradeBillDetailAOS: [],
        relateNo: '',
        relateType: '',
        isFillBill: 0,
        otherTypeName: '',
        outOrgName: '',
        inOrgName: '',
        inStoreName: '',
        returnReason: '',
        NCNO: ''
      },
      // disabled: {
      //   num: false
      // },
      factoryList: [],
      plist: [],
      inOrg: {},
      storeTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }, {
        name: '经销商',
        id: 3
      }],
      rules: {
        billNo: [{
          required: true,
          message: '退货入库单号不能为空，请维护',
          trigger: 'change'
        }],
        otherTypeId: [{
          required: true,
          message: '请选择业务类型',
          trigger: 'change'
        }],
        inOrgName: [{
          required: true,
          message: '请选择收货单位',
          trigger: 'blur'
        }],
        outOrgName: [{
          required: true,
          message: '请选择发货单位',
          trigger: 'blur'
        }],
        inStoreId: [{
          required: true,
          message: '请选择入库库房',
          trigger: 'change'
        }],
        relateNo: [{
          required: true,
          message: '请选择关联单号',
          trigger: 'change'
        }]
      },
      typeVisible: false,
      unitType: '',
      unitShow: false,
      inOutType: '7',
      shouFaType: '1',
      disType: false,
      outcodeShow: false,
      disabled: true,
      options: [],
      showSelect: false
    };
  },
  // 监听
  watch: {
    dialogVisible: function dialogVisible(val) {
      this.getYwTypes();
      if (val) {
        if (this.submitType != 2) {
          this.showSelect = false;
          this.disType = false;
          this.scanData.otherTypeId = '';
          this.getCreateNum();
          this.title = '添加退货入库';
          this.plist = [];
          if (this.$refs['scanForm'] !== undefined) {
            this.$refs['scanForm'].resetFields();
          }
        } else {
          this.upDateShow();
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    upDateShow: function upDateShow() {
      var _this = this;
      var that = this;
      that.disType = true;
      this.title = '修改退货入库';
      // if (that.otherTypeName.indexOf('退货入库') >= 0) {
      //   that.showSelect = false
      // } else {
      //   that.showSelect = true
      // }
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }

      // 获取详细信息
      var param = that.billNo;
      that.scanData.billNo = that.billNo;
      (0, _index.getPeoductionDetail)({
        billNo: param,
        ncProductId: '',
        productId: ''
      }).then(function (res) {
        _this.mloading = true;
        if (res.data.code == 200) {
          var basic = res.data.data.tradeBillAllVO;
          if (basic.otherTypeCode == 'QLT_THRK') {
            that.showSelect = false;
          } else {
            that.showSelect = true;
          }
          var promsg = res.data.data.tradeProductVOS;
          that.plist = promsg.map(function (item) {
            if (isNaN(Number(item.planScanAtoNum))) {
              item.planScanAtoNum = 0;
            }
            if (isNaN(Number(item.planScanNum))) {
              item.planScanNum = 0;
            }
            var packNum = 1;
            var radio;
            if (item.packRadioDesc.indexOf('*') >= 0) {
              radio = item.packRadioDesc.split('*');
            } else {
              var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
              radio = item.packRadioDesc.match(regex)[0].match(/\d+/)[0];
            }

            // var radio = item.packRadioDesc.split('*')

            if (radio.length) {
              // radio.forEach(val => {
              //   packNum = packNum * val
              // })
              packNum = radio[radio.length - 1];
            }
            item.restNum = item.planScanAtoNum - item.planScanNum * packNum;
            return item;
          });
          that.scanData.otherTypeId = basic.otherTypeId;
          that.scanData.otherTypeName = basic.otherTypeName;
          that.scanData.inOrgName = basic.inOrgName;
          that.scanData.outOrgName = basic.outOrgName;
          that.scanData.inOrgId = basic.inOrgId;
          that.scanData.outOrgId = basic.outOrgId;
          that.scanData.inStoreId = basic.inStoreId;
          that.scanData.inStoreName = basic.inStoreName;
          that.scanData.relateNo = basic.relateNo;
          that.transFormData(promsg);
          that.scanData.billNo = basic.billNo;
          that.scanData.NCNO = basic.NCNO;
          that.scanData.billSource = basic.billSource;
          that.scanData.billType = basic.billType;
          that.scanData.billStatus = basic.billStatus;
          that.scanData.returnReason = basic.returnReason;

          // 入库库房
          that.getHouses(basic.inOrgId);
        } else {
          that.plist = [];
          that.transFormData(that.plist);
          that.scanData.billNo = '';
          that.scanData.billSource = '';
          that.scanData.billStatus = '';
          that.scanData.otherTypeId = '';
          that.scanData.inStoreId = '';
          that.scanData.inOrgId = '';
          that.scanData.sumPlanAtoNum = '';
          that.scanData.sumPlanNum = '';
          that.scanData.tradeBillDetailAOS = [];
          that.$message.error(res.data.msg);
        }
        _this.mloading = false;
      });
    },
    // 自动生成入库单号
    getCreateNum: function getCreateNum() {
      var _this2 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        }
        _this2.scanData.billNo = res.data.data;
      });
    },
    // 选择关联单号
    searchRelateNo: function searchRelateNo() {
      var that = this;
      var otherTypeId = that.scanData.otherTypeId;
      if (otherTypeId == '') {
        that.$message.error('请选择业务类型');
      } else {
        that.outcodeShow = true;
      }
    },
    colseCodeVisible: function colseCodeVisible() {
      var that = this;
      that.outcodeShow = false;
    },
    fecthNo: function fecthNo(val) {
      var that = this;
      console.log(val, '选择单位');
      that.scanData.relateNo = val.billNo;
      that.scanData.inOrgName = val.inOrgName;
      that.scanData.inOrgId = val.inOrgId;
      that.scanData.outOrgName = val.outOrgName;
      that.scanData.outOrgId = val.outOrgId;
      that.scanData.inStoreId = '';
      that.getHouses(val.inOrgId);
      that.getProducts(val.billNo);
    },
    // 根据关联订单号获取产品
    getProducts: function getProducts(no) {
      var that = this;
      (0, _index.getProductsByNo)(no).then(function (res) {
        if (res.data.code == 200) {
          var pros = res.data.data.tradeProductVOS;
          that.plist = [];
          if (pros.length) {
            for (var i = 0; i < pros.length; i++) {
              var item = pros[i];
              if (Number(item.realScanAtoNum) > 0) {
                var obj = {};
                obj.packUnit = item.packUnit;
                obj.planScanAtoNum = item.realScanAtoNum;
                obj.planScanNum = item.realScanNum;
                obj.prodBatchNo = item.prodBatchNo;
                obj.productId = item.productId;
                obj.productName = item.productName;
                obj.productCode = item.productCode;
                obj.packRadioDesc = item.packRadioDesc;
                that.plist.push(obj);
              }
            }
          }
        }
      });
    },
    // 数据转换
    transFormData: function transFormData(rsData) {
      var that = this;
      var planScanAtoNum = 0;
      var planScanNum = 0;
      that.scanData.tradeBillDetailAOS = [];
      if (rsData.length > 0) {
        rsData.forEach(function (item) {
          var obj = {};
          planScanAtoNum += Number(item.planScanAtoNum);
          planScanNum += Number(item.planScanNum);
          obj.billNo = that.scanData.billNo;
          obj.packUnit = item.packUnit;
          obj.planScanAtoNum = Number(item.planScanAtoNum);
          obj.planScanNum = Number(item.planScanNum);
          obj.prodBatchNo = item.prodBatchNo;
          obj.productId = item.productId;
          that.scanData.tradeBillDetailAOS.push(obj);
        });
        that.scanData.sumPlanAtoNum = Number(planScanAtoNum);
        that.scanData.sumPlanNum = Number(planScanNum);
      } else {
        that.scanData.tradeBillDetailAOS = [];
        that.scanData.planScanAtoNum = planScanAtoNum;
        that.scanData.planScanNum = planScanNum;
      }
    },
    // 选择产品
    openSel: function openSel() {
      this.proShow = true;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSel: function setSel(val) {
      this.transFirstData(val);
      this.proShow = false;
    },
    transFirstData: function transFirstData(data) {
      var that = this;
      var products = [];
      if (data.length > 0) {
        data.forEach(function (item) {
          var obj = {};
          obj.packUnit = item.minScaleName;
          obj.planScanAtoNum = null;
          obj.planScanNum = null;
          obj.restNum = null;
          obj.prodBatchNo = item.prodBatchNo ? item.prodBatchNo : '';
          obj.productId = item.pId ? item.pId : item.productId;
          obj.productName = item.productName;
          obj.productCode = item.productCode;
          obj.packRadioDesc = item.packScaleDesc ? item.packScaleDesc : item.packScaleExpression;
          products.push(obj);
        });
        var concatData = that.plist.concat(products);
        that.plist = that.uniqueArray(concatData, 'productId');
      }
    },
    // 删除添加产品
    proDel: function proDel(row) {
      var that = this;
      var pid = row.productId;
      var plist = that.plist;
      for (var i = 0; i < plist.length; i++) {
        if (plist[i].productId == pid) {
          plist.splice(i, 1);
          break;
        }
      }
      that.transFormData(that.plist);
      that.proShow = false;
    },
    // 数据检测
    setSum: function setSum(e, row, type) {
      // console.log(e,row,type,"--0--");
      var packNum = 1;
      console.log(row, 'row');
      var scal;
      if (row.packRadioDesc.indexOf('*') >= 0) {
        scal = row.packRadioDesc.split('*');
      } else {
        var regex = /[\u4e00-\u9fa5](\d+)[\u4e00-\u9fa5]/g;
        scal = row.packRadioDesc.match(regex)[0].match(/\d+/)[0];
      }
      if (scal.length > 0) {
        // scal.forEach(item => {
        //   packNum = Number(item) * packNum;
        // });
        packNum = Number(scal[scal.length - 1]) * packNum;
      }
      if (type === 'box') {
        if (e == '' || e == null) {
          row.planScanNum = 0;
          row.planScanAtoNum = row.planScanNum * packNum + Number(row.restNum);
        } else {
          row.planScanNum = Number(e);
          row.planScanAtoNum = row.planScanNum * packNum + Number(row.restNum);
        }
      }
      if (type === 'bottle') {
        if (e == '' || e == null || e < 0) {
          row.restNum = 0;
          row.planScanAtoNum = row.planScanNum * packNum + Number(row.restNum);
        } else {
          // row.restNum = Number(e) % packNum
          row.restNum = Number(e);
          // var boxNum = parseInt(Number(e) / packNum)
          // row.planScanNum += boxNum
          row.planScanAtoNum = row.planScanNum * packNum + Number(row.restNum);
        }
      }
    },
    setScanFactory: function setScanFactory(val) {
      if (!val) return;
      for (var i in this.factoryList) {
        var items = this.factoryList[i];
        if (items.pId == val) {
          if (items.orgId != null) {
            // 获取企业数据
            this.scanData.inOrgId = items.orgId;
          }
        }
      }
      return false;
    },
    // json数组去重
    uniqueArray: function uniqueArray(array, key) {
      var result = [array[0]];
      for (var i = 1; i < array.length; i++) {
        var item = array[i];
        var repeat = false;
        for (var j = 0; j < result.length; j++) {
          if (item[key] == result[j][key]) {
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          result.push(item);
        }
      }
      return result;
    },
    // 获取业务类型
    getYwTypes: function getYwTypes() {
      var that = this;
      var param = {
        orderType: 1
      };
      (0, _select.queryByOrderType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.options = res.data.data || [];
        }
      });
    },
    getType: function getType(val) {
      var that = this;
      var name = '';
      for (var i = 0; i < that.options.length; i++) {
        var item = that.options[i];
        if (item.pid == val) {
          name = item.billCode;
          break;
        }
      }
      if (name == 'QLT_THRK') {
        that.showSelect = false;
      } else {
        that.showSelect = true;
      }
      that.scanData.otherTypeId = val;
      that.scanData.inOrgId = '';
      that.scanData.inOrgType = '';
      that.scanData.inOrgName = '';
      that.scanData.inStoreId = '';
      that.scanData.inStoreName = '';
      that.scanData.outOrgId = '';
      that.scanData.outOrgName = '';
      that.scanData.tradeBillDetailAOS = [];
      that.plist = [];
      that.factoryList = [];
    },
    // 获取收货单位
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        if (that.scanData.outOrgId) {
          that.shouFaType = '2';
          that.inOutType = '1';
          that.unitShow = true;
        } else {
          that.$message.error('请选择发货单位');
        }
      }
      if (arg == 'out') {
        that.shouFaType = '1';
        that.inOutType = '1';
        that.unitShow = true;
      }
      if (arg == 'LLRK') {
        that.shouFaType = '1';
        that.inOutType = '7';
        that.unitShow = true;
      }
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'LLRK') {
        // if (that.scanData.otherTypeId == '1340856452247732226') {
        that.scanData.inOrgName = val.companyName;
        that.scanData.inOrgId = val.companyId;
        that.scanData.outOrgName = val.companyName;
        that.scanData.outOrgId = val.companyId;
        that.scanData.inStoreId = '';
        that.$refs.fhdw.focus();
        that.$refs.fhdw.blur();
        that.getHouses(val.companyId);
      }
      if (type == 'in') {
        if (that.scanData.inOrgId !== val.companyId) {
          that.plist = [];
          this.proList = [];
        }
        that.scanData.inOrgName = val.companyName;
        that.scanData.inOrgId = val.companyId;
        this.channelType = val.companyType;
        this.channelId = val.companyId;
        that.$refs.shdw.focus();
        that.$refs.shdw.blur();
        that.getHouses(val.companyId);
      }
      if (type == 'out') {
        that.scanData.outOrgName = val.companyName;
        that.scanData.outOrgId = val.companyId;
        this.supplierIds = [val.companyId];
        console.log(this.supplierIds);
        that.$refs.fhdw.focus();
        that.$refs.fhdw.blur();
      }
    },
    // 获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      console.log(arg, '参数');
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.factoryList = [];
          that.factoryList = res.data.data;
          return res.data.data;
        }
      });
    },
    // 获取焦点关联库房
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.scanData.inOrgId == '') {
        that.$message.error('请选择收货单位');
        return;
      }
    },
    submitForm: function submitForm(temp) {
      var _this3 = this;
      var that = this;
      that.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          that.btn_loading = true;
          that.transFormData(that.plist);
          var tradeBillAO = {};
          var bool = true;
          tradeBillAO.billInReturnAO = {};
          tradeBillAO.billInReturnAO.inOrgId = that.scanData.inOrgId;
          tradeBillAO.billInReturnAO.outOrgId = that.scanData.outOrgId;
          // tradeBillAO.billInReturnAO.inOrgType = that.scanData.inOrgType
          tradeBillAO.billInReturnAO.inStoreId = that.scanData.inStoreId;
          // tradeBillAO.BillInReturnAO.otherTypeId = that.scanData.otherTypeId
          // tradeBillAO.BillInReturnAO.relateNo = that.scanData.relateNo
          // tradeBillAO.BillInReturnAO.relateType = that.scanData.relateType
          tradeBillAO.billInReturnAO.returnReason = that.scanData.returnReason;
          tradeBillAO.billNo = that.scanData.billNo;
          tradeBillAO.NCNO = that.scanData.NCNO;
          tradeBillAO.billType = that.scanData.billType;
          tradeBillAO.sumPlanAtoNum = Number(that.scanData.sumPlanAtoNum);
          tradeBillAO.sumPlanNum = Number(that.scanData.sumPlanNum);
          tradeBillAO.tradeBillDetailAOS = that.scanData.tradeBillDetailAOS;
          if (tradeBillAO.tradeBillDetailAOS.length == 0) {
            that.$message.error('请选择产品！');
            that.btn_loading = false;
            bool = false;
            return;
          } else {
            var pros = tradeBillAO.tradeBillDetailAOS;
            for (var i = 0; i < pros.length; i++) {
              var item = pros[i];
              if (item.planScanAtoNum == 0 || item.planScanAtoNum == null || item.planScanAtoNum == '') {
                that.$message.error('第' + (i + 1) + '行最小单位瓶数量为空！');
                that.btn_loading = false;
                bool = false;
                break;
              }
            }
          }
          if (!bool) {
            return;
          }
          if (that.submitType == 2) {
            tradeBillAO.billSource = that.scanData.billSource;
            (0, _index.InfoUpdate)(tradeBillAO).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.$parent.getList();
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          } else {
            tradeBillAO.billSource = 2;
            (0, _index.InfoInsert)(tradeBillAO).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.$parent.getList();
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }
        } else {
          that.btn_loading = false;
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      var that = this;
      that.plist = [];
      that.$emit('close');
    }
  }
};
exports.default = _default;