var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "productId", label: "产品" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择产品",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.productName,
                                    callback: function ($$v) {
                                      _vm.productName = $$v
                                    },
                                    expression: "productName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-arrow-down",
                                    },
                                    on: { click: _vm.choseProduct },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "经销商:", prop: "channelId" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择经销商",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.channelName,
                                    callback: function ($$v) {
                                      _vm.channelName = $$v
                                    },
                                    expression: "channelName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-arrow-down",
                                    },
                                    on: { click: _vm.handleSelectDistributor },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "检测状态：",
                                prop: "checkStatus",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择状态" },
                                  model: {
                                    value: _vm.formInline.checkStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "checkStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.checkStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "正常", value: "0" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "异常", value: "1" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "时间范围：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  clearable: "",
                                },
                                on: { change: _vm.sj },
                                model: {
                                  value: _vm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.rangeTime = $$v
                                  },
                                  expression: "rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "生产订单号：", prop: "orderNo" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "请输入生产订单号" },
                                model: {
                                  value: _vm.formInline.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "orderNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库单号：", prop: "billNo" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "请输入出库单号" },
                                model: {
                                  value: _vm.formInline.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "billNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "padding-top": "5px" } },
                            [
                              _c("div", { staticClass: "btnArea" }, [
                                _c(
                                  "div",
                                  { staticClass: "head-btn-group" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.search },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.reset },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "btnArea" }, [
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.downLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.downLoadBtn.icon,
                          },
                          on: { click: _vm.exportDownload },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: { click: _vm.detection },
                        },
                        [_vm._v("检测")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    {
                      staticStyle: {
                        "text-align": "right",
                        padding: "7px 100px 0 0",
                      },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handlePush },
                        },
                        [_vm._v("推送设置")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  width: "200",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: "生产订单号",
                  width: "220",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "billNo",
                  label: "出库单号",
                  width: "220",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "uploadTime",
                  label: "上传时间",
                  width: "180",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "产品名",
                  width: "150",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "channelName",
                  label: "对应经销商",
                  width: "150",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "关联活动名",
                  width: "180",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "checkStatus",
                  label: "检测状态",
                  width: "180",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.checkStatus == 0
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("正常"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.checkStatus == 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("异常"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      prop: "checkStatusDesc",
                      label: "推送状态",
                      width: "90",
                      align: _vm.tableConfig.align,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "推送人员",
                      width: "200",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.params.current,
                  limit: _vm.params.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.params, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.params, "size", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推送设置",
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-row", { staticStyle: { "margin-left": "16px" } }, [
            _c("div", { staticClass: "btnArea" }, [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.addBtn.icon,
                      },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { margin: "20px 0" } }, [
              _vm._v("模板消息通知人员："),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "40px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "1." } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.name1))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-button", { attrs: { plain: "" } }, [
                        _vm._v("选择"),
                      ]),
                      _vm._v(" "),
                      _c("el-button", { attrs: { plain: "" } }, [
                        _vm._v("删除"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "2." } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.name2))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-button", { attrs: { plain: "" } }, [
                        _vm._v("选择"),
                      ]),
                      _vm._v(" "),
                      _c("el-button", { attrs: { plain: "" } }, [
                        _vm._v("删除"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("productSelectDialog", {
        ref: "proSelect",
        on: { change: _vm.selectPro },
      }),
      _vm._v(" "),
      _c("distributorSelect", {
        attrs: { "pro-sel-visible": _vm.isShowDistributor },
        on: { distributorName: _vm.getDistributorName },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }