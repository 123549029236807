"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _invest = require("@/api/market/invest");
var _utils = require("@/utils");
var _activityManage = require("@/api/market/activityManage");
var _TMap = _interopRequireDefault(require("@/components/TMap"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    TMap: _TMap.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    actId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    investId: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      codeForm: {
        awardPrizeRange: '0',
        prizeItemsList: [{
          preUpperLimit: 0
        }],
        extraLocationItems: [],
        preUpperLimit: 0,
        digitalCodeList: [],
        userIdList: [],
        explain: ''
      },
      curPrizeList: [],
      subLoading: false,
      isDisable: false,
      prizeTypes: [{
        name: '红包',
        value: '100'
      }, {
        name: '实物奖品',
        value: '200'
      }
      // { name: '优惠券', value: '300' },
      // { name: '积分', value: '400' },
      // // { name: '字卡', value: '500' },
      // { name: '礼包', value: '900' }
      ],

      codeFormRules: {
        awardPrizeRange: [{
          required: true,
          message: '请选择布奖范围',
          trigger: 'change'
        }],
        putTime: [{
          required: true,
          message: '请选择投放时间',
          trigger: 'change'
        }],
        prizeType: [{
          required: true,
          message: '请选择奖品类型',
          trigger: 'change'
        }],
        extraLocationItems: [{
          required: true,
          message: '请选择经纬度',
          trigger: 'change'
        }],
        userIdList: [{
          required: true,
          message: '请输入微信ID',
          trigger: 'change'
        }],
        digitalCodeList: [{
          required: true,
          message: '请选择参与数码',
          trigger: 'change'
        }]
      },
      hongbaoOption: [],
      shiwuOption: [],
      mapV: false,
      inputVisibleTag: false,
      inputValueTag: '',
      ID: ''
    };
  },
  computed: {
    title: function title() {
      return this.type === 'add' ? '新建额外定投' : '编辑额外定投';
    },
    isDisableType: function isDisableType() {
      return false;
      // return this.type === 'add' ? false : true
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.type == 'edit') {
      this.dialogLoading = true;
      (0, _invest.detail)({
        id: this.investId
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            console.log(res.data.data);
            _this.dialogLoading = false;
            _this.codeForm = res.data.data;
            _this.ID = res.data.data.id;
            window.parseTime = _utils.parseTime;
            _this.codeForm.putTime = [(0, _utils.parseTime)(res.data.data.putStartTime), (0, _utils.parseTime)(res.data.data.putEndTime)];
            _this.$set(_this.codeForm, 'putTime', [(0, _utils.parseTime)(res.data.data.putStartTime), (0, _utils.parseTime)(res.data.data.putEndTime)]);
            _this.codeForm.prizeItemsList.map(function (item, index) {
              item.prizeType && _this.getCurPrizeList(item.prizeType, index, false);
            });
            return;
          } else {
            _this.dialogLoading = false;
            _this.goBack();
            return _this.$message.error(res.data.data.errMsg);
          }
        } else {
          _this.dialogLoading = false;
          _this.goBack();
          return _this.$message.error(res.data.msg);
        }
      });
    }
    this.codeForm.prizeItemsList.map(function (item, index) {
      return item.prizeType && _this.getCurPrizeList(item.prizeType, index);
    });
  },
  methods: {
    getPriName: function getPriName(val) {
      var prizeName = this.codeForm.prizeItemsList[0].plist.filter(function (item) {
        return item.prizeId == val;
      })[0].prizeName;
      this.codeForm.prizeItemsList[0].prizeName = prizeName;
    },
    showInputTag: function showInputTag() {
      var _this2 = this;
      this.inputVisibleTag = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValueTag = this.inputValueTag;
      if (inputValueTag) {
        this.codeForm.digitalCodeList.push(inputValueTag);
      }
      this.inputVisibleTag = false;
      this.inputValueTag = '';
    },
    handleClose: function handleClose(index) {
      this.codeForm.digitalCodeList.splice(index, 1);
    },
    handleInputConfirm1: function handleInputConfirm1() {
      var inputValueTag = this.inputValueTag;
      if (inputValueTag) {
        this.codeForm.userIdList.push(inputValueTag);
      }
      this.inputVisibleTag = false;
      this.inputValueTag = '';
    },
    handleClose1: function handleClose1(index) {
      this.codeForm.userIdList.splice(index, 1);
    },
    // 类型切换
    changeType: function changeType(e) {
      var _this3 = this;
      console.log(e);
      var that = this;
      that.codeForm.awardPrizeRange = e;
      that.$nextTick(function () {
        that.codeForm = {
          awardPrizeRange: e,
          prizeItemsList: [{}],
          extraLocationItems: [],
          preUpperLimit: 0,
          digitalCodeList: [],
          userIdList: [],
          explain: ''
        };
        _this3.$refs['codeForm'].resetFields();
      });
    },
    addAddress: function addAddress() {
      this.mapV = true;
      // this.codeForm.extraLocationItems.push({jwd: '', effectiveRange: 0})
    },
    confirmFun: function confirmFun(data) {
      this.mapV = false;
      var id = data.id,
        latitude = data.latitude,
        longitude = data.longitude;
      if (!id || !latitude || !longitude) return this.$message.error('请选择经纬度');
      this.codeForm.extraLocationItems.push({
        effectiveRange: 0,
        latitude: latitude,
        longitude: longitude,
        priority: this.codeForm.extraLocationItems.length + 1
      });
    },
    closeMap: function closeMap() {
      this.mapV = false;
    },
    deleteAddress: function deleteAddress(index) {
      this.codeForm.extraLocationItems.splice(index, 1);
    },
    getCurPrizeList: function getCurPrizeList(prizeType, index) {
      var _this4 = this;
      var isClear = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: prizeType
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this4.$set(_this4.codeForm.prizeItemsList[index], 'plist', res.data.data);
          // this.codeForm.prizeItemsList[index].plist = res.data.data
        }

        console.log(_this4.codeForm);
      });
      isClear && this.codeForm.prizeItemsList[index].prizeId && (this.codeForm.prizeItemsList[index].prizeId = '');
    },
    // 表单验证
    checkForm: function checkForm(val, text) {
      var _this5 = this;
      return new Promise(function (resolve, reject) {
        _this5.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this5.$message.closeAll();
            _this5.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit('close');
    },
    // 完成
    finish: function finish() {
      var _this6 = this;
      var finishFunc = this.type === 'add' ? _invest.create : _invest.update;
      var eCheck = this.checkForm('codeForm', '请正确填写额外定投表单');
      eCheck.then(function () {
        var data = JSON.parse(JSON.stringify(_this6.codeForm));
        if (data.prizeItemsList[0].prizePr > 100) {
          return _this6.$message.error('奖品概率不能超过100%');
        }
        _this6.subLoading = true;
        _this6.isDisable = true;
        var sumbitData = {
          activityId: _this6.actId,
          awardPrizeRange: data.awardPrizeRange,
          explain: data.explain,
          prizeItemsList: data.prizeItemsList.map(function (item) {
            return {
              oldPriority: 1,
              priority: 1,
              prizeId: item.prizeId,
              prizeNum: item.prizeNum,
              prizePr: item.prizePr,
              prizeName: item.prizeName,
              prizeType: item.prizeType
            };
          }),
          putStartTime: data.putTime[0],
          putEndTime: data.putTime[1]
        };
        if (data.awardPrizeRange == '0') {
          sumbitData.extraLocationItems = data.extraLocationItems;
        }
        if (data.awardPrizeRange == '1') {
          sumbitData.digitalCodeList = data.digitalCodeList;
        }
        if (data.awardPrizeRange == '2') {
          sumbitData.userIdList = data.userIdList;
          sumbitData.prizeItemsList = data.prizeItemsList.map(function (item) {
            return {
              oldPriority: 1,
              priority: 1,
              prizeId: item.prizeId,
              prizeNum: item.prizeNum,
              prizePr: '100',
              prizeName: item.prizeName,
              prizeType: item.prizeType
            };
          });
        }
        if (_this6.type != 'add') {
          sumbitData.id = _this6.ID;
        }
        finishFunc(sumbitData).then(function (res) {
          if (res.data.code == 200) {
            if (res.data.data.code) {
              _this6.subLoading = false;
              _this6.isDisable = false;
              _this6.$message.error(res.data.data.msg);
            } else {
              _this6.$message({
                message: "".concat(_this6.type === 'add' ? '添加' : '编辑', "\u6210\u529F\uFF01"),
                type: 'success',
                duration: 3 * 1000
              });
              _this6.goBack();
              _this6.subLoading = false;
              _this6.isDisable = false;
            }
          } else {
            _this6.subLoading = false;
            _this6.isDisable = false;
            _this6.$message.error(res.data.msg);
            return;
          }
        });
      });
    }
  }
};
exports.default = _default2;