"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _basic = require("@/api/mark/basic");
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
var _checkout = require("@/api/warehouse/checkout");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var qrcode = '';
var TypeDataComs = {
  djly: [],
  djzt: [],
  status: [],
  source: [],
  factory: [],
  classList: [],
  ddyt: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd');
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djztText: function djztText(val) {
      var name = '';
      TypeDataComs.djzt.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: 'success',
        8: 'warning',
        9: 'warning',
        10: 'warning',
        11: 'warning',
        12: 'danger'
      };
      return obj[val];
    }
  },
  data: function data() {
    return {
      typeNum: 1,
      billNo: '',
      inUseName: '',
      searchSendOutComName: '',
      searchSaleOrgName: '',
      searchInOrgName: '',
      productName: '',
      handleShow: false,
      printShow: false,
      factoryShow: false,
      companyShow: false,
      apartShow: false,
      factorycanChange: true,
      companycanChange: true,
      factoryType: '',
      companyType: '',
      orgId: '',
      tableData: [],
      searchForm: {
        endTime: '',
        ncProductName: '',
        pId: 0,
        productId: 0,
        productName: '',
        sourceAccountCode: '',
        sourceAccountName: '',
        sourceDealerCode: '',
        sourceDealerName: '',
        sourceNo: '',
        startTime: '',
        targetAccountCode: '',
        targetAccountName: '',
        targetDealerCode: '',
        targetDealerName: '',
        targetNo: '',
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      djly: [],
      djzt: [],
      storeList: [],
      classList: [],
      ddyt: [],
      // a123
      codetotal: 0,
      billType: 203,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      SyncVisible: false,
      // 同步
      Syncform: {
        time: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        startRow: 0
      },
      Syncrules: {
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      sloading: false,
      editable: false,
      modalType: null
    };
  },
  watch: {},
  activated: function activated() {
    var _this = this;
    this.authId('searchbtn', this.$route.meta.authList);
    // 单据来源
    this.getDirSel('DJLY', 'djly');
    // 单据状态
    this.getDirSel('SCRK_STATUS', 'djzt');
    setTimeout(function () {
      _this.getList();
    }, 500);
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });

    // 单据来源
    this.getDirSel('DJLY', 'djly');
    // 单据状态
    this.getDirSel('DJZT', 'djzt');
    this.getList();
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this3 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _checkout.tradeTransLogPage)(JSON.parse(JSON.stringify(this.searchForm))).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchSendOutComName = '';
      this.searchSaleOrgName = '';
      this.searchInOrgName = '';
      this.productName = '';
      this.searchForm = {
        endTime: '',
        ncProductName: '',
        pId: null,
        productId: null,
        productName: '',
        sourceAccountCode: '',
        sourceAccountName: '',
        sourceDealerCode: '',
        sourceDealerName: '',
        sourceNo: '',
        startTime: '',
        targetAccountCode: '',
        targetAccountName: '',
        targetDealerCode: '',
        targetDealerName: '',
        targetNo: '',
        current: 1,
        size: 20
      };
      this.getList();
    },
    getflow: function getflow() {}
  }
};
exports.default = _default;