var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-section" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "search-condition",
          attrs: {
            model: _vm.searchForm,
            "label-suffix": ":",
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调库单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入调货单号", clearable: "" },
                        model: {
                          value: _vm.searchForm.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "orderNo", $$v)
                          },
                          expression: "searchForm.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请选择产品",
                          readonly: true,
                          clearable: "",
                        },
                        on: {
                          focus: function ($event) {
                            return _vm.inputFocusFun(1)
                          },
                        },
                        model: {
                          value: _vm.searchForm.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "productName", $$v)
                          },
                          expression: "searchForm.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "调拨日期",
                        prop: "startTime",
                        rules: _vm.dateRule,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.dateFun },
                        model: {
                          value: _vm.dateVal,
                          callback: function ($$v) {
                            _vm.dateVal = $$v
                          },
                          expression: "dateVal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.handleShow
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货单位" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请选择发货单位",
                              clearable: "",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.inputFocusFun("out")
                              },
                            },
                            model: {
                              value: _vm.searchForm.outName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "outName", $$v)
                              },
                              expression: "searchForm.outName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.handleShow
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货单位" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请选择收货单位",
                              clearable: "",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.inputFocusFun("in")
                              },
                            },
                            model: {
                              value: _vm.searchForm.inName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "inName", $$v)
                              },
                              expression: "searchForm.inName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                            loading: _vm.searchBtn,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchFun(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.btnConfig.searchBtn.text) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                            loading: _vm.sweepBtn,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchFun(0)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.btnConfig.resetBtn.text) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.handleShow = !_vm.handleShow
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.handleShow ? "收起" : "展开") +
                              "\n            "
                          ),
                          _c("i", {
                            class: _vm.handleShow
                              ? "el-icon-arrow-up"
                              : "el-icon-arrow-down",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "summarizing-wrap" },
        [
          _c(
            "div",
            { staticClass: "summarizing-title" },
            [
              _c("h5", [_vm._v("数据汇总")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.downLoad },
                },
                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              staticClass: "grid-wrap",
              attrs: {
                model: _vm.searchForm,
                "label-suffix": ":",
                "label-position": "left",
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "调库单号数" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.collectData.orderNoCount) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "未执行" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.collectData.nonExecutionCount) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "已完成" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.collectData.executionCount) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "调库最多商品" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.collectData.maxProduct) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货数量最多单位" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.collectData.maxOrg) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "计划调库量" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.collectData.sumPlanNum) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "实际量" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.collectData.realPlanNum) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "单日调库最高" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.collectData.inDateTime) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "单日最大调库量" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.collectData.inCount) + "\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            "element-loading-text": "加载中...",
            data: _vm.list,
            border: "",
            accordion: "",
            "row-key": "makeId",
            width: "100%",
            "highlight-current-row": "",
            "header-cell-style": {
              "background-color": "#fafafa",
              color: "rgba(0, 0, 0,0.85)",
              "font-weight": "500",
              "text-align": "center",
            },
            "cell-style": { "text-align": "center" },
            "row-style": { color: "rgba(0, 0, 0,0.65)", "font-weight": "400" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "orderNo",
              label: "调库单号",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "tradeBillTime",
              label: "调库时间",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("textDate")(row.tradeBillTime)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "billType",
              label: "调库类型",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("billTypeFun")(row.billType)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "materialName",
              label: "商品名称",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "productModel",
              label: "商品规格",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "sumPlanNum",
              label: "计划数量（箱）",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "realPlanNum",
              label: "实际数量（箱）",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "outOrg",
              label: "出货单位",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "inOrg",
              label: "收货单位",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { prop: "createDate", label: "订单状态" } },
            [_vm._v("\n      " + _vm._s("已完成") + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          "auto-scroll": false,
          page: _vm.searchForm.current,
          limit: _vm.searchForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.searchForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.searchForm, "size", $event)
          },
          pagination: _vm.pagingFun,
        },
      }),
      _vm._v(" "),
      _c("pro-dialog", { ref: "selProduct", on: { change: _vm.proDataFun } }),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.setCSel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }