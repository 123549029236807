"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _register = require("@/api/terminal/register");
var _dealer = require("@/api/basic/dealer");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _details_flow = _interopRequireDefault(require("@/views/terminalManagement/terminalRegistration/components/details_flow"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default,
    detailsflow: _details_flow.default
  },
  filters: {
    Status: function Status(status) {
      var statusMap = {
        0: 'danger',
        1: 'success',
        2: 'info'
      };
      return statusMap[status];
    },
    Status2: function Status2(status) {
      var statusMap = {
        1: 'warning',
        2: 'success',
        3: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      listLoading: false,
      ruleForm: {
        current: 1,
        size: 10,
        keyword: '',
        // 门店编码/名称
        provinceCode: '',
        // 省
        cityCode: '',
        // 市
        countyCode: '',
        // 区县
        contacts: '',
        // 联系人
        phone: '',
        // 联系电话
        organizationCode: '',
        // 组织机构代码
        parentKeyword: '',
        // 经销商编码/名称
        warZoneKeyword: '',
        // 战区编码/名称
        warTeamKeyword: '',
        // 战队编码/名称
        createType: '',
        // 创建方式 1：移动端注册 2：移动端认领
        archivesStatus: '',
        // 状态 0禁用1启用2删除
        auditStatus: '',
        // 审核状态 1:待审核,2:审核通过,3:审核驳回
        rangeTime: '',
        // 时间
        startTime: '',
        endTime: ''
      },
      createTypeList: [{
        name: '全部',
        value: ''
      }, {
        name: '移动端注册',
        value: '1'
      }, {
        name: '移动端认领',
        value: '2'
      }],
      archivesStatusList: [{
        name: '全部',
        value: ''
      }, {
        name: '禁用',
        value: '0'
      }, {
        name: '启用',
        value: '1'
      }, {
        name: '删除',
        value: '2'
      }],
      auditStatusList: [{
        name: '全部',
        value: ''
      }, {
        name: '待审核',
        value: '1'
      }, {
        name: '审核通过',
        value: '2'
      }, {
        name: '审核驳回',
        value: '3'
      }],
      total: 0,
      tableKey: '000pbox',
      tableData: [],
      tabHeight: '100%',
      area: [],
      areaList: [],
      // 省市区县

      detailVisible: false,
      mloading: false,
      detailData: {},
      handleShow: false
    };
  },
  created: function created() {
    this.getList();
    this.getAreaAll();
  },
  activated: function activated() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // =========表格过滤===========
    showCreateType: function showCreateType(val) {
      if (!String(val.createType)) return '';
      var obj = this.createTypeList.find(function (item) {
        return item.value == val.createType;
      });
      return obj.name;
    },
    showArchivesStatus: function showArchivesStatus(val) {
      if (!String(val.archivesStatus)) return '';
      var obj = this.archivesStatusList.find(function (item) {
        return item.value == val.archivesStatus;
      });
      return obj.name;
    },
    showAuditStatus: function showAuditStatus(val) {
      if (!String(val.auditStatus)) return '';
      var obj = this.auditStatusList.find(function (item) {
        return item.value == val.auditStatus;
      });
      return obj.name;
    },
    showAuditMode: function showAuditMode(val) {
      if (!String(val.auditMode)) return '';
      var arr = [{
        name: '系统自动审核',
        value: '1'
      }, {
        name: '管理员审核',
        value: '2'
      }];
      var obj = arr.find(function (item) {
        return item.value == val.auditMode;
      });
      return obj.name;
    },
    // ===========================
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: ''
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaList = [];
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      if (that.ruleForm.rangeTime && that.ruleForm.rangeTime.length > 0) {
        that.ruleForm.startTime = that.ruleForm.rangeTime[0];
        that.ruleForm.endTime = that.ruleForm.rangeTime[1];
      } else {
        that.ruleForm.startTime = '';
        that.ruleForm.endTime = '';
      }
      if (that.area) {
        that.ruleForm.provinceCode = that.area[0];
        that.ruleForm.cityCode = that.area[1] || '';
        that.ruleForm.countyCode = that.area[2] || '';
      }
      var searchData = (0, _utils.filterKeyNull)(that.ruleForm);
      delete searchData.rangeTime;
      (0, _register.postAuditList)(searchData).then(function (res) {
        console.log(res.data, 11111);
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
            that.listLoading = false;
          } else {
            that.tableData = [];
            that.total = 0;
            that.listLoading = false;
          }
        }
      });
    },
    DoEdit: function DoEdit(row, qx) {
      this.$refs.flowMain.show(row, qx);
      //
      // getQueryAuthDetail({ terminalId: data.terminalId })
      //   .then((res) => {
      //     console.log(res.data)
      //     this.detailVisible = true
      //     this.detailData = res.data.data.data
      //   }).catch((err) => {
      //     console.log(err)
      //   })
    },
    handleClose: function handleClose() {
      this.detailVisible = false;
    },
    DoDel: function DoDel(data) {},
    resetForm: function resetForm() {
      this.ruleForm = {
        current: 1,
        size: 10,
        keyword: '',
        // 门店编码/名称
        provinceCode: '',
        // 省
        cityCode: '',
        // 市
        countyCode: '',
        // 区县
        contacts: '',
        // 联系人
        phone: '',
        // 联系电话
        organizationCode: '',
        // 组织机构代码
        parentKeyword: '',
        // 经销商编码/名称
        warZoneKeyword: '',
        // 战区编码/名称
        warTeamKeyword: '',
        // 战队编码/名称
        createType: '',
        // 创建方式 1：移动端注册 2：移动端认领
        archivesStatus: '',
        // 状态 0禁用1启用2删除
        auditStatus: '',
        // 审核状态 1:待审核,2:审核通过,3:审核驳回
        rangeTime: '',
        // 时间
        startTime: '',
        endTime: ''
      };
      this.area = [];
      this.getList();
    }
  }
};
exports.default = _default;