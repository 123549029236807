var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardBox" }, [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "cardTitle" }, [
                          _vm._v("公众号基础配置"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 14, offset: 5 } },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "dataForm",
                                    staticStyle: { margin: "10 auto" },
                                    attrs: {
                                      rules: _vm.rules,
                                      model: _vm.temp,
                                      "label-position": "right",
                                      "label-width": "150px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "公众号名：",
                                          prop: "officialName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "4-30字符汉字即可",
                                          },
                                          model: {
                                            value: _vm.temp.officialName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "officialName",
                                                $$v
                                              )
                                            },
                                            expression: "temp.officialName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "公众号原始ID：",
                                          prop: "officialId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "50字符以内，请认真填写，错了不能修改",
                                          },
                                          model: {
                                            value: _vm.temp.officialId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "officialId",
                                                $$v
                                              )
                                            },
                                            expression: "temp.officialId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "微信号：",
                                          prop: "wechatName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "50字符以内" },
                                          model: {
                                            value: _vm.temp.wechatName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "wechatName",
                                                $$v
                                              )
                                            },
                                            expression: "temp.wechatName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "APPid(公众号)：",
                                          prop: "appid",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "50字符以内" },
                                          model: {
                                            value: _vm.temp.appid,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.temp, "appid", $$v)
                                            },
                                            expression: "temp.appid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "APPSecret(公众号)：",
                                          prop: "appsecret",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { "show-password": "" },
                                          model: {
                                            value: _vm.temp.appsecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "appsecret",
                                                $$v
                                              )
                                            },
                                            expression: "temp.appsecret",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "令牌(Token)：",
                                          prop: "appToken",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.temp.appToken,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "appToken",
                                                $$v
                                              )
                                            },
                                            expression: "temp.appToken",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "消息加解密密钥：",
                                          prop: "appEncodingAeskey",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.temp.appEncodingAeskey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "appEncodingAeskey",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "temp.appEncodingAeskey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "hyewmup",
                                        attrs: {
                                          label: "公众号二维码：",
                                          prop: "imageUrl",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            ref: "imageUrl",
                                            staticClass: "upload-demo",
                                            attrs: {
                                              headers: _vm.myHeaders,
                                              action: _vm.fstpUrl,
                                              "on-success":
                                                _vm.handleAvatarSuccesse,
                                              "file-list": _vm.fileList,
                                              "show-file-list": false,
                                              name: "file",
                                              limit: 1,
                                            },
                                          },
                                          [
                                            _vm.temp.imageUrl
                                              ? _c("img", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: _vm.temp.imageUrl,
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-plus avatar-uploader-icon",
                                                }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "支付方式：",
                                          prop: "payWay",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "radio",
                                            model: {
                                              value: _vm.temp.payWay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.temp,
                                                  "payWay",
                                                  $$v
                                                )
                                              },
                                              expression: "temp.payWay",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("商户号支付")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: 2,
                                                  disabled:
                                                    _vm.cid === "6089"
                                                      ? false
                                                      : true,
                                                },
                                              },
                                              [_vm._v("兆信代发")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 0 } },
                                              [_vm._v("不开启")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "默认公众号：",
                                          prop: "isDefault",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "radio",
                                            model: {
                                              value: _vm.temp.isDefault,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.temp,
                                                  "isDefault",
                                                  $$v
                                                )
                                              },
                                              expression: "temp.isDefault",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("是")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.temp.payWay === 1,
                                            expression: "temp.payWay===1",
                                          },
                                        ],
                                        attrs: {
                                          label: "商户号：",
                                          prop: "mchId",
                                          required: _vm.temp.payWay === 1,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            maxlength: "50",
                                            "show-word-limit": "",
                                            placeholder: "50字符以内",
                                          },
                                          model: {
                                            value: _vm.temp.mchId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.temp, "mchId", $$v)
                                            },
                                            expression: "temp.mchId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.temp.payWay === 1,
                                            expression: "temp.payWay===1",
                                          },
                                        ],
                                        attrs: {
                                          required: _vm.temp.payWay === 1,
                                          label: "商户平台支付秘钥：",
                                          prop: "paykey",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "text",
                                            maxlength: "50",
                                            "show-word-limit": "",
                                            placeholder: "50字符以内",
                                          },
                                          model: {
                                            value: _vm.temp.paykey,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.temp, "paykey", $$v)
                                            },
                                            expression: "temp.paykey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.temp.payWay === 1,
                                            expression: "temp.payWay===1",
                                          },
                                        ],
                                        attrs: {
                                          required: _vm.temp.payWay === 1,
                                          label: "商户平台证书：",
                                          prop: "certPath",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              headers: _vm.myHeaders,
                                              action: _vm.uploadUrl,
                                              "on-success":
                                                _vm.handleAvatarSuccess,
                                              "file-list": _vm.fileList,
                                              name: "file",
                                              limit: 1,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("点击上传")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          attrs: { type: "hidden" },
                                          model: {
                                            value: _vm.temp.certPath,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.temp,
                                                "certPath",
                                                $$v
                                              )
                                            },
                                            expression: "temp.certPath",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "normalBtn",
                                            attrs: {
                                              type: "primary",
                                              icon: "el-icon-s-order",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.dialogStatus === "新增"
                                                  ? _vm.createData()
                                                  : _vm.updateData()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      保存\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "normalBtn",
                                            on: { click: _vm.gzhback },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-row",
                              { staticClass: "explainWrap" },
                              [
                                _c("el-col", { staticClass: "title" }, [
                                  _vm._v("说明："),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { staticClass: "content" }, [
                                  _vm._v(
                                    "1、如您选择“商户号支付”，请先在 商户平台关联此公众号；"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { staticClass: "content" }, [
                                  _vm._v(
                                    "2、如您需要支付功能，但商户号不满足开通现金红包的条件，可选择“兆信代发”，目前只能通过线下联系客服在兆信平台开通；"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { staticClass: "content" }, [
                                  _vm._v(
                                    "3、如果您不需要发红包，请选择“不开启”。"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }