"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _source = require("@/api/systems/source");
var _add = _interopRequireDefault(require("./components/add"));
var _edit = _interopRequireDefault(require("./components/edit"));
var _uploadfile = _interopRequireDefault(require("@/components/publicComponent/uploadfile"));
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Systemrolesmangeresourceindex',
  components: {
    add: _add.default,
    edit: _edit.default,
    uploadfile: _uploadfile.default
  },
  data: function data() {
    return {
      tableKey: 'resource',
      listQuery: {
        clientCode: '',
        resourceName: '',
        parentCode: ''
      },
      list: [],
      listLoading: false,
      exportLoading: false,
      tabHeight: '100%',
      systemData: '',
      addShow: false,
      addData: {
        PId: '',
        parentCode: '',
        resourceName: '',
        resourceType: '',
        resourceCode: '',
        menuClass: '',
        resourceId: '',
        resourceUrl: '',
        resourceSort: '',
        clientCode: '',
        clientName: '',
        resourcePath: [{
          url: ''
        }],
        // apiInfoIds: [],
        manualPage: '',
        remark: '',
        level: null
      },
      editData: {},
      type: '',
      editShow: false,
      sysOptions: [],
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      operate: true,
      page2: false,
      uploadProps: {
        title: '上传',
        templateHref: '',
        templateName: '',
        uploadForm: {
          key: 'file'
        },
        uploadUrl: '/api-user/resourceInfo/importResourceInfoExcel',
        tableCols: [{
          label: '资源编码',
          // 列名
          prop: 'resourceCode',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '资源名称',
          // 列名
          prop: 'resourceName',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '页面URL',
          // 列名
          prop: 'resourceUrl',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '后台接口URL',
          // 列名
          prop: 'resourcePath',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '所属系统',
          // 列名
          prop: 'clientName',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '排序号',
          // 列名
          prop: 'resourceSort',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '层级',
          // 列名
          prop: 'resourceLevel',
          // 字段名称
          width: null,
          // 列宽度 null | number
          align: 'left'
        }, {
          label: '备注',
          prop: 'error',
          color: '#f00',
          width: null,
          align: 'left'
        }]
      }
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 370 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    that.getSysData();
    that.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      (0, _source.queryResourceForList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          var treeData = res.data.data;
          res.data.data.forEach(function (el) {
            _this.$set(el, 'resourthpathnew', el.resourcePath);
          });
          that.list = (0, _utils.transTreeData)(treeData, 'resourceCode', 'parentCode', 'children');
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 500);
        } else {
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.getList();
    },
    DoReset: function DoReset() {
      var that = this;
      that.listQuery.clientCode = '';
      that.listQuery.resourceName = '';
      that.getList();
    },
    DoAdd: function DoAdd(data, type) {
      this.type = type;
      // 新增
      var that = this;
      // that.addData.PId = data.pid
      that.addData.resourceName = '';
      that.addData.parentName = data.resourceName;
      that.addData.parentCode = data.resourceCode;
      that.addData.resourceCode = '';
      if (data.clientCode) {
        that.addData.clientCode = Number(data.clientCode);
      } else {
        that.addData.clientCode = '';
      }
      that.addData.clientName = data.clientName;
      that.addData.resourceUrl = '';
      that.addData.resourceId = '';
      this.addData.resourcePath = [{
        url: ''
      }];
      // that.addData.apiInfoIds = []
      // that.addData.manualPage = ''
      that.addData.resourceSort = '';
      that.addData.remark = '';
      that.addData.level = data.resourceLevel;
      that.$nextTick(function () {
        that.addShow = true;
      });
    },
    closeAdd: function closeAdd() {
      var that = this;
      that.addShow = false;
    },
    DoEdit: function DoEdit(data) {
      // 编辑
      var that = this;
      that.$refs.editControl.key = data.resourceType;
      if (!data.resourcePath) {
        data.resourcePath = [{
          url: ''
        }];
      }
      data.resourceSort = data.resourceSort.toString();
      if (typeof data.resourcePath == 'string') {
        data.resourcePath = data.resourcePath.split(',');
        if (data.resourcePath.length > 0) {
          var arr = [];
          data.resourcePath.forEach(function (el) {
            var a = {};
            a.url = el;
            arr.push(a);
          });
        }
        data.resourcePath = arr;
      }
      that.editData = (0, _utils.deepClone)(data);
      that.editData.clientCode = Number(data.clientCode);
      that.$nextTick(function () {
        that.editShow = true;
      });
    },
    closeEdit: function closeEdit() {
      var that = this;
      that.editShow = false;
    },
    DoDel: function DoDel(data) {
      // 删除
      var that = this;
      that.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.resourceCode = data.resourceCode;
        (0, _source.deleteResource)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功'
            });
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('已取消');
      });
    },
    getSysData: function getSysData() {
      var that = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CLIENT_CODE'
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.sysOptions = res.data.data;
          } else {
            that.sysOptions = [];
          }
        }
      });
    },
    // 导出方法
    downLoad: function downLoad() {
      var _this2 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.exportLoading = true;
      var submitData = (0, _utils.deepClone)(this.listQuery);
      (0, _source.exportSourceExcel)(submitData).then(function (res) {
        _this2.exportLoading = false;
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '资源管理列表.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this2.exportLoading = false;
      });
    },
    // 导入方法
    upLoad: function upLoad() {
      this.authId('importbtn', this.$route.meta.authList);
      this.$refs.uploadControl.uploadVisible = true;
      this.uploadProps.uploadForm.uploadFile = '';
    },
    // 重新请求数据
    getNewList: function getNewList(data) {
      this.listQuery = {
        clientCode: '',
        resourceName: '',
        parentCode: ''
      };
      this.getList();
    }
  }
};
exports.default = _default;