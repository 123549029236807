var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              staticClass: "search-condition",
              attrs: {
                model: _vm.queryParams,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖品名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入奖品名称" },
                                model: {
                                  value: _vm.queryParams.prizeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "prizeName", $$v)
                                  },
                                  expression: "queryParams.prizeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "状态" },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "核销层级" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.queryParams.goodsHxTarget,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "goodsHxTarget",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.goodsHxTarget",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "终端核销", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "大区核销", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "品牌部核销", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖品级别" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.queryParams.goodsHxType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "goodsHxType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.goodsHxType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "门店核销", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "线下大奖", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.queryList },
                                },
                                [_vm._v("查询")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.restPrizeList },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("新增")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                    },
                    on: { click: _vm.exportPrize },
                  },
                  [_vm._v("导出")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: _vm.tableConfig.align,
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖品名称",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeAlias",
                  label: "展示名称",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "redSendType",
                  label: "红包类型",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showRedSendType,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "baseValueStr",
                  label: "奖品价值（元）",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "flValue",
                  label: "红包返现（元）",
                  align: _vm.tableConfig.information,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.flValue == 0 ? _c("div") : _vm._e(),
                        _vm._v(" "),
                        scope.row.flValue !== 0
                          ? _c("div", [_vm._v(_vm._s(scope.row.flValue))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsHxTarget",
                  label: "核销层级",
                  align: _vm.tableConfig.information,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.goodsHxTarget == "1,2"
                          ? _c("div", [_vm._v("终端核销,大区核销")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.goodsHxTarget == "1"
                          ? _c("div", [_vm._v("终端核销")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.goodsHxTarget == "2"
                          ? _c("div", [_vm._v("大区核销")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.goodsHxTarget == "2,1"
                          ? _c("div", [_vm._v("大区核销,终端核销")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.goodsHxTarget == "3"
                          ? _c("div", [_vm._v("品牌部核销")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "openDataCollection",
                  label: "信息收集",
                  align: _vm.tableConfig.information,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.openDataCollection == "1"
                          ? _c("el-tag", [_vm._v("是")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.openDataCollection == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsHxType",
                  label: "奖品级别",
                  align: _vm.tableConfig.information,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.goodsHxType == "1"
                          ? _c("div", [_vm._v("门店核销")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.goodsHxType == "2"
                          ? _c("div", [_vm._v("线下大奖")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == "1"
                          ? _c("el-tag", [_vm._v("启用")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "110",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.stop(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 1 ? "停用" : "启用")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getPrizeList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c("div", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("基础选项"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品内部名称:", prop: "prizeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入奖品名称,不超过30个字",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.form.prizeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeName", $$v)
                      },
                      expression: "form.prizeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "中奖展示名称:", prop: "prizeAlias" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入中奖展示名称,不超过20个字",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.form.prizeAlias,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prizeAlias", $$v)
                      },
                      expression: "form.prizeAlias",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.disabled
                    ? _c("p", { staticClass: "formTip" }, [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                        _vm._v(" 备注：中奖后展示给消费者的名称。\n        "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "flex-item",
                  attrs: { label: "红包金额:", prop: "baseValue" },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "numrule",
                      staticStyle: { "margin-right": "20px" },
                      attrs: {
                        placeholder:
                          "建议金额范国 (0.1~200) ，超出200会分多次发放给客户",
                        type: "number",
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkNum($event, "baseValue")
                        },
                      },
                      model: {
                        value: _vm.form.baseValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "baseValue", $$v)
                        },
                        expression: "form.baseValue",
                      },
                    },
                    [_c("template", { slot: "prepend" })],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品级别:", prop: "goodsHxType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      model: {
                        value: _vm.form.goodsHxType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goodsHxType", $$v)
                        },
                        expression: "form.goodsHxType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("门店核销"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("线下大奖（不在同一箱中奖）"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { label: "核销层级:", prop: "goodsHxTarget" },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { display: "flex", "margin-top": "12px" } },
                    [
                      _c("el-switch", {
                        staticStyle: { "margin-right": "8px" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-value": 1,
                          disabled: _vm.isDisabled,
                          "inactive-value": 0,
                          "active-text": "开启中奖通知",
                          "inactive-text": "",
                        },
                        model: {
                          value: _vm.form.notice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "notice", $$v)
                          },
                          expression: "form.notice",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.notice == 1
                        ? _c("el-switch", {
                            staticStyle: { display: "block" },
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              disabled: _vm.isDisabled,
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": "同时通知策划部",
                              "inactive-text": "",
                            },
                            model: {
                              value: _vm.form.noticePlanning,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "noticePlanning", $$v)
                              },
                              expression: "form.noticePlanning",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.isDisabled },
                      on: { change: _vm.handleValueChange },
                      model: {
                        value: _vm.form.goodsHxTarget,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goodsHxTarget", $$v)
                        },
                        expression: "form.goodsHxTarget",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "1" },
                        },
                        [_vm._v("终端核销")]
                      ),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("大区核销"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.disabled
                    ? _c("p", { staticClass: "formTip" }, [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                        _vm._v(
                          " 备注：中奖信息将通知大区负责人及消费者，如需通知策划部请勾选“同时通知策划部”\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取说明:", prop: "explain" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入领取说明不超过50字",
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.form.explain,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explain", $$v)
                      },
                      expression: "form.explain",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "flex-item",
                  attrs: { label: "红包返现:", prop: "flValue" },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "numrule",
                      staticStyle: { "margin-right": "20px" },
                      attrs: {
                        placeholder: "请输入终端红包返现金额",
                        type: "number",
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkNum($event, "flValue")
                        },
                      },
                      model: {
                        value: _vm.form.flValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "flValue", $$v)
                        },
                        expression: "form.flValue",
                      },
                    },
                    [_c("template", { slot: "prepend" })],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注:", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入红包备注", type: "textarea" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("form")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("form")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }