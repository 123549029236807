var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            staticClass: "codeForm",
            attrs: {
              model: _vm.codeForm,
              rules: _vm.codeFormRules,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "数码:", prop: "markCode" } },
                      [
                        _c("el-input", {
                          directives: [{ name: "Int", rawName: "v-Int" }],
                          attrs: { maxlength: "40" },
                          model: {
                            value: _vm.codeForm.markCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.codeForm, "markCode", $$v)
                            },
                            expression: "codeForm.markCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0" } },
                      [
                        _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                          _vm._v(" "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.searchBtn.type,
                              icon: _vm.btnConfig.searchBtn.icon,
                            },
                            on: { click: _vm.queryActivityByMarkCode },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.activityName || _vm.seqId
          ? _c(
              "div",
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c("h3", [_vm._v("数码活动")]),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-width": "100px",
                          "label-position": "left",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动ID:" } },
                          [
                            _vm.seqId == "暂无数据"
                              ? _c("span", [_vm._v(_vm._s(_vm.seqId))])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleToAct },
                                  },
                                  [_vm._v(_vm._s(_vm.seqId))]
                                ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "活动名称:" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.activityName) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }