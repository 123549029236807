var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("采供关系")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "采购商类型",
                                    prop: "purchaseType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.purchaseType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "purchaseType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.purchaseType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "公司", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "经销商", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "终端", value: 2 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "团购", value: 3 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "采购商",
                                    prop: "purchaseCodeAndName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      maxlength: "50",
                                      clearable: "",
                                      placeholder: "编码、名称",
                                    },
                                    model: {
                                      value: _vm.searchForm.purchaseCodeAndName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "purchaseCodeAndName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.purchaseCodeAndName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "供货商类型",
                                    "label-width": "90px",
                                    prop: "supplierType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.supplierType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "supplierType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.supplierType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "公司", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "经销商", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "终端", value: 2 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "团购", value: 3 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称",
                                    prop: "productId",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      readonly: "",
                                      placeholder: "",
                                    },
                                    on: { focus: _vm.openSel },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "供货商",
                                    prop: "supplierCodeAndName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "编码、名称",
                                    },
                                    model: {
                                      value: _vm.searchForm.supplierCodeAndName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "supplierCodeAndName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.supplierCodeAndName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "phead" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.searchBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.searchBtn.icon,
                              plain: _vm.btnConfig.searchBtn.plain,
                            },
                            on: {
                              click: function ($event) {
                                _vm.searchForm.current = 1
                                _vm.getList()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.resetBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.resetBtn.icon,
                              plain: _vm.btnConfig.resetBtn.plain,
                            },
                            on: { click: _vm.resetForm },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.btnConfig.resetBtn.text) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button-group",
                      { staticStyle: { "margin-left": "20px" } },
                      [
                        _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.addBtn.icon,
                                  plain: _vm.btnConfig.addBtn.plain,
                                },
                                on: { click: _vm.add },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.btnConfig.addBtn.text) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasAuth("deletebtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.delBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.delBtn.icon,
                                  plain: _vm.btnConfig.delBtn.plain,
                                },
                                on: { click: _vm.delAll },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.btnConfig.delBtn.text) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button-group",
                      { staticStyle: { "margin-left": "20px" } },
                      [
                        _vm.hasAuth("allinport", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.upLoadBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.upLoadBtn.icon,
                                  plain: _vm.btnConfig.upLoadBtn.plain,
                                },
                                on: { click: _vm.uploadShow },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.btnConfig.upLoadBtn.text) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.downLoadBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.downLoadBtn.icon,
                                  plain: _vm.btnConfig.downLoadBtn.plain,
                                  loading: _vm.downLoading,
                                },
                                on: { click: _vm.dowmLoad },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.btnConfig.downLoadBtn.text) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供货单位编码",
                      prop: "supplierCode",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供货单位名称",
                      prop: "supplierName",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供货单位类型",
                      prop: "supplierlType",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("channelTypeName")(row.supplierlType)
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      182240474
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位编码",
                      prop: "purchaseCode",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位名称",
                      prop: "purchaseName",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位类型",
                      prop: "purchaseType",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("channelTypeName")(row.purchaseType)
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2721956169
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采供商品",
                      prop: "productNum",
                      align: _vm.tableConfig.align,
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      width: "80",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.status == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("启用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("停用"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2244931452
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      align: _vm.tableConfig.align,
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("textDate")(row.createTime))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      953579966
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      "min-width": "90",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("scanbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLookFun(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == 1 &&
                              _vm.hasAuth("startbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.stopFun(row.pId)
                                        },
                                      },
                                    },
                                    [_vm._v("停用")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == 0 &&
                              _vm.hasAuth("startbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.startFun(row.pId)
                                        },
                                      },
                                    },
                                    [_vm._v("启用")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2775508665
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("Insert", {
            attrs: { visible: _vm.dialogVisible, title: _vm.title },
            on: { close: _vm.closeInsertFun },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("productSelectDialog", {
        ref: "selProduct",
        on: { change: _vm.setSel },
      }),
      _vm._v(" "),
      _vm.uploadVisible
        ? _c("uploading", {
            attrs: { visible: _vm.uploadVisible },
            on: { close: _vm.uploadingCloseFun },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }