"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      value: '',
      options: '',
      opts: []
    };
  },
  created: function created() {
    this.getGzh();
  },
  methods: {
    checkopt: function checkopt(val) {
      this.$router.go(0);
      this.$message.success('切换公众号成功');
      sessionStorage.setItem('appId', val);
    },
    getGzh: function getGzh() {
      var _this = this;
      (0, _wxgzh.getgzhlist)({
        appId: '1',
        qyorgId: '9999'
      }).then(function (res) {
        var ths = _this;
        var res = res.data;
        if (res.code * 1 === 200) {
          ths.options = res.data;
          ths.options.forEach(function (val) {
            var n = {
              appName: val.appName,
              appId: val.appId,
              disabled: false
            };
            ths.opts.push(n);
          });
          if (sessionStorage.getItem('appId') != null) {
            var wxName;
            ths.opts.forEach(function (val) {
              if (val.appId === sessionStorage.getItem('appId')) {
                wxName = val;
              }
            });
            ths.value = wxName.appName;
            wxName.disabled = true;
          } else {
            sessionStorage.setItem('appId', ths.opts[0].appId);
            ths.value = ths.opts[0].appName;
            ths.opts[0].disabled = true;
          }
        } else {
          ths.$message.warning(res.msg);
        }
      });
    }
  }
};
exports.default = _default;