"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _stockmange = require("@/api/chanelmange/stockmange");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangestockmangekctzindex';
var _default = {
  name: 'Chanelmangestockmangekctzindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    instockUnit: _channelSelectDialog.default,
    productChose: _productSelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      listQuery: {
        channelId: '',
        startTime: '',
        endTime: '',
        orgId: null,
        orderCode: '',
        productId: '',
        stockStatus: '',
        current: 1,
        size: 20
      },
      active: null,
      stockStatus: null,
      activeName: null,
      channelStr: '',
      channelId: '',
      productStr: '',
      orgOptions: [],
      orgprop: {
        label: 'label',
        value: 'id',
        children: 'children',
        checkStrictly: true
      },
      states: [{
        name: '已删除',
        code: 0
      }, {
        name: '待提交',
        code: 1
      }, {
        name: '待审核',
        code: 2
      }, {
        name: '已审核',
        code: 3
      }],
      unitSelect: {
        channelName: ''
      },
      title: '经销商库存调整',
      tableKey: 'kctz',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      exportLoading: false,
      // unitShow: false,
      // productShow: false,
      proShow: false,
      productsShow: false,
      controlType: '',
      addData: {
        channelId: '',
        channelType: '',
        deliverGoodsSum: '',
        imagesFiles: '',
        mtStockAdjustmentProductParams: [],
        orgId: '',
        pId: '',
        receivingGoodsSum: '',
        remark: '',
        stockStatus: ''
      },
      mtStockAdjustmentProductParams: [],
      uploadImgs: [],
      channelName: '',
      channelCode: '',
      channelClassName: '',
      orgName: '',
      shList: [],
      imgVisible: false,
      dialogImageUrl: '',
      scanVisible: false,
      scanData: {},
      dialogVisible: false,
      loading: false,
      dstate: false,
      submitType: '',
      required: true,
      dialogVisible2: false,
      checklist: [],
      preViewList: [],
      page2: false,
      showCol: false,
      expandTxt: '展开',
      auditRule: {
        flag: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      }
    }, (0, _defineProperty2.default)(_ref, "loading", false), (0, _defineProperty2.default)(_ref, "auditForm", {
      auditMsg: '',
      billNo: '',
      flag: ''
    }), (0, _defineProperty2.default)(_ref, "apis", []), _ref;
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    isAuth: function isAuth(val) {
      var flag = false;
      this.auth.filter(function (item) {
        if (item.resourceId == val) {
          flag = true;
        }
      });
      return flag;
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _stockmange.getRkList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockmange.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearJxs: function clearJxs() {
      var that = this;
      that.listQuery.channelId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      // that.listQuery = filterKeyNull(that.listQuery)
      that.listQuery.current = 1;
      that.getList();
      // that.listQuery.orgId = null
    },
    DoReset: function DoReset() {
      var that = this;
      that.productStr = '';
      that.channelStr = '';
      that.listQuery.channelId = '';
      that.listQuery.startTime = '';
      that.listQuery.endTime = '';
      that.listQuery.orgId = null;
      that.listQuery.orderCode = '';
      that.listQuery.productId = '';
      that.listQuery.stockStatus = '';
      that.listQuery.current = 1;
      that.listQuery.size = 20;
      that.getList();
    },
    // 获取经销商
    getUnit: function getUnit(val) {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.controlType = val;
      this.channelSelectType = 2;
      this.$refs.channelSelect.proSelVisible = true;
    },
    // hideUnit() {
    //   var that = this
    //   that.unitShow = false
    // },
    setCSel: function setCSel(val) {
      var that = this;
      var type = that.controlType;
      if (type == 'search') {
        if (val != '') {
          that.listQuery.channelId = val.pId;
          that.channelStr = val.channelName;
        }
      }
      if (type == 'add') {
        if (val != '') {
          console.log(val, 'val');
          that.addData.channelId = val.pId;
          that.addData.channelType = val.channelType;
          that.addData.orgId = val.orgId;
          that.channelName = val.channelName;
          that.channelCode = val.channelCode;
          that.channelClassName = val.channelClassName;
          that.orgName = val.orgName;
        }
      }
      // that.unitShow = false
    },
    // 获取产品
    getPname: function getPname() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.controlType = 'search';
      this.$refs.selProduct.proSelVisible = true;
    },
    // hideProduct() {
    //   var that = this
    //   that.listQuery.productId = ''
    //   that.listQuery.productStr = ''
    //   that.productShow = false
    // },
    setSel: function setSel(val) {
      var that = this;
      var type = that.controlType;
      if (type == 'search') {
        if (val != '') {
          that.listQuery.productId = val.pId;
          that.productStr = val.productName;
        }
      }
      // that.productShow = false
    },
    // 获取产品多选
    getMore: function getMore() {
      var that = this;
      if (that.addData.channelId == '') {
        that.$message.error('请选择经销商');
        return;
      }
      this.channelId = that.addData.channelId;
      this.proShow = true;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    getProducts: function getProducts(val) {
      var that = this;
      var channelId = that.addData.channelId;
      var arr = [];
      val.forEach(function (item) {
        var obj = {};
        obj.channelId = channelId;
        obj.productId = item.pId;
        arr.push(obj);
      });
      (0, _stockmange.getKcTzPlist)(arr).then(function (res) {
        if (res.data.code == 200) {
          that.transFormProdcutData(res.data.data);
        } else {
          that.$message.error(res.data.msg);
        }
      });
      that.productsShow = false;
    },
    transFormProdcutData: function transFormProdcutData(json) {
      var that = this;
      if (json.length > 0) {
        var arr = [];
        json.forEach(function (item) {
          var obj = {};
          obj.afterSum = item.afterSum;
          obj.availableSum = item.availableSum;
          obj.existingSum = item.existingSum;
          obj.minScaleId = item.minScaleId;
          obj.productId = item.productId;
          obj.remarks = item.remarks;
          obj.stockAdjustmentId = '';
          obj.minScaleName = item.minScaleName;
          obj.productCode = item.productCode;
          obj.productName = item.productName;
          obj.productModel = item.productModel;
          obj.packScaleDesc = item.packScaleDesc;
          obj.productClassName = item.productClassName;
          if (that.submitType == 'add') {
            obj.pId = item.pid;
          } else {
            obj.pId = item.pId;
          }
          arr.push(obj);
        });
        that.mtStockAdjustmentProductParams = that.uniqueArray(arr, 'productCode');
      } else {
        that.mtStockAdjustmentProductParams = [];
      }
    },
    // 删除新增产品列表
    removeItem: function removeItem(row) {
      var _this = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.mtStockAdjustmentProductParams.splice(_this.mtStockAdjustmentProductParams.indexOf(row), 1);
      });
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      that.listQuery.current = 1;
      that.listQuery.size = that.total;
      that.exportLoading = true;
      (0, _stockmange.exportData)(that.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '经销商库存调整列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.title = '库存经销商调整-添加';
      that.submitType = 'add';
      that.resetVoid();
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    resetVoid: function resetVoid() {
      var that = this;
      that.addData.channelId = '';
      that.addData.channelType = '';
      that.addData.deliverGoodsSum = '';
      that.addData.imagesFiles = '';
      that.addData.mtStockAdjustmentProductParams = [];
      that.addData.orgId = '';
      that.addData.pId = '';
      that.addData.receivingGoodsSum = '';
      that.addData.remark = '';
      that.addData.stockStatus = '';
      that.mtStockAdjustmentProductParams = [];
      that.uploadImgs = [];
      that.channelName = '';
      that.channelCode = '';
      that.channelClassName = '';
      that.orgName = '';
      that.shList = [];
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      var param = {};
      that.submitType = 'edit';
      param.pId = data.pId;
      that.getTDetails(param);
    },
    // 查看
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      that.submitType = 'scan';
      that.preViewList = [];
      var param = {};
      param.pId = data.pId;
      that.getTDetails(param);
    },
    DoSh: function DoSh(data) {
      var _this2 = this;
      var that = this;
      var setHeaderId = that.authId('importbtn', that.apis);
      that.submitType = 'sh';
      that.preViewList = [];
      var param = {};
      param.pId = data.pId;
      that.getTDetails(param);
      this.$nextTick(function () {
        _this2.$refs['auditForm'].clearValidate();
      });
    },
    audit: function audit() {
      var _this3 = this;
      var that = this;
      var setHeaderId = that.authId('kctzsh', that.apis);
      this.loading = true;
      this.dsate = true;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this3.auditForm.billNo = _this3.addData.orderCode;
          (0, _stockmange.DoShlist)(_this3.auditForm).then(function (res) {
            if (res.data.code == 200) {
              _this3.$message({
                type: 'success',
                message: '审核完成'
              });
              _this3.showClose();
              that.getList();
              _this3.auditForm = {
                auditMsg: '',
                billNo: '',
                flag: ''
              };
            } else {
              _this3.$message.error(res.data.msg);
            }
            _this3.loading = false;
            _this3.dsate = false;
          }).catch(function () {
            _this3.loading = false;
            _this3.dsate = false;
          });
        } else {
          _this3.$message.error('请完善信息！');
          _this3.loading = false;
          _this3.dsate = false;
        }
      });
    },
    showClose: function showClose() {
      this.dialogVisible2 = false;
      this.channelName = '';
      this.channelCode = '';
      this.channelClassName = '';
      this.orgName = '';
      this.orgName = '';
      this.activeName = '';
      this.preViewList = [];
      this.uploadImgs = [];
      this.addData = {
        channelId: '',
        channelType: '',
        deliverGoodsSum: '',
        imagesFiles: '',
        mtStockAdjustmentProductParams: [],
        orgId: '',
        pId: '',
        receivingGoodsSum: '',
        remark: '',
        stockStatus: ''
      };
    },
    // 获得详情数据
    getTDetails: function getTDetails(param) {
      var that = this;
      var type = that.submitType;
      (0, _stockmange.getKcTzDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            if (res.data.data.MtStockAdjustmentOrderOneVo != null) {
              var base = res.data.data.MtStockAdjustmentOrderOneVo;
              that.addData.channelId = base.channelId;
              that.addData.orderCode = base.orderCode;
              that.addData.channelType = 1;
              that.addData.deliverGoodsSum = base.deliverGoodsSum;
              that.addData.receivingGoodsSum = base.receivingGoodsSum;
              that.addData.orgId = base.orgId;
              that.addData.stockStatus = base.stockStatus;
              that.addData.pId = base.pId;
              that.addData.remark = base.remark;
              that.channelName = base.channelName;
              that.channelCode = base.channelCode;
              that.channelClassName = base.channelClassName;
              that.orgName = base.orgName;
              that.uploadImgs = [];
              if (base.imagesFiles != '' && base.imagesFiles != null) {
                var imgs = base.imagesFiles.split(',');
                for (var i = 0; i < imgs.length; i++) {
                  var obj = {};
                  obj.name = i;
                  obj.src = imgs[i];
                  obj.url = imgs[i];
                  that.preViewList.push(imgs[i]);
                  that.uploadImgs.push(obj);
                }
              }
              switch (base.stockStatus * 1) {
                case 0:
                  that.active = 2;
                  break;
                case 1:
                  that.active = 0;
                  break;
                case 2:
                  that.active = 1;
                  break;
                case 3:
                  that.active = 2;
                  break;
              }
            }
            if (res.data.data.MtStockAdjustmentProductVo != null) {
              var pros = res.data.data.MtStockAdjustmentProductVo;
              that.transFormProdcutData(pros);
            }
            if (res.data.data.MtAuditLogDO != null) {
              that.checklist = res.data.data.MtAuditLogDO;
            }
            if (type == 'edit') {
              that.dialogVisible = true;
            }
            if (type == 'scan' || type == 'sh') {
              that.dialogVisible2 = true;
            }
          } else {
            that.$message.error('未获取详情数据，无法进行下一步操作');
          }
        } else {
          if (type == 'edit') {
            that.$message.error('未获取详情数据，无法编辑');
            that.dialogVisible = false;
          }
          if (type == 'scan') {
            that.$message.error('未获取详情数据，无法查看');
            that.dialogVisible2 = false;
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 提交
    handleClose: function handleClose() {
      var that = this;
      that.resetForm();
    },
    resetForm: function resetForm() {
      var that = this;
      that.resetVoid();
      that.dialogVisible = false;
    },
    submitForm: function submitForm(val) {
      var that = this;
      that.addData.stockStatus = Number(val);
      if (that.channelName == '') {
        that.$message.error('请输入或选择经销');
        return;
      }
      var data = JSON.parse(JSON.stringify(that.addData));
      if (that.mtStockAdjustmentProductParams.length > 0) {
        var ps = that.mtStockAdjustmentProductParams;
        var newarr = [];
        for (var i = 0; i < ps.length; i++) {
          if (ps[i].afterSum == '') {
            that.$message.error('请输入调整后数量');
            return;
          }
          var obj = {};
          obj.afterSum = ps[i].afterSum;
          obj.availableSum = ps[i].availableSum;
          obj.existingSum = ps[i].existingSum;
          obj.minScaleId = ps[i].minScaleId;
          obj.pId = ps[i].pId;
          obj.productId = ps[i].productId;
          obj.remarks = ps[i].remarks;
          obj.stockAdjustmentId = that.addData.pId;
          newarr.push(obj);
        }
        data.mtStockAdjustmentProductParams = newarr;
      } else {
        this.$message.error('请选择产品');
        return;
      }
      if (that.uploadImgs.length > 0) {
        var imgs = that.uploadImgs;
        var imgarr = [];
        imgs.forEach(function (item) {
          imgarr.push(item.src);
        });
        data.imagesFiles = imgarr.join(',');
      } else {
        data.imagesFiles = '';
      }
      that.loading = true;
      that.dsate = true;
      delete data.orderCode;
      if (that.submitType == 'add') {
        (0, _stockmange.insertAndAuditStockAdjustment)(data).then(function (res) {
          if (res.data.code == 200) {
            that.loading = false;
            that.dsate = false;
            that.$message({
              type: 'success',
              message: '添加成功'
            });
            that.getList();
            that.handleClose();
          } else {
            that.$message.error(res.data.msg);
            that.loading = false;
            that.dsate = false;
            return;
          }
        }).catch(function () {
          that.loading = false;
          that.dsate = false;
        });
      }
      if (that.submitType == 'edit') {
        (0, _stockmange.editTZD)(data).then(function (res) {
          if (res.data.code == 200) {
            that.loading = false;
            that.dsate = false;
            that.$message({
              type: 'success',
              message: '修改成功'
            });
            that.getList();
            that.handleClose();
          } else {
            that.$message.error(res.data.msg);
            that.loading = false;
            that.dsate = false;
            return;
          }
        }).catch(function () {
          that.loading = false;
          that.dsate = false;
        });
      }
    },
    // 图片上传删除
    handleRemove: function handleRemove(file, fileList) {
      var that = this;
      var name = file.name;
      if (that.uploadImgs.length > 0) {
        for (var i = 0; i < that.uploadImgs.length; i++) {
          if (name == that.uploadImgs[i].name) {
            that.uploadImgs.splice(i, 1);
            break;
          }
        }
      }
    },
    // 图片预览
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgVisible = true;
    },
    // 图片上传
    uploadImg: function uploadImg(file, fileList) {
      var that = this;
      var len = that.uploadImgs.length;
      var name = file.name;
      var isJPG = file.raw.type === 'image/jpeg';
      var isPNG = file.raw.type === 'image/png';
      var isLtSize = file.raw.size / 1024 / 1024 <= 0.5;
      if (!isJPG && !isPNG) {
        that.$message.error('上传产品图片只能是 JPG/PNG 格式!');
        return;
      }
      if (!isLtSize) {
        that.$message.error('上传产品图片大小不能超过 500k!');
        return;
      }
      var formData = new FormData();
      formData.append('file', file.raw);
      that.loading = true;
      (0, _stockmange.upLoadImg)(formData).then(function (res) {
        if (res.data.code == 200) {
          that.loading = false;
          var obj = {};
          obj.name = name;
          obj.src = res.data.data;
          if (len < 5) {
            that.uploadImgs[len] = obj;
          }
        } else {
          that.handleRemove(file, fileList);
          that.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      });
    },
    handleExceed: function handleExceed(files, fileList) {
      var that = this;
      that.$message.error("\u5F53\u524D\u9650\u5236\u9009\u62E9 5\u4E2A\u6587\u4EF6\uFF0C\u60A8\u5DF2\u9009\u62E9\u4E865\u4E2A");
    },
    DoDelete: function DoDelete(data) {
      var that = this;
      var setHeaderId = that.authId('deletebtn', that.apis);
      var param = {};
      param.pId = data.pid;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _stockmange.delTZD)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('删除已取消');
      });
    },
    // json数组去重
    uniqueArray: function uniqueArray(array, key) {
      var result = [array[0]];
      for (var i = 1; i < array.length; i++) {
        var item = array[i];
        var repeat = false;
        for (var j = 0; j < result.length; j++) {
          if (item[key] == result[j][key]) {
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          result.push(item);
        }
      }
      return result;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;