var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "查看信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1100px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
        },
        [
          _c("div", { staticClass: "stepArea" }, [
            _c("div", { staticClass: "billTitle" }, [
              _c("img", { attrs: { src: require("@/images/topimg.png") } }),
              _vm._v(
                "\n        生产入库单 " +
                  _vm._s(_vm.detailData.tradeBillAllVO.billNo) +
                  "\n        "
              ),
              _c(
                "span",
                {
                  staticClass: "billType",
                  class: _vm._f("typeClass")(
                    _vm.detailData.tradeBillAllVO.billStatus
                  ),
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("filterStatus")(
                        _vm.detailData.tradeBillAllVO.billStatus,
                        _vm.detailData.tradeBillAllVO.billStatus
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "topRight" }, [
                _c("img", { attrs: { src: require("@/images/avatar.png") } }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.createUser)),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.createTime)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-descriptions",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { title: "基本信息", column: 2, border: "" },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "生产工厂:" } }, [
                _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.prodFactoryName)),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "关联生产计划单:" } },
                [_vm._v(_vm._s(_vm.detailData.tradeBillAllVO.relateNo))]
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "收货编码:" } }, [
                _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.inOrgCode)),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "收货单位名称:" } },
                [_vm._v(_vm._s(_vm.detailData.tradeBillAllVO.inOrgName))]
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "收货库房:" } }, [
                _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.inStoreName)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "单据状态:" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("filterStatus")(
                      _vm.detailData.tradeBillAllVO.billStatus,
                      _vm.detailData.tradeBillAllVO.billStatus
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建人:" } }, [
                _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.createUser)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建时间:" } }, [
                _vm._v(_vm._s(_vm.detailData.tradeBillAllVO.createTime)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "itemTitle",
              staticStyle: {
                border: "1px solid #e8e8e8",
                padding: "10px",
                "margin-bottom": "-1px",
                background: "#ececec",
              },
            },
            [
              _vm._v("\n      产品信息\n      "),
              _c("img", { attrs: { src: require("@/images/pro.png") } }),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%", "margin-top": "1px" },
              attrs: {
                data: _vm.proList,
                fit: "",
                "max-height": "300",
                "highlight-current-row": "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "生产批次",
                      prop: "prodBatchNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "产品分类",
                      prop: "prodClassName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品品牌",
                  prop: "prodBrandName",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "定制产品",
                  prop: "productType",
                  align: _vm.tableConfig.align,
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.productType == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("是"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.productType == 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packRadioDesc",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      prop: "packUnit",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划数量(瓶)",
                  prop: "planScanAtoNum",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.planScanAtoNum ? row.planScanAtoNum : 0)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生产入库数量(瓶)",
                  prop: "realScanAtoNum",
                  align: _vm.tableConfig.align,
                  "min-width": "110",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getCodesDetail(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.realScanAtoNum ? row.realScanAtoNum : 0
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "未入库数量(瓶)",
                  prop: "noInOrOutStorageAtoNum",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.autoOutList && _vm.autoOutList.length
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "itemTitle",
                      staticStyle: {
                        border: "1px solid #e8e8e8",
                        padding: "10px",
                        "margin-bottom": "-1px",
                        background: "#ececec",
                      },
                    },
                    [_vm._v("自动发货")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        data: _vm.autoOutList,
                        fit: "",
                        "max-height": "300",
                        "highlight-current-row": "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商编码",
                          prop: "inOrgCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商名称",
                          prop: "inOrgName",
                          align: _vm.tableConfig.align,
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作人",
                          prop: "operateUser",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.createTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2280372158
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              staticClass: "detailCollapse",
              staticStyle: { "margin-bottom": "20px" },
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "操作记录", name: "1" } },
                [
                  _c("template", { slot: "title" }, [_vm._v("操作记录")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tradeOperateLogDTOS,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "max-height": "300",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "120",
                          align: _vm.tableConfig.align,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作类型",
                          prop: "operateType",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("operateType")(row.operateType))
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作描述",
                          prop: "operateDesc",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作人",
                          prop: "operateUser",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("textDate")(row.createTime))
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作备注",
                          prop: "remark",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("codeDetails", {
            attrs: {
              "bill-no": _vm.billNo,
              "product-id": _vm.productId,
              "nc-product-id": _vm.ncProductId,
              "code-visible": _vm.codeVisible,
            },
            on: { close: _vm.codeHide },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }