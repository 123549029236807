"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/web.dom.iterable");
var _store = require("@/api/store");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// filterKeyNull
var _default = {
  name: 'LooseteaPurchasingpurchasingStorageindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      // 勾选
      multipleSelection: [],
      // 限量名称
      limitedList: [],
      listLoading: false,
      tableData: [],
      tabHeight: '100%',
      // 搜索
      ruleForm: {
        limitedEditionName: '',
        creatorName: '',
        batchNoList: [],
        relateNo: ''
      },
      total: 0,
      current: 1,
      size: 10,
      // 新增编辑
      ruleForm2: {
        limitedEditionName: '',
        batchNo: '',
        limitedNum: '',
        codeRulePrefix: '',
        codeRuleFirst: '',
        relateNo: ''
      },
      disabled: false,
      bcList: [],
      bcList2: [],
      rules: {
        limitedEditionName: [{
          required: true,
          message: '限量版名称不能为空，请维护',
          trigger: 'blur'
        }],
        batchNo: [{
          required: true,
          message: '批次号不能为空，请维护',
          trigger: 'change'
        }],
        limitedNum: [{
          required: true,
          message: '批次数量不能为空，请维护',
          trigger: 'change'
        }],
        codeRulePrefix: [{
          required: true,
          message: '不能为空，请维护',
          trigger: 'blur'
        }],
        codeRuleFirst: [{
          required: true,
          message: '不能为空，请维护',
          trigger: 'blur'
        }]
      },
      title: '',
      dialogFormVisible: false,
      scanVisible: false,
      mloading: false,
      lookList: {},
      listLoading2: false,
      tableData2: [],
      total2: 0,
      current2: 1,
      size2: 10,
      downLoading: false,
      downLoadTxt: '下载'
    };
  },
  activated: function activated() {
    this.getList();
    this.getqueryBathNoList(1);
    this.queryBatchSaleList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 列表
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _store.postPageQuery)(that.ruleForm, that.current, that.size).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        } else {
          if (response.data.data != null || response.data.data != '') {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 限量产品名称
    queryBatchSaleList: function queryBatchSaleList() {
      var _this = this;
      (0, _store.queryBatchSaleList)().then(function (result) {
        if (result.data.code != 200) {
          _this.$message.error(result.data.msg);
          return;
        } else {
          if (result.data.data != null || result.data.data != '') {
            _this.limitedList = result.data.data;
          } else {
            _this.limitedList = [];
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    unique: function unique(arr) {
      if (!Array.isArray(arr)) {
        console.log('type error!');
        return;
      }
      var arr1 = [];
      arr.forEach(function (item) {
        arr1.push(item.batchNo);
      });
      var array = [];
      for (var i = 0; i < arr1.length; i++) {
        if (!array.includes(arr1[i])) {
          // includes 检测数组是否有某个值
          array.push(arr1[i]);
        }
      }
      return array;
    },
    // 批次号
    getqueryBathNoList: function getqueryBathNoList(type) {
      var _this2 = this;
      (0, _store.queryBathNoList)({
        type: type
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null || res.data.data != '') {
            _this2.bcList = res.data.data;
            _this2.bcList2 = _this2.unique(res.data.data);
          } else {
            _this2.bcList = [];
            _this2.bcList2 = [];
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 批次号选择
    batchNochange: function batchNochange(val) {
      console.log(val);
      this.ruleForm2.batchNo = val.batchNo;
      this.ruleForm2.limitedNum = val.markCodeNum;
      this.ruleForm2.relateNo = val.orderNo;
    },
    bNchange: function bNchange(val) {
      console.log(val, this.ruleForm.batchNoList);
    },
    // 详情表格列表
    getList2: function getList2(batchNo, relateNo) {
      console.log(batchNo);
      var that = this;
      that.listLoading2 = true;
      (0, _store.postParticulars)({
        batchNo: batchNo,
        relateNo: relateNo
      }, that.current2, that.size2).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          that.listLoading2 = false;
          return;
        } else {
          if (response.data.data != null || response.data.data != '') {
            that.tableData2 = response.data.data.records;
            that.total2 = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading2 = false;
            }, 1 * 500);
            if (that.size2 == 99999) {
              that.download2(that.tableData2);
            }
          } else {
            that.tableData2 = [];
            that.total2 = 0;
            that.listLoading2 = false;
          }
        }
      }).catch(function () {
        that.listLoading2 = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm() {
      this.$refs['ruleForm'].resetFields();
      this.current = 1;
      this.getList();
    },
    // 添加请求
    postinsertBatchSale: function postinsertBatchSale(form) {
      var _this3 = this;
      (0, _store.insertBatchSale)(form).then(function (result) {
        console.log(result.data);
        if (result.data.code != 200) {
          _this3.$message.error(result.data.msg);
          return;
        } else {
          if (result.data.data != null || result.data.data != '') {
            _this3.$message.success('添加成功');
            _this3.resetForm2('ruleForm2');
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 编辑请求
    postupdateBatchSale: function postupdateBatchSale(form) {
      var _this4 = this;
      (0, _store.updateBatchSale)(form).then(function (result) {
        console.log(result.data);
        if (result.data.code != 200) {
          _this4.$message.error(result.data.msg);
          return;
        } else {
          if (result.data.data != null || result.data.data != '') {
            _this4.$message.success('提示：已经将原有信息更新为当前设置');
            _this4.resetForm2('ruleForm2');
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 弹窗确认
    submitForm2: function submitForm2(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _store.checkParams)(_this5.ruleForm2).then(function (result) {
            if (result.data.code != 200) {
              _this5.$message.error(result.data.msg);
              return;
            } else {
              if (result.data.data != null || result.data.data != '') {
                _this5.$message.success(result.data.msg);
                if (_this5.title == '新增限量发售ID') {
                  _this5.postinsertBatchSale(_this5.ruleForm2);
                } else if (_this5.title == '编辑') {
                  _this5.postupdateBatchSale(_this5.ruleForm2);
                }
              }
            }
          }).catch(function (err) {
            console.log(err);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 弹窗关闭
    resetForm2: function resetForm2(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      this.title = '';
      this.ruleForm2 = {
        limitedEditionName: '',
        batchNo: '',
        limitedNum: '',
        codeRulePrefix: '',
        codeRuleFirst: ''
      };
      console.log(this.ruleForm2, '重置');
      this.getList();
      this.getqueryBathNoList(1);
    },
    // 添加
    addE: function addE() {
      this.title = '新增限量发售ID';
      this.dialogFormVisible = true;
      this.getqueryBathNoList(2);
    },
    // 删除
    getdelete: function getdelete() {
      var _this6 = this;
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _store.deleteBatchSale)(_this6.multipleSelection[0].pid).then(function (result) {
          console.log(result.data);
          if (result.data.code != 200) {
            _this6.$message.error(result.data.msg);
            return;
          } else {
            if (result.data.data != null || result.data.data != '') {
              _this6.$message.success('删除成功');
              _this6.multipleSelection = [];
              _this6.getList();
            }
          }
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 勾选
    select: function select(selection, row) {
      console.log(selection, '单选');
      this.multipleSelection = selection;
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
        console.log(selection, '单选');
        this.multipleSelection = selection;
      }
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    // 查看
    look: function look(row) {
      console.log(row, '查看');
      this.scanVisible = true;
      this.lookList = row;
      this.getList2(row.batchNo, row.relateNo);
    },
    // 关闭查看
    handleClose: function handleClose() {
      this.scanVisible = false;
      this.tableData2 = [];
      this.lookList = {};
      this.size2 = 10;
    },
    // 导出
    download: function download() {
      this.size2 = 99999;
      this.getList2(this.lookList.batchNo);
    },
    download2: function download2(listData) {
      var that = this;
      that.downLoadTxt = '下载中...';
      that.downLoading = true;
      var tHeader = ['限量ID编码', '关联数码', '扫码状态'];
      var filterVal = ['limitedMarkcode', 'markcode', 'codeScanningStatus'];
      var list = listData;
      var data = this.formatJson(filterVal, list);
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        excel.export_json_to_excel({
          filename: '限量发售',
          header: tHeader,
          // 表头 必填
          data: data,
          // 具体数据 必填
          autoWidth: true,
          // 非必填
          bookType: 'xlsx' // 非必填
        });
      });

      setTimeout(function () {
        that.downLoading = false;
      }, 1 * 500);
      that.downLoadTxt = '下载';
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (v.codeScanningStatus == 0) {
            v.codeScanningStatus = '未扫';
          } else if (v.codeScanningStatus == 1) {
            v.codeScanningStatus = '已扫';
          }
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          return v[j];
          // }
        });
      });
    },
    // 编辑权限
    checkStockOut: function checkStockOut(batchNo, relateNo) {
      var _this7 = this;
      (0, _store.checkStockOut)(batchNo, relateNo).then(function (result) {
        console.log(result.data);
        if (result.data.code != 200) {
          _this7.$message.error(result.data.msg);
        } else {
          if (result.data.data == true) {
            _this7.disabled = true;
          } else if (result.data.data == false) {
            _this7.disabled = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 编辑
    edit: function edit(row) {
      console.log(row);
      this.title = '编辑';
      this.dialogFormVisible = true;
      this.ruleForm2.limitedEditionName = row.limitedEditionName;
      this.ruleForm2.batchNo = row.batchNo;
      this.ruleForm2.limitedNum = row.limitedNum;
      this.ruleForm2.pid = row.pid;
      this.getNum(row.startCode);
      this.checkStockOut(row.batchNo, row.relateNo);
      console.log(this.disabled);
    },
    getNum: function getNum(text) {
      var value = text.replace(/[^0-9]/ig, '');
      var b = text.replace(/[^A-Z]+/ig, '');
      console.log(value);
      console.log(b);
      this.ruleForm2.codeRulePrefix = b;
      this.ruleForm2.codeRuleFirst = value;
    }
  }
};
exports.default = _default;