"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _modelUploadImg = _interopRequireDefault(require("@/components/modelUploadImg"));
var _templateManage = require("@/api/market/templateManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var infoBg = require('@/images/info-bg.png');
var infoBtnBg = require('@/images/info-btn.png');
var _default2 = {
  components: {
    UploadImg: _modelUploadImg.default
  },
  props: {
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    editType: {
      type: String,
      default: function _default() {
        return 'add';
      }
    },
    editData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      from: '',
      listLoading: false,
      activeName: 'basic',
      active: 0,
      activeMax: 2,
      checkName: '',
      bgImg: '',
      info: [],
      checkBoxList: [],
      checkList: [],
      renderList: [],
      label: '选项图1:',
      cardIndex: 1,
      rotate1: false,
      rotate2: false,
      showBack: true,
      showFront: false,
      infoBg: infoBg,
      infoBtnBg: infoBtnBg,
      // horseRaceLamp: "",
      basicForm: {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '1',
        templateType: '1'
      },
      contentForm: {
        title: '',
        background: '',
        sudokuImg: '',
        squareItemsImg: '',
        squareItemsActiveImg: '',
        startBtnImg: '',
        // startClickBtnImg: "",
        // activityBag: "",
        // horseRaceLamp: [],
        squares: [{
          backImg: '',
          priority: ''
        },
        // 选项1
        {
          backImg: '',
          priority: ''
        },
        // 选项2
        {
          backImg: '',
          priority: ''
        },
        // 选项3
        {
          backImg: '',
          priority: ''
        },
        // 选项4
        {
          backImg: ''
        },
        // 选项5  中间按钮
        {
          backImg: '',
          priority: ''
        },
        // 选项6
        {
          backImg: '',
          priority: ''
        },
        // 选项7
        {
          backImg: '',
          priority: ''
        },
        // 选项8
        {
          backImg: '',
          priority: ''
        } // 选项9
        ]
      },

      prioritys: [{
        name: '一等奖',
        id: 1
      }, {
        name: '二等奖',
        id: 2
      }, {
        name: '三等奖',
        id: 3
      }, {
        name: '四等奖',
        id: 4
      }, {
        name: '五等奖',
        id: 5
      }, {
        name: '六等奖',
        id: 6
      }, {
        name: '七等奖',
        id: 7
      }, {
        name: '八等奖',
        id: 8
      }, {
        name: '谢谢惠顾',
        id: 99999
      }],
      infoForm: {
        openDataCollection: '2',
        openLocation: '2',
        relevanceDataId: '',
        collectionBtn: ''
      },
      rules: {
        templateName: [{
          required: !this.disabled,
          message: '模版名称不能为空',
          trigger: 'change'
        }],
        templateStatus: [{
          required: !this.disabled,
          message: '请选择模板状态',
          trigger: 'change'
        }]
      },
      contentRules: {
        background: [{
          required: true,
          message: '请上传背景',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '请上传标题图片',
          trigger: 'change'
        }],
        squareItemsActiveImg: [{
          required: true,
          message: '请上传方格活动背景图',
          trigger: 'change'
        }],
        sudokuImg: [{
          required: true,
          message: '请上传九宫格背景图片',
          trigger: 'change'
        }],
        // horseRaceLamp: [
        //   {
        //     required: true,
        //     message: "请至少上传一个跑马灯图片",
        //     trigger: "change",
        //   },
        // ],
        squareItemsImg: [{
          required: true,
          message: '请上传方格背景图片',
          trigger: 'change'
        }],
        startBtnImg: [{
          required: true,
          message: '请上传抽奖按钮图片',
          trigger: 'change'
        }]
        // startClickBtnImg: [
        //   {
        //     required: true,
        //     message: "请上传抽奖按钮(点击效果)图片",
        //     trigger: "change",
        //   },
        // ],
      },

      infoRules: {},
      disabledLoading: false,
      isrelatAct: false
    };
  },
  watch: {
    'contentForm.startBtnImg': {
      handler: function handler(newValue) {
        this.contentForm.squares[4].backImg = newValue;
      }
    }
    // horseRaceLamp: {
    //   handler(newValue) {
    //     console.log();
    //     if (this.active !== 1) return;
    //     if (this.contentForm.horseRaceLamp.length >= 3) {
    //       this.$message.error("最多上传三张跑马灯图片");
    //       return;
    //     }
    //     newValue && this.contentForm.horseRaceLamp.push(newValue);
    //   },
    // },
  },
  mounted: function mounted() {
    this.basicForm.id = this.id;
    this.basicForm.templateType = this.type;
    this.getInfo();
  },
  methods: {
    // 获取收集信息
    getInfo: function getInfo() {
      var _this = this;
      (0, _templateManage.getCollectionInfo)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this.checkBoxList = res.data;
          _this.getDetail();
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 监听收集项选择
    handleCheckedInfoChange: function handleCheckedInfoChange(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].fieldName) {
              this.renderList.push(this.checkBoxList[j]);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 设置背景图片
    setBgImg: function setBgImg(val) {
      this.bgImg = val;
    },
    // 取消
    goBack: function goBack() {
      this.active = 0;
      this.$refs.basicForm.resetFields();
      this.$refs.contentForm.resetFields();
      this.$refs.infoForm.resetFields();
      this.basicForm = {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '1',
        templateType: '1'
      };
      this.contentForm = {
        title: '',
        background: '',
        obtainBtn: '',
        prizeBg: '',
        openPrizeBg: '',
        templateType: '1'
      };
      this.infoForm = {
        openDataCollection: '2',
        openLocation: '2',
        relevanceDataId: '',
        collectionBtn: ''
      };
      this.$emit('close');
    },
    getCountTemplate: function getCountTemplate(id) {
      var _this2 = this;
      (0, _templateManage.getCountTemplate)({
        templateId: id
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            var relatActCount = parseInt(res.data.data);
            _this2.isrelatAct = !!relatActCount;
          } else {
            _this2.$message.error(res.data.data.errMsg);
          }
        } else {
          return _this2.$message.error(res.data.msg);
        }
      });
    },
    // 获取模板详细
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
      this.getCountTemplate(this.id);
      var item = this.editData;
      var paraseData = JSON.parse(this.editData.templateElements);
      this.bgImg = paraseData.background;
      this.basicForm = {
        id: item.id,
        templateName: item.templateName,
        templateRemark: item.templateRemark,
        templateStatus: item.templateStatus,
        templateCategory: item.templateCategory,
        templateType: item.templateType
      };
      this.contentForm = {
        title: paraseData.title,
        background: paraseData.background,
        squares: paraseData.squares,
        sudokuImg: paraseData.sudokuImg,
        squareItemsImg: paraseData.squareItemsImg,
        startBtnImg: paraseData.startBtnImg,
        squareItemsActiveImg: paraseData.squareItemsActiveImg
        // activityBag: paraseData.activityBag,
        // horseRaceLamp: paraseData.horseRaceLamp || [],
        // startClickBtnImg: paraseData.startClickBtnImg,
      };
      // this.horseRaceLamp = this.contentForm.horseRaceLamp[0];

      this.infoForm = {
        openDataCollection: item.openDataCollection,
        openLocation: item.openLocation,
        relevanceDataId: item.relevanceDataId,
        collectionBtn: paraseData.collectionBtn
      };
      if (item.relevanceDataId) {
        var newArr = item.relevanceDataId.split(',');
        this.transChangeData(newArr);
      }
    },
    // 是否数据收集切换
    switchChange: function switchChange(val) {
      if (val == '2') {
        this.checkList = [];
        this.renderList = [];
      }
    },
    // 检验奖项是否重复
    checkSame: function checkSame(e, index) {
      for (var i = 0; i < this.contentForm.squares.length; i++) {
        if (i != index && this.contentForm.squares[i].priority == e) {
          this.contentForm.squares[index].priority = null;
          this.$message.error('奖品等级重复，请重新配置！');
          break;
        }
      }
    },
    // delHorseRaceLampItem(index) {
    //   // if (this.contentForm.horseRaceLamp.length <= 0) {
    //   //   this.$message.error("");
    //   //   return;
    //   // }
    //   this.contentForm.horseRaceLamp.splice(index, 1);
    // },
    // 数据转化
    transChangeData: function transChangeData(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        this.checkList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].id) {
              this.renderList.push(this.checkBoxList[j]);
              this.checkList.push(this.checkBoxList[j].fieldName);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 保存
    save: function save() {
      var _this3 = this;
      this.contentForm.collectionBtn = this.infoForm.collectionBtn;
      var cForm = {
        templateElements: JSON.stringify(this.contentForm)
      };
      var arr = [];
      if (this.renderList.length > 0) {
        this.renderList.forEach(function (item) {
          arr.push(item.id);
        });
      }
      this.infoForm.relevanceDataId = arr.length > 0 ? arr.join(',') : '';
      var info = JSON.parse(JSON.stringify(this.infoForm));
      delete info.collectionBtn;
      var object = Object.assign(this.basicForm, info, cForm);
      this.disabledLoading = true;
      if (this.editType == 'add') {
        (0, _templateManage.templateAdd)(object).then(function (res) {
          _this3.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this3.$message({
                message: '添加成功！',
                type: 'success'
              });
              _this3.goBack();
            } else {
              _this3.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this3.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
      if (this.editType == 'edit') {
        (0, _templateManage.templateEdit)(object).then(function (res) {
          _this3.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this3.$message({
                message: '修改成功！',
                type: 'success'
              });
              _this3.goBack();
            } else {
              _this3.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this3.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
    },
    // 按钮事件
    subForm: function subForm(text) {
      var _this4 = this;
      var that = this;
      // 上一步
      if (text === 'up') {
        that.active--;
      } else if (text === 'down') {
        // 下一步
        if (this.active === 0) {
          var basic = this.checkForm('basicForm', '请完善基础信息');
          basic.then(function () {
            that.rotate1 = false;
            that.rotate2 = false;
            that.showFront = false;
            that.showBack = true;
            that.active++;
          });
        } else if (this.active === 1) {
          var content = this.checkForm('contentForm', '请完善活动信息');
          var squares = this.contentForm.squares;
          var indexThanks = this.contentForm.squares.findIndex(function (item) {
            return item.priority == 99999;
          });
          if (indexThanks == -1) {
            return this.$message.error('必须选中一个谢谢惠顾');
          }
          content.then(function () {
            var next = true;
            var _loop = function _loop(i) {
              if (!squares[i].backImg) {
                var namePriority = _this4.prioritys[_this4.prioritys.findIndex(function (item) {
                  return item.id == squares[i].priority;
                })].name;
                _this4.$message.error("\u8BF7\u4E0A\u4F20\u9009\u9879".concat(namePriority, "\u56FE\u7247"));
                next = false;
                return "break";
              }
            };
            for (var i = 0; i < squares.length; i++) {
              var _ret = _loop(i);
              if (_ret === "break") break;
            }
            if (next) {
              that.active++;
            }
          });
        }
      }
    },
    checkForm: function checkForm(val, text) {
      var _this5 = this;
      return new Promise(function (resolve, reject) {
        _this5.$refs[val].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            _this5.$message.closeAll();
            _this5.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
            reject(text);
          }
        });
      });
    }
  }
};
exports.default = _default2;