var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              staticClass: "search-condition",
              attrs: {
                model: _vm.queryParams,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动方案编码" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.queryParams.schemeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "schemeCode", $$v)
                                  },
                                  expression: "queryParams.schemeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动方案名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.queryParams.schemeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "schemeName", $$v)
                                  },
                                  expression: "queryParams.schemeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "状态", clearable: "" },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "禁用", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "删除", value: "3" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "待审核", value: "4" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "已过期", value: "6" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.queryList },
                                },
                                [_vm._v("查询")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.restPrizeList },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("新增")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadig,
                  expression: "listLoadig",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: _vm.tableConfig.align,
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "schemeCode",
                  label: "方案编码",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "schemeName",
                  label: "方案名称",
                  "min-width": "160",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "remarks",
                  label: "备注",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "parentSchemeName",
                  label: "原始方案",
                  "min-width": "160",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "开始时间-截止时间",
                  align: _vm.tableConfig.align,
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("formatTime")(row.startTime))),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("formatTime")(row.endTime))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "documentNum",
                  label: "关联单据数量",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "planNum",
                  label: "计划数量（瓶/盒）",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "supplementNum",
                  label: "增补数量（瓶/盒）",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "actualNum",
                  label: "实发数量（瓶/盒）",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "scanNum",
                  label: "扫码量（瓶/盒）",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "scanPercentage",
                  label: "扫码率",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "bingdingNum",
                  label: "绑定量（箱）",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "binddingPercentage",
                  label: "绑定率",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  align: _vm.tableConfig.align,
                  formatter: _vm.showStatus,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 3
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("删除"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 4
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 6
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("已过期"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "creatorName",
                  label: "创建人",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "160",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  "min-width": "180",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getflow(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setUnUse(scope.row)
                                  },
                                },
                              },
                              [_vm._v("禁用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setInUse(scope.row)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelItem(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getPrizeList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "方案类型:", prop: "type", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeTab },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("普通方案"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("增补方案"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.type == 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "原始方案编码:",
                        prop: "parentSchemeCode",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changeway },
                          model: {
                            value: _vm.form.parentSchemeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parentSchemeCode", $$v)
                            },
                            expression: "form.parentSchemeCode",
                          },
                        },
                        _vm._l(_vm.tableList, function (item) {
                          return _c("el-option", {
                            key: item.schemeCode,
                            attrs: {
                              label: item.schemeName,
                              value: item.schemeCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "方案名称:", prop: "schemeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入方案名称",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.form.schemeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "schemeName", $$v)
                      },
                      expression: "form.schemeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "方案编码:", prop: "schemeCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入方案编码",
                      disabled: _vm.title == "编辑活动方案",
                    },
                    model: {
                      value: _vm.form.schemeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "schemeCode", $$v)
                      },
                      expression: "form.schemeCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "方案有效时间：", prop: "dataTimeRange" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions1,
                    },
                    model: {
                      value: _vm.form.dataTimeRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dataTimeRange", $$v)
                      },
                      expression: "form.dataTimeRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "启用",
                      "inactive-text": "禁用",
                      "active-value": "1",
                      "inactive-value": "2",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注:", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "消费者活动规则:",
                    prop: "ruleImg",
                    "is-required": "",
                  },
                },
                [
                  _c("Upload-img", {
                    ref: "ruleImg",
                    attrs: {
                      data: _vm.form.ruleImg,
                      text: "",
                      "limit-count": 1,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.form, "ruleImg", $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(
                      "建议上传：宽度750的PND或JPG图片，不超过200kB\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "终端推广页面:",
                    prop: "generalizationImg",
                    "is-required": "",
                  },
                },
                [
                  _c("Upload-img", {
                    ref: "generalImgs",
                    attrs: {
                      data: _vm.form.generalizationImg,
                      text: "",
                      "limit-count": 1,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.form, "generalizationImg", $event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v(
                      "建议上传：宽度750的PND或JPG图片，不超过200kB\n        "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("form")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel("form")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }