"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _rkls = require("@/api/chanelmange/rkls");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _accountDialogChoose = _interopRequireDefault(require("@/components/accountDialogChoose"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangestockreportrklsindex';
var _default = {
  name: 'Chanelmangestockreportrklsindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    purchaseDialog: _purchaseDialog.default,
    productChose: _productSelectDialog.default,
    accountDialog: _accountDialogChoose.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        endTime: '',
        startTime: '',
        accountCode: '',
        billClass: 1,
        // 单据分类： 1：入库 2：出库
        billNo: '',
        billType: '',
        inOrgCode: '',
        outOrgCode: '',
        pageNo: 1,
        pageSize: 20,
        productCode: '',
        sumType: '',
        // 1：按产品汇总，2：按收发货单位汇总
        channelCode: ''
      },
      orgOptions: [],
      date: '',
      normalDate: '',
      activeIndex: '1',
      productStr: '',
      businessUser: '',
      businessOption: [],
      tableKey: 'rkls',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      unitType: '',
      productShow: false,
      personShow: false,
      exportLoading: false,
      accountShow: false,
      billTypes: [{
        name: '退货入库',
        code: '103'
      }, {
        name: '采购入库',
        code: '104'
      }, {
        name: '其他入库',
        code: '105'
      }],
      orderStats: [
      // { name: '已删除', code: '1' },
      {
        name: '已提交',
        code: '1'
      },
      // { name: '待审核', code: '3' },
      {
        name: '全部入库',
        code: '4'
      }],
      codes: [{
        name: '是',
        code: '1'
      }, {
        name: '否',
        code: '0'
      }],
      recChannelStr: '',
      sendChannelStr: '',
      accountStr: '',
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      page2: false,
      showCol: false,
      expandTxt: '展开',
      currentUser: JSON.parse(localStorage.getItem('currentUser'))
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        // 近一周时间
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        that.date = [start, end];
        that.normalDate = [start, end];
        that.getOrg();
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var channelType = that.activeIndex;
      var param = {};
      param = (0, _utils.deepClone)(that.listQuery);
      param.channelCode = this.currentUser.channelCode || '';
      param.startTime = this.date ? (0, _utils.parseTime)(this.date[0], '{y}-{m}-{d}') : '';
      param.endTime = this.date ? (0, _utils.parseTime)(this.date[1], '{y}-{m}-{d}') : '';
      if (channelType == '1') {
        (0, _rkls.getJxsOrZdRkList)(param).then(function (res) {
          if (res.data.code == 200) {
            that.list = res.data.data.records;
            that.total = parseInt(res.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.$message.error(res.data.msg);
            that.listLoading = false;
            return;
          }
        }).catch(function () {
          that.listLoading = false;
        });
      }
      if (channelType == '2') {
        (0, _rkls.getZdRkList)(param).then(function (res) {
          if (res.data.code == 200) {
            that.list = res.data.data.records;
            that.total = parseInt(res.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.$message.error(res.data.msg);
            that.listLoading = false;
            return;
          }
        }).catch(function () {
          that.listLoading = false;
        });
      }
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _rkls.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearSh: function clearSh() {
      var that = this;
      that.listQuery.recChannelId = '';
    },
    clearFh: function clearFh() {
      var that = this;
      that.listQuery.sendChannelId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    clearYw: function clearYw() {
      var that = this;
      that.listQuery.businessUserId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      if (formName) {
        this.activeIndex = formName;
      }
      this.date = this.normalDate;
      var that = this;
      that.listQuery = {
        endTime: '',
        startTime: '',
        accountCode: '',
        billClass: 1,
        // 单据分类： 1：入库 2：出库
        billNo: '',
        billType: '',
        inOrgCode: '',
        outOrgCode: '',
        pageNo: 1,
        pageSize: 20,
        productCode: '',
        sumType: '',
        // 1：按产品汇总，2：按收发货单位汇总
        channelCode: ''
      };
      that.businessOption = [];
      that.businessUser = '';
      that.recChannelStr = '';
      that.accountStr = '';
      that.sendChannelStr = '';
      that.productStr = '';
      that.getList();
    },
    // 获取产品名称
    choseProName: function choseProName(val) {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.productStyle = val;
      this.$refs.selProduct.proSelVisible = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    accountChoose: function accountChoose() {
      this.accountShow = true;
    },
    accountClose: function accountClose() {
      this.accountShow = false;
    },
    setAccount: function setAccount(val) {
      if (val) {
        this.accountStr = val.groupName;
        this.listQuery.accountCode = val.groupCode;
      }
    },
    getProduct: function getProduct(val) {
      var that = this;
      // that.listQuery.productId = val.pId
      that.listQuery.productCode = val.productCode;
      that.productStr = val.productName;
      that.productShow = false;
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var _this = this;
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.unitType = val;
      // that.unitShow = true
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'in') {
        // that.listQuery.recChannelId = val.pId
        that.listQuery.inOrgCode = val.channelCode;
        that.recChannelStr = val.channelName;
      }
      if (that.unitType == 'out') {
        // that.listQuery.sendChannelId = val.pId
        that.listQuery.outOrgCode = val.channelCode;
        that.sendChannelStr = val.channelName;
      }
      that.unitShow = false;
    },
    // 获取业务类型
    getbType: function getbType(val) {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      this.listQuery.businessTypeCode = '';
      if (val) {
        that.getBusType(val);
      } else {
        that.businessOption = [];
      }
    },
    getBusType: function getBusType(val) {
      var that = this;
      var param = {
        billEnum: val,
        bussinessStatus: '',
        current: 1,
        outgoType: '2',
        size: 2147483647
      };
      (0, _rkls.getType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.businessOption = res.data.data.records;
        }
      });
    },
    // 选择业务员
    chosePerson: function chosePerson() {
      var that = this;
      that.personShow = true;
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      that.listQuery.businessUserId = JSON.parse(val).pid;
      that.businessUser = JSON.parse(val).userName;
      that.personShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var type = that.activeIndex;
      var param = {};
      param = (0, _utils.deepClone)(that.listQuery);
      param.pageSize = that.total;
      // param.channelType = channelType
      param.startTime = this.date ? (0, _utils.parseTime)(this.date[0], '{y}-{m}-{d}') : '';
      param.endTime = this.date ? (0, _utils.parseTime)(this.date[1], '{y}-{m}-{d}') : '';
      param.channelCode = this.currentUser.channelCode || '';
      that.exportLoading = true;
      if (type == '1') {
        (0, _rkls.flowListExport)(param).then(function (res) {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '经销商入库列表.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          that.exportLoading = false;
        }).catch(function () {
          that.exportLoading = false;
        });
      }
      if (type == '2') {
        (0, _rkls.exportZdRkList)(param).then(function (res) {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '终端入库列表.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          that.exportLoading = false;
        }).catch(function () {
          that.exportLoading = false;
        });
      }
    },
    handleSelect: function handleSelect() {
      var that = this;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;