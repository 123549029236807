"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteById = deleteById;
exports.isReady = isReady;
exports.messageTemplateadd = messageTemplateadd;
exports.messageTemplatedel = messageTemplatedel;
exports.messageTemplateldetail = messageTemplateldetail;
exports.messageTemplatelist = messageTemplatelist;
exports.messageTemplateupdate = messageTemplateupdate;
exports.msgList = msgList;
exports.noticeConfigenable = noticeConfigenable;
exports.noticeConfiginsert = noticeConfiginsert;
exports.noticeConfiglist = noticeConfiglist;
exports.noticeConfigupdate = noticeConfigupdate;
exports.noticeDetail = noticeDetail;
exports.noticeList = noticeList;
exports.sum = sum;
var _request = _interopRequireDefault(require("@/utils/request"));
// 消息模板列表
function messageTemplatelist(params) {
  return (0, _request.default)({
    url: '/api-message/messageTemplate/list',
    method: 'get',
    params: params
  });
}

// 根据pid获取消息模板详情
function messageTemplateldetail(params) {
  return (0, _request.default)({
    url: '/api-message/messageTemplate/detail',
    method: 'get',
    params: params
  });
}

// 创建消息模板
function messageTemplateadd(data) {
  return (0, _request.default)({
    url: '/api-message/messageTemplate/add',
    method: 'post',
    data: data
  });
}

// 修改消息模板
function messageTemplateupdate(data) {
  return (0, _request.default)({
    url: '/api-message/messageTemplate/update',
    method: 'put',
    data: data
  });
}

// 根据pid删除消息模板
function messageTemplatedel(params) {
  return (0, _request.default)({
    url: '/api-message/messageTemplate/del',
    method: 'delete',
    params: params
  });
}

// 消息首页统计
function sum(params) {
  return (0, _request.default)({
    url: '/api-message/smg/index/sum',
    method: 'get',
    params: params
  });
}

// nav公告列表
function noticeList(data) {
  return (0, _request.default)({
    url: '/api-message/smg/index/noticeList',
    method: 'post',
    data: data
  });
}

// nav消息列表
function msgList(data) {
  return (0, _request.default)({
    url: '/api-message/smg/index/msgList',
    method: 'post',
    data: data
  });
}

// 删除消息
function deleteById(params) {
  return (0, _request.default)({
    url: '/api-message/msgInfo/deleteById',
    method: 'get',
    params: params
  });
}

// 公告管理列表
function noticeConfiglist(data) {
  return (0, _request.default)({
    url: '/api-message/noticeConfig/list',
    method: 'post',
    data: data
  });
}

// 用户消息已读/全部消息标记已读
function isReady(data) {
  return (0, _request.default)({
    url: '/api-message/smg/index/isReady',
    method: 'post',
    data: data
  });
}

// 用户公告详情
function noticeDetail(params) {
  return (0, _request.default)({
    url: '/api-message/noticeConfig/detail',
    method: 'get',
    params: params
  });
}

// 公告管理添加
function noticeConfiginsert(data) {
  return (0, _request.default)({
    url: '/api-message/noticeConfig/insert',
    method: 'post',
    data: data
  });
}

// 公告管理编辑
function noticeConfigupdate(data) {
  return (0, _request.default)({
    url: '/api-message/noticeConfig/update',
    method: 'post',
    data: data
  });
}

// 公告管理删除/发布
function noticeConfigenable(params) {
  return (0, _request.default)({
    url: '/api-message/noticeConfig/enable',
    method: 'get',
    params: params
  });
}