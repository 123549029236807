"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
var _wxmake = require("@/api/wxhyyy/wxmake");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import admincut from "@/components/admincut";

var baseUrl = '/wh';
var _default = {
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    },
    typemarke: function typemarke(status) {
      var statusMap = {
        0: '计划开始后立即发送',
        1: '定时发送',
        2: '计划开始后每天',
        3: '计划开始后每周',
        4: '计划开始后每月'
      };
      return statusMap[status];
    },
    typemarke1: function typemarke1(status) {
      var statusMap = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '日'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      tjonbtn: false,
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      ymd: 'HH:mm:ss',
      fstpUrl: '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      value3: '',
      choiceDate: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
        }
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
        }
      },
      options: [],
      hylist: [],
      diatz: false,
      diayx: false,
      distime: false,
      tabPosition: 'left',
      tabPosition1: 'left',
      sjje1: '',
      sjje2: '',
      maketime: '',
      form: {
        planName: '',
        bizSerialNo: '',
        itemList: [{
          intervalValue: '',
          marketType: '0',
          numType: '0',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        }],
        labelCodes: [],
        memberLevel: [],
        planStartTime: '',
        planEndTime: '',
        planStatus: '',
        planType: '0',
        redPackCount: '',
        scoreCount: '',
        smsCount: '',
        targetType: '1',
        updaterId: ''
      },
      redPackCount: '',
      scoreCount: '',
      smsCount: '',
      vipdata: '1',
      options1: [{
        name: '周一',
        value: 1
      }, {
        name: '周二',
        value: 2
      }, {
        name: '周三',
        value: 3
      }, {
        name: '周四',
        value: 4
      }, {
        name: '周五',
        value: 5
      }, {
        name: '周六',
        value: 6
      }, {
        name: '周日',
        value: 7
      }],
      msglist: [{
        text: ''
      }],
      hylist1: [],
      msList: [
        // {
        //   text: ''
        // }
      ],
      // 展示列表
      activeName: 'one',
      tMindex: 0,
      // itemlist索引
      dlogphone: false,
      dloghydj: false,
      dlogcg: false,
      diamb: false,
      mouthvl: '',
      mblist: [],
      mbname: '',
      bqlist: [],
      dlogbq: false,
      edtype: null,
      olditem: null,
      oldtMindex: null
    };
  },
  watch: {
    msList: function msList(newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
    }
  },
  created: function created() {
    var _this = this;
    var that = this;
    var id = this.$route.query.id;
    var type = this.$route.query.type;
    if (id) {
      (0, _wxmake.getdetail)({
        id: id
      }).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          that.form = res.data;
          var itemList = that.form.itemList;
          that.tMindex = itemList.length - 1;
          itemList.forEach(function (v) {
            if (v.marketType === '0') {
              if (v.numType === '1') {
                console.log(v);
                var n = v.numValue;
                var a = n.split('-');
                console.log(a);
                that.sjje1 = a[0];
                that.sjje2 = a[1];
              }
            }
          });
          _this.maketime = [that.form.planStartTime, that.form.planEndTime];
          if (!that.form.labelCodes) {
            that.form.labelCodes = [];
          }
          if (!that.form.memberLevel) {
            that.form.memberLevel = [];
          }
          if (that.form.itemList.length > 0) {
            // that.msList = that.form.itemList
            Object.assign(that.msList, that.form.itemList);
          }
          that.getplansendNum(that.form);
          console.log(that.msList);
        } else {
          that.$message(res.msg);
        }
      });
    }
    console.log(id, type);
    that.getvipdata();
    that.getbqlist();
    for (var i = 0; i < 28; i++) {
      that.options.push({
        value: i + 1,
        name: i + 1 + '号'
      });
    }
  },
  methods: {
    qxmb: function qxmb() {
      var that = this;
      that.diamb = false;
      that.form.itemList[that.tMindex].templateCode = '';
      that.mbname = '';
      console.log(that.mbname);
      console.log(that.form.itemList);
    },
    hbtype: function hbtype(val) {
      var that = this;
      if (val === '0') {
        that.sjje1 = '';
        that.sjje2 = '';
      } else {
        that.form.itemList[that.tMindex].numValue = '';
      }
    },
    fssj: function fssj() {
      var that = this;
      var itemList = that.form.itemList;
      itemList[that.tMindex].sendTime = '';
    },
    dfdfdf: function dfdfdf(val) {
      console.log(val);
    },
    dellist: function dellist(num, mer, val) {
      var that = this;
      var itemList = that.form.itemList;
      var inx;
      itemList.forEach(function (v, i) {
        if (v === val) {
          inx = i;
        }
      });
      console.log('inx', inx);
      if (itemList.length > 1) {
        console.log('inx1111111111', inx);
        console.log('that.tMindex', that.tMindex);
        if (that.tMindex > 0) {
          that.tMindex--;
        }
        itemList.splice(inx, 1);
        that.msList.splice(inx, 1);
        console.log('msList', that.msList);
        console.log('itemList', itemList);
      } else {
        itemList[0] = {
          intervalValue: '',
          marketType: '0',
          numType: '0',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        };
        that.msList = [];
      }
      console.log('tMindex', that.tMindex);
      console.log('itemList', itemList);
      console.log('msList', that.msList);
    },
    editItem: function editItem(num, val, type, old) {
      var _this2 = this;
      // console.log("num", num);
      this.olditem = [];
      this.olditem.concat(old);
      this.oldtMindex = this.tMindex;
      console.log(this.olditem);
      old.forEach(function (v, i) {
        if (v === val) {
          _this2.tMindex = i;
        }
      });
      if (num === '2') {
        this.diatz = true;
      } else {
        if (val.marketType === '0') {
          this.activeName = 'one';
        } else {
          this.activeName = 'two';
        }
        this.diayx = true;
      }
      this.edtype = type;
    },
    tzqxbtn: function tzqxbtn(val) {
      var that = this;
      that.diatz = false;
      if (val.length !== that.msList.length) {
        if (val.length > 1) {
          that.tMindex--;
          val.splice(-1, 1);
        } else {
          console.log(that.tMindex);
          that.form.itemList = [{
            intervalValue: '',
            marketType: '0',
            numType: '0',
            numValue: '',
            sendTime: '',
            sendType: '0',
            smsSource: '0',
            templateCode: ''
          }];
        }
      } else {
        // if(that.edtype === "ed"){
        //    if (that.form.itemList.length > 1) {
        //       that.tMindex--
        //       val.splice(that.tMindex + 1, 1)
        //       that.msList.splice(that.tMindex + 1, 1)
        //     } else {
        //       that.form.itemList = [
        //         {
        //           intervalValue: "",
        //           marketType: "0",
        //           numType: "0",
        //           numValue: "",
        //           sendTime: "",
        //           sendType: "0",
        //           smsSource: "0",
        //           templateCode: "",
        //         },
        //       ];
        //     }
        //  }
      }
    },
    dzqxbtn: function dzqxbtn(val) {
      var that = this;
      that.diayx = false;
      console.log(val);
      if (that.form.itemList.length !== that.msList.length) {
        if (that.form.itemList.length > 1) {
          that.tMindex--;
          val.splice(-1, 1);
        } else {
          that.form.itemList = [{
            intervalValue: '',
            marketType: '0',
            numType: '0',
            numValue: '',
            sendTime: '',
            sendType: '0',
            smsSource: '0',
            templateCode: ''
          }];
        }
      } else {
        //  if(that.edtype === "ed"){
        //    if (that.form.itemList.length > 1) {
        //       that.tMindex--;
        //       val.splice(that.tMindex + 1, 1)
        //       that.msList.splice(that.tMindex + 1, 1)
        //     } else {
        //       that.form.itemList = [
        //         {
        //           intervalValue: "",
        //           marketType: "0",
        //           numType: "0",
        //           numValue: "",
        //           sendTime: "",
        //           sendType: "0",
        //           smsSource: "0",
        //           templateCode: "",
        //         },
        //       ];
        //     }
        //  }
      }
      console.log(that.form.itemList);
      console.log(that.msList);
    },
    handleClose1: function handleClose1(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      var that = this;
      // that.diayx = false;
      that.sjje1 = '';
      that.sjje2 = '';
      if (that.form.itemList.length !== that.msList.length) {
        if (that.form.itemList.length > 1) {
          that.tMindex--;
          that.form.itemList.splice(-1, 1);
        } else {
          that.form.itemList = [{
            intervalValue: '',
            marketType: '0',
            numType: '0',
            numValue: '',
            sendTime: '',
            sendType: '0',
            smsSource: '0',
            templateCode: ''
          }];
        }
      }
      done();
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    numFun2: function numFun2(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        value *= 1;
        return value.toFixed(2);
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    dzbtn: function dzbtn(val) {
      var that = this;
      var itemList = that.form.itemList;
      if (val[that.tMindex].marketType === '0') {
        if (val[that.tMindex].numType === '1') {
          if (!that.sjje1 && !that.sjje2) {
            that.$message('请填写随机红包金额');
            return false;
          } else {
            itemList[that.tMindex].numValue = that.sjje1 + '-' + that.sjje2;
          }
        } else {
          if (!val[that.tMindex].numValue) {
            that.$message('请填写红包金额');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '3') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '4') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '1') {
          if (!val[that.tMindex].sendTime) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
      } else {
        if (!val[that.tMindex].numValue) {
          that.$message('请填写积分数量');
          return false;
        }
        if (val[that.tMindex].sendType === '3') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '4') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '1') {
          if (!val[that.tMindex].sendTime) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
      }
      if (that.edtype === 'ed') {
        for (var i in msList) {
          if (val[that.tMindex].marketType === msList[i].marketType) {
            that.$message('同一类型的营销动作或通知只能创建一个');
            return false;
          }
        }
      } else {
        if (that.msList.length > 0) {
          var msList = that.msList;
          var isSp = false;
          console.log(msList);
          console.log(val);
          for (var _i in msList) {
            if (val[that.tMindex].marketType === msList[_i].marketType) {
              that.$message('同一类型的营销动作或通知只能创建一个');
              isSp = true;
              return false;
            }
          }
          if (!isSp) {
            that.msList.push(val[that.tMindex]);
          }
        } else {
          Object.assign(that.msList, that.form.itemList);
        }
      }
      that.diayx = false;
    },
    tzbtn: function tzbtn(val) {
      var that = this;
      if (val[that.tMindex].marketType === '2') {
        if (!val[that.tMindex].smsSource) {
          that.$message('请选择短信平台');
          return false;
        }
        if (!val[that.tMindex].templateCode) {
          that.$message('请选择短信模板');
          return false;
        }
        if (val[that.tMindex].sendType === '3') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '4') {
          if (!val[that.tMindex].intervalValue) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
        if (val[that.tMindex].sendType === '1') {
          if (!val[that.tMindex].sendTime) {
            that.$message('请填写发送间隔');
            return false;
          }
        }
      }
      if (that.edtype === 'ed') {} else {
        if (that.msList.length > 0) {
          var msList = that.msList;
          var isSp = false;
          for (var i in msList) {
            if (val[that.tMindex].marketType === msList[i].marketType) {
              that.$message('同一类型的营销动作或通知只能创建一个');
              isSp = true;
              return false;
            }
          }
          if (!isSp) {
            that.msList.push(val[that.tMindex]);
          }
        } else {
          Object.assign(that.msList, that.form.itemList);
        }
      }
      that.diatz = false;
    },
    creatdz: function creatdz() {
      var that = this;
      var form = that.form;
      that.edtype = null;
      that.diayx = true;
      console.log('oldtMindex', that.oldtMindex);
      // if (that.oldtMindex) {
      //   that.tMindex = that.oldtMindex;
      // }
      if (that.msList.length > 0) {
        that.tMindex++;
        that.form.itemList.push({
          intervalValue: '',
          marketType: '0',
          numType: '0',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        });
      } else {
        that.form.itemList = [{
          intervalValue: '',
          marketType: '0',
          numType: '0',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        }];
      }
      that.form.itemList[that.tMindex].marketType = '0';
      that.activeName = 'one';
      console.log(that.msList);
      console.log(that.tMindex);
      console.log(that.form.itemList);
      // if(form.itemList)
    },
    creattz: function creattz() {
      var that = this;
      var form = that.form;
      that.edtype = null;
      that.diatz = true;
      // if (that.oldtMindex) {
      //   that.tMindex = that.oldtMindex;
      // }
      if (that.msList.length > 0) {
        that.tMindex++;
        that.form.itemList.push({
          intervalValue: '',
          marketType: '0',
          numType: '',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        });
      } else {
        that.form.itemList = [{
          intervalValue: '',
          marketType: '0',
          numType: '',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        }];
      }
      that.form.itemList[that.tMindex].marketType = '2';
      console.log(that.form.itemList.length);
      console.log(that.msList);
      // if(form.itemList)
    },
    dsadsa: function dsadsa() {
      this.$router.push({
        name: 'wxAccountsmarketingActivitymkPlanplanListindex'
      });
    },
    zdsjche: function zdsjche(val) {
      console.log(val);
      console.log(this.maketime);
      var form = this.form;
      form.planStartTime = val[0];
      form.planEndTime = val[1];
    },
    handleClosemb: function handleClosemb(val) {
      this.form.itemList[this.tMindex].templateCode = '';
      this.mbname = '';
    },
    handleClose: function handleClose(done) {
      done();
    },
    xzmbd: function xzmbd(val) {
      var _this3 = this;
      console.log(val);
      var mblist = this.mblist;
      mblist.forEach(function (v, i) {
        if (val === v.id) {
          _this3.mbname = v.name;
        }
      });
    },
    handleClick: function handleClick(tab, event) {
      // console.log('dfdfgdfgdfg')
      var that = this;
      var form = that.form;
      if (tab.index === '0') {
        Object.assign(form.itemList[that.tMindex], {
          intervalValue: '',
          marketType: '0',
          numType: '0',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        });
      } else {
        Object.assign(form.itemList[that.tMindex], {
          intervalValue: '',
          marketType: '1',
          numType: '',
          numValue: '',
          sendTime: '',
          sendType: '0',
          smsSource: '0',
          templateCode: ''
        });
      }
    },
    xzbqlist: function xzbqlist() {
      var that = this;
      var form = that.form;
      form.targetType = '0';
      if (form.labelCodes.length > 0) {
        that.getplansendNum(form);
      }
      that.dlogbq = false;
    },
    getbqlist: function getbqlist() {
      var _this4 = this;
      var that = this;
      var form = that.form;
      (0, _wxyyKhlb.getmsgBq)().then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.bqlist = res.data;
          }
        } else {
          _this4.$message.warning(res.msg);
        }
      });
      (0, _wxyyKhlb.getmsgList)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.length > 0 && res.data) {
            _this4.msglist = res.data;
          } else {
            return false;
          }
        } else {
          return _this4.$message.info(res.msg);
        }
      });
      (0, _wxyyKhlb.getmsgmbList)(form.itemList[0].smsSource).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.mblist = res.data;
            var itemList = _this4.form.itemList;
            itemList.forEach(function (v) {
              if (v.marketType === '2') {
                var a = Number(v.templateCode);
                console.log(_this4.mblist);
                that.mblist.forEach(function (v) {
                  if (a === v.id) {
                    _this4.mbname = v.name;
                  }
                });
              }
            });
          }
        } else {
          _this4.$message.warning(res.msg);
        }
      });
    },
    // 下载标准文档
    downwd: function downwd() {
      var that = this;
      downloadmsgmb().then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '标准文档.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    xzhylist: function xzhylist() {
      var that = this;
      var form = that.form;
      form.targetType = '2';
      if (form.memberLevel.length > 0) {
        that.getplansendNum(form);
      }
      console.log('hylist', that.form.memberLevel);
      that.dloghydj = false;
    },
    getvipdata: function getvipdata() {
      var _this5 = this;
      var that = this;
      (0, _wxHy.getDataByMember)().then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          if (res.data.rule === '1') {
            that.vipdata = '1';
          } else {
            that.vipdata = '2';
          }
          (0, _wxyyKhlb.getmerberVip)().then(function (res) {
            var res = res.data;
            console.log('vip---', res);
            if (res.code === 200) {
              if (res.data && res.data.length > 0) {
                that.hylist1 = res.data;
                that.hylist = res.data.slice(1);
              }
            } else {
              _this5.$message.warning(res.msg);
            }
          });
        }
      });
    },
    onSubmit: function onSubmit() {
      var that = this;
      var form = that.form;
      var id = that.$route.query.id;
      var type = that.$route.query.type;
      if (!form.planName) {
        that.$message('请输入计划名称');
        return false;
      }
      if (form.planType === '1') {
        if (!form.planStartTime) {
          that.$message('请选择计划开始时间');
          return false;
        }
        if (!form.planEndTime) {
          that.$message('请选择计划结束时间');
          return false;
        }
      }
      if (that.msList.length === 0) {
        that.$message('请至少创建一个营销动作或通知渠道');
        return false;
      }
      that.form.itemList.forEach(function (v) {
        if (v.marketType === '0') {
          if (v.numType === '1') {
            if (!that.sjje1 && !that.sjje2) {
              that.$message('请填写随机红包金额');
              return false;
            } else {
              v.numValue = that.sjje1 + '-' + that.sjje2;
            }
          } else {
            if (!v.numValue) {
              that.$message('请填写红包金额');
              return false;
            }
          }
          if (v.sendType === '3') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '4') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '1' || v.sendType === '2') {
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
        }
        if (v.marketType === '1') {
          if (!v.numValue) {
            that.$message('请填写积分数量');
            return false;
          }
          if (v.sendType === '3') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '4') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '1' || v.sendType === '2') {
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
        }
        if (v.marketType === '2') {
          if (!v.templateCode) {
            that.$message('请填写选择短信模板');
            return false;
          }
          if (v.sendType === '3') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '4') {
            if (!v.intervalValue) {
              that.$message('请填写发送间隔');
              return false;
            }
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
          if (v.sendType === '1' || v.sendType === '2') {
            if (!v.sendTime) {
              that.$message('请填写发送时间');
              return false;
            }
          }
        }
        if (v.sendType === '0') {
          v.sendTime = '';
        }
      });
      that.tjonbtn = true;
      if (type === '2') {
        form.id = id;
        (0, _wxmake.planupdate)(form).then(function (res) {
          var res = res.data;
          console.log(res);
          if (res.code === 200) {
            that.$message.success(res.msg);
            that.$router.push({
              name: 'wxAccountsmarketingActivitymkPlanplanListindex'
            });
          } else {
            that.$message(res.msg);
            that.tjonbtn = false;
          }
        });
      } else {
        form.id = '';
        (0, _wxmake.plancreate)(form).then(function (res) {
          var res = res.data;
          console.log(res);
          if (res.code === 200) {
            that.$message.success(res.msg);
            that.$router.push({
              name: 'wxAccountsmarketingActivitymkPlanplanListindex'
            });
          } else {
            that.$message(res.msg);
            that.tjonbtn = false;
          }
        });
      }
    },
    xztype: function xztype(val) {
      var _this6 = this;
      this.form.bizSerialNo = '';
      this.form.labelCodes = [];
      this.form.memberLevel = [];
      var num;
      console.log('val', val);
      switch (val) {
        case '1':
          num = val;
          break;
        case '2':
          num = val;
          break;
        case '3':
          num = val;
          break;
        case '4':
          num = val;
          break;
        case '5':
          num = val;
          break;
        case '6':
          num = val;
          break;
        case '7':
          num = val;
          break;
        default:
          num = '1';
          break;
      }
      // let a = this.hylist.slice(0, 1)

      var a = [];
      console.log(this.hylist1);
      this.hylist1.forEach(function (v, i) {
        a.push(v.value);
      });
      console.log(a);
      var form = this.form;
      // this.maketime?
      //   form.planStartTime = this.maketime[0];
      //   form.planEndTime = this.maketime[1]
      //   :
      //   form.planStartTime = '';
      //   form.planEndTime = ''
      var n = {
        planName: form.planName,
        planType: form.planType,
        planStartTime: this.maketime[0],
        planEndTime: this.maketime[1],
        targetType: num,
        labelCodes: form.labelCodes,
        memberLevel: form.memberLevel
      };
      if (n.targetType === '3') {
        n.memberLevel = a.slice(0, 1);
        // n.memberLevel = []
      }

      (0, _wxmake.plansendNum)(n).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this6.form.redPackCount = res.data.redPackCount;
          _this6.form.scoreCount = res.data.scoreCount;
          _this6.form.smsCount = res.data.smsCount;
        } else {
          if (val === '1') {
            return;
          }
          _this6.$message.warning(res.msg);
        }
      });
    },
    getplansendNum: function getplansendNum(val) {
      var _this7 = this;
      (0, _wxmake.plansendNum)(val).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this7.form.redPackCount = res.data.redPackCount;
          _this7.form.scoreCount = res.data.scoreCount;
          _this7.form.smsCount = res.data.smsCount;
        } else {
          _this7.$message.warning(res.msg);
        }
      });
    },
    vipFun: function vipFun(val) {
      console.log(this.bqlist);
      console.log(this.form.memberLevel);
      // if (this.form.memberLevel) {
      //   // return
      // } else {
      //   this.form.memberLevel = [];
      // }
      if (val === '2') {
        this.form.targetType = '2';
        this.dloghydj = true;
      }
      if (val === '7') {
        this.form.targetType = '7';
        this.dlogphone = true;
      }
      if (val === '1') {
        // this.form.targetType = "1";
        this.form.targetType = '0';
        this.dlogbq = true;
      }
      // if (val === '2') {
      //   this.dlogdxmu = true
      // }
    }
  }
};
exports.default = _default;