var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "活动方案详情",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
        },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "基本信息", column: 3, border: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "方案编码" } }, [
                _vm._v(_vm._s(_vm.ruleForm.schemeCode)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "方案名称" } }, [
                _vm._v(_vm._s(_vm.ruleForm.schemeName)),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "状态" } },
                [
                  _vm.ruleForm.status == 1
                    ? _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v("启用"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.status == 2
                    ? _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v("禁用"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.status == 3
                    ? _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v("删除"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.status == 4
                    ? _c("el-tag", { attrs: { type: "warning" } }, [
                        _vm._v("已审核"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "方案类型" } }, [
                _vm._v(
                  _vm._s(
                    _vm.ruleForm.type == 1
                      ? "普通方案"
                      : _vm.ruleForm.type == 2
                      ? "增补方案"
                      : "未知"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "开始时间" } }, [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.startTime))),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "截止时间" } }, [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.endTime))),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "关联单据数量" } }, [
                _vm._v(_vm._s(_vm.ruleForm.documentNum)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "计划数量" } }, [
                _vm._v(_vm._s(_vm.ruleForm.planNum)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "总扫码率" } }, [
                _vm._v(_vm._s(_vm.ruleForm.scanPercentage)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.ruleForm.createTime))),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                _vm._v(_vm._s(_vm.ruleForm.creatorName)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                _vm._v(_vm._s(_vm.ruleForm.remarks)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "会员抽奖活动", name: "member" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.memberSchemeActivityList,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动名称",
                          prop: "activityName",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动角色",
                          prop: "role",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动类型",
                          prop: "type",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    row.type == 1
                                      ? "普通活动"
                                      : row.type == 2
                                      ? "追加活动"
                                      : "未知"
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "扫码类型",
                          prop: "scanType",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    row.scanType == 1
                                      ? "盖外码"
                                      : row.scanType == 2
                                      ? "贴标箱码"
                                      : "未知"
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联方案",
                          prop: "schemeCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "开始时间",
                          prop: "startTime",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.startTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "截止时间",
                          prop: "endTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.endTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动状态",
                          prop: "activityStatus",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("activityStatusMap")(
                                        row.activityStatus
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "扫码率",
                          prop: "scanPercentage",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "creatorName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.createTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", [_vm._v("布奖信息")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.memberPrizeDetail,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖项",
                          prop: "priority",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品类型",
                          prop: "prizeTypeName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品名称",
                          prop: "prizeName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品价值",
                          prop: "prizeMoney",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "核销返利",
                          prop: "auditMoney",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "红包返现",
                          prop: "flValue",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动奖品数(原有)",
                          prop: "oldPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "增补奖品数(增补总和)",
                          prop: "appendPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "投放总数量(原有+增补)",
                          prop: "totalPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "投放总金额",
                          prop: "totalPrizeMoney",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发放总数量",
                          prop: "sendNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "已领取数量",
                          prop: "receiveNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发放失败数量",
                          prop: "failNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "剩余奖品",
                          prop: "surplusPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "中奖概率",
                          prop: "prizePr",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "终端抽奖活动", name: "zd" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.zdActivityDetail,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动名称",
                          prop: "activityName",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "活动角色",
                            prop: "role",
                            align: _vm.tableConfig.align,
                            "min-width": "100",
                            "show-overflow-tooltip": "",
                          },
                        },
                        [[_c("span", [_vm._v("终端店主")])]],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动类型",
                          prop: "createWay",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    row.createWay == 0
                                      ? "普通活动"
                                      : row.createWay == 1
                                      ? "追加活动"
                                      : "未知"
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "扫码类型",
                          prop: "scanType",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    row.scanType == 0
                                      ? "贴标箱码"
                                      : row.scanType == 1
                                      ? "预印箱码"
                                      : "未知"
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "关联方案",
                          prop: "schemeName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "开始时间",
                          prop: "startTime",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.startTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "截止时间",
                          prop: "endTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.endTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动状态",
                          prop: "activityStatus",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("activityStatusMap")(
                                        row.activityStatus
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "createUserName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(row.createTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", [_vm._v("布奖信息")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        size: "mini",
                        data: _vm.ruleForm.zdPrizeDetail,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖项",
                          prop: "priority",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品类型",
                          prop: "prizeType",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatPrizeType")(row.prizeType)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品名称",
                          prop: "prizeName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "奖品价值",
                          prop: "prizeMoney",
                          align: _vm.tableConfig.align,
                          "min-width": "80",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "活动奖品数(原有)",
                          prop: "oldPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "增补奖品数(增补总和)",
                          prop: "appendPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "投放总数量(原有+增补)",
                          prop: "totalPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "投放总金额",
                          prop: "totalPrizeMoney",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发放总数量",
                          prop: "sendNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "已领取数量",
                          prop: "receiveNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发放失败数量",
                          prop: "failNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "剩余奖品",
                          prop: "surplusPrizeNum",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "中奖概率",
                          prop: "prizePr",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }