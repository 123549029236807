var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "销售订单-审核",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("销售订单号:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.orderNo)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("采购单位类型:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("purchaseComOwnerType")(
                          _vm.addData.purchaseComOwnerType
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("采购单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.purchaseComName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("付款方式:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(_vm._f("payWayText")(_vm.addData.paymentStyle))
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("订单日期:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("申请人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.applyUser)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("收货单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveComName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("联系人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveContacts)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("联系人电话:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receivePhone)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("收货地址:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveAddress)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("供货信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("发货单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.sendOutComName)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [
            _vm._v("\n      产品信息\n      "),
            _c("img", { attrs: { src: require("@/images/pro.png") } }),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "addTable",
              staticClass: "detailTable",
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": "300",
                data: _vm.productList,
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "row-class-name": "iconNone",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  align: _vm.tableConfig.align,
                  prop: "productCode",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: _vm.tableConfig.align,
                  prop: "productName",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  align: _vm.tableConfig.align,
                  prop: "productModel",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  align: _vm.tableConfig.align,
                  prop: "packScaleExpression",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱数",
                  align: _vm.tableConfig.align,
                  width: "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.chestNum))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盒数",
                  align: _vm.tableConfig.align,
                  width: "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.bottleNum))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单价(元/盒)",
                  align: _vm.tableConfig.align,
                  prop: "packScaleDesc",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.productPrice))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "金额",
                  align: _vm.tableConfig.align,
                  prop: "totalPrice",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.totalPrice))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.addData.receiveComType == 3
                ? _c("el-table-column", {
                    attrs: {
                      label: "返利政策",
                      align: _vm.tableConfig.align,
                      prop: "rebatePolicyId",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.flChange(_vm.$el, row)
                                    },
                                    clear: function ($event) {
                                      return _vm.flClear(_vm.$el, row)
                                    },
                                  },
                                  model: {
                                    value: row.rebatePolicyId,
                                    callback: function ($$v) {
                                      _vm.$set(row, "rebatePolicyId", $$v)
                                    },
                                    expression: "row.rebatePolicyId",
                                  },
                                },
                                _vm._l(row.flList, function (item, idx) {
                                  return _c("el-option", {
                                    key: idx,
                                    attrs: {
                                      label: item.desc,
                                      value: item.rebateId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3273763887
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.addData.receiveComType == 3
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toogleExpand(scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("查看赠品")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      477399154
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "1" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticClass: "detailTable",
                            staticStyle: { width: "100%", "margin-top": "1px" },
                            attrs: {
                              data: props.row.orderGiftDetailDTOS,
                              fit: "",
                              border: _vm.tableConfig.border,
                              stripe: _vm.tableConfig.stripe,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "赠品行",
                                type: "index",
                                align: _vm.tableConfig.align,
                                width: "90",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "赠品编码",
                                prop: "productCode",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "赠品名称",
                                prop: "productName",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "单品数量",
                                prop: "bottleNum",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                prop: "prodPrice",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                prop: "totalPrice",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "countWrap" }, [
            _c("div", { staticStyle: { "min-width": "250px" } }, [
              _vm._v(
                "总金额：" +
                  _vm._s(
                    _vm.addData.totalMoney
                      ? _vm.addData.totalMoney.toFixed(2)
                      : ""
                  )
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "大写金额：" +
                  _vm._s(_vm._f("chnMoney")(_vm.addData.totalMoney))
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.addData.receiveComType == 3
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "itemTitle" },
                    [
                      _vm._v("\n        酒劵使用信息\n        "),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.openPro(2)
                            },
                          },
                        },
                        [_vm._v("添加赠品")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "detailTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.zpList,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: _vm.tableConfig.align,
                          prop: "productCode",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: _vm.tableConfig.align,
                          prop: "productName",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: _vm.tableConfig.align,
                          prop: "productModel",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: _vm.tableConfig.align,
                          prop: "packScaleExpression",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: 99999999,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.chestNum,
                                      callback: function ($$v) {
                                        _vm.$set(row, "chestNum", $$v)
                                      },
                                      expression: "row.chestNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3987246532
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: _vm.mixNumP(row),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.bottleNumOver,
                                      callback: function ($$v) {
                                        _vm.$set(row, "bottleNumOver", $$v)
                                      },
                                      expression: "row.bottleNumOver",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1336253910
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          prop: "bottleNum",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元酒劵/盒)",
                          align: _vm.tableConfig.align,
                          prop: "winePrice",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [_vm._v(_vm._s(_vm.totalWine(row)))]
                              },
                            },
                          ],
                          null,
                          false,
                          1315556519
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoScZp(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3604119748
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c("div", { staticStyle: { "min-width": "350px" } }, [
                      _vm._v("可使用酒劵金额(元)：" + _vm._s(_vm.totalZp)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("本次使用酒券金额(元)：" + _vm._s(_vm.totalInUse)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("备注")]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("span", [_vm._v(_vm._s(_vm.addData.remark))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("状态")]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("orderStatusText")(_vm.addData.orderStatus))
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.addData.orderStatus > 1
            ? _c("div", { staticClass: "middleLine" })
            : _vm._e(),
          _vm._v(" "),
          _vm.addData.orderStatus > 1
            ? _c(
                "el-collapse",
                {
                  staticClass: "detailCollapse",
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [_vm._v("审核日志")]),
                      _vm._v(" "),
                      _vm.addData.orderStatus > 1
                        ? _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                loaidng: _vm.auditLoading,
                                data: _vm.auditList,
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                "max-height": "300",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "auditNode",
                                  label: "审核节点",
                                  "min-width": "120",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("auditNodeName")(
                                                row.auditNode
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4089958081
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "auditUserAccount",
                                  label: "审核人",
                                  "min-width": "120",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "auditNode",
                                  label: "审核结果",
                                  "min-width": "120",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              row.auditStatus == 1
                                                ? "通过"
                                                : "不通过"
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1704786847
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "auditOpinion",
                                  label: "审核时间",
                                  width: "170",
                                  align: _vm.tableConfig.align,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("textDate")(row.createTime)
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2280372158
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "auditOpinion",
                                  label: "审核意见",
                                  "min-width": "120",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("预审")]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 0 15px 0" } },
            [
              _c(
                "el-form",
                {
                  ref: "auditForm",
                  attrs: {
                    model: _vm.auditForm,
                    "label-width": "85px",
                    "label-suffix": ":",
                    rules: _vm.auditRule,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果", prop: "auditResult" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.auditForm.auditResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.auditForm, "auditResult", $$v)
                            },
                            expression: "auditForm.auditResult",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "auditReason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.auditForm.auditReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.auditForm, "auditReason", $$v)
                          },
                          expression: "auditForm.auditReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.audit } },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              isCode: "1",
              proSelVisible: _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }