"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _manufacture = require("@/api/dataAnalyse/manufacture");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import dataList from '../mixins/dataList.js'
var _default = {
  // mixins: [dataList],
  components: {
    Pagination: _Pagination.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      listLoading: false,
      // 列表加载
      searchBtn: false,
      // 搜索按钮
      sweepBtn: false,
      // 重置按钮
      total: 0,
      // 总数量
      list: [],
      // 列表
      dateVal: [],
      dialogVisible: false,
      // 详情状态
      row: {},
      // 行数据
      handleShow: false,
      downLoading: false,
      dateRule: {
        required: false,
        message: '请选择时间',
        trigger: 'change'
      },
      options: [{
        value: '10',
        label: '未生产'
      }, {
        value: '13',
        label: '生产中'
      }, {
        value: '16',
        label: '已生产'
      }],
      searchForm: {
        orderStatus: '',
        // 状态
        current: 0,
        size: 10,
        prodEndTime: '',
        // 结束时间
        prodStartTime: '',
        // 开始时间
        shopCode: '',
        // 车间单号
        materialCode: '',
        // 商品(产品编号)
        orderNo: '',
        // 生产单号
        materialName: '',
        // 产品名称
        batchNo: ''
      },
      // 模糊搜索
      WorkshopList: [],
      scKeyData: {
        attachmakeid: '生产附加信息编号',
        makeid: '数码生产信息编号',
        maketime: '生产日期',
        factoryid: '工厂编号',
        workshop: '车间名称',
        lineid: '生产线编号',
        shiftid: '生产班组编号',
        remarkid: '生产标识',
        inspectorid: '质检员编号',
        batchno: '生产批次号',
        filename: '上传文件名'
      },
      cpKeyData: {
        psid: '产品编码',
        newpsid: '企业自定义产品编号',
        psName: '产品名称',
        enpid: '产品包装比例编号',
        norms: '产品规格',
        expirydate: '有效期',
        typeid: '产品特征编号',
        shelflife: '保质期'
      },
      kfKeyData: {
        storeid: '库房编号',
        createdate: '入库时间',
        rawmaterialsuppliers: '原材料供应商',
        outplan: '计划大箱（托）数量',
        midplan: '计划中箱数量',
        inplan: '计划小盒数量',
        outfact: '实际大箱（托）数量',
        midfact: '实际中箱数量',
        infact: '实际小盒数量',
        note: '备注'
      },
      summarizing: {}
    };
  },
  mounted: function mounted() {
    this.getProdBillListsFun();
    this.getWorkshopList();
  },
  methods: {
    // 获取单据列表
    getProdBillListsFun: function getProdBillListsFun() {
      var _this2 = this;
      (0, _manufacture.getProdBillLists)((0, _objectSpread2.default)({}, this.searchForm)).then(function (resp) {
        console.log(resp);
        if (resp.data.code === 200) {
          _this2.list = resp.data.data.pageData.records;
          _this2.total = resp.data.data.pageData.total * 1;
          _this2.summarizing = resp.data.data.countData;
        }
      });
    },
    // 获取生产组织
    getWorkshopList: function getWorkshopList() {
      var _this3 = this;
      // type	1：工厂 2：车间 3： 产线 4班组
      (0, _select.factoryInfoList)({
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 2
      }).then(function (res) {
        if (res.data.code === 200) {
          _this3.WorkshopList = res.data.data.records;
        }
      });
    },
    // 搜索
    searchFun: function searchFun(type) {
      if (type === 0) {
        this.dateVal = [];
        this.list = [];
        this.searchForm = {
          orderStatus: '',
          // 状态
          current: 0,
          size: 10,
          prodEndTime: '',
          // 结束时间
          prodStartTime: '',
          // 开始时间
          shopCode: '',
          // 车间单号
          materialCode: '',
          // 商品(产品编号)
          orderNo: '',
          // 生产单号
          materialName: '',
          // 产品名称
          startdate: '' // 时间
        };

        this.$refs.form.resetFields();
      }
      //  else {
      //   this.$refs.form.validate((valid) => {
      //     if (valid) {
      //       this.getProdBillListsFun()
      //     } else {
      //       console.log('error submit!!')
      //       return false
      //     }
      //   })
      // }
      this.getProdBillListsFun();
    },
    // 导出
    downLoad: function downLoad() {
      var _this4 = this;
      this.downLoading = true;
      (0, _manufacture.reportExportExcel)((0, _objectSpread2.default)({}, this.searchForm)).then(function (res) {
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产数据.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.downLoading = false;
      });
    },
    // 翻页
    pagingFun: function pagingFun() {
      this.getProdBillListsFun();
    },
    // 详情
    details: function details(row) {
      this.dialogVisible = true;
      this.row = row;
    },
    // 日期选择
    dateFun: function dateFun(val) {
      if (val) {
        this.searchForm.prodStartTime = val[0];
        this.searchForm.prodEndTime = val[1];
      } else {
        this.searchForm.prodStartTime = '';
        this.searchForm.prodEndTime = '';
      }
    },
    inputFocusFun: function inputFocusFun() {
      this.$refs.selProduct.proSelVisible = true;
    },
    proDataFun: function proDataFun(row) {
      this.searchForm.materialCode = row.pId;
      this.searchForm.materialName = row.productName;
    }
  }
};
exports.default = _default;