var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "fodder" } },
    [
      _c(
        "el-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "hyyy-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-card",
                        [
                          _c("div", { staticClass: "hyyy-top-tit" }, [
                            _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                              _c("span", {
                                staticStyle: {
                                  "font-weight": "400",
                                  color: "#333",
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.$route.meta.title),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "hyyy-top-tit-right" },
                              [
                                _c("admincut", {
                                  staticStyle: { "margin-top": "7px" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-divider", { staticStyle: { margin: "0" } }),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticClass: "hyyy-content" },
                            [
                              _c(
                                "el-main",
                                [
                                  _c(
                                    "el-tabs",
                                    {
                                      on: { "tab-click": _vm.handleClick },
                                      model: {
                                        value: _vm.activeName,
                                        callback: function ($$v) {
                                          _vm.activeName = $$v
                                        },
                                        expression: "activeName",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "图文消息",
                                            name: "first",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "图文消息（共" +
                                                        _vm._s(
                                                          _vm.qbtw
                                                            ? _vm.qbtw
                                                            : 0
                                                        ) +
                                                        "条）"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            { staticClass: "twSearch" },
                                            [
                                              _c("el-col", {
                                                attrs: { span: 4 },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "hyyy-search",
                                                  attrs: { span: 3 },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "搜索图文",
                                                      "prefix-icon":
                                                        "el-icon-search",
                                                    },
                                                    nativeOn: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.searchtw(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.input2,
                                                      callback: function ($$v) {
                                                        _vm.input2 = $$v
                                                      },
                                                      expression: "input2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: { span: 3, offset: 1 },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.creatsc,
                                                      },
                                                    },
                                                    [_vm._v("新建图文素材")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "hyyy-edit" },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      icon: "el-icon-plus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.open()
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-button", {
                                                    attrs: {
                                                      icon: "el-icon-minus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.open3(
                                                          _vm.groups
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tabs",
                                                {
                                                  staticClass: "hyyyscgl",
                                                  attrs: {
                                                    "tab-position": "left",
                                                    type: "border-card",
                                                  },
                                                  on: {
                                                    "tab-click":
                                                      _vm.handleClick1,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tab-pane",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hyyy-left-btn",
                                                          attrs: {
                                                            slot: "label",
                                                          },
                                                          slot: "label",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _c("span", [
                                                              _vm._v("全部"),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "(" +
                                                                  _vm._s(
                                                                    _vm.qbtw
                                                                      ? _vm.qbtw
                                                                      : 0
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ]),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("span"),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.twlist.length > 0
                                                        ? _c(
                                                            "el-row",
                                                            {
                                                              staticStyle: {
                                                                "flex-wrap":
                                                                  "wrap",
                                                                "align-content":
                                                                  "space-around",
                                                              },
                                                              attrs: {
                                                                type: "flex",
                                                                justify:
                                                                  "start",
                                                                gutter: 30,
                                                              },
                                                            },
                                                            [
                                                              _vm._l(
                                                                _vm.twlist,
                                                                function (
                                                                  o,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-col",
                                                                    {
                                                                      key: index,
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        span: 6,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-card",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "body-style":
                                                                                {
                                                                                  padding:
                                                                                    "0px",
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          o.fileUrl ==
                                                                            null ||
                                                                          ""
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bdimg",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image",
                                                                                      attrs:
                                                                                        {
                                                                                          src: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bdimg",
                                                                                  style:
                                                                                    {
                                                                                      backgroundImage:
                                                                                        "url(" +
                                                                                        _vm.twImgurl +
                                                                                        o.fileUrl +
                                                                                        ")",
                                                                                      backgroundSize:
                                                                                        "100%",
                                                                                      backgroundRepeat:
                                                                                        "no-repeat",
                                                                                      backgroundPosition:
                                                                                        "center center",
                                                                                    },
                                                                                }
                                                                              ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "bdbm",
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "14px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      o.title.toString(
                                                                                        0,
                                                                                        5
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bottom clearfix",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "time",
                                                                                    {
                                                                                      staticClass:
                                                                                        "time",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          o.createTime
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "twbtn",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$router.push(
                                                                                              {
                                                                                                name: "wxAccountswxgzhwxFunctionfoddernews",
                                                                                                query:
                                                                                                  {
                                                                                                    id: o.id,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "详情"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.amend(
                                                                                              o
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "修改"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.dltw(
                                                                                              o.id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "删除"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-pagination",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.qbtw >
                                                                        10,
                                                                      expression:
                                                                        "qbtw>10",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "twpage",
                                                                  attrs: {
                                                                    background:
                                                                      "",
                                                                    "current-page":
                                                                      _vm
                                                                        .formInline
                                                                        .pageNo,
                                                                    "page-size":
                                                                      _vm
                                                                        .formInline
                                                                        .pageSize,
                                                                    layout:
                                                                      "total,prev, pager, next",
                                                                    total:
                                                                      _vm.qbtw,
                                                                  },
                                                                  on: {
                                                                    "current-change":
                                                                      _vm.twhandleCurrentChange,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _c(
                                                            "el-row",
                                                            [
                                                              _c("el-col", [
                                                                _vm._v(
                                                                  "暂无素材"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-tab-pane",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hyyy-left-btn",
                                                          attrs: {
                                                            slot: "label",
                                                          },
                                                          slot: "label",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _c("span", [
                                                              _vm._v("未分组"),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "(" +
                                                                  _vm._s(
                                                                    _vm.wfztw
                                                                      ? _vm.wfztw
                                                                      : 0
                                                                  ) +
                                                                  ")"
                                                              ),
                                                            ]),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("span"),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.twlist.length > 0
                                                        ? _c(
                                                            "el-row",
                                                            {
                                                              staticStyle: {
                                                                "flex-wrap":
                                                                  "wrap",
                                                                "align-content":
                                                                  "space-around",
                                                              },
                                                              attrs: {
                                                                type: "flex",
                                                                justify:
                                                                  "start",
                                                                gutter: 30,
                                                              },
                                                            },
                                                            [
                                                              _vm._l(
                                                                _vm.twlist,
                                                                function (
                                                                  o,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-col",
                                                                    {
                                                                      key: index,
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        span: 6,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-card",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "body-style":
                                                                                {
                                                                                  padding:
                                                                                    "0px",
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          o.fileUrl ==
                                                                          null
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bdimg",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "image",
                                                                                      attrs:
                                                                                        {
                                                                                          src: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bdimg",
                                                                                  style:
                                                                                    {
                                                                                      backgroundImage:
                                                                                        "url(" +
                                                                                        _vm.twImgurl +
                                                                                        o.fileUrl +
                                                                                        ")",
                                                                                      backgroundSize:
                                                                                        "100%",
                                                                                      backgroundRepeat:
                                                                                        "no-repeat",
                                                                                      backgroundPosition:
                                                                                        "center center",
                                                                                    },
                                                                                }
                                                                              ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "bdbm",
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "14px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  domProps:
                                                                                    {
                                                                                      textContent:
                                                                                        _vm._s(
                                                                                          o.title
                                                                                        ),
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bottom clearfix",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "time",
                                                                                    {
                                                                                      staticClass:
                                                                                        "time",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          o.createTime
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "twbtn",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$router.push(
                                                                                              {
                                                                                                name: "wxAccountswxgzhwxFunctionfoddernews",
                                                                                                query:
                                                                                                  {
                                                                                                    id: o.id,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "详情"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.amend(
                                                                                              o
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "修改"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.dltw(
                                                                                              o.id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "删除"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-pagination",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.wfztw >
                                                                        10,
                                                                      expression:
                                                                        "wfztw>10",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "twpage",
                                                                  attrs: {
                                                                    background:
                                                                      "",
                                                                    "current-page":
                                                                      _vm
                                                                        .formInline
                                                                        .pageNo,
                                                                    "page-size":
                                                                      _vm
                                                                        .formInline
                                                                        .pageSize,
                                                                    layout:
                                                                      "total,prev, pager, next",
                                                                    total:
                                                                      _vm.wfztw,
                                                                  },
                                                                  on: {
                                                                    "current-change":
                                                                      _vm.wfztwhandleCurrentChange,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _c(
                                                            "el-row",
                                                            [
                                                              _c("el-col", [
                                                                _vm._v(
                                                                  "暂无素材"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.groups,
                                                    function (item, index) {
                                                      return _c(
                                                        "el-tab-pane",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "hyyy-left-btn",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "30px",
                                                              },
                                                              attrs: {
                                                                slot: "label",
                                                              },
                                                              slot: "label",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _c("span", {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        item.name
                                                                      ),
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                item.hasOwnProperty(
                                                                  "newCount"
                                                                )
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "(" +
                                                                          _vm._s(
                                                                            item.newCount
                                                                          ) +
                                                                          ")"
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "(0)"
                                                                      ),
                                                                    ]),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("el-button", {
                                                                attrs: {
                                                                  icon: "el-icon-edit",
                                                                  size: "mini",
                                                                  circle: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.open1(
                                                                        index,
                                                                        _vm.groups
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.twlist.length > 0
                                                            ? _c(
                                                                "el-row",
                                                                {
                                                                  staticStyle: {
                                                                    "flex-wrap":
                                                                      "wrap",
                                                                    "align-content":
                                                                      "space-around",
                                                                  },
                                                                  attrs: {
                                                                    type: "flex",
                                                                    justify:
                                                                      "start",
                                                                    gutter: 30,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    _vm.twlist,
                                                                    function (
                                                                      o,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "el-col",
                                                                        {
                                                                          key: i,
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "20px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              span: 6,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-card",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "body-style":
                                                                                    {
                                                                                      padding:
                                                                                        "0px",
                                                                                    },
                                                                                },
                                                                            },
                                                                            [
                                                                              o.fileUrl ==
                                                                              null
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "bdimg",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "image",
                                                                                          attrs:
                                                                                            {
                                                                                              src: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "bdimg",
                                                                                      style:
                                                                                        {
                                                                                          backgroundImage:
                                                                                            "url(" +
                                                                                            _vm.twImgurl +
                                                                                            o.fileUrl +
                                                                                            ")",
                                                                                          backgroundSize:
                                                                                            "100%",
                                                                                          backgroundRepeat:
                                                                                            "no-repeat",
                                                                                          backgroundPosition:
                                                                                            "center center",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "bdbm",
                                                                                  staticStyle:
                                                                                    {
                                                                                      padding:
                                                                                        "14px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      domProps:
                                                                                        {
                                                                                          textContent:
                                                                                            _vm._s(
                                                                                              o.title.toString(
                                                                                                0,
                                                                                                10
                                                                                              )
                                                                                            ),
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "bottom clearfix",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "time",
                                                                                        {
                                                                                          staticClass:
                                                                                            "time",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              o.createTime
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "twbtn",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "el-button",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              size: "mini",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.$router.push(
                                                                                                  {
                                                                                                    name: "wxAccountswxgzhwxFunctionfoddernews",
                                                                                                    query:
                                                                                                      {
                                                                                                        id: o.id,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "详情"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "el-button",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              size: "mini",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.amend(
                                                                                                  o
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "修改"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "el-button",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "primary",
                                                                                              size: "mini",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.dltw(
                                                                                                  o.id
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "删除"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-pagination",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              item.newCount >
                                                                              10,
                                                                            expression:
                                                                              "item.newCount>10",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "twpage",
                                                                      attrs: {
                                                                        background:
                                                                          "",
                                                                        "current-page":
                                                                          _vm
                                                                            .formInline
                                                                            .pageNo,
                                                                        "page-size":
                                                                          _vm
                                                                            .formInline
                                                                            .pageSize,
                                                                        layout:
                                                                          "total,prev, pager, next",
                                                                        total:
                                                                          item.newCount,
                                                                      },
                                                                      on: {
                                                                        "current-change":
                                                                          _vm.fztwhandleCurrentChange,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              )
                                                            : _c(
                                                                "el-row",
                                                                [
                                                                  _c("el-col", [
                                                                    _vm._v(
                                                                      "暂无素材"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "图片",
                                            name: "second",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: 3,
                                                    offset: 20,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-upload",
                                                    {
                                                      attrs: {
                                                        action: _vm.posturl,
                                                        headers: _vm.myHeaders,
                                                        "on-preview":
                                                          _vm.handlePreview,
                                                        "on-remove":
                                                          _vm.handleRemove,
                                                        "before-remove":
                                                          _vm.beforeRemove,
                                                        "before-upload":
                                                          _vm.imgSize,
                                                        "on-success":
                                                          _vm.handleSuccess,
                                                        "file-list": _vm.Lists,
                                                        "show-file-list": false,
                                                        "on-exceed":
                                                          _vm.handleExceed,
                                                        accept:
                                                          ".bmp, .png, .jpeg, .jpg, gif",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "small",
                                                            type: "primary",
                                                          },
                                                        },
                                                        [_vm._v("上传图片")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            {
                                              staticClass:
                                                "el-upload-list el-upload-list--picture-card",
                                              staticStyle: {
                                                "flex-wrap": "wrap",
                                                "align-content": "space-around",
                                              },
                                              attrs: { gutter: 20 },
                                            },
                                            _vm._l(
                                              _vm.Lists,
                                              function (item, index) {
                                                return _c(
                                                  "el-col",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "el-upload-list__item is-ready",
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                      padding: "0",
                                                    },
                                                    attrs: {
                                                      span: 5,
                                                      tabindex: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      item.uploadUrl == null
                                                        ? _c(
                                                            "img",
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "el-upload-list__item-thumbnail",
                                                                attrs: {
                                                                  alt: "",
                                                                  draggable:
                                                                    "true",
                                                                },
                                                              },
                                                              "img",
                                                              {
                                                                src:
                                                                  _vm.scImgurl +
                                                                  item.url,
                                                              },
                                                              false
                                                            )
                                                          )
                                                        : _c(
                                                            "img",
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "el-upload-list__item-thumbnail",
                                                                attrs: {
                                                                  alt: "",
                                                                  draggable:
                                                                    "true",
                                                                },
                                                              },
                                                              "img",
                                                              {
                                                                src: item.uploadUrl,
                                                              },
                                                              false
                                                            )
                                                          ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "el-upload-list__item-actions",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "el-upload-list__item-preview",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handlePictureCardPreview(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-zoom-in",
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          !_vm.disabled
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "el-upload-list__item-delete",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleRemove(
                                                                          index,
                                                                          _vm.Lists
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-delete",
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-dialog",
                                                      {
                                                        attrs: {
                                                          visible:
                                                            _vm.dialogVisible,
                                                        },
                                                        on: {
                                                          "update:visible":
                                                            function ($event) {
                                                              _vm.dialogVisible =
                                                                $event
                                                            },
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            width: "100%",
                                                            src: _vm.dialogImageUrl,
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-pagination", {
                                            staticClass: "imgpage",
                                            attrs: {
                                              background: "",
                                              "current-page":
                                                _vm.formInline.pageNo,
                                              "page-size":
                                                _vm.formInline.pageSize,
                                              layout: "total,prev, pager, next",
                                              total: _vm.total,
                                            },
                                            on: {
                                              "current-change":
                                                _vm.imghandleCurrentChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "语音",
                                            name: "third",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "语音（共" +
                                                      _vm._s(
                                                        _vm.total
                                                          ? _vm.total
                                                          : 0
                                                      ) +
                                                      "条）"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.voiceUpopen,
                                                      },
                                                    },
                                                    [_vm._v("上传语音")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-dialog",
                                                    {
                                                      attrs: {
                                                        title: "上传语音",
                                                        visible: _vm.voiceUp,
                                                      },
                                                      on: {
                                                        "update:visible":
                                                          function ($event) {
                                                            _vm.voiceUp = $event
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-form",
                                                        {
                                                          attrs: {
                                                            model:
                                                              _vm.voiceForm,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "语音名称",
                                                                "label-width":
                                                                  _vm.formLabelWidth,
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  autocomplete:
                                                                    "off",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .voiceForm
                                                                      .name,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.voiceForm,
                                                                        "name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "voiceForm.name",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "语音内容",
                                                                "label-width":
                                                                  _vm.formLabelWidth,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  ref: "upload",
                                                                  attrs: {
                                                                    action:
                                                                      _vm.posturl3,
                                                                    headers:
                                                                      _vm.myHeaders,
                                                                    "on-success":
                                                                      _vm.vohandleSuccess,
                                                                    "on-progress":
                                                                      _vm.projes,
                                                                    "on-change":
                                                                      _vm.vochange,
                                                                    data: _vm.voiceForm,
                                                                    "on-remove":
                                                                      _vm.vorem,
                                                                    "on-error":
                                                                      _vm.voerr,
                                                                    "before-upload":
                                                                      _vm.voSize,
                                                                    limit: 1,
                                                                    "auto-upload":
                                                                      _vm.autoup,
                                                                    accept:
                                                                      ".MP3,.mp3,.AMR,.amr",
                                                                    "show-file-list": true,
                                                                    "on-exceed":
                                                                      _vm.handleExceed,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        size: "small",
                                                                        type: "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "上传语音"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-upload__tip",
                                                                      attrs: {
                                                                        slot: "tip",
                                                                      },
                                                                      slot: "tip",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "格式支持mp3、amr，文件大小不超过2M"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dialog-footer",
                                                          attrs: {
                                                            slot: "footer",
                                                          },
                                                          slot: "footer",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.voiceUp = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("取 消")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                disabled:
                                                                  _vm.vodis,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.upLoadvo,
                                                              },
                                                            },
                                                            [_vm._v("确 定")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.voloaing,
                                                  expression: "voloaing",
                                                },
                                              ],
                                              staticStyle: { width: "70%" },
                                              attrs: {
                                                data: _vm.tableData,
                                                fit: "",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "name",
                                                  label: "名称",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "fileSize",
                                                  label: "大小",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "addree",
                                                  label: "状态",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        scope.row.mediaId ==
                                                          "" || null
                                                          ? _c("el-col", [
                                                              _vm._v("未通过"),
                                                            ])
                                                          : _c("el-col", [
                                                              _vm._v("通过"),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "createTime",
                                                  label: "更新时间",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              scope.row
                                                                .createTime
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "操作",
                                                  width: "300",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.vohandleEdit(
                                                                  scope.$index,
                                                                  _vm.tableData
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("编辑")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dialog",
                                                          {
                                                            attrs: {
                                                              title: "修改语音",
                                                              visible:
                                                                _vm.voiceXg,
                                                            },
                                                            on: {
                                                              "update:visible":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.voiceXg =
                                                                    $event
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-form",
                                                              {
                                                                attrs: {
                                                                  model:
                                                                    _vm.voiceForm,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "语音名称",
                                                                      "label-width":
                                                                        _vm.formLabelWidth,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .voiceForm
                                                                              .name,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.voiceForm,
                                                                                "name",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "voiceForm.name",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dialog-footer",
                                                                attrs: {
                                                                  slot: "footer",
                                                                },
                                                                slot: "footer",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.voiceXg = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "取 消"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.xgLoadvo(
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "确定"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "danger",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.vohandleDelete(
                                                                  scope.$index,
                                                                  _vm.tableData
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.voplayer(
                                                                  scope.$index,
                                                                  _vm.tableData,
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("下载")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dialog",
                                                          {
                                                            attrs: {
                                                              title: "语音播放",
                                                              visible:
                                                                _vm.prevo,
                                                              width: "30%",
                                                              center: "",
                                                            },
                                                            on: {
                                                              "update:visible":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.prevo =
                                                                    $event
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "previ",
                                                              },
                                                              [
                                                                _c(
                                                                  "audio",
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        controls:
                                                                          "controls",
                                                                      },
                                                                    },
                                                                    "audio",
                                                                    {
                                                                      src:
                                                                        _vm.scImgurl +
                                                                        _vm.voSrc,
                                                                    },
                                                                    false
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-pagination", {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: {
                                              background: "",
                                              "current-page":
                                                _vm.formInline.pageNo,
                                              "page-size":
                                                _vm.formInline.pageSize,
                                              layout: "total,prev, pager, next",
                                              total: _vm.total,
                                            },
                                            on: {
                                              "current-change":
                                                _vm.vohandleCurrentChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-tab-pane",
                                        {
                                          attrs: {
                                            label: "视频",
                                            name: "fourth",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "视频（共" +
                                                      _vm._s(
                                                        _vm.total
                                                          ? _vm.total
                                                          : 0
                                                      ) +
                                                      "条）"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.videoUpopen,
                                                      },
                                                    },
                                                    [_vm._v("上传视频")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-dialog",
                                                    {
                                                      attrs: {
                                                        title: "上传视频",
                                                        visible: _vm.videoUp,
                                                      },
                                                      on: {
                                                        "update:visible":
                                                          function ($event) {
                                                            _vm.videoUp = $event
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-form",
                                                        {
                                                          attrs: {
                                                            model:
                                                              _vm.videoForm,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "视频名称",
                                                                "label-width":
                                                                  _vm.formLabelWidth,
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  autocomplete:
                                                                    "off",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .videoForm
                                                                      .name,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.videoForm,
                                                                        "name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "videoForm.name",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "视频内容",
                                                                "label-width":
                                                                  _vm.formLabelWidth,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  ref: "uploadvi",
                                                                  attrs: {
                                                                    action:
                                                                      _vm.posturl3,
                                                                    headers:
                                                                      _vm.myHeaders,
                                                                    "on-success":
                                                                      _vm.vihandleSuccess,
                                                                    "on-error":
                                                                      _vm.vierr,
                                                                    "on-change":
                                                                      _vm.vichange,
                                                                    "on-remove":
                                                                      _vm.virem,
                                                                    data: _vm.videoForm,
                                                                    "before-upload":
                                                                      _vm.viSize,
                                                                    limit: 1,
                                                                    "auto-upload":
                                                                      _vm.autoup,
                                                                    accept:
                                                                      ".MP4, .mp4",
                                                                    "show-file-list": true,
                                                                    "on-exceed":
                                                                      _vm.handleExceed,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        size: "small",
                                                                        type: "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "上传视频"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "el-upload__tip",
                                                                      attrs: {
                                                                        slot: "tip",
                                                                      },
                                                                      slot: "tip",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "请上传时长小于10m的视频，支持mp4视频格式，超出限制的视频请到腾讯视频上传。"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dialog-footer",
                                                          attrs: {
                                                            slot: "footer",
                                                          },
                                                          slot: "footer",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.videoUp = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("取 消")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                disabled:
                                                                  _vm.vidis,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.upLoadvi,
                                                              },
                                                            },
                                                            [_vm._v("确 定")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.viloaing,
                                                  expression: "viloaing",
                                                },
                                              ],
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                data: _vm.tableDatao,
                                                fit: "",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "name",
                                                  label: "名称",
                                                  width: "180",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "fileSize",
                                                  label: "大小",
                                                  width: "180",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "状态",
                                                  width: "180",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        scope.row.mediaId ==
                                                          "" || null
                                                          ? _c("el-col", [
                                                              _vm._v("未通过"),
                                                            ])
                                                          : _c("el-col", [
                                                              _vm._v("通过"),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "createTime",
                                                  label: "更新时间",
                                                  width: "230",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              scope.row
                                                                .createTime
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "操作",
                                                  width: "330",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.xgLoadviopen(
                                                                  scope.$index,
                                                                  _vm.tableDatao
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("编辑")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dialog",
                                                          {
                                                            attrs: {
                                                              title: "修改视频",
                                                              visible:
                                                                _vm.videoXg,
                                                            },
                                                            on: {
                                                              "update:visible":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.videoXg =
                                                                    $event
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-form",
                                                              {
                                                                attrs: {
                                                                  model:
                                                                    _vm.videoForm,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form-item",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "视频名称",
                                                                      "label-width":
                                                                        _vm.formLabelWidth,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .videoForm
                                                                              .name,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.videoForm,
                                                                                "name",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "videoForm.name",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dialog-footer",
                                                                attrs: {
                                                                  slot: "footer",
                                                                },
                                                                slot: "footer",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.videoXg = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "取 消"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.xgLoadvi(
                                                                            scope.$index,
                                                                            _vm.tableDatao
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "确 定"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "danger",
                                                              size: "small",
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteRow(
                                                                  scope.$index,
                                                                  _vm.tableDatao
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              size: "small",
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.previewvi(
                                                                  scope.$index,
                                                                  _vm.tableDatao
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("预览")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-dialog",
                                                          {
                                                            attrs: {
                                                              title: "视频预览",
                                                              visible:
                                                                _vm.previ,
                                                              width: "30%",
                                                              center: "",
                                                            },
                                                            on: {
                                                              "update:visible":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.previ =
                                                                    $event
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "previ",
                                                              },
                                                              [
                                                                _c(
                                                                  "video",
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        controls:
                                                                          "controls",
                                                                      },
                                                                    },
                                                                    "video",
                                                                    {
                                                                      src: _vm.viSrc,
                                                                    },
                                                                    false
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-pagination", {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: {
                                              background: "",
                                              "current-page":
                                                _vm.formInline.pageNo,
                                              "page-size":
                                                _vm.formInline.pageSize,
                                              layout: "total,prev, pager, next",
                                              total: _vm.total,
                                            },
                                            on: {
                                              "current-change":
                                                _vm.vihandleCurrentChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }