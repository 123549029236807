var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("经销商档案")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "keyword" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "关键字", prop: "keyword" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "50",
                                      clearable: "",
                                      placeholder: "编码/名称/联系人/手机号",
                                    },
                                    model: {
                                      value: _vm.searchForm.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "keyword", $$v)
                                      },
                                      expression: "searchForm.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "channelClassId" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "经销商分类",
                                    prop: "channelClassId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.channelClassId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "channelClassId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.channelClassId",
                                      },
                                    },
                                    _vm._l(_vm.classList, function (item) {
                                      return _c("el-option", {
                                        key: item.pid,
                                        attrs: {
                                          label: item.className,
                                          value: item.pid,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "channelGradeId" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "经销商等级",
                                    prop: "channelGradeId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.channelGradeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "channelGradeId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.channelGradeId",
                                      },
                                    },
                                    _vm._l(_vm.gradeList, function (item) {
                                      return _c("el-option", {
                                        key: item.pid,
                                        attrs: {
                                          label: item.gradeName,
                                          value: item.pid,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "regionId" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属大区",
                                    prop: "regionId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "regionId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.regionId",
                                      },
                                    },
                                    _vm._l(_vm.regionList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.regionName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "marketName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属市场",
                                    prop: "marketName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "40",
                                    },
                                    model: {
                                      value: _vm.searchForm.marketName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "marketName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.marketName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "masterChannelName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "主户头名称",
                                    prop: "masterChannelName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "40",
                                    },
                                    model: {
                                      value: _vm.searchForm.masterChannelName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "masterChannelName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.masterChannelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "archivesStatus" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "状态",
                                    prop: "archivesStatus",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.archivesStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "archivesStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.archivesStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "启用", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                            plain:
                                              _vm.btnConfig.searchBtn.plain,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.btnConfig.searchBtn.text
                                            ) + "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                            plain: _vm.btnConfig.resetBtn.plain,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.btnConfig.resetBtn.text
                                            ) + "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "btnArea" },
                [
                  _vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        { staticStyle: { width: "230px", display: "flex" } },
                        [
                          _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                          _vm.hasAuth("exportbtn", _vm.$route.meta.authList) ||
                          _vm.hasAuth("allinport", _vm.$route.meta.authList) ||
                          _vm.hasAuth(
                            "batchaddywybtn",
                            _vm.$route.meta.authList
                          ) ||
                          _vm.hasAuth(
                            "batchupdateywybtn",
                            _vm.$route.meta.authList
                          ) ||
                          _vm.hasAuth(
                            "batchdeleteywybtn",
                            _vm.$route.meta.authList
                          )
                            ? _c(
                                "div",
                                { staticClass: "head-btn-group" },
                                [
                                  _vm.hasAuth(
                                    "addbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.addBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.addBtn.icon,
                                            plain: _vm.btnConfig.plain,
                                          },
                                          on: { click: _vm.add },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.addBtn.text) +
                                              "\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "allinport",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            plain: _vm.btnConfig.plain,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: { click: _vm.uploadShow },
                                        },
                                        [
                                          _vm._v(
                                            "经销商采供关系导入\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "allinport",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            plain: _vm.btnConfig.plain,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: { click: _vm.uploadShow2 },
                                        },
                                        [
                                          _vm._v(
                                            "经销商关联业务员导入\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商编码",
                          prop: "channelCode",
                          "min-width": "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商名称",
                          prop: "channelName",
                          "min-width": "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商等级",
                          prop: "channelGradeName",
                          "min-width": "150",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商分类",
                          prop: "channelClassName",
                          "min-width": "150",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属大区",
                          "min-width": "100",
                          prop: "regionName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "市场",
                          "min-width": "100",
                          prop: "marketName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "主户头名称",
                          prop: "masterChannelName",
                          "min-width": "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "联系人",
                          "min-width": "100",
                          prop: "contacts",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "手机号",
                          "min-width": "150",
                          prop: "phone",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "省",
                          prop: "provinceName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "市",
                          prop: "cityName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "区县",
                          prop: "countyName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          "min-width": "80",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.archivesStatus == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用\n            ")]
                                      )
                                    : row.archivesStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用\n            ")]
                                      )
                                    : row.archivesStatus == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("删除\n            ")]
                                      )
                                    : _c("el-tag", [_vm._v("未激活")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2796237283
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          width: "200",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.detail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情\n            ")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑\n            ")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "startbtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "stopbtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  (_vm.hasAuth(
                                    "jumpbtn",
                                    _vm.$route.meta.authList
                                  ) &&
                                    _vm.hasPage(
                                      "/basic-data/channelmange/purchasingsupply/index",
                                      _vm.$route.meta.pageList
                                    )) ||
                                  _vm.hasAuth(
                                    "checkbtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "unlockbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-dropdown",
                                        { staticClass: "table-dropdown" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n                更多\n                "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _vm.hasAuth(
                                                "startbtn",
                                                _vm.$route.meta.authList
                                              ) ||
                                              _vm.hasAuth(
                                                "stopbtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _vm.hasAuth(
                                                        "startbtn",
                                                        _vm.$route.meta.authList
                                                      ) &&
                                                      row.archivesStatus != 1
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setInUse(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "启用\n                  "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.hasAuth(
                                                        "stopbtn",
                                                        _vm.$route.meta.authList
                                                      ) &&
                                                      row.archivesStatus != 0
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setUnUse(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "禁用\n                  "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "deletebtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      row.archivesStatus != 2
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.DoDel(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "jumpbtn",
                                                _vm.$route.meta.authList
                                              ) &&
                                              _vm.hasPage(
                                                "/basic-data/channelmange/purchasingsupply/index",
                                                _vm.$route.meta.pageList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                {
                                                                  path: "/basic-data/channelmange/purchasingsupply/index",
                                                                  query: {
                                                                    cName:
                                                                      row.channelName,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("查看采供关系")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "unlockbtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleRelieveALLs(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("解除业务")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          508081645
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.searchForm.current,
                      limit: _vm.searchForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.searchForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.searchForm, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "收发货验证修改",
            visible: _vm.centerDialogVisible,
            width: "600px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.checkStrong,
                callback: function ($$v) {
                  _vm.checkStrong = $$v
                },
                expression: "checkStrong",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("强验证")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("弱验证")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkStrong == 1,
                  expression: "checkStrong == 1",
                },
              ],
              staticStyle: { margin: "20px 0", "font-size": "16px" },
            },
            [
              _vm._v("\n      强验证下以下情况不允许进行出入库:\n      "),
              _vm._l(_vm.strongMsgList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: { margin: "10px", "font-size": "16px" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(index * 1 + 1 + ".") +
                        _vm._s(item.errorName) +
                        "\n      "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        1.非出库数码(入库校验)\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        2.数码未签收\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        3.数码重复出库\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        4.数码重复入库\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        5.数码不在库存中\n      ")]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkStrong == 0,
                  expression: "checkStrong == 0",
                },
              ],
              staticStyle: { margin: "10px 0", "font-size": "16px" },
            },
            [_vm._v("\n      弱验证下只进行数码是否真实的校验\n    ")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.setStrong },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.isSourceEnum2 ? {} : _vm.rules,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "经销商编码", prop: "channelCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled || _vm.title == "修改经销商档案",
                      maxlength: "20",
                      placeholder: "请输入手机号",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.channelCode = _vm.valENnum(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.channelCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channelCode", $$v)
                      },
                      expression: "ruleForm.channelCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经销商名称", prop: "channelName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isSourceEnum2, maxlength: "30" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.channelName = _vm.validZDMC(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channelName", $$v)
                      },
                      expression: "ruleForm.channelName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "纳税人识别号", prop: "taxpayerNumber" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isSourceEnum2 },
                    model: {
                      value: _vm.ruleForm.taxpayerNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "taxpayerNumber", $$v)
                      },
                      expression: "ruleForm.taxpayerNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "供货层级", prop: "channelLevelId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: { disabled: "", placeholder: "", clearable: "" },
                      model: {
                        value: _vm.ruleForm.channelLevelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "channelLevelId", $$v)
                        },
                        expression: "ruleForm.channelLevelId",
                      },
                    },
                    _vm._l(_vm.ghList, function (item) {
                      return _c("el-option", {
                        key: item.pid,
                        attrs: { label: item.name, value: item.pid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经销商分类", prop: "classId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.isSourceEnum2,
                        placeholder: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.classId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "classId", $$v)
                        },
                        expression: "ruleForm.classId",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.classStatus == 1,
                            expression: "item.classStatus == 1",
                          },
                        ],
                        key: item.pid,
                        attrs: { label: item.className, value: item.pid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经销商等级", prop: "gradeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.isSourceEnum2,
                        placeholder: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.gradeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "gradeId", $$v)
                        },
                        expression: "ruleForm.gradeId",
                      },
                    },
                    _vm._l(_vm.gradeList, function (item) {
                      return _c("el-option", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.gradeStatus == 1,
                            expression: "item.gradeStatus == 1",
                          },
                        ],
                        key: item.pid,
                        attrs: { label: item.gradeName, value: item.pid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { height: "40px" },
                  attrs: {
                    label: "地址",
                    required: _vm.ruleForm.saleAreaType > 1,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "provinceCode",
                                "label-width": "0",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    disabled: _vm.isSourceEnum2,
                                    placeholder: "省",
                                    clearable: "",
                                  },
                                  on: { change: _vm.ProvinceFun },
                                  model: {
                                    value: _vm.ruleForm.provinceCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "provinceCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.provinceCode",
                                  },
                                },
                                _vm._l(_vm.ProvinceList, function (item) {
                                  return _c("el-option", {
                                    key: item.pid,
                                    attrs: {
                                      label: item.areaName,
                                      value: item.areaCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "cityName",
                                "label-width": "0",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "市",
                                    disabled:
                                      !_vm.ruleForm.provinceCode ||
                                      _vm.isSourceEnum2,
                                    clearable: "",
                                    loading: _vm.aloading,
                                  },
                                  on: { change: _vm.CityFun },
                                  model: {
                                    value: _vm.ruleForm.cityCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "cityCode", $$v)
                                    },
                                    expression: "ruleForm.cityCode",
                                  },
                                },
                                _vm._l(_vm.CityList, function (item) {
                                  return _c("el-option", {
                                    key: item.pid,
                                    attrs: {
                                      label: item.areaName,
                                      value: item.areaCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "countyName",
                                "label-width": "0",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "区",
                                    disabled:
                                      !_vm.ruleForm.cityCode ||
                                      _vm.isSourceEnum2,
                                    clearable: "",
                                    loading: _vm.aloading,
                                  },
                                  on: { change: _vm.CountyFun },
                                  model: {
                                    value: _vm.ruleForm.countyCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "countyCode", $$v)
                                    },
                                    expression: "ruleForm.countyCode",
                                  },
                                },
                                _vm._l(_vm.CountyList, function (item) {
                                  return _c("el-option", {
                                    key: item.pid,
                                    attrs: {
                                      label: item.areaName,
                                      value: item.areaCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "detailedAddress",
                                "label-width": "0",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    !_vm.ruleForm.countyCode ||
                                    _vm.isSourceEnum2,
                                  placeholder: "请输入详细地址",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.ruleForm.detailedAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "detailedAddress",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.detailedAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { height: "40px" },
                  attrs: { label: "所属组织", prop: "regionId" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 4 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "regionId", "label-width": "0" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isSourceEnum2,
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: { change: _vm.regionFun },
                                  model: {
                                    value: _vm.ruleForm.regionName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "regionName", $$v)
                                    },
                                    expression: "ruleForm.regionName",
                                  },
                                },
                                _vm._l(_vm.regionList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.regionName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "teamId",
                                "label-width": "0",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      !_vm.ruleForm.regionName ||
                                      _vm.isSourceEnum2,
                                    clearable: "",
                                    loading: _vm.aloading,
                                  },
                                  on: { change: _vm.teamFun },
                                  model: {
                                    value: _vm.ruleForm.teamName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "teamName", $$v)
                                    },
                                    expression: "ruleForm.teamName",
                                  },
                                },
                                _vm._l(_vm.teamList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.regionName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                prop: "marketName",
                                "label-width": "0",
                                rules: [
                                  {
                                    required: false,
                                    validator: _vm.validateRegion,
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      !_vm.ruleForm.teamName ||
                                      _vm.isSourceEnum2,
                                    clearable: "",
                                    loading: _vm.aloading,
                                  },
                                  on: { change: _vm.marketFun },
                                  model: {
                                    value: _vm.ruleForm.marketName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "marketName", $$v)
                                    },
                                    expression: "ruleForm.marketName",
                                  },
                                },
                                _vm._l(_vm.marketList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.regionName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "salesmanIds" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.disabled,
                          expression: "!disabled",
                        },
                      ],
                      staticClass: "aBtn",
                      attrs: { type: "text" },
                      on: { click: _vm.openSel },
                    },
                    [_vm._v("选择员工\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._l(_vm.salesmanIds, function (tag) {
                      return _c(
                        "el-tag",
                        {
                          key: tag.pId,
                          staticStyle: { "margin-right": "10px" },
                          attrs: { closable: "", type: tag.type },
                          on: {
                            close: function ($event) {
                              return _vm.tag1Close(tag)
                            },
                          },
                        },
                        [_vm._v(_vm._s(tag.loginAccount) + "\n          ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contacts" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isSourceEnum2, maxlength: "10" },
                    model: {
                      value: _vm.ruleForm.contacts,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "contacts", $$v)
                      },
                      expression: "ruleForm.contacts",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isSourceEnum2,
                      value: _vm.ruleForm.phone,
                      maxlength: "11",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.phone = _vm.valENnum(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开户日期", prop: "openTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.isSourceEnum2,
                      type: "date",
                      placeholder: "",
                      format: "yyyy-MM-dd",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.ruleForm.openTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "openTime", $$v)
                      },
                      expression: "ruleForm.openTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经纬度" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { "label-width": "0", prop: "longitude" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isSourceEnum2,
                                  placeholder: "请输入经度",
                                },
                                model: {
                                  value: _vm.ruleForm.longitude,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "longitude", $$v)
                                  },
                                  expression: "ruleForm.longitude",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0", prop: "latitude" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.isSourceEnum2,
                                  placeholder: "请输入纬度",
                                },
                                model: {
                                  value: _vm.ruleForm.latitude,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "latitude", $$v)
                                  },
                                  expression: "ruleForm.latitude",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "100px",
                    "margin-bottom": "10px",
                  },
                },
                [_vm._v("\n        格式：116.335333\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮政编码", prop: "postalCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isSourceEnum2,
                      value: _vm.ruleForm.postalCode,
                      maxlength: "6",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.postalCode = _vm.valENnum(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电子邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isSourceEnum2 },
                    model: {
                      value: _vm.ruleForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "email", $$v)
                      },
                      expression: "ruleForm.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "图片附件", prop: "orgName" } },
                [
                  _c(
                    "el-upload",
                    {
                      class: { hide: _vm.hideUploadBtn },
                      attrs: {
                        disabled: _vm.isSourceEnum2,
                        action: _vm.uploadImg,
                        headers: _vm.myHeaders,
                        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                        "list-type": "picture-card",
                        limit: 5,
                        "file-list": _vm.imgList,
                        "on-change": _vm.selectImg,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-success": _vm.imgUploadSuccess,
                        "on-error": _vm.uploadFileFail,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          staticStyle: {
                            "margin-top": "0",
                            "line-height": "22px",
                          },
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            最多可配置5张图，建议尺寸：750*463，小于200KB\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isSourceEnum2,
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.scanVisible,
            "before-close": _vm.scanClose,
            width: "1200px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 3, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "经销商编码" } },
                    [_vm._v(_vm._s(_vm.scanData.channelCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "经销商名称" } },
                    [_vm._v(_vm._s(_vm.scanData.channelName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "经销商分类" } },
                    [_vm._v(_vm._s(_vm.scanData.className))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "经销商等级" } },
                    [_vm._v(_vm._s(_vm.scanData.gradeName))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "经销层级" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("channelLevelName")(_vm.scanData.channelLevelId)
                      ) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "纳税人识别号" } },
                    [_vm._v(_vm._s(_vm.scanData.taxpayerNumber))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "开户日期" } }, [
                    _vm._v(
                      _vm._s(_vm._f("textDateOnlyDate")(_vm.scanData.openTime))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "联系人" } }, [
                    _vm._v(_vm._s(_vm.scanData.contacts)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                    _vm._v(_vm._s(_vm.scanData.phone)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "邮政编码" } }, [
                    _vm._v(_vm._s(_vm.scanData.postalCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "电子邮箱" } }, [
                    _vm._v(_vm._s(_vm.scanData.email)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "地址" } }, [
                    _vm._v(_vm._s(_vm.scanData.add)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "大区" } }, [
                    _vm._v(_vm._s(_vm.scanData.regionName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "小组" } }, [
                    _vm._v(_vm._s(_vm.scanData.teamName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "市场" } }, [
                    _vm._v(_vm._s(_vm.scanData.marketName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "经纬度" } }, [
                    _vm._v(_vm._s(_vm.scanData.jwd)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.scanData.userName)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "dealerbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "业务员" } },
                    _vm._l(_vm.scanData.salesmans, function (item) {
                      return _c(
                        "el-tag",
                        {
                          key: item.pid,
                          staticStyle: {
                            "margin-right": "10px",
                            "margin-bottom": "10px",
                          },
                          attrs: { effect: "plain" },
                        },
                        [_vm._v(_vm._s(item.loginAccount) + "\n          ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "dealerbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.scanData.remarks) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "dealerbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "图片附件" } },
                    [
                      _c(
                        "el-row",
                        _vm._l(_vm.scanData.imgList, function (item, index) {
                          return _c("el-image", {
                            key: index,
                            staticStyle: {
                              width: "100px",
                              height: "100px",
                              "margin-right": "20px",
                            },
                            attrs: {
                              src: item,
                              "preview-src-list": _vm.scanData.imgList,
                              "z-index": 9999,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.scanClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogSalesman,
            width: "500px !important",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSalesman = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { margin: "3px" } }, [
            _vm._v("是否解除所选经销商当前所选业务"),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "30px 0 10px" } }, [
            _vm._v("业务员"),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "10px 0 10px" } }, [
            _vm._v("\n      姓名:"),
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v(_vm._s(_vm.mome.salesManName)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "10px 0 10px" } }, [
            _vm._v("\n      手机号:"),
            _c("span", { staticStyle: { "margin-left": "10px" } }, [
              _vm._v(_vm._s(_vm.mome.phoneNo)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.restSalesManName } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitSalesManName },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "经销商采供关系导入",
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                inline: true,
                model: _vm.uploadForm,
                rulse: _vm.uploadRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "down",
                      attrs: {
                        href: "https://baiyunbian-default-test.ks3-cn-beijing.ksyuncs.com/2023/11/1699240457921/经销商采供关系.xlsx",
                      },
                    },
                    [_vm._v("经销商采供关系导入Excel模板")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "orgName", label: "文件上传" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                limit: 1,
                                name: "file",
                                data: _vm.uploadForm,
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入\n              ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "经销商关联业务员导入",
            visible: _vm.uploadVisible2,
            "before-close": _vm.uploadClose2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm2",
              attrs: {
                inline: true,
                model: _vm.uploadForm2,
                rulse: _vm.uploadRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "orgName", label: "文件上传" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm2.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm2, "uploadFile", $$v)
                              },
                              expression: "uploadForm2.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl2,
                                limit: 1,
                                name: "file",
                                data: _vm.uploadForm2,
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList2,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile2,
                                "on-success": _vm.uploadFileRes2,
                                "on-error": _vm.uploadFileFail2,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.submitUpload2 },
                                },
                                [_vm._v("导入\n              ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.sloading,
              expression: "sloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "经销商档案手动同步",
            visible: _vm.SyncVisible,
            "before-close": _vm.SyncClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.SyncVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "Syncform",
              staticStyle: { height: "100px" },
              attrs: { model: _vm.Syncform, rules: _vm.Syncrules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间选择：", prop: "time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.Syncform.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.Syncform, "time", $$v)
                      },
                      expression: "Syncform.time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.SyncClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.SyncSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "业务员列表",
            visible: _vm.dialogSalesmanList,
            "before-close": _vm.selCancel,
            width: "850px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSalesmanList = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "page-section" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.querysalesman,
                            "status-icon": "",
                            "label-suffix": ":",
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "用户名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员用户名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman
                                                  .salesManAccount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManAccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManAccount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "姓名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员姓名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman.salesManName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button-group",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig
                                                      .searchBtn.type,
                                                    icon: _vm.btnConfig
                                                      .searchBtn.icon,
                                                    plain:
                                                      _vm.btnConfig.searchBtn
                                                        .plain,
                                                  },
                                                  on: { click: _vm.querylist },
                                                },
                                                [
                                                  _vm._v(
                                                    "搜索\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig.resetBtn
                                                      .type,
                                                    icon: _vm.btnConfig.resetBtn
                                                      .icon,
                                                    plain:
                                                      _vm.btnConfig.resetBtn
                                                        .plain,
                                                  },
                                                  on: {
                                                    click: _vm.resetQuerylist,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "重置\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.salesmanList,
                                "row-key": "salesManId",
                                height: "400px",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManAccount",
                                  label: "用户名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManName",
                                  label: "姓名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "200px",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSalesmanList(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.salesmanTotal > 0,
                                expression: "salesmanTotal > 0",
                              },
                            ],
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.salesmanTotal,
                              page: _vm.current,
                              limit: _vm.salesmanSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.current = $event
                              },
                              "update:limit": function ($event) {
                                _vm.salesmanSize = $event
                              },
                              pagination: function ($event) {
                                return _vm.getcomBoxSalesManlist()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("personnel-checkbox-dialog", {
        ref: "perCheckbox",
        attrs: {
          data: _vm.salesmanIds,
          "dealer-code": _vm.ruleForm.dealerCode,
          "is-readonly": true,
        },
        on: {
          "update:data": function ($event) {
            _vm.salesmanIds = $event
          },
          "update:dealerCode": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          "update:dealer-code": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          change: _vm.setSel,
        },
      }),
      _vm._v(" "),
      _vm.isShow
        ? _c("enterpriseUsers", {
            ref: "bagDialog",
            attrs: {
              id: _vm.ids,
              "sales-man-user-id": _vm.salesManUserId,
              "multiple-list": _vm.oldList,
            },
            on: {
              close: _vm.closePriseUsers,
              change: _vm.getMultipleSelection,
              getData: _vm.getData,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("channel-select-dialog", {
        ref: "channelSelect",
        attrs: { "channel-type": 1, "channel-type-can-change": true },
        on: { change: _vm.setCSel },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }