"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatasupportBoxLevelsupportboxindex',
  components: {
    Pagination: _Pagination.default,
    ProductSelectDialog: _productSelectDialog.default,
    productDialog: _productDialog.default
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入件数'));
      } else {
        var reg = /^\d{1,6}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入0-999999的正整数'));
        }
      }
    };
    return {
      title: '',
      listLoading: false,
      ruleForm: {
        boxSum: null,
        current: 1,
        productCode: null,
        productName: null,
        size: 20,
        supportBoxCode: null
      },
      ruleForm2: {
        boxSum: '',
        productCode: '',
        productId: '',
        productName: ''
      },
      rules: {
        productName: [{
          required: true,
          message: '请选择产品',
          trigger: 'change'
        }],
        boxSum: [{
          required: true,
          validator: checkNum,
          trigger: 'blur'
        }, {
          min: 1,
          max: 6,
          message: '最大长度为6',
          trigger: 'blur'
        }]
      },
      total: 0,
      tableKey: '000pbox',
      dialogFormVisible: false,
      mloading: false,
      dstate: false,
      tableData: [],
      // apis: [
      //   { authBtn: false, resourceCode: 'JDXTPZCX' },
      //   { authBtn: false, resourceCode: 'JDXTPZTJ' },
      //   { authBtn: false, resourceCode: 'JDXTPZGX' },
      //   { authBtn: false, resourceCode: 'JDXTPZSC' }
      // ],
      page2: false,
      // endShow: true,
      // operate: false,
      tabHeight: '100%',
      // 添加生产产品按钮
      addbtn: true,
      proShow: false,
      proList: [],
      plist: [],
      channelId: '',
      channelType: ''
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    number: function number(e) {
      var _value = e.target.value; // 获取到输入框的value值
      var reg = isNaN(_value); // 判断拿到的value是否为数字
      if (reg) {
        // 若为非数字键，则全部替换为空
        e.target.value = _value.replace(/[^1-9]/g, '');
      }
      if (_value === '0') {
        // 若为0，则替换为空
        this.ruleForm2.boxSum = '';
      }
    },
    // 选择产品
    openSel2: function openSel2() {
      this.proShow = true;
    },
    proClose2: function proClose2() {
      this.proShow = false;
    },
    // 选择产品
    setSel2: function setSel2(val) {
      this.proList = val;
      this.transFirstData(this.proList);
      if (this.proList.length == 1) {
        this.ruleForm2.productName = this.proList[0].productName;
        this.ruleForm2.productCode = this.proList[0].productCode;
      } else if (this.proList.length > 1) {
        this.ruleForm2.productName = "\u5DF2\u9009\u62E9".concat(this.proList.length);
        this.ruleForm2.productCode = '';
      } else if (this.proList.length == 0) {
        this.ruleForm2.productName = '';
        this.ruleForm2.productCode = '';
        this.proList = [];
      }
      this.proShow = false;
    },
    transFirstData: function transFirstData(data) {
      var that = this;
      if (data.length > 0) {
        var arr = [];
        data.forEach(function (item) {
          var obj = {};
          obj.packUnit = item.minScaleName;
          obj.planScanAtoNum = '';
          obj.planScanNum = '';
          obj.planScanAllNum = '';
          obj.prodBatchNo = '';
          obj.productId = item.pId;
          obj.productName = item.productName;
          obj.productCode = item.productCode;
          obj.prodClassName = item.productClassName;
          obj.packScaleDesc = item.packScaleDesc;
          obj.packRadioDesc = item.packScaleExpression;
          arr.push(obj);
        });
        console.log(arr);
        that.plist = that.uniqueArray(arr, 'productId');
      } else {
        that.plist = [];
      }
    },
    // json数组去重
    uniqueArray: function uniqueArray(array, key) {
      var result = [];
      for (var i = 0; i < array.length; i++) {
        var item = array[i];
        var repeat = false;
        for (var j = 0; j < this.plist.length; j++) {
          if (item[key] == this.plist[j][key]) {
            result.push(this.plist[j]);
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          result.push(item);
        }
      }
      console.log(result);
      return result;
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      console.log(val);
      this.ruleForm2.productId = val.pId;
      this.ruleForm2.productCode = val.productCode;
      this.ruleForm2.productName = val.productName;
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var searchData = (0, _utils.filterKeyNull)(that.ruleForm);
      (0, _pack.supportBoxLevelList)(searchData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    addE: function addE() {
      var that = this;
      this.authId('addbtn', this.$route.meta.authList);
      that.title = '添加托箱配置信息';
      that.addbtn = true;
      that.selectType = 'add';
      // that.PId = ''
      // that.ruleForm2.materialCode = ''
      // that.ruleForm2.factoryCode = ''
      // that.ruleForm2.configCode = ''
      // that.ruleForm2.packNumber = ''
      that.dialogFormVisible = true;
      // that.addbtn = false
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm2('ruleForm2');
    },
    DoEdit: function DoEdit(data) {
      var that = this;
      this.authId('updatebtn', this.$route.meta.authList);
      that.title = '编辑托箱配置信息';
      that.selectType = 'edit';
      that.ruleForm2.pId = data.pid;
      that.ruleForm2.boxSum = data.boxSum;
      that.ruleForm2.productCode = data.productCode;
      that.ruleForm2.productId = data.productId;
      that.ruleForm2.productName = data.productName;
      that.dialogFormVisible = true;
      that.addbtn = false;
    },
    DoDel: function DoDel(data) {
      var _this2 = this;
      var that = this;
      that.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('deletebtn', _this2.$route.meta.authList);
        (0, _pack.delsupportBoxById)({
          pId: data.pid
        }).then(function (response) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          if (response.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            that.$message({
              type: 'info',
              message: '删除失败!'
            });
          }
        });
      }).catch(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    },
    resetForm: function resetForm() {
      this.ruleForm = {
        boxSum: '',
        current: 1,
        productCode: '',
        productName: '',
        size: 20,
        supportBoxCode: ''
      };
      this.getList();
    },
    submitForm2: function submitForm2(formName) {
      var _this3 = this;
      var that = this;
      var type = that.selectType;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.mloading = true;
          that.dstate = true;
          var data = (0, _utils.filterKeyNull)(that.ruleForm2);
          if (type == 'edit') {
            _this3.authId('updatebtn', _this3.$route.meta.authList);
            (0, _pack.supportBoxLevelUpadte)(data).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '编辑成功!'
                });
                that.getList();
                that.dialogVisible = false;
                that.resetForm2('ruleForm2');
                that.mloading = false;
                that.dstate = false;
              } else {
                that.$message.error(res.data.msg);
                that.mloading = false;
                that.dstate = false;
                return;
              }
            }).catch(function (res) {
              that.mloading = false;
              that.dstate = false;
            });
          }
          if (type == 'add') {
            _this3.authId('addbtn', _this3.$route.meta.authList);
            var newarr = [];
            _this3.plist.forEach(function (item) {
              newarr.push(item.productId);
            });
            console.log(newarr);
            (0, _pack.supportBoxLevelInsertV2)({
              boxSum: data.boxSum,
              productIdList: newarr
            }).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '添加成功!'
                });
                that.getList();
                setTimeout(function () {
                  that.dialogVisible = false;
                  that.resetForm2('ruleForm2');
                  that.proList = [];
                  that.plist = [];
                  that.mloading = false;
                  that.dstate = false;
                }, 1000);
              } else {
                that.$message.error(res.data.msg);
                that.mloading = false;
                that.dstate = false;
                return;
              }
            }).catch(function (res) {
              that.mloading = false;
              that.dstate = false;
            });
            // supportBoxLevelInsert(data)
            //   .then(res => {
            //     if (res.data.code == 200) {
            //       that.$message({
            //         type: 'success',
            //         message: '添加成功!'
            //       })
            //       that.getList()
            //       setTimeout(function() {
            //         that.dialogVisible = false
            //         that.resetForm2('ruleForm2')
            //         that.mloading = false
            //         that.dstate = false
            //       }, 1000)
            //     } else {
            //       that.$message.error(res.data.msg)
            //       that.mloading = false
            //       that.dstate = false
            //       return
            //     }
            //   })
            //   .catch(res => {
            //     that.mloading = false
            //     that.dstate = false
            //   })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm2: function resetForm2(formName) {
      var that = this;
      this.ruleForm2 = {
        boxSum: '',
        productCode: '',
        productId: '',
        productName: ''
      };
      that.$refs[formName].resetFields();
      that.dialogFormVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    }
  }
};
exports.default = _default;