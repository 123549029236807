"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _rebate = require("@/api/chanelmange/rebate");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    codeVisible: Boolean,
    channelArchiveId: String,
    rebateNo: String
  },
  data: function data() {
    return {
      listQuery: {
        channelArchiveId: '',
        pageNo: 1,
        pageSize: 10,
        rebateNo: ''
      },
      listLoading: false,
      tableKey: 'codeScan',
      tabHeight: '100%',
      total: 0,
      tableData: []
    };
  },
  watch: {
    codeVisible: function codeVisible(value) {
      var that = this;
      if (value == true) {
        that.listQuery.channelArchiveId = that.channelArchiveId;
        that.listQuery.rebateNo = that.rebateNo;
        that.getList();
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _rebate.scanCodeDetail)(that.listQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 500);
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('dialogHide');
    }
  }
};
exports.default = _default;