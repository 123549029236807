var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-hover-mask" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "vue-hover-mask_action",
          on: { click: _vm.handleClick },
        },
        [_vm._t("action")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }