var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择",
        visible: _vm.taskVisible,
        "before-close": _vm.handleClose,
        width: "1120px !important",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.taskVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "billNo", label: "单号:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.tradeBillQuery.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery.tradeBillQuery,
                                      "billNo",
                                      $$v
                                    )
                                  },
                                  expression: "listQuery.tradeBillQuery.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "relateNo",
                                label: "关联出库单号:",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value:
                                    _vm.listQuery.tradeBillQuery.billSaleQuery
                                      .relateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery.tradeBillQuery
                                        .billSaleQuery,
                                      "relateNo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "listQuery.tradeBillQuery.billSaleQuery.relateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "outOrgId",
                                    label: "出库企业:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.listQuery.tradeBillQuery
                                            .billSaleQuery.outOrgId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery.tradeBillQuery
                                              .billSaleQuery,
                                            "outOrgId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "listQuery.tradeBillQuery.billSaleQuery.outOrgId",
                                      },
                                    },
                                    _vm._l(_vm.OrgList, function (item) {
                                      return _c("el-option", {
                                        key: item.orgId,
                                        attrs: {
                                          label: item.orgName,
                                          value: item.orgId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showCol || !_vm.formConfig.btnFollow,
                              expression: "showCol||!formConfig.btnFollow",
                            },
                          ],
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "outStoreId", label: "出库库房:" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value:
                                      _vm.listQuery.tradeBillQuery.billSaleQuery
                                        .outStoreId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery.tradeBillQuery
                                          .billSaleQuery,
                                        "outStoreId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "listQuery.tradeBillQuery.billSaleQuery.outStoreId",
                                  },
                                },
                                _vm._l(_vm.storeList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.storeHouseName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billStatus",
                                    label: "单据状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.listQuery.tradeBillQuery
                                            .billStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery.tradeBillQuery,
                                            "billStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "listQuery.tradeBillQuery.billStatus",
                                      },
                                    },
                                    _vm._l(_vm.statusList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "isSplitBill",
                                    label: "拆单状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.listQuery.tradeBillQuery
                                            .billSaleQuery.isSplitBill,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery.tradeBillQuery
                                              .billSaleQuery,
                                            "isSplitBill",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "listQuery.tradeBillQuery.billSaleQuery.isSplitBill",
                                      },
                                    },
                                    _vm._l(_vm.splitedList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "creatDate",
                                    label: "创建日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.creatDate,
                                      callback: function ($$v) {
                                        _vm.creatDate = $$v
                                      },
                                      expression: "creatDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.DoSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  false
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoReset(_vm.listQuery)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  false
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.expand },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.expandTxt) +
                                          "\n                "
                                      ),
                                      _c("i", {
                                        class:
                                          _vm.showCol == false
                                            ? "el-icon-arrow-down"
                                            : "el-icon-arrow-up",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.formConfig.btnFollow
            ? _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: { click: _vm.DoSearch },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.DoReset(_vm.listQuery)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { type: "text" }, on: { click: _vm.expand } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.expandTxt) +
                              "\n          "
                          ),
                          _c("i", {
                            class:
                              _vm.showCol == false
                                ? "el-icon-arrow-down"
                                : "el-icon-arrow-up",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "单号",
                  "min-width": "160",
                  prop: "billNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "关联出库单号",
                  "min-width": "160",
                  prop: "relateNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发货单位",
                  "min-width": "120",
                  prop: "outOrgName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "出库库房",
                  "min-width": "120",
                  prop: "outStoreName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "收货单位",
                  "min-width": "120",
                  prop: "inOrgName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "入库库房",
                      "min-width": "90",
                      prop: "inStoreName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "拆单状态",
                      "min-width": "110",
                      prop: "splitStatus",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.splitStatus == 0
                                ? _c("span", [_vm._v("未拆单")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.splitStatus == 1
                                ? _c("span", [_vm._v("部分拆单")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.splitStatus == 2
                                ? _c("span", [_vm._v("已拆单")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      801909702
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "单据状态",
                    "min-width": "110",
                    prop: "billStatus",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("getStauts")(
                                  row.billStatus,
                                  row.billStatus
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v("-->\n      ")]
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "min-width": "110",
                  prop: "createUser",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "min-width": "110",
                  prop: "createTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoEdit(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.current,
              limit: _vm.listQuery.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }