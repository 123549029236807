"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCityList = getCityList;
exports.getProvinceList = getProvinceList;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/api'
var baseUrl = process.env.VUE_APP_VERSION1;
// 获取所有省
function getProvinceList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-basic/dealerArea/findProvinces',
    method: 'get',
    params: params
  });
}
// 获取下级区划
function getCityList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-basic/dealerArea/nextArea',
    method: 'post',
    data: data
  });
}
// //  获取市所有区
// export function getCountyList(proId, cityId) {
//   return request({
//     url: baseUrl + '/api-uspyx/zoning/countyList',
//     method: 'get',
//     params: { proId: proId, cityId: cityId }
//   })
// }