var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "概览",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1100px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c("h3", [_vm._v("活动计划")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                { staticClass: "form-div", staticStyle: { width: "auto" } },
                [_vm._v("参与活动时间:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planDuration) + " 天"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                { staticClass: "form-div", staticStyle: { width: "auto" } },
                [_vm._v("参与活动产品数量:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planProductCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                { staticClass: "form-div", staticStyle: { width: "auto" } },
                [_vm._v("参与经销商数量:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planChannelCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("活动预算:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planBudgetAmount) + " 元"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("特殊设置:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planPrRuleCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("奖品种类:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planPrizeTypeCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("奖品数量:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.planPrizeCount) + " 个"),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border" }),
      _vm._v(" "),
      _c("h3", [_vm._v("活动实际数据")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                { staticClass: "form-div", staticStyle: { width: "auto" } },
                [_vm._v("活动开始天数:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.realDuration) + " 天"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("实际扫码数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.scanMarkCodeCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("已领奖数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.successPrizeCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("领取失败数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.failPrizeCount) + " 个"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("未领取数:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.waitPrizeCount) + " 个"),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.scanData.prizeItemsList,
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "奖品等级",
              prop: "priority",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterPriority")(row.priority))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "总数",
              prop: "prizeNum",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "已领取数",
              prop: "receivedPrizeNum",
              align: _vm.tableConfig.align,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "领取失败数",
              prop: "receivedFailPrizeNum",
              align: _vm.tableConfig.align,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "剩余数",
              prop: "residualPrizeNum",
              align: _vm.tableConfig.align,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "设置概率",
              prop: "prizePr",
              align: _vm.tableConfig.align,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.prizePr) + "%")])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }