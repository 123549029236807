"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      dataForm: {
        consignmentName: '',
        consignmentMobile: '',
        consignmentAddr: ''
      },
      dataRule: {
        consignmentName: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        consignmentMobile: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        consignmentAddr: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    init: function init(orderNumber, dvyId, dvyFlowId) {
      var _this = this;
      this.visible = true;
      this.$nextTick(function () {
        _this.$refs['dataForm'].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.visible = false;
          _this2.$emit('inputCallback', _this2.dataForm);
        }
      });
    }
  }
};
exports.default = _default;