"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _scrollTo = require("@/utils/scroll-to");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _policyDialog = _interopRequireDefault(require("@/components/policyDialog"));
var _Zorgan = _interopRequireDefault(require("@/components/Zorgan"));
var _Horgan = _interopRequireDefault(require("@/components/Horgan"));
var _BatchDialog = _interopRequireDefault(require("@/components/BatchDialog"));
var _templateManage = require("@/api/terminal/templateManage");
var _basicConfig = require("@/api/terminal/basicConfig");
var _prizeMange = require("@/api/terminal/prizeMange");
var _timers = require("timers");
var _activityManage = require("@/api/terminal/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { activityAlllList } from '@/api/basic/pack'
var _default = {
  components: {
    ProDialog: _productDialog.default,
    BatchDialog: _BatchDialog.default,
    Zorgan: _Zorgan.default,
    Horgan: _Horgan.default,
    PolicyDialog: _policyDialog.default
  },
  filters: {
    intToChinese: function intToChinese(index) {
      var comtype = {
        0: '奖项一：',
        1: '奖项二：',
        2: '奖项三：',
        3: '奖项四：',
        4: '奖项五：',
        5: '奖项六：',
        6: '奖项七：',
        7: '奖项八：',
        8: '奖项九：',
        9: '奖项十：'
      };
      var result = comtype[index];
      return result;
    }
  },
  props: {
    dialogVisible: Boolean,
    type: null,
    actNo: null,
    showOnly: null,
    copy: null,
    num: null
  },
  data: function data() {
    var _this2 = this;
    var checkEmail = function checkEmail(rule, value, callback) {
      if (!value && (_this2.pageTwoForm.actHasWarning * 1 === 1 || _this2.pageTwoForm.actEndtimeWarning * 1 === 1)) {
        return callback(new Error('请输入邮箱地址'));
      } else {
        return callback();
      }
    };
    var _this = this;
    return {
      subLoading: false,
      isDisable: false,
      PolicyDialog: false,
      productVisibleShow: false,
      AproductVisibleShow: false,
      batcheVisibleShow: false,
      v_batcheVisibleShow: false,
      h_batcheVisibleShow: false,
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      pickerOptions2: {
        disabledDate: function disabledDate(time) {
          var aTime = _this.pageOneForm.dataTimeRange;
          var start = aTime[0] ? aTime[0].replace(/\-/g, '/') : '';
          var end = aTime[1] ? aTime[1].replace(/\-/g, '/') : '';
          return time.getTime() < new Date(start).getTime() || time.getTime() > new Date(end).getTime();
        }
      },
      listLoading: true,
      active: 0,
      // 基础设置
      pageOneForm: {
        id: null,
        dataTimeRange: [],
        type: '1',
        name: '',
        templateId: '',
        actExplain: '',
        actRules: '',
        product: '',
        batch: '',
        v_batch: '',
        h_batch: '',
        wxOfficialId: '',
        lq: [],
        payway: ['红包'],
        productIdList: [],
        v_productIdList: [],
        h_productIdList: [],
        redOrTransfer: 0,
        policy: '',
        distributor: '',
        // organization: "",
        participant: '0',
        actType: 1,
        followOfficial: '0',
        productList: [],
        polictName: ''
      },
      // 活动项设置
      pageTwoForm: {
        perPersonJoinTimes: '',
        perPersonDayJoinTimes: '',
        perPersonHitTimes: '',
        perPersonDayHitTimes: '',
        actHasWarning: '0',
        actWarningEmail: '',
        actWarningThreshold: '10',
        actWarningRate: '',
        actEndtimeWarning: '',
        actEndtimeWarningDay: '',
        actEndtimeWarningRate: ''
        // follow: ""
      },

      // 红包
      codeForm: {
        codeUsual: '谢谢惠顾',
        probability: '',
        num: '不限量',
        prizenum: 1000,
        surplus: '',
        actJf: '',
        prizeList: [{
          type: '1',
          prize: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          type: '3',
          prize: '谢谢惠顾',
          scale: '',
          amount: '9999999',
          prizeId: '',
          costprize: 0,
          prizeType: '5000'
        }]
      },
      // 集字
      characterForm: {
        toplimitDay: '',
        toplimitPerson: '',
        characterGathering: 1,
        isRare: '',
        // total: 0,
        wordList: [{
          wordcontent: '',
          probability: '',
          wordnum: ''
        }, {
          wordcontent: '',
          probability: '',
          wordnum: ''
        }],
        wordUsual: '',
        probabilityUsual: '',
        prizeList: [{
          type: '1',
          words: [],
          prizeType: '',
          amount: '',
          dataRange: [],
          budget: '',
          prizeExplain: '',
          prizeId: '',
          costprize: 0
        }]
      },
      // 转盘
      panForm: {
        size: 8,
        tableData: [{
          prizeLevel: 1,
          type: '3',
          name: '谢谢惠顾',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '不限制',
          costprize: 0,
          prizeId: 1,
          prizeType: '5000'
        }, {
          prizeLevel: 2,
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0,
          prizeId: ''
        }, {
          prizeLevel: 3,
          prizeId: '',
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 4,
          prizeId: '',
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 5,
          prizeId: '',
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 6,
          prizeId: '',
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 7,
          prizeId: '',
          type: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 8,
          type: '',
          prizeId: '',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '',
          costprize: 0
        }]
      },
      // 活动预期
      expectForm: {
        hasAnticipate: '0',
        expectTable: [{
          startTime: '',
          endTime: '',
          actPar: '',
          actParnum: '',
          addMerberNum: '',
          prizePercent: ''
        }, {
          startTime: '',
          endTime: '',
          actPar: '',
          actParnum: '',
          addMerberNum: '',
          prizePercent: ''
        }]
      },
      // 基础设置验证
      pageOneRules: {
        name: [{
          required: true,
          message: '活动名称不能为空',
          trigger: 'change'
        }],
        dataTimeRange: [{
          required: true,
          message: '请选择活动日期',
          trigger: 'change'
        }],
        actRules: [{
          required: true,
          message: '活动规则不能为空',
          trigger: 'change'
        }],
        product: [{
          required: true,
          message: '请选择活动产品',
          trigger: 'change'
        }],
        participant: [{
          required: true,
          message: '请选择活动对象',
          trigger: 'change'
        }],
        templateId: [{
          required: true,
          message: '请选择模板',
          trigger: 'change'
        }],
        wxOfficialId: [{
          required: true,
          message: '请选择活动公众号',
          trigger: 'change'
        }],
        // product: [{ required: true, message: '请选择产品', trigger: 'change' }],
        followOfficial: [{
          required: true,
          message: '请选择是否关注公众号',
          trigger: 'change'
        }]
        // redOrTransfer: [
        //   { required: true, message: '请选择支付方式', trigger: 'change' }
        // ]
      },

      // 活动项设置校验
      pageTwoFormRules: {
        actWarningEmail: [{
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }],
        actWarningRate: [{
          required: true,
          message: '请选择奖品预警频率',
          trigger: 'change'
        }],
        actWarningThreshold: [{
          required: true,
          message: '请选填写奖品预警阀值',
          trigger: 'change'
        }],
        actEndtimeWarningDay: [{
          required: true,
          message: '请选填写活动结束预警阀值',
          trigger: 'change'
        }],
        actEndtimeWarningRate: [{
          required: true,
          message: '请选择活动结束预警频率',
          trigger: 'change'
        }]
      },
      //
      codeFormRules: {},
      characterFormRules: {
        //   characterGathering: [
        //     { required: true, message: '请选择集字玩法', trigger: 'change' }
        //   ]
      },
      panFormRules: {},
      mbList: [],
      wcList: [],
      pList: []
    };
  },
  computed: {
    // 红包概率和
    probabilityC: function probabilityC() {
      var num = 100;
      this.codeForm.prizeList.forEach(function (item) {
        if (item.prizeType != 5000) {
          num = num - item.scale * 1;
        }
      });
      return num.toFixed(2);
    },
    // 转盘概率和
    probabilityP: function probabilityP() {
      var num = 100;
      this.panForm.tableData.forEach(function (item) {
        if (item.prizeLevel !== 1) {
          var sum = item.scale * 1;
          num = num - sum;
        }
      });
      return num.toFixed(2);
    },
    // 集字概率和
    probabilityZ: function probabilityZ() {
      var num = 100;
      this.characterForm.wordList.forEach(function (item) {
        var sum = item.probability * 1;
        num = num - sum;
      });
      return num.toFixed(2);
    },
    // 预期概率和
    probabilityY: function probabilityY() {
      var num = 100;
      this.expectForm.expectTable.forEach(function (item) {
        var sum = item.prizePercent * 1;
        num = num - sum;
      });
      return num.toFixed(2);
    },
    // 集字活动预算
    wordTotal: function wordTotal() {
      var total = 0;
      this.characterForm.prizeList.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2) + '元' || 0;
    },
    // 红包活动预算
    codeTotal: function codeTotal() {
      var total = 0;
      this.codeForm.prizeList.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2) + '元';
    },
    // 大转盘活动预算
    panTotal: function panTotal() {
      var total = 0;
      this.panForm.tableData.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2);
    }
  },
  watch: {
    showOnly: function showOnly(newValue, oldValue) {}
  },
  mounted: function mounted() {
    var _this3 = this;
    this.customlistFun();
    this.setPan(8);
    // getList({
    //   pageNo: 1,
    //   pageSize: 9999,
    //   templateType: this.type,
    //   state: this.num == 1 ? '' : '1'
    // }).then((res) => {
    //   res = res.data
    //   if (res.status * 1 === 200) {
    //     this.mbList = res.data.list
    //   } else {
    //     this.$message.closeAll()
    //     this.$message({
    //       message: res.msg,
    //       type: 'error',
    //       duration: 3 * 1000
    //     })
    //   }
    // })
    (0, _basicConfig.wxgetWcList)().then(function (res) {
      res = res.data;
      if (res.state === '200') {
        _this3.wcList = res.data;
        // officialId
        _this3.pageOneForm.wxOfficialId = res.data[0].id;
      } else {
        _this3.$message.closeAll();
        _this3.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    });
    (0, _prizeMange.getPList)({
      pageindex: 1,
      pagesize: 9999,
      state: this.num == 1 ? '' : '1'
    }).then(function (res) {
      res = res.data;
      if (res.state === '200') {
        _this3.pList = res.data.list;
      } else {
        _this3.$message.closeAll();
        _this3.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    }).then(function (res) {
      if (_this3.actNo) {
        (0, _activityManage.detailpage)({
          actNo: _this3.actNo
        }).then(function (res) {
          res = res.data;
          if (res.status * 1 === 200) {
            var product = [];
            res.data.dataTimeRange = [res.data.startTime, res.data.endTime];
            switch (res.data.hasInfo) {
              case '000':
                res.data.lq = [];
                break;
              case '100':
                res.data.lq = ['1'];
                break;
              case '110':
                res.data.lq = ['1', '2'];
                break;
              case '101':
                res.data.lq = ['1', '3'];
                break;
              case '111':
                res.data.lq = ['1', '2', '3'];
                break;
              case '010':
                res.data.lq = ['2'];
                break;
              case '011':
                res.data.lq = ['2', '3'];
                break;
              case '001':
                res.data.lq = ['3'];
                break;
            }
            var aObj = [];
            var aId = [];
            res.data.products.forEach(function (item) {
              product.push(item.productName);
              var obj = {
                pId: item.productId,
                pIproductBrandIdd: item.productBrand,
                productName: item.productName,
                productCode: item.productNo,
                productClassId: item.productType,
                productModel: item.productNorms
              };
              aObj.push(obj);
              aId.push(item.productId);
            });
            var aBatche = [];
            res.data.batcheNos.forEach(function (item) {
              aBatche.push(item.batchNo);
            });
            var dealer = [];
            res.data.dealer.forEach(function (item) {
              dealer.push(item.dealerName);
            });
            var vBatche = [];
            res.data.organ.forEach(function (item) {
              vBatche.push(item.organName);
            });
            // var lengthnum = Number(res.data.dealer.length)
            res.data.productIdList = aId;
            res.data.batch = aBatche.join(',');
            res.data.productList = aObj;
            res.data.batchList = res.data.batcheNos;
            res.data.actRConfigOrgan = res.data.organ;
            res.data.v_batch = vBatche.join(',');
            res.data.h_batch = dealer;
            res.data.product = product;
            _this3.pageOneForm = res.data;
            _this3.pageTwoForm = res.data;
            _this3.pageTwoForm.actEndtimeWarningRate = Number(res.data.actEndtimeWarningRate);
            _this3.codeForm.actJf = res.data.actJf;
            _this3.pageTwoForm.actEndtimeWarning = res.data.actEndtimeWarning.toString();
            switch (_this3.type * 1) {
              case 1:
                _this3.characterForm.characterGathering = res.data.actWordTeype * 1;
                // this.characterForm.prizeList = res.data.prizesConfig
                res.data.prizesConfig.forEach(function (val, key) {
                  if (key * 1 !== 0) {
                    _this3.characterForm.prizeList.push({
                      words: [],
                      prizeType: '',
                      amount: '',
                      dataRange: [],
                      budget: '',
                      prizeExplain: '',
                      prizeId: '',
                      costprize: 0
                    });
                  }
                  _this3.characterForm.prizeList[key].dataRange = [val.prizeStarttime, val.prizeEndtime];
                  if (val.prizeType != 6000) {
                    _this3.characterForm.prizeList[key].type = '1';
                  } else {
                    _this3.characterForm.prizeList[key].type = '2';
                    _this3.characterForm.prizeList[key].integral = val.integral;
                  }
                  _this3.characterForm.prizeList[key].amount = val.amount;
                  _this3.characterForm.prizeList[key].prizeId = val.prizeId;
                  _this3.characterForm.prizeList[key].prizeExplain = val.prizeExplain;
                  val.wordRuleIds.forEach(function (item) {
                    var num = item.wordorder * 1 - 1;
                    _this3.characterForm.prizeList[key].words.push(num);
                  });
                  // val.dataRange = [val.prizeStarttime, val.prizeEndtime]
                  // val.type = '1'
                  _this3.pList.forEach(function (item) {
                    if (item.id === val.prizeId) {
                      _this3.characterForm.prizeList[key].costprize = 0;
                      if (item.costprize) {
                        _this3.characterForm.prizeList[key].costprize = item.costprize * 1;
                      }
                      _this3.characterForm.prizeList[key].prizeType = item.type;
                    }
                  });
                  // this.characterForm.prizeList[key].words = []
                  // val.wordRuleIds.forEach(item => {
                  //   var num = item.wordorder * 1 - 1
                  //   this.characterForm.prizeList[key].words.push(num)
                  // })
                  // val.words = arr
                });

                res.data.words.forEach(function (item) {
                  if (item.isScarcity * 1 === 1) {
                    _this3.characterForm.isRare = item.wordorder - 1;
                    _this3.characterForm.toplimitDay = item.perPersonDayHitTimes;
                    _this3.characterForm.toplimitPerson = item.perPersonHitTimes;
                  }
                });
                _this3.characterForm.wordList = JSON.parse(JSON.stringify(res.data.words));
                break;
              // 红包
              case 2:
                res.data.prizesConfig.forEach(function (item, index) {
                  if (item.prizeType != 6000 && item.prizeType != 5000) {
                    item.type = '1';
                    _this3.pList.forEach(function (val) {
                      if (val.id === item.prizeId) {
                        item.costprize = 0;
                        if (val.costprize) {
                          item.costprize = val.costprize * 1;
                          item.prizeType = val.type;
                        }
                      }
                    });
                  } else {
                    item.type = '2';
                    // item.integral = item.integral
                  }
                });

                _this3.codeForm.prizeList = JSON.parse(JSON.stringify(res.data.prizesConfig));
                break;
              // 转盘
              case 3:
                _this3.panForm.size = res.data.actDialType;
                res.data.prizesConfig.forEach(function (item, index) {
                  switch (item.prizeType * 1) {
                    case 5000:
                      item.type = '3';
                      item.name = '谢谢惠顾';
                      item.img = require('@/assets/images/xxhg.png');
                      item.costprize = 0;
                      item.prizeId = 1;
                      item.prizeType = '5000';
                      item.amount = '不限制';
                      break;
                    case 6000:
                      item.type = '2';
                      item.img = require('@/assets/images/jifen.png');
                      // item.integral = item.integral
                      break;
                    default:
                      item.type = '1';
                      _this3.pList.forEach(function (val) {
                        if (val.id === item.prizeId) {
                          item.costprize = 0;
                          if (val.costprize) {
                            item.costprize = val.costprize * 1;
                          }
                          item.prizeType = val.type;
                          item.img = val.imgUrl;
                        }
                      });
                  }
                  // if (item.prizeType === '') {
                  // } else {
                  //   item.type = '1'
                  //   this.pList.forEach(val => {
                  //     if (val.id === item.prizeId) {
                  //       item.costprize = 0
                  //       if (val.costprize) item.costprize = val.costprize * 1
                  //       item.prizeType = val.type
                  //       item.img = val.imgUrl
                  //     }
                  //   })
                  // }
                });

                _this3.panForm.tableData = JSON.parse(JSON.stringify(res.data.prizesConfig));
                _this3.panForm.tableData[0] = {
                  prizeLevel: 1,
                  type: '3',
                  name: '谢谢惠顾',
                  img: require('@/assets/images/xxhg.png'),
                  scale: '',
                  amount: '不限制',
                  costprize: 0,
                  prizeId: 1,
                  prizeType: '5000'
                };
                break;
              case 4:
                // this.panForm.size = res.data.actDialType
                res.data.prizesConfig.forEach(function (item, index) {
                  switch (item.prizeType * 1) {
                    case 5000:
                      item.type = '3';
                      item.name = '谢谢惠顾';
                      item.img = require('@/assets/images/xxhg.png');
                      item.costprize = 0;
                      item.prizeId = 1;
                      item.prizeType = '5000';
                      item.amount = '不限制';
                      break;
                    case 6000:
                      item.type = '2';
                      item.img = require('@/assets/images/jifen.png');
                      // item.integral = item.integral
                      break;
                    default:
                      item.type = '1';
                      _this3.pList.forEach(function (val) {
                        if (val.id === item.prizeId) {
                          item.costprize = 0;
                          if (val.costprize) {
                            item.costprize = val.costprize * 1;
                          }
                          item.prizeType = val.type;
                          item.img = val.imgUrl;
                        }
                      });
                  }
                  // if (item.prizeType === '') {
                  // } else {
                  //   item.type = '1'
                  //   this.pList.forEach(val => {
                  //     if (val.id === item.prizeId) {
                  //       item.costprize = 0
                  //       if (val.costprize) item.costprize = val.costprize * 1
                  //       item.prizeType = val.type
                  //       item.img = val.imgUrl
                  //     }
                  //   })
                  // }
                });

                _this3.panForm.tableData = JSON.parse(JSON.stringify(res.data.prizesConfig));
                // this.panForm.tableData[0] = {
                //   prizeLevel: 1,
                //   type: '3',
                //   name: '谢谢惠顾',
                //   img: require('@/assets/images/xxhg.png'),
                //   scale: '',
                //   amount: '不限制',
                //   costprize: 0,
                //   prizeId: 1,
                //   prizeType: '5000'
                // }
                break;
            }
            _this3.expectForm.hasAnticipate = res.data.hasAnticipate ? res.data.hasAnticipate : '0';
            if (_this3.expectForm.hasAnticipate * 1 === 1) {
              _this3.expectForm.expectTable = res.data.anticipate;
            }
          } else {
            _this3.$message.closeAll();
            _this3.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    });
  },
  methods: {
    // 获取模板
    customlistFun: function customlistFun() {
      var _this4 = this;
      (0, _activityManage.customlist)({
        pageNo: 1,
        pageSize: 9999,
        templateType: 1
      }).then(function (res) {
        if (res.data.status * 1 === 200) {
          _this4.mbList = res.data.data.list;
        } else {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    goBack: function goBack() {
      this.$emit('close');
    },
    checkPanPercent: function checkPanPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.panForm.tableData[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.panForm.tableData[index][itemName] = '';
      }
      this.panForm.tableData[0].scale = this.probabilityP;
    },
    checkExpectPercent: function checkExpectPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.expectForm.expectTable[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.expectForm.expectTable[index][itemName] = '';
      }
    },
    checkExpectNum: function checkExpectNum(itemName, val, index, $el) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.expectForm.expectTable[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.expectForm.expectTable[index][itemName] = '';
      }
    },
    checCodePercent: function checCodePercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.codeForm.prizeList[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.codeForm.prizeList[index][itemName] = '';
      }
    },
    checkCodetNum: function checkCodetNum(itemName, val, index) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.codeForm.prizeList[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.codeForm.prizeList[index][itemName] = '';
      }
    },
    checCharacterPercent: function checCharacterPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.characterForm.wordList[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.characterForm.wordList[index][itemName] = '';
      }
    },
    checkCharactertNum: function checkCharactertNum(itemName, val, index) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.characterForm.wordList[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.characterForm.wordList[index][itemName] = '';
      }
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;
      this.pageOneForm = Object.assign({}, row); // copy obj
      this.pageOneForm.timestamp = new Date(this.pageOneForm.timestamp);
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this6.pageOneForm);
          tempData.timestamp = +new Date(tempData.timestamp);
        }
      });
    },
    prev: function prev() {
      if (this.active === 3 && this.type * 1 === 3) {
        this.setPan(this.panForm.size);
      }
      if (this.active === 1) {
        this.$refs.pageTwoForm.clearValidate();
      }
      if (this.active === 2 && this.type * 1 === 1) {
        this.$refs.characterForm.clearValidate();
      }
      if (this.active === 2 && this.type * 1 === 2) {
        this.$refs.codeForm.clearValidate();
      }
      if (this.active === 2 && this.type * 1 === 3) {
        this.$refs.panForm.clearValidate();
      }
      if (this.active === 2 && this.type * 1 === 4) {
        this.$refs.panForm.clearValidate();
      }
      if (this.active === 3) {
        this.$refs.expectForm.clearValidate();
      }
      this.active--;
      (0, _scrollTo.scrollTo)(0, 800);
    },
    // 下一步
    next: function next() {
      var _this7 = this;
      if (this.showOnly) {
        this.active++;
        if (this.active === 2 && this.type * 1 === 3) {
          this.setPan(this.panForm.size);
        }
        if (this.active === 2 && this.type * 1 === 1) {
          this.changeCharType(this.characterForm.characterGathering * 1);
        }
        (0, _scrollTo.scrollTo)(0, 800);
      } else {
        if (this.active === 0) {
          var pageOneForm = this.checkForm('pageOneForm', '请完善基本设置');
          pageOneForm.then(function () {
            _this7.active++;
            (0, _scrollTo.scrollTo)(0, 800);
          });
        } else if (this.active === 1) {
          var pageTwoForm = this.checkForm('pageTwoForm', '请完善活动项设置');
          pageTwoForm.then(function () {
            _this7.active++;
            (0, _scrollTo.scrollTo)(0, 800);
          });
        } else if (this.active === 2) {
          switch (this.type * 1) {
            // 集字
            case 1:
              var characterForm = this.checkForm('characterForm', '请完善奖品设置');
              characterForm.then(function (res) {
                if (_this7.probabilityZ < 0) {
                  _this7.$message.closeAll();
                  _this7.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this7.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            // 红包
            case 2:
              var codeForm = this.checkForm('codeForm', '请完善奖品设置');
              codeForm.then(function (res) {
                if (_this7.probabilityC < 0) {
                  _this7.$message.closeAll();
                  _this7.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this7.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            // 转盘
            case 3:
              var panForm = this.checkForm('panForm', '请完善奖品设置');
              panForm.then(function (res) {
                if (_this7.probabilityP < 0) {
                  _this7.$message.closeAll();
                  _this7.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this7.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            case 4:
              var panForm1 = this.checkForm('panForm', '请完善奖品设置');
              panForm1.then(function (res) {
                if (_this7.probabilityP < 0) {
                  _this7.$message.closeAll();
                  _this7.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this7.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
          }
        }
        if (this.active === 1 && this.type * 1 === 3) {
          this.setPan(this.panForm.size);
          (0, _scrollTo.scrollTo)(0, 800);
        }
        if (this.active === 1 && this.type * 1 === 4) {
          (0, _scrollTo.scrollTo)(0, 800);
        }
        if (this.active === 1 && this.type * 1 === 1) {
          this.changeCharType(this.characterForm.characterGathering * 1);
          (0, _scrollTo.scrollTo)(0, 800);
        }
      }
    },
    // 组织机构文字
    // // 产品文字
    productListShow: function productListShow() {
      this.productVisibleShow = true;
      // setTimeout(() => {
      //   this.$refs.productVisible.proSelVisible = true
      //   this.$refs.productVisible.setsel()
      // }, 0)
    },
    // 产品选择
    setProduct: function setProduct(data) {
      this.productVisibleShow = false;
      this.product = data;
      this.pageOneForm.productList = data;
      var aId = [];
      var aName = [];
      var arr = [];
      data.forEach(function (item) {
        aId.push(item.pId);
        aName.push(item.productName);
        var obj = {
          productBrand: item.brandName,
          // 产品品牌
          productId: item.pId,
          // 产品id
          productName: item.productName,
          // 产品名称
          productNo: item.productCode,
          // 产品编号
          productNorms: item.productModel,
          // 产品规格
          productType: item.productClassId // 产品分类
        };

        arr.push(obj);
      });
      this.pageOneForm.product = aName;
      this.pageOneForm.productIdList = aId;
      this.pageOneForm.products = arr;
    },
    Policylistshow: function Policylistshow() {
      this.PolicyDialog = true;
    },
    Policylist: function Policylist(data) {
      this.PolicyDialog = false;
      this.pageOneForm.polictName = data.policyName;
      this.pageOneForm.polictNo = data.policyCode;
      this.pageOneForm.polictId = data.pid;
    },
    distributorListShow: function distributorListShow() {
      var _this8 = this;
      this.batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this8.$refs.distributorVisible.visible = true;
      }, 0);
    },
    setDistributor: function setDistributor(data) {
      var oDor = JSON.parse(data);
      this.pageOneForm.distributor = oDor.name;
    },
    batchListShow: function batchListShow() {
      var _this9 = this;
      this.batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this9.$refs.batchVisible.visible = true;
        _this9.$refs.batchVisible.setsel();
      }, 0);
    },
    setBatch: function setBatch(data) {
      this.batcheVisibleShow = false;
      this.pageOneForm.batcheNos = data;
      var aName = [];
      var arr = [];
      data.forEach(function (item) {
        var obj = {
          batchNo: item.batchNo,
          createtime: item.createTime,
          pid: item.pid
        };
        arr.push(obj);
        aName.push(item.batchNo);
      });
      this.pageOneForm.batch = aName.join(',');
      this.pageOneForm.batcheNos = arr;
    },
    v_batchListShow: function v_batchListShow() {
      var _this10 = this;
      this.v_batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this10.$refs.v_batchVisible.visible = true;
        _this10.$refs.v_batchVisible.setsel();
      }, 0);
    },
    v_setBatch: function v_setBatch(data) {
      console.log(data, 'data');
      this.v_batcheVisibleShow = false;
      this.pageOneForm.v_batchList = data;
      var aName = [];
      var this_num = '';
      var arr = [];
      data.forEach(function (item) {
        aName.push(item.orgName);
        this_num = item.pid;
        var obj = {
          organId: item.pid,
          organName: item.orgName,
          organType: item.orgType == 2 ? 2 : 1,
          createTime: item.createTime,
          organParentId: item.parentPid
        };
        arr.push(obj);
      });
      this.pageOneForm.v_batch = aName.join(',');
      this.pageOneForm.v_batcheNos = this_num;
      this.pageOneForm.actRConfigOrgan = arr;
      this.pageOneForm.h_batchList = [];
      this.pageOneForm.h_batch = [];
      this.pageOneForm.dealer = [];
    },
    h_batchListShow: function h_batchListShow() {
      var _this11 = this;
      this.h_batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this11.$refs.h_batchVisible.visible = true;
        _this11.$refs.h_batchVisible.setsel();
      }, 0);
    },
    h_setBatch: function h_setBatch(data) {
      this.h_batcheVisibleShow = false;
      this.pageOneForm.h_batchList = data;
      var aName = [];
      var arr = [];
      // var lengthnum = Number(data.length)
      data.forEach(function (item, index) {
        aName.push(item.channelName);
        var obj = {
          dealerCode: item.channelCode,
          dealerId: item.pId,
          dealerName: item.channelName
        };
        arr.push(obj);
      });
      this.pageOneForm.h_batch = aName;
      // this.pageOneForm.v_batcheNos = data;
      this.pageOneForm.dealer = arr;
    },
    clickRadio: function clickRadio(e) {
      e === this.characterForm.isRare ? this.characterForm.isRare = '' : this.characterForm.isRare = e;
    },
    // 删除文字--集字
    delWord: function delWord(index) {
      if (this.characterForm.isRare == index) {
        this.characterForm.isRare = '';
      }
      delete this.characterForm.wordList.splice(index, 1);
      this.characterForm.prizeList.forEach(function (val) {
        for (var i = val.words.length - 1; i >= 0; i--) {
          if (val.words[i] * 1 === index * 1) {
            val.words.splice(i, 1);
          } else if (val.words[i] * 1 > index * 1) {
            val.words[i]--;
          }
        }
      });
    },
    // 增加文字--集字
    addWord: function addWord() {
      this.characterForm.wordList.push({
        wordcontent: '',
        probability: '',
        wordnum: ''
      });
      this.changeCharType(this.characterForm.characterGathering * 1);
      // var le = this.characterForm.wordList.length
      // this.$set(this.characterForm.wordList, le, {
      //   wordcontent: '',
      //   probability: '',
      //   wordnum: ''
      // })
    },
    // 删除奖项--集字
    delPrize: function delPrize(index) {
      delete this.characterForm.prizeList.splice(index, 1);
    },
    // 增加奖项--集字
    addPrize: function addPrize() {
      this.characterForm.prizeList.push({
        type: '1',
        words: [],
        prizeType: '',
        amount: '',
        dataRange: [],
        budget: '',
        prizeExplain: '',
        prizeId: '',
        costprize: 0
      });
    },
    // 删除奖项--红包
    delCodePrize: function delCodePrize(index) {
      delete this.codeForm.prizeList.splice(index, 1);
    },
    // 增加奖项--红包
    addCodePrize: function addCodePrize() {
      this.codeForm.prizeList.splice(this.codeForm.prizeList.length - 1, 0, {
        type: '1',
        prize: '',
        scale: '',
        amount: '',
        costprize: 0
      });
    },
    // 删除期望
    delPart: function delPart(index) {
      this.expectForm.expectTable[index + 1].startTime = this.expectForm.expectTable[index].startTime;
      delete this.expectForm.expectTable.splice(index, 1);
    },
    // 增加期望
    addPart: function addPart() {
      var le = this.expectForm.expectTable.length - 1;
      this.expectForm.expectTable.splice(le, 0, {
        startTime: this.expectForm.expectTable[le - 1].endTime,
        endTime: '',
        actPar: '',
        actParnum: '',
        addMerberNum: '',
        prizePercent: ''
      });
      this.expectForm.expectTable[le + 1].startTime = '';
    },
    // 转盘切换
    changePan: function changePan(val) {
      var arr = [{
        prizeLevel: 1,
        type: '3',
        name: '谢谢惠顾',
        img: require('@/assets/images/xxhg.png'),
        scale: '',
        amount: '不限制',
        costprize: 0,
        prizeId: 1,
        prizeType: '5000'
      }];
      for (var i = 1; i < val; i++) {
        var obj = {
          prizeLevel: i + 1,
          type: '',
          img: '',
          prizeId: '',
          scale: '',
          amount: '',
          costprize: 0
        };
        arr.push(obj);
      }
      this.panForm.tableData = arr;
      this.setPan(val);
    },
    // 设置转盘
    setPan: function setPan(val) {
      (0, _timers.setTimeout)(function () {
        var aPart1 = [].slice.call(document.querySelectorAll('.panPart'));
        var aP = [].slice.call(document.querySelectorAll('.panText'));
        aPart1.forEach(function (item, i) {
          if (i !== 0) {
            item.style.transform = 'rotate(' + 360 / val * (i + 1) + 'deg)';
          } else {
            item.style.transform = 'rotate(' + 360 / val * (i + 1) + 'deg)skewY(' + (90 - 360 / val) + 'deg)';
          }
          item.style.zIndex = val - i;
        });
        aP.forEach(function (item, i) {
          item.style.transform = 'rotate(' + 180 / val * (2 * i + 1) + 'deg)';
        });
      }, 0);
    },
    // 扫码选择奖品
    cPChange: function cPChange(val, index) {
      var _this12 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this12.codeForm.prizeList[index].costprize = item.costprize ? item.costprize : 0;
          _this12.codeForm.prizeList[index].prizeType = item.type;
        }
      });
    },
    // 集字切换奖品类型
    jChangeType: function jChangeType(val, index) {
      if (val * 1 === 2) this.characterForm.prizeList[index].prizeType = '6000';
    },
    // 扫码切换奖品类型
    cChangeType: function cChangeType(val, index) {
      if (val * 1 === 2) this.codeForm.prizeList[index].prizeType = '6000';
    },
    // 转盘切换奖品类型
    pChangeType: function pChangeType(val, index) {
      if (val * 1 === 3) {
        this.panForm.tableData[index].name = '谢谢惠顾';
        this.panForm.tableData[index].img = require('@/assets/images/xxhg.png');
        this.panForm.tableData[index].prizeId = 1;
        this.panForm.tableData[index].prizeType = '5000';
        this.panForm.tableData[index].amount = '不限制';
      } else if (val * 1 === 2) {
        this.panForm.tableData[index].img = require('@/assets/images/jifen.png');
        this.panForm.tableData[index].prizeId = '';
        this.panForm.tableData[index].prizeType = '6000';
        this.panForm.tableData[index].amount = '';
      } else {
        this.panForm.tableData[index].img = require('@/assets/images/xxhg.png');
        this.panForm.tableData[index].prizeId = '';
        this.panForm.tableData[index].prizeType = '';
        this.panForm.tableData[index].amount = '';
      }
    },
    // 转盘选择奖品
    pPChange: function pPChange(val, index) {
      var _this13 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this13.panForm.tableData[index].costprize = item.costprize ? item.costprize * 1 : 0;
          _this13.panForm.tableData[index].prizeType = item.type;
          _this13.panForm.tableData[index].prizeId = val;
          _this13.panForm.tableData[index].img = item.imgUrl;
        }
      });
    },
    // 集字选择奖品
    jzChange: function jzChange(val, index) {
      var _this14 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this14.characterForm.prizeList[index].costprize = item.costprize ? item.costprize : 0;
          _this14.characterForm.prizeList[index].prizeType = item.type;
        }
      });
    },
    changeCharType: function changeCharType(val) {
      var _this15 = this;
      if (val === 1) {
        this.characterForm.prizeList = this.characterForm.prizeList.slice(0, 1);
        this.characterForm.wordList.forEach(function (item, i) {
          _this15.characterForm.prizeList[0].words.push(i);
        });
        this.characterForm.prizeList[0].words = (0, _toConsumableArray2.default)(new Set(this.characterForm.prizeList[0].words));
      }
    },
    checkForm: function checkForm(val, text) {
      var _this16 = this;
      return new Promise(function (resolve, reject) {
        _this16.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            reject(text);
            _this16.$message.closeAll();
            _this16.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    endChange: function endChange(e, index) {
      this.expectForm.expectTable[index * 1 + 1].startTime = e;
    },
    // 完成
    finish: function finish() {
      var _this17 = this;
      var eCheck;
      if (this.type * 1 === 1) {
        eCheck = this.checkForm('characterForm', '请完善活动预期设置');
      } else if (this.type * 1 === 2) {
        eCheck = this.checkForm('codeForm', '请完善活动预期设置');
      } else if (this.type * 1 === 3) {
        eCheck = this.checkForm('panForm', '请完善活动预期设置');
      } else if (this.type * 1 === 4) {
        eCheck = this.checkForm('panForm', '请完善活动预期设置');
      }
      // var eCheck = this.checkForm('expectForm', '请完善活动预期设置')
      eCheck.then(function (res) {
        if (_this17.type == 1 && _this17.probabilityZ < 0 || _this17.type == 2 && _this17.probabilityC < 0 || _this17.type == 3 && _this17.probabilityP < 0 || _this17.type == 4 && _this17.probabilityP < 0) {
          _this17.$message.closeAll();
          _this17.$message({
            message: '概率之和必须为100%',
            type: 'error',
            duration: 3 * 1000
          });
        } else {
          // this.sub()
          switch (_this17.type * 1) {
            // 集字
            case 1:
              _this17.pageOneForm.actWordTeype = _this17.characterForm.characterGathering;
              _this17.characterForm.prizeList.forEach(function (val) {
                var arr = [];
                val.words.forEach(function (item) {
                  var obj = {
                    wordContent: _this17.characterForm.wordList[item * 1].wordcontent,
                    wordorder: item + 1
                  };
                  arr.push(obj);
                });
                val.wordRuleIds = arr;
                val.prizeStarttime = val.dataRange[0];
                val.prizeEndtime = val.dataRange[1];
              });
              _this17.characterForm.wordList.forEach(function (val, index) {
                val.wordtype = 1;
                val.wordorder = index + 1;
                val.isScarcity = 0;
                if (index === _this17.characterForm.isRare) {
                  val.isScarcity = 1;
                  val.perPersonDayHitTimes = _this17.characterForm.toplimitDay;
                  val.perPersonHitTimes = _this17.characterForm.toplimitPerson;
                }
              });
              _this17.pageOneForm.prizesConfig = _this17.characterForm.prizeList;
              _this17.pageOneForm.words = _this17.characterForm.wordList;
              break;
            // 红包
            case 2:
              _this17.pageOneForm.prizesConfig = _this17.codeForm.prizeList;
              // this.pageOneForm.calculation = this.codeForm.calculation;
              _this17.pageOneForm.actJf = _this17.codeForm.actJf;
              _this17.pageOneForm.prizesConfig[_this17.pageOneForm.prizesConfig.length - 1].scale = _this17.probabilityC;
              break;
            // 转盘
            case 3:
              // var prbp = this.probabilityP
              // this.panForm.tableData[0].scale = prbp
              _this17.pageOneForm.prizesConfig = _this17.panForm.tableData;
              _this17.pageOneForm.actDialType = _this17.panForm.size;
              // this.pageOneForm.calculation = this.codeFoem.calculation;

              break;
            case 4:
              // var prbp = this.probabilityP
              // this.panForm.tableData[0].scale = prbp
              _this17.pageOneForm.prizesConfig = _this17.panForm.tableData;
              // this.pageOneForm.calculation = this.codeFoem.calculation;

              break;
          }
          if (_this17.pageOneForm.lq.length === 0) {
            _this17.pageOneForm.hasInfo = '000';
          } else {
            var num = _this17.pageOneForm.lq.join('');
            switch (num * 1) {
              case 1:
                _this17.pageOneForm.hasInfo = '100';
                break;
              case 2:
                _this17.pageOneForm.hasInfo = '010';
                break;
              case 3:
                _this17.pageOneForm.hasInfo = '001';
                break;
              case 12:
                _this17.pageOneForm.hasInfo = '110';
                break;
              case 21:
                _this17.pageOneForm.hasInfo = '110';
                break;
              case 13:
                _this17.pageOneForm.hasInfo = '101';
                break;
              case 31:
                _this17.pageOneForm.hasInfo = '101';
                break;
              case 23:
                _this17.pageOneForm.hasInfo = '011';
                break;
              case 32:
                _this17.pageOneForm.hasInfo = '011';
                break;
              case 123:
                _this17.pageOneForm.hasInfo = '111';
                break;
              case 132:
                _this17.pageOneForm.hasInfo = '111';
                break;
              case 213:
                _this17.pageOneForm.hasInfo = '111';
                break;
              case 231:
                _this17.pageOneForm.hasInfo = '111';
                break;
              case 312:
                _this17.pageOneForm.hasInfo = '111';
                break;
              case 321:
                _this17.pageOneForm.hasInfo = '111';
                break;
            }
          }
          _this17.pageOneForm.hasAnticipate = _this17.expectForm.hasAnticipate * 1;
          if (_this17.pageOneForm.hasAnticipate === 1) {
            _this17.expectForm.expectTable.forEach(function (item, index) {
              item.stage = index * 1 + 1;
              if (index === 0) {
                item.startTime = _this17.pageOneForm.dataTimeRange[0];
              }
              if (index * 1 === _this17.expectForm.expectTable.length - 1) {
                item.endTime = _this17.pageOneForm.dataTimeRange[1];
              }
            });
            _this17.pageOneForm.actAnticipate = _this17.expectForm.expectTable;
          }
          _this17.pageOneForm.startTime = _this17.pageOneForm.dataTimeRange[0];
          _this17.pageOneForm.endTime = _this17.pageOneForm.dataTimeRange[1];
          _this17.pageOneForm.type = _this17.type;
          if (_this17.copy) _this17.pageOneForm.id = null;
          _this17.subLoading = true;
          if (_this17.pageOneForm.id) {
            (0, _activityManage.update)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this17.pageOneForm), _this17.pageTwoForm)).then(function (res) {
              _this17.subLoading = false;
              res = res.data;
              if (res.status * 1 === 200) {
                _this17.$message.closeAll();
                _this17.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this17.goBack();
              } else {
                _this17.$message.closeAll();
                _this17.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            }).catch(function () {
              _this17.subLoading = false;
            });
          } else {
            (0, _activityManage.actconfigAdd)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this17.pageOneForm), _this17.pageTwoForm)).then(function (res) {
              _this17.subLoading = false;
              res = res.data;
              if (res.status * 1 === 200) {
                _this17.$message.closeAll();
                _this17.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this17.goBack();
              } else {
                _this17.$message.closeAll();
                _this17.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            }).catch(function () {
              _this17.subLoading = false;
            });
          }
        }
      });
    },
    sub: function sub() {
      var _this18 = this;
      if (this.pageOneForm.lq.length === 0) {
        this.pageOneForm.hasInfo = '000';
      } else {
        var num = this.pageOneForm.lq.join('');
        switch (num * 1) {
          case 1:
            this.pageOneForm.hasInfo = '100';
            break;
          case 2:
            this.pageOneForm.hasInfo = '010';
            break;
          case 3:
            this.pageOneForm.hasInfo = '001';
            break;
          case 12:
            this.pageOneForm.hasInfo = '110';
            break;
          case 21:
            this.pageOneForm.hasInfo = '110';
            break;
          case 13:
            this.pageOneForm.hasInfo = '101';
            break;
          case 31:
            this.pageOneForm.hasInfo = '101';
            break;
          case 23:
            this.pageOneForm.hasInfo = '011';
            break;
          case 32:
            this.pageOneForm.hasInfo = '011';
            break;
          case 123:
            this.pageOneForm.hasInfo = '111';
            break;
          case 132:
            this.pageOneForm.hasInfo = '111';
            break;
          case 213:
            this.pageOneForm.hasInfo = '111';
            break;
          case 231:
            this.pageOneForm.hasInfo = '111';
            break;
          case 312:
            this.pageOneForm.hasInfo = '111';
            break;
          case 321:
            this.pageOneForm.hasInfo = '111';
            break;
        }
      }
      this.pageOneForm.hasAnticipate = this.expectForm.hasAnticipate * 1;
      if (this.pageOneForm.hasAnticipate === 1) {
        this.expectForm.expectTable.forEach(function (item, index) {
          item.stage = index * 1 + 1;
          if (index === 0) {
            item.startTime = _this18.pageOneForm.dataTimeRange[0];
          }
          if (index * 1 === _this18.expectForm.expectTable.length - 1) {
            item.endTime = _this18.pageOneForm.dataTimeRange[1];
          }
        });
        this.pageOneForm.actAnticipate = this.expectForm.expectTable;
      }
      this.pageOneForm.startTime = this.pageOneForm.dataTimeRange[0];
      this.pageOneForm.endTime = this.pageOneForm.dataTimeRange[1];
      this.pageOneForm.type = this.type;
      if (this.pageOneForm.id) {
        (0, _activityManage.update)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageOneForm), this.pageTwoForm)).then(function (res) {
          res = res.data;
          if (res.status * 1 === 200) {
            _this18.$message.closeAll();
            _this18.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this18.goBack();
          } else {
            _this18.$message.closeAll();
            _this18.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      } else {
        (0, _activityManage.actconfigAdd)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageOneForm), this.pageTwoForm)).then(function (res) {
          res = res.data;
          if (res.status * 1 === 200) {
            _this18.$message.closeAll();
            _this18.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this18.goBack();
          } else {
            _this18.$message.closeAll();
            _this18.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    },
    // 预警开关
    switchFun: function switchFun(type, e) {
      if (type === 1) {
        this.$refs.pageTwoForm.clearValidate(['actWarningEmail', 'actWarningRate', 'actWarningThreshold']);
      } else if (type === 2) {
        this.$refs.pageTwoForm.clearValidate(['actWarningEmail', 'actEndtimeWarningDay', 'actEndtimeWarningRate']);
      }
    }
  }
};
exports.default = _default;