var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.device === "mobile"
          ? _c(
              "div",
              {
                key: "collapse",
                staticClass: "sidebar-logo-link",
                on: { click: _vm.toggleSideBar },
              },
              [
                _c(
                  "span",
                  { staticStyle: { color: "#FFFFFF", "font-weight": "bold" } },
                  [_vm._v("白云边数字化营销系统")]
                ),
              ]
            )
          : _c(
              "div",
              [
                _vm.collapse
                  ? _c(
                      "router-link",
                      {
                        key: "collapse",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#FFFFFF",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("白云边数字化营销系统")]
                        ),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "expand",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#FFFFFF",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("白云边数字化营销系统")]
                        ),
                      ]
                    ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }