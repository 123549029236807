"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/mark/basic.js");
var _index = require("@/api/mark/code_status/new_api/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _history = _interopRequireDefault(require("./components/history"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var object_options = [{
  id: 1,
  name: '单码'
}, {
  id: 2,
  name: '制码订单号'
}, {
  id: 3,
  name: '生产批次'
}, {
  id: 4,
  name: '交货单'
}];
var _default = {
  name: 'MarkcodeStatuslogListindex',
  components: {
    Pagination: _Pagination.default,
    history: _history.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (time == null || time == '') return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    getobjectv: function getobjectv(v) {
      var t = '';
      for (var i in object_options) {
        if (object_options[i].id == v) {
          t = object_options[i].name;
          break;
        }
      }
      return t;
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      rowData: null,
      total: 0,
      listLoading: true,
      btn_loading: false,
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        target: null,
        object: null
      },
      dialogFormVisible: false,
      DialogRestoreAccessVisible: false,
      DialogTitle: '',
      form_warehouse_receipt: {
        descr: '',
        target: ''
      },
      btnPowers: null,
      rules: {
        code: [{
          required: true,
          message: '请输入数码',
          trigger: 'blur'
        }],
        cause: [{
          required: true,
          message: '输入恢复原因',
          trigger: 'blur'
        }]
      },
      object_options: object_options
    };
  },
  activated: function activated() {
    this.btnPowers = {};
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _index.listLog)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        this.$refs.searchform.resetFields();
      }
      this.listQuery.pageNo = 1;
      this.getList();
    },
    handleUpdate: function handleUpdate(row, type) {
      this.authId('updatebtn', this.$route.meta.authList);
      if (this.$refs.ListForm != undefined) {
        this.$refs.ListForm.resetFields();
        this.$refs['ListForm'].clearValidate();
      }
      this.rowData = row;
      if (this.rowData.object == 1) {
        this.DialogTitle = '单码恢复';
      }
      if (this.rowData.object == 2) {
        this.DialogTitle = '制码订单号恢复';
      }
      if (this.rowData.object == 3) {
        this.DialogTitle = '生产批次恢复';
      }
      if (this.rowData.object == 4) {
        this.DialogTitle = '交货单恢复';
      }
      this.DialogRestoreAccessVisible = true;
    },
    onSubmitRestore: function onSubmitRestore(form, type) {
      // 操作对象类型：1-单码；2-制码订单号；3-生产批次；4-交货单
      form.target = this.rowData.target;
      this.DialogTitle = '对象恢复';
      if (this.rowData.object == 1) {
        this.DialogTitle = '单码恢复';
        this.unlockByMark();
        return true;
      }
      if (this.rowData.object == 2) {
        this.DialogTitle = '制码订单号恢复';
        this.unlockByPack();
        return true;
      }
      if (this.rowData.object == 3) {
        this.DialogTitle = '生产批次恢复';
        this.unlockByBatch();
        return true;
      }
      if (this.rowData.object == 4) {
        this.DialogTitle = '交货单恢复';
        this.unlockByBill();
        return true;
      }
    },
    Rest: function Rest() {
      this.$refs.ListForm.resetFields();
      this.authId('searchbtn', this.$route.meta.authList);
      this.DialogRestoreAccessVisible = false;
    },
    unlockByBatch: function unlockByBatch() {
      var _this2 = this;
      this.$refs['ListForm'].validate(function (valid) {
        if (valid) {
          var tips = '确认要恢复该生产批次号吗?';
          _this2.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this2.btn_loading = true;
            (0, _index.unlockByBatch)(_this2.form_warehouse_receipt).then(function (response) {
              _this2.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this2.$message.error(data.msg);
                return true;
              }
              _this2.$message({
                message: '恢复成功',
                type: 'success'
              });
              _this2.DialogRestoreAccessVisible = false;
              _this2.getList();
            }).catch(function () {
              _this2.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    unlockByBill: function unlockByBill() {
      var _this3 = this;
      // 根据交货单号解除冻结
      this.$refs['ListForm'].validate(function (valid) {
        if (valid) {
          var tips = '确认要恢复该交货单号吗?';
          _this3.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this3.btn_loading = true;
            (0, _index.unlockByBill)(_this3.form_warehouse_receipt).then(function (response) {
              _this3.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this3.$message.error(data.msg);
                return true;
              }
              _this3.$message({
                message: '恢复成功',
                type: 'success'
              });
              _this3.DialogRestoreAccessVisible = false;
              _this3.getList();
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    unlockByMark: function unlockByMark() {
      var _this4 = this;
      // 单码解除冻结
      this.$refs['ListForm'].validate(function (valid) {
        if (valid) {
          var tips = '确认要恢复该单码吗?';
          _this4.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this4.btn_loading = true;
            (0, _index.unlockByMark)(_this4.form_warehouse_receipt).then(function (response) {
              _this4.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this4.$message.error(data.msg);
                return true;
              }
              _this4.$message({
                message: data.msg,
                type: 'success'
              });
              _this4.DialogRestoreAccessVisible = false;
              _this4.getList();
            }).catch(function () {
              _this4.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    unlockByPack: function unlockByPack() {
      var _this5 = this;
      // 根据制码订单号解除冻结
      this.$refs['ListForm'].validate(function (valid) {
        if (valid) {
          var tips = '确认要恢复该制码订单号吗?';
          _this5.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this5.btn_loading = true;
            (0, _index.unlockByPack)(_this5.form_warehouse_receipt).then(function (response) {
              _this5.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this5.$message.error(data.msg);
                return true;
              }
              _this5.$message({
                message: '恢复成功',
                type: 'success'
              });
              _this5.DialogRestoreAccessVisible = false;
              _this5.getList();
            }).catch(function () {
              _this5.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;