"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _pack = require("@/api/basic/pack");
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatapackingscalepackingindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    levelText: function levelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '1',
        1: '2',
        2: '3'
      };
      return statusMap[status];
    },
    topClass: function topClass(val) {
      if (!String(val)) return '';
      var valMap = {
        1: '内层级',
        2: '中层级',
        3: '外层级'
      };
      return valMap[val];
    }
  },
  data: function data() {
    var _this = this;
    var checkpackNum = function checkpackNum(rule, value, callback) {
      console.log(rule.field, value);
      if (value > 0) {
        if (rule.field != 'levelItemParams.0.packNum' && _this.value == 3) {
          if (_this.ruleForm.levelItemParams[2].packNum % _this.ruleForm.levelItemParams[1].packNum == 0) {
            console.log('整');
          } else if (_this.ruleForm.levelItemParams[2].packNum % _this.ruleForm.levelItemParams[1].packNum != 0) {
            console.log('余');
            callback(new Error('内级层与中级层必须是整数倍关系!'));
          }
        }
        callback();
      } else {
        callback(new Error('数量不能为0'));
      }

      // if (this.packNumAll) {
      //   callback(new Error('数量不能全部为0'))
      // } else {
      //   callback()
      // }
    };

    return {
      dialogVisible: false,
      title: '添加包装比例',
      total: 0,
      searchForm: {
        current: 1,
        packCode: '',
        packScaleDesc: '',
        isEnable: '',
        size: 20
      },
      // ruleForm: {
      //   levelItemParams: [
      //     {
      //       packNum: '1',
      //       topClass: 3,
      //       unitId: '1248074830951972865',
      //       unitName: '箱'
      //     },
      //     {
      //       packNum: '0',
      //       topClass: 2,
      //       unitId: '1248074867652132865',
      //       unitName: '盒'

      //     },
      //     {
      //       packNum: '0',
      //       topClass: 1,
      //       unitId: '1248074897553326082',
      //       unitName: '罐'
      //     }
      //   ]
      // },
      ruleForm: {
        levelItemParams: [{
          topClass: '',
          packNum: '',
          unitName: '',
          levelList: []
        }]
      },
      rules: {
        topClass: {
          required: true,
          message: '包装层级不能为空',
          trigger: 'blur'
        },
        packNum: {
          required: true,
          message: '比例数量不能为空',
          trigger: 'blur'
        },
        unitName: {
          required: true,
          message: '计量单位不能为空',
          trigger: 'change'
        }
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      unitList: [],
      page2: false,
      packNum: 0,
      bzbl: [],
      levelList: [],
      value: '3',
      bzblname: '1箱0盒0罐',
      bzblxq: '每盒0罐'
    };
  },
  computed: {
    packNumAll: function packNumAll() {
      var num = 0;
      this.ruleForm.levelItemParams.forEach(function (item) {
        num += Number(item.packNum);
      });
      return num == 0;
    }
  },
  watch: {
    ruleForm: {
      // handler(val, oldVal) {
      //   console.log(this.value)
      //   if (this.value == 2) {
      //     this.bzblname = this.ruleForm.levelItemParams[0].packNum + this.ruleForm.levelItemParams[0].unitName + this.ruleForm.levelItemParams[1].packNum + this.ruleForm.levelItemParams[1].unitName
      //     this.bzblxq = ''
      //   } else {
      //     this.bzblname = this.ruleForm.levelItemParams[0].packNum + this.ruleForm.levelItemParams[0].unitName + this.ruleForm.levelItemParams[1].packNum + this.ruleForm.levelItemParams[1].unitName + this.ruleForm.levelItemParams[2].packNum + this.ruleForm.levelItemParams[2].unitName
      //     var one = Number(this.ruleForm.levelItemParams[1].packNum)
      //     var two = Number(this.ruleForm.levelItemParams[2].packNum)
      //     var chu = two / one
      //     if (chu == 'Infinity' || !chu) {
      //       chu = 0
      //     }
      //     console.log(two / one, '除')
      //     this.bzblxq = '每' + this.ruleForm.levelItemParams[1].unitName + chu + this.ruleForm.levelItemParams[2].unitName
      //   }
      // },
      // deep: true
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    // this.getUnitList()
    this.getList();
    this.getDirSel('BZBL');
    this.getDirlevel('BZCJ');
  },
  methods: {
    unit: function unit(val) {
      console.log(val);
      var pid = '';
      this.bzbl.forEach(function (item) {
        if (item.name == val) {
          pid = item.pid;
        }
      });
      this.ruleForm.levelItemParams.forEach(function (item) {
        if (item.unitName == val) {
          item.unitId = pid;
        }
      });
      console.log(this.ruleForm);
    },
    arrMap: function arrMap(arr1, arr2) {
      for (var x = 0; x < arr1.length; x++) {
        for (var y = 0; y < arr2.length; y++) {
          if (arr1[x].name == arr2[y].unitName) {
            arr2[y].pId = arr1[x].pid;
          }
        }
      }
      return arr2;
    },
    changeVal: function changeVal(val) {
      this.$refs['ruleForm'].clearValidate();
      if (val == 2) {
        this.ruleForm.levelItemParams = [{
          packNum: '1',
          topClass: 3,
          unitId: '1248074830951972865',
          unitName: '箱'
        }, {
          packNum: '0',
          topClass: 2,
          unitId: '1248074867652132865',
          unitName: '盒'
        }];
      } else {
        this.ruleForm.levelItemParams = [{
          packNum: '1',
          topClass: 3,
          unitId: '1248074830951972865',
          unitName: '箱'
        }, {
          packNum: '0',
          topClass: 2,
          unitId: '1248074867652132865',
          unitName: '盒'
        }, {
          packNum: '0',
          topClass: 1,
          unitId: '1248074897553326082',
          unitName: '罐'
        }];
      }
      this.getDirSel('BZBL');
      // this.ruleForm.levelItemParams = this.ruleForm.levelItemParams.sort(function(a, b) {
      //   return a.topClass - b.topClass
      // })
    },
    getDirSel: function getDirSel(code) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.bzbl = res.data.data;
            _this2.ruleForm.levelItemParams = _this2.arrMap(_this2.bzbl, _this2.ruleForm.levelItemParams);
            console.log(_this2.ruleForm.levelItemParams, '处理');
          } else {
            _this2.bzbl = [];
          }
        }
      });
    },
    getDirlevel: function getDirlevel(code) {
      var _this3 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.ruleForm.levelItemParams[0].levelList = res.data.data;
            // this.ruleForm.levelItemParams = this.arrMap(this.bzbl, this.ruleForm.levelItemParams)
            // console.log(this.ruleForm.levelItemParams, '处理')
          } else {
            _this3.levelList = [];
          }
        }
      });
    },
    changelevel: function changelevel(id, index) {
      this.ruleForm.levelItemParams[index + 1].levelList = this.ruleForm.levelItemParams[index].levelList.filter(function (item) {
        return item.figure < id;
      });
    },
    addlevel: function addlevel(id, index) {
      if (this.ruleForm.levelItemParams[index].topClass == '') {
        this.$message.error('请选择包装层级后再点击新增');
        return false;
      }
      this.ruleForm.levelItemParams.push({
        topClass: '',
        packNum: '',
        unitName: ''
      });
      this.changelevel(id, index);
    },
    minuslevel: function minuslevel(index) {
      this.ruleForm.levelItemParams.splice(index, 1);
    },
    checkUnit: function checkUnit(val, row) {
      var flag = this.ruleForm.levelItemParams.filter(function (number) {
        return row.unitId != '' && number.unitId == row.unitId && number.topClass != row.topClass;
      });
      this.unitList.forEach(function (item) {
        if (item.pId == val) {
          row.unitName = item.unitName;
        }
      });
      if (flag.length > 0) {
        row.unitId = '';
        row.unitName = '';
        this.$message.closeAll();
        this.$message.error('单位不能重复，请重新选择！');
      }
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _pack.packScaleLevelList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        packCode: '',
        packScaleDesc: '',
        isEnable: '',
        size: 20
      };
      this.getList();
    },
    add: function add() {
      var _this4 = this;
      this.title = '添加包装比例';
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs.ruleForm.clearValidate();
      });
      this.ruleForm.levelItemParams = [{
        topClass: '',
        packNum: '',
        unitName: '',
        levelList: []
      }];
      this.getDirlevel('BZCJ');
    },
    edit: function edit(row) {
      var _this5 = this;
      this.title = '编辑包装比例';
      this.dialogVisible = true;
      this.mloading = true;
      (0, _pack.getLevelById)({
        pId: row.pId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          _this5.packNum = 1;
          _this5.ruleForm.pId = res.data.data.packScaleLevel.pId;
          if (res.data.data.packScaleLevelItem) {
            res.data.data.packScaleLevelItem.forEach(function (item) {
              _this5.ruleForm.levelItemParams[item.topClass - 1].pId = item.pId;
              _this5.ruleForm.levelItemParams[item.topClass - 1].topClass = item.topClass;
              _this5.ruleForm.levelItemParams[item.topClass - 1].packNum = String(item.packNum);
              _this5.ruleForm.levelItemParams[item.topClass - 1].unitId = item.unitId;
              _this5.ruleForm.levelItemParams[item.topClass - 1].unitName = item.unitName;
            });
          }
        }
        _this5.mloading = false;
      }).catch(function () {
        _this5.mloading = false;
      });
    },
    del: function del(val, row) {
      var _this6 = this;
      var id = row.pId;
      if (val == 0) {
        row.isEnable = 1;
      } else {
        row.isEnable = 0;
      }
      this.$confirm(val == 0 ? '确认禁用' : '确认启用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.authId('startbtn', _this6.$route.meta.authList);
        (0, _pack.delLevelById)({
          isDelete: val,
          pId: id
        }).then(function (res) {
          _this6.authId('searchbtn', _this6.$route.meta.authList);
          if (res.data.code != 200) {
            _this6.$message.error(res.data.msg);
          } else {
            _this6.$message({
              message: '操作成功',
              type: 'success'
            });
            _this6.getList();
          }
        });
      }).catch(function () {
        _this6.authId('searchbtn', _this6.$route.meta.authList);
      });
    },
    handleClose: function handleClose() {
      this.packNum = 0;
      this.value = '3';
      this.ruleForm = {
        levelItemParams: [{
          packNum: '1',
          topClass: 3,
          unitId: '1248074830951972865',
          unitName: '箱'
        }, {
          packNum: '0',
          topClass: 2,
          unitId: '1248074867652132865',
          unitName: '盒'
        }, {
          packNum: '0',
          topClass: 1,
          unitId: '1248074897553326082',
          unitName: '罐'
        }]
      };
      this.getDirSel('BZBL');
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    submitForm: function submitForm() {
      var _this7 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          var submitData = (0, _utils.deepClone)((0, _utils.filterKeyNull)(_this7.ruleForm));
          submitData.levelItemParams.forEach(function (el) {
            delete el.levelList;
          });
          // submitData.levelItemParams = submitData.levelItemParams.sort(function(a, b) {
          //   return a.topClass - b.topClass
          // })
          console.log(submitData, '提交');
          if (_this7.ruleForm.pId) {
            (0, _pack.packScaleLevelUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
                // this.handleClose()
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          } else {
            _this7.authId('addbtn', _this7.$route.meta.authList);
            (0, _pack.packScaleLevelInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
                // this.handleClose()
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          }
        }
      });
    }
  }
};
exports.default = _default;