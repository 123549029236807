"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _BarChart = _interopRequireDefault(require("@/components/dataAnalyse/BarChart"));
var _fc = require("@/api/dataAnalyse/fc.js");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _dealer = require("@/api/basic/dealer");
var _city = require("@/api/market/city");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BarChart: _BarChart.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      listLoading: false,
      dialogVisible: false,
      searchBtn: false,
      sweepBtn: false,
      downLoading: false,
      count: 0,
      total: 0,
      dateValue: [],
      type: '1',
      formInline: {
        timeType: '',
        endTime: '',
        startTime: '',
        productCode: '',
        productName: ''
      },
      proList: [],
      parentList: [],
      areaData: {
        parentId: '',
        areaName: '',
        isDelete: 1,
        areaType: 2
      },
      rules: {}
    };
  },
  created: function created() {
    // this.getCity()
    this.getScurrystatCityFun('1');
  },
  methods: {
    // 获取产品
    getCity: function getCity() {
      var _this2 = this;
      (0, _dealer.dealerAreaList)((0, _objectSpread2.default)({}, this.areaData)).then(function (res) {
        if (res.data.code === 200) {
          _this2.proList = res.data.data;
        }
      });
    },
    getProvince: function getProvince() {
      var _this3 = this;
      (0, _city.getProvinceList)().then(function (res) {
        console.log(res);
        if (res.status === 200) {
          _this3.parentList = res.data;
        }
      });
    },
    dataFun: function dataFun(res, page) {
      if (!page) {
        var xData = [];
        var sData = [];
        res.data.forEach(function (item) {
          if (item.areaName === '全部地区') {
            item.areaName = item.provinceName + ' ' + item.areaName;
          }
          xData.push(item.areaName);
          sData.push(item.count);
        });
        this.$refs.barChart.xData = xData;
        this.$refs.barChart.sData = sData;
        this.$refs.barChart.initChart();
      }
      this.list = res.data || [];
      this.total = res.count || 0;
    },
    // 获取数据
    getScurrystatCityFun: function getScurrystatCityFun(type, page) {
      var _this4 = this;
      if (type) {
        this.formInline.timeType = type;
      }
      if (type !== '2' && type) {
        this.dateValue = [];
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      if (!page) {
        this.formInline.page = 1;
      }
      (0, _fc.antiChanCityRank)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this4.dataFun(res.data, page);
        }
      });
    },
    // 时间选择
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.getScurrystatCityFun('2');
      } else {
        this.getScurrystatCityFun('1');
      }
    },
    // 城市输入框选择
    inputFocusFun: function inputFocusFun() {
      if (this.proList.length === 0) {
        this.getCity();
        // this.getProvince()
      }

      this.dialogVisible = true;
    },
    // 城市选择
    setCurrent: function setCurrent(row) {
      this.formInline.provinceId = row.provinceId;
      this.formInline.cityId = row.cityId;
      this.formInline.areaName = row.areaName;
      this.dialogVisible = false;
      this.formInline.page = 1;
      this.getScurrystatCityFun('1', 'page');
    },
    // 城市搜索
    searchFun: function searchFun(type) {
      if (type === 0) {
        this.areaData = {
          parentId: '',
          areaName: '',
          isDelete: 1,
          areaType: 2
        };
      }
      this.getCity();
    },
    // 城市清除
    clearFun: function clearFun() {
      if (this.formInline.areaName) {
        this.formInline.areaName = '';
        this.formInline.cityId = '';
        this.formInline.provinceId = '';
        this.formInline.page = 1;
        this.getScurrystatCityFun('1', 'page');
      }
    },
    // 排名分页
    fcPagingFun: function fcPagingFun() {
      this.getScurrystatCityFun('', 'page');
    },
    downFun: function downFun() {
      // scurrystatCityExport({ ...this.formInline }).then(res => {
      //   var blob = new Blob([res], {
      //     type:
      //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      //   })
      //   var fileName
      //   if (this.formInline.timeType === '0') {
      //     fileName = '本月疑似窜货城市排行.xlsx'
      //   } else if (this.formInline.timeType === '1') {
      //     fileName = '本年疑似窜货城市排行.xlsx'
      //   } else if (this.formInline.timeType === '2') {
      //     fileName = this.formInline.startTime + '至' + this.formInline.endTime + '疑似窜货城市排行.xlsx'
      //   }
      //   const elink = document.createElement('a')
      //   if ('download' in elink) {
      //     // 非IE下载
      //     elink.download = fileName
      //     elink.style.display = 'none'
      //     elink.href = URL.createObjectURL(blob)
      //     document.body.appendChild(elink)
      //     elink.click()
      //     URL.revokeObjectURL(elink.href) // 释放URL 对象
      //     document.body.removeChild(elink)
      //   } else {
      //     // IE10+下载
      //     navigator.msSaveBlob(blob, fileName)
      //   }
      // })
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outoTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      var fileName;
      if (this.formInline.timeType === '1') {
        fileName = '本月疑似窜货城市排行.xlsx';
      } else if (this.formInline.timeType === '2') {
        fileName = '本年疑似窜货城市排行.xlsx';
      } else if (this.formInline.timeType === '3') {
        fileName = this.formInline.startTime + '至' + this.formInline.endTime + '疑似窜货城市排行.xlsx';
      }
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        fileName);
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;