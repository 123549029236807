var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("div", { staticClass: "page-section" }, [
            _c("h3", { staticClass: "h-title" }, [_vm._v("浙品码合成日志")]),
            _vm._v(" "),
            _c(
              "section",
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.searchForm,
                      "status-icon": "",
                      "label-suffix": ":",
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "storeHouseCode" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "编号",
                                      prop: "storeHouseCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.pinNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.searchForm, "pinNo", $$v)
                                        },
                                        expression: "searchForm.pinNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "storeHouseCode" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "用户ID",
                                      prop: "storeHouseCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.userId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "userId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.userId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "storeHouseCode" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "手机号",
                                      prop: "storeHouseCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.mobile,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "mobile",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.mobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "storeHouseCode" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "产品编码",
                                      prop: "storeHouseCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.productCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.productCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "storeHouseName" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "产品名称",
                                      prop: "storeHouseName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.productName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.productName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "storeHouseName" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "规格型号",
                                      prop: "storeHouseName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.productModel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productModel",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.productModel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "storeHouseName" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "溯源码",
                                      prop: "storeHouseName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.markCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "markCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.markCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "storeHouseName" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "归属大区",
                                      prop: "storeHouseName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.regionName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "regionName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.regionName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "orgId" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "orgName",
                                      label: "销售部门",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.orgId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "orgId",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.orgId",
                                        },
                                      },
                                      _vm._l(_vm.saleorgList, function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.orgName,
                                            value: item.pId,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "orgId" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "渠道分类", prop: "orgId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.classId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "classId",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.classId",
                                        },
                                      },
                                      _vm._l(_vm.channelList, function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.className,
                                            value: item.pId,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6, prop: "orgId" },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "用户类型", prop: "orgId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          loading: _vm.loading,
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchForm.userType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "userType",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.userType",
                                        },
                                      },
                                      _vm._l(_vm.userTypeList, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "createTime",
                                      label: "创建起止时间",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      on: {
                                        change: _vm.changeCreateTime,
                                        clear: _vm.getList,
                                      },
                                      model: {
                                        value: _vm.createTime,
                                        callback: function ($$v) {
                                          _vm.createTime = $$v
                                        },
                                        expression: "createTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    style: {
                                      width: _vm.formConfig.btnAreaHasShowMore,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchForm.current = 1
                                                _vm.getList()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                            },
                                            on: { click: _vm.resetForm },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                _vm.handleShow = !_vm.handleShow
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.handleShow
                                                    ? "收起"
                                                    : "展开"
                                                ) +
                                                "\n                  "
                                            ),
                                            _c("i", {
                                              class: _vm.handleShow
                                                ? "el-icon-arrow-up"
                                                : "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c("el-divider", { staticClass: "btnDivider" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c(
                      "div",
                      { staticClass: "btnArea" },
                      [
                        _c("el-col", { staticClass: "head-btn-group" }, [
                          _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                            ? _c(
                                "div",
                                { staticClass: "head-btn-group" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.downLoadBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.downLoadBtn.icon,
                                        plain: _vm.btnConfig.plain,
                                        loading: _vm.downLoading,
                                      },
                                      on: { click: _vm.download },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.downLoadBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.tabHeight,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        align: _vm.tableConfig.align,
                        width: "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pinNo",
                        label: "编号",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "loginAccount",
                        label: "用户名",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorId",
                        label: "用户ID",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobile",
                        label: "手机号",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "userType",
                        label: "用户类型",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("formatterUserType")(row.userType)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2711644966
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCode",
                        label: "产品编码",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productModel",
                        label: "规格型号",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "markCode",
                        label: "溯源码",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "regionName",
                        label: "数码归属大区",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "销售部门",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "className",
                        label: "渠道分类",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "areaCode",
                        label: "区域代码",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "provinceName",
                        label: "省份",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cityName",
                        label: "市",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "countyName",
                        label: "区/县",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        align: _vm.tableConfig.align,
                        width: "170",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("textDate")(row.createTime))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        953579966
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.searchForm.current,
                    limit: _vm.searchForm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "append-to-body": true,
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "库房编码", prop: "storeHouseCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      value: _vm.ruleForm.storeHouseCode,
                      maxlength: "20",
                      disabled: _vm.status == "启用",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.storeHouseCode = e)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "库房名称", prop: "storeHouseName" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.storeHouseName },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.storeHouseName = _vm.validTe(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属公司",
                    prop: "orgId",
                    rules: {
                      required: true,
                      message: "请选择",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.status == "启用",
                        filterable: "",
                        loading: _vm.loading,
                        placeholder: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeorg(_vm.ruleForm.orgId)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "orgId", $$v)
                        },
                        expression: "ruleForm.orgId",
                      },
                    },
                    _vm._l(_vm.orgSelList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: { label: item.orgName, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属部门", prop: "departId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", placeholder: "" },
                      on: { change: _vm.changetab },
                      model: {
                        value: _vm.ruleForm.departId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "departId", $$v)
                        },
                        expression: "ruleForm.departId",
                      },
                    },
                    _vm._l(_vm.depList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: { label: item.orgName, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属工厂", prop: "factoryOrgId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.status == "启用",
                        filterable: "",
                        loading: _vm.loading,
                        placeholder: "",
                      },
                      on: { focus: _vm.openFactory },
                      model: {
                        value: _vm.ruleForm.factoryOrgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "factoryOrgId", $$v)
                        },
                        expression: "ruleForm.factoryOrgId",
                      },
                    },
                    _vm._l(_vm.factoryList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: { label: item.factoryName, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      maxlength: "60",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "isEnable" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.status == "启用",
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.ruleForm.isEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "isEnable", $$v)
                      },
                      expression: "ruleForm.isEnable",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }