"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  filters: {
    filterPriority: function filterPriority(val) {
      var obj = {
        1: '一等奖',
        2: '二等奖',
        3: '三等奖',
        4: '四等奖',
        5: '五等奖',
        6: '六等奖',
        7: '七等奖',
        8: '八等奖',
        99999: '谢谢惠顾'
      };
      return obj[Number(val)];
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      dialogLoading: false,
      scanData: {},
      tableKey: 'scamPreview'
    };
  },
  mounted: function mounted() {
    if (this.id) {
      this.getDetail(this.id);
    }
  },
  methods: {
    // 获取详情
    getDetail: function getDetail(id) {
      var _this = this;
      (0, _activityManage.getPreview)({
        id: id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this.scanData = res.data.data;
        }
      });
    },
    // 关闭
    goBack: function goBack() {
      this.$emit('close');
    }
  }
};
exports.default = _default2;