"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMerchant = addMerchant;
exports.addOfficial = addOfficial;
exports.generateurl = generateurl;
exports.getMerchantList = getMerchantList;
exports.isStop = isStop;
exports.officialList = officialList;
exports.upLoadImg = upLoadImg;
exports.updateMerchant = updateMerchant;
exports.updateOfficial = updateOfficial;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
var mode = '';

// 公众号配置列表
function officialList() {
  return (0, _request.default)({
    url: base + '/market-zdConfig/wechatOfficialConfig/officialList',
    //  headers:{
    //    'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',
    //    "Access_Control-Allow-Origin":"*",
    //    "Access-Control-Allow-Credentials":true
    //  },
    method: 'get'
  });
}

// 公众号添加
function addOfficial(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/wechatOfficialConfig/officialSave',
    method: 'post',
    data: data
  });
}
// 公众号配置修改
function updateOfficial(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/wechatOfficialConfig/officialUpdate',
    method: 'post',
    data: data
  });
}

// 公众号授权
function generateurl(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/third/api/auth/goview',
    method: 'get',
    params: params
  });
}

// 获取商户号列表
function getMerchantList(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/merchantCodeInfo/merchantList',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    method: 'get',
    params: params
  });
}

// 添加商户号
function addMerchant(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/merchantCodeInfo/merchantSave',
    // headers:{
    //   'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    // },
    method: 'post',
    data: data
  });
}

// 商户号的启用停用
function isStop(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/merchantCodeInfo/merchantSwitch',
    // headers:{
    //   'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
    // },
    method: 'get',
    params: params
  });
}

// 商户号配置更新
function updateMerchant(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/merchantCodeInfo/merchantUpdate',
    method: 'post',
    data: data
  });
}
// 商户号配置接口
function upLoadImg(data) {
  return (0, _request.default)({
    url: mode + '/api-basic/jc/file/upload/uploadImg',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}