"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/mark/basic");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultSeachForm = {
  display_time: undefined,
  // 前台展示时间
  chosedate: []
};
var _default = {
  directives: {
    waves: _waves.default
  },
  props: ['numberType'],
  data: function data() {
    return {
      title: '',
      vurlCode: '',
      defaultSeachForm: Object.assign({}, defaultSeachForm),
      temp: {
        pageNo: 1,
        pageSize: 20,
        tempateName: '',
        numberType: ''
      },
      shortcuts: [],
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  computed: {
    displayTime: {
      // set and get is useful when the data
      // returned by the back end api is different from the front end
      // back end return => "2013-06-25 06:59:25"
      // front end need timestamp => 1372114765000
      get: function get() {
        return +new Date(this.defaultSeachForm.display_time);
      },
      set: function set(val) {
        this.defaultSeachForm.display_time = new Date(val);
      }
    },
    chosedate: {
      get: function get() {
        var e = this.shortcuts;
        if (e == null || e == '') {
          e = ['', ''];
        }
        return [new Date(e[0]), new Date(e[1])];
      },
      set: function set(val) {
        if (val == null || val == '') {
          val = ['', ''];
        }
        this.shortcuts = val;
      }
    }
  },
  created: function created() {
    var time = (0, _basic.normalDate)();
    this.shortcuts = [time[0], time[1]];
  },
  methods: {
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        var time = (0, _basic.normalDate)();
        this.shortcuts = [time[0], time[1]];
        this.$refs.searchform.resetFields();
      }
      this.temp.startTime = this.shortcuts[0];
      this.temp.endTime = this.shortcuts[1];
      this.temp.pageNo = 1;
      this.$parent.searchList(this.temp);
    }
  }
};
exports.default = _default;