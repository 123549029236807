"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _unlockPrizeRule = require("@/api/market/unlockPrizeRule");
var _activityManage = require("@/api/market/activityManage");
var _creatUnlockPrizeRules = _interopRequireDefault(require("./components/creatUnlockPrizeRules"));
var _actRelatRules = _interopRequireDefault(require("./components/actRelatRules"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageunlockPrizeRulesunlockPrizeRulesManageindex';
var _default = {
  name: 'MarketingManageunlockPrizeRulesunlockPrizeRulesManageindex',
  components: {
    Pagination: _Pagination.default,
    creatUnlockPrizeRules: _creatUnlockPrizeRules.default,
    actRelatRules: _actRelatRules.default
  },
  filters: {
    filterButtonStatus: function filterButtonStatus(status) {
      return status === '1' ? '停用' : '启用';
    },
    filterUnlockType: function filterUnlockType(status) {
      return status === '1' ? '按次布奖' : '倍数布奖';
    }
  },
  data: function data() {
    return {
      handleShow: false,
      editType: '',
      creatVisible: false,
      creatActVisible: false,
      ruleCode: '',
      ruleCodeAct: '',
      searchForm: {
        pageNo: 1,
        pageSize: 20
      },
      rangeTime: '',
      total: 0,
      tableData: [],
      listLoading: false
    };
  },
  activated: function activated() {
    this.queryForPage();
    this.getPrizeLists(100);
    this.getPrizeLists(200);
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  mounted: function mounted() {
    this.getPrizeLists(100);
    this.getPrizeLists(200);
  },
  methods: {
    getPrizeLists: function getPrizeLists(prizeType) {
      var _this = this;
      var data = {
        status: 1,
        prizeType: prizeType,
        pageNo: 0,
        pageSize: 0
      };
      (0, _activityManage.getPrizeLists)(data).then(function (res) {
        if (res.data.code == 200) {
          var keyValue = prizeType == 100 ? 'hongbaoOption' : 'shiwuOption';
          sessionStorage.setItem(keyValue, JSON.stringify(res.data.data));
        } else {
          _this.$message.error(res.data.msg);
        }
      });
    },
    // 获取表格
    queryForPage: function queryForPage() {
      var _this2 = this;
      this.listLoading = true;
      (0, _unlockPrizeRule.getUnlockPrzeRuleList)(this.searchForm).then(function (res) {
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this2.listLoading = false;
          _this2.tableData = res.data.data.records;
          _this2.total = res.data.data.total;
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    // 创建解锁奖品规则
    creatUnlockPrizeRules: function creatUnlockPrizeRules() {
      this.editType = 'add';
      this.creatVisible = true;
    },
    // 编辑解锁奖品规则
    editUnlockPrize: function editUnlockPrize(row) {
      this.editType = 'edit';
      this.ruleCode = row.id;
      this.creatVisible = true;
    },
    // 打开关联奖品
    relationActivity: function relationActivity(row) {
      this.ruleCodeAct = row.unlockRuleCode;
      this.creatActVisible = true;
    },
    // 关闭解锁奖品规则
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.queryForPage();
    },
    closeActRelatRules: function closeActRelatRules() {
      this.creatActVisible = false;
      this.queryForPage();
    },
    onOrOffOperation: function onOrOffOperation(data) {
      var _this3 = this;
      var isOn = data.status === '1' ? '停用' : '启用';
      var isOnFlag = data.status === '1' ? '2' : '1';
      this.$confirm("\u662F\u5426".concat(isOn, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.isOperateUnlockPrzeRule({
          unlockRuleId: data.id,
          flag: isOnFlag
        }, isOn);
      }).catch(function (err) {
        console.log(err);
      });
    },
    isOperateUnlockPrzeRule: function isOperateUnlockPrzeRule(data, isOn) {
      var _this4 = this;
      if (isOn === '启用') {
        this.onOrOffUnlockPrzeRule(data, isOn);
        return;
      }
      (0, _unlockPrizeRule.getActCountByRuleCode)({
        code: data.unlockRuleId
      }).then(function (res) {
        if (res.data.code == 200) {
          var actCount = Number(res.data.data);
          if (actCount !== 0) {
            var strMessage = "\u8BE5\u89C4\u5219\u5DF2\u7ECF\u5173\u8054".concat(actCount, "\u672A\u8FC7\u671F\u7684\u4E2A\u6D3B\u52A8\uFF0C\u4ECD\u8981\u505C\u7528\u8BF7\u5148\u53D6\u6D88\u5173\u8054");
            _this4.$confirm(strMessage, {
              confirmButtonText: '确定',
              showCancelButton: false
            });
            return;
          }
          _this4.onOrOffUnlockPrzeRule(data, isOn);
        } else {
          _this4.$message.error(res.data.msg);
          return;
        }
      });
    },
    onOrOffUnlockPrzeRule: function onOrOffUnlockPrzeRule(data, isOn) {
      var _this5 = this;
      (0, _unlockPrizeRule.onOrOffUnlockPrzeRule)(data).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this5.queryForPage();
            _this5.$message.success("".concat(isOn, "\u6210\u529F"));
          } else {
            return _this5.$message.error(res.data.data.errMsg);
          }
        } else {
          return _this5.$message.error(res.data.msg);
        }
      });
    },
    // 时间选择
    handleTimeChange: function handleTimeChange(res) {
      if (res) {
        this.searchForm.startTime = res[0];
        this.searchForm.endTime = res[1];
      } else {
        delete this.searchForm.startTime;
        delete this.searchForm.endTime;
      }
    },
    // 搜索
    search: function search() {
      this.searchForm.pageNo = 1;
      this.queryForPage();
    },
    // 重置
    reset: function reset() {
      this.$refs['searchForm'].resetFields();
      this.rangeTime = '';
      this.searchForm = {
        pageNo: 1,
        pageSize: 20
      };
      this.queryForPage();
    }
  }
};
exports.default = _default;