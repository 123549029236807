"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IntExcel = IntExcel;
exports.IntPage = IntPage;
exports.OutExcel = OutExcel;
exports.OutPage = OutPage;
exports.exportExcel = exportExcel;
exports.queryPage = queryPage;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = process.env.VUE_APP_VERSION2;

// 箱内码日志查询列表
function IntPage(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/codeScan/intPage',
    method: 'post',
    data: data
  });
}
// 箱内码导出
function IntExcel(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/codeScan/intExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 箱外码列表查询
function OutPage(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/codeScan/outPage',
    method: 'post',
    data: data
  });
}
// 箱外码导出
function OutExcel(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/codeScan/outExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 终端流水列表查询
function queryPage(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/cashAccount/queryPage',
    method: 'post',
    data: data
  });
}
// 终端流水导出
function exportExcel(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/cashAccount/excel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}