import { render, staticRenderFns } from "./detail-flow.vue?vue&type=template&id=36bf21df&scoped=true&"
import script from "./detail-flow.vue?vue&type=script&lang=js&"
export * from "./detail-flow.vue?vue&type=script&lang=js&"
import style0 from "./detail-flow.vue?vue&type=style&index=0&id=36bf21df&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bf21df",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36bf21df')) {
      api.createRecord('36bf21df', component.options)
    } else {
      api.reload('36bf21df', component.options)
    }
    module.hot.accept("./detail-flow.vue?vue&type=template&id=36bf21df&scoped=true&", function () {
      api.rerender('36bf21df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/chanelmange/rebate/mange/component/detail-flow.vue"
export default component.exports