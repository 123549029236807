var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "销售订单-提货",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("销售订单号:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.orderNo)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("采购单位类型:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("purchaseComOwnerType")(
                          _vm.addData.purchaseComOwnerType
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("采购单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.purchaseComName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("付款方式:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(_vm._f("payWayText")(_vm.addData.paymentStyle))
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("订单日期:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("申请人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.applyUser)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("收货单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveComName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("联系人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveContacts)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("联系人电话:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receivePhone)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("收货地址:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.receiveAddress)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("供货信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("发货单位:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.addData.sendOutComName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("发货库房:")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-top": "-10px" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.setStore },
                          model: {
                            value: _vm.addData.sendOutStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.addData, "sendOutStoreId", $$v)
                            },
                            expression: "addData.sendOutStoreId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [
            _vm._v("\n      产品信息\n      "),
            _c("img", { attrs: { src: require("@/images/pro.png") } }),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "addTable",
              staticClass: "detailTable",
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": "300",
                data: _vm.productList,
                fit: "",
                "row-class-name": _vm.tableRowClassName,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  align: _vm.tableConfig.align,
                  prop: "productCode",
                  width: "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: _vm.tableConfig.align,
                  prop: "productName",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  align: _vm.tableConfig.align,
                  prop: "packScaleExpression",
                  width: "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱数",
                  align: _vm.tableConfig.align,
                  width: "80",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.chestNum))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盒数",
                  align: _vm.tableConfig.align,
                  width: "80",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.bottleNum))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "已提数量(箱)",
                  align: _vm.tableConfig.align,
                  prop: "extractNum",
                  width: "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.actualChestNum))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "已提数量(盒)",
                  align: _vm.tableConfig.align,
                  prop: "extractNum",
                  width: "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.actualBottleNum))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "提货数量(箱)",
                  align: _vm.tableConfig.align,
                  prop: "packScaleDesc",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            disabled: row.actualChestNum == row.chestNum,
                            controls: false,
                            precision: 0,
                            min: 0,
                            max: _vm.maxNumX(row),
                          },
                          on: {
                            change: function (currentValue, oldValue) {
                              _vm.takeGoodNumChange(currentValue, oldValue, row)
                            },
                          },
                          model: {
                            value: row.takeGoodNum,
                            callback: function ($$v) {
                              _vm.$set(row, "takeGoodNum", $$v)
                            },
                            expression: "row.takeGoodNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "提货数量(盒)",
                  align: _vm.tableConfig.align,
                  prop: "packScaleDesc",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            disabled: row.actualBottleNum == row.bottleNum,
                            controls: false,
                            precision: 0,
                            min: _vm.mixNumP(row),
                            max: _vm.maxNumP(row),
                          },
                          model: {
                            value: row.takeGoodAtoNum,
                            callback: function ($$v) {
                              _vm.$set(row, "takeGoodAtoNum", $$v)
                            },
                            expression: "row.takeGoodAtoNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "库存(箱)",
                  align: _vm.tableConfig.align,
                  width: "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(row.isComProd == 2 ? "-" : row.storeInventory)
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.take },
            },
            [_vm._v("生成提货单")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }