"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _auth = require("@/utils/auth");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  name: 'TablePackset',
  components: {
    // Pagination
  },
  props: {
    title: {
      type: String,
      required: true
    },
    // 批量导入接口地址
    api: {
      type: String,
      required: true
    },
    // 嵌套Dialog则为true
    appendToBody: {
      type: Boolean,
      required: false
    },
    closeOnClickModal: {
      type: Boolean,
      required: true
    },
    limitCount: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      // imgUrl: require('../../../../images/origin.png'),
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      // token固定
      uploadDisabled: true,
      listLoading: false,
      result: '',
      file: '',
      resultExcel: '',
      mloading: false,
      btnLoading: false,
      tableData: [],
      uploadVisible: false,
      disabled: false,
      uploadData: [],
      classList: [],
      normalList: [],
      packList: [],
      uploadForm: {
        // isFuGai: '',
        uploadFile: ''
      },
      uploadRules: {},
      fileList: []
    };
  },
  computed: {
    // 批量导入接口地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + "".concat(this.api);
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    trimLR: function trimLR(val) {
      this.searchForm[val] = this.searchForm[val].replace(/\s+/g, '');
    },
    upload: function upload() {
      this.uploadVisible = true;
      this.result = '';
    },
    // 手动选择文件
    selectFile: function selectFile(file, fileList) {
      console.log(file.name, 'file选择');
      console.log(file.status, 'file选择');
      console.log(file.raw, 'file选择raw');
      this.uploadForm.uploadFile = file.name;
      if (file.name) {
        this.uploadDisabled = false;
      } else {
        this.uploadDisabled = true;
      }
      // if (fileList.length > 0) {
      //   this.uploadForm.uploadFile = [fileList[fileList.length - 1]]
      // }
    },
    uploadClose: function uploadClose() {
      this.uploadForm = {
        uploadFile: ''
      };
      this.$refs.upload.clearFiles();
      this.uploadVisible = false;
      this.uploadDisabled = true;
      this.result = '';
      this.resultExcel = '';
    },
    submitUpload: function submitUpload() {
      if (this.uploadForm.uploadFile) {
        this.btnLoading = true;
        this.$refs.upload.submit();
      }
    },
    setResult: function setResult(result, msg, type) {
      this.result = result;
      this.$message[type](msg);
      this.loading = false;
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      console.log(file, '成功回调file');
      console.log(res, 'res');
      if (res.code != 200) {
        this.$message.error(res.msg);
        this.btnLoading = false;
        this.$refs.upload.clearFiles();
        this.fileList = [];
        this.uploadForm.uploadFile = '';
      } else {
        // 根据res.msg判断
        if (res.msg == '导入成功！') {
          this.$message.success(res.msg);
          this.setResult(1, res.msg, 'success');
          // 关闭导入窗口
          this.uploadClose();
          this.$emit('fetchSuccessData');
        } else {
          this.setResult('', res.msg, 'error');
        }
        this.btnLoading = false;
        this.$refs.upload.clearFiles();
        console.log(file.status, '成功回调 文件状态');
        file.status = 'ready';
        file.name = '';
        this.uploadDisabled = false;
        // this.uploadForm.uploadFile = ''
      }
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.$refs.upload.clearFiles();
      this.mloading = false;
    }
  }
};
exports.default = _default;