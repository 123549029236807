"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _MapCharts = _interopRequireDefault(require("@/components/dataAnalyse/MapCharts"));
var _leftBarCharts = _interopRequireDefault(require("@/components/dataAnalyse/leftBarCharts"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _fc = require("@/api/dataAnalyse/fc.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MapChart: _MapCharts.default,
    LeftBarCharts: _leftBarCharts.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      sweepBtn: false,
      listLoading: false,
      dialogVisible: false,
      dateValue: [],
      formInline: {
        timeType: '0',
        productCode: '',
        productName: '',
        endTime: '',
        startTime: ''
      },
      downLoading: false
    };
  },
  created: function created() {
    this.getScurrystatProvinceFun('1');
  },
  methods: {
    mapDataFun: function mapDataFun(res) {
      var mapData = [];
      res.data.forEach(function (item) {
        if (item.provinceName === '臺灣省') {
          mapData.push({
            name: '台湾',
            value: item.count
          });
        } else if (item.provinceName === '香港特别行政区') {
          mapData.push({
            name: '香港',
            value: item.count
          });
        } else if (item.provinceName === '澳门特别行政区') {
          mapData.push({
            name: '澳门',
            value: item.count
          });
        } else {
          mapData.push({
            name: item.provinceName,
            value: item.count
          });
        }
      });
      mapData.sort(function (o1, o2) {
        return o1.value > o2.value;
      });
      this.$refs.mapChart.mapData = mapData;
      this.$refs.barChart.mapData = mapData;
      this.$refs.barCharts.mapData = mapData;
      this.$refs.mapChart.initChart();
      this.$refs.barChart.initChart();
      this.$refs.barCharts.initChart();
    },
    // 获取数据
    getScurrystatProvinceFun: function getScurrystatProvinceFun(type) {
      var _this2 = this;
      if (type) {
        this.formInline.timeType = type;
      }
      if (type !== '2' && type) {
        this.dateValue = [];
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      (0, _fc.antiChanProvinceRank)((0, _objectSpread2.default)({}, this.formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this2.mapDataFun(res);
        }
      });
    },
    // 时间选择
    dateFun: function dateFun(val, page) {
      if (val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.getScurrystatProvinceFun('2');
      } else {
        this.getScurrystatProvinceFun('1');
      }
    },
    // 产品选择
    inputFocusFun: function inputFocusFun() {
      // this.dialogVisible = true
      this.$refs.selProduct.proSelVisible = true;
    },
    // 产品选择
    setCurrent: function setCurrent(row) {
      this.formInline.productName = row.productName;
      this.formInline.productCode = row.productCode;
      this.dialogVisible = false;
      this.getScurrystatProvinceFun('1');
    },
    clearFun: function clearFun() {
      if (this.formInline.productCode) {
        this.formInline.productCode = '';
        this.formInline.productName = '';
      }
      this.getScurrystatProvinceFun();
    },
    downFun: function downFun() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outoTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);
      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '疑似窜货热力图.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;