var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  [
                    _c("div", { staticClass: "hyyy-top-tit" }, [
                      _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                        _c("span", {
                          staticStyle: { "font-weight": "400", color: "#333" },
                          domProps: {
                            textContent: _vm._s(_vm.$route.meta.title),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "hyyy-top-tit-right" },
                        [
                          _c("admincut", {
                            staticStyle: { "margin-top": "7px" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "hyyy-content" },
                      [
                        _c(
                          "el-main",
                          [
                            _c("el-divider"),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { attrs: { type: "flex", justify: "center" } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("ul", { staticClass: "debox" }, [
                                    _c("li", [_vm._v("积分变动通知")]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.product.industryOne) +
                                          " - " +
                                          _vm._s(_vm.product.industryTwo) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.product.updateTime) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        style: {
                                          color: _vm.color,
                                          padding: "7px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.input) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", [_c("span")]),
                                    _vm._v(" "),
                                    _c("li", [_c("span")]),
                                    _vm._v(" "),
                                    _c("li", [_c("span")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 10, offset: 1 } },
                                  [
                                    _c("ul", { staticClass: "debox-r" }, [
                                      _c("li", [
                                        _vm._v(
                                          "\n                      模板标题：\n                      "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.product.templateName)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _vm._v(
                                          "\n                      行业：\n                      "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.product.industryOne) +
                                              " - " +
                                              _vm._s(_vm.product.industryTwo)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _vm._v(
                                          "\n                      编辑内容：\n                      "
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 8,
                                                placeholder: "请输入内容",
                                                maxlength: "50",
                                                "show-word-limit": "",
                                              },
                                              model: {
                                                value: _vm.input,
                                                callback: function ($$v) {
                                                  _vm.input =
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                },
                                                expression: "input",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        [
                                          _vm._v(
                                            "\n                      字体颜色：\n\n                      "
                                          ),
                                          _c("el-color-picker", {
                                            model: {
                                              value: _vm.color,
                                              callback: function ($$v) {
                                                _vm.color = $$v
                                              },
                                              expression: "color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        [
                                          _vm._v(
                                            "\n                      跳转形式：\n                      "
                                          ),
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.linkform,
                                                callback: function ($$v) {
                                                  _vm.linkform = $$v
                                                },
                                                expression: "linkform",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "1" } },
                                                [
                                                  _vm._v(
                                                    "\n                          详情链接\n                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "2" } },
                                                [
                                                  _vm._v(
                                                    "\n                          小程序\n\n                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.linkform == "1",
                                                      expression:
                                                        "linkform == '1'",
                                                    },
                                                  ],
                                                  staticClass: "xcxlj",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("详情链接:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入详情链接",
                                                    },
                                                    model: {
                                                      value: _vm.input3,
                                                      callback: function ($$v) {
                                                        _vm.input3 =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "input3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.linkform == "2",
                                                      expression:
                                                        "linkform == '2'",
                                                    },
                                                  ],
                                                  staticClass: "xcxlj",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("小程序APPID:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入内容",
                                                    },
                                                    model: {
                                                      value: _vm.input1,
                                                      callback: function ($$v) {
                                                        _vm.input1 =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "input1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "可在微信公众平台-小程序后台>开发>开发设置找到对应的APPID和小程序路径。（前提：该公众号要绑定小程序才可以跳转）"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "padding-bottom": "20px" },
                                attrs: { type: "flex", justify: "center" },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.save },
                                      },
                                      [_vm._v("保存")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c(
                                      "el-button",
                                      { on: { click: _vm.back } },
                                      [_vm._v("取消")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }