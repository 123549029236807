"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sceneMarketing = require("@/api/terminal/sceneMarketing");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagesceneMarketingwriteOffindex';
var _default = {
  name: 'MarketingManagesceneMarketingwriteOffindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 0,
      timeData: [],
      datalist: [],
      tabledata: [],
      tabHeight: '100%',
      listLoading: false,
      dialogTableVisible: false,
      fileList: [],
      searchForm: {
        pageNow: 1,
        pageSize: 10,
        userName: ''
      },
      formInline: {
        name: '',
        imgUrl: '',
        prop: '',
        dataTime: ''
      },
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      this.searchForm.startTime = this.timeData[0];
      this.searchForm.endTime = this.timeData[1];
      (0, _sceneMarketing.ActWriteOfflist)(this.searchForm).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this.datalist = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    // 弹窗取消
    cancel: function cancel() {
      this.dialogTableVisible = false;
    },
    // 弹窗取消
    // 详情
    getDetail: function getDetail(id) {
      var _this2 = this;
      this.authId('scanbtn', this.apis);
      (0, _sceneMarketing.ActWriteOffdetailes)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this2.dialogTableVisible = true;
          _this2.formInline = res.data;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
      (0, _sceneMarketing.getproduct)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this2.tabledata = res.data;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 详情
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.timeData = [];
    }
  }
};
exports.default = _default;