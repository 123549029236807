"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportCgRkList = exportCgRkList;
exports.exportDbRkList = exportDbRkList;
exports.exportQtRkList = exportQtRkList;
exports.getCgDetail = getCgDetail;
exports.getCgRkList = getCgRkList;
exports.getCodeDetail = getCodeDetail;
exports.getDbDetail = getDbDetail;
exports.getDbRkList = getDbRkList;
exports.getHasPrizeOrders = getHasPrizeOrders;
exports.getQtDetail = getQtDetail;
exports.getQtRkList = getQtRkList;
exports.getQueryOrg = getQueryOrg;
exports.getType = getType;
exports.outstock = outstock;
exports.outstockdetail = outstockdetail;
exports.outstockfyj = outstockfyj;
exports.outstockfyjExport = outstockfyjExport;
exports.outstockfyjdetail = outstockfyjdetail;
exports.queryPrizeTradeForPage = queryPrizeTradeForPage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg(data) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取销售出库列表
function getCgRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockxs',
    method: 'post',
    params: param
  });
}

// 销售出库导出
function exportCgRkList(param) {
  var data = JSON.parse(JSON.stringify(param));
  delete data.pageNo;
  delete data.pageSize;
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockxsExport',
    method: 'post',
    responseType: 'blob',
    params: data
  });
}

// 获取业务类型
function getType(param) {
  return (0, _request.default)({
    url: '/api-channel/common/businesstype',
    method: 'get',
    params: param
  });
}

// 获取销售出库详情
function getCgDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/outstockdetail/outstockxsdetail',
    method: 'get',
    params: param
  });
}

// 获取数码查看详情
function getCodeDetail(data) {
  return (0, _request.default)({
    url: '/api-channel/qd/orderInfoCode/getCodeByOrderId',
    method: 'post',
    data: data
  });
}

// 获取调拨出库列表
function getDbRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockdb',
    method: 'get',
    params: param
  });
}

// 调拨出库导出
function exportDbRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockdbExport',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

// 获取调拨出库详情
function getDbDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/outstockdetail/outstockdbdetail',
    method: 'get',
    params: param
  });
}

// 获取其他出库列表
function getQtRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockqt',
    method: 'get',
    params: param
  });
}

// 其他出库导出
function exportQtRkList(param) {
  var data = JSON.parse(JSON.stringify(param));
  delete data.pageNo;
  delete data.pageSize;
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockqtExport',
    method: 'post',
    responseType: 'blob',
    params: data
  });
}

// 获取其他出库详情
function getQtDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/outstockdetail/outstockqtdetail',
    method: 'get',
    params: param
  });
}

// 获取费用酒出库列表
function outstockfyj(params) {
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockfyj',
    method: 'get',
    params: params
  });
}

// 费用酒出库导出
function outstockfyjExport(param) {
  var data = JSON.parse(JSON.stringify(param));
  delete data.pageNo;
  delete data.pageSize;
  return (0, _request.default)({
    url: '/api-channel/outstock/outstockfyjExport',
    method: 'post',
    responseType: 'blob',
    params: data
  });
}

// 获取费用酒出库详情
function outstockfyjdetail(param) {
  return (0, _request.default)({
    url: '/api-channel/outstockdetail/outstockfyjdetail',
    method: 'get',
    params: param
  });
}

// 获取单据布奖列表-渠道
function outstock(params) {
  // var obj = JSON.parse(JSON.stringify(data))
  // delete obj.pageNo
  // delete obj.pageSize
  return (0, _request.default)({
    url: '/api-channel/outstock/outstock',
    method: 'post',
    params: params
  });
}

// 获取单据布奖列表-仓储
function queryPrizeTradeForPage(data) {
  var obj = JSON.parse(JSON.stringify(data));
  delete obj.pageNo;
  delete obj.pageSize;
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryPrizeTradeForPage?current=' + data.pageNo + '&size=' + data.pageSize,
    method: 'post',
    data: obj
  });
}

// 获取销售出库详情
function outstockdetail(param) {
  return (0, _request.default)({
    url: '/api-channel/outstockdetail/outstockdetail',
    method: 'get',
    params: param
  });
}
// 参与布奖订单
function getHasPrizeOrders(current, size, data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}