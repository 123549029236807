var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称", prop: "productCode" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  "suffix-icon": "el-icon-search",
                                  placeholder: "请选择",
                                },
                                on: {
                                  focus: _vm.openProductWin,
                                  clear: _vm.clearProduct,
                                },
                                model: {
                                  value: _vm.productCode,
                                  callback: function ($$v) {
                                    _vm.productCode = $$v
                                  },
                                  expression: "productCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属企业", prop: "orgCode" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  "clear-value-text": "",
                                  placeholder: "请选择组织...",
                                  options: _vm.orgOptions,
                                },
                                on: {
                                  select: _vm.getOrgVal,
                                  input: _vm.emptyStores,
                                },
                                model: {
                                  value: _vm.orgCode,
                                  callback: function ($$v) {
                                    _vm.orgCode = $$v
                                  },
                                  expression: "orgCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "factoryCode", label: "库房名称" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { focus: _vm.getDownHouses },
                                  model: {
                                    value: _vm.ruleForm.factoryCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "factoryCode", $$v)
                                    },
                                    expression: "ruleForm.factoryCode",
                                  },
                                },
                                _vm._l(
                                  _vm.TypeDataComs.StoreList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.pId,
                                      attrs: {
                                        label: item.storeHouseName,
                                        value: item.pId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品批次号", prop: "batchNo" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "batchNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.ruleForm.current = 1), _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                false
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.downLoadBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.downLoadBtn.icon,
                          loading: _vm.downLoading,
                        },
                        on: { click: _vm.download },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "60",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "proBatchNo",
                      label: "批次号",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "storeHouseName",
                      label: "在库库房",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编号",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "age",
                      label: "茶龄",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.age
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "left",
                                      disabled: row.age.length <= 2,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      _vm._l(row.age, function (item, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item) +
                                              "\n                "
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      _vm._l(
                                        row.age.slice(0, 2),
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "text-overflow",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productPrice",
                      label: "价格",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.productPrice) +
                                  "元\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "packScaleExpression",
                      label: "包装比例",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "boxCount",
                      label: "库存数量",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "chestCount",
                      label: "库存最大单位数",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.ruleForm.current,
                  limit: _vm.ruleForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.ruleForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.ruleForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }