"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      dataForm: {
        dvyId: '',
        dvyFlowId: 0,
        dvyNames: [],
        orderNumber: 0
      },
      dataRule: {
        dvyFlowId: [{
          required: true,
          message: '快递单号不能为空',
          trigger: 'blur'
        }],
        dvyId: [{
          required: true,
          message: '快递公司不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    init: function init(orderNumber, dvyId, dvyFlowId) {
      var _this = this;
      this.visible = true;
      this.dataForm.orderNumber = orderNumber || '';
      this.dataForm.dvyId = dvyId || '';
      this.dataForm.dvyFlowId = dvyFlowId || '';
      (0, _integralMall.delivery)().then(function (res) {
        if (res.status === 200) {
          _this.dataForm.dvyNames = res.data;
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _integralMall.addDelivery)({
            orderNumber: _this2.dataForm.orderNumber,
            dvyId: _this2.dataForm.dvyId,
            dvyFlowId: _this2.dataForm.dvyFlowId
          }).then(function (data) {
            _this2.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this2.visible = false;
                _this2.$emit('refreshDataList');
              }
            });
          });
        }
      });
    },
    validTe: function validTe(value) {
      value = value.replace(/[^a-zA-Z0-9]/g, '');
      return value;
    }
  }
};
exports.default = _default;