var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "searchForm",
      staticClass: "search-condition",
      attrs: {
        model: _vm.searchForm,
        "status-icon": "",
        "label-width": "100px",
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "billNo", label: "入库单号:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      placeholder: "请输入",
                      maxlength: "20",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.searchForm.num = _vm.validSe(e))
                      },
                    },
                    model: {
                      value: _vm.searchForm.billNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "billNo", $$v)
                      },
                      expression: "searchForm.billNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "inOrgId", label: "入库企业:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: { change: _vm.setFactory },
                      model: {
                        value: _vm.searchForm.billReturnQuery.inOrgId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.searchForm.billReturnQuery,
                            "inOrgId",
                            $$v
                          )
                        },
                        expression: "searchForm.billReturnQuery.inOrgId",
                      },
                    },
                    _vm._l(_vm.TypeDataComs.OrgList, function (item) {
                      return _c("el-option", {
                        key: item.orgId,
                        attrs: { label: item.orgName, value: item.orgId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "inStoreId", label: "入库库房:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: { change: _vm.setFactory },
                      model: {
                        value: _vm.searchForm.billReturnQuery.inStoreId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.searchForm.billReturnQuery,
                            "inStoreId",
                            $$v
                          )
                        },
                        expression: "searchForm.billReturnQuery.inStoreId",
                      },
                    },
                    _vm._l(_vm.TypeDataComs.StoreList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: { label: item.storeHouseName, value: item.pId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "productName", label: "产品名称:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { clearable: "", placeholder: "请输入" },
                    on: { focus: _vm.openProductWin, clear: _vm.clearProduct },
                    model: {
                      value: _vm.productName,
                      callback: function ($$v) {
                        _vm.productName = $$v
                      },
                      expression: "productName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showCol
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status", label: "单据状态:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setFactory },
                          model: {
                            value: _vm.searchForm.billStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "billStatus", $$v)
                            },
                            expression: "searchForm.billStatus",
                          },
                        },
                        _vm._l(_vm.TypeDataComs.statusList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCol
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "source", label: "单据来源:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setFactory },
                          model: {
                            value: _vm.searchForm.billSource,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "billSource", $$v)
                            },
                            expression: "searchForm.billSource",
                          },
                        },
                        _vm._l(_vm.TypeDataComs.sourceList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCol
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "startTime", label: "创建起始时间:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.searchForm.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "startTime", $$v)
                          },
                          expression: "searchForm.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCol
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "endTime", label: "创建结束时间:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.searchForm.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "endTime", $$v)
                          },
                          expression: "searchForm.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCol
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "returnOrg",
                      attrs: { prop: "outOrgId", label: "退货单位:" },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.getreturnOrgList },
                                  model: {
                                    value: _vm.returnOrgType,
                                    callback: function ($$v) {
                                      _vm.returnOrgType = $$v
                                    },
                                    expression: "returnOrgType",
                                  },
                                },
                                _vm._l(
                                  _vm.TypeDataComs.returnOrgType,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value:
                                      _vm.searchForm.billReturnQuery.outOrgId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm.billReturnQuery,
                                        "outOrgId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchForm.billReturnQuery.outOrgId",
                                  },
                                },
                                _vm._l(
                                  _vm.TypeDataComs.returnOrgList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "arrowbox",
          on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btnbox",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.showCol = !_vm.showCol
                },
              },
            },
            [
              _c("i", {
                ref: "arrow",
                class: _vm.showCol
                  ? "el-icon-caret-top"
                  : "el-icon-caret-bottom",
                staticStyle: { color: "#595959 !important" },
              }),
              _vm._v(" "),
              _c(
                "span",
                { ref: "arrowtest", staticStyle: { color: "#595959" } },
                [_vm._v(_vm._s(_vm.showCol ? "收起" : "展开") + "\n      ")]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }