"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Workyw',
  components: {},
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  props: {
    url: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      formData: {},
      tableData: {},
      noteData: {},
      imgData: {},
      preViewList: [],
      cols: 1
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getYwDetails();
  },
  methods: {
    getYwDetails: function getYwDetails() {
      var that = this;
      var param = that.url;
      (0, _work.getYwDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          console.log(res.data.data, '业务数据');
          var resData = res.data.data;
          if (resData && resData.length) {
            for (var i = 0; i < resData.length; i++) {
              var item = resData[i];
              switch (resData[i].panelType) {
                case 'FORM':
                  that.formData = item;
                  break;
                case 'TABLE':
                  var arr = [];
                  if (item.tbHead.length > 0) {
                    item.tbHead.forEach(function (value) {
                      arr.push(value.length);
                    });
                  }
                  that.cols = arr.length > 0 ? Math.max.apply(Math, (0, _toConsumableArray2.default)(arr)) : 1;
                  that.tableData = item;
                  break;
                case 'NOTE':
                  that.noteData.panelTitle = item.panelTitle;
                  that.noteData.note = item.noteBody.note;
                  break;
                case 'GALLERY':
                  that.imgData = item;
                  if (item.galleryDetailPanel.length) {
                    item.galleryDetailPanel.forEach(function (value) {
                      that.preViewList.push(value.imgUrl);
                    });
                  }
                  break;
              }
            }
          } else {
            that.formData = {};
            that.tableData = {};
            that.noteData = {};
            that.imgData = {};
          }
        }
      });
    }
  }
};
exports.default = _default2;