"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    unitVisible: Boolean
  },
  data: function data() {
    return {
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      cityProps: {
        value: 'areaCode',
        label: 'areaName',
        children: 'children',
        checkStrictly: true
      },
      listLoading: false,
      tableKey: '',
      tabHeight: '100%',
      area: [],
      areaList: [],
      areaSel: [],
      searchForm: {
        keyword: '',
        channelType: null,
        archivesStatus: 1,
        current: 1,
        size: 10
      },
      tableData: [],
      total: 0,
      orgList: [],
      normalList: [],
      types: [{
        name: '经销商',
        id: 1
      }, {
        name: '终端',
        id: 2
      }, {
        name: '销售公司',
        id: 22
      }, {
        name: '生产公司',
        id: 33
      }, {
        name: '团购客户',
        id: 3
      }],
      orgInUseList: []
    };
  },
  watch: {
    unitVisible: function unitVisible(value) {
      var that = this;
      if (value == true) {
        this.getList();
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    // 获取所有组织
    getOrgListAll: function getOrgListAll() {
      var _this = this;
      var that = this;
      var orgId = JSON.parse(localStorage.getItem('currentUser')).orgId;
      (0, _org.fetchOrgList)({
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var arr = [];
          datas.forEach(function (item) {
            var obj = {};
            obj.id = item.pid;
            obj.label = item.orgName;
            obj.pid = item.pid;
            obj.parentPid = item.parentPid;
            arr.push(obj);
          });
          that.orgList = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.deepClone)(that.searchForm);
      (0, _channel.getChannelAndOrg)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 500);
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      this.searchForm.current = 1;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        keyword: '',
        archivesStatus: 1,
        channelType: null,
        current: 1,
        size: 10
      };
      this.getList();
    },
    detail: function detail(row) {
      var that = this;
      that.$emit('select', row);
      that.handleClose();
    },
    handleClose: function handleClose() {
      var that = this;
      that.searchForm = {
        keyword: '',
        archivesStatus: 1,
        channelType: null,
        current: 1,
        size: 10
      };
      that.$emit('dialogHide');
    }
  }
};
exports.default = _default;