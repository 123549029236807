"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stock = require("@/api/warehouse/stock/stock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _inventory_search = _interopRequireDefault(require("./components/inventory_search"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import productDialog from '@/components/productSelectDialog'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    // productDialog,
    search: _inventory_search.default
  },
  filters: {
    // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.code == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  data: function data() {
    return {
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      showCol: false,
      listLoading: true,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      pageTotalSum: '',
      totalSum: ''
    };
  },
  activated: function activated() {
    // const that = this
    // this.getList()
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    searchList: function searchList(data) {
      this.searchForm = data;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _stock.queryStoreProdForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records[0].storeProductVOS;
            that.total = parseInt(response.data.data.total);
            that.pageTotalSum = response.data.data.records[0].pageTotalSum;
            that.totalSum = response.data.data.records[0].totalSum;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 导出功能
    download: function download() {
      var _this2 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _stock.excelStorageOut)(this.searchForm).then(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '库存清单.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this2.downLoading = false;
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        _this2.downLoading = false;
      });
    }
  }
};
exports.default = _default;