var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "scroll-pane",
        {
          ref: "scrollPane",
          staticClass: "tags-view-wrapper",
          on: { scroll: _vm.handleScroll },
        },
        _vm._l(_vm.visitedViews, function (tag) {
          return _c(
            "router-link",
            {
              key: tag.path,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: {
                to: {
                  path: tag.path,
                  query: tag.query,
                  fullPath: tag.fullPath,
                },
                tag: "span",
              },
              nativeOn: {
                mouseup: function ($event) {
                  if ("button" in $event && $event.button !== 1) {
                    return null
                  }
                  !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                },
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                },
              },
            },
            [
              _c(
                "div",
                {
                  on: {
                    mouseenter: function ($event) {
                      return _vm.mouseover(tag)
                    },
                    mouseleave: function ($event) {
                      return _vm.mouseLeave(tag)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "pad-left" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { class: { tagactive: tag.title == _vm.currentTitle } },
                    [_vm._v(_vm._s(tag.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "icon-container",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.closeSelectedTag(tag)
                        },
                      },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: tag.path == _vm.currentPath,
                            expression: "tag.path == currentPath",
                          },
                        ],
                        staticClass: "el-icon-close",
                      }),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "contextmenu" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("i", {
                      staticClass: "el-icon-arrow-down",
                      staticStyle: { "font-size": "16px" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", [
                        _c("div", { on: { click: _vm.closeOthersTags } }, [
                          _vm._v("关闭其他"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.closeAllTags(_vm.selectedTag)
                              },
                            },
                          },
                          [_vm._v("关闭所有")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visible
        ? _c(
            "ul",
            {
              staticClass: "contextmenu",
              style: { left: _vm.left + "px", top: _vm.top + "px" },
            },
            [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.refreshSelectedTag(_vm.selectedTag)
                    },
                  },
                },
                [_vm._v("Refresh")]
              ),
              _vm._v(" "),
              !(_vm.selectedTag.meta && _vm.selectedTag.meta.affix)
                ? _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.closeSelectedTag(_vm.selectedTag)
                        },
                      },
                    },
                    [_vm._v("Close")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("li", { on: { click: _vm.closeOthersTags } }, [
                _vm._v("Close Others"),
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeAllTags(_vm.selectedTag)
                    },
                  },
                },
                [_vm._v("Close All")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }