"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = exports.all = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);
/* Layout */

var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      noCache: false,
      affix: true
    }
  }]
}, {
  path: '/chanelmange',
  component: _layout.default,
  name: '/chanelmange',
  meta: {
    title: '渠道管理',
    icon: 'nested'
  },
  resourceLevel: 1,
  hidden: true,
  children: [{
    path: '/chanelmange/rebate/component/detailsHdfl',
    name: 'detailsHdfl',
    meta: {
      title: '活动返利方案详情',
      showTagView: false,
      noCache: true,
      keepAlive: false,
      activeMenu: '/chanelmange/rebate/market-config/hdfl'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/detailsHdfl.vue'));
      });
    },
    hidden: true
  }, {
    path: '/chanelmange/rebate/component/insertHdfl',
    name: 'insertHdfl',
    meta: {
      title: '新增返利方案',
      showTagView: false,
      noCache: true,
      keepAlive: false,
      activeMenu: '/chanelmange/rebate/market-config/hdfl'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/insertHdfl.vue'));
      });
    },
    hidden: true
  }, {
    path: '/chanelmange/rebate/component/hdflSh',
    name: 'hdflSh',
    meta: {
      title: '活动返利审核',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/hdflSh.vue'));
      });
    },
    hidden: true
  }, {
    path: '/chanelmange/rebate/component/detailsMzfn',
    name: 'detailsMzfn',
    meta: {
      title: '买赠方案详情',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/detailsMzfn.vue'));
      });
    },
    hidden: true
  }, {
    path: '/chanelmange/rebate/component/insertMzfn',
    name: 'insertMzfn',
    meta: {
      title: '买赠方案',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/insertMzfn.vue'));
      });
    },
    hidden: true
  }, {
    path: '/chanelmange/rebate/component/mzfnSh',
    name: 'mzfnSh',
    meta: {
      title: '买赠方案审核',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/chanelmange/rebate/component/mzfnSh.vue'));
      });
    },
    hidden: true
  }]
}, {
  path: '/orderCenter',
  component: _layout.default,
  name: 'orderCenter',
  meta: {
    title: '订单中心',
    icon: 'example'
  },
  resourceLevel: 1,
  hidden: true,
  children: [{
    path: '/orderCenter/channelOrders/order/component/detail',
    name: 'purchaseOrderDetail',
    meta: {
      title: '渠道采购订单详情',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderCenter/channelOrders/order/component/detail.vue'));
      });
    },
    hidden: true
  }, {
    path: '/orderCenter/channelOrders/sales/component/detail',
    name: 'salesOrderDetail',
    meta: {
      title: '渠道采购订单详情',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderCenter/channelOrders/sales/component/detail.vue'));
      });
    },
    hidden: true
  }, {
    path: '/orderCenter/channelOrders/ydgl/component/detail',
    name: 'ydOrderDetail',
    meta: {
      title: '引单管理详情',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderCenter/channelOrders/ydgl/component/detail.vue'));
      });
    },
    hidden: true
  }]
}, {
  path: '/system',
  component: _layout.default,
  name: 'system',
  meta: {
    title: '系统配置',
    icon: 'example'
  },
  resourceLevel: 1,
  hidden: true,
  children: [{
    path: '/system/splcmange/spfiles/hdflsh',
    name: 'hdflsh',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/splcmange/spfiles/hdflSh.vue'));
      });
    },
    meta: {
      title: '活动返利审核',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/system/splcmange/spfiles/mzfnSh',
    name: 'mzfnSh',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/splcmange/spfiles/mzfnSh.vue'));
      });
    },
    meta: {
      title: '买赠方案审核',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/system/splcmange/spfiles/saleorderaudit',
    name: 'xsddsh',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/splcmange/spfiles/saleorderaudit.vue'));
      });
    },
    meta: {
      title: '销售订单审核',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/system/splcmange/spfiles/saleorderfinancial',
    name: 'xsddsh',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/splcmange/spfiles/saleorderfinancial.vue'));
      });
    },
    meta: {
      title: '销售订单取款',
      showTagView: false,
      noCache: true,
      keepAlive: false
    },
    hidden: true
  }]
},
/**
*
* 工作流审批详情路由配置
*
 **/
// {
// 	path: '/chanelmange',
// 	component: Layout,
// 	name: '/chanelmange',
// 	meta: {
// 		title: '渠道管理',
// 		icon: 'nested'
// 	},
// 	resourceLevel: 1,
// 	hidden: true,
// 	children: [
// 		{
// 			path: "/chanelmange/stockmange/kctzxq",
// 			name: "kctzxq",
// 			meta: {
// 				title: '库存调整审批单详情'
// 			},
// 			component: () => import('@/views/chanelmange/stockmange/kctzxq/index.vue')
// 		}
// 	]
// },

// {
// 	path: '/system',
// 	component: Layout,
// 	name: 'system',
// 	meta: {
// 		title: '系统配置',
// 		icon: 'example'
// 	},
// 	resourceLevel: 1,
// 	children: [
// 		{
// 			path: "/system/messageManage",
// 			name: 'system/messageManage',
// 			meta: {
// 				title: '消息和待办'
// 			},
// 			component: () => import('@/views/system/messageManage/index.vue'),
// 			children: [
// 				{
// 					path: "/system/messageManage/messageTemplate",
// 					name: '/system/messageManage/messageTemplate',
// 					meta: {
// 						title: '消息模板'
// 					},
// 					component: () => import('@/views/system/messageManage/messageTemplate/index.vue'),
// 				},
// 				{
// 					path: "/system/messageManage/messageCenter",
// 					name: 'systemmessageManagemessageCenterindex',
// 					meta: {
// 						title: '消息中心'
// 					},
// 					component: () => import('@/views/system/messageManage/messageCenter/index.vue'),
// 				},
// 				{
// 					path: "/system/messageManage/announcementManagement",
// 					name: 'system/messageManage/announcementManagement',
// 					meta: {
// 						title: '公告管理'
// 					},
// 					component: () => import('@/views/system/messageManage/announcementManagement/index.vue'),
// 				},
// 			]
// 		},
// 		{
// 			path: "/system/interfaceConfig/index",
// 			name: '/system/interfaceConfig/index',
// 			meta: {
// 				title: '接口管理'
// 			},
// 			component: () => import('@/views/system/interfaceConfig/index.vue'),
// 		},
// 	]

// },

// {
// 	path: '/marketingManage/cardManagement',
// 	component: Layout,
// 	name: "cardManagement",
// 	meta: {
// 		title: '酒卡管理',
// 		icon: 'nested'
// 	},
// 	resourceLevel: 1,
// 	children: [
// 		{
// 			path: '/marketingManage/cardManagement/CardManage',
// 			name: 'CardManage',
// 			meta: {
// 				title: '酒卡管理'
// 			},
// 			resourceLevel: 2,
// 			component: () => import('@/views/marketingManage/cardManagement/CardManage/index'),
// 		},
// 		{
// 			path: '/marketingManage/cardManagement/cardWriteoff',
// 			name: 'invitationList',
// 			meta: {
// 				title: '酒卡核销记录'
// 			},
// 			resourceLevel: 2,
// 			component: () => import('@/views/marketingManage/cardManagement/cardWriteoff/index'),
// 		},

// 	]
// },

/* {

  path: '/chanelmange',
  component: Layout,
  name: 'chanelmange',
  meta: {
    title: '渠道管理',
    icon: 'nested'
  },
  resourceLevel: 1,
  children: [{
    path: "/chanelmange/rebate",
    name: 'rebate',
    meta: {
      title: '返利核销'
    },
    component: () => import('@/views/chanelmange/rebate/index.vue'),
    resourceLevel: 2,
    children: [

      {
        path: "/chanelmange/rebate/reportStatic",
        name: 'reportStatic',
        meta: {
          title: '报表统计'
        },
        component: () => import('@/views/chanelmange/rebate/reportStatic/index.vue'),
        resourceLevel: 3,
        children: [{
            path: "/chanelmange/rebate/reportStatic/flsyRecord",
            name: 'flsyRecord',
            meta: {
              title: '酒券使用记录'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/flsyRecord.vue'),
            resourceLevel: 4,
          },
          {
            path: "/chanelmange/rebate/reportStatic/zdhdflRecord",
            name: 'zdhdflRecord',
            meta: {
              title: '活动返利流水'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/zdhdflRecord.vue'),
            resourceLevel: 4,
          },
          {
            path: "/chanelmange/rebate/reportStatic/zdndflRecord",
            name: 'zdndflRecord',
            meta: {
              title: '年度返利流水'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/zdndflRecord.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/reportStatic/zdfrMx",
            name: 'zdfrMx',
            meta: {
              title: '返利收支明细'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/zdfrMx.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/reportStatic/zdJy",
            name: 'zdJy',
            meta: {
              title: '活动返利结余表'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/zdJy.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/reportStatic/zdLj",
            name: 'zdLj',
            meta: {
              title: '返利累计统计表'
            },
            component: () => import('@/views//chanelmange/rebate/reportStatic/zdLj.vue'),
            resourceLevel: 4,
          }

        ]
      },

      {
        path: "/chanelmange/rebate/mange",
        name: 'mange',
        meta: {
          title: '返利管理'
        },
        component: () => import('@/views/chanelmange/rebate/mange/index.vue'),
        resourceLevel: 3,
        children: [{
            path: "/chanelmange/rebate/mange/hflAcount",
            name: 'hflRecord',
            meta: {
              title: '活动返利账户'
            },
            component: () => import('@/views/chanelmange/rebate/mange/hflAcount.vue'),
            resourceLevel: 4,
          },
          {
            path: "/chanelmange/rebate/mange/nflAcount",
            name: 'hflRecord',
            meta: {
              title: '年度返利账户'
            },
            component: () => import('@/views/chanelmange/rebate/mange/nflAcount.vue'),
            resourceLevel: 4,
          },
          {
            path: "/chanelmange/rebate/mange/jxsWarnHx",
            name: 'jxsWarnHx',
            meta: {
              title: '经销商酒券核算'
            },
            component: () => import('@/views/chanelmange/rebate/mange/jxsWarnHx.vue'),
            resourceLevel: 4,
          },
          {
            path: "/chanelmange/rebate/mange/jqhs",
            name: 'jqhs',
            meta: {
              title: '酒券核算'
            },
            component: () => import('@/views/chanelmange/rebate/mange/jqhs.vue'),
            resourceLevel: 4,
          }
        ]
      },

      {
        path: "/chanelmange/rebate/market-config/index",
        name: 'chanelmangerebateconfigindex',
        meta: {
          title: '返利配置'
        },
        component: () => import('@/views/chanelmange/rebate/market-config/index.vue'),
        resourceLevel: 3,
        children: [
          {
            path: "/chanelmange/rebate/market-config/params",
            name: 'chanelmangerebateconfigparams',
            meta: {
              title: '返利参数'
            },
            component: () => import('@/views/chanelmange/rebate/market-config/params.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/market-config/policy",
            name: 'chanelmangerebateconfigpolicy',
            meta: {
              title: '返利政策'
            },
            component: () => import('@/views/chanelmange/rebate/market-config/policy.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/market-config/hdfl",
            name: 'chanelmangerebateconfighdfl',
            meta: {
              title: '活动返利方案'
            },
            component: () => import('@/views/chanelmange/rebate/market-config/hdfl.vue'),
            resourceLevel: 4,
          },

          {
            path: "/chanelmange/rebate/market-config/ndfl",
            name: 'chanelmangerebateconfigndfl',
            meta: {
              title: '年度返利方案'
            },
            component: () => import('@/views/chanelmange/rebate/market-config/ndfl.vue'),
            resourceLevel: 4,
          }
        ]
      },

    ]
  }]
},*/

// {
//   path: '/basic-data',
//   component: Layout,
//   redirect: '/orgmange',
//   meta: {
//     title: '基础数据',
//     icon: 'chart',
//   },
//   children: [
//     {
//       path: '/basic-data/orgmange',
//       component: () => import('@/views/basic-data/orgmange/index'),
//       name: '组织机构',
//       meta: {
//         title: '组织机构',
//       },
//       children: [
//         {
//           path: '/basic-data/orgmange/org',
//           component: () => import('@/views/basic-data/orgmange/org/index'),
//           name: 'orgmange',
//           meta: {
//             title: '组织机构列表',
//           },
//         }
//       ]
//     },
//     {
//       path: '/basic-data/regionmange',
//       component: () => import('@/views/basic-data/regionmange/index'),
//       name: '行政区划',
//       meta: {
//         title: '行政区划',
//       },
//       children: [
//         {
//           path: '/basic-data/regionmange/region',
//           component: () => import('@/views/basic-data/regionmange/region/index'),
//           name: 'regionmange',
//           meta: {
//             title: '行政区划列表',
//           },
//         }
//       ]
//     },
//     {
//       path: '/basic-data/productmange',
//       component: () => import('@/views/basic-data/productmange/index'),
//       name: '产品管理',
//       meta: {
//         title: '产品管理',
//       },
//       children: [
//         {
//           path: '/basic-data/productmange/productcategory',
//           component: () => import('@/views/basic-data/productmange/productcategory/index'),
//           name: 'productcategory',
//           meta: {
//             title: '产品分类',
//           }
//         },
//         {
//           path: '/basic-data/productmange/productbrand',
//           component: () => import('@/views/basic-data/productmange/productbrand/index'),
//           name: 'productbrand',
//           meta: {
//             title: '产品品牌',
//           }
//         },
//         {
//           path: '/basic-data/productmange/productfile',
//           component: () => import('@/views/basic-data/productmange/productfile/index'),
//           name: 'productfile',
//           meta: {
//             title: '产品档案',
//           }
//         }
//       ]
//     },
//     {
//       path: 'channelmange',
//       component: () => import('@/views/basic-data/channelmange/index'),
//       name: '渠道管理',
//       meta: {
//         title: '渠道管理',
//       },
//       children: [
//         {
//           path: 'channelcategory',
//           component: () => import('@/views/basic-data/channelmange/channelcategory/index'),
//           name: 'channelcategory',
//           meta: {
//             title: '渠道分类',
//             noCache: false
//           }
//         }, {
//           path: 'channellevel',
//           component: () => import('@/views/basic-data/channelmange/channellevel/index'),
//           name: 'channellevel',
//           meta: {
//             title: '渠道等级',
//             noCache: false
//           }
//         }, {
//           path: '/basic-data/channelmange/dealerprofile',
//           component: () => import('@/views/basic-data/channelmange/dealerprofile/index'),
//           name: 'dealerprofile',
//           meta: {
//             title: '经销商档案',
//           }
//         }, {
//           path: '/basic-data/channelmange/terminalfile',
//           component: () => import('@/views/basic-data/channelmange/terminalfile/index'),
//           name: 'terminalfile',
//           meta: {
//             title: '终端档案',
//           }
//         }, {
//           path: '/basic-data/channelmange/purchasingsupply',
//           component: () => import('@/views/basic-data/channelmange/purchasingsupply/index'),
//           name: 'purchasingsupply',
//           meta: {
//             title: '采供关系',
//           }
//         }
//       ]
//     },
//     {
//       path: '/basic-data/packingscale',
//       component: () => import('@/views/basic-data/packingscale/index'),
//       name: '包装比例',
//       meta: {
//         title: '包装比例',
//       },
//       children: [{
//         path: '/basic-data/packingscale/packing',
//         component: () => import('@/views/basic-data/packingscale/packing/index'),
//         name: 'packing',
//         meta: {
//           title: '包装比例',
//         },
//       }]
//     },
//     {
//       path: '/basic-data/supportBoxLevel',
//       component: () => import('@/views/basic-data/supportBoxLevel/index'),
//       name: 'supportBoxLevel',
//       meta: {
//         title: '托箱比例',
//       },
//       children: [{
//         path: '/basic-data/supportBoxLevel/supportbox',
//         component: () => import('@/views/basic-data/supportBoxLevel/supportbox/index'),
//         name: '托箱比例',
//         meta: {
//           title: '托箱比例',
//         },
//       }]
//     },
//     {
//       path: '/basic-data/unitmange',
//       component: () => import('@/views/basic-data/unitmange/index'),
//       name: '计量单位',
//       meta: {
//         title: '计量单位',
//       },
//       children: [{
//         path: '/basic-data/unitmange/unit',
//         component: () => import('@/views/basic-data/unitmange/unit/index'),
//         name: 'unit',
//         meta: {
//           title: '计量单位',
//         },
//       }]
//     },
//     {
//       path: '/basic-data/storehousemange',
//       component: () => import('@/views/basic-data/storehousemange/index'),
//       name: '库房档案',
//       meta: {
//         title: '库房档案',
//       },
//       children: [{
//         path: '/basic-data/storehousemange/storehousefile',
//         component: () => import('@/views/basic-data/storehousemange/storehousefile/index'),
//         name: 'storehousefile',
//         meta: {
//           title: '库房档案',
//         },
//       }]
//     },
//     {
//       path: '/basic-data/factorymange',
//       component: () => import('@/views/basic-data/factorymange/index'),
//       name: '工厂档案',
//       meta: {
//         title: '工厂档案',
//       },
//       children: [{
//         path: '/basic-data/factorymange/factoryfile',
//         component: () => import('@/views/basic-data/factorymange/factoryfile/index'),
//         name: 'factoryfile',
//         meta: {
//           title: '工厂档案',
//         },
//       }]
//     },
//     {
//       path: '/basic-data/suppliermange',
//       component: () => import('@/views/basic-data/suppliermange/index'),
//       name: '包材供应商档案',
//       meta: {
//         title: '包材供应商档案',
//       },
//       children: [{
//         path: '/basic-data/suppliermange/supplierfile',
//         component: () => import('@/views/basic-data/suppliermange/supplierfile/index'),
//         name: 'supplierfile',
//         meta: {
//           title: '包材供应商档案',
//         },
//       }]
//     }
//   ]
// },
// {
//   path: '/mark',
//   component: Layout,
//   meta: {
//     title: '制码管理',
//     icon: 'nested',
//   },
//   children: [
//     {
//       path: '/mark/configure',
//       component: () => import('@/views/mark/configure/index'),
//       name: '制码配置管理',
//       meta: {
//         title: '制码配置管理',
//       },
//       children: [
//         {
//           path: '/mark/configure/url_manage',
//           component: () => import('@/views/mark/configure/url_manage/index'),
//           name: 'URL配置',
//           meta: {
//             title: 'URL配置',
//           }
//         },
//         {
//           path: '/mark/configure/typeConfig',
//           component: () => import('@/views/mark/configure/typeConfig/index'),
//           name: '制码类型设置',
//           meta: {
//             title: '制码类型设置',
//           }
//         },
//         {
//           path: '/mark/configure/label_group_manage',
//           component: () => import('@/views/mark/configure/label_group_manage/index'),
//           name: '标识组配置',
//           meta: {
//             title: '标识组配置',
//           }
//         },
//         {
//           path: '/mark/configure/supplier_identification',
//           component: () => import('@/views/mark/configure/supplier_identification/index'),
//           name: '供应商标识组配置',
//           meta: {
//             title: '供应商标识组配置',
//           }
//         }
//       ]
//     },
//     {
//       path: '/mark/client',
//       component: () => import('@/views/mark/client/index'),
//       name: '客户端管理',
//       meta: {
//         title: '客户端管理',
//       },
//       children: [
//         {
//           path: '/mark/client/client_licensing',
//           component: () => import('@/views/mark/client/client_licensing/index'),
//           name: '客户端授权配置',
//           meta: {
//             title: '客户端授权配置',

//           },
//         },
//         {
//           path: '/mark/client/client_mac_licensing_approval',
//           component: () => import('@/views/mark/client/client_mac_licensing_approval/index'),
//           name: '客户端授权审核',
//           meta: {
//             title: '客户端授权审核',

//           }
//         },
//         {
//           path: '/mark/client/create_code_mange',
//           component: () => import('@/views/mark/client/create_code_mange/index'),
//           name: '码包下载生成',
//           meta: {
//             title: '码包下载生成',

//           }
//         }
//       ]
//     },
//     {
//       path: '/mark/coding_service',
//       component: () => import('@/views/mark/coding_service/index'),
//       name: '制码服务管理',
//       meta: {
//         title: '制码服务管理',

//       },
//       children: [
//         {
//           path: '/mark/coding_service/coding_apply_mange',
//           component: () => import('@/views/mark/coding_service/coding_apply_mange'),
//           name: '标签厂制码申请',
//           meta: {
//             title: '标签厂制码申请',

//           },
//         },
//         {
//           path: '/mark/coding_service/cover_apply_mange',
//           component: () => import('@/views/mark/coding_service/cover_apply_mange'),
//           name: '制盖厂制码申请',
//           meta: {
//             title: '制盖厂制码申请',

//           },
//         },
//         {
//           path: '/mark/coding_service/platform_apply_mange',
//           component: () => import('@/views/mark/coding_service/platform_apply_mange'),
//           name: '平台制码申请',
//           meta: {
//             title: '平台制码申请',

//           },
//         },
//         {
//           path: '/mark/coding_service/create_code_mange',
//           component: () => import('@/views/mark/coding_service/create_code_mange'),
//           name: '制码生成列表',
//           meta: {
//             title: '制码生成列表',

//           },
//         },
//         {
//           path: '/mark/coding_service/code_pack_down',
//           component: () => import('@/views/mark/coding_service/code_pack_down'),
//           name: '制码下载列表',
//           meta: {
//             title: '制码下载列表',

//           },
//         },
//         {
//           path: '/mark/coding_service/racket_return',
//           component: () => import('@/views/mark/coding_service/racket_return'),
//           name: '制码回传列表',
//           meta: {
//             title: '制码回传列表',

//           },
//         },
//       ]
//     },
//     {
//       path: '/mark/code_status',
//       component: () => import('@/views/mark/code_status/index'),
//       name: '码状态管理',
//       meta: {
//         title: '码状态管理',

//       },
//       children: [
//         {
//           path: '/mark/code_status/code_freeze_restore',
//           component: () => import('@/views/mark/code_status/code_freeze_restore'),
//           name: '码冻结管理',
//           meta: {
//             title: '码冻结管理',

//           },
//         },
//         {
//           path: '/mark/code_status/code_abolish',
//           component: () => import('@/views/mark/code_status/code_abolish'),
//           name: '码废除管理',
//           meta: {
//             title: '码废除管理',

//           },
//         },
//         {
//           path: '/mark/code_status/log_list',
//           component: () => import('@/views/mark/code_status/log_list/index'),
//           name: '码恢复管理',
//           meta: {
//             title: '码恢复管理',

//           },
//         },
//       ]
//     },
//   ]
// },
// {
//   path: '/storeService',
//   component: Layout,
//   meta: {
//     title: '生产服务',
//     icon: 'excel',
//   },
//   children: [
//     {
//       path: '/storeService/storeManage',
//       component: () => import('@/views/storeService/storeManage/index'),
//       name: '生产管理',
//       meta: {
//         title: '生产管理',
//       },
//       children: [
//         {
//           path: '/storeService/storeManage/productionPlan',
//           component: () => import('@/views/storeService/storeManage/productionPlan/index'),
//           name: '生产计划单',
//           meta: {
//             title: '生产计划单',
//           }
//         },
//         {
//           path: '/storeService/storeManage/order',
//           component: () => import('@/views/storeService/storeManage/order/index'),
//           name: '生产任务单',
//           meta: {
//             title: '生产任务单',
//           }
//         },
//         {
//           path: '/storeService/storeManage/relationship',
//           component: () => import('@/views/storeService/storeManage/relationship/index'),
//           name: '生产关联关系查询',
//           meta: {
//             title: '生产关联关系查询',
//           }
//         }
//       ]
//     },
//   ]
// },
// {
//   path: '/fakeExpel',
//   component: Layout,
//   meta: {
//     title: '防伪防窜服务',
//     icon: 'component',
//   },
//   children: [
//     {
//       path: '/fakeExpel/warningManage',
//       component: () => import('@/views/fakeExpel/warningManage'),
//       name: '预警管理',
//       meta: {
//         title: '预警管理',
//       },
//       children: [
//         {
//           path: '/fakeExpel/warningManage/config',
//           component: () => import('@/views/fakeExpel/warningManage/config'),
//           name: '防伪防窜预警设置',
//           meta: {
//             title: '防伪防窜预警设置',
//           }
//         },
//         {
//           path: '/fakeExpel/warningManage/fake',
//           component: () => import('@/views/fakeExpel/warningManage/fake'),
//           name: '防伪预警',
//           meta: {
//             title: '防伪预警',
//           }
//         },
//         {
//           path: '/fakeExpel/warningManage/expel',
//           component: () => import('@/views/fakeExpel/warningManage/expel'),
//           name: '防窜预警',
//           meta: {
//             title: '防窜预警',
//           }
//         }
//       ]
//     },
//     {
//       path: '/fakeExpel/mapManage',
//       component: () => import('@/views/fakeExpel/mapManage'),
//       name: '地图管理',
//       meta: {
//         title: '地图管理',
//       },
//       children: [
//         {
//           path: '/fakeExpel/mapManage/fake',
//           component: () => import('@/views/fakeExpel/mapManage/fake'),
//           name: '防伪地图',
//           meta: {
//             title: '防伪地图',
//           }
//         },
//         {
//           path: '/fakeExpel/mapManage/expel',
//           component: () => import('@/views/fakeExpel/mapManage/expel'),
//           name: '防窜地图',
//           meta: {
//             title: '防窜地图',
//           }
//         }
//       ]
//     },
//     {
//       path: '/fakeExpel/queryManage',
//       component: () => import('@/views/fakeExpel/queryManage/index'),
//       name: '查询管理',
//       meta: {
//         title: '查询管理',
//       },
//       children: [
//         {
//           path: '/fakeExpel/queryManage/audit',
//           component: () => import('@/views/fakeExpel/queryManage/audit'),
//           name: '稽查查询',
//           meta: {
//             title: '稽查查询',
//           }
//         },
//         {
//           path: '/fakeExpel/queryManage/code',
//           component: () => import('@/views/fakeExpel/queryManage/code'),
//           name: '数码查询',
//           meta: {
//             title: '数码查询',
//           }
//         },
//         {
//           path: '/fakeExpel/queryManage/tracing',
//           component: () => import('@/views/fakeExpel/queryManage/tracing'),
//           name: '溯源查询',
//           meta: {
//             title: '溯源查询',
//           }
//         }
//       ]
//     },
//   ]
// },
// {
//   path: '/marketingManage',
//   component: Layout,
//   meta: {
//     title: '智慧营销',
//     icon: 'component',
//   },
//   children: [
//     {
//       path: '/marketingManage/basicConfig',
//       component: () => import('@/views/marketingManage/basicConfig/index'),
//       name: '基础配置管理',
//       meta: {
//         title: '基础配置管理',
//       },
//       children: [
//         {
//           path: '/marketingManage/basicConfig/publicConfig',
//           component: () => import('@/views/marketingManage/basicConfig/publicConfig/index'),
//           name: '公众号基础配置',
//           meta: {
//             title: '公众号基础配置',
//           }
//         }
//       ]
//     },
//     {
//       path: '/marketingManage/awardManage',
//       name: 'AwardManage',
//       component: () => import('@/views/marketingManage/awardManage/index'),
//       meta: {
//         title: '奖项管理',
//       },
//       children: [
//         {
//           path: '/marketingManage/awardManage/prizeManage',
//           name: 'PrizeManage',
//           component: () =>
//             import('@/views/marketingManage/awardManage/prizeManage/index'),
//           meta: {
//             title: '奖品管理'
//           }
//         },
//         {
//           path: '/marketingManage/awardManage/winningRecord',
//           name: 'winningRecord',
//           component: () =>
//             import('@/views/marketingManage/awardManage/winningRecord/index'),
//           meta: {
//             title: '中奖记录',
//             moduleId: '120101',
//             proId: '12'
//           }
//         }
//       ]
//     },
//     {
//       path: '/marketingManage/marketingActivities',
//       name: 'MarketingActivities',
//       component: () =>
//         import('@/views/marketingManage/marketingActivities/index'),
//       meta: {
//         title: '营销活动',
//       },
//       children: [
//         {
//           path: '/marketingManage/marketingActivities/activityManage',
//           name: 'ActivityManage',
//           component: () =>
//             import(
//               '@/views/marketingManage/marketingActivities/activityManage/index'
//             ),
//           meta: {
//             title: '活动管理'
//           }
//         },
//         {
//           path: '/marketingManage/marketingActivities/activityManage/create',
//           name: 'CreateCtivity',
//           component: () =>
//             import('@/views/marketingManage/marketingActivities/activityManage/create/index'),
//           meta: {
//             title: '创建活动'
//           }
//         },
//         {
//           path: '/marketingManage/marketingActivities/templateManage',
//           name: 'TemplateManage',
//           component: () =>
//             import(
//               '@/views/marketingManage/marketingActivities/templateManage/index'
//             ),
//           meta: {
//             title: '活动模板管理'
//           }
//         },
//         {
//           path: '/marketingManage/marketingActivities/templateManage/create',
//           name: 'CreateTemplate',
//           component: () =>
//             import(
//               '@/views/marketingManage/marketingActivities/templateManage/create/index'
//             ),
//           meta: {
//             title: '创建模板'
//           }
//         },
//         {
//           path: '/marketingManage/marketingActivities/templateManage/repository',
//           name: 'TemplateRepository',
//           component: () =>
//             import(
//               '@/views/marketingManage/marketingActivities/templateManage/repository/index'
//             ),
//           meta: {
//             title: '模板库'
//           }
//         }
//       ]
//     },
//     {
//       path: '/marketingManage/dataAnalysis',
//       name: 'DataAnalysis',
//       component: () => import('@/views/marketingManage/dataAnalysis/index'),
//       meta: {
//         title: '数据分析',
//       },
//       children: [
//         {
//           path: '/marketingManage/dataAnalysis/daily',
//           name: 'Daily',
//           component: () =>
//             import('@/views/marketingManage/dataAnalysis/daily'),
//           meta: {
//             title: '活动日报'
//           }
//         },
//         {
//           path: '/marketingManage/dataAnalysis/effect',
//           name: 'Effect',
//           component: () =>
//             import('@/views/marketingManage/dataAnalysis/effect'),
//           meta: {
//             title: '活动效果统计'
//           }
//         }
//       ]
//     },
//     {
//       path: '/marketingManage/memberManage',
//       component: () => import('@/views/marketingManage/memberManage'),

//       name: 'memberManage',

//       meta: {
//         title: '会员管理',

//       },
//       children: [
//         {
//           path: '/marketingManage/memberManage/member',
//           name: 'Member',
//           component: () => import('@/views/marketingManage/memberManage/member/index'),
//           meta: {
//             title: '会员',
//             moduleId: '120101',
//             proId: '12'
//           }
//         },
//         {
//           path: '/marketingManage/memberManage/overview',
//           name: 'Overview',
//           component: () => import('@/views/marketingManage/memberManage/overview/index'),
//           meta: {
//             title: '概览',
//           }
//         },
//         // {
//         //   path: 'detail',
//         //   name: 'MemberDetailShow',
//         //   component: () => import('@/views/memberManage/detail'),
//         //   meta: {
//         //     title: '会员详情'
//         //   },
//         //   hidden: true
//         // },
//         // {
//         //   path: 'detailUvpdate',
//         //   name: 'MemberDetailUpdate',
//         //   component: () => import('@/views/memberManage/detail'),
//         //   meta: {
//         //     title: '会员详情-编辑'
//         //   },
//         //   hidden: true
//         // }
//       ]
//     }
//   ]
// },
// {
//   path: '/chanelmange',
//   component: Layout,
//   name: 'chanelmange',
//   meta: {
//     title: '渠道管理',
//     icon: 'nested'
//   },
//   resourceLevel: 1,
//   children: [{
//     path: "/chanelmange/basicset",
//     name: 'basicset',
//     meta: {
//       title: '基础设置'
//     },
//     component: () => import('@/views/chanelmange/basicset/index.vue'),
//     resourceLevel: 2,
//     children: [
//       {
//         path: "/chanelmange/basicset/chanelparam",
//         name: 'chanelparam',
//         meta: {
//           title: '渠道参数'
//         },
//         component: () => import('@/views/chanelmange/basicset/chanelparam/index.vue'),
//         resourceLevel: 3,
//       },
//       {
//         path: "/chanelmange/basicset/chanelapp",
//         name: 'chanelapp',
//         meta: {
//           title: '渠道app配置'
//         },
//         component: () => import('@/views/chanelmange/basicset/chanelapp/index.vue'),
//         resourceLevel: 3,
//       },
//       {
//         path: "/chanelmange/basicset/ywtype",
//         name: 'ywtype',
//         meta: {
//           title: '业务类型'
//         },
//         component: () => import('@/views/chanelmange/basicset/ywtype/index.vue'),
//         resourceLevel: 3,
//       },
//       {
//         path: "/chanelmange/basicset/tgtype",
//         name: 'tgtype',
//         meta: {
//           title: '团购类型'
//         },
//         component: () => import('@/views/chanelmange/basicset/tgtype/index.vue'),
//         resourceLevel: 3,
//       },
//       {
//         path: "/chanelmange/basicset/tgdan",
//         name: 'tgdan',
//         meta: {
//           title: '团购档案'
//         },
//         component: () => import('@/views/chanelmange/basicset/tgdan/index.vue'),
//         resourceLevel: 3,
//       }
//     ]
//   },
//   {
//     path: "/chanelmange/purchase",
//     name: 'purchase',
//     meta: {
//       title: '采购'
//     },
//     component: () => import('@/views/chanelmange/purchase/index.vue'),
//     resourceLevel: 2,
//     children: [{
//       path: "/chanelmange/purchase/order",
//       name: 'order',
//       meta: {
//         title: '采购订单'
//       },
//       component: () => import('@/views/chanelmange/purchase/order/index.vue'),
//       resourceLevel: 3
//     }
//     ]
//   },
//   {
//     path: "/chanelmange/instock",
//     name: 'instock',
//     meta: {
//       title: '入库管理'
//     },
//     component: () => import('@/views/chanelmange/instock/index.vue'),
//     resourceLevel: 2,
//     children: [
//       {
//         path: "/chanelmange/instock/qcrk",
//         name: 'qcrk',
//         meta: {
//           title: '期初入库'
//         },
//         component: () => import('@/views/chanelmange/instock/qcrk/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/instock/cgrk",
//         name: 'cgrk',
//         meta: {
//           title: '采购入库'
//         },
//         component: () => import('@/views/chanelmange/instock/cgrk/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/instock/tbrk",
//         name: 'tbrk',
//         meta: {
//           title: '调拨入库'
//         },
//         component: () => import('@/views/chanelmange/instock/tbrk/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/instock/qtrk",
//         name: 'qtrk',
//         meta: {
//           title: '其他入库'
//         },
//         component: () => import('@/views/chanelmange/instock/qtrk/index.vue'),
//         resourceLevel: 3
//       }
//     ]
//   },

//   {
//     path: "/chanelmange/outstock",
//     name: 'outstock',
//     meta: {
//       title: '出库管理'
//     },
//     component: () => import('@/views/chanelmange/outstock/index.vue'),
//     resourceLevel: 2,
//     children: [

//       {
//         path: "/chanelmange/outstock/xsck",
//         name: 'xsck',
//         meta: {
//           title: '销售出库'
//         },
//         component: () => import('@/views/chanelmange/outstock/xsck/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/outstock/tbck",
//         name: 'tbck',
//         meta: {
//           title: '调拨出库'
//         },
//         component: () => import('@/views/chanelmange/outstock/tbck/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/outstock/qtck",
//         name: 'qtrk',
//         meta: {
//           title: '其他出库'
//         },
//         component: () => import('@/views/chanelmange/outstock/qtck/index.vue'),
//         resourceLevel: 3
//       }
//     ]
//   },

//   {
//     path: "/chanelmange/stockmange",
//     name: 'stockmange',
//     meta: {
//       title: '库存管理'
//     },
//     component: () => import('@/views/chanelmange/stockmange/index.vue'),
//     resourceLevel: 2,
//     children: [
//       {
//         path: "/chanelmange/stockmange/kctz",
//         name: 'kctz',
//         meta: {
//           title: '经销商库存调整'
//         },
//         component: () => import('@/views/chanelmange/stockmange/kctz/index.vue'),
//         resourceLevel: 3
//       }
//     ]
//   },
//   {
//     path: "/chanelmange/stockscan",
//     name: 'stockscan',
//     meta: {
//       title: '库存查看'
//     },
//     component: () => import('@/views/chanelmange/stockscan/index.vue'),
//     resourceLevel: 2,
//     children: [
//       {
//         path: "/chanelmange/stockscan/jxskc",
//         name: 'jxskc',
//         meta: {
//           title: '经销商库存查询'
//         },
//         component: () => import('@/views/chanelmange/stockscan/jxskc/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/stockscan/zdkc",
//         name: 'zdkc',
//         meta: {
//           title: '终端库存查询'
//         },
//         component: () => import('@/views/chanelmange/stockscan/zdkc/index.vue'),
//         resourceLevel: 3
//       }
//     ]
//   },
//   {
//     path: "/chanelmange/stockreport",
//     name: 'stockreport',
//     meta: {
//       title: '库存报表'
//     },
//     component: () => import('@/views/chanelmange/stockreport/index.vue'),
//     resourceLevel: 2,
//     children: [
//       {
//         path: "/chanelmange/stockreport/rkls",
//         name: 'rkls',
//         meta: {
//           title: '入库流水'
//         },
//         component: () => import('@/views/chanelmange/stockreport/rkls/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/stockreport/ckls",
//         name: 'ckls',
//         meta: {
//           title: '出库流水'
//         },
//         component: () => import('@/views/chanelmange/stockreport/ckls/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/stockreport/rkhz",
//         name: 'rkhz',
//         meta: {
//           title: '经销商入库汇总表'
//         },
//         component: () => import('@/views/chanelmange/stockreport/rkhz/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/stockreport/ckhz",
//         name: 'ckhz',
//         meta: {
//           title: '经销商出库汇总表'
//         },
//         component: () => import('@/views/chanelmange/stockreport/ckhz/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/chanelmange/stockreport/sfchz",
//         name: 'sfchz',
//         meta: {
//           title: '经销商收发存汇总'
//         },
//         component: () => import('@/views/chanelmange/stockreport/sfchz/index.vue'),
//         resourceLevel: 3
//       }
//     ]
//   },
//   ]
// },
// {
//   path: '/warehouseService',
//   component: Layout,
//   meta: {
//     title: '仓储系统',
//     icon: 'nested',
//   },
//   children: [
//     {
//       path: '/warehouseService/inventory/index',
//       component: () => import('@/views/warehouseService/inventory/index'),
//       name: '盘点管理',
//       meta: {
//         title: '盘点管理'
//       },
//       children: [
//         {
//           path: '/warehouseService/inventory/inventory',
//           component: () => import('@/views/warehouseService/inventory/inventory'),
//           name: '盘点管理',
//           meta: {
//             title: '盘点管理'
//           },
//         }
//       ]
//     },
//     // {
//     //   path: '/warehouseService/ordergl/index',
//     //   component: () => import('@/views/warehouseService/ordergl/index'),
//     //   name: '订单管理',
//     //   meta: {
//     //     title: '订单管理'
//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/ordergl/cgorder/index',
//     //       component: () => import('@/views/warehouseService/ordergl/cgorder/index'),
//     //       name: '采购订单',
//     //       meta: {
//     //         title: '采购订单',
//     //       }
//     //     },

//     //     {
//     //       path: '/warehouseService/ordergl/saleorder/index',
//     //       component: () => import('@/views/warehouseService/ordergl/saleorder/index'),
//     //       name: '销售订单',
//     //       meta: {
//     //         title: '销售订单',
//     //       }
//     //     },

//     //     {
//     //       path: '/warehouseService/ordergl/ydgl/index',
//     //       component: () => import('@/views/warehouseService/ordergl/ydgl/index'),
//     //       name: '引单管理',
//     //       meta: {
//     //         title: '引单管理',
//     //       }
//     //     }
//     //   ]
//     // },
//     // {
//     //   path: '/warehouseService/warehousing',
//     //   component: () => import('@/views/warehouseService/warehousing'),
//     //   name: '入库管理',
//     //   meta: {
//     //     title: '入库管理',

//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/warehousing/production/production',
//     //       component: () => import('@/views/warehouseService/warehousing/production/production'),
//     //       name: '生产入库',
//     //       meta: {
//     //         title: '生产入库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/warehousing/allot/allot',
//     //       component: () => import('@/views/warehouseService/warehousing/allot/allot'),
//     //       name: '调拨入库',
//     //       meta: {
//     //         title: '调拨入库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/warehousing/refunds/refunds',
//     //       component: () => import('@/views/warehouseService/warehousing/refunds/refunds'),
//     //       name: '退货入库',
//     //       meta: {
//     //         title: '退货入库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/warehousing/purchase/purchase',
//     //       component: () => import('@/views/warehouseService/warehousing/purchase/purchase'),
//     //       name: '采购入库',
//     //       meta: {
//     //         title: '采购入库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/warehousing/other/other',
//     //       component: () => import('@/views/warehouseService/warehousing/other/other'),
//     //       name: '其他入库',
//     //       meta: {
//     //         title: '其他入库',

//     //       },
//     //     },

//     //   ]
//     // },
//     // {
//     //   path: '/warehouseService/checkout',
//     //   component: () => import('@/views/warehouseService/checkout'),
//     //   name: '出库管理',
//     //   meta: {
//     //     title: '出库管理',

//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/checkout/allot/allot',
//     //       component: () => import('@/views/warehouseService/checkout/allot/allot'),
//     //       name: '调拨出库',
//     //       meta: {
//     //         title: '调拨出库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/checkout/sale/sale',
//     //       component: () => import('@/views/warehouseService/checkout/sale/sale'),
//     //       name: '销售出库',
//     //       meta: {
//     //         title: '销售出库',

//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/checkout/other/other',
//     //       component: () => import('@/views/warehouseService/checkout/other/other'),
//     //       name: '其他出库',
//     //       meta: {
//     //         title: '其他出库',

//     //       },
//     //     },
//     //   ]
//     // },
//     // {
//     //   path: '/warehouseService/stock',
//     //   component: () => import('@/views/warehouseService/stock'),
//     //   name: '库存管理',
//     //   meta: {
//     //     title: '库存管理',

//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/stock/turnover',
//     //       component: () => import('@/views/warehouseService/stock/turnover'),
//     //       name: '入出库流水查询',
//     //       meta: {
//     //         title: '入出库流水查询',
//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/stock/inventory',
//     //       component: () => import('@/views/warehouseService/stock/inventory'),
//     //       name: '库存清单',
//     //       meta: {
//     //         title: '库存清单',
//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/stock/warnings',
//     //       component: () => import('@/views/warehouseService/stock/warnings'),
//     //       name: '库存预警',
//     //       meta: {
//     //         title: '库存预警',
//     //       },
//     //     },

//     //   ]
//     // },
//     // {
//     //   path: '/warehouseService/record',
//     //   component: () => import('@/views/warehouseService/record'),
//     //   name: '记录管理',
//     //   meta: {
//     //     title: '记录管理',

//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/record/download',
//     //       component: () => import('@/views/warehouseService/record/download'),
//     //       name: '单据下载记录',
//     //       meta: {
//     //         title: '单据下载记录',
//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/record/codeReplace',
//     //       component: () => import('@/views/warehouseService/record/codeReplace'),
//     //       name: '数码替换记录',
//     //       meta: {
//     //         title: '数码替换记录',
//     //       },
//     //     },
//     //   ]
//     // },
//     // {
//     //   path: '/warehouseService/configure',
//     //   component: () => import('@/views/warehouseService/configure'),
//     //   name: '配置管理',
//     //   meta: {
//     //     title: '配置管理',

//     //   },
//     //   children: [
//     //     {
//     //       path: '/warehouseService/configure/documenType',
//     //       component: () => import('@/views/warehouseService/configure/documenType'),
//     //       name: '单据类型设置',
//     //       meta: {
//     //         title: '单据类型设置',
//     //       },
//     //     },
//     //     {
//     //       path: '/warehouseService/configure/setup',
//     //       component: () => import('@/views/warehouseService/configure/setup'),
//     //       name: '生产入库设置',
//     //       meta: {
//     //         title: '生产入库设置',
//     //       },
//     //     }
//     //   ]
//     // },
//   ]
// },
// {
//   path: '/system',
//   component: Layout,
//   name: 'system',
//   meta: {
//     title: '系统配置',
//     icon: 'example'
//   },
//   resourceLevel: 1,
//   children: [
//     {
//       path: "/system/logmange",
//       name: 'logmange',
//       meta: {
//         title: '日志管理'
//       },
//       component: () => import('@/views/system/logmange/index.vue'),
//       resourceLevel: 2,
//       children: [
//         {
//           path: "/system/logmange/operatelog",
//           name: 'operatelog',
//           meta: {
//             title: '操作日志'
//           },
//           component: () => import('@/views/system/logmange/operatelog/index.vue'),
//           resourceLevel: 3,
//         },
//         {
//           path: "/system/logmange/visitedlog",
//           name: 'visitedlog',
//           meta: {
//             title: '访问日志'
//           },
//           component: () => import('@/views/system/logmange/visitedlog/index.vue'),
//           resourceLevel: 3,
//         }
//       ]
//     },
//     {
//       path: "/system/rolesmange",
//       name: 'rolesmange',
//       meta: {
//         title: '角色用户'
//       },
//       component: () => import('@/views/system/rolesmange/index.vue'),
//       resourceLevel: 2,
//       children: [
//         {
//           path: "/system/rolesmange/resource",
//           name: 'resource',
//           meta: {
//             title: '资源管理'
//           },
//           component: () => import('@/views/system/rolesmange/resource/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/compyrole",
//           name: 'compyrole',
//           meta: {
//             title: '企业角色'
//           },
//           component: () => import('@/views/system/rolesmange/compyrole/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/compyuser",
//           name: 'compyuser',
//           meta: {
//             title: '企业用户'
//           },
//           component: () => import('@/views/system/rolesmange/compyuser/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/chanelrole",
//           name: 'chanelrole',
//           meta: {
//             title: '渠道角色'
//           },
//           component: () => import('@/views/system/rolesmange/chanelrole/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/chaneluser",
//           name: 'chaneluser',
//           meta: {
//             title: '渠道用户'
//           },
//           component: () => import('@/views/system/rolesmange/chaneluser/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/suplyrole",
//           name: 'suplyrole',
//           meta: {
//             title: '供货角色'
//           },
//           component: () => import('@/views/system/rolesmange/suplyrole/index.vue'),
//           resourceLevel: 3
//         },
//         {
//           path: "/system/rolesmange/suplyuser",
//           name: 'suplyuser',
//           meta: {
//             title: '供货用户'
//           },
//           component: () => import('@/views/system/rolesmange/suplyuser/index.vue'),
//           resourceLevel: 3
//         }
//       ]
//     },
//     {
//       path: "/system/splcmange",
//       name: 'splcmange',
//       meta: {
//         title: '审批流程'
//       },
//       component: () => import('@/views/system/splcmange/index.vue'),
//       resourceLevel: 2,
//       children: [{
//         path: "/system/splcmange/spset",
//         name: 'spset',
//         meta: {
//           title: '审批流程配置'
//         },
//         component: () => import('@/views/system/splcmange/spset/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/system/splcmange/wfqd",
//         name: 'wfqd',
//         meta: {
//           title: '我发起的'
//         },
//         component: () => import('@/views/system/splcmange/wfqd/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/system/splcmange/unsp",
//         name: 'unsp',
//         meta: {
//           title: '待我审批'
//         },
//         component: () => import('@/views/system/splcmange/unsp/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/system/splcmange/spwc",
//         name: 'spwc',
//         meta: {
//           title: '审批完成'
//         },
//         component: () => import('@/views/system/splcmange/spwc/index.vue'),
//         resourceLevel: 3
//       },
//       {
//         path: "/system/splcmange/spscan",
//         name: 'spscan',
//         meta: {
//           title: '审批单据查看'
//         },
//         component: () => import('@/views/system/splcmange/spscan/index.vue'),
//         resourceLevel: 3
//       }
//       ]

//     }

//   ]
// },

{
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [];
exports.asyncRoutes = asyncRoutes;
var all = [
  // {
  //   path: '/basic-data',
  //   component: Layout,
  //   asypath: null,
  //   level: 1,
  //   resourceCode: 'JCSJ',
  //   redirect: '/basic-data'
  // },
  // {
  //   path: '/basic-data/orgmange/index',
  //   component: () => import('@/views/basic-data/orgmange/index'),
  //   asypath: '/basic-data/orgmange',
  //   redirect: '/basic-data',
  //   resourceCode: 'ZZJGGL',
  //   level: 2
  // },

  // {
  //   path: '/basic-data/orgmange/org/index',
  //   component: () => import('@/views/basic-data/orgmange/org/index'),
  //   asypath: '/basic-data/orgmange/org',
  //   resourceCode: 'ZZJGLB',
  //   level: 3
  // },
  // {
  //   path: '/basic-data/regionmange/index',
  //   component: () => import('@/views/basic-data/regionmange/index'),
  //   asypath: '/basic-data/regionmange',
  //   redirect: '/basic-data',
  //   resourceCode: 'QYGL',
  //   level: 2
  // },

  // {
  //   path: '/basic-data/regionmange/region/index',
  //   component: () => import('@/views/basic-data/regionmange/region/index'),
  //   asypath: '/basic-data/regionmange/region',
  //   resourceCode: 'QYLB',
  //   level: 3
  // },
  // {
  //   path: '/basic-data/productmange/index',
  //   component: () => import('@/views/basic-data/productmange/index'),
  //   asypath: '/basic-data/productmange',
  //   level: 2,
  //   // resourceCode: '',
  //   redirect: '/basic-data'
  // },
  // {
  //   path: '/basic-data/productmange/productcategory/index',
  //   component: () => import('@/views/basic-data/productmange/productcategory/index'),
  //   asypath: '/basic-data/productmange/productcategory',
  //   // resourceCode: '',
  //   level: 3
  // },
];
exports.all = all;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
var _default = router;
exports.default = _default;