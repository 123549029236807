"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderDevy = _interopRequireDefault(require("./order-devy"));
var _orderInfo = _interopRequireDefault(require("./orderInfo"));
var _consignmentInfo = _interopRequireDefault(require("./consignment-info"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'integralMallintegralOrderorder';
var _default = {
  name: 'IntegralMallintegralOrderorder',
  components: {
    DevyAdd: _orderDevy.default,
    AddOrUpdate: _orderInfo.default,
    ConsignmentInfo: _consignmentInfo.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 0,
      // 总页数
      formInline: {
        current: 1,
        // 当前页数
        size: 10,
        // 每页显示多少条
        dateRange: null
      },
      // 订单状态
      options: [
      // {
      //   value: 1,
      //   label: '待付款'
      // },
      {
        value: 2,
        label: '待发货'
      }, {
        value: 3,
        label: '已发货'
      },
      // {
      //   value: 4,
      //   label: '待评价'
      // },
      {
        value: 5,
        label: '成功'
      }, {
        value: 6,
        label: '失败'
      }],
      // 数据列表
      dataList: [],
      dataListLoading: false,
      // 订单详情弹窗
      addOrUpdateVisible: false,
      // 订单发货
      devyVisible: false,
      consignmentInfoVisible: false,
      downLoading: false,
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.dataList && that.dataList.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    if (!hasCache) {
      that.getDataList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.dataList = [];
      that.total = 0;
    }
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    getDataList: function getDataList() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.dataListLoading = true;
      if (this.formInline.dateRange === null) {
        this.formInline.startTime = null;
        this.formInline.endTime = null;
      } else {
        this.formInline.startTime = this.formInline.dateRange[0]; // 开始时间
        this.formInline.endTime = this.formInline.dateRange[1]; // 结束时间
      }

      (0, _integralMall.orderList)(this.formInline).then(function (data) {
        if (data.status === 200) {
          _this.dataList = data.data.records;
          _this.total = data.data.total;
        }
        _this.dataListLoading = false;
      });
    },
    // 订单详情
    addOrUpdateHandle: function addOrUpdateHandle(val) {
      var _this2 = this;
      this.authId('detailbtn', this.apis);
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(val);
      });
    },
    // 订单发货
    changeOrder: function changeOrder(orderNumber) {
      var _this3 = this;
      this.authId('sendorder', this.apis);
      this.devyVisible = true;
      this.$nextTick(function () {
        _this3.$refs.devyAdd.init(orderNumber, _this3.dvyId, _this3.dvyFlowId);
      });
    },
    // 导出待发货订单
    getWaitingConsignmentExcel: function getWaitingConsignmentExcel(consignmentInfo) {
      this.authId('importorder', this.apis);
      (0, _integralMall.waitingConsignmentExcel)({
        // consignmentName: consignmentInfo.consignmentName,
        // consignmentMobile: consignmentInfo.consignmentMobile,
        // consignmentAddr: consignmentInfo.consignmentAddr,
        startTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[0],
        // 开始时间
        endTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[1] // 结束时间
      }).then(function (data) {
        var blob = new Blob([data.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        var fileName = '待发货信息整理.xlsx';
        var elink = document.createElement('a');
        if ('download' in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    // 导出销售记录
    getSoldExcel: function getSoldExcel() {
      this.authId('importrecord', this.apis);
      (0, _integralMall.soldExcel)({
        status: 5,
        startTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[0],
        // 开始时间
        endTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[1] // 结束时间
      }).then(function (data) {
        var blob = new Blob([data.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        var fileName = '销售信息整理.xlsx';
        var elink = document.createElement('a');
        if ('download' in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    }
  }
};
exports.default = _default;