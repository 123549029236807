exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".el-select{width:100%}.typeConfig .el-input-group__append,.typeConfig .el-input-group__prepend{background-color:#5B2832;color:#fff;vertical-align:middle;display:table-cell;position:relative;border:1px solid transparent;border-top-right-radius:4px;border-bottom-right-radius:4px;padding:0 20px;width:1px;white-space:nowrap}\n", ""]);

// exports
exports.locals = {
	"menuText": "#515151",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#5B2832",
	"menuBg": "#fff",
	"menuHover": "#5B2832",
	"subMenuBg": "#FAFAFB",
	"subMenuActiveBg": "#5B2832",
	"subMenuHover": "#5B2832",
	"sideBarWidth": "210px",
	"bg": "#f1f2f6",
	"skin": "#5B2832",
	"menuIcon": "#A2A5AF"
};