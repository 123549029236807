"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _shop = require("@/api/wxhyyy/shop.js");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      // 列表加载
      total: 0,
      // 总页面
      list: [{}],
      // 列表
      pageForm: {
        cid: '',
        pageSize: 10,
        pageNo: 1
      }
    };
  },
  mounted: function mounted() {
    this.listLoading = true;
    this.userId = localStorage.getItem('userId');
    this.pageForm.cid = localStorage.getItem('cid');
    this.getIntegralActFun();
  },
  methods: {
    // 获取积分规则
    getIntegralActFun: function getIntegralActFun() {
      var _this = this;
      (0, _shop.getIntegralAct)((0, _objectSpread2.default)({}, this.pageForm)).then(function (resp) {
        var resp = resp.data;
        _this.listLoading = false;
        if (resp.code === 0) {
          _this.list = resp.data.lstResult;
          _this.total = resp.data.pageTotal;
        }
      });
    },
    // 翻页
    pagingFun: function pagingFun() {
      this.getIntegralActFun();
    },
    // 路由跳转设备授权页
    routeTo: function routeTo(type, data) {
      var obj = JSON.stringify({
        type: type,
        id: data
      });
      this.$router.push({
        name: 'salesIntegralSet',
        query: {
          data: obj
        }
      });
    },
    switchFun: function switchFun(statuse, data) {
      var _this2 = this;
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(statuse === '1' ? '开启' : '关闭', "\u8BE5\u6761\u79EF\u5206\u89C4\u5219, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        (0, _shop.integralActOpenOrOlose)({
          cid: data.cid,
          id: data.id,
          statuse: statuse
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this2.list.forEach(function (item) {
              if (item.id === data.id) {
                item.statuse = statuse;
              }
            });
            _this2.$message({
              type: 'success',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u6210\u529F!")
            });
          } else {
            _this2.$message({
              type: 'info',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u5931\u8D25!")
            });
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(statuse === '1' ? '开启' : '关闭')
        });
      });
    },
    // 删除
    deleteFun: function deleteFun(data) {
      var _this3 = this;
      this.$confirm('此操作将永久删除该积分规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _shop.deleteIntegralAct)({
          cid: data.cid,
          id: data.id
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this3.$message({
              type: 'success',
              message: resp.msg
            });
            _this3.getIntegralActFun();
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    }
  }
};
exports.default = _default;