"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _auth = require("@/utils/auth");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
var _wxintegral = require("@/api/wxhyyy/wxintegral");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */

var baseUrl = '/wh';
var _default = {
  name: 'WxAccountscustomerctmsgmassrulemassindex',
  data: function data() {
    return {
      centerDialogVisible: false,
      tjTags: [],
      payewm: '',
      addcustomdia: false,
      // customdata: {
      //   name: '',
      //   type: ''
      // },
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      gqgz: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime();
        }
      },
      sendTime: '',
      fftxt: '',
      input: '',
      input1: '',
      form: {
        companyName: '',
        appaccessKey: '',
        secretKey: '',
        signName: '',
        smsSource: '',
        token: ''
      },
      imageUrl: {
        url: ''
      },
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      fstpUrl: '/api-memberscrm/member/admin/file/upload/single',
      twImgurl: 'http://testsaas.panpass.com/images/',
      disabled: false,
      msglist: [],
      msgmblist: []
    };
  },
  created: function created() {
    this.twImgurl = _wxHy.base;
    this.getdetail();
    this.getmslist();
    // this.getmsmblist()
  },

  methods: {
    getdetail: function getdetail() {
      var _this = this;
      (0, _wxyyKhlb.getmsgdetail)({
        smsSource: 0
      }).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data) {
            _this.form = res.data;
            console.log('form--', _this.form);
          } else {
            return false;
          }
        } else {
          return _this.$message.info(res.msg);
        }
      });
    },
    getmslist: function getmslist() {
      var _this2 = this;
      (0, _wxyyKhlb.getmsgList)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.length > 0 && res.data) {
            _this2.msglist = res.data;
          } else {
            return false;
          }
        } else {
          return _this2.$message.info(res.msg);
        }
      });
    },
    // addbtn() {
    //   if (this.tjTags.length > 2) {
    //     this.$message.warning('最多添加三组信息')
    //     return
    //   }
    //   this.addcustomdia = true
    //   this.customdata = {
    //     name: '',
    //     type: ''
    //   }
    // },
    handleClose: function handleClose(done) {
      done();
    },
    // 添加条件
    // addtj(val) {
    //   var that = this
    //   if (that.customdata.name.replace(/(^\s*)|(\s*$)/g, '') === '') {
    //     that.$message.warning('请填写名称')
    //     return
    //   }
    //   if (that.customdata.type === '') {
    //     that.$message.warning('请选择类型')
    //     return
    //   }
    //   that.tjTags.push(that.customdata.name)
    //   that.form.moWxMemberConditionDOList.push(that.customdata)
    //   console.log(that.form.moWxMemberConditionDOList)
    //   that.addcustomdia = false
    // },
    handleClosetj: function handleClosetj(tag, index) {
      var that = this;
      that.tjTags.splice(that.tjTags.indexOf(tag), 1);
      that.form.moWxMemberConditionDOList.splice(index, 1);
      console.log(that.form.moWxMemberConditionDOList);
    },
    // 支付测试
    upbtn: function upbtn() {
      var _this3 = this;
      console.log(this.form);
      (0, _wxintegral.gererateQRCode)().then(function (res) {
        res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'imageType'
        });
        _this3.payewm = (window.URL || window.webkitURL).createObjectURL(blob);
        _this3.centerDialogVisible = true;
      });
    },
    // 提交或修改支付
    onbtn: function onbtn(val) {
      var that = this;
      console.log(val);
      if (!val.smsSource) {
        that.$message.warning('请选择短信平台');
        return;
      }
      if (!val.companyName) {
        that.$message.warning('请填写企业名称');
        return;
      }
      if (val.accessKey.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请填写accessKey');
        return;
      }
      if (val.secretKey.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请填写secretKey');
        return;
      }
      if (!val.signName) {
        that.$message.warning('请填写短信签名');
        return;
      }
      (0, _wxyyKhlb.getmsgsave)(this.form).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.$message.success(res.msg);
        } else {
          that.$message.success(res.msg);
        }
      });
      // if(val.moWxMemberConditionDOList.length < 1 ) {
      //   that.$message.warning('请添加自定义条件')
      //   return
      // }
      // if (val.isRule === '') {
      //   that.$message.warning('请选择有效期')
      //   return
      // }
      // if (val.isRule === '2') {
      //   if (val.invalidDay < 1) {
      //     that.$message.warning('请正确填写天数')
      //     return
      //   }
      // }
      // if (val.isRule === '3') {
      //   if (val.invalidDateStr === null || val.invalidDateStr === '') {
      //     that.$message.warning('请选择时间')
      //     return
      //   }
      // }
      // updateRule(val).then(res => {
      //   if (res.code === 200) {
      //     that.$message.success('提交成功')
      //   } else {
      //     that.$message.warning(res.msg)
      //   }
      // })
      // this.centerDialogVisible = true
    },
    // 上传封面成功
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code * 1 === 200) {
        this.$message.success('上传成功');
        this.form.url = '';
        this.form.imgId = response.data.mediaId;
      } else {
        this.$message({
          message: response.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }

      // 上传图片url给左侧
      // console.log(this.imgIndex)
      this.imageUrl.url = URL.createObjectURL(file.raw);
      // 给tw添加url地址
      // this.tw[this.ele].img = this.imageUrl[this.ele].url
    },
    imgUploaderr: function imgUploaderr() {
      this.$message.closeAll();
      this.$message({
        message: '上传失败，请重试！',
        type: 'error',
        duration: 3 * 1000
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isImg = /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name);
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) {
        this.$message.error('上传图片只能是 gif/jpg/jpeg/png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isImg && isLt2M;
    },
    handleInput: function handleInput(e) {
      console.log(e);
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.form.invalidDay = e.target.value;
      } else if (e.target.value.length < this.form.invalidDay.length) {
        this.form.invalidDay = e.target.value;
      }
      // if (reg.test(e.target.value)) {
      //   this.sendObj.hdsjNum = e.target.value
      // } else if (e.target.value.length < this.sendObj.hdsjNum.length) {
      //   this.sendObj.hdsjNum = e.target.value
      // }
      this.$refs.inputss.value = this.form.invalidDay;
      console.log(this.form.invalidDay);
      console.log(this.$refs.inputss.value);
    }
  }
};
exports.default = _default;