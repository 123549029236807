"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProdBillLists = getProdBillLists;
exports.productOrderFailChart = productOrderFailChart;
exports.reportExportExcel = reportExportExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
// ---------------------------生产
// 获取列表
function getProdBillLists(params) {
  return (0, _request.default)({
    url: '/storage-center/ccProductReport/queryReportForPage',
    method: 'get',
    params: params
  });
}
// 导出
function reportExportExcel(data) {
  return (0, _request.default)({
    url: '/storage-center/ccProductReport/reportExportExcel',
    method: 'get',
    responseType: 'blob',
    params: data
  });
}
// 异常情况分析
function productOrderFailChart(params) {
  return (0, _request.default)({
    url: '/storage-center/ccUnusualChart/productOrderFailChart',
    method: 'get',
    params: params
  });
}