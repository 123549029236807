"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _work = require("@/api/systems/work");
var _compyrole = require("@/api/systems/compyrole");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _edit = _interopRequireDefault(require("./component/edit"));
var _add = _interopRequireDefault(require("./component/add"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var bills = [];
var cachName = 'systemsplcmangespsetindex';
var _default = {
  name: 'systemsplcmangespsetindex',
  components: {
    Pagination: _Pagination.default,
    edit: _edit.default,
    add: _add.default
  },
  data: function data() {
    return {
      listQuery: {
        moduleCode: '',
        name: '',
        billCode: '',
        status: '',
        pageNo: 1,
        pageSize: 20
      },
      list: [],
      tableKey: 'operratelog',
      total: 0,
      listLoading: false,
      loading: false,
      mloading: false,
      moduleData: [],
      pageData: [],
      stateOptions: [{
        id: '1',
        name: '生效'
      }, {
        id: '0',
        name: '失效'
      }],
      apis: [],
      rules: {
        nodeType: [{
          required: true,
          message: '请选择角色/用户',
          trigger: 'change'
        }],
        nodeValue: [{
          type: 'array',
          required: true,
          message: '请选择对应节点',
          trigger: 'change'
        }]
      },
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      addShow: false,
      editShow: false,
      pId: '',
      dialogVisible: false,
      fileList: [],
      pidString: '',
      nodeList: [],
      roleList: [],
      companyList: [],
      configVisible: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list && that.list.length > 0 ? true : false;
    that.apis = that.$route.meta.authList;
    if (!hasCache) {
      that.getPages();
      that.getMoudle();
      setTimeout(function () {
        that.getList();
      }, 200);
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _work.getWorkFlu)(that.listQuery).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.list.forEach(function (item) {
            item.billName = that.transName(item.billCode);
          });
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    getMoudle: function getMoudle() {
      var that = this;
      (0, _work.getMoudleMsg)().then(function (res) {
        if (res.data.code == 200) {
          that.moduleData = res.data.data;
        }
      });
    },
    getPages: function getPages() {
      var that = this;
      var param = {};
      param.code = 'SPLDJLX';
      (0, _work.getPageMsg)(param).then(function (res) {
        if (res.data.code == 200) {
          that.pageData = res.data.data;
          bills = res.data.data;
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.name = '';
      that.listQuery.billCode = '';
      that.listQuery.moduleCode = '';
      that.$refs[formName].resetFields();
      that.listQuery.pageNo = 1;
      that.getList();
    },
    transName: function transName(code) {
      var that = this;
      var return_name = '';
      if (bills.length > 0) {
        var name = bills.filter(function (item) {
          return item.figure == Number(code);
        });
        return_name = name[0].name;
      }
      return return_name;
    },
    //失效
    disadleState: function disadleState(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      that.changeState(data.pidString, '0');
    },
    //起效
    enadleState: function enadleState(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      that.changeState(data.pidString, '1');
    },
    //状态改变
    changeState: function changeState(pid, status) {
      var that = this;
      var param = {};
      param.pId = pid;
      param.status = status;
      (0, _work.changeStates)(param).then(function (res) {
        console.log(res.data);
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '状态修改成功'
          });
          that.getList();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    //修改
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      that.pId = data.pidString;
      that.editShow = true;
    },
    closeEdit: function closeEdit() {
      var that = this;
      that.editShow = false;
    },
    //添加
    add: function add() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.addShow = true;
    },
    closeAdd: function closeAdd() {
      var that = this;
      that.addShow = false;
    },
    downLoad: function downLoad(data) {
      var that = this;
      var param = {
        pId: data.pidString
      };
      (0, _work.downLoadFile)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/zip'
        });
        var href = window.URL.createObjectURL(blob);
        var downloadElement = document.createElement('a');
        downloadElement.style.display = 'none';
        downloadElement.href = href;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    upLoad: function upLoad(data) {
      var that = this;
      that.pidString = data.pidString;
      that.dialogVisible = true;
    },
    change: function change(file, fileList) {
      var that = this;
      that.fileList = [];
      that.fileList = [file];
    },
    submitUpload: function submitUpload() {
      var that = this;
      if (that.fileList.length == 0) {
        that.$message.error('请选择文件');
        return;
      }
      that.loading = true;
      var pId = that.pidString;
      var file = that.fileList[0];
      var formData = new FormData();
      formData.append('file', file.raw);
      (0, _work.upLoadFile)(pId, formData).then(function (res) {
        that.loading = false;
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '上传成功！'
          });
          that.fileList = [];
          that.getList();
          that.handleClose();
        } else {
          that.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.fileList = [];
      that.dialogVisible = false;
    },
    //审批节点配置
    setConfigNode: function setConfigNode(data) {
      var that = this;
      that.mloading = true;
      var setHeaderId = that.authId('setbtn', that.apis);
      var param = {
        modelCheckId: data.pidString
      };
      if (that.roleList.length == 0 || that.companyList.length == 0) {
        (0, _compyrole.getRoleList)({
          pageNo: 1,
          pageSize: 5000
        }).then(function (response) {
          if (response.data.code == 200) {
            that.roleList = response.data.data.records;
          } else {
            that.roleList = [];
          }
        }).then(function (res) {
          (0, _compyuser.getCompanyList)({
            current: 1,
            size: 5000
          }, {
            loginAccount: '',
            userName: '',
            userType: 0,
            phoneNum: '',
            isEnable: '',
            orgList: [],
            roleList: []
          }).then(function (response) {
            if (response.data.code == 200) {
              that.companyList = response.data.data.records;
            } else {
              that.companyList = [];
            }
          }).then(function (res) {
            that.setAllNodes(param);
          });
        });
      } else {
        that.setAllNodes(param);
      }
    },
    setAllNodes: function setAllNodes(param) {
      var that = this;
      (0, _work.getAllNodes)(param).then(function (res) {
        var nodeList = [];
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData && resData.length) {
            nodeList = resData.map(function (item) {
              return {
                checkModelId: item.checkModelId,
                isAllowModify: item.isAllowModify,
                nodeKey: item.isAllowModify,
                nodeName: item.nodeName,
                nodeType: item.nodeType,
                nodeArr: [],
                nodeValue: item.nodeValue ? item.nodeValue.split(',') : [],
                nodeValueName: item.nodeValueName,
                pId: item.pId
              };
            });
          } else {
            nodeList = [];
          }
        } else {
          nodeList = [];
        }
        return nodeList;
      }).then(function (res) {
        var list = res;
        list.forEach(function (item) {
          if (item.nodeType == '1') {
            var data = item.nodeValue;
            var arr = [];
            for (var i = 0; i < data.length; i++) {
              for (var j = 0; j < that.companyList.length; j++) {
                if (data[i] == that.companyList[j].pid) {
                  arr.push(data[i]);
                  break;
                }
              }
            }
            item.nodeArr = arr;
          }
          if (item.nodeType == '2') {
            var data = item.nodeValue;
            var arr = [];
            for (var _i = 0; _i < data.length; _i++) {
              for (var _j = 0; _j < that.roleList.length; _j++) {
                if (data[_i] == that.roleList[_j].roleCode) {
                  arr.push(data[_i]);
                  break;
                }
              }
            }
            item.nodeArr = arr;
          }
        });
        that.nodeList = list;
        that.mloading = false;
        that.configVisible = true;
      });
    },
    configClose: function configClose() {
      var that = this;
      that.configVisible = false;
    },
    saveData: function saveData() {
      var that = this;
      var subBoolean = true;
      if (that.nodeList.length) {
        for (var i = 0; i < that.nodeList.length; i++) {
          if (!that.nodeList[i].nodeType) {
            that.$message.error('请选择审批角色或用户');
            subBoolean = false;
            break;
          }
          if (that.nodeList[i].nodeValue.length == 0) {
            that.$message.error('请选择节点');
            subBoolean = false;
            break;
          }
        }
      } else {
        that.$message.error('请配置审批节点');
        return;
      }
      if (subBoolean) {
        that.mloading = true;
        var subData = that.nodeList.map(function (item) {
          var obj = (0, _utils.deepClone)(item);
          obj.nodeValue = obj.nodeValue.join(',');
          return obj;
        });
        (0, _work.saveNodeConfig)(subData).then(function (res) {
          that.mloading = false;
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '保存成功'
            });
            that.getList();
            that.configClose();
          }
        }).catch(function () {
          that.mloading = false;
        });
      }
    }
  }
};
exports.default = _default;