"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _channel = require("@/api/basic/channel");
var _dealer = require("@/api/basic/dealer");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '经销商',
        2: '终端'
      };
      return statusMap[status];
    }
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    channelTypeCanChange: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      areaList: [],
      area: [],
      selLoading: false,
      proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        channelType: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        keyword: ''
      },
      tableData: [],
      normalList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  computed: {},
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.getAreaAll();
        this.getList();
      }
    }
  },
  // mounted() {

  // },

  methods: {
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaList = [];
          }
        }
      });
    },
    setsel: function setsel() {
      var _this = this;
      this.getList();
      this.selLoading = true;
      this.$nextTick(function () {
        _this.data.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });

        setTimeout(function () {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.selLoading = false;
        }, 500);
      });
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      if (that.area) {
        submitData.provinceCode = that.area[0];
        submitData.cityCode = that.area[1] || '';
        submitData.countyCode = that.area[2] || '';
      }
      (0, _channel.channelArchivesList)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: ''
      };
      this.area = [];
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val, '已选');
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.proSelVisible = false;
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;