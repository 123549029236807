"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
var _source = require("@/api/systems/source");
var _work = require("@/api/systems/work");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Add',
  props: {
    formData: Object,
    dialogVisible: Boolean,
    type: String
  },
  data: function data() {
    var checkPageNo = function checkPageNo(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入定位页码'));
      } else if (!/^[0-9]*$/.test(Number(value))) {
        return callback(new Error('请输入数字值'));
      } else {
        callback();
      }
    };
    return {
      resourceTypes: [{
        name: '菜单',
        id: '1'
      }, {
        name: '按钮',
        id: '2'
      }
      // { name: '页面', id: '3' }
      ],

      cilentTypes: [],
      rules: {
        resourceCode: [{
          required: true,
          message: '请输入资源编码',
          trigger: 'blur'
        }],
        resourceName: [{
          required: true,
          validator: _source.checkRequiredWord,
          trigger: 'blur'
        }],
        resourceType: [{
          required: true,
          message: '请选择资源类型',
          trigger: 'change'
        }],
        // resourceUrl: [
        //   {
        //     required: true,
        //     message: '请输入页面URL',
        //     trigger: 'blur'
        //   }
        // ],
        resourceId: [{
          required: true,
          validator: _source.checkId,
          trigger: 'blur'
        }],
        menuClass: [{
          required: true,
          message: '请选择资源分类',
          trigger: 'change'
        }],
        resourceSort: [{
          required: true,
          validator: _source.checkNum,
          trigger: 'blur'
        }]
      },
      dstate: false,
      mloading: false,
      key: '1',
      level: null,
      options: [],
      btnData: [],
      // apiInfoIds: [],
      loading: false,
      showIds: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var that = this;
      if (val == true) {
        that.level = that.$parent.addData.level;
        that.$parent.addData.resourceType = '1';
        var resouceCode = that.formData.parentCode;
        var param = {
          current: 1,
          keywords: '',
          size: 1000
        };
        that.getBtnIds(resouceCode);
        that.getApiList(param);
        this.getsuoshu();
      }
    }
  },
  methods: {
    getsuoshu: function getsuoshu() {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CLIENT_CODE'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.cilentTypes = res.data.data;
          } else {
            _this.cilentTypes = [];
          }
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('formData');
    },
    remoteMethod: function remoteMethod(query) {
      var that = this;
      var param = {
        current: 1,
        keywords: '',
        size: 1000
      };
      if (query !== '') {
        that.loading = true;
        param.keywords = query;
        that.getApiList(param);
      }
    },
    getBtnIds: function getBtnIds(arg) {
      var that = this;
      var param = {
        resouceCode: arg
      };
      (0, _source.getAllBtnIds)(param).then(function (res) {
        if (res.data.code == 200) {
          that.btnData = res.data.data;
        } else {
          that.btnData = [];
        }
      }).catch(function () {
        that.btnData = [];
      });
    },
    getApiList: function getApiList(param) {
      var that = this;
      (0, _work.getApiList)(param).then(function (res) {
        that.loading = false;
        if (res.data.code == 200) {
          that.options = res.data.data.records;
        } else {
          that.options = [];
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    scanID: function scanID() {
      var that = this;
      that.showIds = !that.showIds;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var that = this;
      that.formData.resourceId = val.resourceId;
      that.$refs.resourceId.focus();
      that.$refs.resourceId.blur();
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.mloading = true;
          that.dsate = true;
          var data = that.formData;
          console.log(data);
          var param = {};
          param.parentCode = data.parentCode;
          param.resourceName = data.resourceName;
          param.resourceType = data.resourceType;
          param.resourceId = data.resourceId;
          param.resourceCode = data.resourceCode;
          param.menuClass = data.menuClass;
          var arr = [];
          if (data.resourcePath.length > 0) {
            data.resourcePath.forEach(function (el) {
              arr.push(el.url);
            });
          }
          param.resourcePath = arr;
          // param.resourcePath = data.resourcePath
          // param.apiInfoIds = that.apiInfoIds
          param.resourceUrl = data.resourceUrl;
          param.resourceSort = data.resourceSort;
          param.clientCode = data.clientCode;
          // param.manualPage = data.manualPage
          param.remark = data.remark;
          (0, _source.addResource)(param).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '新增成功'
              });
              that.handleClose();
              that.$parent.getList();
              that.mloading = false;
              that.dsate = false;
            } else {
              that.$message.error(res.data.msg);
              that.mloading = false;
              that.dsate = false;
              return;
            }
          }).catch(function () {
            that.mloading = false;
            that.dsate = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      // that.apiInfoIds = []
      that.key = '1';
      that.$refs[formName].resetFields();
      that.$emit('close');
    },
    getKey: function getKey(val) {
      var that = this;
      that.key = val;
      // if (that.key == '1') {
      //   that.rules.resourceUrl[0].required = true
      // } else if (that.key == '2') {
      //   that.rules.resourceUrl[0].required = false
      // }
    },
    addurl: function addurl() {
      this.formData.resourcePath.push({
        url: ''
      });
    },
    deleteurl: function deleteurl(index) {
      this.formData.resourcePath.splice(index, 1);
    }
  }
};
exports.default = _default;