"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBlacklist = addBlacklist;
exports.delBlacklist = delBlacklist;
exports.detailMember = detailMember;
exports.detailMemberUpdate = detailMemberUpdate;
exports.freezeMember = freezeMember;
exports.getBar = getBar;
exports.getIntegralByMemberId = getIntegralByMemberId;
exports.getList = getList;
exports.getMap = getMap;
exports.memberExcel = memberExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员列表
// var baseUrl = '/user'
// var baseUrl = '/api'
var baseUrl = '';
function getList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/search',
    method: 'POST',
    data: data
  });
}
// 根据id查看会员详情
function detailMember(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/detailMember',
    method: 'get',
    params: params
  });
}
// 编辑会员信息
function detailMemberUpdate(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/editMember',
    method: 'post',
    data: data
  });
}
// 冻结 解冻会员
function freezeMember(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/freezeMember',
    method: 'PUT',
    params: params
  });
}
// 解除黑名单
function delBlacklist(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/delBlacklist',
    method: 'get',
    params: params
  });
}
// 移至黑名单
function addBlacklist(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/addBlacklist',
    method: 'get',
    params: params
  });
}
// 会员概要按时间统计
function getBar(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/countMember',
    method: 'get',
    params: params
  });
}
// 会员概要区域统计
function getMap(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/proviceMember',
    method: 'get',
    params: params
  });
}
// 根据会员id查询积分详情
function getIntegralByMemberId(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/api/integral/getByMemberId',
    method: 'post',
    data: data
  });
}

// 根据会员列表导出
function memberExcel(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/memberExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}