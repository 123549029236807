"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _WechatUser = require("@/api/operationalTools/WechatUser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsactiveUsersindex';
var _default = {
  name: 'MarketingManageoperationalToolsactiveUsersindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      // 请求参数
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: false,
      look: false,
      tabHeight: '100%',
      // 表格数据
      tableData: [],
      rangeTime: '',
      formInline: {
        searchField: '',
        status: '',
        startTime: null,
        endTime: null
      },
      // 导出按钮加载
      downLoading: false,
      // 批量导入
      mloading: false,
      uploadVisible: false,
      uploadForm: {
        uploadFile: ''
      },
      uploadRules: {},
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      imgList: [],
      fileList: [],
      uploadData: [],
      //
      form: {
        checked1: false,
        number1: '',
        checked2: false,
        number2: '',
        checked3: false
      },
      dialogRestrict: false,
      title: '',
      dialogVisible: false,
      salesmanFrom: {
        // activityTimes: "", //参与活动次数
        // createTime: "", //创建时间
        // dayLimitDraw: 0, //单日限制中奖次数s
        // enableLastPrize: 0, //启用只用末等奖
        // extVal: "", //扩展属性值
        // id: "", //主键id
        // ipAddress: "", //最近ip
        // openId: "", //参与者openid
        // phoneNo: "", //手机号
        // status: 0, //微信用户状态
        // totalDrawTimes: 0, //总限制中奖次数
        // unionId: "", //参与者unionid
        // userName: "", //姓名
        // wechatName: "" //参与者微信昵称
      },
      listData: [],
      params2: {
        pageNo: 1,
        pageSize: 10,
        userId: ''
      },
      total2: 0,
      listLoading2: false,
      // 详情扩展信息
      isBasics: false,
      activeName: 'first',
      isEdit: false,
      editType: 'edit'
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + process.env.VUE_APP_VERSION2 + '/market-config/activityWechatUser/importBlacklist';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.formInline.status = '';
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getUserPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取活动用户列表
    getUserPage: function getUserPage(data) {
      var that = this;
      that.listLoading = true;
      var sumitData = Object.assign((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      (0, _WechatUser.getUserPage)(sumitData).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = Number(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    getTableItemIndex: function getTableItemIndex(index) {
      return (this.params.pageNo - 1) * this.params.pageSize + index + 1;
    },
    // 获取详情
    getDetails: function getDetails() {
      var _this = this;
      (0, _WechatUser.getDetails)({
        userId: this.params2.userId
      }).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode == 500) {
          return _this.$message.error(res.data.data.errMsg);
        }
        _this.salesmanFrom = res.data.data;
      }).catch(function (res) {
        _this.$message.error(res.data.msg);
      });
    },
    // 详情表格
    getUserReferenceRecordPage: function getUserReferenceRecordPage() {
      var _this2 = this;
      this.listLoading2 = true;
      var that = this;
      (0, _WechatUser.getUserReferenceRecordPage)(this.params2).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode == 500) {
          return _this2.$message.error(res.data.data.errMsg);
        } else {
          if (res.data.data != null) {
            that.listData = res.data.data.records;
            that.total2 = Number(res.data.data.total);
            setTimeout(function () {
              that.listLoading2 = false;
            }, 1 * 800);
          } else {
            that.listData = [];
            that.total2 = 0;
            that.listLoading2 = false;
          }
        }
      }).catch(function () {
        that.listLoading2 = false;
      });
    },
    // 时间选择器
    sj: function sj(res) {
      console.log(res);
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.getUserPage(this.formInline);
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.params.pageNo = 1;
      this.$refs[formName].resetFields();
      this.rangeTime = '';
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.formInline.nickName = '';
      this.getUserPage(this.formInline);
    },
    // 限制用户权限按钮
    restrictUsers: function restrictUsers() {
      this.dialogRestrict = true;
    },
    // 点击编辑按钮
    handleEdit: function handleEdit(row) {
      this.title = '编辑';
      this.editType = 'edit';
      this.activeName = 'first';
      this.isEdit = false;
      this.salesmanFrom = JSON.parse(JSON.stringify(row));
      // console.log(this.salesmanFrom);
      this.dialogVisible = true;
    },
    // 详情
    handleDetail: function handleDetail(row) {
      console.log(row);
      this.title = '详情';
      this.params2.userId = row.id;
      this.getDetails();
      this.getUserReferenceRecordPage();
      // this.isEdit = true;
      // this.editType = "preview";
      // this.salesmanFrom = JSON.parse(JSON.stringify(row));
      // this.salesmanFrom.status = Number(row.status);
      this.look = true;
    },
    handleClose: function handleClose() {
      this.look = false;
    },
    // 限制,黑名单
    Switchstate: function Switchstate(row, type) {
      var _this3 = this;
      if (type == 1) {
        if (row.status == '1') {
          this.$confirm('是否修改状态为正常', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            (0, _WechatUser.getBlacklist)({
              userId: row.memberId,
              status: row.status == '1' ? '0' : '1'
            }).then(function (res) {
              if (res.data.data.errCode) {
                return _this3.$message.error(res.data.data.errMsg);
              }
              _this3.getUserPage();
              _this3.$message.success("\u72B6\u6001\u4FEE\u6539\u4E3A".concat(row.status == '1' ? '正常' : '限制', "\u6210\u529F!"));
            });
          });
        } else {
          this.$prompt('请输入单人每天抽奖上限', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /(^[0-9]\d{0,6}$)/,
            inputValue: row.dayLimitDraw || '',
            inputErrorMessage: '请输入长度不超过6的正整数'
          }).then(function (_ref) {
            var value = _ref.value;
            console.log(value);
            (0, _WechatUser.getLimited)({
              count: value,
              userId: row.memberId
            }).then(function (res) {
              if (res.data.data.errCode) {
                return _this3.$message.error(res.data.data.errMsg);
              }
              _this3.getUserPage();
              _this3.$message.success('已限制该用户抽奖上限');
            });
          }).catch(function () {});
        }
      } else if (type == 2) {
        this.$confirm("\u662F\u5426\u4FEE\u6539\u72B6\u6001\u4E3A".concat(row.status == '2' ? '正常' : '黑名单', "?"), '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _WechatUser.getBlacklist)({
            userId: row.memberId,
            status: row.status == '2' ? '0' : '2'
          }).then(function (res) {
            // console.log(res.data);
            _this3.getUserPage();
            _this3.$message({
              type: 'success',
              message: "\u72B6\u6001\u4FEE\u6539\u4E3A".concat(row.status == '2' ? '正常' : '黑名单', "\u6210\u529F!")
            });
          });
        }).catch(function () {
          _this3.$message({
            type: 'info',
            message: "\u5DF2\u53D6\u6D88\u4FEE\u6539"
          });
        });
      }
    },
    // 记录跳转
    handleNumber: function handleNumber(row) {
      this.$router.push({
        name: 'marketingManageoperationalToolswinningLotteryindex',
        params: {
          userId: row.memberId
        }
      });
    },
    // 弹框取消关闭
    cancel: function cancel() {
      this.dialogVisible = false;
    },
    // 弹框确定
    confirm: function confirm() {
      var _this4 = this;
      (0, _WechatUser.detailUpdate)({
        id: this.salesmanFrom.id,
        phoneNo: this.salesmanFrom.phoneNo,
        userName: this.salesmanFrom.userName
      }).then(function (res) {
        // console.log(res.data);
        if (res.data.data.errCode) {
          return _this4.$message.error(res.data.data.errMsg);
        }
        _this4.$message.success('编辑成功');
        _this4.dialogVisible = false;
        _this4.getUserPage();
      }).catch(function (res) {
        console.log(res);
      });
      // console.log(this.salesmanFrom);
    },
    // 基础按钮
    basics: function basics() {
      this.isBasics = true;
    },
    // 导出
    download: function download() {
      var _this5 = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      // console.log(submitData);
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _WechatUser.getUserExportExcel)(submitData).then(function (res) {
        _this5.downLoading = false;
        if (res.status != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '活动用户管理.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this5.downLoading = false;
      });
    },
    // 导入
    uploadShow: function uploadShow() {
      this.uploadForm.uploadFile = '';
      this.uploadVisible = true;
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.$refs.upload.clearFiles();
      this.uploadVisible = false;
    },
    selectFile: function selectFile(file, fileList) {
      this.uploadForm.uploadFile = file.name;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.imgList = fileList;
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.data.errCode == 500) {
        this.$message.error(res.data.errMsg);
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.mloading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
      }
      this.mloading = false;
      this.uploadClose();
      this.getUserPage();
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    beforeAvatarUpload: function beforeAvatarUpload(file, fileList) {
      // const file = param.file
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.2;
      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 PNG/JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 200kb!');
      }
      // if ((isJPG || isPNG) && isLt2M) {
      //   const formData = new FormData()
      //   formData.append('file', file)
      //   imgupload(formData).then(response => {
      //     var data = response.data
      //     if (data.code != 200) {
      //       this.$message.error(data.msg)
      //     } else {
      //       this.imgList.push({ url: data.data })
      //     }
      //   })
      // }
      return (isJPG || isPNG) && isLt2M;
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    submitUpload: function submitUpload() {
      if (!this.uploadForm.uploadFile) {
        this.$message.error('请导入文件');
        return;
      } else {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      }
    }
  }
};
exports.default = _default;