"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic.js");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var codeTypeList = [];
var _default = {
  name: 'MarkclientcreateCodeMangeindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (!time) {
        return '';
      }
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      return item[0].markLen + '位' + statusPack[packLevel] + statusMark[markType];
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      downlist: null,
      total: 0,
      downTotal: 0,
      listLoading: true,
      dialogLoading: true,
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        packageOrderNum: ''
      },
      DownListQuery: {
        pageNo: 1,
        pageSize: 10,
        packageOrderNum: ''
      },
      temp: {
        state: '',
        code: ''
      },
      selectOptions: {
        supplierType: [{
          id: 1,
          name: '标签厂'
        }, {
          id: 2,
          name: '制盖厂'
        }
        // { id: 3, name: '膜厂' },
        // { id: 4, name: '仓储商' }
        ],

        ApprovalType: 1,
        auditStatus: [{
          id: 1,
          name: '生成中'
        }, {
          id: 3,
          name: '生成失败'
        }, {
          id: 2,
          name: '生成成功'
        }]
      },
      dialogFormVisible: false,
      showReason: false,
      dialogStatus: '',
      textMap: {
        approval: '授权码审核详情'
      },
      dialogPvVisible: false,
      rules: {
        Reason: [{
          required: true,
          message: '输入url描述内容',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      btnPowers: null
    };
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.btnPowers = {};
    this.getCodeType();
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    handleClose: function handleClose() {
      this.temp = {
        state: '',
        code: ''
      };
      this.dialogFormVisible = false;
      this.getList();
    },
    getCodeType: function getCodeType() {
      var _this = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        _this.numberType = [];
        if (data.code != 200) {
          _this.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _client_licensing.clientAuthCodeSerach)(this.listQuery).then(function (response) {
        var data = response.data;
        _this2.list = null;
        _this2.total = 0;
        _this2.listLoading = false;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this2.list = data.data.records;
          _this2.total = Number(data.data.total);
        } else {
          _this2.list = null;
          _this2.total = 0;
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery = {
        pageNo: 1,
        pageSize: 10,
        packageOrderNum: ''
      };
      this.getList();
    },
    changeApprovalTypeHandler: function changeApprovalTypeHandler(v) {
      if (v == 0) {
        this.showReason = true;
        return true;
      }
      this.showReason = false;
      this.temp.Reason = '';
      return true;
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this3 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      this.dialogFormVisible = true;
      this.dialogLoading = true;
      (0, _client_licensing.generateAuthCode)({
        pid: row.pid,
        markOrderNo: row.markOrderNo,
        supplierCode: row.supplierCode
      }).then(function (res) {
        var data = res.data;
        _this3.dialogLoading = false;
        if (data.code != 200) {
          _this3.temp.state = 2;
          _this3.$message.error(data.msg);
        } else {
          _this3.temp.state = 1;
          _this3.temp.code = data.data;
        }
      }).catch(function () {
        _this3.temp.state = 2;
        _this3.dialogLoading = false;
      });
      // this.$confirm(
      //   '制码订单号：[' + row.packOrderNum + ']是否重新生成码?',
      //   '提示',
      //   {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }
      // )
      //   .then(() => {
      //     if (row.applyNumId == null || row.applyNumId == '') {
      //       this.$message.error('提交信息不全')
      //       return false
      //     }
      //     generate({ applyNumId: row.applyNumId }).then(res => {
      //       var data = res.data
      //       if (data.code != 200) {
      //         this.$message.error(data.msg)
      //         return false
      //       }
      //       this.getList()
      //       this.$message({ message: '重新申请成功', type: 'success' })
      //     })
      //   })
      //   .catch(() => {})
    }
  }
};
exports.default = _default;