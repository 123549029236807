"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loglist = loglist;
exports.posExport = posExport;
exports.posLogExport = posLogExport;
exports.postAgreement = postAgreement;
exports.postCreate = postCreate;
exports.postDisable = postDisable;
exports.postEnable = postEnable;
exports.postPage = postPage;
exports.postUpdate = postUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
// 终端协议列表
function postPage(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/page',
    method: 'post',
    data: data
  });
}

// 终端协议添加
function postCreate(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/create',
    method: 'post',
    data: data
  });
}
// 终端协议编辑
function postUpdate(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/update',
    method: 'post',
    data: data
  });
}
// 终端协议启用
function postEnable(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/enable/' + data,
    method: 'post'
  });
}
// 终端协议停用
function postDisable(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/disable/' + data,
    method: 'post'
  });
}
// 导出终端协议
function posExport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
// 终端协议详情
function postAgreement(id) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/' + id,
    method: 'get'
  });
}

// 终端协议日志
function loglist(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/sign-log/page',
    method: 'post',
    data: data
  });
}
// 导出终端协议日志
function posLogExport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/agreement/sign-log/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}