"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Insert from './components/insert.vue'
var _default = {
  name: 'BasicDataCustomerGroupIndex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  data: function data() {
    return {
      // 搜素
      handleShow: false,
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        keyword: '',
        // groupCode: '',
        // groupName: '',
        channelCode: '',
        channelName: ''
      },
      // 表单
      tableData: [],
      listLoading: false,
      tableKey: '1101',
      tabHeight: '100%',
      total: 0,
      groupCode: '',
      groupName: '',
      dialogVisible: false,
      mloading: false,
      title: '',
      downLoading: false,
      pid: '',
      rowData: {},
      type: '',
      classType: []
    };
  },
  watch: {},
  mounted: function mounted() {
    this.$nextTick(function () {
      this.tabHeight = window.innerHeight - 380 + 'px';
    });
  },
  activated: function activated() {
    this.getList();
    // this.getItemByDataDictionaryByCodeFun()
    // this.$nextTick(() => {
    //   this.$refs.table.doLayout()
    // })
  },

  methods: {
    handleShowFun: function handleShowFun() {
      this.handleShow = !this.handleShow;
      this.$nextTick(function () {
        this.tabHeight = (0, _utils.tableHeight)();
      });
    },
    getItemByDataDictionaryByCodeFun: function getItemByDataDictionaryByCodeFun() {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'classType'
      }).then(function (res) {
        if (res.data.code === 200) {
          _this.classType = res.data.data;
        }
      });
    },
    // 获取数据
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(that.searchForm)));
      submitData.channelCode = that.searchForm.groupCode;
      submitData.channelName = that.searchForm.groupName;
      delete submitData.productName;
      delete submitData.groupCode;
      delete submitData.groupName;
      (0, _channel.grouplist)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm(formName) {
      this.searchForm = {
        pageNo: 1,
        pageSize: 20,
        keyword: '',
        groupCode: '',
        groupName: ''
        // channelKeyword: ''
        // classType: ''
      };

      this.getList();
    },
    // 详情
    getDetail: function getDetail(row) {
      var _this2 = this;
      this.authId('detailbtn', this.$route.meta.authList);
      this.title = '客户组详情';
      this.dialogVisible = true;
      this.mloading = true;
      this.groupCode = row.groupCode;
      this.groupName = row.groupName;
      (0, _channel.getDetailByCode)({
        groupCode: row.groupCode
      }).then(function (res) {
        _this2.mloading = false;
        if (res.data.code === 200) {
          _this2.rowData = res.data.data;
        }
      });
    },
    // 关闭
    dialogClose: function dialogClose() {
      this.dialogVisible = false;
      this.mloading = false;
    },
    // 导出列表
    dowmLoad: function dowmLoad() {},
    // 添加
    // add() {
    //   this.authId('addbtn', this.$route.meta.authList)
    //   this.title = '添加客户组'
    //   this.dialogVisible = true
    //   this.pid = ''
    //   this.type = ''
    // },
    // 编辑查看
    // openLookFun(row, type) {
    //   if (type === 1) {
    //     this.title = '客户组编辑'
    //   } else {
    //     this.title = '客户组查看'
    //   }
    //   this.pid = row.pId
    //   this.rowData = row
    //   this.type = type
    //   this.dialogVisible = true
    // },
    // 采供关系关闭
    closeInsertFun: function closeInsertFun(type) {
      this.dialogVisible = false;
      if (type === 1) {
        this.ruleForm = {
          name: '',
          productCode: '',
          productName: '',
          busiMangeLoginAccout: '',
          purchaseId: '',
          supplierIds: []
        };
        this.getList();
      }
    }
  }
};
exports.default = _default;