"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excelOut = excelOut;
exports.excelOutList = excelOutList;
exports.excelOutMapProWlmsList = excelOutMapProWlmsList;
exports.fetchList = fetchList;
exports.queryList = queryList;
exports.queryMapCity = queryMapCity;
exports.queryMapCityList = queryMapCityList;
exports.queryMapPro = queryMapPro;
exports.queryMapProList = queryMapProList;
exports.queryMapProWlList = queryMapProWlList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 防伪预警查询服务
 * @param {*} query 
 */
function fetchList(data) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/query',
    method: 'post',
    data: data
  });
}

/**
 * 防伪预警导出服务
 * @param {*} query 
 */
function excelOut(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/excelOut',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

/**
 * 防伪预警导出服务
 * @param {*} query 
 */
function excelOutMapProWlmsList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/excelOutMapProWlmsList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

/**
 * 防伪预警详情导出服务
 * @param {*} query 
 */
function excelOutList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/excelOutList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

/**
 * 防伪预警查询详情服务
 * @param {*} id 
 */
function queryList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryList',
    method: 'get',
    params: params
  });
}

/**
 * 防伪预警地图查询服务地图 一级地图---所有省份
 * @param {*} data 
 */
function queryMapPro(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryMapPro',
    method: 'get',
    params: params
  });
}

/**
 * 防伪预警地图查询服务地图 二级地图---省份下面对应的地市
 * @param {*} data 
 */
function queryMapCity(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryMapCity',
    method: 'get',
    params: params
  });
}

/**
 *防伪预警地图查询服务 一级地图省份单击省份展示的列表
 * @param {*} data 
 */
function queryMapProList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryMapProList',
    method: 'get',
    params: params
  });
}

/**
 *防伪预警地图查询服务 一级地图省份单击省份展示列表下拉对应的物料
 * @param {*} data 
 */
function queryMapProWlList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryMapProWlList',
    method: 'get',
    params: params
  });
}

/**
 * 防伪预警地图查询服务二级地图地市列表
 * @param {*} data 
 */
function queryMapCityList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/queryMapCityList',
    method: 'get',
    params: params
  });
}