"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import {
//   getSysSearchDefDate
// } from '@/api/fake_expel/basic'
// import {
//   parseTime
// } from '@/utils'
var _default = {
  data: function data() {
    return {
      value1: '',
      value2: '',
      option1: {},
      option2: {},
      chart1: '',
      activeIndex: '1',
      list: [{
        channelName: '渠道A',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道B',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道C',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道D',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道E',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道F',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }],
      customColor: 'linear-gradient(180deg, #98B6FF 0%, #5F7FFF 100%)'
    };
  },
  activated: function activated() {
    var that = this;
    that.chart1 = _echarts.default.init(this.$refs.static);
    that.loadChart1();
  },
  methods: {
    loadChart1: function loadChart1() {
      var that = this;
      that.chart1 = _echarts.default.init(this.$refs.static);
      // console.log('执行了...')
      var option = {
        backgroundColor: '#fff',
        'title': {
          'text': '生产量统计',
          x: 'center',
          y: '4%',
          textStyle: {
            color: '#252525FF',
            fontSize: '22'
          },
          subtextStyle: {
            color: '#252525FF',
            fontSize: '16'
          },
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(152,182,255,0.2)'
            }
          },
          backgroundColor: '#fff',
          padding: [10, 10],
          extraCssText: 'box-shadow: 2px 2px 2px 0 rgba(163,163,163,0.2)',
          textStyle: {
            color: '#252525FF',
            fontSize: 13
          },
          formatter: function formatter(param) {
            return '<div style="width:150px;">' + '<div class="cell">' + '<div class="cell-primary">' + '<div>' + param[0].name + '</div>' + '<div style="padding:10px 0 0 0">' + param[0].value + '箱/件</div>' + '</div>' + '<div class="cell-ft" style="position:absolute;top:10px;right:20px;">' + '<img style="width:14px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEQAAAADY5vLqAAABQUlEQVQ4EY2Tz0rDQBDG59v8MSkU0YMUbwXvoi/g0ZOvIYh3r9KzeQPBZ/ApPBnx4MVa6KXFi4IipUltd5zE7LYphG5ymPl2vt/OhN0g7Y+vGHRNzC1yfECYkKIeHt9GU2KKHLmlDTRVziBwD+DO0kyxsmJDEvvBxfHB/jmBMmN1hmcUZNJ5AcbcCRYzG+NKhMkbOwt4q/zWroz5YszrsREm8MNRd+dL2jyvQXaaGizdUiK81s2wY1brVltYVmZ+2zvxvOCsDjcrCzMhP+x0JmG49Vm3sx2zWrfawnXATS1hkMfM0L9Z8I+qKiIsNM+zoKjL65utbVL8GOng/Qak98qi1pdPg3GkmU8LrWmRSP1j9Toj7Y9y+Yhyd7OjS5QzyJVcgkSOSLsAxlP6WSXlmQ2HHP2o79gUN8W23p52u8j+AMbTX4Xjv5ogAAAAAElFTkSuQmCC">' + '</div>' + '</div></div>';
          }
        },
        grid: {
          top: '14%',
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#252525FF',
            textStyle: {
              fontSize: 13
            }
          }
        }],
        yAxis: [{
          name: '箱/件',
          nameTextStyle: {
            color: '#252525FF',
            fontSize: 13,
            left: 0
          },
          axisLabel: {
            formatter: '{value}',
            color: '#252525FF'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E5ECF1FF'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#E5ECF1FF'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: [300, 450, 479, 229, 210, 250, 120, 200, 180, 300, 180, 260],
          barWidth: '20px',
          itemStyle: {
            normal: {
              color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(152,182,255,1)' // 0% 处的颜色
              }, {
                offset: 1,
                color: 'rgba(95,127,255,1)' // 100% 处的颜色
              }], false),
              barBorderRadius: [3, 3, 3, 3],
              shadowColor: 'rgba(152,182,255,1)',
              shadowBlur: 2
            }
          },
          label: {
            normal: {
              show: false
            }
          }
        }]
      };
      that.chart1.setOption(option);
      that.chart1.resize();
    },
    handleSelect: function handleSelect() {}
  }
};
exports.default = _default;