"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentInfodelete = contentInfodelete;
exports.contentInfodetail = contentInfodetail;
exports.contentInfoedit = contentInfoedit;
exports.contentInfopage = contentInfopage;
exports.contentInfosave = contentInfosave;
exports.contentScopelist = contentScopelist;
exports.uploadImg = uploadImg;
exports.zcRebatePolicy = zcRebatePolicy;
var _request = _interopRequireDefault(require("@/utils/request"));
function uploadImg(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  });
}

// 发布范围列表
function contentScopelist(params) {
  return (0, _request.default)({
    url: '/api-user/contentScope/list',
    method: 'get',
    params: params
  });
}

// 列表 分页查询
function contentInfopage(params) {
  return (0, _request.default)({
    url: '/api-user/contentInfo/page',
    method: 'get',
    params: params
  });
}

// 获取政策详情
function zcRebatePolicy(params) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/detail',
    method: 'get',
    params: params
  });
}

// 详情
function contentInfodetail(params) {
  return (0, _request.default)({
    url: '/api-user/contentInfo/detail',
    method: 'get',
    params: params
  });
}

// 添加
function contentInfosave(data) {
  return (0, _request.default)({
    url: '/api-user/contentInfo/save',
    method: 'post',
    data: data
  });
}
// 编辑
function contentInfoedit(data) {
  return (0, _request.default)({
    url: '/api-user/contentInfo/edit',
    method: 'post',
    data: data
  });
}

// 删除
function contentInfodelete(params) {
  return (0, _request.default)({
    url: '/api-user/contentInfo/delete',
    method: 'post',
    params: params
  });
}