"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _insert = _interopRequireDefault(require("./components/insert"));
var _allot = _interopRequireDefault(require("./components/allot"));
var _insertbatch = _interopRequireDefault(require("./components/insertbatch"));
var _addInsert = _interopRequireDefault(require("./components/addInsert"));
var _amendInsert = _interopRequireDefault(require("./components/amendInsert"));
var _apartDialog = _interopRequireDefault(require("./components/apartDialog"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _print = _interopRequireDefault(require("./components/print"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _index = require("@/api/warehouse/checkout/index");
var _dictionary = require("@/api/systems/dictionary");
var _factory = require("@/api/basic/factory");
var _record = require("@/api/warehouse/record/record");
var _basic = require("@/api/mark/basic");
var _channel = require("@/api/basic/channel");
var _storage = require("@/api/storage/storage");
var _thirdNC = require("@/api/warehouse/checkout/thirdNC");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var qrcode = '';
var TypeDataComs = {
  djly: [],
  djzt: [],
  djlx: [],
  status: [],
  source: [],
  factory: [],
  classList: [],
  ddyt: []
};
var _default = {
  name: 'TableSuply',
  components: {
    printDialog: _print.default,
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    factorySelectDialog: _factorySelectDialog.default,
    companySelectDialog: _companySelectDialog.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    allot: _allot.default,
    insertbatch: _insertbatch.default,
    apartDialog: _apartDialog.default,
    addInsert: _addInsert.default,
    amendInsert: _amendInsert.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd');
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djztText: function djztText(val) {
      var name = '';
      TypeDataComs.djzt.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlxText: function djlxText(val) {
      var name = '';
      TypeDataComs.djlx.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        101: '',
        102: '',
        103: '',
        104: '',
        105: 'success',
        106: 'warning',
        107: 'danger'
      };
      return obj[val];
    },
    // 客户类型
    khlxText: function khlxText(val) {},
    // 订单用途
    billApplication: function billApplication(val) {
      // var name = "";
      // TypeDataComs.djly.forEach(function(c) {
      //   if (c.figure == val) {
      //     name = c.name;
      //     return;
      //   }
      // });
      // return name;
    },
    // 出库方式
    billOutType: function billOutType(val) {
      // 1:扫码出库 2：生产任务单处理 3：生产批次号出库
      if (val == 1) {
        return '扫码出库';
      } else if (val == 2) {
        return '生产任务单处理';
      } else if (val == 3) {
        return '生产批次号出库';
      }
    }
  },
  data: function data() {
    return {
      typeNum: 1,
      billNo: '',
      NCNO: '',
      inUseName: '',
      searchSendOutComName: '',
      searchSaleOrgName: '',
      searchInOrgName: '',
      productName: '',
      handleShow: false,
      printShow: false,
      factoryShow: false,
      companyShow: false,
      apartShow: false,
      factorycanChange: true,
      companycanChange: true,
      factoryType: '',
      companyType: '',
      orgId: '',
      tableData: [],
      searchForm: {
        billSaleQuery: {
          inOrgId: '',
          inOrgName: '',
          inOrgCode: '',
          outOrgName: '',
          isSplitBill: '',
          outStoreId: '',
          saleOrgId: '',
          relateNo: '',
          outOrgId: '',
          // 新增
          billOutType: '',
          className: '',
          billDate: '',
          accountName: '',
          accountCode: '',
          dealerName: '',
          dealerCode: '',
          outSaleBillType: '',
          displayTransOut: 0 // 默认0不显示
        },

        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '203',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20,
        ncno: ''
      },
      tableKey: 'rn',
      total: 0,
      djly: [],
      djzt: [],
      djlx: [],
      storeList: [],
      classList: [],
      ddyt: [],
      // a123
      codetotal: 0,
      billType: 203,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      SyncVisible: false,
      // 同步
      Syncform: {
        time: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        startRow: 0
      },
      Syncrules: {
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      sloading: false
    };
  },
  computed: {
    formattedDjlx: function formattedDjlx() {
      return function (val) {
        var name = '';
        TypeDataComs.djlx.forEach(function (c) {
          if (c.figure === val) {
            name = c.name;
          }
        });
        return name;
      };
    },
    formattedDjzt: function formattedDjzt() {
      return function (val) {
        var name = '';
        TypeDataComs.djzt.forEach(function (c) {
          if (c.figure === val) {
            name = c.name;
          }
        });
        return name;
      };
    }
  },
  watch: {},
  activated: function activated() {
    var _this = this;
    this.authId('searchbtn', this.$route.meta.authList);
    // 单据来源
    this.getDirSel('DJLY', 'djly');
    // 单据状态
    this.getDirSel('DJZT', 'djzt');
    // 单据类型
    this.getDirSel('XSCKDJLX', 'djlx');
    // 客户类型
    this.getClassAll();
    // 订单用途
    // this.getDirSel('DDYT', 'ddyt')

    this.getStoreList();
    setTimeout(function () {
      _this.getList();
    }, 500);
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.getList();
    this.getStoreList();
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getClassAll: function getClassAll() {
      var that = this;
      (0, _channel.channelClassList)({
        pageNo: 0,
        pageSize: 0,
        channelType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.classList = response.data.data;
          } else {
            that.classList = [];
          }
        }
      }).catch(function (res) {});
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
            if (list == 'djzt') {
              TypeDataComs[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString());
              });
              _this2[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString());
              });
            }
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    /**
     * 工厂选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 1 ：企业, 3：工厂,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType) {
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      this.factoryShow = true;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'sendOutComName':
          this.addData.sendOutComId = val.pId;
          this.addData.sendOutComType = val.companyType == 3 ? 1 : 2;
          break;
        case 'searchSendOutComName':
          this.searchForm.billSaleQuery.outOrgId = val.pId;
          break;
        case 'searchSaleOrgName':
          this.searchForm.billSaleQuery.saleOrgId = val.pId;
          break;
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factoryShow = false;
    },
    handleFilter: function handleFilter(_type) {
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchSendOutComName = '';
      this.searchSaleOrgName = '';
      this.searchInOrgName = '';
      this.productName = '';
      this.searchForm = {
        billSaleQuery: {
          inOrgId: '',
          inOrgName: '',
          inOrgCode: '',
          outOrgName: '',
          isSplitBill: '',
          outStoreId: '',
          saleOrgId: '',
          relateNo: '',
          outOrgId: '',
          // 新增
          billOutType: '',
          className: '',
          billDate: '',
          accountName: '',
          accountCode: '',
          dealerName: '',
          dealerCode: '',
          outSaleBillType: '',
          displayTransOut: 0 // 默认不显示
        },

        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '203',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20,
        ncno: ''
      };
      this.getList();
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} orgId 采购单位ID 获取收货单位使用
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchInOrgName':
          this.searchForm.billSaleQuery.inOrgId = val.companyId;
          break;
        case 'searchSendOutComName':
          this.searchForm.billSaleQuery.outOrgId = val.companyId;
          break;
        case 'searchSaleOrgName':
          this.searchForm.billSaleQuery.saleOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    // 获取产品
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    getList: function getList() {
      var _this3 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryPage)(JSON.parse(JSON.stringify(this.searchForm))).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        if (!this.searchForm.factoryCode) {
          this.sellineList = JSON.parse(JSON.stringify(this.lineList));
          this.selteamList = JSON.parse(JSON.stringify(this.teamList));
        } else {
          this.sellineList = [];
          this.selteamList = [];
        }
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })

      this.sellineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.selteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })
      this.scanlineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.scanteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    // add() {
    //   this.$refs.insertMain.show(1, this.billType)
    // },
    // 以生产任务出库
    doUpdate: function doUpdate() {
      this.$refs.insertMain.show(1, this.billType);

      // this.$refs.insertMain.upDateShow()
    },
    // 以生产批次号
    doUpdate2: function doUpdate2() {
      this.$refs.insertbatch.show(1, this.billType);
      // this.$refs.insertMain.upDateShow()
    },
    // 添加
    addInsertFun: function addInsertFun() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs.addInsert.show(1, _this4.billType, 'add');
      });
    },
    // 修改
    amendInsertFun: function amendInsertFun(row, modalType) {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.$refs.addInsert.upDateShow(row, 'update', modalType);
      });
    },
    goApart: function goApart(row) {
      this.billNo = row.billNo;
      this.apartShow = true;
    },
    apartChange: function apartChange() {
      this.apartClose();
      this.getList();
    },
    apartClose: function apartClose() {
      this.billNo = '';
      this.apartShow = false;
    },
    goPrint: function goPrint(row) {
      this.authId('printbtn', this.$route.meta.authList);
      this.billNo = row.billNo;
      this.NCNO = row.NCNO;
      this.printShow = true;
    },
    printClose: function printClose() {
      this.billNo = '';
      this.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      console.log(row);
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    zdUpdate: function zdUpdate(row) {},
    DoAllot: function DoAllot(row, type) {
      this.$refs.allot.show(row, type);
    },
    stopItem: function stopItem(row) {
      var _this6 = this;
      // this.$refs.addInsert.stopItem(row)
      this.$confirm('当前单据禁用后不可下载，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: function beforeClose(action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            (0, _index.disableTradeBillById)(row.pid).then(function (res) {
              if (res.data.code == 200) {
                _this6.$message.success('操作成功');
                _this6.getList();
              } else {
                _this6.$message.error(res.data.msg);
              }
            }).catch(function () {}).finally(function () {
              setTimeout(function () {
                instance.confirmButtonLoading = false;
              }, 300);
              done();
            });
          } else {
            done();
          }
        }
      }).then(function () {}).catch(function () {});
    },
    zdDelete: function zdDelete(row) {
      var _this7 = this;
      this.$confirm('确认要删除当前单据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: function beforeClose(action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            (0, _index.deleteTradeBillById)(row.pid).then(function (res) {
              if (res.data.code == 200) {
                _this7.$message.success('操作成功');
                _this7.getList();
              } else {
                _this7.$message.error(res.data.msg);
              }
            }).catch(function () {}).finally(function () {
              setTimeout(function () {
                instance.confirmButtonLoading = false;
              }, 300);
              done();
            });
          } else {
            done();
          }
        }
      }).then(function () {}).catch(function () {});
    },
    // 导出功能
    download: function download() {
      var _this8 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var data = JSON.parse(JSON.stringify(this.searchForm));
      delete data.current;
      delete data.size;
      (0, _index.excelOut)(data).then(function (res) {
        _this8.authId('searchbtn', _this8.$route.meta.authList);
        if (res.status != 200) {
          _this8.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '销售出库.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this8.downLoading = false;
      }).catch(function () {
        _this8.authId('searchbtn', _this8.$route.meta.authList);
        _this8.downLoading = false;
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 178,
          height: 178,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 撤销结单
    cancelOr: function cancelOr(row) {
      var _this9 = this;
      this.authId('cxjdbtn', this.$route.meta.authList);
      this.$confirm('已入库的销售出库单，允许撤销结单，撤销结单后，将销售出库单、库存、条码恢复至结单前的状态，请问是否确认撤销结单？。', '是否撤销结单', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _record.revertFinish)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this9.$message.error(data.msg);
            return false;
          }
          _this9.$message({
            message: '操作成功',
            type: 'success'
          });
          _this9.getList();
        });
      }).catch(function () {
        _this9.authId('searchbtn', _this9.$route.meta.authList);
      });
    },
    // 数码导出
    digitalExport: function digitalExport(row) {
      var _this10 = this;
      this.$confirm('数码导出中，成功后可在导出记录中查询下载。', '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _storage.deriveBillInfo)({
          billNo: row.billNo
        }).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this10.$message.error(data.msg);
            return false;
          }
          _this10.$message({
            message: '导出成功',
            type: 'success'
          });
          _this10.getList();
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {});
    },
    // 同步
    SyncSubmit: function SyncSubmit() {
      var _this11 = this;
      this.$refs['Syncform'].validate(function (valid) {
        if (valid) {
          _this11.sloading = true;
          (0, _index.getManual)(_this11.Syncform).then(function (res) {
            if (res.data.code == 200) {
              _this11.$message.success(res.data.msg);
            } else {
              _this11.$message.error(res.data.msg);
            }
            _this11.getList();
            _this11.sloading = false;
            _this11.SyncClose();
          }).catch(function (err) {
            console.log(err);
            _this11.sloading = false;
            _this11.SyncClose;
          });
        } else {
          return false;
        }
      });
    },
    SyncClose: function SyncClose() {
      this.SyncVisible = false;
      this.$refs['Syncform'].resetFields();
    }
  }
};
exports.default = _default;