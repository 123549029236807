"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _factory = require("@/api/basic/factory");
var _pack = require("@/api/basic/pack");
var _store = require("@/api/store");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _UploadImgS = _interopRequireDefault(require("@/components/UploadImgS"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var planNum = '';
var groupList = [];
var _default = {
  name: 'StoreServicestoreManageproductionPlanindex',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    UploadImg: _UploadImgS.default
  },
  filters: {
    groupName: function groupName(status) {
      var t = '';
      groupList.forEach(function (item) {
        if (item.figure == status) {
          t = item.name;
        }
      });
      return t;
    },
    minSum: function minSum(sum, packScaleExpression) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum / arr[0];
      miniUnit = arr[le - 1] * num;
      return arr[le - 1] * num;
    },
    sum: function sum(_sum, packScaleExpression) {
      console.log(_sum, packScaleExpression, '总和');
      if (!_sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      planNum = parseInt(_sum / arr[le - 1]);

      // var num = sum / arr[0]
      return planNum;
    },
    statusText: function statusText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '草稿',
        5: '已创建，待审核',
        7: '已审核，未通过',
        10: '已审核，待下载',
        13: '已下载，执行中',
        16: '执行完成'
      };
      return statusMap[status];
    },
    uploadMode: function uploadMode(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '按托上传',
        1: '整单上传'
      };
      return statusMap[status];
    },
    labelText: function labelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '前关联',
        1: '后关联'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: 'ERP',
        1: '平台',
        2: '产线',
        3: 'PDA'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1].unitName;
      } else if (arr.length == 3) {
        name = arr[2].unitName;
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0].unitName;
      }
      return name;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    // const checkpackNum = (rule, value, callback) => {
    //   console.log(value)
    //   callback()
    // }
    return {
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      listtableLoading: false,
      handleShow: false,
      groupShow: false,
      isAudit: false,
      factoryList: [],
      zhiInfo: {
        itemList: []
      },
      workshopList: [],
      scanworkshopList: [],
      workshopListLoading: false,
      scanworkshopListLoading: false,
      lineList: [],
      scanlineList: [],
      lineListLoading: false,
      scanlineListLoading: false,
      teamList: [],
      orderList: [],
      scanteamList: [],
      teamListLoading: false,
      scanteamListLoading: false,
      dialogBaseWine: false,
      boxList: [],
      storeList: [],
      storeSELList: [],
      codeList: [],
      proList: [],
      searchForm: {
        custCode: null,
        custName: null,
        orderNo: null,
        orderType: null,
        productCode: null,
        productionStatus: null,
        productName: null,
        productSpec: null,
        scheduleNo: null,
        taskNo: null,
        taskStatus: null,
        userId: null,
        startTime: null,
        endTime: null,
        current: 1,
        size: 20
      },
      total: 0,
      codetotal: 0,
      tableData: [],
      tableKey: '01004',
      listLoading: false,
      btnLoading: false,
      codeListLoading: false,
      downLoading: false,
      orderNoloading: false,
      mals: '',
      orderStatus: '',
      title: '生产计划详情',
      multipleSelection: [],
      productTime: '',
      scanData: {
        orderNo: '',
        // uploadMode: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        // baseWineCode: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        checklistUrl: '',
        productTime: ''
      },
      detailData: {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        encryptBachNo: '',
        packScaleLevelItemDTOList: []
      },
      rules: {
        orderNo: [{
          required: true,
          message: '生产计划单号不能为空，请维护',
          trigger: 'change'
        }],
        productTime: [{
          required: true,
          message: '生产日期不能为空，请维护',
          trigger: 'change'
        }],
        factoryCode: [{
          required: true,
          message: '生产工厂不能为空，请维护',
          trigger: 'change'
        }],
        shopCode: [{
          required: true,
          message: '车间名称不能为空，请维护',
          trigger: 'change'
        }],
        productLineCode: [{
          required: true,
          message: '产线不能为空，请维护',
          trigger: 'change'
        }],
        teamCode: [{
          required: true,
          message: '班组不能为空，请维护',
          trigger: 'change'
        }],
        storehouseCode: [{
          required: true,
          message: '所属库房不能为空，请维护',
          trigger: 'change'
        }],
        // uploadMode: [
        //   {
        //     required: true,
        //     message: '发布不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        traychestScaleCode: [{
          required: true,
          message: '每托箱数不能为空，请维护',
          trigger: 'change'
        }],
        materialCode: [{
          required: true,
          trigger: 'blur',
          validator: validatematerialCode
        }
        // {
        //   required: true,
        //   message: '产品明细不能为空，请维护',
        //   trigger: 'change'
        // }
        ],

        planNum: [{
          required: true,
          message: '计划产量不能为空，请维护',
          trigger: 'change'
        }],
        miniUnit: [{
          required: true,
          message: '最小单位不能为空，请维护',
          trigger: 'change'
        }
        // { validator: checkpackNum, trigger: 'blur' }
        ],

        batchNo: [{
          required: true,
          message: '生产批次不能为空，请维护',
          trigger: 'change'
        }, {
          pattern: /^\d{8}$/,
          message: '只能输入纯数字，长度为8'
        }],
        // traychestScaleCode: [
        //   {
        //     required: true,
        //     message: '拖箱比例不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        baseWineName: [{
          required: true,
          message: '请选择基酒类型',
          trigger: 'change'
        }]
      },
      detailForm: {
        current: 1,
        size: 10,
        orderNo: ''
      },
      materialId: '',
      dialogVisible: false,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      groupList: [],
      page2: false,
      tabHeight: '100%',
      submitType: '',
      groupId: '',
      failVisible: false,
      failList: [],
      failQuery: {
        materialId: '',
        orderNo: '',
        current: 1,
        size: 10
      },
      failtotal: 0,
      auditForm: {
        auditReasons: '',
        handlerResult: ''
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      detailtable: [],
      arrangeShow: false,
      shAuth: true,
      traychestScaleCodeList: [],
      dialogChoose: false,
      baseWineLess: {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      },
      baseWineName: '',
      // baseWineLesslist: {
      //   baseWineCode: '',
      //   baseWineName: '',
      //   btnId: '',
      //   current: 1,
      //   isEnable: 0,
      //   size: 1000
      // },
      tableChoose: [],
      totalBaseWineLess: 0,
      multipleList: [],
      flag: false,
      tableChooselist: [],
      channelSelectType: '',
      pickerOptions: {
        disableDate: function disableDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {
    var that = this;
    var auths = (0, _utils.getAuthBtn)();
    var id = 'scjhsh';
    if (auths.length > 0) {
      for (var i = 0; i < auths.length; i++) {
        if (auths[i].resourceId == id) {
          that.shAuth = true;
          break;
        }
      }
    } else {
      that.shAuth = false;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var _this2 = this;
    var that = this;
    this.authId('searchbtn', this.$route.meta.authList);
    (0, _dictionary.getItemByDataDictionaryByCode)({
      code: 'XSDLX_CRM'
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          _this2.orderList = res.data.data;
          if (_this2.orderList) {
            _this2.orderList.forEach(function (el) {
              _this2.$set(el, 'newName', el.bewrite + '[' + el.name + ']');
            });
          }
        } else {
          _this2.orderList = [];
        }
      }
    });
    this.getList();
    this.getFactoryList(1, '', 'factoryList');
    // this.getFactoryList(2, '', 'workshopList')
    this.getFactoryList(3, '', 'lineList');
    // this.getFactoryList(4, '', 'teamList')
    // this.getFactoryList(2, '', 'scanworkshopList')
    // this.getFactoryList(3, '', 'scanlineList')
    // this.getFactoryList(4, '', 'scanteamList')
    this.getStoreList();
    this.getBoxList();
    this.getBaseWineList();
    // this.baseWineList()
  },

  methods: {
    changeData: function changeData(flag) {
      if (flag) {
        if (this.searchForm.endTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.startTime = null;
            this.$message.error('开始时间不能大于结束时间！');
          }
        }
      } else {
        if (this.searchForm.startTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.endTime = null;
            this.$message.error('结束时间不能小于开始时间！');
          }
        }
      }
    },
    // handleSuccess(data) {
    //   this.scanData.poductMaterial = data
    // },
    // handleRemove() {
    //   this.scanData.poductMaterial = ''
    // },
    checkGroup: function checkGroup(id) {
      this.groupId = id;
      this.groupShow = true;
    },
    /**
     *
     * 获取工厂、车间、产线、班组
     * @param {*} val 1:工厂,2:车间,3:产线,4:班组
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList(val, pId, list) {
      var _this3 = this;
      this[list + 'Loading'] = true;
      (0, _factory.factoryInfoAllList)({
        type: val,
        isEnable: 1,
        parentId: pId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3[list] = res.data.data;
          } else {
            _this3[list] = [];
          }
        }
        _this3[list + 'Loading'] = false;
      }).catch(function () {
        _this3[list + 'Loading'] = false;
      });
    },
    getBoxList: function getBoxList(productCode, type) {
      var _this4 = this;
      var that = this;
      (0, _pack.supportBoxLevelList)({
        current: 1,
        productCode: productCode,
        size: 2147483647
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null && response.data.data.records.length > 0) {
            that.boxList = response.data.data.records;
            if (that.boxList.length === 1 && !type) {
              _this4.scanData.traychestScaleCode = _this4.boxList[0].pid;
            } else if (!type) {
              _this4.scanData.traychestScaleCode = '';
            }
          } else {
            _this4.scanData.traychestScaleCode = '';
            that.boxList = [];
          }
        }
      });
    },
    getStoreList: function getStoreList(val) {
      var _this5 = this;
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
            _this5.storeSELList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      console.log('重置');
      var that = this;
      that.searchForm = {
        custCode: null,
        custName: null,
        orderNo: null,
        orderType: null,
        productCode: null,
        productionStatus: null,
        productName: null,
        productSpec: null,
        scheduleNo: null,
        taskNo: null,
        taskStatus: null,
        userId: null,
        startTime: null,
        endTime: null,
        current: 1,
        size: 20
      };
      // that.searchForm.current = 1
      // that.searchForm.size = 20
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this6 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择创建结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择创建起始时间');
        return;
      }
      that.listtableLoading = true;
      (0, _store.getorder)(that.searchForm).then(function (response) {
        that.listtableLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records || [];
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this6.listLoading = false;
      });
      that.listLoading = false;
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = '';
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.lineList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
        this.getFactoryList(4, val, 'teamList');
      }
    },
    setScanFactory: function setScanFactory(val) {
      this.scanData.shopCode = '';
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      this.scanData.storehouseCode = '';
      this.scanlineList = [];
      this.scanteamList = [];
      this.scanData.traychestScaleCode = '';
      this.proList = [];
      if (!val) {
        this.scanworkshopList = [];
        // this.storeSELList = []
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        // this.storeSELList = this.storeList.filter(function(number) {
        //   return number.factoryId == val
        // })
      }
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        this.getFactoryList(4, val, 'scanteamList');
      }
    },
    add: function add() {
      var _this7 = this;
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '添加生产计划单';
      this.multipleList = [];
      this.flag = false;
      this.submitType = 1;
      this.getFactoryList(1, '', 'factoryList');
      // this.getStoreList()
      // this.getBoxList()
      this.getCode('storage_product_order_no');
      this.getCode('storage_product_batch_no');
      this.$nextTick(function () {
        _this7.dialogVisible = true;
      });
    },
    openSel: function openSel() {
      if (this.scanData.factoryCode) {
        if (this.proList) {
          this.proList = [];
          this.scanData.planNum = '';
          this.scanData.miniUnit = '';
          this.scanData.materialCode = '';
          this.scanData.traychestScaleCode = '';
        }
        this.$refs.selProduct.searchForm.factoryId = this.scanData.factoryCode;
        this.$refs.selProduct.getList();
        this.$refs.selProduct.proSelVisible = true;
      } else {
        this.$message.error('请选择生产工厂');
      }
    },
    setSel: function setSel(val) {
      console.log(val, '选择产品');
      this.scanData.materialCode = val.pId;
      this.scanData.planNum = '';
      this.proList = [val];
      this.getBoxList(val.productCode);
    },
    // 拖箱比例 列表
    // traychestScaleCodeFun() {
    // },
    setSum: function setSum(e) {
      this.scanData = JSON.parse(JSON.stringify(this.scanData));
      this.scanData.planNum = this.valENnum(e);
    },
    setSum2: function setSum2(e) {
      this.scanData = JSON.parse(JSON.stringify(this.scanData));
      this.scanData.miniUnit = this.valENnum(e);
    },
    proDel: function proDel() {
      this.proList = [];
      this.scanData.planNum = '';
      this.scanData.miniUnit = '';
      this.scanData.materialCode = '';
      this.scanData.traychestScaleCode = '';
    },
    getCode: function getCode(type) {
      var _this8 = this;
      (0, _store.generateOrderSequence)({
        bizType: type
      }).then(function (res) {
        if (res.data.code != 200) {
          _this8.$message.error(res.data.msg);
        } else {
          if (type == 'storage_product_order_no') {
            _this8.scanData.orderNo = res.data.data;
          }
          // if (type == 'storage_product_batch_no') {
          //   this.scanData.batchNo = res.data.data
          // }
        }
      });
    },
    submitForm: function submitForm(temp) {
      var _this9 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          // this.scanData.planNum = planNum
          // this.scanData.baseWineCode = this.baseWineCode
          if (_this9.scanData.planNum == 0) {
            _this9.$message.error('最大单位计划产量不能为0');
            return;
          }
          // if (this.scanData.miniUnit == 0) {
          //   this.$message.error('最小单位计划产量不能为0')
          //   return
          // }
          _this9.btnLoading = true;
          _this9.scanData.temp = temp;
          // this.scanData.miniUnit = miniUnit
          var params = JSON.parse(JSON.stringify(_this9.scanData));
          // debugger
          // params.baseWineCode = this.baseWineCode
          if (_this9.submitType == 2) {
            if (_this9.orderStatus == 7) {
              (0, _store.reviewNoPassupdate)(params).then(function (res) {
                if (res.data.code != 200) {
                  _this9.$message.error(res.data.msg);
                } else {
                  _this9.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this9.getList();
                  _this9.dialogClose();
                }
                _this9.btnLoading = false;
              }).catch(function () {
                _this9.btnLoading = false;
              });
            } else {
              (0, _store.getOrderupdate)(params).then(function (res) {
                if (res.data.code != 200) {
                  _this9.$message.error(res.data.msg);
                } else {
                  _this9.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this9.getList();
                  _this9.dialogClose();
                }
                _this9.btnLoading = false;
              }).catch(function () {
                _this9.btnLoading = false;
              });
            }
          } else {
            (0, _store.getOrdersave)(params).then(function (res) {
              if (res.data.code != 200) {
                _this9.$message.error(res.data.msg);
              } else {
                _this9.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this9.getList();
                _this9.dialogClose();
              }
              _this9.btnLoading = false;
            }).catch(function () {
              _this9.btnLoading = false;
            });
          }
        } else {
          _this9.$message.error('请完善信息！');
        }
      });
    },
    dialogClose: function dialogClose() {
      this.orderStatus = '';
      this.proList = [];
      this.scanworkshopList = [];
      this.scanlineList = [];
      this.scanteamList = [];
      this.$refs.scanForm.resetFields();
      this.scanData = {
        orderNo: '',
        // uploadMode: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        // baseWineCode: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        checklistUrl: '',
        productTime: ''
      };
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    handleClose: function handleClose() {
      this.detailData = {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        packScaleLevelItemDTOList: []
      };
      this.$refs.scanData.resetFields();
      this.isAudit = false;
      this.detailVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    scanDetail: function scanDetail(row) {
      var _this10 = this;
      console.log(row, 'row');
      this.authId('detailbtn', this.$route.meta.authList);
      this.scanData = JSON.parse(JSON.stringify(row));
      (0, _store.policyDetail)({
        policyCode: row.policyCode
      }).then(function (res) {
        if (res.data.code == 200) {
          _this10.zhiInfo = res.data.data;
          _this10.detailVisible = true;
        } else {
          _this10.$message.error(res.data.msg);
        }
      });
      this.detailVisible = true;
    },
    DoAudit: function DoAudit(row) {
      var _this11 = this;
      this.authId('scjhsh', this.$route.meta.authList);
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this11.detailData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          console.log(_this11.detailData);
          _this11.isAudit = true;
          _this11.detailVisible = true;
        } else {
          _this11.$message.error(res.data.msg);
        }
      });
    },
    doUpdate: function doUpdate(row) {
      var _this12 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      this.submitType = 2;
      this.dialogVisible = true;
      this.mloading = true;
      this.title = '编辑生产计划单';
      this.getFactoryList(1, '', 'factoryList');
      // this.getBoxList()
      this.storeSELList = [];
      (0, _store.getOrderdetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code != 200) {
          _this12.$message.error(res.data.msg);
          return;
        } else {
          var data = res.data.data;
          _this12.scanData.pid = data.pId;
          _this12.scanData.orderNo = data.orderNo;
          _this12.scanData.factoryCode = data.factoryId;
          _this12.scanData.shopCode = data.shopId;
          _this12.scanData.productLineCode = data.productLineId;
          _this12.scanData.teamCode = data.teamId;
          _this12.scanData.storehouseCode = data.storehouseId;
          _this12.scanData.traychestScaleCode = data.traychestScaleId;
          _this12.scanData.planNum = data.planNum;
          _this12.scanData.miniUnit = data.miniUnit;
          _this12.scanData.batchNo = data.batchNo;
          _this12.scanData.labelType = data.labelType;
          _this12.scanData.remarks = data.remarks;
          _this12.scanData.materialCode = data.materialId;
          _this12.scanData.originPlace = data.originPlace;
          _this12.scanData.director = data.director;
          _this12.scanData.checklistUrl = row.checklistUrl;
          _this12.scanData.productTime = data.productTime.slice(0, 10);
          // this.scanData.baseWineCode = data.baseWineName
          // this.baseWineCode = data.baseWineId
          // this.scanData.uploadMode = data.uploadMode
          _this12.orderStatus = row.orderStatus;
          // this.scanFactoryList = this.storeList.filter(
          //   a => a.pId == data.storehouseId
          // )
          _this12.getFactoryList(2, data.factoryId, 'scanworkshopList');
          _this12.getFactoryList(3, data.shopId, 'scanlineList');
          _this12.getFactoryList(4, data.shopId, 'scanteamList');
          _this12.storeSELList = _this12.storeList.filter(function (number) {
            return number.factoryId == data.factoryId;
          });
          _this12.proList = [{
            productClassName: data.productClassName,
            productName: data.materialName,
            packScaleExpression: data.packScale,
            planNum: data.planNum,
            packScaleDesc: data.packScaleDesc,
            productCode: data.materialCode,
            productModel: data.productModel
          }];
          _this12.getBoxList(data.materialCode, 'bj');
        }
        _this12.mloading = false;
      }).catch(function () {
        _this12.mloading = false;
      });
    },
    codeDetail: function codeDetail(orderNo, materialId) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.orderNo = orderNo;
      this.materialId = materialId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this13 = this;
      // this.detailForm.orderNo = this.detailData.orderNo
      (0, _store.pageParentMarkCode)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this13.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this13.codeList = res.data.data.records;
            _this13.codetotal = parseInt(res.data.data.total);
          } else {
            _this13.codeList = [];
            _this13.codetotal = 0;
          }
        }
        _this13.codeListLoading = false;
      }).catch(function () {
        _this13.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this14 = this;
      (0, _store.subMarkCode)({
        markCode: tree.markCode,
        materialId: this.materialId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this14.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    codeClose: function codeClose() {
      this.detailForm = {
        current: 1,
        size: 10,
        orderNo: ''
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    showFailList: function showFailList() {
      this.failVisible = true;
      this.codeListLoading = true;
      this.failQuery.orderNo = this.detailData.orderNo;
      this.failQuery.materialId = this.detailData.materialId;
      this.getFailList();
    },
    getFailList: function getFailList() {
      var _this15 = this;
      (0, _store.backFailMarkCodeList)(this.failQuery).then(function (res) {
        if (res.data.code != 200) {
          _this15.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this15.failList = res.data.data.records;
            _this15.failtotal = parseInt(res.data.data.total);
            _this15.listLoading = false;
          } else {
            _this15.failList = [];
            _this15.failtotal = 0;
          }
        }
        _this15.codeListLoading = false;
      }).catch(function () {
        _this15.codeListLoading = false;
      });
    },
    failClose: function failClose() {
      this.failVisible = false;
      this.failQuery = {
        orderNo: '',
        current: 1,
        size: 10
      };
      this.failList = [];
      this.failtotal = 0;
    },
    del: function del(orderNo) {
      var _this16 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this16.authId('deletebtn', _this16.$route.meta.authList);
        (0, _store.orderdelete)({
          orderNo: orderNo
        }).then(function (res) {
          _this16.authId('searchbtn', _this16.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this16.$message.error(data.msg);
            return false;
          }
          _this16.$message({
            message: '删除成功',
            type: 'success'
          });
          _this16.getList();
        });
      }).catch(function () {
        _this16.authId('searchbtn', _this16.$route.meta.authList);
      });
    },
    // 导出功能
    download: function download() {
      var _this17 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _store.exportorderExcel)(this.searchForm).then(function (res) {
        _this17.authId('searchbtn', _this17.$route.meta.authList);
        if (res.status != 200) {
          _this17.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产任务单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this17.downLoading = false;
      }).catch(function () {
        _this17.authId('searchbtn', _this17.$route.meta.authList);
        _this17.downLoading = false;
      });
    },
    auditReset: function auditReset() {
      this.$refs.auditForm.resetFields();
      this.auditForm = {
        auditReasons: '',
        handlerResult: ''
      };
    },
    audit: function audit() {
      var _this18 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this18.btnLoading = true;
          if (_this18.auditForm.handlerResult) {
            (0, _store.reviewPass)({
              orderNo: _this18.detailData.orderNo,
              auditReasons: _this18.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this18.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this18.auditReset();
                _this18.handleClose();
                _this18.getList();
              } else {
                _this18.$message.error(res.data.msg);
              }
              _this18.btnLoading = false;
            }).catch(function () {
              _this18.btnLoading = false;
            });
          } else {
            (0, _store.reviewNoPass)({
              orderNo: _this18.detailData.orderNo,
              auditReasons: _this18.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this18.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this18.auditReset();
                _this18.handleClose();
                _this18.getList();
              } else {
                _this18.$message.error(res.data.msg);
              }
              _this18.btnLoading = false;
            }).catch(function () {
              _this18.btnLoading = false;
            });
          }
        } else {
          _this18.$message.error('请完善信息！');
        }
      });
    },
    // 获取基酒列表
    getBaseWineList: function getBaseWineList() {
      var _this19 = this;
      (0, _store.baseWineList)(this.baseWineLess).then(function (res) {
        if (res.data.code !== 200) {
          return _this19.$message.error('获取列表失败！');
        } else {
          _this19.totalBaseWineLess = parseInt(res.data.data.total);
          _this19.tableChoose = res.data.data.records;
          _this19.tableChooselist = res.data.data.records;
        }
      });
    },
    // baseWineList() {
    //   baseWineList(this.baseWineLesslist).then(res => {
    //     if (res.data.code !== 200) {
    //       return this.$message.error('获取列表失败！')
    //     } else {
    //       this.totalBaseWineLess = parseInt(res.data.data.total)
    //       this.tableChooselist = res.data.data.records
    //     }
    //   })
    // },
    // 基酒选择弹框
    baseWineShow: function baseWineShow() {
      var _this20 = this;
      // this.getBaseWineList()
      this.dialogChoose = true;
      this.$nextTick(function () {
        if (_this20.multipleList.length) {
          _this20.$refs.multipleTable.toggleRowSelection(_this20.multipleList[0], true);
        } else {
          _this20.$refs.multipleTable.clearSelection();
        }
      });
    },
    queryList: function queryList() {
      this.baseWineLess.current = 1;
      this.getBaseWineList();
    },
    restQueryList: function restQueryList() {
      this.baseWineLess = {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      };
      this.tableChoose = [];
      this.getBaseWineList();
    },
    handleSaveTemplateType: function handleSaveTemplateType(data) {
      this.dialogChoose = false;
      this.scanData.baseWineCode = this.multipleList[0].baseWineName;
      this.baseWineCode = this.multipleList[0].id;
      this.flag = true;
    },
    resetFormChoose: function resetFormChoose() {
      if (!this.flag) {
        this.multipleList = [];
      }

      // this.scanData.baseWineCode = ''
      // this.$nextTick(() => {
      //   this.$refs.multipleTable.clearSelection()
      // })
      this.dialogChoose = false;
    },
    select: function select(selection, row) {
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      this.multipleList = selection;
      // this.baseWineLess.baseWineName = this.multipleList[0].baseWineName
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    openBaseWine: function openBaseWine() {
      this.dialogBaseWine = true;
    },
    submit: function submit(row) {
      console.log(row);
      this.baseWineName = row.baseWineName;
      this.searchForm.baseWineCode = row.id;
      this.dialogBaseWine = false;
    },
    resetBaseWine: function resetBaseWine() {
      this.dialogBaseWine = false;
    },
    // 新增撤销
    revocation: function revocation(res) {
      var _this21 = this;
      this.$confirm('撤销审核，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _store.postRevoke)({
          orderNo: res.orderNo
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code != 200) {
            _this21.$message.error(res.data.msg);
            return false;
          }
          _this21.$message({
            message: '撤销成功',
            type: 'success'
          });
          _this21.getList();
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {});
    },
    // 暂停
    stopFun: function stopFun(id) {
      var _this22 = this;
      this.$confirm('确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(22222222);
        // this.authId('deletebtn', this.$route.meta.authList)
        SupplDisable({
          pId: id
        }).then(function (res) {
          // this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this22.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this22.getList();
          } else {
            _this22.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this22.authId('searchbtn', _this22.$route.meta.authList);
      });
    },
    // 启用
    startFun: function startFun(id) {
      var _this23 = this;
      this.$confirm('确认启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this23.authId('deletebtn', _this23.$route.meta.authList);
        SupplEnable({
          pId: id
        }).then(function (res) {
          _this23.authId('searchbtn', _this23.$route.meta.authList);
          if (res.data.code == 200) {
            _this23.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this23.getList();
          } else {
            _this23.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this23.authId('searchbtn', _this23.$route.meta.authList);
      });
    },
    doArrange: function doArrange(row) {
      this.$refs.insertMain.show();
    }
  }
};
exports.default = _default;