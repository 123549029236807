"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _LineChart = _interopRequireDefault(require("@/components/dataAnalyse/LineChart"));
var _overview = require("@/api/dataAnalyse/overview");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getSalesCountGroupByDate } from '@/api/dataAnalyse/fwfc'
var _default = {
  components: {
    BarChart: _LineChart.default,
    proDialog: _productSelectDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      list: [],
      downLoading: false,
      listLoading: false,
      dialogVisible: false,
      searchBtn: false,
      sweepBtn: false,
      count: 0,
      total: 0,
      dateValue: [],
      type: '1',
      formInline: {
        endTime: '',
        startTime: '',
        productId: '',
        productName: '',
        type: ''
      },
      proList: [],
      proData: {
        productName: '',
        productId: '',
        newPsid: '',
        page: 1,
        limit: 10
      },
      rules: {}
    };
  },
  created: function created() {
    // this.monthFun()
    // this.annualFun()
    // this.dateFun('1')
  },
  activated: function activated() {
    this.monthFun();
  },
  methods: {
    getDealerTendencyFun: function getDealerTendencyFun() {
      var _this2 = this;
      var formInline = JSON.parse(JSON.stringify(this.formInline));
      delete formInline.productName;
      (0, _overview.dealerTendency)((0, _objectSpread2.default)({}, formInline)).then(function (res) {
        if (res.data.code === 200) {
          _this2.list = res.data.data;
          var xData = [];
          var sData = [];
          res.data.data.forEach(function (item) {
            xData.push(item.time);
            sData.push(item.sellNum);
          });
          _this2.$refs.lineChart.xData = xData;
          _this2.$refs.lineChart.sData = [{
            name: '销量',
            type: 'line',
            data: sData,
            symbol: 'circle',
            smooth: true,
            symbolSize: 0,
            lineStyle: {
              width: 3,
              color: '#FFC52A'
            },
            itemStyle: {
              color: '#FFC52A'
            },
            areaStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 1,
                    color: 'rgba(255,255,255,0)' // 0% 处的颜色
                  }, {
                    offset: 0,
                    color: 'rgba(255,197,42,0.5)' // 100% 处的颜色
                  }],

                  global: false // 缺省为 false
                }
              }
            }
          }];

          _this2.$refs.lineChart.initChart();
        }
      });
    },
    // 本月
    monthFun: function monthFun(page) {
      this.formInline.type = '1';
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getDealerTendencyFun();
    },
    // 本年
    annualFun: function annualFun(page) {
      this.formInline.type = '2';
      this.dateValue = [];
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getDealerTendencyFun();
    },
    // 时间选择
    dateFun: function dateFun(val, page) {
      if (val) {
        this.formInline.type = '3';
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
        this.getDealerTendencyFun();
      } else {
        this.monthFun();
      }
    },
    // 产品选择
    inputFocusFun: function inputFocusFun() {
      this.$refs.selProduct.proSelVisible = true;
    },
    // 产品选择
    setCurrent: function setCurrent(row) {
      this.formInline.productName = row.productName;
      this.formInline.productId = row.pid;
      this.dialogVisible = false;
      this.getDealerTendencyFun();
    },
    clearFun: function clearFun() {
      if (this.formInline.productId) {
        this.formInline.productId = '';
        this.formInline.productName = '';
        this.getDealerTendencyFun();
      }
    },
    downLoad: function downLoad() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outoTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '销售数据.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;