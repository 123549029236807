var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardBox" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticStyle: { margin: "10 auto" },
                attrs: {
                  rules: _vm.rules,
                  model: _vm.temp,
                  "label-position": "right",
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-row",
                  { staticStyle: { margin: "0 20px" }, attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "公众号名：",
                              prop: "officialName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "4-30字符汉字即可",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.officialName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "officialName", $$v)
                                },
                                expression: "temp.officialName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "公众号原始ID：",
                              prop: "officialId",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "50字符以内，请认真填写，错了不能修改",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.officialId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "officialId", $$v)
                                },
                                expression: "temp.officialId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "微信号：", prop: "wechatName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "50字符以内",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.wechatName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "wechatName", $$v)
                                },
                                expression: "temp.wechatName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "APPid(公众号)：", prop: "appid" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "50字符以内",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.appid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "appid", $$v)
                                },
                                expression: "temp.appid",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "APPSecret(公众号)：",
                              prop: "appsecret",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                "show-password": "",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.appsecret,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "appsecret", $$v)
                                },
                                expression: "temp.appsecret",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "公众号二维码：",
                              prop: "imageUrl",
                            },
                          },
                          [
                            _c("Upload-img", {
                              ref: "imageUrl",
                              attrs: {
                                data: _vm.temp.imageUrl,
                                disabled: _vm.disabled,
                                text: "只能上传png/jpg格式图片",
                              },
                              on: {
                                "update:data": function ($event) {
                                  return _vm.$set(_vm.temp, "imageUrl", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticStyle: { margin: "0 20px" }, attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "支付方式：",
                              prop: "payWay",
                              disabled: _vm.disabled,
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "radio",
                                model: {
                                  value: _vm.temp.payWay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "payWay", $$v)
                                  },
                                  expression: "temp.payWay",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("商户号支付"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("不开启"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.temp.payWay !== 0,
                                expression: "temp.payWay!==0",
                              },
                            ],
                            attrs: {
                              label: "商户号：",
                              prop: "mchId",
                              rules: {
                                required: _vm.temp.payWay === 1,
                                message: "请填写商户号",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                maxlength: "50",
                                "show-word-limit": "",
                                placeholder: "50字符以内",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.mchId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "mchId", $$v)
                                },
                                expression: "temp.mchId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.temp.payWay !== 0,
                                expression: "temp.payWay!==0",
                              },
                            ],
                            attrs: {
                              rules: {
                                required: _vm.temp.payWay === 1,
                                message: "请填写商户平台支付秘钥",
                                trigger: "change",
                              },
                              label: "商户平台支付秘钥：",
                              prop: "paykey",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                maxlength: "50",
                                "show-word-limit": "",
                                placeholder: "50字符以内",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: _vm.temp.paykey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "paykey", $$v)
                                },
                                expression: "temp.paykey",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.temp.payWay !== 0,
                                expression: "temp.payWay!==0",
                              },
                            ],
                            attrs: {
                              rules: {
                                required: _vm.temp.payWay === 1,
                                message: "请上传商户平台证书",
                                trigger: "change",
                              },
                              label: "商户平台证书：",
                              prop: "certPath",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-demo",
                                attrs: {
                                  headers: _vm.myHeaders,
                                  action: _vm.uploadUrl,
                                  "on-success": _vm.handleAvatarSuccess,
                                  "file-list": _vm.fileList,
                                  name: "file",
                                  limit: 1,
                                },
                              },
                              [
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _vm._v("点击上传"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "explainWrap" },
              [
                _c(
                  "el-col",
                  { staticClass: "content", staticStyle: { width: "50px" } },
                  [_vm._v("说明：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-col", { staticClass: "content" }, [
                      _vm._v(
                        "1、如您选择“商户号支付”，请先在 商户平台关联此公众号；"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { staticClass: "content" }, [
                      _vm._v("2、如果您不需要发红包，请选择“不开启”。"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-col"),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        !_vm.disabled
          ? _c(
              "el-button",
              {
                attrs: { icon: "", type: "primary" },
                on: {
                  click: function ($event) {
                    String(_vm.temp.id) ? _vm.updateData() : _vm.createData()
                  },
                },
              },
              [_vm._v("保存")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-button",
          { staticClass: "normalBtn", on: { click: _vm.cancel } },
          [_vm._v("取消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }