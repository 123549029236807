var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.visible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formData",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.formData,
                "label-width": "130px",
                "label-suffix": ":",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "procurement-wrap",
                  attrs: { label: "供货(上游)单位", prop: "supplierIds" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "aBtn",
                      attrs: { type: "primary" },
                      on: { click: _vm.openCheck },
                    },
                    [_vm._v("添加")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "supplier-wrap" }, [
                    _vm.suppliers.length > 0
                      ? _c(
                          "div",
                          { staticClass: "supplier-item-wrap" },
                          _vm._l(
                            _vm.suppliers,
                            function (suppliersItem, index) {
                              return _c(
                                "div",
                                {
                                  key: suppliersItem.pId,
                                  staticClass: "supplier-item",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "supplier-item-top" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "supplier-name" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/images/icon11.png"),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(suppliersItem.channelName)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "supplier-item-delete el-icon el-icon-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSupplie(
                                              suppliersItem.pId
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "supplier-item-bottom" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "producte-wrap" },
                                        _vm._l(
                                          suppliersItem.products,
                                          function (item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.pId,
                                                staticClass: "item",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.productName)
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon el-icon-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteProducteItem(
                                                        index,
                                                        item.pId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "add-product-btn",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openProduct(
                                                index,
                                                suppliersItem
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  添加产品\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "procurement-wrap",
                  staticStyle: { "border-bottom": "none" },
                  attrs: { label: "采购(下游)单位", prop: "purchaseId" },
                },
                [
                  _c("div", { staticClass: "flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "procurement-name-wrap" },
                      _vm._l(_vm.purchases, function (item) {
                        return _c(
                          "div",
                          { key: item.pId, staticClass: "item" },
                          [
                            _c("span", [_vm._v(_vm._s(item.channelName))]),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePurchaseItem(item.pId)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              disabled: _vm.suppliers.length > 1,
                            },
                            on: { click: _vm.openPurchase },
                          },
                          [_vm._v("\n              添加\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("supplierDialog", {
        ref: "channelCheckbox",
        attrs: {
          "selected-data": _vm.supplierArr,
          amount: _vm.purchases.length,
          "purchases-type": _vm.purchasesType,
        },
        on: { change: _vm.setSupplier },
      }),
      _vm._v(" "),
      _vm.purchaseVisible
        ? _c("purchaseDialog", {
            ref: "channelSelect",
            attrs: {
              visible: _vm.purchaseVisible,
              "selected-data": _vm.purchases,
              type: _vm.supplierType,
              amount: _vm.supplierArr.length,
            },
            on: {
              close: function ($event) {
                _vm.purchaseVisible = false
              },
              change: _vm.setPurchase,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productVisible
        ? _c("productDialog", {
            ref: "selProduct",
            attrs: {
              visible: _vm.productVisible,
              products: _vm.products,
              "channel-id": _vm.channelId,
            },
            on: { close: _vm.closeProduct, change: _vm.setProduct },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }