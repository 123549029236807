"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/web.dom.iterable");
var _zdscan = require("@/api/chanelmange/zdscan");
var _product = require("@/api/basic/product");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangestockscanzdkcindex';
var _default = {
  name: 'Chanelmangestockscanzdkcindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    instockUnit: _channelSelectDialog.default,
    productChose: _productSelectDialog.default,
    purchaseDialog: _purchaseDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        channelId: JSON.parse(localStorage.getItem('currentUser')).channelId,
        isAsc: '',
        isDisplayZeroExistnum: 0,
        orderbyColName: '',
        orgId: null,
        pageNo: 1,
        pageSize: 20,
        productId: '',
        productTypeId: null
      },
      productName: '',
      handleShow: false,
      productShow: false,
      orgOptions: [],
      activeVal: 1,
      inactiveVal: 0,
      channelStr: '',
      tableKey: 'jxskc',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      search: {
        billStatus: '',
        billType: '',
        channelId: '',
        isCode: '',
        outgoType: '',
        recDateStart: '',
        recDateEnd: '',
        sendDateStart: '',
        sendDateEnd: '',
        recOrgId: '',
        sendOrgId: '',
        pageNo: 1,
        pageSize: 10
      },
      list2: [],
      total2: 0,
      billStats: [{
        name: '已提交',
        code: 1
      }, {
        name: '全部出库',
        code: 4
      }, {
        name: '已收货',
        code: 5
      }],
      billStats1: [{
        name: '已提交',
        code: 1
      }, {
        name: '全部入库',
        code: 4
      }],
      billTypes: [{
        name: '退货入库',
        code: 103
      }, {
        name: '采购入库',
        code: 104
      },
      // {
      //   name: '其他入库',
      //   code: 105
      // },
      {
        name: '销售出库',
        code: 203
      }, {
        name: '退货出库',
        code: 204
      }, {
        name: '其他出库',
        code: 205
      }],
      outgoTypes: [{
        name: '入库',
        code: 1
      }, {
        name: '出库',
        code: 2
      }],
      isCodes: [{
        name: '是',
        code: 1
      }, {
        name: '否',
        code: 0
      }],
      scanVisible: false,
      scanData: {},
      sum1: '',
      sum2: '',
      sum3: '',
      classList: [],
      unitShow: false,
      unitType: '',
      fhStr: '',
      shStr: '',
      exportLoading: false,
      listLoading2: false,
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      page2: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', that.apis);
    if (!hasCache && auth) {
      that.getOrg();
      that.getClass();
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _zdscan.getKcList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.pages.records;
          that.total = parseInt(res.data.data.pages.total);
          that.sum1 = res.data.data.existingSumTotalTrans;
          that.sum2 = res.data.data.availableSumTotalTrans;
          that.sum3 = res.data.data.beingSumTotalTrans;
        } else {
          that.$message.error(res.data.msg);
        }
        that.listLoading = false;
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _zdscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
      if (that.listQuery.productTypeId == '') {
        that.listQuery.productTypeId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        channelId: JSON.parse(localStorage.getItem('currentUser')).channelId,
        isAsc: '',
        isDisplayZeroExistnum: 0,
        orderbyColName: '',
        orgId: null,
        pageNo: 1,
        pageSize: 20,
        productId: '',
        productTypeId: null
      };
      that.channelStr = '';
      that.productName = '';
      that.productName = '';
      that.getList();
    },
    // 获取产品分类
    getClass: function getClass() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.productClassName;
              obj.pid = item.pid;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.classList = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getChannel: function getChannel(val) {
      this.authId('searchbtn', this.apis);
      this.unitType = val;
      this.$refs.dwSelect.proSelVisible = true;
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.unitType = val;
      this.$refs.channelSelect.proSelVisible = true;
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'search') {
        that.listQuery.channelId = val.pId;
        that.channelStr = val.channelName;
      }
      if (that.unitType == 'recOrgId') {
        that.search.recOrgId = val.pId;
        that.shStr = val.channelName;
      }
      if (that.unitType == 'sendOrgId') {
        that.search.sendOrgId = val.pId;
        that.fhStr = val.channelName;
      }
      that.unitShow = false;
    },
    // 获取产品
    getProductId: function getProductId() {
      this.authId('searchbtn', this.apis);
      this.$refs.selProduct.proSelVisible = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      that.listQuery.productId = val.pId;
      that.productName = val.productName;
      that.productShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = {};
      param.channelId = that.listQuery.channelId;
      param.isAsc = that.listQuery.isAsc;
      param.isDisplayZeroExistnum = that.listQuery.isDisplayZeroExistnum;
      param.orderbyColName = that.listQuery.orderbyColName;
      param.orgId = that.listQuery.orgId;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.total;
      param.productId = that.listQuery.productId;
      param.productTypeId = that.listQuery.productTypeId;
      that.exportLoading = true;
      (0, _zdscan.exportKcList)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '终端库存列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.authId('detailbtn', that.apis);
      that.scanData = (0, _utils.deepClone)(data);
      this.search = {
        // billStatus: '',
        billType: '',
        channelId: data.channelId,
        // isCode: '',
        outgoType: 1,
        // recDateStart: '',
        // recDateEnd: '',
        // sendDateStart: '',
        // sendDateEnd: '',
        statementTimeEnd: '',
        statementTimeStart: '',
        recOrgId: '',
        sendOrgId: '',
        pageNo: 1,
        pageSize: 10
      };
      that.searchList();
      that.scanVisible = true;
    },
    searchList: function searchList() {
      var that = this;
      that.listLoading2 = true;
      var param = (0, _utils.deepClone)(that.search);
      param.productCode = that.scanData.productCode;
      (0, _zdscan.getKcMxList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list2 = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading2 = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading2 = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading2 = false;
      });
    },
    resetList: function resetList(formName) {
      var that = this;
      this.fhStr = '';
      this.shStr = '';
      that.search = Object.assign(this.search, {
        // billStatus: '',
        billType: '',
        // channelId: '',
        // isCode: '',
        outgoType: 1,
        // recDateStart: '',
        // recDateEnd: '',
        // sendDateStart: '',
        // sendDateEnd: '',
        statementTimeEnd: '',
        statementTimeStart: '',
        recOrgId: '',
        sendOrgId: '',
        pageNo: 1,
        pageSize: 10
      });
      that.searchList();
    },
    getList2: function getList2() {
      var that = this;
      that.searchList();
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;