"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excelOut = excelOut;
exports.excelOutList = excelOutList;
exports.excelOutMapChan = excelOutMapChan;
exports.excelOutMapJxsChan = excelOutMapJxsChan;
exports.excelOutMapWlChan = excelOutMapWlChan;
exports.excelQueryMapChanList = excelQueryMapChanList;
exports.excelQueryMapJxsChanList = excelQueryMapJxsChanList;
exports.excelQueryMapWlChanList = excelQueryMapWlChanList;
exports.fetchList = fetchList;
exports.getChanArea = getChanArea;
exports.getOneLveDealer = getOneLveDealer;
exports.queryList = queryList;
exports.queryMapChan = queryMapChan;
exports.queryMapChanList = queryMapChanList;
exports.queryMapJxsChan = queryMapJxsChan;
exports.queryMapJxsChanList = queryMapJxsChanList;
exports.queryMapProOrCity = queryMapProOrCity;
exports.queryMapWlChan = queryMapWlChan;
exports.queryMapWlChanList = queryMapWlChanList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 防窜预警列表查询服务
 * @param {*} query 
 */
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/query',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警列表详情
 * @param {*} query 
 */
function queryList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryList',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图疑似窜货统计
 * @param {*} query 
 */
function queryMapChan(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapChan',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图一级经销商疑似窜货统计
 * @param {*} query 
 */
function queryMapJxsChan(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapJxsChan',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图查询
 * @param {*} query 
 */
function queryMapProOrCity(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapProOrCity',
    method: 'post',
    data: params
  });
}
/**
 * 防窜预警地图物料疑似窜货统计
 * @param {*} query 
 */
function queryMapWlChan(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapWlChan',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图疑似窜货统计清单
 */
function queryMapChanList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapChanList',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图一级经销商疑似窜货统计清单
 */
function queryMapJxsChanList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapJxsChanList',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警地图物料疑似窜货统计清单
 */
function queryMapWlChanList(params) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/queryMapWlChanList',
    method: 'post',
    data: params
  });
}

/**
 * 防窜预警列表数据导出
 * @param {*} query 
 */
function excelOut(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelOut',
    method: 'post',
    data: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警列表详情导出
 * @param {*} query 
 */
function excelOutList(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelOutList',
    method: 'post',
    data: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图疑似窜货统计导出
 * @param {*} query 
 */
function excelOutMapChan(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelOutMapChan',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图疑似窜货统计导出
 * @param {*} query 
 */
function excelOutMapJxsChan(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelOutMapJxsChan',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图物料疑似窜货统计导出
 * @param {*} query 
 */
function excelOutMapWlChan(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelOutMapWlChan',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图疑似窜货统计清单导出
 * @param {*} query 
 */
function excelQueryMapChanList(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelQueryMapChanList',
    method: 'post',
    data: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图一级经销商疑似窜货统计清单导出
 * @param {*} query 
 */
function excelQueryMapJxsChanList(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelQueryMapJxsChanList',
    method: 'post',
    data: query,
    responseType: 'blob'
  });
}

/**
 * 防窜预警地图物料疑似窜货统计清单导出
 * @param {*} query 
 */
function excelQueryMapWlChanList(query) {
  return (0, _request.default)({
    url: '/api-prevent/antichan/excelQueryMapWlChanList',
    method: 'post',
    data: query,
    responseType: 'blob'
  });
}

/**
 * 疑似窜货区域下拉
 */
function getChanArea() {
  return (0, _request.default)({
    url: '/api-prevent/dealerAndChanArea/getChanArea',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 一级经销商下拉
 */
function getOneLveDealer() {
  return (0, _request.default)({
    url: '/api-prevent/dealerAndChanArea/getOneLveDealer',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}