var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "search-condition",
            attrs: {
              model: _vm.searchForm,
              "label-suffix": ":",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "el-row",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { gutter: 20 },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入生产单号",
                          },
                          model: {
                            value: _vm.searchForm.orderNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "orderNo", $$v)
                            },
                            expression: "searchForm.orderNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "批次编号" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "", placeholder: "请输入批次号" },
                          model: {
                            value: _vm.searchForm.batchNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "batchNo", $$v)
                            },
                            expression: "searchForm.batchNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产组织" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择企业车间",
                            },
                            model: {
                              value: _vm.searchForm.shopCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "shopCode", $$v)
                              },
                              expression: "searchForm.shopCode",
                            },
                          },
                          _vm._l(_vm.WorkshopList, function (item) {
                            return _c("el-option", {
                              key: item.pId,
                              attrs: {
                                label: item.factoryName,
                                value: item.pId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.handleShow
                  ? _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请选择产品",
                                readonly: true,
                                clearable: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.inputFocusFun(1)
                                },
                              },
                              model: {
                                value: _vm.searchForm.materialName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "materialName", $$v)
                                },
                                expression: "searchForm.materialName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.handleShow
                  ? _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "生产日期",
                              prop: "prodStartTime",
                              rules: _vm.dateRule,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "-",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions,
                              },
                              on: { change: _vm.dateFun },
                              model: {
                                value: _vm.dateVal,
                                callback: function ($$v) {
                                  _vm.dateVal = $$v
                                },
                                expression: "dateVal",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.handleShow
                  ? _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择订单状态",
                                },
                                model: {
                                  value: _vm.searchForm.orderStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "orderStatus", $$v)
                                  },
                                  expression: "searchForm.orderStatus",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-col",
                  { staticClass: "head-btn-group", attrs: { span: 6 } },
                  [
                    _c("el-form-item", { attrs: { "label-width": "0" } }, [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                                loading: _vm.searchBtn,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchFun(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.btnConfig.searchBtn.text) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                                loading: _vm.sweepBtn,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchFun(0)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.btnConfig.resetBtn.text) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.handleShow = !_vm.handleShow
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.handleShow ? "收起" : "展开") +
                                  "\n                "
                              ),
                              _c("i", {
                                class: _vm.handleShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "summarizing-wrap" },
          [
            _c(
              "div",
              { staticClass: "summarizing-title" },
              [
                _c("h5", [_vm._v("数据汇总")]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.downLoadBtn.icon,
                      loading: _vm.downLoading,
                    },
                    on: { click: _vm.downLoad },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                staticClass: "grid-wrap",
                attrs: {
                  model: _vm.searchForm,
                  "label-suffix": ":",
                  "label-position": "right",
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "生产单号数" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.orderNoCount,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.orderNoCount)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "未生产数" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.unproducedCount,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.unproducedCount)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "已生产数" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.producedCount,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(" " + _vm._s(_vm.summarizing.producedCount)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产中" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.producingCount,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.producingCount)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产数量最大单品" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.materialName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.materialName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "计划量（盒）" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.planNumMiniUnit,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.planNumMiniUnit)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "实际生产（盒）" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.backBottleSum,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.backBottleSum)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "单日最大生产日期" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm._f("textDateOnlyDate")(
                            _vm.summarizing.productDateTime
                          ),
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("textDateOnlyDate")(
                                _vm.summarizing.productDateTime
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "单日最大生产量" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.productCount,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.productCount)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产数量最多车间" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.summarizing.shopName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "tooltip-wrap" }, [
                          _vm._v(_vm._s(_vm.summarizing.shopName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            attrs: {
              "element-loading-text": "加载中...",
              data: _vm.list,
              border: "",
              accordion: "",
              "row-key": "makeId",
              width: "100%",
              "highlight-current-row": "",
              "header-cell-style": {
                "background-color": "#fafafa",
                color: "rgba(0, 0, 0,0.85)",
                "font-weight": "500",
                "text-align": "center",
              },
              "cell-style": { "text-align": "center" },
              "row-style": {
                color: "rgba(0, 0, 0,0.65)",
                "font-weight": "400",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "orderNo",
                label: "生产单号",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "shopName",
                label: "生产组织",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "batchNo",
                label: "批次号",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "materialName",
                label: "商品名称",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "productModel",
                label: "商品规格",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "planNum",
                label: "计划数量箱",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "miniUnit",
                label: "计划数量盒",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "backChestSum",
                label: "实际生产量箱",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "backBottleSum",
                label: "实际生产量盒",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createDate",
                label: "订单状态",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.orderStatus == 10
                        ? _c("span", [_vm._v("未生产")])
                        : scope.row.orderStatus == 13
                        ? _c("span", [_vm._v("生产中")])
                        : scope.row.orderStatus == 16
                        ? _c("span", [_vm._v("已生产")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "storehouseName",
                label: "入库仓库",
                "show-overflow-tooltip": "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total>0",
            },
          ],
          attrs: {
            total: _vm.total,
            "auto-scroll": false,
            page: _vm.searchForm.current,
            limit: _vm.searchForm.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.searchForm, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.searchForm, "size", $event)
            },
            pagination: _vm.pagingFun,
          },
        }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogVisible,
              width: "1200px",
              title: "生产详情",
              top: "10vh",
              "custom-class": "dialog-wrap",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "dialog-content" }, [
              _c("p", { staticClass: "title" }, [_vm._v("生产信息")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-wrap" },
                _vm._l(_vm.scKeyData, function (item, key) {
                  return _c("li", { key: key }, [
                    _c("span", [_vm._v(_vm._s(item + ":"))]),
                    _vm._v(
                      "\n            " + _vm._s(_vm.row[key]) + "\n          "
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog-content" }, [
              _c("p", { staticClass: "title" }, [_vm._v("产品信息")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-wrap" },
                _vm._l(_vm.cpKeyData, function (item, key) {
                  return _c("li", { key: key }, [
                    _c("span", [_vm._v(_vm._s(item + ":"))]),
                    _vm._v(
                      "\n            " + _vm._s(_vm.row[key]) + "\n          "
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog-content" }, [
              _c("p", { staticClass: "title" }, [_vm._v("库房信息")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-wrap" },
                _vm._l(_vm.kfKeyData, function (item, key) {
                  return _c("li", { key: key }, [
                    _c("span", [_vm._v(_vm._s(item + ":"))]),
                    _vm._v(
                      "\n            " + _vm._s(_vm.row[key]) + "\n          "
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("pro-dialog", { ref: "selProduct", on: { change: _vm.proDataFun } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }