"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _org = require("@/api/basic/org");
var _outstock = require("@/api/chanelmange/outstock");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Tips',
  components: {
    Pagination: _Pagination.default,
    purchaseDialog: _purchaseDialog.default,
    purchaseDialo: _purchaseDialog.default
  },
  props: {
    aId: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    oldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: '',
      listLoading: true,
      visible: false,
      selLoading: false,
      total: 0,
      buyer: '',
      seller: '',
      dateVal: [],
      listQuery: {
        pageNo: 1,
        businessType: 'Q226,Q225,Q200,Q201',
        outEndDate: '',
        outStartDate: '',
        pageSize: 10,
        billNo: '',
        buyerId: '',
        sellerId: '',
        status: '4,5'
      },
      list: [],
      listCopy: [],
      list1: [],
      list1Copy: this.oldList,
      upList: [],
      downList: [],
      multipleSelection: []
    };
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.oldList.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.fetchData();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.selLoading = false;
      }, 500);
    },
    // mounted() {
    //   this.fetchData();
    // },
    handleSelectionChange: function handleSelectionChange(val) {
      this.radio = this.list.indexOf(val);
      this.multipleSelection = [val];
    },
    clear: function clear() {
      this.radio = '';
      this.multipleSelection = [];
      // this.$refs.multipleTable.clearSelection()
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      (0, _outstock.outstock)((0, _objectSpread2.default)({}, this.listQuery)).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          console.log(res, 'res');
          _this2.total = Number(res.data.total);
          _this2.list = res.data.records;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.list = [];
      this.visible = false;
      this.$emit('close');
    },
    update: function update() {
      this.$emit('fn', this.multipleSelection);
      this.visible = false;
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    restFun: function restFun() {
      this.buyer = '';
      this.seller = '';
      this.listQuery = {
        pageNo: 1,
        outEndDate: '',
        outStartDate: '',
        pageSize: 10,
        billNo: '',
        buyerId: '',
        sellerId: '',
        status: '4,5'
      };
      this.fetchData();
    },
    dateFun: function dateFun(val) {
      if (val) {
        this.listQuery.outStartDate = val[0];
        this.listQuery.outEndDate = val[1];
      } else {
        this.listQuery.outStartDate = '';
        this.listQuery.outEndDate = '';
      }
    },
    openSel: function openSel() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    openSell: function openSell() {
      this.$refs.channelSelectt.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.listQuery.sellerId = val.pId;
      this.seller = val.channelName;
    },
    setSell: function setSell(val) {
      this.listQuery.buyerId = val.pId;
      this.buyer = val.channelName;
    }
  }
};
exports.default = _default2;