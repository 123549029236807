"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//
// const animationDuration = 6000
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    tipName: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    titleName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      chart: null,
      xData: [],
      sData: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        color: ['#1890ff', '#5ec45f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: this.tipName
        },
        grid: {
          top: 30,
          left: 30,
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.xData,
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value',
          name: this.titleName
        }],
        series: this.sData
        //   {
        //     name: this.tipName,
        //     type: 'line',
        //     data: this.sData,
        //     animationDuration
        //   }
        // ]
      });
    }
  }
};
exports.default = _default2;