"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _saleExport = require("@/api/warehouse/saleExport/saleExport");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "warehouseServicerecordsaleExport",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      ruleForm: {
        billNo: "",
        status: "",
        pageSize: 10,
        pageNo: 1
      },
      total: 0,
      listLoading: false,
      tabHeight: "100%",
      dialogVisible: false,
      password: ""
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      console.log("that.ruleForm", that.ruleForm);
      (0, _saleExport.billDeriveRecord)(that.ruleForm).then(function (response) {
        console.log(response.data.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.getList();
    },
    look: function look(row) {
      this.password = row.zipPassword;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.password = "";
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;