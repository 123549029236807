var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "picker-options": _vm.pickerOptions2,
      format: "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      align: "right",
    },
    model: {
      value: _vm.chosedate,
      callback: function ($$v) {
        _vm.chosedate = $$v
      },
      expression: "chosedate",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }