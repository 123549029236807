"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'vue_admin_template_token';

/**
 *
 *
 * @export
 * @returns
 */
function getToken() {
  return _jsCookie.default.get(TokenKey);
}

/**
 *
 *
 * @export
 * @param {*} token
 * @returns
 */
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

/**
 *
 *
 * @export
 * @returns
 */
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}