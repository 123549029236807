var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "searchForm",
      staticClass: "search-condition",
      attrs: {
        model: _vm.searchForm,
        "status-icon": "",
        "label-width": "100px",
      },
    },
    [
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "billNo", label: "单号:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          placeholder: "请输入",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.searchForm.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "billNo", $$v)
                          },
                          expression: "searchForm.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "relateNo", label: "关联出库单号:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.searchForm.billInTransQuery.relateNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm.billInTransQuery,
                              "relateNo",
                              $$v
                            )
                          },
                          expression: "searchForm.billInTransQuery.relateNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("label", { staticClass: "form-label" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "outOrgName", label: "发货单位:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请选择",
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                            },
                            on: {
                              clear: _vm.clearUnitOut,
                              focus: function ($event) {
                                return _vm.getInUnit("out")
                              },
                            },
                            model: {
                              value: _vm.outOrgName,
                              callback: function ($$v) {
                                _vm.outOrgName = $$v
                              },
                              expression: "outOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("label", { staticClass: "form-label" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "inOrgName", label: "收货单位:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请选择",
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                            },
                            on: {
                              clear: _vm.clearUnitIn,
                              focus: function ($event) {
                                return _vm.getInUnit("in")
                              },
                            },
                            model: {
                              value: _vm.inOrgName,
                              callback: function ($$v) {
                                _vm.inOrgName = $$v
                              },
                              expression: "inOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "inStoreIdtore", label: "收货库房:" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { focus: _vm.getDownHouses },
                              model: {
                                value:
                                  _vm.searchForm.billInTransQuery.inStoreId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchForm.billInTransQuery,
                                    "inStoreId",
                                    $$v
                                  )
                                },
                                expression:
                                  "searchForm.billInTransQuery.inStoreId",
                              },
                            },
                            _vm._l(_vm.TypeDataComs.StoreList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.storeHouseName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "productName", label: "产品名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            on: {
                              focus: _vm.openProductWin,
                              clear: _vm.clearProduct,
                            },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName = $$v
                              },
                              expression: "productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "billStatus", label: "单据状态:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setFactory },
                              model: {
                                value: _vm.searchForm.billStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "billStatus", $$v)
                                },
                                expression: "searchForm.billStatus",
                              },
                            },
                            _vm._l(
                              _vm.TypeDataComs.statusList,
                              function (item) {
                                return _c("el-option", {
                                  key: item.figure,
                                  attrs: {
                                    label: item.name,
                                    value: item.figure,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "billSource", label: "单据来源:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setFactory },
                              model: {
                                value: _vm.searchForm.billSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "billSource", $$v)
                                },
                                expression: "searchForm.billSource",
                              },
                            },
                            _vm._l(
                              _vm.TypeDataComs.sourceList,
                              function (item) {
                                return _c("el-option", {
                                  key: item.figure,
                                  attrs: {
                                    label: item.name,
                                    value: item.figure,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dateValue", label: "创建时间:" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.dateValue,
                              callback: function ($$v) {
                                _vm.dateValue = $$v
                              },
                              expression: "dateValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilter("search")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-search" }),
                          _vm._v(" 查询\n\t\t\t\t\t\t"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilter("rest")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-refresh" }),
                          _vm._v(" 重置\n\t\t\t\t\t\t"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { type: "text" }, on: { click: _vm.expand } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" + _vm._s(_vm.expandTxt) + " "
                          ),
                          _c("i", {
                            class:
                              _vm.showCol == false
                                ? "el-icon-arrow-down"
                                : "el-icon-arrow-up",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          inOutType: _vm.inOutType,
          shouFaType: _vm.shouFaType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }