"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _chanelrole = require("@/api/systems/chanelrole");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _scan = _interopRequireDefault(require("./components/scan"));
var _auth = _interopRequireDefault(require("./components/auth"));
var _scanAuth = _interopRequireDefault(require("./components/scanAuth"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemrolesmangechanelroleindex';
var _default = {
  name: 'Systemrolesmangechanelroleindex',
  components: {
    Pagination: _Pagination.default,
    scan: _scan.default,
    auth: _auth.default,
    scanAuth: _scanAuth.default,
    ChannelSelectDialog: _channelSelectDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        roleName: '',
        roleTypeDetail: '',
        dealerId: '',
        dealerName: '',
        clientCode: '',
        pageNo: 1,
        pageSize: 20
      },
      addData: {
        roleName: '',
        clientCodes: [],
        dealerName: '',
        dealerCode: '',
        dealerId: '',
        roleTypeDetail: '',
        remark: '',
        isEnable: true
      },
      editData: {
        roleName: '',
        clientCodes: [],
        dealerName: '',
        dealerCode: '',
        dealerId: '',
        roleTypeDetail: '',
        remark: '',
        isEnable: true
      },
      qdParam: {
        keyword: '',
        current: 1,
        size: 20
      },
      qdOptions: [],
      sysOptions: [],
      list: [],
      qdData: [],
      tableKey: 'compyrole',
      total: 0,
      total2: 0,
      listLoading: false,
      channelSelect: false,
      tableLoading: false,
      loading: false,
      loading2: false,
      multipleSelection: [],
      dstate: false,
      dstate2: false,
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      qdVisible: false,
      dialogVisible: false,
      dialogVisible2: false,
      argument: '',
      rules: {
        roleName: [{
          required: true,
          validator: _utils.checkRequiredWord,
          message: '请输入角色名称',
          trigger: 'blur'
        }],
        clientCodes: [{
          required: true,
          message: '请选择系统',
          trigger: 'change'
        }],
        roleTypeDetail: [{
          required: true,
          message: '请选择渠道类型',
          trigger: 'change'
        }],
        dealerName: [{
          required: false,
          message: '请选择渠道',
          trigger: 'blur'
        }]
      },
      apis: [],
      addShow: false,
      scanData: {},
      scanShow: false,
      authData: {},
      authShow: false,
      scanAuthData: [],
      scanAuthShow: false,
      scanRoleName: '',
      authName: '',
      currentRow: '',
      showCol: false,
      expandTxt: '展开',
      parentIds: [],
      unitShow: false,
      showQd: false,
      qdType: null
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getType();
      that.getSysData();
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = {};
      param.roleName = that.listQuery.roleName;
      param.roleTypeDetail = that.listQuery.roleTypeDetail;
      param.dealerId = that.listQuery.dealerId;
      param.clientCode = that.listQuery.clientCode;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      (0, _chanelrole.getRoleList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.list.forEach(function (item) {
            item.roleTypeName = that.turnName(item.roleTypeDetail);
          });
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    turnName: function turnName(val) {
      var that = this;
      var data = that.qdOptions;
      var name = '';
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].code == val) {
            name = data[i].name;
            break;
          }
        }
      }
      return name;
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.roleName = '';
      that.listQuery.clientCode = '';
      that.listQuery.pageNo = 1;
      that.listQuery.dealerId = '';
      that.listQuery.dealerName = '';
      that.listQuery.roleTypeDetail = '';
      that.getList();
    },
    // 获取系统数据
    getSysData: function getSysData() {
      var that = this;
      var param = {};
      param.type = 1;
      (0, _chanelrole.getSystem)(param).then(function (res) {
        if (res.data.code == 200) {
          that.sysOptions = res.data.data;
        } else {
          that.sysOptions = [];
        }
      });
    },
    // 获取渠道类型
    getType: function getType() {
      var that = this;
      (0, _chanelrole.getQdType)().then(function (res) {
        if (res.data.code == 200) {
          that.qdOptions = res.data.data;
        } else {
          that.qdOptions = [];
        }
      });
    },
    // 单项选择
    getQdData: function getQdData(param) {
      var _this = this;
      var that = this;
      that.argument = param;
      if (param == 'search') {
        that.qdType = null;
      }
      this.channelSelect = true;
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    close: function close() {
      this.channelSelect = false;
    },
    makeSure: function makeSure(data) {
      var that = this;
      var type = that.argument;
      var val = data;
      if (type == 'search') {
        if (val != null) {
          that.listQuery.dealerId = val.pId;
          that.listQuery.dealerName = val.channelName;
          if (val.channelType == 1) {
            that.listQuery.roleTypeDetail = 'JXS';
          }
          if (val.channelType == 2) {
            that.listQuery.roleTypeDetail = 'ZD';
          }
        }
      }
      if (type == 'add') {
        if (val != null) {
          that.addData.dealerId = val.pId;
          that.addData.dealerCode = val.channelCode;
          that.addData.dealerName = val.channelName;
          that.$refs.addChanel.focus();
          that.$refs.addChanel.blur();
        }
      }
      if (type == 'edit') {
        if (val != null) {
          that.editData.dealerId = val.pId;
          that.editData.dealerCode = val.channelCode;
          that.editData.dealerName = val.channelName;
          that.$refs.editChanel.focus();
          that.$refs.editChanel.blur();
        }
      }
    },
    clearqd: function clearqd() {
      var that = this;
      that.listQuery.dealerId = '';
      that.listQuery.dealerName = '';
    },
    hideDialog: function hideDialog() {
      var that = this;
      that.channelSelect = false;
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    getCurType: function getCurType(e) {
      var that = this;
      that.showQd = true;
      that.addData.dealerId = '';
      that.addData.dealerCode = '';
      that.addData.dealerName = '';
      if (e == 'JXS') {
        that.qdType = 1;
      }
      if (e == 'ZD') {
        that.qdType = 2;
      }
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      var param = {};
      param.roleId = data.pid;
      that.editData.roleTypeDetail = data.roleTypeDetail;
      if (that.editData.roleTypeDetail == 'JXS') {
        that.qdType = 1;
      }
      if (that.editData.roleTypeDetail == 'ZD') {
        that.qdType = 2;
      }
      (0, _chanelrole.getRoleDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData == null || resData == '') {
            that.$message.error('未获取详情，无法修改');
            return;
          }
          var clients = [];
          that.editData.pid = resData.pid;
          that.editData.dealerId = resData.dealerId;
          that.editData.dealerName = resData.dealerName;
          that.editData.dealerCode = resData.dealerCode;
          that.editData.roleName = resData.roleName;
          that.editData.remark = resData.remark;
          if (resData.isEnable == 1) {
            that.editData.isEnable = true;
          } else {
            that.editData.isEnable = false;
          }
          if (resData.clients.length > 0) {
            resData.clients.forEach(function (item) {
              clients.push(item.clientCode);
            });
            that.editData.clientCodes = clients;
          } else {
            that.editData.clientCodes = [];
          }
        } else {
          that.$message.error(res.data.msg + ',未获取详情，无法修改');
          that.editData.clientCodes = [];
          return;
        }
        that.dialogVisible2 = true;
      }).catch(function () {
        that.editData.clientCodes = [];
        that.dialogVisible2 = false;
      });
    },
    getCurType2: function getCurType2(e) {
      var that = this;
      that.editData.dealerId = '';
      that.editData.dealerCode = '';
      that.editData.dealerName = '';
      if (e == 'JXS') {
        that.qdType = 1;
      }
      if (e == 'ZD') {
        that.qdType = 2;
      }
    },
    // 选择启动项
    handleSelectionChange: function handleSelectionChange(val) {
      var that = this;
      that.multipleSelection = val;
    },
    handleClose: function handleClose() {
      var that = this;
      that.showQd = false;
      this.qdType = null;
      that.resetForm('addForm');
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var param = {};
          param.roleName = that.addData.roleName;
          param.clientCodes = that.addData.clientCodes.join(',');
          param.remark = that.addData.remark;
          param.dealerId = that.addData.dealerId;
          param.roleTypeDetail = that.addData.roleTypeDetail;
          if (that.addData.isEnable == true) {
            param.isEnable = 1;
          } else {
            param.isEnable = 0;
          }
          (0, _chanelrole.addRole)(param).then(function (res) {
            if (res.data.code == 200) {
              that.loading = false;
              that.dsate = false;
              that.$message({
                type: 'success',
                message: '添加成功'
              });
              that.getList();
              that.dialogVisible = false;
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
              return;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    },
    handleClose2: function handleClose2() {
      var that = this;
      this.qdType = null;
      that.resetForm2('editForm');
    },
    submitForm2: function submitForm2(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading2 = true;
          that.dsate2 = true;
          var param = {};
          param.roleName = that.editData.roleName;
          param.clientCodes = that.editData.clientCodes.join(',');
          param.remark = that.editData.remark;
          param.dealerId = that.editData.dealerId;
          param.roleTypeDetail = that.editData.roleTypeDetail;
          param.pId = that.editData.pid;
          if (that.editData.isEnable == true) {
            param.isEnable = 1;
          } else {
            param.isEnable = 0;
          }
          (0, _chanelrole.editRole)(param).then(function (res) {
            if (res.data.code == 200) {
              that.loading2 = false;
              that.dsate2 = false;
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.getList();
              that.dialogVisible2 = false;
            } else {
              that.$message.error(res.data.msg);
              that.loading2 = false;
              that.dsate2 = false;
              return;
            }
          }).catch(function () {
            that.loading2 = false;
            that.dsate2 = false;
          });
        }
      });
    },
    resetForm2: function resetForm2(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible2 = false;
    },
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      that.scanData = (0, _utils.deepClone)(data);
      that.$nextTick(function () {
        that.scanShow = true;
      });
    },
    getRoleAuth: function getRoleAuth(data) {
      var that = this;
      var setHeaderId = that.authId('authassign', that.apis);
      var resData = [];
      var param = {};
      that.parentIds = [];
      that.authData.roleName = data.roleName;
      that.authData.roleCode = data.roleCode;
      param.roleCode = data.roleCode;
      (0, _chanelrole.getRoleAuths)(param).then(function (res) {
        if (res.data.code == 200) {
          var allData = res.data.data;
          if (allData.length > 0) {
            allData.forEach(function (item) {
              var obj = {};
              obj.pid = item.parentCode;
              obj.id = item.resourceCode;
              obj.resourceName = item.resourceName;
              resData.push(obj);
            });
          }
        } else {
          resData = [];
        }
        return resData;
      }).then(function (res) {
        var fromData = res;
        (0, _chanelrole.scanRoleAuth)(param).then(function (response) {
          if (response.data.code == 200) {
            var rData = response.data.data;
            var current = [];
            var checkedArr = [];
            that.authData.originData = rData;
            if (rData.length > 0) {
              rData.forEach(function (item) {
                var obj = {};
                obj.pid = item.parentCode;
                obj.id = item.resourceCode;
                obj.resourceName = item.resourceName;
                current.push(obj);
                checkedArr.push(item.resourceCode);
              });
              var toData = (0, _utils.transTreeData)(current, 'id', 'pid', 'children');
              that.calleArr(toData);
              var parentIds = that.parentIds;
              for (var i = 0; i < parentIds.length; i++) {
                for (var j = 0; j < checkedArr.length; j++) {
                  if (parentIds[i] == checkedArr[j]) {
                    checkedArr.splice(j, 1);
                  }
                }
              }
              that.authData.defaultCheckedKeys = checkedArr;
              that.authData.toData = [];
              that.authData.fromData = (0, _utils.transTreeData)(fromData, 'id', 'pid', 'children');
            } else {
              that.authData.defaultCheckedKeys = [];
              that.authData.toData = [];
              that.authData.fromData = (0, _utils.transTreeData)(fromData, 'id', 'pid', 'children');
            }
          }
          that.authShow = true;
        }).catch(function () {
          that.authData.toData = [];
          that.authData.defaultCheckedKeys = [];
          if (fromData.length > 0) {
            that.authData.fromData = (0, _utils.transTreeData)(data, 'id', 'pid', 'children');
          } else {
            that.authData.fromData = [];
          }
          that.authShow = true;
        });
      }).catch(function () {
        that.authData.toData = [];
        that.authData.fromData = [];
        that.authData.defaultCheckedKeys = [];
        that.authShow = true;
      });
    },
    hideAuth: function hideAuth() {
      var that = this;
      that.authShow = false;
    },
    // 数据递归
    calleArr: function calleArr(arr) {
      var that = this;
      for (var i in arr) {
        var data = arr[i];
        if (data.children) {
          that.parentIds.push(data.id);
          that.calleArr(data.children);
        }
      }
    },
    // 查看渠道角色权限
    scanRoleAuth: function scanRoleAuth(data) {
      var that = this;
      var setHeaderId = that.authId('scanauth', that.apis);
      that.scanRoleName = data.roleName;
      var param = {};
      param.roleCode = data.roleCode;
      (0, _chanelrole.scanRoleAuth)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.length > 0) {
            that.scanAuthData = (0, _utils.transTreeData)(res.data.data, 'resourceCode', 'parentCode', 'children');
          } else {
            that.scanAuthData = [];
          }
        } else {
          that.scanAuthData = [];
        }
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      }).catch(function () {
        that.scanAuthData = [];
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      var param = {};
      param.roleId = data.pid;
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _chanelrole.stopRole)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      var param = {};
      param.roleId = data.pid;
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _chanelrole.startRole)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    // 批量启用
    getStarts: function getStarts() {
      var that = this;
      var setHeaderId = that.authId('allstart', that.apis);
      var sdata = that.multipleSelection;
      if (sdata.length > 0) {
        var param = [];
        sdata.forEach(function (item) {
          param.push(item.pid);
        });
        var subData = {};
        subData.roleIds = param.join(',');
        that.$confirm('是否启用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _chanelrole.startItems)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '启用成功'
              });
              that.listQuery.current = 1;
              that.getList();
            } else {
              that.$message.error(res.data.msg);
              return;
            }
          });
        }).catch(function () {
          console.log('已取消');
        });
      } else {
        that.$message.error('请选择要启动的项');
      }
    },
    // 批量禁用
    getStops: function getStops() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var sdata = that.multipleSelection;
      if (sdata.length > 0) {
        var param = [];
        sdata.forEach(function (item) {
          param.push(item.pid);
        });
        var subData = {};
        subData.roleIds = param.join(',');
        that.$confirm('是否禁用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _chanelrole.stopItems)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '禁用成功'
              });
              that.listQuery.current = 1;
              that.getList();
            } else {
              that.$message.error(res.data.msg);
              return;
            }
          });
        }).catch(function () {
          console.log('已取消');
        });
      } else {
        that.$message.error('请选择要禁用的项');
      }
    },
    searchanel: function searchanel() {
      var that = this;
      that.qdParam.current = 1;
      that.getChanelData();
    },
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;