"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-mark/clientUpload/queryForPage',
    method: 'get',
    params: params
  });
}