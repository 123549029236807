"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorQueryingNumberList = require("@/api/operationalTools/errorQueryingNumberList");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolserrorQueryingNumberListindex';
var _default = {
  name: 'MarketingManageoperationalToolserrorQueryingNumberListindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 10,
      current: 1,
      size: 10,
      query: {
        markCode: '',
        status: ''
      },
      strategyIds: [],
      listLoading: false,
      tableData: [],
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.query.status = '';
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getQueryForPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 列表查询
    getQueryForPage: function getQueryForPage() {
      var that = this;
      that.listLoading = true;
      (0, _errorQueryingNumberList.getQueryForPage)(this.current, this.size, this.query).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = res.data.data.total;
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    // 单个解除封禁
    singleEnable: function singleEnable(markCode) {
      var _this = this;
      (0, _errorQueryingNumberList.singleEnable)(markCode).then(function (res) {
        if (res.data.data.errCode == 500) {
          return _this.$message.error(res.data.data.errMsg);
        }
        _this.$message.success('解除封禁成功');
        _this.getQueryForPage();
      });
    },
    // 批量解除封禁
    batchEnable: function batchEnable(all) {
      var _this2 = this;
      (0, _errorQueryingNumberList.batchEnable)(all).then(function (res) {
        if (res.data.data.errCode == 500) {
          return _this2.$message.error(res.data.data.errMsg);
        }
        _this2.$message.success('解除封禁成功');
        _this2.getQueryForPage();
      });
    },
    // 搜索
    search: function search() {
      this.current = 1;
      this.getQueryForPage();
    },
    // 重置搜索框
    reset: function reset() {
      this.current = 1;
      this.$refs['query'].resetFields();
      this.getQueryForPage();
    },
    selectInit: function selectInit(row, index) {
      if (row.status == 1) {
        return false; // 不可勾选
      } else {
        return true; // 可勾选
      }
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      // console.log("复选框", data);
      var arr = data.map(function (item) {
        return item.markCode;
      });
      this.strategyIds = arr;
      // this.strategyIds = arr.toString();
      // console.log(arr.toString());
    },
    // 全部解禁
    allRelease: function allRelease() {
      var _this3 = this;
      if (!this.strategyIds.length > 0) {
        return this.$message.info('请选择解禁项');
      }
      this.$confirm('是否解除封禁?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.batchEnable(_this3.strategyIds);
        _this3.getQueryForPage();
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 单解禁
    handleClick: function handleClick(res) {
      var _this4 = this;
      this.$confirm('是否解除封禁?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.singleEnable(res.markCode);
        _this4.getQueryForPage();
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};
exports.default = _default;