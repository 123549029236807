"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _log = require("@/api/terminal/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = ''; // 缓存名字为当前path去掉/
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        wechatNickName: '',
        wechatOpenId: '',
        wechatId: '',
        ipAddress: '',
        entrance: '',
        feedbackType: '',
        rangeTime: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      // 导出按钮加载
      downLoading: false,
      // 查看
      mloading: false,
      scanVisible: false,
      ruleForm: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.queryListPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    queryListPage: function queryListPage() {
      var that = this;
      that.listLoading = true;
      if (this.formInline.rangeTime && this.formInline.rangeTime.length > 0) {
        this.formInline.startTime = this.formInline.rangeTime[0];
        this.formInline.endTime = this.formInline.rangeTime[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.formInline));
      delete sdata.rangeTime;
      (0, _log.queryListPage)(sdata).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          that.listLoading = false;
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.queryListPage();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.formInline = {
        wechatIdOrOpenId: '',
        terminalCodeOrName: '',
        phone: '',
        rangeTime: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.queryListPage();
    },
    detail: function detail(row) {
      var _this = this;
      this.mloading = true;
      this.ruleForm = row;
      var arr = row.pictureUrl ? row.pictureUrl.split(',') : [];
      this.ruleForm.imgList = arr;
      this.scanVisible = true;
      setTimeout(function () {
        _this.mloading = false;
      }, 1000);
    },
    handleClose: function handleClose() {
      this.ruleForm = {};
      this.scanVisible = false;
    } // 导出
    // download() {
    //   this.downLoading = true
    //   if (this.formInline.rangeTime && this.formInline.rangeTime.length > 0) {
    //     this.formInline.startTime = this.formInline.rangeTime[0]
    //     this.formInline.endTime = this.formInline.rangeTime[1]
    //   } else {
    //     this.formInline.startTime = ''
    //     this.formInline.endTime = ''
    //   }
    //   var submitData = JSON.parse(JSON.stringify(this.formInline))
    //   exportExcel(submitData)
    //     .then(res => {
    //       this.downLoading = false
    //       if (res.status != 200) {
    //         this.$message.error(res.data.msg)
    //       } else {
    //         var blob = new Blob([res.data], {
    //           type: 'application/vnd.ms-excel;charset=utf-8'
    //         })
    //         var downloadElement = document.createElement('a')
    //         var href = window.URL.createObjectURL(blob)
    //         downloadElement.href = href
    //         downloadElement.download = '终端提现流水.xlsx'
    //         document.body.appendChild(downloadElement)
    //         downloadElement.click()
    //         document.body.removeChild(downloadElement)
    //         window.URL.revokeObjectURL(href)
    //       }
    //     })
    //     .catch(() => {
    //       this.downLoading = false
    //     })
    // }
  }
};
exports.default = _default;