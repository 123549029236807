"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _templateManage = require("@/api/market/templateManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _config = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/config"));
var _configFanpai = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/configFanpai"));
var _configRotaryTable = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/configRotaryTable"));
var _configSudoku = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/configSudoku"));
var _configScratchCard = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/configScratchCard"));
var _configEggTwistMachine = _interopRequireDefault(require("@/views/marketingManage/marketingTemplate/templateManage/configEggTwistMachine"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarketingModulemarketingManagemarketingTemplatetemplateManageindex',
  components: {
    Pagination: _Pagination.default,
    Content: _config.default,
    Content2: _configFanpai.default,
    ContentRotaryTable: _configRotaryTable.default,
    ContentSudoku: _configSudoku.default,
    ContentScratchCard: _configScratchCard.default,
    ContentEggTwistMachine: _configEggTwistMachine.default
  },
  filters: {
    templateType: function templateType(index) {
      var comtype = {
        0: '引导关注',
        1: '扫码抽奖',
        2: '大转盘',
        // 3: "集字",
        4: '翻牌',
        5: '九宫格',
        6: '刮刮乐',
        7: '扭蛋机'
      };
      var result = comtype[index];
      return result;
    },
    templateState: function templateState(index) {
      var comtype = {
        1: '启用',
        2: '停用'
      };
      var result = comtype[index];
      return result;
    },
    templateCategory: function templateCategory(index) {
      var comtype = {
        1: '抽奖类',
        // 2: "集字类",
        3: '竞猜类',
        4: '引导关注类'
      };
      var result = comtype[index];
      return result;
    },
    parseTime: function parseTime(val) {
      if (val) {
        var time = new Date(val);
        var yy = time.getFullYear();
        var mm = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var dd = time.getDay() >= 10 ? time.getDay() : '0' + time.getDay();
        var hh = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
        var min = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes();
        var ss = time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds();
        return yy + '-' + mm + '-' + dd + '\n' + hh + ':' + min + ':' + ss;
      }
    },
    issysbuild: function issysbuild(index) {
      var comtype = {
        1: '系统预置',
        0: '自定义'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      templateCategorys: [{
        name: '抽奖类',
        value: '1'
      },
      // { name: "集字", value: "2" },
      {
        name: '竞猜',
        value: '3'
      }],
      templateStatus: [{
        name: '启用',
        value: '1'
      }, {
        name: '停用',
        value: '2'
      }],
      actlist: [{
        img: require('@/assets/images/hongbao.png'),
        title: '扫码抽奖',
        type: '1'
      }, {
        img: require('@/assets/images/zhuanpan.png'),
        title: '大转盘',
        type: '2'
      }, {
        img: require('@/assets/images/fanpai.png'),
        title: '翻牌',
        type: '4'
      }, {
        img: require('@/assets/images/shudu.png'),
        title: '九宫格',
        type: '5'
      }
      // {
      //   img: require('@/assets/images/guajiang.png'),
      //   title: '刮刮乐',
      //   type: '6'
      // },
      // {
      //   img: require('@/assets/images/niudan.png'),
      //   title: '扭蛋机',
      //   type: '7'
      // }
      ],

      tabHeight: '100%',
      list: null,
      listLoading: false,
      total: 0,
      formInline: {
        pageNo: 1,
        pageSize: 20,
        startTime: '',
        endTime: '',
        templateName: null,
        templateStatus: null,
        templateCategory: '1',
        rangeTime: ''
      },
      editType: 'add',
      editData: {},
      dialogVisible: false,
      actDialogVisible: false,
      disabled: false,
      type: '',
      id: ''
    };
  },
  activated: function activated() {
    this.getDataList();
  },
  mounted: function mounted() {
    var that = this;
    that.$nextTick(function () {
      var h = (0, _utils.tableHeight)();
      that.tabHeight = h;
    });
  },
  methods: {
    // 查询
    searchForm: function searchForm() {
      this.formInline.pageNo = 1;
      this.getDataList();
    },
    // 获取列表
    getDataList: function getDataList() {
      var _this = this;
      this.listLoading = true;
      var sdata = (0, _utils.deepClone)(this.formInline);
      sdata.startTime = this.formInline.rangeTime ? this.formInline.rangeTime[0] : '';
      sdata.endTime = this.formInline.rangeTime ? this.formInline.rangeTime[1] : '';
      delete sdata.rangeTime;
      (0, _templateManage.getList)(sdata).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (!res.data.errCode) {
            _this.list = res.data.records;
            _this.total = res.data.total;
          } else {
            _this.$message({
              message: res.data.errMsg,
              type: 'error',
              duration: 2 * 1000
            });
          }
        } else {
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 2 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    // 重置
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.formInline.rangeTime = '';
      this.getDataList();
    },
    // 添加模板
    creatAct: function creatAct(row) {
      this.editType = 'add';
      this.type = row.templateType;
      this.id = '';
      this.disabled = false;
      this.editData = {};
      this.dialogVisible = true;
    },
    // 编辑模板
    editAct: function editAct(data) {
      this.id = data.id;
      this.type = data.templateType;
      this.disabled = false;
      this.editType = 'edit';
      this.editData = (0, _utils.deepClone)(data);
      this.dialogVisible = true;
    },
    // 模板预览
    preView: function preView(data) {
      this.id = data.id;
      this.type = data.templateType;
      this.disabled = true;
      this.editType = 'edit';
      this.editData = (0, _utils.deepClone)(data);
      this.dialogVisible = true;
    },
    // 关闭模板类型选择
    cancel: function cancel() {
      this.actDialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
      this.type = '';
      this.cancel();
      this.searchForm();
    },
    // 启用停用
    enable: function enable(data, val) {
      var _this2 = this;
      var title = '';
      var status = '';
      if (val == 2) {
        status = '2';
        title = '确定停用？';
      } else {
        status = '1';
        title = '确定启用？';
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          id: data.id,
          status: status
        };
        (0, _templateManage.templateEnable)(param).then(function (res) {
          if (res.data.code == 200) {
            _this2.searchForm();
          } else {
            _this2.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;