var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "formInline",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.formInline,
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getDataList(_vm.formInline)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单编号：",
                                  prop: "orderNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "订单编号" },
                                  model: {
                                    value: _vm.formInline.orderNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "orderNumber",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.orderNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "订单状态：", prop: "status" },
                              },
                              [
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择订单状态" },
                                      model: {
                                        value: _vm.formInline.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.status",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单日期：",
                                  prop: "dateRange",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetimerange",
                                    "range-separator": "-",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.formInline.dateRange,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "dateRange", $$v)
                                    },
                                    expression: "formInline.dateRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "head-btn-group",
                    staticStyle: { width: "230px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.searchBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.searchBtn.icon,
                        },
                        on: {
                          click: function ($event) {
                            _vm.formInline.current = 1
                            _vm.getDataList()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.resetBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.resetBtn.icon,
                        },
                        on: {
                          click: function ($event) {
                            _vm.restForm("formInline")
                            _vm.getDataList()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "head-btn-group fun-btn-group" },
                  [
                    _vm.hasAuth("importorder", _vm.apis)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getWaitingConsignmentExcel()
                              },
                            },
                          },
                          [_vm._v("导出待发货订单")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasAuth("importrecord", _vm.apis)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getSoldExcel()
                              },
                            },
                          },
                          [_vm._v("导出销售记录")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "content mod-order-order" },
                [
                  _c(
                    "div",
                    { staticClass: "tit" },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 15 },
                            },
                            [
                              _c("span", { staticClass: "item product" }, [
                                _vm._v("详情"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", { staticClass: "item" }, [
                                _vm._v("积分"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", { staticClass: "item" }, [
                                _vm._v("订单状态"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", { staticClass: "item" }, [
                                _vm._v("操作"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.dataList, function (order) {
                    return _c(
                      "div",
                      { key: order.orderId, staticClass: "prod" },
                      [
                        _c("div", { staticClass: "prod-tit" }, [
                          _c("span", [
                            _vm._v("订单编号：" + _vm._s(order.orderNumber)),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("下单时间：" + _vm._s(order.createTime)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "prod-cont" },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: { span: 15 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "prod-item" },
                                      _vm._l(
                                        order.orderItems,
                                        function (orderItem) {
                                          return _c(
                                            "div",
                                            {
                                              key: orderItem.orderItemId,
                                              staticClass: "items name",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "prod-image" },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "100px",
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      src: orderItem.pic,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "prod-name" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(orderItem.prodName)
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "prod-info",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          orderItem.skuName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("div", { staticClass: "item" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "totalprice" },
                                          [
                                            _vm._v(
                                              "积分" + _vm._s(order.actualTotal)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "共" +
                                              _vm._s(order.productNums) +
                                              "件"
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("div", { staticClass: "item" }, [
                                      order.status === 1
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                            },
                                            [_vm._v("待付款")]
                                          )
                                        : order.status === 2
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                            },
                                            [_vm._v("待发货")]
                                          )
                                        : order.status === 3
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                            },
                                            [_vm._v("已发货")]
                                          )
                                        : order.status === 4
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                            },
                                            [_vm._v("待评价")]
                                          )
                                        : order.status === 5
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                            },
                                            [_vm._v("成功")]
                                          )
                                        : _c(
                                            "span",
                                            { attrs: { size: "small" } },
                                            [_vm._v("失败")]
                                          ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("div", { staticClass: "item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "operate" },
                                        [
                                          _vm.hasAuth("detailbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOrUpdateHandle(
                                                        order.orderNumber
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("订单详情")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          order.status === 2 &&
                                          _vm.hasAuth("sendorder", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "0",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeOrder(
                                                        order.orderNumber
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("订单发货")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "remark" }, [
                          _c("div", { staticClass: "buyer-remark" }, [
                            _c("span", [
                              _vm._v("备注:" + _vm._s(order.remarks)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.formInline.current,
                limit: _vm.formInline.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.formInline, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.formInline, "size", $event)
                },
                pagination: _vm.getDataList,
              },
            }),
            _vm._v(" "),
            _vm.addOrUpdateVisible
              ? _c("add-or-update", {
                  ref: "addOrUpdate",
                  on: { refreshDataList: _vm.getDataList },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.consignmentInfoVisible
              ? _c("consignment-info", {
                  ref: "consignmentInfo",
                  on: { inputCallback: _vm.getWaitingConsignmentExcel },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.devyVisible
              ? _c("devy-add", {
                  ref: "devyAdd",
                  on: { refreshDataList: _vm.getDataList },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(0)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }