"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/api/warehouse/checkout/index");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {},
  filters: {},
  data: function data() {
    return {
      title: '单据调拨',
      billType: 203,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      allotLoading: false,
      visibleType: '',
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      returnOrgType: 1,
      ruleForm: {
        NCNO: '',
        // NC单据单号
        billNo: '',
        // 单据单号
        inTransBillNO: '' // 转入单号
      },

      disabled: {
        num: false
      },
      factoryList: [],
      proList: [],
      plist: [],
      rules: {
        NCNO: [{
          required: true,
          message: 'NC物流单号不能为空，请维护',
          trigger: 'change'
        }],
        billNo: [{
          required: true,
          message: '物流单号不能为空，请维护',
          trigger: 'change'
        }],
        inTransBillNO: [{
          required: true,
          message: '转入单号不能为空，请维护',
          trigger: 'change'
        }]
      },
      typeVisible: false,
      unitType: '',
      unitShow: false,
      inOutType: '1',
      shouFaType: '1',
      disType: false,
      supplierIds: [],
      purchaseType: '',
      orderNo: '',
      orderNoList: [],
      loading: false,
      productId: '',
      ddyt: [],
      billDesc: {},
      productDesc: ''
    };
  },
  // 监听
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {}
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    setDetail: function setDetail(row) {
      var _this = this;
      this.allotLoading = true;
      (0, _index.getPeoductionDetail)({
        billNo: row.billNo,
        ncProductId: row.ncproductId ? row.ncproductId : '',
        productId: row.productId ? row.productId : ''
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          _this.billDesc = res.data.data.tradeBillAllVO;
          _this.productDesc = res.data.data.tradeProductVOS[0];
          _this.ruleForm.billNo = _this.billDesc.billNo;
          _this.ruleForm.NCNO = _this.billDesc.NCNO;
        }
        _this.allotLoading = false;
      });
    },
    show: function show(row, type) {
      this.dialogVisible = true;
      this.visibleType = type;
      this.setDetail(row);
    },
    submitForm: function submitForm(type) {
      var _this2 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          var submitData = {};
          submitData.tradeProductList = [];
          submitData.billNo = _this2.ruleForm.billNo;
          submitData.inTransBillNO = _this2.ruleForm.inTransBillNO;
          submitData.billSource = _this2.billDesc.billSource;
          submitData.billStatus = _this2.billDesc.billStatus;
          submitData.billType = _this2.billDesc.billType;
          submitData.pId = _this2.billDesc.pid;
          // 单个调拨必传产品明细
          if (type == 'single') {
            var obj = {};
            obj.ncproductName = _this2.productDesc.ncproductName;
            obj.ncProductId = _this2.productDesc.ncProductId;
            obj.productName = _this2.productDesc.productName;
            obj.productId = _this2.productDesc.productId;
            obj.planScanNum = _this2.productDesc.planScanNum;
            obj.planScanAtoNum = _this2.productDesc.planScanAtoNum;
            obj.realScanNum = _this2.productDesc.realScanNum;
            obj.realScanAtoNum = _this2.productDesc.realScanAtoNum;
            obj.productModel = _this2.productDesc.productModel;
            submitData.tradeProductList.push(obj);
            submitData.wholeTrans = 0; // 是否整单调拨：0否，1是
          } else {
            submitData.wholeTrans = 1;
          }
          (0, _index.billTrans)(submitData).then(function (res) {
            _this2.btn_loading = false;
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              _this2.$message({
                message: '操作成功',
                type: 'success'
              });
              _this2.handleClose();
              _this2.$parent.getList();
            }
          }).catch(function () {
            _this2.btn_loading = false;
          });
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      var that = this;
      that.dialogVisible = false;
      that.ruleForm.NCNO = '';
      that.ruleForm.billNo = '';
      that.ruleForm.inTransBillNO = '';
      that.billDesc = {};
      that.productDesc = {};
    }
  }
};
exports.default = _default;