"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/mark/basic.js");
var _racket_return = require("@/api/mark/coding_service/racket_return/racket_return");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// [{id:1,'name':'制盖'},{id:2,'name':'制签'}]
var selectOptions = {
  supplierType: [{
    id: 1,
    name: '标签厂'
  }, {
    id: 2,
    name: '制盖厂'
  }
  // { id: 3, name: '膜厂' },
  // { id: 4, name: '仓储商' }
  ]
};

var listQuery = {
  current: 1,
  size: 20,
  batchNo: '',
  supplierName: '',
  uploadStartTime: '',
  uploadEndTime: ''
};
var temp = {
  pid: 0,
  Reason: ''
};
var _default = {
  name: 'MarkcodingServiceracketReturnindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (time == null || time == '') return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    supplierType: function supplierType(v) {
      var t = '';
      for (var i in selectOptions.supplierType) {
        if (selectOptions.supplierType[i].id == v) {
          t = selectOptions.supplierType[i].name;
          break;
        }
      }
      return t;
    },
    applyNumType: function applyNumType(v) {
      v = Number(v);
      var type = {
        0: '',
        1: '标码',
        2: '盖码'
      };
      return type[v];
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: listQuery,
      temp: temp,
      dialogFormVisible: false,
      btnPowers: null
    };
  },
  activated: function activated() {
    this.btnPowers = {};
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _racket_return.fetchList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.$refs.searchform.resetFields();
      }
      this.listQuery.current = 1;
      this.getList();
    }
  }
};
exports.default = _default;