"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _user = require("@/api/user");
var _pwdconfig = require("@/api/systems/pwdconfig");
var _forgetthepassword = _interopRequireDefault(require("@/components/forgetthepassword"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    password: _forgetthepassword.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入用户名！'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入密码！'));
      } else {
        callback();
      }
    };
    var checkMixZm = function checkMixZm(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入验证码'));
      } else {
        var reg = /^[0-9a-zA-Z]*$/g;
        var value2 = value.replace(/\s+/g, '');
        if (reg.test(value2)) {
          callback();
        } else {
          return callback(new Error('请输入正确的验证码，不含有特殊字符'));
        }
      }
    };
    return {
      imgs: [{
        className: 'img4'
      }, {
        className: 'img3'
      }, {
        className: 'img2'
      }, {
        className: 'img1'
      }],
      activeName: 'first',
      loginForm: {
        username: '',
        password: '',
        code: '',
        cookieId: '',
        smsCode: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        code: [{
          required: true,
          trigger: 'blur',
          validator: checkMixZm
        }],
        smsCode: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }]
      },
      ruleForm1: {
        loginAccount: '',
        validCode: '',
        cookieId: '',
        mobile: '',
        smsCode: '',
        email: '',
        emailCode: '',
        type: '0'
      },
      ruleForm2: {
        password: '',
        repassword: ''
      },
      ruleForm3: {},
      rules: {
        type: [{
          required: true,
          trigger: 'blur'
        }],
        loginAccount: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        validCode: [{
          required: true,
          trigger: 'blur',
          validator: checkMixZm
        }],
        mobile: [{
          required: true,
          trigger: 'blur',
          message: ''
        }],
        email: [{
          required: true,
          trigger: 'blur',
          message: ''
        }],
        smsCode: [{
          required: true,
          trigger: 'blur',
          message: '请输入手机验证码'
        }],
        emailCode: [{
          required: true,
          trigger: 'blur',
          message: '请输入邮箱验证码'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      showText: '获取验证码',
      codeSrc: '',
      codeSrc2: '',
      imgLoading: false,
      step1: false,
      firstStep: false,
      step2: false,
      secStep: false,
      step3: false,
      thrStep: false,
      active: 0,
      dialogVisible: false,
      loginFormVisible: true,
      timeOut: false,
      sessionPhone: '',
      showErm: false,
      disabled: false,
      codeBtnWord: '获取验证码',
      // 获取验证码按钮文字
      waitTime: 60,
      // 获取验证码按钮失效时间
      getCodeBtnDisable: false,
      safeRule: {},
      errCount: 0,
      showSms: false
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    },
    errCount: {
      handler: function handler(newval, oldval) {
        if (this.safeRule.requireSmsVerification == 0) {
          if (this.loginForm.username != 'admin') {
            if (newval >= 2) {
              this.showSms = true;
            } else {
              this.showSms = false;
            }
          } else {
            this.showSms = false;
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    var that = this;
    sessionStorage.removeItem('refresh_token');
    // var uid = that.generateUUID()
    // that.loginForm.cookieId = uid
    that.getRuleDetail();
    that.getVCode();
  },
  mounted: function mounted() {
    this.errCount = sessionStorage.getItem('errCount') ? sessionStorage.getItem('errCount') : 0;
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    } else if (this.loginForm.code === '') {
      this.$refs.code.focus();
    }
  },
  destroyed: function destroyed() {
    window.removeEventListener('storage', this.afterQRScan);
  },
  methods: {
    // 切换模式
    changeMode: function changeMode(arg) {
      var that = this;
      that.showErm = Number(arg) == 1;
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      var that = this;
      that.disabled = true;
      that.$refs.loginForm.validate(function (valid) {
        if (valid) {
          that.loading = true;
          if (that.loginForm.username == 'admin') {
            that.$store.dispatch('user/login', that.loginForm).then(function () {
              that.$router.push({
                path: that.redirect || '/',
                query: that.otherQuery
              });
              that.loading = false;
            }).then(function () {
              setTimeout(function () {
                that.disabled = false;
              }, 4000);
            }).catch(function () {
              that.disabled = false;
              that.loading = false;
              _this2.changeImg();
            });
            return;
          }
          var obj = {};
          obj.loginAccount = that.loginForm.username;
          obj.smsCode = that.loginForm.smsCode;
          obj.type = 1;
          // 非强制使用短信验证码
          if (that.safeRule.requireSmsVerification == 0) {
            if (sessionStorage.getItem('errCount') && sessionStorage.getItem('errCount') >= 2) {
              // 非强制短信验证码校验，错误两次后校验- 验证短信验证码
              that.showSms = true;
              that.checkSmsCode(obj);
              return;
            }
            if (!sessionStorage.getItem('errCount') || sessionStorage.getItem('errCount') < 2) {
              // 不验证短信验证码
              that.noCheckSmsWay();
              return;
            }
          }

          // 强制验证-每次都需要验证
          if (that.safeRule.requireSmsVerification == 1) {
            that.checkSmsCode(obj);
          }
        } else {
          that.disabled = false;
          that.loading = false;
          return false;
        }
      });
    },
    // 不验证短信验证码方法
    noCheckSmsWay: function noCheckSmsWay() {
      var _this3 = this;
      var that = this;
      // 不验证短信验证码
      var sdata2 = (0, _utils.deepClone)(that.loginForm);
      delete sdata2.smsCode;
      sdata2.username = that.loginForm.username;
      console.log('登录', sdata2);
      that.$store.dispatch('user/login', sdata2).then(function (res) {
        if (res == 101 || res == '101') {
          that.loading = false;
          return;
        }
        that.$router.push({
          path: that.redirect || '/',
          query: that.otherQuery
        });
        that.loading = false;
      }).then(function () {
        setTimeout(function () {
          that.disabled = false;
        }, 4000);
      }).catch(function () {
        that.showSmsCodeOrNot();
        that.disabled = false;
        that.loading = false;
        _this3.changeImg();
      });
    },
    // 是否显示短信验证码
    showSmsCodeOrNot: function showSmsCodeOrNot() {
      var that = this;
      // 判断是否需要短信验证码
      that.errCount = sessionStorage.getItem('errCount') ? sessionStorage.getItem('errCount') : 0;
      if (that.safeRule.requireSmsVerification == 0) {
        if (that.errCount >= 2) {
          that.showSms = true;
        } else {
          that.showSms = false;
        }
      }
    },
    // 校验短信验证码方法
    checkSmsCode: function checkSmsCode(obj) {
      var that = this;
      (0, _user.postsmsCode)(obj).then(function (res) {
        if (res.data.data.code != 200) {
          that.loading = false;
          that.disabled = false;
          that.$message.error(res.data.data.msg);
          return;
        }
        var sdata2 = (0, _utils.deepClone)(that.loginForm);
        delete sdata2.smsCode;
        sdata2.username = that.loginForm.username;
        that.$store.dispatch('user/login', sdata2).then(function (res) {
          if (res == 101 || res == '101') {
            that.loading = false;
            return;
          }
          that.$router.push({
            path: that.redirect || '/',
            query: that.otherQuery
          });
          that.loading = false;
        }).then(function () {
          setTimeout(function () {
            that.disabled = false;
          }, 4000);
        }).catch(function () {
          that.disabled = false;
          that.loading = false;
          that.changeImg();
        });
      });
    },
    // 发送短信验证码
    sendCode: function sendCode() {
      var _this4 = this;
      var that = this;
      if (that.getCodeBtnDisable) {
        return;
      }
      if (!that.loginForm.code) {
        that.$message.warning('请先输入图像验证码!');
        return;
      }
      var params = {};
      params.loginAccount = that.loginForm.username;
      params.type = 1;
      var validCode = JSON.parse(JSON.stringify(that.loginForm));
      (0, _user.getsmsCode)(params, validCode).then(function (res) {
        // console.log('短信', res)
        if (res.data.data.code != 200) {
          that.$message.error(res.data.data.msg);
          return;
        }
        that.$message.success('验证码发送成功');
        that.waitTime--;
        that.getCodeBtnDisable = true;
        that.codeBtnWord = "".concat(_this4.waitTime, "s \u540E\u91CD\u65B0\u53D1\u9001");
        var timer = setInterval(function () {
          if (that.waitTime > 1) {
            that.waitTime--;
            that.codeBtnWord = "".concat(that.waitTime, "s \u540E\u91CD\u65B0\u53D1\u9001");
          } else {
            clearInterval(timer);
            that.codeBtnWord = '获取验证码';
            that.getCodeBtnDisable = false;
            that.waitTime = 60;
          }
        }, 1000);
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    changeImg: function changeImg() {
      var that = this;
      that.imgLoading = true;
      that.loginForm.code = '';
      var param = that.loginForm.cookieId;
      that.getVCode(param);
    },
    changeImg2: function changeImg2() {
      var that = this;
      that.imgLoading = true;
      that.ruleForm1.validCode = '';
      var param = that.ruleForm1.clientId;
      that.getSetCode(param);
    },
    getVCode: function getVCode() {
      var that = this;
      (0, _user.getvImg)().then(function (res) {
        console.log(res, '获取图片2');
        that.codeSrc = res.data.data.vaildImg;
        that.loginForm.cookieId = res.data.data.uuidKey;
        that.imgLoading = false;
      });
    },
    getSetCode: function getSetCode(uid) {
      var that = this;
      (0, _user.getvImg)(uid).then(function (res) {
        console.log(res, '获取图片');
        // that.codeSrc2 = res.request.responseURL + '?v=' + new Date().getTime()
        that.codeSrc2 = res.data.data.vaildImg;
        that.ruleForm1.cookieId = res.data.data.uuidKey;
        that.imgLoading = false;
      });
    },
    generateUUID: function generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },
    forgetPwd: function forgetPwd() {
      var that = this;
      that.dialogVisible = true;
      // that.$message.info('短信验证功能尚未开通，请联系系统管理员。')
      // return
      // var uidCode = that.generateUUID()
      // that.ruleForm1.clientId = uidCode
      // that.ruleForm1.loginAccount = that.loginForm.username
      // that.getSetCode(that.ucode)
      // that.active = 0
      // that.dialogVisible = true
      // that.loginFormVisible = false
      // console.log(that.ruleForm1.loginAccount)
      // if (that.ruleForm1.loginAccount) {
      //   that.getUserinfo()
      // }
    },
    // 获取安全规则
    getRuleDetail: function getRuleDetail() {
      var _this5 = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.data.code == 200) {
          if (res.data.data.data && res.data.data.data.length > 0) {
            _this5.safeRule = res.data.data.data[0];
            // 这里的判断是刷新操作
            var count = sessionStorage.getItem('errCount');
            if (_this5.safeRule.requireSmsVerification == 0) {
              if (count && count >= 2) {
                _this5.showSms = true;
              }
            }
          }
        }
        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      });
    },
    // 获取admin
    getUserNameState: function getUserNameState(e) {
      if (e == 'admin') {
        this.showSms = false;
        return;
      }
      var count = sessionStorage.getItem('errCount');
      if (count >= 2) {
        this.showSms = true;
      }
    },
    // 删除session
    removeItemSession: function removeItemSession() {
      var count = sessionStorage.getItem('errCount');
      if (count) {
        sessionStorage.removeItem('errCount');
      }
    }
  }
};
exports.default = _default;