"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPwdRuleList = getPwdRuleList;
exports.updatePwdRule = updatePwdRule;
var _request = _interopRequireDefault(require("@/utils/request"));
// 修改密码规则
function updatePwdRule(data) {
  return (0, _request.default)({
    url: '/api-umm/validata/sysPasswordRules/update',
    method: 'post',
    data: data
  });
}

// 获取规则列表
function getPwdRuleList() {
  return (0, _request.default)({
    url: '/api-umm/validata/sysPasswordRules/list',
    method: 'get'
  });
}