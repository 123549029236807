"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _chaneluser = require("@/api/systems/chaneluser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _scan = _interopRequireDefault(require("./components/scan"));
var _scanRole = _interopRequireDefault(require("./components/scanRole"));
var _assignRole = _interopRequireDefault(require("./components/assignRole"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _pwdconfig = require("@/api/systems/pwdconfig");
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemrolesmangechaneluserindex';
var _default = {
  name: 'Systemrolesmangechaneluserindex',
  components: {
    Pagination: _Pagination.default,
    scan: _scan.default,
    scanRole: _scanRole.default,
    assignRole: _assignRole.default,
    ChannelSelectDialog: _channelSelectDialog.default
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        size: 20
      },
      param: {
        loginAccount: '',
        userName: '',
        phoneNum: '',
        isEnable: '',
        channelList: [],
        roleList: []
      },
      list: [],
      roleOption: [],
      tabHeight: '100%',
      multipleSelection: [],
      tableKey: 'chanelTable',
      total: 0,
      listLoading: false,
      qdParam: {
        keyword: '',
        current: 1,
        size: 20
      },
      argument: '',
      qdVisible: false,
      qdData: [],
      tableLoading: false,
      total2: 0,
      qdStr: '',
      addData: {
        loginAccount: '',
        userName: '',
        channelId: '',
        channelType: '',
        channelCode: '',
        channelName: '',
        loginPwd: '',
        reloginPwd: '',
        phoneNum: '',
        email: '',
        isEnable: true,
        remark: '',
        id: ''
      },
      rules: {
        loginAccount: [{
          required: true,
          message: '请输入用户名(手机号)',
          validator: _chaneluser.checkPhone,
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        channelName: [{
          required: true,
          message: '请选择所属渠道',
          trigger: 'blur'
        }],
        loginPwd: [{
          required: true,
          validator: _chaneluser.checkPwd,
          trigger: 'blur'
        }],
        reloginPwd: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }],
        phoneNum: [{
          required: true,
          validator: _chaneluser.checkPhone,
          trigger: 'blur'
        }],
        email: [{
          validator: _chaneluser.checkEmail,
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      loading: false,
      title: '渠道用户-添加',
      type: '',
      page2: false,
      endShow: true,
      operate: false,
      scanData: {},
      scanShow: false,
      roleData: [],
      roleShow: false,
      scanAuthData: [],
      scanAuthShow: false,
      asroleShow: false,
      id: '',
      expandTxt: '展开',
      showCol: false,
      dealerId: '',
      roleTypeDetail: '',
      unitShow: false,
      apis: [],
      autocomplete: 'off',
      autofocus: false,
      safeRules: {},
      channelDisabled: false,
      dsate: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getUserRloes();
      that.getList();
      that.getRuleDetail();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _chaneluser.getCompanyList)(that.listQuery, that.param).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 1000);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.param.loginAccount = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.channelList = [];
      that.param.roleList = [];
      that.qdStr = '';
      that.listQuery.current = 1;
      that.$refs[formName].resetFields();
      that.getList();
    },
    // 获取下拉角色
    getUserRloes: function getUserRloes() {
      var that = this;
      (0, _chaneluser.getAllQdRoles)().then(function (res) {
        if (res.data.code == 200) {
          that.roleOption = res.data.data;
        } else {
          that.roleOption = [];
        }
      });
    },
    // 获取渠道数据
    getQdData: function getQdData(param) {
      var that = this;
      that.argument = param;
      this.$refs.channelSelect.proSelVisible = true;
    },
    hideDialog: function hideDialog() {
      var that = this;
      that.unitShow = false;
    },
    makeSure: function makeSure(val) {
      var that = this;
      var type = that.argument;
      var chanelData = val;
      if (type == 'search') {
        if (chanelData != null) {
          that.param.channelList[0] = chanelData.pId;
          that.qdStr = chanelData.channelName;
        }
      }
      if (type == 'add') {
        if (chanelData != null) {
          that.addData.channelId = chanelData.pId;
          that.addData.channelType = chanelData.channelType;
          that.addData.channelCode = chanelData.channelCode;
          that.addData.channelName = chanelData.channelName;
        }
      }
      if (type == 'edit') {
        if (chanelData != null) {
          that.addData.channelId = chanelData.pId;
          that.addData.channelType = chanelData.channelType;
          that.addData.channelCode = chanelData.channelCode;
          that.addData.channelName = chanelData.channelName;
        }
      }
      that.hideDialog();
    },
    clearqd: function clearqd() {
      var that = this;
      that.param.channelList = [];
      that.qdStr = '';
    },
    // 添加
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      var obj = JSON.parse(localStorage.getItem('currentUser'));
      that.title = '渠道用户-添加';
      that.type = 'add';
      that.addData.loginAccount = '';
      that.addData.userName = '';
      that.addData.channelId = '';
      that.addData.channelType = '';
      that.addData.channelCode = '';
      that.addData.channelName = '';
      that.addData.loginPwd = '';
      that.addData.reloginPwd = '';
      that.addData.phoneNum = '';
      that.addData.email = '';
      that.addData.isEnable = true;
      that.addData.remark = '';
      that.addData.id = '';
      if (obj.userType == 1) {
        that.channelDisabled = true;
        that.addData.channelId = obj.channelId;
        that.addData.channelType = obj.channelType;
        that.addData.channelCode = obj.channelCode;
        that.addData.channelName = obj.channelName;
      }
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 编辑
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      var obj = JSON.parse(localStorage.getItem('currentUser'));
      if (obj.userType == 1) {
        that.channelDisabled = true;
      }
      var param = {};
      that.type = 'edit';
      that.title = '渠道用户-修改';
      param.id = data.pid;
      (0, _chaneluser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.addData.loginAccount = res.data.data.loginAccount;
          that.addData.userName = res.data.data.userName;
          that.addData.channelId = res.data.data.channleId;
          that.addData.channelType = res.data.data.channelType;
          that.addData.channelCode = res.data.data.channleCode;
          that.addData.channelName = res.data.data.channleName;
          that.addData.phoneNum = res.data.data.phoneNum;
          that.addData.email = res.data.data.email;
          that.addData.remark = res.data.data.remark;
          that.addData.sourceEnum = res.data.data.sourceEnum;
          that.addData.loginPwd = '';
          that.addData.reloginPwd = '';
          that.addData.id = param.id;
          if (res.data.data.isEnable == 1) {
            that.addData.isEnable = true;
          } else {
            that.addData.isEnable = false;
          }
        } else {
          that.addData = {};
        }
        that.dialogVisible = true;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.channelDisabled = false;
      that.resetForm('addForm');
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      var that = this;
      var type = that.type;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var param = {};
          var addData = (0, _utils.deepClone)(that.addData);
          var loginAccount = addData.loginAccount;
          var pwd = "".concat(loginAccount.trim(), "+").concat(addData.loginPwd);
          param.loginAccount = addData.loginAccount;
          param.userName = addData.userName;
          param.channelId = addData.channelId;
          param.channelTyppe = addData.channelType;
          param.loginPwd = (0, _jsMd.default)(pwd);
          param.phoneNum = addData.phoneNum;
          param.email = addData.email;
          param.isEnable = addData.isEnable;
          param.remark = addData.remark;
          param.id = addData.id;
          param.groupCode = '4';
          param.postCode = '-1';
          if (param.isEnable == false) {
            param.isEnable = 0;
          } else {
            param.isEnable = 1;
          }
          if (that.addData.loginAccount != that.addData.phoneNum) {
            that.$message.error('用户名与手机号不一致，请重新输入！');
            that.loading = false;
            that.dsate = false;
            return;
          }
          if (that.addData.loginPwd != that.addData.reloginPwd) {
            that.$message.error('确认密码与输入密码不一致，请重新输入！');
            that.loading = false;
            that.dsate = false;
            return;
          }
          // 密码安全等级校验
          var weekCheck = that.safeRules.weakCheck.split(',');
          if (weekCheck.includes(that.addData.loginPwd)) {
            _this.$confirm('密码安全等级太低,建议立即修改', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showClose: false,
              showCancelButton: false,
              type: 'warning',
              center: true
            }).then(function () {
              that.addData.loginPwd = '';
              that.addData.reloginPwd = '';
            }).catch(function () {});
            return;
          }
          if (type == 'add') {
            (0, _chaneluser.addCompyUser)(param).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                that.loading = false;
                that.dsate = false;
                that.resetForm('addForm');
                that.getList();
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (type == 'edit') {
            console.log(param);
            (0, _chaneluser.editUserInfo)(param).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                that.loading = false;
                that.dsate = false;
                that.resetForm('addForm');
                that.getList();
              } else {
                that.loading = false;
                that.dsate = false;
                that.$message.error(res.data.msg);
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.addData.sourceEnum = '';
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    },
    // 查看
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _chaneluser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
        } else {
          that.scanData = {};
        }
      });
      that.$nextTick(function () {
        that.scanShow = true;
      });
    },
    setPwd: function setPwd(data) {
      var that = this;
      var setHeaderId = that.authId('setbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _chaneluser.setUserPwd)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$alert(res.data.msg, '提示', {
            confirmButtonText: '我知道啦',
            callback: function callback(action) {
              console.log('已修改');
            }
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    scanRole: function scanRole(data) {
      var that = this;
      var setHeaderId = that.authId('scanrole', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _chaneluser.scanUserRole)(param).then(function (res) {
        if (res.data.code == 200) {
          that.roleData = res.data.data;
          that.roleShow = true;
        } else {
          that.roleData = [];
          that.roleShow = true;
        }
      });
    },
    scanAuth: function scanAuth(data) {
      var that = this;
      that.id = data.pid;
      that.$nextTick(function () {
        that.scanAuthShow = true;
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      var that = this;
      that.multipleSelection = data;
    },
    getAuth: function getAuth(data) {
      var that = this;
      that.authData = (0, _utils.deepClone)(data);
      that.$nextTick(function () {
        that.authShow = true;
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _chaneluser.stopUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.getList();
          } else {
            that.$message.error('禁用失败');
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _chaneluser.startUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.getList();
          } else {
            that.$message.error('启用失败');
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    AllStop: function AllStop() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要禁用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      (0, _chaneluser.stopItems)(sdata).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '禁用成功'
          });
          that.multipleSelection = [];
          that.getList();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    AllStart: function AllStart() {
      var that = this;
      var setHeaderId = that.authId('allstart', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要启用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      (0, _chaneluser.startItems)(sdata).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '启用成功'
          });
          that.multipleSelection = [];
          that.getList();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    rankRole: function rankRole() {
      var that = this;
      if (that.multipleSelection.length == 0) {
        that.$message.error('请选择需要分配的项');
        return;
      }
      var arr = [];
      var sdata = that.multipleSelection;
      sdata.forEach(function (item) {
        arr.push(item.channelId);
      });
      var uni = that.unique(arr);
      if (uni.length > 1) {
        that.$message.error('您所选的渠道编码不一致，无法分配角色，请选择渠道编码一致项');
        return;
      }
      that.roleTypeDetail = sdata[0].channelType == 1 ? 'JXS' : 'ZD';
      that.dealerId = uni[0];
      var setHeaderId = that.authId('roleassign', that.apis);
      that.$nextTick(function () {
        that.asroleShow = true;
      });
    },
    // 数组去重
    unique: function unique(arr) {
      return Array.from(new Set(arr));
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取安全规则
    getRuleDetail: function getRuleDetail() {
      var _this2 = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.data.code == 200) {
          if (res.data.data.data && res.data.data.data.length > 0) {
            _this2.safeRules = res.data.data.data[0];
            if (res.data.data.data[0].strongCheck == 1) {
              _this2.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                    }
                  }
                }
              }];
            }
            if (res.data.data.data[0].strongCheck == 0) {
              _this2.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^[A-Za-z\d$@!%*#?&]{7,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度7-20位'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度7-20位'));
                    }
                  }
                }
              }];
            }
          } else {
            _this2.safeRules = {};
          }
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;