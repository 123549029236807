"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _select = require("@/api/warehouse/select");
var _productSelectDialog = _interopRequireDefault(require("../../../productSelectDialog"));
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'table-suply',
  components: {
    productDialog: _productSelectDialog.default,
    allotUnitSelect: _allotUnitSelect.default
  },
  data: function data() {
    return {
      productCode: '',
      TypeDataComs: {
        statusList: [],
        StoreList: [],
        OrgList: [],
        sourceList: []
      },
      searchForm: {
        billNo: '',
        inOrgId: '',
        inStoreId: '',
        outOrgId: '',
        relateNo: '',
        billSource: '',
        billStatus: '',
        billType: 104,
        endTime: '',
        productId: '',
        startTime: ''
      },
      dateValue: [],
      productName: '',
      expandTxt: '展开',
      showCol: false,
      inOrgName: '',
      outOrgName: '',
      unitShow: false,
      unitType: '',
      inOutType: '6',
      shouFaType: '1'
    };
  },
  created: function created() {
    this.getFactoryInfoList();
    this.getStoreList();
    this.getStatusList();
    this.getSourceList();
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.searchForm.productId = '';
        this.productName = '';
        this.searchForm.billNo = '';
        this.searchForm.inOrgId = '';
        this.searchForm.inStoreId = '';
        this.searchForm.outOrgId = '';
        this.searchForm.relateNo = '';
        this.searchForm.billSource = '';
        this.searchForm.billStatus = '';
        this.searchForm.billType = 104;
        this.searchForm.endTime = '';
        this.searchForm.startTime = '';
        this.inOrgName = '';
        this.outOrgName = '';
        this.dateValue = [];
        this.$refs.searchForm.resetFields();
      }
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      if (this.dateValue.length > 0) {
        this.searchForm.endTime = this.dateValue[1];
        this.searchForm.startTime = this.dateValue[0];
      } else {
        this.searchForm.endTime = '';
        this.searchForm.startTime = '';
      }
      this.$parent.searchList(this.searchForm);
    },
    //选择产品
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.searchForm.productId = '';
      this.productName = '';
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.searchForm.productId = val.pId;
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this = this;
      //获取工厂下啦列表数据
      this.TypeDataComs.OrgList = [];
      (0, _select.ccOrgInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this.TypeDataComs.OrgList = res.data.data;
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this2 = this;
      //获取库房下拉列表数据
      this.TypeDataComs.StoreList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this2.TypeDataComs.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    getStatusList: function getStatusList() {
      var _this3 = this;
      //获取单据状态
      this.TypeDataComs.statusList = [];
      var param = {};
      param.code = 'DJZT';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this3.TypeDataComs.statusList = res.data.data;
        }
      }).catch(function () {});
    },
    getSourceList: function getSourceList() {
      var _this4 = this;
      //获取单据来源
      this.TypeDataComs.sourceList = [];
      var param = {};
      param.code = 'DJLY';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this4.TypeDataComs.sourceList = res.data.data;
        }
      }).catch(function () {});
    },
    setFactory: function setFactory(v) {},
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    //获取收货单位
    clearUnitIn: function clearUnitIn() {
      var that = this;
      that.inOrgName = '';
      that.searchForm.inOrgId = '';
      that.searchForm.inStoreId = '';
      that.TypeDataComs.StoreList = [];
    },
    clearUnitOut: function clearUnitOut() {
      var that = this;
      that.outOrgName = '';
      that.searchForm.outOrgId = '';
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        that.shouFaType = '1';
      }
      if (arg == 'out') {
        that.shouFaType = '2';
      }
      that.unitShow = true;
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        that.inOrgName = val.companyName;
        that.searchForm.inOrgId = val.companyId;
        that.searchForm.inStoreId = '';
        that.getHouses(val.companyId);
      }
      if (type == 'out') {
        that.outOrgName = val.companyName;
        that.searchForm.outOrgId = val.companyId;
      }
    },
    //获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataComs.StoreList = [];
          that.TypeDataComs.StoreList = res.data.data;
        }
      });
    },
    //获取焦点关联库房
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.searchForm.inOrgId == '') {
        that.TypeDataComs.StoreList = [];
        that.$message.error('请选择收货单位');
        return;
      }
    }
  }
};
exports.default = _default;