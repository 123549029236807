"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _policyManagement = require("@/api/policySettings/policyManagement");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagepolicySettingspolicyGroupindex';
var _default = {
  name: 'MarketingManagepolicySettingspolicyGroupindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        strategyName: '',
        strategyTypeOne: null,
        strategyTypeTwo: null
      },
      tabHeight: '100%',
      //  策略管理列表
      tableData: [],
      listLoading: false,
      strategyTypeList: [],
      // 策略类型列表
      strategyTypeListOne: [],
      // 策略类型列表
      strategyTypeListTwo: [],
      // 策略类型列表
      title: '',
      dialogVisible: false,
      dialogFormVisible: false,
      look: false,
      // 备注策略信息
      remark: {
        id: null,
        remark: ''
      },
      // 策略配置信息内容
      ruleForm: {
        id: null,
        // 策略主键id
        strategyName: '',
        // 策略名称
        strategyTypeOne: null,
        // 一级策略id
        strategyTypeTwo: null,
        // 二级策略id
        strategyJsonExpandBo: {}
      },
      // 保质期
      awardBo: {
        expirationDay: null,
        // 保质期
        expirationRule: '0' // 超期策略
      },

      // 次数与金额
      safeBo: {
        dayLotteryNumLimit: null,
        // 每人每天抽奖次数
        dayWinningNumLimit: null,
        // 每人每天最大中奖次数
        lotteryInterval: null,
        // 每人抽奖间隔（秒）
        totalMoneyLimit: null,
        // 单人活动抽总奖金额上限（元）
        totalNumLimit: null // 每人抽奖总次数
      },

      // 数码安全
      codeSafeBo: {
        bannedMinutes: null,
        // 封禁时长(分钟)
        dragIntoFlag: false,
        // 连续触发2次，是否拉入数码黑名单 true开启 false关闭
        errorNum: null // 同数码连续错误次数
      },

      // 验证策略配置信息
      rules: {
        strategyName: [{
          required: true,
          message: '请输入策略名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 10,
          message: '长度在 2 到 10 个字符',
          trigger: 'blur'
        }],
        strategyTypeOne: [{
          required: true,
          message: '请选择一级策略类型',
          trigger: 'change'
        }],
        strategyTypeTwo: [{
          required: true,
          message: '请选择二级策略类型',
          trigger: 'change'
        }],
        'strategyJsonExpandBo.awardBo.expirationDay': [{
          required: true,
          message: '请输入保质期',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.awardBo.expirationRule': [{
          required: true,
          message: '请选择超期策略',
          trigger: 'change'
        }],
        'strategyJsonExpandBo.safeBo.totalNumLimit': [{
          required: true,
          message: '请输入抽奖总次数',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.safeBo.dayLotteryNumLimit': [{
          required: true,
          message: '请输入每天抽奖次数',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.safeBo.dayWinningNumLimit': [{
          required: true,
          message: '请输入每天最大中奖次数',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.safeBo.lotteryInterval': [{
          required: true,
          message: '请输入抽奖间隔(秒)',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.safeBo.totalMoneyLimit': [{
          required: true,
          message: '请输入活动抽总奖金额上限（元）',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.codeSafeBo.errorNum': [{
          required: true,
          message: '请输入同数码连续错误次数',
          trigger: 'blur'
        }],
        'strategyJsonExpandBo.codeSafeBo.bannedMinutes': [{
          required: true,
          message: '请输入封禁时长(分钟)',
          trigger: 'blur'
        }]
      },
      isNumber: true,
      isPrize: false,
      isSecuritye: false,
      isUser: false,
      isQuality: false,
      // 用来控制保质期超期策略显示隐藏
      isTagName: false,
      // 用来控制标签名称显示隐藏
      isShow: false,
      // 用来控制每人抽奖总次数等选项显示隐藏
      isCode: false,
      // 数码安全
      isRegion: false,
      isName2: true,
      isStartContinuous: false,
      isPacking: false,
      isAppoint: false,
      populationType: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      width: 140,
      secondwidth: 280,
      secondwidth2: 260,
      queryParams: {
        pageSize: 10,
        pageNo: 1
      },
      total: 0,
      parentId: null,
      // 用来记录一级策略id
      // 编辑功能id
      id: null,
      // 查看策略
      lookdata: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.tableData && that.tableData.length > 0);
    if (!hasCache) {
      that.getStrategy();
      that.getStrategyType();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.tableData = [];
      that.total = 0;
    }
  },
  methods: {
    // 获取策略列表
    getStrategy: function getStrategy(data) {
      var _this = this;
      this.listLoading = true;
      (0, _policyManagement.getStrategy)(Object.assign((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), data))).then(function (res) {
        // console.log(res.data.data);
        if (res.data.data.errCode == 500) {
          _this.$message.error('获取失败！');
          _this.listLoading = false;
          return;
        } else {
          if (res.data.data !== null) {
            _this.total = res.data.data.total;
            _this.tableData = res.data.data.records;
            setTimeout(function () {
              _this.listLoading = false;
            }, 1 * 800);
          } else {
            _this.tableData = [];
            _this.listLoading = false;
          }
        }
      }).catch(function () {
        _this.$message.error('获取失败！');
        _this.listLoading = false;
      });
    },
    // 获取策略类型
    getStrategyType: function getStrategyType() {
      var _this2 = this;
      (0, _policyManagement.getStrategyType)({
        level: '',
        parentId: ''
      }).then(function (res) {
        // console.log(res.data);
        if (res.data.data && res.data.data.length) {
          _this2.strategyTypeList = res.data.data;
          _this2.strategyTypeListOne = res.data.data.filter(function (item) {
            return item.strategyTypeLevel == 1;
          });
          _this2.strategyTypeListTwo = res.data.data.filter(function (item) {
            return item.strategyTypeLevel == 2;
          });
        }
      });
    },
    // 筛选功能
    onSubmit: function onSubmit() {
      this.queryParams.pageNo = 1;
      this.getStrategy(this.formInline);
    },
    // 重置新增编辑表单
    resetFrom: function resetFrom() {
      // console.log("重置表单");
      this.ruleForm.id = '';
      this.ruleForm.strategyName = '';
      this.ruleForm.strategyTypeOne = null;
      this.ruleForm.strategyTypeTwo = null;
      this.ruleForm.strategyJsonExpandBo = {};
      this.awardBo = {
        expirationDay: null,
        // 保质期
        expirationRule: '0' // 超期策略
      };

      this.safeBo = {
        dayLotteryNumLimit: null,
        // 每人每天抽奖次数
        dayWinningNumLimit: null,
        // 每人每天最大中奖次数
        lotteryInterval: null,
        // 每人抽奖间隔（秒）
        totalMoneyLimit: null,
        // 单人活动抽总奖金额上限（元）
        totalNumLimit: null // 每人抽奖总次数
      };

      this.codeSafeBo = {
        bannedMinutes: null,
        // 封禁时长(分钟)
        dragIntoFlag: false,
        // 连续触发2次，是否拉入数码黑名单 true开启 false关闭
        errorNum: null // 同数码连续错误次数
      };
    },
    // 筛选重置
    reset: function reset() {
      this.formInline = {};
      this.getStrategy(this.formInline);
    },
    relevance: function relevance(value, data) {
      // 清空二级策略内容
      this.formInline.strategyTypeTwo = null;
      // 存储一级策略id
      this.parentId = value;
      var item = this.strategyTypeList.find(function (ele) {
        return ele.id == value;
      });
      if (item && item.parentId == 0) {
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return item.id == ele.parentId;
        });
      }
    },
    relevance2: function relevance2(val) {
      if (val == 3) {
        this.formInline.strategyTypeOne = 1;
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return ele.parentId == 1;
        });
      } else if (val == 4) {
        this.formInline.strategyTypeOne = 2;
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return ele.parentId == 2;
        });
      } else if (val == 5) {
        this.formInline.strategyTypeOne = 2;
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return ele.parentId == 2;
        });
      }
    },
    // 策略备注
    handleRemarks: function handleRemarks(row) {
      this.remark.id = row.id;
      this.remark.remark = row.remark;
      this.dialogFormVisible = true;
    },
    // 取消策略备注
    remarkCancel: function remarkCancel(value) {
      this.$refs[value].resetFields();
      this.remark.id = null;
      this.dialogFormVisible = false;
    },
    remarkEnter: function remarkEnter(ruleForms) {
      var _this3 = this;
      this.$refs[ruleForms].validate(function (valid) {
        if (valid) {
          (0, _policyManagement.remarks)(_this3.remark).then(function (res) {
            if (res.data.code !== 200) {
              _this3.$message.error('备注失败');
              return;
            } else {
              _this3.$message.success('备注成功');
              _this3.getStrategy();
              _this3.dialogFormVisible = false;
            }
          });
        }
      });
    },
    // 查看策略
    handleClick: function handleClick(data) {
      this.title = '查看策略配置';
      this.look = true;
      this.lookdata = data;
      console.log(this.lookdata);
    },
    handleClose: function handleClose() {
      this.look = false;
      this.lookdata = [];
    },
    // 删除策略
    handleDelete: function handleDelete(row) {
      var _this4 = this;
      this.$confirm("\u662F\u5426\u5220\u9664\u8BE5\u6570\u636E\u9879?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        (0, _policyManagement.deleteStrategy)({
          id: row.id
        }).then(function (res) {
          if (res.data.data.errCode == 500) {
            _this4.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this4.$message.success('删除成功');
            _this4.getStrategy();
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 编辑策略
    handleEdit: function handleEdit(row) {
      var arr = JSON.parse(JSON.stringify(row));
      this.ruleForm.id = arr.id;
      this.ruleForm.strategyName = arr.strategyName;
      this.ruleForm.strategyTypeOne = Number(arr.strategyTypeOne);
      this.ruleForm.strategyTypeTwo = Number(arr.strategyTypeTwo);
      this.ruleForm.strategyJsonExpandBo = arr.strategyJsonExpandBo;
      if (arr.strategyTypeTwo == 3) {
        this.isQuality = true;
        this.isShow = false;
        this.isCode = false;
        this.ruleForm.strategyTypeTwo = 3;
        this.ruleForm.strategyJsonExpandBo.awardBo.expirationRule = '0';
      } else if (arr.strategyTypeTwo == 4) {
        this.isShow = true;
        this.isQuality = false;
        this.isCode = false;
        this.ruleForm.strategyTypeTwo = 4;
      } else if (arr.strategyTypeTwo == 5) {
        this.isCode = true;
        this.isQuality = false;
        this.isShow = false;
        this.ruleForm.strategyTypeTwo = 5;
      }
      this.title = '编辑策略配置';
      this.dialogVisible = true;
      // this.$refs["ruleForm"].clearValidate();
    },
    // 策略类型选择二级
    strategyTypeTwo: function strategyTypeTwo(val) {
      /**
       * 一级策略id
       *  1 布奖策略
       *    3 保质期策略
       *  2 安全策略
       *    4 次数与金额
       *    5 数字安全
       */
      if (val == 3) {
        // 显示 保质期 超期策略选项
        this.isQuality = true;
        this.isShow = false;
        this.isCode = false;
        this.ruleForm.strategyTypeOne = 1;
        this.ruleForm.strategyJsonExpandBo = {};
        this.ruleForm.strategyJsonExpandBo.awardBo = this.awardBo;
        this.$refs['ruleForm'].clearValidate();
      } else if (val == 4) {
        // 显示 每人抽奖总次数等选项
        this.isShow = true;
        this.isQuality = false;
        this.isCode = false;
        this.ruleForm.strategyTypeOne = 2;
        this.ruleForm.strategyJsonExpandBo = {};
        this.ruleForm.strategyJsonExpandBo.safeBo = this.safeBo;
        this.$refs['ruleForm'].clearValidate();
      } else if (val == 5) {
        this.isCode = true;
        this.isQuality = false;
        this.isShow = false;
        this.ruleForm.strategyTypeOne = 2;
        this.ruleForm.strategyJsonExpandBo = {};
        this.ruleForm.strategyJsonExpandBo.codeSafeBo = this.codeSafeBo;
        this.$refs['ruleForm'].clearValidate();
      }
    },
    // 策略类型选择一级
    strategyType: function strategyType(value) {
      // 清空二级策略内容
      this.ruleForm.strategyTypeTwo = null;
      // 隐藏所有选项
      this.isQuality = false;
      this.isShow = false;
      this.isCode = false;
      // 存储一级策略id
      this.parentId = value;
      var item = this.strategyTypeList.find(function (ele) {
        return ele.id == value;
      });
      if (item && item.parentId == 0) {
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return item.id == ele.parentId;
        });
      }
    },
    // choicePrize(value) {
    // console.log(value);
    // this.isQuality = value == 1 ? true : false;
    // this.isStartContinuous = value == 2 ? true : false;
    // this.isPacking = value == 3 ? true : false;
    // this.isAppoint = value == 4 ? true : false;
    // },
    // 取消添加策略
    cancel: function cancel() {
      // console.log("清空");
      // this.$refs["ruleForm"].resetFields();
      this.resetFrom();
      this.isShow = false;
      this.isCode = false;
      this.isQuality = false;
      this.dialogVisible = false;
      this.getStrategyType();
    },
    // 确定添加策略
    determine: function determine(ruleForms) {
      var _this5 = this;
      this.$refs[ruleForms].validate(function (valid) {
        if (valid) {
          if (_this5.title == '新增策略') {
            (0, _policyManagement.addStrategy)(_this5.ruleForm).then(function (res) {
              if (res.data.data.errCode == 500) {
                return _this5.$message.error(res.data.data.errMsg);
              }
              _this5.$message.success('新增成功！');
              // this.dialogVisible = false;
              _this5.cancel();
              _this5.getStrategy();
            });
          } else {
            (0, _policyManagement.addStrategy)(_this5.ruleForm).then(function (res) {
              if (res.data.data.errCode == 500) {
                return _this5.$message.error(res.data.data.errMsg);
              }
              _this5.$message.success('编辑成功！');
              // this.dialogVisible = false;
              _this5.cancel();
              _this5.getStrategy();
            });
          }
        }
      });
    },
    // 新增策略按钮
    addStrategy: function addStrategy() {
      this.title = '新增策略';
      this.dialogVisible = true;
      if (this.ruleForm.id == '') {
        this.$refs['ruleForm'].clearValidate();
      }
    },
    // 分页功能
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getStrategy(this.formInline);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.queryParams.pageNo = val;
      this.getStrategy(this.formInline);
    },
    clearValidate: function clearValidate() {
      this.$refs['ruleForm'].clearValidate();
    },
    handleNodeClick: function handleNodeClick() {}
  }
};
exports.default = _default;