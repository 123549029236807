"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      handleShow: false,
      prostatus: [],
      categoryName: '',
      onename: '',
      twoname: '',
      dataForm: {
        prodName: ''
      },
      formInline: {
        prodName: null,
        categoryName: '',
        status: '',
        categoryId: null,
        pcategoryId: null,
        current: 1,
        // 当前页数
        size: 10
      },
      options: [],
      options1: [],
      // props: {
      //   checkStrictly: true,
      //   lazy: true,
      //   lazyLoad(node, resolve) {
      //     // const _self = this
      //     // console.log(node)
      //     // const { level } = node
      //     let addr;
      //     setTimeout(() => {
      //       if (node.level === 0) {
      //         // console.log('node====', node)

      //         if (addr) {
      //           return;
      //         }
      //         // _self.
      //         selectOneByData().then((res) => {
      //         var res = res.data
      //           // console.log(res)
      //           const nodes = res.map((item) => ({
      //             value: item.categoryId,
      //             label: item.categoryName,
      //             // leaf: node.level >= 4,
      //             categoryId: item.categoryId,
      //           }));
      //           resolve(nodes);
      //         });
      //       }
      //       if (node.level === 1) {
      //         // console.log('node1====', node)
      //         selectTwoByData(node.value).then((res) => {
      //   var res = res.data
      //           // console.log(res)
      //           var arrts = res.map((item) => ({
      //             value: item.categoryId,
      //             label: item.categoryName,
      //             // leaf: node.grade >= 1,
      //             categoryId: item.categoryId,
      //           }));
      //           resolve(arrts);
      //           addr = arrts;
      //         });
      //       }
      //       // const nodes = Array.from({ length: level + 1 })
      //       //   .map(item => ({
      //       //     value: ++id,
      //       //     label: `选项${id}`,
      //       //     leaf: level >= 2
      //       //   }))
      //       // 通过调用resolve将子节点数据返回，通知组件数据加载完成
      //       //  // console.log(nodes)
      //     }, 1000);
      //   },
      // },
      total: 0,
      // 总页数
      dataList: [{}],
      dataListSelections: [],
      listLoading: false
      // tableOption: tableOption,
      // resourcesUrl: window.SITE_CONFIG.resourcesUrl
    };
  },
  created: function created() {
    this.fetchData();
    this.getfl();
  },
  methods: {
    // 获取分类数据
    getfl: function getfl() {
      var _this = this;
      (0, _wXintegralMall.selectOneByData)().then(function (res) {
        res = res.data;
        // console.log(res)
        _this.options = res;
      });
    },
    getfl1: function getfl1(val) {
      var _this2 = this;
      this.formInline.categoryId = '';
      (0, _wXintegralMall.selectTwoByData)(val).then(function (res) {
        res = res.data;
        _this2.options1 = res;
      });
    },
    checkstatus: function checkstatus(e, id, index) {
      var _this3 = this;
      (0, _wXintegralMall.updateStatus)(id).then(function (res) {
        _this3.fetchData();
      });
    },
    handleoption: function handleoption(e) {
      var pathvalue = this.$refs.sysCascader.getCheckedNodes()[0];
      if (pathvalue.hasChildren) {
        this.formInline.pcategoryId = pathvalue.data.categoryId;
        this.formInline.categoryId = null;
      } else {
        this.formInline.categoryId = pathvalue.data.categoryId;
        this.formInline.pcategoryId = null;
      }
      this.formInline.categoryName = pathvalue.data.categoryId;
      //  // console.log(pathvalue)
    },
    restForm: function restForm(refname) {
      this.$refs['formInline'].resetFields();
      // console.log(this.formInline)
      this.formInline = {
        prodName: null,
        categoryName: '',
        status: null,
        categoryId: null,
        pcategoryId: null,
        current: 1,
        // 当前页数
        size: 10
      };
      this.fetchData();
    },
    // 获取数据列表
    fetchData: function fetchData() {
      var _this4 = this;
      this.listLoading = true;
      (0, _wXintegralMall.getProdPage)(this.formInline).then(function (data) {
        // console.log(data)
        data = data.data;
        if (data.records) {
          _this4.dataList = data.records;
          _this4.prostatus = [];
          _this4.dataList.forEach(function (item) {
            // console.log(item)
            if (item.status === 1) {
              var n = {
                status: true
              };
              _this4.prostatus.push(n);
            } else {
              var m = {
                status: false
              };
              _this4.prostatus.push(m);
            }
          });
          for (var key in _this4.dataList) {
            if (_this4.dataList.hasOwnProperty(key)) {
              var element = _this4.dataList[key];
              element.imgs = element.imgs.split(',')[0];
            }
          }
          _this4.total = data.total;
        }
        _this4.listLoading = false;
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(id) {
      this.$router.push({
        // path: '/wxAccounts/wxIntegralMall/wxProdListManage/wxProdInfo',
        name: 'wxAccountswxIntegralMallprodprodInfo',
        query: {
          prodId: id,
          disabled: false
        }
      });
    },
    showDetail: function showDetail(id) {
      this.$router.push({
        name: 'wxAccountswxIntegralMallprodprodInfo',
        // path: '/wxAccounts/wxIntegralMall/wxProdListManage/wxProdInfo',
        query: {
          prodId: id,
          disabled: true
        }
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this5 = this;
      this.$confirm("\u786E\u5B9A\u8981\u8FDB\u884C".concat(id ? '删除' : '批量删除', "\u64CD\u4F5C\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params;
        if (id) {
          params = id;
          (0, _wXintegralMall.delProd)(params).then(function (data) {
            _this5.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this5.fetchData();
              }
            });
          }).catch(function (err) {
            _this5.$message.closeAll();
            _this5.$message({
              message: err.response.data,
              type: 'error',
              duration: 1500
            });
          });
        } else {
          params = [];
          var isDeleteArr = [];
          _this5.dataListSelections.forEach(function (item) {
            if (item.status === 1) {
              isDeleteArr.push(item);
            } else {
              params.push(item.prodId);
            }
          });
          if (isDeleteArr.length > 0) {
            _this5.$message.error('请选择未上架的产品进行批量删除');
            return;
          }
          // console.log(params.join(','))
          (0, _wXintegralMall.delProds)({
            prodIds: params.join(',')
          }).then(function (data) {
            console.log(data);
            // if (data) {
            _this5.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this5.fetchData();
              }
            });
            // } else {
            //   this.$message({
            //     message: data,
            //     type: 'success',
            //     duration: 1500,
            //     onClose: () => {
            //       this.fetchData()
            //     }
            //   })
            // }
            //  // console.log(data)
            // this.$message({
            //   message: '操作成功',
            //   type: 'success',
            //   duration: 1500,
            //   onClose: () => {
            //     this.fetchData()
            //   }
            // })
          });
          // .catch(err => {
          //   this.$message.closeAll()
          //   this.$message({
          //     message: err,
          //     type: 'info',
          //     duration: 1500,
          //     onClose: () => {
          //       this.fetchData()
          //     }
          //   })
          // })
        }
      }).catch(function () {});
    },
    // 多选变化
    handleSelectionChange: function handleSelectionChange(val) {
      this.dataListSelections = val;
    }
  }
};
exports.default = _default;