var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "订单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orderNo", $$v)
                                      },
                                      expression: "listQuery.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "purchaseOwnerOrgId",
                                    label: "采购单位组织:",
                                  },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织",
                                      options: _vm.orgOptions,
                                    },
                                    model: {
                                      value:
                                        _vm.listQuery.channelOrderInfoQuery
                                          .purchaseOwnerOrgId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery.channelOrderInfoQuery,
                                          "purchaseOwnerOrgId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.channelOrderInfoQuery.purchaseOwnerOrgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "receiveComId",
                                    label: "采购单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getUnit("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.searchReceiveComName,
                                      callback: function ($$v) {
                                        _vm.searchReceiveComName = $$v
                                      },
                                      expression: "searchReceiveComName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "sendOutComId",
                                    label: "发货单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getUnit("out")
                                      },
                                    },
                                    model: {
                                      value: _vm.searchSendOutComName,
                                      callback: function ($$v) {
                                        _vm.searchSendOutComName = $$v
                                      },
                                      expression: "searchSendOutComName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "businessUser",
                                        label: "创建人:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          readonly: "",
                                          placeholder: "请输入",
                                        },
                                        on: { focus: _vm.chosePerson },
                                        model: {
                                          value: _vm.businessUser,
                                          callback: function ($$v) {
                                            _vm.businessUser = $$v
                                          },
                                          expression: "businessUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderTime",
                                        label: "订单日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.orderTime,
                                          callback: function ($$v) {
                                            _vm.orderTime = $$v
                                          },
                                          expression: "orderTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderStatus",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.orderStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orderStatus",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orderStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderStatus,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: [item.figure],
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  staticStyle: {
                                    "min-width": "273px",
                                    "padding-left": "10px",
                                    "padding-right": "0",
                                  },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.DoSearch },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoReset(
                                                    _vm.listQuery
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.expand },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.expandTxt) +
                                                  "\n                "
                                              ),
                                              _c("i", {
                                                class:
                                                  _vm.showCol == false
                                                    ? "el-icon-arrow-down"
                                                    : "el-icon-arrow-up",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.DoSearch },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n          "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group fun-btn-group" },
                    [
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.exportLoading,
                              },
                              on: { click: _vm.DoExportAll },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "订单编码",
                      "min-width": "160",
                      prop: "orderNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位组织",
                      "min-width": "120",
                      prop: "purchaseOwnerOrgName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位类型",
                      "min-width": "120",
                      prop: "purchaseComType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComType")(row.purchaseComType)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4254233862
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位",
                      "min-width": "120",
                      prop: "purchaseCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位类型",
                      "min-width": "120",
                      prop: "sendOutComType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComType")(row.sendOutComType)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2918446815
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      "min-width": "120",
                      prop: "sendOutCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      "min-width": "110",
                      prop: "creatorUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人类型",
                      "min-width": "110",
                      prop: "creatorType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("creatorType")(row.creatorType))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      519759814
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量(箱)",
                      "min-width": "110",
                      prop: "planTotalChestNum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量(盒)",
                      "min-width": "120",
                      prop: "planTotalBottleNum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStatusText")(row.orderStatus)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3420173691
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.apis)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3072384774
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.detailShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.detailShow,
                "before-close": _vm.detailClose,
                width: "1200px !important",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailShow = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购订单号:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.orderNo)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.purchaseComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("付款方式:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("payWayText")(_vm.addData.paymentStyle)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("订单日期:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("申请人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.applyUser)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.receiveComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveContacts)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人电话:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receivePhone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货地址:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveAddress)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("供货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("发货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.sendOutComName)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [
                    _vm._v("\n        产品信息\n        "),
                    _c("img", { attrs: { src: require("@/images/pro.png") } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "detailTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.productList,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: _vm.tableConfig.align,
                          prop: "productCode",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: _vm.tableConfig.align,
                          prop: "productName",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: _vm.tableConfig.align,
                          prop: "productModel",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: _vm.tableConfig.align,
                          prop: "packScaleExpression",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [_vm._v(_vm._s(row.chestNum))])
                                    : _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              value: row.chestNum,
                                              maxlength: "9",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (row.chestNum =
                                                  _vm.valENnum(e))
                                              },
                                              change: function ($event) {
                                                return _vm.changeChestNum(
                                                  _vm.$el,
                                                  row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1789067851
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [_vm._v(_vm._s(row.bottleNum))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1498079734
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元/盒)",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.productPrice)),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "only-number",
                                            rawName: "v-only-number",
                                            value: {
                                              min: 0,
                                              precision: 2,
                                              max: 99999999,
                                              set: row,
                                              name: "productPrice",
                                            },
                                            expression:
                                              "{\n                min: 0,\n                precision: 2,\n                max: 99999999,\n                set: row,\n                name: 'productPrice'\n              }",
                                          },
                                        ],
                                        staticStyle: { "text-align": "right" },
                                        on: { change: _vm.resetList },
                                        model: {
                                          value: row.productPrice,
                                          callback: function ($$v) {
                                            _vm.$set(row, "productPrice", $$v)
                                          },
                                          expression: "row.productPrice",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          421812965
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      row.totalPrice == 0 ? "-" : row.totalPrice
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1304949539
                        ),
                      }),
                      _vm._v(" "),
                      _vm.subType != "scan" && _vm.addData.orderStatus == 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoSc(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              906714060
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c(
                      "div",
                      { staticStyle: { "min-width": "250px" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "赠品产品及酒劵兑换产品不计金额",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "tipBtn",
                                attrs: { type: "text" },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info tipIcon",
                                }),
                                _vm._v(
                                  "\n              总金额：\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n          " + _vm._s(_vm.totalMoney) + "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "大写金额：" +
                          _vm._s(_vm._f("chnMoney")(_vm.totalMoney))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("备注")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _vm.subType == "scan" || _vm.addData.orderStatus != 1
                            ? _c("span", [_vm._v(_vm._s(_vm.addData.remark))])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                    _vm.subType == "scan"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderStatusText")(_vm.addData.orderStatus)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.detailClose } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _vm.personShow
        ? _c("personChose", {
            attrs: { "person-visible": _vm.personShow },
            on: { dialogHide: _vm.hidePerson, select: _vm.getPerson },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }