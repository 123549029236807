"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flagTemplatePage = flagTemplatePage;
exports.supplierRFlagList = supplierRFlagList;
exports.supplierRFlagSave = supplierRFlagSave;
exports.supplierWithFlag = supplierWithFlag;
var _request = _interopRequireDefault(require("@/utils/request"));
function supplierRFlagList(params) {
  return (0, _request.default)({
    url: '/api-mark/supplierRFlag/search',
    method: 'get',
    params: params
  });
}
function flagTemplatePage(params) {
  return (0, _request.default)({
    url: '/api-mark/supplierRFlag/flagTemplatePage',
    method: 'get',
    params: params
  });
}
function supplierRFlagSave(data) {
  return (0, _request.default)({
    url: '/api-mark/supplierRFlag/save',
    method: 'post',
    data: data
  });
}

// 新供应商选择
function supplierWithFlag(params) {
  return (0, _request.default)({
    url: '/api-mark/supplierRFlag/supplierWithFlag',
    method: 'get',
    params: params
  });
}