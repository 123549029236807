"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setItem = require("@/api/systems/setItem");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const cachName = 'systemshsetindex'
var _default = {
  name: 'Systemshsetindex',
  data: function data() {
    return {
      radioData: [],
      radio: '',
      listLoading: false,
      apis: []
    };
  },
  mounted: function mounted() {},
  activated: function activated() {
    var that = this;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    that.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _setItem.getAllItems)().then(function (response) {
        if (response.data.code != 200) {
          that.listLoading = false;
          that.$message.error(response.data.msg);
          return;
        } else {
          that.radioData = response.data.data;
          that.listLoading = false;
        }
      }).then(function () {
        (0, _setItem.getSlectedItem)().then(function (res) {
          if (res.data.code == 200) {
            that.radio = res.data.data;
          }
        });
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    saveItem: function saveItem(e) {
      var that = this;
      that.authId('savebtn', that.apis);
      var param = {};
      param.itemValue = e;
      (0, _setItem.saveItems)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '配置成功'
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    }
  }
};
exports.default = _default;