"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompyUser = addCompyUser;
exports.checkPwd = exports.checkPhone = exports.checkEmail = void 0;
exports.distributionUserRole = distributionUserRole;
exports.editUserInfo = editUserInfo;
exports.getAllQdRoles = getAllQdRoles;
exports.getCompanyList = getCompanyList;
exports.getQdList = getQdList;
exports.getRoleUser = getRoleUser;
exports.getUserInfo = getUserInfo;
exports.scanUserRole = scanUserRole;
exports.setUserPwd = setUserPwd;
exports.startItems = startItems;
exports.startUser = startUser;
exports.stopItems = stopItems;
exports.stopUser = stopUser;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取渠道角色下拉列表
function getAllQdRoles() {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getAllQdRoles',
    method: 'get'
  });
}

// 所属渠道
function getQdList(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/list',
    method: 'post',
    params: param
  });
}

// 获取渠道用户列表
function getCompanyList(param, data) {
  return (0, _request.default)({
    url: '/api-user/userInfo/channelList?current=' + param.current + '&size=' + param.size,
    method: 'post',
    data: data
  });
}

// 添加渠道用户
function addCompyUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/addChannelUserInfo',
    method: 'post',
    params: param
  });
}

// 获取渠道用户详情
function getUserInfo(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/getChannelUserDetail',
    method: 'get',
    params: param
  });
}

// 批量禁用
function stopItems(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateChannelBatchEnableEnd',
    method: 'put',
    params: param
  });
}

// 批量启用
function startItems(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateChannelBatchEnableStart',
    method: 'put',
    params: param
  });
}

// 启用
function startUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateChannelEnableStart',
    method: 'put',
    params: param
  });
}

// 禁用
function stopUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateChannelEnableEnd',
    method: 'put',
    params: param
  });
}

// 重置渠道用户密码
function setUserPwd(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/resettingChannelPwd',
    method: 'put',
    params: param
  });
}

// 获取渠道用户可分配角色
function getRoleUser(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/getQdForList',
    method: 'get',
    params: param
  });
}

// 渠道用户分配角色
function distributionUserRole(param) {
  return (0, _request.default)({
    url: '/api-user/userRRole/distributionChannelUserRole',
    method: 'post',
    data: param
  });
}

// 渠道查看用户角色
function scanUserRole(param) {
  return (0, _request.default)({
    url: '/api-user/userRRole/getChannelUserRole',
    method: 'get',
    params: param
  });
}

// 修改渠道用户
function editUserInfo(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateChannelUserInfo',
    method: 'put',
    params: param
  });
}

// 验证规则
var checkPwd = function checkPwd(rule, value, callback) {
  var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/;
  if (value) {
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('密码必须为字母、数字组合，长度为8位'));
    }
  } else {
    return callback(new Error('请输入密码'));
  }
};
exports.checkPwd = checkPwd;
var checkPhone = function checkPhone(rule, value, callback) {
  if (value) {
    var reg = /^1[23456789]\d{9}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  } else {
    return callback(new Error('请输入手机号'));
  }
};
exports.checkPhone = checkPhone;
var checkEmail = function checkEmail(rule, value, callback) {
  if (value) {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确格式邮箱'));
    }
  } else {
    callback();
  }
};
exports.checkEmail = checkEmail;