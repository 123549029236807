"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostrequestCcindex',
  components: {
    Pagination: _Pagination.default,
    companySelectDialog: _companySelectDialog.default
  },
  filters: {
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value * 1];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: 'success',
        6: 'danger',
        7: 'danger',
        8: '',
        9: 'success',
        10: 'danger'
      };
      return obj[val];
    }
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        size: 20,
        beginDate: '',
        businessType: 1,
        orderType: '',
        endDate: '',
        orderNo: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        saleOrgId: '',
        tradeStatus: '',
        tradeType: 4
      },
      orderStatus: [],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      inUseName: '',
      companyShow: false,
      // 查询收货单位名称
      searchReceiveOrgName: '',
      // 查询采购单位名称
      searchPurchaseName: '',
      // 查询销售单位名称
      searchSaleOrgName: '',
      orgId: '',
      inOutType: '',
      companycanChange: true,
      companyType: '',
      isEnable: '',
      normalList: [],
      showCol: false,
      expandTxt: '展开'
    };
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.getDirSel('CCCGDDZT', 'orderStatus');
    this.getCompanyList();
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getCompanyList: function getCompanyList() {
      var _this2 = this;
      (0, _order.companyList)({
        current: 1,
        size: 2147483647
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.normalList = res.data.data.records;
          } else {
            _this2.normalList = [];
          }
        }
      });
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var param = JSON.parse(JSON.stringify(that.listQuery));
      (0, _order.queryJudgeApplyForPage)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      this.authId('addbtn', this.$route.meta.authList);
      this.$router.push({
        name: 'orderCentercostrequest-ccconfig'
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchReceiveOrgName':
          this.listQuery.receiveOrgId = val.companyId;
          break;
        case 'searchPurchaseName':
          this.listQuery.purchaseOrgId = val.companyId;
          break;
        case 'searchSaleOrgName':
          this.listQuery.saleOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.searchReceiveOrgName = '';
      this.searchPurchaseName = '';
      this.searchSaleOrgName = '';
      this.listQuery = {
        orderType: '',
        current: 1,
        size: 20,
        beginDate: '',
        businessType: 1,
        endDate: '',
        orderNo: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        saleOrgId: '',
        tradeStatus: '',
        tradeType: 4
      };
      this.getList();
    },
    // 查看
    DoScan: function DoScan(data) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$router.push({
        name: 'orderCentercostrequest-ccdetail',
        query: {
          id: data.orderNo
        }
      });
    },
    // 修改
    DoEdit: function DoEdit(data) {
      this.authId('updatebtn', this.$route.meta.authList);
      this.$router.push({
        name: 'orderCentercostrequest-ccupdate',
        query: {
          id: data.orderNo
        }
      });
    }
  }
};
exports.default = _default;