"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTgData = addTgData;
exports.chageState = chageState;
exports.chageTypeState = chageTypeState;
exports.downLoadFiles = downLoadFiles;
exports.exportData = exportData;
exports.getGroupDetail = getGroupDetail;
exports.getGroupList = getGroupList;
exports.getQueryOrg = getQueryOrg;
exports.getTgTypeDetail = getTgTypeDetail;
exports.getTgTypeList = getTgTypeList;
exports.tgTypeInsert = tgTypeInsert;
exports.updateTgData = updateTgData;
exports.updateTgTypeData = updateTgTypeData;
exports.uploadFiles = uploadFiles;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取团购类型列表
function getTgTypeList(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupBuyType/getList',
    method: 'post',
    params: param
  });
}

// 添加团购类型
function tgTypeInsert(data) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupBuyType/insert',
    method: 'post',
    data: data
  });
}

// 编辑团购类型
function updateTgTypeData(data) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupBuyType/update',
    method: 'post',
    data: data
  });
}

// 团购类型禁启用
function chageTypeState(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupBuyType/status',
    method: 'post',
    params: param
  });
}

// //团购类型详情
// export function getTgTypeDetail(param) {
//   return request({
//     url: '/api-channel/jcGroupBuyType/getDetailById',
//     method: 'post',
//     params:param
//   })
// }

// 团购类型详情
function getTgTypeDetail(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupBuyType/getDetailById',
    method: 'post',
    params: param
  });
}

// 获取团购档案列表
function getGroupList(param) {
  return (0, _request.default)({
    url: 'api-basic/jcGroupArchives/getList',
    method: 'post',
    params: param
  });
}

// 添加团购档案
function addTgData(data) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/insert',
    method: 'post',
    data: data
  });
}

// 编辑团购档案
function updateTgData(data) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/update',
    method: 'post',
    data: data
  });
}

// 启用,停用，删除
function chageState(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/status',
    method: 'post',
    params: param
  });
}

// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: '',
    orgType: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 团购档案导出
function exportData(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/exportExcel',
    responseType: 'blob',
    method: 'post',
    params: param
  });
}

// 获取团购档案详情
function getGroupDetail(param) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/getDetailById',
    method: 'post',
    params: param
  });
}

// 文件上传
function uploadFiles(data) {
  return (0, _request.default)({
    url: '/api-basic/jcGroupArchives/importExcel',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

// 下载团购档案
function downLoadFiles() {
  return (0, _request.default)({
    url: 'api-basic/jcGroupArchives/exportExcelTemplate',
    responseType: 'blob',
    method: 'post'
  });
}