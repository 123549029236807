"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _param = require("@/api/chanelmange/param");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    stateFilter: function stateFilter(status) {
      var statusMap = {
        100: '待审核',
        200: '审核通过',
        300: '驳回',
        400: '结束',
        500: '新增',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  props: {
    dialogVisible: Boolean
  },
  data: function data() {
    return {
      tabHeight: '100%',
      list: [],
      listLoading: true,
      total: 0,
      formInline: {
        pageNo: 1,
        pageSize: 10,
        policyId: '',
        endTime: '',
        calculation: '',
        name: '',
        orderno: '',
        startTime: '',
        state: '',
        type: ''
      },
      presentDate: new Date().getTime(),
      handleShow: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formInline.policyId = this.policyId;
        this.fetchData();
      }
    }
  },
  methods: {
    // 重置表单
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取订单列表
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      var obj = {
        startTime: this.formInline.startTime ? this.formInline.startTime + ' 00:00:00' : '',
        endTime: this.formInline.endTime ? this.formInline.endTime + ' 23:59:59' : ''
      };
      Object.assign(this.formInline, obj);
      (0, _param.getActInfoByPolicy)(this.formInline).then(function (res) {
        if (res.data.code === 200) {
          _this.list = res.data.data.list;
          _this.total = Number(res.data.data.total);
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    // 时间函数
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.endTime = val[1];
        this.formInline.startTime = val[0];
      } else {
        this.formInline.endTime = '';
        this.formInline.startTime = '';
      }
    },
    getTimeFun: function getTimeFun(val) {
      var num = new Date(val).getTime();
      return num;
    }
  }
};
exports.default = _default;