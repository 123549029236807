var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "系统用户-查看数据权限",
        model: _vm.auth,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      [
        _c(
          "el-form",
          { attrs: { data: _vm.uname } },
          [
            _c(
              "el-form-item",
              { attrs: { label: "用户名:", prop: "userName" } },
              [_c("span", [_vm._v(_vm._s(_vm.uname.userName))])]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "组织", name: "first" } },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.data,
                    props: _vm.defaultProps,
                    "default-expand-all": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              { attrs: { label: "库房", name: "second" } },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.storelist,
                      border: "",
                      stripe: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "库房编码",
                        prop: "storeHouseCode",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "库房名称",
                        prop: "storeHouseName",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属工厂",
                        prop: "factoryName",
                        width: "120",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属组织",
                        prop: "orgName",
                        width: "120",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        prop: "isEnable",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.isEnable == 1
                                ? _c("span", [_vm._v("启用")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.isEnable == 0
                                ? _c("span", [_vm._v("禁用")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }