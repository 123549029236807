"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkZZSNum = exports.checkWord = exports.checkRequiredWord = exports.checkRequiredTxt = exports.checkRequiredNum = exports.checkPhone = exports.checkPackNum = exports.checkNum = exports.checkName = exports.checkMixZm = exports.checkHolk = exports.checkH5 = exports.checkFloat3 = exports.checkConstraint1 = exports.checkConstraint = exports.checkBzNum = exports.checkAdress = void 0;
exports.isExternal = isExternal;
exports.unReqiuredMixZm = exports.requiredLink = void 0;
exports.validUsername = validUsername;
require("core-js/modules/es6.regexp.replace");
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
var checkNum = function checkNum(rule, value, callback) {
  if (value) {
    var reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正数'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
exports.checkNum = checkNum;
var checkBzNum = function checkBzNum(rule, value, callback) {
  if (value) {
    var reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正数'));
    } else {
      if (value >= 1000) {
        return callback(new Error('最大长度为3'));
      } else {
        callback();
      }
    }
  } else {
    callback();
  }
};
exports.checkBzNum = checkBzNum;
var checkZZSNum = function checkZZSNum(rule, value, callback) {
  if (value) {
    var reg = /^[0-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正整数'));
    } else {
      if (value >= 1000) {
        return callback(new Error('最大长度为3'));
      } else {
        callback();
      }
    }
  } else {
    callback();
  }
};
exports.checkZZSNum = checkZZSNum;
var checkHolk = function checkHolk(rule, value, callback) {
  if (value) {
    var reg = /^(([^0][0-9]+|0)\.([0-9]{1})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1})$)|^(([1-9]+)$)/;
    if (reg.test(value)) {
      if (value > 100) {
        return callback(new Error('最大酒精度数不超过100'));
      } else {
        callback();
      }
    } else {
      return callback(new Error('请输入小于100的整数或小数点后保留一位有效数字的正数'));
    }
  } else {
    return callback(new Error('请输入,不能为空'));
  }
};
exports.checkHolk = checkHolk;
var checkWord = function checkWord(rule, value, callback) {
  if (value) {
    var reg = /[^\u4e00-\u9fa5a-zA-Z\d\,.，。、]+/;
    value = value.replace(/\s+/g, '');
    if (reg.test(value)) {
      return callback(new Error('不能含有非法字符'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
exports.checkWord = checkWord;
var checkRequiredWord = function checkRequiredWord(rule, value, callback) {
  if (value) {
    var reg = /^[\u4E00-\u9FA5\uf900-\ufa2d\w\,.，。、;]+$/;
    value = value.replace(/\s+/g, '');
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('不能含有非法字符'));
    }
  } else {
    return callback(new Error('请输入，不能为空'));
  }
};
exports.checkRequiredWord = checkRequiredWord;
var checkRequiredTxt = function checkRequiredTxt(rule, value, callback) {
  if (value) {
    var reg = /[@#\￥%\^&\*]+/;
    value = value.replace(/\s+/g, '');
    if (!reg.test(value)) {
      callback();
    } else {
      return callback(new Error('不能含有@#￥%^&*非法字符'));
    }
  } else {
    return callback(new Error('请输入，不能为空'));
  }
};
exports.checkRequiredTxt = checkRequiredTxt;
var checkPhone = function checkPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else {
    var reg = /^1[0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
};
exports.checkPhone = checkPhone;
var checkMixZm = function checkMixZm(rule, value, callback) {
  if (!value) {
    return callback(new Error('不能为空'));
  } else {
    var reg = /^[0-9a-zA-Z]*$/g;
    var value = value.replace(/\s+/g, '');
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入字母，数字或字母数字组合'));
    }
  }
};
exports.checkMixZm = checkMixZm;
var unReqiuredMixZm = function unReqiuredMixZm(rule, value, callback) {
  // var value=value.replace(/\s+/g,"");
  if (!value) {
    return callback(new Error('不能为空'));
  } else {
    var reg = /^[0-9a-zA-Z]*$/g;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入字母，数字或字母数字组合'));
    }
  }
};
exports.unReqiuredMixZm = unReqiuredMixZm;
var requiredLink = function requiredLink(rule, value, callback) {
  if (!value) {
    return callback(new Error('不能为空'));
  } else {
    value = value.replace(/\s+/g, '');
    var reg = /^(\w+:\/\/)?\w+(\.\w+)+.*$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的网址'));
    }
  }
};
exports.requiredLink = requiredLink;
var checkRequiredNum = function checkRequiredNum(rule, value, callback) {
  if (value) {
    var reg = /^[0-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正整数'));
    } else {
      callback();
    }
  } else {
    return callback(new Error('请输入数字，最大长度15'));
  }
};
exports.checkRequiredNum = checkRequiredNum;
var checkConstraint = function checkConstraint(rule, value, callback) {
  if (value) {
    var reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入大于0的正整数'));
    } else {
      callback();
    }
  } else {
    return callback(new Error('请输入大于0的正整数，最大8位'));
  }
};
exports.checkConstraint = checkConstraint;
var checkConstraint1 = function checkConstraint1(rule, value, callback) {
  if (value) {
    var reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入大于0的正整数'));
    } else {
      callback();
    }
  } else {
    return callback(new Error('请输入大于0的正整数，最大3位'));
  }
};
exports.checkConstraint1 = checkConstraint1;
var checkAdress = function checkAdress(rule, value, callback) {
  if (value) {
    var value = value.replace(/\s+/g, '');
    var reg = /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]|["',，.。/、\]\[【】\！!?？——_<>%;‘’；)《（）》(&+=`“”·*#@@]){0,}$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入汉字，字母，数字，及常用标点符号'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
exports.checkAdress = checkAdress;
var checkH5 = function checkH5(rule, value, callback) {
  if (value) {
    value = value.replace(/\s+/g, '');
    if (value.length == 0) {
      return callback(new Error('请输入H5名称'));
    } else {
      callback();
    }
  } else {
    return callback(new Error('请输入H5名称'));
  }
};
exports.checkH5 = checkH5;
var checkFloat3 = function checkFloat3(rule, value, callback) {
  if (value) {
    var reg = /^(([^0][0-9]+|0)\.([0-9]{1,2,3})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2,3})$)|^(([1-9]+)$)/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正整数或者小数点后最多保留3位有效数字的正数'));
    }
  } else {
    return callback(new Error('请输入,不能为空'));
  }
};
exports.checkFloat3 = checkFloat3;
var checkPackNum = function checkPackNum(rule, value, callback) {
  if (value) {
    var reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正确的包装数量(正整数,最大长度为2)'));
    } else {
      if (value >= 100) {
        return callback(new Error('最大长度为2'));
      } else {
        callback();
      }
    }
  } else {
    return callback(new Error('请输入包装数量'));
  }
};
exports.checkPackNum = checkPackNum;
var checkName = function checkName(rule, value, callback) {
  var len = 0;
  if (!value) {
    callback(new Error('请输入,不能为空'));
  } else {
    for (var i = 0; i < value.length; i++) {
      var c = value.charCodeAt(i);
      // 单字节加1
      if (c >= 0x0001 && c <= 0x007e || c >= 0xff60 && c <= 0xff9f) {
        len++;
      } else {
        len += 2;
      }
    }
    if (len > 50) {
      callback(new Error('最多为不超过50个字符，相当于25个汉字。'));
    }
    callback();
  }
};
exports.checkName = checkName;