"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endPrize_Rule = endPrize_Rule;
exports.exportPrize_Rule = exportPrize_Rule;
exports.getPrize_Rule_Detail = getPrize_Rule_Detail;
exports.getRuleListPage = getRuleListPage;
exports.saveOrUpdate_Rule = saveOrUpdate_Rule;
exports.startPrize_Rule = startPrize_Rule;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = process.env.VUE_APP_VERSION2;
function getRuleListPage(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/getRuleListPage',
    method: 'post',
    data: data
  });
}
function exportPrize_Rule(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/getRuleListExp',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}
function startPrize_Rule(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/startRule',
    method: 'post',
    params: params
  });
}
function endPrize_Rule(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/endRule',
    method: 'post',
    params: params
  });
}
function getPrize_Rule_Detail(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/ruleDetail',
    method: 'get',
    params: params
  });
}
function saveOrUpdate_Rule(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/wordExchangeRule/saveOrUpdate',
    method: 'post',
    data: data
  });
}