"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _rebate = require("@/api/chanelmange/rebate");
var _codeScan = _interopRequireDefault(require("./codeScan"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var orgs = [];
var _default = {
  name: '',
  components: {
    codeScan: _codeScan.default
  },
  props: {},
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    }),
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success'
        };
        return statusMap[value];
      }
    },
    filterOrg: function filterOrg(value) {
      var name = '';
      if (orgs.length > 0) {
        orgs.forEach(function (item) {
          if (item.pid == value) {
            name = item.orgName;
            return;
          }
        });
      }
      return name;
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      title: '查看详情',
      num: '',
      active: 0,
      detailVisible: false,
      mloading: false,
      listLoading: false,
      scanData: {},
      temp: {},
      flowList: [],
      settype: 'scan',
      showflow: false,
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      detailData: {},
      proList: [],
      tradeOperateLogDTOS: [],
      codeVisible: false,
      billNo: '',
      productId: '',
      activeName: '0',
      tabName: '0',
      remarks: '',
      response: {}
    }, (0, _defineProperty2.default)(_ref, "codeVisible", false), (0, _defineProperty2.default)(_ref, "id", ''), (0, _defineProperty2.default)(_ref, "rebateNo", ''), _ref;
  },
  created: function created() {
    var that = this;
    orgs = that.$parent.orgOrigin;
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    shows: function shows(rows, settype) {
      var that = this;
      orgs = that.$parent.orgOrigin;
      that.response = rows;
      if (settype == 'scan') {
        that.title = '查看详情';
      }
      if (settype == 'sh') {
        that.title = '审核详情';
      }
      var argument = {};
      argument.billId = rows.id;
      argument.billType = '0';
      var param = {};
      param.id = rows.id;
      param.userType = rows.channelArchiveType;
      that.mloading = true;
      that.detailVisible = true;
      (0, _rebate.getVerifyDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          switch (res.data.data.status) {
            case '0':
              that.active = 1;
              break;
            case '1':
              that.active = 2;
              break;
            default:
              that.active = 0;
          }
          var resObj = (0, _utils.deepClone)(res.data.data);
          if (res.data.data.rebateList && res.data.data.rebateList.length) {
            resObj.rebateList = (0, _utils.uniqueJsonArray)(res.data.data.rebateList, 'rebateNo');
          }
          if (res.data.data.freezeRebateList && res.data.data.freezeRebateList.length) {
            resObj.freezeRebateList = (0, _utils.uniqueJsonArray)(res.data.data.freezeRebateList, 'rebateNo');
          }
          that.detailData = resObj;
        } else {
          that.mloading = false;
          that.$message.error(res.data.msg);
          that.detailData = {};
          that.proList = [];
          return;
        }
      }).then(function () {
        (0, _rebate.getCheckApplyLog)(argument).then(function (res) {
          that.mloading = false;
          if (res.data.code == 200) {
            that.tradeOperateLogDTOS = res.data.data;
          }
        }).catch(function () {
          that.mloading = false;
        });
      }).catch(function () {
        that.mloading = false;
      });
    },
    handleClick: function handleClick(tab, event) {
      var that = this;
      var index = tab.index;
      that.tabName = index;
    },
    handleClose: function handleClose() {
      this.detailVisible = false;
    },
    doSh: function doSh() {
      var that = this;
      var paramData = that.response;
      var param = {};
      that.mloading = true;
      if (typeof paramData.id != 'undefined') {
        param.billId = paramData.id;
        param.checkerId = paramData.checkerId;
        param.checkerType = paramData.checkerType;
        param.remarks = that.remarks;
        param.status = 1;
        (0, _rebate.checkApplyVerify)(param).then(function (res) {
          if (res.data.code == 200) {
            that.mloading = false;
            that.$message({
              type: 'success',
              message: '审核通过'
            });
          } else {
            that.mloading = false;
            that.$message.error(res.data.msg);
          }
        }).catch(function () {
          that.mloading = false;
        });
      } else {
        that.mloading = false;
        that.$message.error('参数缺失，无法审核通过');
      }
    },
    closeSh: function closeSh() {
      var that = this;
      that.remarks = '';
      that.detailVisible = false;
    },
    scanDetail: function scanDetail(data) {
      var that = this;
      that.rebateNo = data.rebateNo;
      if (that.tabName == '0') {
        that.id = that.detailData.channelArchiveId;
      }
      if (that.tabName == '1') {
        that.id = that.detailData.checkerId;
      }
      that.codeVisible = true;
    },
    hideDialog: function hideDialog() {
      var that = this;
      that.codeVisible = false;
    }
  }
};
exports.default = _default;