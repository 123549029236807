"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _factory = require("@/api/basic/factory");
var _pack = require("@/api/basic/pack");
var _warranty = require("@/api/basic/warranty");
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _store = require("@/api/store");
var _dictionary = require("@/api/systems/dictionary");
var _auth = require("@/utils/auth");
var _org = require("@/api/basic/org");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qs = _interopRequireDefault(require("qs"));
var _UploadImgS = _interopRequireDefault(require("@/components/UploadImgS"));
var _utils = require("@/utils");
var _detail = _interopRequireDefault(require("./detail.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var planNum = '';
var groupList = [];
var _default = {
  name: 'StoreServicestoreManageproductionPlanindex',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    channelDialog: _channelSelectDialog.default,
    Arrange: _detail.default
  },
  filters: {
    groupName: function groupName(status) {
      var t = '';
      groupList.forEach(function (item) {
        if (item.figure == status) {
          t = item.name;
        }
      });
      return t;
    },
    statusText: function statusText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '草稿',
        5: '已创建，待审核',
        7: '已审核，未通过',
        10: '已审核，待下载',
        13: '已下载，执行中',
        16: '执行完成'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '系统同步',
        2: '平台创建'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    // const checkpackNum = (rule, value, callback) => {
    //   console.log(value)
    //   callback()
    // }
    return {
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      handleShow: false,
      groupShow: false,
      isAudit: false,
      factoryList: [],
      saleorgList: [],
      workshopList: [],
      zhiInfo: {
        itemList: []
      },
      scanworkshopList: [],
      workshopListLoading: false,
      scanworkshopListLoading: false,
      lineList: [],
      scanlineList: [],
      zhibaoList: [],
      lineListLoading: false,
      scanlineListLoading: false,
      teamList: [],
      scanteamList: [],
      teamListLoading: false,
      scanteamListLoading: false,
      dialogBaseWine: false,
      boxList: [],
      storeList: [],
      storeSELList: [],
      codeList: [],
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        channelType: 1,
        uploadFile: ''
      },
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      uploadRules: {},
      fileList: [],
      proList: [],
      searchForm: {
        orderNo: null,
        current: 1,
        factories: '',
        custName: null,
        custCode: null,
        crmOrderNo: null,
        areaCode: null,
        className: null,
        orderStatus: null,
        orderSource: null,
        orderType: null,
        orgName: null,
        productCode: null,
        size: 20,
        startTime: null,
        productionStatus: null,
        productName: null,
        productSpec: null,
        sapOrderNo: null,
        thirdOrderNo: null
      },
      total: 0,
      codetotal: 0,
      tableData: [],
      tableKey: '01004',
      listLoading: false,
      btnLoading: false,
      codeListLoading: false,
      downLoading: false,
      orderNoloading: false,
      mals: '',
      orderStatus: '',
      title: '生产计划详情',
      multipleSelection: [],
      productTime: '',
      scanData: {
        backBottleSum: '',
        backChestSum: '',
        coefficient: '',
        custCode: '',
        factoryCode: '',
        orderNo: '',
        orderTypeCode: '',
        policyCode: '',
        productCode: '',
        productSpec: '',
        remark: '',
        thirdOrderNo: '',
        channelClassName: '',
        areaCode: '',
        provinceName: '',
        cityName: '',
        regionName: ''
      },
      channelName: '',
      pid: '',
      productName: '',
      detailData: {},
      rules: {
        orderTypeCode: [{
          required: true,
          message: '订单类型不能为空，请维护',
          trigger: 'change'
        }],
        factoryCode: [{
          required: true,
          message: '生产工厂不能为空，请维护',
          trigger: 'change'
        }],
        policyCode: [{
          required: true,
          message: '质保政策不能为空，请维护',
          trigger: 'change'
        }],
        coefficient: [{
          required: true,
          message: '系数不能为空，请维护',
          trigger: 'change'
        }],
        backChestSum: [{
          required: true,
          message: '组数不能为空，请维护',
          trigger: 'blur'
        }],
        productSpec: [{
          required: true,
          message: '规格型号不能为空，请维护',
          trigger: 'blur'
        }]
      },
      detailForm: {
        current: 1,
        size: 10,
        orderNo: ''
      },
      materialId: '',
      dialogVisible: false,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      groupList: [],
      page2: false,
      tabHeight: '100%',
      submitType: '',
      groupId: '',
      failVisible: false,
      failList: [],
      failQuery: {
        materialId: '',
        orderNo: '',
        current: 1,
        size: 10
      },
      failtotal: 0,
      auditForm: {
        auditReasons: '',
        handlerResult: ''
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      detailtable: [],
      arrangeShow: false,
      shAuth: true,
      traychestScaleCodeList: [],
      dialogChoose: false,
      baseWineLess: {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      },
      baseWineName: '',
      xiList: [],
      orderList: [],
      channelList: [],
      // baseWineLesslist: {
      //   baseWineCode: '',
      //   baseWineName: '',
      //   btnId: '',
      //   current: 1,
      //   isEnable: 0,
      //   size: 1000
      // },
      tableChoose: [],
      factoryId: '',
      totalBaseWineLess: 0,
      multipleList: [],
      flag: false,
      tableChooselist: [],
      listtableLoading: false,
      channelSelectType: '',
      pickerOptions: {
        disableDate: function disableDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-prod/productPlanOrder/planOrder/importFromExcel';
    }
  },
  created: function created() {
    var that = this;
    var auths = (0, _utils.getAuthBtn)();
    var id = 'scjhsh';
    if (auths.length > 0) {
      for (var i = 0; i < auths.length; i++) {
        if (auths[i].resourceId == id) {
          that.shAuth = true;
          break;
        }
      }
    } else {
      that.shAuth = false;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var _this2 = this;
    var that = this;
    this.authId('searchbtn', this.$route.meta.authList);
    (0, _dictionary.getItemByDataDictionaryByCode)({
      code: 'XSDLX_CRM'
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          _this2.orderList = res.data.data;
          if (_this2.orderList) {
            _this2.orderList.forEach(function (el) {
              _this2.$set(el, 'newName', el.bewrite + '[' + el.name + ']');
            });
          }
        } else {
          _this2.orderList = [];
        }
      }
    });
    (0, _dictionary.getItemByDataDictionaryByCode)({
      code: 'SCXS'
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          _this2.xiList = res.data.data;
        } else {
          _this2.xiList = [];
        }
      }
    });
    (0, _store.findAllChannelClass)({}).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          _this2.channelList = res.data.data;
        } else {
          _this2.channelList = [];
        }
      }
    });
    (0, _org.fetchOrgList)({}).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          _this2.saleorgList = res.data.data;
        } else {
          _this2.saleorgList = [];
        }
      }
    });
    this.getList();
    this.getFactoryList();
    // this.getFactoryList(2, '', 'workshopList')
    // this.getFactoryList(4, '', 'teamList')
    // this.getFactoryList(2, '', 'scanworkshopList')
    // this.getFactoryList(3, '', 'scanlineList')
    // this.getFactoryList(4, '', 'scanteamList')
    this.getStoreList();
    this.getBaseWineList();
    // this.baseWineList()
  },

  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    number: function number(e) {
      var _value = e.target.value; // 获取到输入框的value值
      var reg = isNaN(_value); // 判断拿到的value是否为数字
      if (reg) {
        // 若为非数字键，则全部替换为空
        e.target.value = _value.replace(/[^1-9]/g, '');
      }
      if (_value === '0') {
        // 若为0，则替换为空
        e.target.value = _value.replace(/^[^1-9]/g, '');
      }
    },
    changefactory: function changefactory(e) {
      var obj = this.factoryList.find(function (el) {
        return el.factoryCode == e;
      });
      this.factoryId = obj.pId;
      this.productName = '';
      this.scanData.productSpec = '';
    },
    clearProduct: function clearProduct(e) {
      this.productName = '';
      this.scanData.productCode = '';
    },
    clearChannel: function clearChannel() {
      this.channelName = '';
      this.scanData.custCode = '';
    },
    changeData: function changeData(flag) {
      if (flag) {
        if (this.searchForm.endTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.startTime = null;
            this.$message.error('开始时间不能大于结束时间！');
          }
        }
      } else {
        if (this.searchForm.startTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.endTime = null;
            this.$message.error('结束时间不能小于开始时间！');
          }
        }
      }
    },
    checkGroup: function checkGroup(id) {
      this.groupId = id;
      this.groupShow = true;
    },
    /**
     *
     * 获取生产工厂
     * @param {*} val
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList() {
      var _this3 = this;
      // this[list + 'Loading'] = true
      (0, _factory.findAllFactory)({}).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.factoryList = res.data.data;
          } else {
            _this3.factoryList = [];
          }
        }
        // this[list + 'Loading'] = false
      }).catch(function () {
        _this3[list + 'Loading'] = false;
      });
    },
    getStoreList: function getStoreList(val) {
      var _this4 = this;
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
            _this4.storeSELList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      console.log('重置');
      var that = this;
      that.searchForm = {
        orderNo: null,
        current: 1,
        factories: '',
        custName: null,
        custCode: null,
        crmOrderNo: null,
        areaCode: null,
        className: null,
        orderStatus: null,
        orderSource: null,
        orderType: null,
        orgName: null,
        productCode: null,
        size: 20,
        startTime: null,
        productionStatus: null,
        productName: null,
        productSpec: null,
        sapOrderNo: null,
        thirdOrderNo: null
      };
      this.baseWineName = '';
      // that.searchForm.current = 1
      // that.searchForm.size = 20
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this5 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择创建结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择创建起始时间');
        return;
      }
      that.listtableLoading = true;
      (0, _store.getplanorderlist)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, that.searchForm), {}, {
        factories: (0, _typeof2.default)(that.searchForm.factories) == 'object' ? that.searchForm.factories.toString() : that.searchForm.factories
      })).then(function (response) {
        that.listtableLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            if (that.tableData) {
              that.tableData.forEach(function (el) {
                if (Number(el.backBottleSum - el.scheduleSum) < 0) {
                  that.$set(el, 'noarrange', 0);
                } else {
                  that.$set(el, 'noarrange', Number(el.backBottleSum - el.scheduleSum));
                }
              });
            }
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this5.listLoading = false;
      });
      that.listLoading = false;
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = '';
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.lineList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
        this.getFactoryList(4, val, 'teamList');
      }
    },
    setScanFactory: function setScanFactory(val) {
      this.scanData.shopCode = '';
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      this.scanData.storehouseCode = '';
      this.scanlineList = [];
      this.scanteamList = [];
      this.scanData.traychestScaleCode = '';
      this.proList = [];
      if (!val) {
        this.scanworkshopList = [];
        // this.storeSELList = []
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        // this.storeSELList = this.storeList.filter(function(number) {
        //   return number.factoryId == val
        // })
      }
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        this.getFactoryList(4, val, 'scanteamList');
      }
    },
    add: function add() {
      var _this6 = this;
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '添加生产计划单';
      this.pid = '';
      (0, _warranty.warrantyPolicyList)({
        current: 1,
        size: 100,
        policyStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          _this6.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this6.zhibaoList = response.data.data.records;
          } else {
            _this6.zhibaoList = [];
          }
        }
      });
      this.$nextTick(function () {
        if (_this6.$refs.scanForm) {
          _this6.$refs.scanForm.clearValidate();
        }
        _this6.dialogVisible = true;
      });
    },
    openSel: function openSel() {
      if (this.scanData.factoryCode) {
        this.$refs.selProduct.searchForm.factoryId = this.factoryId;
        this.$refs.selProduct.getList();
        this.$refs.selProduct.proSelVisible = true;
      } else {
        this.$message.error('请选择生产工厂');
      }
    },
    openChannel: function openChannel() {
      this.$refs.selChannel.getList();
      this.$refs.selChannel.proSelVisible = true;
    },
    setchannel: function setchannel(val) {
      this.channelName = val.channelName;
      this.scanData.custCode = val.channelCode;
      this.scanData.channelClassName = val.channelClassName;
      this.scanData.areaCode = val.areaCode;
      this.scanData.provinceName = val.provinceName;
      this.scanData.cityName = val.cityName;
      this.scanData.regionName = val.countyName;
    },
    setSel: function setSel(val) {
      var _this7 = this;
      this.productName = val.productName;
      this.scanData.productCode = val.productCode;
      this.scanData.productSpec = val.productModel;
      this.$nextTick(function () {
        _this7.$refs.scanForm.rules.productSpec[0].required = false;
      });
    },
    proDel: function proDel() {
      this.proList = [];
      this.scanData.planNum = '';
      this.scanData.miniUnit = '';
      this.scanData.materialCode = '';
      this.scanData.traychestScaleCode = '';
    },
    changexishu: function changexishu(e) {
      if (this.scanData.backChestSum) {
        this.scanData.backBottleSum = this.scanData.backChestSum * e;
      }
    },
    changezushu: function changezushu(e) {
      if (this.scanData.coefficient) {
        this.scanData.backBottleSum = this.scanData.coefficient * e;
      }
    },
    submitForm: function submitForm() {
      var _this8 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          if (_this8.productName == '') {
            _this8.$message.error('产品信息不能为空');
            return;
          }
          if (_this8.channelName == '') {
            _this8.$message.error('客户信息不能为空');
            return;
          }
          _this8.scanData.backBottleSum = _this8.scanData.backBottleSum.toString();
          delete _this8.scanData.areaCode;
          delete _this8.scanData.provinceName;
          delete _this8.scanData.cityName;
          delete _this8.scanData.regionName;
          delete _this8.scanData.channelClassName;
          delete _this8.scanData.productSpec;
          _this8.btnLoading = true;
          if (_this8.pid) {
            var params = {};
            params.backBottleSum = _this8.scanData.backBottleSum;
            params.backChestSum = _this8.scanData.backChestSum;
            params.coefficient = _this8.scanData.coefficient;
            params.custCode = _this8.scanData.custCode;
            params.factoryCode = _this8.scanData.factoryCode;
            if (_this8.scanData.orderTypeCode.indexOf('[') !== -1 || _this8.scanData.orderTypeCode.indexOf(']') !== -1) {
              var a = /\[(.*?)\]/gi;
              var b = _this8.scanData.orderTypeCode;
              var c = b.match(a);
              if (c) {
                for (var i = 0; i < c.length; i++) {
                  params.orderTypeCode = c[i].replace(a, '$1');
                }
              }
            } else {
              params.orderTypeCode = _this8.scanData.orderTypeCode;
            }
            params.policyCode = _this8.scanData.policyCode;
            params.productCode = _this8.scanData.productCode;
            params.pid = _this8.pid;
            params.orderNo = _this8.scanData.orderNo;
            params.remark = _this8.scanData.remark;
            params.thirdOrderNo = _this8.scanData.thirdOrderNo;
            (0, _store.getOrderup)(params).then(function (res) {
              if (res.data.code != 200) {
                _this8.$message.error(res.data.msg);
              } else {
                _this8.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this8.getList();
                _this8.dialogClose();
              }
              _this8.btnLoading = false;
            }).catch(function () {
              _this8.btnLoading = false;
            });
          } else {
            var _params = JSON.parse(JSON.stringify(_this8.scanData));
            (0, _store.getOrderadd)(_params).then(function (res) {
              if (res.data.code != 200) {
                _this8.$message.error(res.data.msg);
              } else {
                _this8.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this8.getList();
                _this8.dialogClose();
              }
              _this8.btnLoading = false;
            }).catch(function () {
              _this8.btnLoading = false;
            });
          }
        } else {
          _this8.$message.error('请完善信息！');
        }
      });
    },
    dialogClose: function dialogClose() {
      this.$refs.scanForm.resetFields();
      this.scanData = {
        backBottleSum: '',
        backChestSum: '',
        coefficient: '',
        custCode: '',
        factoryCode: '',
        orderNo: '',
        orderTypeCode: '',
        policyCode: '',
        productCode: '',
        productSpec: '',
        remark: '',
        thirdOrderNo: '',
        channelClassName: '',
        areaCode: '',
        provinceName: '',
        cityName: '',
        regionName: ''
      };
      this.productName = '';
      this.pid = '';
      this.channelName = '';
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    handleClose: function handleClose() {
      this.detailData = {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        packScaleLevelItemDTOList: []
      };
      this.$refs.scanData.resetFields();
      this.isAudit = false;
      this.detailVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    scanDetail: function scanDetail(row) {
      var _this9 = this;
      console.log(row);
      this.authId('detailbtn', this.$route.meta.authList);
      this.detailData = JSON.parse(JSON.stringify(row));
      (0, _store.policyDetail)({
        policyCode: row.policyCode
      }).then(function (res) {
        if (res.data.code == 200) {
          _this9.zhiInfo = res.data.data;
        } else {
          _this9.$message.error(res.data.msg);
        }
      });
      (0, _store.productScheduleBillList)({
        current: 1,
        size: 100,
        orderNo: row.orderNo
      }).then(function (response) {
        if (response.data.code != 200) {
          _this9.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this9.detailtable = response.data.data.records;
          } else {
            _this9.detailtable = [];
          }
        }
      });
      this.detailVisible = true;
    },
    DoAudit: function DoAudit(row) {
      var _this10 = this;
      this.authId('scjhsh', this.$route.meta.authList);
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this10.detailData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          _this10.isAudit = true;
          _this10.detailVisible = true;
        } else {
          _this10.$message.error(res.data.msg);
        }
      });
    },
    doUpdate: function doUpdate(row) {
      var _this11 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      this.dialogVisible = true;
      this.mloading = true;
      this.title = '编辑生产计划单';
      (0, _warranty.warrantyPolicyList)({
        current: 1,
        size: 100,
        policyStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          _this11.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            _this11.zhibaoList = response.data.data.records;
          } else {
            _this11.zhibaoList = [];
          }
        }
      });
      this.scanData = JSON.parse(JSON.stringify(row));
      this.scanData.provinceName = row.province;
      this.productName = row.productName;
      this.scanData.productSpec = row.productModel;
      this.channelName = row.channelName;
      this.scanData.cityName = row.city;
      this.scanData.channelClassName = row.className;
      this.scanData.regionName = row.county;
      this.scanData.backBottleSum = row.coefficient * row.backChestSum;
      this.pid = row.pid;
      this.mloading = false;
    },
    submitUpload: function submitUpload() {
      if (this.fileList.length > 0) {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      } else {
        this.$message.error('请导入上传文件');
      }
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.mloading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.uploadClose();
        this.getList();
      }
      this.mloading = false;
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.resetForm('uploadForm');
      this.uploadVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    selectFile: function selectFile(file, fileList) {
      if (!file.response) {
        this.fileList = fileList;
        this.uploadForm.uploadFile = file.name;
      } else {
        this.fileList = [];
        this.uploadForm.uploadFile = '';
      }
      // this.uploadForm.uploadFile = file.name
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    selectImg: function selectImg(file, fileList) {
      this.imgList = fileList;
      this.hideUploadBtn = fileList.length >= 5;
    },
    uploadShow: function uploadShow() {
      this.uploadVisible = true;
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    codeDetail: function codeDetail(orderNo, materialId) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.orderNo = orderNo;
      this.materialId = materialId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this12 = this;
      // this.detailForm.orderNo = this.detailData.orderNo
      (0, _store.pageParentMarkCode)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this12.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this12.codeList = res.data.data.records;
            _this12.codetotal = parseInt(res.data.data.total);
          } else {
            _this12.codeList = [];
            _this12.codetotal = 0;
          }
        }
        _this12.codeListLoading = false;
      }).catch(function () {
        _this12.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this13 = this;
      (0, _store.subMarkCode)({
        markCode: tree.markCode,
        materialId: this.materialId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this13.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    codeClose: function codeClose() {
      this.detailForm = {
        current: 1,
        size: 10,
        orderNo: ''
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    showFailList: function showFailList() {
      this.failVisible = true;
      this.codeListLoading = true;
      this.failQuery.orderNo = this.detailData.orderNo;
      this.failQuery.materialId = this.detailData.materialId;
      this.getFailList();
    },
    getFailList: function getFailList() {
      var _this14 = this;
      (0, _store.backFailMarkCodeList)(this.failQuery).then(function (res) {
        if (res.data.code != 200) {
          _this14.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this14.failList = res.data.data.records;
            _this14.failtotal = parseInt(res.data.data.total);
            _this14.listLoading = false;
          } else {
            _this14.failList = [];
            _this14.failtotal = 0;
          }
        }
        _this14.codeListLoading = false;
      }).catch(function () {
        _this14.codeListLoading = false;
      });
    },
    failClose: function failClose() {
      this.failVisible = false;
      this.failQuery = {
        orderNo: '',
        current: 1,
        size: 10
      };
      this.failList = [];
      this.failtotal = 0;
    },
    del: function del(orderNo) {
      var _this15 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this15.authId('deletebtn', _this15.$route.meta.authList);
        (0, _store.orderdelete)({
          orderNo: orderNo
        }).then(function (res) {
          _this15.authId('searchbtn', _this15.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this15.$message.error(data.msg);
            return false;
          }
          _this15.$message({
            message: '删除成功',
            type: 'success'
          });
          _this15.getList();
        });
      }).catch(function () {
        _this15.authId('searchbtn', _this15.$route.meta.authList);
      });
    },
    // 导出功能
    download: function download() {
      var _this16 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _store.exportExcel)(this.searchForm).then(function (res) {
        _this16.authId('searchbtn', _this16.$route.meta.authList);
        if (res.status != 200) {
          _this16.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产计划单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this16.downLoading = false;
      }).catch(function () {
        _this16.authId('searchbtn', _this16.$route.meta.authList);
        _this16.downLoading = false;
      });
    },
    auditReset: function auditReset() {
      this.$refs.auditForm.resetFields();
      this.auditForm = {
        auditReasons: '',
        handlerResult: ''
      };
    },
    audit: function audit() {
      var _this17 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this17.btnLoading = true;
          if (_this17.auditForm.handlerResult) {
            (0, _store.reviewPass)({
              orderNo: _this17.detailData.orderNo,
              auditReasons: _this17.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this17.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this17.auditReset();
                _this17.handleClose();
                _this17.getList();
              } else {
                _this17.$message.error(res.data.msg);
              }
              _this17.btnLoading = false;
            }).catch(function () {
              _this17.btnLoading = false;
            });
          } else {
            (0, _store.reviewNoPass)({
              orderNo: _this17.detailData.orderNo,
              auditReasons: _this17.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this17.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this17.auditReset();
                _this17.handleClose();
                _this17.getList();
              } else {
                _this17.$message.error(res.data.msg);
              }
              _this17.btnLoading = false;
            }).catch(function () {
              _this17.btnLoading = false;
            });
          }
        } else {
          _this17.$message.error('请完善信息！');
        }
      });
    },
    // 获取基酒列表
    getBaseWineList: function getBaseWineList() {
      var _this18 = this;
      (0, _store.baseWineList)(this.baseWineLess).then(function (res) {
        if (res.data.code !== 200) {
          return _this18.$message.error('获取列表失败！');
        } else {
          _this18.totalBaseWineLess = parseInt(res.data.data.total);
          _this18.tableChoose = res.data.data.records;
          _this18.tableChooselist = res.data.data.records;
        }
      });
    },
    // baseWineList() {
    //   baseWineList(this.baseWineLesslist).then(res => {
    //     if (res.data.code !== 200) {
    //       return this.$message.error('获取列表失败！')
    //     } else {
    //       this.totalBaseWineLess = parseInt(res.data.data.total)
    //       this.tableChooselist = res.data.data.records
    //     }
    //   })
    // },
    // 基酒选择弹框
    baseWineShow: function baseWineShow() {
      var _this19 = this;
      // this.getBaseWineList()
      this.dialogChoose = true;
      this.$nextTick(function () {
        if (_this19.multipleList.length) {
          _this19.$refs.multipleTable.toggleRowSelection(_this19.multipleList[0], true);
        } else {
          _this19.$refs.multipleTable.clearSelection();
        }
      });
    },
    queryList: function queryList() {
      this.baseWineLess.current = 1;
      this.getBaseWineList();
    },
    restQueryList: function restQueryList() {
      this.baseWineLess = {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      };
      this.tableChoose = [];
      this.getBaseWineList();
    },
    handleSaveTemplateType: function handleSaveTemplateType(data) {
      this.dialogChoose = false;
      this.scanData.baseWineCode = this.multipleList[0].baseWineName;
      this.baseWineCode = this.multipleList[0].id;
      this.flag = true;
    },
    resetFormChoose: function resetFormChoose() {
      if (!this.flag) {
        this.multipleList = [];
      }

      // this.scanData.baseWineCode = ''
      // this.$nextTick(() => {
      //   this.$refs.multipleTable.clearSelection()
      // })
      this.dialogChoose = false;
    },
    select: function select(selection, row) {
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      this.multipleList = selection;
      // this.baseWineLess.baseWineName = this.multipleList[0].baseWineName
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    openBaseWine: function openBaseWine() {
      this.dialogBaseWine = true;
    },
    submit: function submit(row) {
      console.log(row);
      this.baseWineName = row.baseWineName;
      this.searchForm.baseWineCode = row.id;
      this.dialogBaseWine = false;
    },
    resetBaseWine: function resetBaseWine() {
      this.dialogBaseWine = false;
    },
    // 新增撤销
    revocation: function revocation(res) {
      var _this20 = this;
      this.$confirm('撤销审核，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _store.postRevoke)({
          orderNo: res.orderNo
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code != 200) {
            _this20.$message.error(res.data.msg);
            return false;
          }
          _this20.$message({
            message: '撤销成功',
            type: 'success'
          });
          _this20.getList();
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {});
    },
    // 暂停
    stopFun: function stopFun(id) {
      var _this21 = this;
      this.$confirm('确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(22222222);
        // this.authId('deletebtn', this.$route.meta.authList)
        (0, _store.disable)({
          orderNo: id
        }).then(function (res) {
          // this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this21.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this21.getList();
          } else {
            _this21.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this21.authId('searchbtn', _this21.$route.meta.authList);
      });
    },
    // 启用
    startFun: function startFun(id) {
      var _this22 = this;
      this.$confirm('确认启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this22.authId('deletebtn', _this22.$route.meta.authList);
        (0, _store.enable)({
          orderNo: id
        }).then(function (res) {
          _this22.authId('searchbtn', _this22.$route.meta.authList);
          if (res.data.code == 200) {
            _this22.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this22.getList();
          } else {
            _this22.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this22.authId('searchbtn', _this22.$route.meta.authList);
      });
    },
    doArrange: function doArrange(row, type) {
      this.$refs.insertMain.show(row, type);
    },
    multiArrange: function multiArrange(arr, type) {
      if (arr.length == 0) {
        this.$message.warning('请选择生产计划单排产');
        return false;
      } else if (arr.length < 2) {
        this.$message.warning('请至少选择两个生产计划单排产');
        return false;
      } else {
        var notEqualFlag = arr.some(function (item) {
          return arr.filter(function (ele) {
            return ele.factoryCode !== item.factoryCode;
          }).length >= 1;
        });
        if (notEqualFlag == true) {
          this.$message.warning('请选择同一生产工厂的生产计划单排产');
          return false;
        } else {
          this.$refs.insertMain.show(arr, type);
        }
      }
    }
  }
};
exports.default = _default;