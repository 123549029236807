var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "API-添加",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "formData",
          attrs: {
            rules: _vm.rules,
            model: _vm.formData,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "API资源ID:", prop: "methodKey" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "请输入",
                  maxlength: "40",
                },
                model: {
                  value: _vm.formData.methodKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "methodKey", $$v)
                  },
                  expression: "formData.methodKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "API地址:", prop: "methodPath" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.methodPath,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "methodPath", $$v)
                  },
                  expression: "formData.methodPath",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "资源描述", prop: "methodDesc" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  clearable: "",
                  placeholder: "请输入",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.formData.methodDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "methodDesc", $$v)
                  },
                  expression: "formData.methodDesc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "align-right",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("formData")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.mloading },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formData")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }