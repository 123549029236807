"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _compyrole = require("@/api/systems/compyrole");
var _compyuser = require("@/api/systems/compyuser");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Add',
  components: {
    Treeselect: _vueTreeselect.default
  },
  props: {
    add: Boolean
  },
  data: function data() {
    return {
      addData: {
        roleName: '',
        roleCode: '',
        clientCodes: [],
        remark: '',
        orgId: null,
        isEnable: true
      },
      rules: {
        roleName: [{
          required: true,
          validator: _compyrole.checkRequiredWord,
          trigger: 'blur'
        }],
        roleCode: [{
          required: true,
          message: '请输入角色编码',
          trigger: 'blur'
        }],
        clientCodes: [{
          required: true,
          message: '请选择系统',
          trigger: 'change'
        }],
        orgId: [{
          required: false,
          message: '请选择组织结构',
          trigger: 'change'
        }],
        isEnable: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      loading: false,
      dstate: false,
      options: [],
      orgOptions: []
    };
  },
  watch: {
    add: function add(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.options = that.$parent.sysOptions;
        that.getOrg();
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('addForm');
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var param = {};
          param.roleName = that.addData.roleName;
          param.roleCode = that.addData.roleCode;
          param.remark = that.addData.remark;
          if (that.addData.isEnable == true) {
            param.isEnable = 1;
          } else {
            param.isEnable = 0;
          }
          param.clientCodes = that.addData.clientCodes;
          param.orgId = that.addData.orgId;
          (0, _compyrole.addRole)(param).then(function (res) {
            if (res.data.code == 200) {
              that.loading = false;
              that.dsate = false;
              that.$message({
                type: 'success',
                message: '添加成功'
              });
              that.$parent.DoSearch();
              that.dialogVisible = false;
              that.$parent.addShow = false;
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
              return;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.addShow = false;
      that.dialogVisible = false;
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.orgId;
              obj.label = item.orgName;
              obj.pid = item.orgId;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    }
  }
};
exports.default = _default;