"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _configpanel = require("@/api/systems/configpanel");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Systemconfigpanelindex',
  data: function data() {
    return {
      listQuery: {
        code: '',
        name: '',
        description: ''
      },
      list: [],
      listLoading: false,
      loading: false,
      mloading: false,
      dialog: false,
      tabHeight: '100%',
      title: '',
      radio: '1',
      scanData: {
        code: '',
        name: '',
        type: '1',
        description: '',
        itemList: [],
        menuId: ''
      },
      itemList: [],
      rules: {
        code: [{
          required: true,
          message: '变量名必填项，请填写！',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '中文名必填项，请填写！',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '配置类型必填项，请选择！',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    var h = (0, _utils.tableHeight)();
    this.$nextTick(function () {
      _this.tabHeight = h;
    });
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    console.log(this.$route.meta.authList, ' this.$route.meta.authList');
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _configpanel.configPanellist)(this.listQuery).then(function (res) {
        if (res.data.code == 200) {
          _this2.list = res.data.data;
          if (_this2.list && _this2.list.length) {
            _this2.list.forEach(function (item) {
              item.pid = item.menuId;
              if (item.configList.length) {
                item.configList.forEach(function (val) {
                  val.menuName1 = item.menuName;
                  val.menuId = item.menuId;
                });
              }
            });
          }
          _this2.listLoading = false;
        } else {
          _this2.listLoading = false;
          return;
        }
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      this.getList();
    },
    DoReset: function DoReset() {
      this.listQuery = {
        code: '',
        name: '',
        description: ''
      };
      this.getList();
    },
    reFlash: function reFlash() {
      var _this3 = this;
      (0, _configpanel.refreshAllCache)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          _this3.$message({
            message: '操作成功',
            type: 'success'
          });
          _this3.getList();
        }
      });
    },
    changeStatus: function changeStatus(val, row) {
      var _this4 = this;
      console.log(val, 'val');
      if (val == 2) {
        row.status = '1';
      } else {
        row.status = '2';
      }
      this.$confirm(val == 2 ? '确认禁用' : '确认启用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // this.authId('startbtn', this.$route.meta.authList)
        (0, _configpanel.configPanelstatus)({
          status: val,
          pId: row.pid
        }).then(function (res) {
          // this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code != 200) {
            _this4.$message.error(res.data.msg);
          } else {
            _this4.$message({
              message: '操作成功',
              type: 'success'
            });
            _this4.getList();
          }
        });
      }).catch(function () {
        // this.authId('searchbtn', this.$route.meta.authList)
      });
    },
    handleClose: function handleClose() {
      this.scanData = {
        code: '',
        name: '',
        type: '1',
        description: '',
        itemList: [],
        menuId: ''
      };
      this.$refs.dataForm.resetFields();
      this.dialog = false;
    },
    add: function add(row) {
      console.log(row);
      this.title = '创建配置项';
      this.scanData.menuId = row.menuId;
      this.scanData.menuName = row.menuName;
      this.dialog = true;
    },
    audit: function audit(row) {
      var _this5 = this;
      this.title = '修改配置项';
      this.dialog = true;
      this.mloading = true;
      (0, _configpanel.detail)({
        pId: row.pid
      }).then(function (res) {
        _this5.mloading = false;
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          _this5.scanData = res.data.data;
          var list = res.data.data.itemList;
          var aItem = [];
          for (var i = 0; i < list.length; i++) {
            aItem.push({
              code: list[i].code,
              name: list[i].name,
              edit: false
            });
            if (list[i].selected == 1) {
              if (res.data.data.type == 1) {
                _this5.radio = list[i].code;
              } else {
                _this5.radio = i;
              }
            }
          }
          _this5.itemList = aItem;
        }
      }).catch(function () {
        _this5.mloading = false;
      });
    },
    typeChange: function typeChange(val) {
      if (val == 2) {
        this.itemList = [{
          code: '',
          name: '',
          edit: true
        }, {
          code: '',
          name: '',
          edit: true
        }];
      } else {
        this.radio = '1';
      }
    },
    addItem: function addItem() {
      this.itemList.push({
        code: '',
        name: '',
        edit: true
      });
    },
    delItem: function delItem(row) {
      this.itemList.splice(this.itemList.indexOf(row), 1);
    },
    sub: function sub() {
      var _this6 = this;
      this.loading = true;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var itemList = [];
          if (_this6.scanData.type == 1) {
            itemList = [{
              code: '1',
              name: '是',
              selected: _this6.radio == 1 ? 1 : 2
            }, {
              code: '2',
              name: '否',
              selected: _this6.radio == 2 ? 1 : 2
            }];
          } else {
            if (_this6.radio > _this6.itemList.length) {
              _this6.loading = false;
              _this6.$message.error('请选择默认选项');
              return;
            }
            for (var i = 0; i < _this6.itemList.length; i++) {
              if (_this6.itemList[i].code == '' || _this6.itemList[i].name == '') {
                _this6.loading = false;
                _this6.$message.error('选项文本、选项值不能为空');
                return;
              }
              var oItem = {
                code: _this6.itemList[i].code,
                name: _this6.itemList[i].name,
                selected: _this6.radio == i ? 1 : 2
              };
              itemList.push(oItem);
            }
          }
          var obj = JSON.parse(JSON.stringify(_this6.scanData));
          delete obj.menuName;
          obj.itemList = itemList;
          (0, _configpanel.addOrUpdate)(obj).then(function (res) {
            _this6.loading = false;
            if (res.data.code != 200) {
              _this6.$message.error(res.data.msg);
            } else {
              _this6.handleClose();
              _this6.$message({
                message: '操作成功',
                type: 'success'
              });
              _this6.getList();
            }
          }).catch(function () {
            _this6.loading = false;
          });
        } else {
          _this6.loading = false;
          _this6.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;