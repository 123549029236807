"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addmuban = addmuban;
exports.downUserRecord = downUserRecord;
exports.editStatus = editStatus;
exports.editmuban = editmuban;
exports.enableTemplate = enableTemplate;
exports.exportMarkCodeDetail = exportMarkCodeDetail;
exports.getdetailpage = getdetailpage;
exports.importExcel = importExcel;
exports.queryPage = queryPage;
exports.querymubanPage = querymubanPage;
exports.replaceCode = replaceCode;
exports.searchMenuDataDetail = searchMenuDataDetail;
exports.selectChildCode = selectChildCode;
exports.selectDetailByCode = selectDetailByCode;
exports.selectOrderDetailPage = selectOrderDetailPage;
exports.selectPage = selectPage;
exports.selectReplacePage = selectReplacePage;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-mark';

// 锂电数码替换列表
function selectReplacePage(current, size, data) {
  return (0, _request.default)({
    url: '/api-mark/replaceMarkLog/selectReplacePage?current=' + current + '&size=' + size,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

// 锂电数码替换
function replaceCode(data) {
  return (0, _request.default)({
    url: '/api-mark/replaceMarkLog/replaceCode',
    method: 'post',
    data: data
  });
}

// 成品列表查询
function selectPage(current, size, data) {
  return (0, _request.default)({
    url: '/api-mark/endProductMark/selectPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 成品列表导出
function downUserRecord(data) {
  return (0, _request.default)({
    url: '/api-mark/endProductMark/exportTemplate',
    method: 'POST',
    responseType: 'blob',
    data: data
  });
}

// 原辅料数码导出
function searchMenuDataDetail(params) {
  return (0, _request.default)({
    url: 'api-mark/rawMaterialOrder/exportExcel',
    method: 'GET',
    params: params,
    responseType: 'blob'
  });
}

// 锂电数码导出
function exportMarkCodeDetail(params) {
  return (0, _request.default)({
    url: 'api-mark/rawMaterialOrder/exportMarkCodeDetail',
    method: 'GET',
    params: params,
    responseType: 'blob'
  });
}

// 原辅料数码导入
function importExcel(data) {
  return (0, _request.default)({
    url: '/api-mark/rawMaterialOrder/importExcel',
    method: 'post',
    data: data
  });
}

// 原辅料列表
function queryPage(current, size, data) {
  return (0, _request.default)({
    url: '/api-mark/rawMaterialOrder/queryPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 原辅料数码详情查询下级
function selectChildCode(params) {
  return (0, _request.default)({
    url: '/api-mark/rawMaterialOrder/selectChildCode',
    method: 'GET',
    params: params
  });
}

// 原辅料数码详情查询最高级
function selectOrderDetailPage(params) {
  return (0, _request.default)({
    url: '/api-mark/rawMaterialOrder/selectOrderDetailPage',
    method: 'GET',
    params: params
  });
}

// 锂电模板列表
function querymubanPage(current, size, data) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/queryPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}
// 新增锂电模板
function addmuban(data) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/add',
    method: 'post',
    data: data
  });
}

// 编辑锂电模板
function editmuban(data) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/edit',
    method: 'post',
    data: data
  });
}

// 查询模板详情
function getdetailpage(params) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/detail',
    method: 'GET',
    params: params
  });
}

// 编辑模板状态
function editStatus(params) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/editStatus',
    method: 'GET',
    params: params
  });
}

// 查询启用模板
function enableTemplate(data) {
  return (0, _request.default)({
    url: '/api-mark/lithiumTemplate/enableTemplate',
    method: 'post',
    data: data
  });
}

// 锂电数码查询
function selectDetailByCode(params) {
  return (0, _request.default)({
    url: '/api-mark/rawMaterialOrder/selectDetailByCode',
    method: 'GET',
    params: params
  });
}