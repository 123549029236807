var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "padding-right": "20px" },
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "兑奖规则名称:", prop: "ruleName" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.isDisable },
                model: {
                  value: _vm.form.ruleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ruleName", $$v)
                  },
                  expression: "form.ruleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "集字:", prop: "wordPrizes" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        icon: _vm.btnConfig.addBtn.icon,
                        disabled: _vm.isDisable,
                      },
                      on: { click: _vm.handleSelectWord },
                    },
                    [_vm._v("添加字卡")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#909399" } }, [
                    _vm._v("最多可选8个字"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "word-container" },
                _vm._l(_vm.form.wordPrizes, function (item, index) {
                  return _c(
                    "el-tag",
                    { key: index, attrs: { type: "success" } },
                    [_vm._v("\n          " + _vm._s(item.word) + "\n        ")]
                  )
                }),
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品:" } },
            [
              _c(
                "el-row",
                {
                  attrs: { type: "flex", justify: "space-between", gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 15 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "exchangePrizeType",
                            rules: {
                              required: true,
                              message: "请选择奖品类型",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDisable,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCurPrizeType($event)
                                },
                              },
                              model: {
                                value: _vm.form.exchangePrizeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "exchangePrizeType", $$v)
                                },
                                expression: "form.exchangePrizeType",
                              },
                            },
                            _vm._l(_vm.prizeTypes, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "exchangePrizeId",
                            rules: {
                              required: true,
                              message: "请选择奖品",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.isDisable,
                              },
                              model: {
                                value: _vm.form.exchangePrizeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "exchangePrizeId", $$v)
                                },
                                expression: "form.exchangePrizeId",
                              },
                            },
                            _vm._l(_vm.plist, function (obj, pIndex) {
                              return _c("el-option", {
                                key: pIndex,
                                attrs: {
                                  label: obj.prizeName,
                                  value: obj.prizeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "资金来源:",
                prop: "financeSourceId",
                "is-required": "",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.isDisable },
                  model: {
                    value: _vm.form.financeSourceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "financeSourceId", $$v)
                    },
                    expression: "form.financeSourceId",
                  },
                },
                _vm._l(_vm.sourceList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.fundConfigurationName,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效截止日期:", prop: "rangeTime" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.isDisable,
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                },
                model: {
                  value: _vm.form.rangeTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rangeTime", $$v)
                  },
                  expression: "form.rangeTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "规则说明:", prop: "explain" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "",
                  type: "textarea",
                  rows: 5,
                  maxlength: "500",
                  resize: "none",
                  disabled: _vm.isDisable,
                },
                model: {
                  value: _vm.form.explain,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "explain", $$v)
                  },
                  expression: "form.explain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSave("form")
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleCancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectWordVisible
        ? _c("selectWord", {
            attrs: {
              visible: _vm.selectWordVisible,
              data: _vm.form.wordPrizes,
            },
            on: {
              change: _vm.getSelectWord,
              close: function ($event) {
                _vm.selectWordVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }