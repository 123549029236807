"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chanelparam = require("@/api/chanelmange/chanelparam");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangebasicsetchanelparamindex';
var _default = {
  name: 'chanelmangebasicsetchanelparamindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      loading: false,
      tableData: [],
      editData: {
        parametersName: '',
        parametersValue: '',
        pid: '',
        remarks: ''
      },
      rules: {
        parametersValue: [{
          required: true,
          validator: _chanelparam.checkPositiveNum,
          trigger: 'blur'
        }]
      },
      dstate: false,
      dialogVisible: false,
      apis: [],
      page2: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.tableData.length > 0 ? true : false;
    that.apis = that.$route.meta.authList;
    if (!hasCache) {
      that.getList();
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.tableData = [];
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var auth = that.hasAuth('searchbtn', that.apis);
      if (!auth) {
        return;
      }
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _chanelparam.getAllParams)().then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data) {
            that.tableData = response.data.data;
          } else {
            that.tableData = [];
          }
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 500);
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('editData');
    },
    DoEdit: function DoEdit(row, name) {
      var that = this;
      that.editData.parametersName = row.parametersName;
      that.editData.parametersValue = row.parametersValue;
      that.editData.pid = row.pId;
      that.editData.remarks = row.remarks;
      that.dialogVisible = true;
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var setHeaderId = that.authId('updatebtn', that.apis);
          that.loading = true;
          that.dsate = true;
          (0, _chanelparam.saveParams)(that.editData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.loading = false;
              that.dsate = false;
              that.resetForm('editData');
              that.getList();
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;