"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _channel = require("@/api/basic/channel");
var _org = require("@/api/basic/org");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _factory = require("@/api/basic/factory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // orgId: {
    //   typs: String,
    //   default: ''
    // },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      selLoading: false,
      proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        orgId: '',
        departmentId: '',
        keyWord: ''
      },
      firmList: [],
      depList: [],
      orgList: [],
      tableData: [],
      normalList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.getOrgListAll();
        this.getOrgList();
      }
    }
  },
  mounted: function mounted() {
    // var that = this
    // if (sessionStorage.getItem('orgList')) {
    //   that.orgList = JSON.parse(sessionStorage.getItem('orgList'))
    //   that.normalList = JSON.parse(sessionStorage.getItem('orgNormalList'))
    // } else {
    // }
  },
  methods: {
    changeorg: function changeorg(orgId, type) {
      var _this = this;
      if (type == 'N') {
        this.searchForm.departmentId = '';
      }
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    getOrgListAll: function getOrgListAll() {
      var _this2 = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this2;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
          sessionStorage.setItem('orgList', JSON.stringify(Data));
          sessionStorage.setItem('orgNormalList', JSON.stringify(datas));
        }
      });
    },
    getOrgList: function getOrgList() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.firmList = response.data.data;
        }
      });
    },
    setsel: function setsel() {
      var _this3 = this;
      this.getList();
      this.selLoading = true;
      this.$nextTick(function () {
        _this3.data.forEach(function (row) {
          _this3.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });

        setTimeout(function () {
          _this3.$refs.multipleTable.toggleRowSelection(_this3.tableData[0]);
          _this3.$refs.multipleTable.toggleRowSelection(_this3.tableData[0]);
          _this3.selLoading = false;
        }, 500);
      });
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var _this4 = this;
      var that = this;
      this.listLoading = true;
      // var submitData = deepClone(filterKeyNull(that.searchForm))
      // submitData.orgId = that.searchForm.orgId || that.searchForm.departmentId
      // delete submitData.departmentId
      (0, _channel.getUserByRoleCode)((0, _objectSpread2.default)({
        roleCode: 'cscs'
      }, that.searchForm)).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data;
            // that.total = Number(res.data.data.total)
          } else {
            // that.total = 0
            that.tableData = [];
          }
        }
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      // if (this.isReadonly) {
      //   this.searchForm = Object.assign(this.searchForm, {
      //     current: 1,
      //     size: 20,
      //     keyWord: '',
      //   })
      // } else {
      this.searchForm = {
        current: 1,
        size: 20,
        orgId: '',
        departmentId: '',
        keyWord: ''
      };
      // }

      this.filterText = '';
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.searchForm = {
        current: 1,
        size: 20,
        orgId: '',
        keyWord: ''
      };
      this.filterText = '';
      this.clear();
      this.proSelVisible = false;
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;