var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cardBox" },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticClass: "creatItemWrap",
                  attrs: { sm: 24, md: 8, lg: 8 },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "0px" },
                        shadow: "hover",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "creatImg",
                        attrs: { src: item.img },
                        on: {
                          click: function ($event) {
                            return _vm.creatAct(item.type)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { padding: "20px 25px" },
                          on: {
                            click: function ($event) {
                              return _vm.creatAct(item.type)
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "craeatTitle",
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("Tipdialog", {
            ref: "hasConsumerTipVisible",
            attrs: {
              width: "350px",
              content: "请先在【公众号基础配置】中选择“消费者公众号”。",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("Content", {
            ref: "content",
            attrs: { "dialog-visible": _vm.dialogVisible, type: _vm.type },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }