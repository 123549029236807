"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
var _enterpriseUsers = _interopRequireDefault(require("./components/enterpriseUsers"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _dealer = require("@/api/basic/dealer");
var _product = require("@/api/basic/product");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _personnelCheckboxDialog = _interopRequireDefault(require("@/components/personnelCheckboxDialog"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
var _factory = require("@/api/basic/factory");
var _salesArea = require("@/api/basic/salesArea");
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatachannelmangeterminalfileindex',
  components: {
    Pagination: _Pagination.default,
    PersonnelCheckboxDialog: _personnelCheckboxDialog.default,
    ChannelSelectDialog: _channelSelectDialog.default,
    Treeselect: _vueTreeselect.default,
    enterpriseUsers: _enterpriseUsers.default
  },
  data: function data() {
    var _this = this;
    var validateOrg = function validateOrg(rule, value, callback) {
      if (_this.orgselList.length == 0) {
        callback(new Error('所属公司不能为空，请维护'));
      } else {
        callback();
      }
    };
    var validateSSQ = function validateSSQ(rule, value, callback) {
      console.log(rule, value, 123123);
      if (_this.ruleForm.countyName == '') {
        callback(new Error("地址不能为空，请维护"));
      } else {
        callback();
      }
    };
    var validatelongitude = function validatelongitude(rule, value, callback) {
      var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{6})|180\.0{6})$/;
      if (value) {
        if (!longreg.test(value)) {
          callback(new Error('经度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatelatitude = function validatelatitude(rule, value, callback) {
      var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{6}|90\.0{6}|[0-8]?\d{6})$/;
      if (value) {
        if (!latreg.test(value)) {
          callback(new Error('纬度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatepostalCode = function validatepostalCode(rule, value, callback) {
      if (value) {
        if (value.length != 6) {
          callback(new Error('邮政编码格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatemaster = function validatemaster(rule, value, callback) {
      if (value) {
        if (_this.ruleForm.masterChannelId == _this.ruleForm.pid) {
          callback(new Error('所属主体不能是自己'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      regionList: [],
      ProvinceList: [],
      CityList: [],
      CountyList: [],
      handleShow: false,
      centerDialogVisible: false,
      pId: false,
      checkStrong: 1,
      strongMsgList: [],
      value: '',
      channelSelectType: '',
      masterName: '',
      masterChannelName: '',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      listLoading: false,
      btnLoading: false,
      btnDisabled: false,
      downLoading: false,
      areaList: [],
      map: new Map(),
      areaInUseList: [],
      classList: [],
      gradeList: [],
      page2: false,
      disabled: false,
      tableKey: '',
      tabHeight: '100%',
      channelOrgId: '',
      area: [],
      areaSel: [],
      dialogSalesmanList: false,
      size: 10,
      current: 1,
      salesmanTotal: 0,
      salesmanSize: 10,
      salesmanList: [],
      salesManName: '',
      salesManNameq: '',
      ids: [],
      oldList: [],
      isShow: false,
      querysalesman: {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      },
      searchForm: {
        keyword: '',
        archivesStatus: '',
        channelClassId: '',
        channelGradeId: '',
        channelOrgId: '',
        masterChannelId: '',
        channelType: 2,
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        current: 1,
        size: 20
      },
      tableData: [],
      total: 0,
      orgList: [],
      orgInUseList: [],
      archivesList: [],
      multipleSelection: [],
      normalList: [],
      proClassList: [],
      proNormal: [],
      dialogVisible: false,
      scanVisible: false,
      title: '终端档案',
      mloading: false,
      orgselList: [],
      porList: [],
      salesmanIds: [],
      scanData: {},
      ruleForm: {
        creditCode: '',
        sourceEnum: 1,
        channelName: '',
        channelType: 2,
        contacts: '',
        isCode: 0,
        detailedAddress: '',
        phone: '',
        pid: '',
        cardNo: '' // 身份证号
      },

      rules: {
        cardNo: [{
          validator: this.validatecardNo,
          trigger: 'blur'
        }],
        channelName: [{
          required: true,
          message: '终端名称不能为空，请维护',
          trigger: 'change'
        }],
        countyName: [{
          required: true,
          validator: validateSSQ,
          trigger: "change"
        }],
        orgselList: [{
          required: true,
          validator: validateOrg,
          trigger: 'change'
        }],
        latitude: [{
          validator: validatelatitude,
          trigger: 'change'
        }],
        longitude: [{
          validator: validatelongitude,
          trigger: 'change'
        }],
        postalCode: [{
          validator: validatepostalCode,
          trigger: 'change'
        }],
        masterChannelId: [{
          validator: validatemaster,
          trigger: 'change'
        }],
        email: [{
          type: 'email',
          message: '电子邮箱格式不正确',
          trigger: ['blur', 'change']
        }],
        contacts: [{
          required: true,
          message: '联系人不能为空，请维护',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '手机号不能为空，请维护',
          trigger: 'change'
        }, {
          pattern: /^1[23456789]\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }],
        classId: [{
          required: true,
          message: '终端分类不能为空，请维护',
          trigger: 'change'
        }],
        gradeId: [{
          required: true,
          message: '终端等级不能为空，请维护',
          trigger: 'change'
        }],
        orgId: [{
          required: true,
          message: '所属公司不能为空，请维护',
          trigger: 'change'
        }]
      },
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        channelType: 2,
        uploadFile: ''
        // regionId: ''
      },

      uploadRules: {},
      fileList: [],
      imgList: [],
      depList: [],
      firmList: []
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jcChannelArchives/importExcel';
    },
    uploadImg: function uploadImg() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadImg';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    this.getAreaAll();
    this.getOrgListAll();
    this.getProClassAll();
    this.getClassAll();
    this.getGradeAll();
    this.getAllList();
    this.getOrgInUseList();
    // this.getAreaInUse()
    // this.newlistFun()
    // this.getStrong()
    // this.getcomBoxSalesManlist()
    this.querylist();
  },
  methods: (_methods = {
    getregionList: function getregionList() {
      var that = this;
      (0, _salesArea.selectLargeRegionByType)({
        regionType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.regionList = response.data.data;
        }
      });
    },
    // changeregion(e) {
    //   if (this.regionList) {
    //     const obj = this.regionList.find(el => el.regionId == e)
    //     this.uploadForm.regionName = obj.regionName
    //   }
    // },
    newlistFun: function newlistFun() {
      var _this2 = this;
      (0, _dealer.newlist)({
        areaCode: "",
        areaName: "",
        areaType: "1",
        isDelete: 1,
        parentId: ""
      }).then(function (res) {
        if (res.data.code === 200) {
          _this2.ProvinceList = res.data.data;
        }
      });
    },
    validateSSQ: function validateSSQ(rule, value, callback) {
      if (this.ruleForm.countyName == '') {
        callback(new Error("地址不能为空，请维护"));
      } else {
        callback();
      }
    },
    validatecardNo: function validatecardNo(rule, value) {
      return new Promise(function (resolve, reject) {
        var regExp = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (value && !regExp.test(value)) {
          reject(new Error('请输入有效的身份证号'));
        } else {
          resolve();
        }
      });
    },
    ProvinceFun: function ProvinceFun(parentId) {
      var _this3 = this;
      this.ruleForm.cityName = '';
      this.ruleForm.countyName = '';
      (0, _dealer.nextArea)({
        isDelete: "",
        parentId: parentId
      }).then(function (res) {
        if (res.data.code === 200) {
          _this3.CityList = res.data.data;
        }
      });
    },
    // 城市
    CityFun: function CityFun(parentId) {
      var _this4 = this;
      this.ruleForm.countyName = '';
      (0, _dealer.nextArea)({
        isDelete: "",
        parentId: parentId
      }).then(function (res) {
        if (res.data.code === 200) {
          _this4.CountyList = res.data.data;
        }
      });
    },
    changetab: function changetab() {
      this.$forceUpdate();
    },
    changeorg: function changeorg(orgId, type) {
      var _this5 = this;
      if (type == 'N') {
        this.ruleForm.departmentId = '';
      }
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this5.$message.error(response.data.msg);
        } else {
          _this5.depList = response.data.data;
        }
      });
    }
  }, (0, _defineProperty2.default)(_methods, "changetab", function changetab(tab) {
    if (tab == 0) {
      this.ruleForm.list = [];
    }
  }), (0, _defineProperty2.default)(_methods, "getOrgList", function getOrgList() {
    var that = this;
    (0, _org.fetchOrgList)({
      orgCodeAndName: '',
      orgStatus: 1,
      orgType: 1
    }).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
      } else {
        that.firmList = response.data.data;
      }
    });
  }), (0, _defineProperty2.default)(_methods, "clearMaster", function clearMaster() {
    this.masterChannelName = '';
    this.ruleForm.masterChannelId = '';
  }), (0, _defineProperty2.default)(_methods, "getStrong", function getStrong() {
    var _this6 = this;
    (0, _channel.detailList)().then(function (res) {
      if (res.data.code == 200) {
        _this6.strongMsgList = res.data.data;
      } else {
        _this6.$message.error(res.data.msg);
      }
    });
  }), (0, _defineProperty2.default)(_methods, "setCheckStrong", function setCheckStrong(row) {
    this.authId('checkbtn', this.$route.meta.authList);
    this.pId = row.pId;
    this.checkStrong = row.strongCheck;
    this.centerDialogVisible = true;
  }), (0, _defineProperty2.default)(_methods, "setStrong", function setStrong() {
    var _this7 = this;
    this.btnLoading = true;
    (0, _channel.checkStrong)({
      pId: this.pId,
      checkStrong: this.checkStrong
    }).then(function (response) {
      _this7.btnLoading = false;
      if (response.data.code != 200) {
        _this7.$message.error(response.data.msg);
        return;
      } else {
        _this7.$message({
          type: 'success',
          message: '修改成功'
        });
        _this7.getList();
        _this7.centerDialogVisible = false;
      }
    }).catch(function () {
      _this7.btnLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "openTSel", function openTSel() {
    this.channelSelectType = 1;
    this.$refs.channelSelect.proSelVisible = true;
  }), (0, _defineProperty2.default)(_methods, "openCSel", function openCSel() {
    this.channelSelectType = 2;
    this.$refs.channelSelect.proSelVisible = true;
  }), (0, _defineProperty2.default)(_methods, "setCSel", function setCSel(val) {
    if (this.channelSelectType == 1) {
      this.searchForm.masterChannelId = val.pId;
      this.masterName = val.channelName;
    }
    if (this.channelSelectType == 2) {
      this.ruleForm.masterChannelId = val.pId;
      this.masterChannelName = val.channelName;
    }
  }), (0, _defineProperty2.default)(_methods, "getAllList", function getAllList() {
    var _this8 = this;
    (0, _channel.channelArchivesList)({
      channelType: 2,
      current: 1,
      size: 2147483647
    }).then(function (response) {
      if (response.data.code != 200) {
        _this8.$message.error(response.data.msg);
        return;
      } else {
        if (response.data.data != null) {
          _this8.archivesList = response.data.data.records;
        } else {
          _this8.archivesList = [];
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "getList", function getList() {
    this.authId('searchbtn', this.$route.meta.authList);
    var that = this;
    that.listLoading = true;
    var submitData = (0, _utils.filterKeyNull)(that.searchForm);
    if (that.channelOrgId) {
      submitData.channelOrgId = that.channelOrgId[that.channelOrgId.length - 1];
    }
    if (that.area) {
      submitData.provinceCode = that.area[0];
      submitData.cityCode = that.area[1] || '';
      submitData.countyCode = that.area[2] || '';
    }
    (0, _channel.channelArchivesList)(submitData).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
        return;
      } else {
        if (response.data.data != null) {
          that.total = parseInt(response.data.data.total);
          that.tableData = response.data.data.records;
        } else {
          that.total = 0;
          that.tableData = [];
        }
        that.listLoading = false;
      }
    }).catch(function (res) {
      that.listLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "ordSel", function ordSel(val) {
    var that = this;
    if (val && val.length) {
      that.$refs.perCheckbox.searchForm.orgId = val[val.length - 1];
    } else {
      that.$refs.perCheckbox.searchForm.orgId = '';
    }
  }), (0, _defineProperty2.default)(_methods, "getGradeAll", function getGradeAll() {
    var that = this;
    (0, _channel.channelGradeList)({
      pageNo: 0,
      pageSize: 0,
      channelType: 2
    }).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
        return;
      } else {
        if (response.data.data != null) {
          that.gradeList = response.data.data;
        } else {
          that.gradeList = [];
        }
      }
    }).catch(function (res) {});
  }), (0, _defineProperty2.default)(_methods, "getClassAll", function getClassAll() {
    var that = this;
    (0, _channel.channelClassList)({
      pageNo: 0,
      pageSize: 0,
      channelType: 2
    }).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
        return;
      } else {
        if (response.data.data != null) {
          that.classList = response.data.data;
        } else {
          that.classList = [];
        }
      }
    }).catch(function (res) {});
  }), (0, _defineProperty2.default)(_methods, "getOrgListAll", function getOrgListAll() {
    var _this9 = this;
    (0, _org.fetchOrgList)({
      orgCodeAndName: '',
      orgStatus: '',
      orgType: 1
    }).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
      } else {
        var that = _this9;
        var datas = response.data.data;
        var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
        that.orgList = JSON.parse(JSON.stringify(Data));
        that.normalList = JSON.parse(JSON.stringify(datas));
      }
    });
  }), (0, _defineProperty2.default)(_methods, "getOrgInUseList", function getOrgInUseList() {
    var _this10 = this;
    (0, _org.fetchOrgList)({
      orgCodeAndName: '',
      orgStatus: 1
    }).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
      } else {
        var that = _this10;
        var datas = response.data.data;
        var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
        that.orgInUseList = JSON.parse(JSON.stringify(Data));
      }
    });
  }), (0, _defineProperty2.default)(_methods, "getProClassAll", function getProClassAll() {
    var that = this;
    (0, _product.productClassList)({
      classStatus: 1
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
        return;
      } else {
        if (res.data.data != null) {
          var arr = [];
          res.data.data.forEach(function (item) {
            var obj = {
              id: item.pId,
              label: item.productClassName,
              pId: item.pId,
              parentId: item.parentId
            };
            arr.push(obj);
          });
          console.log(arr, 'arr');
          var data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(arr)), 'pId', 'parentId', 'children');
          console.log(data, 'data');
          that.proClassList = data;
          that.proNormal = arr;
        } else {
          that.proClassList = [];
          that.proNormal = [];
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "getAreaAll", function getAreaAll() {
    var _this11 = this;
    (0, _dealer.dealerAreaList)({
      isDelete: ''
    }).then(function (res) {
      if (res.data.code !== 200) {
        _this11.$message.error(res.data.msg);
      } else {
        var areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
        _this11.areaList = areaList;
      }
    });
  }), (0, _defineProperty2.default)(_methods, "loadArea", function loadArea(node, resolve) {
    if (node.value != null) {
      (0, _dealer.nextArea)({
        isDelete: '',
        parentId: node.value
      }).then(function (res) {
        resolve(res.data.data);
      });
    }
  }), (0, _defineProperty2.default)(_methods, "getAreaInUse", function getAreaInUse() {
    var that = this;
    (0, _dealer.dealerAreaList)({
      isDelete: 1
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          that.areaInUseList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
        } else {
          that.areaInUseList = [];
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "resetForm", function resetForm(formName) {
    this.searchForm = {
      archivesStatus: '',
      channelClassId: '',
      channelGradeId: '',
      channelOrgId: '',
      // masterChannelId: '',
      channelType: 2,
      provinceCode: '',
      cityCode: '',
      countyCode: '',
      current: 1,
      size: 20
    };
    this.masterName = '';
    this.channelOrgId = '';
    this.salesManName = '';
    this.area = [];
    this.getList();
  }), (0, _defineProperty2.default)(_methods, "handleSelectionChange", function handleSelectionChange(val) {
    this.multipleSelection = val;
    if (this.multipleSelection.length) {
      this.ids = this.multipleSelection.map(function (item) {
        return item.pId;
      });
    }
  }), (0, _defineProperty2.default)(_methods, "filterparentList", function filterparentList(val) {
    var that = this;
    return this.normalList.filter(function (number) {
      if (number.pId == val) {
        that.orgselList.unshift(val);
        if (number.parentPid) {
          that.filterparentList(number.parentPid);
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "filterPorList", function filterPorList(val, item) {
    var that = this;
    var arr = item || [];
    this.proNormal.forEach(function (number) {
      if (number.pId == val) {
        arr.unshift(val);
        if (number.parentId) {
          that.filterPorList(number.parentId, arr);
        }
      }
    });
    return arr;
  }), (0, _defineProperty2.default)(_methods, "add", function add() {
    this.myHeaders.btnId = this.authId('addbtn', this.$route.meta.authList);
    this.title = '添加终端档案';
    this.getOrgList();
    this.dialogVisible = true;
  }), (0, _defineProperty2.default)(_methods, "scanClose", function scanClose() {
    this.scanData = {};
    this.imgList = [];
    this.scanVisible = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "detail", function detail(row) {
    var _this12 = this;
    this.authId('detailbtn', this.$route.meta.authList);
    this.title = '终端档案';
    this.scanVisible = true;
    this.mloading = true;
    (0, _channel.channelArchivesDetail)({
      pId: row.pId
    }).then(function (res) {
      _this12.mloading = false;
      if (res.data.code == 200) {
        _this12.scanData = res.data.data;
        var provinceName = _this12.scanData.provinceName || '';
        var cityName = _this12.scanData.cityName || '';
        var countyName = _this12.scanData.countyName || '';
        var detailedAddress = _this12.scanData.detailedAddress || '';
        _this12.scanData.add = provinceName + cityName + countyName + detailedAddress;
        var longitude = _this12.scanData.longitude || '';
        var latitude = _this12.scanData.latitude || '';
        _this12.scanData.jwd = longitude + '\xa0\xa0\xa0' + latitude;
        if (res.data.data.businessLicense) {
          _this12.scanData.imgList = res.data.data.businessLicense.split(',');
        }
        if (res.data.data.productClasses) {
          _this12.porList = [];
          res.data.data.productClasses.forEach(function (item) {
            var arr = _this12.filterPorList(item.pid);
            _this12.porList.push(arr);
          });
        }
        if (res.data.data.salesmans) {
          _this12.salesmanIds = [];
          res.data.data.salesmans.forEach(function (item) {
            _this12.salesmanIds.push({
              pid: item.pid,
              loginAccount: item.loginAccount
            });
          });
        }
      } else {
        _this12.$message.error(res.data.msg);
      }
      _this12.mloading = false;
    }).catch(function () {
      _this12.mloading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "DoEdit", function DoEdit(row) {
    var _this13 = this;
    var that = this;
    that.title = '修改终端档案';
    this.mloading = true;
    this.getOrgList();
    this.myHeaders.btnId = this.authId('updatebtn', this.$route.meta.authList);
    (0, _channel.channelArchivesDetail)({
      pId: row.pId
    }).then(function (res) {
      if (res.data.code == 200) {
        _this13.ruleForm = res.data.data;
        // this.ruleForm.EMail = res.data.data.email
        _this13.ruleForm.pid = res.data.data.pid;
        // if (res.data.data.masterChannelArchives) {
        //   this.ruleForm.masterChannelId =
        //     res.data.data.masterChannelArchives.pid
        //   this.masterChannelName =
        //     res.data.data.masterChannelArchives.channelName
        // }
        // this.changeorg(this.ruleForm.orgId, 'Y')
        // this.ruleForm.departmentId = res.data.data.departmentId
        // if (res.data.data.orgId) {
        //   this.orgselList = []
        //   this.filterparentList(res.data.data.orgId)
        //   that.$refs.perCheckbox.searchForm.orgId = res.data.data.orgId
        // }
        if (res.data.data.businessLicense) {
          var imgArr = res.data.data.businessLicense.split(',');
          imgArr.forEach(function (item) {
            _this13.imgList.push({
              url: item,
              response: {
                data: item
              }
            });
          });
        }
        var porList = [];
        // if (res.data.data.productClasses) {
        //   res.data.data.productClasses.forEach(item => {
        //     // var arr = this.filterPorList(item.pid)
        //     porList.push(item.pid)
        //   })
        // }
        that.porList = porList;
        if (res.data.data.salesmans) {
          _this13.salesmanIds = res.data.data.salesmans;
        }
      } else {
        _this13.$message.error(res.data.msg);
      }
      _this13.mloading = false;
    }).catch(function () {
      _this13.mloading = false;
    });
    this.dialogVisible = true;
  }), (0, _defineProperty2.default)(_methods, "handleClose", function handleClose() {
    this.salesmanIds = [];
    this.areaSel = [];
    this.porList = [];
    this.orgselList = [];
    this.imgList = [];
    this.masterChannelName = '';
    this.ruleForm = {
      creditCode: '',
      sourceEnum: 1,
      channelName: '',
      channelType: 2,
      contacts: '',
      isCode: 0,
      detailedAddress: '',
      phone: '',
      pid: '',
      cardNo: '' // 身份证号
    };

    this.disabled = false;
    this.$refs.ruleForm.resetFields();
    this.dialogVisible = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "submitForm", function submitForm() {
    var _this14 = this;
    this.$refs['ruleForm'].validate(function (valid) {
      if (valid) {
        _this14.btnLoading = true;
        var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this14.ruleForm)));
        submitData.openTime = _this14.ruleForm.openTime ? (0, _utils.parseTime)(_this14.ruleForm.openTime, '{y}-{m}-{d}') : '';
        submitData.pId = _this14.ruleForm.pid;
        console.log(_this14.ruleForm, 'this.ruleForm');
        delete submitData.channelGradeId;
        delete submitData.pid;
        delete submitData.createTime;
        delete submitData.updateTime;
        delete submitData.productClasses;
        delete submitData.masterChannelArchives;
        delete submitData.creatorId;
        delete submitData.updaterId;
        delete submitData.salesmans;
        delete submitData.strongCheck;
        delete submitData.cityName;
        delete submitData.className;
        delete submitData.countyName;
        delete submitData.gradeName;
        delete submitData.orgName;
        delete submitData.provinceName;
        delete submitData.products;
        delete submitData.groupCode;
        delete submitData.groupName;
        delete submitData.isUsed;
        delete submitData.minTypeName;
        delete submitData.minType;
        delete submitData.departmentId;
        delete submitData.masterChannelId;
        delete submitData.departmentName;
        delete submitData.createUserName;
        delete submitData.regionName;
        delete submitData.marketName;
        delete submitData.marketId;
        delete submitData.teamName;
        delete submitData.teamId;
        delete submitData.saleArea;
        delete submitData.areaCode;
        delete submitData.userName;
        // submitData.orgId = this.orgselList[this.orgselList.length - 1]
        if (_this14.porList.length > 0) {
          // var strArr = []
          // this.porList.forEach((item) => {
          //   strArr.push(item[item.length - 1])
          // })
          // submitData.productClassIds = this.porList.join(',')
        }
        if (_this14.imgList.length > 0) {
          var imgArr = [];
          _this14.imgList.forEach(function (item) {
            imgArr.push(item.response.data);
          });
          submitData.businessLicense = imgArr.join(',');
        }
        if (_this14.salesmanIds.length > 0) {
          var salesArr = [];
          _this14.salesmanIds.forEach(function (item) {
            salesArr.push(item.pid);
          });
          submitData.salesmanIds = salesArr.join(',');
        }
        if (_this14.ruleForm.pid) {
          _this14.authId('updatebtn', _this14.$route.meta.authList);
          console.log(_this14.ruleForm, 'this.ruleForm');
          console.log(submitData, 'submitData');
          (0, _channel.channelArchivesUpdate)(submitData).then(function (res) {
            if (res.data.code != 200) {
              _this14.$message.error(res.data.msg);
            } else {
              _this14.$message({
                message: '保存成功',
                type: 'success'
              });
              _this14.handleClose();
              _this14.getList();
            }
            _this14.btnLoading = false;
          }).catch(function () {
            _this14.btnLoading = false;
          });
        } else {
          _this14.authId('addbtn', _this14.$route.meta.authList);
          console.log(submitData, 'submitData');
          (0, _channel.channelArchivesInsert)(submitData).then(function (res) {
            if (res.data.code != 200) {
              _this14.$message.error(res.data.msg);
            } else {
              _this14.$message({
                message: '保存成功',
                type: 'success'
              });
              _this14.handleClose();
              _this14.getList();
            }
            _this14.btnLoading = false;
          }).catch(function () {
            _this14.btnLoading = false;
          });
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "setInUse", function setInUse(id) {
    var _this15 = this;
    this.$confirm('请确认是否启用?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _this15.authId('startbtn', _this15.$route.meta.authList);
      (0, _channel.channelArchivesEnable)({
        archivesStatus: 1,
        pId: id
      }).then(function (res) {
        _this15.authId('searchbtn', _this15.$route.meta.authList);
        if (res.data.code != 200) {
          _this15.$message.error(res.data.msg);
        } else {
          _this15.$message({
            message: '操作成功',
            type: 'success'
          });
          _this15.getList();
        }
      });
    }).catch(function () {
      _this15.authId('searchbtn', _this15.$route.meta.authList);
    });
  }), (0, _defineProperty2.default)(_methods, "setUnUse", function setUnUse(id) {
    var _this16 = this;
    this.$confirm('请确认是否禁用?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _this16.authId('stopbtn', _this16.$route.meta.authList);
      (0, _channel.channelArchivesEnable)({
        archivesStatus: 0,
        pId: id
      }).then(function (res) {
        _this16.authId('searchbtn', _this16.$route.meta.authList);
        if (res.data.code != 200) {
          _this16.$message.error(res.data.msg);
        } else {
          _this16.$message({
            message: '操作成功',
            type: 'success'
          });
          _this16.getList();
        }
      });
    }).catch(function () {
      _this16.authId('searchbtn', _this16.$route.meta.authList);
    });
  }), (0, _defineProperty2.default)(_methods, "DoDel", function DoDel(id) {
    var _this17 = this;
    var that = this;
    this.authId('deletebtn', this.$route.meta.authList);
    that.$confirm('确认删除?将不可撤销', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _this17.authId('deletebtn', _this17.$route.meta.authList);
      (0, _channel.channelArchivesDelete)({
        pId: id,
        isDelete: 2
      }).then(function (res) {
        _this17.authId('searchbtn', _this17.$route.meta.authList);
        if (res.data.code == 200) {
          _this17.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.getList();
        } else {
          _this17.$message({
            type: 'info',
            message: res.data.msg
          });
        }
      });
    }).catch(function () {
      _this17.authId('searchbtn', _this17.$route.meta.authList);
    });
  }), (0, _defineProperty2.default)(_methods, "selectFile", function selectFile(file, fileList) {
    this.fileList = fileList;
    this.uploadForm.uploadFile = file.name;
  }), (0, _defineProperty2.default)(_methods, "uploadClose", function uploadClose() {
    this.resetForm('uploadForm');
    this.uploadForm.uploadFile = '';
    // this.uploadForm.regionId = ''
    this.uploadData = [];
    this.uploadVisible = false;
    this.btnDisabled = false;
    this.btnLoading = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "tag1Close", function tag1Close(tag) {
    if (!this.disabled) {
      this.salesmanIds.splice(this.salesmanIds.indexOf(tag), 1);
    }
  }), (0, _defineProperty2.default)(_methods, "handleRemove", function handleRemove(file, fileList) {
    this.imgList = fileList;
  }), (0, _defineProperty2.default)(_methods, "beforeAvatarUpload", function beforeAvatarUpload(file, fileList) {
    // const file = param.file
    var isJPG = file.type === 'image/jpeg';
    var isPNG = file.type === 'image/png';
    var isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG && !isPNG) {
      this.$message.error('上传图片只能是 PNG/JPG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
    }
    // if ((isJPG || isPNG) && isLt2M) {
    //   const formData = new FormData()
    //   formData.append('file', file)
    //   imgupload(formData).then(response => {
    //     var data = response.data
    //     if (data.code != 200) {
    //       this.$message.error(data.msg)
    //     } else {
    //       this.imgList.push({ url: data.data })
    //     }
    //   })
    // }
    return (isJPG || isPNG) && isLt2M;
  }), (0, _defineProperty2.default)(_methods, "openSel", function openSel() {
    // if (this.orgselList.length == 0) {
    //   this.$message.error('请先选择组织')
    // } else {
    //   this.$refs.perCheckbox.searchForm.orgId = this.orgselList[
    //     this.orgselList.length - 1
    //   ]
    this.$refs.perCheckbox.proSelVisible = true;
    this.$refs.perCheckbox.setsel();
    // }
  }), (0, _defineProperty2.default)(_methods, "setSel", function setSel(val) {
    this.salesmanIds = val;
  }), (0, _defineProperty2.default)(_methods, "download", function download() {
    var _this18 = this;
    this.authId('exportbtn', this.$route.meta.authList);
    this.downLoading = true;
    var submitData = (0, _utils.filterKeyNull)(this.searchForm);
    submitData = JSON.parse(JSON.stringify(submitData));
    delete submitData.size;
    // submitData.size = 2147483647
    (0, _channel.channelArchivesExportExcel)(submitData).then(function (res) {
      _this18.authId('searchbtn', _this18.$route.meta.authList);
      _this18.downLoading = false;
      if (res.status != 200) {
        _this18.$message.error(res.data.msg);
      } else {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '终端档案.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    }).catch(function () {
      _this18.authId('searchbtn', _this18.$route.meta.authList);
      _this18.downLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "uploadShow", function uploadShow() {
    this.getregionList();
    this.myHeaders.btnId = this.authId('allinport', this.$route.meta.authList);
    this.uploadVisible = true;
  }), (0, _defineProperty2.default)(_methods, "submitUpload", function submitUpload() {
    if (!this.uploadForm.uploadFile) {
      this.$message.error('请导入文件');
      return;
    }
    // if (!this.uploadForm.regionId) {
    //   this.$message.error('请选择销售组织')
    //   return
    // }
    this.mloading = true;
    this.uploadData = [];
    this.btnLoading = true;
    this.btnDisabled = true;
    this.$refs.upload.submit();
  }), (0, _defineProperty2.default)(_methods, "uploadFileRes", function uploadFileRes(res, file, fileList) {
    if (res.code == 2004) {
      this.$message.error(res.msg);
      this.uploadData = res.data;
    } else if (res.code != 200) {
      this.$message.error(res.msg);
    } else {
      this.$message({
        type: 'success',
        message: '导入成功!'
      });
      this.uploadClose();
      file.name = '';
      this.getList();
    }
    this.mloading = false;
    this.fileList = [];
    this.$refs.upload.clearFiles();
  }), (0, _defineProperty2.default)(_methods, "imgUploadSuccess", function imgUploadSuccess(res, file, fileList) {
    if (res.code != 200) {
      this.$message.error(res.msg);
    }
  }), (0, _defineProperty2.default)(_methods, "selectImg", function selectImg(file, fileList) {
    this.imgList = fileList;
  }), (0, _defineProperty2.default)(_methods, "uploadFileFail", function uploadFileFail(res, file, fileList) {
    this.$message.error('上传失败请重试');
    this.mloading = false;
  }), (0, _defineProperty2.default)(_methods, "openSalesManId", function openSalesManId() {
    this.dialogSalesmanList = true;
  }), (0, _defineProperty2.default)(_methods, "batchAddSalesman", function batchAddSalesman() {
    if (!this.multipleSelection.length) return this.$message.error('请选择经销商!');
    this.salesManUserId = '';
    this.isShow = true;
  }), (0, _defineProperty2.default)(_methods, "batchUpdateSalesman", function batchUpdateSalesman() {
    if (!this.searchForm.salesManId) return this.$message.error('请选择业务员!');
    if (!this.multipleSelection.length) return this.$message.error('请选择经销商!');
    this.salesManUserId = this.searchForm.salesManId;
    this.isShow = true;
  }), (0, _defineProperty2.default)(_methods, "batchDeleteSalesman", function batchDeleteSalesman() {
    var _this19 = this;
    if (this.multipleSelection.length > 0) {
      this.$confirm('是否解除所选经销商所有业务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var pList = [];
        _this19.multipleSelection.forEach(function (item) {
          pList.push({
            'channelId': item.pId
          });
        });
        (0, _channel.batcRemoveAllSalesMan)(pList).then(function (res) {
          if (res.data.code != 200) {
            _this19.$message.error(res.data.msg);
            _this19.getList();
          } else {
            _this19.$message({
              message: '操作成功',
              type: 'success'
            });
            _this19.searchForm.salesManId = '';
            _this19.salesManName = '';
            _this19.getList();
          }
        });
      }).catch(function () {
        _this19.searchForm.salesManId = '';
        _this19.salesManName = '';
        _this19.getList();
      });
    } else {
      this.$message.error('请选择经销商');
    }
  }), (0, _defineProperty2.default)(_methods, "querylist", function querylist() {
    this.current = 1;
    this.getcomBoxSalesManlist();
  }), (0, _defineProperty2.default)(_methods, "resetQuerylist", function resetQuerylist() {
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.salesmanSize = 10;
    this.current = 1;
    this.channelOrgId = '';
    this.getcomBoxSalesManlist();
  }), (0, _defineProperty2.default)(_methods, "selCancel", function selCancel() {
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.size = 10;
    this.current = 1;
    this.channelOrgId = '';
    this.getcomBoxSalesManlist();
    this.dialogSalesmanList = false;
    this.searchForm.current = 1;
    this.getList();
  }), (0, _defineProperty2.default)(_methods, "submitSalesmanList", function submitSalesmanList(row) {
    console.log(row);
    this.salesManName = row.salesManName;
    this.searchForm.salesManId = row.pid;
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.size = 10;
    // this.current = 1
    this.channelOrgId = '';
    this.searchForm.current = 1;
    this.getList();
    // this.getcomBoxSalesManlist()
    this.dialogSalesmanList = false;
  }), (0, _defineProperty2.default)(_methods, "getcomBoxSalesManlist", function getcomBoxSalesManlist() {
    var _this20 = this;
    (0, _dealer.comBoxSalesManlist)(this.current, this.salesmanSize, this.querysalesman).then(function (res) {
      console.log(res.data, 'xialkuan');
      if (res.data.code !== 200) {
        return _this20.$message.error(res.data.msg);
      } else {
        if (res.data.data !== null) {
          _this20.salesmanTotal = res.data.data.total * 1;
          _this20.salesmanList = res.data.data.records;
          _this20.salesmanSize = res.data.data.size * 1;
          // var hash = {}
          // this.salesmanList = this.salesmanList.reduce(function(item, next) {
          //   hash[next.pid] ? '' : hash[next.pid] = true && item.push(next)
          //   return item
          // }, [])
          // console.log(this.salesmanList, 'this.salesmanList')
        } else {
          _this20.salesmanTotal = 0;
          _this20.salesmanList = [];
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "closePriseUsers", function closePriseUsers(data, errorSalesManName, isTips) {
    this.isShow = false;
    if (data && data.length) {
      this.isShowErrorPrise = true;
      this.errorPriseUsers = data;
      this.errorSalesManName = errorSalesManName;
    } else {
      isTips && this.$message.success('操作成功');
    }
  }), (0, _defineProperty2.default)(_methods, "getMultipleSelection", function getMultipleSelection() {}), (0, _defineProperty2.default)(_methods, "getData", function getData() {
    this.searchForm.salesManId = '';
    this.salesManName = '';
    this.getList();
  }), _methods)
};
exports.default = _default;