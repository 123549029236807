"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _vuePdf = _interopRequireDefault(require("vue-pdf"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Systemguid',
  components: {
    pdf: _vuePdf.default
  },
  props: {
    pdfVisible: Boolean,
    filePath: String,
    page: Number
  },
  data: function data() {
    return {
      currentPage: 0,
      // pdf文件页码
      pageCount: 0,
      // pdf文件总页数
      fileType: 'pdf',
      // 文件类型
      pageRotate: 0,
      loadedRatio: 0,
      src: '',
      loading: false
    };
  },
  watch: {
    pdfVisible: function pdfVisible(val) {
      var that = this;
      if (val) {
        var path = that.filePath;
        var loadingTask = _vuePdf.default.createLoadingTask(path);
        that.src = loadingTask;
        that.currentPage = that.page;
        that.src.promise.then(function (pdf) {
          that.pageCount = pdf.numPages;
        });
      }
    }
  },
  methods: {
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage: function changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    // pdf加载时
    loadPdfHandler: function loadPdfHandler(e) {
      this.loading = true;
      this.currentPage = this.page;
    },
    // pdf加载完成时
    pageLoaded: function pageLoaded(e) {
      this.curPageNum = e;
      this.loading = false;
    },
    selCancel: function selCancel() {
      this.$emit('close');
    },
    handleScroll: function handleScroll() {
      var that = this;
      var scrollTop = document.getElementById('pdf').scrollTop;
      var windowH = document.getElementById('pdf').clientHeight;
      var scrollH = document.getElementById('pdf').scrollHeight;
      if (scrollTop + windowH == scrollH) {
        if (that.currentPage < that.pageCount) {
          setTimeout(function () {
            that.currentPage++;
            document.getElementById('pdf').scrollTo(0, 20);
          }, 50);
        }
      }
      if (scrollH - scrollTop == scrollH) {
        if (that.currentPage > 1) {
          setTimeout(function () {
            that.currentPage--;
            document.getElementById('pdf').scrollTo(0, 20);
          }, 50);
        }
      }
    }
  }
};
exports.default = _default;