"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _personDialog = _interopRequireDefault(require("@/components/personDialog"));
var _productDialogPD = _interopRequireDefault(require("@/components/productDialogPD"));
var _factory = require("@/api/basic/factory");
var _stock = require("@/api/warehouse/stock/stock");
var _inventory = require("@/api/warehouse/inventory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialogPD.default,
    personDialog: _personDialog.default
  },
  filters: {
    // minSum(sum, row) {
    //   if (!sum || !row.packScaleExpression) return 0
    //   var arr = row.packScaleExpression.split('*')
    //   var le = arr.length
    //   if (le == 1) {
    //     return sum
    //   }
    //   var num = (sum * arr[le - 1]).toFixed(0)
    //   // miniUnit = num
    //   row.storeInventoryB = num * 1
    //   return num * 1
    // }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    submitType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.productList.length == 0 || _this.productList == '' || _this.productList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '盘点单-新增',
      formLoading: false,
      listLoading: false,
      btn_loading: false,
      proShow: false,
      isUpdate: false,
      scanData: {
        inventoryDate: '',
        inventoryOrderDetailDTOList: [
          // {
          //   bottleActualNum: 0,
          //   bottleDiffNum: 0,
          //   bottleInventory: 0,
          //   chestActualNum: 0,
          //   chestDiffNum: 0,
          //   chestInventory: 0,
          //   inventoryOrderNo: '',
          //   packRaidoExpress: '',
          //   productCode: '',
          //   productId: '',
          //   productName: '',
          //   productSpec: ''
          // }
        ],
        inventoryOrderNo: '',
        inventoryUser: JSON.parse(sessionStorage.getItem('userId')),
        inventoryUserName: JSON.parse(sessionStorage.getItem('userName')),
        remark: '',
        storeId: '',
        orgId: '',
        storeName: ''
      },
      rules: {
        inventoryOrderNo: [{
          required: true,
          message: '盘点单号是必填项，请维护',
          trigger: 'change'
        }],
        inventoryDate: [{
          required: true,
          message: '盘点日期是必填项，请维护',
          trigger: 'change'
        }],
        storeId: [{
          required: true,
          message: '盘点库房是必填项，请维护',
          trigger: 'change'
        }],
        inventoryUserName: [{
          required: true,
          message: '盘点人房是必填项，请维护',
          trigger: 'change'
        }],
        materialCode: [{
          required: true,
          trigger: 'change',
          validator: validatematerialCode
        }]
      },
      storeList: [],
      productList: []
    };
  },
  mounted: function mounted() {
    this.getStoreList();
    if (!this.id) {
      this.getCreateNum();
    } else {
      this.isUpdate = true;
      this.title = '盘点单-编辑';
      this.formLoading = true;
      this.getDetail();
    }
  },
  methods: {
    getCreateNum: function getCreateNum() {
      var _this2 = this;
      (0, _inventory.generateOrderSequence)().then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        }
        _this2.scanData.inventoryOrderNo = res.data.data;
      });
    },
    getDetail: function getDetail() {
      var _this3 = this;
      (0, _inventory.queryById)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this3.scanData = res.data.data;
          var arr = [];
          _this3.scanData.inventoryOrderDetailDTOList.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productName: item.productName,
              productCode: item.productCode,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              chestInventory: item.chestInventory,
              bottleInventory: item.bottleInventory
            };
            arr.push(obj);
          });
          _this3.productList = arr;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.formLoading = false;
      }).catch(function () {
        _this3.formLoading = false;
      });
    },
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    openUser: function openUser() {
      this.$refs.personDialog.proSelVisible = true;
    },
    setUser: function setUser(val) {
      this.scanData.inventoryUser = val.pid;
      this.scanData.inventoryUserName = val.userName;
    },
    openSel: function openSel() {
      this.proShow = true;
    },
    setSel: function setSel(val) {
      // console.log(val)
      // var arr = JSON.parse(JSON.stringify(this.productList))
      // if (val.length) {
      //   if (this.productList.length == 0) {
      //     var obj = {
      //       prodUnit: 1,
      //       chestNum: '',
      //       bottleNum: ''
      //     }
      //     val.forEach(item => {
      //       item = Object.assign(item, obj)
      //     })
      //   } else {
      //     val.forEach(item => {
      //       item.prodUnit = 1
      //       item.chestNum = ''
      //       item.bottleNum = ''
      //       arr.forEach(el => {
      //         if (el.pId == item.pId) {
      //           item.prodUnit = el.prodUnit
      //           item.chestNum = el.chestNum
      //           item.bottleNum = el.bottleNum
      //         }
      //       })
      //     })
      //   }
      this.productList = JSON.parse(JSON.stringify(val));
      this.setStore(this.scanData.storeId);
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    // 删除产品明细列表
    del: function del(row) {
      var _this4 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.productList.splice(_this4.productList.indexOf(row), 1);
      });
    },
    setStore: function setStore(val) {
      var _this5 = this;
      if (!this.productList.length || !val) {
        return;
      }
      var sendComId;
      this.storeList.filter(function (item) {
        if (val == item.pId) {
          sendComId = item.orgId;
          _this5.scanData.orgId = item.orgId;
        }
      });
      this.listLoading = true;
      var _loop = function _loop(i) {
        (0, _stock.queryStoreProdForPage)({
          productCode: _this5.productList[i].pId,
          storageCode: val,
          orgCode: sendComId,
          beginDate: '',
          current: 1,
          size: 20,
          endDate: '',
          // orgCode: '1258942158274752514',
          // productCode: '1265182581178724354',
          // storageCode: '1264759790273634305',
          storeStatus: ''
        }).then(function (res) {
          _this5.productList[i].chestInventory = 0;
          _this5.productList[i].bottleInventory = 0;
          if (res.data.code == 200) {
            if (res.data.data.records && res.data.data.records.length > 0 && res.data.data.records[0].storeProductVOS.length) {
              _this5.productList[i].chestInventory = res.data.data.records[0].storeProductVOS[0].storeChestCount || 0;
              _this5.productList[i].bottleInventory = res.data.data.records[0].storeProductVOS[0].storeBottleCount || 0;
            }
          }
          if (i == _this5.productList.length - 1) {
            _this5.resetList();
            _this5.listLoading = false;
          }
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    change: function change() {
      this.$emit('change');
    },
    submitForm: function submitForm() {
      var _this6 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this6.btn_loading = true;
          var data = JSON.parse(JSON.stringify(_this6.scanData));
          _this6.storeList.forEach(function (item) {
            if (item.pId == _this6.scanData.storeId) {
              data.storeName = item.storeHouseName;
            }
          });
          var arr = [];
          _this6.productList.forEach(function (item) {
            var obj = {
              bottleActualNum: item.bottleActualNum || '',
              bottleDiffNum: item.bottleDiffNum || '',
              bottleInventory: item.bottleInventory,
              chestActualNum: item.chestActualNum || '',
              chestDiffNum: item.chestDiffNum || '',
              chestInventory: item.chestInventory,
              inventoryOrderNo: data.inventoryOrderNo,
              packRaidoExpress: item.packScaleExpression,
              productCode: item.productCode,
              productId: item.pId,
              productName: item.productName,
              productSpec: item.productModel
            };
            arr.push(obj);
          });
          data.inventoryOrderDetailDTOList = arr;
          if (_this6.isUpdate) {
            (0, _inventory.updateOrder)(data).then(function (res) {
              _this6.btn_loading = false;
              if (res.data.code == 200) {
                _this6.$message({
                  type: 'success',
                  message: '编辑成功'
                });
                _this6.change();
              } else {
                _this6.$message.error(res.data.msg);
              }
            }).catch(function () {
              _this6.btn_loading = false;
            });
          } else {
            (0, _inventory.insertOrder)(data).then(function (res) {
              _this6.btn_loading = false;
              if (res.data.code == 200) {
                _this6.$message({
                  type: 'success',
                  message: '添加成功'
                });
                _this6.change();
              } else {
                _this6.$message.error(res.data.msg);
              }
            }).catch(function () {
              _this6.btn_loading = false;
            });
          }
        } else {
          _this6.btn_loading = false;
          _this6.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;