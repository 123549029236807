"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostrequestCcdetail',
  filters: {
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value * 1];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      cardPadding: {
        padding: '0 0 20px'
      },
      loading: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      orderStatus: [],
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: '',
        tradeType: ''
      },
      productList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCCGDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentercostrequest-ccindex'
      });
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$router.go(-1);
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              // pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum
              // totalPrice: item.totalPrice,
              // productPrice: item.prodPrice,
            };

            arr.push(obj);
          });
          _this3.productList = arr;
          _this3.addData = res.data.data;
          _this3.purchaseComName = data.purchaseOrgName;
          _this3.receiveComName = data.receiveOrgName;
          _this3.sendOutComName = data.saleOrgName;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;