"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDateCollectionInfoList = addDateCollectionInfoList;
exports.dateCollection = dateCollection;
exports.dateCollectionInfoList = dateCollectionInfoList;
exports.editDateCollectionInfoList = editDateCollectionInfoList;
exports.isStop = isStop;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 数据收集列表
function dateCollectionInfoList(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityDateCollection/dateCollectionInfo',
    method: 'get',
    params: params
  });
}

// 添加数据收集
function addDateCollectionInfoList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityDateCollection/dateCollectionSave',
    method: 'post',
    data: data
  });
}
// 编辑数据收集
function editDateCollectionInfoList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityDateCollection/dateCollectionUpdate',
    method: 'post',
    data: data
  });
}
// 数据收集启用停用
function isStop(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityDateCollection/dateCollectionSwitch',
    method: 'get',
    params: params
  });
}
// 删除数据收集数据
function dateCollection(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityDateCollection/dateCollectionDelete',
    method: 'get',
    params: params
  });
}