"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work = require("@/api/systems/work");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'WorkVerify',
  components: {},
  props: {
    pId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    taskId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    callbackArgs: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    usenormal: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      subData: {
        pId: '',
        auditMsg: '',
        callbackArgs: '',
        taskId: ''
      },
      loading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    that.subData.pid = that.pId;
    that.subData.taskId = that.taskId;
    that.subData.callbackArgs = that.callbackArgs ? that.callbackArgs : '';
    console.log(that.callbackArgs, '回调参数');
  },
  methods: {
    handleClose1: function handleClose1() {
      var that = this;
      that.subData.pid = '';
      that.subData.auditMsg = '';
      that.subData.taskId = '';
      that.subData.callbackArgs = '';
      that.$router.go(-1);
    },
    doCancel: function doCancel() {
      var _this = this;
      var that = this;
      that.loading = true;
      if (that.subData.auditMsg == '') {
        that.$message.error('请输入审核意见');
        that.loading = false;
        this.$emit('changeLoading');
        return;
      }
      (0, _work.refuesePass)(that.subData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '已驳回'
          });
          that.loading = false;
          _this.$emit('changeLoading');
          that.handleClose1();
        } else {
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.loading = false;
        _this.$emit('changeLoading');
      });
    },
    submit: function submit() {
      var _this2 = this;
      var that = this;
      that.loading = true;
      if (that.subData.auditMsg == '') {
        that.$message.error('请输入审核意见');
        that.loading = false;
        this.$emit('changeLoading');
        return;
      }
      (0, _work.onPass)(that.subData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '已通过'
          });
          that.loading = false;
          _this2.$emit('changeLoading');
          that.handleClose1();
        } else {
          that.$message.error(res.data.msg);
          that.loading = false;
          _this2.$emit('changeLoading');
        }
      }).catch(function () {
        that.loading = false;
        _this2.$emit('changeLoading');
      });
    }
  }
};
exports.default = _default2;