"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    products: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    },
    channelCollectionId: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      imgUrl: require('@/images/origin.png'),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: ''
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  mounted: function mounted() {
    this.getClass();
    this.getBrand();
    this.getPack();
    // this.getList()
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.products.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.getList();
      setTimeout(function () {
        if (_this.tableData.length) {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        }
        _this.selLoading = false;
      }, 500);
    },
    // 获取分类
    getClass: function getClass() {
      var _this2 = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this2.normalList = data;
          } else {
            _this2.normalList = [];
          }
        }
      });
    },
    // 获取品牌
    getBrand: function getBrand() {
      var _this3 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code !== 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.brandList = res.data.data;
          } else {
            _this3.brandList = [];
          }
        }
      });
    },
    // 包装比例
    getPack: function getPack() {
      var _this4 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code !== 200) {
          _this4.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this4.packList = res.data.data;
          } else {
            _this4.packList = [];
          }
        }
      });
    },
    // 获取列表
    getList: function getList() {
      var _this5 = this;
      var that = this;
      this.listLoading = true;
      var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(that.searchForm)));
      var le = that.searchForm.productClassId.length - 1 || '';
      submitData.productClassId = that.searchForm.productClassId[le] || '';
      submitData.channelCollectionId = this.channelCollectionId || '';
      // submitData.channelId = this.channelId || ''
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    // 重置
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        channelCollectionId: '',
        isCode: this.isCode
      };
      this.filterText = '';
      this.getList();
    },
    // 选择列表
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteProductItem: function deleteProductItem(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    // 清除
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    // 取消
    selCancel: function selCancel() {
      this.clear();
      this.$emit('close');
      // this.visible = false
    },
    // 提交
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    }
  }
};
exports.default = _default2;