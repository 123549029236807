"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic.js");
var _pack = require("@/api/basic/pack");
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _label = require("@/api/mark/coding_service/coding_apply_mange/label");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _label_tree = _interopRequireDefault(require("./label_tree"));
var _audit_log = _interopRequireDefault(require("../../components/audit_log"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { queryForList } from '@/api/mark/configure/labelGroupManage'

// const selectOptions = {
//   applyType: [
//     { id: 1, name: '套标申请' },
//     { id: 2, name: '后关联申请' }
//   ] // 申请方式
//   // packCodeList: null
// }
// const listQuery = {
//   pageNo: 1,
//   pageSize: 20,
//   packageOrderNum: '',
//   applyType: ''
// }
var setTemp = {
  packScaleDesc: '',
  packScaleExpression: '',
  packCode: '',
  flagName: ''
};
var codeTypeList = [];
var packNum = {
  2: 0,
  3: 0,
  4: 0
};
var _default = {
  name: 'ComplexTable',
  components: {
    label_tree: _label_tree.default,
    audit_list: _audit_log.default,
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (!time) return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    generateStatus: function generateStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '未生成',
        2: '生成成功',
        3: '生成失败'
      };
      return statusMap[status];
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    packSum: function packSum(level, ordNum) {
      if (!level || !ordNum) return '';
      if (level == 5) return '暂无';
      return (packNum[level] * ordNum).toFixed(2);
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内层级',
        2: '层级',
        3: '外层级'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      return statusPack[packLevel] + statusMark[markType] + '-' + item[0].markLen + '位';
    }
  },
  props: ['applyType', 'id', 'dialogStatus', 'dialogApplyVisible'],
  data: function data() {
    var _this = this;
    var validateOrderNum = function validateOrderNum(rule, value, callback) {
      if (_this.temp.applyStyle == 1 && !value) {
        callback('请填写订单数量');
      } else {
        callback();
      }
    };
    return {
      imgUrl: require("../../../../../images/origin.png"),
      generateColor: {
        1: 'unset',
        2: 'success',
        3: 'fail'
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      active: 0,
      tableKey: 2,
      dataFormdisabled: false,
      // list: null,
      flagelist: null,
      logList: null,
      temp: {
        PId: '',
        isPlatform: 0,
        applicationType: '',
        applyMarkType: 1,
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      },
      setTemp: setTemp,
      codelistLoading: false,
      // loglistLoading: false,
      // input_orderNum: false,
      // orderNum: 1,
      bottleNum: 1,
      boxNum: 1,
      chestNum: 1,
      btn_loading: false,
      loading: false,
      showPackCode: true,
      showOrderNum: true,
      showafterBox: false,
      showCareNum: false,
      showBoxNum: false,
      showBottleNum: false,
      packList: [],
      // packCodeList: selectOptions.packCodeList,
      // dialogStatus: '',
      textMap: {
        create: '制码申请',
        set: '查看详情',
        edit: '编辑详情'
      },
      // dialogApplyVisible: false,
      groupInnerVisible: false,
      rules: {
        applyStyle: [{
          required: true,
          message: '请选择申请方式',
          trigger: 'change'
        }],
        applicationType: [{
          required: true,
          message: '请选择应用场景',
          trigger: 'change'
        }],
        markOrderNo: [{
          required: true,
          message: '请填写制码订单号',
          trigger: 'change'
        }],
        orderNum: [{
          required: true,
          trigger: 'blur',
          validator: validateOrderNum
        }],
        expireDate: [{
          required: true,
          message: '请选择数码过期时间',
          trigger: 'change'
        }],
        packRatioCode: [{
          required: true,
          message: '请选择包装比例',
          trigger: 'change'
        }],
        flagGroupId: [{
          required: true,
          message: '请选择标识组',
          trigger: 'change'
        }]
      },
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        flagId: '',
        supplierType: 1
      }
    };
  },
  watch: {
    'temp.PId': function tempPId(newVal, oldVal) {
      if (Number(newVal) == 0) {
        this.Reset();
      } else {
        this.Details();
      }
    },
    'temp.flagGroupCode': function tempFlagGroupCode(newVal, oldVal) {
      this.getSelectCode(newVal);
    } // 'temp.packCode'(newVal, oldVal) {
    //   this.setTemp.packCode = newVal
    //   this.packCodeVal(newVal)
    // },
    // dialogStatus(newVal, oldVal) {
    //   if (newVal == 'set' || newVal == null) {
    //     this.dataFormdisabled = true
    //   }
    //   if (newVal == 'edit' || newVal == 'create') {
    //     this.dataFormdisabled = false
    //   }
    // }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    this.getUserInfo();
    this.getCodeType();
    if (this.dialogStatus == 'set') {
      this.dataFormdisabled = true;
    } else if (this.dialogStatus == 'edit' || this.dialogStatus == 'create') {
      this.dataFormdisabled = false;
    }
    (0, _pack.packScaleLeveAlllList)().then(function (res) {
      if (res.data.code != 200) {
        _this2.$message.error(res.data.msg);
        return;
      } else {
        if (res.data.data != null) {
          _this2.packList = res.data.data;
        } else {
          _this2.packList = [];
        }
      }
    }).then(function () {
      if (_this2.dialogStatus == 'set' || _this2.dialogStatus == 'edit') {
        _this2.loading = true;
        _this2.getDetail();
      }
    });
  },
  methods: {
    getDetail: function getDetail() {
      var _this3 = this;
      (0, _label.getMarkApplyDetail)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this3.temp = Object.assign(_this3.temp, res.data.data);
          _this3.temp.PId = _this3.temp.pid;
          _this3.temp.orderNum = Number(res.data.data.orderNum);
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    getCodeType: function getCodeType() {
      var _this4 = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this4.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getUserInfo: function getUserInfo() {
      var _this5 = this;
      (0, _label.getSupplierByUser)().then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this5.temp.supplierCode = res.data.data.supplierCode;
            _this5.temp.supplierName = res.data.data.supplierName;
          } else {
            _this5.$message.error('获取供应商信息失败，请使用供应商用户登录！');
          }
        }
      });
    },
    getapplyType: function getapplyType(v, status) {
      this.showPackCode = true;
      this.showOrderNum = true;
      this.showafterBox = false;
      this.showCareNum = false;
      this.showBoxNum = false;
      this.showBottleNum = false;
      if (!status) {
        this.temp.orderNum = '';
        this.bottleNum = 1;
        this.boxNum = 1;
        this.chestNum = 1;
        this.temp.bottleNum = '';
        this.temp.boxNum = '';
        this.temp.chestNum = '';
      }
      if (v == 2) {
        this.showPackCode = false;
        this.showOrderNum = false;
        this.showafterBox = true;
      }
      if (this.listQuery.flagId) {
        this.getModelList();
      }
    },
    packCodeVal: function packCodeVal(packCode) {
      var _this6 = this;
      var item = this.packList.filter(function (number) {
        return number.pId == packCode;
      });
      if (item.length) {
        this.setTemp.packScaleDesc = item[0].packScaleDesc;
        (0, _pack.getLevelById)({
          pId: item[0].pId
        }).then(function (res) {
          var obj = {
            2: 0,
            3: 0,
            4: 0
          };
          if (res.data.code == 200 && res.data.data && res.data.data.packScaleLevelItem && res.data.data.packScaleLevelItem.length > 0) {
            res.data.data.packScaleLevelItem.forEach(function (item) {
              obj[item.topClass * 1 + 1] = item.packNum * 1;
            });
          }
          packNum = obj;
          _this6.flagelist = JSON.parse(JSON.stringify(_this6.flagelist));
        });
      }
    },
    showLabelCode: function showLabelCode() {
      // 显示标签组页面
      this.groupInnerVisible = true;
    },
    getModelList: function getModelList() {
      var _this7 = this;
      var that = this;
      that.modeLoading = true;
      (0, _supplierRFlag.flagTemplatePage)(that.listQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.flagelist = response.data.data.records;
          } else {
            that.total = 0;
            that.flagelist = [];
          }
          if (_this7.temp.applyStyle == 2) {
            for (var i in _this7.flagelist) {
              if (_this7.flagelist[i].packLevel == 2) {
                _this7.showBottleNum = true;
                _this7.bottleNum = _this7.bottleNum == 1 ? 1 : _this7.bottleNum;
              }
              if (_this7.flagelist[i].packLevel == 3) {
                _this7.showBoxNum = true;
                _this7.boxNum = _this7.boxNum == 1 ? 1 : _this7.boxNum;
              }
              if (_this7.flagelist[i].packLevel == 4) {
                _this7.showCareNum = true;
                _this7.chestNum = _this7.chestNum == 1 ? 1 : _this7.chestNum;
              }
            }
          }
          that.modeLoading = false;
        }
      }).catch(function (res) {
        that.modeLoading = false;
      });
    },
    getSelectCode: function getSelectCode($code) {
      var code = this.$refs.labelGroupMain.flagGroupId;
      this.temp.flagGroupId = code;
      this.listQuery.flagId = code;
      if (code) {
        this.getModelList();
      } else {
        this.flagelist = [];
        this.total = 0;
      }
      this.$refs.dataForm.validateField('flagGroupId');
      this.labelClose();
    },
    Reset: function Reset() {
      if (this.$refs.dataForm != undefined) {
        // this.$refs.dataForm.resetFields()
      }
    },
    CloseMain: function CloseMain() {
      this.Reset();
      // this.dialogStatus = ''
      this.setTemp.packScaleDesc = '';
      this.temp = {
        PId: '',
        isPlatform: 0,
        applicationType: '',
        applyMarkType: 1,
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      };
      this.bottleNum = 1;
      this.boxNum = 1;
      this.chestNum = 1;
      this.flagelist = [];
      // this.dialogApplyVisible = false
      this.groupInnerVisible = false;
      this.logList = null;
      this.showPackCode = true;
      this.showOrderNum = true;
      this.showafterBox = false;
      this.showCareNum = false;
      this.showBoxNum = false;
      this.showBottleNum = false;
      this.authId('searchbtn', this.$route.meta.authList);
      this.$emit('close');
    },
    handleClose: function handleClose(done) {
      this.CloseMain();
    },
    labelClose: function labelClose() {
      this.$refs.labelGroupMain.flagGroupId = '';
      this.$refs.labelGroupMain.$refs.list_con.modelList = [];
      this.$refs.labelGroupMain.$refs.list_con.total = 0;
      this.total = 0;
      this.groupInnerVisible = false;
    },
    numChange: function numChange() {
      this.flagelist = JSON.parse(JSON.stringify(this.flagelist));
    },
    createData: function createData() {
      var _this8 = this;
      /* if(this.list.length!=this.setTemp.packScaleExpression.split('*').length && this.temp.applyType==1){
        this.$message('包装层级与标识组无法对应');
        return false;
      }
      */
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this8.btn_loading = true;
          if (_this8.temp.applyStyle == 2) {
            _this8.temp.orderNum = '';
            if (_this8.showBottleNum) {
              _this8.temp.bottleNum = _this8.bottleNum;
            } else {
              _this8.temp.bottleNum = 0;
            }
            if (_this8.showBoxNum) {
              _this8.temp.boxNum = _this8.boxNum;
            } else {
              _this8.temp.boxNum = 0;
            }
            if (_this8.showCareNum) {
              _this8.temp.chestNum = _this8.chestNum;
            } else {
              _this8.temp.chestNum = 0;
            }
          }
          // else {
          //   this.temp.orderNum = this.formatFloat(this.orderNum * 10000)
          //   this.temp.bottleNum = 0
          //   this.temp.boxNum = 0
          //   this.temp.chestNum = 0
          // }
          (0, _label.create)(_this8.temp).then(function (res) {
            _this8.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this8.$message.error(data.msg);
              return false;
            }
            _this8.$message({
              message: '操作成功',
              type: 'success'
            });
            _this8.$parent.getList();
            _this8.CloseMain();
          }).catch(function () {
            _this8.btn_loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this9 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this9.btn_loading = true;
          _this9.temp.PId = _this9.temp.pid;
          delete _this9.temp.createTime;
          delete _this9.temp.creatorId;
          delete _this9.temp.updateTime;
          delete _this9.temp.updaterId;
          delete _this9.temp.pid;
          if (_this9.temp.applyStyle == 2) {
            _this9.temp.orderNum = '';
            if (_this9.showBottleNum) {
              _this9.temp.bottleNum = _this9.bottleNum;
            } else {
              _this9.temp.bottleNum = 0;
            }
            if (_this9.showBoxNum) {
              _this9.temp.boxNum = _this9.boxNum;
            } else {
              _this9.temp.boxNum = 0;
            }
            if (_this9.showCareNum) {
              _this9.temp.chestNum = _this9.chestNum;
            } else {
              _this9.temp.chestNum = 0;
            }
          }
          (0, _label.update)(_this9.temp).then(function (res) {
            _this9.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this9.$message.error(data.msg);
              return false;
            }
            _this9.$parent.getList();
            _this9.$message({
              message: '操作成功',
              type: 'success'
            });
            _this9.CloseMain();
          }).catch(function () {
            _this9.btn_loading = false;
          });
        }
      });
    },
    // getflagGroupById(pId) {
    //   flagGroupByCode(pId).then(res => {
    //     var data = res.data
    //     if (data.code == 200) {
    //       this.temp.flagName = data.data.flagName
    //     }
    //   })
    // },
    Details: function Details() {
      this.listQuery.flagId = this.temp.flagGroupId;
      if (this.temp.applyStatus != 2) {
        this.active = 1;
      } else if (this.temp.generateStatus != 2) {
        this.active = 2;
      } else if (this.temp.downloadStatus != 2) {
        this.active = 3;
      } else {
        this.active = 4;
      }
      // if (this.listQuery.flagId) {
      //   this.getModelList()
      // }
      this.getModelList();
      this.getapplyType(this.temp.applyStyle, 'set');
      if (this.temp.applyStyle == 1) {
        this.packCodeVal(this.temp.packRatioCode);
      } else {
        this.bottleNum = this.temp.bottleNum;
        this.boxNum = this.temp.boxNum;
        this.chestNum = this.temp.chestNum;
      }
    }
  }
};
exports.default = _default;