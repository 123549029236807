var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardBox" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c("el-card", { staticClass: "box-card" }, [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", {
                        staticClass: "cardTitle",
                        domProps: {
                          textContent: _vm._s(_vm.$route.meta.title),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mod-prod-info" },
                    [
                      _c(
                        "el-form",
                        { ref: "dataForm", attrs: { model: _vm.dataForm } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("tiny-mce", {
                                ref: "content",
                                staticStyle: { width: "100%" },
                                model: {
                                  value: _vm.dataForm.rexplain,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "rexplain", $$v)
                                  },
                                  expression: "dataForm.rexplain",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.go(-1)
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.dataFormSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }