"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _stockscan = require("@/api/chanelmange/stockscan");
var _dictionary = require("@/api/systems/dictionary");
var _param = require("@/api/chanelmange/param");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _rebate = _interopRequireDefault(require("./component/rebate"));
var _markting = _interopRequireDefault(require("./component/markting"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var policys = [];
var _default = {
  name: 'BasicDatazcmangepolicyindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    rebate: _rebate.default,
    markting: _markting.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    filterType: function filterType(val) {
      var name = '';
      for (var i = 0; i < policys.length; i++) {
        if (val == policys[i].figure) {
          name = policys[i].name;
          break;
        }
      }
      return name;
    }
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        endTime: null,
        orgId: null,
        policyCode: null,
        policyName: null,
        policyType: null,
        status: 1,
        size: 20,
        startTime: null
      },
      tableKey: 'flzc',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      orgOptions: [],
      showCol: false,
      title: '政策管理编辑',
      expandTxt: '展开',
      dialogVisible: false,
      associatedPolicy: '',
      dialogVisible2: false,
      policyId: '',
      dialogVisible3: false,
      param: {
        endTime: '',
        files: '',
        orgId: null,
        policyName: '',
        policyType: null,
        pid: '',
        remark: '',
        startTime: ''
      },
      types: [],
      loading: false,
      uploading: false,
      fileList: [],
      editType: 'add',
      rules: {
        orgId: [{
          required: true,
          message: '请选择所属公司',
          trigger: 'change'
        }],
        policyName: [{
          required: true,
          message: '请输入政策名称',
          trigger: 'blur'
        }],
        policyType: [{
          required: true,
          message: '请选择政策类型',
          trigger: 'change'
        }],
        startTime: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'change'
        }],
        endTime: [{
          required: true,
          message: '请选择截止时间',
          trigger: 'change'
        }]
      },
      rebateOrGift: 1,
      dialogVisible4: false,
      detailData: {}
    };
  },
  activated: function activated() {
    var that = this;
    that.getTypes();
    that.getOrg();
    that.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _param.getFlZcList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getTypes: function getTypes() {
      var that = this;
      var param = {
        code: 'POLICY_TYPE'
      };
      (0, _dictionary.getItemByDataDictionaryByCode)(param).then(function (res) {
        if (res.data.code == 200) {
          policys = res.data.data;
          that.types = res.data.data;
        }
      });
    },
    getOrg: function getOrg() {
      var that = this;
      var param = {};
      param.orgType = '2';
      param.orgStatus = 1;
      param.orgTypeList = ['2'];
      (0, _stockscan.getTjQueryOrg)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.listQuery.size = 20;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        current: 1,
        endTime: null,
        orgId: null,
        policyCode: null,
        policyName: null,
        policyType: null,
        status: 1,
        size: 20,
        startTime: null
      };
      that.getList();
    },
    // 删除
    del: function del(data) {
      var that = this;
      var param = {
        pId: data.pid
      };
      that.$confirm('确认删除').then(function () {
        (0, _param.delPolicyMange)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功！'
            });
            that.DoSearch();
          } else {
            that.$message.error(res.data.msg);
            return;
          }
        });
      }).catch(function () {});
    },
    // 查看详情
    detail: function detail(data) {
      var that = this;
      (0, _param.getPolicyMangeDetail)({
        pId: data.pid
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data) {
            that.detailData = res.data.data;
          }
        }
        return true;
      }).then(function (res) {
        that.dialogVisible4 = true;
      });
    },
    handleWinClose: function handleWinClose() {
      var that = this;
      that.dialogVisible4 = false;
    },
    // 查看方案列表
    DoScan: function DoScan(data) {
      var that = this;
      that.rebateOrGift = Number(data.policyType);
      if (data.policyType == 1 || data.policyType == 2) {
        that.associatedPolicy = data.id;
        that.dialogVisible = true;
      }
      if (data.policyType == 3) {
        that.policyId = data.id;
        that.dialogVisible2 = true;
      }
    },
    closeRebate: function closeRebate() {
      var that = this;
      that.dialogVisible = false;
    },
    closeMarket: function closeMarket() {
      var that = this;
      that.dialogVisible2 = false;
    },
    // 编辑
    setZc: function setZc(data) {
      var that = this;
      that.title = '政策管理编辑';
      that.editType = 'edit';
      that.param.pid = data.pid;
      that.param.policyType = data.policyType;
      (0, _param.getPolicyMangeDetail)({
        pId: data.pid
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data) {
            that.param.endTime = res.data.data.endTime;
            that.param.files = res.data.data.files;
            that.param.orgId = res.data.data.orgId;
            that.param.policyName = res.data.data.policyName;
            that.param.policyCode = res.data.data.policyCode;
            that.param.remark = '';
            that.param.startTime = res.data.data.startTime;
          }
        }
        return true;
      }).then(function (res) {
        that.dialogVisible3 = true;
      });
    },
    handleCancel: function handleCancel() {
      this.handleClose('form');
    },
    handleClose: function handleClose(formName) {
      var that = this;
      that.param = {
        endTime: '',
        files: '',
        orgId: null,
        policyName: '',
        policyType: null,
        pid: '',
        remark: '',
        startTime: ''
      };
      that.fileList = [];
      that.$refs[formName].resetFields();
      that.dialogVisible3 = false;
    },
    handleSave: function handleSave(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var begin = new Date(that.param.startTime).getTime();
          var end = new Date(that.param.endTime).getTime();
          if (begin > end) {
            that.$message.error('开始日期不应大于结束日期！');
            return;
          }
          that.loading = true;
          if (that.editType == 'edit') {
            (0, _param.updatePolicyMange)(that.param).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.$message({
                  type: 'success',
                  message: '保存成功！'
                });
                that.handleCancel();
                that.DoSearch();
              } else {
                that.loading = false;
                that.$message.error(res.data.msg);
                return;
              }
            }).catch(function () {
              that.loading = false;
            });
          }
          if (that.editType == 'add') {
            var sdata = (0, _utils.deepClone)(that.param);
            delete sdata.pid;
            (0, _param.addPolicyMange)(sdata).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.$message({
                  type: 'success',
                  message: '保存成功！'
                });
                that.handleCancel();
                that.DoSearch();
              } else {
                that.loading = false;
                that.$message.error(res.data.msg);
                return;
              }
            }).catch(function () {
              that.loading = false;
            });
          }
        } else {
          that.loading = false;
          return false;
        }
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      that.editType = 'add';
      that.title = '政策管理添加';
      that.param = {
        endTime: '',
        files: '',
        orgId: null,
        policyName: '',
        policyType: null,
        pid: '',
        remark: '',
        startTime: ''
      };
      that.fileList = [];
      that.dialogVisible3 = true;
    },
    handleChange: function handleChange(file, fileList) {
      var that = this;
      var name = file.name;
      var formData = new FormData();
      formData.append('file', file.raw);
      that.fileList = [];
      that.uploading = true;
      (0, _param.upLoadFile)(formData).then(function (res) {
        var bool = true;
        if (res.data.code == 200) {
          var obj = {};
          obj.name = name;
          obj.url = res.data.data;
          that.fileList.push(obj);
          bool = true;
        } else {
          bool = false;
        }
        return bool;
      }).then(function (res) {
        that.uploading = false;
        var files = [];
        if (that.fileList.length) {
          that.fileList.forEach(function (item) {
            files.push(item.url);
          });
        }
        that.param.files = files.length > 0 ? files.join(',') : '';
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;