var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryParams",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.queryParams,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "参与数码",
                                    prop: "markCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryParams.markCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "markCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryParams.markCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动方案:",
                                    prop: "schemeCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.queryParams.schemeCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "schemeCode",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.schemeCode",
                                      },
                                    },
                                    _vm._l(_vm.activeList, function (item) {
                                      return _c("el-option", {
                                        key: item.schemeCode,
                                        attrs: {
                                          label: item.schemeName,
                                          value: item.schemeCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动名称:",
                                    prop: "activityName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryParams.activityName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "activityName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryParams.activityName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "中奖结果:",
                                    prop: "winStatus",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryParams.winStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "winStatus",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.winStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "未中奖", value: "0" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "已中奖", value: "1" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "奖品类型:",
                                    prop: "prizeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择领取状态",
                                      },
                                      model: {
                                        value: _vm.queryParams.prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "prizeType",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.prizeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "红包", value: "100" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "实物", value: "200" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "领取状态:", prop: "status" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择领取状态",
                                      },
                                      model: {
                                        value: _vm.queryParams.status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "status",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.status",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "未发放", value: "0" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "发放成功",
                                          value: "1",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "发放失败",
                                          value: "2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "核销时间:" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      clearable: "",
                                    },
                                    on: { change: _vm.sj },
                                    model: {
                                      value: _vm.rangeTime,
                                      callback: function ($$v) {
                                        _vm.rangeTime = $$v
                                      },
                                      expression: "rangeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.querylist },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: { click: _vm.resetWinningLottery },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "phead" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "markCode",
                      label: "参与数码",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "schemeName",
                      label: "活动方案",
                      width: "300",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "activityName",
                      label: "参与活动",
                      width: "300",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "openid",
                      label: "用户openid",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "wechatUserId",
                      label: "用户ID",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商务订单号",
                      prop: "mchBillNo",
                      "min-width": "220",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "disableStyle",
                                  attrs: {
                                    type: "text",
                                    disabled: row.sendStatus != 2,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.routerErrorPrize(
                                        row.mchBillNo,
                                        row.sendStatus != 2
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(row.mchBillNo))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1283976826
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "活动产品",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "agentName",
                      label: "数码经销商",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roleName",
                      label: "用户角色",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "belongRegion",
                      label: "所属大区",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "winStatus",
                      label: "中奖结果",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.winStatus == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("未中奖"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.winStatus == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("已中奖"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      342812145
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeType",
                      label: "奖品类型",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      formatter: _vm.showPrizeType,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeName",
                      label: "奖品名称",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeMoney",
                      label: "奖品价值(元)",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖项来源",
                      prop: "prizeSourceName",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sendStatus",
                      label: "领取状态",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.sendStatus == 0
                                ? _c("el-tag", { attrs: { type: "info" } }, [
                                    _vm._v("未发放"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.sendStatus == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("发放成功"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.sendStatus == 2
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("发放失败"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2818331531
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "joinDateTime",
                      label: "参与时间",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sendDateTime",
                      label: "核销时间",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.params.pageNo,
                  limit: _vm.params.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.params, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.params, "pageSize", $event)
                  },
                  pagination: _vm.getWinningLottery,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.look,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.look = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "90px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "物码信息", column: 2, border: "" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "物码" } }, [
                        _vm._v(_vm._s(_vm.detailsList.markInfo.markCode) + " "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属经销商" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.markInfo.agentName) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属产品" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.markInfo.productName) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "120px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "活动信息", column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "所属活动" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.activityName) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "扫码时间" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.joinDateTime) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "奖品名称" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.prizeName) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发奖时间" } },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsList.activityInfo.sendDateTime) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "领取状态" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("status")(
                                _vm.detailsList.activityInfo.status
                              )
                            ) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "奖品形式" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("prizeType")(
                                _vm.detailsList.activityInfo.prizeType
                              )
                            ) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticStyle: { "margin-right": "15px" } },
              [
                _c("el-image", {
                  staticStyle: { width: "100px", height: "120px" },
                  attrs: {
                    src: _vm.detailsList.wechatUserInfo.headImgUrl,
                    fit: "cover",
                  },
                }),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "text" }, [_vm._v("头像")])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "min-height": "100px" },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { title: "用户信息", column: 2, border: "" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "微信昵称" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.nickName) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "用户ID" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.memberId) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "unionID" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.unionId) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "最近IP" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.ipAddress) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "关联openid" } },
                          [
                            _vm._v(
                              _vm._s(_vm.detailsList.wechatUserInfo.openId) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticStyle: { "min-height": "150px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: { title: "扩展信息(收集)", column: 2, border: "" },
                    },
                    _vm._l(_vm.collectInfo, function (item, index) {
                      return _c(
                        "el-descriptions-item",
                        { key: index, attrs: { label: item.fieldName } },
                        [_vm._v(_vm._s(item.value) + " ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.show
                    ? _c(
                        "el-col",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "line-height": "150px",
                          },
                          attrs: { span: 24 },
                        },
                        [_vm._v("\n          暂无数据\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出明细列表",
            visible: _vm.popupExportDetail,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupExportDetail = $event
            },
            close: _vm.hadnleCloseExportDetail,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.exportDataListLoading,
                      expression: "exportDataListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.exportDataList,
                    border: "",
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": true,
                    height: "550px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileName",
                      label: "文件名称",
                      "min-width": "20%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      "min-width": "35%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileUrl",
                      label: "下载地址",
                      "min-width": "30%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  href: scope.row.fileUrl,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.fileUrl))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "15%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c("el-tag", [_vm._v("进行中")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("完成"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.exportParams.total > 0,
                    expression: "exportParams.total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.exportParams.total,
                  page: _vm.exportParams.current,
                  limit: _vm.exportParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.exportParams, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.exportParams, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.queryExprotActivityForPage(
                      _vm.exportParams.current,
                      _vm.exportParams.size
                    )
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.hadnleCloseExportDetail },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }