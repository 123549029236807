"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaffResetPassword = SaffResetPassword;
exports.addIntegralAct = addIntegralAct;
exports.addOrdeleteIntegral = addOrdeleteIntegral;
exports.deleteGuideIfo = deleteGuideIfo;
exports.deleteIntegralAct = deleteIntegralAct;
exports.deleteSaff = deleteSaff;
exports.freeze = freeze;
exports.getByGuideIdex = getByGuideIdex;
exports.getChosenProduct = getChosenProduct;
exports.getGuideApprove = getGuideApprove;
exports.getGuideIfo = getGuideIfo;
exports.getGuideIfodownlode = getGuideIfodownlode;
exports.getIntegralAct = getIntegralAct;
exports.getProduct = getProduct;
exports.getPunchRecord = getPunchRecord;
exports.getPunchRecordex = getPunchRecordex;
exports.getStroe = getStroe;
exports.getsaff = getsaff;
exports.getsaffInfo = getsaffInfo;
exports.getsaffdetail = getsaffdetail;
exports.importstroe = void 0;
exports.integralActDetails = integralActDetails;
exports.integralActOpenOrOlose = integralActOpenOrOlose;
exports.saveOrUpdateGuideApprove = saveOrUpdateGuideApprove;
exports.savesaff = savesaff;
exports.savestroe = savestroe;
exports.stroeTemplate = void 0;
exports.updateGuideApprove = updateGuideApprove;
exports.updateIntegralAct = updateIntegralAct;
exports.updateSaff = updateSaff;
exports.updateSaffResetPassword = updateSaffResetPassword;
exports.updatestroe = updatestroe;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/api'
var baseUrl = '';
function getStroe(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/stroe/getstroe',
    method: 'POST',
    data: data
  });
}
// /stroe/updatestroe
// 编辑门店信息
function updatestroe(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/stroe/updatestroe',
    method: 'POST',
    data: data
  });
}
// /stroe/savestroe
// 添加门店信息
function savestroe(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/stroe/savestroe',
    method: 'POST',
    data: data
  });
}

//  下载
var stroeTemplate = process.env.VUE_APP_TEMPLATE;
// 上传
exports.stroeTemplate = stroeTemplate;
var importstroe = process.env.VUE_APP_BASE_API + '/uspyx/stroe/importstroe';

// 导购员

// 获取导购信息
exports.importstroe = importstroe;
function getGuideIfo(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/getGuideIfo',
    method: 'POST',
    data: data
  });
}

// 冻结解冻
function freeze(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/freeze',
    method: 'POST',
    data: data
  });
}

// 删除

function deleteGuideIfo(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/deleteGuideIfo',
    method: 'POST',
    data: data
  });
}

// 重置密码

function updateSaffResetPassword(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/updateSaffResetPassword',
    method: 'POST',
    data: data
  });
}
// 添加或减少积分
function addOrdeleteIntegral(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/addOrdeleteIntegral',
    method: 'POST',
    data: data
  });
}

// 获取员工

function getsaff(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/getsaff',
    method: 'POST',
    data: data
  });
}

// 获取打卡记录
function getPunchRecord(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/getPunchRecord',
    method: 'POST',
    data: data
  });
}

// 添加员工信息
function savesaff(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/savesaff',
    method: 'POST',
    data: data
  });
}

// 重置员工密码
function SaffResetPassword(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/updateSaffResetPassword',
    method: 'POST',
    data: data
  });
}

// 添加积分活动

function addIntegralAct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/addIntegralAct',
    method: 'POST',
    data: data
  });
}

// 删除积分活动
function deleteIntegralAct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/deleteIntegralAct',
    method: 'get',
    params: data
  });
}

// 获取活动已选择产品

function getChosenProduct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/getChosenProduct',
    method: 'get',
    params: data
  });
}

// 获取积分活动

function getIntegralAct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/getIntegralAct',
    method: 'POST',
    data: data
  });
}

// 获取产品列表
function getProduct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/getProduct',
    method: 'get',
    params: data
  });
}

// 详情
function integralActDetails(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/integralActDetails',
    method: 'get',
    params: data
  });
}

// 开启关闭

function integralActOpenOrOlose(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/integralActOpenOrOlose',
    method: 'get',
    params: data
  });
}

// 编辑积分活动
function updateIntegralAct(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guideIntegralAct/updateIntegralAct',
    method: 'POST',
    data: data
  });
}

// 员工编辑
function updateSaff(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/updateSaff',
    method: 'POST',
    data: data
  });
}

// 获取导购员审批
function getGuideApprove(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/getGuideApprove',
    method: 'POST',
    data: data
  });
}
// 设置导购员审批
function saveOrUpdateGuideApprove(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/saveOrUpdateGuideApprove',
    method: 'POST',
    data: data
  });
}
// 导购员手动审核
function updateGuideApprove(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/updateGuideApprove',
    method: 'POST',
    data: data
  });
}

// 员工删除
function deleteSaff(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/deleteSaff',
    method: 'DELETE',
    params: data
  });
}

// 导购积分导出

function getByGuideIdex(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/api/integral/getByGuideIdex',
    method: 'POST',
    responseType: 'blob',
    data: data
  });
}

// 获取员信息

function getsaffInfo(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/getsaff',
    method: 'POST',
    data: data
  });
}

// 打开记录

function getsaffdetail(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/getsaffdetail',
    method: 'POST',
    params: data
  });
}
// 打开记录导出
function getPunchRecordex(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/saff/getPunchRecordex',
    method: 'POST',
    responseType: 'blob',
    data: data
  });
}

// 导购员导出

function getGuideIfodownlode(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/guide/getGuideIfodownlode',
    method: 'get',
    responseType: 'blob',
    params: data
  });
}