"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data: function data() {
    return {
      yAxisData: [],
      seriesData: [],
      formatterData: '{@score}次'
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          show: false
        },
        yAxis: {
          type: 'category',
          splitNumber: 10,
          interval: 10,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: this.yAxisData
        },
        color: ['#409EFF'],
        series: [{
          type: 'bar',
          data: this.seriesData,
          barMaxWidth: 20,
          label: {
            normal: {
              show: true,
              position: 'right',
              formatter: function formatter(params) {
                // debugger
                var str = params.data.clikeRate !== undefined ? ' ' + params.value + '人' : ' ' + params.value + '人';
                // var str = ''
                // if (params.data.clikeRate !== undefined) {
                //   var str = params.data.clikeRate * 100 + '% ' + params.value + '次'
                // } else {
                //   var str = params.value + '次'
                // }
                // debugger
                console.log(str);
                return str;
              },
              textStyle: {
                color: 'rgba(0, 0, 0, 1)',
                fontSize: 14
              }
            }
          }
        }]
      });
    }
  }
};
exports.default = _default;