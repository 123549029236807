"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic");
var _urlManage = require("@/api/mark/configure/urlManage");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const defaultSeachForm = {
//   display_time: undefined, // 前台展示时间
//   chosedate: []
// }
var _default = {
  name: 'MarkconfigureurlManageindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (time == 0 || time == '' || time == null) {
        return '';
      }
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  data: function data() {
    var checkLast = function checkLast(rule, value, callback) {
      // var reg = /(\d+)$/g
      // if (reg.test(value)) {
      //   callback(new Error('URL不能以数字结尾'))
      // } else {
      //   callback()
      // }
      var reg = /=|\/$/g;
      if (!reg.test(value)) {
        callback(new Error("URL必须以'='或'/'结尾"));
      } else {
        callback();
      }
    };
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      btn_loading: false,
      listQuery: {
        current: 1,
        size: 20,
        urlDesc: ''
      },
      temp: {
        urlDesc: '',
        urlAddress: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑URL',
        create: '新增URL'
      },
      dialogPvVisible: false,
      rules: {
        urlDesc: [{
          required: true,
          message: '输入url描述内容',
          trigger: 'blur'
        }],
        urlAddress: [{
          type: 'url',
          required: true,
          message: '请输入正确的Url地址',
          trigger: 'blur'
        }, {
          validator: checkLast,
          trigger: 'change'
        }]
      },
      downloadLoading: false,
      btnPowers: null
    };
  },
  computed: {
    displayTime: {
      get: function get() {
        return +new Date(this.defaultSeachForm.display_time);
      },
      set: function set(val) {
        this.defaultSeachForm.display_time = new Date(val);
      }
    }
  },
  activated: function activated() {
    // this.btnPowers = setPermission(this, 'URLGL')
    this.btnPowers = {};
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _urlManage.urlInfoqueryForPage)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      });
    },
    searchList: function searchList() {
      this.getList();
    },
    handleFilter: function handleFilter() {
      this.listQuery = {
        current: 1,
        size: 20,
        urlDesc: ''
      };
      this.getList();
    },
    close: function close() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogFormVisible = false;
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      var _this2 = this;
      // 删除
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('deletebtn', _this2.$route.meta.authList);
        (0, _urlManage.urlInfodeleteById)(row.pid).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: '保存成功',
            type: 'success'
          });
          _this2.getList();
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    },
    getDetails: function getDetails(row) {
      var _this3 = this;
      // 获取指定id url 详情
      (0, _urlManage.urlInfoqueryById)(row.pid).then(function (res) {
        var data = res.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return false;
        }
        _this3.temp = Object.assign({}, data.data);
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.btn_loading = true;
          (0, _urlManage.urlInfoSaveUrl)(_this4.temp).then(function (res) {
            _this4.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this4.$message.error(data.msg);
              return false;
            }
            _this4.$message({
              message: '保存成功',
              type: 'success'
            });
            _this4.getList();
            _this4.$refs.dataForm.resetFields();
            _this4.dialogFormVisible = false;
          }).catch(function () {
            _this4.btn_loading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this5 = this;
      this.authId('addbtn', this.$route.meta.authList);
      this.btn_loading = false;
      this.temp = {
        urlDesc: '',
        urlAddress: ''
      };
      if (row) {
        this.temp = Object.assign({}, row); // copy obj
      }

      this.dialogStatus = data_type;
      this.dialogFormVisible = true;
      if (this.$refs.dataForm !== undefined) {
        this.$refs.dataForm.resetFields();
      }
      if (data_type == 'update') {
        this.authId('updatebtn', this.$route.meta.authList);
        this.getDetails(row);
      }
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this6.btn_loading = true;
          var tempData = {
            pId: _this6.temp.pid,
            urlAddress: _this6.temp.urlAddress,
            urlDesc: _this6.temp.urlDesc
          };
          (0, _urlManage.urlInfomodifyById)(tempData).then(function (res) {
            _this6.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this6.$message.error(data.msg);
              return false;
            }
            _this6.$message({
              message: '保存成功',
              type: 'success'
            });
            _this6.getList();
            _this6.$refs.dataForm.resetFields();
            _this6.dialogFormVisible = false;
          }).catch(function () {
            _this6.btn_loading = false;
          });
        }
      });
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return (0, _utils.parseTime)(v[j]);
          } else {
            return v[j];
          }
        });
      });
    }
  }
};
exports.default = _default;