"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _redEnvelopePrize = require("@/api/prizeSetting/redEnvelopePrize");
var _details_flow = _interopRequireDefault(require("./details_flow"));
var _giftPackageManagement = require("@/api/prizeSetting/giftPackageManagement");
var _unlockPrizeRule = require("@/api/market/unlockPrizeRule");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _validate = require("@/utils/validate");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _utils = require("@/utils");
var _util = require("util");
var _content = require("@/api/systems/content");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageprizeSettingredEnvelopePrizeindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageprizeSettingredEnvelopePrizeindex',
  components: {
    Pagination: _Pagination.default,
    UploadImg: _UploadImg.default,
    detailsflow: _details_flow.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  data: function data() {
    var validateOrg = _validate.checkName;
    return {
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        schemeCode: '',
        schemeName: '',
        status: ''
      },
      total: 0,
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      listLoadig: false,
      tableData: [],
      fileList: [],
      form: {
        attachment: '',
        // 红包名称
        endTime: '',
        // 发送者名称
        remarks: '',
        // 红包备注
        // schemeCode: '', // 红包说明
        schemeName: '',
        // 红包场景
        // money: '',
        startTime: '',
        type: '1',
        schemeCode: '',
        parentSchemeCode: '',
        parentSchemeName: '',
        dataTimeRange: [],
        status: 2,
        ruleImg: '',
        generalizationImg: ''
      },
      rules: {
        schemeName: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        ruleImg: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        schemeCode: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        dataTimeRange: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        parentSchemeCode: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        attachment: [{
          required: true,
          message: '请上传',
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      title: '',
      flagId: '',
      isShowMoney: false,
      // 控制体现起点金额选项是否显示
      export: {
        goodsHxTarget: '',
        goodsHxType: '',
        goodsType: '',
        pageNo: 1,
        pageSize: 10,
        prizeName: '',
        prizeType: '100',
        redSendType: '',
        status: ''
      },
      tableList: [],
      disabled: false,
      uploading: false,
      btnLoading: false,
      isRedSceneId: false,
      isRemark: false,
      dateCollectionList: [],
      dateCollectionIds: [],
      value: [],
      dateCollectionAll: [],
      isDisabled: false
    };
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getPrizeList();
    }
    this.getdateCollectionList();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    changeTab: function changeTab(e) {
      if (e == 1) {
        this.rules.parentSchemeCode[0].required = false;
      } else if (e == 2) {
        this.rules.parentSchemeCode[0].required = true;
      }
    },
    handleSwitchChange: function handleSwitchChange() {
      this.form.imposeNum = '';
    },
    handleCheckboxChange: function handleCheckboxChange(value) {
      this.dateCollectionAll = value;
      this.form.relevanceDataId = this.dateCollectionAll.join(',');
      console.log(this.dateCollectionAll);
    },
    getdateCollectionList: function getdateCollectionList() {
      var _this2 = this;
      (0, _giftPackageManagement.getdateCollectionList)().then(function (res) {
        if (res.data.data.code || res.data.data.code == 0) {
          _this2.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data !== null) {
            _this2.dateCollectionList = res.data.data;
          } else {
            _this2.dateCollectionList = [];
          }
        }
      });
    },
    getflow: function getflow(row) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row);
    },
    // 获取活动方案列表
    getPrizeList: function getPrizeList() {
      var _this3 = this;
      this.listLoadig = true;
      (0, _activityFlow.getwayList)(this.queryParams).then(function (res) {
        _this3.listLoadig = false;
        if (res.data.data.code || res.data.data.code == 0) {
          _this3.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data !== null) {
            _this3.tableData = res.data.data.records;
            _this3.total = res.data.data.total;
          } else {
            _this3.total = 0;
            _this3.tableData = [];
          }
        }
      }).finally(function () {
        _this3.listLoadig = false;
      });
    },
    // 查询
    queryList: function queryList() {
      this.queryParams.pageNo = 1;
      this.getPrizeList();
    },
    uploadfile: function uploadfile(file, fileList) {
      var formData = new FormData();
      var isLt2M = file.size / 1024 / 1024 < 100;
      var _this = this;
      // if (!isJPG && !isPNG) {
      //   this.$message.error('上传图片只能是 PNG/JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 100Mb!');
        return;
      }
      this.uploading = true;
      formData.append('file', file.raw);
      (0, _content.uploadImg)(formData).then(function (res) {
        _this.uploading = false;
        if (res.data.code == 200) {
          _this.fileList = [];
          _this.form.attachment = res.data.data;
        } else {
          _this.handleRemove(file, fileList);
          _this.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      }).catch(function () {
        _this.uploading = false;
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = [];
    },
    // 删除红包
    handleDelItem: function handleDelItem(row) {
      var _this4 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        (0, _activityFlow.editStatus)({
          status: 3,
          schemeCode: row.schemeCode
        }).then(function (res) {
          if (res.data.code !== 200) {
            _this4.$message.console.error('删除失败');
          } else {
            if (res.data.data.code) {
              _this4.$message.error(res.data.data.msg);
            } else {
              _this4.getPrizeList();
              _this4.$message.success('删除成功');
            }
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeway: function changeway(e) {
      var _this5 = this;
      if (this.tableData) {
        this.tableData.forEach(function (el) {
          if (el.schemeCode == e) {
            _this5.form.parentSchemeName = el.schemeName;
            if (el.ruleImg) {
              _this5.$set(_this5.form, 'ruleImg', el.ruleImg);
            } else {
              _this5.$set(_this5.form, 'ruleImg', '');
            }
            if (el.generalizationImg) {
              _this5.$set(_this5.form, 'generalizationImg', el.generalizationImg);
            } else {
              _this5.$set(_this5.form, 'generalizationImg', '');
            }
            if (_this5.form.ruleImg) {
              _this5.$nextTick(function () {
                if (_this5.$refs.ruleImg) {
                  _this5.$refs.ruleImg.fileList = [];
                  _this5.$refs.ruleImg.fileList.push({
                    url: _this5.form.ruleImg
                  });
                  _this5.$refs.ruleImg.hideUpload = true;
                }
              });
            } else {
              _this5.$refs.ruleImg.fileList = [];
            }
            if (_this5.form.generalizationImg) {
              _this5.$nextTick(function () {
                if (_this5.$refs.generalImgs) {
                  _this5.$refs.generalImgs.fileList = [];
                  _this5.$refs.generalImgs.fileList.push({
                    url: _this5.form.generalizationImg
                  });
                  _this5.$refs.generalImgs.hideUpload = true;
                }
              });
            } else {
              _this5.$refs.generalImgs.fileList = [];
            }
          }
        });
      }
    },
    clearform: function clearform() {
      this.form = {
        // 缺少体现起点金额字段
        attachment: '',
        // 红包名称
        endTime: '',
        // 发送者名称
        remarks: '',
        // 红包备注
        // schemeCode: '', // 红包说明
        schemeName: '',
        // 红包场景
        // money: '',
        schemeCode: '',
        startTime: '',
        dataTimeRange: [],
        status: 2,
        type: 1,
        ruleImg: '',
        generalizationImg: ''
      };
      // this.$nextTick(() => {
      //   this.$refs.image.fileList = []
      // })
    },
    // 新增方法
    handleAdd: function handleAdd() {
      var _this6 = this;
      this.disabled = false;
      this.btnLoading = false;
      this.isDisabled = false;
      this.dateCollectionAll = [];
      this.title = '添加活动方案';
      this.clearform();
      (0, _activityFlow.getwayList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), {}, {
        type: 1,
        status: 1
      })).then(function (res) {
        _this6.listLoadig = false;
        if (res.data.data.code || res.data.data.code == 0) {
          _this6.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data !== null) {
            _this6.tableList = res.data.data.records;
          } else {
            _this6.total = 0;
            _this6.tableData = [];
          }
        }
      }).finally(function () {
        _this6.listLoadig = false;
      });
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this6.$refs.form.resetFields();
        _this6.clearform();
        _this6.form.generalizationImg = '';
        _this6.form.ruleImg = '';
      });
    },
    // 编辑方法
    handleUpdate: function handleUpdate(row) {
      var _this7 = this;
      this.disabled = false;
      this.btnLoading = false;
      (0, _activityFlow.getwayList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), {}, {
        type: 1,
        status: 1
      })).then(function (res) {
        _this7.listLoadig = false;
        if (res.data.data.code == 500 || res.data.data.code == 0) {
          _this7.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data !== null) {
            _this7.tableList = res.data.data.records;
          } else {
            _this7.total = 0;
            _this7.tableData = [];
          }
        }
      }).finally(function () {
        _this7.listLoadig = false;
      });
      this.title = '编辑活动方案';
      this.dialogVisible = true;
      this.form.attachment = row.attachment;
      this.$set(this.form, 'parentSchemeCode', row.parentSchemeCode);
      // this.form.parentSchemeCode = row.parentSchemeCode
      this.form.type = row.type;
      this.form.remarks = row.remarks;
      this.form.ruleImg = row.ruleImg;
      this.form.generalizationImg = row.generalizationImg;
      if (this.form.ruleImg) {
        this.$nextTick(function () {
          if (_this7.$refs.ruleImg) {
            _this7.$refs.ruleImg.fileList = [];
            _this7.$refs.ruleImg.fileList.push({
              url: _this7.form.ruleImg
            });
            _this7.$refs.ruleImg.hideUpload = true;
          }
        });
      }
      if (this.form.generalizationImg) {
        this.$nextTick(function () {
          if (_this7.$refs.generalImgs) {
            _this7.$refs.generalImgs.fileList = [];
            _this7.$refs.generalImgs.fileList.push({
              url: _this7.form.generalizationImg
            });
            _this7.$refs.generalImgs.hideUpload = true;
          }
        });
      }
      this.form.schemeName = row.schemeName;
      this.form.schemeCode = row.schemeCode;
      this.form.status = row.status.toString();
      this.form.dataTimeRange = [this.$options.filters.formatTime(row.startTime), this.$options.filters.formatTime(row.endTime)];
    },
    // 保存/编辑红包方法
    handleSave: function handleSave(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this8.form.startTime = _this8.form.dataTimeRange[0];
          _this8.form.endTime = _this8.form.dataTimeRange[1];
          if (_this8.form.startTime == _this8.form.endTime) {
            _this8.$message.warning('开始时间不能等于结束时间');
            return;
          }
          delete _this8.form.dataTimeRange;
          _this8.form.status = Number(_this8.form.status);
          if (_this8.title == '添加活动方案') {
            (0, _activityFlow.addOrEditWay)(_this8.form).then(function (res) {
              if (res.data.data.code == 500 || res.data.data.code == 0) {
                _this8.disabled = false;
                _this8.btnLoading = false;
                _this8.$message.error(res.data.data.msg);
                return;
              } else {
                _this8.$message.success('新增成功');
                _this8.getPrizeList();
                _this8.dialogVisible = false;
                _this8.disabled = true;
                _this8.btnLoading = true;
                _this8.clearform();
                if (_this8.$refs.generalImgs) {
                  _this8.$refs.generalImgs.fileList = [];
                }
                if (_this8.$refs.ruleImg) {
                  _this8.$refs.ruleImg.fileList = [];
                }
              }
            });
          } else if (_this8.title == '编辑活动方案') {
            (0, _activityFlow.editway)(_this8.form).then(function (res) {
              if (res.data.data.code == 500 || res.data.data.code == 0) {
                _this8.disabled = false;
                _this8.btnLoading = false;
                _this8.$message.error(res.data.data.msg);
                return;
              } else {
                _this8.$message.success('编辑成功');
                _this8.dialogVisible = false;
                _this8.getPrizeList();
                _this8.disabled = true;
                _this8.btnLoading = true;
                _this8.clearform();
                if (_this8.$refs.generalImgs) {
                  _this8.$refs.generalImgs.fileList = [];
                }
                if (_this8.$refs.ruleImg) {
                  _this8.$refs.ruleImg.fileList = [];
                }
              }
            });
          }
        } else {
          _this8.disabled = false;
          _this8.btnLoading = false;
        }
      });
    },
    // 导出红包
    exportPrize: function exportPrize() {
      var _this9 = this;
      console.log(this.queryParams, 123);
      (0, _redEnvelopePrize.exportPrize)(this.queryParams).then(function (res) {
        console.log(res.data, 'res');
        _this9.$message.success('导出成功！');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '红包奖品列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 取消
    handleCancel: function handleCancel(form) {
      this.clearform();
      if (this.$refs.generalImgs) {
        this.$refs.generalImgs.fileList = [];
      }
      if (this.$refs.ruleImg) {
        this.$refs.ruleImg.fileList = [];
      }
      this.$refs[form].resetFields();
      this.dialogVisible = false;
    },
    setInUse: function setInUse(row) {
      var _this10 = this;
      this.$confirm('请确认是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this10.authId('startbtn', _this10.$route.meta.authList);
        (0, _activityFlow.editStatus)({
          status: 1,
          schemeCode: row.schemeCode
        }).then(function (res) {
          _this10.authId('searchbtn', _this10.$route.meta.authList);
          if (res.data.code != 200) {
            _this10.$message.error(res.data.msg);
          } else {
            if (res.data.data.code) {
              _this10.$message.error(res.data.data.msg);
            } else {
              _this10.$message({
                message: '操作成功',
                type: 'success'
              });
              _this10.getPrizeList();
            }
          }
        });
      }).catch(function () {
        _this10.authId('searchbtn', _this10.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(row) {
      var _this11 = this;
      this.$confirm('请确认是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this11.authId('stopbtn', _this11.$route.meta.authList);
        (0, _activityFlow.editStatus)({
          status: 2,
          schemeCode: row.schemeCode
        }).then(function (res) {
          _this11.authId('searchbtn', _this11.$route.meta.authList);
          if (res.data.code != 200) {
            _this11.$message.error(res.data.msg);
          } else {
            if (res.data.data.code) {
              _this11.$message.error(res.data.data.msg);
            } else {
              _this11.$message({
                message: '操作成功',
                type: 'success'
              });
              _this11.getPrizeList();
            }
          }
        });
      }).catch(function () {
        _this11.authId('searchbtn', _this11.$route.meta.authList);
      });
    },
    // 红包复选框change事件
    handleValueChange: function handleValueChange(val) {
      // console.log(val)
      this.isShowMoney = val == 0;
      this.isRedSceneId = val == 1;
      this.isRemark = val == 1;
    },
    restPrizeList: function restPrizeList() {
      this.queryParams = {
        pageSize: 10,
        pageNo: 1,
        schemeCode: '',
        status: '',
        schemeName: ''
      };
      this.getPrizeList();
    },
    showStatus: function showStatus(row) {
      if (row.status == 1) return '启用';
      return '禁用';
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getPrizeList();
    },
    // 检验长度
    checkNum: function checkNum(val, arg) {
      var value = val.target.value;
      var match = value.match(/\d*(\.\d{0,2})?/)[0];
      console.log(match);
      if (arg == 'baseValue') {
        this.form.baseValue = match;
      }
      if (arg == 'redMaxValue') {
        if (match < 0.3 || match > 200) {
          this.form.redMaxValue = 0;
        } else {
          this.form.redMaxValue = match;
        }
      }
    }
  }
};
exports.default = _default;