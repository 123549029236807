"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBook = addBook;
exports.delBook = delBook;
exports.downLoadBook = downLoadBook;
exports.getBookType = getBookType;
exports.getMoudleMsg = getMoudleMsg;
exports.getMoudles = getMoudles;
exports.getOperateBook = getOperateBook;
exports.getOperateLog = getOperateLog;
exports.getPageMsg = getPageMsg;
exports.getParentId = getParentId;
exports.getVisitLog = getVisitLog;
exports.upLoadBook = upLoadBook;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取操作日志
function getOperateLog(param) {
  return (0, _request.default)({
    url: '/api-user/operationLog/list',
    method: 'get',
    params: param
  });
}

// 获取模块信息
function getMoudleMsg() {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/getModleList',
    method: 'get'
  });
}

// 获取页面信息
function getPageMsg(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/getModleList',
    method: 'get',
    params: param
  });
}

// 获取日志访问列表
function getVisitLog(param) {
  return (0, _request.default)({
    url: '/api-user/visitLog/list',
    method: 'get',
    params: param
  });
}

// 获取操作手册
function getOperateBook(param) {
  return (0, _request.default)({
    url: '/api-user/documentLibrary/page',
    method: 'get',
    params: param
  });
}

// 获取手册类型
function getBookType(param) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getItemByDataDictionaryByCode',
    method: 'get',
    params: param
  });
}

// 手册所属模块
function getMoudles() {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/all',
    method: 'get'
  });
}

// 删除手册
function delBook(param) {
  return (0, _request.default)({
    url: '/api-user/documentLibrary/delete',
    method: 'get',
    params: param
  });
}

// 手册下载
function downLoadBook(param) {
  return (0, _request.default)({
    url: '/api-user/documentLibrary/download',
    method: 'get',
    responseType: 'blob',
    params: param
  });
}

// 上传pdf手册
function upLoadBook(data) {
  return (0, _request.default)({
    url: '/api-user/documentLibrary/uploadFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  });
}

// 手册添加
function addBook(data) {
  return (0, _request.default)({
    url: '/api-user/documentLibrary/save',
    method: 'post',
    data: data
  });
}

// 根据子集菜单ID获取顶级菜单ID
function getParentId(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/getTopIdByChildId',
    method: 'get',
    params: param
  });
}