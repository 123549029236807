var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-data" },
    [
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("模块:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.moduleName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("单据:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.billName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("审批流编码:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.exeutionNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("单据号:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.billNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("所属组织:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.orgName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("状态:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("fiterStatus")(_vm.basic.status))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("发起人:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.createName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("发起时间:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("formatTime")(_vm.basic.createTime))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("上节点审批人:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.basic.lastApproveUserName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v("上节点审批时间:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(_vm._f("formatTime")(_vm.basic.lastApprovedDate))
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }