var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "返利方案-详情",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "itemTitle" }, [_vm._v("关联政策")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { lg: 6, md: 12 } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.detailData.policyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailData, "policyName", $$v)
                      },
                      expression: "detailData.policyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 6, md: 12 } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.scanPolicy } },
                    [_vm._v("查看政策")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("方案编号:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.schemeCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("方案名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.schemeName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("开始时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.beginTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("结束时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.endTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("状态:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.detailData.schemeState == 0
                      ? _c("span", [_vm._v("待提交")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailData.schemeState == 1
                      ? _c("span", [_vm._v("待审核")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailData.schemeState == 2
                      ? _c("span", [_vm._v("已生效")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailData.schemeState == 3
                      ? _c("span", [_vm._v("已终止")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("方案类型:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.detailData.schemeType == 0
                      ? _c("span", [_vm._v("月度")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailData.schemeType == 1
                      ? _c("span", [_vm._v("年度")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 24, md: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("方案描述:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.schemeDescribe)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _vm._v("\n    参与产品\n    "),
        _c("img", { attrs: { src: require("@/images/pro.png") } }),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            data: _vm.proList,
            fit: "",
            "max-height": "300",
            "highlight-current-row": "",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              prop: "productCode",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "productName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品分类",
              prop: "productClass",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "返利计量单位",
              prop: "measurementUnit",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.measurementUnit == 1
                      ? _c("span", [_vm._v("罐")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.measurementUnit == 2
                      ? _c("span", [_vm._v("盒")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.measurementUnit == 3
                      ? _c("span", [_vm._v("箱")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "返利倍数",
              prop: "rebateProportion",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("返利范围")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tree-contianer" },
        [
          _c("el-tree", {
            attrs: {
              data: _vm.treeData,
              "show-checkbox": "",
              "node-key": "id",
              "default-checked-keys": _vm.checkedKeys,
              props: _vm.defaultProps,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("返利配置")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            data: _vm.configData,
            fit: "",
            "max-height": "300",
            "highlight-current-row": "",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "触发动作",
              prop: "triggerAction",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.triggerAction == 1
                      ? _c("span", [_vm._v("终端签收")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.triggerAction == 2
                      ? _c("span", [_vm._v("消费者扫码")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.triggerAction == 3
                      ? _c("span", [_vm._v("下订单")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "渠道类型",
              prop: "channelType",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.channelType == 1
                      ? _c("span", [_vm._v("经销商")])
                      : _vm._e(),
                    _vm._v(" "),
                    row.channelType == 2
                      ? _c("span", [_vm._v("终端")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "渠道分类",
              prop: "className",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "渠道等级",
              prop: "gradeName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "返现奖品数量(元/个)",
              prop: "rebateProportion",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      _vm._s(row.productName) +
                        " 买 " +
                        _vm._s(row.buyNum) +
                        " 赠 " +
                        _vm._s(row.presentNum)
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-group-right" },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
      _vm._v(" "),
      _c("scanZc", {
        attrs: {
          policyCode: _vm.detailData.policyCode,
          detailVisible: _vm.policyVisible,
        },
        on: { close: _vm.closeScan },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }