var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.isBatch ? "批量编辑活动概率" : "活动概率",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1200px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("div", { staticClass: "phead" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.addBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.addBtn.icon,
                          disabled: _vm.isActivityOverdue,
                        },
                        on: { click: _vm.creatActPr },
                      },
                      [_vm._v("新建概率")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    height: "500",
                    fit: "",
                    border: true,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "规则",
                      type: "index",
                      width: "50",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "时间区间",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.prTimeType == "0"
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.prStartTime) +
                                      " - " +
                                      _vm._s(row.prEndTime) +
                                      "\n            "
                                  ),
                                ])
                              : _c("div", [
                                  row.prTimeType == "1"
                                    ? _c("span", [_vm._v("单日")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.prTimeType == "2"
                                    ? _c("span", [_vm._v("双日")])
                                    : _vm._e(),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "时段",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(
                            row.prTimeItemsList,
                            function (item, index) {
                              return _c("div", { key: index }, [
                                item.startHour
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.startHour) +
                                          "-" +
                                          _vm._s(item.endHour) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "类型",
                      prop: "prType",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.prType == "0"
                              ? _c("span", [_vm._v("时间规则")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.prType == "1"
                              ? _c("span", [_vm._v("超区规则")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "内容",
                      prop: "activityFlowName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(
                            row.prizeItemsList,
                            function (item, index) {
                              return _c("div", { key: index }, [
                                item.priority != 99999
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(item.priority) +
                                          "等奖 概率" +
                                          _vm._s(item.prizePr) +
                                          "% 数量" +
                                          _vm._s(item.prizeNum)
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "谢谢惠顾  概率" +
                                          _vm._s(item.prizePr) +
                                          "%"
                                      ),
                                    ]),
                              ])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: _vm.isActivityOverdue,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editActPr(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            !_vm.isBatch
                              ? _c(
                                  "el-dropdown",
                                  { staticClass: "table-dropdown" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _vm._v(
                                          "\n                更多\n                "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        !_vm.isBatch
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "a-btn",
                                                attrs: {
                                                  disabled:
                                                    _vm.isActivityOverdue,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.upActPr(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("升序")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isBatch
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "a-btn",
                                                attrs: {
                                                  disabled:
                                                    _vm.isActivityOverdue,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downActPr(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("降序")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "a-btn",
                                            attrs: {
                                              disabled: _vm.isActivityOverdue,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delPr(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.isActivityOverdue,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchDelPr(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getDataList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.creatVisible
            ? _c("creatActPr", {
                attrs: {
                  id: _vm.id,
                  type: _vm.editType,
                  "act-id": _vm.activityId,
                  "show-only": _vm.only,
                  "dialog-visible": _vm.creatVisible,
                  "is-batch": _vm.isBatch,
                  "current-data": _vm.currentData,
                  "select-item": _vm.selectItem,
                },
                on: { close: _vm.closeCreat, getItemData: _vm.getItemData },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _vm.isBatch
            ? _c(
                "el-button",
                {
                  staticClass: "normalBtn",
                  attrs: { type: "primary", loading: _vm.finishLoading },
                  on: { click: _vm.finish },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }