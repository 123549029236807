"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _lithService = require("@/api/lithService");
var _basic = require("@/api/mark/basic");
var _select = require("@/api/warehouse/select");
var _index = require("@/api/warehouse/checkout/index");
var _codeDetails = _interopRequireDefault(require("@/components/codeDetails"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'primary',
          5: 'primary',
          6: 'primary',
          7: 'success',
          8: 'warning',
          9: 'danger',
          10: 'info',
          11: 'info'
        };
        return statusMap[value];
      }
    },
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '创建单据',
          2: '修改单据',
          3: '删除单据',
          4: '拆单',
          5: '审核不通过',
          6: '审核通过',
          7: '单据下载',
          8: '单据扫描',
          9: '正常结单',
          10: '强制结单',
          11: '撤销下载',
          12: '撤销结单'
        };
        return statusMap[value];
      }
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1];
      } else if (arr.length == 3) {
        name = arr[2];
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0];
      }
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      num: '',
      active: 0,
      detailVisible: false,
      mloading: false,
      listLoading: false,
      scanData: {},
      temp: {},
      flowList: [],
      settype: 2,
      showflow: false,
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      detailData: {
        tradeBillAllVO: {
          billNo: ''
        }
      },
      searchForm: {
        current: 1,
        size: 10
      },
      detailtable: [],
      total: 0,
      proList: [],
      tradeOperateLogDTOS: [],
      codeVisible: false,
      billNo: '',
      productId: '',
      activeName: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      var that = this;
      this.detailVisible = true;
      this.mloading = true;
      this.scanData = rows;
      that.getList();
      this.mloading = false;
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      (0, _lithService.selectOrderDetailPage)({
        current: this.searchForm.current,
        size: this.searchForm.size,
        materialNo: this.scanData.materialNo
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var rData = res.data.data.records;
            if (rData.length > 0) {
              rData.forEach(function (item) {
                if (item.markLevel !== 1) {
                  item.hasChildren = true;
                }
              });
            }
            that.detailtable = rData;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this.listLoading = false;
      }).catch(function () {
        that.total = 0;
        _this.listLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var param = {};
      param.materialCode = tree.materialCode;
      // param.parentMarkCode = tree.markCode
      // param.productId = tree.productId
      (0, _lithService.selectChildCode)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            if (res.data.data.length == 0) {
              tree.hasChildren = false;
            } else {
              res.data.data.forEach(function (item) {
                if (item.markLevel !== 1) {
                  item.hasChildren = true;
                }
              });
              setTimeout(function () {
                resolve(res.data.data);
              }, 100);
            }
          } else {
            tree.hasChildren = false;
          }
        } else {
          tree.hasChildren = false;
        }
      });
    },
    handleClose: function handleClose() {
      var auth = this.authId('searchbtn', this.$route.meta.authList);
      this.detailVisible = false;
    },
    // 获取数码详情
    getCodesDetail: function getCodesDetail(value) {
      var that = this;
      that.billNo = value.billNo;
      that.productId = value.productId;
      that.codeVisible = true;
    },
    codeHide: function codeHide() {
      var that = this;
      that.codeVisible = false;
    }
  }
};
exports.default = _default;