"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.deleteInvest = deleteInvest;
exports.detail = detail;
exports.downInvest = downInvest;
exports.getInvestList = getInvestList;
exports.upInvest = upInvest;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
var mode = process.env.VUE_APP_BASE_API;
function getInvestList(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/page',
    method: 'get',
    params: params
  });
}
function downInvest(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/down',
    method: 'get',
    params: params
  });
}
function upInvest(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/up',
    method: 'get',
    params: params
  });
}
function deleteInvest(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/delete',
    method: 'delete',
    params: params
  });
}
function detail(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/detail',
    method: 'get',
    params: params
  });
}
function update(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/update',
    method: 'post',
    data: data
  });
}
function create(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityExtraFixedLaunchConfig/create',
    method: 'post',
    data: data
  });
}