var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    model: _vm.searchForm,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "qyorgId" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "公司", prop: "qyorgId" } },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择公司",
                                      options: _vm.orgList,
                                      filterable: "",
                                      clearable: "",
                                      props: _vm.props,
                                    },
                                    model: {
                                      value: _vm.qyorgId,
                                      callback: function ($$v) {
                                        _vm.qyorgId = $$v
                                      },
                                      expression: "qyorgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "活动名称", prop: "actName" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { placeholder: "请输入活动名称" },
                                    model: {
                                      value: _vm.searchForm.actName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "actName", $$v)
                                      },
                                      expression: "searchForm.actName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "创建日期",
                                    prop: "createTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: _vm.timeData,
                                      callback: function ($$v) {
                                        _vm.timeData = $$v
                                      },
                                      expression: "timeData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              _vm.searchForm.pageNow = 1
                              _vm.fetchData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              _vm.restForm("searchForm")
                              _vm.fetchData()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group fun-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.addActive },
                            },
                            [_vm._v("制作活动")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.datalist,
                    "element-loading-text": "Loading",
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "活动名称",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      prop: "actName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "背景图片",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      prop: "bgImageName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "背景音乐",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      prop: "bgMusicName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "抽奖道具",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.props == 1
                                ? _c("span", [_vm._v(_vm._s("幸运号码"))])
                                : scope.row.props == 2
                                ? _c("span", [_vm._v(_vm._s("手机号"))])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      661522232
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建日期",
                      align: _vm.tableConfig.align,
                      prop: "createTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.getTimeFun(scope.row.endTime) <
                              _vm.presentDate
                                ? _c("el-tag", [_vm._v(_vm._s("已结束"))])
                                : scope.row.status == 1
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.status)
                                      )
                                    ),
                                  ])
                                : scope.row.status == 2
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.status)
                                      )
                                    ),
                                  ])
                                : scope.row.status == 3
                                ? _c("el-tag", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(scope.row.status)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3299134117
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: _vm.tableConfig.align,
                      label: "操作",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.hasAuth("previewbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getExecute_h5(
                                            scope.row.id,
                                            scope.row.props,
                                            3
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("预览")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("scanbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDetail(
                                            2,
                                            scope.row.type,
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (scope.row.status * 1 == 2 ||
                                scope.row.status * 1 == 1) &&
                              _vm.getTimeFun(scope.row.endTime) >
                                _vm.presentDate
                                ? _c(
                                    "el-dropdown",
                                    { staticClass: "table-dropdown" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [
                                          _vm._v(
                                            "\n              更多\n              "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              scope.row.status * 1 == 1 &&
                                              _vm.hasAuth("updatebtn", _vm.apis)
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "button configText",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getDetail(
                                                            1,
                                                            scope.row.type,
                                                            scope.row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _vm.getTimeFun(
                                                scope.row.endTime
                                              ) > _vm.presentDate &&
                                              _vm.hasAuth("startbtn", _vm.apis)
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "button configText",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getExecute(
                                                            scope.row.id,
                                                            scope.row.props
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("执行")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              scope.row.status * 1 == 2 &&
                                              _vm.hasAuth("endbtn", _vm.apis)
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "button configText",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setEnd(
                                                            scope.row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("结束")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1249066170
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.searchForm.pageNow,
                  limit: _vm.searchForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "pageNow", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "pageSize", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogTableVisible,
            "before-close": _vm.cancel,
            "close-on-click-modal": false,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticStyle: { margin: "10 auto" },
              attrs: {
                rules: _vm.formInlineRules,
                model: _vm.formInline,
                "label-position": "right",
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称：", prop: "actName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入活动名称",
                      maxlength: "16",
                      type: "text",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formInline.actName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "actName", $$v)
                      },
                      expression: "formInline.actName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  class: { disabled: !_vm.disabled },
                  attrs: { label: "活动签到码：", prop: "qrCode" },
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: _vm.formInline.qrCode,
                      "preview-src-list": _vm.srcList,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "背景图片：", prop: "bgImageAdderss" } },
                [
                  _c("Upload-img", {
                    ref: "bgImageUpload",
                    attrs: {
                      data: _vm.formInline.bgImageAdderss,
                      "file-name": _vm.formInline.bgImageName,
                      text: "（只支持.png,.jpg 格式）",
                      max: "50",
                      disabled: _vm.disabled,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(
                          _vm.formInline,
                          "bgImageAdderss",
                          $event
                        )
                      },
                      "update:fileName": function ($event) {
                        return _vm.$set(_vm.formInline, "bgImageName", $event)
                      },
                      "update:file-name": function ($event) {
                        return _vm.$set(_vm.formInline, "bgImageName", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled,
                      expression: "!disabled",
                    },
                  ],
                  attrs: { label: "背景音乐：", prop: "bgMusicAddress" },
                },
                [
                  _c("Upload-music", {
                    ref: "bgMusicUpload",
                    attrs: {
                      data: _vm.formInline.bgMusicAddress,
                      "file-name": _vm.formInline.bgMusicName,
                      text: "（只支持.mp3,.flv 格式）",
                      disabled: _vm.disabled,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(
                          _vm.formInline,
                          "bgMusicAddress",
                          $event
                        )
                      },
                      "update:fileName": function ($event) {
                        return _vm.$set(_vm.formInline, "bgMusicName", $event)
                      },
                      "update:file-name": function ($event) {
                        return _vm.$set(_vm.formInline, "bgMusicName", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.disabled,
                      expression: "disabled",
                    },
                  ],
                  attrs: { label: "背景音乐：", prop: "bgMusicName" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "背景音乐：",
                      type: "text",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formInline.bgMusicName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "bgMusicName", $$v)
                      },
                      expression: "formInline.bgMusicName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "抽奖道具：", prop: "props" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { height: "40px", "padding-top": "12px" },
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.formInline.props,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "props", $$v)
                        },
                        expression: "formInline.props",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("幸运号码"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("幸运手机号"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "签到得抽奖券：", prop: "issignin" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { height: "40px", "padding-top": "12px" },
                      attrs: { disabled: _vm.disabled },
                      on: {
                        change: function ($event) {
                          return _vm.changeval()
                        },
                      },
                      model: {
                        value: _vm.formInline.issignin,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "issignin", $$v)
                        },
                        expression: "formInline.issignin",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formInline.issignin == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "签到奖券数量：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          oninput: "value=value.replace(/[^\\d]/g,'')",
                          placeholder: "请输入奖券数量",
                          type: "text",
                          disabled: _vm.disabled,
                          maxlength: "5",
                        },
                        model: {
                          value: _vm.formInline.lotteryNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "lotteryNum", $$v)
                          },
                          expression: "formInline.lotteryNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑奖过期时间：", prop: "endTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formInline.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "endTime", $$v)
                      },
                      expression: "formInline.endTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入内容",
                      maxlength: "50",
                      "show-word-limit": "",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formInline.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "remark", $$v)
                      },
                      expression: "formInline.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "elrowrel",
                  attrs: { label: "奖项设置", prop: "yxCjActPrizeList" },
                },
                [
                  _c("el-input", {
                    staticClass: "disabled",
                    staticStyle: { width: "70%" },
                    attrs: {
                      placeholder: "请选择奖项",
                      readonly: "",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.Prizename,
                      callback: function ($$v) {
                        _vm.Prizename = $$v
                      },
                      expression: "Prizename",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.disabled,
                          expression: "!disabled",
                        },
                      ],
                      staticClass: "elrowpos",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", disabled: _vm.disabled },
                          on: { click: _vm.productListShow },
                        },
                        [_vm._v("增 加")]
                      ),
                      _vm._v(" "),
                      _vm.formInline.yxCjActPrizeList.length
                        ? _c(
                            "el-button",
                            {
                              attrs: { plain: "", disabled: _vm.disabled },
                              on: { click: _vm.clearListShow },
                            },
                            [_vm._v("清 空")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formInline.yxCjActPrizeList.length
                ? _c(
                    "el-form-item",
                    { staticClass: "formInline", attrs: { label: " " } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          class: { margintop: _vm.disabled },
                          staticStyle: { width: "calc(100% - 50px)" },
                          attrs: {
                            fit: "",
                            border: "",
                            "row-key": "pid",
                            "highlight-current-row": "",
                            data: _vm.formInline.yxCjActPrizeList,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "奖品分类",
                              prop: "orders",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "奖项名称",
                              prop: "name",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "数量", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请填写数量",
                                          oninput:
                                            "value=value.replace(/[^\\d]/g,'')",
                                          disabled: _vm.disabled,
                                          maxlength: "5",
                                        },
                                        model: {
                                          value: scope.row.totalNum,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "totalNum", $$v)
                                          },
                                          expression: "scope.row.totalNum",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              987561531
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品数量(袋)", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.type * 1 == 3 ||
                                      scope.row.type * 1 == 2
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              oninput:
                                                "value=value.replace(/[^\\d]/g,'')",
                                              disabled: _vm.disabled,
                                              maxlength: "5",
                                            },
                                            model: {
                                              value: scope.row.bottleNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "bottleNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.bottleNum",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1778620313
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品数量(箱)", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.type * 1 == 2
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              oninput:
                                                "value=value.replace(/[^\\d]/g,'')",
                                              disabled: _vm.disabled,
                                              maxlength: "5",
                                            },
                                            model: {
                                              value: scope.row.boxNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "boxNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.boxNum",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3796868280
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-remove",
                        class: { disabled: _vm.disabled },
                        attrs: { size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.removeTable()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "elrowrel",
                  attrs: { label: "活动说明：", prop: "description" },
                },
                [
                  _c("el-input", {
                    staticStyle: { "white-space": "pre" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入内容",
                      maxlength: "500",
                      rows: 10,
                      "show-word-limit": "",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.formInline.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "description", $$v)
                      },
                      expression: "formInline.description",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticClass: "btnpos" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.disabled,
                              expression: "!disabled",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.templatehtml()
                            },
                          },
                        },
                        [_vm._v("默认模板")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  class: { disabled: _vm.disabled },
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.choseData ? _vm.createData() : _vm.updateData()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "normalBtn",
                  class: { disabled: _vm.disabled },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.productVisibleShow
            ? _c("actDialog", {
                ref: "productVisible",
                attrs: { "pro-sel-visible": _vm.productVisibleShow },
                on: {
                  change: _vm.setProduct,
                  close: function ($event) {
                    _vm.productVisibleShow = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }