var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("el-header"),
      _vm._v(" "),
      _c("el-main", [
        _vm.tw[0].fileUrl == null
          ? _c("div", [_c("div")])
          : _c("div", { staticClass: "cover1" }, [
              _c(
                "img",
                _vm._b(
                  { attrs: { alt: "" } },
                  "img",
                  { src: _vm.twImgurl + _vm.tw[0].fileUrl },
                  false
                )
              ),
            ]),
        _vm._v(" "),
        _c("h3", { domProps: { textContent: _vm._s(_vm.tw[0].title) } }),
        _vm._v(" "),
        _c("span", {
          staticClass: "author",
          domProps: { textContent: _vm._s(_vm.tw[0].author) },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "time",
          domProps: { textContent: _vm._s(_vm.tw[0].createTime) },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.tw[0].description) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "el-footer",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "wxAccountswxgzhwxFunctionfodderindex",
                    params: {},
                  })
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }