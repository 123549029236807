"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _user = require("@/api/user");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _utils = require("@/utils");
var _layout = _interopRequireDefault(require("@/layout"));
var _elementUi = require("element-ui");
var _jsMd = _interopRequireDefault(require("js-md5"));
var importComponent = require('@/router/_import_' + process.env.NODE_ENV);
var filterAsyncRoutes = function filterAsyncRoutes(routes) {
  if (routes.length == 0) {
    return [];
  }
  var icons = ['chart', 'nested', 'tree-table', 'international', 'component', 'tab', 'example', 'search', 'excel', 'icon', 'table', 'list', 'documentation', 'clipboard', 'excel', 'form'];
  var newArry = [];
  for (var i = 0; i < routes.length; i++) {
    var level = routes[i].resourceLevel;
    if (level > 0) {
      var obj = {};
      obj.id = routes[i].resourceCode;
      obj.pid = routes[i].parentCode;
      obj.name = routes[i].resourceUrl ? routes[i].resourceUrl.replace(/\//g, '') : '';
      obj.path = routes[i].resourceUrl;
      obj.level = level;
      if (level == 1) {
        obj.component = _layout.default;
        obj.meta = {
          title: routes[i].resourceName,
          icon: icons[i] ? icons[i] : icons[4],
          id: routes[i].pid,
          menuId: routes[i].pid,
          resourceCode: routes[i].resourceCode,
          manualPage: routes[i].manualPage,
          authList: (0, _utils.getAuthBtn)(routes[i].resourceCode),
          pageList: JSON.parse(localStorage.getItem('pageList'))
        };
      } else {
        var comPath = routes[i].resourceUrl;
        if (comPath == null || comPath == '') {
          obj.component = _layout.default;
        } else {
          obj.component = importComponent("".concat(comPath));
        }
        obj.meta = {
          title: routes[i].resourceName,
          id: routes[i].pid,
          menuId: routes[i].pid,
          resourceCode: routes[i].resourceCode,
          manualPage: routes[i].manualPage,
          authList: (0, _utils.getAuthBtn)(routes[i].resourceCode),
          pageList: JSON.parse(localStorage.getItem('pageList'))
        };
      }
      if (routes[i].resourceCode == 'CGGXDXQ') {
        obj.hidden = true;
        obj.meta.showTagView = false;
        obj.meta.noCache = true;
      }
      if (typeof obj.path !== 'undefined' && obj.path != null) {
        newArry.push(obj);
      }
    }
  }
  var newData = (0, _utils.transTreeData)(newArry, 'id', 'pid', 'children');
  /* const mark = getUrlParams('mark')
  console.log(mark)
  if (!mark) {
    newData.forEach(item => {
      console.log(item)
      if (item.name === 'mark') {
          item.hidden = true
      }
    })
  }*/
  return newData;
};
exports.filterAsyncRoutes = filterAsyncRoutes;
var state = {
  token: (0, _auth.getToken)(),
  routes: [],
  addRoutes: [],
  auths: false
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  },
  SET_AUTHS: function SET_AUTHS(state, auths) {
    state.auths = auths;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch;
    var username = userInfo.username,
      password = userInfo.password,
      code = userInfo.code,
      cookieId = userInfo.cookieId;
    localStorage.setItem('username', userInfo.username);
    return new Promise(function (resolve, reject) {
      // md5加密32位小写
      var user = username.trim();
      var str = "".concat(username.trim(), "+").concat(password);
      var pwd = (0, _jsMd.default)(str);
      (0, _user.login)({
        userAccount: user,
        password: pwd
      }, {
        code: code,
        cookieId: cookieId
      }).then(function (response) {
        console.log(response.data);
        if (!response) {
          return;
        }
        var data = response.data.data;
        var tokenStr = '';
        if (response.data.code == 200) {
          if (data.access_token) {
            commit('SET_TOKEN', data.access_token);
            (0, _auth.setToken)(data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            sessionStorage.setItem('loginStatus', data.loginStatus);
            localStorage.setItem('loginStatus', data.loginStatus);
            sessionStorage.removeItem('errCount');
            sessionStorage.removeItem('errUser');
            tokenStr = data.access_token;
            resolve(data.access_token);
          } else {
            if (data.code == 401) {
              var count = sessionStorage.getItem('errCount') ? sessionStorage.getItem('errCount') : 0;
              // count++
              sessionStorage.setItem('errCount', count);
              sessionStorage.setItem('errUser', username.trim());
            }
            reject();
            (0, _elementUi.Message)({
              message: response.data.data.msg,
              type: 'error',
              duration: 2 * 1000
            });
          }
        } else {
          reject();
          (0, _elementUi.Message)({
            message: response.data.msg,
            type: 'error',
            duration: 2 * 1000
          });
          return;
        }

        /* localStorage.setItem('cid', '9999')
        getgzhlist({ appId: '1', qyorgId: '9999' })
          .then(response => {
            if (response.data.data && response.data.data.length > 0) {
              sessionStorage.setItem('appId', response.data.data[0].appId)
            }
          })*/
        return tokenStr;
      });
    }).then(function () {
      (0, _user.getCurrentUserDetail)().then(function (res) {
        localStorage.setItem('currentUser', JSON.stringify(res.data.data));
        sessionStorage.setItem('userName', JSON.stringify(res.data.data.userName));
        sessionStorage.setItem('userId', JSON.stringify(res.data.data.pid));
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2, token) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(token).then(function (response) {
        if (response.data.code != 200) {
          reject('验证失败，请重新登录！');
        }
        var route = response.data.data.menu;
        var button = response.data.data.button;
        var authList;
        var accessedRoutes;
        // let resolveData
        commit('SET_AUTHS', true);
        if (button.length > 0) {
          authList = button;
        } else {
          authList = [];
        }
        localStorage.setItem('authList', JSON.stringify(authList));
        localStorage.setItem('pageList', JSON.stringify(route));
        if (route.length > 0) {
          accessedRoutes = filterAsyncRoutes(route);
        } else {
          accessedRoutes = [];
        }
        var resolveData = _router.constantRoutes.concat(accessedRoutes);
        commit('SET_ROUTES', accessedRoutes);
        resolve(resolveData);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state,
      dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)().then(function (res) {
        if (!res) {
          commit('SET_TOKEN', '');
          commit('SET_ROUTES', []);
          commit('SET_AUTHS', false);
          (0, _auth.removeToken)();
          (0, _router.resetRouter)();
          dispatch('tagsView/delAllViews', null, {
            root: true
          });
          resolve();
          return;
        }
        if (res.data.code == 200) {
          commit('SET_TOKEN', '');
          commit('SET_ROUTES', []);
          commit('SET_AUTHS', false);
          (0, _auth.removeToken)();
          (0, _router.resetRouter)();
          window.sessionStorage.clear();
          localStorage.clear();
          dispatch('tagsView/delAllViews', null, {
            root: true
          });
          resolve();
        } else {
          (0, _elementUi.Message)({
            message: res.data.msg,
            type: 'error',
            duration: 2 * 1000
          });
        }
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROUTES', []);
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;