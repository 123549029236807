var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "box", staticClass: "cardBox tabBox" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
          },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-steps",
                  {
                    attrs: {
                      active: _vm.active,
                      space: 800,
                      "align-center": "",
                      "finish-status": "success",
                    },
                  },
                  [
                    _c("el-step", { attrs: { title: "基础信息" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "引导页设置" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 0,
                          expression: "active === 0",
                        },
                      ],
                      staticStyle: { padding: "40px" },
                      attrs: { label: "基础信息", name: "basic" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "row-bg", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 24, md: 24, sm: 24 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "basicForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.rules,
                                    model: _vm.basicForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模版名称:",
                                        prop: "templateName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入不能超过30个字符",
                                          type: "text",
                                          maxlength: "30",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.basicForm.templateName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.basicForm,
                                              "templateName",
                                              $$v
                                            )
                                          },
                                          expression: "basicForm.templateName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "状态:",
                                        prop: "templateStatus",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "radio",
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: _vm.basicForm.templateStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.basicForm,
                                                "templateStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "basicForm.templateStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("启用")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("停用")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active == 1,
                          expression: "active == 1",
                        },
                      ],
                      staticClass: "templatess",
                      attrs: { label: "页面设置", name: "active" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 20 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.contentForm.background +
                                        ");",
                                    },
                                    [
                                      _c("div", { staticClass: "phoneTitle" }, [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.contentForm.title
                                                ? _vm.contentForm.title
                                                : "标题"
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "defaultBG" }, [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.contentForm.background
                                                ? ""
                                                : "背景图"
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.contentForm.guideAutoJump !=
                                                1,
                                              expression:
                                                "contentForm.guideAutoJump != 1",
                                            },
                                          ],
                                          staticClass: "phoneBtn",
                                          style:
                                            "background-image: url(" +
                                            _vm.contentForm.bkbutton +
                                            ");",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.contentForm.bkbutton
                                                  ? ""
                                                  : "引导按钮"
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 10, sm: 20 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "contentForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.contentRules,
                                    model: _vm.contentForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "标题：", prop: "title" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入不能超过8个字",
                                          type: "text",
                                          maxlength: "8",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.contentForm.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contentForm,
                                              "title",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "contentForm.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "背景：",
                                        prop: "background",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: _vm.contentForm.background,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contentForm,
                                                "background",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contentForm.background",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c("Upload-img", {
                                                ref: "guidebktotal",
                                                attrs: {
                                                  data: _vm.contentForm
                                                    .background,
                                                  disabled: _vm.disabled,
                                                  text: "建议尺寸：（750px*1624px)",
                                                },
                                                on: {
                                                  "update:data": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.contentForm,
                                                      "background",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "imgbg" }, [
                                        _c(
                                          "div",
                                          { staticClass: "uploadTip" },
                                          [_vm._v("建议尺寸:(750px*1500px)")]
                                        ),
                                        _vm._v(" "),
                                        _vm.contentForm.background
                                          ? _c(
                                              "div",
                                              { staticClass: "showLoadBg" },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                  },
                                                  attrs: {
                                                    fit: "scale-down",
                                                    src: _vm.contentForm
                                                      .background,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "跳转形式：",
                                        prop: "guideAutoJump",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value:
                                              _vm.contentForm.guideAutoJump,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contentForm,
                                                "guideAutoJump",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contentForm.guideAutoJump",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "自动",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "手动",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "等待时间：",
                                        prop: "guideWaitSeconds",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value:
                                              _vm.contentForm.guideWaitSeconds,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contentForm,
                                                "guideWaitSeconds",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contentForm.guideWaitSeconds",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "1秒", value: "1" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "2秒", value: "2" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "3秒", value: "3" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "4秒", value: "4" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: { label: "5秒", value: "5" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.contentForm.guideAutoJump != 1,
                                          expression:
                                            "contentForm.guideAutoJump!= 1",
                                        },
                                      ],
                                      attrs: {
                                        label: "引导按钮：",
                                        prop:
                                          _vm.contentForm.guideAutoJump !== "1"
                                            ? "bkbutton"
                                            : "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: _vm.contentForm.bkbutton,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contentForm,
                                                "bkbutton",
                                                $$v
                                              )
                                            },
                                            expression: "contentForm.bkbutton",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c("Upload-img", {
                                                ref: "guidebkbutton",
                                                attrs: {
                                                  data: _vm.contentForm
                                                    .bkbutton,
                                                  disabled: _vm.disabled,
                                                  text: "建议尺寸：（600px*140px)",
                                                },
                                                on: {
                                                  "update:data": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.contentForm,
                                                      "bkbutton",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "imgbg" }, [
                                        _c(
                                          "div",
                                          { staticClass: "uploadTip" },
                                          [_vm._v("建议尺寸:(600px*140px)")]
                                        ),
                                        _vm._v(" "),
                                        _vm.contentForm.bkbutton
                                          ? _c(
                                              "div",
                                              { staticClass: "showLoadBg" },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                  },
                                                  attrs: {
                                                    fit: "scale-down",
                                                    src: _vm.contentForm
                                                      .bkbutton,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "margin-top": "20px",
                              "margin-bottom": "30px",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "normalBtn",
                                on: { click: _vm.goBack },
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _vm.active !== 0 && _vm.active <= _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("up")
                                      },
                                    },
                                  },
                                  [_vm._v("上一步")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.active == 0 || _vm.active < _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("down")
                                      },
                                    },
                                  },
                                  [_vm._v("下一步")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.active == _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: {
                                      type: "primary",
                                      disabled:
                                        _vm.disabledLoading || _vm.disabled,
                                      loading: _vm.disabledLoading,
                                    },
                                    on: { click: _vm.save },
                                  },
                                  [
                                    _vm._v(
                                      "\n                保存\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }