"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.function.name");
var _prizeMange = require("@/api/market/prizeMange");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
var _prizeSelectDialog = _interopRequireDefault(require("@/components/prizeSelectDialog"));
var _auth = require("@/utils/auth");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagezdAwardManagewinningRecordindex';
var _default = {
  name: 'MarketingManagezdAwardManagewinningRecordindex',
  components: {
    Pagination: _Pagination.default,
    Tipdialog: _TipDialog.default,
    prizeSelectDialog: _prizeSelectDialog.default
  },
  filters: {
    stateName: function stateName(index) {
      var comtype = {
        1: '未发放',
        2: '已发放'
      };
      var result = comtype[index];
      return result;
    },
    reName: function reName(val) {
      if (!val) return '';
      var comtype = {
        SENDING: '发放中',
        SENT: '已发放待领取',
        FAILED: '发放失败',
        RECEIVED: '已领取',
        RFUND_ING: '退款中',
        REFUND: '已退款'
      };
      var result = comtype[val];
      return result;
    },
    prizeType: function prizeType(val) {
      if (!val) return '';
      var comtype = {
        1101: '红包',
        1102: '裂变',
        1104: '红包',
        2000: '实物奖品',
        4000: '第三方',
        6000: '积分'
      };
      var result = comtype[val];
      return result;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      tabHeight: '100%',
      list: [],
      listLoading: true,
      total: 0,
      disabled: false,
      dialogFormVisible: false,
      prizeName: '',
      dialogTitle: '',
      selectId: '',
      limitCount: 1,
      tipContent: '',
      fileList: [],
      imgBackup: '',
      aliasBackup: '',
      formInline: {
        actid: '',
        activityName: '',
        awardsStat: '',
        endTime: '',
        receiveState: '',
        id: '',
        innerCode: '',
        pageindex: 1,
        pagesize: 20,
        phone: '',
        prizeName: '',
        prizeType: '',
        qyorgId: '',
        phonenumber: '',
        reStatus: '',
        startTime: '',
        userId: '',
        userName: ''
      },
      form: {},
      status: {},
      eList: [],
      amtTotal: '',
      downLoading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    if (this.$route.query.name) {
      this.prizeName = this.$route.query.name;
      this.formInline.prizeId = this.$route.query.id;
    }
    if (this.$route.query.userName) {
      this.formInline.userName = this.$route.query.userName;
    }
    if (this.$route.query.userId) {
      this.formInline.userId = this.$route.query.userId;
    }
    if (this.$route.query.activityId) {
      this.formInline.actid = this.$route.query.activityId;
    }
  },
  activated: function activated() {
    var _this = this;
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
        (0, _prizeMange.getExpress)().then(function (res) {
          res = res.data;
          if (res.status * 1 === 200) {
            _this.eList = res.data;
          } else {
            _this.$message.closeAll();
            _this.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    clearPrize: function clearPrize() {
      this.prizeName = '';
      this.formInline.prizeId = '';
    },
    getPrize: function getPrize() {
      this.$refs.prizeSelect.proSelVisible = true;
    },
    setPrize: function setPrize(val) {
      this.prizeName = val.alias;
      this.formInline.prizeId = val.id;
    },
    fixedOnInputChange: function fixedOnInputChange(val, len) {
      while (this.fixedStrLength(val) > len) {
        val = val.substr(0, val.length - 1);
      }
      return val;
    },
    fixedStrLength: function fixedStrLength(value) {
      var cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
      var mat = value.match(cnReg);
      if (mat) {
        return mat.length * 3 + (value.length - mat.length);
      }
      return value.length;
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      this.authId('searchbtn', this.apis);
      (0, _prizeMange.getRecordList)(this.formInline).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this2.list = res.data.list;
          if (res.data.list && res.data.list.length > 0) {
            res.data.list.forEach(function (item) {
              if (item.amtTotal) {
                _this2.amtTotal = item.amtTotal;
              }
            });
          }
          _this2.total = res.data.total;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    restForm: function restForm(refname) {
      this.clearPrize();
      this.$refs[refname].resetFields();
    },
    isDj: function isDj(state, val, id, data) {
      var _this3 = this;
      if (state * 1 === 1) {
        this.$confirm('<div style="line-height:21px;"><i class="el-icon-question" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>此会员已被冻结，是否还要发货？</div>', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(function () {
          _this3.getDetail(val, id, data);
        }).catch(function () {});
      } else {
        this.getDetail(val, id, data);
      }
    },
    getDetail: function getDetail(val, id, data) {
      var _this4 = this;
      switch (val) {
        case 1:
          this.dialogTitle = '详情';
          this.disabled = true;
          break;
        case 2:
          this.authId('sendprize', this.apis);
          this.dialogTitle = '发放奖品';
          this.disabled = false;
          break;
        case 3:
          this.authId('updatebtn', this.apis);
          this.dialogTitle = '修改快递信息';
          this.disabled = false;
          break;
      }
      this.form = data;
      this.dialogFormVisible = true;
      return;
      (0, _prizeMange.recevingDetail)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          res.data.exId = res.data.expressId;
          _this4.form = res.data;
        } else {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
      this.dialogFormVisible = true;
    },
    sub: function sub() {
      var _this5 = this;
      var _this$form = this.form,
        id = _this$form.id,
        exId = _this$form.exId,
        expressOrder = _this$form.expressOrder;
      (0, _prizeMange.updateExpress)({
        id: id,
        exId: exId,
        expressOrder: expressOrder,
        moduleId: this.$route.meta.moduleId,
        proId: this.$route.meta.proId
      }).then(function (res) {
        res = res.data;
        if (res.state * 1 === 200) {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this5.cancle();
          _this5.fetchData();
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    report: function report(id) {
      var _this6 = this;
      this.$confirm('<div style="line-height:21px;"><i class="el-icon-question" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>点击【确定】后将再次给消费者发一次红包，请谨慎操作。</div>', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(function () {
        _this6.authId('insertbtn', _this6.apis);
        (0, _prizeMange.payRedPack)({
          id: id
        }).then(function (res) {
          res = res.data;
          if (res.state * 1 === 200) {
            _this6.$message.closeAll();
            _this6.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this6.fetchData();
            _this6.cancle();
          } else {
            _this6.$message.closeAll();
            _this6.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }).catch(function () {});
    },
    // 导出功能
    download: function download() {
      var _this7 = this;
      this.downLoading = true;
      this.authId('importbtn', this.apis);
      (0, _prizeMange.excelZdRecordOut)(this.formInline).then(function (res) {
        if (res.status != 200) {
          _this7.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '终端中奖记录.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this7.downLoading = false;
      }).catch(function () {
        _this7.downLoading = false;
      });
    },
    getSummaries: function getSummaries(param) {
      var _this8 = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '奖品合计金额(元)';
          return;
        }
        if (index == 1) {
          sums[index] = _this8.amtTotal;
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    cancle: function cancle() {
      this.form = {};
      this.dialogFormVisible = false;
    }
  }
};
exports.default = _default;