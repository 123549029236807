"use strict";

/*
 * @Author: your name
 * @Date: 2020-09-03 10:17:30
 * @LastEditTime: 2020-12-16 16:13:39
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \saas2.0\src\settings.js
 */
module.exports = {
  title: '白云边数字化营销系统',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  showSettings: true,
  fixedHeader: true,
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
};