"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _dataAnalysis = require("@/api/market/dataAnalysis");
var _index = require("@/utils/index.js");
var _LineChart = _interopRequireDefault(require("@/components/Charts/LineChart"));
var _timers = require("timers");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LineChart: _LineChart.default
  },
  data: function data() {
    return {
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
      formInline: {
        actNo: null,
        time: null
      },
      activeList: [],
      rules: {
        actNo: [{
          required: true,
          message: '请选择活动',
          trigger: 'change'
        }],
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      list: {},
      listExpect: {},
      list1: {},
      show: false,
      isAnticipate: 0
    };
  },
  created: function created() {
    var _this = this;
    (0, _dataAnalysis.selectConfig)().then(function (res) {
      res = res.data;
      if (res.status === 200) {
        _this.activeList = res.data;
      } else {
        _this.$message.closeAll();
        _this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    });
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          _this2.listLoading = true;
          (0, _dataAnalysis.selectDay)(_this2.formInline).then(function (res) {
            res = res.data;
            if (res.state * 1 === 200) {
              _this2.isAnticipate = res.data.isAnticipate;
              _this2.list = res.data.actDaySum;
              _this2.listExpect = res.data.actDaySumExpect;
              var xData = [];
              var sData = [];
              var aReally = [];
              var aExpect = [];
              if (res.data.actHourSumVos.length > 0) {
                res.data.actHourSumVos.forEach(function (item) {
                  xData.push(item.crenttime.split(' ')[1] + ':00');
                  aReally.push(item.hourActivitySum);
                  aExpect.push(item.hourActivitySumExpect);
                });
              }
              var oReally = {
                name: '实际参与量',
                type: 'line',
                data: aReally,
                animationDuration: 3000
              };
              var oExpect = {
                name: '预期参与量',
                type: 'line',
                data: aExpect,
                animationDuration: 3000
              };
              if (res.data.isAnticipate * 1 === 1) {
                sData = [oReally, oExpect];
              } else {
                sData = [oReally];
              }
              _this2.show = true;
              (0, _timers.setTimeout)(function () {
                _this2.$refs.barChart.xData = xData;
                _this2.$refs.barChart.sData = sData;
                _this2.$refs.barChart.initChart();
              }, 500);
            } else {
              _this2.$message.closeAll();
              _this2.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
            _this2.listLoading = false;
          });
          (0, _dataAnalysis.surplusCount)(_this2.formInline).then(function (res) {
            res = res.data;
            if (res.state * 1 === 200) {
              _this2.list1 = res.data;
            } else {
              _this2.$message.closeAll();
              _this2.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    // getmapChart(list) {
    //   this.listLoading = true
    //   getMap().then(res => {
    //     if (res.status === 200) {
    //       var data = []
    //       res.data.forEach(item => {
    //         var obj = {
    //           name: item.provinceName,
    //           value: item.count
    //         }
    //         data.push(obj)
    //       })
    //       this.$refs.mapChart.mapData = data
    //       this.$refs.mapChart.initChart()
    //     } else {
    //       this.$message({
    //         message: res.msg,
    //         type: 'error',
    //         duration: 3 * 1000
    //       })
    //     }
    //   })
    // },
    changeChart: function changeChart(num) {
      this.active = num;
      this.fetchData((0, _index.getFormatDate)(num));
    },
    selectAct: function selectAct(val) {
      var _this3 = this;
      this.formInline.time = null;
      this.activeList.forEach(function (item) {
        if (item.actNo === val) {
          var start = new Date(item.startTime.replace(/-/g, '/').split(' ')[0]).getTime();
          var end = new Date(item.endTime.replace(/-/g, '/').split(' ')[0]).getTime();
          _this3.pickerOptions1 = {
            disabledDate: function disabledDate(time) {
              return time.getTime() < start || time.getTime() > end || time.getTime() > Date.now() - 8.64e7;
            }
          };
        }
      });
    }
  }
};
exports.default = _default;