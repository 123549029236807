"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _added = _interopRequireDefault(require("./component/added"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _wordExchangeRule = require("@/api/prizeSetting/wordExchangeRule");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageprizeSettingsetWordexchangePrizesindex';
var _default = {
  name: 'MarketingManageprizeSettingsetWordexchangePrizesindex',
  components: {
    Pagination: _Pagination.default,
    added: _added.default
  },
  data: function data() {
    return {
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        prizeName: '',
        status: '',
        exchangePrizeType: '',
        ruleName: ''
      },
      total: 0,
      listLoadig: false,
      tableData: [],
      dialogVisible: false,
      title: '',
      currentruleId: ''
    };
  },
  activated: function activated() {
    this.getRuleListPage();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 搜索条件
    getRuleListPage: function getRuleListPage() {
      var _this = this;
      this.listLoadig = true;
      (0, _wordExchangeRule.getRuleListPage)(this.queryParams).then(function (res) {
        _this.listLoadig = false;
        if (res.data.data.code || res.data.data.code == 0) {
          _this.$message.error(res.data.data.msg);
          return;
        } else {
          if (res.data.data !== null) {
            _this.total = parseInt(res.data.data.total);
            _this.tableData = res.data.data.records;
          } else {
            _this.total = 0;
            _this.tableData = [];
          }
        }
      }).finally(function () {
        _this.listLoadig = false;
      });
    },
    // 重置搜索条件
    restQueryList: function restQueryList() {
      this.queryParams.ruleName = '';
      this.queryParams.status = '';
      this.queryParams.exchangePrizeType = '';
      this.getRuleListPage();
    },
    // 新增按钮
    handleAdd: function handleAdd() {
      this.title = '新增集字兑换规则';
      this.currentruleId = '';
      this.dialogVisible = true;
    },
    // 导出按钮
    exportFile: function exportFile() {
      var _this2 = this;
      (0, _wordExchangeRule.exportPrize_Rule)(this.queryParams).then(function (res) {
        _this2.$message.success('导出成功！');
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '兑换规则列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 查看详情
    handleDetails: function handleDetails(item) {
      this.title = '详情';
      this.currentruleId = item.id;
      this.dialogVisible = true;
    },
    // 编辑
    handleUpdate: function handleUpdate(item) {
      this.title = '编辑集字兑换规则';
      this.currentruleId = item.id;
      this.dialogVisible = true;
    },
    // 启用停用
    stop: function stop(row) {
      var _this3 = this;
      var str = row.status == 1 ? '停用' : '启用';
      this.$confirm("\u662F\u5426".concat(str, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        if (row.status == 1) {
          (0, _wordExchangeRule.endPrize_Rule)({
            ruleId: row.id
          }).then(function (res) {
            if (res.data.data.code || res.data.data.code == 0) {
              return _this3.$message.error(res.data.data.msg);
            } else {
              _this3.getRuleListPage();
              _this3.$message.success('' + str + '成功');
            }
          });
        } else {
          (0, _wordExchangeRule.startPrize_Rule)({
            ruleId: row.id
          }).then(function (res) {
            if (res.data.data.code || res.data.data.code == 0) {
              return _this3.$message.error(res.data.data.msg);
            } else {
              _this3.getRuleListPage();
              _this3.$message.success('' + str + '成功');
            }
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCancel: function handleCancel() {
      this.getRuleListPage();
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;