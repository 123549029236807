var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("产品字段维护")]),
              _vm._v(" "),
              _c("div", { staticClass: "phead" }, [
                _c(
                  "div",
                  {
                    staticClass: "head-btn-group",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      width: "200px",
                    },
                  },
                  [
                    _c(
                      "el-button-group",
                      { staticStyle: { display: "flex" } },
                      [
                        _vm.hasAuth("savebtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  plain: _vm.btnConfig.plain,
                                },
                                on: { click: _vm.saveconfig },
                              },
                              [_vm._v("保存内容")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasAuth("resetbtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.downLoadBtn.type,
                                  size: _vm.btnConfig.size,
                                  loading: _vm.downLoading,
                                  plain: _vm.btnConfig.plain,
                                },
                                on: { click: _vm.cancleconfig },
                              },
                              [_vm._v("放弃编辑")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                      ? _c("i", {
                          staticClass: "el-icon-circle-plus",
                          staticStyle: {
                            "font-size": "36px",
                            "margin-left": "10px",
                            color: "#409eff",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addproduct()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      ref: "table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        id: "settingtable",
                        "header-cell-style": { padding: "12px 0" },
                        data: _vm.form.tableData,
                        height: _vm.tabHeight,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-page-no-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", type: "index", width: "60" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "字段编码",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".attributeCode",
                                        rules: _vm.rules.attributeCode,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "20",
                                        },
                                        on: {
                                          input: function (e) {
                                            return (scope.row.attributeCode =
                                              _vm.validTe(e))
                                          },
                                        },
                                        model: {
                                          value: scope.row.attributeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "attributeCode",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.attributeCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2639748417
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "字段名称",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".attributeName",
                                        rules: _vm.rules.attributeName,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "10",
                                        },
                                        on: {
                                          input: function (e) {
                                            return (scope.row.attributeName =
                                              _vm.cTrim(e))
                                          },
                                        },
                                        model: {
                                          value: scope.row.attributeName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "attributeName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.attributeName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3188942820
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "类型",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".attributeType",
                                        rules: _vm.rules.attributeType,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: "请选择",
                                            disabled: scope.row.flag == "Y",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changetype(
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.attributeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "attributeType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attributeType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "文本",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "图片",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "字典",
                                              value: "3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3977214498
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "约束",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (ref) {
                                return [
                                  _c("span", [_vm._v("约束")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                        staticStyle: {
                                          "font-size": "14px",
                                          "vertical-align": "middle",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "类型为文本的约束X，代表产品档案自定义字段输入数字或汉字或英文的长度为X"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "类型为图片的约束X，代表产品档案自定义字段添加图片的张数为X"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "类型为字典的约束X，代表产品档案自定义字段单选或者多选"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".attributeConstraint",
                                        rules:
                                          scope.row.attributeType == "1"
                                            ? _vm.rules.attributeConstraint
                                            : scope.row.attributeType == "2"
                                            ? _vm.rules.attributeConstraint1
                                            : scope.row.attributeType == "3"
                                            ? _vm.rules.attributeConstraint2
                                            : "",
                                      },
                                    },
                                    [
                                      scope.row.attributeType == "3"
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  scope.row.attributeConstraint,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "attributeConstraint",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attributeConstraint",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "单选",
                                                  value: "0",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "多选",
                                                  value: "1",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.attributeType == "1"
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入字符长度",
                                              maxlength: "3",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (scope.row.attributeConstraint =
                                                  _vm.cTrim(e))
                                              },
                                            },
                                            model: {
                                              value:
                                                scope.row.attributeConstraint,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "attributeConstraint",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.attributeConstraint",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.attributeType == "2"
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入图片张数",
                                              maxlength: "1",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (scope.row.attributeConstraint =
                                                  _vm.cTrim(e))
                                              },
                                            },
                                            model: {
                                              value:
                                                scope.row.attributeConstraint,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "attributeConstraint",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.attributeConstraint",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2560500813
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "数据源",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".attributeDictionaryName",
                                        rules:
                                          scope.row.attributeType == "3"
                                            ? _vm.rules.attributeDictionaryName
                                            : [],
                                      },
                                    },
                                    [
                                      scope.row.attributeType == "3"
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入",
                                              disabled: scope.row.flag == "Y",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.openTSel(
                                                  scope.$index
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                scope.row
                                                  .attributeDictionaryName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "attributeDictionaryName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.attributeDictionaryName",
                                            },
                                          })
                                        : _c("div"),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4293280428
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          align: "center",
                          "min-width": "150",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { model: scope.row } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: scope.row.attributeStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "attributeStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attributeStatus",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "启用",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "禁用",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          917676448
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: "center",
                          width: "240",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteproduct(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          866481288
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数据字典选择",
            visible: _vm.yuanvisible,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.yuanvisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section",
                      staticStyle: {
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "listQuery",
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.listQuery,
                            "status-icon": "",
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "dataDictionaryName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "字典名称",
                                    prop: "dataDictionaryName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      maxlength: "50",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.dataDictionaryName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "dataDictionaryName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.dataDictionaryName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "dataDictionaryCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "字典编号",
                                    prop: "dataDictionaryCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      maxlength: "50",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.dataDictionaryCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "dataDictionaryCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.dataDictionaryCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                "margin-top": "48px",
                              },
                              attrs: { span: 11 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.listQuery.current = 1
                                      _vm.getdictionarylist()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetdictionary },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                data: _vm.tableyuanData,
                                height: "440px",
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "字典名称",
                                  prop: "dictionaryName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "字典编号",
                                  prop: "dictionaryCode",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: _vm.tableConfig.align,
                                  width: "100",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submit(row)
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total > 0",
                              },
                            ],
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.total,
                              page: _vm.listQuery.current,
                              limit: _vm.listQuery.size,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.listQuery,
                                  "current",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(_vm.listQuery, "size", $event)
                              },
                              pagination: _vm.getdictionarylist,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }