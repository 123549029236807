"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/basic/product");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      imgUrl: require("../../images/origin.png"),
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        productClassName: '',
        pageNo: 1,
        pageSize: 20
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      channel: '',
      searchStatus: false,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      },
      isShowSelectedTable: true
    };
  },
  mounted: function mounted() {
    this.setsel();
  },
  methods: {
    setViewData: function setViewData(key, data) {
      this.$emit('change', data);
    },
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.getList(true);
      this.$nextTick(function () {
        _this.data.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      setTimeout(function () {
        if (_this.tableData.length) {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
        }
        _this.selLoading = false;
      }, 500);
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList(isOverView) {
      var _this2 = this;
      var overView = isOverView == true;
      this.listLoading = true;
      var submitData = JSON.parse(JSON.stringify(this.searchForm));
      (0, _product.marketList)(submitData).then(function (res) {
        if (res.data.code !== 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.tableData = res.data.data.records;
            _this2.total = Number(res.data.data.total);
          } else {
            _this2.total = 0;
            _this2.tableData = [];
          }
        }
        _this2.listLoading = false;
      }).then(function () {
        _this2.data.length && _this2.tableData.length && _this2.data.map(function (row) {
          var rowItem = _this2.tableData.find(function (item) {
            return row.pId == item.pId;
          });
          overView && _this2.$refs.multipleTable.toggleRowSelection(rowItem || row, true);
        });
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchStatus = false;
      this.searchForm = {
        productClassName: '',
        pageNo: 1,
        pageSize: 20
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
    },
    submit: function submit() {
      console.log(this.multipleSelection, '选择');
      this.isShowSelectedTable = false;
      // this.$emit('change', this.multipleSelection)
      // this.selCancel()
    },
    tag1Close: function tag1Close(row) {
      var _this3 = this;
      this.$nextTick(function () {
        var closeItem = _this3.tableData.find(function (item) {
          return row.pId == item.pId;
        });
        _this3.$refs.multipleTable.toggleRowSelection(closeItem || row, false);
      });
    }
  }
};
exports.default = _default2;