"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/templateManage/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Content: _config.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      type: '',
      list: [{
        img: require('@/assets/images/hongbao.png'),
        title: '扫码抽奖',
        type: '2'
      }, {
        img: require('@/assets/images/zhuanpan.png'),
        title: '大转盘',
        type: '3'
      }, {
        img: require('@/assets/images/jizi.png'),
        title: '集字',
        type: '1'
      }
      // {
      //   img: require('@/assets/images/jiugongge.png'),
      //   title: '九宫格抽奖',
      //   type: '4'
      // }
      ]
    };
  },

  methods: {
    creatAct: function creatAct(type) {
      this.type = type;
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
      this.type = '';
    }
  }
};
exports.default = _default;