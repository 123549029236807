"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _echarts = _interopRequireDefault(require("echarts"));
/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory);
  } else if ((typeof exports === "undefined" ? "undefined" : (0, _typeof2.default)(exports)) === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'));
  } else {
    // Browser globals
    factory({}, _echarts.default);
  }
})(void 0, function (exports, echarts) {
  var log = function log(msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log('ECharts is not Loaded');
    return;
  }
  if (!echarts.registerMap) {
    log('ECharts Map is not loaded');
    return;
  }
  echarts.registerMap('香港', {
    "type": "FeatureCollection",
    "features": [{
      "id": "810001",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@D@bKBoCWKACBGCI@CJSVGFIBKCM@ABAF@LFHPFJJPFVB"], ["@@ABD@@AA@"], ["@@AAEAAB@DDBFC"]],
        "encodeOffsets": [[[116895, 22829]], [[116861, 22818]], [[116860, 22817]]]
      },
      "properties": {
        "cp": [114.154334, 22.281931],
        "name": "中西区",
        "childNum": 3
      }
    }, {
      "id": "810002",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@BMHBBGD@BAHBD@HKDEF@FHDEDECCGCIAAQWCUBSBDXApREHD"],
        "encodeOffsets": [[116927, 22822]]
      },
      "properties": {
        "cp": [114.18299, 22.276345],
        "name": "湾仔区",
        "childNum": 1
      }
    }, {
      "id": "810003",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@rWAGBEJKRO@ACCBEAIAEGEACKPA@MDICIDOBKDGAIBIABRNDHFEHADEGE@CFGLC@GAABC@AHECABAN|TTI"],
        "encodeOffsets": [[116967, 22827]]
      },
      "properties": {
        "cp": [114.225965, 22.279779],
        "name": "东区",
        "childNum": 1
      }
    }, {
      "id": "810004",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@JBJAHBLCPAJCJDPCLOJM@IBAFBBA@CE@AAE@AAFIBQBADAFECCBEA@GFCAYBABIRC@QEGEACBEHMACECQACFE`A@GCK@AFFHADMJBBF@BB@FBHCFI@KLEBCAGWAAQDGCGJQHOPOHS`KLN@LDJAHETUDIJ@HDxCZD"], ["@@ACCBFB"], ["@@D@@ACCCB@CC@CFBDJA"], ["@@BA@AG@@B@BF@"], ["@@DABAGCADADBBDA"], ["@@BAEBD@"]],
        "encodeOffsets": [[[116946, 22787]], [[116886, 22776]], [[116934, 22767]], [[117006, 22758]], [[116932, 22748]], [[116970, 22738]]]
      },
      "properties": {
        "cp": [114.160023, 22.245811],
        "name": "南区",
        "childNum": 6
      }
    }, {
      "id": "810005",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@AIBINQNIHU]IsRC@AvFBHFDCLDV@"],
        "encodeOffsets": [[116920, 22860]]
      },
      "properties": {
        "cp": [114.173347, 22.311632],
        "name": "油尖旺区",
        "childNum": 1
      }
    }, {
      "id": "810006",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@H[@EEGU@KCCDGEEABQi@AHCF@B^P@BABOFCFBFF@FDNADADHJDjB"],
        "encodeOffsets": [[116919, 22881]]
      },
      "properties": {
        "cp": [114.163349, 22.333775],
        "name": "深水埗区",
        "childNum": 1
      }
    }, {
      "id": "810007",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@DEBEFC@CJKD@DCHDLDBMEG@CFCLICETSDQSJeMGVKHGJIR@HFJBFG^FDF@"],
        "encodeOffsets": [[116925, 22883]]
      },
      "properties": {
        "cp": [114.193047, 22.312373],
        "name": "九龙城区",
        "childNum": 1
      }
    }, {
      "id": "810008",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@AC@IH_BAYCEDIAGDICGCCDC@IL@DEDAFCFNDVD@BADBBHCFDJA^D"],
        "encodeOffsets": [[116970, 22892]]
      },
      "properties": {
        "cp": [114.203985, 22.336112],
        "name": "黄大仙区",
        "childNum": 1
      }
    }, {
      "id": "810009",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@F@HWF@DABD@IHBB@GOAENMBE@AEAGOBCFE@CqXCRSTDFKJED@DFHANFCJBFCZD"],
        "encodeOffsets": [[116974, 22868]]
      },
      "properties": {
        "cp": [114.214083, 22.320679],
        "name": "观塘区",
        "childNum": 1
      }
    }, {
      "id": "810010",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@AEDQ@WQACIKIGCQCABAHC@]I@ADC@ECICCKEGA@NuASIHyO@IEQW@CÙF~bNJFL@D@FLBBNPJJFIDAHEDBDHBDDBBTCB@AHDB^@@JHHF@DDLFJFDBHCD@PPD@L@RLLBDHVBNAHBBABGhB"],
        "encodeOffsets": [[116914, 22950]]
      },
      "properties": {
        "cp": [114.121234, 22.368458],
        "name": "荃湾区",
        "childNum": 1
      }
    }, {
      "id": "810011",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@AC@G]@CABGA@SDAACCGAACFCBGJCIEOIAMKAEHGFgFgPS@E@MEGEEGCAO@E@SLUNCH@DDH@H@JEHAJCHQFAB\\`ZHTCFKBAHFFJFJBBZ@FC^CND@AEINGXA@OL@@U@@HN@BEE@@KTA@G"], ["@@BAFADAAC@AACDACGEBBHAFGFBDD@"], ["@@B@B@@CA@AD"], ["@@BAB@CCA@ABDD"], ["@@D@CGABAFD@"], ["@@@ACICABJFD"], ["@@DA@ACCC@ADBFBBBC"], ["@@@A@@ABB@"]],
        "encodeOffsets": [[[116810, 22924]], [[116618, 22917]], [[116612, 22891]], [[116626, 22888]], [[116629, 22880]], [[116725, 22874]], [[116708, 22866]], [[116720, 22861]]]
      },
      "properties": {
        "cp": [113.976308, 22.393896],
        "name": "屯门区",
        "childNum": 8
      }
    }, {
      "id": "810012",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@BMDIEK@CDCAMHG@C@MCG@YBAZHZDLIJA[gAGBEHMTO@EAIKAQKK@C@OOC@GDCAIEKECCE@EE@HSB@LF@AFM@@G@@VK@@PWBMHFJ@BMC]DEDY@AAEIEIGEABELSDVHNRJPHJLHXHFBP^ZVBRB@\\CJIDEH@DDAJHDFF@DGL@FFB^CRED@JFDPJHPBHED@HFFF"],
        "encodeOffsets": [[116828, 23059]]
      },
      "properties": {
        "cp": [114.032528, 22.44132],
        "name": "元朗区",
        "childNum": 1
      }
    }, {
      "id": "810013",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@A@@HIEK@EA@BDH@BBBD@BBBAB@MN@DH@@BEFBDFBDA@C@@VABBDAJ@B@D@D@@CC@ECGB@ACACCA@IFEBAC@CFABEHGF@BACGGB"], ["@@DC@CEDAFB@BA"], ["@@AABB"], ["@@@AC@DB"], ["@@AABB"], ["@@BAAAEABDDB"], ["@@@A@CEDBBD@"], ["@@@AABB@"], ["@@DA@CIE@JFB"], ["@@@@@@@@"], ["@@DCD@BNDBDIAE@I@EE@ABABOHAJDDFABC"], ["@@@A@B"], ["@@B@B@ECAFB@BA"], ["@@AA@BB@"], ["@@@@A@B@"], ["@@A@B@@@"], ["@@@A@B"], ["@@BCAB@B"], ["@@@A@B"], ["@@@FBCDBDABBBBBABCB@ABDBBDB@DH@JABBDNEBE@CD@FCKKG@AEC@EBA@ACIEA@EDBB@DB@ED"], ["@@A@B@"], ["@@D@BCG@ADBBBA"], ["@@@@@@"], ["@@BAAB"], ["@@A@B@"], ["@@@@AABB"], ["@@QCKMECAKH@FDD@FFDABAFBBHJBPZBDJ@BCD@BBHA@DF@HAAAEEDA@EF@DGF@BC@CGE@GFBLABBADBBDAH@D@@AEIBAFF@ADBDAGGAEA@EIBCLBBA@CDBBCHBBABCTKLFZHRJH@DASMQGGIOEGAGBaNM@eEK@EDKLGBCFEBAAAGGGEEEIIGAEIDU@GBK@IDUAIAEEEGCIEBEAAHM@AABC@EKCWOMEIBKJYCYGAB@ZDH@N@DGHBNCFFNEN@JBFMJBDNDFHFBHEDALDFAFD@KFAFDBFH@FHDBF@DFHAHDLABAFBBDFF@BAAA@@D@DD@@HH@LLDCJFFCJ@BABEFABBJAB@DADDH@BBB@FBDCPBFEJBFC@AFADC@IDA"]],
        "encodeOffsets": [[[117049, 23071]], [[117017, 23092]], [[117013, 23092]], [[117010, 23091]], [[117004, 23090]], [[117009, 23086]], [[117081, 23081]], [[117014, 23081]], [[117057, 23081]], [[117015, 23077]], [[117062, 23071]], [[117020, 23075]], [[117024, 23072]], [[117021, 23072]], [[117029, 23071]], [[117037, 23071]], [[116974, 23071]], [[116957, 23067]], [[117036, 23065]], [[117043, 23049]], [[117033, 23064]], [[117035, 23059]], [[117043, 23052]], [[117049, 23053]], [[117040, 23048]], [[117054, 23039]], [[116975, 23082]]]
      },
      "properties": {
        "cp": [114.147404, 22.496143],
        "name": "北区",
        "childNum": 27
      }
    }, {
      "id": "810014",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@HGNA@ACGC@G@OFADCL@FDDJDBA@M"], ["@@@EACUMIMGGGCE@@CACIEMCMISGG@CESIGKGEQGG@CBABALQTCDABC@GCK@EBCACAAKBAHABAB@HA@AE@@ENADBDLFBFABACGEEICG@ICQB@B@J@@GBAAEEAODERKMIGMAAUAAAUA@ACAAA[ASAAHABGAMBUAAA@D@FSPIRBJ\\hNFXPLD@FADBBN@BGFBFAHPDFHD\\BJCL@HAV@JCBFJHFJFFHHBHDBHGHALKFCL@fFN@bMLATHHJRHTN"], ["@@CIEAEJBBBDBBBBHAFBEG"], ["@@@CFABAAGCC@ECCEACB@HAJAB@FEDJJDBHABA@CAC"], ["@@BAEBBBBA"], ["@@^DLCFBBBBJFJNLH@DHN@NXJFFHPHDABABKDAFDDABGAEBCDBFFALDDBFDBDADIBIFOCCICAAAI@CDG@KJ@HEBBDJCBALDBFBBBDB@HBHHALEDE@IEG@EGA@EDIBYEGAIEEICADMFOLA@KEE@OHKHYDAC@KCECAS@CCAEBGGOK@CACIYCEAGEBLCH@JGJ@FBXCHDL@P"], ["@@AEA@ABB@@FB@BA"], ["@@@CABBB"], ["@@BAAAABBB"], ["@@BACDBA"], ["@@@A@B"], ["@@@DHBAEC@A@"], ["@@AA@BB@"], ["@@BB@CAA@D"]],
        "encodeOffsets": [[[117183, 23086]], [[117087, 23049]], [[117113, 23039]], [[117114, 23018]], [[117122, 23021]], [[116998, 22974]], [[117082, 23017]], [[117084, 23018]], [[117081, 23013]], [[117180, 23003]], [[117039, 23001]], [[116966, 22997]], [[117006, 22983]], [[116968, 22976]]]
      },
      "properties": {
        "cp": [114.171713, 22.44573],
        "name": "大埔区",
        "childNum": 14
      }
    }, {
      "id": "810015",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@B@@@C@BB@A"], ["@@FIJBFCF@JDF@DDBEDBDEHACGDK@EFEFK@EGAI@CDILEBEAIG@C@EECCKBCJEFGFAJGAGDKEGDG@GCCC@CBCFC@AIGAACFEEACC@CEAGB@EC@@AID@KBC@CACCGE@ABALGLBFA@GGEAE@GJAFBFIFCJHN@JOFGFG@@DBHABCFKAAEDCACI@GBGAAFI@GJEBE@ECAEBICCCU@KJI@IGKIBAAHcDEFENAFADE@CDGDET@BCBEJGDKAIKEEEGAACBCF@DA@MHI@CACCAE@AGE@OLEHGBAA@CECCGEAEBITGDANHRGLCDG@QSEG@DEDADHPFB@BEJIJBFHPA@GA@JACCBEBGVE@ABG`@JBDB@ADBFPBPJLLBJRDAJHFFBZDFLN@HPAHBFDDT@DBDF@LBDZCLGPGF@LFB@PKNEBCJDFFBJFHAZCJ@FHB"], ["@@A@@@B@"], ["@@@@@@"], ["@@AABB"], ["@@BAC@@DBA"], ["@@BAA@ABB@"], ["@@@AABB@"], ["@@@AABB@"], ["@@B@BACEC@@DDD"], ["@@A@B@"], ["@@@AADBA"], ["@@DC@CA@ADA@ADD@"], ["@@DBFADBLC@MDEBIA@@GJAHGCCBEAA@CCA@CAEKBEBGACBEDEJAFHHAB@LABBDA@@AA@CBBB@DD@@DCDCB@JBBHA"], ["@@@AAAAD@@D@"], ["@@@A@B@@"], ["@@HE@C@ICAA@AHAHCEEFBF@BD@FA"], ["@@BACA@CC@@DFD"], ["@@@AA@BB"], ["@@BCFE@ACCDQCACB@FCBCLGHFFBHFDB@DE@AEBBE"], ["@@@ACAAFFA"], ["@@BA@@CBB@"], ["@@B@C@BB@A"], ["@@@AABB@"], ["@@@AA@@DBB@C"], ["@@ACABDB"], ["@@BAAB"], ["@@@@@@"], ["@@BACA@BBB"], ["@@A@BB@A"], ["@@FADIFEAICAACC@ABI@CFBDCD@BCHFFLB"], ["@@CEBEBACA@AA@KD@JBHH@@BF@BE"], ["@@@@A@B@"], ["@@@CE@BFB@BA"], ["@@AB@FDC@AAA"], ["@@DHFEDAFGACBCMBEJ@D"], ["@@BADABAB@BCKBCFDB"], ["@@B@B@C@"], ["@@A@@BB@@A"], ["@@D@C@@@"], ["@@BAAB"], ["@@@AA@BB"], ["@@BAAB@@"], ["@@@AADBA"], ["@@@@@@"], ["@@@A@B"], ["@@AA@DBA"], ["@@@A@B"], ["@@@A@B"], ["@@@AABB@"], ["@@BAEBBBBA"], ["@@@@@@"], ["@@A@B@"], ["@@BC@AAAEDBDD@"], ["@@B@@AAB"], ["@@BACKAAC@@JDDBBB@"], ["@@AA@B@@B@"], ["@@BCA@@D"], ["@@@@@@"], ["@@@A@B"], ["@@@EAABAE@AAA@@JF@@FBBBA@C"], ["@@@CCB@BD@"], ["@@AA@GAAEAAE@@E@AJFLDDFADFF@@EEC"], ["@@AFDBJBAADCDEACFEGA@EEB@FEDAH"], ["@@JDB@ACDC@AA@ACB@@ADA@EC@@EAEC@E@AECAABBFIBED@F@DFHFDDAFF"]],
        "encodeOffsets": [[[117146, 22985]], [[117119, 22980]], [[117154, 22972]], [[117153, 22970]], [[117139, 22942]], [[117137, 22939]], [[117137, 22938]], [[117033, 22925]], [[117063, 22925]], [[117066, 22923]], [[117031, 22921]], [[117064, 22919]], [[117029, 22917]], [[117054, 22915]], [[117038, 22915]], [[117048, 22915]], [[117075, 22911]], [[117036, 22912]], [[117039, 22911]], [[117043, 22905]], [[117051, 22909]], [[117044, 22906]], [[117050, 22906]], [[117074, 22902]], [[117143, 22898]], [[117036, 22899]], [[117076, 22898]], [[117116, 22882]], [[117120, 22880]], [[117102, 22876]], [[117073, 22876]], [[117119, 22871]], [[117126, 22873]], [[117085, 22870]], [[117121, 22865]], [[117041, 22863]], [[117123, 22866]], [[117118, 22860]], [[117118, 22859]], [[117118, 22859]], [[117069, 22860]], [[117077, 22857]], [[117116, 22856]], [[117068, 22845]], [[117059, 22840]], [[117059, 22838]], [[117061, 22837]], [[117066, 22837]], [[117066, 22837]], [[117061, 22835]], [[117070, 22834]], [[117061, 22834]], [[117068, 22833]], [[117064, 22810]], [[117100, 22804]], [[117100, 22803]], [[117118, 22799]], [[117120, 22799]], [[117028, 22798]], [[117095, 22795]], [[117103, 22791]], [[117097, 22787]], [[117105, 22856]], [[117111, 22850]], [[117039, 22789]]]
      },
      "properties": {
        "cp": [114.264813, 22.314203],
        "name": "西贡区",
        "childNum": 65
      }
    }, {
      "id": "810016",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@PGJAVDFD@HDBJBPA@OCKDGAYHM@IDGAKBIQCAIKKOIMACEBC_CIBECGDAABC@AUCKCG@ICeAICAAGHAL@FFJCP@FDHADLBFDLJDJRB@XCRBFHBBBDB@BVBBBXBHPNJ"],
        "encodeOffsets": [[116956, 22970]]
      },
      "properties": {
        "cp": [114.195126, 22.379715],
        "name": "沙田区",
        "childNum": 1
      }
    }, {
      "id": "810017",
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": ["@@AECBMBECGA@CDEPEBA]QDGBGj@@cUADF@F@DCFIFSJQTQBaASPEBGzTJvB@MHBLFDDDJ@FCD@@ZJHBBIJBBCCI@GDKEI@EBKHG"],
        "encodeOffsets": [[116892, 22877]]
      },
      "properties": {
        "cp": [114.13918, 22.363908],
        "name": "葵青区",
        "childNum": 1
      }
    }, {
      "id": "810018",
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [["@@AIIGQCQ@GE@G@ICCGAAABCBA@GBGCA@ADOAGO@KBMLC@ACAEFARSDEACSGCEACLIL@DEPCBACEAECCE@AACEIECCKCMGI@KJGJEJ@FDLIBSGUAOOSEAABE@ECG_CSBUPAHGBICEOUMGCKBC@GCGGMCABDRGDAF@HBFEHAP@JJJH@BNRFDDBHEBICAH@DPHF@DAFFFNLBRFBHNNBD@HEJJVED@DBDZD@DEPFBAFCBAI[@CFGF@~dÚE"], ["@@DC@AKE@ABADDF@BCFCAAIEG@CBAB@DABAFGDBBXF"], ["@@BAAAC@ADDBBA"], ["@@BCAAIBA@BDBDJB@CAA"], ["@@BCCGGCGBABBHFDL@"], ["@@J@FC@AAKFGCIC@EDADAD@B@BCBAAEHI@AF@BB@@DT@"], ["@@CEMI@ABEHHFBHBHAFDFBF@BCLBDC@AGIK@OMDKJM@CAAC@YP@AHKAGAACBGHEGC@GBMDCD@HBDFDDDBXCFBNAHEBA@@IEAGCK@CNBDLHHD@BEBDFGD@DVJBBBBBCF@BECGACLGBEAADEDADFF@DE@E"], ["@@@CEA@DDDBA"], ["@@FEFBD@DEC@@CCAACGF@AE@CCBAC@CCCCCBBBG@AHH@DHH@DFADABE@CD@BD@DF@BABBDDBH@DBDB@E@AEAFABAKCAA@EDA"], ["@@D@ACAACD@B@BDA"], ["@@BAC@BB"], ["@@B@BBBA@EACGAEFA@GDBHJJFA@CB@BCAA@C"], ["@@D@BCBEAECCGC@BIB@CAA@FA@@PTD"], ["@@DE@KG@CBAHBDDABFB@"], ["@@BB@CAB"], ["@@BHBB@CAEA@"], ["@@BADBDABAD@BAAABAAAABABAA@CA@ADAAAB@DABCACCC@BRHCBC"], ["@@@A@ACA@DDB"], ["@@DAAC@@CD@BB@"], ["@@ABBA"], ["@@BAC@BB"], ["@@AAA@BDBA"], ["@@A@B@"], ["@@BBBAAAABCAA@FB"], ["@@@BD@DDCDBDDBDAB@FCAAE@@EBABEE@CAI@AACAGF@DFDF@DA"], ["@@@A@A@D"], ["@@BACA@BBB"], ["@@B@@AEABBBB"], ["@@DFPFBFF@BCFCFICCDEBIQA@DA@EC@ECCEAE@FJIBABD@@DCAABBJDF"]],
        "encodeOffsets": [[[116799, 22849]], [[116787, 22826]], [[116803, 22822]], [[116823, 22817]], [[116799, 22797]], [[116780, 22791]], [[116882, 22747]], [[116881, 22757]], [[116774, 22741]], [[116575, 22748]], [[116686, 22746]], [[116733, 22725]], [[117001, 22721]], [[117036, 22720]], [[117038, 22719]], [[117053, 22714]], [[116651, 22714]], [[117053, 22714]], [[116664, 22710]], [[116665, 22706]], [[116984, 22706]], [[116645, 22704]], [[116647, 22703]], [[116640, 22702]], [[116646, 22692]], [[116656, 22693]], [[116663, 22685]], [[116998, 22691]], [[117001, 22702]]]
      },
      "properties": {
        "cp": [113.946059, 22.286371],
        "name": "离岛区",
        "childNum": 29
      }
    }],
    "UTF8Encoding": true
  });
});