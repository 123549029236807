"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _pack = require("@/api/basic/pack");
var _store = require("@/api/store");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    labelText: function labelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '前关联',
        2: '后关联'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: 'ERP',
        1: '平台'
      };
      return statusMap[status];
    },
    // inboundStatus(status) {
    //   if (!String(status)) return ''
    //   const statusMap = {
    //     '0': '待入库',
    //     '1': '已入库'
    //   }
    //   return statusMap[status]
    // },
    minSum: function minSum(sum, packScaleExpression) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum / arr[0];
      for (var i = 1; i < le; i++) {
        num = arr[i] * num;
      }
      return num;
    }
  },
  props: {
    taskVisible: {
      default: false
    }
  },
  data: function data() {
    return {
      handleShow: true,
      factoryList: [],
      workshopList: [],
      workshopListLoading: false,
      lineList: [],
      lineListLoading: false,
      teamList: [],
      teamListLoading: false,
      boxList: [],
      storeList: [],
      codeList: [],
      searchForm: {
        batchNo: '',
        current: 1,
        endTime: '',
        factoryCode: '',
        inboundStatus: '',
        materialCode: '',
        materialName: '',
        orderNo: '',
        orderSource: '',
        orderStatus: '',
        pageNo: '',
        pageSize: '',
        productLineCode: '',
        shopCode: '',
        size: 20,
        startTime: '',
        storehouseCode: '',
        teamCode: ''
      },
      rules: {
        startTime: [{
          required: true,
          message: '请选择起始日期',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择结束日期',
          trigger: 'blur'
        }]
      },
      total: 0,
      codetotal: 0,
      tableData: [],
      tableKey: '01004',
      listLoading: false,
      codeListLoading: false,
      downLoading: false,
      mals: '',
      title: '生产订单详情',
      multipleSelection: [],
      scanData: {
        proOrderNum: '',
        sum: '',
        list: []
      },
      detailForm: {
        current: 1,
        size: 10,
        orderNo: ''
      },
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      apis: [{
        authBtn: true,
        resourceCode: 'SCDDCX'
      }],
      page2: false,
      tabHeight: '100%',
      submitType: ''
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.searchForm.orderStatus = 16;
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.getFactoryList(1, '', 'factoryList');
    this.getStoreList();
    this.getBoxList();
    this.getList();
  },
  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.labelType && row.batchNo) {
        return 'success-row';
      } else {
        return 'success-row';
      }
    },
    /**
     * 获取工厂、车间、产线、班组
     * @param {*} val 1:工厂,2:车间,3:产线,4:班组
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList(val, pId, list) {
      var _this = this;
      this[list + 'Loading'] = true;
      (0, _factory.factoryInfoAllList)({
        type: val,
        isEnable: 1,
        parentId: pId
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
          } else {
            _this[list] = [];
          }
        }
        _this[list + 'Loading'] = false;
      }).catch(function () {
        _this[list + 'Loading'] = false;
      });
    },
    getBoxList: function getBoxList() {
      var that = this;
      (0, _pack.supportBoxLevelList)({
        current: 1,
        size: 2147483647
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.boxList = response.data.data.records;
          } else {
            that.boxList = [];
          }
        }
      });
    },
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)().then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.pageNo = 1;
      that.searchForm.pageSize = 20;
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var that = this;
      that.listLoading = true;
      (0, _store.gettaskList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = '';
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      this.searchForm.storehouseCode = '';
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.workshopList = [];
      } else {
        this.getFactoryList(2, val, 'workshopList');
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
        this.getFactoryList(4, val, 'teamList');
      }
    },
    // 导出功能
    download: function download() {
      var _this3 = this;
      this.downLoading = true;
      (0, _store.taskexportExcel)(this.searchForm).then(function (res) {
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产任务单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.downLoading = false;
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;
      // if (!this.scanData.labelType) {
      //   this.$message.error('标签关联类型不能为空，请维护')
      //   return
      // }
      if (String(this.scanData.batchNo) == '') {
        this.$message.error('生产批次不能为空，请维护');
        return;
      }
      (0, _store.taskOrderupdate)({
        batchNo: this.scanData.batchNo,
        labelType: this.scanData.labelType,
        orderNo: this.scanData.orderNo
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          _this4.$message.success(res.data.msg);
          _this4.handleClose();
          _this4.getList();
        }
      });
    },
    scanDetail: function scanDetail(row, status) {
      this.submitType = status;
      this.scanData = JSON.parse(JSON.stringify(row));
      this.detailVisible = true;
    },
    codeDetail: function codeDetail() {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this5 = this;
      this.detailForm.orderNo = this.scanData.orderNo;
      (0, _store.markCodeList)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this5.codeList = res.data.data.records;
            _this5.codetotal = parseInt(res.data.data.total);
            setTimeout(function () {
              _this5.listLoading = false;
            }, 1 * 500);
          } else {
            _this5.codeList = [];
            _this5.codetotal = 0;
          }
        }
        _this5.codeListLoading = false;
      }).catch(function () {
        _this5.codeListLoading = false;
      });
    },
    qrCode: function qrCode(row) {
      var _this6 = this;
      (0, _store.generateQrcode)({
        orderNo: row.orderNo
      }).then(function (res) {
        var data = res.data;
        if (data.code != 200) {
          _this6.$message.error(data.msg);
        }
        _this6.$message({
          message: data.msg,
          type: 'success'
        });
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.detailVisible = false;
      that.$emit('dialogHide');
    },
    getDetail: function getDetail(row) {
      var that = this;
      that.$emit('select', JSON.stringify(row));
    }
  }
};
exports.default = _default;