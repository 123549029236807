"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWechat = addWechat;
exports.deleteProductAudio = deleteProductAudio;
exports.detail = detail;
exports.edit = edit;
exports.getAmountloglist = getAmountloglist;
exports.getAudioFileInfo = getAudioFileInfo;
exports.getBalance = getBalance;
exports.getEffectiveWechat = getEffectiveWechat;
exports.getProduct = getProduct;
exports.getV2Enterprise = getV2Enterprise;
exports.getV2Product = getV2Product;
exports.getWcList = getWcList;
exports.isAvailable = isAvailable;
exports.setConsum = setConsum;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
/* eslint-disable no-unused-vars */

// 公众号列表
// var baseUrl = '/user'
var baseUrl = '';
function getWcList(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/list',
    method: 'POST',
    data: data
  });
}
// 设置为消费者公众号
function setConsum(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/isConsum',
    method: 'GET',
    params: params
  });
}
// 添加公众号配置
function addWechat(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/add',
    method: 'POST',
    data: data
  });
}
// 校验公众号名称/公众号原始ID/APPid是否存在
function isAvailable(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/isAvailable',
    method: 'GET',
    params: params
  });
}
// 公众号配置详情
function detail(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/detail',
    method: 'GET',
    params: params
  });
}
// 编辑公众号配置
function edit(data) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/edit',
    method: 'POST',
    params: data
  });
}
// 获取产品列表
function getProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/product/getProduct',
    method: 'GET',
    params: params
  });
}
// 获取ar音频信息
function getAudioFileInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/ar/getAudioFileInfo',
    method: 'GET',
    params: params
  });
}
// 删除产品语音
function deleteProductAudio(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/ar/deleteProductAudio',
    method: 'POST',
    params: params
  });
}
// 获取v2企业列表
function getV2Enterprise(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/product/getV2Enterprise',
    method: 'GET',
    params: params
  });
}
// 获取v2产品列表
function getV2Product(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/product/getV2Product',
    method: 'GET',
    params: params
  });
}

// 获取有效的公众号

function getEffectiveWechat(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/getEffectiveWechat',
    method: 'GET',
    params: params
  });
}

// /wechat/official/balance
// 企业资金余额
function getBalance(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/balance',
    method: 'GET',
    params: params
  });
}
// 企业资金流水
function getAmountloglist(params) {
  return (0, _request.default)({
    url: baseUrl + '/uspyx/wechat/official/amountloglist',
    method: 'POST',
    data: params
  });
}