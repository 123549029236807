"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _tinymce = _interopRequireDefault(require("tinymce"));
var _integralMall = require("@/api/integral/integralMall");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
// import Editor from '@tinymce/tinymce-vue'
// import 'tinymce/themes/silver/theme'
// import 'tinymce/plugins/image' // 插入上传图片插件
// import 'tinymce/plugins/media' // 插入视频插件
// import 'tinymce/plugins/table' // 插入表格插件
// import 'tinymce/plugins/lists' // 列表插件
// import 'tinymce/plugins/wordcount' // 字数统计插件
var _default = {
  props: {
    value: {
      type: String,
      default: ''
    },
    tinymceHeight: {
      default: 500,
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // components: { Editor },
  data: function data() {
    var tinymceId = 'tinymce' + (0, _utils.getUUID)();
    return {
      // initConfig: {
      //   language_url: '/tinymce/langs/zh_CN.js',
      //   language: 'zh_CN',
      //   skin_url: '/tinymce/skins/ui/oxide',
      //   // skin_url: '/tinymce/skins/ui/oxide-dark',//暗色系
      //   height: 300,
      //   plugins: this.plugins,
      //   toolbar: this.toolbar,
      //   branding: false,
      //   menubar: false,
      //   // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
      //   // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
      //   images_upload_handler: (blobInfo, success, failure) => {
      //     const img = 'data:image/jpeg;base64,' + blobInfo.base64()
      //     success(img)
      //   }
      // },
      // myValue: this.value,
      flag: true,
      tinymceId: tinymceId,
      myEditor: null
    };
  },
  watch: {
    value: function value(val) {
      if (this.flag) {
        this.setContent(val);
      }
      this.flag = true;
    }
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    _tinymce.default.remove("#".concat(this.tinymceId));
  },
  deactivated: function deactivated() {
    _tinymce.default.remove("#".concat(this.tinymceId));
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    // 可以添加一些自己的自定义事件，如清空内容
    clear: function clear() {
      this.myValue = '';
    },
    init: function init() {
      var _this = this;
      var self = this;
      this.Editor = _tinymce.default.init((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.getDefalutConfig()), {}, {
        // 图片上传
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          if (blobInfo.blob().size > self.maxSize) {
            failure('文件体积过大');
          }
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          (0, _integralMall.uploadImg)(formData).then(function (data) {
            console.log(data);
            if (data.status === 200) {
              success(data.data.data);
            } else {
              _this.$message.closeAll();
              _this.$message({
                message: data.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          }).catch(function () {
            failure('服务器出了点小差');
          });
          // this.$http({
          //   url: this.$http.adornUrl(`/admin/file/upload/tinymceEditor`),
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //     token: this.$cookie.get('token')
          //   },
          //   method: 'post',
          //   data: formData
          // })
          //   .then(data => {
          //     success(data)
          //   })
          //   .catch(() => {
          //     failure('服务器出了点小差')
          //   })
        }
      }, this.config), {}, {
        // 挂载的DOM对象
        selector: "#".concat(this.tinymceId),
        setup: function setup(editor) {
          self.myEditor = editor;
          editor.on('init', function () {
            self.loading = false;
            if (self.value) {
              editor.setContent(self.value);
            } else {
              editor.setContent('');
            }
          });
          editor.on('change', function (e) {
            console.log('change event', e);
          });
          // 抛出 'input' 事件钩子，同步value数据
          editor.on('input change undo redo execCommand', function () {
            self.flag = false;
            self.$emit('input', editor.getContent());
          });
        }
      }));
    },
    getDefalutConfig: function getDefalutConfig() {
      if (this.$route.query.disabled) {
        // eslint-disable-next-line eqeqeq
        var readonly = JSON.parse(this.$route.query.disabled) == true ? 1 : 0;
      }
      return {
        // GLOBAL
        height: this.tinymceHeight,
        theme: 'modern',
        readonly: readonly,
        language: 'zh_CN',
        toolbar: "styleselect | fontselect | formatselect | fontsizeselect | forecolor backcolor | bold italic underline strikethrough | insertfile link image | table | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | preview removeformat  hr | paste code | undo redo  ",
        // 需要的工具栏 | fullscreen 全屏
        plugins: "\n            paste\n            importcss\n            image\n            code\n            table\n            advlist\n            fullscreen\n            link\n            media\n            lists\n            textcolor\n            colorpicker\n            hr\n            preview\n          ",
        // CONFIG
        forced_root_block: 'p',
        force_p_newlines: true,
        importcss_append: true,
        relative_urls: false,
        remove_script_host: false,
        // CONFIG: ContentStyle 这块很重要， 在最后呈现的页面也要写入这个基本样式保证前后一致， `table`和`img`的问题基本就靠这个来填坑了
        content_style: "\n            *                         { padding:0; margin:0; }\n            html, body                { height:100%; }\n            img                       { max-width:100%; display:block;height:auto; }\n            a                         { text-decoration: none; }\n            iframe                    { width: 100%; }\n            p                         { line-height:1.6; margin: 0px; }\n            table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }\n            .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }\n            ul,ol                     { list-style-position:inside; }\n          ",
        insert_button_items: 'image link | inserttable',
        // CONFIG: Paste
        paste_retain_style_properties: 'all',
        paste_word_valid_elements: '*[*]',
        // word需要它
        paste_data_images: false,
        // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false,
        // 插入word文档需要该属性
        paste_webkit_styles: 'all',
        paste_merge_formats: true,
        nonbreaking_force_tab: false,
        paste_auto_cleanup_on_paste: false,
        // CONFIG: Font
        fontsize_formats: '10px 11px 12px 14px 16px 18px 20px 24px',
        // CONFIG: StyleSelect
        style_formats: [{
          title: '首行缩进',
          block: 'p',
          styles: {
            'text-indent': '2em'
          }
        }, {
          title: '行高',
          items: [{
            title: '1',
            styles: {
              'line-height': '1'
            },
            inline: 'span'
          }, {
            title: '1.5',
            styles: {
              'line-height': '1.5'
            },
            inline: 'span'
          }, {
            title: '2',
            styles: {
              'line-height': '2'
            },
            inline: 'span'
          }, {
            title: '2.5',
            styles: {
              'line-height': '2.5'
            },
            inline: 'span'
          }, {
            title: '3',
            styles: {
              'line-height': '3'
            },
            inline: 'span'
          }]
        }],
        // FontSelect
        font_formats: "\n            \u5FAE\u8F6F\u96C5\u9ED1=\u5FAE\u8F6F\u96C5\u9ED1;\n            \u5B8B\u4F53=\u5B8B\u4F53;\n            \u9ED1\u4F53=\u9ED1\u4F53;\n            \u4EFF\u5B8B=\u4EFF\u5B8B;\n            \u6977\u4F53=\u6977\u4F53;\n            \u96B6\u4E66=\u96B6\u4E66;\n            \u5E7C\u5706=\u5E7C\u5706;\n            Andale Mono=andale mono,times;\n            Arial=arial, helvetica,\n            sans-serif;\n            Arial Black=arial black, avant garde;\n            Book Antiqua=book antiqua,palatino;\n            Comic Sans MS=comic sans ms,sans-serif;\n            Courier New=courier new,courier;\n            Georgia=georgia,palatino;\n            Helvetica=helvetica;\n            Impact=impact,chicago;\n            Symbol=symbol;\n            Tahoma=tahoma,arial,helvetica,sans-serif;\n            Terminal=terminal,monaco;\n            Times New Roman=times new roman,times;\n            Trebuchet MS=trebuchet ms,geneva;\n            Verdana=verdana,geneva;\n            Webdings=webdings;\n            Wingdings=wingdings,zapf dingbats",
        // Tab
        tabfocus_elements: ':prev,:next',
        object_resizing: true,
        // Image
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions'
      };
    },
    setContent: function setContent(content) {
      // if (!content) {
      //   return
      // }
      this.myEditor.setContent(content);
    },
    getContent: function getContent() {
      return this.myEditor.getContent();
    }
  }
};
exports.default = _default;