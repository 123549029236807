"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compyuser = require("@/api/systems/compyuser");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'allEdit',
  components: {
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      mloading: false,
      options: [],
      orgId: null,
      dstate: false
    };
  },
  props: {
    allEdit: Boolean
  },
  watch: {
    allEdit: function allEdit(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.orgId = null;
        that.options = that.$parent.options;
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.allEditShow = false;
      that.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var that = this;
      var data = {};
      data.ids = that.$parent.ids;
      data.orgId = that.orgId;
      that.mloading = true;
      that.dsate = true;
      (0, _compyuser.allEditData)(data).then(function (res) {
        if (res.data.code == 200) {
          that.mloading = false;
          that.dsate = false;
          that.$message({
            type: 'success',
            message: '批量修改成功！'
          });
          that.handleClose();
          that.$parent.getList();
        } else {
          that.$message.error(res.data.msg);
          that.mloading = false;
          that.dsate = false;
        }
      }).catch(function () {
        that.mloading = false;
        that.dsate = false;
      });
    },
    resetForm: function resetForm() {
      var that = this;
      that.handleClose();
    }
  }
};
exports.default = _default;