var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "checkgzh",
          attrs: { type: "flex", justify: "start", align: "cener;" },
        },
        [
          _c(
            "el-col",
            {
              staticClass: "gzhsz",
              staticStyle: { "text-align": "right" },
              attrs: { span: 8 },
            },
            [_vm._v("\n      切换公众号：\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "gzhbd",
                  attrs: { placeholder: "切换公众号" },
                  on: { change: _vm.checkopt },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.opts, function (item) {
                  return _c("el-option", {
                    key: item.appId,
                    attrs: {
                      label: item.appName,
                      value: item.appId,
                      disabled: item.disabled,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }