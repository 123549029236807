"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _lithService = require("@/api/lithService");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {},
  filters: {},
  data: function data() {
    return {
      tradeBillAO: {
        newMark: '',
        oldMark: ''
      },
      btn_loading: false,
      rules: {
        oldMark: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        newMark: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    if (this.$refs['tradeBillAO'] !== undefined) {
      this.$refs['tradeBillAO'].resetFields();
      this.$refs['tradeBillAO'].clearValidate();
    }
  },
  methods: {
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['tradeBillAO'].validate(function (valid) {
        if (valid) {
          (0, _lithService.replaceCode)((0, _objectSpread2.default)({}, _this.tradeBillAO)).then(function (res) {
            if (res.data.code != 200) {
              _this.$message.error(res.data.msg);
            } else {
              _this.$message({
                message: '保存成功',
                type: 'success'
              });
            }
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;