"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelCheckboxDialog = _interopRequireDefault(require("@/components/channelCheckboxDialog"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangerebateconfighdfl';
var _default = {
  name: 'chanelmangerebateconfighdfl',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    channelMultiSelect: _channelCheckboxDialog.default
  },
  data: function data() {
    var _ref;
    return _ref = {
      listQuery: {
        beginTime: '',
        endTime: '',
        orgList: [],
        productName: '',
        schemeCode: '',
        schemeName: '',
        schemeState: '',
        rebateOrGift: 1,
        schemeType: 0,
        current: 1,
        size: 20
      },
      statusOption: [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }],
      tabHeight: '400',
      tableKey: 'jxskc',
      list: [],
      listLoading: false,
      total: 0,
      orgOptions: [],
      visible: false,
      pid: '',
      showCol: false,
      expandTxt: '展开',
      selectedData: []
    }, (0, _defineProperty2.default)(_ref, "pid", ''), (0, _defineProperty2.default)(_ref, "insertVisible", false), (0, _defineProperty2.default)(_ref, "sHvisible", false), (0, _defineProperty2.default)(_ref, "shAuth", false), (0, _defineProperty2.default)(_ref, "type", 'add'), (0, _defineProperty2.default)(_ref, "blackVisible", false), (0, _defineProperty2.default)(_ref, "bloading", false), (0, _defineProperty2.default)(_ref, "bData", []), (0, _defineProperty2.default)(_ref, "initData", {}), (0, _defineProperty2.default)(_ref, "apis", []), _ref;
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    fliterState: function fliterState(value) {
      var name = '';
      var statusOption = [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }];
      statusOption.forEach(function (item) {
        if (item.id == value) {
          name = item.name;
          return;
        }
      });
      return name;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      var param = {};
      var current = that.listQuery.current;
      var size = that.listQuery.size;
      param.beginTime = that.listQuery.beginTime;
      param.endTime = that.listQuery.endTime;
      param.orgList = that.listQuery.orgList;
      param.productName = that.listQuery.productName;
      param.schemeCode = that.listQuery.schemeCode;
      param.schemeName = that.listQuery.schemeName;
      param.schemeState = that.listQuery.schemeState;
      param.schemeType = that.listQuery.schemeType;
      param.rebateOrGift = that.listQuery.rebateOrGift;
      that.listLoading = true;
      (0, _param.getFlHdList)(current, size, param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        beginTime: '',
        endTime: '',
        orgList: [],
        productName: '',
        schemeCode: '',
        schemeName: '',
        schemeState: '',
        schemeType: 0,
        rebateOrGift: 1,
        current: 1,
        size: 20
      };
      that.getList();
    },
    //多选
    handleSelectionChange: function handleSelectionChange(val) {
      var that = this;
      that.selectedData = val;
    },
    //添加
    DoAdd: function DoAdd() {
      var that = this;
      that.type = 'add';
      that.pid = '';
      that.$router.push({
        name: 'insertHdfl',
        params: {
          type: 'add',
          pid: '',
          orgOptions: that.orgOptions
        }
      });
    },
    //编辑
    DoEdit: function DoEdit(row) {
      var that = this;
      that.pid = row.pid;
      that.type = 'edit';
      that.$router.push({
        name: 'insertHdfl',
        params: {
          type: 'edit',
          pid: row.pid,
          orgOptions: that.orgOptions
        }
      });
    },
    //终止
    DoStop: function DoStop() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var data = that.selectedData;
      if (data.length > 0) {
        var ids = '';
        var arr = [];
        data.forEach(function (item) {
          arr.push(item.pid);
        });
        ids = arr.join(',');
        console.log(ids);
        that.$confirm('是否要终止？', '确认消息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function () {
          (0, _param.stopHdList)({
            ids: ids
          }).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '终止成功'
              });
              that.getList();
            } else {
              that.$message.error(res.data.msg);
            }
          });
        }).catch(function () {});
      } else {
        that.$message.error('请选择要终止的项');
        return;
      }
    },
    //查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.pid = data.pid;
      that.$router.push({
        name: 'detailsHdfl',
        params: {
          pid: data.pid
        }
      });
    },
    closeComponent: function closeComponent() {
      var that = this;
      that.visible = false;
    },
    //审核
    DoSh: function DoSh(data) {
      var that = this;
      that.sHvisible = true;
      that.pid = data.pid;
      that.$router.push({
        name: 'hdflSh',
        params: {
          pid: data.pid
        }
      });
    },
    //添加黑名单
    addBlack: function addBlack(val) {
      var that = this;
      var setHeaderId = that.authId('insertbtn', that.apis);
      that.pid = val.pid;
      (0, _param.getBlackList)({
        pid: val.pid
      }).then(function (res) {
        if (res.data.code == 200) {
          that.selectedData = res.data.data;
          that.$refs.chanel.proSelVisible = true;
        } else {
          that.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {});
    },
    saveBlackParam: function saveBlackParam(val) {
      var that = this;
      var submitData = {};
      var list = [];
      if (val.length > 0) {
        val.forEach(function (item) {
          var obj = {};
          obj.channelArchiveId = item.pId;
          obj.channelType = item.channelType;
          obj.schemeId = that.pid;
          list.push(obj);
        });
      }
      submitData.list = list;
      submitData.schemeId = that.pid;
      (0, _param.saveBlackList)(submitData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '保存成功'
          });
          that.getList();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;