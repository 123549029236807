"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _outstock = require("@/api/chanelmange/outstock");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _personChose = _interopRequireDefault(require("./component/personChose"));
var _purchase = require("@/api/chanelmange/purchase");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var cachName = 'orderCenterchannelOrdersydglindex';
var _default = {
  name: 'orderCenterchannelOrdersydglindex',
  components: {
    Pagination: _Pagination.default,
    purchaseDialog: _purchaseDialog.default,
    Treeselect: _vueTreeselect.default,
    personChose: _personChose.default
  },
  filters: {
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: 'success',
        6: 'danger',
        7: 'danger',
        8: '',
        9: 'success',
        10: 'danger'
      };
      return obj[val];
    },
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    purchaseComType: function purchaseComType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          4: '终端',
          3: '经销商',
          2: '销售公司',
          1: '生产公司'
        };
        return statusMap[value];
      }
    },
    creatorType: function creatorType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          3: '业务员',
          2: '终端',
          1: '经销商'
        };
        return statusMap[value];
      }
    }
  },
  data: function data() {
    var _listQuery;
    return {
      unitType: '',
      orderTime: '',
      businessUser: '',
      listQuery: (_listQuery = {
        current: 1,
        creatorId: '',
        size: 20,
        beginDate: null,
        businessType: 2,
        endDate: null
      }, (0, _defineProperty2.default)(_listQuery, "endDate", null), (0, _defineProperty2.default)(_listQuery, "orderNo", ''), (0, _defineProperty2.default)(_listQuery, "purchaseOrgId", ''), (0, _defineProperty2.default)(_listQuery, "purchaseOwnerOrgId", null), (0, _defineProperty2.default)(_listQuery, "saleOrgId", ''), (0, _defineProperty2.default)(_listQuery, "tradeStatus", ''), (0, _defineProperty2.default)(_listQuery, "tradeType", 1), _listQuery),
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      orgOptions: [],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      downLoading: false,
      listLoading: false,
      exportLoading: false,
      total: 0,
      personShow: false,
      purchaseOrgName: '',
      saleOrgName: '',
      purchaseComName: '',
      receiveComName: '',
      sendOutComName: '',
      title: '采购订单',
      dialogVisible: false,
      detailShow: false,
      loading: false,
      subType: '',
      showCol: false,
      expandTxt: '展开',
      addData: {},
      productList: [],
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getDirSel('1268714597296320514', 'orderStatus');
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getDirSel: function getDirSel(pid, list) {
      var _this = this;
      (0, _dictionary.getDataDictionaryItemList)({
        isEnable: 1,
        pid: pid,
        current: 1,
        size: 2112211
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data.records;
            TypeDataComs[list] = res.data.data.records;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 供货商为企业时，选择组织机构
    getOrg: function getOrg() {
      var that = this;
      var param = {
        orgTypeList: [1, 2, 3, 0],
        orgStatus: 1
      };
      (0, _outstock.getQueryOrg)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = JSON.parse(JSON.stringify(that.listQuery));
      param.beginDate = this.orderTime[0] || null;
      param.endDate = this.orderTime[1] || null;
      delete param.current;
      delete param.size;
      (0, _purchase.getYdList)(that.listQuery.current, that.listQuery.size, param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getUnit: function getUnit(val) {
      var _this2 = this;
      var that = this;
      that.unitType = val;
      this.$nextTick(function () {
        _this2.$refs.channelSelect.proSelVisible = true;
      });
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'in') {
        that.listQuery.purchaseOrgId = val.pId;
        that.purchaseOrgName = val.channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.saleOrgId = val.pId;
        that.saleOrgName = val.channelName;
      }
    },
    // 选择业务员
    chosePerson: function chosePerson() {
      var that = this;
      that.personShow = true;
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      that.listQuery.creatorId = val.pid;
      that.businessUser = val.userName;
      that.personShow = false;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var _this$listQuery;
      this.purchaseOrgName = '';
      this.saleOrgName = '';
      this.businessUser = '';
      this.orderTime = [];
      this.listQuery = (_this$listQuery = {
        current: 1,
        creatorId: '',
        size: 20,
        beginDate: null,
        businessType: 2,
        endDate: null
      }, (0, _defineProperty2.default)(_this$listQuery, "endDate", null), (0, _defineProperty2.default)(_this$listQuery, "orderNo", ''), (0, _defineProperty2.default)(_this$listQuery, "purchaseOrgId", ''), (0, _defineProperty2.default)(_this$listQuery, "purchaseOwnerOrgId", null), (0, _defineProperty2.default)(_this$listQuery, "saleOrgId", ''), (0, _defineProperty2.default)(_this$listQuery, "tradeStatus", ''), (0, _defineProperty2.default)(_this$listQuery, "tradeType", 1), _this$listQuery);
      this.getList();
    },
    // 查看
    DoScan: function DoScan(data) {
      var setHeaderId = this.authId('searchbtn', this.apis);
      var id = data.orderNo;
      this.$router.push({
        name: 'ydOrderDetail',
        query: {
          id: data.orderNo
        }
      });
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId('exportbtn', that.apis);
      var param = that.listQuery;
      var data = {};
      if (that.orderTime.length > 0) {
        param.beginDate = that.orderTime[0];
        param.endDate = that.orderTime[1];
      } else {
        param.beginDate = '';
        param.endDate = '';
      }
      delete param.current;
      delete param.size;
      that.exportLoading = true;
      (0, _purchase.exportYData)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '引单统计清单.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    }
  }
};
exports.default = _default;