"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompyUser = addCompyUser;
exports.allEditData = allEditData;
exports.checkEmail = void 0;
exports.checkOnly = checkOnly;
exports.checkPwd = exports.checkPhone = void 0;
exports.distributionUserRole = distributionUserRole;
exports.editUserInfo = editUserInfo;
exports.getAllQyRoles = getAllQyRoles;
exports.getCompanyList = getCompanyList;
exports.getDDSwitch = getDDSwitch;
exports.getDataAuthROrg = getDataAuthROrg;
exports.getQyOrg = getQyOrg;
exports.getRoleAuths = getRoleAuths;
exports.getRoleUser = getRoleUser;
exports.getStoreAuth = getStoreAuth;
exports.getStoreTable = getStoreTable;
exports.getUserInfo = getUserInfo;
exports.orgInfoAll = orgInfoAll;
exports.rankDataAuth = rankDataAuth;
exports.rankRoleAuths = rankRoleAuths;
exports.scanUserRole = scanUserRole;
exports.setUserPwd = setUserPwd;
exports.startItems = startItems;
exports.startUser = startUser;
exports.stopItems = stopItems;
exports.stopUser = stopUser;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取企业角色下拉列表
function getAllQyRoles() {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getAllQyRoles',
    method: 'get'
  });
}

// 所属企业组织
function getQyOrg() {
  var param = {
    orgName: ''
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/combox/queryAll',
    method: 'get',
    params: param
  });
}
function orgInfoAll(data) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}
// 获取企业用户列表
function getCompanyList(param, data) {
  return (0, _request.default)({
    url: '/api-user/userInfo/companylist?current=' + param.current + '&size=' + param.size,
    method: 'post',
    data: data
  });
}

// 添加企业用户
function addCompyUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/addUserInfo',
    method: 'post',
    params: param
  });
}

// 批量禁用
function stopItems(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateBatchEnableEnd',
    method: 'put',
    params: param
  });
}

// 批量启用
function startItems(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateBatchEnableStart',
    method: 'put',
    params: param
  });
}

// 获取企业用户角色
function getRoleUser(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/getQyForList',
    method: 'get',
    params: param
  });
}

// 企业用户分配角色
function distributionUserRole(data) {
  return (0, _request.default)({
    url: '/api-user/userRRole/distributionUserRole',
    method: 'post',
    data: data
  });
}

// 启用
function startUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateEnableStart',
    method: 'put',
    params: param
  });
}

// 禁用
function stopUser(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateEnableEnd',
    method: 'put',
    params: param
  });
}

// 查看企业用户角色
function scanUserRole(param) {
  return (0, _request.default)({
    url: '/api-user/userRRole/getUserRole',
    method: 'get',
    params: param
  });
}

// 重置企业用户密码
function setUserPwd(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/resettingPwd',
    method: 'put',
    params: param
  });
}

// 获取企业用户详情
function getUserInfo(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/getUserDetail',
    method: 'get',
    params: param
  });
}

// 修改企业用户
function editUserInfo(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updateUserInfo',
    method: 'put',
    params: param
  });
}

// 查看库房数据权限
function getStoreAuth(param) {
  return (0, _request.default)({
    url: '/api-user/userDataAuthRStore/getDataAuthRStore',
    method: 'get',
    params: param
  });
}

// 获取全部的角色权限
function getRoleAuths() {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryResourceList',
    method: 'get'
  });
}

// 分配权限
function rankRoleAuths(data) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/giveResourceQy',
    method: 'post',
    data: data
  });
}

// 获取库房列表
function getStoreTable(param, data) {
  return (0, _request.default)({
    url: '/api-basic/storeHouse/combox/queryStoreHouseByOrgId?current=' + param.current + '&size=' + param.size,
    method: 'post',
    data: data
  });
}

// 企业用户分配数据权限
function rankDataAuth(data) {
  return (0, _request.default)({
    url: '/api-user/userDataAuthROrg/saveDataAuth',
    method: 'post',
    data: data
  });
}

// 批量修改
function allEditData(param) {
  return (0, _request.default)({
    url: '/api-user/userROrg/updateOrAddBatchUserOrg',
    method: 'put',
    params: param
  });
}

// 查看组织结构数据权限
function getDataAuthROrg(param) {
  var param1 = {
    userId: param.id
  };
  return (0, _request.default)({
    url: '/api-user/userDataAuthROrg/queryAuthByUserId',
    method: 'get',
    params: param1
  });
}

// 检验手机号唯一性
function checkOnly(param) {
  return (0, _request.default)({
    url: '/api-workflow/userManager/has/mobile',
    method: 'post',
    params: param
  });
}

// 获取钉钉是否开启状态
function getDDSwitch() {
  return (0, _request.default)({
    url: '/api-user/itemConfig/getDingDingSwitch',
    method: 'get'
  });
}

// 验证规则
var checkPwd = function checkPwd(rule, value, callback) {
  var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/;
  if (value) {
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('密码必须为字母、数字组合，长度为8位'));
    }
  } else {
    return callback(new Error('请输入密码'));
  }
};
exports.checkPwd = checkPwd;
var checkPhone = function checkPhone(rule, value, callback) {
  if (value) {
    var reg = /^1[23456789]\d{9}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  } else {
    return callback(new Error('请输入手机号'));
  }
};
exports.checkPhone = checkPhone;
var checkEmail = function checkEmail(rule, value, callback) {
  if (value) {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确格式邮箱'));
    }
  } else {
    callback();
  }
};
exports.checkEmail = checkEmail;