"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _product = require("@/api/basic/product");
var _activityManage = require("@/api/market/activityManage");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TablePackset',
  components: {},
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: String,
      default: ''
    },
    isCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        orderNo: ''
      },
      tableData: [],
      packList: [],
      normalList: [],
      brandList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.setsel();
      }
    }
  },
  mounted: function mounted() {
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var that = this;
      // that.selLoading = true
      this.tableData = this.data;
      // setTimeout(() => {
      //   if (that.tableData.length) {
      //     that.$refs.multipleTable.toggleRowSelection(this.tableData[0])
      //   }
      // }, 500)
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var that = this;
      this.listLoading = true;
      var arr = [];
      var obj = this.tableData.find(function (el) {
        return el.orderNo == that.searchForm.orderNo;
      });
      arr.push(obj);
      that.tableData = arr;
      that.listLoading = false;
    },
    resetForm: function resetForm(formName) {
      this.searchForm.orderNo = '';
      this.tableData = this.data;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
    },
    submit: function submit() {
      var _this = this;
      this.$emit('change', this.multipleSelection);
      this.multipleSelection.forEach(function (el) {
        var obj = _this.tableData.findIndex(function (a) {
          return a == el;
        });
        _this.tableData.splice(obj, 1);
      });
      this.$emit('close');
    },
    tag1Close: function tag1Close(row) {
      var index = this.tableData.findIndex(function (item) {
        return item.pId == row.pId;
      });
      this.$refs.multipleTable.toggleRowSelection(this.tableData[index], false);
    }
  }
};
exports.default = _default2;