var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "formInline",
            staticClass: "search-condition",
            attrs: {
              model: _vm.formInline,
              "status-icon": "",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "抽奖时间:" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                clearable: "",
                              },
                              on: { change: _vm.sj1 },
                              model: {
                                value: _vm.rangeTime1,
                                callback: function ($$v) {
                                  _vm.rangeTime1 = $$v
                                },
                                expression: "rangeTime1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发奖时间:" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                clearable: "",
                              },
                              on: { change: _vm.sj2 },
                              model: {
                                value: _vm.rangeTime2,
                                callback: function ($$v) {
                                  _vm.rangeTime2 = $$v
                                },
                                expression: "rangeTime2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0", prop: "keyword" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "用户ID/数码" },
                              model: {
                                value: _vm.formInline.keyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "keyword",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formInline.keyword",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "规则类型:", prop: "ruleType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择规则类型",
                                },
                                model: {
                                  value: _vm.formInline.ruleType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "ruleType", $$v)
                                  },
                                  expression: "formInline.ruleType",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: { label: "活动规则", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "2",
                                  attrs: { label: "兑换规则", value: "2" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.search },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.reset },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "btnDivider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btnArea" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                    },
                    on: { click: _vm.allReplacement },
                  },
                  [_vm._v("全部补发")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.downLoadBtn.type,
                      size: _vm.btnConfig.size,
                    },
                    on: { click: _vm.exportLs },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: _vm.tabHeight,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              fit: "",
              "highlight-current-row": "",
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _vm._v(" "),
            _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "markCode",
                label: "参与数码",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "activityName",
                label: "活动/兑换规则名称",
                width: "180",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "ruleType",
                label: "类型",
                width: "100",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.ruleType == 1
                        ? _c("div", [
                            _vm._v("\n            活动规则\n          "),
                          ])
                        : scope.row.ruleType == 2
                        ? _c("div", [
                            _vm._v("\n            兑换规则\n          "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "wechatName",
                label: "用户",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "openId",
                label: "用户openID",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "userId",
                label: "用户ID",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "joinDateTime",
                label: "参与时间",
                width: "200",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("textDate")(scope.row.joinDateTime)) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "sendDateTime",
                label: "发奖时间",
                width: "200",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("textDate")(scope.row.drawTime)) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizePrice",
                label: "奖品金额(元)",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "wxMsg",
                label: "失败原因",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "操作",
                width: "100",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "a-btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClick(scope.row)
                            },
                          },
                        },
                        [_vm._v("重发")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.params.pageNo,
            limit: _vm.params.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.params, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.params, "pageSize", $event)
            },
            pagination: _vm.activityPrizeRecordPage,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }