"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _product = require("@/api/basic/product");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: '',
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      handleShow: false,
      listLoading: false,
      selLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        productClassName: '',
        classStatus: ''
      },
      tableData: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      channel: '',
      sourceData: []
    };
  },
  mounted: function mounted() {
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      // this.selLoading = true
      this.getList(true);
      // setTimeout(() => {
      //   if (this.tableData.length) {
      //     this.$refs.multipleTable.toggleRowSelection(this.tableData[0])
      //     this.$refs.multipleTable.toggleRowSelection(this.tableData[0])
      //   }
      //   this.selLoading = false
      // }, 500)
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList(isOverView) {
      var _this = this;
      var overView = isOverView == true ? true : false;
      var that = this;
      this.listLoading = true;
      var submitData = JSON.parse(JSON.stringify(that.searchForm));
      (0, _product.productClassList)(submitData).then(function (res) {
        if (res.data.code !== 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, 'pId', 'parentId', 'children');
            _this.setDisable(1, data, 3);
            that.sourceData = res.data.data;
            that.tableData = data;
          } else {
            that.tableData = [];
          }
        }
        _this.listLoading = false;
        if (!overView) return;
        _this.setTreeData();
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    setTreeData: function setTreeData() {
      var _this2 = this;
      this.data.map(function (row) {
        var rowItem = _this2.sourceData.find(function (item) {
          return row.pId == item.pId;
        });
        _this2.$refs.multipleTable.toggleRowSelection(rowItem || row, true);
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        productClassName: '',
        classStatus: ''
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 树
    setDisable: function setDisable(count, data, maxNum) {
      var _this3 = this;
      if (count > maxNum) {
        data.forEach(function (v) {
          v.disabled = true;
        });
      } else {
        data.forEach(function (v) {
          v.count = count; // 设置最外层数据的初始count
          if (v.children && v.children.length) {
            v.count++;
            _this3.setDisable(v.count, v.children, maxNum); // 子级循环时把这一层数据的count传入
          }
        });
      }
    },
    setChildren: function setChildren(children, type) {
      var _this4 = this;
      // 编辑多个子层级
      children.map(function (j) {
        _this4.toggleSelection(j, type);
        if (j.children) {
          _this4.setChildren(j.children, type);
        }
      });
    },
    // 选中父节点时，子节点一起选中取消
    select: function select(selection, row) {
      var hasSelect = selection.some(function (el) {
        return row.pId === el.pId;
      });
      if (hasSelect) {
        if (row.children) {
          // 解决子组件没有被勾选到
          this.setChildren(row.children, true);
        }
      } else {
        if (row.children) {
          this.setChildren(row.children, false);
        }
      }
    },
    toggleSelection: function toggleSelection(row, select) {
      var _this5 = this;
      if (row) {
        this.$nextTick(function () {
          _this5.$refs.multipleTable && _this5.$refs.multipleTable.toggleRowSelection(row, select);
        });
      }
    },
    // 选择全部
    selectAll: function selectAll(selection) {
      var _this6 = this;
      // tabledata第一层只要有在selection里面就是全选
      var isSelect = selection.some(function (el) {
        var tableDataIds = _this6.tableData.map(function (j) {
          return j.pId;
        });
        return tableDataIds.includes(el.pId);
      });
      // tableDate第一层只要有不在selection里面就是全不选
      var isCancel = !this.tableData.every(function (el) {
        var selectIds = selection.map(function (j) {
          return j.pId;
        });
        return selectIds.includes(el.pId);
      });
      if (isSelect) {
        selection.map(function (el) {
          if (el.children) {
            // 解决子组件没有被勾选到
            _this6.setChildren(el.children, true);
          }
        });
      }
      if (isCancel) {
        this.tableData.map(function (el) {
          if (el.children) {
            // 解决子组件没有被勾选到
            _this6.setChildren(el.children, false);
          }
        });
      }
    },
    selectionChange: function selectionChange(val) {
      this.multipleSelection = val;
    },
    //
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit('close');
    },
    submit: function submit() {
      this.$emit('change', this.multipleSelection);
      this.selCancel();
    },
    tag1Close: function tag1Close(row) {
      var _this7 = this;
      this.$nextTick(function () {
        var closeItem = _this7.sourceData.find(function (item) {
          return row.pId == item.pId;
        });
        _this7.$refs.multipleTable.toggleRowSelection(closeItem || row, false);
      });
    }
  }
};
exports.default = _default2;