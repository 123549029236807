"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  name: 'TablePackset',
  // components: { Pagination },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        2: '销售公司',
        3: '生产公司'
      };
      return statusMap[status];
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    canChange: {
      type: Boolean,
      default: true
    },
    companyType: {
      type: String,
      default: ''
    },
    orgId: {
      type: String,
      default: ''
    },
    isEnable: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      companyTypeOption: [{
        name: '销售公司',
        code: 0
      }, {
        name: '生产公司',
        code: 1
      }],
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        orgCodeAndName: '',
        orgStatus: this.isEnable,
        orgTypeList: this.companyType
      },
      tableData: [],
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(val) {
      var _this = this;
      var that = this;
      this.listLoading = true;
      var data = JSON.parse(JSON.stringify(this.searchForm));
      switch (this.searchForm.orgTypeList) {
        case 2:
          data.orgTypeList = ['2'];
          break;
        case 3:
          data.orgTypeList = ['3'];
          break;
        default:
          data.orgTypeList = ['2', '3'];
      }
      (0, _org.fetchOrgList)(data).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data;
            // that.total = Number(res.data.data.total)
          } else {
            that.tableData = [];
            // that.total = Number(res.data.data.total)
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        orgCodeAndName: '',
        orgStatus: this.isEnable,
        orgTypeList: this.companyType
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;