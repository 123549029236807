var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-form",
              {
                ref: "queryParams",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.queryParams,
                  "status-icon": "",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "兑换时间:" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetimerange",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                clearable: "",
                              },
                              on: { change: _vm.sj },
                              model: {
                                value: _vm.rangeTime,
                                callback: function ($$v) {
                                  _vm.rangeTime = $$v
                                },
                                expression: "rangeTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "openIdOrUserId",
                              "label-width": "0",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "input-with-select",
                              attrs: { placeholder: "openid/用户id" },
                              model: {
                                value: _vm.queryParams.openIdOrUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "openIdOrUserId",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryParams.openIdOrUserId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发奖状态:", prop: "sendStatus" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择领取状态",
                                },
                                model: {
                                  value: _vm.queryParams.sendStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "sendStatus", $$v)
                                  },
                                  expression: "queryParams.sendStatus",
                                },
                              },
                              [
                                _c("el-option", {
                                  key: "1",
                                  attrs: { label: "未发放", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "2",
                                  attrs: { label: "已发放", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "3",
                                  attrs: { label: "未核销", value: "3" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "4",
                                  attrs: { label: "已核销", value: "4" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  key: "5",
                                  attrs: { label: "失败", value: "5" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showCol && false
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "兑换产品:", prop: "prizeId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择奖项产品",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryParams.prizeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "prizeId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.prizeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.productList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.comBoxName,
                                          value: item.comBoxId,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "用户昵称:", prop: "nickName" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "input-with-select",
                                  attrs: { placeholder: "请输入数码" },
                                  model: {
                                    value: _vm.queryParams.nickName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "nickName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "queryParams.nickName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "奖品形式:",
                                  prop: "prizeType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择领取状态",
                                    },
                                    model: {
                                      value: _vm.queryParams.prizeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "prizeType",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.prizeType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "1",
                                      attrs: { label: "红包", value: "100" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      key: "2",
                                      attrs: { label: "实物", value: "200" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.querylist },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.resetWinningLottery },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.expand },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.expandTxt) +
                                    "\n                "
                                ),
                                _c("i", {
                                  class:
                                    _vm.showCol == false
                                      ? "el-icon-arrow-down"
                                      : "el-icon-arrow-up",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.formConfig.btnFollow
          ? _c("el-divider", { staticClass: "btnDivider" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "btnArea" }, [
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                  },
                  on: { click: _vm.exportLs },
                },
                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "exchangeRuleName",
                label: "兑换规则名称",
                width: "180",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "openid",
                label: "openid",
                width: "150",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "openid",
                label: "商务订单号",
                width: "220",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "disableStyle",
                          attrs: {
                            type: "text",
                            disabled: scope.row.sendStatus != 5,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.routerErrorPrize(
                                scope.row.mchBillNo,
                                scope.row.sendStatus != 5
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.mchBillNo))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizeName",
                label: "奖品名称",
                width: "180",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "words",
                label: "字卡",
                width: "150",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "nickName",
                label: "扫码用户",
                width: "120",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "memberId",
                label: "用户ID",
                width: "180",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "sendStatus",
                label: "发奖状态",
                width: "100",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.sendStatus == 1
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("未发放"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.sendStatus == 2
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("已发放"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.sendStatus == 3
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("未核销"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.sendStatus == 4
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("已核销"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.sendStatus == 5
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("失败"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizeType",
                label: "奖品形式",
                width: "100",
                align: _vm.tableConfig.align,
                formatter: _vm.showPrizeType,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prizeName",
                label: "奖项",
                width: "180",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "winMoney",
                label: "中奖金额(元)",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "exchangeTime",
                label: "兑换时间",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.current,
            limit: _vm.size,
          },
          on: {
            "update:page": function ($event) {
              _vm.current = $event
            },
            "update:limit": function ($event) {
              _vm.size = $event
            },
            pagination: _vm.getExchangeRecordPage,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }