"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      formInline: {
        options: [{
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则'
          }, {
            value: 'zhinan',
            label: '状元郎'
          }]
        }, {
          value: 'zhuangyuanlang',
          label: '状元郎',
          children: [{
            value: 'yuan',
            label: '20元'
          }, {
            value: 'zhinan',
            label: '状元郎'
          }]
        }],
        time: ''
      }
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      console.log('submit!');
    },
    initMyChart: function initMyChart() {
      var myChart = _echarts.default.init(document.getElementById('bar'));
      var option = {
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          data: ['销售1部', '销售1部', '销售1部', '销售1部', '销售1部', '销售1部', '销售1部', '销售1部']
        },
        yAxis: {
          type: 'value',
          show: true,
          scale: false,
          name: '单位：万',
          //  interval : 1000,
          // min:0,
          // max:3000,
          // axisLabel: {
          //   formatter: function(val) {
          //     console.log(val)
          //     return val/10000
          //   }
          // },
          axisTick: {
            show: false
          }
          // data: [0,500,1000,1500,2000,2500,3000]
        },

        grid: {
          top: '20%',
          left: '10%',
          bottom: 30,
          right: '10%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {// 坐标轴指示器，坐标轴触发有效
            // type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: ['直接访问', '邮件营销']
        },
        series: [{
          data: [55000, 13300, 12100, 9100, 8100, 6100, 5100, 3100],
          type: 'bar',
          name: '直接访问',
          barWidth: 30
        }
        // {
        //     data: [55000, 13300, 12100, 9100, 8100, 6100, 5100,3100],
        //     type: 'bar',
        //     name:'邮件营销',
        //     barWidth: 30
        // }
        ]
      };

      myChart.setOption(option);
    }
  },
  mounted: function mounted() {
    this.initMyChart();
  }
};
exports.default = _default;