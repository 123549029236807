"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'edit',
  components: {},
  data: function data() {
    return {
      formData: {
        methodDesc: '',
        methodKey: '',
        methodPath: '',
        pid: ''
      },
      rules: {
        methodKey: [{
          required: true,
          message: '请输入API资源ID',
          trigger: 'blur'
        }]
      },
      mloading: false
    };
  },
  props: {
    dialogVisible: Boolean,
    name: String
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var that = this;
      if (val == true) {
        that.getDetail();
      }
    }
  },
  methods: {
    getDetail: function getDetail() {
      var that = this;
      that.formData.methodDesc = '';
      that.formData.methodKey = '';
      that.formData.methodPath = '';
      that.formData.pid = '';
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          //给header传参
          var pid = that.authId(that.name, that.$parent.apis);
          that.mloading = true;
          var param = that.formData;
          (0, _work.insertApi)(param).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '添加成功'
              });
              that.mloading = false;
              that.resetForm('formData');
              that.$parent.getList();
            } else {
              that.mloading = false;
              that.$message.error(res.data.msg);
            }
          }).catch(function () {
            that.mloading = false;
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.handleClose();
    },
    handleClose: function handleClose() {
      var that = this;
      that.formData.methodDesc = '';
      that.formData.methodKey = '';
      that.formData.methodPath = '';
      that.formData.pid = '';
      that.$emit("close");
    }
  }
};
exports.default = _default;