"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// filterKeyNull
var _default = {
  name: 'LooseteaPurchasingpurchasingStorageindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      multipleSelection: [],
      listLoading: false,
      tableData: [],
      tabHeight: '100%',
      ruleForm: {
        baseWineCode: '',
        baseWineName: '',
        isEnable: ''
      },
      ruleForm2: {
        a: '',
        b: '',
        c: '',
        d: '',
        e: '',
        f: ''
      },
      imageUrl: '',
      imgloading: false,
      sourceImages: '',
      disabled: false,
      rules: {
        a: [{
          required: true,
          message: '不能为空，请维护',
          trigger: 'change'
        }],
        b: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        c: [{
          required: true,
          message: '不能为空，请维护',
          trigger: 'change'
        }],
        d: [{
          required: true,
          message: '不能为空，请维护',
          trigger: 'change'
        }],
        e: [{
          required: true,
          message: '请上传产品图片',
          trigger: 'change'
        }]
      },
      total: 0,
      current: 1,
      size: 10,
      title: '',
      dialogFormVisible: false,
      scanVisible: false,
      mloading: false,
      lookList: {}
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 列表
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      //   var searchData = filterKeyNull(that.ruleForm)
      this.tableData = [{
        a: 'xx品种毛茶',
        b: 'yl001',
        c: '辅料',
        d: '吨',
        e: '3',
        f: '外部',
        g: 'cz0001',
        h: '张三',
        j: 'xx仓库',
        k: '2021-01-01',
        l: '供货商123',
        m: '在途',
        n: '李四'
      }, {
        a: 'xx品种毛茶',
        b: 'yl001',
        c: '辅料',
        d: '吨',
        e: '3',
        f: '内部',
        g: 'cz0001',
        h: '张三',
        j: 'xx仓库',
        k: '2021-01-01',
        l: '供货商123',
        m: '在途',
        n: '李四'
      }];
      that.total = 1;
      that.listLoading = false;
    },
    // 重置搜索
    resetForm: function resetForm() {
      this.$refs['ruleForm'].resetFields();
      this.current = 1;
      this.getList();
    },
    // 上传照片
    beforeAvatarUpload: function beforeAvatarUpload(param) {
      var _this2 = this;
      var file = param.file;
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;
      var _this = this;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 PNG/JPG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 500kb!');
        return false;
      }
      this.imgloading = true;
      var formData = new FormData();
      formData.append('file', file);
      (0, _user.imgupload)(formData).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this.imageUrl = data.data;
        _this2.imgloading = false;
      }).catch(function () {
        _this2.imgloading = false;
      });
      return isJPG && isLt2M;
    },
    // 弹窗确认
    submitForm2: function submitForm2(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          //   var submitData = filterKeyNull(this.ruleForm2)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 弹窗重置
    resetForm2: function resetForm2(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    // 添加
    addE: function addE() {
      this.title = '新增采购入库';
      this.dialogFormVisible = true;
    },
    // 勾选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection, '勾选');
    },
    // 查看
    look: function look(row) {
      console.log(row, '查看');
      this.scanVisible = true;
      this.lookList = row;
    },
    // 关闭查看
    handleClose: function handleClose() {
      this.scanVisible = false;
    }
  }
};
exports.default = _default;