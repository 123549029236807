"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _externalGamesConfig = require("@/api/market/externalGamesConfig");
var _selectAct = _interopRequireDefault(require("./selectAct"));
var _selectGame = _interopRequireDefault(require("./selectGame"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "creat",
  components: {
    selectAct: _selectAct.default,
    selectGame: _selectGame.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      sourceList: [],
      form: {
        activityIds: '',
        gameName: '',
        gameId: "",
        gameExplain: ""
      },
      rules: {
        activityIds: [{
          required: true,
          message: "请选择关联活动ID",
          trigger: "change"
        }],
        gameName: [{
          required: true,
          message: '请输入赛事名称',
          trigger: 'change'
        }],
        gameId: [{
          required: true,
          message: "请输入赛事ID",
          trigger: "change"
        }],
        gameExplain: [{
          required: true,
          message: "请输入赛事说明",
          trigger: "change"
        }]
      },
      btnLoading: false,
      isShowSelectAct: false,
      isShowSelectGame: false
    };
  },
  computed: {
    isDisable: function isDisable() {
      return this.title == '详情' ? true : false;
    }
  },
  methods: {
    handleClickActID: function handleClickActID() {
      console.log('1');
      this.isShowSelectAct = true;
    },
    handleClickGame: function handleClickGame() {
      this.isShowSelectGame = true;
    },
    close: function close() {
      this.isShowSelectAct = false;
      this.isShowSelectGame = false;
    },
    handleSave: function handleSave() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.btnLoading = true;
          (0, _externalGamesConfig.gameConfigSave)(_this.form).then(function (res) {
            if (res.data.data.errCode) {
              _this.btnLoading = false;
              return _this.$message.error(res.data.data.errMsg);
            } else {
              _this.btnLoading = false;
              _this.$message.success('新增成功');
              _this.$emit("close");
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$emit("close");
    },
    selectActID: function selectActID(data) {
      this.form.activityIds = data;
      this.close();
    },
    selectDataGame: function selectDataGame(data) {
      this.form.gameName = data.gameName;
      this.form.gameId = data.id;
      this.close();
    }
  }
};
exports.default = _default;