"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
require("echarts/map/js/china.js");
//
//
//
//
// 引入中国地图数据
// const animationDuration = 6000
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    tipName: {
      type: String,
      default: ''
    },
    fontSize: {
      type: Number,
      default: 12
    },
    layoutSize: {
      type: String,
      default: '90%'
    },
    layoutCenter: {
      type: Array,
      default: function _default() {
        return ['50%', '50%'];
      }
    },
    isClick: {
      type: Boolean,
      default: false
    },
    getCityData: {
      type: Function,
      default: function _default() {}
    },
    tooltipType: {
      type: String,
      default: '1'
    }
  },
  data: function data() {
    return {
      loading: true,
      chart: null,
      mapData: [],
      max: 50,
      provinceList: []
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      this.loading = false;
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        title: {
          text: '',
          textStyle: {
            fontSize: 30
          },
          x: 'center'
        },
        tooltip: {
          show: true,
          padding: [10, 10, 10, 20],
          formatter: function formatter(params) {
            if (params.data) {
              if (_this.tooltipType === '1') {
                return params.name + '：<br/>' + params.data.value;
              } else {
                return '疑似流入' + params.name + '：<br/>' + params.data.value + ' 个窜货商品';
              }
            } else {
              return '';
            }
          }
        },
        visualMap: {
          type: 'continuous',
          text: ['', ''],
          showLabel: true,
          seriesIndex: [0],
          min: 0,
          max: 1000,
          itemWidth: 12,
          itemHeight: 100,
          inRange: {
            color: ['#ebfafb', '#1E9FFF']
          },
          textStyle: {
            color: '#000'
          },
          bottom: 0,
          left: 'left'
        },
        geo: {
          roam: false,
          map: 'china',
          left: '20',
          right: '300',
          layoutCenter: this.layoutCenter,
          layoutSize: this.layoutSize,
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            emphasis: {
              areaColor: '#ebfafb'
            }
          },
          regions: [{
            name: '南海诸岛',
            value: 0,
            itemStyle: {
              normal: {
                opacity: 0,
                label: {
                  show: false
                }
              }
            }
          }]
        },
        series: [{
          name: 'mapSer',
          type: 'map',
          roam: false,
          geoIndex: 0,
          label: {
            show: false
          },
          data: this.mapData,
          zlevel: 10
        }]
      });
      this.chart.on('click', function (params) {
        if (_this.isClick) {
          // console.log(333333333333)
          _this.getCityData(params.data);
        }
      });
    }
  }
};
exports.default = _default2;