"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      value: [],
      isShowTagInput: false,
      addTagInput: {
        propName: '',
        selectValues: []
      },
      tagItemInputs: [],
      // sku的标记
      // tags: [],
      // 数据库中的规格
      dbTags: [],
      // 根据选定的规格所查询出来的规格值
      dbTagValues: [],
      specs: [],
      // 使用的规格
      initing: false
    };
  },
  // props: {
  //   tags: { // sku的标记
  //     default: [],
  //     type: Array
  //   }
  // },
  computed: {
    // 未使用的规格, 通过计算属性计算
    unUseTags: function unUseTags() {
      var _this = this;
      var res = [];
      var _loop = function _loop() {
        var dbTag = _this.dbTags[i];
        var specIndex = _this.skuTags.findIndex(function (tag) {
          return tag.tagName === dbTag.propName;
        });
        if (specIndex === -1) {
          res.push(dbTag);
        }
      };
      for (var i = 0; i < this.dbTags.length; i++) {
        _loop();
      }
      return res;
    },
    skuTags: {
      get: function get() {
        return this.$store.state.prod.skuTags;
      },
      set: function set(val) {
        this.$store.commit('prod/updateSkuTags', val);
      }
    },
    defalutSku: function defalutSku() {
      return this.$store.state.prod.defalutSku;
    }
  },
  watch: {
    skuTags: {
      handler: function handler(val, oldVal) {
        var _this2 = this;
        if (this.initing) {
          this.initing = false;
          return;
        }
        var skuList = [];
        var tempSkuList = [];
        val.forEach(function (tag) {
          if (skuList.length === 0) {
            tag.tagItems.forEach(function (tagItem) {
              var sku = Object.assign({}, _this2.defalutSku);
              sku.properties = "".concat(tag.tagName, ":").concat(tagItem); // 销售属性组合字符串
              skuList.push(sku);
            });
          } else {
            tempSkuList = [];
            tag.tagItems.forEach(function (tagItem) {
              skuList.forEach(function (sku) {
                var newSku = Object.assign({}, sku);
                newSku.properties = "".concat(sku.properties, ";").concat(tag.tagName, ":").concat(tagItem);
                tempSkuList.push(newSku);
              });
            });
            skuList = tempSkuList;
          }
        });
        if (!skuList.length) {
          skuList.push(Object.assign({}, this.defalutSku));
        }
        // debugger
        this.$emit('change', skuList);
      },
      deep: true
    }
  },
  created: function created() {
    var _this3 = this;
    (0, _wXintegralMall.listSpec)().then(function (data) {
      var data = data.data;
      _this3.dbTags = data;
    });
  },
  methods: {
    init: function init(skuList) {
      var _this4 = this;
      this.value = skuList;
      if (!skuList || !skuList.length) {
        this.$emit('change', [Object.assign({}, this.defalutSku)]);
        return;
      }
      this.initing = true;
      var skuTags = [];
      for (var i = 0; i < skuList.length; i++) {
        var sku = skuList[i];
        if (!sku.properties) break;
        var propertiesArray = sku.properties.split(';');
        var _loop2 = function _loop2() {
          var cnProperties = propertiesArray[j].split(':');
          if (!skuTags[j]) {
            skuTags[j] = {
              tagName: cnProperties[0],
              tagItems: []
            };
            _this4.tagItemInputs.push({
              visible: false,
              value: ''
            });
          }
          var tagItemNameIndex = skuTags[j].tagItems.findIndex(function (tagItemName) {
            return tagItemName === cnProperties[1];
          });
          if (tagItemNameIndex === -1) {
            skuTags[j].tagItems.push(cnProperties[1]);
          }
        };
        for (var j in propertiesArray) {
          _loop2();
        }
      }
      this.skuTags = skuTags;
    },
    inselect: function inselect(val) {
      console.log(val.length);
      console.log(val);
      if (val.length > 9) {
        this.$message('不能超过10个规格值');
        val = val.splice(9, val.length - 10);
        return false;
      }
      val.forEach(function (item, i) {
        console.log(item);
        console.log(item.length);
        if (item.length > 10) {
          console.log(item.substring(0, 10));
          val[i] = item.substring(0, 10);
        }
      });
      // if (val[0].length > 10) {
      //   val[0] = val[0].substring(0, 10)
      // }
    },
    // 显示规格名、规格值输入框
    shopTagInput: function shopTagInput() {
      this.isShowTagInput = true;
    },
    // 隐藏规格名、规格值输入框
    hideTagInput: function hideTagInput() {
      this.isShowTagInput = false;
      this.cleanTagInput();
    },
    addTag: function addTag() {
      var selectValues = this.addTagInput.selectValues;
      if (!this.addTagInput.propName) {
        this.$message.error('请输入规格名');
        return;
      }
      if (!selectValues.length) {
        this.$message.error('请输入规格值');
        return;
      }
      this.isShowTagInput = false;
      var tagItems = [];
      for (var i = 0; i < selectValues.length; i++) {
        var element = selectValues[i];
        tagItems.push(element);
      }
      // 向规格中放入规格输入框内的数据
      this.$store.commit('prod/addSkuTag', {
        tagName: this.addTagInput.propName,
        tagItems: tagItems
      });
      this.cleanTagInput();
    },
    // 清除规格值输入框内容
    cleanTagInput: function cleanTagInput() {
      this.addTagInput = {
        propName: '',
        selectValues: []
      };
    },
    // 规格名输入框，选中规格事件
    handleTagClick: function handleTagClick() {
      var _this5 = this;
      // 清空规格值输入框
      this.dbTagValues = [];
      this.addTagInput.selectValues = [];
      // 判断规格名输入的值是否为数据库中已有的值
      var specsIndex = this.dbTags.findIndex(function (spec) {
        return spec.propName === _this5.addTagInput.propName;
      });
      // 如果不是，则说明为用户随便输入
      if (specsIndex === -1) return;
      // 如果数据库已有该规格名，则获取根据id获取该规格名称含有的规格值
      (0, _wXintegralMall.listSpecValue)(this.dbTags[specsIndex].propId).then(function (data) {
        var data = data.data;
        _this5.dbTagValues = data;
      });
    },
    // 关闭标签
    handleTagClose: function handleTagClose(tagIndex, tagItemIndex) {
      if (this.skuTags[tagIndex].tagItems.length === 1) {
        return;
      }
      this.$store.commit('prod/removeSkuTagItem', {
        tagIndex: tagIndex,
        tagItemIndex: tagItemIndex
      });
    },
    // 标签输入框确定时调用
    handleInputConfirm: function handleInputConfirm(tagIndex) {
      console.log(this.skuTags[tagIndex].tagItems);
      var itemlist = this.skuTags[tagIndex].tagItems;
      var tagItem = this.tagItemInputs[tagIndex].value;
      if (tagItem.length > 10) {
        return this.$message('长度在10个字符以内');
      }
      for (var i = 0; i < itemlist.length; i++) {
        if (itemlist.indexOf(tagItem) !== -1) {
          this.$message('请勿填写重复的规格！');
          return false;
        }
      }
      if (tagItem) {
        this.$store.commit('prod/addSkuTagItem', {
          tagIndex: tagIndex,
          tagItem: tagItem
        });
      }
      this.tagItemInputs[tagIndex].visible = false;
      this.tagItemInputs[tagIndex].value = '';
    },
    // 显示标签输入框
    showTagInput: function showTagInput(tagIndex) {
      var _this6 = this;
      // if (this.tag.tagItems.length > 10) {
      //   this.$message.error('不超过10个规格')
      //   return
      // }
      console.log(this.skuTags[tagIndex]);
      var sklist = this.skuTags[tagIndex].tagItems;
      if (sklist.length > 9) {
        this.$message('最多不超过10个规格');
        return false;
      }
      this.tagItemInputs.push({
        visible: false,
        value: ''
      });
      this.tagItemInputs[tagIndex].visible = true;
      this.$nextTick(function () {
        _this6.$refs["saveTagInput".concat(tagIndex)][0].$refs.input.focus();
      });
    },
    removeTag: function removeTag(tagIndex) {
      this.$store.commit('prod/removeSkuTag', tagIndex);
    }
  }
};
exports.default = _default;