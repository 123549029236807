var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c("div", { staticClass: "page-section" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "radio-group",
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.saveItem },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                _vm._l(_vm.radioData, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(0)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }