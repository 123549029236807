var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 3, offset: 21 } },
                [_c("admincut")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c("h3", { staticStyle: { "margin-left": "12px" } }, [
            _vm._v("新建群发消息"),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "群发名称：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "该名称不对外展示，不超过15个字",
                      maxlength: "15",
                      "show-word-limit": "",
                    },
                    on: { input: _vm.sdname },
                    model: {
                      value: _vm.sendsName,
                      callback: function ($$v) {
                        _vm.sendsName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "sendsName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送内容：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selecc",
                      attrs: { placeholder: "请选择消息模版" },
                      model: {
                        value: _vm.templateId,
                        callback: function ($$v) {
                          _vm.templateId = $$v
                        },
                        expression: "templateId",
                      },
                    },
                    _vm._l(_vm.tag, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.templateName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h4", { staticStyle: { "margin-left": "12px" } }, [
                _vm._v("\n        选择群发对象\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.labelFun },
                      model: {
                        value: _vm.sendsObj.bq,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "bq", $$v)
                        },
                        expression: "sendsObj.bq",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("按用户标签选择"),
                      ]),
                      _vm._v(" "),
                      [
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              title: "按用户标签选择",
                              "show-close": false,
                              visible: _vm.labelDialog && _vm.sendsObj.bq == 1,
                              width: "50%",
                            },
                            on: {
                              "update:visible": function ($event) {
                                return _vm.$set(
                                  _vm.labelDialog && _vm.sendsObj,
                                  "bq == 1",
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.labelCheckFun },
                                model: {
                                  value: _vm.labelOption,
                                  callback: function ($$v) {
                                    _vm.labelOption = $$v
                                  },
                                  expression: "labelOption",
                                },
                              },
                              _vm._l(_vm.labelOptions, function (item, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: item.tagName },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.closedDialog("no", 1)
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closedDialog("yes", 1)
                                      },
                                    },
                                  },
                                  [_vm._v("确 定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渠道来源：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.sourceFun },
                      model: {
                        value: _vm.sendsObj.qd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "qd", $$v)
                        },
                        expression: "sendsObj.qd",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("按渠道二维码选择"),
                      ]),
                      _vm._v(" "),
                      [
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              "show-close": false,
                              title: "按渠道二维码选择",
                              visible: _vm.sourceDialog && _vm.sendsObj.qd == 1,
                              width: "40%",
                            },
                            on: {
                              "update:visible": function ($event) {
                                return _vm.$set(
                                  _vm.sourceDialog && _vm.sendsObj,
                                  "qd == 1",
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.checkboxFun },
                                model: {
                                  value: _vm.sourceOption,
                                  callback: function ($$v) {
                                    _vm.sourceOption = $$v
                                  },
                                  expression: "sourceOption",
                                },
                              },
                              _vm._l(_vm.sourceOptions, function (item, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: item.codeName },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.closedDialog("no", 2)
                                      },
                                    },
                                  },
                                  [_vm._v("取 消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closedDialog("yes", 2)
                                      },
                                    },
                                  },
                                  [_vm._v("确 定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关注时间：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changsj },
                      model: {
                        value: _vm.sendsObj.gzsj,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "gzsj", $$v)
                        },
                        expression: "sendsObj.gzsj",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: "1" } },
                        [
                          _vm._v("少于等于"),
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.sendsObj.gzsj !== "1",
                              type: "number",
                              size: "mini",
                            },
                            on: { input: _vm.changemin },
                            model: {
                              value: _vm.smallnum,
                              callback: function ($$v) {
                                _vm.smallnum = $$v
                              },
                              expression: "smallnum",
                            },
                          }),
                          _vm._v("个月"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { attrs: { label: "2" } },
                        [
                          _vm._v("大于等于"),
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.sendsObj.gzsj !== "2",
                              type: "number",
                              size: "mini",
                            },
                            on: { input: _vm.changemax },
                            model: {
                              value: _vm.bignum,
                              callback: function ($$v) {
                                _vm.bignum = $$v
                              },
                              expression: "bignum",
                            },
                          }),
                          _vm._v("个月"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "互动频次：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendsObj.hdpc,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "hdpc", $$v)
                        },
                        expression: "sendsObj.hdpc",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("高频次（互动次数前20%为高频次）"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("中频次（互动次数前60%为中频次）"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("低频次"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "互动时间：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.hdTimeFun },
                      model: {
                        value: _vm.sendsObj.hdsj,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "hdsj", $$v)
                        },
                        expression: "sendsObj.hdsj",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("今天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("昨天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("近7天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("近15天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "5" } }, [
                        _vm._v("近30天"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "6" } }, [
                        _vm._v("自定义\n            "),
                        _vm.sendsObj.hdsj == 6
                          ? _c("span", { staticClass: "hdsj" }, [
                              _c("input", {
                                ref: "inputss",
                                staticStyle: { width: "100px" },
                                attrs: {
                                  placeholder: "请输入内容",
                                  type: "text",
                                },
                                on: { input: _vm.handleInput },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否是会员：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendsObj.sfshy,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "sfshy", $$v)
                        },
                        expression: "sendsObj.sfshy",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("否")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("是\n            "),
                        _vm.sendsObj.sfshy == "2"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  "margin-left": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    staticStyle: {
                                      border: "1px solid: #ccc",
                                      padding: "5px",
                                      display: "block",
                                    },
                                    model: {
                                      value: _vm.sendsObj.hyidList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sendsObj, "hyidList", $$v)
                                      },
                                      expression: "sendsObj.hyidList",
                                    },
                                  },
                                  _vm._l(_vm.viplist, function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        staticStyle: {
                                          display: "block",
                                          "margin-bottom": "5px",
                                        },
                                        attrs: { label: item.value },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "性别：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendsObj.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "sex", $$v)
                        },
                        expression: "sendsObj.sex",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("男")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("女")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "年龄：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendsObj.age,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendsObj, "age", $$v)
                        },
                        expression: "sendsObj.age",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("不限"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("0-20"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("21-30"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("31-40"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("41-50"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "5" } }, [
                        _vm._v("51以上"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择发送时间：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendsType,
                        callback: function ($$v) {
                          _vm.sendsType = $$v
                        },
                        expression: "sendsType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("立即发送"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("定时发送\n            "),
                        _vm.sendsType == "2"
                          ? _c(
                              "span",
                              {
                                staticClass: "timedet",
                                staticStyle: { "margin-left": "10px" },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "选择日期时间",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  model: {
                                    value: _vm.sendsTime,
                                    callback: function ($$v) {
                                      _vm.sendsTime = $$v
                                    },
                                    expression: "sendsTime",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("群发消息")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "temMsgg",
                            params: { ac: "1" },
                          })
                        },
                      },
                    },
                    [_vm._v("取消群发")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }