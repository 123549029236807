var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "globalRule",
            staticClass: "globalRule",
            attrs: {
              model: _vm.ruleData,
              rules: _vm.globalRule,
              "label-width": "200px",
            },
          },
          [
            _c("div", { staticClass: "itemTitle" }, [
              _vm._v("全局活动抽奖机制"),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "活动抽奖限制数量(人/天):",
                  prop: "limitNumber",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", align: "middle", gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c("el-input", {
                          directives: [{ name: "Int", rawName: "v-Int" }],
                          attrs: { maxlength: "6" },
                          model: {
                            value: _vm.ruleData.limitNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "limitNumber", $$v)
                            },
                            expression: "ruleData.limitNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "启用",
                            "inactive-value": "2",
                            "active-value": "1",
                          },
                          model: {
                            value: _vm.ruleData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "status", $$v)
                            },
                            expression: "ruleData.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.saveLoad, type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleSave("globalRule")
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }