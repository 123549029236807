var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orgId", label: "销售公司:" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  placeholder: "请选择...",
                                  options: _vm.orgOptions,
                                },
                                model: {
                                  value: _vm.listQuery.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orgId", $$v)
                                  },
                                  expression: "listQuery.orgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "is-required",
                                  attrs: {
                                    prop: "policyCode",
                                    label: "政策编号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.policyCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "policyCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.policyCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "policyName", label: "政策名称:" },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.policyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "policyName", $$v)
                                  },
                                  expression: "listQuery.policyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "policyType", label: "政策类型:" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.policyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "policyType", $$v)
                                    },
                                    expression: "listQuery.policyType",
                                  },
                                },
                                _vm._l(_vm.types, function (item) {
                                  return _c("el-option", {
                                    key: item.figure,
                                    attrs: {
                                      label: item.name,
                                      value: item.figure,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "开始日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      format: "yyyy 年 MM 月 dd 日",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.listQuery.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "endTime",
                                    label: "截止日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      format: "yyyy 年 MM 月 dd 日",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.listQuery.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "endTime", $$v)
                                      },
                                      expression: "listQuery.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.DoSearch },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-search" }),
                                  _vm._v(" 查询\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead operate-btns" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.DoAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.listQuery.current - 1) * _vm.listQuery.size +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "政策编码",
                  prop: "policyCode",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "政策名称",
                  prop: "policyName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "政策类型",
                  prop: "policyTypeName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "开始日期",
                  prop: "startTime",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "截止日期",
                  prop: "endTime",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "creatorName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新人",
                  prop: "updaterName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  prop: "updateTime",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.detail(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { staticClass: "table-dropdown" },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多\n              "),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setZc(row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.DoScan(row)
                                          },
                                        },
                                      },
                                      [_vm._v("方案列表")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.current,
              limit: _vm.listQuery.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            "append-to-body": "",
            visible: _vm.dialogVisible3,
            "before-close": _vm.handleCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible3 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "500px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.param,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "orgId", label: "所属公司:" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: false,
                          "flatten-search-results": true,
                          placeholder: "请选择...",
                          options: _vm.orgOptions,
                        },
                        model: {
                          value: _vm.param.orgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "orgId", $$v)
                          },
                          expression: "param.orgId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.editType == "edit"
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "policyCode", label: "政策编号:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.param.policyCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.param, "policyCode", $$v)
                              },
                              expression: "param.policyCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "policyName", label: "政策名称:" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.param.policyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "policyName", $$v)
                          },
                          expression: "param.policyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "政策类型:", prop: "policyType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.param.policyType,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "policyType", $$v)
                            },
                            expression: "param.policyType",
                          },
                        },
                        _vm._l(_vm.types, function (item) {
                          return _c("el-option", {
                            key: item.figure,
                            attrs: { label: item.name, value: item.figure },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "startTime", label: "开始日期:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.param.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "startTime", $$v)
                          },
                          expression: "param.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "endTime", label: "截止日期:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.param.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "endTime", $$v)
                          },
                          expression: "param.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "files", label: "附件:" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            "auto-upload": false,
                            "on-change": _vm.handleChange,
                            "file-list": _vm.fileList,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                loading: _vm.uploading,
                                type: "primary",
                              },
                            },
                            [_vm._v("点击上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "el-upload__tip",
                              staticStyle: { "margin-left": "10px" },
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("只能上传jpg/png文件，且不超过100MB")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("form")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("form")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("rebate", {
        attrs: {
          "dialog-visible": _vm.dialogVisible,
          "associated-policy": _vm.associatedPolicy,
          "rebate-or-gift": _vm.rebateOrGift,
        },
        on: { close: _vm.closeRebate },
      }),
      _vm._v(" "),
      _c("markting", {
        attrs: {
          "dialog-visible": _vm.dialogVisible2,
          "policy-id": _vm.policyId,
        },
        on: { close: _vm.closeMarket },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "政策管理详情",
            "close-on-click-modal": false,
            "append-to-body": "",
            visible: _vm.dialogVisible4,
            "before-close": _vm.handleWinClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible4 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "details", attrs: { model: _vm.detailData } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("所属公司:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.orgName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("政策编号:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.policyCode)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("政策名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.policyName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("政策类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("filterType")(_vm.detailData.policyType)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("开始日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.startTime)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("截止日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.endTime)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "50px" },
                        },
                        [_vm._v("附件:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm.detailData.files
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.detailData.files.split(","),
                                function (item, index) {
                                  return _c(
                                    "a",
                                    {
                                      key: index,
                                      staticStyle: {
                                        color: "#4181FF",
                                        display: "block",
                                        "padding-bottom": "10px",
                                      },
                                      attrs: { href: item },
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleWinClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }