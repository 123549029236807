"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _record = require("@/api/warehouse/record/record");
var _dictionary = require("@/api/systems/dictionary");
var _factory = require("@/api/basic/factory");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _personDialog = _interopRequireDefault(require("@/components/personDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  djlx: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    personDialog: _personDialog.default
  },
  filters: {
    djlxText: function djlxText(val) {
      var name = '';
      TypeDataComs.djlx = [{
        name: '生产入库',
        figure: '101'
      }, {
        name: '材料入库',
        figure: '106'
      }, {
        name: '退货入库',
        figure: '103'
      }, {
        name: '销售出库',
        figure: '203'
      }, {
        name: '材料出库',
        figure: '205'
      }];
      TypeDataComs.djlx.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.figure == code) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      storeList: [],
      djlx: [{
        name: '生产入库',
        figure: '101'
      }, {
        name: '材料入库',
        figure: '106'
      }, {
        name: '退货入库',
        figure: '103'
      }, {
        name: '销售出库',
        figure: '203'
      }, {
        name: '材料出库',
        figure: '205'
      }],
      djzt: [],
      pdaUser: '',
      tableData: [],
      searchForm: {
        beginDate: null,
        endDate: null,
        billNum: null,
        billType: null,
        billStatus: null,
        downUser: null,
        storageId: null,
        ncNo: '',
        inOrgOrAccountName: '',
        productName: '',
        isHideCanceledBill: 1,
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      handleShow: false,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%'
    };
  },
  activated: function activated() {
    this.getStoreList();
    // this.getDirSel('CCLX', 'djlx')
    // 单据状态
    this.getDirSel('DJZT', 'djzt');
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    openUser: function openUser() {
      this.$refs.personDialog.proSelVisible = true;
    },
    setUser: function setUser(val) {
      this.searchForm.downUser = val.pid;
      this.pdaUser = val.userName;
    },
    clearUser: function clearUser() {
      this.searchForm.downUser = '';
      this.pdaUser = '';
    },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
            if (list == 'djzt') {
              TypeDataComs[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString());
              });
              _this[list] = res.data.data.filter(function (item) {
                return /^1/.test(item.figure.toString());
              });
            }
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getStoreList: function getStoreList(val) {
      var that = this;
      (0, _factory.ccGetHouseList)().then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.searchForm.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.pdaUser = '';
      this.searchForm = {
        beginDate: null,
        endDate: null,
        billNum: null,
        billType: null,
        billStatus: null,
        downUser: null,
        storageId: null,
        ncNo: '',
        inOrgOrAccountName: '',
        productName: '',
        isHideCanceledBill: 1,
        current: 1,
        size: 20
      };
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      console.log('that.searchForm', that.searchForm);
      (0, _record.queryDownBillForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    scanDetail: function scanDetail(row, fun) {
      var _this3 = this;
      var msg = '此操作会删除已扫描数码?';
      if (fun == 'forceFinish') {
        msg = '确定要强制结单吗?';
      }
      if (fun == 'revertFinish') {
        msg = '确定要撤销结单吗？';
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('updatebtn', _this3.$route.meta.authList);
        if (fun == 'revertDown') {
          _this3.setRevertDown(row);
        } else {
          _this3.setRevertFinish(row);
        }
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    setRevertDown: function setRevertDown(row) {
      var _this4 = this;
      (0, _record.revertDown)(row.pid).then(function (response) {
        if (response.data.code != 200) {
          _this4.$message.error(response.data.msg);
          return;
        } else {
          _this4.$message.success('操作成功');
          _this4.getList();
        }
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    setRevertFinish: function setRevertFinish(row) {
      var _this5 = this;
      (0, _record.revertFinish)(row.pid).then(function (response) {
        if (response.data.code != 200) {
          _this5.$message.error(response.data.msg);
          return;
        } else {
          _this5.$message.success('操作成功');
          _this5.getList();
        }
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;