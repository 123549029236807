"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _tgItem = require("@/api/chanelmange/tgItem");
var _param2 = require("@/api/chanelmange/param");
var _dictionary = require("@/api/systems/dictionary");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _index = require("@/api/warehouse/checkout/index");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _tgSelectDialog = _interopRequireDefault(require("@/components/tgSelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _detail = _interopRequireDefault(require("./components/detail"));
var _audit = _interopRequireDefault(require("./components/audit"));
var _financial = _interopRequireDefault(require("./components/financial"));
var _take = _interopRequireDefault(require("./components/take"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'order',
  components: {
    Pagination: _Pagination.default,
    factorySelectDialog: _factorySelectDialog.default,
    companySelectDialog: _companySelectDialog.default,
    tgSelectDialog: _tgSelectDialog.default,
    productDialog: _productDialog.default,
    detail: _detail.default,
    audit: _audit.default,
    financial: _financial.default,
    take: _take.default
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        console.log(totalSumAll, 122121);
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    },
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  filters: {
    // formatTime: function(value) {
    //   if (value == '' || value == null) {
    //     return ''
    //   } else {
    //     return parseTime(value, '{y}-{m}-{d}')
    //   }
    // },
    purchaseComOwnerType: function purchaseComOwnerType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '渠道客户',
          2: '团购客户',
          3: '销售公司'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分',
        str = '';
      n = String((n * 1).toFixed(2));
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      pid: '',
      activeName: '',
      proType: '',
      totalZp: 0.0,
      listQuery: {
        businessClass: 1,
        beginDate: null,
        endDate: null,
        orderStatus: null,
        orderType: 2,
        sendOutComId: null,
        orderNo: null,
        storeOrderInfoQuery: {
          receiveComId: null,
          linkedOrderNo: null,
          queryType: null,
          queryContent: null,
          purchaseComOwnerType: null
        },
        current: 1,
        size: 20
      },
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      businessOption: [{
        name: '发货库房',
        code: 1
      }, {
        name: '联系人',
        code: 2
      }, {
        name: '联系电话',
        code: 3
      },
      // {
      //   name: '票据主管',
      //   code: 5
      // },
      // {
      //   name: '部门经理',
      //   code: 6
      // },
      {
        name: '申请人',
        code: 7
      }, {
        name: '采购单位',
        code: 8
      }],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      inUseName: '',
      factoryShow: false,
      companyShow: false,
      tgShow: false,
      productShow: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 查询收货单位名称
      searchReceiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      // 查询发货单位名称
      searchSendOutComName: '',
      // 发货单位所属组织id
      sendComId: '',
      orgId: '',
      inOutType: '',
      isEnable: '',
      receiveLoading: false,
      auditLoading: false,
      companycanChange: true,
      factorycanChange: true,
      companyType: '',
      factoryType: '',
      proSelectList: [],
      productList: [],
      zpList: [],
      normalFacList: [],
      storeList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComOwnerType: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        businessClass: 1,
        orderDetailDTOS: []
      },
      rules: {
        purchaseComOwnerType: [{
          required: true,
          message: '采购单位类型是必填项，请填写！',
          trigger: 'change'
        }],
        purchaseComId: [{
          required: true,
          message: '采购单位是必填项，请填写！',
          trigger: 'change'
        }],
        paymentStyle: [{
          required: true,
          message: '付款方式是必填项，请填写！',
          trigger: 'change'
        }],
        placeOrderDate: [{
          required: true,
          message: '请选择订单日期',
          trigger: 'change'
        }],
        applyUser: [{
          required: true,
          message: '申请人是必填项，请填写！',
          trigger: 'change'
        }],
        receiveComId: [{
          required: true,
          message: '收货单位是必填项，请填写！',
          trigger: 'change'
        }],
        sendOutStoreId: [{
          required: true,
          message: '发货库房是必填项，请填写！',
          trigger: 'change'
        }],
        sendOutComId: [{
          required: true,
          message: '发货单位是必填项，请填写！',
          trigger: 'change'
        }]
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      title: '采购订单',
      dialogVisible: false,
      detailShow: false,
      auditShow: false,
      financialShow: false,
      takeShow: false,
      loading: false,
      subType: '',
      showCol: false,
      expandTxt: '展开',
      auditForm: {
        pId: '',
        handlerResult: '',
        remark: ''
      },
      auth: []
    };
  },
  activated: function activated() {
    var that = this;
    this.auth = (0, _utils.getAuthBtn)();
    that.getList();
    this.getDirSel('CCXSDDZT', 'orderStatus');
    that.getFacList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    //按钮验证
    isAuth: function isAuth(val) {
      var flag = false;
      this.auth.filter(function (item) {
        if (item.resourceId == val) {
          flag = true;
        }
      });
      return flag;
    },
    //获取数据字典信息
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    //提货校验-----需要整
    maxNum: function maxNum(row) {
      var num = 0,
        hasTakeNum = row.hasTakeNum || 0,
        outNum = row.storeInventory || 0;
      num = row.chestNum - hasTakeNum;
      return Math.min(num, outNum);
    },
    checkQueryType: function checkQueryType() {
      if (!this.listQuery.storeOrderInfoQuery.queryType) {
        this.$message.error('请选择查询类型');
      }
    },
    //获取仓库下拉
    getStoreList: function getStoreList(pid) {
      var _this2 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.storeList = res.data.data;
          } else {
            _this2.storeList = [];
          }
        }
      });
    },
    storeName: function storeName(id) {
      var name = '';
      if (id) {
        this.storeList.filter(function (item) {
          if (item.pId = id) {
            name = item.storeHouseName;
          }
        });
      }
      return name;
    },
    setStore: function setStore(val) {
      var _this3 = this;
      var _loop = function _loop(i) {
        (0, _ordergl.getCurrentInventory)({
          productId: _this3.productList[i].pId,
          sendStoreId: val,
          sendComId: _this3.sendComId
        }).then(function (res) {
          _this3.productList[i].storeInventory = res.data.data || 0;
          _this3.resetList();
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    getFacList: function getFacList() {
      var _this4 = this;
      (0, _org.getXgOrgInfo)({
        orgTypeList: ['2', '3']
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this4.normalFacList = res.data.data;
          } else {
            _this4.normalFacList = [];
          }
        }
      });
    },
    getList: function getList() {
      var auth = this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var param = JSON.parse(JSON.stringify(that.listQuery));
      (0, _ordergl.queryForPage)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    //新增
    DoAdd: function DoAdd() {
      var _this5 = this;
      var auth = this.authId('addbtn', this.$route.meta.authList);
      var that = this;
      that.subType = 'add';
      this.title = '销售订单';
      (0, _ordergl.getOrderNo)(2).then(function (res) {
        if (res.data.code == 200) {
          _this5.addData.orderNo = res.data.data;
          that.dialogVisible = true;
          _this5.$nextTick(function () {
            _this5.$refs.addForm.clearValidate();
          });
        } else {
          that.$message.error('订单号获取失败，请重试！');
        }
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} orgId 采购单位ID 获取收货单位使用
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType, orgId) {
      if (name == 'purchaseComName' && !this.addData.purchaseComOwnerType) {
        this.$message.error('请先选择采购单位类型');
        return;
      }
      if (name == 'receiveComName' && !orgId) {
        this.$message.error('请先选择采购单位');
        return;
      }
      this.orgId = orgId || '';
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      if (name == 'purchaseComName' && this.addData.purchaseComOwnerType == 2) {
        this.tgShow = true;
      } else {
        this.companyShow = true;
      }
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      if (this.productList.length) {
        this.productList.forEach(function (item) {
          item.rebatePolicyId = '';
          item.rebatePolicyName = '';
          item.orderGiftDetailDTOS = [];
        });
      }
      switch (this.inUseName) {
        case 'searchSendOutComName':
          this.listQuery.sendOutComId = val.companyId;
          break;
        case 'purchaseComName':
          this.addData.purchaseComId = val.companyId;
          // val.companyType 1.经销商 22销售 33 生产
          switch (val.companyType * 1) {
            case 1:
              this.addData.purchaseComType = 3;
              this.addData.receiveComType = 3;
              break;
            case 22:
              this.addData.purchaseComType = 2;
              this.addData.receiveComType = 2;
              break;
            case 33:
              this.addData.purchaseComType = 1;
              this.addData.receiveComType = 1;
              break;
            default:
              this.addData.purchaseComType = 2;
              this.addData.receiveComType = 2;
          }
          this.addData.receiveComId = val.companyId;
          this.addData.purchaseComName = val.companyName;
          this.addData.receiveComName = val.companyName;
          this.receiveComName = val.companyName;
          this.getFL();
          this.getWine();
          this.resetList();
          this.getReceiveInfo(val);
          break;
        case 'receiveComName':
          // val.companyType 1.经销商 22销售 33 生产
          switch (val.companyType * 1) {
            case 1:
              this.addData.receiveComType = 3;
              break;
            case 22:
              this.addData.receiveComType = 2;
              break;
            case 33:
              this.addData.receiveComType = 1;
              break;
            default:
              this.addData.receiveComType = 2;
          }
          this.addData.receiveComId = val.companyId;
          this.addData.receiveComName = val.companyName;
          this.getFL();
          this.getWine();
          this.resetList();
          this.getReceiveInfo(val);
          break;
        case 'searchReceiveComName':
          this.listQuery.storeOrderInfoQuery.receiveComId = val.companyId;
          break;
        case 'sendOutComName':
          this.addData.sendOutComId = val.companyId;
          switch (val.companyType * 1) {
            case 1:
              this.addData.sendOutComType = 3;
              break;
            case 22:
              this.addData.sendOutComType = 2;
              break;
            case 33:
              this.addData.sendOutComType = 1;
              break;
            default:
              this.addData.sendOutComType = 1;
          }
          this.addData.sendOutComName = val.companyName;
          this.getFL();
          this.getWine();
          break;
      }
    },
    pTypeChange: function pTypeChange() {
      var _this6 = this;
      if (this.productList.length) {
        this.productList.forEach(function (item) {
          item.rebatePolicyId = '';
          item.rebatePolicyName = '';
          item.orderGiftDetailDTOS = [];
        });
      }
      this.zpList = [];
      this.addData.purchaseComId = '';
      this.addData.purchaseComType = '';
      this.addData.purchaseComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveComName = '';
      this.addData.receiveComType = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
      this.getFL();
      this.getWine();
      this.resetList();
      this.$nextTick(function () {
        _this6.$refs.addForm.clearValidate();
      });
    },
    setTg: function setTg(val) {
      this.addData.purchaseComType = '';
      this.addData.receiveComType = '';
      this.addData.purchaseComId = val.pId;
      this.addData.receiveComId = val.pId;
      this.addData.purchaseComName = val.groupArchivesName;
      this.addData.receiveComName = val.groupArchivesName;
      this.addData.receiveContacts = val.contacts || '';
      this.addData.receivePhone = val.phone || '';
      var provinceName = val.provinceName || '';
      var cityName = val.cityName || '';
      var countyName = val.countyName || '';
      var detailedAddress = val.detailedAddress || '';
      this.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
    },
    tgClose: function tgClose() {
      this.inUseName = '';
      this.tgShow = false;
    },
    //匹配收货单位信息
    getReceiveInfo: function getReceiveInfo(val) {
      var _this7 = this;
      this.receiveLoading = true;
      (0, _ordergl.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this7;
          var datas = response.data.data;
          _this7.addData.receiveContacts = datas.contacts || '';
          _this7.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this7.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this7.receiveLoading = false;
      }).catch(function () {
        _this7.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    /**
     * 工厂选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 2 ：销售公司,3: 生产公司,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType, isEnable) {
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      this.isEnable = isEnable;
      this.factoryShow = true;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'sendOutComName':
          this.addData.sendOutComId = val.pid;
          this.addData.sendOutComType = val.orgType == 2 ? 2 : 1;
          break;
        case 'searchSendOutComName':
          this.listQuery.sendOutComId = val.pid;
          break;
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factorystatus = '';
      this.isEnable = '';
      this.factoryShow = false;
    },
    openPro: function openPro(val) {
      this.proType = val;
      if (!this.addData.sendOutComId || !this.addData.purchaseComId || !this.addData.receiveComId) {
        this.$message.error('请先选择采购单位、收货单位、供货单位');
        return;
      }
      this.proSelectList = val == 1 ? this.productList : this.zpList;
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var _this8 = this;
      var list, arr;
      if (this.proType == 1) {
        arr = JSON.parse(JSON.stringify(this.productList));
        list = 'productList';
      } else {
        arr = JSON.parse(JSON.stringify(this.zpList));
        list = 'zpList';
      }
      if (val.length) {
        if (this[list].length == 0) {
          var obj = {
            prodUnit: 1,
            chestNum: 0,
            bottleNum: 0,
            bottleNumOver: 0,
            rebatePolicyId: '',
            isGift: this.proType == 1 ? 1 : 0,
            isUseCoupon: this.proType == 1 ? 0 : 1,
            orderGiftDetailDTOS: []
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
          });
        } else {
          val.forEach(function (item) {
            item.prodUnit = 1;
            item.isGift = _this8.proType == 1 ? 1 : 0;
            item.isUseCoupon = _this8.proType == 1 ? 0 : 1;
            item.rebatePolicyId = '';
            item.chestNum = 0;
            item.bottleNum = 0;
            item.bottleNumOver = 0;
            item.orderGiftDetailDTOS = [];
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.prodUnit = el.prodUnit;
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.bottleNumOver = el.bottleNumOver;
                item.isGift = el.isGift;
                item.isUseCoupon = el.isUseCoupon;
                item.orderGiftDetailDTOS = el.orderGiftDetailDTOS;
                item.rebatePolicyId = el.rebatePolicyId;
                item.rebatePolicyName = el.rebatePolicyName;
                item.winePrice = el.winePrice;
                item.wineTotalPrice = el.wineTotalPrice;
              }
            });
          });
        }
        console.log(val, '987');
        this[list] = JSON.parse(JSON.stringify(val));
        if (this.addData.receiveComType == 3 && this.proType == 1) {
          this.getFL(1);
        }
      } else {
        this[list] = [];
      }
    },
    proClose: function proClose() {
      this.proSelectList = '';
      this.proType = '';
      this.productShow = false;
    },
    toogleExpand: function toogleExpand(idx) {
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        if (idx != i) {
          $table.toggleRowExpansion(this.productList[i], false);
        }
      }
      // this.setZPnum(this.productList[idx])
      $table.toggleRowExpansion(this.productList[idx]);
    },
    getFL: function getFL(val) {
      var _this9 = this;
      if (this.addData.purchaseComId && this.addData.sendOutComId && this.addData.receiveComId && this.productList.length) {
        var _loop2 = function _loop2(i) {
          param = (0, _defineProperty2.default)({
            buyNum: 0,
            harvestId: _this9.addData.receiveComId,
            harvestType: _this9.addData.receiveComType,
            productId: '',
            purchaseId: _this9.addData.purchaseComId,
            purchaseType: _this9.addData.purchaseComType,
            supplierId: _this9.addData.sendOutComId,
            supplierType: _this9.addData.sendOutComType
          }, "productId", _this9.productList[i].pId);
          (0, _ordergl.matchChannelBuyGift)(param).then(function (res) {
            if (res.data.code == 200) {
              _this9.productList[i].flList = res.data.data || [];
              if (!val) _this9.productList[i].rebatePolicyId = '';
            } else {
              _this9.$message.error(res.data.msg);
              _this9.productList[i].flList = [];
            }
            if (val) {
              _this9.flChange('', _this9.productList[i]);
            }
            _this9.resetList();
          });
        };
        // var param = {
        //   buyNum: 1,
        //   harvestId: '1278155864498208769',
        //   harvestType: 3,
        //   productId: '1265813287266222082'
        // }
        for (var i = 0; i < this.productList.length; i++) {
          var param;
          _loop2(i);
        }
      }
    },
    getWine: function getWine() {
      var _this10 = this;
      if (this.addData.receiveComId && this.addData.sendOutComId) {
        (0, _ordergl.buyerMoneyWine)({
          harvestId: this.addData.receiveComId,
          harvestType: 1,
          supplierId: this.addData.sendOutComId,
          supplierType: this.addData.sendOutComType == 3 ? 1 : 0
        }).then(function (res) {
          if (res.data.code == 200) {
            _this10.totalZp = res.data.data;
          } else {
            _this10.$message.error(res.data.msg);
          }
        });
      }
    },
    flChange: function flChange($el, row) {
      var _this11 = this;
      var arr = [];
      row.flList.forEach(function (item) {
        if (item.rebateId == row.rebatePolicyId) {
          if (!item.detail || item.detail.length == 0) {
            _this11.$message.error('无匹配的返利方案');
            return;
          }
          row.rebatePolicyName = item.desc;
          item.detail.forEach(function (val) {
            var obj = {
              buyNum: val.buyNum,
              giftNum: val.giftNum,
              bottleNum: 0,
              chestNum: 0,
              isComProd: val.product.isCode,
              prodPrice: val.product.productPrice || 0,
              productCode: val.product.productCode,
              productId: val.product.pId,
              productName: val.product.productName,
              rpzRebateSchemeConfigId: val.rpzRebateSchemeConfigId,
              rpzRebateSchemeId: val.rpzRebateSchemeId,
              rpzRebateSchemeOrgId: val.rpzRebateSchemeOrgId,
              rpzRebateSchemeProId: val.rpzRebateSchemeProId,
              totalPrice: ''
            };
            if (row.orderGiftDetailDTOS.length) {
              row.orderGiftDetailDTOS.forEach(function (el) {
                if (el.productId == val.product.pId) {
                  obj.pid = el.pid;
                  obj.orderDetailId = el.orderDetailId;
                }
              });
            }
            arr.push(obj);
          });
        }
      });
      row.orderGiftDetailDTOS = arr;
      this.setZPnum(row);
      this.resetList();
    },
    flClear: function flClear($el, row) {
      row.orderGiftDetailDTOS = [];
      this.resetList();
    },
    setZPnum: function setZPnum(row) {
      if (row.orderGiftDetailDTOS.length) {
        row.orderGiftDetailDTOS.forEach(function (item) {
          if (row.bottleNum) {
            item.bottleNum = Math.floor(row.bottleNum / item.buyNum) * item.giftNum;
            item.totalPrice = item.bottleNum * item.prodPrice;
          } else {
            item.bottleNum = 0;
            item.totalPrice = 0;
          }
        });
      } else {
        this.resetList();
      }
      this.resetZpList();
      row.orderGiftDetailDTOS = JSON.parse(JSON.stringify(row.orderGiftDetailDTOS));
    },
    totalPrice: function totalPrice(row) {
      var num = row.bottleNum * 1;
      num = num ? num : 0;
      var prize = row.productPrice || 0;
      var total = num * (prize * 1);
      row.totalPrice = total.toFixed(2);
      return row.totalPrice;
    },
    totalWine: function totalWine(row) {
      var num = row.bottleNum * 1;
      num = num ? num : 0;
      var prize = row.winePrice || 0;
      var total = num * (prize * 1);
      row.wineTotalPrice = total.toFixed(2);
      return row.wineTotalPrice;
    },
    resetProdUnit: function resetProdUnit($el, row) {
      row.chestNum = 0;
      row.bottleNum = 0;
      this.setZPnum(row);
      // this.resetList()
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.bottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNumOver || 0);
      this.setZPnum(row);
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    resetZpList: function resetZpList() {
      this.zpList = JSON.parse(JSON.stringify(this.zpList));
    },
    //删除产品明细列表
    DoSc: function DoSc(row) {
      var _this12 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this12.productList.splice(_this12.productList.indexOf(row), 1);
      });
    },
    //删除赠品明细列表
    DoScZp: function DoScZp(row) {
      var _this13 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this13.zpList.splice(_this13.zpList.indexOf(row), 1);
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.searchReceiveComName = '';
      this.searchSendOutComName = '';
      this.listQuery = {
        beginDate: null,
        endDate: null,
        orderStatus: null,
        businessClass: 1,
        orderType: 2,
        sendOutComId: null,
        orderNo: null,
        storeOrderInfoQuery: {
          receiveComId: null,
          linkedOrderNo: null,
          queryType: null,
          queryContent: null,
          purchaseComOwnerType: null
        },
        current: 1,
        size: 20
      };
      this.getList();
    },
    //修改
    DoEdit: function DoEdit(data) {
      var auth = this.authId('updatebtn', this.$route.meta.authList);
      this.title = '销售订单-修改';
      this.subType = 'edit';
      this.getDetailData(data.pid, 'edit');
      this.dialogVisible = true;
    },
    // 查看
    DoScan: function DoScan(data) {
      var auth = this.authId('detailbtn', this.$route.meta.authList);
      this.title = '销售订单-查看';
      this.pid = data.pid;
      this.detailShow = true;
    },
    detailClose: function detailClose() {
      this.pid = '';
      this.detailShow = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    //预审
    DoAudit: function DoAudit(data) {
      var auth = this.authId('xsddsh', this.$route.meta.authList);
      this.pid = data.pid;
      this.auditShow = true;
    },
    auditClose: function auditClose() {
      this.pid = '';
      this.auditShow = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    audit: function audit() {
      this.auditClose();
      this.getList();
    },
    //确款
    DoFinancial: function DoFinancial(data) {
      this.pid = data.pid;
      this.financialShow = true;
    },
    financialClose: function financialClose() {
      this.pid = '';
      this.financialShow = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    financial: function financial() {
      this.financialClose();
      this.getList();
    },
    //提货
    DoTake: function DoTake(data) {
      var auth = this.authId('xsddth', this.$route.meta.authList);
      this.pid = data.pid;
      this.takeShow = true;
    },
    takeClose: function takeClose() {
      this.pid = '';
      this.takeShow = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    take: function take() {
      this.takeClose();
      this.getList();
    },
    //获取详情数据
    getDetailData: function getDetailData(id) {
      var _this14 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this14.addData = res.data.data;
          data.orderDetailDTOS.forEach(function (item) {
            var aNum = item.packRaidoExpress.split('*');
            var le = aNum.length - 1;
            var obj = {
              pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              prodUnit: item.prodUnit,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              bottleNumOver: item.bottleNum % aNum[le],
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isGift: item.isGift,
              isUseCoupon: item.isUseCoupon,
              extractNum: _this14.subType == 'take' ? '' : item.extractNum,
              hasTakeNum: item.extractNum,
              rebatePolicyId: item.rebatePolicyId,
              rebatePolicyName: item.rebatePolicyName,
              orderGiftDetailDTOS: item.orderGiftDetailDTOS,
              winePrice: item.winePrice,
              wineTotalPrice: item.wineTotalPrice
            };
            if (item.isUseCoupon) {
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this14.productList = proArr;
          _this14.zpList = zpArr;
          if (_this14.subType == 'take') {
            _this14.addData.sendOutStoreId = '';
          }
          _this14.purchaseComName = data.purchaseComName;
          _this14.receiveComName = data.receiveComName;
          _this14.sendOutComName = data.sendOutComName;
          _this14.getStoreList(data.sendOutComId);
          if (_this14.addData.receiveComType == 3) {
            _this14.getFL(1);
            _this14.getWine();
          }
          _this14.sendComId = data.sendOutComId;
        } else {
          _this14.$message.error(res.data.msg);
        }
        _this14.loading = false;
      }).catch(function () {
        _this14.loading = false;
      }).then(function () {
        _this14.$nextTick(function () {
          if (_this14.subType == 'edit') {
            _this14.$nextTick(function () {
              _this14.$refs.addForm.clearValidate();
            });
          }
        });
        if (_this14.addData.orderStatus > 1) {
          _this14.auditLoading = true;
          (0, _ordergl.getAuditLog)({
            pId: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this14.auditList = res.data.data;
            } else {
              that.$message.error('审核日志获取失败');
            }
            _this14.auditLoading = false;
          }).catch(function () {
            _this14.auditLoading = false;
          });
        }
      });
    },
    //置空字段
    resetVoid: function resetVoid() {
      this.addData = {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComOwnerType: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        businessClass: 1,
        orderDetailDTOS: []
      };
      this.productList = [];
      this.zpList = [];
      this.totalZp = 0.0;
      this.sendComId = '';
      this.purchaseComName = '';
      this.receiveComName = '';
      this.sendOutComName = '';
    },
    handleClose: function handleClose() {
      var that = this;
      this.$refs.addForm.resetFields();
      that.resetVoid();
      that.dialogVisible = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    auditReset: function auditReset() {
      this.$refs.auditForm.resetFields();
      this.auditForm = {
        pId: '',
        handlerResult: '',
        remark: ''
      };
    },
    submitForm: function submitForm(val) {
      var _this15 = this;
      var that = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (that.addData.sendOutComId == that.addData.purchaseComId) {
            that.$message.error('采购单位和发货单位不能一致');
            return;
          }
          that.addData.totalMoney = that.totalMoney;
          // if (that.addData.orderTime == '') {
          //   that.$message.error('请选择订单日期')
          //   return
          // }
          if (!that.productList.length) {
            that.$message.error('请选择产品');
            return;
          }
          if (that.zpList.length > 0 && that.totalInUse > _this15.totalZp) {
            that.$message.error('使用酒劵金额不能超过可用酒劵金额');
            return;
          }
          var plist = JSON.parse(JSON.stringify([].concat((0, _toConsumableArray2.default)(that.productList), (0, _toConsumableArray2.default)(that.zpList))));
          var oldList = JSON.parse(JSON.stringify(that.addData.orderDetailDTOS));
          var newList = [];
          if (plist.length > 0) {
            for (var i = 0; i < plist.length; i++) {
              if (!plist[i].bottleNum || plist[i].bottleNum == 0) {
                if (i > that.productList.length - 1) {
                  that.$message.error('在酒劵兑换列表第' + (i - that.productList.length + 1) + '行请输入采购数量');
                } else {
                  that.$message.error('在产品列表第' + (i + 1) + '行请输入采购数量');
                }
                return;
              }
              if (that.subType == 'add') {
                var obj = {
                  bottleNum: plist[i].bottleNum,
                  chestNum: plist[i].chestNum,
                  orderNo: that.addData.orderNo,
                  packRaidoExpress: plist[i].packScaleExpression,
                  prodPrice: plist[i].productPrice,
                  prodUnit: plist[i].prodUnit,
                  productCode: plist[i].productCode,
                  productId: plist[i].pId,
                  productName: plist[i].productName,
                  productSpec: plist[i].productModel,
                  totalPrice: plist[i].totalPrice,
                  isGift: plist[i].isGift,
                  isUseCoupon: plist[i].isUseCoupon,
                  orderGiftDetailDTOS: plist[i].orderGiftDetailDTOS,
                  rebatePolicyId: plist[i].rebatePolicyId,
                  rebatePolicyName: plist[i].rebatePolicyName,
                  winePrice: plist[i].winePrice,
                  wineTotalPrice: plist[i].wineTotalPrice * 1
                };
                var orderGiftDetailDTOS = [];
                var le = plist[i].orderGiftDetailDTOS.length;
                if (le) {
                  for (var j = 0; j < le; j++) {
                    delete plist[i].orderGiftDetailDTOS[j].buyNum;
                    delete plist[i].orderGiftDetailDTOS[j].giftNum;
                    delete plist[i].orderGiftDetailDTOS[j].productCode;
                    delete plist[i].orderGiftDetailDTOS[j].productName;
                    if (plist[i].orderGiftDetailDTOS[j].bottleNum > 0) {
                      orderGiftDetailDTOS.push(plist[i].orderGiftDetailDTOS[j]);
                    }
                  }
                }
                obj.orderGiftDetailDTOS = orderGiftDetailDTOS;
                newList.push(obj);
              }
              if (that.subType == 'edit') {
                var obj = {
                  pid: plist[i].pid,
                  bottleNum: plist[i].bottleNum,
                  chestNum: plist[i].chestNum,
                  orderNo: that.addData.orderNo,
                  packRaidoExpress: plist[i].packScaleExpression,
                  prodPrice: plist[i].productPrice,
                  prodUnit: plist[i].prodUnit,
                  productCode: plist[i].productCode,
                  productId: plist[i].pId,
                  productName: plist[i].productName,
                  productSpec: plist[i].productModel,
                  totalPrice: plist[i].totalPrice,
                  isGift: plist[i].isGift,
                  isUseCoupon: plist[i].isUseCoupon,
                  orderGiftDetailDTOS: plist[i].orderGiftDetailDTOS,
                  rebatePolicyId: plist[i].rebatePolicyId,
                  rebatePolicyName: plist[i].rebatePolicyName,
                  winePrice: plist[i].winePrice,
                  wineTotalPrice: plist[i].wineTotalPrice * 1
                };
                oldList.forEach(function (item) {
                  if (item.pid == obj.pid) {
                    obj = Object.assign(item, obj);
                  }
                });
                var orderGiftDetailDTOS = [];
                var le = plist[i].orderGiftDetailDTOS.length;
                if (le) {
                  for (var j = 0; j < le; j++) {
                    delete plist[i].orderGiftDetailDTOS[j].buyNum;
                    delete plist[i].orderGiftDetailDTOS[j].giftNum;
                    delete plist[i].orderGiftDetailDTOS[j].productCode;
                    delete plist[i].orderGiftDetailDTOS[j].productName;
                    if (plist[i].orderGiftDetailDTOS[j].bottleNum > 0) {
                      orderGiftDetailDTOS.push(plist[i].orderGiftDetailDTOS[j]);
                    }
                  }
                }
                obj.orderGiftDetailDTOS = orderGiftDetailDTOS;
                newList.push(obj);
              }
            }
          }
          that.addData.orderDetailDTOS = newList;
          that.loading = true;
          that.dsate = true;
          var subData = that.addData;
          console.log(subData);
          if (that.subType == 'add') {
            (0, _ordergl.insertOrder)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                _this15.handleClose();
                that.getList();
              } else {
                that.$message.error(res.data.msg);
              }
              that.loading = false;
              that.dsate = false;
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (that.subType == 'edit') {
            (0, _ordergl.updateOrder)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                _this15.handleClose();
                that.getList();
              } else {
                that.$message.error(res.data.msg);
              }
              that.loading = false;
              that.dsate = false;
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        } else {
          _this15.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;