"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logisticsStatisticsExcel = logisticsStatisticsExcel;
exports.queryReportForPage = queryReportForPage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 防伪统计api

// 疑似防窜城市排行
function queryReportForPage(params) {
  return (0, _request.default)({
    url: '/storage-center/logisticsStatistics/queryReportForPage',
    method: 'get',
    params: params
  });
}
// 导出
function logisticsStatisticsExcel(params) {
  return (0, _request.default)({
    url: '/storage-center/logisticsStatistics/logisticsStatisticsExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}