var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "手册查看",
        visible: _vm.pdfVisible,
        fullscreen: "",
        "before-close": _vm.selCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.pdfVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.fileType === "pdf",
                expression: "fileType === 'pdf'",
              },
            ],
            staticClass: "pdf",
          },
          [
            _c("div", { staticClass: "pdf-wraper" }, [
              _c(
                "div",
                { staticClass: "pdf-page", attrs: { id: "pdf" } },
                [
                  _c("pdf", {
                    attrs: {
                      src: _vm.src,
                      page: _vm.currentPage,
                      rotate: _vm.pageRotate,
                    },
                    on: {
                      "num-pages": function ($event) {
                        _vm.pageCount = $event
                      },
                      loaded: _vm.loadPdfHandler,
                      "page-loaded": function ($event) {
                        return _vm.pageLoaded($event)
                      },
                      progress: function ($event) {
                        _vm.loadedRatio = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "space" }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "arrow" },
        [
          _c(
            "el-button",
            {
              staticClass: "turn",
              class: { grey: _vm.currentPage == 1 },
              on: {
                click: function ($event) {
                  return _vm.changePdfPage(0)
                },
              },
            },
            [_vm._v("上一页")]
          ),
          _vm._v(
            "\n    " +
              _vm._s(_vm.currentPage) +
              " / " +
              _vm._s(_vm.pageCount) +
              "\n    "
          ),
          _c(
            "el-button",
            {
              staticClass: "turn",
              class: { grey: _vm.currentPage == _vm.pageCount },
              on: {
                click: function ($event) {
                  return _vm.changePdfPage(1)
                },
              },
            },
            [_vm._v("下一页")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }