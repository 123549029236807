"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _fake = require("@/api/fake_expel/fake/fake");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  props: ['dialogTitle'],
  data: function data() {
    return {
      tableKey: 0,
      total: 0,
      listLoading: false,
      excelOut_loading: false,
      list: null,
      rowInfo: {
        digit: ''
      },
      dialogDigitalVisible: false,
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        antiNum: '',
        queryTime: ''
      },
      Column: {
        gueneShow: true
      },
      rules: {}
    };
  },
  watch: {
    'rowInfo.digit': function rowInfoDigit(newVal, oldVal) {}
  },
  created: function created() {},
  methods: {
    excelOutList: function excelOutList() {
      var _this = this;
      this.excelOut_loading = true;
      (0, _fake.excelOutList)(this.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '防伪预警-' + _this.listQuery.antiNum + '详情.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this.excelOut_loading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _fake.queryList)(this.listQuery).then(function (response) {
        _this2.listLoading = false;
        var data = response.data;
        console.log(data, 'data');
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.list = data.data.records;
        _this2.total = Number(data.data.total);
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    Reset: function Reset() {
      this.list = [];
      this.total = 0;
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogDigitalVisible = false;
    }
  }
};
exports.default = _default;