var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售出库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { disabled: _vm.disabled.num, readonly: true },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                      _vm._v(" "),
                      _vm.isUpdate
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getCreateNum()
                                },
                              },
                            },
                            [_vm._v("自动生成")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号:", prop: "relateNo" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            readonly: "",
                            disabled: _vm.submitType == 2,
                          },
                          model: {
                            value: _vm.scanData.billoutSaleAO.relateNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutSaleAO,
                                "relateNo",
                                $$v
                              )
                            },
                            expression: "scanData.billoutSaleAO.relateNo",
                          },
                        },
                        [
                          _vm.isUpdate
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.openBill },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "出库库房:",
                        prop: "billoutSaleAO.outStoreId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setScanFactory },
                          model: {
                            value: _vm.scanData.billoutSaleAO.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutSaleAO,
                                "outStoreId",
                                $$v
                              )
                            },
                            expression: "scanData.billoutSaleAO.outStoreId",
                          },
                        },
                        _vm._l(_vm.factoryList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库企业:", prop: "outOrg" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", disabled: _vm.submitType == 2 },
                        model: {
                          value: _vm.outOrgName,
                          callback: function ($$v) {
                            _vm.outOrgName = $$v
                          },
                          expression: "outOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售单位:", prop: "salerOrg" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", disabled: _vm.submitType == 2 },
                        model: {
                          value: _vm.saleOrgName,
                          callback: function ($$v) {
                            _vm.saleOrgName = $$v
                          },
                          expression: "saleOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货单位:", prop: "inOrgId" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            readonly: "",
                            disabled: _vm.submitType == 2,
                          },
                          model: {
                            value: _vm.inOrgName,
                            callback: function ($$v) {
                              _vm.inOrgName = $$v
                            },
                            expression: "inOrgName",
                          },
                        },
                        [
                          _vm.isUpdate
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.openBill },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.productList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "120",
                              align: _vm.tableConfig.align,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm._f("getRowNumber")($index))
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              "min-width": "100",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              "min-width": "100",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              "min-width": "100",
                              prop: "productSpec",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              "min-width": "100",
                              prop: "packUnit",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划箱",
                              prop: "planScanNum",
                              align: _vm.tableConfig.align,
                              "min-width": "160",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划盒",
                              prop: "planScanAtoNum",
                              align: _vm.tableConfig.align,
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "库存(箱)",
                              align: _vm.tableConfig.align,
                              prop: "packScaleDesc",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.storeInventory))]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "dialog-footer" },
                    [
                      _vm.submitType != 3
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(2)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(3)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("关 闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.proShow,
              data: _vm.productList,
            },
            on: {
              "update:data": function ($event) {
                _vm.productList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.billShow
        ? _c("out-bill-dialog", {
            ref: "selBill",
            attrs: { "is-code": "0", "pro-sel-visible": _vm.billShow },
            on: { change: _vm.setBill, close: _vm.billClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }