"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportJxRkList = exportJxRkList;
exports.getQueryOrg = getQueryOrg;
exports.getSfcList = getSfcList;
exports.periodListExport = periodListExport;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取经收发存列表
function getSfcList(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/detstockSumail',
    method: 'post',
    data: data
  });
}

// 经销商收发存导出
function exportJxRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/exportJxStockSum',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 经销商收发存汇总列表导出
function periodListExport(params) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/periodListExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}