"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _index = require("@/api/warehouse/checkout/index");
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    allotUnitSelect: _allotUnitSelect.default
  },
  filters: {},
  data: function data() {
    return {
      title: '',
      dialogVisible: false,
      mloading: false,
      billShow: false,
      tableKey: '2586',
      btn_loading: false,
      scanData: {
        billNo: '',
        relateNo: '',
        orderNo: '',
        outOrgName: '',
        // 发货单位
        outOrgId: '',
        // 发货单位ID
        outStoreName: '',
        // 出库库房
        inOrgCode: '',
        //
        inOrgId: '',
        // 收货单位id
        inOrgName: '',
        // 收货单位
        receiptUser: '' // 收货人
      },

      rules: {
        billNo: [{
          required: true,
          message: '销售出库单号不能为空',
          trigger: 'change'
        }],
        relateNo: [{
          required: false,
          message: '订单号不能为空',
          trigger: 'change'
        }],
        outOrgName: [{
          required: true,
          message: '发货单位',
          trigger: 'change'
        }],
        'outStoreName': [{
          required: true,
          message: '发货库房',
          trigger: 'change'
        }],
        inOrgName: [{
          required: true,
          message: '收货单位',
          trigger: 'change'
        }]
      },
      productList: [],
      unitShow: false,
      inOutType: '1',
      shouFaType: '1',
      supplierIds: [],
      purchaseType: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 修改收货单位 获取收货单位详情
    upDateShow: function upDateShow(row) {
      var _this = this;
      console.log(row);
      this.title = '修改销售出库';
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = true;
      (0, _index.getPeoductionDetail)(row.billNo).then(function (res) {
        console.log(res.data);
        _this.mloading = false;
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var data = res.data.data;
          _this.scanData.billNo = data.tradeBillAllVO.billNo;
          _this.scanData.relateNo = data.tradeBillAllVO.relateNo;
          _this.scanData.orderNo = data.tradeBillAllVO.orderNo;
          _this.scanData.outOrgName = data.tradeBillAllVO.outOrgName;
          _this.scanData.outOrgId = data.tradeBillAllVO.outOrgId;
          _this.scanData.outStoreName = data.tradeBillAllVO.outStoreName;
          _this.scanData.inOrgCode = data.tradeBillAllVO.inOrgCode;
          _this.scanData.inOrgId = data.tradeBillAllVO.inOrgId;
          _this.scanData.inOrgName = data.tradeBillAllVO.inOrgName;
          _this.scanData.receiptUser = data.tradeBillAllVO.receiptUser;
          _this.supplierIds = [data.tradeBillAllVO.outOrgId];
          if (data.tradeProductVOS && data.tradeProductVOS.length) {
            var arr = [];
            data.tradeProductVOS.forEach(function (item) {
              var obj = {
                pId: item.productId,
                productCode: item.productCode,
                productName: item.productName,
                packRadioDesc: item.packRadioDesc,
                packUnit: item.packUnit,
                planScanNum: item.planScanNum,
                planScanAtoNum: item.planScanAtoNum,
                packList: item.packList
              };
              arr.push(obj);
              console.log(item, '数据');
            });
            _this.productList = arr;
            console.log(arr, '表格数据');
          } else {
            _this.productList = [];
          }
        }
      }).catch(function () {
        _this.mloading = false;
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;
      if (this.scanData.billNo && this.scanData.inOrgId) {
        (0, _index.updateReceiveInfo)({
          billNo: this.scanData.billNo,
          inOrgId: this.scanData.inOrgId,
          receiptUser: this.scanData.receiptUser
        }).then(function (res) {
          if (res.data.code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.handleClose();
          } else {
            _this2.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$message.error('必填项不能为空');
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    closeUnit: function closeUnit() {
      this.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        that.scanData.inOrgName = val.companyName;
        that.scanData.inOrgId = val.companyId;
      }
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        if (that.scanData.outOrgId) {
          that.shouFaType = '1';
          that.unitShow = true;
        } else {
          that.$message.error('请选择发货单位');
        }
      }
      if (arg == 'out') {
        that.shouFaType = '2';
        that.unitShow = true;
      }
    }
  }
};
exports.default = _default;