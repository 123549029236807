"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activityManage = require("@/api/terminal/activityManage");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _creatActPr = _interopRequireDefault(require("./creatActPr"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    creatActPr: _creatActPr.default
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    actId: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    dataActivityStatus: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    selectItem: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showOnly: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    batchPrData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: false,
      total: 0,
      editType: 'add',
      creatVisible: false,
      activityId: '',
      id: '',
      only: false,
      finishLoading: false,
      currentEditItemIndex: -1,
      //-1为新增   其他为编辑的item index
      currentData: {},
      searchForm: {
        current: 1,
        size: 20,
        activityId: ''
      }
    };
  },
  computed: {
    isActivityOverdue: function isActivityOverdue() {
      return this.dataActivityStatus == '3';
    },
    isBatch: function isBatch() {
      return !this.actId;
    },
    getBatchPrData: function getBatchPrData() {
      return this.batchPrData;
    }
  },
  mounted: function mounted() {
    this.only = this.showOnly;
    this.getDataList();
  },
  methods: {
    // 获取概率列表
    getDataList: function getDataList() {
      var _this = this;
      this.listLoading = true;
      this.searchForm.activityId = this.actId;
      if (this.isBatch) {
        this.getBatchDataList();
        return;
      }
      (0, _activityManage.geActPrList)(this.searchForm).then(function (res) {
        if (res.data.code == 200) {
          _this.listLoading = false;
          _this.list = res.data.data.records;
          _this.total = res.data.data.total || 0;
        } else {
          _this.listLoading = false;
          _this.$message.error(res.data.msg);
          return;
        }
      });
    },
    //获取批量概率数据
    getBatchDataList: function getBatchDataList() {
      this.list = this.getBatchPrData;
      this.listLoading = false;
    },
    // 新增活动概率
    creatActPr: function creatActPr() {
      // this.authId('addbtn', this.apis)
      this.editType = 'add';
      this.only = false;
      this.id = '';
      this.activityId = this.actId;
      if (this.isBatch) {
        this.currentEditItemIndex = -1;
        this.currentData = {};
      }
      this.creatVisible = true;
    },
    // 编辑活动概率
    editActPr: function editActPr(data, index) {
      this.editType = 'edit';
      this.id = data.id;
      this.activityId = this.actId;
      if (this.isBatch) {
        this.currentEditItemIndex = index;
        this.currentData = (0, _utils.deepClone)(data);
      }
      this.creatVisible = true;
    },
    // 关闭活动概率
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.getDataList();
    },
    //批量--  获取添加的活动概率数据(未提交到后台)
    getItemData: function getItemData(data) {
      if (this.currentEditItemIndex === -1) {
        data.priority = '';
        this.list.push(data);
      } else {
        this.list[this.currentEditItemIndex] = data;
        this.list = this.list.slice();
      }
      this.creatVisible = false;
    },
    // 批量删除活动概率
    batchDelPr: function batchDelPr(index) {
      var _this2 = this;
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.list.splice(index, 1);
        _this2.$message.success('删除成功');
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 删除活动概率
    delPr: function delPr(data) {
      var _this3 = this;
      var param = {
        id: data.id
      };
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _activityManage.delActPr)(param).then(function (res) {
          if (res.data.code == 200) {
            if (res.data.data.errCode) {
              _this3.$message.error(res.data.data.errMsg);
              return;
            } else {
              _this3.getDataList();
              _this3.$message.success('删除成功');
            }
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 概率升序
    upActPr: function upActPr(data, index) {
      var _this4 = this;
      if (!index) {
        this.$message.error('已处于最顶层不能再上移');
        return;
      }
      var param = {
        id: data.id
      };
      (0, _activityManage.rankUp)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this4.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this4.getDataList();
          }
        } else {
          _this4.$message.error(res.data.msg);
          return;
        }
      });
    },
    // 概率降序
    downActPr: function downActPr(data, index) {
      var _this5 = this;
      if (index + 1 === this.list.length) {
        this.$message.error('已处于最底层不能再下移');
        return;
      }
      var param = {
        id: data.id
      };
      (0, _activityManage.rankDown)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this5.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this5.getDataList();
            return;
          }
        } else {
          _this5.$message.error(res.data.msg);
          return;
        }
      });
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      // this.getList()
      this.actId = '';
      this.readonly = false;
      this.type = 'add';
    },
    // 编辑
    edit: function edit(data) {
      if (data.activityStatus == '1' || data.activityStatus == '2') {
        this.readonly = true;
      } else {
        this.readonly = false;
      }
      this.type = 'edit';
      this.actId = data.id;
      this.dialogVisible = true;
    },
    //批量保存
    finish: function finish() {
      var _this6 = this;
      this.finishLoading = true;
      (0, _activityManage.batchUpdatePr)({
        activityIdList: this.selectItem,
        prList: this.list
      }).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.errCode) {
            _this6.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this6.$message.success('批量保存成功');
            _this6.finishLoading = false;
            _this6.goBack();
            return;
          }
        } else {
          _this6.$message.error(res.data.msg);
          return;
        }
      });
    },
    goBack: function goBack() {
      this.$emit('close');
    }
  }
};
exports.default = _default2;