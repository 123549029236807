"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _channel = require("@/api/basic/channel");
var _dealer = require("@/api/basic/dealer");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "TablePackset",
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    channelTypeName: function channelTypeName(status) {
      if (!String(status)) return "";
      var statusMap = {
        1: "经销商",
        2: "终端"
      };
      return statusMap[status];
    }
  },
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    proSelVisible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    channelType: {
      type: [String, Number],
      default: ""
    },
    channelTypeCanChange: {
      type: [String, Boolean],
      default: false
    }
  },
  data: function data() {
    return {
      area: [],
      areaList: [],
      distributorList: [],
      // 经销商列表
      // proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: "111",
      tabHeight: "100%",
      activeClass: "",
      searchForm: {
        current: 1,
        size: 20,
        archivesStatus: 1,
        channelType: this.channelType,
        provinceCode: "",
        cityCode: "",
        countyCode: "",
        keyword: ""
      },
      tableData: [],
      normalList: [],
      multipleSelection: [],
      selectedData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  watch: {
    // proSelVisible(val) {
    //   if (val) {
    //     this.getAreaAll()
    //     this.getList()
    //   }
    // },
  },
  mounted: function mounted() {
    // this.getDistributorList()
    this.getList();
    this.getAreaAll();
  },
  methods: {
    // 选取
    handleSelect: function handleSelect(row) {
      this.$emit("distributorName", row);
      this.searchForm.current = 1;
      this.getList();
    },
    // 获取经销商列表
    // getDistributorList() {
    //   channelArchivesList({
    //     channelType: 1,
    //     current: 1,
    //     size: 2147483647
    //   }).then((response) => {
    //     if (response.data.code != 200) {
    //       this.$message.error(response.data.msg)
    //       return
    //     } else {
    //       if (response.data.data != null) {
    //         this.distributorList = response.data.data.records
    //       } else {
    //         this.archivesList = []
    //       }
    //     }
    //   })
    // },
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), "pId", "parentId", "children");
          } else {
            that.areaList = [];
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      if (that.area) {
        submitData.provinceCode = that.area[0];
        submitData.cityCode = that.area[1] || "";
        submitData.countyCode = that.area[2] || "";
      }
      (0, _channel.channelArchivesList)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        archivesStatus: 1,
        channelType: this.channelType,
        provinceCode: "",
        cityCode: "",
        countyCode: "",
        keyword: ""
      };
      this.area = [];
      this.getList();
    },
    selCancel: function selCancel() {
      var _this = this;
      // this.proSelVisible = false
      this.$emit("distributorName");
      this.searchForm.current = 1;
      this.getList();
      return;
      this.resetForm();
      this.$nextTick(function () {
        _this.$emit("close");
      });
    },
    submit: function submit(row) {
      this.$emit("change", row);
      this.selCancel();
    }
  }
};
exports.default = _default2;