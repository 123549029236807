var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "新建赛事配置",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "padding-right": "20px" },
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关联活动ID:", prop: "activityIds" } },
            [
              _c(
                "div",
                { on: { click: _vm.handleClickActID } },
                [
                  _c("el-input", {
                    attrs: {
                      readonly: "",
                      value: _vm.form.activityIds.toString(),
                      disabled: _vm.isDisable,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "赛事名称:", prop: "gameName" } },
            [
              _c(
                "div",
                { on: { click: _vm.handleClickGame } },
                [
                  _c("el-input", {
                    attrs: {
                      readonly: "",
                      value: _vm.form.gameName,
                      disabled: _vm.isDisable,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "赛事ID:", prop: "gameId" } }, [
            _c(
              "div",
              { on: { click: _vm.handleClickGame } },
              [
                _c("el-input", {
                  attrs: {
                    readonly: "",
                    value: _vm.form.gameId,
                    disabled: _vm.isDisable,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "赛事说明:", prop: "gameExplain" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.isDisable },
                model: {
                  value: _vm.form.gameExplain,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gameExplain", $$v)
                  },
                  expression: "form.gameExplain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSave("form")
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleCancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectAct", {
        attrs: { visible: _vm.isShowSelectAct },
        on: { close: _vm.close, selectActID: _vm.selectActID },
      }),
      _vm._v(" "),
      _c("selectGame", {
        attrs: { visibleGame: _vm.isShowSelectGame },
        on: { close: _vm.close, selectDataGame: _vm.selectDataGame },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }