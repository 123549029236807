"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EdSubmit = EdSubmit;
exports.cancelUser = cancelUser;
exports.fetchInsert = fetchInsert;
exports.fetchOrgList = fetchOrgList;
exports.getXgOrgInfo = getXgOrgInfo;
exports.orgInfoAll = orgInfoAll;
exports.updateStatus = updateStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''
function fetchOrgList(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}
function getXgOrgInfo(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/getXgOrgInfo',
    method: 'post',
    data: data
  });
}
function fetchInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/insert',
    method: 'post',
    data: data
  });
}
function updateStatus(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/updateStatus',
    method: 'post',
    data: data
  });
}
function EdSubmit(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/update',
    method: 'post',
    data: data
  });
}
function orgInfoAll(data) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}
// export function fetchOrgContent(param){
// 	return request({
// 		url: '/api-basic/orgInfo/selectOne',
// 		method:'get',
// 		params:param
// 	})
// }

// export function fetchFactorys(){
// 	return request({
// 		url: '/api-basic/basic/comboBox/getStartoverAllfactories',
// 	    method:'get'
// 	})
// }

// export function fetchHouse(param){
// 	  return request({
// 			url: '/api-basic/basic/comboBox/warehouseToFactory',
// 			method:'get',
// 			params:param
// 		})
// }

// export function fetchBrands(){
// 	 return request({
// 		 url: '/api-basic/basic/comboBox/getBrandInfoList',
// 		 method:'get'
// 	 })
// }

// export function fetchUsers(param){
// 	  return request({
// 	    url: '/api-user/user/queryForListByOrg',
// 	    method:'get',
// 	    params:param
// 	 })
// }

// export function fetchOrgInfo(param){
// 	  return request({
// 	    url: '/api-basic/orgInfo/selectUpdateInfo',
// 	    method:'get',
// 	    params:param
// 	 })
// }

// export function fetchDel(param) {
//   return request({
//     url: '/api-basic/orgInfo/delete',
//     method: 'DELETE',
// 	  params:param
//   })
// }

function cancelUser(param) {
  return (0, _request.default)({
    url: modelUrl + '/orgInfo/deleteById',
    method: 'DELETE',
    params: param
  });
}