"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'order',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    taskVisible: {
      default: false
    }
  },
  computed: {},
  filters: {
    getStauts: function getStauts(v) {
      var name = '';
      var slist = [{
        name: '已创建',
        id: 1
      }, {
        name: '待审核',
        id: 2
      }, {
        name: '待下载',
        id: 3
      }, {
        name: '已下载',
        id: 4
      }, {
        name: '部分扫描',
        id: 5
      }, {
        name: '扫描完成',
        id: 6
      }, {
        name: '正常结单',
        id: 7
      }, {
        name: '强制结单',
        id: 8
      }, {
        name: '异常单据',
        id: 9
      }, {
        name: '撤销结单',
        id: 10
      }, {
        name: '撤销下载',
        id: 11
      }];
      for (var i = 0; i < slist.length; i++) {
        if (slist[i].id == v) {
          name = slist[i].name;
          break;
        }
      }
      return name;
    }
  },
  data: function data() {
    return {
      listQuery: {
        tradeBillQuery: {
          billNo: '',
          billType: 203,
          billSource: '',
          billStatus: '',
          productId: '',
          startTime: '',
          endTime: '',
          billSaleQuery: {
            inOrgId: '',
            isSplitBill: '',
            outOrgId: '',
            relateNo: '',
            saleOrgId: ''
          }
        },
        current: 1,
        size: 20
      },
      creatDate: [],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      inUseName: '',
      factoryShow: false,
      companyShow: false,
      productShow: false,
      dialogVisible: false,
      loading: false,
      storeList: [],
      orgList: [],
      statusList: [{
        name: '正常结单',
        id: 7
      }, {
        name: '强制结单',
        id: 8
      }],
      sourceList: [],
      splitedList: [{
        id: 0,
        name: '未拆单'
      }, {
        id: 1,
        name: '部分拆单'
      }, {
        id: 2,
        name: '已拆单'
      }],
      showCol: false,
      expandTxt: '展开'
    };
  },
  created: function created() {
    var that = this;
  },
  watch: {
    taskVisible: function taskVisible(val) {
      if (val) {
        this.getList();
        this.getFactoryInfoList();
        this.getStoreList();
      }
    }
  },
  methods: {
    getFactoryInfoList: function getFactoryInfoList() {
      //获取工厂下啦列表数据
      var that = this;
      that.OrgList = [];
      (0, _select.ccOrgInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          that.OrgList = res.data.data;
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      //获取库房下拉列表数据
      var that = this;
      that.storeList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          that.storeList = res.data.data;
        }
      }).catch(function () {});
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      if (that.creatDate.length > 0) {
        that.listQuery.tradeBillQuery.startTime = that.creatDate[0];
        that.listQuery.tradeBillQuery.endTime = that.creatDate[1];
      } else {
        that.listQuery.tradeBillQuery.startTime = '';
        that.listQuery.tradeBillQuery.endTime = '';
      }
      (0, _select.getSaleOutList)(that.listQuery.current, that.listQuery.size, that.listQuery.tradeBillQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.listQuery = {
        tradeBillQuery: {
          billNo: '',
          billType: 203,
          billSource: '',
          billStatus: 7,
          productId: '',
          startTime: '',
          endTime: '',
          billSaleQuery: {
            inOrgId: '',
            isSplitBill: '',
            outOrgId: '',
            relateNo: '',
            saleOrgId: ''
          }
        },
        current: 1,
        size: 20
      };
      this.getList();
    },
    //关闭弹窗
    handleClose: function handleClose() {
      var that = this;
      that.$emit('dialogHide');
    },
    //选择
    DoEdit: function DoEdit(row) {
      var that = this;
      that.$emit('select', JSON.stringify(row));
    },
    setFactory: function setFactory(v) {
      console.log(v);
    }
  }
};
exports.default = _default;