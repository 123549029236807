var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "main-content" },
        [
          _c(
            "el-col",
            { staticClass: "main" },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-scrollbar",
                    { staticStyle: { height: "103%" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            disabled: _vm.disabled,
                            rules: _vm.disabled ? {} : _vm.rules,
                            "label-width": "200px",
                            "label-suffix": "：",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "规则名称", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { maxlength: "30" },
                                model: {
                                  value: _vm.dataForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "name", $$v)
                                  },
                                  expression: "dataForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "导购员得积分数额",
                                prop: "amount",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { value: _vm.dataForm.amount },
                                on: {
                                  input: function (e) {
                                    return (_vm.dataForm.amount = _vm.numFun(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "范围类型", prop: "type" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "radio",
                                      model: {
                                        value: _vm.dataForm.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "type", $$v)
                                        },
                                        expression: "dataForm.type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "100" } },
                                        [_vm._v("按产品设置")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "101" } },
                                        [_vm._v("按数码段设置")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", { staticClass: "text-span" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataForm.type === "100"
                                          ? "按产品设置"
                                          : "按数码段设置"
                                      )
                                    ),
                                  ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dataForm.type === "100"
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "0px" },
                                    attrs: {
                                      "label-width": "0px",
                                      prop:
                                        _vm.dataForm.range === "2"
                                          ? "products"
                                          : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "product-btn" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: { click: _vm.productListShow },
                                          },
                                          [_vm._v(" 选 择 产 品")]
                                        ),
                                        _vm._v(" "),
                                        _c("i", [
                                          _vm._v("提示：请至少选择1个产品"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          data: _vm.list,
                                          border: "",
                                          "max-height": "300",
                                          "header-cell-style": {
                                            "background-color": "#fafafa",
                                            color: "rgba(0, 0, 0,0.85)",
                                            "font-weight": "500",
                                            "text-align": "center",
                                          },
                                          "cell-style": {
                                            "text-align": "center",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "NEWPSID",
                                            label: "产品编号",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "PRODUCT_NAME",
                                            label: "产品名称",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "PRODUCT_NORMS",
                                            label: "产品规格",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "content-pages" },
                                      [
                                        _c("Pagination", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.total > 0,
                                              expression: "total>0",
                                            },
                                          ],
                                          attrs: {
                                            total: _vm.total,
                                            layout:
                                              "total, prev, pager, next, jumper",
                                            page: _vm.PageForm.pageNo,
                                            limit: _vm.PageForm.pageSize,
                                          },
                                          on: {
                                            "update:page": function ($event) {
                                              return _vm.$set(
                                                _vm.PageForm,
                                                "pageNo",
                                                $event
                                              )
                                            },
                                            "update:limit": function ($event) {
                                              return _vm.$set(
                                                _vm.PageForm,
                                                "pageSize",
                                                $event
                                              )
                                            },
                                            pagination: _vm.pagingFun,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "输入数码区段",
                                      prop: "actSectionList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dataForm.actSectionList,
                                    function (item, index) {
                                      return _c(
                                        "el-row",
                                        { key: index, staticClass: "codeItem" },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "actSectionList." +
                                                      index +
                                                      ".sectionStart",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          "请输入起始区段数码",
                                                        trigger: "blur",
                                                      },
                                                      {
                                                        required: true,
                                                        validator:
                                                          _vm.sectionStart,
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      value: item.sectionStart,
                                                      placeholder:
                                                        "输入区段起始值",
                                                    },
                                                    on: {
                                                      input: function (e) {
                                                        return (item.sectionStart =
                                                          _vm.validTe(e))
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "line",
                                              attrs: { span: 1 },
                                            },
                                            [
                                              _c("i", { staticClass: "i" }, [
                                                _vm._v("~"),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "actSectionList." +
                                                      index +
                                                      ".sectionEnd",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          "请输入结束区段数码",
                                                        trigger: "blur",
                                                      },
                                                      {
                                                        required: true,
                                                        validator:
                                                          _vm.sectionEnd,
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      value: item.sectionEnd,
                                                      placeholder:
                                                        "输入区段结束值",
                                                    },
                                                    on: {
                                                      input: function (e) {
                                                        return (item.sectionEnd =
                                                          _vm.validTe(e))
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              index === 0 && !_vm.disabled
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "addCode",
                                                      attrs: {
                                                        type: "primary",
                                                        circle: "",
                                                        size: "mini",
                                                      },
                                                      on: { click: _vm.addFun },
                                                    },
                                                    [_vm._v("+")]
                                                  )
                                                : index + 1 <=
                                                    _vm.dataForm.actSectionList
                                                      .length && !_vm.disabled
                                                ? _c("el-button", {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "danger",
                                                      icon: "el-icon-delete",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteFun(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btns" },
                        [
                          _vm.type !== 2
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.go(-1)
                                      },
                                    },
                                  },
                                  [_vm._v("取 消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      loading: _vm.btnIs,
                                      type: "primary",
                                    },
                                    on: { click: _vm.submitFun },
                                  },
                                  [_vm._v("确 定")]
                                ),
                              ]
                            : [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.go(-1)
                                      },
                                    },
                                  },
                                  [_vm._v("确 定")]
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.productVisibleShow
                        ? _c("ProDialog", {
                            ref: "productVisible",
                            attrs: {
                              "business-type": "1",
                              "old-list": _vm.list,
                              "activite-id": _vm.activiteId,
                            },
                            on: {
                              fn: _vm.setProduct,
                              close: function ($event) {
                                _vm.productVisibleShow = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }