"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _auth = require("@/utils/auth");
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */

// import admincut from '@/components/admincut'

var baseUrl = '/wh';
var _default = {
  components: {
    TinyMce: _tinyMce.default
    // admincut
  },

  filters: {
    strsp: function strsp(val) {
      if (val.length > 4) {
        val = val.substring(0, 4) + '...';
      }
      return val;
    }
  },
  data: function data() {
    return {
      mstext: null,
      phoneList: [],
      creatload: false,
      rwzx: '',
      syxz1: '',
      syxz2: '',
      fwyzshop: false,
      tarr: [],
      fmurl: '',
      upxcxswich: true,
      qyarr: [],
      fkvip: 1,
      rightload: false,
      commonxcx: false,
      offxcx: false,
      // 等级会员卡创建
      levelset: 0,
      // 普通会员卡提交后操作
      ordinary: 0,
      // 普通会员卡等级会员卡切换
      listIndex: 0,
      tableData: [
        // { needTask: 10,
        //   title: '111',
        //   backgroundType: 1,
        //   backgroundImg: '#000',
        //   memberCradNumber: 'pytr',
        //   memberEquitylist: 'wewe'
        // }
      ],
      twImgurl: 'http://dev20gw.panpass.cn/images/',
      tabloading: false,
      multipleSelection: [],
      taskconterdl: false,
      upxcx: false,
      // 普通
      upxcx1: false,
      // 会员
      downxcx: false,
      downxcx1: false,
      vipinfo: false,
      outerVisible: false,
      outerVisible1: false,
      innerVisible: false,
      innerVisible1: false,
      addcustomdia: false,
      addcustomdia1: false,
      taskName: '',
      formr: {
        isRegisterMember: false,
        isPurchaseCommodity: false,
        isCounterfeiting: false,
        isFriend: false,
        isFollow: false,
        isBirthday: false,
        valueRegisterMember: '',
        taskCommodityList: [{
          taskConsumption: '',
          taskCompany: '',
          taskValue: ''
        }],
        programAppId: '',
        programUrl: '',
        taskCounterfeitingList: [{
          taskConsumption: '',
          taskValue: ''
        }],
        taskText: '',
        taskFriend: '',
        invitationTitle: '',
        taskExplain: '',
        invitationTitleId: '',
        taskFollow: '',
        followName: '',
        taskBirthday: '',
        appidUrl: ''
      },
      customdata: {
        name: '',
        type: ''
      },
      rightsform: {
        equityImg: '',
        equityName: '',
        equityExpain: ''
      },
      rightsData: [
        // {
        //   name: '1',
        //   icon: '2',
        //   sm: '3',
        //   type: '4'
        // },
        // {
        //   name: '1',
        //   icon: '1',
        //   sm: '3',
        //   type: '4'
        // }
      ],
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'companyId': localStorage.getItem('cid')
      },
      color: '',
      checkpay: false,
      checkmsg: false,
      checkpay1: false,
      checkmsg1: false,
      list: [{
        name: '普通会员卡'
      }, {
        name: '等级会员卡'
      }],
      fftxt: '',
      form: {
        title: '',
        backgroundType: '',
        noticeForUse: '',
        memberEquitylist: '',
        memberConditionType: '2',
        memberConditionPay: '2',
        memberConditionMsg: '2',
        memberCondition: '',
        backgroundImg: '',
        memberCradNumber: 0,
        qx: ''
      },
      form1: {
        title: '',
        needTask: '',
        id: '',
        missionCoefficient: 1,
        backgroundType: '',
        noticeForUse: '',
        memberEquitylist: '',
        memberConditionType: '2',
        memberConditionPay: '2',
        memberConditionMsg: '2',
        memberCondition: '',
        backgroundImg: '',
        memberCradNumber: ''
      },
      bgimgurl: '',
      bgimgurl1: '',
      qyiconurl: '',
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      fstpUrl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single2',
      dynamicTags: [],
      // tjTags: ['姓名', '手机号验证', '性别', '生日', '地区'],
      tjTags: [],
      qylist: [],
      taskindex: 0,
      taskNum: 0,
      xcxinfo: {
        appid: '',
        appurl: ''
      },
      eIndex: 0
    };
  },
  // computed: {
  //   // 设置上传地址
  //   fstpUrl() {
  //     return process.env.VUE_APP_BASE_API + '/api/v1/api-memberscrm/member/admin/file/upload/single2'
  //   }
  // },
  created: function created() {
    var _this = this;
    this.twImgurl = _wxHy.basesc;
    (0, _wxHy.getDataByMember)().then(function (res) {
      res = res.data;
      if (res.code === 200) {
        var generalMemberdata = res.data.generalMember.data;
        if (generalMemberdata === null) {
          return;
        } else {
          if (res.data.rule === '1') {
            _this.commonxcx = true;
            _this.listIndex = 1;
            _this.getlistvipseac();
          } else {
            _this.commonxcx = false;
          }
          _this.form = res.data.generalMember.data;
          _this.form.qx = 1;
          _this.strrep(_this.form.noticeForUse, '1');
          if (_this.form.backgroundType === '1') {
            _this.color = _this.form.backgroundImg;
            _this.bgimgurl = '';
          } else {
            _this.bgimgurl = _this.twImgurl + _this.form.backgroundImg;
          }
          _this.ordinary = 1;
          _this.fkvip = '';
          if (_this.form.memberConditionPay === '1') {
            _this.checkpay = true;
          } else {
            _this.checkpay = false;
          }
          if (_this.form.memberConditionMsg === '1') {
            _this.checkmsg = true;
          } else {
            _this.checkmsg = false;
          }
          _this.qylist = _this.form.moWxMemberEquityDOList;
          var c = [];
          _this.qylist.forEach(function (e) {
            c.push(e.id);
          });
          _this.qyarr = c;
        }
      }
    });

    // this.getlistvipseac()
    this.gettaskid();
    this.getPhonelist();
  },
  methods: {
    getPhonelist: function getPhonelist() {
      var _this2 = this;
      (0, _wxHy.getRuleMsg)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.ruleType === '2') {
            _this2.phoneList = res.data.taskVOList;
          } else {
            _this2.mstext = res.data.text;
          }
        }
      });
    },
    onXcx: function onXcx(val) {
      if (val === '1') {
        this.upxcx = true;
      } else {
        console.log(this.taskindex, (0, _typeof2.default)(this.taskindex));
        if (this.taskindex === '0') {
          this.$message('请先设置好会员卡规则才可以启用小程序');
          return false;
        } else {
          this.upxcx1 = true;
        }
      }
    },
    strrep: function strrep(val, num) {
      if (num === '1') {
        this.syxz1 = val.replace(/\n|\r\n/g, '<br>');
      } else {
        this.syxz2 = val.replace(/\n|\r\n/g, '<br>');
      }
    },
    coefficientnum: function coefficientnum(value) {
      // this.form1.missionCoefficient = Math.floor(this.form1.missionCoefficient * 10) / 10
      this.form1.missionCoefficient = Number(this.form1.missionCoefficient).toFixed(1);
    },
    bjimg: function bjimg(statuss, val) {
      if (val === '1') {
        if (status === '1') {
          this.form.backgroundImg = '';
          this.bgimgurl = '';
        } else {
          this.form.backgroundImg = '';
          this.color = '';
        }
      } else {
        if (status === '1') {
          this.form1.backgroundImg = '';
          this.bgimgurl1 = '';
        } else {
          this.form1.backgroundImg = '';
          this.color = '';
        }
      }
    },
    gettaskid: function gettaskid() {
      var _this3 = this;
      (0, _wxHy.selectTaskById)().then(function (res) {
        var that = _this3;
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data === null) {
            return;
          } else {
            that.formr = res.data;
            that.taskName = res.data.taskName;
            that.fmurl = that.twImgurl + res.data.appidUrl;
            var val = that.formr;
            if (val.isRegisterMember === '1') {
              that.formr.isRegisterMember = true;
            } else {
              that.formr.isRegisterMember = false;
            }
            if (val.isPurchaseCommodity === '1') {
              that.formr.isPurchaseCommodity = true;
            } else {
              that.formr.isPurchaseCommodity = false;
            }
            if (val.isCounterfeiting === '1') {
              that.formr.isCounterfeiting = true;
            } else {
              that.formr.isCounterfeiting = false;
            }
            if (val.isFriend === '1') {
              that.formr.isFriend = true;
            } else {
              that.formr.isFriend = false;
            }
            if (val.isFollow === '1') {
              that.formr.isFollow = true;
            } else {
              that.formr.isFollow = false;
            }
            if (val.isBirthday === '1') {
              that.formr.isBirthday = true;
            } else {
              that.formr.isBirthday = false;
            }
            if (val.taskCounterfeitingList === null) {
              that.formr.taskCounterfeitingList = [{
                taskConsumption: '',
                taskValue: ''
              }];
            }
            if (val.taskCommodityList === null) {
              that.formr.taskCommodityList = [{
                taskConsumption: '',
                taskCompany: '',
                taskValue: ''
              }];
            }
            console.log(that.formr);
          }
        } else {
          that.$message(res.msg);
        }
      });
    },
    taskbtn: function taskbtn(val) {
      console.log(this.formr);
      // this.gettaskid()
      // this.taskconterdl = true
      this.rwzx = val;
      this.$router.push({
        // name: 'viptask',
        name: 'wxAccountsvipmanageviptaskindex',
        params: {}
      });
    },
    editvip: function editvip() {
      this.ordinary = 0;
    },
    // 普通会员数据请求
    getcommondata: function getcommondata() {
      var _this4 = this;
      (0, _wxHy.getDataByMember)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          var generalMemberdata = res.data.generalMember.data;
          if (generalMemberdata === null) {
            console.log('2123123');
            return;
          } else {
            console.log('55555555');
            if (res.data.rule === '1') {
              _this4.commonxcx = true;
              // this.listIndex = 1
              // this.eIndex = 1
            } else {
              _this4.commonxcx = false;
            }
            _this4.form = res.data.generalMember.data;
            _this4.form.qx = 1;
            if (_this4.form.backgroundType === '1') {
              _this4.color = _this4.form.backgroundImg;
              _this4.bgimgurl = '';
            } else {
              _this4.bgimgurl = _this4.twImgurl + _this4.form.backgroundImg;
            }
            _this4.ordinary = 1;
            _this4.fkvip = '';
            if (_this4.form.memberConditionPay === '1') {
              _this4.checkpay = true;
            } else {
              _this4.checkpay = false;
            }
            if (_this4.form.memberConditionMsg === '1') {
              _this4.checkmsg = true;
            } else {
              _this4.checkmsg = false;
            }
            _this4.qylist = _this4.form.moWxMemberEquityDOList;
            var c = [];
            _this4.qylist.forEach(function (e) {
              c.push(e.id);
            });
            _this4.qyarr = c;
          }
        }
      });
    },
    // 生成二维码
    getscenebtn: function getscenebtn() {
      var that = this;
      var loading = that.$loading({
        lock: true,
        text: '生成二维码加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _wxHy.getScene)().then(function (res) {
        res = res.data;
        console.log(res);
        setTimeout(function () {
          window.location.href = that.twImgurl + res.msg;
          loading.close();
        }, 1500);
      });
    },
    // 会员信息
    vipinfobtn: function vipinfobtn() {
      var that = this;
      (0, _wxHy.getAppletMsg)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.xcxinfo.appid = res.data.appId;
          that.xcxinfo.appurl = res.data.appUrl;
        } else {
          that.$message(res.msg);
        }
      });
      that.vipinfo = true;
    },
    // 启动禁止小程序
    upxcxbtn: function upxcxbtn() {
      var that = this;
      that.upxcx = false;
      (0, _wxHy.startMember)({
        type: 1
      }).then(function (res) {
        res = res.data;
        console.log(res);
        that.$message(res.msg);
      });
      that.commonxcx = false;
    },
    upxcxbtn1: function upxcxbtn1() {
      var that = this;
      that.upxcx1 = false;
      (0, _wxHy.startMember)({
        type: 2
      }).then(function (res) {
        res = res.data;
        console.log(res);
        that.$message(res.msg);
      });
      that.offxcx = false;
    },
    downxcxbtn: function downxcxbtn() {
      var that = this;
      (0, _wxHy.startMember)({
        type: 1
      }).then(function (res) {
        res = res.data;
        console.log(res);
        that.$message(res.msg);
      });
      that.downxcx = false;
      that.commonxcx = true;
    },
    downxcxbtn1: function downxcxbtn1() {
      var that = this;
      (0, _wxHy.startMember)({
        type: 2
      }).then(function (res) {
        res = res.data;
        console.log(res);
        that.$message(res.msg);
      });
      that.downxcx1 = false;
      that.offxcx = true;
    },
    // 购买商品添加门槛
    reduceshoplist: function reduceshoplist(index, val) {
      val.splice(index, 1);
    },
    addshoplist: function addshoplist(index, val) {
      var a = {
        taskConsumption: '',
        taskCompany: '',
        taskValue: ''
      };
      if (val.length === 5) {
        this.$message.warning('最多添加4组门槛');
        return;
      }
      val.push(a);
    },
    // 防伪验证添加门槛
    reducefwlist: function reducefwlist(index, val) {
      val.splice(index, 1);
    },
    addfwlist: function addfwlist(index, val) {
      var a = {
        taskConsumption: '',
        taskValue: ''
      };
      if (val.length === 5) {
        this.$message.warning('最多添加4组门槛');
        return;
      }
      val.push(a);
    },
    notjvip: function notjvip(val) {
      if (val === '1') {
        console.log('1111');
        this.form.memberConditionPay = '2';
        this.form.memberConditionMsg = '2';
        this.checkpay = false;
        this.checkmsg = false;
      }
    },
    notjvip1: function notjvip1(val) {
      if (val === '1') {
        this.form1.memberConditionPay = '2';
        this.form1.memberConditionMsg = '2';
        this.checkpay1 = false;
        this.checkmsg1 = false;
      }
      // else {
      //   this.form1.memberConditionPay = '1'
      // }
    },
    payvip: function payvip(val) {
      // this.form.memberConditionType = '2'
      // if (this.form.memberConditionType === '1') {
      //   this.$message('无条件成为会员和付款成为会员只能选择一种会员')
      //   return false
      // }
      if (val) {
        // 是
        this.form.memberConditionType = '2';
        this.form.memberConditionPay = '1';
      } else {
        // 否
        this.form.memberConditionPay = '2';
      }
    },
    payvip1: function payvip1(val) {
      // this.form1.memberConditionType = '2'
      // if (this.form1.memberConditionType === '1') {
      //   return this.$message('无条件成为会员和付款成为会员只能选择一种会员')
      // }
      if (val) {
        // 是
        this.form1.memberConditionType = '2';
        this.form1.memberConditionPay = '1';
      } else {
        // 否
        this.form1.memberConditionPay = '2';
      }
    },
    textvip: function textvip(val) {
      if (val) {
        this.form.memberConditionMsg = '1';
        this.form.memberConditionType = '2';

        //  this.form.memberConditionType = '3'
      } else {
        this.form.memberConditionMsg = '2';
        //  this.form.memberConditionType = ''
      }
    },
    textvip1: function textvip1(val) {
      if (val) {
        this.form1.memberConditionMsg = '1';
        this.form1.memberConditionType = '2';
        // this.form1.memberConditionType = '3'
      } else {
        this.form1.memberConditionMsg = '2';
        // this.form1.memberConditionType = ''
      }
    },
    // 颜色选择器
    colorChang: function colorChang(val, num) {
      var that = this;
      if (num === '1') {
        that.form.backgroundImg = val;
      } else {
        that.form1.backgroundImg = val;
      }
    },
    // 会员卡取消操作
    upSubmit: function upSubmit(val) {
      var that = this;
      if (val === '1') {
        if (that.form.qx) {
          that.ordinary = 1;
        } else {
          that.form = {
            title: '',
            backgroundType: '',
            noticeForUse: '',
            memberEquitylist: '',
            memberConditionType: '2',
            memberConditionPay: '2',
            memberConditionMsg: '2',
            memberCondition: '',
            backgroundImg: '',
            memberCradNumber: 0
          };
          that.$message.info('取消保存');
        }
      } else {
        that.levelset = 0;
      }
    },
    // 等级会员创建编辑操作
    levelbtn: function levelbtn(val, num) {
      console.log(val);
      var that = this;
      that.levelset = 1;
      that.ordinary = 0;
      if (num === '1') {
        that.form1 = {
          title: '',
          needTask: '',
          id: '',
          missionCoefficient: 1,
          backgroundType: '',
          noticeForUse: '',
          memberEquitylist: '',
          memberConditionType: '2',
          memberConditionPay: '2',
          memberConditionMsg: '2',
          memberCondition: '',
          backgroundImg: ''
        };
        that.form1.memberCradNumber = val;
        that.bgimgurl1 = '';
        that.color = '';
        that.syxz2 = '';
        that.qyarr = [];
      } else {
        that.form1.id = val;
        (0, _wxHy.getDataById)({
          id: that.form1.id
        }).then(function (res) {
          res = res.data;
          console.log(res);
          if (res.code === 200) {
            that.form1 = res.data;
            // that.qyarr = res.data.memberEquitylist

            // that.qylist = res.data.moWxMemberConditionDOList
            that.strrep(that.form1.noticeForUse, '2');
            that.qylist = res.data.moWxMemberEquityDOList;
            console.log(that.qylist);
            if (res.data.backgroundType === '1') {
              that.color = res.data.backgroundImg;
              that.bgimgurl1 = '';
            } else {
              that.bgimgurl1 = that.twImgurl + res.data.backgroundImg;
            }
            console.log(that.qyarr);
            var aylist = res.data.memberEquitylist;
            that.qyarr = aylist.split(',');
            //  that.qylist = that.form1.
          } else {
            that.$message.warning(res.msg);
          }
        });
      }
      that.dynamicTags = [];
      that.tjTags = ['姓名', '手机号验证', '性别', '生日', '地区'];
      that.qylist = [];
    },
    // 提交任务中心
    savetask: function savetask(val) {
      var that = this;
      console.log(val);
      if (that.isNull(that.taskName)) {
        that.$message.warning('请填写任务名称');
        return false;
      }
      if (val.isRegisterMember === false && val.isPurchaseCommodity === false && val.isCounterfeiting === false && val.isFriend === false && val.isFollow === false && val.isBirthday === false) {
        that.$message.warning('至少选择一个选项');
        return false;
      }
      if (val.isRegisterMember === true) {
        if (val.valueRegisterMember === 0 || val.valueRegisterMember === '') {
          that.$message.warning('请填写注册会员任务值');
          return false;
        }
      }
      if (val.isPurchaseCommodity === true) {
        // for (let i = 0; i < val.taskCommodityList.length; i++) {
        //   if (that.formr.taskCommodityList[i].taskConsumption === '' || (that.formr.taskCommodityList[i] === '') || that.formr.tasktaskCommodityList[i].value === '') {
        //     return that.$message.warning('请填写消费任务规则')
        //   }
        // }
        if (val.taskCommodityList.length > 0) {
          var a;
          val.taskCommodityList.forEach(function (e) {
            if (e.taskConsumption < 1 || e.taskCompany === '' || e.taskValue < 1) {
              that.$message.warning('请填写消费任务规则');
              a = true;
              return false;
            }
          });
          if (a) {
            return false;
          }
        } else {
          var e = val.taskCommodityList[0];
          if (e.taskConsumption < 1 || e.taskCompany === '' || e.taskValue < 1) {
            that.$message.warning('请填写消费任务规则');
            return false;
          }
        }
        console.log(that.isNull(val.programAppId));
        if (that.isNull(val.programAppId)) {
          that.$message.warning('请填写小程序完整信息');
          return false;
        }
        // if ((that.isNull(val.programAppId)) && (!that.isNull(val.programUrl))) {
        //   that.$message.warning('请填写小程序完整信息')
        //   return false
        // }
      }

      if (val.isCounterfeiting === true) {
        // for (let i = 0; i < val.taskCounterfeitingList.length; i++) {
        //   if (val.taskCounterfeitingList[i].taskConsumption === ('' || 0) || val.taskCounterfeitingList[i].taskValue === ('' || 0)) {
        //     return that.$message.warning('请填写扫码规则完整信息')
        //   }
        // }
        if (val.taskCounterfeitingList.length > 0) {
          val.taskCounterfeitingList.forEach(function (e) {
            if (e.taskConsumption < 1 || e.taskValue < 1) {
              that.$message.warning('请填写扫码规则完整信息');
              return false;
            }
          });
        } else {
          var _e = val.taskCounterfeitingList[0];
          if (_e.taskConsumption < 1 || _e.taskValue < 1) {
            that.$message.warning('请填写扫码规则完整信息');
            return false;
          }
        }
      }
      if (val.isFriend === true) {
        if (val.taskFriend === '' || val.taskFriend === 0) {
          that.$message.warning('请填写分享值');
          return false;
        }
      }
      if (val.isFollow === true) {
        if (val.taskFollow === '' || val.appidUrl === '') {
          that.$message.warning('请填写完整公众号信息');
          return false;
        }
      }
      if (val.isBirthday === true) {
        if (val.taskBirthday === 0 || val.taskBirthday === '') {
          that.$message.warning('请填写生日任务值');
          return false;
        }
      }
      if (val.isRegisterMember === true) {
        val.isRegisterMember = 1;
      } else {
        val.isRegisterMember = 2;
      }
      if (val.isPurchaseCommodity === true) {
        val.isPurchaseCommodity = 1;
      } else {
        val.isPurchaseCommodity = 2;
      }
      if (val.isCounterfeiting === true) {
        val.isCounterfeiting = 1;
      } else {
        val.isCounterfeiting = 2;
      }
      if (val.isFriend === true) {
        val.isFriend = 1;
      } else {
        val.isFriend = 2;
      }
      if (val.isFollow === true) {
        val.isFollow = 1;
      } else {
        val.isFollow = 2;
      }
      if (val.isBirthday === true) {
        val.isBirthday = 1;
      } else {
        val.isBirthday = 2;
      }
      val.taskName = that.taskName;
      (0, _wxHy.updateTask)(val).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          that.$message.success('提交成功');
          if (that.rwzx === '2') {
            return;
          } else {
            that.$router.go(0);
          }
        } else {
          that.$message.warning(res.msg);
        }
      });
      that.taskName = '';
      that.formr = {
        isRegisterMember: false,
        isPurchaseCommodity: false,
        isCounterfeiting: false,
        isFriend: false,
        isFollow: false,
        isBirthday: false,
        valueRegisterMember: '',
        // money_purchase_commodity: '',
        // company_purchase_commodity: '',
        // task_purchase_commodity: '',
        taskCommodityList: [{
          taskConsumption: '',
          taskCompany: '',
          taskValue: ''
        }],
        programAppId: '',
        programUrl: '',
        // number_counterfeiting: '',
        // Integer: '',
        taskCounterfeitingList: [{
          taskConsumption: '',
          taskValue: ''
        }],
        taskText: '',
        taskFriend: '',
        invitationTitle: '',
        taskExplain: '',
        invitationTitleId: '',
        taskFollow: '',
        followName: '',
        taskBirthday: '',
        appidUrl: ''
      };
      // console.log(val)
      that.taskconterdl = false;
    },
    isNull: function isNull(val) {
      return val.replace(/(^\s*)|(\s*$)/g, '') === '';
    },
    // 添加条件
    addtj: function addtj(val) {
      var _this5 = this;
      var that = this;
      if (that.customdata.name.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请填写名称');
        return;
      }
      if (that.customdata.type === '') {
        that.$message.warning('请选择类型');
        return;
      }
      (0, _wxHy.createMemberCondition)(that.customdata).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.tjTags.push(that.customdata.name);
          that.$message.success('创建成功');
          // const a = []
          if (val === '1') {
            if (_this5.form.memberCondition) {
              _this5.form.memberCondition += ',' + res.data.id.toString();
            } else {
              _this5.form.memberCondition = '';
              _this5.form.memberCondition += res.data.id.toString();
            }
          } else {
            if (_this5.form1.memberCondition) {
              _this5.form1.memberCondition += ',' + res.data.id.toString();
            } else {
              _this5.form1.memberCondition = '';
              _this5.form1.memberCondition += res.data.id.toString();
            }
          }
        } else {
          that.$message.warning(res.msg);
        }
      });
      that.addcustomdia = false;
    },
    handleCloseqy: function handleCloseqy(tag) {
      this.qylist.splice(this.qylist.indexOf(tag), 1);
      console.log(tag);
      console.log(this.qyarr);
      this.qyarr.splice(this.qyarr.indexOf(tag.id), 1);
      console.log(this.qyarr);
      if (this.listIndex === 0) {
        this.form.memberEquitylist = this.qyarr.toString();
      } else {
        this.form1.memberEquitylist = this.qyarr.toString();
      }
    },
    handleClosetj: function handleClosetj(tag) {
      this.tjTags.splice(this.tjTags.indexOf(tag), 1);
    },
    // 多选
    handleSelectionChange: function handleSelectionChange(val) {
      var that = this;
      console.log(val);
      that.multipleSelection = val;
      // val.forEach(item => {
      //   item.operation = 2
      // })
      // val = this.tarr
      // that.multipleSelection.forEach((item) => {
      //   that.dynamicTags.push(item.equityName)
      //   c.push(item.id)
      // })
      // that.form.memberEquitylist = c.toString()
    },
    // handleSelectionChange1(val) {
    //   const that = this
    //   console.log(val)
    //   var c = []
    //   that.multipleSelection = val
    //   that.multipleSelection.forEach((item) => {
    //     that.dynamicTags.push(item.equityName)
    //     c.push(item.id)
    //   })
    //   that.form1.memberEquitylist = c.toString()
    // },
    // 添加权益
    addequitybtn: function addequitybtn(val) {
      var that = this;
      that.rightsform.equityName = '';
      that.qyiconurl = '';
      that.rightsform.equityExpain = '';
      that.rightsform.equityImg = '';
      if (val === '1') {
        that.innerVisible = true;
      } else {
        that.innerVisible1 = true;
      }
    },
    // 选择权益
    electequitybtn: function electequitybtn(val) {
      var that = this;
      if (val === '1') {
        that.outerVisible = true;
      } else {
        that.outerVisible1 = true;
      }
      console.log(that.qyarr);
      that.searchequity(that.qyarr);
      setTimeout(function () {
        that.rightsData.forEach(function (item) {
          if (item.operation === '2') {
            that.$refs.multipleTable.toggleRowSelection(item, true);
          }
        });
      }, 1000);
    },
    // 权益确定
    qybtn: function qybtn(num) {
      var that = this;
      // that.outerVisible = false
      that.dynamicTags = [];
      var c = [];
      that.multipleSelection.forEach(function (item) {
        // item.operation = 2
        that.dynamicTags.push(item.equityName);
        c.push(item.id);
      });
      if (num === '1') {
        that.form.memberEquitylist = c.toString();
      } else {
        that.form1.memberEquitylist = c.toString();
      }
      console.log(that.form.memberEquitylist);
      that.qyarr = c;
      that.outerVisible = false;
      that.outerVisible1 = false;
      // that.searchequity(that.qyarr)
      console.log(that.qyarr);
      that.qylist = that.multipleSelection;
      //  console.log(that.dynamicTags)
    },
    // 查询权益
    searchequity: function searchequity(val) {
      var a = val || [0];
      var that = this;
      // console.log()
      (0, _wxHy.getEquitysearch)(a).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          // console.log(res)
          that.rightsData = res.data;
        } else {
          that.$message.warning(res.msg);
        }
      });
      console.log(that.multipleSelection);
    },
    // 上传图片
    handleSuccess: function handleSuccess(response, file, fileList) {
      if (response.code * 1 === 200) {
        this.$message.success('上传成功');
        this.formr.appidUrl = response.data.mediaId;
        this.fmurl = URL.createObjectURL(file.raw);
      } else {
        this.$message({
          message: response.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    imgSize: function imgSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    handError: function handError() {
      this.$message.error('上传失败');
    },
    changewe: function changewe(val) {
      this.formr.text = this.totet(val);
    },
    totet: function totet(val) {
      return val.replace(/<\/?p[^>]*>/gi, '');
    },
    handleClose: function handleClose(done) {
      done();
    },
    onSubmit: function onSubmit(val, num) {
      var _this6 = this;
      var that = this;
      console.log(val);
      if (num === '1') {
        // that.ordinary = 1

        if (val.title.replace(/(^\s*)|(\s*$)/g, '') === '' || val.title === null) {
          return that.$message.warning('请填写标题');
        }
        if (val.backgroundType === '' || val.backgroundType === null) {
          return that.$message.warning('请选择背景类型');
        }
        if (val.backgroundImg === '' || val.backgroundImg === null) {
          return that.$message.warning('请选择背景色或背景图');
        }
        if (val.noticeForUse.replace(/(^\s*)|(\s*$)/g, '') === '' || val.noticeForUse === null) {
          return that.$message.warning('请填写须知');
        }
        if (val.memberEquitylist === '' || val.memberEquitylist === null) {
          return that.$message.warning('请选择会员权益');
        }
        // if (val.memberConditionType === '2' && val.memberConditionPay === '2' && val.memberConditionMsg === '2') {
        //   return that.$message.warning('请选择会员条件类型')
        // }
        // if (val.memberConditionPay === '1') {
        //   if (val.memberConditionMay === '' || val.memberConditionMay === null || val.memberConditionMay === '0') {
        //     return that.$message.warning('请填写正确的付款会员金额')
        //   }
        // }
        // createGeneralMember(val).then(res => {
        //   if (res.code === 200) {
        //     that.$message.success('创建会员卡成功')
        //     that.ordinary = 1
        //   } else {
        //     that.$message.warning(res.msg)
        //   }
        // })
      } else {
        if (val.title.replace(/(^\s*)|(\s*$)/g, '') === '' || val.title === null) {
          return that.$message.warning('请填写标题');
        }
        if (val.backgroundType === '' || val.backgroundType === null) {
          return that.$message.warning('请选择背景类型');
        }
        if (val.backgroundImg === '' || val.backgroundImg === null) {
          return that.$message.warning('请选择背景色或背景图');
        }
        if (val.needTask < 0 || val.needTask === null || val.needTask === '') {
          return that.$message.warning('请填写所需任务值');
        }
        if (val.missionCoefficient < 0 || val.missionCoefficient === null || val.missionCoefficient === '') {
          return that.$message.warning('请填写任务值系数');
        }
        if (val.noticeForUse.replace(/(^\s*)|(\s*$)/g, '') === '' || val.noticeForUse === null) {
          return that.$message.warning('请填写须知');
        }
        if (val.memberEquitylist === '' || val.memberEquitylist === null) {
          return that.$message.warning('请选择会员权益');
        }
        // if (val.memberConditionType === '2' && val.memberConditionPay === '2' && val.memberConditionMsg === '2') {
        //   return that.$message.warning('请选择会员条件类型')
        // }
        // if (val.memberConditionPay === '1') {
        //   if (val.memberConditionMay === '' || val.memberConditionMay === null || val.memberConditionMay === '0') {
        //     return that.$message.warning('请填写正确的付款会员金额')
        //   }
        // }
      }

      (0, _wxHy.createGeneralMember)(val).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          that.$message.success('创建会员卡成功');
          that.levelset = 0;
          if (num === '1') {
            _this6.ordinary = 1;
          }
          that.getlistvipseac();
        } else {
          that.$message.warning(res.msg);
        }
      });
      console.log(val);
      console.log(this.color);
      // this.ordinary = 1
    },
    onSubmitqy: function onSubmitqy(val, num) {
      console.log(val);
      var that = this;
      console.log(that.rightsform.equityName.length);
      if (that.rightsform.equityName.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请输入权益名称');
        return;
      }
      if (that.rightsform.equityImg === '') {
        that.$message.warning('请上传权益图标');
        return;
      }
      if (that.rightsform.equityExpain.replace(/(^\s*)|(\s*$)/g, '') === '') {
        that.$message.warning('请输入权益说明');
        return;
      }
      (0, _wxHy.createMemberEquity)(that.rightsform).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.$message.success('添加权益成功');
          // that.innerVisible = false
        } else {
          that.$message.warning(res.msg);
        }
        // that.rightload = true
        // that.searchequity()
        // that.rightload = false
      });

      if (num === '1') {
        this.innerVisible = false;
        this.outerVisible = false;
      } else {
        this.innerVisible1 = false;
        this.outerVisible1 = false;
      }
    },
    getlistvipseac: function getlistvipseac() {
      var that = this;
      (0, _wxHy.getlistvipsearch)().then(function (res) {
        console.log('res11', res);
        res = res.data;
        if (res.code === 200) {
          // var aslist = res.data.memberEquityList
          // if (aslist[0].createTime === null) {
          //   return false
          // }
          that.tableData = res.data.memberEquityList;
          console.log('tableData', that.tableData);
          that.taskindex = res.data.type;
          that.taskNum = res.data.munber;
          if (res.data.rule === '1') {
            that.offxcx = true;
          } else {
            that.offxcx = false;
          }
        }
        that.tabloading = false;
      });
    },
    togle: function togle(index) {
      this.listIndex = index;
      this.qyarr = [];
      //  this.tabloading = true
      if (index === 1) {
        this.getlistvipseac();
      } else {
        this.getcommondata();
      }
    },
    // 上传封面成功
    handleAvatarSuccessbj: function handleAvatarSuccessbj(response, file, fileList) {
      console.log(response);
      if (response.code * 1 === 200) {
        this.form.backgroundImg = response.data.url;
        console.log(this.form.backgroundImg);
      } else {
        this.$message({
          message: '请重新选取封面',
          type: 'error',
          duration: 3 * 1000
        });
      }
      this.bgimgurl = URL.createObjectURL(file.raw);
    },
    // 上传封面成功
    handleAvatarSuccessbj1: function handleAvatarSuccessbj1(response, file, fileList) {
      console.log(response);
      if (response.code * 1 === 200) {
        this.form1.backgroundImg = response.data.url;
      } else {
        this.$message({
          message: '请重新选取封面',
          type: 'error',
          duration: 3 * 1000
        });
      }
      this.bgimgurl1 = URL.createObjectURL(file.raw);
    },
    // 上传权益图标成功
    handleAvatarSuccessqy: function handleAvatarSuccessqy(response, file, fileList) {
      console.log(response);
      if (response.code * 1 === 200) {
        this.rightsform.equityImg = response.data.mediaId;
      } else {
        this.$message({
          message: '请重新选取封面',
          type: 'error',
          duration: 3 * 1000
        });
      }

      // 上传图片url给左侧
      // console.log(this.imgIndex)
      this.qyiconurl = URL.createObjectURL(file.raw);
      // 给tw添加url地址
      // this.tw[this.ele].img = this.imageUrl[this.ele].url
    },
    imgUploaderr: function imgUploaderr() {
      this.$message.closeAll();
      this.$message({
        message: '上传失败，请重试！',
        type: 'error',
        duration: 3 * 1000
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isImg = /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name);
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) {
        this.$message.error('上传图片只能是 gif/jpg/jpeg/png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isImg && isLt2M;
    },
    handleInput: function handleInput(e) {
      console.log(e);
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.form.memberConditionMay = e.target.value;
      } else if (e.target.value.length < this.form.memberConditionMay.length) {
        this.form.memberConditionMay = e.target.value;
      }
      if (this.form.memberConditionMay === '0') {
        this.$message('不能为0元');
        return;
      }
      this.$refs.inputss.value = this.form.memberConditionMay;
    },
    handleInput1: function handleInput1(e) {
      console.log(e);
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.form1.memberConditionMay = e.target.value;
      } else if (e.target.value.length < this.form1.memberConditionMay.length) {
        this.form1.memberConditionMay = e.target.value;
      }
      if (this.form1.memberConditionMay === '0') {
        this.$message('不能为0元');
        return;
      }
      this.$refs.inputsss.value = this.form1.memberConditionMay;
    }
  }
};
exports.default = _default;