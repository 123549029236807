var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "page-section",
          },
          [
            _c("div", { staticClass: "head" }, [_vm._v("终端")]),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "listQuery1",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.listQuery1,
                  "status-icon": "",
                  "label-width": "140px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "返利申请日:" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "31",
                        clearable: "",
                        placeholder: "请输入",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkDate("begin", "1")
                        },
                      },
                      model: {
                        value: _vm.listQuery1.rebateBeginTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery1, "rebateBeginTime", $$v)
                        },
                        expression: "listQuery1.rebateBeginTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "rangeTxt" }, [_vm._v("至")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "31",
                        clearable: "",
                        placeholder: "请输入",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkDate("end", "1")
                        },
                      },
                      model: {
                        value: _vm.listQuery1.rebateEndTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery1, "rebateEndTime", $$v)
                        },
                        expression: "listQuery1.rebateEndTime",
                      },
                    }),
                    _vm._v(" "),
                    false
                      ? _c("el-date-picker", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.getValue1 },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "返利最低申请金额:" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        type: "number",
                        clearable: "",
                        placeholder: "请输入",
                      },
                      on: { blur: _vm.getInputV1 },
                      model: {
                        value: _vm.listQuery1.rebateMinimumAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery1, "rebateMinimumAmount", $$v)
                        },
                        expression: "listQuery1.rebateMinimumAmount",
                      },
                    }),
                    _vm._v("元\n      "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "返利说明:" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "400px" } },
                    [
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "auto-upload": false,
                                "show-file-list": false,
                                "on-change": _vm.handleChange.bind(null, {
                                  index: 1,
                                }),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "只能上传jpg/png文件，建议尺寸：753*1335像素"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.imageUrl1
                        ? _c("div", { staticClass: "imgc" }, [
                            _c("div", { staticClass: "closebar" }, [
                              _c("i", {
                                staticClass: "el-icon-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeImg("1")
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "previewImg",
                              attrs: { src: _vm.imageUrl1 },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "现金返利设置:" } }, [
                  _c(
                    "div",
                    { staticClass: "itemSet" },
                    _vm._l(_vm.companys, function (item) {
                      return _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("div", [_vm._v(_vm._s(item.orgName))]),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: item.cashRebateType,
                                callback: function ($$v) {
                                  _vm.$set(item, "cashRebateType", $$v)
                                },
                                expression: "item.cashRebateType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v(
                                  "店长(系统默认的终端管理员角色)获取红包"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("收货人获取红包"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "middle-line" }),
            _vm._v(" "),
            _c("div", { staticClass: "head" }, [_vm._v("经销商")]),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "listQuery2",
                staticClass: "uploadForm",
                attrs: {
                  model: _vm.listQuery2,
                  "status-icon": "",
                  "label-width": "140px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "返利申请日:" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请输入",
                        type: "number",
                        min: "1",
                        max: "31",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkDate("begin", "2")
                        },
                      },
                      model: {
                        value: _vm.listQuery2.rebateBeginTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery2, "rebateBeginTime", $$v)
                        },
                        expression: "listQuery2.rebateBeginTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "rangeTxt" }, [_vm._v("至")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请输入",
                        type: "number",
                        min: "1",
                        max: "31",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.checkDate("end", "2")
                        },
                      },
                      model: {
                        value: _vm.listQuery2.rebateEndTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery2, "rebateEndTime", $$v)
                        },
                        expression: "listQuery2.rebateEndTime",
                      },
                    }),
                    _vm._v(" "),
                    false
                      ? _c("el-date-picker", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.getValue2 },
                          model: {
                            value: _vm.value2,
                            callback: function ($$v) {
                              _vm.value2 = $$v
                            },
                            expression: "value2",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "返利最低申请金额:" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        type: "number",
                        clearable: "",
                        placeholder: "请输入",
                      },
                      on: { blur: _vm.getInputV2 },
                      model: {
                        value: _vm.listQuery2.rebateMinimumAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery2, "rebateMinimumAmount", $$v)
                        },
                        expression: "listQuery2.rebateMinimumAmount",
                      },
                    }),
                    _vm._v("元\n      "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "返利说明:" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "400px" } },
                    [
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "auto-upload": false,
                                "show-file-list": false,
                                "on-change": _vm.handleChange.bind(null, {
                                  index: 2,
                                }),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "只能上传jpg/png文件，建议尺寸：753*1335像素"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.imageUrl2
                        ? _c("div", { staticClass: "imgc" }, [
                            _c("div", { staticClass: "closebar" }, [
                              _c("i", {
                                staticClass: "el-icon-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeImg("2")
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "previewImg",
                              attrs: { src: _vm.imageUrl2 },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hr-space" }, [
              _c(
                "div",
                { staticClass: "btn-group-center" },
                [
                  _vm.hasAuth("savebtn", _vm.apis)
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { padding: "10px 30px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.DoSave },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(0)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }