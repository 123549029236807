var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "详情",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "append-to-body": "",
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "page-section-1", staticStyle: { padding: "0 20px" } },
          [
            _vm.workId
              ? _c("workBasic", { attrs: { "p-id": _vm.workId } })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "middleLine" }),
            _vm._v(" "),
            _vm.url ? _c("workYw", { attrs: { url: _vm.url } }) : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "middleLine" }),
            _vm._v(" "),
            _vm.workId
              ? _c("workFlow", { attrs: { "p-id": _vm.workId } })
              : _vm._e(),
            _vm._v(" "),
            _vm.check
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "middleLine" }),
                    _vm._v(" "),
                    _vm.workId && _vm.taskId
                      ? _c("workVerify", {
                          attrs: { "p-id": _vm.workId, "task-id": _vm.taskId },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.check
              ? _c(
                  "div",
                  {
                    staticClass: "align-right",
                    staticStyle: { padding: "20px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }