"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _outstock = require("@/api/chanelmange/outstock");
var _scrollTo = require("@/utils/scroll-to");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _BatchDialog = _interopRequireDefault(require("@/components/BatchDialog"));
var _templateManage = require("@/api/market/templateManage");
var _organ = _interopRequireDefault(require("@/components/organ"));
var _qdckDialog = _interopRequireDefault(require("@/components/qdckDialog"));
var _ccckDialog = _interopRequireDefault(require("@/components/ccckDialog"));
var _basicConfig = require("@/api/market/basicConfig");
var _policyDialog = _interopRequireDefault(require("@/components/policyDialog"));
var _prizeMange = require("@/api/market/prizeMange");
var _timers = require("timers");
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable vue/require-default-prop */
/* eslint-disable vue/no-unused-components */
/* eslint-disable eqeqeq */
var _default = {
  components: {
    ProDialog: _productDialog.default,
    BatchDialog: _BatchDialog.default,
    organ: _organ.default,
    borgan: _qdckDialog.default,
    PolicyDialog: _policyDialog.default,
    ccDiaolog: _ccckDialog.default
  },
  filters: {
    intToChinese: function intToChinese(index) {
      var comtype = {
        0: '奖项一：',
        1: '奖项二：',
        2: '奖项三：',
        3: '奖项四：',
        4: '奖项五：',
        5: '奖项六：',
        6: '奖项七：',
        7: '奖项八：',
        8: '奖项九：',
        9: '奖项十：'
      };
      var result = comtype[index];
      return result;
    }
  },
  props: {
    dialogVisible: Boolean,
    type: null,
    actNo: null,
    showOnly: null,
    num: null,
    copy: null
  },
  data: function data() {
    var checkEmail = function checkEmail(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入邮箱地址'));
      } else {
        callback();
      }
    };
    var _this = this;
    return {
      awardsoptions: [{
        value: 2,
        label: '均匀布奖'
      }, {
        value: 1,
        label: '随机布奖'
      }],
      awards: '',
      usable: '',
      btnLoading: false,
      showOther: false,
      o_batcheVisibleShow: false,
      b_batcheVisibleShow: false,
      cc_batcheVisibleShow: false,
      productVisibleShow: false,
      batcheVisibleShow: false,
      PolicyDialog: false,
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      pickerOptions2: {
        disabledDate: function disabledDate(time) {
          var aTime = _this.pageOneForm.dataTimeRange;
          var start = aTime[0] ? aTime[0].replace(/\-/g, '/') : '';
          var end = aTime[1] ? aTime[1].replace(/\-/g, '/') : '';
          return time.getTime() < new Date(start).getTime() || time.getTime() > new Date(end).getTime();
        }
      },
      listLoading: true,
      active: 0,
      base: {
        outNum: '',
        sellerName: ''
      },
      productList: [],
      // 基础设置
      pageOneForm: {
        // orderSource: '',
        // orderShip: '',
        orderReceipt: '',
        id: null,
        dataTimeRange: [],
        type: '1',
        name: '',
        actExplain: '',
        actRules: '',
        product: '',
        awardorder: '',
        batch: '',
        wxOfficialId: '',
        lq: [],
        payway: ['红包'],
        productIdList: [],
        redOrTransfer: 0,
        policy: '',
        distributor: '',
        o_batch: '',
        o_productIdList: [],
        b_batch: '',
        b_productIdList: [],
        organization: '',
        participant: '0',
        actRConfigOrder: [],
        actType: 2,
        followOfficial: '0'
      },
      // 活动项设置
      pageTwoForm: {
        perPersonJoinTimes: '',
        perPersonDayJoinTimes: '',
        perPersonHitTimes: '',
        perPersonDayHitTimes: '',
        actHasWarning: '0',
        actWarningEmail: '',
        actWarningThreshold: '10',
        actWarningRate: '',
        actEndtimeWarning: '0',
        actEndtimeWarningDay: '',
        actEndtimeWarningRate: ''
      },
      // 红包
      codeForm: {
        codeUsual: '谢谢惠顾',
        probability: '',
        num: '不限量',
        prizenum: 1000,
        surplus: '',
        calculation: '',
        actJf: '',
        prizeList: [{
          type: '1',
          prize: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          type: '3',
          prize: '谢谢惠顾',
          scale: '',
          amount: '9999999',
          prizeId: '',
          costprize: 0,
          prizeType: '5000'
        }]
      },
      // 集字
      characterForm: {
        toplimitDay: '',
        toplimitPerson: '',
        characterGathering: 1,
        isRare: '',
        // total: 0,
        wordList: [{
          wordcontent: '',
          probability: '',
          wordnum: ''
        }, {
          wordcontent: '',
          probability: '',
          wordnum: ''
        }],
        wordUsual: '',
        probabilityUsual: '',
        prizeList: [{
          type: '1',
          words: [],
          prizeType: '',
          amount: '',
          dataRange: [],
          budget: '',
          prizeExplain: '',
          prizeId: '',
          costprize: 0
        }]
      },
      // 转盘
      panForm: {
        size: 8,
        tableData: [{
          prizeLevel: 1,
          type: '3',
          name: '谢谢惠顾',
          img: require('@/assets/images/xxhg.png'),
          scale: '',
          amount: '不限制',
          costprize: 0,
          prizeId: 1,
          prizeType: '5000'
        }, {
          prizeLevel: 2,
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0,
          prizeId: ''
        }, {
          prizeLevel: 3,
          prizeId: '',
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 4,
          prizeId: '',
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 5,
          prizeId: '',
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 6,
          prizeId: '',
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 7,
          prizeId: '',
          type: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }, {
          prizeLevel: 8,
          type: '',
          prizeId: '',
          img: '',
          scale: '',
          amount: '',
          costprize: 0
        }]
      },
      // 活动预期
      expectForm: {
        hasAnticipate: '0',
        expectTable: [{
          startTime: '',
          endTime: '',
          actPar: '',
          actParnum: '',
          addMerberNum: '',
          prizePercent: ''
        }, {
          startTime: '',
          endTime: '',
          actPar: '',
          actParnum: '',
          addMerberNum: '',
          prizePercent: ''
        }]
      },
      // 基础设置验证
      pageOneRules: {
        name: [{
          required: true,
          message: '活动名称不能为空',
          trigger: 'change'
        }],
        dataTimeRange: [{
          required: true,
          message: '请选择活动日期',
          trigger: 'change'
        }],
        actRules: [{
          required: true,
          message: '活动规则不能为空',
          trigger: 'change'
        }],
        participant: [{
          required: true,
          message: '请选择活动对象',
          trigger: 'change'
        }],
        templateId: [{
          required: true,
          message: '请选择模板',
          trigger: 'change'
        }],
        wxOfficialId: [{
          required: true,
          message: '请选择活动公众号',
          trigger: 'change'
        }],
        product: [{
          required: true,
          message: '请选择产品',
          trigger: 'change'
        }],
        b_batch: [{
          required: true,
          message: '请选择订单',
          trigger: 'change'
        }],
        followOfficial: [{
          required: true,
          message: '请选择是否关注公众号',
          trigger: 'change'
        }]
      },
      // 活动项设置校验
      pageTwoFormRules: {
        actWarningEmail: [{
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }],
        actWarningRate: [{
          required: true,
          message: '请选择奖品预警频率',
          trigger: 'change'
        }],
        actWarningThreshold: [{
          required: true,
          message: '请选填写奖品预警阀值',
          trigger: 'change'
        }],
        actEndtimeWarningDay: [{
          required: true,
          message: '请选填写活动结束预警阀值',
          trigger: 'change'
        }],
        actEndtimeWarningRate: [{
          required: true,
          message: '请选择活动结束预警频率',
          trigger: 'change'
        }]
      },
      //
      codeFormRules: {
        calculation: [{
          required: true,
          message: '请选择布奖形式',
          trigger: 'change'
        }]
      },
      characterFormRules: {
        //   characterGathering: [
        //     { required: true, message: '请选择集字玩法', trigger: 'change' }
        //   ]
      },
      panFormRules: {},
      mbList: [],
      wcList: [],
      pList: []
    };
  },
  computed: {
    // 计算布奖订单数量
    numAward: function numAward() {
      var num = 0;
      if (this.base.outNum) {
        num = this.base.outNum - this.usable;
      }
      return Number(num);
    },
    // 红包概率和
    probabilityC: function probabilityC() {
      var num = 100;
      this.codeForm.prizeList.forEach(function (item) {
        if (item.prizeType != 5000) {
          num = num - item.scale * 1;
        }
      });
      return num.toFixed(2);
    },
    // 转盘概率和
    probabilityP: function probabilityP() {
      var num = 100;
      this.panForm.tableData.forEach(function (item) {
        var sum = item.scale * 1;
        num = num - sum;
      });
      return num.toFixed(2);
    },
    // 集字概率和
    probabilityZ: function probabilityZ() {
      var num = 100;
      this.characterForm.wordList.forEach(function (item) {
        var sum = item.probability * 1;
        num = num - sum;
      });
      return num.toFixed(2);
    },
    // 预期概率和
    probabilityY: function probabilityY() {
      var num = 100;
      this.expectForm.expectTable.forEach(function (item) {
        var sum = item.prizePercent * 1;
        num = num - sum;
      });
      return num.toFixed(2);
    },
    // 集字活动预算
    wordTotal: function wordTotal() {
      var total = 0;
      this.characterForm.prizeList.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2) + '元' || 0;
    },
    // 红包活动预算
    codeTotal: function codeTotal() {
      var total = 0;
      this.codeForm.prizeList.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2) + '元';
    },
    // 大转盘活动预算
    panTotal: function panTotal() {
      var total = 0;
      this.panForm.tableData.forEach(function (item) {
        if (item.type * 1 == 1) {
          total += item.costprize * item.amount;
        }
      });
      return total.toFixed(2);
    }
  },
  watch: {
    numAward: function numAward(newValue, oldValue) {
      this.changeValue();
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.setPan(8);
    (0, _templateManage.getList)({
      pageNo: 1,
      pageSize: 9999,
      templateType: this.type,
      state: this.num == 1 ? '' : '1'
    }).then(function (res) {
      res = res.data;
      if (res.status * 1 === 200) {
        _this2.mbList = res.data.list;
      } else {
        _this2.$message.closeAll();
        _this2.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    });
    (0, _basicConfig.getWechatList)().then(function (res) {
      res = res.data;
      if (res.state === '200') {
        _this2.wcList = res.data;
      } else {
        _this2.$message.closeAll();
        _this2.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    });
    (0, _prizeMange.getPList)({
      pageindex: 1,
      pagesize: 9999,
      state: this.num == 1 ? '' : '1'
    }).then(function (res) {
      res = res.data;
      if (res.state === '200') {
        _this2.pList = res.data.list;
      } else {
        _this2.$message.closeAll();
        _this2.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    }).then(function (res) {
      if (_this2.actNo) {
        (0, _activityManage.detailpage)({
          actNo: _this2.actNo
        }).then(function (res) {
          res = res.data;
          console.log(res, 'res');
          if (res.status * 1 === 200) {
            var product = [];
            res.data.dataTimeRange = [res.data.startTime, res.data.endTime];
            switch (res.data.hasInfo) {
              case '000':
                res.data.lq = [];
                break;
              case '100':
                res.data.lq = ['1'];
                break;
              case '110':
                res.data.lq = ['1', '2'];
                break;
              case '101':
                res.data.lq = ['1', '3'];
                break;
              case '111':
                res.data.lq = ['1', '2', '3'];
                break;
              case '010':
                res.data.lq = ['2'];
                break;
              case '011':
                res.data.lq = ['2', '3'];
                break;
              case '001':
                res.data.lq = ['3'];
                break;
            }
            var aObj = [];
            var aId = [];
            // res.data.products.forEach(item => {
            //   product.push(item.productName)
            //   var obj = {
            //     pId: item.productId,
            //     pIproductBrandIdd: item.productBrand,
            //     productName: item.productName,
            //     productCode: item.productNo,
            //     productClassId: item.productType,
            //     productModel: item.productNorms
            //   }
            //   aObj.push(obj)
            //   aId.push(item.productId)
            // })
            var orderSource;
            res.data.order.forEach(function (item) {
              aObj.push(item.orderNo);
              orderSource = item.orderSource;
            });
            var aBatche = [];
            res.data.batcheNos.forEach(function (item) {
              aBatche.push(item.batchNo);
            });
            res.data.productIdList = aId;
            res.data.batch = aBatche.join(',');
            // res.data.productList = aObj
            res.data.b_batch = aObj[0];
            _this2.base.outNum = res.data.order[0].boxNum;
            if (orderSource == 2) {
              (0, _outstock.outstockdetail)({
                billNo: aObj[0]
              }).then(function (res) {
                var data = res.data;
                if (data.code === 200) {
                  if (data.data.product) {
                    _this2.productList = data.data.product;
                    _this2.base.sellerName = data.data.base.sellerName;
                  }
                }
              });
            } else {
              (0, _outstock.queryPrizeTradeForPage)({
                pageNo: 1,
                pageSize: 10,
                billNo: aObj[0],
                endTime: '',
                inOrgName: '',
                outOrgName: '',
                startTime: ''
              }).then(function (res) {
                res = res.data;
                if (res.code * 1 === 200) {
                  _this2.productList = res.data.records[0].prizeTradeDetailVOS;
                  _this2.productList.forEach(function (val) {
                    val.outNum = val.totalBottleNum;
                  });
                  _this2.base.sellerName = res.data.records[0].outOrgName;
                } else {
                  _this2.$message.closeAll();
                  _this2.$message({
                    message: res.msg,
                    type: 'error',
                    duration: 3 * 1000
                  });
                }
              });
            }
            console.log(res.data.batcheNos, 'ssss');
            (0, _activityManage.getorderNum)({
              orderNo: aObj[0]
            }).then(function (res) {
              if (res.status === 200) {
                _this2.usable = res.data.data;
                // if (this.copy) {
                // const obj = {
                //   boxNum: data[0].outNum,
                //   effectiveNum: data[0].outNum - this.usable,
                //   orderId: this.orderNo,
                //   orderNo: aObj[0],
                //   orderType: data[0].orderStatus
                // }
                // this.pageOneForm.actRConfigOrder = [obj]
                // }
              }
            });

            res.data.batchList = res.data.batcheNos;
            res.data.product = product.join(',');
            // this.pageOneForm = Object.assign(this.pageOneForm, res.data)
            _this2.pageOneForm = res.data;
            _this2.pageOneForm.orderSource = orderSource;
            _this2.pageTwoForm = res.data;
            _this2.codeForm.actJf = res.data.actJf;
            _this2.pageTwoForm.actEndtimeWarningRate = Number(res.data.actEndtimeWarningRate);
            _this2.pageTwoForm.actEndtimeWarning = res.data.actEndtimeWarning.toString();
            _this2.codeForm.calculation = res.data.calculation;
            _this2.pageOneForm.actRConfigOrder = res.data.order;
            switch (_this2.type * 1) {
              case 1:
                _this2.characterForm.characterGathering = res.data.actWordTeype * 1;
                // this.characterForm.prizeList = res.data.prizesConfig
                res.data.prizesConfig.forEach(function (val, key) {
                  if (key * 1 !== 0) {
                    _this2.characterForm.prizeList.push({
                      words: [],
                      prizeType: '',
                      amount: '',
                      dataRange: [],
                      budget: '',
                      prizeExplain: '',
                      prizeId: '',
                      costprize: 0
                    });
                  }
                  _this2.characterForm.prizeList[key].dataRange = [val.prizeStarttime, val.prizeEndtime];
                  if (val.prizeType != 6000) {
                    _this2.characterForm.prizeList[key].type = '1';
                  } else {
                    _this2.characterForm.prizeList[key].type = '2';
                    _this2.characterForm.prizeList[key].integral = val.integral;
                  }
                  _this2.characterForm.prizeList[key].amount = val.amount;
                  _this2.characterForm.prizeList[key].prizeId = val.prizeId;
                  _this2.characterForm.prizeList[key].prizeExplain = val.prizeExplain;
                  val.wordRuleIds.forEach(function (item) {
                    var num = item.wordorder * 1 - 1;
                    _this2.characterForm.prizeList[key].words.push(num);
                  });
                  // val.dataRange = [val.prizeStarttime, val.prizeEndtime]
                  // val.type = '1'
                  _this2.pList.forEach(function (item) {
                    if (item.id === val.prizeId) {
                      _this2.characterForm.prizeList[key].costprize = 0;
                      if (item.costprize) {
                        _this2.characterForm.prizeList[key].costprize = item.costprize * 1;
                      }
                      _this2.characterForm.prizeList[key].prizeType = item.type;
                    }
                  });
                  // this.characterForm.prizeList[key].words = []
                  // val.wordRuleIds.forEach(item => {
                  //   var num = item.wordorder * 1 - 1
                  //   this.characterForm.prizeList[key].words.push(num)
                  // })
                  // val.words = arr
                });

                res.data.words.forEach(function (item) {
                  if (item.isScarcity * 1 === 1) {
                    _this2.characterForm.isRare = item.wordorder - 1;
                    _this2.characterForm.toplimitDay = item.perPersonDayHitTimes;
                    _this2.characterForm.toplimitPerson = item.perPersonHitTimes;
                  }
                });
                _this2.characterForm.wordList = JSON.parse(JSON.stringify(res.data.words));
                break;
              // 红包
              case 2:
                res.data.prizesConfig.forEach(function (item, index) {
                  if (item.prizeType != 6000 && item.prizeType != 5000) {
                    item.type = '1';
                    _this2.pList.forEach(function (val) {
                      if (val.id === item.prizeId) {
                        item.costprize = 0;
                        if (val.costprize) item.costprize = val.costprize * 1;
                        item.prizeType = val.type;
                      }
                    });
                  } else {
                    item.type = '2';
                  }
                });
                _this2.codeForm.prizeList = JSON.parse(JSON.stringify(res.data.prizesConfig));
                break;
              // 转盘
              case 3:
                _this2.panForm.size = res.data.actDialType;
                res.data.prizesConfig.forEach(function (item, index) {
                  switch (item.prizeType * 1) {
                    case 5000:
                      item.type = '3';
                      item.name = '谢谢惠顾';
                      item.img = require('@/assets/images/xxhg.png');
                      item.costprize = 0;
                      item.prizeId = 1;
                      item.prizeType = '5000';
                      item.amount = '不限制';
                      break;
                    case 6000:
                      item.type = '2';
                      item.img = require('@/assets/images/jifen.png');
                      break;
                    default:
                      item.type = '1';
                      _this2.pList.forEach(function (val) {
                        if (val.id === item.prizeId) {
                          item.costprize = 0;
                          if (val.costprize) {
                            item.costprize = val.costprize * 1;
                          }
                          item.prizeType = val.type;
                          item.img = val.imgUrl;
                        }
                      });
                  }
                  // if (item.prizeType === '') {
                  // } else {
                  //   item.type = '1'
                  //   this.pList.forEach(val => {
                  //     if (val.id === item.prizeId) {
                  //       item.costprize = 0
                  //       if (val.costprize) item.costprize = val.costprize * 1
                  //       item.prizeType = val.type
                  //       item.img = val.imgUrl
                  //     }
                  //   })
                  // }
                });

                _this2.panForm.tableData = JSON.parse(JSON.stringify(res.data.prizesConfig));
                _this2.panForm.tableData[0] = {
                  prizeLevel: 1,
                  type: '3',
                  name: '谢谢惠顾',
                  img: require('@/assets/images/xxhg.png'),
                  scale: '',
                  amount: '不限制',
                  costprize: 0,
                  prizeId: 1,
                  prizeType: '5000'
                };
                break;
              case 4:
                // this.panForm.size = res.data.actDialType
                res.data.prizesConfig.forEach(function (item, index) {
                  switch (item.prizeType * 1) {
                    case 5000:
                      item.type = '3';
                      item.name = '谢谢惠顾';
                      item.img = require('@/assets/images/xxhg.png');
                      item.costprize = 0;
                      item.prizeId = 1;
                      item.prizeType = '5000';
                      item.amount = '不限制';
                      break;
                    case 6000:
                      item.type = '2';
                      item.img = require('@/assets/images/jifen.png');
                      break;
                    default:
                      item.type = '1';
                      _this2.pList.forEach(function (val) {
                        if (val.id === item.prizeId) {
                          item.costprize = 0;
                          if (val.costprize) {
                            item.costprize = val.costprize * 1;
                          }
                          item.prizeType = val.type;
                          item.img = val.imgUrl;
                        }
                      });
                  }
                  // if (item.prizeType === '') {
                  // } else {
                  //   item.type = '1'
                  //   this.pList.forEach(val => {
                  //     if (val.id === item.prizeId) {
                  //       item.costprize = 0
                  //       if (val.costprize) item.costprize = val.costprize * 1
                  //       item.prizeType = val.type
                  //       item.img = val.imgUrl
                  //     }
                  //   })
                  // }
                });

                _this2.panForm.tableData = JSON.parse(JSON.stringify(res.data.prizesConfig));
                _this2.panForm.tableData[0] = {
                  prizeLevel: 1,
                  type: '3',
                  name: '谢谢惠顾',
                  img: require('@/assets/images/xxhg.png'),
                  scale: '',
                  amount: '不限制',
                  costprize: 0,
                  prizeId: 1,
                  prizeType: '5000'
                };
                break;
            }
            _this2.expectForm.hasAnticipate = res.data.hasAnticipate ? res.data.hasAnticipate : '0';
            if (_this2.expectForm.hasAnticipate * 1 === 1) {
              _this2.expectForm.expectTable = res.data.anticipate;
            }
          } else {
            _this2.$message.closeAll();
            _this2.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    });
  },
  methods: {
    Policylistshow: function Policylistshow() {
      this.PolicyDialog = true;
    },
    Policylist: function Policylist(data) {
      this.PolicyDialog = false;
      this.pageOneForm.polictName = data.policyName;
      this.pageOneForm.polictNo = data.policyCode;
      this.pageOneForm.polictId = data.pid;
    },
    changeValue: function changeValue() {
      var val = this.codeForm.calculation;
      if (val == '2') {
        var num = 0;
        var numAward = this.numAward;
        this.showOther = true;
        this.codeForm.prizeList.forEach(function (item, index) {
          if (item.prizeType != 5000) {
            num = item.amount / numAward;
            item.scale = (num * 100).toFixed(1);
          }
        });
      } else {
        this.showOther = false;
      }
    },
    o_batchListShow: function o_batchListShow() {
      var _this3 = this;
      this.o_batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this3.$refs.o_batchVisible.visible = true;
        _this3.$refs.o_batchVisible.setsel();
      }, 0);
    },
    o_setBatch: function o_setBatch(data) {
      this.o_batcheVisibleShow = false;
      this.pageOneForm.o_batchList = data;
      var aName = [];
      data.forEach(function (item) {
        aName.push(item.orgName);
      });
      this.pageOneForm.o_batch = aName.join(',');
      this.pageOneForm.batcheNos = data;
    },
    // 订单选择
    b_batchListShow: function b_batchListShow() {
      var _this4 = this;
      if (this.pageOneForm.orderSource == 1) {
        this.cc_batcheVisibleShow = true;
        (0, _timers.setTimeout)(function () {
          _this4.$refs.cc_batchVisible.visible = true;
          _this4.$refs.cc_batchVisible.setsel();
        }, 0);
      } else if (this.pageOneForm.orderSource == 2) {
        this.b_batcheVisibleShow = true;
        (0, _timers.setTimeout)(function () {
          _this4.$refs.b_batchVisible.visible = true;
          _this4.$refs.b_batchVisible.setsel();
        }, 0);
      } else {
        this.$message.error('请选择订单类型');
      }
    },
    orderSourceChange: function orderSourceChange(val) {
      this.pageOneForm.b_batchList = [];
      this.orderNo = '';
      this.productList = [];
      this.pageOneForm.actRConfigOrder = [];
      this.base = '';
      this.usable = '';
      this.pageOneForm.b_batch = '';
    },
    b_setBatch: function b_setBatch(data) {
      var _this5 = this;
      if (data.length < 1) {
        return;
      }
      this.b_batcheVisibleShow = false;
      this.pageOneForm.b_batchList = data;
      var aName = [];
      data.forEach(function (item) {
        aName.push(item.no);
        _this5.orderNo = item.no;
      });
      (0, _outstock.outstockdetail)({
        billNo: aName[0]
      }).then(function (res) {
        var data = res.data;
        if (data.code === 200) {
          if (data.data.product) {
            if (data.data.product.length) {
              _this5.productList = data.data.product;
              _this5.base = data.data.base;
            } else {
              _this5.$message.error('此订单无可用产品');
            }
          } else {
            _this5.$message.error('此订单无可用产品');
          }
        }
      }).then(function () {
        (0, _activityManage.getorderNum)({
          orderNo: _this5.orderNo
        }).then(function (res) {
          if (res.status === 200) {
            _this5.usable = res.data.data;
            var obj = {
              boxNum: data[0].outNum,
              effectiveNum: data[0].outNum - _this5.usable,
              orderId: _this5.orderNo,
              orderNo: aName[0],
              orderType: data[0].orderStatus,
              orderShip: _this5.base.sellerName,
              orderReceipt: _this5.base.buyerName,
              orderSource: _this5.pageOneForm.orderSource
            };
            _this5.pageOneForm.actRConfigOrder = [obj];
          }
        });
        _this5.pageOneForm.b_batch = aName.join(',');
      });
    },
    cc_setBatch: function cc_setBatch(data) {
      var _this6 = this;
      if (data.length < 1) {
        return;
      }
      this.cc_batcheVisibleShow = false;
      this.pageOneForm.b_batchList = data;
      var aName = [data[0].billNo];
      if (data[0].totalMarkNum) {
        this.productList = data[0].prizeTradeDetailVOS;
        this.productList.forEach(function (val) {
          val.outNum = val.totalBottleNum;
        });
        this.base = {
          outNum: data[0].totalMarkNum,
          sellerName: data[0].outOrgName
        };
      } else {
        this.$message.error('此订单无可用产品');
      }
      this.orderNo = data[0].billNo;
      (0, _activityManage.getorderNum)({
        orderNo: this.orderNo
      }).then(function (res) {
        if (res.status === 200) {
          _this6.usable = res.data.data;
          var obj = {
            boxNum: data[0].totalMarkNum,
            effectiveNum: data.totalMarkNum - _this6.usable,
            orderId: _this6.orderNo,
            orderNo: aName[0],
            orderShip: data[0].outOrgName,
            orderReceipt: data[0].inOrgName,
            orderSource: _this6.pageOneForm.orderSource
          };
          _this6.pageOneForm.actRConfigOrder = [obj];
        }
      });
      this.pageOneForm.b_batch = aName.join(',');
    },
    // 关闭创建活动
    goBack: function goBack() {
      this.$emit('close');
    },
    checkPanPercent: function checkPanPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.panForm.tableData[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.panForm.tableData[index][itemName] = '';
      }
    },
    checkExpectPercent: function checkExpectPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.expectForm.expectTable[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.expectForm.expectTable[index][itemName] = '';
      }
    },
    checkExpectNum: function checkExpectNum(itemName, val, index, $el) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.expectForm.expectTable[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.expectForm.expectTable[index][itemName] = '';
      }
    },
    checCodePercent: function checCodePercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.codeForm.prizeList[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.codeForm.prizeList[index][itemName] = '';
      }
    },
    checkCodetNum: function checkCodetNum(itemName, val, index) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.codeForm.prizeList[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.codeForm.prizeList[index][itemName] = '';
      }
      var value = this.codeForm.calculation;
      if (value == '2') {
        var num = 0;
        var numAward = this.numAward;
        this.codeForm.prizeList.forEach(function (item, index) {
          if (item.prizeType != 5000) {
            num = item.amount / numAward;
            item.scale = (num * 100).toFixed(1);
          }
        });
      }
    },
    checCharacterPercent: function checCharacterPercent(itemName, val, index, max) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.characterForm.wordList[index][itemName] = (val.match(/^\d*(\.?\d{0,2})/g)[0] > max ? max : val.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
      } else {
        this.characterForm.wordList[index][itemName] = '';
      }
    },
    checkCharactertNum: function checkCharactertNum(itemName, val, index) {
      if (val) {
        if (typeof val === 'number') val = val.toString();
        this.characterForm.wordList[index][itemName] = val.match(/^\d*/g)[0] || null;
      } else {
        this.characterForm.wordList[index][itemName] = '';
      }
    },
    handleUpdate: function handleUpdate(row) {
      var _this7 = this;
      this.pageOneForm = Object.assign({}, row); // copy obj
      this.pageOneForm.timestamp = new Date(this.pageOneForm.timestamp);
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this7.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this8 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this8.pageOneForm);
          tempData.timestamp = +new Date(tempData.timestamp);
        }
      });
    },
    // 上一步
    prev: function prev() {
      var _this9 = this;
      if (this.active === 1) {
        this.$refs.pageTwoForm.clearValidate();
      } else if (this.active === 2 && this.type * 1 === 1) {
        this.$refs.characterForm.clearValidate();
      } else if (this.active === 2 && this.type * 1 === 2) {
        this.$refs.codeForm.clearValidate();

        // this.$refs.codeForm.fields.forEach((item) => {
        //   // Object.keys(item).forEach(key => {
        //   //   this.$refs.codeForm.clearValidate([key])
        //   // })
        //   item.clearValidate()
        // })
      } else if (this.active === 2 && this.type * 1 === 3) {
        this.$refs.panForm.clearValidate();
      } else if (this.active === 2 && this.type * 1 === 4) {
        this.$refs.panForm.clearValidate();
      } else if (this.active === 3) {
        this.$refs.expectForm.clearValidate();
      } else if (this.active === 3 && this.type * 1 === 3) {
        this.setPan(this.panForm.size);
      }
      this.$nextTick(function () {
        _this9.active--;
      });
      (0, _scrollTo.scrollTo)(0, 800);
    },
    // 下一步
    next: function next() {
      var _this10 = this;
      if (this.showOnly) {
        this.active++;
        if (this.active === 2 && this.type * 1 === 3) {
          this.setPan(this.panForm.size);
        }
        if (this.active === 2 && this.type * 1 === 1) {
          this.changeCharType(this.characterForm.characterGathering * 1);
        }
        (0, _scrollTo.scrollTo)(0, 800);
      } else {
        if (this.active === 0) {
          if (this.base.outNum - this.usable == 0) {
            this.$message.closeAll();
            this.$message.error('可布奖数量不能为0');
            return;
          }
          var pageOneForm = this.checkForm('pageOneForm', '请完善基本设置');
          pageOneForm.then(function () {
            _this10.active++;
            (0, _scrollTo.scrollTo)(0, 800);
          });
        } else if (this.active === 1) {
          var pageTwoForm = this.checkForm('pageTwoForm', '请完善活动项设置');
          pageTwoForm.then(function () {
            _this10.active++;
            (0, _scrollTo.scrollTo)(0, 800);
          });
        } else if (this.active === 2) {
          switch (this.type * 1) {
            // 集字
            case 1:
              var characterForm = this.checkForm('characterForm', '请完善奖品设置');
              characterForm.then(function (res) {
                if (_this10.probabilityZ < 0) {
                  _this10.$message.closeAll();
                  _this10.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this10.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            // 红包
            case 2:
              var codeForm = this.checkForm('codeForm', '请完善奖品设置');
              codeForm.then(function (res) {
                if (_this10.probabilityC < 0) {
                  _this10.$message.closeAll();
                  _this10.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this10.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            // 转盘
            case 3:
              var panForm = this.checkForm('panForm', '请完善奖品设置');
              panForm.then(function (res) {
                if (_this10.probabilityP < 0) {
                  _this10.$message.closeAll();
                  _this10.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this10.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
            case 4:
              var panForm = this.checkForm('panForm', '请完善奖品设置');
              panForm.then(function (res) {
                if (_this10.probabilityP < 0) {
                  _this10.$message.closeAll();
                  _this10.$message({
                    message: '概率之和必须为100%',
                    type: 'error',
                    duration: 3 * 1000
                  });
                } else {
                  _this10.active++;
                  (0, _scrollTo.scrollTo)(0, 800);
                }
              });
              break;
          }
        }
        if (this.active === 1 && this.type * 1 === 3) {
          this.setPan(this.panForm.size);
          (0, _scrollTo.scrollTo)(0, 800);
        }
        if (this.active === 1 && this.type * 1 === 4) {
          (0, _scrollTo.scrollTo)(0, 800);
        }
        if (this.active === 1 && this.type * 1 === 1) {
          this.changeCharType(this.characterForm.characterGathering * 1);
          (0, _scrollTo.scrollTo)(0, 800);
        }
      }
    },
    // 产品文字
    productListShow: function productListShow() {
      this.productVisibleShow = true;
      // setTimeout(() => {
      //   this.$refs.productVisible.proSelVisible = true
      //   this.$refs.productVisible.setsel()
      // }, 0)
    },
    // 产品选择
    setProduct: function setProduct(data) {
      this.productVisibleShow = false;
      this.product = data;
      this.pageOneForm.productList = data;
      var aId = [];
      var aName = [];
      var arr = [];
      data.forEach(function (item) {
        aId.push(item.pId);
        aName.push(item.productName);
        var obj = {
          productBrand: item.productBrandId,
          // 产品品牌
          productId: item.pId,
          // 产品id
          productName: item.productName,
          // 产品名称
          productNo: item.productCode,
          // 产品编号
          productNorms: item.productModel,
          // 产品规格
          productType: item.productClassId // 产品分类
        };

        arr.push(obj);
      });
      this.pageOneForm.product = aName.join(',');
      this.pageOneForm.productIdList = aId;
      this.pageOneForm.products = arr;
    },
    distributorListShow: function distributorListShow() {
      var _this11 = this;
      this.batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this11.$refs.distributorVisible.visible = true;
      }, 0);
    },
    setDistributor: function setDistributor(data) {
      var oDor = JSON.parse(data);
      this.pageOneForm.distributor = oDor.name;
    },
    batchListShow: function batchListShow() {
      var _this12 = this;
      this.batcheVisibleShow = true;
      (0, _timers.setTimeout)(function () {
        _this12.$refs.batchVisible.visible = true;
        _this12.$refs.batchVisible.setsel();
      }, 0);
    },
    setBatch: function setBatch(data) {
      this.batcheVisibleShow = false;
      this.pageOneForm.batchList = data;
      var aName = [];
      data.forEach(function (item) {
        aName.push(item.batchNo);
      });
      this.pageOneForm.batch = aName.join(',');
      this.pageOneForm.batcheNos = data;
    },
    clickRadio: function clickRadio(e) {
      e === this.characterForm.isRare ? this.characterForm.isRare = '' : this.characterForm.isRare = e;
    },
    // 删除文字--集字
    delWord: function delWord(index) {
      if (this.characterForm.isRare == index) {
        this.characterForm.isRare = '';
      }
      delete this.characterForm.wordList.splice(index, 1);
      this.characterForm.prizeList.forEach(function (val) {
        for (var i = val.words.length - 1; i >= 0; i--) {
          if (val.words[i] * 1 === index * 1) {
            val.words.splice(i, 1);
          } else if (val.words[i] * 1 > index * 1) {
            val.words[i]--;
          }
        }
      });
    },
    // 增加文字--集字
    addWord: function addWord() {
      this.characterForm.wordList.push({
        wordcontent: '',
        probability: '',
        wordnum: ''
      });
      this.changeCharType(this.characterForm.characterGathering * 1);
      // var le = this.characterForm.wordList.length
      // this.$set(this.characterForm.wordList, le, {
      //   wordcontent: '',
      //   probability: '',
      //   wordnum: ''
      // })
    },
    // 删除奖项--集字
    delPrize: function delPrize(index) {
      delete this.characterForm.prizeList.splice(index, 1);
    },
    // 增加奖项--集字
    addPrize: function addPrize() {
      this.characterForm.prizeList.push({
        type: '1',
        words: [],
        prizeType: '',
        amount: '',
        dataRange: [],
        budget: '',
        prizeExplain: '',
        prizeId: '',
        costprize: 0
      });
    },
    // 删除奖项--红包
    delCodePrize: function delCodePrize(index) {
      delete this.codeForm.prizeList.splice(index, 1);
    },
    // 增加奖项--红包
    addCodePrize: function addCodePrize() {
      this.codeForm.prizeList.splice(this.codeForm.prizeList.length - 1, 0, {
        type: '1',
        prize: '',
        scale: '',
        amount: '',
        costprize: 0
      });
    },
    // 删除期望
    delPart: function delPart(index) {
      this.expectForm.expectTable[index + 1].startTime = this.expectForm.expectTable[index].startTime;
      delete this.expectForm.expectTable.splice(index, 1);
    },
    // 增加期望
    addPart: function addPart() {
      var le = this.expectForm.expectTable.length - 1;
      this.expectForm.expectTable.splice(le, 0, {
        startTime: this.expectForm.expectTable[le - 1].endTime,
        endTime: '',
        actPar: '',
        actParnum: '',
        addMerberNum: '',
        prizePercent: ''
      });
      this.expectForm.expectTable[le + 1].startTime = '';
    },
    // 转盘切换
    changePan: function changePan(val) {
      var arr = [{
        prizeLevel: 1,
        type: '3',
        name: '谢谢惠顾',
        img: require('@/assets/images/xxhg.png'),
        scale: '',
        amount: '不限制',
        costprize: 0,
        prizeId: 1,
        prizeType: '5000'
      }];
      for (var i = 1; i < val; i++) {
        var obj = {
          prizeLevel: i + 1,
          type: '',
          img: '',
          prizeId: '',
          scale: '',
          amount: '',
          costprize: 0
        };
        arr.push(obj);
      }
      this.panForm.tableData = arr;
      this.setPan(val);
    },
    // 设置转盘
    setPan: function setPan(val) {
      (0, _timers.setTimeout)(function () {
        var aPart1 = [].slice.call(document.querySelectorAll('.panPart'));
        var aP = [].slice.call(document.querySelectorAll('.panText'));
        aPart1.forEach(function (item, i) {
          if (i !== 0) {
            item.style.transform = 'rotate(' + 360 / val * (i + 1) + 'deg)';
          } else {
            item.style.transform = 'rotate(' + 360 / val * (i + 1) + 'deg)skewY(' + (90 - 360 / val) + 'deg)';
          }
          item.style.zIndex = val - i;
        });
        aP.forEach(function (item, i) {
          item.style.transform = 'rotate(' + 180 / val * (2 * i + 1) + 'deg)';
        });
      }, 0);
    },
    // 扫码选择奖品
    cPChange: function cPChange(val, index) {
      var _this13 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this13.codeForm.prizeList[index].costprize = item.costprize ? item.costprize : 0;
          _this13.codeForm.prizeList[index].prizeType = item.type;
        }
      });
    },
    // 集字切换奖品类型
    jChangeType: function jChangeType(val, index) {
      if (val * 1 === 2) {
        this.characterForm.prizeList[index].prizeType = '6000';
        this.characterForm.prizeList[index].prizeId = '';
        this.characterForm.prizeList[index].amount = '';
      }
    },
    // 扫码切换奖品类型
    cChangeType: function cChangeType(val, index) {
      if (val * 1 === 2) {
        this.codeForm.prizeList[index].prizeType = '6000';
        this.codeForm.prizeList[index].prizeId = '';
        this.codeForm.prizeList[index].amount = '';
      }
    },
    // 转盘切换奖品类型
    pChangeType: function pChangeType(val, index) {
      if (val * 1 === 3) {
        this.panForm.tableData[index].name = '谢谢惠顾';
        this.panForm.tableData[index].img = require('@/assets/images/xxhg.png');
        this.panForm.tableData[index].prizeId = 1;
        this.panForm.tableData[index].prizeType = '5000';
        this.panForm.tableData[index].amount = '不限制';
      } else if (val * 1 === 2) {
        this.panForm.tableData[index].img = require('@/assets/images/jifen.png');
        this.panForm.tableData[index].prizeId = '';
        this.panForm.tableData[index].prizeType = '6000';
        this.panForm.tableData[index].amount = '';
      } else {
        this.panForm.tableData[index].img = '';
        this.panForm.tableData[index].prizeId = '';
        this.panForm.tableData[index].prizeType = '';
        this.panForm.tableData[index].amount = '';
      }
    },
    // 转盘选择奖品
    pPChange: function pPChange(val, index) {
      var _this14 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this14.panForm.tableData[index].costprize = item.costprize ? item.costprize * 1 : 0;
          _this14.panForm.tableData[index].prizeType = item.type;
          _this14.panForm.tableData[index].prizeId = val;
          _this14.panForm.tableData[index].img = item.imgUrl;
        }
      });
    },
    // 集字选择奖品
    jzChange: function jzChange(val, index) {
      var _this15 = this;
      this.pList.forEach(function (item) {
        if (item.id === val) {
          _this15.characterForm.prizeList[index].costprize = item.costprize ? item.costprize : 0;
          _this15.characterForm.prizeList[index].prizeType = item.type;
        }
      });
    },
    changeCharType: function changeCharType(val) {
      var _this16 = this;
      if (val === 1) {
        this.characterForm.prizeList = this.characterForm.prizeList.slice(0, 1);
        this.characterForm.wordList.forEach(function (item, i) {
          _this16.characterForm.prizeList[0].words.push(i);
        });
        this.characterForm.prizeList[0].words = (0, _toConsumableArray2.default)(new Set(this.characterForm.prizeList[0].words));
      }
    },
    checkForm: function checkForm(val, text) {
      var _this17 = this;
      return new Promise(function (resolve, reject) {
        _this17.$refs[val].validate(function (valid) {
          if (valid) {
            resolve(valid);
          } else {
            // reject(text)
            _this17.$message.closeAll();
            _this17.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      });
    },
    endChange: function endChange(e, index) {
      this.expectForm.expectTable[index * 1 + 1].startTime = e;
    },
    // 完成
    finish: function finish() {
      var _this18 = this;
      if (this.type * 1 === 1) {
        var eCheck = this.checkForm('characterForm', '请完善活动预期设置');
      } else if (this.type * 1 === 2) {
        var eCheck = this.checkForm('codeForm', '请完善活动预期设置');
      } else if (this.type * 1 === 3) {
        var eCheck = this.checkForm('panForm', '请完善活动预期设置');
      } else if (this.type * 1 === 4) {
        var eCheck = this.checkForm('panForm', '请完善活动预期设置');
      }
      // var eCheck = this.checkForm('expectForm', '请完善活动预期设置')
      eCheck.then(function (res) {
        if (_this18.type == 1 && _this18.probabilityZ < 0 || _this18.type == 2 && _this18.probabilityC < 0 || _this18.type == 3 && _this18.probabilityP < 0 || _this18.type == 4 && _this18.probabilityP < 0) {
          _this18.$message.closeAll();
          _this18.$message({
            message: '概率之和必须为100%',
            type: 'error',
            duration: 3 * 1000
          });
        } else {
          // this.sub()
          switch (_this18.type * 1) {
            // 集字
            case 1:
              _this18.pageOneForm.actWordTeype = _this18.characterForm.characterGathering;
              _this18.characterForm.prizeList.forEach(function (val) {
                var arr = [];
                val.words.forEach(function (item) {
                  var obj = {
                    wordContent: _this18.characterForm.wordList[item * 1].wordcontent,
                    wordorder: item + 1
                  };
                  arr.push(obj);
                });
                val.wordRuleIds = arr;
                val.prizeStarttime = val.dataRange[0];
                val.prizeEndtime = val.dataRange[1];
              });
              _this18.characterForm.wordList.forEach(function (val, index) {
                val.wordtype = 1;
                val.wordorder = index + 1;
                val.isScarcity = 0;
                if (index === _this18.characterForm.isRare) {
                  val.isScarcity = 1;
                  val.perPersonDayHitTimes = _this18.characterForm.toplimitDay;
                  val.perPersonHitTimes = _this18.characterForm.toplimitPerson;
                }
              });
              _this18.pageOneForm.prizesConfig = _this18.characterForm.prizeList;
              _this18.pageOneForm.words = _this18.characterForm.wordList;
              break;
            // 红包
            case 2:
              _this18.pageOneForm.prizesConfig = _this18.codeForm.prizeList;
              _this18.pageOneForm.calculation = _this18.codeForm.calculation;
              _this18.pageOneForm.actJf = _this18.codeForm.actJf;
              _this18.pageOneForm.prizesConfig[_this18.pageOneForm.prizesConfig.length - 1].scale = _this18.probabilityC;
              break;
            // 转盘
            case 3:
              // var prbp = this.probabilityP
              // this.panForm.tableData[0].scale = prbp
              _this18.pageOneForm.prizesConfig = _this18.panForm.tableData;
              _this18.pageOneForm.actDialType = _this18.panForm.size;
              _this18.pageOneForm.calculation = _this18.codeFoem.calculation;
              break;
            case 4:
              // var prbp = this.probabilityP
              // this.panForm.tableData[0].scale = prbp
              _this18.pageOneForm.prizesConfig = _this18.panForm.tableData;
              _this18.pageOneForm.calculation = _this18.codeFoem.calculation;
              break;
          }
          if (_this18.pageOneForm.lq.length === 0) {
            _this18.pageOneForm.hasInfo = '000';
          } else {
            var num = _this18.pageOneForm.lq.join('');
            switch (num * 1) {
              case 1:
                _this18.pageOneForm.hasInfo = '100';
                break;
              case 2:
                _this18.pageOneForm.hasInfo = '010';
                break;
              case 3:
                _this18.pageOneForm.hasInfo = '001';
                break;
              case 12:
                _this18.pageOneForm.hasInfo = '110';
                break;
              case 21:
                _this18.pageOneForm.hasInfo = '110';
                break;
              case 13:
                _this18.pageOneForm.hasInfo = '101';
                break;
              case 31:
                _this18.pageOneForm.hasInfo = '101';
                break;
              case 23:
                _this18.pageOneForm.hasInfo = '011';
                break;
              case 32:
                _this18.pageOneForm.hasInfo = '011';
                break;
              case 123:
                _this18.pageOneForm.hasInfo = '111';
                break;
              case 132:
                _this18.pageOneForm.hasInfo = '111';
                break;
              case 213:
                _this18.pageOneForm.hasInfo = '111';
                break;
              case 231:
                _this18.pageOneForm.hasInfo = '111';
                break;
              case 312:
                _this18.pageOneForm.hasInfo = '111';
                break;
              case 321:
                _this18.pageOneForm.hasInfo = '111';
                break;
            }
          }
          _this18.pageOneForm.hasAnticipate = _this18.expectForm.hasAnticipate * 1;
          if (_this18.pageOneForm.hasAnticipate === 1) {
            _this18.expectForm.expectTable.forEach(function (item, index) {
              item.stage = index * 1 + 1;
              if (index === 0) {
                item.startTime = _this18.pageOneForm.dataTimeRange[0];
              }
              if (index * 1 === _this18.expectForm.expectTable.length - 1) {
                item.endTime = _this18.pageOneForm.dataTimeRange[1];
              }
            });
            _this18.pageOneForm.actAnticipate = _this18.expectForm.expectTable;
          }
          _this18.pageOneForm.startTime = _this18.pageOneForm.dataTimeRange[0];
          _this18.pageOneForm.endTime = _this18.pageOneForm.dataTimeRange[1];
          _this18.pageOneForm.type = _this18.type;
          if (_this18.copy) _this18.pageOneForm.id = null;
          if (_this18.pageOneForm.id) {
            (0, _activityManage.update)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this18.pageOneForm), _this18.pageTwoForm)).then(function (res) {
              res = res.data;
              if (res.status * 1 === 200) {
                _this18.$message.closeAll();
                _this18.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this18.goBack();
              } else {
                _this18.$message.closeAll();
                _this18.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            });
          } else {
            (0, _activityManage.actconfigAdd)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this18.pageOneForm), _this18.pageTwoForm)).then(function (res) {
              res = res.data;
              if (res.status * 1 === 200) {
                _this18.$message.closeAll();
                _this18.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this18.goBack();
              } else {
                _this18.$message.closeAll();
                _this18.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            });
          }
        }
      });
    },
    sub: function sub() {
      var _this19 = this;
      if (this.pageOneForm.lq.length === 0) {
        this.pageOneForm.hasInfo = '000';
      } else {
        var num = this.pageOneForm.lq.join('');
        switch (num * 1) {
          case 1:
            this.pageOneForm.hasInfo = '100';
            break;
          case 2:
            this.pageOneForm.hasInfo = '010';
            break;
          case 3:
            this.pageOneForm.hasInfo = '001';
            break;
          case 12:
            this.pageOneForm.hasInfo = '110';
            break;
          case 21:
            this.pageOneForm.hasInfo = '110';
            break;
          case 13:
            this.pageOneForm.hasInfo = '101';
            break;
          case 31:
            this.pageOneForm.hasInfo = '101';
            break;
          case 23:
            this.pageOneForm.hasInfo = '011';
            break;
          case 32:
            this.pageOneForm.hasInfo = '011';
            break;
          case 123:
            this.pageOneForm.hasInfo = '111';
            break;
          case 132:
            this.pageOneForm.hasInfo = '111';
            break;
          case 213:
            this.pageOneForm.hasInfo = '111';
            break;
          case 231:
            this.pageOneForm.hasInfo = '111';
            break;
          case 312:
            this.pageOneForm.hasInfo = '111';
            break;
          case 321:
            this.pageOneForm.hasInfo = '111';
            break;
        }
      }
      this.pageOneForm.hasAnticipate = this.expectForm.hasAnticipate * 1;
      if (this.pageOneForm.hasAnticipate === 1) {
        this.expectForm.expectTable.forEach(function (item, index) {
          item.stage = index * 1 + 1;
          if (index === 0) {
            item.startTime = _this19.pageOneForm.dataTimeRange[0];
          }
          if (index * 1 === _this19.expectForm.expectTable.length - 1) {
            item.endTime = _this19.pageOneForm.dataTimeRange[1];
          }
        });
        this.pageOneForm.actAnticipate = this.expectForm.expectTable;
      }
      this.pageOneForm.startTime = this.pageOneForm.dataTimeRange[0];
      this.pageOneForm.endTime = this.pageOneForm.dataTimeRange[1];
      this.pageOneForm.type = this.type;
      if (this.pageOneForm.id) {
        this.btnLoading = true;
        (0, _activityManage.update)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageOneForm), this.pageTwoForm)).then(function (res) {
          res = res.data;
          _this19.btnLoading = false;
          if (res.status * 1 === 200) {
            _this19.$message.closeAll();
            _this19.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this19.goBack();
          } else {
            _this19.$message.closeAll();
            _this19.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      } else {
        this.btnLoading = true;
        (0, _activityManage.actconfigAdd)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageOneForm), this.pageTwoForm)).then(function (res) {
          res = res.data;
          _this19.btnLoading = false;
          if (res.status * 1 === 200) {
            _this19.$message.closeAll();
            _this19.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this19.goBack();
          } else {
            _this19.$message.closeAll();
            _this19.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    },
    // 预警开关
    switchFun: function switchFun(type, e) {
      if (type === 1) {
        this.$refs.pageTwoForm.clearValidate(['actWarningEmail', 'actWarningRate', 'actWarningThreshold']);
      } else if (type === 2) {
        this.$refs.pageTwoForm.clearValidate(['actWarningEmail', 'actEndtimeWarningDay', 'actEndtimeWarningRate']);
      }
    }
  }
};
exports.default = _default;