"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _prizeMange = require("@/api/market/prizeMange");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'table-packset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    data: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  filters: {
    stateName: function stateName(index) {
      var comtype = {
        // '0': '新建',
        1: '启用',
        2: '禁用'
      };
      var result = comtype[index];
      return result;
    }
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.getList();
      } else {}
    }
  },
  data: function data() {
    return {
      proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        pageindex: 1,
        pagesize: 10,
        startTime: '',
        endTime: '',
        name: '',
        type: '',
        state: '',
        title: '',
        redpacktype: this.data
      },
      tableData: [],
      total: 0,
      getRowKeys: function getRowKeys(row) {
        return row.pId;
      }
    };
  },
  // mounted() {
  //   this.getAreaAll()
  //   this.getList()
  // },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var obj = {
        loginId: 0
      };
      Object.assign(this.searchForm, obj);
      (0, _prizeMange.getPList)(this.searchForm).then(function (res) {
        res = res.data;
        if (res.state === '200') {
          _this.tableData = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        pageindex: 1,
        pagesize: 10,
        startTime: '',
        endTime: '',
        name: '',
        type: '',
        state: '',
        title: '',
        redpacktype: this.data
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.proSelVisible = false;
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default2;