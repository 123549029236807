"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _number = require("@/api/generalSettings/number");
var _capitalallocation = require("@/api/generalSettings/capitalallocation");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagegeneralSettingscapitalallocationindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagegeneralSettingscapitalallocationindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var validateOrg = _validate.checkName;
    return {
      formInline: {
        user: '',
        region: ''
      },
      // 资金配置列表
      capitalList: [],
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        configurationStatus: null,
        configurationType: null,
        fundConfigurationName: null,
        wechatOfficialId: null
      },
      total: 0,
      merchantTotal: 0,
      isShowCapital: false,
      title: '',
      capitalFrom: {
        fundConfigurationName: '',
        // 资金配置名称
        configurationType: '1',
        // 配置模式
        wechatOfficialId: '',
        // 公众号id
        fundConfigurationMerchant: [{
          fundProportion: '',
          merchantId: '',
          merchantName: ''
        }],
        // fundProportion: '', // 资金比例
        // merchantName: '', // 商户号名称
        id: null
      },
      merchantName: '',
      // 选取的商户号名称
      merchantId: '',
      // 商户号id
      listLoadig: false,
      fundProportion: '',
      // 获取百分比
      // 添加资金配置验证表单
      capitalRules: {
        fundConfigurationName: [{
          required: true,
          trigger: 'blur',
          validator: validateOrg
        }],
        wechatOfficialId: [{
          required: true,
          message: '请选择配置公众号',
          trigger: 'blur'
        }],
        // 商户号1名称校验
        'fundConfigurationMerchant[0].merchantName': [{
          required: true,
          message: '请选择商户号',
          trigger: 'blur'
        }],
        // 商户号1名称校验
        'fundConfigurationMerchant[1].merchantName': [{
          required: true,
          message: '请选择商户号',
          trigger: 'blur'
        }],
        // 比例1校验
        'fundConfigurationMerchant[1].fundProportion': [{
          required: true,
          message: '请输入资金比例',
          trigger: 'blur'
        }],
        // 比例1校验
        'fundConfigurationMerchant[0].fundProportion': [{
          required: true,
          message: '请输入资金比例',
          trigger: 'blur'
        }]
        // fundProportion: [
        //   { required: true, message: '请请输入资金比例', trigger: 'change' },
        // ],
        // merchantNumber1: [
        //   { required: true, message: '请选择商户号', trigger: 'blur' },
        // ],
        // merchantNumber2: [
        //   { required: true, message: '请选择商户号', trigger: 'blur' },
        // ],
        // proportion1: [
        //   { required: true, message: '请输入比例值', trigger: 'blur' },
        // ],
        // proportion2: [
        //   { required: true, message: '请输入比例值', trigger: 'blur' },
        // ],
      },

      isShowSelectmerchants: false,
      Selectmertiele: '',
      // 选取商户号列表
      isShow: false,
      selectMerchants: '默认',
      selectMerchants1: '默认1',
      flag: null,
      officialList: [],
      // 公众号列表
      merchantList: [],
      // 商户号列表
      params: {
        pageSize: 10,
        pageNo: 1,
        merchantName: '',
        merchantType: ''
      },
      flagId: '',
      disabled: false,
      btnLoading: false,
      capitalStatus: [{
        name: '启用',
        value: '1'
      }, {
        name: '停用',
        value: '2'
      }]
    };
  },
  activated: function activated() {
    this.getMerchantList();
    this.getofficialList();
    // var hasCache1 = this.merchantList.length >0 ? true :false
    // var hasCache2 = this.officialList.length >0 ? true :false
    var hasCache3 = this.capitalList.length > 0;
    // if(!hasCache2){
    //   this.getofficialList();
    // }
    // if(!hasCache1){
    //   this.getMerchantList();
    // }
    if (!hasCache3) {
      this.getCapitalList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      // this.merchantList = []
      // this.officialList = []
      this.capitalList = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取公众号列表
    getofficialList: function getofficialList() {
      var _this = this;
      (0, _number.officialList)().then(function (res) {
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data !== null) {
            _this.officialList = res.data.data;
          } else {
            _this.capitalFrom.wechatOfficialId = [];
          }
        }
      });
    },
    // 获取商户号列表
    getMerchantList: function getMerchantList() {
      var _this2 = this;
      (0, _number.getMerchantList)(this.params).then(function (res) {
        console.log(res);
        if (res.data.data.errCode) {
          return _this2.$message.error(res.data.data.errMsg);
        } else {
          if (res.data.data.records.length) {
            _this2.merchantList = res.data.data.records;
            // this.merchantList.forEach(item => {
            //   // console.log(item ,111);
            //   if(item.merchantType == 1){
            //     item.merchantDealerName = ''

            //   }
            // })
            _this2.merchantTotal = res.data.data.total;
          } else {
            _this2.merchantList = [];
            _this2.merchantTotal = 0;
          }
        }
      });
    },
    handleRest: function handleRest() {
      this.params = {
        pageSize: 10,
        pageNo: 1,
        merchantName: '',
        merchantType: ''
      };
      this.getMerchantList();
    },
    // 获取资金配置列表
    getCapitalList: function getCapitalList() {
      var _this3 = this;
      this.listLoadig = true;
      (0, _capitalallocation.getCapitalList)(this.queryParams).then(function (res) {
        _this3.listLoadig = false;
        if (res.data.data.errCode) {
          _this3.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.total = parseInt(res.data.data.total);
            _this3.capitalList = res.data.data.records;
          } else {
            _this3.total = 0;
            _this3.capitalList = [];
          }
        }
      }).finally(function () {
        _this3.listLoadig = false;
      });
    },
    reset: function reset() {
      this.capitalFrom = {
        fundConfigurationName: '',
        // 资金配置名称
        configurationType: '1',
        // 配置模式
        wechatOfficialId: '',
        // 公众号id
        fundConfigurationMerchant: [{
          fundProportion: '',
          merchantId: '',
          merchantName: ''
        }],
        // fundProportion: '', // 资金比例
        // merchantName: '', // 商户号名称
        id: null
      };
    },
    addCapital: function addCapital() {
      var _this4 = this;
      this.title = '新增资金配置';
      this.disabled = false;
      this.btnLoading = false;
      this.isShow = false;
      this.$nextTick(function () {
        _this4.reset();
        _this4.$refs['capitalRuleForm'].resetFields();
      });
      this.isShowCapital = true;
    },
    handleCancel: function handleCancel() {},
    // 查询
    queryParamsList: function queryParamsList() {
      this.params.pageNo = 1;
      this.getMerchantList();
    },
    // 配置默认设置
    handledefItem: function handledefItem(row) {
      var _this5 = this;
      console.log(row);
      if (row.isDefault == 1) {
        return;
      }
      // const str = row.isDefault == 1 ? '取消默认' : '设置默认';
      this.$confirm('是否设置默认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        var params = {
          id: row.id
        };
        (0, _capitalallocation.defaultItem)(params).then(function (res) {
          if (res.data.data.errCode) {
            _this5.$message.error(res.data.data.errMsg);
            return;
          } else {
            _this5.getCapitalList();
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 取消新增资金
    cleanCapital: function cleanCapital(capital) {
      this.reset();
      // this.$nextTick(() => {
      this.$refs['capitalRuleForm'].resetFields();
      // })
      this.isShowCapital = false;
      this.isShow = false;
      // this.$nextTick(() => {
      // this.$refs[capital].resetFields();
      // })
    },
    // 确定新增资金
    handleSave: function handleSave(capital) {
      var _this6 = this;
      // this.disabled = true
      // this.btnLoading = true
      this.$refs[capital].validate(function (valid) {
        if (valid) {
          // 判断是否混合模式
          // 不是混合模式 capitalFrom.fundConfigurationName
          // debugger
          // let item1;
          // const name = this.capitalFrom.fundConfigurationMerchant[0].merchantId;
          // if (this.capitalFrom.fundConfigurationMerchant.length > 1) {
          //   const name1 = this.capitalFrom.fundConfigurationMerchant[1].merchantId;
          //   item1 = this.merchantList.find(item => name1 == item.merchantName);
          // }
          // let item = this.merchantList.find(item => name == item.merchantName);
          // debugger
          if (_this6.capitalFrom.fundConfigurationMerchant.length != 1) {
            if (Number(_this6.capitalFrom.fundConfigurationMerchant[0].fundProportion) + Number(_this6.capitalFrom.fundConfigurationMerchant[1].fundProportion) != 100) {
              _this6.$message.error('比例必须等于100%');
              return;
            }
            // this.capitalFrom.fundConfigurationMerchant[0].merchantId = item.id;
            // this.capitalFrom.fundConfigurationMerchant[1].merchantId = item1.id;
          } else {
            if (Number(_this6.capitalFrom.fundConfigurationMerchant[0].fundProportion) != 100) {
              _this6.$message.error('比例必须等于100%');
              return;
            }
            // this.capitalFrom.fundConfigurationMerchant[0].merchantId = item.id;
          }

          var params = JSON.parse(JSON.stringify(_this6.capitalFrom));
          console.log(params, 'params');
          if (_this6.title != '新增资金配置') {
            (0, _capitalallocation.updateItem)(params).then(function (res) {
              if (res.data.data.errCode) {
                _this6.disabled = false;
                _this6.btnLoading = false;
                _this6.$message.error('该资金配置被活动占用无法修改');
                return;
              } else {
                _this6.getCapitalList();
                _this6.$message.success('修改成功');
                _this6.isShowCapital = false;
                _this6.disabled = true;
                _this6.btnLoading = true;
              }
              _this6.isShowCapital = false;
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          } else {
            (0, _capitalallocation.addCapitalList)(params).then(function (res) {
              if (res.data.data.errCode) {
                _this6.disabled = false;
                _this6.btnLoading = false;
                _this6.$message.error(res.data.data.errMsg);
                return;
              } else {
                _this6.$message.success('新增成功');
                _this6.getCapitalList();
                _this6.isShowCapital = false;
                _this6.disabled = true;
                _this6.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          }
        } else {
          _this6.disabled = false;
          _this6.btnLoading = false;
        }
      });
    },
    // 点击编辑出现弹框
    handleEditCapita: function handleEditCapita(row) {
      console.log(row);
      this.disabled = false;
      this.btnLoading = false;
      var item = JSON.parse(JSON.stringify(row));
      // debugger
      this.title = '编辑资金配置';
      this.isShow = item.configurationType == 2;

      // this.isShow = row.radio == 2 ? true : false;
      this.capitalFrom.configurationType = item.configurationType;
      if (Array.isArray(item.merchantVoList) && item.merchantVoList.length) {
        this.capitalFrom.fundConfigurationMerchant = item.merchantVoList;
        console.log(this.capitalFrom.fundConfigurationMerchant, 'this.capitalFrom.fundConfigurationMerchant');
        // for (let d of this.capitalFrom.fundConfigurationMerchant) {
        //   d.merchantId = d.merchantName;
        // }
      }

      this.capitalFrom.wechatOfficialId = item.wechatOfficialId;
      this.capitalFrom.fundConfigurationName = item.fundConfigurationName;
      this.capitalFrom.id = item.id;
      this.isShowCapital = true;
    },
    selection: function selection(data) {
      this.flag = data;
      this.Selectmertiele = '选取商户号';
      this.isShowSelectmerchants = true;
    },
    // 确定商户选取按钮
    handleSelection: function handleSelection() {
      this.isShowSelectmerchants = false;
      this.params.pageNo = 1;
      this.getMerchantList();
    },
    // 配置模式选取
    configure: function configure(value) {
      console.log(value);
      // this.capitalFrom.proportion1 = null;
      // this.capitalFrom.proportion2 = null;
      this.isShow = value == 2;
      if (value != 2) {
        this.capitalFrom.fundConfigurationMerchant = [{
          fundProportion: '',
          merchantId: '',
          merchantName: ''
        }];
        this.isShow = false;
      } else {
        this.capitalFrom.fundConfigurationMerchant = [{
          fundProportion: '',
          merchantId: '',
          merchantName: ''
        }];
        this.capitalFrom.fundConfigurationMerchant.push({
          fundProportion: '',
          merchantId: '',
          merchantName: ''
        });
        this.isShow = true;
      }
    },
    // 确定选取商户号
    confirmSelection: function confirmSelection(row) {
      console.log(row);
      console.log(this.capitalFrom.fundConfigurationMerchant);
      // 说明选中的不是混合模式
      if (this.flag) {
        this.capitalFrom.fundConfigurationMerchant[0]['merchantName'] = row.merchantName;
        this.capitalFrom.fundConfigurationMerchant[0]['merchantId'] = row.id;
      } else {
        //  this.capitalFrom.fundConfigurationMerchant.push({ fundProportion: '',merchantId: '', merchantName: ''})
        this.capitalFrom.fundConfigurationMerchant[1]['merchantName'] = row.merchantName;
        this.capitalFrom.fundConfigurationMerchant[1]['merchantId'] = row.id;
      }
      // this.capitalFrom.fundConfigurationMerchant[1]['merchantId'] = row.merchantName;
      //  this.capitalFrom.fundConfigurationMerchant[0]['merchantId'] = row.merchantName;
      // if (this.isShow) {
      //    this.capitalFrom.fundConfigurationMerchant[1]['merchantId'] = row.merchantName;
      // } else {
      //   this.capitalFrom.fundConfigurationMerchant[0]['merchantId'] = row.merchantName;
      // }
      this.isShowSelectmerchants = false;
    },
    // 取消商户号选取
    cancelSelectmerchants: function cancelSelectmerchants() {
      this.params.merchantName = '';
      this.params.merchantType = null;
      this.params.pageNo = 1;
      this.getMerchantList();
      this.isShowSelectmerchants = false;
    },
    // 删除
    handleDelItem: function handleDelItem(row) {
      var _this7 = this;
      var txt = '';
      if (row.configurationStatus == '1') {
        txt = '停用';
      } else {
        txt = '启用';
      }
      this.$confirm('是否' + txt + '该数据项?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        var params = {
          status: row.configurationStatus == 1 ? 2 : 1,
          id: row.id
        };
        (0, _capitalallocation.delItem)(params).then(function (res) {
          if (res.data.data.code) {
            _this7.$message.error('该资金配置被活动占用无法修改');
            return;
          } else {
            var str = row.configurationStatus == 1 ? '停用' : '启用';
            _this7.getCapitalList();
            _this7.$message.success(str + '成功');
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    restCapitalList: function restCapitalList() {
      this.queryParams.configurationStatus = '';
      this.queryParams.configurationType = '';
      this.queryParams.fundConfigurationName = '';
      this.queryParams.wechatOfficialId = '';
      this.getCapitalList();
    },
    typeFormatter: function typeFormatter(row) {
      if (row.configurationType == 1) {
        return '企业自主';
      } else if (row.configurationType == 2) {
        return '混合模式';
      } else {
        return '大商专属';
      }
    },
    showFormatter: function showFormatter(row) {
      if (row.configurationStatus == 1) return '启用';
      return '停用';
    },
    nameFormatter: function nameFormatter(row) {
      if (row.merchantType == 1) return '企业';
      return '经销商';
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getCapitalList();
    }
  }
};
exports.default = _default;