var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image: url('" + _vm.list.background + "');",
      },
      [
        _c("el-image", {
          staticClass: "title",
          attrs: { lazy: "", src: _vm.list.title },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "prizebg-c" }, [
          _c(
            "div",
            { staticClass: "scratch-container" },
            [
              _c("el-image", {
                staticClass: "scratch-bgImg",
                attrs: { lazy: "", src: _vm.list.scratchBottomBg },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "scratch-cover-bgcolor" }, [
                _c(
                  "div",
                  { staticClass: "scratchBtnBg" },
                  [
                    _c("el-image", {
                      staticClass: "scratch-btnBgImg",
                      attrs: { lazy: "", src: _vm.list.scratchBtnBg },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }