"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _param = require("@/api/chanelmange/param");
var _chanelapp = require("@/api/chanelmange/chanelapp");
var _utils = require("@/utils");
var _name$components$data; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var numReg = /^([\+]?(([1-9]\d*)|(0)))([.]\d{0,2})?$/;
var cachName = 'chanelmangerebateconfigparams';
var _default = (_name$components$data = {
  name: 'chanelmangerebateconfigparams',
  components: {},
  data: function data() {
    return {
      listQuery1: {
        pid: '',
        rebateBeginTime: '',
        rebateEndTime: '',
        rebateIllustrateImage: '',
        rebateMinimumAmount: '',
        rebateType: 1
      },
      listQuery2: {
        pid: '',
        rebateBeginTime: '',
        rebateEndTime: '',
        rebateIllustrateImage: '',
        rebateMinimumAmount: '',
        rebateType: 2
      },
      imageUrl1: '',
      imageUrl2: '',
      value1: '',
      value2: '',
      showCol: false,
      expandTxt: '展开',
      listLoading: false,
      loading: false,
      companys: [],
      apis: []
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
  },
  activated: function activated() {
    var that = this;
    that.loading = true;
    setTimeout(function () {
      that.getList();
    }, 50);
  }
}, (0, _defineProperty2.default)(_name$components$data, "activated", function activated() {
  var that = this;
  var hasCache = that.companys.length > 0 ? true : false;
  var authList = that.$route.meta.authList;
  that.apis = authList;
  var auth = that.hasAuth('searchbtn', authList);
  var setHeaderId = that.authId('searchbtn', that.apis);
  if (!hasCache) {
    if (auth) {
      that.getList();
    }
  }
}), (0, _defineProperty2.default)(_name$components$data, "deactivated", function deactivated() {
  var that = this;
  var name = cachName;
  var cache = that.getCaches(that, name);
  if (!cache) {
    that.companys = [];
  }
}), (0, _defineProperty2.default)(_name$components$data, "filters", {
  formatTime: function formatTime(value) {
    if (value == '' || value == null) {
      return '';
    } else {
      return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
    }
  }
}), (0, _defineProperty2.default)(_name$components$data, "methods", {
  getList: function getList() {
    var that = this;
    var setHeaderId = that.authId('searchbtn', that.apis);
    (0, _param.getDebateParam)().then(function (res) {
      that.loading = false;
      if (res.data.code == 200) {
        if (res.data.data.length > 0) {
          var resData = res.data.data;
          resData.forEach(function (item) {
            if (item.rebateType == 1) {
              that.listQuery1.pid = item.pid;
              that.listQuery1.rebateBeginTime = item.rebateBeginTime;
              that.listQuery1.rebateEndTime = item.rebateEndTime;
              that.listQuery1.rebateIllustrateImage = item.rebateIllustrateImage;
              that.listQuery1.rebateMinimumAmount = Number(item.rebateMinimumAmount).toFixed(2);
              that.listQuery1.rebateType = item.rebateType;
              that.value1 = [item.rebateBeginTime, item.rebateEndTime];
              that.imageUrl1 = item.rebateIllustrateImage;
              that.getCompnys(item.pid);
            }
            if (item.rebateType == 2) {
              that.listQuery2.pid = item.pid;
              that.listQuery2.rebateBeginTime = item.rebateBeginTime;
              that.listQuery2.rebateEndTime = item.rebateEndTime;
              that.listQuery2.rebateIllustrateImage = item.rebateIllustrateImage;
              that.listQuery2.rebateMinimumAmount = Number(item.rebateMinimumAmount).toFixed(2);
              that.listQuery2.rebateType = item.rebateType;
              that.value2 = [item.rebateBeginTime, item.rebateEndTime];
              that.imageUrl2 = item.rebateIllustrateImage;
            }
          });
        } else {
          that.getCompnys('');
        }
        setTimeout(function () {
          that.loading = false;
        }, 1 * 800);
      } else {
        that.$message.error(res.data.msg);
        that.loading = false;
        return;
      }
    }).catch(function () {
      that.loading = false;
    });
  },
  //获取所有的销售公司
  getCompnys: function getCompnys(id) {
    var that = this;
    var allocationId = id;
    var data = {
      orgType: '2',
      orgTypeList: ['2'],
      orgStatus: 1
    };
    (0, _param.getSalesCompy)(data).then(function (res) {
      if (res.data.code == 200) {
        var resData = res.data.data;
        that.companys = resData.map(function (item) {
          return {
            cashRebateType: null,
            orgId: item.pid,
            orgName: item.orgName,
            pid: ''
          };
        });
      } else {
        that.companys = [];
      }
      return allocationId;
    }).then(function (res) {
      that.getCashRebate(res);
    });
  },
  //获取返利对象
  getCashRebate: function getCashRebate(id) {
    var that = this;
    var param = {
      allocationId: id
    };
    (0, _param.getRebateCompy)(param).then(function (res) {
      if (res.data.code == 200) {
        var resData = res.data.data;
        if (resData && resData.length > 0) {
          for (var i = 0; i < resData.length; i++) {
            for (var j = 0; j < that.companys.length; j++) {
              if (resData[i].orgId == that.companys[j].orgId) {
                that.companys[j].cashRebateType = resData[i].cashRebateType;
                that.companys[j].pid = resData[i].pid;
                break;
              }
            }
          }
        }
      }
    });
  },
  handleRemove: function handleRemove(file, fileList) {
    console.log(file, fileList);
  },
  handlePreview: function handlePreview(file) {
    console.log(file);
  },
  handleChange: function handleChange(obj, file) {
    var that = this;
    var isJPG = file.raw.type === 'image/jpeg';
    var isPNG = file.raw.type === 'image/png';
    var isLtSize = file.raw.size / 1024 / 1024 <= 1;
    var index = obj.index;
    if (!isJPG && !isPNG) {
      that.$message.error('上传产品图片只能是 JPG/PNG 格式!');
      return;
    }
    if (!isLtSize) {
      that.$message.error('上传产品图片大小不能超过 1M!');
      return;
    }
    var setHeaderId = that.authId('uploadbtn', that.apis);
    var formData = new FormData();
    formData.append('file', file.raw);
    (0, _chanelapp.upLoadImg)(formData).then(function (res) {
      if (res.data.code == 200) {
        if (index == 1) {
          that.imageUrl1 = res.data.data;
          that.listQuery1.rebateIllustrateImage = res.data.data;
        }
        if (index == 2) {
          that.imageUrl2 = res.data.data;
          that.listQuery2.rebateIllustrateImage = res.data.data;
        }
      } else {
        that.$message.error(res.data.msg + '，请重新上传！');
        return;
      }
    });
  },
  removeImg: function removeImg(arg) {
    var that = this;
    if (arg == '1') {
      that.imageUrl1 = '';
      that.listQuery1.rebateIllustrateImage = '';
    }
    if (arg == '2') {
      that.imageUrl2 = '';
      that.listQuery2.rebateIllustrateImage = '';
    }
  },
  getValue1: function getValue1(val) {
    var that = this;
    if (val != null) {
      that.listQuery1.rebateBeginTime = val[0];
      that.listQuery1.rebateEndTime = val[1];
    } else {
      that.listQuery1.rebateBeginTime = '';
      that.listQuery1.rebateEndTime = '';
    }
  },
  getValue2: function getValue2(val) {
    var that = this;
    if (val != null) {
      that.listQuery2.rebateBeginTime = val[0];
      that.listQuery2.rebateEndTime = val[1];
    } else {
      that.listQuery2.rebateBeginTime = '';
      that.listQuery2.rebateEndTime = '';
    }
  },
  getInputV1: function getInputV1() {
    var that = this;
    var v = that.listQuery1.rebateMinimumAmount;
    if (v == '') {
      that.listQuery1.rebateMinimumAmount = Number(v).toFixed(2);
    } else {
      if (numReg.test(v)) {
        that.listQuery1.rebateMinimumAmount = Number(v).toFixed(2);
      } else {
        that.$message.error('输入金额必须为数字，且为正数，可保留两位小数');
        that.listQuery1.rebateMinimumAmount = Number(0).toFixed(2);
        return;
      }
    }
  },
  getInputV2: function getInputV2() {
    var that = this;
    var v = that.listQuery2.rebateMinimumAmount;
    if (v == '') {
      that.listQuery2.rebateMinimumAmount = Number(0).toFixed(2);
    } else {
      if (numReg.test(v)) {
        that.listQuery2.rebateMinimumAmount = Number(v).toFixed(2);
      } else {
        that.$message.error('输入金额必须为数字，且为正数，可保留两位小数');
        that.listQuery2.rebateMinimumAmount = Number(0).toFixed(2);
        return;
      }
    }
  },
  checkDate: function checkDate(origin, type) {
    var that = this;
    var reg = /^\+?[1-9]\d*$/;
    if (origin == 'begin' && type == '1') {
      var value = that.listQuery1.rebateBeginTime;
      if (!reg.test(value)) {
        that.$message.error('请输入正确的申请日,最大值为31');
        that.listQuery1.rebateBeginTime = 1;
        return;
      } else {
        if (value > 31) {
          that.$message.error('请输入正确的申请日,最大值为31');
          that.listQuery1.rebateBeginTime = 1;
          return;
        } else {
          var end = that.listQuery1.rebateEndTime ? that.listQuery1.rebateEndTime : 0;
          if (value > end) {
            that.$message.error('起始申请日不应,大于终止申请日');
            that.listQuery1.rebateBeginTime = 1;
            return;
          }
        }
      }
    }
    if (origin == 'end' && type == '1') {
      var value = that.listQuery1.rebateEndTime;
      if (!reg.test(value)) {
        that.$message.error('请输入正确的申请日,最大值为31');
        that.listQuery1.rebateEndTime = 31;
        return;
      } else {
        if (value > 31) {
          that.$message.error('请输入正确的申请日,最大值为31');
          that.listQuery1.rebateEndTime = 31;
          return;
        } else {
          var start = that.listQuery1.rebateBeginTime ? that.listQuery1.rebateBeginTime : 0;
          if (value < start) {
            that.$message.error('终止申请日应不小于起始申请日，且最大值为31');
            that.listQuery1.rebateEndTime = 31;
            return;
          }
        }
      }
    }
    if (origin == 'begin' && type == '2') {
      var value = that.listQuery2.rebateBeginTime;
      if (!reg.test(value)) {
        that.$message.error('请输入正确的申请日,最大值为31');
        that.listQuery2.rebateBeginTime = 1;
        return;
      } else {
        if (value > 31) {
          that.$message.error('请输入正确的申请日,最大值为31');
          that.listQuery2.rebateBeginTime = 1;
          return;
        } else {
          var end = that.listQuery2.rebateEndTime ? that.listQuery2.rebateEndTime : 0;
          if (value > end) {
            that.$message.error('起始申请日不应,大于终止申请日');
            that.listQuery2.rebateBeginTime = 1;
            return;
          }
        }
      }
    }
    if (origin == 'end' && type == '2') {
      var value = that.listQuery2.rebateEndTime;
      if (!reg.test(value)) {
        that.$message.error('请输入正确的申请日,最大值为31');
        that.listQuery2.rebateEndTime = 31;
        return;
      } else {
        if (value > 31) {
          that.$message.error('请输入正确的申请日,最大值为31');
          that.listQuery2.rebateEndTime = 31;
          return;
        } else {
          var start = that.listQuery2.rebateBeginTime ? that.listQuery2.rebateBeginTime : 0;
          if (value < start) {
            that.$message.error('终止申请日应不小于起始申请日，且最大值为31');
            that.listQuery2.rebateEndTime = 31;
            return;
          }
        }
      }
    }
  },
  DoSave: function DoSave() {
    var that = this;
    var setHeaderId = that.authId('savebtn', that.apis);
    that.loading = true;
    var param = {};
    param.raList = [that.listQuery1, that.listQuery2];
    if (that.companys.length > 0) {
      param.raiList = that.companys.map(function (item) {
        return {
          allocationId: that.listQuery1.pid,
          cashRebateType: item.cashRebateType,
          orgId: item.orgId,
          pid: item.pid
        };
      });
    } else {
      param.raiList = [];
    }
    (0, _param.saveDebateParam)(param).then(function (res) {
      if (res.data.code == 200) {
        that.loading = false;
        that.$message({
          type: 'success',
          message: '保存成功'
        });
        that.getList();
      } else {
        that.loading = false;
        that.$message.error(res.data.msg);
      }
    }).catch(function () {
      that.loading = false;
    });
  }
}), _name$components$data);
exports.default = _default;