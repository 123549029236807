"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/fake_expel/basic");
var _expel = require("@/api/fake_expel/expel/expel");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _seach = _interopRequireDefault(require("../components/map/seach"));
var _data_list = _interopRequireDefault(require("../components/map/data_list"));
var _echarts = _interopRequireDefault(require("echarts"));
require("../../../../../node_modules/echarts/map/js/china.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 引入中国地图数据
var geoCoordMap = {
  安徽: [117.17, 31.52],
  北京: [116.24, 39.55],
  重庆: [106.54, 29.59],
  福建: [119.18, 26.05],
  甘肃: [103.51, 36.04],
  广东: [113.14, 23.08],
  广西: [108.19, 22.48],
  贵州: [106.42, 26.35],
  海南: [110.2, 20.02],
  河北: [114.3, 38.02],
  河南: [113.4, 34.46],
  黑龙江: [126.36, 45.44],
  湖北: [114.17, 30.35],
  湖南: [112.59, 28.12],
  吉林: [125.19, 43.54],
  江苏: [118.46, 32.03],
  江西: [115.55, 28.4],
  辽宁: [123.25, 41.48],
  内蒙古: [111.41, 40.48],
  宁夏: [106.16, 38.27],
  青海: [101.48, 36.38],
  山东: [117.0, 36.4],
  山西: [112.33, 37.54],
  陕西: [108.57, 34.17],
  上海: [121.29, 31.14],
  四川: [104.04, 30.4],
  天津: [117.12, 39.02],
  西藏: [91.08, 29.39],
  新疆: [87.36, 43.45],
  云南: [102.42, 25.04],
  浙江: [120.1, 30.16],
  香港: [115.12, 21.23],
  澳门: [115.07, 21.33],
  台湾: [121.3, 25.03]
};
var color = ['#ffa022', '#46bee9', '#FF0000', '#76EEC6', '#000080', '#00CD66', '#00EE00', '#00BFFF', '#FFF68F', '#32CD32', '#EEC900', '#8B658B', '#CD5C5C', '#FF6A6A', '#E9967A', '#FF4500', '#DA70D6', '#8B4C39', '#9370DB', '#BA55D3', '#CD5B45', '#FF1493', '#CD6090', '#836FFF', '#CD00CD', '#0000EE', '#63B8FF', '#912CEE', '#00FF00', '#2E8B57', '#006400', '#a6c84c', '#48D1CC', '#7FFFD4'];
var planePath = 'arrow';
var _default = {
  components: {
    seach: _seach.default,
    dataList: _data_list.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      tabHeight: 'calc(100vh - 350px)',
      tableKey: 0,
      total: 0,
      listLoading: false,
      // listQuery: {
      //   pageNo: 1,
      //   pageSize: 10,
      //   ppwlList: [],
      //   timeType: '1',
      //   provinceName: ''
      // },
      listMapQuery: {
        startTime: '',
        endTime: '',
        ppwlList: [],
        province: ''
      },
      exoutQuery: {
        ppwlList: [],
        timeType: '1',
        provinceName: '',
        ppName: ''
      },
      ProvinceList: null,
      CityList: null,
      chart: null,
      chartMapList: null,
      ProvinceShowList: false,
      CityShowList: false,
      excelOut_loading: false,
      provinces: ['shanghai', 'hebei', 'shanxi', 'neimenggu', 'liaoning', 'jilin', 'heilongjiang', 'jiangsu', 'zhejiang', 'anhui', 'fujian', 'jiangxi', 'shandong', 'henan', 'hubei', 'hunan', 'guangdong', 'guangxi', 'hainan', 'sichuan', 'guizhou', 'yunnan', 'xizang', 'shanxi1', 'gansu', 'qinghai', 'ningxia', 'xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen', 'taiwan'],
      provincesText: ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津', '重庆', '香港', '澳门', '台湾'],
      map: '',
      loadMapData: [],
      province: ''
    };
  },
  activated: function activated() {
    var that = this;
    // var h = tableHeight()
    // that.$nextTick(function() {
    //   that.tabHeight = h
    // })

    // setTimeout(() => {
    this.map = _echarts.default.init(this.$refs.myEchart);
    this.map.on('legendselectchanged', function (obj) {
      console.log('这是', obj);
      var selected = obj.selected;
      var legend = obj.name;
      this.province = '';
      // 使用 legendToggleSelect Action 会重新触发 legendselectchanged Event，导致本函数重复运行
      // 使得 无 selected 对象
      if (selected != undefined) {
        this.province = legend;
        that.$refs.dataListIfam.activeName = 'queryMapChan';
        that.$refs.dataListIfam.listMapQuery.province = legend;
        that.$refs.dataListIfam.queryMapChan_label = '[' + legend + ']疑似窜货统计';
        that.$refs.dataListIfam.queryMapWlChan_label = '[' + legend + ']产品疑似窜货统计';
        that.$refs.dataListIfam.queryMapJxsChan_label = '[' + legend + ']经销商/终端疑似窜货统计';
        that.$refs.dataListIfam.queryMapChan();
      }
    });
    this.initMap();
    var time = (0, _basic.getSysSearchDefDate)(7);
    this.listMapQuery.startTime = time[0].replace(/-/g, '');
    this.listMapQuery.endTime = time[1].replace(/-/g, '');
    this.getMapList();
    // }, 1500)
  },

  methods: {
    initMap: function initMap() {
      var selected = 'china';
      this.loadMap(selected, [], []);
    },
    loadMap: function loadMap(param, data, series) {
      console.log('地图', series);
      // 参数为要显示地图区域的名字

      this.map.setOption({
        backgroundColor: '#02AFDB',
        roam: true,
        // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启

        scaleLimit: {
          min: 1.2,
          max: 1.9
        },
        // 缩放
        title: {
          text: '疑似窜出数据概况',
          subtext: '',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function formatter(params, ticket, callback) {
            console.log('选地区', params);
            if (params.data.md) {
              var htmlStr = '<div>';
              htmlStr += '窜出地区：' + params.seriesName;
              htmlStr += '<div style="border: 1px solid #FFEB3B"></div>';
              htmlStr += '窜入地区：' + params.data.md + '; 窜入数量：' + params.data.value;
              htmlStr += '<div style="border: 1px solid #FFEB3B"></div>';
              htmlStr += '</div>';
              return htmlStr;
            }
          }
        },
        legend: {
          orient: 'vertical',
          top: 'bottom',
          left: 'right',
          data: [],
          textStyle: {
            color: '#fff'
          },
          selectedMode: 'single'
        },
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: false
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              // borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: series
      });
    },
    tableHeaderColor: function tableHeaderColor(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (rowIndex === 0) {
        return 'background-color: lightblue;color: #fff;font-weight: 500;';
      }
    },
    // excelOutMapProWlmsList() {
    //   this.excelOut_loading = true
    //   this.exoutQuery = getKeysetVal(this.exoutQuery, this.listMapQuery)
    //   excelOutMapProWlmsList(this.exoutQuery).then((res) => {
    //     var blob = new Blob([res.data], {
    //       type: 'application/vnd.ms-excel;charset=utf-8'
    //     })
    //     var downloadElement = document.createElement('a')
    //     var href = window.URL.createObjectURL(blob)
    //     downloadElement.href = href
    //     downloadElement.download =
    //       '防伪预警-' + this.exoutQuery.ppName + '.xlsx'
    //     document.body.appendChild(downloadElement)
    //     downloadElement.click()
    //     document.body.removeChild(downloadElement)
    //     window.URL.revokeObjectURL(href)
    //     this.excelOut_loading = false
    //   })
    // },
    getMapList: function getMapList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.map.showLoading({
        text: '正在加载数据...'
      });
      this.loadMapData = [];
      this.listLoading = true;
      delete this.listMapQuery.pageNo;
      delete this.listMapQuery.pageSize;
      this.$refs.dataListIfam.listQuery = JSON.parse(JSON.stringify(this.listMapQuery));
      this.$refs.dataListIfam.activeName = 'queryMapChan';
      this.$refs.dataListIfam.queryMapChan();
      (0, _expel.queryMapProOrCity)(this.listMapQuery).then(function (response) {
        var data = response.data;
        var option;
        if (data.code != 200) {
          option = _this.map.getOption();
          option.series = [{
            // 系列名称，用于tooltip的显示
            name: '',
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              // 特效动画的时间，单位为 s
              trailLength: 0.5,
              // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
              color: color[i],
              // 移动箭头颜色
              symbol: planePath,
              symbolSize: 6 // 特效标记的大小
            },

            // lineStyle出发到目的地 的线条颜色
            lineStyle: {
              normal: {
                color: color[i],
                width: 1,
                curveness: 0.2 // 幅度
              }
            },

            data: [] // 开始到结束数据
          }, {
            // 出发地信息
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: '#fff'
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            data: []
          }, {
            // 目的地信息
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: '#fff'
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            data: []
          }];
          option.legend[0].data = [];
          _this.map.setOption(option, true);
          _this.map.hideLoading(); // 提示关闭
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data.length <= 0) {
          option = _this.map.getOption();
          option.series = [{
            // 系列名称，用于tooltip的显示
            name: '',
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              // 特效动画的时间，单位为 s
              trailLength: 0.5,
              // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
              color: color[i],
              // 移动箭头颜色
              symbol: planePath,
              symbolSize: 6 // 特效标记的大小
            },

            // lineStyle出发到目的地 的线条颜色
            lineStyle: {
              normal: {
                color: color[i],
                width: 1,
                curveness: 0.2 // 幅度
              }
            },

            data: [] // 开始到结束数据
          }, {
            // 出发地信息
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: '#fff'
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            data: []
          }, {
            // 目的地信息
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: '#fff'
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: '#fff'
              }
            },
            data: []
          }];
          option.legend[0].data = [];
          _this.map.setOption(option, true);
          _this.map.hideLoading(); // 提示关闭
          return true;
        }
        var res = [];
        var coord = [];
        var labeldata = [];
        var getMapData = data.data;
        for (var i in getMapData) {
          res = [];
          coord = [];
          var v = getMapData[i];
          for (var t in _this.provincesText) {
            if (v.ysccPro.indexOf(_this.provincesText[t]) > -1) {
              labeldata.push(_this.provincesText[t]);
              var fromCoord = geoCoordMap[_this.provincesText[t]];
              for (var n in v.cxCityList) {
                var ncx = v.cxCityList[n];
                for (var nt in _this.provincesText) {
                  if (ncx.cxCity.indexOf(_this.provincesText[nt]) > -1) {
                    res.push([{
                      name: _this.provincesText[t],
                      s_name: ncx.cxCity,
                      value: v.value
                    }, {
                      name: _this.provincesText[nt],
                      s_name: ncx.cxCity,
                      value: ncx.value
                    }]);
                    var toCoord = geoCoordMap[_this.provincesText[nt]];
                    if (fromCoord && toCoord) {
                      coord.push([{
                        coord: fromCoord,
                        md: _this.provincesText[nt],
                        value: ncx.value
                      }, {
                        coord: toCoord,
                        md: _this.provincesText[nt],
                        value: ncx.value
                      }]);
                    }
                  }
                }
              }
              console.log(_this.provincesText[t], res, coord);
              _this.loadMapData.push([_this.provincesText[t], res, coord]);
            }
          }
        }
        console.log(_this.loadMapData);
        var series = [];
        _this.loadMapData.forEach(function (item, i) {
          series.push({
            // 系列名称，用于tooltip的显示
            name: item[0],
            type: 'lines',
            zlevel: 1,
            // 用于 Canvas 分层，不同zlevel值的图形会放置在不同的 Canvas 中
            // effect出发到目的地 的白色尾巴线条
            // 线特效的配置
            effect: {
              show: true,
              period: 6,
              // 特效动画的时间，单位为 s
              trailLength: 0.5,
              // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
              color: color[i],
              // 移动箭头颜色
              symbol: planePath,
              symbolSize: 6 // 特效标记的大小
            },

            // lineStyle出发到目的地 的线条颜色
            lineStyle: {
              normal: {
                color: color[i],
                width: 1,
                curveness: 0.2 // 幅度
              }
            },

            data: item[2] // 开始到结束数据
          }, {
            // 出发地信息
            name: item[0],
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: color[i]
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: color[i]
              }
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[0].name,
                s_name: dataItem[0].s_name,
                value: geoCoordMap[dataItem[0].name].concat([dataItem[0].value])
              };
            })
          }, {
            // 目的地信息
            name: item[0],
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: color[i]
                }
              }
            },
            symbolSize: function symbolSize(val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: color[i]
              }
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[1].name,
                s_name: dataItem[1].s_name,
                value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
              };
            })
          });
        });
        option = _this.map.getOption();
        option.series = series;
        option.legend[0].data = labeldata;
        _this.map.setOption(option, true);
        _this.map.hideLoading(); // 提示关闭
        _this.listLoading = false;
      });
    },
    searchList: function searchList(data) {
      this.listMapQuery = JSON.parse(JSON.stringify(data));
      this.getMapList();
      this.$refs.dataListIfam.activeName = 'queryMapChan';
      this.$refs.dataListIfam.listQuery.province = '';
      this.$refs.dataListIfam.queryMapChan_label = '疑似窜货统计';
      this.$refs.dataListIfam.queryMapWlChan_label = '产品疑似窜货统计';
      this.$refs.dataListIfam.queryMapJxsChan_label = '经销商/终端疑似窜货统计';
    }
  }
};
exports.default = _default;