"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/basic/product");
var _expel = require("@/api/fake_expel/expel/expel");
var _index = require("@/api/fake_expel/brand/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultSeachForm = {
  display_time: undefined,
  // 前台展示时间
  chosedate: []
};
var _default = {
  directives: {
    waves: _waves.default
  },
  props: {
    numberType: {
      default: ''
    },
    handleShow: {
      default: false
    }
  },
  data: function data() {
    return {
      // handleShow: false,
      formData: {
        pageNo: 1,
        pageSize: 20,
        ppwlList: [],
        wlm: '',
        queryType: '0',
        yschgsType: '',
        yschNum: '',
        yschqy: '',
        yjjxs: '',
        startTime: '',
        endTime: ''
      },
      brand: null,
      queryTime: [],
      month: parseInt(new Date().getMonth()) + 1,
      yjjxs: null,
      yschqy: null,
      brandData: '',
      optBrand: '',
      showSelectBrand: null,
      SelectBrand: [{
        pid: -1,
        brandName: '全部'
      }],
      brandList: null,
      defaultSeachForm: Object.assign({}, defaultSeachForm),
      shortcuts: [],
      selProList: [],
      proList: [],
      changeNumberv: true
      // pickerOptions2: {
      //   shortcuts: [
      //     {
      //       text: '最近一周',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //         picker.$emit('pick', [start, end])
      //       }
      //     },
      //     {
      //       text: '最近一个月',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      //         picker.$emit('pick', [start, end])
      //       }
      //     },
      //     {
      //       text: '最近三个月',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      //         picker.$emit('pick', [start, end])
      //       }
      //     }
      //   ]
      // }
    };
  },

  computed: {
    // chosedate: {
    //   get() {
    //     var e = this.shortcuts
    //     if (e == null || e == '') {
    //       e = ['', '']
    //     }
    //     return [new Date(e[0]), new Date(e[1])]
    //   },
    //   set(val) {
    //     if (val == null || val == '') {
    //       val = ['', '']
    //     }
    //     this.shortcuts = val
    //   }
    // }
  },
  created: function created() {
    this.month = this.month < 10 ? '0' + this.month + '' : this.month;
    var d = new Date().getFullYear() + '' + this.month;
    var lastDay = d + '' + this.getLastDay(new Date().getFullYear(), this.month);
    this.formData.startTime = d + '01';
    this.formData.endTime = lastDay;
    this.authId('searchbtn', this.$route.meta.authList);
    this.getBrand();
    // this.getOneLveDealerv()
    this.getChanAreav();
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getBrand: function getBrand() {
      var _this = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this.brandList = res.data.data;
          } else {
            _this.brandList = [];
          }
        }
      });
    },
    // 获得某月的最后一天
    getLastDay: function getLastDay(year, month) {
      // 取当前的年份
      var new_year = year;
      // 取下一个月的第一天，方便计算（最后一天不固定）
      var new_month = month++;
      if (month > 12) {
        // 月份减
        new_month -= 12;
        // 年份增
        new_year++;
      }
      // 取当年当月中的第一天
      var new_date = new Date(new_year, new_month, 1);
      // 获取当月最后一天日期
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate();
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.formData.yschNum = '';
        this.$parent.month = parseInt(new Date().getMonth()) + 1;
        this.month = this.$parent.month < 10 ? '0' + this.$parent.month + '' : this.$parent.month;
        var d = new Date().getFullYear() + '' + this.month;
        var lastDay = d + this.getLastDay(new Date().getFullYear(), this.month);
        this.formData = {
          pageNo: 1,
          pageSize: 20,
          ppwlList: [],
          wlm: '',
          queryType: '0',
          yschgsType: '',
          yschNum: '',
          yschqy: '',
          yjjxs: '',
          startTime: '',
          endTime: ''
        };
        this.formData.startTime = d + '01';
        this.formData.endTime = lastDay;
        this.selProList = [];
        this.brandData = '';
        this.proList = [];
      }
      var lists = [];
      if (!this.selProList.length && this.brandData.pId != '') {
        for (var i in this.proList) {
          lists.push(this.proList[i].materialCode);
        }
      } else {
        for (var obj in this.selProList) {
          lists.push(this.selProList[obj]);
        }
      }
      if (this.brandData.pId == '-1') {
        this.selProList = '';
        lists = [];
      }
      this.formData.pageNo = 1;
      this.formData.ppwlList = lists;
      this.$parent.searchList(this.formData);
    },
    getOneLveDealerv: function getOneLveDealerv() {
      var _this2 = this;
      (0, _expel.getOneLveDealer)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.yjjxs = data.data;
      });
    },
    getChanAreav: function getChanAreav() {
      var _this3 = this;
      (0, _expel.getChanArea)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return true;
        }
        _this3.yschqy = data.data;
      });
    },
    getqueryBrand: function getqueryBrand() {
      var _this4 = this;
      this.loading = true;
      (0, _index.queryBrand)({}).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this4.$message.error(data.msg);
          return true;
        }
        _this4.loading = false;
        for (var i in data.data) {
          _this4.SelectBrand.push({
            pid: data.data[i].pid,
            brandName: data.data[i].brandName
          });
        }
      });
    },
    getqueryMaterial: function getqueryMaterial() {
      var _this5 = this;
      this.loading = true;
      this.selProList = [];
      if (this.brandData == '-1' || this.brandData == '' || this.brandData == null) {
        this.proList = [];
        return false;
      }
      (0, _index.queryMaterial)({
        pId: this.brandData
      }).then(function (response) {
        var data = response.data;
        _this5.proList = [];
        if (data.code != 200) {
          _this5.$message.error(data.msg);
          return true;
        }
        _this5.loading = false;
        if (data.data.length <= 0) {
          _this5.$message.error('当前选择品牌下不存在产品信息');
          return true;
        }
        _this5.proList = data.data;
      }).catch(function () {
        _this5.proList = [];
        _this5.loading = false;
      });
    }
  }
};
exports.default = _default;