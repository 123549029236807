"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _log = require("@/api/systems/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _pdfScan = _interopRequireDefault(require("@/components/pdfScan"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemguidindex';
var _default = {
  name: 'Systemguidindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    pdfScan: _pdfScan.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        module: null,
        name: '',
        size: 20,
        type: ''
      },
      bookOption: [],
      orgOriginData: [],
      orgOptions: [],
      tableKey: 'scgl',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      showCol: false,
      expandTxt: '展开',
      pdfVisible: false,
      filePath: '',
      page: 1,
      addData: {
        filePath: '',
        module: null,
        moduleDesc: '',
        name: '',
        remark: '',
        type: '',
        typeDesc: ''
      },
      addVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入手册名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择手册类型',
          trigger: 'change'
        }],
        module: [{
          required: true,
          message: '请选择所属模块',
          trigger: 'change'
        }],
        filePath: [{
          required: true,
          message: '请选择上传手册文件',
          trigger: 'blur'
        }]
      },
      upLoading: false,
      subLoading: false,
      apis: []
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getOrg();
      that.getType();
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _log.getOperateBook)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _log.getMoudles)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          if (rData.length >= 0) {
            var arr = [];
            rData.forEach(function (item) {
              if (item.resourceLevel == 1 || item.resourceLevel == 2) {
                arr.push({
                  clientCode: item.clientCode,
                  id: item.pid,
                  pid: item.resourceCode,
                  parentPid: item.parentCode,
                  label: item.resourceName,
                  level: item.resourceLevel
                });
              }
            });
            that.orgOriginData = arr;
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        current: 1,
        module: null,
        name: '',
        size: 20,
        type: ''
      };
      that.getList();
    },
    // 获取手册类型
    getType: function getType() {
      var that = this;
      var param = {};
      param.code = 'CZSC';
      (0, _log.getBookType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.bookOption = res.data.data;
        }
      });
    },
    // 查看手册
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      that.filePath = data.filePath;
      that.page = 1;
      if (data.filePath) {
        that.$nextTick(function () {
          that.pdfVisible = true;
        });
      } else {
        that.$message.error('PDF路径为空，无法预览');
        return;
      }
    },
    hidePDF: function hidePDF() {
      this.pdfVisible = false;
    },
    // 删除
    DoDel: function DoDel(data) {
      var that = this;
      var setHeaderId = that.authId('deletebtn', that.apis);
      var param = {};
      param.id = data.pid;
      that.$confirm('确认要删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        that.deleteSure(param);
      }).catch(function (err) {
        console.log(err);
      });
    },
    deleteSure: function deleteSure(param) {
      var that = this;
      (0, _log.delBook)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.getList();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    // 手册下载
    downLoad: function downLoad(data) {
      var that = this;
      var setHeaderId = that.authId('downloadbtn', that.apis);
      var param = {};
      var flileName = data.name;
      param.filePath = data.filePath;
      (0, _log.downLoadBook)(param).then(function (res) {
        console.log(res);
        var blob = new Blob([res.data], {
          type: 'application/pdf;charset-UTF-8'
        });
        var href = window.URL.createObjectURL(blob);
        var downloadElement = document.createElement('a');
        downloadElement.href = href;
        downloadElement.download = flileName + '.pdf';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 添加
    add: function add() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.addData = {
        filePath: '',
        module: null,
        moduleDesc: '',
        name: '',
        remark: '',
        type: '',
        typeDesc: ''
      };
      that.addVisible = true;
    },
    chose: function chose(e) {
      var that = this;
      var key = e;
      var data = that.bookOption;
      for (var i = 0; i < data.length; i++) {
        if (data[i].figure == key) {
          that.addData.typeDesc = data[i].name;
          break;
        }
      }
    },
    selectModule: function selectModule(e) {
      var that = this;
      that.addData.moduleDesc = e.label;
      if (e.level == 1) {
        if (e.clientCode.indexOf('PC') >= 0) {
          that.$confirm('所属模块只可选择移动端一级节点与PC二级节点,请重新选择', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showClose: false,
            showCancelButton: false,
            type: 'warning'
          }).then(function () {
            that.clearMoudle();
          }).catch(function () {
            that.clearMoudle();
          });
          return;
        }
      }
    },
    clearMoudle: function clearMoudle() {
      var that = this;
      that.addData.module = null;
      that.addData.moduleDesc = '';
    },
    handleChange: function handleChange(file) {
      var that = this;
      that.upLoading = true;
      that.beforeUpload(file);
    },
    beforeUpload: function beforeUpload(file) {
      var that = this;
      var type = file.raw.type;
      var isPDF = type === 'application/pdf';
      if (!isPDF) {
        that.$message.error('请上传PDF文件');
        return;
      } else {
        that.handleUpload(file);
      }
    },
    handleUpload: function handleUpload(file) {
      var that = this;
      var formData = new FormData();
      formData.append('file', file.raw);
      (0, _log.upLoadBook)(formData).then(function (res) {
        that.upLoading = false;
        if (res.data.code == 200) {
          that.addData.filePath = res.data.data;
          that.$refs.file.focus();
          that.$refs.file.blur();
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    // 添加
    save: function save(formName) {
      var that = this;
      that.subLoading = true;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _log.addBook)(that.addData).then(function (res) {
            if (res.data.code == 200) {
              that.subLoading = false;
              that.$message({
                type: 'success',
                message: '保存成功'
              });
              that.getList();
              that.addVisible = false;
            } else {
              that.subLoading = false;
              that.$message.error(res.data.msg);
            }
          }).catch(function () {
            that.subLoading = false;
          });
        } else {
          that.subLoading = false;
          return false;
        }
      });
    },
    docancel: function docancel() {
      var that = this;
      that.addData = {
        filePath: '',
        module: null,
        moduleDesc: '',
        name: '',
        remark: '',
        type: '',
        typeDesc: ''
      };
      that.addVisible = false;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;