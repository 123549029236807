"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _compyrole = require("@/api/systems/compyrole");
var _compyuser = require("@/api/systems/compyuser");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  components: {
    Treeselect: _vueTreeselect.default
  },
  props: {
    editData: Object,
    edit: Boolean
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      rules: {
        roleName: [{
          required: true,
          validator: _compyrole.checkRequiredWord,
          trigger: 'blur'
        }],
        roleCode: [{
          required: true,
          message: '请输入角色编码',
          trigger: 'blur'
        }],
        orgId: [{
          required: true,
          message: '请选择组织机构',
          trigger: 'change'
        }],
        clientCodes: [{
          required: true,
          message: '请选择系统',
          trigger: 'change'
        }],
        isEnable: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      orgOptions: [],
      options: [],
      dstate: false
    };
  },
  watch: {
    edit: function edit(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.options = that.$parent.sysOptions;
        that.getOrg();
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('editForm');
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var data = that.$parent.editData;
          var subData = {};
          // subData.PId = data.pid
          subData.roleName = data.roleName;
          subData.roleCode = data.roleCode;
          subData.clientCodes = data.clientCodes.join(',').split(',');
          subData.remark = data.remark;
          subData.orgId = data.orgId;
          if (data.isEnable == true) {
            subData.isEnable = 1;
          } else {
            subData.isEnable = 0;
          }
          (0, _compyrole.editRole)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.$parent.editShow = false;
              that.dialogVisible = false;
              that.loading = false;
              that.dsate = false;
              that.$parent.getList();
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.editShow = false;
      that.dialogVisible = false;
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.orgId;
              obj.label = item.orgName;
              obj.pid = item.orgId;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    }
  }
};
exports.default = _default;