"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataList = _interopRequireDefault(require("../mixins/dataList.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    //
    billTypeFun: function billTypeFun(val) {
      var billType = {
        '101': '生产入库',
        '102': '调货入库',
        '103': '退货入库',
        '104': '采购入库',
        '105': '其它入库',
        '106': '销售退货',
        '107': '费用酒入库',
        '108': '稽查入库',
        '201': '返工出库',
        '202': '调货出库',
        '203': '销售出库',
        '204': '退货出库',
        '205': '其它出库',
        '207': '费用酒出库'
      };
      return billType[val];
    }
  },
  mixins: [_dataList.default],
  data: function data() {
    return {
      searchForm: {
        inOrg: '',
        outOrg: '',
        materialCode: '',
        productName: '',
        orderNo: '',
        endTime: '',
        startTime: '',
        current: 1,
        size: 10,
        orderType: '2'
      },
      outOptions: [{
        value: '14',
        label: '销售出库'
      }, {
        value: '16',
        label: '销毁出库'
      }, {
        value: '15',
        label: '调拨出库'
      }],
      cpKeyData: {
        newpsid: '企业自定义产品编号',
        psid: '产品编码',
        psname: '产品名称',
        typeid: '产品特征编号',
        norms: '产品规格'
        // billstate: '单据状态',
        // enpid: '包装比例编号',
        // seqid: '企业编号'
      },

      kfKeyData: {
        billnum: '单据号',
        billtypeStr: '单据类型说明',
        outstore: '出库库房',
        outdealer: '出库经销商',
        instore: '入库库房',
        indealer: '入库经销商',
        plancount: '计划总量',
        factcount: '实际总量',
        createdate: '入库日期',
        note: '备注'
      },
      title: '出库'
    };
  },
  mounted: function mounted() {},
  methods: {}
};
exports.default = _default;