"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
require("../../../node_modules/echarts/map/js/china.js");
//
//
//
//
// 引入中国地图数据
// const animationDuration = 6000
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '800px'
    },
    tipName: {
      type: String,
      default: ''
    },
    fontSize: {
      type: Number,
      default: 12
    }
  },
  data: function data() {
    return {
      chart: null,
      mapData: [],
      max: 50
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        backgroundColor: '#ffffff',
        tooltip: {},
        // 鼠标移到图里面的浮动提示框
        visualMap: {
          show: true,
          min: 1,
          max: this.max,
          realtime: true,
          orient: 'horizontal',
          align: 'bottom',
          itemWidth: '10px',
          calculable: true,
          inRange: {
            color: ['LightSkyBlue', 'RoyalBlue']
          },
          top: '10px',
          right: '10px'
        },
        geo: {
          // 这个是重点配置区
          map: 'china',
          // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true,
              // 是否显示对应地名
              fontSize: this.fontSize,
              textStyle: {
                color: 'rgba(0,0,0,0.45)'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [{
          type: 'scatter',
          coordinateSystem: 'geo' // 对应上方配置
        }, {
          name: this.tipName,
          // 浮动框的标题
          type: 'map',
          geoIndex: 0,
          data: this.mapData
        }]
      });
    }
  }
};
exports.default = _default;