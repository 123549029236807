var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-width": "60px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "moduleCode", label: "模块" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.getPages },
                                  model: {
                                    value: _vm.listQuery.moduleCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "moduleCode", $$v)
                                    },
                                    expression: "listQuery.moduleCode",
                                  },
                                },
                                _vm._l(_vm.moduleData, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.moduleName,
                                      value: item.moduleCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "billCode", label: "单据" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.billCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "billCode", $$v)
                                    },
                                    expression: "listQuery.billCode",
                                  },
                                },
                                _vm._l(_vm.pageData, function (item) {
                                  return _c("el-option", {
                                    key: item.billCode,
                                    attrs: {
                                      label: item.billName,
                                      value: item.billCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "stauts", label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.stauts,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "stauts", $$v)
                                    },
                                    expression: "listQuery.stauts",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 0,
                                    attrs: { label: "审批中", value: 0 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: 1,
                                    attrs: { label: "审批结束", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: { label: "审批驳回", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billNo", label: "单号" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "billNo", $$v)
                                      },
                                      expression: "listQuery.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orgCode", label: "组织" } },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织...",
                                      options: _vm.options,
                                    },
                                    model: {
                                      value: _vm.listQuery.orgCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orgCode", $$v)
                                      },
                                      expression: "listQuery.orgCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "40px" } },
                            [
                              _vm.apis[0].authBtn
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.DoSearch },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-search",
                                      }),
                                      _vm._v(" 查询\n              "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset("listQuery")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.expand },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.expandTxt) +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class:
                                      _vm.showCol == false
                                        ? "el-icon-arrow-down"
                                        : "el-icon-arrow-up",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "编码",
                  prop: "exeutionNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "模块",
                  prop: "moduleName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据",
                  prop: "billName",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据号",
                  prop: "billNo",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "所属组织",
                  prop: "orgName",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "status",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == "0"
                          ? _c("span", [_vm._v("审批中")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "1"
                          ? _c("span", [_vm._v("审批结束")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "2"
                          ? _c("span", [_vm._v("审批驳回")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-progress", {
                          attrs: {
                            "text-inside": true,
                            "stroke-linecap": "square",
                            "stroke-width": 16,
                            percentage: Number(row.progress),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "来自",
                  prop: "createName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "最近处理时间",
                  prop: "updateTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("formatTime")(row.updateTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "耗时",
                  prop: "createTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("rangeTime")(row.createTime)) +
                            "\n          "
                        ),
                        _c("span", [_vm._v("小时")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoScan(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.pageNo,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "审批-查看",
            visible: _vm.dialogVisible1,
            "before-close": _vm.handleClose1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c("h3", [_vm._v("基本信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.scanData } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "编码:", prop: "exeutionNo" } },
                [_vm._v(_vm._s(_vm.scanData.exeutionNo))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块:", prop: "moduleName" } },
                [_vm._v(_vm._s(_vm.scanData.moduleName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据:", prop: "billName" } },
                [_vm._v(_vm._s(_vm.scanData.billName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据号:", prop: "billNo" } },
                [_vm._v(_vm._s(_vm.scanData.billNo))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属组织:", prop: "orgName" } },
                [_vm._v(_vm._s(_vm.scanData.orgName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "status" } },
                [
                  _vm.scanData.status == "0"
                    ? _c("span", [_vm._v("审批中")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scanData.status == "1"
                    ? _c("span", [_vm._v("审批结束")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scanData.status == "2"
                    ? _c("span", [_vm._v("审批驳回")])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "来自:", prop: "createName" } },
                [_vm._v(_vm._s(_vm.scanData.createName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最近处理时间:", prop: "updateTime" } },
                [_vm._v(_vm._s(_vm._f("formatTime")(_vm.scanData.updateTime)))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "耗时:", prop: "createTime" } },
                [_vm._v(_vm._s(_vm._f("rangeTime")(_vm.scanData.createTime)))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("审批流")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "节点名称",
                  prop: "name",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批人",
                  prop: "userNmae",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批角色",
                  prop: "userRole",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批人组织",
                  prop: "userOrg",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批时间",
                  prop: "checkTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批结果",
                  prop: "type",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.type == "1"
                          ? _c("span", [_vm._v("通过")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.type == "2"
                          ? _c("span", [_vm._v("拒绝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批意见",
                  prop: "auditmsg",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }