var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "moduleCode", label: "模块:" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.moduleCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "moduleCode",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.moduleCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.moduleData,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.moduleName,
                                            value: item.moduleCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billCode", label: "单据:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.billCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "billCode",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.billCode",
                                      },
                                    },
                                    _vm._l(_vm.pageData, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.name,
                                          value: item.figure,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "name", label: "审批名称:" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "name", $$v)
                                      },
                                      expression: "listQuery.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "status", label: "状态:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "status", $$v)
                                        },
                                        expression: "listQuery.status",
                                      },
                                    },
                                    _vm._l(_vm.stateOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: { click: _vm.DoSearch },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.DoReset("listQuery")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group fun-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.add },
                            },
                            [_vm._v("创建审批流")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "审批流编码",
                      prop: "modelKey",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "模块",
                      prop: "moduleName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据",
                      prop: "billName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批流名称",
                      prop: "modelName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "详情页地址",
                      prop: "detailUrl",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "status",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.status == "0"
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("失效"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "1"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("生效"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3412546753
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "220",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("updatebtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("setbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setConfigNode(row)
                                        },
                                      },
                                    },
                                    [_vm._v("审批节点配置")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "0" &&
                              _vm.hasAuth("startbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enadleState(row)
                                        },
                                      },
                                    },
                                    [_vm._v("生效")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == "1" &&
                              _vm.hasAuth("stopbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.disadleState(row)
                                        },
                                      },
                                    },
                                    [_vm._v("失效")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.upLoad(row)
                                        },
                                      },
                                    },
                                    [_vm._v("上传")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downLoad(row)
                                        },
                                      },
                                    },
                                    [_vm._v("下载")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3901518212
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("edit", {
        attrs: { dialogVisible: _vm.editShow, pId: _vm.pId },
        on: { close: _vm.closeEdit },
      }),
      _vm._v(" "),
      _c("add", {
        attrs: { dialogVisible: _vm.addShow },
        on: { close: _vm.closeAdd },
      }),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            title: "审批节点配置",
            visible: _vm.configVisible,
            "before-close": _vm.configClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.configVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.nodeList, function (item, index) {
            return _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleform" + index,
                    refInFor: true,
                    attrs: { model: item, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: item.nodeName,
                                  "label-width": "100px",
                                  prop: "nodeType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        item.isAllowModify == 1 ? false : true,
                                    },
                                    model: {
                                      value: item.nodeType,
                                      callback: function ($$v) {
                                        _vm.$set(item, "nodeType", $$v)
                                      },
                                      expression: "item.nodeType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "2",
                                      attrs: { label: "角色", value: "2" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      key: "1",
                                      attrs: { label: "用户", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { "label-width": "0", prop: "nodeArr" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      multiple: "",
                                      placeholder: "请选择",
                                      disabled:
                                        item.isAllowModify == 1 ? false : true,
                                    },
                                    model: {
                                      value: item.nodeArr,
                                      callback: function ($$v) {
                                        _vm.$set(item, "nodeArr", $$v)
                                      },
                                      expression: "item.nodeArr",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.roleList, function (node) {
                                      return item.nodeType == "2"
                                        ? _c("el-option", {
                                            key: node.roleCode,
                                            attrs: {
                                              label: node.roleName,
                                              value: node.roleCode,
                                            },
                                          })
                                        : _vm._e()
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.companyList, function (node) {
                                      return item.nodeType == "1"
                                        ? _c(
                                            "el-option",
                                            {
                                              key: node.pid,
                                              attrs: {
                                                label: node.loginAccount,
                                                value: node.pid,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(node.loginAccount)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "right",
                                                    color: "#8492a6",
                                                    "font-size": "12px",
                                                    "margin-right": "60px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(node.phoneNum))]
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.mloading,
                    loading: _vm.mloading,
                  },
                  on: { click: _vm.saveData },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.configClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审批流配置-上传",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            "append-to-body": "",
            width: "1200px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "160px" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    "on-change": _vm.change,
                    "file-list": _vm.fileList,
                    "auto-upload": false,
                    multiple: false,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        type: "primary",
                        size: "small",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选取文件")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("请上传xml文件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                  },
                  on: { click: _vm.submitUpload },
                },
                [_vm._v("上传到服务器")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n\t\t"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }