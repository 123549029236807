var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "table-box" } },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              {
                attrs: { label: _vm.queryMapChan_label, name: "queryMapChan" },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading.queryMapChan,
                        expression: "listLoading.queryMapChan",
                      },
                    ],
                    key: _vm.tableKey.queryMapChan,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list.queryMapChan,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "120",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "疑似窜出地区",
                        "min-width": "120",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.ysccPro))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "疑似窜货个数",
                        align: _vm.tableConfig.align,
                        width: "170",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "primary",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { title: "点击查看疑似窜货个数明细" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getSetDigital(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.value))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "排名",
                        type: "index",
                        align: _vm.tableConfig.align,
                        width: "170",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("digital", { ref: "Dialogdigital" }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: _vm.queryMapWlChan_label,
                  name: "queryMapWlChan",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading.queryMapWlChan,
                        expression: "listLoading.queryMapWlChan",
                      },
                    ],
                    key: _vm.tableKey.queryMapWlChan,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list.queryMapWlChan,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "120",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "品牌",
                        align: _vm.tableConfig.align,
                        width: "170",
                        "show-overflow-tooltip": "",
                        prop: "ppName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品名称",
                        align: _vm.tableConfig.align,
                        "min-width": "180",
                        prop: "wlms",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "疑似窜货个数",
                        align: _vm.tableConfig.align,
                        width: "170",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "primary",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { title: "点击查看疑似窜货个数明细" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getSetqueryMapWlChan(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.value))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "排名",
                        align: _vm.tableConfig.align,
                        width: "170",
                        type: "index",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("queryMapwlChanDigital", {
                  ref: "DialogqueryMapwlChanDigital",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: _vm.queryMapJxsChan_label,
                  name: "queryMapJxsChan",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading.queryMapJxsChan,
                        expression: "listLoading.queryMapJxsChan",
                      },
                    ],
                    key: _vm.tableKey.queryMapJxsChan,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list.queryMapJxsChan,
                      "tooltip-effect": "dark",
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "120",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "经销商/终端",
                        "min-width": "280",
                        align: _vm.tableConfig.align,
                        prop: "yjjxs",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "疑似窜货个数",
                        align: _vm.tableConfig.align,
                        width: "170",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "primary",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { title: "点击查看疑似窜货个数明细" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getSetqueryMapJxsChan(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.value))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "排名",
                        align: _vm.tableConfig.align,
                        width: "170",
                        type: "index",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("queryMapJxsChanDigital", {
                  ref: "DialogqueryMapJxsChanDigital",
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }