"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCgData = addCgData;
exports.delCgData = delCgData;
exports.editCgData = editCgData;
exports.exportCgData = exportCgData;
exports.exportKcList = exportKcList;
exports.exportYData = exportYData;
exports.getCgDetail = getCgDetail;
exports.getCgList = getCgList;
exports.getCgState = getCgState;
exports.getPackMinNum = getPackMinNum;
exports.getQdXj = getQdXj;
exports.getQueryOrg = getQueryOrg;
exports.getSaleList = getSaleList;
exports.getYdList = getYdList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * getCgList,
 getCgDetail,
getCgState,
exportCgData,
getQdXj
*
* **/

// 获取采购列表
function getCgList(current, size, data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryPurchaseForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 获取采购订单列表详情
function getCgDetail(param) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/queryById/' + param,
    method: 'get'
  });
}

// 获取采购订单列表状态
function getCgState(param) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getItemByDataDictionaryByCode',
    method: 'get',
    params: param
  });
}

// 添加采购订单
function addCgData(data) {
  return (0, _request.default)({
    url: '/api-channel/pc/cg/insert',
    method: 'post',
    data: data
  });
}

// 编辑采购订单
function editCgData(data) {
  return (0, _request.default)({
    url: '/api-channel/pc/cg/update',
    method: 'post',
    data: data
  });
}

// 删除采购单
function delCgData(param) {
  return (0, _request.default)({
    url: '/api-channel/pc/cg/delete',
    method: 'post',
    params: param
  });
}

// 导出采购订单
function exportCgData(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/exportChannelForExcel',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}

// 导出引单
function exportYData(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/exportBusinessSale',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}

// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: ''
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 查询全渠道下级
function getQdXj(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/xjlist',
    method: 'get',
    params: param
  });
}

// 产品包装比例
function getPackMinNum(data) {
  return (0, _request.default)({
    url: '/api-channel/pc/product/packNum',
    method: 'post',
    data: data
  });
}

// 采购订单导出
function exportKcList(param) {
  return (0, _request.default)({
    url: '/api-channel/xcx/yd/cg/export',
    method: 'post',
    params: param
  });
}

// 获取销售订单列表
function getSaleList(current, size, data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/querySaleForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 引单管理统计
function getYdList(current, size, data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryBusinessSaleForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}