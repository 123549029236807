"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'table-suply',
  components: {},
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      miniUnit = num;
      row.sumPlanAto = num;
      return num;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '修改调拨入库',
      billType: 102,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        inStoreId: '',
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: 102,
        sumPlanAtoNum: '',
        sumPlanNum: '',
        tradeBillDetailAOS: []
      },
      disabled: {
        num: true
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      proList: [],
      sourceList: [{
        name: '平台自动创建',
        id: 1
      }, {
        name: '平台用户创建',
        id: 2
      }, {
        name: 'PDA创建',
        id: 3
      }, {
        name: 'ERP同步',
        id: 4
      }],
      inOrgTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }, {
        name: '经销商',
        id: 3
      }],
      outOrgTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }],
      inOrg: {},
      taskShow: false,
      rules: {
        billNo: [{
          required: true,
          message: '入库单号不能为空，请维护',
          trigger: 'change'
        }],
        relateNo: [{
          required: true,
          message: '销售出库单号不能为空，请维护',
          trigger: 'change'
        }],
        inStoreId: [{
          required: true,
          message: '请选入库库房',
          trigger: 'change'
        }]
      },
      inOrgName: '',
      outOrgName: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    init: function init() {
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
    },
    handClose: function handClose() {
      var that = this;
      that.scanData = {
        billNo: '',
        billType: 102,
        billSource: '',
        sumPlanAtoNum: '',
        sumPlanNum: '',
        inStoreId: '',
        tradeBillDetailAOS: []
      };
      that.dialogVisible = false;
    },
    upDateShow: function upDateShow(row) {
      var that = this;
      this.init();
      this.title = '修改调拨入库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      this.inOrgName = '';
      this.outOrgName = '';
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      setTimeout(function () {
        that.dialogVisible = true;
      }, 500);

      //获取详细信息
      var param = row.billNo;
      that.scanData.billNo = row.billNo;
      (0, _index.getPeoductionDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          var basic = res.data.data.tradeBillAllVO;
          var promsg = res.data.data.tradeProductVOS;
          that.proList = promsg;
          that.transFormData(promsg);
          that.scanData.billNo = basic.billNo;
          that.scanData.billSource = basic.billSource;
          that.scanData.billStatus = basic.billStatus;

          //出入库企业id
          that.inOrgName = basic.inOrgName;
          that.outOrgName = basic.outOrgName;
          var inStoreName = basic.inStoreName;

          //入库库房
          that.factoryList = [];
          that.getHouses(basic.inOrgId, inStoreName);
        } else {
          that.proList = [];
          that.transFormData(that.proList);
          that.scanData.billNo = '';
          that.scanData.billSource = '';
          that.scanData.billStatus = '';
          that.scanData.inStoreId = '';
          that.inOrgName = '';
          that.outOrgName = '';
          that.$message.error(res.data.msg);
          return;
        }
      });
      this.mloading = false;
    },
    //创建单号
    getCreateNum: function getCreateNum() {
      var _this2 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        }
        _this2.scanData.billNo = res.data.data;
        _this2.disabled.num = true;
      }).catch(function (err) {});
    },
    //获取销售出库单号
    getRelateNo: function getRelateNo() {
      var that = this;
      that.taskShow = true;
    },
    hideTask: function hideTask() {
      var that = this;
      that.taskShow = false;
    },
    //获取入库库房
    getHouses: function getHouses(arg, storeName) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.factoryList = [];
          that.factoryList = res.data.data;
          return res.data.data;
        }
      }).then(function (res) {
        if (res.length > 0) {
          for (var i = 0; i < res.length; i++) {
            if (res[i].storeHouseName == storeName) {
              that.scanData.inStoreId = res[i].pId;
              break;
            }
          }
        }
      });
    },
    //产品数据转换
    transFormData: function transFormData(rsData) {
      var that = this;
      var planScanAtoNum = 0;
      var planScanNum = 0;
      that.scanData.tradeBillDetailAOS = [];
      if (rsData.length > 0) {
        rsData.forEach(function (item) {
          var obj = {};
          planScanAtoNum += Number(item.planScanAtoNum);
          planScanNum += Number(item.planScanNum);
          obj.billNo = that.scanData.billNo;
          obj.packUnit = item.packUnit;
          obj.planScanAtoNum = item.planScanAtoNum;
          obj.planScanNum = item.planScanNum;
          obj.prodBatchNo = item.prodBatchNo;
          obj.productId = item.productId;
          that.scanData.tradeBillDetailAOS.push(obj);
        });
      }
      that.scanData.planScanAtoNum = planScanAtoNum;
      that.scanData.planScanNum = planScanNum;
    },
    submitForm: function submitForm(temp) {
      var _this3 = this;
      //this.setDetail()
      var that = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          if (_this3.submitType == 2) {
            var tradeBillAO = {};
            tradeBillAO.billInTransAO = {};
            tradeBillAO.billInTransAO.inStoreId = _this3.scanData.inStoreId;
            tradeBillAO.billNo = _this3.scanData.billNo;
            tradeBillAO.billType = _this3.scanData.billType;
            tradeBillAO.billSource = _this3.scanData.billSource;
            tradeBillAO.billStatus = _this3.scanData.billStatus;
            tradeBillAO.sumPlanAtoNum = that.scanData.planScanAtoNum;
            tradeBillAO.sumPlanNum = that.scanData.planScanNum;
            tradeBillAO.tradeBillDetailAOS = _this3.scanData.tradeBillDetailAOS;
            (0, _index.InfoUpdate)(tradeBillAO).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.$parent.getList();
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }
        }
      });
    },
    handleClose: function handleClose() {
      this.handClose();
      this.dialogVisible = false;
      this.$parent.getList();
    }
  }
};
exports.default = _default;