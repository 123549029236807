"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detailBypId = detailBypId;
exports.findAllPolicyStyle = findAllPolicyStyle;
exports.findAllProduct = findAllProduct;
exports.warrantyPolicyList = warrantyPolicyList;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';

// 获取质保列表-分页
function warrantyPolicyList(data) {
  return (0, _request.default)({
    url: modelUrl + '/warrantyPolicy/list',
    method: 'post',
    data: data
  });
}
// 根据ID获取质保政策详情
function detailBypId(params) {
  return (0, _request.default)({
    url: modelUrl + '/warrantyPolicy/detail',
    method: 'get',
    params: params
  });
}

// 查询全部质保政策及明细,不分页
function findAllProduct(data) {
  return (0, _request.default)({
    url: modelUrl + '/warrantyPolicy/findAllProduct',
    method: 'post',
    data: data
  });
}
// 全部质保政策保修方式下拉()
function findAllPolicyStyle(data) {
  return (0, _request.default)({
    url: modelUrl + '/warrantyPolicy/findAllPolicyStyle',
    method: 'post',
    data: data
  });
}