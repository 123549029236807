"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stockscan = require("@/api/chanelmange/stockscan");
var _rebate = require("@/api/chanelmange/rebate");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _selectHdFn = _interopRequireDefault(require("./component/selectHdFn"));
var _codeYearScan = _interopRequireDefault(require("./component/codeYearScan"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = "chanelmangerebatereportStaticzdndflRecord";
var _default = {
  name: "chanelmangerebatereportStaticzdndflRecord",
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    instockUnit: _purchaseDialog.default,
    productChose: _productSelectDialog.default,
    selectHdFn: _selectHdFn.default,
    codeYearScan: _codeYearScan.default
  },
  data: function data() {
    return {
      listQuery: {
        channelId: null,
        channelType: null,
        code: null,
        current: 1,
        endTime: null,
        orgId: null,
        productId: null,
        rebateConfigId: null,
        size: 20,
        startTime: null,
        supplierId: null,
        supplierType: null
      },
      channelName: "",
      supplierName: "",
      rebateConfigName: "",
      orgOptions: [],
      productName: "",
      productShow: false,
      unitShow: false,
      tableKey: "hdflrecord",
      tabHeight: "100%",
      list: [],
      listLoading: false,
      exportLoading: false,
      codeVisible: false,
      codeParams: {},
      total: 0,
      showCol: false,
      expandTxt: "展开",
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth("searchbtn", authList);
    var setHeaderId = that.authId("searchbtn", that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == "" || value == null) {
        return "";
      } else {
        return (0, _utils.parseTime)(value, "{y}-{m}-{d}");
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId("searchbtn", that.apis);
      that.listLoading = true;
      (0, _rebate.getNdFlRecord)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.pages.records;
          that.total = parseInt(res.data.data.pages.total);
          that.listLoading = false;
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
      if (that.listQuery.orgId == "") {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.listQuery = {
        channelId: null,
        channelType: null,
        code: null,
        current: 1,
        endTime: null,
        orgId: null,
        productId: null,
        rebateConfigId: null,
        size: 20,
        startTime: null,
        supplierId: null,
        supplierType: null
      };
      that.channelName = "";
      that.supplierName = "";
      that.productName = "";
      that.rebateConfigName = "";
      that.listQuery.current = 1;
      that.getList();
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, "pid", "parentPid", "children");
          }
        }
      });
    },
    clearQd: function clearQd() {
      var that = this;
      that.listQuery.channelId = "";
      that.channelName = "";
    },
    clearSply: function clearSply() {
      var that = this;
      that.listQuery.supplierId = "";
      that.supplierName = "";
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = "";
      that.productName = "";
    },
    //获取单位
    getUnit: function getUnit(val) {
      var _this = this;
      var that = this;
      var setHeaderId = that.authId("searchbtn", that.apis);
      that.unitType = val;
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == "qd") {
        that.listQuery.channelId = val.pId;
        that.listQuery.channelType = val.channelType;
        that.channelName = val.channelName;
      }
      if (that.unitType == "sply") {
        that.listQuery.supplierId = val.pId;
        that.listQuery.supplierType = val.channelType == 22 ? 0 : val.channelType;
        that.supplierName = val.channelName;
      }
      that.unitShow = false;
    },
    //获取产品
    getProductId: function getProductId() {
      var that = this;
      var setHeaderId = that.authId("searchbtn", that.apis);
      this.$refs.selProduct.proSelVisible = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      that.listQuery.productId = val.pId;
      that.productName = val.productName;
      that.productShow = false;
    },
    //获取活动方案
    clearConfig: function clearConfig() {
      var that = this;
      that.rebateConfigName = "";
      that.listQuery.rebateConfigId = "";
    },
    showConfig: function showConfig() {
      var that = this;
      var setHeaderId = that.authId("searchbtn", that.apis);
      that.$refs.hdfn.configVisible = true;
    },
    getConfig: function getConfig(val) {
      var that = this;
      that.rebateConfigName = val.schemeName;
      that.listQuery.rebateConfigId = val.pid;
    },
    //查看数码
    scanCodes: function scanCodes(row) {
      var that = this;
      var setHeaderId = that.authId("detailbtn", that.apis);
      that.codeParams.channelId = row.channelId;
      that.codeParams.productId = row.productID;
      that.codeParams.rebateConfigId = row.rebateConfigId;
      that.codeParams.supplierId = row.supplierId;
      that.codeParams.supplierType = row.supplierType;
      that.codeParams.triggerKey = row.triggerKey;
      that.$nextTick(function () {
        that.codeVisible = true;
      });
    },
    closeCode: function closeCode() {
      var that = this;
      that.codeVisible = false;
    },
    //导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId("importbtn", that.apis);
      if (that.total > 50000) {
        that.$message.error("抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条");
        return;
      }
      var param = {};
      param.channelId = that.listQuery.channelId;
      param.channelType = that.listQuery.channelType;
      param.code = that.listQuery.code;
      param.endTime = that.listQuery.endTime;
      param.orgId = that.listQuery.orgId;
      param.productId = that.listQuery.productId;
      param.rebateConfigId = that.listQuery.rebateConfigId;
      param.startTime = that.listQuery.startTime;
      param.supplierId = that.listQuery.supplierId;
      param.supplierType = that.listQuery.supplierType;
      that.exportLoading = true;
      (0, _rebate.exportNdFlAll)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "活动返利流水.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = "收起";
      } else {
        that.expandTxt = "展开";
      }
    }
  }
};
exports.default = _default;