"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCenterpurchaseCcupdate',
  components: {
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      placeOrderDateEdit: false,
      remarkEdit: false,
      cardPadding: {
        padding: '0 0 20px'
      },
      btncardPadding: {
        padding: '0'
      },
      loading: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      orderStatus: [],
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: '',
        tradeType: ''
      },
      productList: [],
      companyShow: false,
      productShow: false
    };
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCCGDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCenterpurchase-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum
              // totalPrice: item.totalPrice,
              // productPrice: item.prodPrice,
            };

            arr.push(obj);
          });
          _this3.productList = arr;
          _this3.addData = res.data.data;
          _this3.purchaseComName = data.purchaseOrgName;
          _this3.receiveComName = data.receiveOrgName;
          _this3.sendOutComName = data.saleOrgName;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 0采购单位 1 收获单位 2发货单位(订单：销售单位) 3 销售单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'purchaseComName':
          this.addData.purchaseOrgId = val.companyId;
          // //  0企业 1一级经销商 2终端,3工厂，22销售公司,33生产公司
          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.purchaseComType = 3
          //     this.addData.receiveComType = 3
          //     break
          //   case 22:
          //     this.addData.purchaseComType = 2
          //     this.addData.receiveComType = 2
          //     break
          //   case 33:
          //     this.addData.purchaseComType = 1
          //     this.addData.receiveComType = 1
          //     break
          //   default:
          //     this.addData.purchaseComType = 2
          //     this.addData.receiveComType = 2
          // }
          this.addData.receiveOrgId = val.companyId;
          this.receiveComName = val.companyName;
          this.getReceiveInfo(val);
          break;
        case 'receiveComName':
          this.addData.receiveOrgId = val.companyId;

          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.receiveComType = 3
          //     break
          //   case 22:
          //     this.addData.receiveComType = 2
          //     break
          //   case 33:
          //     this.addData.receiveComType = 1
          //     break
          //   default:
          //     this.addData.receiveComType = 2
          // }
          this.getReceiveInfo(val);
          break;
        case 'sendOutComName':
          this.addData.saleOrgId = val.companyId;
          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.sendOutComType = 3
          //     break
          //   case 22:
          //     this.addData.sendOutComType = 2
          //     break
          //   case 33:
          //     this.addData.sendOutComType = 1
          //     break
          //   default:
          //     this.addData.sendOutComType = 1
          // }
          break;
      }
    },
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _order.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveUser = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    openPro: function openPro() {
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var arr = JSON.parse(JSON.stringify(this.productList));
      if (val.length) {
        if (this.productList.length == 0) {
          var obj = {
            chestNum: '',
            bottleNum: '',
            totalBottleNum: 0
            // isUseCoupon: 0,
          };

          val.forEach(function (item) {
            delete item.pid;
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.chestNum = '';
            item.bottleNum = '';
            item.totalBottleNum = 0;
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.pid = el.pid;
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.totalBottleNum = el.totalBottleNum;
              }
            });
          });
        }
        this.productList = JSON.parse(JSON.stringify(val));
      } else {
        this.productList = [];
      }
    },
    proClose: function proClose() {
      this.productShow = false;
    },
    totalPrice: function totalPrice(row) {
      var value = row.packScaleExpression;
      var num = row.bottleNum * 1;
      num = num || 0;
      var prize = row.productPrice;
      if (!value || !num) {
        row.totalPrice = 0;
        return 0;
      } else {
        var total = num * (prize * 1);
        row.totalPrice = total.toFixed(2);
        return row.totalPrice;
      }
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.totalBottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNum || 0);
      this.resetList();
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this5 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.productList.splice(_this5.productList.indexOf(row), 1);
      });
    },
    mixNumP: function mixNumP(row) {
      if (!row.packScaleExpression) return 0;
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    handleClose: function handleClose() {
      this.$router.replace({
        name: 'orderCenterpurchase-ccindex'
      });
    },
    submitForm: function submitForm(val) {
      var _this6 = this;
      var that = this;
      // that.addData.totalMoney = that.totalMoney
      if (!that.productList.length) {
        that.$message.error('请选择产品');
        return;
      }
      var plist = that.productList;
      var oldList = JSON.parse(JSON.stringify(that.addData.orderProductDetailVOS));
      var newList = [];
      if (plist.length > 0) {
        for (var i = 0; i < plist.length; i++) {
          if (!plist[i].totalBottleNum || plist[i].totalBottleNum == 0) {
            that.$message.error('在列表第' + (i + 1) + '行请输入采购数量');
            return;
          }
          var obj = {
            pid: plist[i].pid,
            totalBottleNum: plist[i].totalBottleNum,
            bottleNum: plist[i].bottleNum,
            chestNum: plist[i].chestNum,
            orderNo: that.addData.orderNo,
            packScaleExpression: plist[i].packScaleExpression,
            // prodPrice: plist[i].productPrice,
            // productCode: plist[i].productCode,
            productId: plist[i].pId,
            productName: plist[i].productName
            // productSpec: plist[i].productModel,
            // totalPrice: plist[i].totalPrice,
            // isUseCoupon: plist[i].isUseCoupon,
          };

          oldList.forEach(function (item) {
            if (item.pid == obj.pid) {
              obj = Object.assign(item, obj);
            }
          });
          newList.push(obj);
        }
      }
      // that.addData.orderDetailDTOS = newList
      that.loading = true;
      that.dsate = true;
      var subData = {
        orderNo: that.addData.orderNo,
        orderProductAOS: newList,
        orderSource: 1,
        orderType: that.addData.orderType,
        purchaseOrgId: that.addData.purchaseOrgId,
        receiveAddress: that.addData.receiveAddress,
        receiveOrgId: that.addData.receiveOrgId,
        receivePhone: that.addData.receivePhone,
        receiveUser: that.addData.receiveUser,
        remark: that.addData.remark,
        saleOrgId: that.addData.saleOrgId,
        tradeDate: that.addData.tradeDate.split(' ')[0],
        tradeType: 1
      };
      (0, _order.modPurchaseOrder)(subData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '操作成功'
          });
          _this6.handleClose();
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    }
  }
};
exports.default = _default;