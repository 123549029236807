var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "详情",
        visible: _vm.visible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "cols" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.list, border: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    width: "80",
                    align: "center",
                    type: "index",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "销售订单号",
                    "min-width": "160",
                    prop: "orderNo",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                false
                  ? _c("el-table-column", {
                      attrs: {
                        label: "订单来源",
                        "min-width": "160",
                        prop: "orderSource",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("orderSource")(row.orderSource))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1238629830
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "关联采购单号",
                    "min-width": "160",
                    prop: "relationOrderNo",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "采购单位",
                    "min-width": "120",
                    prop: "receiveOrgName",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "发货单位",
                    "min-width": "120",
                    prop: "sendOrgName",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "收货单位",
                    "min-width": "120",
                    prop: "receiveOrgName",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "销售单位",
                    "min-width": "120",
                    prop: "saleOrgName",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "销售订单类型",
                    "min-width": "120",
                    prop: "orderType",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(_vm._s(_vm._f("orderType")(row.orderType))),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                false
                  ? _c("el-table-column", {
                      attrs: {
                        label: "订单状态",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("tradeStatus")(row.tradeStatus))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        57197830
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "寄存数量(盒)",
                    "min-width": "110",
                    prop: "depositAtoNum",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "寄存数量(箱)",
                    "min-width": "110",
                    prop: "depositNum",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建人",
                    "min-width": "110",
                    prop: "creatorUser",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建日期",
                    "min-width": "110",
                    prop: "createTime",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    "min-width": "110",
                    prop: "remark",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.listQuery.current,
                limit: _vm.listQuery.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.selCancel },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }