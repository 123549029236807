var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "loginAccount",
                                    label: "用户名:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.param.loginAccount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.param, "loginAccount", $$v)
                                      },
                                      expression: "param.loginAccount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "userName",
                                    label: "真实姓名:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.param.userName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.param, "userName", $$v)
                                      },
                                      expression: "param.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression: "showCol||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "phoneNum", label: "手机号:" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.param.phoneNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.param, "phoneNum", $$v)
                                      },
                                      expression: "param.phoneNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "supplierStr",
                                    label: "供货商:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入供应商" },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getQdTable("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.param.supplierStr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.param, "supplierStr", $$v)
                                      },
                                      expression: "param.supplierStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "roleList",
                                        label: "所属角色:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            multiple: true,
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.param.roleList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.param,
                                                "roleList",
                                                $$v
                                              )
                                            },
                                            expression: "param.roleList",
                                          },
                                        },
                                        _vm._l(_vm.roleOption, function (item) {
                                          return _c("el-option", {
                                            key: item.roleCode,
                                            attrs: {
                                              label: item.roleName,
                                              value: item.roleCode,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "isEnable",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.isEnable,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "isEnable",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.isEnable",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            key: "0",
                                            attrs: {
                                              label: "禁用",
                                              value: "0",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            key: "1",
                                            attrs: {
                                              label: "启用",
                                              value: "1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset("listQuery")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset("listQuery")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.DoAdd },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAuth("allstart", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.setBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.setBtn.icon,
                              },
                              on: { click: _vm.AllStart },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.setBtn.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAuth("allstop", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.unSetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.unSetBtn.icon,
                              },
                              on: { click: _vm.AllStop },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.unSetBtn.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAuth("roleassign", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.updateBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.updateBtn.icon,
                              },
                              on: { click: _vm.rankRole },
                            },
                            [_vm._v("分配角色")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tableHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      align: _vm.tableConfig.align,
                      width: "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名",
                      prop: "loginAccount",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      "min-width": "90",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "姓名",
                      prop: "userName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      "min-width": "90",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属角色",
                      prop: "roleName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供货商编码",
                      prop: "supplierCode",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供货商名称",
                      prop: "supplierName",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号",
                      prop: "phoneNum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "isEnable",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.isEnable == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("启用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.isEnable == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("禁用"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1070255681
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "200",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoScan(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("updatebtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { staticClass: "table-dropdown" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n              更多\n              "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          row.isEnable == 1 &&
                                          _vm.hasAuth("stopbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoStop(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("禁用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          row.isEnable == 0 &&
                                          _vm.hasAuth("startbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoStart(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("启用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth("setbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setPwd(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("重置密码")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth("scanrole", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.scanRole(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看角色")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2667375841
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("scan", { attrs: { scan: _vm.scanShow, "scan-data": _vm.scanData } }),
      _vm._v(" "),
      _c("scanRole", {
        attrs: { role: _vm.roleShow, "role-data": _vm.roleData },
      }),
      _vm._v(" "),
      _c("assignRole", { attrs: { asrole: _vm.asroleShow } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "供货用户-供应商选择",
            visible: _vm.dlogVisible,
            "before-close": _vm.handleCloseWin,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dlogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.sloading,
                  expression: "sloading",
                },
              ],
              attrs: {
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                model: _vm.sparam,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "供应商名称:" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.sparam.supplierName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.sparam, "supplierName", $$v)
                                    },
                                    expression: "sparam.supplierName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: { click: _vm.searchSply },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.slist,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "供货商编码",
                  prop: "supplierCode",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "供货商名称",
                  prop: "supplierName",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoSelect(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total2 > 0,
                expression: "total2 > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total2,
              page: _vm.sparam.current,
              limit: _vm.sparam.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.sparam, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.sparam, "size", $event)
              },
              pagination: _vm.getQdTable,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "addForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.addData,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "loginAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "20" },
                    model: {
                      value: _vm.addData.loginAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "loginAccount", $$v)
                      },
                      expression: "addData.loginAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "20" },
                    model: {
                      value: _vm.addData.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "userName", $$v)
                      },
                      expression: "addData.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "供应商名称", prop: "supplierName" },
                },
                [
                  _c(
                    "el-input",
                    {
                      ref: "focusInput",
                      attrs: { placeholder: "请输入内容", readonly: "" },
                      model: {
                        value: _vm.addData.supplierName,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "supplierName", $$v)
                        },
                        expression: "addData.supplierName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getSingleCode()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.argument == "add"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "loginPwd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "16" },
                        model: {
                          value: _vm.addData.loginPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.addData, "loginPwd", $$v)
                          },
                          expression: "addData.loginPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.argument == "add"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "确认密码", prop: "reloginPwd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", maxlength: "16" },
                        model: {
                          value: _vm.addData.reloginPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.addData, "reloginPwd", $$v)
                          },
                          expression: "addData.reloginPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phoneNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "11" },
                    model: {
                      value: _vm.addData.phoneNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "phoneNum", $$v)
                      },
                      expression: "addData.phoneNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "40" },
                    model: {
                      value: _vm.addData.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "email", $$v)
                      },
                      expression: "addData.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "状态", prop: "isEnable" },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "启用", "inactive-text": "禁用" },
                    model: {
                      value: _vm.addData.isEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "isEnable", $$v)
                      },
                      expression: "addData.isEnable",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入内容",
                      maxlength: "100",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.addData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remark", $$v)
                      },
                      expression: "addData.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "align-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: _vm.dstate },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("addForm")
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("addForm")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }