var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "5px" } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleabolishClick },
          model: {
            value: _vm.activeRestoreName,
            callback: function ($$v) {
              _vm.activeRestoreName = $$v
            },
            expression: "activeRestoreName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "单数码恢复", name: "restore_on" } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "mark_form",
                  staticStyle: { width: "90%", margin: "0 auto" },
                  attrs: {
                    model: _vm.form_on,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "135px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请输入数码:", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form_on.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_on, "code", $$v)
                          },
                          expression: "form_on.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "恢复原因:", prop: "cause" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form_on.cause,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_on, "cause", $$v)
                          },
                          expression: "form_on.cause",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.onSubmitRestore(
                                _vm.form_on,
                                "codeForm"
                              )
                            },
                          },
                        },
                        [_vm._v("恢复")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.restore_show
            ? _c(
                "el-tab-pane",
                { attrs: { label: "生产批次恢复", name: "restore_ons" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "batchForm",
                      staticClass: "mark_form",
                      staticStyle: { width: "90%", margin: "0 auto" },
                      attrs: {
                        model: _vm.form_ons,
                        rules: _vm.ruless,
                        "label-position": "right",
                        "label-width": "135px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "请输入生产批次:", prop: "bacthNum" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form_ons.bacthNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_ons, "bacthNum", $$v)
                              },
                              expression: "form_ons.bacthNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "恢复原因:", prop: "cause" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.form_ons.cause,
                              callback: function ($$v) {
                                _vm.$set(_vm.form_ons, "cause", $$v)
                              },
                              expression: "form_ons.cause",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onSubmitRestore(
                                    _vm.form_ons,
                                    "batchForm"
                                  )
                                },
                              },
                            },
                            [_vm._v("恢复")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.restore_show
            ? _c(
                "el-tab-pane",
                {
                  attrs: { label: "出入库单数码恢复", name: "restore_access" },
                },
                [
                  _c("div", { staticClass: "el-row page-section" }, [
                    _c("div", { staticClass: "phead" }, [
                      _c("img", {
                        attrs: { src: require("@/images/icon-1.jpg") },
                      }),
                      _vm._v("\n          数据筛选\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { id: "seach-box" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "abolishForm",
                            staticClass: "mark_form",
                            staticStyle: { width: "90%", margin: "0 auto" },
                            attrs: {
                              model: _vm.warehouse_receipt,
                              "label-position": "right",
                              "label-width": "90px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "filter-container" },
                              [
                                _c(
                                  "el-row",
                                  { staticStyle: { "margin-bottom": "0px" } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticStyle: { "margin-bottom": "0px" },
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0px",
                                            },
                                            attrs: {
                                              label: "请输入单号:",
                                              prop: "orderNum",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                staticClass:
                                                  "input-with-select",
                                                attrs: {
                                                  placeholder:
                                                    "输入单号，查询记录",
                                                },
                                                model: {
                                                  value:
                                                    _vm.warehouse_receipt
                                                      .orderNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.warehouse_receipt,
                                                      "orderNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "warehouse_receipt.orderNum",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      slot: "append",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleFilter(
                                                          "warehouse_receipt_search"
                                                        )
                                                      },
                                                    },
                                                    slot: "append",
                                                  },
                                                  [_vm._v("查询")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-row page-section" }, [
                    _c("div", { staticClass: "phead" }, [
                      _c("img", {
                        attrs: { src: require("@/images/icon-2.jpg") },
                      }),
                      _vm._v("\n          数据列表\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { id: "table-box" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            key: _vm.tableKey,
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.list,
                              "tooltip-effect": "dark",
                              border: "",
                              fit: "",
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "120",
                                label: "序号",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据来源",
                                align: _vm.tableConfig.align,
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.billSource == 1
                                          ? _c(
                                              "span",
                                              { staticClass: "blue" },
                                              [_vm._v("SAP")]
                                            )
                                          : scope.row.billSource == 2
                                          ? _c(
                                              "span",
                                              { staticClass: "success" },
                                              [_vm._v("新平台")]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "danger" },
                                              [_vm._v("PDA")]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3471192972
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "交货单号",
                                align: _vm.tableConfig.align,
                                "min-width": "190",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.billNum)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3686136667
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "行号",
                                align: _vm.tableConfig.align,
                                "min-width": "90",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.rowNo)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4066146029
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库类型",
                                align: _vm.tableConfig.align,
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.storeType == 2
                                          ? _c(
                                              "span",
                                              { staticClass: "success" },
                                              [_vm._v("试点仓")]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "danger" },
                                              [_vm._v("非试点仓")]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                419469231
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "物料代码",
                                align: _vm.tableConfig.align,
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.matterCode)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3905737008
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "物料描述",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.matterDescribe)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1650765168
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "交货单类型",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.billType)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                992606805
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "发货库点代码",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.outWarehouse)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2512842925
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "发货库点名称",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.outWarehouseName)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3185251178
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货库点代码",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.inWarehouse)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3465820068
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货库点名称",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.inWarehouseName)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1899095683
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "客户喷码",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.bolnr)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                37108059
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售订单号",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.salesOrders)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                676963699
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "SCI单号",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.sci)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3006659295
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "计划数量（箱）",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.planCount)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                639382166
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: _vm.tableConfig.align,
                                "min-width": "60",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.calcUnit)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3868547181
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "实际数量",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.realCount)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2710457311
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "扫描率",
                                align: _vm.tableConfig.align,
                                "min-width": "170",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.scanRate)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3883723867
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                fixed: "right",
                                align: _vm.tableConfig.align,
                                width: "100",
                                "class-name": "small-padding fixed-width",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                row.newNumStatus == null
                                                  ? false
                                                  : true,
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "warehouse_receipt"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("恢复")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2601334500
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              "close-on-click-modal": false,
                              "append-to-body": true,
                              title: "出入库单恢复",
                              visible: _vm.DialogRestoreAccessVisible,
                              width: "30% !important",
                              center: "",
                            },
                            on: {
                              "update:visible": function ($event) {
                                _vm.DialogRestoreAccessVisible = $event
                              },
                            },
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "ListForm",
                                staticClass: "mark_form",
                                staticStyle: { width: "90%", margin: "0 auto" },
                                attrs: {
                                  model: _vm.form_warehouse_receipt,
                                  rules: _vm.rules_list,
                                  "label-position": "right",
                                  "label-width": "95px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "2px" },
                                    attrs: {
                                      label: "恢复原因:",
                                      prop: "cause",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: "5" },
                                      model: {
                                        value: _vm.form_warehouse_receipt.cause,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form_warehouse_receipt,
                                            "cause",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form_warehouse_receipt.cause",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "footer" },
                                slot: "footer",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.btn_loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSubmitRestore(
                                          _vm.form_warehouse_receipt,
                                          "ListForm"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("确 定")]
                                ),
                                _vm._v(" "),
                                _c("el-button", { on: { click: _vm.Rest } }, [
                                  _vm._v("取 消"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }