var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-section" },
    [
      _c("h3", [_vm._v("疑似窜货经销商排行")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formInline",
          attrs: {
            model: _vm.formInline,
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button-group",
                        { staticClass: "button-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type:
                                  _vm.formInline.timeType === "1"
                                    ? "primary"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.antiChanDealerRankFun("1")
                                },
                              },
                            },
                            [_vm._v("本月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type:
                                  _vm.formInline.timeType === "2"
                                    ? "primary"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.antiChanDealerRankFun("2")
                                },
                              },
                            },
                            [_vm._v("本年")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.dateFun },
                        model: {
                          value: _vm.dateValue,
                          callback: function ($$v) {
                            _vm.dateValue = $$v
                          },
                          expression: "dateValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bar-chart", {
        ref: "barChart",
        attrs: { "tip-name": "疑似窜货经销商" },
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "全部经销商",
                  readonly: true,
                  clearable: "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.inputFocusFun(1)
                  },
                },
                model: {
                  value: _vm.formInline.dealerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "dealerName", $$v)
                  },
                  expression: "formInline.dealerName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-size",
                  attrs: {
                    type: _vm.btnConfig.resetBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.resetBtn.icon,
                    loading: _vm.sweepBtn,
                  },
                  on: { click: _vm.clearFun },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.btnConfig.resetBtn.text) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-size",
                  staticStyle: { float: "right" },
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.downFun },
                },
                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            id: "outoTable",
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            align: "center",
            "header-cell-style": {
              "background-color": "#fafafa",
              color: "rgba(0, 0, 0,0.85)",
              "font-weight": "500",
              "text-align": "center",
            },
            "cell-style": { "text-align": "center" },
            "row-style": { color: "rgba(0, 0, 0,0.65)", "font-weight": "400" },
          },
        },
        [
          _c("el-table-column", { attrs: { label: "排名", prop: "no" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "疑似窜货流入地区", prop: "dealerName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "疑似窜货数量", prop: "count" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          "auto-scroll": false,
          page: _vm.formInline.page,
          limit: _vm.formInline.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.formInline, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.formInline, "limit", $event)
          },
          pagination: _vm.fcPagingFun,
        },
      }),
      _vm._v(" "),
      _c("channel-select-dialog", {
        ref: "channelSelect",
        attrs: { "channel-type": "", "channel-type-can-change": false },
        on: { change: _vm.setCurrent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }