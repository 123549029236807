"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'scan',
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      },
      roleName: ''
    };
  },
  props: {
    scanAuthData: Array,
    scanAuth: Boolean,
    name: String
  },
  watch: {
    scanAuth: function scanAuth(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.roleName = that.$parent.scanRoleName || that.name;
      } else {
        that.dialogVisible = false;
        that.roleName = '';
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.scanAuthShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;