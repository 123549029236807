var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择商品",
        modal: false,
        "close-on-click-modal": false,
        visible: _vm.visible,
        "before-close": _vm.cancle,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataListLoading,
              expression: "dataListLoading",
            },
          ],
          ref: "prodTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" },
          on: { "selection-change": _vm.selectChangeHandle },
        },
        [
          _vm.isSingle
            ? _c("el-table-column", {
                attrs: {
                  width: "50",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: scope.row.prodId },
                                  nativeOn: {
                                    change: function ($event) {
                                      return _vm.getSelectProdRow(scope.row)
                                    },
                                  },
                                  model: {
                                    value: _vm.singleSelectProdId,
                                    callback: function ($$v) {
                                      _vm.singleSelectProdId = $$v
                                    },
                                    expression: "singleSelectProdId",
                                  },
                                },
                                [_vm._v(" ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  870715150
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSingle
            ? _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "header-align": "center",
                  align: "center",
                  width: "50",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "prodName",
              "header-align": "center",
              align: "center",
              label: "产品名称",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", width: "140", label: "产品图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: {
                        src: scope.row.pic,
                        width: "100",
                        height: "100",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pageIndex,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.pageSize,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle,
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.cancle } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitProds()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }