var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("组织机构")]),
      _vm._v(" "),
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            {
              staticStyle: { display: "flex", height: "calc(100% - 5px)" },
              attrs: { gutter: 20 },
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "set-h",
                  staticStyle: {
                    width: "calc(100% - 675px)",
                    "min-width": "850px",
                    flex: "1",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "listQuery",
                      staticClass: "page-section pad-sec",
                      attrs: {
                        model: _vm.listQuery,
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "form-control",
                              staticStyle: { "margin-top": "10px" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orgCodeAndName",
                                        label: "组织编码/名称",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          maxlength: "40",
                                          placeholder: "请输入组织编码/名称",
                                        },
                                        model: {
                                          value: _vm.listQuery.orgCodeAndName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "orgCodeAndName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "listQuery.orgCodeAndName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "0" },
                                      attrs: {
                                        prop: "orgType",
                                        label: "机构类型",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择机构类型",
                                          },
                                          model: {
                                            value: _vm.listQuery.orgType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orgType",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orgType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.leixinglist,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: item.figure,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "duty",
                                        label: "机构职责",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择职责",
                                          },
                                          model: {
                                            value: _vm.listQuery.duty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "duty",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.duty",
                                          },
                                        },
                                        _vm._l(_vm.zhizelist, function (item) {
                                          return _c("el-option", {
                                            key: item.figure,
                                            attrs: {
                                              label: item.name,
                                              value: item.figure,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orgStatus",
                                        label: "状态",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择状态",
                                          },
                                          model: {
                                            value: _vm.listQuery.orgStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orgStatus",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orgStatus",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "启用",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "停用",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", {
                                staticClass: "pad-top-label head-btn-group",
                                staticStyle: { width: "210px" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btnArea",
                                  staticStyle: { padding: "24px 20px" },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                            plain:
                                              _vm.btnConfig.searchBtn.plain,
                                          },
                                          on: { click: _vm.getOrgList },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                            plain: _vm.btnConfig.resetBtnplain,
                                          },
                                          on: { click: _vm.reset },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c("el-divider", { staticClass: "btnDivider" })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btnArea" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: { width: _vm.formConfig.btnArea },
                            },
                            [
                              _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.addBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.addBtn.icon,
                                        plain: _vm.btnConfig.plain,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.append({}, "N")
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          staticStyle: {
                            height: "calc(100% - 260px)",
                            "padding-bottom": "10px",
                            "border-radio": "50px 0 0 50px",
                          },
                        },
                        [
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  id: "orgTable",
                                  data: _vm.treeData,
                                  height: "100%",
                                  "row-key": "pId",
                                  border: _vm.tableConfig.border,
                                  stripe: _vm.tableConfig.stripe,
                                  "highlight-current-row": true,
                                  "tree-props": {
                                    children: "children",
                                    hasChildren: "hasChildren",
                                  },
                                },
                                on: {
                                  "current-change": _vm.handleCurrentChange,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "组织机构编码",
                                    "show-overflow-tooltip": "",
                                    prop: "orgCode",
                                    align: _vm.tableConfig.align,
                                    "min-width": "200",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "组织机构名称",
                                    "show-overflow-tooltip": "",
                                    prop: "orgName",
                                    align: _vm.tableConfig.align,
                                    "min-width": "200",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "机构职责",
                                    "show-overflow-tooltip": "",
                                    prop: "dutyDesc",
                                    align: _vm.tableConfig.align,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "机构类型",
                                    "show-overflow-tooltip": "",
                                    prop: "orgType",
                                    align: _vm.tableConfig.align,
                                    "min-width": "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            row.orgType == 1
                                              ? _c("span", [_vm._v("公司")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.orgType == 2
                                              ? _c("span", [_vm._v("部门")])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    624054241
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "状态",
                                    align: _vm.tableConfig.align,
                                    "min-width": "60",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            row.orgStatus == 1
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "success" },
                                                  },
                                                  [_vm._v("启用")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.orgStatus == 0
                                              ? _c(
                                                  "el-tag",
                                                  { attrs: { type: "danger" } },
                                                  [_vm._v("停用")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2500046172
                                  ),
                                }),
                                _vm._v(" "),
                                _vm.hasAuth(
                                  "addbtn",
                                  _vm.$route.meta.authList
                                ) ||
                                _vm.hasAuth(
                                  "updatebtn",
                                  _vm.$route.meta.authList
                                ) ||
                                _vm.hasAuth(
                                  "startbtn",
                                  _vm.$route.meta.authList
                                ) ||
                                _vm.hasAuth("stopbtn", _vm.$route.meta.authList)
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "操作",
                                        width: "240",
                                        align: _vm.tableConfig.align,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _vm.hasAuth(
                                                  "addbtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.append(
                                                              row,
                                                              "Y"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "updatebtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.edit(row)
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("编辑")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "startbtn",
                                                  _vm.$route.meta.authList
                                                ) && row.orgStatus == 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setInUse(
                                                              row.pId
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("启用")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "stopbtn",
                                                  _vm.$route.meta.authList
                                                ) && row.orgStatus == 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setUnUse(
                                                              row.pId
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("停用")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "deletebtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteId(
                                                              row.pId
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2500493702
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { staticStyle: { width: "500px" } }, [
                _c("div", { staticClass: "page-section set-h" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "tab-pad",
                    },
                    [
                      [
                        _c(
                          "el-row",
                          { staticStyle: { border: "1px solid #e8e8e8" } },
                          [
                            _c("div", { staticClass: "detail_title" }, [
                              _vm._v("基本信息"),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("组织编码"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.nodeContent
                                        ? _vm.nodeContent.orgCode
                                        : ""
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("组织名称"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(_vm._s(_vm.nodeContent.orgName)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("上级编码"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(_vm._s(_vm.nodeContent.parentCode)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("上级名称"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(_vm._s(_vm.nodeContent.parentName)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("负责人"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(_vm._s(_vm.nodeContent.contacts)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("联系方式"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(_vm._s(_vm.nodeContent.phone)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("状态"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orgStatus")(
                                        _vm.nodeContent.orgStatus
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { lg: 12 } }, [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("组织类型"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orgType")(_vm.nodeContent.orgType)
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", [
                              _c("div", { staticClass: "vue-from-block" }, [
                                _c("div", { staticClass: "form-div" }, [
                                  _vm._v("地址"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _vm._v(
                                    _vm._s(_vm.nodeContent.provinceName) +
                                      _vm._s(_vm.nodeContent.cityName) +
                                      _vm._s(_vm.nodeContent.countyName) +
                                      _vm._s(_vm.nodeContent.detailedAddress)
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { staticStyle: { border: "1px solid #e8e8e8" } },
                          [
                            _c("div", { staticClass: "detail_title" }, [
                              _vm._v("创建信息"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { lg: 12 } },
                              [
                                _c("el-col", [
                                  _c("div", { staticClass: "vue-from-block" }, [
                                    _c("div", { staticClass: "form-div" }, [
                                      _vm._v("创建人"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(_vm.nodeContent.creatorName)
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", [
                                  _c("div", { staticClass: "vue-from-block" }, [
                                    _c("div", { staticClass: "form-div" }, [
                                      _vm._v("创建时间"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("textDateOnlyDate")(
                                            _vm.nodeContent.createTime
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { lg: 12 } },
                              [
                                _c("el-col", [
                                  _c("div", { staticClass: "vue-from-block" }, [
                                    _c("div", { staticClass: "form-div" }, [
                                      _vm._v("修改人"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(_vm.nodeContent.updaterName)
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", [
                                  _c("div", { staticClass: "vue-from-block" }, [
                                    _c("div", { staticClass: "form-div" }, [
                                      _vm._v("修改时间"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("textDateOnlyDate")(
                                            _vm.nodeContent.updateTime
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
                "label-suffix": ":",
                "close-on-click-modal": false,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "组织编码", prop: "orgCode" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.orgCode = _vm.validCode(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.orgCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "orgCode", $$v)
                      },
                      expression: "ruleForm.orgCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组织机构名称", prop: "orgName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.ruleForm.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "orgName", $$v)
                      },
                      expression: "ruleForm.orgName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.orgType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上级组织", prop: "parentList" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: "",
                          "show-all-levels": false,
                          options: _vm.orgSelList,
                          clearable: "",
                          props: _vm.props,
                        },
                        model: {
                          value: _vm.parentList,
                          callback: function ($$v) {
                            _vm.parentList = $$v
                          },
                          expression: "parentList",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "orgType", label: "机构类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择机构类型",
                        disabled: "",
                      },
                      model: {
                        value: _vm.ruleForm.orgType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "orgType", $$v)
                        },
                        expression: "ruleForm.orgType",
                      },
                    },
                    _vm._l(_vm.leixinglist, function (item) {
                      return _c("el-option", {
                        key: item.pid,
                        attrs: { label: item.name, value: item.figure },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "duty", label: "机构职责" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择机构职责",
                        multiple: "",
                      },
                      on: { change: _vm.changecheck },
                      model: {
                        value: _vm.ruleForm.duty,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "duty", $$v)
                        },
                        expression: "ruleForm.duty",
                      },
                    },
                    _vm._l(_vm.zhizelist, function (item) {
                      return _c("el-option", {
                        key: item.pid,
                        attrs: { label: item.name, value: item.figure },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { prop: "areaList" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.areaList,
                              props: {
                                value: "pId",
                                label: "areaName",
                                children: "children",
                              },
                            },
                            model: {
                              value: _vm.areaSelectList,
                              callback: function ($$v) {
                                _vm.areaSelectList = $$v
                              },
                              expression: "areaSelectList",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { prop: "detailedAddress" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入详细地址  例如：XXX街道XX号",
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.ruleForm.detailedAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "detailedAddress", $$v)
                              },
                              expression: "ruleForm.detailedAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "负责人", prop: "contacts" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.ruleForm.contacts,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "contacts", $$v)
                      },
                      expression: "ruleForm.contacts",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.phone, maxlength: "11" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.phone = _vm.valENnum(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "orgStatus" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "停用",
                    },
                    model: {
                      value: _vm.ruleForm.orgStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "orgStatus", $$v)
                      },
                      expression: "ruleForm.orgStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }