"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _auth = require("@/utils/auth");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg1"));
var _wxbasicConfig = require("@/api/wxhyyy/wxbasicConfig");
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 关闭页面时删除dialogStatus
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('dialogStatus');
    next();
  },
  components: {
    UploadImg: _UploadImg.default
  },
  data: function data() {
    var _this = this;
    var chekeName = function chekeName(rule, value, callback) {
      if (value && _this.oldNmae !== value) {
        (0, _wxbasicConfig.isAvailable)({
          param: value,
          sign: 1
        }).then(function (res) {
          var res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('公众号名称已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    var chekeId = function chekeId(rule, value, callback) {
      if (value && _this.oldId !== value) {
        (0, _wxbasicConfig.isAvailable)({
          param: value,
          sign: 2
        }).then(function (res) {
          var res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('公众号原始ID已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    var chekeAppid = function chekeAppid(rule, value, callback) {
      if (value && _this.oldApp !== value) {
        (0, _wxbasicConfig.isAvailable)({
          param: value,
          sign: 2
        }).then(function (res) {
          var res = res.data;
          if (res.data.isAvailable === 'success') {
            callback();
          } else {
            return callback(new Error('APPid(公众号)已存在，请重新输入！'));
          }
        });
      } else {
        callback();
      }
    };
    return {
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      // 上传token
      disabled: false,
      // 是否禁用
      dialogStatus: '',
      // 新增或者编辑
      oldNmae: null,
      // 微信名称
      oldId: null,
      // 微信Id
      oldApp: null,
      // 微信公众号appid
      fileList: [],
      // 文件列表 上传证书用
      temp: {
        // 数据表单
        id: undefined,
        officialName: '',
        officialId: '',
        wechatName: '',
        appid: '',
        appsecret: '',
        imageUrl: '',
        payWay: 1,
        isDefault: 2,
        mchId: '',
        paykey: '',
        certPath: '',
        certificate: '',
        appToken: '',
        appEncodingAeskey: ''
      },
      bgimgurl: '',
      twImgurl: '',
      fstpUrl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single2',
      rules: {
        // 表单验证
        officialName: [{
          required: true,
          message: '公众号名不能为空',
          trigger: 'change'
        }, {
          validator: chekeName,
          trigger: 'blur'
        }],
        officialId: [{
          required: true,
          message: '原始ID不能为空',
          trigger: 'change'
        }, {
          validator: chekeId,
          trigger: 'blur'
        }],
        appid: [{
          required: true,
          message: 'APPid不能为空',
          trigger: 'change'
        }, {
          validator: chekeAppid,
          trigger: 'blur'
        }],
        imageUrl: [{
          required: true,
          message: '请上传公众号二维码',
          trigger: 'change'
        }]
        // appToken: [
        //   { required: true, message: '令牌不能为空', trigger: 'blur' }
        // ],
        // appEncodingAeskey: [
        //   {
        //     required: true,
        //     message: '消息加解密密钥不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      }
    };
  },

  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/uspyx/wechat/official/upload';
    }
  },
  created: function created() {
    this.cid = localStorage.getItem('cid');
    this.twImgurl = _wxHy.basesc;
    if (this.$route.params.state) {
      // 进入页面 存储页面状态 编辑或者新增
      this.dialogStatus = this.$route.params.state;
      this.temp.id = this.$route.params.id;
      sessionStorage.setItem('dialogStatus', JSON.stringify({
        id: this.$route.params.id,
        dialogStatus: this.$route.params.state
      }));
    } else if (sessionStorage.getItem('dialogStatus')) {
      // 刷新页面使用
      this.dialogStatus = JSON.parse(sessionStorage.getItem('dialogStatus')).dialogStatus;
      this.temp.id = JSON.parse(sessionStorage.getItem('dialogStatus')).id;
    } else {
      // 无进入公众号配置首页
      this.$router.replace({
        name: 'wxAccountswxgzhpublicConfigwxpzindex'
      });
    }
    // 有公众号id 获取公众号配置详情
    if (this.temp.id) this.getDetail(this.temp.id);
    console.log(this.temp);
  },
  methods: {
    gzhback: function gzhback() {
      this.$router.go(-1);
    },
    handleAvatarSuccesse: function handleAvatarSuccesse(response, file, fileList) {
      console.log('response1', response);
      if (response.code * 1 === 200) {
        this.bgimgurl = URL.createObjectURL(file.raw);
        this.temp.imageUrl = this.twImgurl + response.data.url;
      } else {
        this.$message({
          message: '请重新选取封面',
          type: 'error',
          duration: 3 * 1000
        });
      }
      // this.bgimgurl = URL.createObjectURL(file.raw)
    },
    // 上传证书
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      if (res.status === 200) {
        this.temp.certPath = res.data;
        this.fileList = [file];
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
      } else {
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    // 获取详情
    getDetail: function getDetail(val) {
      var _this2 = this;
      (0, _wxbasicConfig.detail)({
        id: val
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.state * 1 === 200) {
          res.data.wechatConfig.payWay = res.data.wechatConfig.payMethod * 1; // 支付方法
          res.data.wechatConfig.isDefault = res.data.wechatConfig.isDefault * 1; // 默认公众号
          // 对象合并
          Object.assign(_this2.temp, res.data.wechatConfig, res.data.payInfo);
          console.log(_this2.temp);
          _this2.$refs.imageUrl.fileList.push({
            url: _this2.temp.imageUrl
          });
          _this2.$refs.imageUrl.hideUpload = true;
          if (_this2.temp.certPath) {
            _this2.fileList = [{
              name: _this2.temp.certPath
            }];
          }
          // 公众号名
          _this2.oldNmae = _this2.temp.officialName;
          // 公众号ID
          _this2.oldId = _this2.temp.officialId;
          // appid
          _this2.oldApp = _this2.temp.appid;
        }
      });
    },
    // 新增
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.temp.isOpen = _this3.temp.payWay === 0 ? 0 : 1;
          _this3.temp.payMethod = _this3.temp.payWay;
          (0, _wxbasicConfig.addWechat)(_this3.temp).then(function (res) {
            var res = res.data;
            if (res.state * 1 === 200) {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'success',
                duration: 3 * 1000
              });
              _this3.$router.go(-1);
            } else {
              _this3.$message.closeAll();
              _this3.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      console.log(row);
      this.temp = Object.assign({}, row); // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    // 编辑
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this5.temp.isOpen = _this5.temp.payWay === 0 ? 0 : 1;
          _this5.temp.payMethod = _this5.temp.payWay;
          (0, _wxbasicConfig.edit)(_this5.temp).then(function (res) {
            var res = res.data;
            if (res.state * 1 === 200) {
              _this5.$message.closeAll();
              _this5.$message({
                message: res.msg,
                type: 'success',
                duration: 3 * 1000
              });
              _this5.$router.go(-1);
            } else {
              _this5.$message.closeAll();
              _this5.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;