"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityJoinRecord = activityJoinRecord;
exports.auditReport = auditReport;
exports.doReissuePrize = doReissuePrize;
exports.exportActivityJoin = exportActivityJoin;
exports.exportPrizeReport = exportPrizeReport;
exports.exportPrizeSend = exportPrizeSend;
exports.exportReportList = exportReportList;
exports.exportSendFail = exportSendFail;
exports.prizeReport = prizeReport;
exports.prizeSendRecord = prizeSendRecord;
exports.queryReportTimeConfig = queryReportTimeConfig;
exports.reissueRecord = reissueRecord;
exports.reportList = reportList;
exports.saveReportTimeConfig = saveReportTimeConfig;
exports.scanCountForScheme = scanCountForScheme;
exports.sendFailRecord = sendFailRecord;
exports.submitReport = submitReport;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
// 扫码统计报表
function scanCountForScheme(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/scanCountForScheme',
    method: 'post',
    data: data
  });
}
// 季度/月度报表
function reportList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/reportList',
    method: 'post',
    data: data
  });
}

// 季度/月度报表导出
function exportReportList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/exportReportList',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
// 季度/月度报表 提交 审核
function submitReport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/submitReport',
    method: 'post',
    data: data
  });
}
function auditReport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/auditReport',
    method: 'post',
    data: data
  });
}
// 活动奖品统计
function prizeReport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/prizeReport',
    method: 'post',
    data: data
  });
}

// 活动奖品统计导出
function exportPrizeReport(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/exportPrizeReport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 奖品发放
function prizeSendRecord(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/prizeSendRecord',
    method: 'post',
    data: data
  });
}

// 奖品发放导出
function exportPrizeSend(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/exportPrizeSend',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 活动参与记录
function activityJoinRecord(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/activityJoinRecord',
    method: 'post',
    data: data
  });
}
// 活动参与记录导出
function exportActivityJoin(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/exportActivityJoin',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 奖品发放失败
function sendFailRecord(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/sendFailRecord',
    method: 'post',
    data: data
  });
}
// 奖品发放失败导出
function exportSendFail(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/exportSendFail',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
// 奖品发放失败补发
function doReissuePrize(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/doReissuePrize',
    method: 'post',
    data: data
  });
}
// 补发列表
function reissueRecord(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/reissueRecord',
    method: 'post',
    data: data
  });
}
//
function queryReportTimeConfig(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/queryReportTimeConfig',
    method: 'get',
    params: params
  });
}
function saveReportTimeConfig(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/marketingReport/saveReportTimeConfig',
    method: 'post',
    data: data
  });
}