"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _generateOrder = require("@/api/operationalTools2/generateOrder2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _distributorSelect = _interopRequireDefault(require("@/components/distributorSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsgenerateOrderindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsgenerateOrderindex',
  components: {
    Pagination: _Pagination.default,
    productSelectDialog: _productSelectDialog.default,
    distributorSelect: _distributorSelect.default
  },
  data: function data() {
    return {
      productList: [],
      agentList: [],
      params: {
        current: 1,
        size: 10
      },
      total: 0,
      formInline: {
        channelId: '',
        checkStatus: '1',
        startTime: '',
        endTime: '',
        orderNo: '',
        billNo: '',
        productId: ''
      },
      productName: '',
      channelName: '',
      isShowDistributor: false,
      rangeTime: '',
      listLoading: false,
      tableData: [],
      tabHeight: '100%',
      dialogVisible: false,
      form: {
        name1: 'user',
        name2: 'yangsir'
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    handlePush: function handlePush() {
      this.dialogVisible = true;
    },
    // 表格
    getPage: function getPage() {
      var that = this;
      that.listLoading = true;
      (0, _generateOrder.getPage)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function (err) {
        that.listLoading = false;
      });
    },
    // 时间选择
    sj: function sj(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 选择产品
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    selectPro: function selectPro(val) {
      this.formInline.productId = val.pId;
      this.productName = val.productName;
    },
    // 获取经销商名称
    getDistributorName: function getDistributorName(data) {
      console.log(data);
      if (data) {
        this.channelName = data.channelName;
        this.formInline.channelId = data.pid;
      }
      this.isShowDistributor = false;
    },
    // 经销商选取弹框
    handleSelectDistributor: function handleSelectDistributor() {
      this.isShowDistributor = true;
    },
    // 选取经销商
    seletdistributor: function seletdistributor(row) {
      console.log(row);
      this.channelName = row.channelName;
      this.formInline.channelId = row.pid;
      this.isShowDistributor = false;
    },
    // 搜索
    search: function search() {
      this.params.current = 1;
      this.getPage();
    },
    // 重置搜索框
    reset: function reset() {
      this.params.current = 1;
      this.$refs['formInline'].resetFields();
      this.rangeTime = '';
      this.productName = '';
      this.channelName = '';
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getPage();
    },
    // 导出
    exportDownload: function exportDownload() {
      var _this = this;
      (0, _generateOrder.getExportExcel)(this.formInline).then(function (res) {
        setTimeout(function () {
          _this.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '生产订单核查.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 检测
    detection: function detection() {
      var _this2 = this;
      (0, _generateOrder.postCheck)().then(function (res) {
        if (res.data.data.errCode) {
          return _this2.$message.error(res.data.data.errMsg);
        } else {
          _this2.$message.success('检测成功');
          _this2.getPage();
        }
      });
    }
  }
};
exports.default = _default;