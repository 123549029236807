"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addIntegral = addIntegral;
exports.deleteCombo = deleteCombo;
exports.endMaterial = endMaterial;
exports.exportPrize = exportPrize;
exports.getPrizeList = getPrizeList;
exports.prizeRelevance = prizeRelevance;
exports.startMaterial = startMaterial;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = process.env.VUE_APP_VERSION2;

// 积分列表
function getPrizeList(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/getPrizeList',
    method: 'post',
    data: data
  });
}

// 新增实物
function addIntegral(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/savePrize_Integral',
    method: 'post',
    data: data
  });
}

// 启用实物
function startMaterial(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/startPrize',
    method: 'post',
    params: params
  });
}

// 导出礼包明细
function exportPrize(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/getPrizeList_exp',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 停用实物
function endMaterial(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/endPrize',
    method: 'post',
    params: params
  });
}

// 删除礼包
function deleteCombo(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/delPrize',
    method: 'post',
    params: params
  });
}

// 获取奖品是否关联活动
function prizeRelevance(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/prize/prizeRelevance',
    method: 'post',
    params: params
  });
}