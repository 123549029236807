"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TinyMce: _tinyMce.default
  },
  data: function data() {
    return {
      dataForm: {
        rexplainkfxx: ''
      }
    };
  },
  created: function created() {
    this.getRules();
  },
  methods: {
    // 获取分类数据
    getRules: function getRules() {
      var _this = this;
      (0, _wXintegralMall.getIntegralRule)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.status * 1 === 200) {
          if (res.data) {
            _this.dataForm = res.data;
          }
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var param = Object.assign({}, _this2.dataForm);
          if (param.id) {
            (0, _wXintegralMall.updateRuleById)(param).then(function (res) {
              var res = res.data;
              if (res.status * 1 === 200) {
                _this2.$message.closeAll();
                _this2.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this2.$router.push({
                  name: 'wxAccountswxIntegralMallintegralRulesindex',
                  query: {}
                });
              } else {
                _this2.$message.closeAll();
                _this2.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            });
          } else {
            (0, _wXintegralMall.insertRule)(param).then(function (res) {
              var res = res.data;
              if (res.status * 1 === 200) {
                _this2.$message.closeAll();
                _this2.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
                _this2.$router.push({
                  name: 'wxAccountswxIntegralMallintegralRulesindex',
                  query: {}
                });
              } else {
                _this2.$message.closeAll();
                _this2.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
            });
          }
        }
      });
    }
  }
};
exports.default = _default;