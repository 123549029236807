"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detailExportExcel = detailExportExcel;
exports.exportExcel = exportExcel;
exports.getDetail = getDetail;
exports.getExcel = getExcel;
exports.getonOrOff = getonOrOff;
exports.postCreateScheme = postCreateScheme;
exports.postPage = postPage;
exports.postUpdate = postUpdate;
exports.postrebateDetail = postrebateDetail;
exports.postrebatePage = postrebatePage;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
//  获取活动列表
function postPage(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/page',
    method: 'post',
    data: data
  });
}

// 导出活动
function exportExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

//  获取返利
function postrebatePage(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateDetail/page',
    method: 'post',
    data: data
  });
}

//  创建返利方案
function postCreateScheme(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/createScheme',
    method: 'post',
    data: data
  });
}

//  创建返利方案
function postUpdate(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/update',
    method: 'post',
    data: data
  });
}
//  返利方案详情
function getDetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/detail',
    method: 'get',
    params: params
  });
}
// 返利方案启用/停用
function getonOrOff(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/onOrOff',
    method: 'get',
    params: params
  });
}
// 返利明细查询
function postrebateDetail(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateDetail/page',
    method: 'post',
    data: data
  });
}
// 返利明细导出
function getExcel(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateDetail/getExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
// 查看导出
function detailExportExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/rebateScheme/detailExportExcel',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}