var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "detailWrap" },
          [
            _c("div", { staticClass: "billTitle" }, [
              _c("img", { attrs: { src: require("@/images/topimg.png") } }),
              _vm._v(" "),
              _c("span", { staticClass: "billTitleText" }, [
                _vm._v("销售订单 " + _vm._s(_vm.addData.orderNo)),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "billType",
                  class: _vm._f("typeClass")(_vm.addData.tradeStatus),
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("orderStatusText")(_vm.addData.tradeStatus))
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "topRight" }, [
                _c("img", { attrs: { src: require("@/images/avatar.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.addData.creatorUser))]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "10px" } }, [
                  _vm._v(_vm._s(_vm._f("textDate")(_vm.addData.createTime))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "topWrap", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [
                      _vm._v("订单日期:"),
                    ]),
                    _vm._v(" "),
                    !_vm.placeOrderDateEdit
                      ? _c("div", { staticClass: "topContent" }, [
                          _vm._v(
                            _vm._s(
                              _vm.addData.tradeDate
                                ? _vm.addData.tradeDate.split(" ")[0]
                                : " "
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [
                      _vm._v("订单类型:"),
                    ]),
                    _vm._v(" "),
                    !_vm.typeEdit
                      ? _c("div", { staticClass: "topContent" }, [
                          _vm._v(
                            _vm._s(_vm._f("orderType")(_vm.addData.orderType))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "topItem" }, [
                    _c("div", { staticClass: "topLabel" }, [_vm._v("备注:")]),
                    _vm._v(" "),
                    !_vm.remarkEdit
                      ? _c(
                          "div",
                          { staticClass: "topContent" },
                          [
                            _vm.addData.remark
                              ? _c(
                                  "el-popover",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placement: "bottom-start",
                                      width: "500",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "popoverremark" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.addData.remark
                                              ? _vm.addData.remark
                                              : " "
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "remark",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.addData.remark
                                              ? _vm.addData.remark
                                              : " "
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c("div", { staticClass: "remark" }, [
                                  _vm._v(_vm._s(" ")),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "box-card", attrs: { shadow: "hover" } },
          [
            _c(
              "div",
              {
                staticClass: "clearfix detailCardTitle",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v("订单相关方信息")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "detailRow", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("销售单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.saleOrgName) +
                          "\n              "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("发货单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.sendOrgName ? _vm.sendOrgName : " ")),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("采购单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.purchaseOrgName) +
                          "\n              "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货单位:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.receiveOrgName)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "detailRow", attrs: { gutter: 42 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货联系人:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receiveUser)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货联系电话:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receivePhone)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("收货地址:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.addData.receiveAddress)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "margin-top": "12px" },
            attrs: { shadow: "hover", "body-style": _vm.cardPadding },
          },
          [
            _c(
              "div",
              {
                staticClass: "clearfix detailCardTitle titleBtn",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", { attrs: { src: require("@/images/cpxx.png") } }),
                _vm._v(" "),
                _c("span", [_vm._v("产品信息")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "addTable",
                staticStyle: { width: "100%", margin: "-1px 0 0" },
                attrs: {
                  "max-height": "600",
                  data: _vm.productList,
                  fit: "",
                  "row-class-name": "iconNone",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    align: "center",
                    width: "80",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    align: "center",
                    prop: "productCode",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    align: "center",
                    prop: "productName",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    align: "center",
                    prop: "productModel",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装比例",
                    align: "center",
                    prop: "packScaleExpression",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "箱数",
                    prop: "chestNum",
                    align: "center",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "盒数",
                    prop: "bottleNum",
                    align: "center",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "总盒数",
                    prop: "totalBottleNum",
                    align: "center",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _vm.addData.orderType == 1 && _vm.addData.saleOrgType != 1
                  ? _c("el-table-column", {
                      attrs: {
                        label: "买赠方案",
                        align: "center",
                        prop: "rebatePolicyId",
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.giftProductAOS &&
                                scope.row.giftProductAOS.length > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toogleExpand(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.activityName
                                              ? scope.row.activityName
                                              : "查看赠品"
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3009754713
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { type: "expand", width: "1" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                size: "mini",
                                data: props.row.giftProductAOS,
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "赠品行",
                                  type: "index",
                                  align: "center",
                                  width: "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "zpindex" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "赠品" +
                                                      (scope.$index * 1 + 1)
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _c("img", {
                                                staticClass: "zpimg",
                                                attrs: {
                                                  src: require("@/images/zpimg.png"),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "140",
                                  label: "赠品编码",
                                  prop: "productCode",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "140",
                                  label: "赠品名称",
                                  prop: "productName",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格",
                                  align: "center",
                                  prop: "productModel",
                                  "min-width": "140",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装比例",
                                  align: "center",
                                  prop: "packScaleExpression",
                                  "min-width": "140",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "箱数",
                                  prop: "chestNum",
                                  align: "center",
                                  "min-width": "140",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盒数",
                                  prop: "bottleNum",
                                  align: "center",
                                  "min-width": "140",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "总盒数",
                                  prop: "totalBottleNum",
                                  align: "center",
                                  "min-width": "140",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    align: "center",
                                    prop: "rebatePolicyId",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _vm._v(" "),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.addData.orderType == 2
          ? _c(
              "el-card",
              {
                staticClass: "box-card",
                staticStyle: { "margin-top": "12px" },
                attrs: { shadow: "hover", "body-style": _vm.cardPadding },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "clearfix detailCardTitle",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("img", { attrs: { src: require("@/images/jjdh.png") } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("酒券兑换信息")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "countWrap" }, [
                      _c("div", [
                        _vm._v(
                          "本次使用酒券金额(元)：" + _vm._s(_vm.totalInUse)
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", margin: "-1px 0 0" },
                    attrs: { "max-height": "400", data: _vm.zpList, fit: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        type: "index",
                        align: "center",
                        width: "65",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品编码",
                        align: "center",
                        prop: "productCode",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品名称",
                        align: "center",
                        prop: "productName",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "规格",
                        align: "center",
                        prop: "productModel",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "包装比例",
                        align: "center",
                        prop: "packScaleExpression",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "箱数",
                        prop: "chestNum",
                        align: "center",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "盒数",
                        prop: "bottleNum",
                        align: "center",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "总盒数",
                        prop: "totalBottleNum",
                        align: "center",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "酒券金额",
                        align: "center",
                        prop: "wineTotalPrice",
                        "min-width": "140",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(_vm.totalWine(row)))]
                            },
                          },
                        ],
                        null,
                        false,
                        1315556519
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.addData.tradeStatus > 1
          ? _c(
              "el-card",
              {
                staticClass: "box-card",
                staticStyle: { "margin-top": "12px" },
                attrs: { shadow: "hover", "body-style": _vm.cardPadding },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "clearfix detailCardTitle",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("审核日志")])]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      loaidng: _vm.auditLoading,
                      data: _vm.auditList,
                      "max-height": "300",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateUser",
                        label: "审核人",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(row.operateUser))]
                            },
                          },
                        ],
                        null,
                        false,
                        4146631382
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateType",
                        label: "审核结果",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("operateType")(row.operateType))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        723791686
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "auditOpinion",
                        label: "审核时间",
                        width: "180",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(_vm._f("textDate")(row.createTime))
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2280372158
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateRemark",
                        label: "审核意见",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { "margin-top": "12px" },
            attrs: { shadow: "hover", "body-style": _vm.btncardPadding },
          },
          [
            _c(
              "div",
              { staticClass: "btnWrap" },
              [
                _c(
                  "el-form",
                  {
                    ref: "auditForm",
                    attrs: {
                      model: _vm.auditForm,
                      "label-width": "75px",
                      "label-suffix": ":",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核意见", prop: "auditReason" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "100", "show-word-limit": "" },
                          model: {
                            value: _vm.auditForm.auditReason,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.auditForm,
                                "auditReason",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "auditForm.auditReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-left": "auto" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default", plain: "" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { loading: _vm.loading, type: "default" },
                        on: {
                          click: function ($event) {
                            return _vm.audit(2)
                          },
                        },
                      },
                      [_vm._v("驳回")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { loading: _vm.loading, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.audit(1)
                          },
                        },
                      },
                      [_vm._v("通过")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }