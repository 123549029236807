"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inventory = require("@/api/warehouse/inventory");
var _factory = require("@/api/basic/factory");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _detail = _interopRequireDefault(require("./components/detail"));
var _audit = _interopRequireDefault(require("./components/audit"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    insert: _insert.default,
    detail: _detail.default,
    productDialog: _productSelectDialog.default,
    audit: _audit.default
  },
  filters: {
    djztText: function djztText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '待盘点',
          2: '已下载',
          3: '已盘点',
          4: '审核通过',
          5: '审核拒绝'
        };
        return statusMap[value];
      }
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: 'success',
        4: '',
        5: 'danger'
      };
      return obj[val];
    } // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.figure == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  data: function data() {
    return {
      storeList: [],
      djzt: [{
        id: 1,
        name: '待盘点'
      }, {
        id: 2,
        name: '已下载'
      }, {
        id: 3,
        name: '已盘点'
      }, {
        id: 4,
        name: '审核通过'
      }, {
        id: 5,
        name: '审核拒绝'
      }],
      tableData: [],
      searchForm: {
        beginDate: null,
        endDate: null,
        productId: null,
        inventoryOrderNo: null,
        orderStatus: null,
        storeId: null,
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      productName: '',
      total: 0,
      tabHeight: '100%',
      handleShow: false,
      listLoading: false,
      addShow: false,
      detailShow: false,
      auditShow: false,
      orderId: ''
    };
  },
  activated: function activated() {
    this.auth = (0, _utils.getAuthBtn)();
    this.authId('searchbtn', this.$route.meta.authList);
    this.getStoreList();
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    // 按钮验证
    isAuth: function isAuth(val) {
      var flag = false;
      this.auth.filter(function (item) {
        if (item.resourceId == val) {
          flag = true;
        }
      });
      return flag;
    },
    getStoreList: function getStoreList(val) {
      var that = this;
      (0, _factory.ccGetHouseList)().then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    DoSearch: function DoSearch() {
      this.searchForm.current = 1;
      this.getList();
    },
    DoReset: function DoReset(formName) {
      this.pdaUser = '';
      this.productName = '';
      this.searchForm = {
        beginDate: null,
        endDate: null,
        inventoryOrderNo: null,
        productId: null,
        orderStatus: null,
        storeId: null,
        current: 1,
        size: 20
      };
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _inventory.queryForPage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            that.listLoading = false;
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    close: function close() {
      this.orderId = '';
      this.addShow = false;
      this.detailShow = false;
      this.auditShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    DoAdd: function DoAdd() {
      this.authId('addbtn', this.$route.meta.authList);
      this.addShow = true;
    },
    inSertSub: function inSertSub() {
      this.orderId = '';
      this.addShow = false;
      this.getList();
    },
    detail: function detail(row) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.orderId = row.pid;
      this.detailShow = true;
    },
    DoAudit: function DoAudit(row) {
      this.authId('pdsh', this.$route.meta.authList);
      this.orderId = row.pid;
      this.auditShow = true;
    },
    editor: function editor(row) {
      this.authId('updatebtn', this.$route.meta.authList);
      this.orderId = row.pid;
      this.addShow = true;
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.authId('deletebtn', _this2.$route.meta.authList);
        (0, _inventory.inventoryDelete)({
          pId: row.pid
        }).then(function (res) {
          _this2.authId('searchbtn', _this2.$route.meta.authList);
          if (res.data.code == 200) {
            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this2.getList();
          } else {
            _this2.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
      });
    },
    cancel: function cancel(row) {
      var _this3 = this;
      this.$confirm('确认撤销下载？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('cxxzbtn', _this3.$route.meta.authList);
        (0, _inventory.revocation)({
          pId: row.pid
        }).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          if (res.data.code == 200) {
            _this3.$message({
              type: 'success',
              message: '撤销成功!'
            });
            _this3.getList();
          } else {
            _this3.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;