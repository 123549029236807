"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _globalRuleConfig = require("@/api/generalSettings2/globalRuleConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarketingManagegeneralSettingsglobalRuleConfigindex',
  components: {},
  data: function data() {
    return {
      codeTable: {
        ruleCode: 'dayLotteryNumLimit' // 全局规则code
      },

      ruleData: {
        limitNumber: 10,
        // 默认 10
        status: '1',
        // 默认启用

        auditList: [],
        disableId: '',
        // 禁用
        enableId: '' // 启用
      },

      globalRule: {
        limitNumber: [{
          required: true,
          message: '请输入限制数量',
          trigger: 'blur'
        }]
      },
      saveLoad: false
    };
  },
  activated: function activated() {
    this.getRuleDetail();
    // this.getAllTerminalAuditConfig()
  },
  deactivated: function deactivated() {},
  methods: {
    getRuleDetail: function getRuleDetail() {
      var _this = this;
      (0, _globalRuleConfig.getRuleDetail)({
        ruleCode: this.codeTable.ruleCode
      }).then(function (res) {
        console.log(res);
        if (res.data && res.data.data.errCode) {
          return _this.$message.error(res.data.data.errMsg);
        } else {
          if (res.data && res.data.data) {
            _this.ruleData.limitNumber = res.data.data.globalRuleValue;
            _this.ruleData.status = res.data.data.status;
          }
        }
      });
    },
    // 审核
    getAllTerminalAuditConfig: function (_getAllTerminalAuditConfig) {
      function getAllTerminalAuditConfig() {
        return _getAllTerminalAuditConfig.apply(this, arguments);
      }
      getAllTerminalAuditConfig.toString = function () {
        return _getAllTerminalAuditConfig.toString();
      };
      return getAllTerminalAuditConfig;
    }(function () {
      var _this2 = this;
      getAllTerminalAuditConfig().then(function (res) {
        console.log(res.data, '终端审核方式');
        if (res.data.code != 200) {
          return _this2.$message.error(res.data.data.errMsg);
        } else {
          if (res.data && res.data.data) {
            _this2.ruleData.auditList = res.data.data;
            _this2.ruleData.auditList.forEach(function (item) {
              if (item.isEnable == 0) {
                _this2.ruleData.disableId = item.pid;
              }
              if (item.isEnable == 1) {
                _this2.ruleData.enableId = item.pid;
              }
            });
            console.log(_this2.ruleData);
          }
        }
      });
    }),
    auditChange: function auditChange(val) {
      console.log(val, '选择');
      var obj = this.ruleData.auditList.find(function (item) {
        return item.pid != val;
      });
      this.ruleData.disableId = obj.pid;
      this.ruleData.enableId = val;
    },
    handleSave: function handleSave(name) {
      var _this3 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this3.saveLoad = true;
          var sumitData = {
            globalRuleCode: _this3.codeTable.ruleCode,
            globalRuleValue: _this3.ruleData.limitNumber + '',
            status: _this3.ruleData.status
          };
          (0, _globalRuleConfig.saveGlobalRule)(sumitData).then(function (res) {
            if (res.data.data.errCode) {
              // this.changeTerminalAuditConfig()
              _this3.saveLoad = false;
              return _this3.$message.error(res.data.data.errMsg);
            } else {
              // this.changeTerminalAuditConfig()
              _this3.saveLoad = false;
              _this3.$message.success('更新成功');
              _this3.getRuleDetail();
            }
          });
        }
      });
    },
    changeTerminalAuditConfig: function (_changeTerminalAuditConfig) {
      function changeTerminalAuditConfig() {
        return _changeTerminalAuditConfig.apply(this, arguments);
      }
      changeTerminalAuditConfig.toString = function () {
        return _changeTerminalAuditConfig.toString();
      };
      return changeTerminalAuditConfig;
    }(function () {
      var _this4 = this;
      var sumitData2 = {
        disableId: this.ruleData.disableId,
        // 禁用
        enableId: this.ruleData.enableId // 启用
      };

      changeTerminalAuditConfig(sumitData2).then(function (res) {
        console.log(res.data, '修改');
        if (res.data.code != 200) {
          _this4.saveLoad = false;
          _this4.$message.error(res.data.msg);
          return;
        } else {
          _this4.$message.success(res.data.msg);
          // this.getAllTerminalAuditConfig()
          _this4.saveLoad = false;
        }
      });
    }),
    hanleReset: function hanleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
exports.default = _default;