"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _awardFailureList = require("@/api/operationalTools2/awardFailureList2");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsawardFailureListindex';
var _default = {
  name: 'MarketingManageoperationalToolsawardFailureListindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 10,
      formInline: {
        terminalCodeOrName: '',
        wechatIdOrOpenId: '',
        withDrawType: '',
        startTime: '',
        // 时间
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      rangeTime1: '',
      rangeTime2: '',
      strategyIds: [],
      listLoading: false,
      tableData: [],
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    // console.log(this.$route.params)
    // if (this.$route.params && this.$route.params.mchBillNo) {
    //   this.formInline.mchBillNo = this.$route.params.mchBillNo
    // }
    // this.formInline.status = ''
    this.activityPrizeRecordPage();
    // var hasCache1 = this.tableData.length > 0 ? true : false;
    // if (!hasCache1) {
    //   this.activityPrizeRecordPage();
    // }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 表格
    activityPrizeRecordPage: function activityPrizeRecordPage() {
      var that = this;
      that.listLoading = true;
      (0, _awardFailureList.queryDefeatPage)(this.formInline).then(function (res) {
        console.log(res.data);
        if (res.data.data.data.errCode) {
          that.$message.error(res.data.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data.data != null) {
            that.total = parseInt(res.data.data.data.total);
            that.tableData = res.data.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 时间选择
    sj1: function sj1(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 搜索
    search: function search() {
      this.activityPrizeRecordPage();
    },
    // 重置搜索框
    reset: function reset() {
      this.$refs['formInline'].resetFields();
      this.rangeTime1 = '';
      this.activityPrizeRecordPage();
    },
    // 重发
    restSendPrize: function restSendPrize(id) {
      var that = this;
      var arr = [];
      if (!Array.isArray(id)) {
        arr.push(id);
      } else {
        arr = id;
      }
      (0, _awardFailureList.cashReissue)(arr).then(function (res) {
        if (res.data.data.errCode) {
          return that.$message.error(res.data.data.errMsg);
        }
        that.$message.success('您选择的记录已成功加入补发队列，请稍后查看补发结果');
        arr = [];
        setTimeout(function () {
          that.activityPrizeRecordPage();
        }, 1 * 800);
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      var arr = data.map(function (item) {
        return item.id;
      });
      this.strategyIds = arr;
    },
    // 全部补发
    allReplacement: function allReplacement() {
      var _this = this;
      if (this.strategyIds.length < 1) {
        return this.$message.info('请选择补发项');
      }
      this.$confirm("\u662F\u5426".concat(this.strategyIds.length > 1 ? '全部补发' : '补发选中', "?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.restSendPrize(_this.strategyIds);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 补发
    handleClick: function handleClick(row) {
      var _this2 = this;
      this.$confirm("\u662F\u5426\u91CD\u53D1?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.restSendPrize(row.id);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};
exports.default = _default;