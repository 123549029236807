"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _select = require("@/api/warehouse/select");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getLevelById, supportBoxLevelList } from '@/api/basic/pack'
// import { parseTime, getAuthBtn, tableHeight } from '@/utils'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productSelectDialog.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    showCol: {
      default: false
    }
  },
  data: function data() {
    return {
      productCode: '',
      TypeDataComs: {
        factoryList: [],
        OrgList: [],
        StoreList: [],
        billTypes: []
      },
      orgOptions: [],
      searchForm: {
        beginDate: '',
        billNo: '',
        billType: '',
        current: 1,
        endDate: '',
        orgCode: null,
        productCode: '',
        size: 20,
        storeageCode: ''
      },
      dateValue: [],
      expandTxt: '展开'
      //   showCol: false,
    };
  },
  created: function created() {
    this.getStatusList();
    this.getFactoryInfoList();
    // this.getStoreList()
  },
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.searchForm.productCode = '';
        this.productCode = '';
        this.dateValue = [];
        this.$refs.searchForm.resetFields();
        this.searchForm.orgCode = null;
      }
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      if (this.dateValue.length > 0) {
        this.searchForm.beginDate = this.dateValue[0];
        this.searchForm.endDate = this.dateValue[1];
      } else {
        this.searchForm.beginDate = '';
        this.searchForm.endDate = '';
      }
      this.$parent.searchList(this.searchForm);
    },
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.productCode = '';
      this.searchForm.productCode = '';
    },
    setSel: function setSel(val) {
      this.productCode = val.productName;
      this.searchForm.productCode = val.pId;
    },
    getstoreTypeInfoList: function getstoreTypeInfoList(type, keyName) {
      // 4:单据来源，7：单据状态，103：工厂代码
      var that = this;
      (0, _select.storeTypeInfoList)(type).then(function (res) {
        if (res.data.code != 200) {
          that.TypeDataComs[keyName] = [];
          return;
        } else {
          that.TypeDataComs[keyName] = res.data.data;
        }
      }).catch(function () {
        that.TypeDataComs[keyName] = [];
      });
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this = this;
      // 获取工厂下啦列表数据
      var that = this;
      this.TypeDataComs.OrgList = [];
      var data = {};
      (0, _select.queryAuthByUserId)(data).then(function (res) {
        console.log(res);
        if (res.data.code != 200) {
          return;
        } else {
          var rData = res.data.data;
          var arr = [];
          _this.TypeDataComs.OrgList = res.data.data;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this2 = this;
      // 获取库房下拉列表数据
      this.TypeDataComs.StoreList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this2.TypeDataComs.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    setFactory: function setFactory(v) {},
    // 组织change
    getOrgVal: function getOrgVal(val) {
      var that = this;
      that.TypeDataComs.StoreList = [];
      that.searchForm.storeageCode = '';
      that.getHouses(val.id);
    },
    emptyStores: function emptyStores(val) {
      var that = this;
      if (typeof val == 'undefined') {
        that.TypeDataComs.StoreList = [];
        that.searchForm.storeageCode = '';
      }
    },
    // 获取库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      that.TypeDataComs.StoreList = [];
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataComs.StoreList = res.data.data;
        }
      });
    },
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.searchForm.orgCode == null || that.searchForm.orgCode == '') {
        that.$message.error('请选择所属企业');
        return;
      }
    },
    // 获取单据状态
    getStatusList: function getStatusList() {
      var _this3 = this;
      this.TypeDataComs.billTypes = [];
      var param = {};
      param.code = 'CCLX';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this3.TypeDataComs.billTypes = res.data.data;
        }
      }).catch(function () {});
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;