var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "space-between" } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 2 },
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticStyle: {
                                  "font-weight": "400",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                用户分析\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c("el-row", { staticClass: "hyyy-content" }, [
                      _c(
                        "div",
                        { staticClass: "hyyy-main" },
                        [
                          _c(
                            "el-row",
                            { attrs: { type: "flex", justify: "start" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10, md: 12 } },
                                [
                                  _c("span", [_vm._v("领取时间：")]),
                                  _vm._v(" "),
                                  _c("el-date-picker", {
                                    attrs: {
                                      size: "large",
                                      type: "daterange",
                                      align: "right",
                                      "unlink-panels": "",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.value2,
                                      callback: function ($$v) {
                                        _vm.value2 = $$v
                                      },
                                      expression: "value2",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 2 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.getdatamsa },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { attrs: { type: "flex", justify: "start" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "bodbox", attrs: { span: 12 } },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n                    性别分布\n                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "300px",
                                        "margin-top": "70px",
                                      },
                                    },
                                    [_c("PieChart", { ref: "sexSourcePie" })],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "bodbox", attrs: { span: 12 } },
                                [
                                  _c("h4", [_vm._v("年龄分布")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20, md: 21 } },
                                        [
                                          _vm._v(
                                            "\n                      每\n                      "
                                          ),
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: _vm.list.age,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.list,
                                                      "age",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "list.age",
                                                },
                                              },
                                              _vm._l(
                                                _vm.options1,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          _vm._v(
                                            "\n                      岁为区间间隔，\n                      起始值为\n                      "
                                          ),
                                          _c("el-input", {
                                            model: {
                                              value: _vm.list.startAge,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.list,
                                                  "startAge",
                                                  $$v
                                                )
                                              },
                                              expression: "list.startAge",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 2 } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.getdatamsa },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "300px",
                                        "margin-top": "10px",
                                      },
                                    },
                                    [_c("PieChart", { ref: "ageSourcePie" })],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { attrs: { type: "flex", justify: "start" } },
                            [
                              _c(
                                "el-col",
                                { staticClass: "bodbox", attrs: { span: 12 } },
                                [
                                  _c("h4", [_vm._v("会员来源")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "300px",
                                      },
                                    },
                                    [
                                      _c("PieChart", {
                                        ref: "vipsourSourcePie",
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "bodbox", attrs: { span: 12 } },
                                [
                                  _c("h4", [_vm._v("会员等级分布")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "300px",
                                      },
                                    },
                                    [
                                      _c("PieChart", {
                                        ref: "viplevelSourcePie",
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "bodbox",
                              attrs: { type: "flex", justify: "start" },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24, md: 24 } },
                                [
                                  _c("h4", [_vm._v("任务值分布")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 18, md: 20 } },
                                        [
                                          _vm._v(
                                            "\n                      每\n                      "
                                          ),
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: _vm.list.taskValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.list,
                                                      "taskValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "list.taskValue",
                                                },
                                              },
                                              _vm._l(
                                                _vm.options2,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          _vm._v(
                                            "\n                      任务值为区间间隔，\n                      起始值为\n                      "
                                          ),
                                          _c("el-input", {
                                            model: {
                                              value: _vm.list.startTaskValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.list,
                                                  "startTaskValue",
                                                  $$v
                                                )
                                              },
                                              expression: "list.startTaskValue",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 2 } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.getdatamsa },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("barChart", {
                                    staticStyle: { height: "400px" },
                                    attrs: { "chart-data": _vm.barChartData1 },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.memberInfoTaskVlueVOList,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "taskStr",
                                          label: "任务值",
                                          width: "180",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "number",
                                          label: "会员数",
                                          width: "180",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "percentage",
                                          label: "占比",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("filternum")(
                                                      scope.row.percentage
                                                    )
                                                  ) + "%"
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "bodbox", attrs: { span: 24 } },
                                [
                                  _c("ChinaChart", {
                                    ref: "chinachart",
                                    attrs: { "chart-data": _vm.Chinadate },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "bodbox",
                              attrs: { type: "flex", justify: "start" },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("barChart", {
                                    staticStyle: { height: "400px" },
                                    attrs: { "chart-data": _vm.barChartData2 },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { data: _vm.cityDataList },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "nme",
                                          label: "城市",
                                          width: "180",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "number",
                                          label: "会员人数",
                                          width: "180",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "percentage",
                                          label: "占比",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("filternum")(
                                                      scope.row.percentage
                                                    )
                                                  ) + "%"
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }