"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _abolish = _interopRequireDefault(require("./components/abolish"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 码状态 0：未激活 1：有效 2:冻结 3.废除  (恢复操作=有效)
var _default = {
  name: 'ComplexTable',
  components: {
    abolish: _abolish.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      activeName: 'abolish',
      dialogFormVisible: false
    };
  },
  created: function created() {},
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
exports.default = _default;