var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "供货角色-查看权限",
        model: _vm.scanAuth,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "append-to-body": "",
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px" } },
        [
          _c("el-form-item", { attrs: { label: "角色名称:" } }, [
            _vm._v(_vm._s(_vm.roleName)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tree-container" }, [
        _c(
          "div",
          { staticClass: "tree-scroll" },
          [
            _c("el-tree", {
              attrs: {
                data: _vm.scanAuthData,
                "node-key": "resourceCode",
                props: _vm.defaultProps,
                "default-expand-all": "",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }