"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _activityManage = require("@/api/terminal/activityManage");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _distributorSelect = _interopRequireDefault(require("@/components/distributorSelect"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _config = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/config"));
var _actPr = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/actPr"));
var _scan = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/scan.vue"));
var _relatUnlockPrize = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/relatUnlockPrize"));
var _batchUpdateActPri = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/batchUpdateActPri"));
var _batchUpdateActSet = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/activityManage/components/batchUpdateActSet"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagemarketingActivitiesactivityManageindex';
var _default = {
  components: {
    Pagination: _Pagination.default,
    actConfig: _config.default,
    actPr: _actPr.default,
    actPreview: _scan.default,
    productSelectDialog: _productSelectDialog.default,
    relatUnlockPrize: _relatUnlockPrize.default,
    batchUpdateActPri: _batchUpdateActPri.default,
    batchUpdateActSet: _batchUpdateActSet.default,
    distributorSelect: _distributorSelect.default
  },
  data: function data() {
    return {
      handleShow: false,
      shloading: false,
      timer: null,
      tabHeight: '100%',
      list: null,
      listLoading: false,
      total: 0,
      investVisible: false,
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        activityNameOrActivityCode: '',
        activityStatus: '',
        productId: '',
        fundId: '',
        productName: '',
        endTime: '',
        startTime: '',
        rangeTime: '',
        dealerId: '',
        mchBillNo: ''
      },
      activityDimensions: [{
        name: '产品',
        value: '0'
      }, {
        name: '订单',
        value: '1'
      }, {
        name: '生产任务单',
        value: '2'
      }],
      activityStatus: [{
        name: '未开始',
        value: '0'
      }, {
        name: '停用',
        value: '1'
      }, {
        name: '进行中',
        value: '2'
      }, {
        name: '已过期',
        value: '3'
      }],
      sourceList: [],
      multipleSelection: [],
      currentBatchUpdatePrizeData: [],
      batchPrData: [],
      dialogVisible: false,
      actId: '',
      activityId: '',
      type: 'add',
      readonly: false,
      showOnly: false,
      currentDataActivityStatus: '',
      // 0 未开始   1 停用   2 进行中  3 已过期
      actPrVisible: false,
      scanVisible: false,
      relatUnlockPrizeVisible: false,
      batchUpdActPriVisible: false,
      batchUpdActSetVisible: false,
      scanId: '',
      proVisible: true,
      downLoading: false,
      activityFlowId: '',
      isShowDistributor: false
    };
  },
  computed: {
    tableMultipleSelectionId: function tableMultipleSelectionId() {
      return this.multipleSelection.map(function (item) {
        return item.id;
      });
    }
  },
  watch: {
    // 防错位
    list: function list(val) {
      this.doLayout();
    }
  },
  activated: function activated() {
    if (this.$route.params && this.$route.params.seqId) {
      this.searchForm.seqNo = this.$route.params.seqId;
    }
    var that = this;
    that.getList();
    that.getZjSourceList();
    that.getInitPrizeList('100');
    that.getInitPrizeList('200');
    // that.getInitPrizeList('300')
    // that.getInitPrizeList('400')
    that.getInitPrizeList('500');
    // that.getInitPrizeList('900')

    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    that.$nextTick(function () {
      var h = (0, _utils.tableHeight)();
      that.tabHeight = h;
    });
  },
  methods: {
    // 切换查询条件
    reSearch: function reSearch(e) {
      var that = this;
      that.$nextTick(function () {
        that.getList();
      });
    },
    // 刷新列表
    doLayout: function doLayout() {
      var that = this;
      that.$nextTick(function () {
        that.$refs.table.doLayout();
      });
    },
    // 获取活动列表
    getList: function getList() {
      var _this = this;
      if (this.searchForm.seqNo) {
        var seqNoList = this.searchForm.seqNo.split(',');
        var isPass = seqNoList.filter(function (item) {
          return item;
        }).every(function (item) {
          return !isNaN(Number(item));
        });
        if (!isPass) {
          this.$message.error('活动ID输入错误');
          return;
        }
      }
      this.listLoading = true;
      if (this.searchForm.rangeTime && this.searchForm.rangeTime.length > 0) {
        this.searchForm.startTime = this.searchForm.rangeTime[0];
        this.searchForm.endTime = this.searchForm.rangeTime[1];
      } else {
        this.searchForm.startTime = '';
        this.searchForm.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.searchForm));
      delete sdata.rangeTime;
      delete sdata.productName;
      delete sdata.dealerName;
      (0, _activityManage.geActList)(sdata).then(function (res) {
        if (res.data.code == '200') {
          _this.listLoading = false;
          if (!res.data.data.errCode) {
            _this.list = res.data.data.records;
            _this.total = res.data.data.total;
            if (_this.searchForm.current != 1 && !res.data.data.records.length) {
              _this.searchForm.current = 1;
              _this.getList();
            }
          } else {
            _this.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this.listLoading = false;
          _this.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 获取资金来源
    getZjSourceList: function getZjSourceList() {
      var _this2 = this;
      (0, _activityManage.getMoneySource)().then(function (res) {
        if (res.data.code == 200) {
          _this2.sourceList = res.data.data;
        }
      });
    },
    // 导出奖品
    exportExcel: function exportExcel() {
      var _this3 = this;
      this.downLoading = true;
      if (this.searchForm.rangeTime && this.searchForm.rangeTime.length > 0) {
        this.searchForm.startTime = this.searchForm.rangeTime[0];
        this.searchForm.endTime = this.searchForm.rangeTime[1];
      } else {
        this.searchForm.startTime = '';
        this.searchForm.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.searchForm));
      // sdata.seqNo = this.multipleSelection.map(item => item.seqNo).join(',')
      delete sdata.rangeTime;
      delete sdata.productName;
      delete sdata.dealerName;
      (0, _activityManage.exportExcel)(sdata).then(function (res) {
        _this3.downLoading = false;
        if (res.status !== 200) {
          _this3.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this3.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '活动列表.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this3.downLoading = false;
      });
    },
    checkSelectable: function checkSelectable(row) {
      return row.activityStatus != '3';
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 重置表单
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.searchForm.activityCode = '';
      this.searchForm.pageNo = 1;
      this.searchForm.pageSize = 20;
      this.searchForm.activityNameOrActivityCode = '';
      this.searchForm.activityStatus = '';
      this.searchForm.productId = '';
      this.searchForm.fundId = '';
      this.searchForm.productName = '';
      this.searchForm.endTime = '';
      this.searchForm.startTime = '';
      this.searchForm.rangeTime = '';
      this.searchForm.dealerId = '';
      this.searchForm.mchBillNo = '';
      this.getList();
    },
    // 打开批量修改活动奖品
    openBatchUpdateActPri: function openBatchUpdateActPri(data) {
      var _this4 = this;
      if (!this.tableMultipleSelectionId.length) {
        this.$message.error('至少选择一个活动');
        return;
      }
      console.log(this.multipleSelection);
      var activityIdList = this.tableMultipleSelectionId;
      (0, _activityManage.getBatchUpdatePrize)({
        activityIdList: activityIdList
      }).then(function (res) {
        console.log(res);
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this4.currentBatchUpdatePrizeData = res.data.data;
            _this4.activityId = data.id;
            _this4.activityFlowId = _this4.multipleSelection[0].activityFlowId;
            _this4.batchUpdActPriVisible = true;
          } else {
            _this4.$message.error(res.data.data.errMsg);
          }
        } else {
          _this4.$message.error(res.data.msg);
        }
      });
    },
    closeBatchUpdateActPri: function closeBatchUpdateActPri() {
      this.activityId = '';
      this.activityFlowId = '';
      this.batchUpdActPriVisible = false;
      this.getList();
    },
    // 打开批量修改活动设置
    openBatchUpdateActSet: function openBatchUpdateActSet(data) {
      if (!this.tableMultipleSelectionId.length) {
        this.$message.error('至少选择一个活动');
        return;
      }
      this.activityId = data.id;
      this.batchUpdActSetVisible = true;
    },
    closeBatchUpdateActSet: function closeBatchUpdateActSet() {
      this.activityId = '';
      this.batchUpdActSetVisible = false;
      this.getList();
    },
    // 新增活动
    creatAct: function creatAct() {
      // this.authId('addbtn', this.apis)
      this.type = 'add';
      this.readonly = false;
      this.dialogVisible = true;
    },
    // 创建关闭
    close: function close() {
      this.dialogVisible = false;
      this.getList();
      this.actId = '';
      this.readonly = false;
      this.type = 'add';
    },
    // 编辑
    edit: function edit(data) {
      if (data.activityStatus == '1' || data.activityStatus == '2') {
        this.readonly = true;
      } else {
        this.readonly = false;
      }
      this.type = 'edit';
      this.actId = data.id;
      this.dialogVisible = true;
    },
    // 复制活动
    copy: function copy(data) {
      // this.authId('copyBtn', this.apis)
      this.type = 'copy';
      this.actId = data.id;
      this.readonly = false;
      this.dialogVisible = true;
    },
    // 追加活动
    zhuijia: function zhuijia(data) {
      // this.authId('copyBtn', this.apis)
      this.type = 'zhuijia';
      this.actId = data.id;
      this.readonly = false;
      this.dialogVisible = true;
    },
    // 活动停止,开始
    setDisable: function setDisable(val) {
      var _this5 = this;
      // this.authId('stopbtn', this.apis)
      (0, _activityManage.actDisable)({
        id: val.id
      }).then(function (res) {
        console.log(res);
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this5.getList();
          } else {
            _this5.$message.error(res.data.data.errMsg);
          }
        } else {
          _this5.$message.error(res.data.msg);
          return;
        }
      });
    },
    // 活动数据，中奖记录
    getRecord: function getRecord(data) {
      this.$router.push({
        name: 'marketingModulemarketingManageoperationalToolswinningLotteryindex',
        params: {
          actid: data.id
        }
      });
    },
    // 概览
    scan: function scan(data) {
      this.scanId = data.id;
      this.scanVisible = true;
    },
    closeScan: function closeScan() {
      this.scanVisible = false;
    },
    openBatchActPr: function openBatchActPr() {
      var _this6 = this;
      if (!this.tableMultipleSelectionId.length) {
        this.$message.error('至少选择一个活动');
        return;
      }
      var activityIdList = this.tableMultipleSelectionId;
      (0, _activityManage.batchUpdatePrValid)({
        activityIdList: activityIdList
      }).then(function (res) {
        if (res.data.code == 200) {
          if (!res.data.data.errCode) {
            _this6.batchPrData = res.data.data;
            _this6.showOnly = true;
            _this6.actPrVisible = true;
          } else {
            _this6.$message.error(res.data.data.errMsg);
          }
        } else {
          _this6.$message.error(res.data.msg);
        }
      });

      // this.showOnly = true
      // this.actPrVisible = true
    },
    // 打开活动概率
    openActPr: function openActPr(data) {
      if (data.activityStatus == '1' || data.activityStatus == '2') {
        this.showOnly = true;
      } else {
        this.showOnly = false;
      }
      this.currentDataActivityStatus = data.activityStatus;
      this.activityId = data.id;
      this.actPrVisible = true;
    },
    // 关闭概率
    closeActPr: function closeActPr() {
      this.actPrVisible = false;
      this.currentDataActivityStatus = '';
      this.activityId = '';
      this.getList();
    },
    openBatchRelatUnlockPrize: function openBatchRelatUnlockPrize() {
      if (!this.tableMultipleSelectionId.length) {
        this.$message.error('至少选择一个活动');
        return;
      }
      this.relatUnlockPrizeVisible = true;
    },
    // 打开关联解锁奖品
    openRelatUnlockPrize: function openRelatUnlockPrize(data) {
      this.activityId = data.id;
      this.currentDataActivityStatus = data.activityStatus;
      this.relatUnlockPrizeVisible = true;
    },
    // 关闭关联解锁奖品
    closeRelatUnlockPrize: function closeRelatUnlockPrize() {
      this.relatUnlockPrizeVisible = false;
      this.activityId = '';
      this.currentDataActivityStatus = '';
      this.getList();
    },
    choseDealer: function choseDealer() {
      this.isShowDistributor = true;
    },
    // 选择产品
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    selectPro: function selectPro(val) {
      this.searchForm.productId = val.pId;
      this.searchForm.productName = val.productName;
    },
    selectDealer: function selectDealer(val) {
      this.searchForm.dealerId = val.pId;
      this.searchForm.dealerName = val.channelName;
      this.isShowDistributor = false;
    },
    // 根据奖品类型初始化奖品列表
    getInitPrizeList: function getInitPrizeList(type) {
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: type
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          sessionStorage.setItem(type, JSON.stringify(res.data.data));
        }
      });
    },
    closeInvest: function closeInvest() {
      this.investVisible = false;
      this.activityId = '';
      this.getList();
    }
  }
};
exports.default = _default;