"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delLevelById = delLevelById;
exports.delsupportBoxById = delsupportBoxById;
exports.getExcelTemplate = getExcelTemplate;
exports.getLevelById = getLevelById;
exports.packScaleLeveAlllList = packScaleLeveAlllList;
exports.packScaleLevelInsert = packScaleLevelInsert;
exports.packScaleLevelList = packScaleLevelList;
exports.packScaleLevelUpdate = packScaleLevelUpdate;
exports.supportBoxLevelInsert = supportBoxLevelInsert;
exports.supportBoxLevelInsertV2 = supportBoxLevelInsertV2;
exports.supportBoxLevelList = supportBoxLevelList;
exports.supportBoxLevelUpadte = supportBoxLevelUpadte;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''

function packScaleLevelList(data) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/list',
    method: 'post',
    data: data
  });
}
function packScaleLeveAlllList(data) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/listAll',
    method: 'post',
    data: data
  });
}
function packScaleLevelInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/insert',
    method: 'post',
    data: data
  });
}
function packScaleLevelUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/update',
    method: 'post',
    data: data
  });
}
function getLevelById(params) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/getLevelById',
    method: 'get',
    params: params
  });
}
function delLevelById(params) {
  return (0, _request.default)({
    url: modelUrl + '/packScaleLevel/enablePackScaleDictionary',
    method: 'get',
    params: params
  });
}
function supportBoxLevelList(data) {
  return (0, _request.default)({
    url: modelUrl + '/supportBoxLevel/list',
    method: 'post',
    data: data
  });
}
function supportBoxLevelInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/supportBoxLevel/insert',
    method: 'post',
    data: data
  });
}
// 修改后添加
function supportBoxLevelInsertV2(data) {
  return (0, _request.default)({
    url: modelUrl + '/supportBoxLevel/insertV2',
    method: 'post',
    data: data
  });
}
function supportBoxLevelUpadte(data) {
  return (0, _request.default)({
    url: modelUrl + '/supportBoxLevel/update',
    method: 'post',
    data: data
  });
}
function delsupportBoxById(params) {
  return (0, _request.default)({
    url: modelUrl + '/supportBoxLevel/enableSupportBoxLevel',
    method: 'get',
    params: params
  });
}

// 下载模板
function getExcelTemplate(params) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/getExcelTemplate',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}