"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.discardedByMark = discardedByMark;
exports.discardedByPack = discardedByPack;
exports.listLog = listLog;
exports.lockByBatch = lockByBatch;
exports.lockByBill = lockByBill;
exports.lockByMark = lockByMark;
exports.lockByPack = lockByPack;
exports.unlockByBatch = unlockByBatch;
exports.unlockByBill = unlockByBill;
exports.unlockByMark = unlockByMark;
exports.unlockByPack = unlockByPack;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 码状态管理日志查询
 * @param {*} data 
 */
function listLog(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/listLog',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据生产批次号解除冻结
 * @param {*} data 
 */
function unlockByBatch(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/unlockByBatch',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据交货单号解除冻结
 * @param {*} data 
 */
function unlockByBill(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/unlockByBill',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码解除冻结
 * @param {*} data 
 */
function unlockByMark(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/unlockByMark',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据包材订单号解除冻结
 * @param {*} data 
 */
function unlockByPack(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/unlockByPack',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码废除
 * @param {*} data 
 */
function discardedByMark(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/discardedByMark',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据包材订单号废除
 * @param {*} data 
 */
function discardedByPack(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/discardedByPack',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据生产批次号冻结
 * @param {*} data 
 */
function lockByBatch(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/lockByBatch',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据交货单号冻结
 * @param {*} data 
 */
function lockByBill(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/lockByBill',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码冻结
 * @param {*} data 
 */
function lockByMark(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/lockByMark',
    method: 'post',
    params: data
  });
}

/**
 * 根据包材订单号冻结
 * @param {*} data 
 */
function lockByPack(data) {
  return (0, _request.default)({
    url: '/api-mark/mark/statusManager/lockByPack',
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}