"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addJudgeApplyOrder = addJudgeApplyOrder;
exports.addJudgeOrder = addJudgeOrder;
exports.addPurchaseOrder = addPurchaseOrder;
exports.addSaleOrder = addSaleOrder;
exports.buyerMoneyWine = buyerMoneyWine;
exports.byOrgFactoryId = byOrgFactoryId;
exports.byPurchaseIdList = byPurchaseIdList;
exports.companyList = companyList;
exports.deliverList = deliverList;
exports.excelOut = excelOut;
exports.generateTakeOrder = generateTakeOrder;
exports.getAuditLog = getAuditLog;
exports.getCompanyById = getCompanyById;
exports.getGhsById = getGhsById;
exports.getInOutCompany = getInOutCompany;
exports.getOrderNo = getOrderNo;
exports.judgeAuditOrder = judgeAuditOrder;
exports.matchChannelBuyGift = matchChannelBuyGift;
exports.modJudgeApplyOrder = modJudgeApplyOrder;
exports.modJudgeOrder = modJudgeOrder;
exports.modPurchaseOrder = modPurchaseOrder;
exports.modSaleOrder = modSaleOrder;
exports.preAuditOrder = preAuditOrder;
exports.prodAuditOrder = prodAuditOrder;
exports.queryByOrderNo = queryByOrderNo;
exports.queryInventByStoreIdAndProdId = queryInventByStoreIdAndProdId;
exports.queryJudgeApplyForPage = queryJudgeApplyForPage;
exports.queryJudgeForPage = queryJudgeForPage;
exports.queryPurchaseForPage = queryPurchaseForPage;
exports.querySaleForPage = querySaleForPage;
exports.queryTakeByOrderNo = queryTakeByOrderNo;
exports.sureAuditOrder = sureAuditOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
// 根据单位ID获取详情信息
function getCompanyById(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/getCompanyById',
    method: 'post',
    data: data
  });
}

//  采购订单分页查询
function queryPurchaseForPage(data) {
  var current = data.current,
    size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryPurchaseForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 获取订单号
function getOrderNo(params) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/getOrderNo/' + params,
    method: 'get'
  });
}

// 订单查询根据ordeNO
function queryByOrderNo(params) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryByOrderNo/' + params,
    method: 'get'
  });
}

// 新增采购订单
function addPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/addPurchaseOrder',
    method: 'post',
    data: data
  });
}

// 修改采购订单
function modPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/modPurchaseOrder',
    method: 'post',
    data: data
  });
}

//  销售订单分页查询
function querySaleForPage(data) {
  var current = data.current,
    size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/api-order/tradeOrder/querySaleForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 新增销售订单
function addSaleOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/addSaleOrder',
    method: 'post',
    data: data
  });
}

// 修改销售订单
function modSaleOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/modSaleOrder',
    method: 'post',
    data: data
  });
}

// 审核销售订单
function preAuditOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/preAuditOrder',
    method: 'post',
    data: data
  });
}

// 审核销售订单
function prodAuditOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/prodAuditOrder',
    method: 'post',
    data: data
  });
}

// 确款销售订单
function sureAuditOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/sureAuditOrder',
    method: 'post',
    data: data
  });
}

// 提货详情根据ID
function queryTakeByOrderNo(params) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryTakeByOrderNo/' + params,
    method: 'get'
  });
}

// 查询下属仓库
function byOrgFactoryId(params) {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/byOrgFactoryId',
    method: 'get',
    params: params
  });
}

//  获取当前产品库存量
function queryInventByStoreIdAndProdId(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryInventByStoreIdAndProdId',
    method: 'post',
    data: data
  });
}

// 根据单号提货
function generateTakeOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/generateTakeOrder',
    method: 'post',
    data: data
  });
}

//  费用酒订单分页查询
function queryJudgeForPage(data) {
  var current = data.current,
    size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryJudgeForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 新增费用酒订单
function addJudgeOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/addJudgeOrder',
    method: 'post',
    data: data
  });
}

// 修改费用酒订单
function modJudgeOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/modJudgeOrder',
    method: 'post',
    data: data
  });
}

//  费用酒申请单分页查询
function queryJudgeApplyForPage(data) {
  var current = data.current,
    size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryJudgeApplyForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 新增 费用酒申请单
function addJudgeApplyOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/addJudgeApplyOrder',
    method: 'post',
    data: data
  });
}

// 修改 费用酒申请单
function modJudgeApplyOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/modJudgeApplyOrder',
    method: 'post',
    data: data
  });
}

// 审核费用酒订单
function judgeAuditOrder(data) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/judgeAuditOrder',
    method: 'post',
    data: data
  });
}

// old
// 采购、收货单位列表
function companyList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/companyList',
    method: 'post',
    data: data
  });
}

// 发货单位列表
function deliverList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/deliverList',
    method: 'post',
    data: data
  });
}

// 根据产品及渠道商获取匹配到的返利政策
function matchChannelBuyGift(data) {
  return (0, _request.default)({
    url: '/api-rebate/rebateOrder/matchChannelBuyGift',
    method: 'post',
    data: data
  });
}

// 获取审核日志
function getAuditLog(params) {
  return (0, _request.default)({
    url: '/api-order/tradeOrderLog/queryOrderLog',
    method: 'get',
    params: params
  });
}

/**
 * 导出服务
 * @param {*} query
 */
function excelOut(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/excelOut',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

// 出入库收发货单位接口
function getInOutCompany(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/getInOutCompany',
    method: 'post',
    data: data
  });
}

// 根据采购商Id获取供货商信息PURCHASE_ID
function byPurchaseIdList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/byPurchaseIdList',
    method: 'post',
    data: data
  });
}

// 查询收货单位可用酒券金额
function buyerMoneyWine(data) {
  return (0, _request.default)({
    url: '/api-rebate/rebateOrder/buyerMoneyWine',
    method: 'post',
    data: data
  });
}

// 根据供货商Id获取采购商信息
function getGhsById(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/bySupplierIdList',
    method: 'post',
    data: data
  });
}

// // 根据产品及渠道商获取匹配到的返利政策
// export function matchChannelBuyGift(data) {
//     return request({
//         url: '/storage-center/orderInfo/matchChannelBuyGift',
//         method: 'post',
//         data
//     })
// }