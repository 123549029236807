"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _modelUploadImg = _interopRequireDefault(require("@/components/modelUploadImg"));
var _templateManage = require("@/api/market/templateManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var infoBg = require('@/images/info-bg.png');
var infoBtnBg = require('@/images/info-btn.png');
var _default2 = {
  components: {
    UploadImg: _modelUploadImg.default
  },
  props: {
    type: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    editType: {
      type: String,
      default: function _default() {
        return 'add';
      }
    },
    editData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      from: '',
      listLoading: false,
      activeName: 'basic',
      active: 0,
      activeMax: 2,
      checkName: '',
      bgImg: '',
      info: [],
      checkBoxList: [],
      checkList: [],
      renderList: [],
      label: '背面图：',
      cardIndex: 0,
      rotate1: false,
      rotate2: false,
      showBack: true,
      showFront: false,
      infoBg: infoBg,
      infoBtnBg: infoBtnBg,
      prioritys: [{
        name: '一等奖',
        id: 1
      }, {
        name: '二等奖',
        id: 2
      }, {
        name: '三等奖',
        id: 3
      }, {
        name: '四等奖',
        id: 4
      }, {
        name: '五等奖',
        id: 5
      }, {
        name: '六等奖',
        id: 6
      }, {
        name: '七等奖',
        id: 7
      }, {
        name: '八等奖',
        id: 8
      }, {
        name: '谢谢惠顾',
        id: 99999
      }],
      basicForm: {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '1',
        templateType: '1'
      },
      contentForm: {
        title: '',
        background: '',
        frontImg: '',
        backImg: '',
        defaultWordColor: '#FFFFFF',
        activeWordColor: '#FFE600',
        activeBackImg: '',
        cards: [{
          frontImg: '',
          backImg: ''
        }, {
          frontImg: '',
          backImg: ''
        }, {
          frontImg: '',
          backImg: ''
        }, {
          frontImg: '',
          backImg: ''
        }, {
          frontImg: '',
          backImg: ''
        }, {
          frontImg: '',
          backImg: ''
        }],
        prizeImgs: [{
          priority: null,
          imgurl: ''
        }]
      },
      infoForm: {
        openDataCollection: '2',
        openLocation: '2',
        relevanceDataId: '',
        collectionBtn: ''
      },
      rules: {
        templateName: [{
          required: !this.disabled,
          message: '模版名称不能为空',
          trigger: 'change'
        }],
        templateStatus: [{
          required: !this.disabled,
          message: '请选择模板状态',
          trigger: 'change'
        }]
      },
      contentRules: {
        background: [{
          required: true,
          message: '请上传背景',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '请上传标题图片',
          trigger: 'change'
        }],
        frontImg: [{
          required: true,
          message: '请上传正面图片',
          trigger: 'change'
        }],
        defaultWordColor: [{
          required: true,
          message: '请选择默认字体颜色',
          trigger: 'change'
        }],
        activeWordColor: [{
          required: true,
          message: '请选择中奖字体颜色',
          trigger: 'change'
        }],
        activeBackImg: [{
          required: true,
          message: '请上传中奖正面图片',
          trigger: 'change'
        }],
        backImg: [{
          required: true,
          message: '请上背面图片',
          trigger: 'change'
        }]
      },
      infoRules: {},
      disabledLoading: false
    };
  },
  watch: {
    'contentForm.backImg': {
      handler: function handler(newValue, oldValue) {
        var _this = this;
        if (this.cardIndex == 0) {
          this.contentForm.cards.forEach(function (item) {
            item.backImg = newValue;
          });
        } else {
          if (this.cardIndex - 1 >= 0) {
            this.contentForm.cards[this.cardIndex - 1].backImg = newValue;
          }
        }
        if (!this.showBack) {
          this.rotate1 = false;
          this.rotate2 = true;
          setTimeout(function () {
            _this.showFront = false;
            _this.showBack = true;
          }, 500);
        }
      },
      deep: true
    },
    'contentForm.frontImg': {
      handler: function handler(newValue, oldValue) {
        var _this2 = this;
        this.contentForm.cards.forEach(function (item) {
          item.frontImg = newValue;
        });
        this.rotate1 = !this.rotate1;
        if (!this.contentForm.activeBackImg) {
          this.contentForm.activeBackImg = newValue;
          // this.$set(this.contentForm, 'activeBackImg', newValue)
        }

        setTimeout(function () {
          _this2.showFront = true;
          _this2.showBack = false;
        }, 500);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.basicForm.id = this.id;
    this.basicForm.templateType = this.type;
    if (this.id) {
      this.cardIndex = null;
    } else {
      this.cardIndex = 0;
    }
    this.getInfo();
  },
  methods: {
    // 获取收集信息
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _templateManage.getCollectionInfo)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this3.checkBoxList = res.data;
          _this3.getDetail();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 监听收集项选择
    handleCheckedInfoChange: function handleCheckedInfoChange(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].fieldName) {
              this.renderList.push(this.checkBoxList[j]);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 设置背景图片
    setBgImg: function setBgImg(val) {
      this.bgImg = val;
    },
    // 取消
    goBack: function goBack() {
      this.active = 0;
      this.$refs.basicForm.resetFields();
      this.$refs.contentForm.resetFields();
      this.$refs.infoForm.resetFields();
      this.basicForm = {
        id: '',
        templateName: '',
        templateRemark: '',
        templateStatus: '1',
        templateCategory: '1',
        templateType: '1'
      };
      this.contentForm = {
        title: '',
        background: '',
        obtainBtn: '',
        prizeBg: '',
        openPrizeBg: '',
        templateType: '1'
      };
      this.infoForm = {
        openDataCollection: '2',
        openLocation: '2',
        relevanceDataId: '',
        collectionBtn: ''
      };
      this.$emit('close');
    },
    // 获取模板详细
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
      var item = this.editData;
      var paraseData = JSON.parse(item.templateElements);
      this.bgImg = paraseData.background;
      this.basicForm = {
        id: item.id,
        templateName: item.templateName,
        templateRemark: item.templateRemark,
        templateStatus: item.templateStatus,
        templateCategory: item.templateCategory,
        templateType: item.templateType
      };
      this.contentForm = {
        title: paraseData.title,
        backImg: paraseData.backImg,
        background: paraseData.background,
        cards: paraseData.cards,
        frontImg: paraseData.frontImg,
        prizeImgs: paraseData.prizeImgs,
        defaultWordColor: paraseData.defaultWordColor,
        activeWordColor: paraseData.activeWordColor,
        activeBackImg: paraseData.activeBackImg
      };
      this.infoForm = {
        openDataCollection: item.openDataCollection,
        openLocation: item.openLocation,
        relevanceDataId: item.relevanceDataId,
        collectionBtn: paraseData.collectionBtn
      };
      if (item.relevanceDataId) {
        var newArr = item.relevanceDataId.split(',');
        this.transChangeData(newArr);
      }
      if (this.editType == 'edit') {
        this.uploads(6);
      }
    },
    // 是否数据收集切换
    switchChange: function switchChange(val) {
      if (val == '2') {
        this.checkList = [];
        this.renderList = [];
      }
    },
    // 数据转化
    transChangeData: function transChangeData(val) {
      if (val && val.length > 0) {
        this.renderList = [];
        this.checkList = [];
        for (var i = 0; i < val.length; i++) {
          for (var j = 0; j < this.checkBoxList.length; j++) {
            if (val[i] == this.checkBoxList[j].id) {
              this.renderList.push(this.checkBoxList[j]);
              this.checkList.push(this.checkBoxList[j].fieldName);
              break;
            }
          }
        }
      } else {
        this.renderList = [];
      }
    },
    // 上传背面图片
    uploads: function uploads(arg) {
      this.cardIndex = arg;
      this.label = '背面图' + arg + '：';
      this.contentForm.backImg = this.contentForm.cards[arg - 1].backImg ? this.contentForm.cards[arg - 1].backImg : this.contentForm.backImg;
    },
    // 添加奖品图片
    addPrize: function addPrize() {
      this.contentForm.prizeImgs.push({
        priority: null,
        imgurl: ''
      });
    },
    // 删除奖项
    delPrize: function delPrize(index) {
      this.contentForm.prizeImgs.splice(index, 1);
      if (!this.contentForm.prizeImgs.length) {
        this.contentForm.prizeImgs = [{
          priority: null,
          imgurl: ''
        }];
      }
    },
    // 检验奖项是否重复
    checkSame: function checkSame(e, index) {
      for (var i = 0; i < this.contentForm.prizeImgs.length; i++) {
        if (i != index && this.contentForm.prizeImgs[i].priority == e) {
          this.contentForm.prizeImgs[index].priority = null;
          this.$message.error('奖品等级重复，请重新配置！');
          break;
        }
      }
    },
    // 保存
    save: function save() {
      var _this4 = this;
      this.contentForm.collectionBtn = this.infoForm.collectionBtn;
      var cForm = {
        templateElements: JSON.stringify(this.contentForm)
      };
      var arr = [];
      if (this.renderList.length > 0) {
        this.renderList.forEach(function (item) {
          arr.push(item.id);
        });
      }
      this.infoForm.relevanceDataId = arr.length > 0 ? arr.join(',') : '';
      var info = JSON.parse(JSON.stringify(this.infoForm));
      delete info.collectionBtn;
      var object = Object.assign(this.basicForm, info, cForm);
      this.disabledLoading = true;
      if (this.editType == 'add') {
        (0, _templateManage.templateAdd)(object).then(function (res) {
          _this4.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this4.$message({
                message: '添加成功！',
                type: 'success'
              });
              _this4.goBack();
            } else {
              _this4.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this4.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
      if (this.editType == 'edit') {
        (0, _templateManage.templateEdit)(object).then(function (res) {
          _this4.disabledLoading = false;
          if (res.data.code == 200) {
            if (res.data.data == true) {
              _this4.$message({
                message: '修改成功！',
                type: 'success'
              });
              _this4.goBack();
            } else {
              _this4.$message.error(res.data.data.errMsg);
              return;
            }
          } else {
            _this4.$message.error(res.data.msg);
            return;
          }
        });
        return;
      }
    },
    // 按钮事件
    subForm: function subForm(text) {
      var _this5 = this;
      var that = this;
      // 上一步
      if (text === 'up') {
        that.active--;
      } else if (text === 'down') {
        // 下一步
        if (this.active === 0) {
          var basic = this.checkForm('basicForm', '请完善基础信息');
          basic.then(function () {
            that.rotate1 = false;
            that.rotate2 = false;
            that.showFront = false;
            that.showBack = true;
            that.active++;
          });
        } else if (this.active === 1) {
          var content = this.checkForm('contentForm', '请完善活动信息');
          var prizems = this.contentForm.prizeImgs;
          content.then(function () {
            var next = true;
            for (var j = 0; j < prizems.length; j++) {
              if (prizems[j].priority != null && prizems[j].imgurl == '') {
                _this5.$message.error('请配置第' + (j + 1) + '行，与奖品等级相匹配的奖品图片');
                next = false;
                break;
              }
              if (prizems[j].priority == null && prizems[j].imgurl != '') {
                _this5.$message.error('请配置第' + (j + 1) + '行，精品等级');
                next = false;
                break;
              }
            }
            if (next) {
              that.active++;
            }
          });
        }
      }
    },
    checkForm: function checkForm(val, text) {
      var _this6 = this;
      return new Promise(function (resolve, reject) {
        _this6.$refs[val].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            _this6.$message.closeAll();
            _this6.$message({
              message: text,
              type: 'error',
              duration: 3 * 1000
            });
            reject(text);
          }
        });
      });
    }
  }
};
exports.default = _default2;