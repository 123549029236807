"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportSalesmanScanRecordsList = exportSalesmanScanRecordsList;
exports.getSalesmanScanRecordsList = getSalesmanScanRecordsList;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = process.env.VUE_APP_VERSION2;

// 业务员核销记录列表
function getSalesmanScanRecordsList(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesmanrecord/getSalesmanScanRecordsList',
    method: 'post',
    data: data
  });
}

// 导出业务员核销记录列表
function exportSalesmanScanRecordsList(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesmanrecord/getSalesmanScanRecordsList_exp',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}