"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
var _default = {
  components: {
    admincut: _admincut.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      // 公众号切换
      // options: [
      // ],
      // value: '',
      // 时间选择器
      handleShow: false,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value2: '',
      // 标签查询
      formInline: {
        appId: '',
        // 结束时间
        endTime: '',
        // 用户名
        nickname: '',
        pageNo: 1,
        pageSize: 10,
        // 开始时间
        startTime: '',
        // 渠道来源
        qrScene: '',
        // 标签
        tagId: ''
      },
      // 标签
      tagss: [{
        tagName: '全部',
        tagId: 0
      }, {
        tagName: '无标签',
        tagId: 1
      }],
      // 渠道
      restaurants: [],
      state: '',
      timeout: null,
      // 标签
      tags: ['标签一', '标签二', '标签三'],
      // 表格
      tableData: [],
      multipleSelection: [],
      // 修改标签
      checkList: '',
      dialogVisible: false,
      userload: true,
      // 打标签数据
      tagsList: {
        tagId: '',
        userTagQuery: []
      },
      taggg: [],
      total: 0
    };
  },
  created: function created() {
    this.getserrch();
    this.getAlltags();
    this.formInline.appId = sessionStorage.getItem('appId');
  },
  mounted: function mounted() {},
  methods: {
    // 获取所有标签
    getAlltags: function getAlltags() {
      var _this = this;
      (0, _wxgzh.getAllTag)({
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          var _this$tagss;
          _this.tagss = [{
            tagName: '全部',
            tagId: 0
          }, {
            tagName: '无标签',
            tagId: 1
          }];
          (_this$tagss = _this.tagss).push.apply(_this$tagss, (0, _toConsumableArray2.default)(res.data));
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // 获取渠道
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var _this2 = this;
      var restaurants = this.restaurants;
      var n = {
        codeName: queryString,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getUserCode)(n).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          restaurants = res.data;
          var list = [];
          for (var i in res.data) {
            var _n = {
              value: res.data[i].codeName,
              sceneId: res.data[i].sceneId
            };
            list.push(_n);
          }
          restaurants = list;
          cb(restaurants);
        } else {
          _this2.$message.warning(res.msg);
        }
      });
    },
    handleSelect: function handleSelect(item) {
      console.log(item.sceneId);
      this.formInline.qrScene = item.sceneId;
      console.log(this.formInline);
    },
    // 打标签选中
    getcheck: function getcheck(val) {
      console.log(val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      this.vipload = true;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.pageNo = val;
      // const a = {
      //   pageNo: this.formInline.pageNo,
      //   pageSize: this.formInline.pageSize
      // }
      (0, _wxgzh.getSearchuser)(this.formInline).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          _this3.total = res.data.total;
          if (_this3.total * 1 === 0) {
            _this3.userload = false;
            _this3.tableData = '';
          }
          _this3.tableData = res.data.records;
        } else {
          _this3.$message.warning(res.msg);
        }
        _this3.userload = false;
      });
    },
    // 获取用户列表
    getserrch: function getserrch() {
      var _this4 = this;
      this.formInline.tagId = 0;
      this.formInline.endTime = '';
      this.formInline.startTime = '';
      this.formInline.nickname = '';
      this.formInline.qrScene = '';
      this.formInline.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.getSearchuser)(this.formInline).then(function (res) {
        console.log(res);
        var res = res.data;
        if (res.code * 1 === 200) {
          _this4.total = res.data.total;
          if (_this4.total * 1 === 0) {
            _this4.userload = false;
            _this4.tableData = '';
          }
          _this4.tableData = res.data.records;
        } else {
          _this4.$message.warning(res.msg);
        }
        _this4.userload = false;
      });
    },
    // 删除标签
    handleClosetag: function handleClosetag(tag, list, row) {
      var _this5 = this;
      this.$confirm('删除后将无法恢复，确定删除？', '删除操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var a = {
          tagId: tag.tagId,
          userId: row.userId,
          appId: sessionStorage.getItem('appId')
        };
        console.log(list);
        var i = list.indexOf(tag);
        list.splice(i, 1);
        (0, _wxgzh.batchUnTagging)(a).then(function (res) {
          var res = res.data;
          if (res.code * 1 === 200) {
            _this5.$message.success('删除成功');
          } else {
            _this5.$message.warning(res.msg);
          }
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 查询操作
    onSubmit: function onSubmit() {
      var _this6 = this;
      this.userload = true;
      this.formInline.startTime = this.value2[0];
      this.formInline.endTime = this.value2[1];
      this.formInline.qrScene = this.state;
      this.formInline.pageNo = 1;
      this.formInline.appId = sessionStorage.getItem('appId');
      if (this.formInline.tagId == undefined) {
        this.$message.warning('请选择一个标签');
        return;
      }
      (0, _wxgzh.getSearchuser)(this.formInline).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          if (res.data.records.length > 0 && res.data.records) {
            _this6.tableData = res.data.records;
          }
        } else {
          _this6.$message.warning(res.msg);
        }
        _this6.userload = false;
      });
    },
    onReset: function onReset() {
      this.formInline.nickname = '';
      this.value2 = '';
      this.state = '';
      this.formInline.tagId = 0;
    },
    // 表格选中操作
    handleSelection: function handleSelection(selection, row) {
      console.log(selection);
    },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      var _this7 = this;
      this.multipleSelection = val;
      this.tagsList.userTagQuery = [];
      this.multipleSelection.forEach(function (val) {
        var n = {
          openId: '',
          userId: ''
        };
        n.openId = val.openId;
        n.userId = val.userId;
        _this7.tagsList.userTagQuery.push(n);
      });
      console.log(this.tagsList);
    },
    // 打标签
    opentagging: function opentagging() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择用户再进行操作');
        return;
      }
      this.dialogVisible = true;
      this.checkList = '';
      this.taggg = this.tagss.slice(2);
      console.log(this.tagss);
    },
    // 提交打标签
    tagging: function tagging() {
      var _this8 = this;
      // if (this.checkList.length > 1) {
      //   this.$message.warning('只能选择一个标签')
      //   return
      // }
      console.log(this.checkList);
      // let a
      // this.tagss.forEach((val) => {
      //   if (val.tagName == this.checkList[0]) {
      //     a = val
      //   }
      // })
      // // this.tagsList.tagId = a.tagId
      // console.log(this.tagsList)
      if (this.checkList === '') {
        this.$message.warning('请选择一个标签');
      }
      this.tagsList.userTagQuery.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.batchTagging)(this.tagsList.userTagQuery, this.checkList).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          _this8.$message.success('操作成功');
        } else {
          _this8.$message.warning('操作失败，请重试');
        }
        _this8.dialogVisible = false;
        _this8.userload = true;
        _this8.getserrch();
      });
    },
    // 删除用户
    handleDelete: function handleDelete(index, rows, row) {
      var _this9 = this;
      console.log(index, row);
      var n = {
        userId: row.userId,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getDeleteuser)(n).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          _this9.$message.success('删除成功');
          rows.splice(index, 1);
        } else {
          _this9.$message.warning(res.msg);
        }
        _this9.userload = true;
        _this9.getserrch();
      });
    },
    // 去重
    setGround: function setGround(val) {
      var groArr = [];
      for (var i in this.tagss) {
        groArr.push(this.tagss[i].tagName);
      }
      var Arr = new Set(groArr);
      // console.log(Arr)
      return Arr.has(val);
    },
    // 新建标签
    open: function open() {
      var _this10 = this;
      this.$prompt('标签名称', '新建标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: //,
        // inputErrorMessage: ''
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this10.setGround(value)) {
          _this10.$message({
            type: 'error',
            message: '重复的标签名'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this10.$message({
            type: 'error',
            message: '请输入有效的标签名'
          });
          return;
        }
        var n = {
          tagName: value,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.ccrrTag)(n).then(function (res) {
          var res = res.data;
          console.log(res);
          if (res.code * 1 === 200) {
            _this10.$message({
              type: 'success',
              message: '新建标签是: ' + value
            });
            _this10.getAlltags();
          } else {
            _this10.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }).catch(function () {
        _this10.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    handleClose: function handleClose(done) {
      this.$confirm('确认关闭？').then(function (_) {
        done();
      });
      //  .catch(_ => {});
    }
  }
};
exports.default = _default;