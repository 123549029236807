var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "查看信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanData",
          staticClass: "info-items",
          attrs: {
            model: _vm.detailData,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "基本信息", column: 2, border: "" } },
            [
              _c("el-descriptions-item", { attrs: { label: "单据编码" } }, [
                _vm._v(_vm._s(_vm.scanData.materialNo)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "引用模板名称" } }, [
                _vm._v(_vm._s(_vm.scanData.templateName)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "上传组数" } }, [
                _vm._v(_vm._s(_vm.scanData.uploadGroup)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "批次号" } }, [
                _vm._v(_vm._s(_vm.scanData.batchNo)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                _vm._v(_vm._s(_vm.scanData.remark)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                _vm._v(_vm._s(_vm.scanData.createName)),
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                _vm._v(_vm._s(_vm._f("formatDate")(_vm.scanData.createTime))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "itemTitle",
              staticStyle: {
                border: "1px solid #e8e8e8",
                padding: "10px",
                "margin-bottom": "-1px",
                background: "#ececec",
              },
            },
            [_vm._v("\n      数码详情\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticClass: "detailTable",
              staticStyle: { width: "100%", "margin-top": "1px" },
              attrs: {
                data: _vm.detailtable,
                fit: "",
                "max-height": "400",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "row-key": "materialCode",
                height: "440px",
                "highlight-current-row": "",
                lazy: "",
                load: _vm.load,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "60",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数码",
                  prop: "materialCode",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "层级名称",
                  prop: "levelName",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }