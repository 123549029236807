"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    data: String
  },
  data: function data() {
    return {
      eggPosition: [{
        bottom: "20px",
        left: "25px",
        font: '1',
        isShowFont: true
      }, {
        bottom: "7px",
        left: "40px"
      }, {
        bottom: "27px",
        left: "50px"
      }, {
        bottom: "0px",
        left: "62px"
      }, {
        bottom: "24px",
        left: "75px"
      }, {
        bottom: "-1px",
        left: "86px",
        font: '2',
        isShowFont: true
      }, {
        bottom: "10px",
        left: "109px",
        font: '3',
        isShowFont: true
      }, {
        bottom: "35px",
        left: "97px"
      }, {
        bottom: "23px",
        left: "126px"
      }, {
        bottom: "47px",
        left: "137px"
      }, {
        bottom: "41px",
        left: "10px"
      }, {
        bottom: "47px",
        left: "63px",
        font: '4',
        isShowFont: true
      }],
      list: {
        eggs: []
      }
    };
  },
  computed: {
    getEggList: function getEggList() {
      var eggList = [];
      if (this.list.eggs && this.list.eggs.length) {
        for (var i = 0; i < 3; i++) {
          eggList.push.apply(eggList, (0, _toConsumableArray2.default)(this.list.eggs));
        }
      }
      return eggList;
    }
  },
  mounted: function mounted() {
    if (this.data) {
      this.list = JSON.parse(this.data);
    }
  },
  methods: {}
};
exports.default = _default;