var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择",
        visible: _vm.unitVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.unitVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "searchForm",
          staticClass: "page-section search-condition",
          attrs: { model: _vm.searchForm, "status-icon": "" },
        },
        [
          _c("div", { staticClass: "phead" }, [
            _vm._v("\n      数据筛选\n      "),
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-refresh" },
                    on: { click: _vm.resetForm },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, prop: "keyword" } },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("关键字"),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "编码/名称/联系人/手机号",
                        },
                        model: {
                          value: _vm.searchForm.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "keyword", $$v)
                          },
                          expression: "searchForm.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, prop: "channelType" } },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("渠道类型"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.searchForm.channelType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "channelType", $$v)
                            },
                            expression: "searchForm.channelType",
                          },
                        },
                        _vm._l(_vm.channelTypeOption, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, prop: "channelOrgId" } },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("所属组织"),
                      ]),
                      _vm._v(" "),
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "",
                          options: _vm.orgList,
                          filterable: "",
                          clearable: "",
                          props: _vm.props,
                        },
                        model: {
                          value: _vm.channelOrgId,
                          callback: function ($$v) {
                            _vm.channelOrgId = $$v
                          },
                          expression: "channelOrgId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, prop: "area" } },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("省市区"),
                      ]),
                      _vm._v(" "),
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.areaList,
                          props: _vm.cityProps,
                          clearable: "",
                        },
                        model: {
                          value: _vm.area,
                          callback: function ($$v) {
                            _vm.area = $$v
                          },
                          expression: "area",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page-section" }, [
        _c(
          "section",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "渠道编码",
                    prop: "channelCode",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "渠道名称",
                    prop: "channelName",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "渠道分类",
                    prop: "channelClassName",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "渠道等级",
                    prop: "channelGradeName",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属组织",
                    prop: "orgName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "联系人",
                    prop: "contacts",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "手机号",
                    prop: "phone",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属主体",
                    prop: "masterChannelName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "省",
                    prop: "provinceName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "市",
                    prop: "cityName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "区县",
                    prop: "countyName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    fixed: "right",
                    align: _vm.tableConfig.align,
                    width: "80",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.detail(row)
                                },
                              },
                            },
                            [_vm._v("选择")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }