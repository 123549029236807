var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "审批流配置-修改",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "append-to-body": "",
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "editForm",
          staticStyle: { width: "80%", margin: "0 auto" },
          attrs: {
            model: _vm.editData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审批流编码:", prop: "modelKey" } },
            [
              _c("el-input", {
                attrs: { clearable: "", disabled: "disabled" },
                model: {
                  value: _vm.editData.modelKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "modelKey", $$v)
                  },
                  expression: "editData.modelKey",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "审批流名称:", prop: "modelName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.editData.modelName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "modelName", $$v)
                  },
                  expression: "editData.modelName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "接口回调地址:", prop: "callbackUrl" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.editData.callbackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "callbackUrl", $$v)
                  },
                  expression: "editData.callbackUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "详情页地址:", prop: "detailUrl" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.editData.detailUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "detailUrl", $$v)
                  },
                  expression: "editData.detailUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "billCode", label: "单据:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.editData.billCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.editData, "billCode", $$v)
                    },
                    expression: "editData.billCode",
                  },
                },
                _vm._l(_vm.pageData, function (item) {
                  return _c("el-option", {
                    key: item.figure,
                    attrs: { label: item.name, value: item.figure },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "模块:", prop: "moduleCode" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.editData.moduleCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.editData, "moduleCode", $$v)
                    },
                    expression: "editData.moduleCode",
                  },
                },
                _vm._l(_vm.moduleData, function (item) {
                  return _c("el-option", {
                    key: item.moduleCode,
                    attrs: { label: item.moduleName, value: item.moduleCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "审批流模板:", prop: "templateId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: { change: _vm.chnageSrc },
                  model: {
                    value: _vm.editData.templateId,
                    callback: function ($$v) {
                      _vm.$set(_vm.editData, "templateId", $$v)
                    },
                    expression: "editData.templateId",
                  },
                },
                _vm._l(_vm.templates, function (item) {
                  return _c("el-option", {
                    key: item.pid,
                    attrs: { label: item.name, value: item.pid },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.imgSrc
                ? _c("el-image", {
                    staticClass: "blockimg",
                    attrs: { src: _vm.imgSrc, "preview-src-list": _vm.srcList },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述:", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入",
                  clearable: "",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.editData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.editData, "remark", $$v)
                  },
                  expression: "editData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "align-right", staticStyle: { padding: "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.dstate },
                    on: {
                      click: function ($event) {
                        return _vm.submit("editForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "default", plain: "" },
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }