"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _compyuser = require("@/api/systems/compyuser");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _add = _interopRequireDefault(require("./components/add"));
var _edit = _interopRequireDefault(require("./components/edit"));
var _scan = _interopRequireDefault(require("./components/scan"));
var _scanRole = _interopRequireDefault(require("./components/scanRole"));
var _scanAuth = _interopRequireDefault(require("./components/scanAuth"));
var _assignRole = _interopRequireDefault(require("./components/assignRole"));
var _addAuth = _interopRequireDefault(require("./components/addAuth"));
var _allEdit = _interopRequireDefault(require("./components/allEdit"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemrolesmangesysuserindex';
var _default = {
  name: 'Systemrolesmangesysuserindex',
  components: {
    Pagination: _Pagination.default,
    add: _add.default,
    edit: _edit.default,
    scan: _scan.default,
    scanRole: _scanRole.default,
    scanAuth: _scanAuth.default,
    Treeselect: _vueTreeselect.default,
    assignRole: _assignRole.default,
    addAuth: _addAuth.default,
    allEdit: _allEdit.default
  },
  data: function data() {
    return {
      groupList: [],
      postList: [],
      listQuery: {
        current: 1,
        size: 20
      },
      param: {
        loginAccount: '',
        userName: '',
        userType: 3,
        phoneNum: '',
        isEnable: '',
        orgList: [],
        roleList: []
      },
      list: [],
      multipleSelection: [],
      tableKey: '010099',
      total: 0,
      listLoading: false,
      roleOption: [],
      apis: [],
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      addShow: false,
      editData: {},
      editShow: false,
      scanData: {},
      scanShow: false,
      roleData: [],
      roleShow: false,
      scanAuthData: [],
      scanAuthShow: false,
      asroleShow: false,
      authShow: false,
      authDataShow: false,
      id: '',
      orgOriginData: [],
      options: [],
      uname: {},
      names: {},
      ids: '',
      allEditShow: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      // 获取下拉角色
      that.getUserRloes();
      that.getList();
      that.getOrg();
      // 分组
      this.getDirSel('FZ100', 'groupList');
      // 岗位
      this.getDirSel('GC100', 'postList');
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
          } else {
            _this[list] = [];
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _compyuser.getCompanyList)(that.listQuery, that.param).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 1000);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.getList();
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.param.loginAccount = '';
      that.param.userName = '';
      that.param.phoneNum = '';
      that.param.isEnable = '';
      that.param.orgList = [];
      that.param.roleList = [];
      that.listQuery.current = 1;
      that.listQuery.current = 1;
      that.$refs[formName].resetFields();
      that.getList();
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.options = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.$nextTick(function () {
        that.addShow = true;
      });
    },
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _compyuser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.editData.loginAccount = res.data.data.loginAccount;
          that.editData.userName = res.data.data.userName;
          that.editData.userType = 3;
          that.editData.orgId = that.getOrgId(res.data.data.orgName);
          that.editData.phoneNum = res.data.data.phoneNum;
          that.editData.email = res.data.data.email;
          that.editData.remark = res.data.data.remark;
          that.editData.id = res.data.data.pid;
          that.editData.postCode = 1;
          that.editData.groupCode = 4;
          if (res.data.data.isEnable == 1) {
            that.editData.isEnable = true;
          } else {
            that.editData.isEnable = false;
          }
        } else {
          that.editData = {};
        }
        that.editShow = true;
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _compyuser.getUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
        } else {
          that.scanData = {};
        }
      });
      that.$nextTick(function () {
        that.scanShow = true;
      });
    },
    setPwd: function setPwd(data) {
      var that = this;
      var setHeaderId = that.authId('setbtn', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _compyuser.setUserPwd)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$alert(res.data.msg, '提示', {
            confirmButtonText: '我知道啦',
            callback: function callback(action) {
              console.log('已修改');
            }
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    scanRole: function scanRole(data) {
      var that = this;
      var setHeaderId = that.authId('scanrole', that.apis);
      var param = {};
      param.id = data.pid;
      (0, _compyuser.scanUserRole)(param).then(function (res) {
        if (res.data.code == 200) {
          that.roleData = res.data.data;
          that.roleShow = true;
        } else {
          that.roleData = [];
          that.roleShow = true;
        }
      });
    },
    scanAuth: function scanAuth(data) {
      var that = this;
      var setHeaderId = that.authId('scanauth', that.apis);
      that.id = data.pid;
      that.uname = data;
      that.$nextTick(function () {
        that.scanAuthShow = true;
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      var that = this;
      that.multipleSelection = data;
    },
    getAuth: function getAuth(data) {
      var that = this;
      that.authData = (0, _utils.deepClone)(data);
      that.$nextTick(function () {
        that.authShow = true;
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _compyuser.stopUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.getList();
          } else {
            that.$message.error('禁用失败');
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {};
        param.id = data.pid;
        (0, _compyuser.startUser)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.getList();
          } else {
            that.$message.error('启用失败');
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    getUserRloes: function getUserRloes() {
      var that = this;
      (0, _compyuser.getAllQyRoles)().then(function (res) {
        if (res.data.code == 200) {
          that.roleOption = res.data.data;
        } else {
          that.roleOption = [];
        }
      });
    },
    AllStop: function AllStop() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要禁用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _compyuser.stopItems)(sdata).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.multipleSelection = [];
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    AllStart: function AllStart() {
      var that = this;
      var setHeaderId = that.authId('allstart', that.apis);
      var param = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要启用的项');
        return;
      }
      data.forEach(function (item) {
        param.push(item.pid);
      });
      var sdata = {};
      sdata.ids = param.join(',');
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _compyuser.startItems)(sdata).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.multipleSelection = [];
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    AllEdit: function AllEdit() {
      var that = this;
      var setHeaderId = that.authId('alledit', that.apis);
      that.ids = '';
      var ids = [];
      var data = that.multipleSelection;
      if (data.length == 0) {
        that.$message.error('请选择要修改的项');
        return;
      }
      data.forEach(function (item) {
        ids.push(item.pid);
      });
      that.ids = ids.join(',');
      that.$nextTick(function () {
        that.allEditShow = true;
      });
    },
    rankRole: function rankRole() {
      var that = this;
      var setHeaderId = that.authId('roleassign', that.apis);
      if (that.multipleSelection.length == 0) {
        that.$message.error('请选择需要分配的项');
        return;
      }
      that.$nextTick(function () {
        that.asroleShow = true;
      });
    },
    getOrgId: function getOrgId(param) {
      var that = this;
      var data = that.orgOriginData;
      var id = '';
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].orgName == param) {
            id = data[i].pid;
            break;
          }
        }
      } else {
        id = '';
      }
      return id;
    },
    getPostCode: function getPostCode(name) {
      var id = '';
      if (this.postList.length > 0) {
        this.postList.forEach(function (item) {
          if (item.name == name) {
            id = item.figure;
          }
        });
      }
      console.log(id, 'getPostCode');
      return id;
    },
    getGroupCode: function getGroupCode(name) {
      var id = '';
      if (this.groupList.length > 0) {
        this.groupList.forEach(function (item) {
          if (item.name == name) {
            id = item.figure;
          }
        });
      }
      console.log(id, 'getGroupCode');
      return id;
    },
    rankAuth: function rankAuth() {
      var that = this;
      var setHeaderId = that.authId('authassign', that.apis);
      var data = that.multipleSelection;
      var ids = [];
      var names = [];
      if (data.length == 0) {
        that.$message.error('请选择要分配的项');
        return;
      }
      data.forEach(function (item) {
        ids.push(item.pid);
        names.push(item.loginAccount);
      });
      that.names.userName = names.join(',');
      that.ids = ids.join(',');
      that.$nextTick(function () {
        that.authDataShow = true;
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;