"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//
// const animationDuration = 6000
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    isClick: {
      type: Boolean,
      default: false
    },
    clickFun: {
      type: Function,
      default: function _default() {}
    },
    colors: {
      type: Array,
      default: function _default() {
        return ['#F0514A', '#339BF3', '#66DC79', '#FFCB00', '#5F2EB3', '#FE7F8F', '#FC7B20', '#0066CC', '#FFCD31', '#00B7CE'];
      }
    },
    legend: {
      type: Object,
      default: function _default() {
        return {
          show: true,
          orient: 'vartical',
          selectedMode: true,
          top: 'center',
          right: 40
        };
      }
    },
    pieCenter: {
      type: Array,
      default: function _default() {
        return ['35%', '50%'];
      }
    },
    pieRadius: {
      type: Array,
      default: function _default() {
        return ['55%', '78%'];
      }
    }
  },
  data: function data() {
    return {
      loading: true,
      chart: null,
      xData: [],
      sData: []
    };
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      // this.initChart()
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      this.loading = false;
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        color: this.colors,
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: true
        },
        legend: this.legend,
        series: [{
          type: 'pie',
          radius: this.pieRadius,
          avoidLabelOverlap: true,
          center: this.pieCenter,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.sData
        }]
      });
      this.chart.on('click', function (params) {
        if (_this.isClick) {
          _this.clickFun(params.data);
        }
      });
    }
  }
};
exports.default = _default2;