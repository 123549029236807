"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    data: String
  },
  data: function data() {
    return {
      list: {}
    };
  },
  mounted: function mounted() {
    if (this.data) {
      this.list = JSON.parse(this.data);
    }
  },
  methods: {}
};
exports.default = _default;