"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
var _default2 = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    tipName: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    titleName: {
      type: String,
      default: ''
    },
    isClick: {
      type: Boolean,
      default: false
    },
    clickFun: {
      type: Function,
      default: function _default() {}
    },
    rotate: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: true,
      chart: null,
      xData: [],
      sData: []
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      this.loading = false;
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        color: ['#1890ff', '#5ec45f', '#FF7755'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          }
        },

        legend: {
          data: this.tipName
        },
        grid: {
          top: 10,
          left: 0,
          right: 50,
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: this.xData,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CCCCCC'
            }
          },
          nameTextStyle: {
            color: '#fff'
          },
          axisLabel: {
            show: true,
            color: '#333',
            rotate: this.rotate
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          type: 'value',
          name: this.titleName,
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CCCCCC'
            }
          },
          nameTextStyle: {
            color: '#CCCCCC'
          },
          axisLabel: {
            show: true,
            color: '#333'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }],
        series: this.sData
      });
      this.chart.getZr().on('click', function (params) {
        if (_this.isClick) {
          _this.clickFun();
        }
      });
    }
  }
};
exports.default = _default2;