var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "销售订单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orderNo", $$v)
                                      },
                                      expression: "listQuery.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression: "showCol||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "linkedOrderNo",
                                    label: "关联采购单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value:
                                        _vm.listQuery.storeOrderInfoQuery
                                          .linkedOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery.storeOrderInfoQuery,
                                          "linkedOrderNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "listQuery.storeOrderInfoQuery.linkedOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "purchaseComOwnerType",
                                        label: "采购单位类型:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.listQuery.storeOrderInfoQuery
                                                .purchaseComOwnerType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery
                                                  .storeOrderInfoQuery,
                                                "purchaseComOwnerType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "listQuery.storeOrderInfoQuery.purchaseComOwnerType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "渠道客户",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "团购客户",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "销售公司",
                                              value: "3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "sendOutComId",
                                    label: "发货单位:",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchSendOutComName,
                                        callback: function ($$v) {
                                          _vm.searchSendOutComName = $$v
                                        },
                                        expression: "searchSendOutComName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchSendOutComName",
                                              2,
                                              12
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "receiveComId",
                                    label: "收货单位:",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchReceiveComName,
                                        callback: function ($$v) {
                                          _vm.searchReceiveComName = $$v
                                        },
                                        expression: "searchReceiveComName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchReceiveComName",
                                              1,
                                              12
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderStartTime",
                                        label: "订单起始日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.beginDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "beginDate",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.beginDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderEndTime",
                                        label: "订单结束日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.endDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "endDate",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.endDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderStatus",
                                        label: "状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.orderStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "orderStatus",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.orderStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderStatus,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: [item.figure],
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "queryType",
                                        label: "查询类型:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.listQuery.storeOrderInfoQuery
                                                .queryType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery
                                                  .storeOrderInfoQuery,
                                                "queryType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "listQuery.storeOrderInfoQuery.queryType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.businessOption,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.code,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "queryContent",
                                        label: "查询内容:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        on: { focus: _vm.checkQueryType },
                                        model: {
                                          value:
                                            _vm.listQuery.storeOrderInfoQuery
                                              .queryContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery.storeOrderInfoQuery,
                                              "queryContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "listQuery.storeOrderInfoQuery.queryContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  staticStyle: {
                                    width: "240px",
                                    "padding-left": "0",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(_vm.listQuery)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset(_vm.listQuery)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group fun-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.DoAdd },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "120",
                      align: _vm.tableConfig.align,
                      type: "index",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "销售订单号",
                      "min-width": "160",
                      prop: "orderNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoScan(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.orderNo))]
                                  )
                                : _c("div", [_vm._v(_vm._s(row.orderNo))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      758945116
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联采购单号",
                      "min-width": "160",
                      prop: "linkedOrderNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._v(_vm._s(row.linkedOrderNo))]
                          },
                        },
                      ],
                      null,
                      false,
                      1699284305
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位类型",
                      "min-width": "120",
                      prop: "purchaseCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("purchaseComOwnerType")(
                                    row.purchaseComOwnerType
                                  )
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2277093446
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位",
                      "min-width": "120",
                      prop: "purchaseCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      "min-width": "120",
                      prop: "sendOutCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位",
                      "min-width": "120",
                      prop: "receiveCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "付款方式",
                      "min-width": "90",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("payWayText")(row.paymentStyle))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3711130961
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStatusText")(row.orderStatus)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3551039611
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请人",
                      "min-width": "90",
                      prop: "applyUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      "min-width": "110",
                      prop: "creatorUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下单日期",
                      "min-width": "110",
                      prop: "placeOrderDate",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      "min-width": "110",
                      prop: "remark",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("xsddsh", _vm.$route.meta.authList) ||
                  _vm.hasAuth("xsddth", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "160",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: row.orderStatus != 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "xsddth",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "xsddsh",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-dropdown",
                                        { staticClass: "table-dropdown" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n              更多\n              "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _vm.hasAuth(
                                                "xsddth",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            disabled:
                                                              row.orderStatus !=
                                                              1,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoAudit(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("预审")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "xsddsh",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            disabled: !(
                                                              row.orderStatus ==
                                                                3 ||
                                                              row.orderStatus ==
                                                                4
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoTake(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("提货")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          71252468
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "1200px !important",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("采购信息")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addData,
                    "label-width": "115px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "销售订单号:", prop: "orderNo" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.addData.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "orderNo", $$v)
                                  },
                                  expression: "addData.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.subType != "add"
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联采购单号:",
                                    prop: "linkedOrderNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.addData.linkedOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addData,
                                          "linkedOrderNo",
                                          $$v
                                        )
                                      },
                                      expression: "addData.linkedOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "采购单位类型:",
                                prop: "purchaseComOwnerType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.addData.orderStatus != 1,
                                  },
                                  on: { change: _vm.pTypeChange },
                                  model: {
                                    value: _vm.addData.purchaseComOwnerType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addData,
                                        "purchaseComOwnerType",
                                        $$v
                                      )
                                    },
                                    expression: "addData.purchaseComOwnerType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "渠道客户", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "团购客户", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "采购单位:",
                                prop: "purchaseComId",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    readonly: true,
                                    disabled: _vm.subType == "scan",
                                  },
                                  model: {
                                    value: _vm.addData.purchaseComName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addData,
                                        "purchaseComName",
                                        $$v
                                      )
                                    },
                                    expression: "addData.purchaseComName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      disabled: _vm.addData.orderStatus != 1,
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCompany(
                                          "purchaseComName",
                                          0,
                                          12
                                        )
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "付款方式:",
                                prop: "paymentStyle",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.addData.orderStatus != 1,
                                  },
                                  model: {
                                    value: _vm.addData.paymentStyle,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addData, "paymentStyle", $$v)
                                    },
                                    expression: "addData.paymentStyle",
                                  },
                                },
                                _vm._l(_vm.payWay, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订单日期:",
                                prop: "placeOrderDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                  disabled: _vm.addData.orderStatus != 1,
                                },
                                on: { change: _vm.getFL },
                                model: {
                                  value: _vm.addData.placeOrderDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "placeOrderDate", $$v)
                                  },
                                  expression: "addData.placeOrderDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "申请人:", prop: "applyUser" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: 30,
                                  disabled: _vm.addData.orderStatus != 1,
                                },
                                model: {
                                  value: _vm.addData.applyUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "applyUser", $$v)
                                  },
                                  expression: "addData.applyUser",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("收货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收货单位:",
                                prop: "receiveComId",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { readonly: "" },
                                  model: {
                                    value: _vm.addData.receiveComName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addData,
                                        "receiveComName",
                                        $$v
                                      )
                                    },
                                    expression: "addData.receiveComName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      disabled:
                                        _vm.addData.orderStatus != 1 ||
                                        _vm.addData.purchaseComOwnerType == 2,
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCompany(
                                          "receiveComName",
                                          1,
                                          12,
                                          _vm.addData.purchaseComId
                                        )
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人:",
                                prop: "receiveContacts",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.receiveLoading,
                                    expression: "receiveLoading",
                                  },
                                ],
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.addData.receiveContacts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addData,
                                      "receiveContacts",
                                      $$v
                                    )
                                  },
                                  expression: "addData.receiveContacts",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人电话:",
                                prop: "receivePhone",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.receiveLoading,
                                    expression: "receiveLoading",
                                  },
                                ],
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.addData.receivePhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "receivePhone", $$v)
                                  },
                                  expression: "addData.receivePhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收货地址:",
                                prop: "receiveAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.receiveLoading,
                                    expression: "receiveLoading",
                                  },
                                ],
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.addData.receiveAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "receiveAddress", $$v)
                                  },
                                  expression: "addData.receiveAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("供货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货单位:",
                                prop: "sendOutComId",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { readonly: "" },
                                  model: {
                                    value: _vm.addData.sendOutComName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addData,
                                        "sendOutComName",
                                        $$v
                                      )
                                    },
                                    expression: "addData.sendOutComName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      disabled: _vm.addData.orderStatus != 1,
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCompany(
                                          "sendOutComName",
                                          2,
                                          12
                                        )
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.addData.orderStatus == 3 ||
                      _vm.addData.orderStatus == 4 ||
                      _vm.addData.orderStatus == 5 ||
                      _vm.addData.orderStatus == 8 ||
                      _vm.addData.orderStatus == 9
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "发货库房:",
                                    prop: "sendOutStoreId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.setStore },
                                      model: {
                                        value: _vm.addData.sendOutStoreId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addData,
                                            "sendOutStoreId",
                                            $$v
                                          )
                                        },
                                        expression: "addData.sendOutStoreId",
                                      },
                                    },
                                    _vm._l(_vm.storeList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.storeHouseName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("产品明细"),
                  ]),
                  _vm._v(" "),
                  _vm.addData.orderStatus == 1 &&
                  !(_vm.subType == "audit" || _vm.subType == "scan")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.openPro(1)
                            },
                          },
                        },
                        [_vm._v("添加产品")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "addTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "300",
                    data: _vm.productList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "row-class-name": "iconNone",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      align: _vm.tableConfig.align,
                      prop: "productCode",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: _vm.tableConfig.align,
                      prop: "productName",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      align: _vm.tableConfig.align,
                      prop: "productModel",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      align: _vm.tableConfig.align,
                      prop: "packScaleExpression",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "箱数",
                      align: _vm.tableConfig.align,
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                controls: false,
                                precision: 0,
                                min: 0,
                                max: 99999999,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeChestNum(row)
                                },
                              },
                              model: {
                                value: row.chestNum,
                                callback: function ($$v) {
                                  _vm.$set(row, "chestNum", $$v)
                                },
                                expression: "row.chestNum",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盒数",
                      align: _vm.tableConfig.align,
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "110px" },
                              attrs: {
                                controls: false,
                                precision: 0,
                                min: 0,
                                max: _vm.mixNumP(row),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeChestNum(row)
                                },
                              },
                              model: {
                                value: row.bottleNumOver,
                                callback: function ($$v) {
                                  _vm.$set(row, "bottleNumOver", $$v)
                                },
                                expression: "row.bottleNumOver",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总盒数",
                      prop: "bottleNum",
                      align: _vm.tableConfig.align,
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单价(元/盒)",
                      align: _vm.tableConfig.align,
                      prop: "productPrice",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "金额",
                      align: _vm.tableConfig.align,
                      prop: "packScaleDesc",
                      width: "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(_vm.totalPrice(row)))]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.addData.receiveComType == 3
                    ? _c("el-table-column", {
                        attrs: {
                          label: "返利政策",
                          align: _vm.tableConfig.align,
                          prop: "rebatePolicyId",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { clearable: "" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.flChange(_vm.$el, row)
                                        },
                                        clear: function ($event) {
                                          return _vm.flClear(_vm.$el, row)
                                        },
                                      },
                                      model: {
                                        value: row.rebatePolicyId,
                                        callback: function ($$v) {
                                          _vm.$set(row, "rebatePolicyId", $$v)
                                        },
                                        expression: "row.rebatePolicyId",
                                      },
                                    },
                                    _vm._l(row.flList, function (item, idx) {
                                      return _c("el-option", {
                                        key: idx,
                                        attrs: {
                                          label: item.desc,
                                          value: item.rebateId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3273763887
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subType == "take"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "提货数量",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "180",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: _vm.maxNum(row),
                                    },
                                    model: {
                                      value: row.extractNum,
                                      callback: function ($$v) {
                                        _vm.$set(row, "extractNum", $$v)
                                      },
                                      expression: "row.extractNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3536726483
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.addData.orderStatus == 4 ||
                  _vm.addData.orderStatus == 5 ||
                  _vm.addData.orderStatus == 8 ||
                  _vm.addData.orderStatus == 9
                    ? _c("el-table-column", {
                        attrs: {
                          label: "已提货数量",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [_vm._v(_vm._s(row.hasTakeNum))]
                              },
                            },
                          ],
                          null,
                          false,
                          990187880
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subType == "take"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "库存(箱)",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [_vm._v(_vm._s(row.storeInventory))]
                              },
                            },
                          ],
                          null,
                          false,
                          2107683722
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !(_vm.subType == "audit" || _vm.subType == "scan") &&
                  _vm.addData.orderStatus == 1
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.addData.receiveComType == 3 &&
                                  scope.row.rebatePolicyId
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toogleExpand(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看赠品")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoSc(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3283992581
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "1" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "el-table",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "1px",
                                },
                                attrs: {
                                  size: "mini",
                                  data: props.row.orderGiftDetailDTOS,
                                  border: _vm.tableConfig.border,
                                  stripe: _vm.tableConfig.stripe,
                                  fit: "",
                                  "highlight-current-row": "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "赠品行",
                                    type: "index",
                                    align: _vm.tableConfig.align,
                                    width: "90",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "赠品编码",
                                    prop: "productCode",
                                    align: _vm.tableConfig.align,
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "赠品名称",
                                    prop: "productName",
                                    align: _vm.tableConfig.align,
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单品数量",
                                    prop: "bottleNum",
                                    align: _vm.tableConfig.align,
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单价",
                                    prop: "prodPrice",
                                    align: _vm.tableConfig.align,
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    prop: "totalPrice",
                                    align: _vm.tableConfig.align,
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "countWrap" }, [
                _c("div", { staticStyle: { "min-width": "250px" } }, [
                  _vm._v("总金额：" + _vm._s(_vm.totalMoney)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "大写金额：" + _vm._s(_vm._f("chnMoney")(_vm.totalMoney))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.addData.receiveComType == 3
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "20px" } },
                            [_vm._v("酒劵使用")]
                          ),
                          _vm._v(" "),
                          _vm.addData.orderStatus == 1 &&
                          !(_vm.subType == "audit" || _vm.subType == "scan")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPro(2)
                                    },
                                  },
                                },
                                [_vm._v("添加赠品")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "max-height": "300",
                            data: _vm.zpList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "row-class-name": "iconNone",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: _vm.tableConfig.align,
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              align: _vm.tableConfig.align,
                              prop: "productCode",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              align: _vm.tableConfig.align,
                              prop: "productName",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              align: _vm.tableConfig.align,
                              prop: "productModel",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              align: _vm.tableConfig.align,
                              prop: "packScaleExpression",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "箱数",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                          min: 0,
                                          max: 99999999,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChestNum(row)
                                          },
                                        },
                                        model: {
                                          value: row.chestNum,
                                          callback: function ($$v) {
                                            _vm.$set(row, "chestNum", $$v)
                                          },
                                          expression: "row.chestNum",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3987246532
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "盒数",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                          min: 0,
                                          max: _vm.mixNumP(row),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChestNum(row)
                                          },
                                        },
                                        model: {
                                          value: row.bottleNumOver,
                                          callback: function ($$v) {
                                            _vm.$set(row, "bottleNumOver", $$v)
                                          },
                                          expression: "row.bottleNumOver",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1336253910
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "总盒数",
                              prop: "bottleNum",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "单价(元酒劵/盒)",
                              align: _vm.tableConfig.align,
                              prop: "winePrice",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "金额",
                              align: _vm.tableConfig.align,
                              prop: "packScaleDesc",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [_vm._v(_vm._s(_vm.totalWine(row)))]
                                  },
                                },
                              ],
                              null,
                              false,
                              1315556519
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScZp(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3604119748
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "countWrap" }, [
                        _c("div", { staticStyle: { "min-width": "350px" } }, [
                          _vm._v("可使用酒劵金额(元)：" + _vm._s(_vm.totalZp)),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "本次使用酒券金额(元)：" + _vm._s(_vm.totalInUse)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("备注")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0" } },
                [
                  _vm.subType == "audit" ||
                  _vm.subType == "scan" ||
                  _vm.addData.orderStatus != 1
                    ? _c("span", [_vm._v(_vm._s(_vm.addData.remark))])
                    : _c("el-input", {
                        attrs: {
                          disabled: _vm.addData.orderStatus != 1,
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                          placeholder: "",
                          autosize: { minRows: 4, maxRows: 4 },
                        },
                        model: {
                          value: _vm.addData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addData, "remark", $$v)
                          },
                          expression: "addData.remark",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("状态")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0" } },
                [
                  _vm.subType == "audit" || _vm.subType == "scan"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("orderStatusText")(_vm.addData.orderStatus)
                          )
                        ),
                      ])
                    : _c(
                        "el-select",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.addData.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.addData, "orderStatus", $$v)
                            },
                            expression: "addData.orderStatus",
                          },
                        },
                        _vm._l(_vm.orderStatus, function (item) {
                          return _c("el-option", {
                            key: item.figure,
                            attrs: { label: item.name, value: item.figure },
                          })
                        }),
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addData.orderStatus > 1
                ? _c("div", { staticClass: "title" }, [_vm._v("审核日志")])
                : _vm._e(),
              _vm._v(" "),
              _vm.addData.orderStatus > 1
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        loaidng: _vm.auditLoading,
                        data: _vm.auditList,
                        border: "",
                        "max-height": "300",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "auditNode",
                          label: "审核节点",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("auditNodeName")(row.auditNode)
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4089958081
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "auditUserAccount",
                          label: "审核人",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "auditNode",
                          label: "审核结果",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      row.auditStatus == 1 ? "通过" : "不通过"
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1704786847
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "auditOpinion",
                          label: "审核意见",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "auditOpinion",
                          label: "审核时间",
                          width: "170",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.createTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2280372158
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subType == "audit"
                ? _c("div", { staticClass: "title" }, [_vm._v("预审")])
                : _vm._e(),
              _vm._v(" "),
              _vm.subType == "financial"
                ? _c("div", { staticClass: "title" }, [_vm._v("到款审核")])
                : _vm._e(),
              _vm._v(" "),
              _vm.subType == "audit" || _vm.subType == "financial"
                ? _c(
                    "div",
                    { staticStyle: { padding: "0 0 15px 0" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "auditForm",
                          attrs: {
                            model: _vm.auditForm,
                            "label-width": "85px",
                            "label-suffix": ":",
                            rules: _vm.auditRule,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审核结果",
                                prop: "handlerResult",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.auditForm.handlerResult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditForm,
                                        "handlerResult",
                                        $$v
                                      )
                                    },
                                    expression: "auditForm.handlerResult",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("通过"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("不通过"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "审核意见", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "100",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.auditForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.auditForm, "remark", $$v)
                                  },
                                  expression: "auditForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.detailShow
        ? _c("detail", {
            attrs: { visible: _vm.detailShow, id: _vm.pid },
            on: { close: _vm.detailClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.auditShow
        ? _c("audit", {
            attrs: { visible: _vm.auditShow, id: _vm.pid },
            on: { change: _vm.audit, close: _vm.auditClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.financialShow
        ? _c("financial", {
            attrs: { visible: _vm.financialShow, id: _vm.pid },
            on: { change: _vm.financial, close: _vm.financialClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.takeShow
        ? _c("take", {
            attrs: { visible: _vm.takeShow, id: _vm.pid },
            on: { change: _vm.take, close: _vm.takeClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              canChange: _vm.factorycanChange,
              companyType: _vm.factoryType,
              isEnable: _vm.isEnable,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              inOutType: _vm.inOutType,
              companyType: _vm.companyType,
              purchaseType: _vm.addData.purchaseComOwnerType,
              orgId: _vm.orgId,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tgShow
        ? _c("tgSelectDialog", {
            attrs: { visible: _vm.tgShow },
            on: { change: _vm.setTg, close: _vm.tgClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              isCode: "1",
              proSelVisible: _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }