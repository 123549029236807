"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProductList = getProductList;
exports.getShUnit = getShUnit;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取产品列表
function getProductList(data) {
  return (0, _request.default)({
    url: '/api-basic/productArchives/list',
    method: 'post',
    data: data
  });
}

//获取收货单位
function getShUnit(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/list',
    method: 'post',
    params: param
  });
}