"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _flow = require("@/api/warehouse/flow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'table-packset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    showflow: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    num: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      flowList: [],
      flowlistLoading: false,
      btn_loading: false,
      flowTableKey: 'pid',
      form: {}
    };
  },
  mounted: function mounted() {
    var that = this;
    console.log('showflowshowflowshowflow', this.showflow);
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.flowlistLoading = true;
      (0, _flow.getFlowList)(this.num).then(function (res) {
        _this.flowlistLoading = false;
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.flowList = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.flowList = [];
          }
        }
      });
    },
    submitForm: function submitForm(type) {},
    handleClose: function handleClose() {
      this.form = {};
      this.$emit('handleClose');
    }
  }
};
exports.default = _default2;