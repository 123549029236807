var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cardBox" },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("div", { staticClass: "hyyy-top-tit" }, [
                        _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                          _c("span", {
                            staticStyle: {
                              "font-weight": "400",
                              color: "#333",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$route.meta.title),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hyyy-top-tit-right" }),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { staticStyle: { margin: "0" } }),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "formInline",
                          staticClass: "searchForm demo-form-inline",
                          attrs: {
                            model: _vm.formInline,
                            inline: true,
                            gutter: 10,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "属性名称：",
                                        prop: "propName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "filter-item",
                                        attrs: { placeholder: "属性名称" },
                                        model: {
                                          value: _vm.formInline.propName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formInline,
                                              "propName",
                                              $$v
                                            )
                                          },
                                          expression: "formInline.propName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "hyyy-head-btn" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "normalBtn",
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.formInline.current = 1
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card box-car" },
                    [
                      _c(
                        "div",
                        { staticClass: "btnWrap" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addOrUpdateHandle()
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          attrs: {
                            data: _vm.dataList,
                            "element-loading-text": "Loading",
                            fit: "",
                            "header-cell-style": {
                              "background-color": "#fafafa",
                              color: "rgba(0, 0, 0,0.85)",
                              "font-weight": "500",
                            },
                            "row-style": {
                              color: "rgba(0, 0, 0,0.65)",
                              "font-weight": "400",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "属性ID", prop: "propId" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "属性名称", prop: "propName" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "属性值" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.prodPropValues,
                                    function (item) {
                                      return _c(
                                        "el-tag",
                                        { key: item.valueId },
                                        [_vm._v(_vm._s(item.propValue))]
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.addOrUpdateHandle(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText red",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteHandle(
                                              scope.row.propId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.formInline.current,
                          limit: _vm.formInline.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.formInline, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.formInline, "size", $event)
                          },
                          pagination: _vm.fetchData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.addOrUpdateVisible
        ? _c("add-or-update", {
            ref: "addOrUpdate",
            on: { refreshDataList: _vm.fetchData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }