"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchNumAbolish = batchNumAbolish;
exports.batchNumEffective = batchNumEffective;
exports.batchNumFreeze = batchNumFreeze;
exports.inOrOutAbolish = inOrOutAbolish;
exports.inOrOutAbolishQuery = inOrOutAbolishQuery;
exports.inOrOutEffective = inOrOutEffective;
exports.inOrOutEffectiveQuery = inOrOutEffectiveQuery;
exports.inOrOutFreeze = inOrOutFreeze;
exports.inOrOutFreezeQuery = inOrOutFreezeQuery;
exports.packageOrderNumAbolish = packageOrderNumAbolish;
exports.singleCodeAbolish = singleCodeAbolish;
exports.singleCodeEffective = singleCodeEffective;
exports.singleCodeFreeze = singleCodeFreeze;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 按生产批次废除
 * @param {*} data 
 */
function batchNumAbolish(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/batchNumAbolish',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 按包材订单号废除
 * @param {*} data 
 */
function packageOrderNumAbolish(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/packageOrderNumAbolish',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码废除
 * @param {*} data 
 */
function singleCodeAbolish(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/singleCodeAbolish',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 生产批次恢复
 * @param {*} data 
 */
function batchNumEffective(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/batchNumEffective',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码恢复
 * @param {*} data 
 */
function singleCodeEffective(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/singleCodeEffective',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 生产批次冻结
 * @param {*} data 
 */
function batchNumFreeze(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/batchNumFreeze',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 单码冻结
 * @param {*} data 
 */
function singleCodeFreeze(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/singleCodeFreeze',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 出入库订单废除
 * @param {*} data 
 */
function inOrOutAbolish(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutAbolish',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 废除出入库单查询
 * @param {*} data 
 */
function inOrOutAbolishQuery(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutAbolishQuery',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 出入库订单恢复
 * @param {*} data 
 */
function inOrOutEffective(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutEffective',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 恢复出入库单查询
 * @param {*} data 
 */
function inOrOutEffectiveQuery(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutEffectiveQuery',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 出入库订单冻结
 * @param {*} data 
 */
function inOrOutFreeze(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutFreeze',
    method: 'put',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 冻结出入库单查询
 * @param {*} data 
 */
function inOrOutFreezeQuery(data) {
  return (0, _request.default)({
    url: '/api-mark/numStateItem/inOrOutFreezeQuery',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}