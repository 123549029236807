"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _printDialog = _interopRequireDefault(require("@/components/printDialog"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _apartDialog = _interopRequireDefault(require("./components/apartDialog"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _index = require("@/api/warehouse/checkout/index");
var _dictionary = require("@/api/systems/dictionary");
var _factory = require("@/api/basic/factory");
var _basic = require("@/api/mark/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// ???

var qrcode = '';
var TypeDataComs = {
  djly: [],
  djzt: [],
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    factorySelectDialog: _factorySelectDialog.default,
    companySelectDialog: _companySelectDialog.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    apartDialog: _apartDialog.default,
    printDialog: _printDialog.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djztText: function djztText(val) {
      var name = '';
      TypeDataComs.djzt.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: 'success',
        8: 'warning',
        9: 'warning',
        10: 'warning',
        11: 'warning',
        12: 'danger'
      };
      return obj[val];
    }
  },
  data: function data() {
    return {
      billNo: '',
      typeNum: 1,
      inUseName: '',
      searchSendOutComName: '',
      searchSaleOrgName: '',
      searchInOrgName: '',
      productName: '',
      handleShow: false,
      printShow: false,
      factoryShow: false,
      companyShow: false,
      apartShow: false,
      factorycanChange: true,
      companycanChange: true,
      factoryType: '',
      companyType: '',
      orgId: '',
      tableData: [],
      searchForm: {
        // billSaleQuery: {
        //   inOrgId: '',
        //   isSplitBill: '',
        //   outStoreId: '',
        //   saleOrgId: '',
        //   relateNo: '',
        //   outOrgId: ''
        // },
        billOutTransQuery: {
          inOrgId: '',
          outOrgId: '',
          outStoreId: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '202',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      djly: [],
      djzt: [],
      storeList: [],
      searchStoreList: [],
      // a123
      codetotal: 0,
      billType: 202,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      SyncVisible: false,
      // 同步
      Syncform: {
        time: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        startRow: 0
      },
      Syncrules: {
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      sloading: false
    };
  },
  activated: function activated() {
    this.getList();
    // 单据来源
    this.getDirSel('DJLY', 'djly');
    // 单据状态
    this.getDirSel('DJZT', 'djzt');
    // this.getstoreTypeInfoList(4, 'source')
    // this.getstoreTypeInfoList(7, 'status')
    // this.getstoreTypeInfoList(103, 'factory')
    // TypeDataComs = this.TypeDataComs
    this.getStoreList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    goPrint: function goPrint(row) {
      this.authId('printbtn', this.$route.meta.authList);
      this.billNo = row.billNo;
      this.printShow = true;
    },
    printClose: function printClose() {
      this.billNo = '';
      this.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    getStoreList: function getStoreList() {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
            that.searchStoreList = response.data.data;
          } else {
            that.storeList = [];
            that.searchStoreList = [];
          }
        }
      });
    },
    /**
     * 工厂选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 1 ：企业, 3：工厂,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType) {
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      this.factoryShow = true;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'sendOutComName':
          this.addData.sendOutComId = val.pid;
          this.addData.sendOutComType = val.companyType == 3 ? 1 : 2;
          break;
        case 'searchSendOutComName':
          this.searchForm.billOutTransQuery.outOrgId = val.pid;
          this.searchStoreList = this.storeList.filter(function (item) {
            return item.orgId == val.pid;
          });
          this.searchForm.billOutTransQuery.outStoreId = '';
          break;
        case 'searchInOrgName':
          this.searchForm.billOutTransQuery.inOrgId = val.pid;
          break;
        case 'searchSaleOrgName':
          this.searchForm.billOutTransQuery.saleOrgId = val.pid;
          break;
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factoryShow = false;
    },
    handleFilter: function handleFilter(_type) {
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchSendOutComName = '';
      this.searchSaleOrgName = '';
      this.searchInOrgName = '';
      this.productName = '';
      this.searchStoreList = this.storeList;
      this.searchForm = {
        billOutTransQuery: {
          inOrgId: '',
          outOrgId: '',
          outStoreId: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: '202',
        endTime: '',
        productId: '',
        startTime: '',
        current: 1,
        size: 20
      };
      this.getList();
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 0 ：企业 1：一级经销商  "" : 全部
     * @param {*} orgId  采购单位id 设置收货单位时使用
     *
     */
    openCompany: function openCompany(name, status, companyType, orgId, isEnable) {
      this.inUseName = name;
      this.companycanChange = status;
      this.companyType = companyType;
      this.orgId = orgId;
      this.isEnable = isEnable;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchInOrgName':
          this.searchForm.billOutTransQuery.inOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryPage)(JSON.parse(JSON.stringify(this.searchForm))).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        if (!this.searchForm.factoryCode) {
          this.sellineList = JSON.parse(JSON.stringify(this.lineList));
          this.selteamList = JSON.parse(JSON.stringify(this.teamList));
        } else {
          this.sellineList = [];
          this.selteamList = [];
        }
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })

      this.sellineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.selteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })
      this.scanlineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.scanteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.$refs.insertMain.show(1, this.billType);
    },
    doUpdate: function doUpdate(row) {
      this.authId('updatebtn', this.$route.meta.authList);
      this.$refs.insertMain.upDateShow(row);
    },
    goApart: function goApart(row) {
      this.authId('splitbtn', this.$route.meta.authList);
      this.billNo = row.billNo;
      this.apartShow = true;
    },
    apartChange: function apartChange() {
      this.apartClose();
      this.getList();
    },
    apartClose: function apartClose() {
      this.billNo = '';
      this.apartShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    del: function del(row) {
      var _this3 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('deletebtn', _this3.$route.meta.authList);
        (0, _index.InfoDel)(row.pid).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this3.$message.error(data.msg);
            return false;
          }
          _this3.$message({
            message: '保存成功',
            type: 'success'
          });
          _this3.getList();
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this4 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var data = JSON.parse(JSON.stringify(this.searchForm));
      delete data.current;
      delete data.size;
      (0, _index.excelOut)(data).then(function (res) {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '调拨出库.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 178,
          height: 178,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 同步
    SyncSubmit: function SyncSubmit() {
      var _this5 = this;
      this.$refs['Syncform'].validate(function (valid) {
        if (valid) {
          _this5.sloading = true;
          (0, _index.allotOrderAll)(_this5.Syncform).then(function (res) {
            if (res.data.code == 200) {
              _this5.$message.success(res.data.msg);
            } else {
              _this5.$message.error(res.data.msg);
            }
            _this5.getList();
            _this5.sloading = false;
            _this5.SyncClose();
          }).catch(function (err) {
            console.log(err);
            _this5.sloading = false;
            _this5.SyncClose;
          });
        } else {
          return false;
        }
      });
    },
    SyncClose: function SyncClose() {
      this.SyncVisible = false;
      this.$refs['Syncform'].resetFields();
    }
  }
};
exports.default = _default;