"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addProductRelation = addProductRelation;
exports.fetchProdByTaskNo = fetchProdByTaskNo;
var _request = _interopRequireDefault(require("@/utils/request"));
// NC同步单据
function fetchProdByTaskNo(params) {
  return (0, _request.default)({
    url: "/storage-center/third/getNCSaleOutTradeBillByNCNO",
    method: 'get',
    params: params
  });
}
// 新增NC产品与系统产品关联关系
function addProductRelation(data) {
  return (0, _request.default)({
    url: '/api-basic/productArchives/addProductRelation',
    method: 'post',
    data: data
  });
}