"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _echarts = _interopRequireDefault(require("echarts"));
/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/

!function (e, n) {
  "function" == typeof define && define.amd ? define(["exports", "echarts"], n) : "object" == (typeof exports === "undefined" ? "undefined" : (0, _typeof2.default)(exports)) && "string" != typeof exports.nodeName ? n(exports, require("echarts")) : n({}, e.echarts);
}(void 0, function (e, n) {
  var a = function a(e) {
    "undefined" != typeof console && console && console.error && console.error(e);
  };
  n ? n.registerMap ? n.registerMap("台湾", {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      properties: {
        adm1_code: "TWN-1156",
        OBJECTID_1: 1250,
        diss_me: 1156,
        adm1_cod_1: "TWN-1156",
        iso_3166_2: "TW-KHH",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Kaohsiung City",
        name: "高雄市",
        name_local: null,
        type: "Zhixiashi",
        type_en: "Special Municipality",
        code_local: null,
        code_hasc: "TW.KH.KC",
        note: "Established on 2010-12-25 by merging Kaohsiung City (old) with Kaohsiung County",
        hasc_maybe: null,
        region: "Special Municipalities",
        region_cod: null,
        provnum_ne: 12,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "KH",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 14,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 20070571,
        woe_label: null,
        woe_name: "Kaohsiung City",
        latitude: 23.0157,
        longitude: 120.609,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 6724652,
        gn_name: "Kao-hsiung Hsien",
        gns_id: -2632385,
        gns_name: "Gaoxiong Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.KHQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW02",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@PiWckQIUH[jYreVuBQ¹[q[][{cGiRiUcw[GqRW@aisQJyX_nmJc]C@@gHaAomhy~yKqi¥p_qs}SÉHMcIqSyDqAk×@sTuDyÓSHK@MEO@¥º¢­KtO^@@KVil|[^KbPbJ^KhUjevcG`BVLZBPCJIFIJEPUdENO¢pZBC^}fOºOFG`J\\TrRp`ThVdjtpnrfztr|º¬Rp@@GnKbBb\\ZtEnF tbflz^bd\\prÐbElObiDM"],
        encodeOffsets: [[123924, 24e3]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1158",
        OBJECTID_1: 1251,
        diss_me: 1158,
        adm1_cod_1: "TWN-1158",
        iso_3166_2: "TW-PIF",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Pingtung",
        name: "屏东县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.PT",
        note: "Contains the Island Liuch'iu Y?, which should belong to Kaohsiung Shi Special Municipality",
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 11,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "PT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347340,
        woe_label: null,
        woe_name: "Pingtung County",
        latitude: 22.5344,
        longitude: 120.661,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1670479,
        gn_name: "Pingtung",
        gns_id: -2635733,
        gns_name: "Pingdong Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.PIF",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW18",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@BCB@@CLMCC@CSKCAKIWQ@@@@EVJT@@VZP@FD"], ["@@z}ngpbBhG@@\\mFchGlYU£@eOa]eS{Ki_Qo[mWuE©HoPiHs@eVcdgS[iWO[C_V[ZKX_@iDiXkdaadBFƳKuSiYQ_[B{JAicpsf}Z{NCoQQYF[XAPJrkAR@NBX@FXRZDTÙƶMTiraOTnWNIBGFG\\CHaVbcVe^OJCBÔCzSv@tlØBCrTzJrNdÊG~Trt`¦orjzL"]],
        encodeOffsets: [[[123261, 22893]], [[123632, 23437]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1160",
        OBJECTID_1: 290,
        diss_me: 1160,
        adm1_cod_1: "TWN-1160",
        iso_3166_2: "TW-TNN",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 7,
        name_en: "Tainan City",
        name: "台南市",
        name_local: null,
        type: "Zhixiashi",
        type_en: "Special Municipality",
        code_local: null,
        code_hasc: "TW.TW.TN",
        note: "Established on 2010-12-25 by merging Tainan City (old) with Tainan County",
        hasc_maybe: null,
        region: "Special Municipalities",
        region_cod: null,
        provnum_ne: 6,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TN",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28751581,
        woe_label: null,
        woe_name: "Tainan City",
        latitude: 23.0464,
        longitude: 120.182,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1668354,
        gn_name: "Tainan",
        gns_id: -2637871,
        gns_name: "Tainan Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TNQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW21",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@CIQNM^DJD@HGDMROAE"], ["@@Uq@B^ BAD@KcAI"], ["@@A@\\FB@M_y"], ["@@AQ@@DDAEw"], ["@@M\xA5L_dY\\@jRhD\\G\x82EQo\x91\xAB\x8D\xB9q{ysqeomisUcSgo_qQ[S_I\x81H\x7FE\xB9PeP]~ADoY@@GlMZ]\\fXTlGfqFIKGYMQY@IPDrER_NQOMSUEUREZFXHPXL\\BXHHVIJ\x89R@N~@ERa\\UVF\\TLTFLRG\\IVHVJZRTCPDPZ\x80LLTF[PL^hM\x86IxKpTTblZtfdbp`~Z\xB6T\x82BxO`QP]Lc\x8E\x87Hu"]],
        encodeOffsets: [[[122927, 23603]], [[122929, 23701]], [[122943, 23747]], [[122967, 23828]], [[123431, 23863]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1161",
        OBJECTID_1: 1252,
        diss_me: 1161,
        adm1_cod_1: "TWN-1161",
        iso_3166_2: "TW-HSZ",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Hsinchu City",
        name: "新竹市",
        name_local: null,
        type: "Shi",
        type_en: "Provincial City",
        code_local: null,
        code_hasc: "TW.TW.HS",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 15,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "HH",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 12,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28751582,
        woe_label: null,
        woe_name: "Hsinchu City",
        latitude: 24.7757,
        longitude: 120.959,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1675103,
        gn_name: "Hsinchu",
        gns_id: -2631089,
        gns_name: "Xinzhu Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.HSQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW08",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@AAcGaLU\\_RuR@@J^jRTFLALCLGPJVNL@@W¶ajS^[PYGYQIiEkO[_QgQS"],
        encodeOffsets: [[123864, 25309]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1162",
        OBJECTID_1: 1253,
        diss_me: 1162,
        adm1_cod_1: "TWN-1162",
        iso_3166_2: "TW-HSQ",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Hsinchu",
        name: "新竹县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.HH",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 14,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "HH",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347334,
        woe_label: null,
        woe_name: "Hsinchu County",
        latitude: 24.6748,
        longitude: 121.163,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1675107,
        gn_name: "Hsinchu",
        gns_id: -2631085,
        gns_name: "Xinzhu Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.HSZ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW09",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@ªHdU`}fMbG`MtO\\]GiZYtM`UXU^C^B\\YT_bMZSBaLsMucmD_h[pYlE`Sx]fOQmKaF]]i­£_UFGECCbcxYXI^e\\¡UeDsC{M_FFdJbG\\ETFVHXB\\Ef]Xho`]`aRTRh\\`lPjFRJHZOZ]\\iTµbX@BLPFZFTPVTNTFAJFTRjPX@@XG"],
        encodeOffsets: [[123888, 25547]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1163",
        OBJECTID_1: 1254,
        diss_me: 1163,
        adm1_cod_1: "TWN-1163",
        iso_3166_2: "TW-ILA",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Yilan",
        name: "宜兰县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.IL",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 10,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "IL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 5,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347336,
        woe_label: null,
        woe_name: "Yilan County",
        latitude: 24.5913,
        longitude: 121.644,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1674197,
        gn_name: "Yilan",
        gns_id: -2631998,
        gns_name: "Yilan Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.ILA",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW26",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@B@PAFKFCEO]@IFCD@@INHATJ@BB@@@"], ["@@@KYBWASEMITcJmEgmWCQFSLSFSSOoYwwAWDoGSIOGUCmCKGGCIHKBAG@LÏ]EQig_q@Vµ\\qVSNmbe@cO]C]RaReHKZFf[H`®¤^jE^LbRnDL^JZCZHR\\Wh\\XFnLfL^XfXtV^TCNGX@\\Ndf\\^P¦f¨xpNhZXdX\\^N\\HbVNZSZLTP\\R^nJZMFG@@ikaÑßUaQoKuCuBsZ­A[K[ByZkt[|I"]],
        encodeOffsets: [[[124869, 25450]], [[124808, 25195]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1164",
        OBJECTID_1: 1255,
        diss_me: 1164,
        adm1_cod_1: "TWN-1164",
        iso_3166_2: "TW-KEE",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Keelung City",
        name: "基隆市",
        name_local: null,
        type: "Shi",
        type_en: "Provincial City",
        code_local: null,
        code_hasc: "TW.TW.CL",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 18,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "CL",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 12,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 22695855,
        woe_label: null,
        woe_name: "Keelung City",
        latitude: 25.1131,
        longitude: 121.704,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 6724654,
        gn_name: "Keelung",
        gns_id: 10126902,
        gns_name: "Jilong Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.KEE",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW12",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@ABS\\[CQQK_IgD^WP[XUXMZUZCTXPxVbT@@LEFGLK^IQ"],
        encodeOffsets: [[124684, 25747]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1165",
        OBJECTID_1: 1256,
        diss_me: 1165,
        adm1_cod_1: "TWN-1165",
        iso_3166_2: "TW-MIA",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Miaoli",
        name: "苗栗县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.ML",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 19,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "ML",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347338,
        woe_label: null,
        woe_name: "Miaoli County",
        latitude: 24.5253,
        longitude: 120.942,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1671968,
        gn_name: "Miaoli",
        gns_id: -2634238,
        gns_name: "Miaoli Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.MIA",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW15",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@dHBBb^_p_g^WFeA[GWEUFSH[IaEc`E|NtDfC¢Vf[J]ZWdwDa[QMgeMi@_Sc_mUuSakPefmRFiWGsgSCnqHyZ³qfcd¤@@xzN\\HpPfnNnLRtNNPJRJR`^dXlJxGGfVZ\\ZPjFL\\NBH@@vQ`QV[bK"],
        encodeOffsets: [[123845, 25304]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1166",
        OBJECTID_1: 1257,
        diss_me: 1166,
        adm1_cod_1: "TWN-1166",
        iso_3166_2: "TW-TPE",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Taipei City",
        name: "台北市",
        name_local: null,
        type: "Zhixiashi",
        type_en: "Special Municipality",
        code_local: null,
        code_hasc: "TW.TP.TC",
        note: "Incorrectly labeled as Taipei County in previous versions",
        hasc_maybe: null,
        region: "Special Municipalities",
        region_cod: null,
        provnum_ne: 5,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TW",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: "TW03",
        fips_alt: "TW04",
        woe_id: 20070568,
        woe_label: null,
        woe_name: "Taipei City",
        latitude: 25.0904,
        longitude: 121.559,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1668338,
        gn_name: "T'ai-pei Shih",
        gns_id: -2637885,
        gns_name: "Taibei Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TPE",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW03",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@mBeF[X]^k`_pNtIlmh[^LTNRP`\\\\nVP`N\\GROEWDYLUNYR^WN_MmBcdQ\\WM]{_M"],
        encodeOffsets: [[124522, 25570]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1167",
        OBJECTID_1: 1258,
        diss_me: 1167,
        adm1_cod_1: "TWN-1167",
        iso_3166_2: "TW-TPQ",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "New Taipei City",
        name: "新北市",
        name_local: null,
        type: "Zhixiashi",
        type_en: "Special Municipality",
        code_local: null,
        code_hasc: "TW.TW.TP",
        note: "Incorrectly labeled as Taipei City in previous versions; established on 2010-12-25 by upgrading from Taipei County",
        hasc_maybe: null,
        region: "Special Municipalities",
        region_cod: null,
        provnum_ne: 9,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TP",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 15,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: "TW03",
        fips_alt: null,
        woe_id: 20070569,
        woe_label: null,
        woe_name: "Taipei County",
        latitude: 24.911,
        longitude: 121.646,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1665148,
        gn_name: "Taipei",
        gns_id: -2641168,
        gns_name: "Xinbei Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TPQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW23",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@\\uHDVFXBPC@EGIGK@OHGRK@@aSwUWODSVYNYVW\\WXO\x92]hC`JRLDR[\\ATB\x96\u014E{XMKYCUFcT}T[PEvF\\AbG\\MPQ\x83]@@EHYNmI\x81][Q\x95OKSTYMYaU[G]MW[WcgYoM\xA7w\xA5e]Oe[Mc@[HWDM]SsUeW]WeKmKQf\x85|IhGdZZJf\x7F\x98gNyCgTJpGdgdIdJjAj\\^\xA6ZZZH\\C^gtaP]FYTcVqV_`@@\x98NdNpdNFRDX@\\GNSJQLKZJ[hkjWNFRNLPFFFPdXjtj\x8Ab\x96R\x94IdW\x8E\xA1", "@@MQKS\\]ngJkMs`ol_^]\\WfEnA`N|N^[XcRAdNnM`]XQMZKVCZFXQP[H_MUOm[[O_"], ["@@OIATRI"]],
        encodeOffsets: [[[124556, 25829], [124391, 25743]], [[125010, 26246]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1168",
        OBJECTID_1: 1259,
        diss_me: 1168,
        adm1_cod_1: "TWN-1168",
        iso_3166_2: "TW-TAO",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Taoyuan",
        name: "桃园市",
        name_local: null,
        type: "Shi",
        type_en: "City",
        code_local: null,
        code_hasc: "TW.TW.TY",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 16,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TY",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347345,
        woe_label: null,
        woe_name: "Taoyuan County",
        latitude: 24.961,
        longitude: 121.227,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1667900,
        gn_name: "Taoyuan",
        gns_id: -2638324,
        gns_name: "Taoyuan Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TAO",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW25",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@zDhMIeYYHcJg{ReWEg[XQ[YGYD]ICKePw^_TkFoZg\\C`dnNvKtAbYTaNS`[Z]A]DWV_VsNYZHj[^sP_NaHeN_~cV©GWH¤vzxlÐjbT\\VdRVÜZ`_rUdUZS^EbOhsD]G[YY¥Y[]BiIiJchcHcIohS"],
        encodeOffsets: [[124283, 25442]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1169",
        OBJECTID_1: 1260,
        diss_me: 1169,
        adm1_cod_1: "TWN-1169",
        iso_3166_2: "TW-CHA",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Changhua",
        name: "彰化县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.CG",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 20,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "CG",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 8,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 20070572,
        woe_label: null,
        woe_name: "Changhua County",
        latitude: 24.0016,
        longitude: 120.464,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1679134,
        gn_name: "Changhua",
        gns_id: -2627040,
        gns_name: "Zhanghua Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.CHA",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW05",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@@YaIIUF[O_Me@iImEPk\\W^IlSGY]IQOSKGCPm@jÙP`sLyDëS{`@@FJNrHVxpPTTx`vn´T`XVTLPHLJFVHRh^JVDHTTR nM`YTkHubeYbYXaP[@]JW`ClY@I"],
        encodeOffsets: [[123564, 24597]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1170",
        OBJECTID_1: 292,
        diss_me: 1170,
        adm1_cod_1: "TWN-1170",
        iso_3166_2: "TW-CYQ",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 7,
        name_en: "Chiayi",
        name: "嘉义县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.CH",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 22,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "CH",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 7153409,
        woe_label: null,
        woe_name: "Chiayi County",
        latitude: 23.4465,
        longitude: 120.26,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1678835,
        gn_name: "Chiayi",
        gns_id: -2627340,
        gns_name: "Jiayi Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.CYQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW07",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@MICHRVHRHJFC@GKMIU"], ["@@EG@NPZHDCMMU"], ["@@@@@^DA@SCG"], ["@@CKAB@ACPHC@@B@"], ["@@CIGLADLC@AB@"], ["@@YK^h¬¤¢RD§a_WY[YWOaY"], ["@@¤_\\DPfWR[rFP`J@XBdD@pEtQ`GhSAeWeJaTmDiHY|IĆAHAÏq[oacy]kaesmEsF[YAaLaHm@@F[HgCiQ[@cZK`N¦GvKdO^_RwPAµS}Yo_casekYSaoSwLJgN^BZJJCEXQKMDS@@XTh\\Bj@[_zNZ@NWJDLNPFR`fCA~F@BBHap@^VNdPTjFvjbP^\\ÀvN¤RtBjafi", "@@]]FgpOvazItRdDXPSrS\\_PiN}CK"]],
        encodeOffsets: [[[122989, 23871]], [[122997, 23916]], [[123016, 23949]], [[123022, 23983]], [[123017, 24005]], [[122901, 24001]], [[123426, 24170], [123278, 24074]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1171",
        OBJECTID_1: 1261,
        diss_me: 1171,
        adm1_cod_1: "TWN-1171",
        iso_3166_2: "TW-CYI",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Chiayi City",
        name: "嘉义市",
        name_local: null,
        type: "Shi",
        type_en: "Provincial City",
        code_local: null,
        code_hasc: "TW.TW.CS",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 3,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "CH",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 11,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28751583,
        woe_label: null,
        woe_name: "Chiayi City",
        latitude: 23.4822,
        longitude: 120.441,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1678834,
        gn_name: "Chiayi",
        gns_id: -2627341,
        gns_name: "Jiayi Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.CYI",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW06",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@yJuboPEh^^L~DjM`OT[TqWOcCsQ"],
        encodeOffsets: [[123346, 24009]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1172",
        OBJECTID_1: 1262,
        diss_me: 1172,
        adm1_cod_1: "TWN-1172",
        iso_3166_2: "TW-HUA",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Hualien",
        name: "花莲县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.HL",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 13,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "HL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347335,
        woe_label: null,
        woe_name: "Hualien County",
        latitude: 23.7743,
        longitude: 121.381,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1674502,
        gn_name: "Hualien",
        gns_id: -2631692,
        gns_name: "Hualian Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.HUA",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW10",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@h`Rj^FÐKH@@@FEFIFIBGKOkiq{cYIMDOEIscSQUiUyI_@aNcLOJGHI@UGY]gEYAmY³o·ŝcȉ@@[He]eNqDo[qYeUmQ{]wcYkBoGkq[J²kLqFgbmT}VezItARUvqfiZG\\JVlRXdOjpXXTMlLTL\\h\\zLzBhbL|^d|TbXLdZJ|GrIhW\\O\\lBZTr^|X~LDvM`OXRvh~BZKbgXUTA\\TtPjRAXKn@@VNJNZLX\\JdXbzbRlD^dbTMrU¶[Ur@"],
        encodeOffsets: [[124523, 24915]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1173",
        OBJECTID_1: 1263,
        diss_me: 1173,
        adm1_cod_1: "TWN-1173",
        iso_3166_2: "TW-NAN",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Nantou",
        name: "南投县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.NT",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 17,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "NT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347339,
        woe_label: null,
        woe_name: "Nantou County",
        latitude: 23.7977,
        longitude: 120.988,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1671564,
        gn_name: "Nantou",
        gns_id: -2634645,
        gns_name: "Nantou Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.NAN",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW16",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@LmBWiQsO[SBVShWLaAY}gQuPWN_CuKW}]{SqAYkP[X[JgHqI{YKcaW{S]cK{gayAyKg[K[KSNkWSoWCNajkPaFGBąB{JGZCjSnIbXfBfgT_HsRoFSXEuMkR_bHhNdGxHrPbOtTLRP^JHZkT]J[XOlF Jn@jNfP`E\\JVbJ@Z`@ÌQbDfbz¦^|rVIpBlvXNoZFThf\\rAhN~vfTdFbpBpF`dBdG¨G"],
        encodeOffsets: [[124256, 24818]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1174",
        OBJECTID_1: 1264,
        diss_me: 1174,
        adm1_cod_1: "TWN-1174",
        iso_3166_2: "TW-TXG",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Taichung City",
        name: "台中市",
        name_local: null,
        type: "Zhixiashi",
        type_en: "Special Municipality",
        code_local: null,
        code_hasc: "TW.TW.TG",
        note: "Established on 2010-12-25 by merging Taichung City (old) with Taichung County",
        hasc_maybe: null,
        region: "Special Municipalities",
        region_cod: null,
        provnum_ne: 23,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TH",
        area_sqkm: 0,
        sameascity: 1,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 13,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28751584,
        woe_label: null,
        woe_name: "Taichung City",
        latitude: 24.238,
        longitude: 120.966,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1668392,
        gn_name: "Taichung",
        gns_id: -2637831,
        gns_name: "Taizhong Shi",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TXQ",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW19",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@DhTHtjXEnQfelObvTnVd``Tj@fNNh\\RFDHVE\\GEeLYfGbQ^Q^DdPf@nacaC]QkyaWaIcW[YKIMUM@@§HcHcA_oEoAacEeS}ugMqBe[SgYEpWMkuoAJqU]{y¥eaaCËR_@@JkZ_DIX@^O\\WbaZZafGvSl_ZmN@@FDFXZTPlV²VZf¨¨£dcre´zYrGm"],
        encodeOffsets: [[123754, 24876]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1176",
        OBJECTID_1: 1265,
        diss_me: 1176,
        adm1_cod_1: "TWN-1176",
        iso_3166_2: "TW-YUN",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Yunlin",
        name: "云林县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.YL",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 21,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "YL",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347346,
        woe_label: null,
        woe_name: "Yunlin County",
        latitude: 23.7253,
        longitude: 120.427,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1665194,
        gn_name: "Yunlin",
        gns_id: -2641046,
        gns_name: "Yunlin Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.YUN",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW27",
        gns_region: null
      },
      geometry: {
        type: "Polygon",
        coordinates: ["@@ìTzCtK_ÚOin@OHDPsOaGqHwMcGg`alQvNFTWC@Ac@W_IOqEQ\\eXO[C£`ejibsA£QuM¿][aOuiiEOSMc]Uo@bGAAE@ddA¶FTbvJR~JVpzHTFJl^FL@@|_"],
        encodeOffsets: [[123160, 24395]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-1177",
        OBJECTID_1: 1233,
        diss_me: 1177,
        adm1_cod_1: "TWN-1177",
        iso_3166_2: "TW-TTT",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 4,
        name_en: "Taitung",
        name: "台东县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.TT",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 2,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TT",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 7,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 2347344,
        woe_label: null,
        woe_name: "Taitung County",
        latitude: 22.0499,
        longitude: 121.54,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1668292,
        gn_name: "Taitung",
        gns_id: -2637931,
        gns_name: "Taidong Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.TTT",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW24",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@EIIHFJJG"], ["@@iMyVsb]\\HR@TGPMPÚ@@MCGGOAUHQdYPOCS"], ["@@@GEBM@GBADUHUbI^DFDJCDME[EKCKDK"], ["@@OOcWQOGOKie{UQi©IS«Y[ASDkASGWQWcke_»UIOSgScWiOc¹·IgK³B©cAbcbWlCj@jW`YLU\\D`P\\jXT\\chUd@fGtOjGpFªXv\\nRpj`|LfT^Pb@fV¤kZgHEd[n^DIdmnW`IzRtbj@XQHrx\\VdQjHj|d^\\r\\º\\Jsfy~UnSharElK±Ir\\HlApZld^xR|VnZf\\rCpMr^ff\\GcûQcuUcOkI@Ge"]],
        encodeOffsets: [[[124525, 22481]], [[124506, 22543]], [[124413, 23182]], [[124295, 23666]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-3414",
        OBJECTID_1: 291,
        diss_me: 3414,
        adm1_cod_1: "TWN-3414",
        iso_3166_2: "TW-PEN",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 7,
        name_en: "Penghu",
        name: "澎湖县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.TW.PH",
        note: null,
        hasc_maybe: null,
        region: "Taiwan Province",
        region_cod: null,
        provnum_ne: 0,
        gadm_level: 0,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "PH",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 22695856,
        woe_label: null,
        woe_name: "Penghu County",
        latitude: 23.5724,
        longitude: 119.597,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1670651,
        gn_name: "Penghu",
        gns_id: -2635561,
        gns_name: "Penghu Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.PEN",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW17",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@MEIJKbHH\\JVEBMAKIGKO"], ["@@KUQDORARJHXCNU"], ["@@A@@@A@@BGHDBADN@BBDADCACDEG@@AG@"], ["@@A@@FBC@A"], ["@@BAR@CGD@GKEK@@@@CJEJBLB@@B"], ["@@@EB@@CCBC@@BA@D@DF"], ["@@MEHNFG"], ["@@A@A@@@ADBBDA@C"], ["@@AAABADD@@@BC"], ["@@@GC@ALFC"], ["@@EAE@WKABBJHLFDLBLGAG"], ["@@BQDIFCFEEIGG@GDEKCWBKDJVA^ECOCCPPdLDV[D@DC"], ["@@FABBJCACBGCEOCCD@@EDHPB@BB"], ["@@SKCAAAC@KCQGAB@@EBCLBB@@@@DBNABBHBDB@@RHRC@@B@"], ["@@EI`HXARBLRd{J]CUMPSLUD_OUCICEKG[EGcSeEeNebJRVU^EbH\\TSHMHKLKNCE@AAAGCA^B\\N@PCzbdJG]"], ["@@[@DPJHNDTBPFFN@bZXV`YQIMYU{@ODMAIWCO@KAICIG"], ["@@QCGJEZOTa^l^XKZSJUQOFYIO"], ["@@AGAD@@GRBDCFNJHPDFDC@CTICKOIOO"]],
        encodeOffsets: [[[122292, 23752]], [[122287, 23795]], [[122477, 23806]], [[122482, 23815]], [[122532, 23823]], [[122524, 23825]], [[122373, 23830]], [[122194, 23877]], [[122179, 23883]], [[122194, 23884]], [[122401, 23929]], [[122368, 23953]], [[122176, 23973]], [[122404, 24062]], [[122480, 24162]], [[122324, 24128]], [[122456, 24192]], [[122466, 24301]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-3415",
        OBJECTID_1: 3446,
        diss_me: 3415,
        adm1_cod_1: "TWN-3415",
        iso_3166_2: "TW-",
        wikipedia: null,
        iso_a2: "TW",
        adm0_sr: 4,
        name_en: "Kinmen",
        name: "金门县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.FK.KM",
        note: null,
        hasc_maybe: null,
        region: "Fujian Province",
        region_cod: null,
        provnum_ne: 0,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "CM",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28760735,
        woe_label: null,
        woe_name: "Kinmen",
        latitude: 24.454,
        longitude: 118.368,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 1676511,
        gn_name: "Kinmen",
        gns_id: -2629674,
        gns_name: "Jinmen Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.1676511",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW13",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@gIUHfJJPDR@NINKAUIOK@IGCGIE"], ["@@dG}]_s^gLEu__kYK]NSBSFGPBDRTFJ@N@RE^M^ANLF|\\ovFH~RhzJxm"], ["@@UIKHFVRJNABKEO"]],
        encodeOffsets: [[[121078, 24999]], [[121293, 25094]], [[122342, 25581]]]
      }
    }, {
      type: "Feature",
      properties: {
        adm1_code: "TWN-5128",
        OBJECTID_1: 6631,
        diss_me: 5128,
        adm1_cod_1: "TWN-5128",
        iso_3166_2: "TW-",
        wikipedia: "http://en.wikipedia.org/wiki/Matsu_Islands",
        iso_a2: "TW",
        adm0_sr: 1,
        name_en: "Lienchiang",
        name: "连江县",
        name_local: null,
        type: "Hsien",
        type_en: "County",
        code_local: null,
        code_hasc: "TW.FK.LK",
        note: null,
        hasc_maybe: null,
        region: "Fujian Province",
        region_cod: null,
        provnum_ne: 16,
        gadm_level: 2,
        check_me: 20,
        scalerank: 9,
        datarank: 8,
        abbrev: null,
        postal: "TY",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 9,
        featurecla: "Admin-1 scale rank",
        name_len: 10,
        mapcolor9: 7,
        mapcolor13: 2,
        fips: null,
        fips_alt: null,
        woe_id: 28760734,
        woe_label: null,
        woe_name: "Lienchiang",
        latitude: 26.1519,
        longitude: 119.938,
        sov_a3: "TWN",
        adm0_a3: "TWN",
        adm0_label: 2,
        admin: "Taiwan",
        geonunit: "Taiwan",
        gu_a3: "TWN",
        gn_id: 6724655,
        gn_name: "Lienchiang",
        gns_id: 10126903,
        gns_name: "Lianjiang Xian",
        gn_level: 2,
        gn_region: null,
        gn_a1_code: "TW.6724655",
        region_sub: null,
        sub_code: null,
        gns_level: 1,
        gns_lang: "zho",
        gns_adm1: "TW14",
        gns_region: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@@OOMMEQGTfRABFHB"], ["@@OMQX\\HVIOG"], ["@@HCbRV@@KUUiSMASLEPDTHNH@@GBERE"], ["@@AGAGDGAEIHIPIDQGGBIAIKIcGFCJD^DLHDTFFFPJTAJMHCDE"], ["@@GAC@CBEAEEGOADIBEBGDHLCJHDPNVKDGHG"]],
        encodeOffsets: [[[122870, 26593]], [[122824, 26596]], [[122812, 26788]], [[122895, 26850]], [[123400, 27009]]]
      }
    }, {
      type: "Feature",
      properties: {
        name: "中国属钓鱼岛"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [["@@DFDDFBB@FEDCBCAEIAGBEFAB"], ["@@AJFJJHTFJAFAJ@NKAEICI@M@IEMCKAAD"], ["@@HFB@@ADGDAAC@AGA@DEHA@@B"], ["@@DBHBHAHE@ACAC@KDI@BD"], ["@@F@BAG@@B"], ["@@@@D@B@BD@BDCGCC@@B"], ["@@@@B@@@@@BB@A@@@AA@@@AB"]],
        encodeOffsets: [[[126644, 26546]], [[126422, 26354]], [[126503, 26350]], [[126509, 26342]], [[126469, 26354]], [[126505, 26399]], [[126532, 26374]]]
      }
    }],
    UTF8Encoding: !0
  }) : a("ECharts Map is not loaded") : a("ECharts is not Loaded");
});