"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDetails = fetchDetails;
exports.fetchList = fetchList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 制码包下载查询
 * @param {*} query 
 */
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-mark/markPack/queryForPage',
    method: 'get',
    params: params
  });
}

/**
 * 下载次数详情
 * @param {*} pId 
 */
function fetchDetails(pId) {
  return (0, _request.default)({
    url: '/api-mark/markPack/queryDownDetail/' + pId,
    method: 'get'
  });
}