var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c("div", { staticClass: "bt" }, [_vm._v(_vm._s(_vm.list.title))]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image: url('" + _vm.list.background + "');",
      },
      [
        _c("div", {
          staticClass: "prizeBg",
          style: "background-image: url('" + _vm.list.ermImg + "');",
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }