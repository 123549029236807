var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    {
      staticClass: "app-breadcrumb",
      attrs: { "separator-class": "el-icon-arrow-right" },
    },
    _vm._l(_vm.levelList, function (item) {
      return _c("el-breadcrumb-item", { key: item.path }, [
        _vm._v("\n    " + _vm._s(item.meta.title) + "\n  "),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }