"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _PieChart = _interopRequireDefault(require("@/components/dataAnalyse/PieChart"));
var _fw = require("@/api/dataAnalyse/fw.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import PieChart from '@/components/dataAnalyse/PieChart'
var _default = {
  components: {
    PieCharts: _PieChart.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      CountList: [],
      typeList: [],
      listLoading: false,
      dateValue: [],
      formInline: {},
      type: '1',
      searchForm: {
        timeType: '',
        startTime: '',
        endTime: ''
      }
    };
  },
  created: function created() {},
  activated: function activated() {
    this.monthFun();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      (0, _fw.searchNum)(this.searchForm).then(function (res) {
        if (res.data.code === 200) {
          _this2.CustomFreqandtypeFun(res.data);
          console.log(res);
        }
      });
    },
    // 本月
    monthFun: function monthFun() {
      this.searchForm.timeType = '1';
      this.dateValue = [];
      this.searchForm.startTime = '';
      this.searchForm.endTime = '';
      // getFrequencyMonthly().then((res) => {
      //   if (res.code === 0) {
      //     this.CountList = res.data
      //   }
      // })
      // getQuerytypeMonthly({}).then((res) => {
      //   if (res.code === 0) {
      //     this.typeList = res.data
      //   }
      // })
      // getMonthlyFreqandtype({}).then((res) => {
      //   if (res.code === 0) {
      //     this.CustomFreqandtypeFun(res)
      //   }
      // })
      this.getData();
    },
    // 本年
    annualFun: function annualFun() {
      this.searchForm.timeType = '2';
      this.dateValue = [];
      this.searchForm.startTime = '';
      this.searchForm.endTime = '';
      this.getData();
    },
    // 时间选择
    dateFun: function dateFun(val) {
      if (val) {
        this.searchForm.timeType = '3';
        this.searchForm.startTime = val[0];
        this.searchForm.endTime = val[1];
        this.getData();
      } else {
        // this.searchForm.timeType = '1'
        // this.searchForm.startTime = ''
        // this.searchForm.endTime = ''
        this.monthFun();
      }
    },
    CustomFreqandtypeFun: function CustomFreqandtypeFun(res) {
      // this.$refs.barChart.xData = [
      //   {
      //     value: res.data.queryTypeStatDto.count1,
      //     name: 'App二维码查询'
      //   },
      //   {
      //     value: res.data.queryTypeStatDto.count2,
      //     name: 'Web查询'
      //   },
      //   {
      //     value: res.data.queryTypeStatDto.count3,
      //     name: '电话查询'
      //   },
      //   {
      //     value: res.data.queryTypeStatDto.count4,
      //     name: '短信查询'
      //   }
      // ]
      // this.$refs.barChart.sData = [
      //   {
      //     value: res.data.frequencyStatDto.count1,
      //     name: '1次',
      //     type: '1次'
      //   },
      //   {
      //     value: res.data.frequencyStatDto.count2,
      //     name: '2-5次',
      //     type: '2-5次'
      //   },
      //   {
      //     value: res.data.frequencyStatDto.count3,
      //     name: '6-10次',
      //     type: '6-10次'
      //   },
      //   {
      //     value: res.data.frequencyStatDto.count4,
      //     name: '>10次',
      //     type: '>10次'
      //   }
      // ]
      this.CountList = res.data;
      var sData = [];
      res.data.forEach(function (item) {
        sData.push({
          value: item.num,
          name: item.timeFrame
        });
      });
      this.$refs.barChart.sData = sData;
      this.$refs.barChart.initChart();
      // this.$refs.barChart.initChart()
    },
    downCountFun: function downCountFun() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '防伪查询次数.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    downTypeFun: function downTypeFun() {
      var xlsxParam = {
        raw: true
      }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector('#outTypeTable');
      var wb = _xlsx.default.utils.table_to_book(table, xlsxParam);

      /* 获取二进制字符串作为输出 */
      var wbout = _xlsx.default.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        _fileSaver.default.saveAs(
        // Blob 对象表示一个不可变、原始数据的类文件对象。 //Blob 表示的不一定是JavaScript原生格式的数据。
        // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        new Blob([wbout], {
          type: 'application/octet-stream'
        }),
        // 设置导出文件名称
        '防伪查询类型次数.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    }
  }
};
exports.default = _default;