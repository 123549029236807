"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportCodeList = ImportCodeList;
exports.activityPrizeSend = activityPrizeSend;
exports.addOneCode = addOneCode;
exports.errorCodeListPage = errorCodeListPage;
exports.postErrorCodeExport = postErrorCodeExport;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 错误数码兼容列表/日志列表 分页查询
function errorCodeListPage(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/errorCodeCompatibilityInfo/errorCodeListPage',
    method: 'post',
    data: data
  });
}

// 错误数码兼容列表/日志列表 导出
function postErrorCodeExport(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/errorCodeCompatibilityInfo/errorCodeExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 批量导入数码
function ImportCodeList(file) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/errorCodeCompatibilityInfo/importCodeList',
    method: 'post',
    file: file
  });
}
// 新增单个数码
function addOneCode(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/errorCodeCompatibilityInfo/addOneCode',
    method: 'get',
    params: params
  });
}

// 奖品发放日志
function activityPrizeSend(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityPrizeRecord/activityPrizeSend',
    method: 'get',
    params: params
  });
}