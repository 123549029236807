"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDetails = fetchDetails;
exports.fetchList = fetchList;
exports.generate = generate;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 制码状态查询
 * @param {*} query 
 */
function fetchList(params) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/queryGenerateForPage',
    method: 'get',
    params: params
  });
}

/**
 * 码重新生成
 * @param {applyNumId} query 
 */
function generate(query) {
  return (0, _request.default)({
    url: '/api-mark/markApplyOrder/restGenerateMark/' + query,
    method: 'get'
    // params: query,
    // headers: {
    //   'Content-Type': 'application/json',
    //   'X-Requested-With': 'XMLHttpRequest'
    // }
  });
}

function fetchDetails(pId) {
  return (0, _request.default)({
    url: '/api-mark/markApplyDetail/queryDetailForList/' + pId,
    method: 'get'
  });
}