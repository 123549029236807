"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buyerMoneyWine = buyerMoneyWine;
exports.byOrgFactoryId = byOrgFactoryId;
exports.byPurchaseIdList = byPurchaseIdList;
exports.companyList = companyList;
exports.deliverList = deliverList;
exports.excelOut = excelOut;
exports.extractDetailById = extractDetailById;
exports.factoryInfo = factoryInfo;
exports.getAuditLog = getAuditLog;
exports.getCompanyById = getCompanyById;
exports.getCurrentInventory = getCurrentInventory;
exports.getGhsById = getGhsById;
exports.getInOutCompany = getInOutCompany;
exports.getOrderNo = getOrderNo;
exports.insertOrder = insertOrder;
exports.matchChannelBuyGift = matchChannelBuyGift;
exports.orderAudit = orderAudit;
exports.orderConfirm = orderConfirm;
exports.queryById = queryById;
exports.queryByOrderNo = queryByOrderNo;
exports.queryForPage = queryForPage;
exports.takeGoodsForOrder = takeGoodsForOrder;
exports.updateOrder = updateOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
// 采购、收货单位列表
function companyList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/companyList',
    method: 'post',
    data: data
  });
}

// 发货单位列表
function deliverList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/deliverList',
    method: 'post',
    data: data
  });
}

//  订单分页查询
function queryForPage(data) {
  console.log(data, 'data');
  var current = data.current,
    size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/storage-center/orderInfo/queryForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 获取订单号
function getOrderNo(params) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/getOrderNo/' + params,
    method: 'get'
  });
}

//根据订单号获取详情
function queryByOrderNo(param) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryByOrderNo/' + param,
    method: 'get'
  });
}

// 新增订单
function insertOrder(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/insertOrder',
    method: 'post',
    data: data
  });
}

// 修改订单
function updateOrder(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/updateOrder',
    method: 'put',
    data: data
  });
}

// 订单查询根据ID
function queryById(params) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/queryById/' + params,
    method: 'get'
  });
}

// 提货详情根据ID
function extractDetailById(params) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/extractDetailById/' + params,
    method: 'get'
  });
}

// 查询下属仓库
function byOrgFactoryId(params) {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/byOrgFactoryId',
    method: 'get',
    params: params
  });
}
// 获取工厂信息
function factoryInfo(params) {
  return (0, _request.default)({
    url: '/api-basic/factoryInfo/selectById',
    method: 'get',
    params: params
  });
}

//  获取当前产品库存量
function getCurrentInventory(params) {
  return (0, _request.default)({
    url: '/storage-center/orderDetail/getCurrentInventory',
    method: 'get',
    params: params
  });
}

// 审核
function orderAudit(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/audit',
    method: 'put',
    data: data
  });
}

// 审核
function orderConfirm(params) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/confirm',
    method: 'put',
    params: params
  });
}

// 根据单号提货
function takeGoodsForOrder(data) {
  return (0, _request.default)({
    url: '/storage-center/orderDetail/takeGoodsForOrder',
    method: 'post',
    data: data
  });
}

// 获取审核日志
function getAuditLog(params) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/getAuditLog',
    method: 'get',
    params: params
  });
}
// ???

// /**
//  * 查询审核记录
//  * @param {*} num 
//  */
// export function getflowInfo(num) {
// 	return request({
// 		url: '/storage-center/bill/confirm/flow/' + num,
// 		method: 'post',
// 		data: {}
// 	})
// }

/**
 * 导出服务
 * @param {*} query 
 */
function excelOut(params) {
  return (0, _request.default)({
    url: '/api-prevent/antifake/excelOut',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}

// 出入库收发货单位接口
function getInOutCompany(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/getInOutCompany',
    method: 'post',
    data: data
  });
}

// 根据采购商Id获取供货商信息PURCHASE_ID
function byPurchaseIdList(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/byPurchaseIdList',
    method: 'post',
    data: data
  });
}

// 根据单位ID获取详情信息
function getCompanyById(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/getCompanyById',
    method: 'post',
    data: data
  });
}

// 查询收货单位可用酒券金额
function buyerMoneyWine(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/buyerMoneyWine',
    method: 'post',
    data: data
  });
}

// 根据供货商Id获取采购商信息
function getGhsById(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/bySupplierIdList',
    method: 'post',
    data: data
  });
}

// 根据产品及渠道商获取匹配到的返利政策
function matchChannelBuyGift(data) {
  return (0, _request.default)({
    url: '/storage-center/orderInfo/matchChannelBuyGift',
    method: 'post',
    data: data
  });
}