"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batcRemoveAllSalesMan = batcRemoveAllSalesMan;
exports.batcRemoveSalesMan = batcRemoveSalesMan;
exports.batchUpdateSalesMan = batchUpdateSalesMan;
exports.comBoxSalesMan = comBoxSalesMan;
exports.comBoxSalesManlist = comBoxSalesManlist;
exports.dealerAreaEnableArea = dealerAreaEnableArea;
exports.dealerAreaInsert = dealerAreaInsert;
exports.dealerAreaList = dealerAreaList;
exports.dealerAreaUpdate = dealerAreaUpdate;
exports.findAllProduct = findAllProduct;
exports.getManualTest = getManualTest;
exports.newlist = newlist;
exports.nextArea = nextArea;
exports.querySalesManById = querySalesManById;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''
function dealerAreaList(data) {
  return (0, _request.default)({
    url: modelUrl + '/dealerArea/newlist',
    method: 'post',
    data: data
  });
}
function dealerAreaInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/dealerArea/insert',
    method: 'post',
    data: data
  });
}
function dealerAreaUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/dealerArea/update',
    method: 'post',
    data: data
  });
}
function dealerAreaEnableArea(params) {
  return (0, _request.default)({
    url: modelUrl + '/dealerArea/enableArea',
    method: 'get',
    params: params
  });
}
function newlist(data) {
  return (0, _request.default)({
    url: '/api-basic/dealerArea/newlist',
    method: 'post',
    data: data
  });
}
function nextArea(data) {
  return (0, _request.default)({
    url: '/api-basic/dealerArea/nextArea',
    method: 'post',
    data: data
  });
}
//
function findAllProduct(data) {
  return (0, _request.default)({
    url: '/api-basic/productArchives/findAllProduct',
    method: 'post',
    data: data
  });
}

//
function getManualTest(params) {
  return (0, _request.default)({
    url: modelUrl + '/basicKinDee/manualTest',
    method: 'get',
    params: params
  });
}
// export function getDealers(param) {
//   return request({
//     url: '/api-basic/dealerInfo/list',
//     method: 'get',
// 		params:param
//   })
// }

// export function getSingalInfo(param) {
//   return request({
//     url: '/api-basic/dealerInfo/selectOneDealer',
//     method: 'get',
// 		params:param
//   })
// }

// export function getSh(param) {
//   return request({
//     url: '/api-basic/basic/comboBox/linkageSearch',
//     method: 'get',
// 		params:param
//   })
// }

// export function submitData(param){
// 	return request({
// 		 url: '/api-basic/dealerInfo/update',
// 		 method: 'PUT',
// 		 params:param
// 	})
// }

// export function insertData(param){
// 	return request({
// 		 url: '/api-basic/dealerInfo/insert',
// 		 method:'post',
// 		 params:param
// 	})
// }

// export function delData(param){
// 	return request({
// 		 url: '/api-basic/dealerInfo/delDealer',
// 		 method:'DELETE',
// 		 params:param
// 	})
// }
// 批量全部解除
function batcRemoveAllSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batcRemoveAllSalesMan',
    method: 'post',
    data: data
  });
}

// 业务员详情
function comBoxSalesMan(current, size, data) {
  return (0, _request.default)({
    url: "/api-basic/jcChannelArchives/comBoxSalesMan?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
// 业务员详情
function comBoxSalesManlist(current, size, data) {
  return (0, _request.default)({
    url: "/api-basic/jcChannelArchives/comBoxListSalesMan?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}

// 业务员弹出详情
function querySalesManById(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/querySalesManById',
    method: 'get',
    params: params
  });
}

// 业务员批量变更
function batchUpdateSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batchUpdateSalesMan',
    method: 'post',
    data: data
  });
}

// 业务员批量解除
function batcRemoveSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batcRemoveSalesMan',
    method: 'post',
    data: data
  });
}