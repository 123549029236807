"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _basicConfig = require("@/api/wxhyyy/basicConfig");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    stateName: function stateName(index) {
      if (!index) return '';
      var comtype = {
        '0': '未授权',
        '1': '已授权'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    return {
      list: [],
      sweepBtn: false,
      listLoading: false,
      searchBtn: false,
      total: 0,
      radio: '',
      newRadio: '',
      setUpDialogVisible: false,
      typeData: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '充值'
      }, {
        value: '2',
        label: '提现'
      }],
      searchForm: {
        cid: '',
        operType: '',
        pageNo: 1,
        pageSize: 10
      },
      cid: '',
      rechargeAmount: '0'
    };
  },
  computed: {
    consumer: {
      get: function get() {
        return this.radio;
      },
      set: function set(val) {
        if (!this.radio) {
          this.newRadio = val;
          this.isSetUp();
        }
      }
    }
  },
  created: function created() {
    var cid = localStorage.getItem('cid');
    this.searchForm.cid = cid;
    this.cid = cid;
    this.getBalanceFun();
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _basicConfig.getAmountloglist)((0, _objectSpread2.default)({}, this.searchForm)).then(function (res) {
        if (res.code === 0) {
          _this.list = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    getBalanceFun: function getBalanceFun() {
      var _this2 = this;
      (0, _basicConfig.getBalance)({
        cid: this.cid
      }).then(function (res) {
        if (res.code === 0) {
          _this2.rechargeAmount = res.data.rechargeAmount;
        }
      });
    },
    pagingFun: function pagingFun() {
      this.fetchData();
    },
    searchFun: function searchFun(type) {
      if (type === 1) {
        this.searchForm = {
          operType: '',
          pageNo: 1,
          pageSize: 10
        };
      }
      this.fetchData();
    }
  }
};
exports.default = _default;