"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billConfigList = billConfigList;
exports.deleteById = deleteById;
exports.fetchDetails = fetchDetails;
exports.getDType = getDType;
exports.installPack = installPack;
exports.queryConfig = queryConfig;
exports.saveOrderConfig = saveOrderConfig;
exports.updateById = updateById;
exports.updateConfig = updateConfig;
exports.versionControldelete = versionControldelete;
exports.versionControlpage = versionControlpage;
exports.versionControlsave = versionControlsave;
exports.versionControlupdate = versionControlupdate;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 单据类型分页查询
 * @param {*} params
 */
function billConfigList(params) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/queryForPage',
    method: 'get',
    params: params
  });
}

/**
 * 单据类型新增
 * @param {*} params
 */
function saveOrderConfig(params) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/saveOrderConfig',
    method: 'post',
    params: params
  });
}

/**
 * 编辑单据信息
 * @param {*} params
 */
function updateById(params) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/updateById',
    method: 'put',
    params: params
  });
}

/**
 * 删除单据信息
 * @param {*} params {pId:1}
 */
function deleteById(params) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/deleteById/' + params,
    method: 'get',
    params: params
  });
}

/**
 * 查询生产入库配置信息
 * @param {*} params
 */
function queryConfig(params) {
  return (0, _request.default)({
    url: '/storage-center/productConfig/queryConfig',
    method: 'get',
    params: params
  });
}

/**
 * 修改生产入库配置信息
 * @param {*} params
 */
function updateConfig(params) {
  return (0, _request.default)({
    url: '/storage-center/productConfig/updateConfig',
    method: 'put',
    params: params
  });
}

/**
 * 获取下级类型
 * **/
function getDType(param) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/queryByOrderType',
    method: 'post',
    params: param
  });
}

/**
 *获取详情
**/

function fetchDetails(param) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/queryById/' + param,
    method: 'get'
  });
}

// 分页查询
function versionControlpage(params) {
  return (0, _request.default)({
    url: '/api-user/versionControl/page',
    method: 'get',
    params: params
  });
}

//  添加
function versionControlsave(data) {
  return (0, _request.default)({
    url: '/api-user/versionControl/save',
    method: 'post',
    data: data
  });
}

//  编辑
function versionControlupdate(data) {
  return (0, _request.default)({
    url: '/api-user/versionControl/update',
    method: 'post',
    data: data
  });
}
// 详情
function installPack(params) {
  return (0, _request.default)({
    url: '/api-user/versionControl/installPack',
    method: 'get',
    params: params
  });
}

// 删除
function versionControldelete(params) {
  return (0, _request.default)({
    url: '/api-user/versionControl/delete',
    method: 'get',
    params: params
  });
}