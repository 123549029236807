"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work = require("@/api/systems/work");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _edit = _interopRequireDefault(require("./component/edit"));
var _insert = _interopRequireDefault(require("./component/insert"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'systemapilistindex',
  components: {
    Pagination: _Pagination.default,
    edit: _edit.default,
    insert: _insert.default
  },
  data: function data() {
    return {
      listQuery: {
        methodDesc: '',
        methodKey: '',
        methodPath: '',
        current: 1,
        size: 20
      },
      list: [],
      tableKey: 'api',
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      apis: [],
      page2: false,
      editData: {},
      editVisible: false,
      addVisible: false,
      editName: '',
      addName: ''
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    that.apis = that.$route.meta.authList;
    if (!hasCache) {
      that.listLoading = true;
      that.list = [];
      that.total = 0;
      that.getList();
      var h = (0, _utils.tableHeight)();
      that.$nextTick(function () {
        that.tabHeight = h;
      });
    } else {
      that.listQuery = that.listQuery;
      that.list = that.list;
      that.total = that.total;
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = 'systemapilistindex';
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      //获取按钮btnId
      var pid = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = (0, _utils.deepClone)(that.listQuery);
      (0, _work.getApiList)(param).then(function (response) {
        that.listLoading = false;
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
        } else {
          that.$message.error(response.data.msg);
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.listQuery.current = 1;
      that.getList();
    },
    DoEdit: function DoEdit(data, name) {
      var that = this;
      that.editName = name;
      that.editData = (0, _utils.deepClone)(data);
      that.editVisible = true;
    },
    DoDel: function DoDel(data, name) {
      var that = this;
      var param = {
        pId: data.pId
      };
      that.$confirm('确定删除？').then(function (res) {
        if (res == 'confirm') {
          var pid = that.authId(name, that.apis);
          (0, _work.deleteApi)(param).then(function (res) {
            var back = false;
            if (res.data.code == 200) {
              back = true;
              that.$message({
                message: '删除成功！',
                type: 'success'
              });
            } else {
              that.$message.error(res.data.msg);
              back = false;
            }
            return back;
          }).then(function (res) {
            if (res) {
              that.listQuery.current = 1;
              that.getList();
            }
          });
        }
      }).catch(function () {});
    },
    closeEdit: function closeEdit() {
      var that = this;
      that.editVisible = false;
    },
    closeAdd: function closeAdd() {
      var that = this;
      that.addVisible = false;
    },
    DoAdd: function DoAdd(name) {
      var that = this;
      that.addName = name;
      that.addVisible = true;
    }
  }
};
exports.default = _default;