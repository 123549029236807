"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _wxintegral = require("@/api/wxhyyy/wxintegral.js");
var _auth = require("@/utils/auth");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      infoDalog: false,
      infoBtn: false,
      // infoList: [],
      // infoData: {

      //   checkList: [
      //     {

      //     }
      //   ]

      // },
      infoRules: {
        checkList: [{
          required: true,
          message: '请选择必填项',
          trigger: 'change'
        }]
      },
      jfDalog: false,
      // 积分弹窗
      addBtnIs: false,
      dataForm: {
        id: '',
        rtype: '1'
      },
      list: [],
      pageForm: {
        pageNo: 0,
        pageSize: 5
      },
      total: 0,
      rtype: '0',
      cid: '',
      form: {
        isGmsp: false,
        isFwyz: false,
        isFxhy: false,
        isGzgzh: false,
        isMsg: '2',
        isSrlb: '2',
        isXrlb: '2',
        isName: '2',
        isPhone: '2',
        isSex: '2',
        isAge: '2',
        isSsq: '2',
        isXxdz: '2',
        isSrlbBs: '',
        dayMaxTask: '',
        maxTask: '',
        birthdayValue: '',
        birthdayTimes: '',
        birthdayMax: '',
        newPeopleValue: '',
        fxhd: '',
        gzhd: '',
        gzhUrl: '',
        appid: '',
        appsecret: '',
        moWxRuleMoneyDOList: [{
          je: '',
          hdjf: ''
        }],
        glAppid: ''
      },
      gzhimgurl: '',
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      fstpUrl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single',
      twImgurl: '',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      }
    };
  },
  created: function created() {
    // this.cid = localStorage.getItem('cid')
    this.getRules();
    this.twImgurl = _wxHy.base;
    this.getIntegralFun();
    this.getrule();
    console.log(this.form);
  },
  methods: {
    xmcha: function xmcha(val) {
      console.log(val);
    },
    wsxx: function wsxx() {
      console.log((0, _typeof2.default)(this.form.isMsg));
    },
    // 完善个人信息
    infoSubmit: function infoSubmit() {
      this.infoDalog = false;
      this.$message.success('保存成功');
      // this.$refs.infoForm.validate((valid) => {
      //   if (valid) {
      //     if (this.infoId) {
      //       updateIntegralAct({ ...this.infoData, cid: this.cid, id: this.infoId }).then(res => {
      //         if (res.code === 0) {
      //           this.infoDalog = false
      //           this.getIntegralActCollect()
      //           this.$message.closeAll()
      //           this.$message({
      //             message: res.msg,
      //             type: 'success',
      //             duration: 3 * 1000
      //           })
      //         }
      //       })
      //     } else {
      //       addIntegralAct({ ...this.infoData, cid: this.cid }).then(res => {
      //         if (res.code === 0) {
      //           this.$message.closeAll()
      //           this.infoDalog = false
      //           this.getIntegralActCollect()
      //           this.$message({
      //             message: res.msg,
      //             type: 'success',
      //             duration: 3 * 1000
      //           })
      //         }
      //       })
      //     }
      //   } else {
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
    },
    getrule: function getrule() {
      var _this = this;
      (0, _wXintegralMall.getIntegralRule)().then(function (res) {
        var res = res.data;
        if (res.status === 200) {
          if (res.data) {
            _this.dataForm.id = res.data.id;
            _this.dataForm.rtype = res.data.rtype;
            _this.rtype = res.data.rtype;
          } else {
            return;
          }
        } else {
          _this.$message.info(res.msg);
        }
      });
    },
    gzsz: function gzsz() {
      this.getrule();
      this.jfDalog = true;
    },
    saverule: function saverule() {
      var _this2 = this;
      var data = this.form;
      if (!data.appid) {
        this.$message.info('请填写appid');
        return;
      }
      if (!data.appsecret) {
        this.$message.info('请填写appsecret');
        return;
      }
      if (data.isGmsp) {
        var mowxlist = data.moWxRuleMoneyDOList;
        for (var i = 0; i < mowxlist.length; i++) {
          if (!mowxlist[i].je) {
            this.$message.info('请填写消费金额');
            return;
          }
          if (!mowxlist[i].hdjf) {
            this.$message.info('请填写获取积分');
            return;
          }
        }
        // data.moWxRuleMoneyDOList.forEach(item => {
        //   if (!item.je) {
        //     this.$message.info('请填写消费金额')
        //     return
        //   }
        //   if (!item.hdjf) {
        //     this.$message.info('请填写获取积分')
        //     return
        //   }
        // })
        if (!data.glAppid) {
          this.$message.info('请填写关联公众号appid');
          return;
        }
        data.isGmsp = 1;
      } else {
        // data.moWxRuleMoneyDOList = []
        data.isGmsp = 2;
      }
      if (data.isFwyz) {
        data.isFwyz = 1;
      } else {
        data.isFwyz = 2;
      }
      if (data.isFxhy) {
        if (!data.fxhd) {
          this.$message.info('请填写分享积分');
          return;
        }
        data.isFxhy = 1;
      } else {
        data.isFxhy = 2;
      }
      if (data.isGzgzh) {
        if (!data.gzhd) {
          this.$message.info('请填写关注公众号获得积分');
          return;
        }
        if (!data.gzhUrl) {
          this.$message.info('请上传公众号图片');
          return;
        }
        data.isGzgzh = 1;
      } else {
        data.isGzgzh = 2;
      }
      if (data.isMsg === '1') {
        console.log(data);
        if (data.isMsgValue < 0 || data.isMsgValue === null) {
          this.$message.warning('请输入正确的积分');
          return false;
        }
        if ((data.isName === '2' || data.isName === null) && (data.isPhone === '2' || data.isName === null) && (data.isSex === '2' || data.isName === null) && (data.isAge === '2' || data.isName === null) && (data.isSsq === '2' || data.isName === null) && (data.isXxdz === '2' || data.isName === null)) {
          this.$message.warning('请至少选择一个完善信息选项');
          return false;
        }
      }

      // console.log(data)
      // JSON.stringify(data)
      if (data.id) {
        (0, _wXintegralMall.updateRuleDeail)(data).then(function (res) {
          var res = res.data;
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.getRules();
          } else {
            _this2.$message.success(res.msg);
          }
        });
      } else {
        data.id = '';
        (0, _wXintegralMall.updateRuleDeail)(data).then(function (res) {
          var res = res.data;
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.getRules();
          } else {
            _this2.$message.success(res.msg);
          }
        });
      }
    },
    back: function back(val) {
      // console.log(val)
    },
    // gmsp(val) {
    //   //  // console.log(val)
    //   if (val) {
    //     this.form.isGmsp = 1 || true
    //     //  // console.log(val)
    //      // console.log(this.form.isGmsp)
    //   } else {
    //     // val = 2
    //     this.form.isGmsp = 2 || false
    //      // console.log(this.form.isGmsp)
    //   }
    // },
    // 上传图片
    handleSuccess: function handleSuccess(response, file, fileList) {
      if (response.code * 1 === 200) {
        this.$message.success('上传成功');
        this.form.gzhUrl = response.data.mediaId;
        this.gzhimgurl = URL.createObjectURL(file.raw);
      } else {
        this.$message({
          message: response.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    imgSize: function imgSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    handError: function handError() {
      this.$message.error('上传失败');
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    // 添加门槛
    reduceshoplist: function reduceshoplist(index, val) {
      val.splice(index, 1);
    },
    addshoplist: function addshoplist(index, val) {
      var a = {
        je: '',
        hdjf: ''
      };
      if (val.length === 5) {
        this.$message.warning('最多添加4组门槛');
        return;
      }
      val.push(a);
    },
    // 获取分类数据
    getRules: function getRules() {
      var _this3 = this;
      (0, _wXintegralMall.selectWxRuleDeailById)().then(function (res) {
        // console.log(res)
        var res = res.data;
        if (res.code * 1 === 200) {
          if (res.data) {
            // this.dataForm = res.data
            _this3.form = res.data;
            // this.rtype = res.data.rtype
            var data = _this3.form;
            if (data.moWxRuleMoneyDOList === null) {
              data.moWxRuleMoneyDOList = [{
                je: '',
                hdjf: ''
              }];
            }
            if (data.gzhUrl) {
              _this3.gzhimgurl = _this3.twImgurl + data.gzhUrl;
            }
            if (data.isGmsp === '1') {
              data.isGmsp = true;
            } else {
              data.isGmsp = false;
            }
            if (data.isFwyz === '1') {
              data.isFwyz = true;
            } else {
              data.isFwyz = false;
            }
            if (data.isFxhy === '1') {
              data.isFxhy = true;
            } else {
              data.isFxhy = false;
            }
            if (data.isGzgzh === '1') {
              data.isGzgzh = true;
            } else {
              data.isGzgzh = false;
            }
          }
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this4 = this;
      this.dataForm.rtype = this.rtype;
      var param = Object.assign({}, this.dataForm);
      this.addBtnIs = true;
      // console.log(param)
      // console.log(this.dataForm)
      // if (this.form.id) {
      //   param.id = this.form.id
      // } else {
      //   param.id = null
      // }

      if (param.id) {
        (0, _wXintegralMall.updateRuleById)(param).then(function (res) {
          var res = res.data;
          _this4.addBtnIs = false;
          // console.log(res)
          if (res.status * 1 === 200) {
            _this4.dataForm.rtype = _this4.rtype;
            _this4.jfDalog = false;
            _this4.$message.closeAll();
            _this4.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
          } else {
            _this4.$message.closeAll();
            _this4.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      } else {
        (0, _wXintegralMall.insertRule)(param).then(function (res) {
          var res = res.data;
          _this4.addBtnIs = false;
          if (res.status * 1 === 200) {
            _this4.dataForm.rtype = _this4.rtype;
            _this4.jfDalog = false;
            _this4.$message.closeAll();
            _this4.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
          } else {
            _this4.$message.closeAll();
            _this4.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    },
    closeFun: function closeFun() {
      this.rtype = this.dataForm.rtype;
    },
    routeTo: function routeTo(type, data) {
      var obj = JSON.stringify({
        type: type,
        id: data
      });
      this.$router.push({
        name: 'wxAccountswxIntegralMallintegralSetindex',
        query: {
          data: obj
        }
      });
    },
    pagingFun: function pagingFun() {
      this.getIntegralFun();
    },
    getIntegralFun: function getIntegralFun() {
      var _this5 = this;
      (0, _wxintegral.getIntegralAct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageForm), {}, {
        cid: this.cid
      })).then(function (resp) {
        var resp = resp.data;
        if (resp.code === 0) {
          _this5.list = resp.data.records;
          // this.list = resp.data.lstResult
          // this.total = resp.data.pageTotal
          _this5.total = resp.data.total;
        }
      });
    },
    switchFun: function switchFun(statuse, id) {
      var _this6 = this;
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(statuse === '1' ? '开启' : '关闭', "\u8BE5\u6761\u79EF\u5206\u89C4\u5219, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        (0, _wxintegral.integralActOpenOrOlose)({
          cid: _this6.cid,
          id: id,
          statuse: statuse
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this6.list.forEach(function (item) {
              if (item.id === id) {
                item.statuse = statuse;
              }
            });
            _this6.$message({
              type: 'success',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u6210\u529F!")
            });
          } else {
            _this6.$message({
              type: 'info',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u5931\u8D25!")
            });
          }
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(statuse === '1' ? '开启' : '关闭')
        });
      });
    },
    deleteFun: function deleteFun(id) {
      var _this7 = this;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        (0, _wxintegral.deleteIntegralAct)({
          cid: _this7.cid,
          id: id
        }).then(function (resp) {
          var resp = resp.data;
          if (resp.code === 0) {
            _this7.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this7.list = _this7.list.filter(function (item, i) {
              return item.id !== id;
            });
          } else {
            _this7.$message({
              type: 'error',
              message: '删除失败！'
            });
          }
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
exports.default = _default;