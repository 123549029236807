var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("search", { ref: "searchMain" }),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "btnArea" }, [
                _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.downLoadBtn.type,
                                  size: _vm.btnConfig.size,
                                  plain: _vm.btnConfig.downLoadBtn.plain,
                                  icon: _vm.btnConfig.downLoadBtn.icon,
                                  loading: _vm.downLoading,
                                },
                                on: { click: _vm.download },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "80",
                      label: "序号",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (_vm.searchForm.current - 1) *
                                      _vm.searchForm.size +
                                      scope.$index +
                                      1
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      998093746
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "入库单号",
                      prop: "billNo",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "NC单号",
                      prop: "NCNO",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      prop: "outOrgName",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位",
                      prop: "inOrgName",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "入库库房",
                      prop: "inStoreName",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm._f("tagStatus")(row.billStatus),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterStatus")(
                                        row.billStatus,
                                        row.billStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      880549003
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createUser",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据来源",
                      prop: "billSource",
                      align: _vm.tableConfig.align,
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.billSource == 1
                                ? _c("span", [_vm._v("平台自动创建")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.billSource == 2
                                ? _c("span", [_vm._v("平台用户创建")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.billSource == 3
                                ? _c("span", [_vm._v("PDA创建")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.billSource == 4
                                ? _c("span", [_vm._v("ERP同步")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2385607989
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("qrcodebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("printbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("cxjd", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          fixed: "right",
                          width: "170px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getflow(row, 2)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: !(row.billStatus == 101),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doUpdate(row)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3401336319
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.code_title,
            visible: _vm.codeVisible,
            width: "312px !important",
            "before-close": _vm.handleCodeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("div", {
              ref: "qrCodeUrl",
              staticClass: "qrcode",
              attrs: { id: "qrcode" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "promt-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "", size: "mini" },
                  on: { click: _vm.handleCodeClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("insert", {
        ref: "insertMain",
        attrs: {
          "dialog-visible": _vm.insertVisible,
          "submit-type": _vm.submitType,
          "bill-no": _vm.billNo,
          "bill-type": _vm.billType,
          "other-type-name": _vm.otherTypeName,
        },
        on: { close: _vm.closeInsert },
      }),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
      _vm._v(" "),
      _vm.pShow
        ? _c("printDialog", {
            attrs: {
              type: _vm.typeNum,
              visible: _vm.printShow,
              "bill-no": _vm.billNo,
            },
            on: { close: _vm.printClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }