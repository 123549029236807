"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFlowList = getFlowList;
exports.setFlowStart = setFlowStart;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 查询流程状态
 * @param {*} query 
 */
function getFlowList(num) {
  return (0, _request.default)({
    url: '/storage-center/bill/flow/' + num,
    method: 'get',
    data: {}
  });
}

/**
 * 重新发起审核
 * @param {*} query 
 */
function setFlowStart(num) {
  return (0, _request.default)({
    url: '/storage-center/bill/confirm/start/' + num,
    method: 'post',
    data: {}
  });
}