"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _details_flow = _interopRequireDefault(require("@/views/warehouseService/checkout/other/components/details_flow"));
var _details_flow2 = _interopRequireDefault(require("@/views/warehouseService/warehousing/other/components/details_flow"));
var _inventory = require("@/api/warehouse/inventory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default,
    checkout: _details_flow.default,
    checkin: _details_flow2.default
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      activeName: '',
      title: '盘点单-详情',
      listLoading: false,
      btn_loading: false,
      proShow: false,
      scanData: {},
      detailForm: {
        inventoryOrderNo: '',
        productId: '',
        current: 1,
        size: 10
      },
      codeVisible: false,
      codeListLoading: false,
      codeList: [],
      codetotal: 0,
      productList: [],
      auditList: [],
      isUpdate: true
    };
  },
  mounted: function mounted() {
    this.getDetail();
    this.getAudit();
  },
  methods: {
    getflow: function getflow(row, qx, comName) {
      var obj = {};
      if (comName == 'checkin') obj.billNo = row.profitOrderNo;
      if (comName == 'checkout') obj.billNo = row.lossesOrderNo;
      this.$refs[comName].show(obj, qx);
    },
    getDetail: function getDetail() {
      var _this = this;
      (0, _inventory.queryById)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this.scanData = res.data.data;
        } else {
          _this.$message.error(res.data.msg);
        }
      });
    },
    getAudit: function getAudit() {
      var _this2 = this;
      this.listLoading = true;
      (0, _inventory.getAuditLog)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this2.auditList = res.data.data;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    goLink: function goLink(row) {
      if (row.aaa > 0) {
        this.$router.push({
          path: '/warehouseService/warehousing/other/other',
          query: {
            type: '1240638462320933421'
          }
        });
      }
      this.$router.push({
        path: '/warehouseService/checkout/other/other',
        query: {
          type: '1240638462320935423'
        }
      });
      this.handleClose();
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    codeClose: function codeClose() {
      this.detailForm = {
        inventoryOrderNo: '',
        productId: '',
        current: 1,
        size: 10
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    codeDetail: function codeDetail(row) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.inventoryOrderNo = this.scanData.inventoryOrderNo;
      this.detailForm.productId = row.productId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this3 = this;
      (0, _inventory.queryProductDetail)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this3.codeList = res.data.data.records;
            _this3.codetotal = parseInt(res.data.data.total);
          } else {
            _this3.codeList = [];
            _this3.codetotal = 0;
          }
        }
        _this3.codeListLoading = false;
      }).catch(function () {
        _this3.codeListLoading = false;
      });
    }
  }
};
exports.default = _default;