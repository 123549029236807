var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择产品",
        visible: _vm.productVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.productVisible = $event
        },
      },
    },
    [
      [
        _c(
          "div",
          { staticClass: "page-container" },
          [
            _c(
              "el-row",
              { attrs: { type: "flex", gutter: 10 } },
              [
                _c("div", { staticClass: "page-section leftSide" }, [
                  _c("div", { staticClass: "phead" }, [_vm._v("产品分类")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24, prop: "materialCode" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "分类编码/名称",
                                  },
                                  model: {
                                    value: _vm.filterText,
                                    callback: function ($$v) {
                                      _vm.filterText = $$v
                                    },
                                    expression: "filterText",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticStyle: {
                                      "background-color": "#4181FF",
                                    },
                                    attrs: {
                                      slot: "append",
                                      type: "primary",
                                      icon: "el-icon-search",
                                    },
                                    on: { click: _vm.handleFilter },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tree-container" }, [
                        _c(
                          "div",
                          { staticClass: "trc" },
                          [
                            _c("el-tree", {
                              ref: "tree",
                              attrs: {
                                data: _vm.classList,
                                props: _vm.props,
                                "highlight-current": true,
                                "filter-node-method": _vm.filterNode,
                                "default-expand-all": "",
                                "expand-on-click-node": false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var node = ref.node
                                    var data = ref.data
                                    return _c(
                                      "div",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.scan(data)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "custom-tree-node",
                                            class:
                                              _vm.activeClass == data.id
                                                ? "active"
                                                : "",
                                            attrs: { index: data.id },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  width: "70%",
                                                  display: "inlin-block",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(data.productClassName)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("el-col", { staticStyle: { width: "calc(100% - 300px)" } }, [
                  _c(
                    "div",
                    { staticClass: "page-section" },
                    [
                      _c("div", { staticClass: "phead" }, [_vm._v("数据筛选")]),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          staticClass: "search-condition",
                          staticStyle: { padding: "10px" },
                          attrs: {
                            model: _vm.searchForm,
                            "status-icon": "",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "productCode",
                                            label: "产品编码:",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.productCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "productCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.productCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "productName",
                                            label: "产品名称:",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value: _vm.searchForm.productName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "productName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.productName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "productBrandId",
                                            label: "产品品牌:",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入",
                                            },
                                            model: {
                                              value:
                                                _vm.searchForm.productBrandId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "productBrandId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.productBrandId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "archivesStatus",
                                            label: "状态:",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.searchForm.archivesStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.searchForm,
                                                    "archivesStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "searchForm.archivesStatus",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "全部",
                                                  value: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "启用",
                                                  value: 1,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "禁用",
                                                  value: 0,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "删除",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "8" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                icon: "el-icon-search",
                                              },
                                              on: { click: _vm.getList },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-refresh",
                                              },
                                              on: { click: _vm.resetForm },
                                            },
                                            [_vm._v("重置")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                height: 400,
                                border: "",
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品图片",
                                  prop: "configCode",
                                  align: _vm.tableConfig.align,
                                  width: "125",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("img", {
                                          staticStyle: {
                                            width: "60px",
                                            height: "60px",
                                          },
                                          attrs: {
                                            src: row.productImages,
                                            fit: "contain",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编码",
                                  prop: "productCode",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productName",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品分类",
                                  prop: "productClassName",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品品牌",
                                  prop: "brandName",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格",
                                  prop: "productModel",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "状态",
                                  align: _vm.tableConfig.align,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        row.archivesStatus == 1
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "success" } },
                                              [_vm._v("启用")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.archivesStatus == 0
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "danger" } },
                                              [_vm._v("禁用")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.archivesStatus == 2
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "info" } },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  align: _vm.tableConfig.align,
                                  width: "80",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.DoSelect(row)
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total > 0",
                              },
                            ],
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.total,
                              page: _vm.searchForm.current,
                              limit: _vm.searchForm.size,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.searchForm,
                                  "current",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(_vm.searchForm, "size", $event)
                              },
                              pagination: _vm.getList,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }