"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Order',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    visible: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: '',
      listLoading: true,
      selLoading: false,
      total: 0,
      listQuery: {
        current: 1,
        batchNo: null,
        uploadMode: null,
        endTime: null,
        factoryCode: null,
        inboundStatus: null,
        materialCode: null,
        materialName: null,
        orderNo: null,
        orderSource: null,
        orderStatus: '16',
        // pageNo: '',
        // pageSize: '',
        productLineCode: null,
        shopCode: null,
        size: 20,
        startTime: null,
        storehouseCode: null,
        teamCode: null
      },
      list: [],
      multipleSelection: [],
      dateVlaue: ''
    };
  },
  mounted: function mounted() {
    this.getInitData();
  },
  methods: {
    getInitData: function getInitData() {
      var _this = this;
      this.listLoading = true;
      var sub = JSON.parse(JSON.stringify(this.listQuery));
      (0, _store.gettaskList)(sub).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this.total = Number(res.data.total);
          _this.list = res.data.records;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      }).then(function () {
        console.log(_this.data);
        _this.data.map(function (row) {
          var rowItem = _this.list.find(function (item) {
            return row.pid == item.pid;
          });
          // console.log(rowItem)
          _this.$refs.multipleTable.toggleRowSelection(rowItem || row, true);
        });
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      var sub = JSON.parse(JSON.stringify(this.listQuery));
      (0, _store.gettaskList)(sub).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this2.total = Number(res.data.total);
          _this2.list = res.data.records;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      }).then(function () {
        // this.multipleSelection.length && this.list.length &&
        // this.multipleSelection.map(row => {
        //   const rowItem = this.list.find(item => {
        //     return row.pid == item.pid
        //   })
        //   console.log(rowItem)
        //   rowItem && this.$refs.multipleTable.toggleRowSelection(rowItem, true)
        // })
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    datePickerFun: function datePickerFun(val) {
      if (!val) {
        val = [];
      }
      this.listQuery.startTime = val[0] || '';
      this.listQuery.endTime = val[1] || '';
    },
    handleClose: function handleClose() {
      this.list = [];
      this.$emit('close');
    },
    update: function update() {
      this.$emit('change', this.multipleSelection);
      this.handleClose();
    },
    tag1Close: function tag1Close(row) {
      var _this3 = this;
      this.$nextTick(function () {
        var closeItem = _this3.list.find(function (item) {
          return row.pid == item.pid;
        });
        _this3.$refs.multipleTable.toggleRowSelection(closeItem || row, false);
      });
    },
    restFun: function restFun() {
      this.dateVlaue = [];
      this.listQuery = {
        batchNo: null,
        uploadMode: null,
        current: 1,
        endTime: null,
        factoryCode: null,
        inboundStatus: null,
        materialCode: null,
        materialName: null,
        orderNo: null,
        orderSource: null,
        orderStatus: '16',
        productLineCode: null,
        shopCode: null,
        size: 20,
        startTime: null,
        storehouseCode: null,
        teamCode: null
      };
      this.fetchData();
    }
  }
};
exports.default = _default2;