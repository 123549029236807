"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gameConfigDelete = gameConfigDelete;
exports.gameConfigSave = gameConfigSave;
exports.gameConfigUpdateStatus = gameConfigUpdateStatus;
exports.gameListItemDelete = gameListItemDelete;
exports.gameListItemSave = gameListItemSave;
exports.getGameConfig = getGameConfig;
exports.getGameList = getGameList;
exports.getGameLog = getGameLog;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
function getGameList(data, current, size) {
  return (0, _request.default)({
    url: baseUrl + "/market-config/gameInfo/queryForPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
function gameListItemDelete(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/gameInfo/delete',
    method: 'post',
    params: params
  });
}
function gameListItemSave(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/gameInfo/save',
    method: 'post',
    data: data
  });
}
function getGameConfig(data, current, size) {
  return (0, _request.default)({
    url: baseUrl + "/market-config/gameConfig/queryForPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
function gameConfigDelete(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/gameConfig/delete',
    method: 'post',
    data: data
  });
}
function gameConfigSave(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/gameConfig/save',
    method: 'post',
    data: data
  });
}
function gameConfigUpdateStatus(params) {
  return (0, _request.default)({
    url: baseUrl + "/market-config/gameConfig/updateStatus",
    method: 'post',
    params: params
  });
}
function getGameLog(data, current, size) {
  return (0, _request.default)({
    url: baseUrl + "/market-config/gameRecord/queryForPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}