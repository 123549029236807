var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" } },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link" },
                [
                  _c("el-avatar", {
                    attrs: {
                      src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                    },
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    [
                      _c("el-avatar", {
                        attrs: {
                          src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    [
                      _c("el-avatar", {
                        attrs: {
                          src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "被关注回复", name: "first" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "first" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("h3", [_vm._v("被关注回复功能开关")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "用户关注公众号后，可快速进行自动回复。关闭自动回复之后，将立即对所有用户生效。"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    { attrs: { action: "" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          staticStyle: { width: "900px", height: "300px" },
                          attrs: {
                            type: "border-card",
                            "label-width": "100px",
                          },
                          on: { "tab-click": _vm.handleClick2 },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v("文本")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 8,
                                  maxlength: "600",
                                  "show-word-limit": "",
                                  placeholder: "请输入内容",
                                },
                                model: {
                                  value: _vm.bzghf.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bzghf, "content", $$v)
                                  },
                                  expression: "bzghf.content",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.bzgdialogVisible = true
                                    },
                                  },
                                },
                                [_vm._v("插入超链接")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dialog",
                                {
                                  attrs: {
                                    title: "",
                                    visible: _vm.bzgdialogVisible,
                                    width: "30%",
                                    "before-close": _vm.handleClose,
                                  },
                                  on: {
                                    "update:visible": function ($event) {
                                      _vm.bzgdialogVisible = $event
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        model: _vm.bzgclj,
                                        "label-width": "80px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "文本内容" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.bzgclj.content,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.bzgclj,
                                                  "content",
                                                  $$v
                                                )
                                              },
                                              expression: "bzgclj.content",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "链接地址" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.bzgclj.url,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.bzgclj, "url", $$v)
                                              },
                                              expression: "bzgclj.url",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dialog-footer",
                                      attrs: { slot: "footer" },
                                      slot: "footer",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.bzgdialogVisible = false
                                            },
                                          },
                                        },
                                        [_vm._v("取 消")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.bzgdialogVisible = false
                                            },
                                          },
                                        },
                                        [_vm._v("确 定")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "图片" } },
                            [
                              _vm.proUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProimg },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                action: _vm.posturl,
                                                "before-upload": _vm.imgSize,
                                                "on-success": _vm.handleSuccess,
                                                "on-error": _vm.handError,
                                                "file-list": _vm.Lists,
                                                "show-file-list": false,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                  },
                                                },
                                                [_vm._v("上传图片")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c(
                                          "img",
                                          _vm._b(
                                            {
                                              staticClass: "chenkimg",
                                              attrs: { alt: "" },
                                            },
                                            "img",
                                            { src: _vm.twImgurl + _vm.proUrl },
                                            false
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delProurl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "语音" } },
                            [
                              _vm.voUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProvoice },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.voiceUp = true
                                                },
                                              },
                                            },
                                            [_vm._v("上传语音")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c("audio", {
                                          attrs: { src: _vm.voUrl.url },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.voUrl.name)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delvourl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "视频" } },
                            [
                              _vm.viUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProvideo },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.videoUp = true
                                                },
                                              },
                                            },
                                            [_vm._v("上传视频")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c("video", {
                                          staticClass: "checkvi",
                                          attrs: { src: _vm.viUrl.url },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delviurl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "btn" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.isDsb,
                                  },
                                  on: { click: _vm.savedata },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 2, offset: 1 } },
                            [_c("el-button", [_vm._v("删除")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "收到消息回复", name: "second" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "first", gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("h3", [_vm._v("被关注回复功能开关")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "用户关注公众号后，可快速进行自动回复。关闭自动回复之后，将立即对所有用户生效。"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    { attrs: { action: "" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          staticStyle: { width: "900px", height: "300px" },
                          attrs: {
                            type: "border-card",
                            "label-width": "100px",
                          },
                          on: { "tab-click": _vm.handleClick3 },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "文字" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 8,
                                  placeholder: "请输入内容",
                                  maxlength: "600",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.sdhf.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sdhf, "content", $$v)
                                  },
                                  expression: "sdhf.content",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v("插入超链接"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-dialog",
                                {
                                  attrs: {
                                    title: "",
                                    visible: _vm.sddialogVisible,
                                    width: "30%",
                                    "before-close": _vm.handleClose,
                                  },
                                  on: {
                                    "update:visible": function ($event) {
                                      _vm.sddialogVisible = $event
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        model: _vm.sdclj,
                                        "label-width": "80px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "文本内容" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.sdclj.content,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sdclj,
                                                  "content",
                                                  $$v
                                                )
                                              },
                                              expression: "sdclj.content",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "链接地址" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.sdclj.url,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.sdclj, "url", $$v)
                                              },
                                              expression: "sdclj.url",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dialog-footer",
                                      attrs: { slot: "footer" },
                                      slot: "footer",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.sddialogVisible = false
                                            },
                                          },
                                        },
                                        [_vm._v("取 消")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.sddialogVisible = false
                                            },
                                          },
                                        },
                                        [_vm._v("确 定")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "图片" } },
                            [
                              _vm.proUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProimg },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              attrs: {
                                                action: _vm.posturl,
                                                "before-upload": _vm.imgSize,
                                                "on-success": _vm.handleSuccess,
                                                "on-error": _vm.handError,
                                                "file-list": _vm.Lists,
                                                "show-file-list": false,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                  },
                                                },
                                                [_vm._v("上传图片")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c(
                                          "img",
                                          _vm._b(
                                            {
                                              staticClass: "chenkimg",
                                              attrs: { alt: "" },
                                            },
                                            "img",
                                            { src: _vm.twImgurl + _vm.proUrl },
                                            false
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delProurl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "语音" } },
                            [
                              _vm.voUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProvoice },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.voiceUp = true
                                                },
                                              },
                                            },
                                            [_vm._v("上传语音")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c("audio", {
                                          attrs: { src: _vm.voUrl.url },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.voUrl.name)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delvourl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "视频" } },
                            [
                              _vm.viUrl == ""
                                ? _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "success",
                                                icon: "el-icon-folder",
                                              },
                                              on: { click: _vm.getProvideo },
                                            },
                                            [_vm._v("从素材库选择")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "col-sec",
                                          attrs: { span: 11, offset: 2 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.videoUp = true
                                                },
                                              },
                                            },
                                            [_vm._v("上传视频")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c("video", {
                                          staticClass: "checkvi",
                                          attrs: { src: _vm.viUrl.url },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 1 } },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              size: "mini",
                                              circle: "",
                                            },
                                            on: { click: _vm.delviurl },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "btn" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.isDsb1,
                                  },
                                  on: { click: _vm.savedata1 },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 2, offset: 1 } },
                            [_c("el-button", [_vm._v("删除")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "关键词回复", name: "third" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "first", gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("h3", [_vm._v("关键词回复开关")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "粉丝发送的消息中包含关键字将会优先关键字自动回复（如开启了完全匹配，需粉丝发送与设置一样的关键字才会自动回复）。关闭自动回复之后，将立即对所有用户生效。"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "first", gutter: 200 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              "prefix-icon": "el-icon-search",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchkeycode($event)
                              },
                            },
                            model: {
                              value: _vm.keycode.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.keycode, "keyword", $$v)
                              },
                              expression: "keycode.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addKeyws },
                            },
                            [_vm._v("添加关键词")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.keyload,
                          expression: "keyload",
                        },
                      ],
                      staticStyle: { width: "70%" },
                      attrs: { data: _vm.keycodeList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "规则名称",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "val", label: "关键词", width: "180" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "type", label: "匹配条件" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "发送次数", prop: "numb" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.numb == null
                                  ? _c("span", [_vm._v("0")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(scope.row.numb))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        _vm.centerDialogVisible = true
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteRow(
                                          scope.$index,
                                          _vm.tableData
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "添加关键词",
                        visible: _vm.centerDialogVisible,
                        width: "50%",
                        center: "",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.centerDialogVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.form } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "规格名称",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off" },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关键词",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _vm._l(
                                _vm.form.wxKeywordDTOList,
                                function (t, index) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: index,
                                      staticStyle: { "margin-bottom": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "关键词" },
                                              model: {
                                                value:
                                                  _vm.form.wxKeywordDTOList[
                                                    index
                                                  ].type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.wxKeywordDTOList[
                                                      index
                                                    ],
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.wxKeywordDTOList[index].type",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "部分匹配",
                                                  value: "1",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "完全匹配",
                                                  value: "2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 14, offset: 1 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              autocomplete: "off",
                                              "label-width": "80px",
                                            },
                                            model: {
                                              value:
                                                _vm.form.wxKeywordDTOList[index]
                                                  .val,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.wxKeywordDTOList[
                                                    index
                                                  ],
                                                  "val",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.wxKeywordDTOList[index].val",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.form.wxKeywordDTOList.length == 1
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-circle-plus-outline",
                                                  size: "small",
                                                  circle: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addKeyword(
                                                      index,
                                                      _vm.form.wxKeywordDTOList
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-remove-outline",
                                                  size: "small",
                                                  circle: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reduceKeyword(
                                                      index,
                                                      _vm.form.wxKeywordDTOList
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-button", {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-circle-plus-outline",
                                                  size: "small",
                                                  circle: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addKeyword(
                                                      index,
                                                      _vm.form.wxKeywordDTOList
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "部分匹配指包含该关键词即有效；完全匹配指与输入关键词完全相同才有效！"
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "回复内容",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _vm._l(
                                    _vm.form.wxContentReplyDOList,
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: index,
                                          staticClass: "wxContentReplyDOList",
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _c(
                                            "el-tabs",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "300px",
                                              },
                                              attrs: { type: "border-card" },
                                              on: {
                                                "tab-click": _vm.handleClick1,
                                              },
                                              model: {
                                                value:
                                                  _vm.keyWordtype[index].name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.keyWordtype[index],
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "keyWordtype[index].name",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tab-pane",
                                                {
                                                  attrs: {
                                                    label: "文本",
                                                    name: "1",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "textarea",
                                                      rows: 8,
                                                      placeholder: "请输入内容",
                                                      maxlength: "600",
                                                      "show-word-limit": "",
                                                      dat: (_vm.form.wxContentReplyDOList[
                                                        index
                                                      ].type =
                                                        _vm.keyWordtype[
                                                          index
                                                        ].name),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .wxContentReplyDOList[
                                                          index
                                                        ].content,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form
                                                            .wxContentReplyDOList[
                                                            index
                                                          ],
                                                          "content",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.wxContentReplyDOList[index].content",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tab-pane",
                                                {
                                                  attrs: {
                                                    label: "图片",
                                                    name: "2",
                                                  },
                                                },
                                                [
                                                  _vm.proUrl == ""
                                                    ? _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "success",
                                                                    icon: "el-icon-folder",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.getProimg,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "从素材库选择"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                                offset: 2,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  attrs: {
                                                                    action:
                                                                      _vm.posturl,
                                                                    "before-upload":
                                                                      _vm.imgSize,
                                                                    "on-success":
                                                                      _vm.handleSuccess,
                                                                    "on-error":
                                                                      _vm.handError,
                                                                    "file-list":
                                                                      _vm.Lists,
                                                                    "show-file-list": false,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        size: "small",
                                                                        type: "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "上传图片"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 4,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "img",
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "chenkimg",
                                                                    attrs: {
                                                                      alt: "",
                                                                    },
                                                                  },
                                                                  "img",
                                                                  {
                                                                    src:
                                                                      _vm.twImgurl +
                                                                      _vm.proUrl,
                                                                  },
                                                                  false
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 1,
                                                              },
                                                            },
                                                            [
                                                              _c("el-button", {
                                                                attrs: {
                                                                  type: "danger",
                                                                  icon: "el-icon-delete",
                                                                  size: "mini",
                                                                  circle: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.delProurl,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tab-pane",
                                                {
                                                  attrs: {
                                                    label: "语音",
                                                    name: "3",
                                                  },
                                                },
                                                [
                                                  _vm.voUrl == ""
                                                    ? _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "success",
                                                                    icon: "el-icon-folder",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.getProvoice,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "从素材库选择"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                                offset: 2,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.voiceUp = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "上传语音"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 4,
                                                              },
                                                            },
                                                            [
                                                              _c("audio", {
                                                                attrs: {
                                                                  src: _vm.voUrl
                                                                    .url,
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.voUrl
                                                                      .name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 1,
                                                              },
                                                            },
                                                            [
                                                              _c("el-button", {
                                                                attrs: {
                                                                  type: "danger",
                                                                  icon: "el-icon-delete",
                                                                  size: "mini",
                                                                  circle: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.delvourl,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tab-pane",
                                                {
                                                  attrs: {
                                                    label: "视频",
                                                    name: "4",
                                                  },
                                                },
                                                [
                                                  _vm.viUrl == ""
                                                    ? _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "success",
                                                                    icon: "el-icon-folder",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.getProvideo,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "从素材库选择"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticClass:
                                                                "col-sec",
                                                              attrs: {
                                                                span: 11,
                                                                offset: 2,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.videoUp = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "上传视频"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 4,
                                                              },
                                                            },
                                                            [
                                                              _c("video", {
                                                                staticClass:
                                                                  "checkvi",
                                                                attrs: {
                                                                  src: _vm.viUrl
                                                                    .url,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 1,
                                                              },
                                                            },
                                                            [
                                                              _c("el-button", {
                                                                attrs: {
                                                                  type: "danger",
                                                                  icon: "el-icon-delete",
                                                                  size: "mini",
                                                                  circle: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.delviurl,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "10px",
                                      },
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-circle-plus-outline",
                                          size: "small",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addcontent(
                                              _vm.form.wxContentReplyDOList
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  attrs: { "label-width": _vm.formLabelWidth },
                                },
                                [
                                  _vm._v(
                                    "出现同时可匹配多个关键词时，回复方式为："
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.radio,
                                    callback: function ($$v) {
                                      _vm.radio = $$v
                                    },
                                    expression: "radio",
                                  },
                                },
                                [_vm._v("随机回复一条")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  model: {
                                    value: _vm.radio,
                                    callback: function ($$v) {
                                      _vm.radio = $$v
                                    },
                                    expression: "radio",
                                  },
                                },
                                [_vm._v("全部回复")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.centerDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addKeyWordReply(_vm.form)
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "选择图片",
                visible: _vm.dialogVisibletp,
                width: "70%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibletp = $event
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading1,
                      expression: "loading1",
                    },
                  ],
                  staticStyle: {
                    "flex-wrap": "wrap",
                    "align-content": "space-around",
                  },
                  attrs: { type: "flex", justify: "start" },
                },
                _vm._l(_vm.Lists, function (o, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { span: 3 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "imglist",
                          on: {
                            click: function ($event) {
                              return _vm.tabImg(index, o)
                            },
                          },
                        },
                        [
                          _c(
                            "img",
                            _vm._b(
                              { attrs: { alt: "" } },
                              "img",
                              { src: _vm.twImgurl + o.url },
                              false
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.clickCheck != index ? "hide" : "sucheck",
                              ],
                            },
                            [_c("i", { staticClass: "el-icon-circle-check" })]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibletp = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibletp = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "选择语音",
                visible: _vm.dialogVisibleyy,
                width: "70%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibleyy = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading2,
                          expression: "loading2",
                        },
                      ],
                      staticStyle: { width: "70%" },
                      attrs: {
                        data: _vm.voLists,
                        "highlight-current-row": "",
                        fit: "",
                      },
                      on: { "current-change": _vm.handleCurrentChangevo },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "语音名称",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fileSize",
                          label: "大小",
                          width: "180",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "createTime", label: "更新时间" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pitchOn(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选中")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibleyy = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisibleyy = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading3,
                  expression: "loading3",
                },
              ],
              attrs: {
                title: "选择视频",
                visible: _vm.dialogVisiblesp,
                width: "70%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisiblesp = $event
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading3,
                      expression: "loading3",
                    },
                  ],
                  staticStyle: {
                    "flex-wrap": "wrap",
                    "align-content": "space-around",
                  },
                  attrs: { type: "flex", justify: "start" },
                },
                _vm._l(_vm.viLists, function (o, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { span: 3 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vilist",
                          on: {
                            click: function ($event) {
                              return _vm.tabVi(index, o)
                            },
                          },
                        },
                        [
                          _c(
                            "video",
                            _vm._b(
                              { attrs: { width: "100%" } },
                              "video",
                              { src: _vm.twImgurl + o.url },
                              false
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.clickCheck != index ? "hide" : "sucheckvi",
                              ],
                            },
                            [_c("i", { staticClass: "el-icon-circle-check" })]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisiblesp = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisiblesp = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "上传语音", visible: _vm.voiceUp },
              on: {
                "update:visible": function ($event) {
                  _vm.voiceUp = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.voiceForm } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "语音名称",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.voiceForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.voiceForm, "name", $$v)
                          },
                          expression: "voiceForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "语音内容",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.posturl,
                            "on-success": _vm.vohandleSuccess,
                            "before-upload": _vm.voSize,
                            accept:
                              ".MP3, .mp3, .WAV, .wav, .WMA, .wma, .AMR, .amr",
                            "show-file-list": false,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("上传语音")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "格式支持mp3、wma、wav、amr，文件大小不超过2M"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.voiceUp = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.upLoadvo } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                title: "上传视频",
                visible: _vm.videoUp,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.videoUp = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.videoForm } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "视频名称",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.videoForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.videoForm, "name", $$v)
                          },
                          expression: "videoForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "视频内容",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.posturl,
                            "on-success": _vm.vihandleSuccess,
                            "before-upload": _vm.viSize,
                            multiple: "",
                            accept: ".MP4, .mp4",
                            "show-file-list": false,
                            "on-exceed": _vm.handleExceed,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("上传视频")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "请上传时长小于10m的视频，支持mp4视频格式，超出限制的视频请到腾讯视频上传。"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.videoUp = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.upLoadvi } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }