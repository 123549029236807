"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _index = require("../../../../../../utils/index.js");
var _analyze = require("@/api/wxhyyy/analyze.js");
var _lineChart = _interopRequireDefault(require("../../menufxx/components/lineChart"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    LineChart: _lineChart.default,
    admincut: _admincut.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        return y + '-' + m + '-' + d;
      }
      return value;
    }
  },
  data: function data() {
    return {
      fansDate: [],
      // 粉丝数时间
      fansContrastDate: [],
      // 对比时间
      isFansContrastDate: true,
      // 是否对比
      fansTableDate: [],
      // 表单时间
      total: 0,
      // 总数
      current: 1,
      // 当前页
      pageSizes: [10, 20, 30, 40],
      // 每页显示的数量
      pageSize: 10,
      // 默认显示的数量
      records: [],
      // 粉丝表单列表
      ContrastData: {},
      totalObj: {},
      // 粉丝数
      totalList: [],
      // 粉丝数表单

      btnClass: 'fansCount',
      lineList: [{
        name: '粉丝总数',
        id: 'fansCount'
      }, {
        name: '新关注粉丝数',
        id: 'newFansCount'
      }, {
        name: '取消关注粉丝数',
        id: 'cancelFansCount'
      }, {
        name: '净增长粉丝数',
        id: 'netIncreaseFansCount'
      }, {
        name: '活跃用户数',
        id: 'activeUser'
      }, {
        name: '活跃率',
        id: 'activeRate'
      }, {
        name: '互动次数',
        id: 'enableCount'
      }],
      lineListData: {},
      lineChartData: {
        expectedData: [],
        actualData: [],
        xData: [],
        xxData: []
      },
      details: {},
      contrasDetails: {},
      aHref: ''
    };
  },
  watch: {
    // 监听用户分析时间
    fansDate: function fansDate(newValue, oldValue) {
      var _this = this;
      if (newValue === null) {
        this.fansDate = [];
      } else if (newValue !== null && this.fansDate.length === 2 && (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1])) {
        // this.getUserDataIncludeTotal(1,this.fansDate)
        (0, _analyze.userDataIncludeTotal)({
          appId: sessionStorage.getItem('appId'),
          startTime: this.fansDate[0],
          endTime: this.fansDate[1]
        }).then(function (resp) {
          var resp = resp.data;
          console.log(resp);
          var _resp = resp,
            code = _resp.code,
            data = _resp.data,
            msg = _resp.msg;
          if (code === 200) {
            // isFansContrastDate为真 无对比状态
            if (data) {
              var total = data.total,
                details = data.details;
              total.date = _this.fansDate.join('~');
              _this.totalObj = total;
              _this.details = details;
            } else {
              _this.totalObj = {
                date: _this.fansDate.join('~')
              };
              _this.details = [];
            }
            if (_this.fansContrastDate.length === 2) {
              _this.totalList = [_this.totalObj, _this.ContrastData];
            } else {
              _this.totalList = [_this.totalObj];
            }
            if (_this.isFansContrastDate) {
              _this.DoFilterData(_this.details, 'single');
            } else {
              var Ldetails = {
                newsByDate: _this.details,
                contrastNewsByDate: _this.contrasDetails
              };
              _this.DoFilterData(Ldetails, 'double');
            }
          } else {
            _this.$message(msg);
          }
        });
      }
    },
    // 监听用户分析对比时间
    fansContrastDate: function fansContrastDate(newValue, oldValue) {
      var _this2 = this;
      if (newValue !== null) {
        if (newValue !== '') {
          (0, _analyze.userDataIncludeTotal)({
            appId: sessionStorage.getItem('appId'),
            startTime: this.fansContrastDate[0],
            endTime: this.fansContrastDate[1]
          }).then(function (resp) {
            var resp = resp.data;
            console.log(resp);
            var _resp2 = resp,
              code = _resp2.code,
              data = _resp2.data,
              msg = _resp2.msg;
            if (code === 200) {
              if (data) {
                var total = data.total,
                  details = data.details;
                total.date = _this2.fansContrastDate.join('~');
                _this2.contrasDetails = details;
                _this2.ContrastData = total;
                if (_this2.fansDate.length === 2) {
                  _this2.totalList.splice(1, 1, total);
                  var obj = {
                    newsByDate: _this2.details,
                    contrastNewsByDate: _this2.contrasDetails
                  };
                  _this2.DoFilterData(obj, 'double');
                } else {
                  _this2.$message('请输入或选择时间对比起始时间');
                }
              } else {
                var _total = {
                  date: _this2.fansContrastDate.join('~')
                };
                _this2.contrasDetails = [];
                _this2.ContrastData = _total;
                if (_this2.fansDate.length === 2) {
                  _this2.totalList.splice(1, 1, _total);
                  var _obj = {
                    newsByDate: _this2.details,
                    contrastNewsByDate: _this2.contrasDetails
                  };
                  _this2.DoFilterData(_obj, 'double');
                } else {
                  _this2.$message('请输入或选择时间对比起始时间');
                }
              }
            } else {
              _this2.$message(msg);
            }
          });
        } else {
          this.totalList.splice(1, 1);
          this.DoFilterData(this.details, 'single');
          this.ContrastData = {};
        }
      }
    },
    // 监听用户表单时间
    fansTableDate: function fansTableDate(newValue, oldValue) {
      if (newValue !== null) {
        this.userDataFun({
          appId: sessionStorage.getItem('appId'),
          endTime: this.fansTableDate[1],
          pageNo: 1,
          pageSize: 10,
          startTime: this.fansTableDate[0]
        });
      }
    }
  },
  mounted: function mounted() {
    this.fansDate = this.getDate(30);
    this.fansTableDate = this.getDate(30);
  },
  methods: {
    // 每页显示多少条
    handleSizeChange: function handleSizeChange(val) {
      if (this.total > this.pageSize || this.records.length > val) {
        this.pageSize = val;
        this.userDataFun({
          appId: sessionStorage.getItem('appId'),
          endTime: this.fansTableDate[1],
          pageNo: 1,
          pageSize: this.pageSize,
          startTime: this.fansTableDate[0]
        });
      }
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.current = val;
      this.userDataFun({
        appId: sessionStorage.getItem('appId'),
        endTime: this.fansTableDate[1],
        pageNo: this.current,
        pageSize: this.pageSize,
        startTime: this.fansTableDate[0]
      });
    },
    // 最近30天
    latelyFun: function latelyFun() {
      this.fansDate = this.getDate(30);
    },
    // 获取时间
    getDate: function getDate(val) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      var arr = [(0, _index.parseTime)(start, '{y}-{m}-{d}'), (0, _index.parseTime)(end, '{y}-{m}-{d}')];
      return arr;
    },
    // 获取用户表单
    userDataFun: function userDataFun(data) {
      var _this3 = this;
      (0, _analyze.userData)({
        appId: data.appId,
        endTime: data.endTime,
        pageNo: data.pageNo,
        pageSize: data.pageSize,
        startTime: data.startTime
      }).then(function (resp) {
        var resp = resp.data;
        var _resp3 = resp,
          code = _resp3.code,
          data = _resp3.data,
          msg = _resp3.msg;
        console.log(resp);
        if (code === 200) {
          if (data) {
            _this3.records = data.records;
            _this3.total = data.total;
            _this3.aHref = "/api-memberscrm/member/admin/userAnalysis/userDataDown?appId=".concat(sessionStorage.getItem('appId'), "&startTime=").concat(_this3.fansTableDate[0], "&endTime=").concat(_this3.fansTableDate[1]);
          } else {
            _this3.records = [];
          }
        } else {
          _this3.$message(msg);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 切换 对比
    isFansContrastFun: function isFansContrastFun(e) {
      if (e) {
        this.isFansContrastDate = false;
      } else {
        this.isFansContrastDate = true;
        this.fansContrastDate = '';
      }
    },
    // 切换数据种类
    linefun: function linefun(val) {
      var that = this;
      that.btnClass = val;
      // const xdad = that.lineListData[val].xData
      // const xxdad = that.lineListData[val].xxData
      // if (xdad.length !== xxdad.length) {
      //   if (xdad.length > xxdad.length) {
      //     console.log(xdad)
      //     const a = xdad.length - xxdad.length
      //     for (let i = 0; i < a; i++) {
      //       let c = Math.ceil(a / (2 + i))
      //       if (c === (xdad.length - 1)) {
      //         c = Math.ceil(a / (3 + i))
      //       }
      //       console.log(c)
      //       xdad.splice(c, 1)
      //     }
      //     that.lineListData[val].xData = xdad
      //   } else {
      //     console.log(xxdad)
      //     const b = xxdad.length - xdad.length
      //     for (let i = 0; i < b; i++) {
      //       let d = Math.ceil(b / (2 + i))
      //       if (d === (xxdad.length - 1)) {
      //         d = Math.ceil(b / (3 + i))
      //       }
      //       console.log(d)
      //       xxdad.splice(d, 1)
      //     }
      //     that.lineListData[val].xxData = xxdad
      //   }
      // }
      // console.log(that.lineListData)
      this.datasplice(that.lineListData[val]);
      //  that.lineChartData = that.lineListData[val]

      // this.lineChartData = this.lineListData[val]
    },
    getUserDataIncludeTotal: function getUserDataIncludeTotal(type, time) {},
    // 过滤折线数据
    DoFilterData: function DoFilterData(data, type) {
      var _this4 = this;
      console.log(data);
      if (type === 'single') {
        this.lineList.forEach(function (item, index) {
          _this4.lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        if (data.length > 0) {
          data.forEach(function (item, index) {
            Object.keys(_this4.lineListData).forEach(function (key) {
              _this4.lineListData[key].expectedData.push(item[key]);
              _this4.lineListData[key].xData.push((0, _index.parseTime)(item.createDate, '{y}-{m}-{d}'));
            });
          });
        }
        this.lineChartData = this.lineListData[this.lineList[0].id];
        this.datasplice(this.lineListData[this.lineList[0].id]);
        // const xdad = this.lineChartData.xData
        // const xxdad = this.lineChartData.xxData
        // if (xdad.length !== xxdad.length) {
        //   if (xdad.length > xxdad.length) {
        //     console.log(xdad)
        //     const a = xdad.length - xxdad.length
        //     for (let i = 0; i < a; i++) {
        //       let c = Math.ceil(a / (2 + i))
        //       if (c === (xdad.length - 1)) {
        //         c = Math.ceil(a / (3 + i))
        //       }
        //       console.log(c)
        //       xdad.splice(c, 1)
        //     }
        //     this.lineChartData.xData = xdad
        //   } else {
        //     console.log(xxdad)
        //     const b = xxdad.length - xdad.length
        //     for (let i = 0; i < b; i++) {
        //       let d = Math.ceil(b / (2 + i))
        //       if (d === (xxdad.length - 1)) {
        //         d = Math.ceil(b / (3 + i))
        //       }
        //       console.log(d)
        //       xxdad.splice(d, 1)
        //     }
        //     this.lineChartData.xxData = xxdad
        //   }
        // }
        console.log(this.lineChartData);
      } else {
        this.lineList.forEach(function (item, index) {
          _this4.lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        var lineListData = {};
        this.lineList.forEach(function (item, index) {
          lineListData[item.id] = {
            expectedData: [],
            actualData: [],
            xData: [],
            xxData: []
          };
        });
        if (data.newsByDate === '' || data.newsByDate === null) {
          this.lineList.forEach(function (item) {
            lineListData[item.id].expectedData = [];
            lineListData[item.id].xData = [];
          });
        } else {
          data.newsByDate.forEach(function (item, index) {
            Object.keys(_this4.lineListData).forEach(function (key) {
              lineListData[key].expectedData.push(item[key]);
              lineListData[key].xData.push((0, _index.parseTime)(item.createDate, '{y}-{m}-{d}'));
            });
          });
        }
        if (data.contrastNewsByDate === '' || data.contrastNewsByDate === null) {
          this.lineList.forEach(function (item) {
            lineListData[item.id].actualData = [];
            lineListData[item.id].xxData = [];
          });
        } else {
          data.contrastNewsByDate.forEach(function (item, index) {
            Object.keys(_this4.lineListData).forEach(function (key) {
              lineListData[key].actualData.push(item[key]);
              lineListData[key].xxData.push((0, _index.parseTime)(item.createDate, '{y}-{m}-{d}'));
            });
          });
        }
        this.lineListData = lineListData;
        // this.lineChartData = lineListData[this.lineList[0].id]
        this.datasplice(lineListData[this.lineList[0].id]);
        // const xdad = this.lineChartData.xData
        // const xxdad = this.lineChartData.xxData
        // if (xdad.length !== xxdad.length) {
        //   if (xdad.length > xxdad.length) {
        //     console.log(xdad)
        //     const a = xdad.length - xxdad.length
        //     for (let i = 0; i < a; i++) {
        //       let c = Math.ceil(a / (2 + i))
        //       if (c === (xdad.length - 1)) {
        //         c = Math.ceil(a / (3 + i))
        //       }
        //       console.log(c)
        //       xdad.splice(c, 1)
        //     }
        //     this.lineChartData.xData = xdad
        //   } else {
        //     console.log(xxdad)
        //     const b = xxdad.length - xdad.length
        //     for (let i = 0; i < b; i++) {
        //       let d = Math.ceil(b / (2 + i))
        //       if (d === (xxdad.length - 1)) {
        //         d = Math.ceil(b / (3 + i))
        //       }
        //       console.log(d)
        //       xxdad.splice(d, 1)
        //     }
        //     this.lineChartData.xxData = xxdad
        //   }
        // }
        console.log(this.lineChartData);
      }
    },
    userDataDownFun: function userDataDownFun() {
      this.$message.closeAll();
      this.$message.error('暂无数据可下载，切换日期看看');
    },
    datasplice: function datasplice(val) {
      var that = this;
      var xdad = val.xData;
      var xxdad = val.xxData;
      if (xdad.length !== xxdad.length && xxdad.length !== 0 && xdad.length !== 0) {
        if (xdad.length > xxdad.length) {
          var a = xdad.length - xxdad.length;
          for (var i = 0; i < a; i++) {
            var c = Math.ceil(a / (2 + i));
            if (c === xdad.length - 1) {
              c = Math.ceil(a / (3 + i));
            }
            xdad.splice(c, 1);
          }
          val.xData = xdad;
        } else {
          var b = xxdad.length - xdad.length;
          for (var _i = 0; _i < b; _i++) {
            var d = Math.ceil(b / (2 + _i));
            if (d === xxdad.length - 1) {
              d = Math.ceil(b / (3 + _i));
            }
            console.log(d);
            xxdad.splice(d, 1);
          }
          val.xxData = xxdad;
        }
      }
      that.lineChartData = val;
    },
    downLoad: function downLoad() {
      var that = this;
      // appId=${sessionStorage.getItem('appId')}&startTime=${
      //           this.fansTableDate[0]
      //         }&endTime=${this.fansTableDate[1]}`
      var appId = sessionStorage.getItem('appId');
      console.log(appId);
      (0, _analyze.downLoadData)({
        appId: appId,
        startTime: this.fansTableDate[0],
        endTime: this.fansTableDate[1]
      }).then(function (res) {
        var res = res.data;
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '粉丝列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function () {
        that.downLoading = false;
      });
    }
  }
};
exports.default = _default;