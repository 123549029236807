"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _factory = require("@/api/basic/factory");
var _factoryDialog = _interopRequireDefault(require("@/components/factoryDialog"));
var _user = require("@/api/user");
var _dealer = require("@/api/basic/dealer");
var _org = require("@/api/basic/org");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _utils = require("@/utils");
var _salesArea = require("@/api/basic/salesArea");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BasicDatafactorymangefactoryfileindex",
  components: {
    Pagination: _Pagination.default,
    productDialog: _productDialog.default,
    factoryDialog: _factoryDialog.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return "";
      var statusMap = {
        1: "工厂",
        2: "车间",
        3: "产线",
        4: "班组"
      };
      return statusMap[status];
    },
    enterpriseTypeName: function enterpriseTypeName(type) {}
  },
  inject: ["reload"],
  data: function data() {
    var checkUsccCode = function checkUsccCode(rule, value, callback) {
      // if (!value) {
      //   callback(new Error('请填写当前平台类型的信用代码'))
      // } else {
      var a = /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
      if (!a.test(value)) {
        callback(new Error("请输入正确的统一社会信用代码"));
      }
      callback();
      // }
    };

    var validatewarningDesc = function validatewarningDesc(rule, value, callback) {
      var reg = /^[\s]|[ ]$/gi;
      if (reg.test(value)) {
        callback(new Error("联系电话不能以' '开头或结尾"));
      } else {
        callback();
      }
    };
    return {
      orgList: [],
      filterText: "",
      lastNode: "",
      lastResolve: "",
      listLoading: false,
      btnLoading: false,
      mloading: false,
      page2: false,
      proShow: false,
      facShow: false,
      currentFacId: "",
      tableKey: "111",
      tabHeight: "100%",
      activeClass: "",
      searchForm: {
        current: 1,
        factoryCode: "",
        factoryName: "",
        isEnable: "",
        parentId: "",
        size: 20,
        type: 1
      },
      tableData: [],
      areaList: [],
      areaSelectList: [],
      normalList: [{
        pId: 0,
        factoryName: "所有工厂",
        leaf: true
      }],
      total: 0,
      ruleForm: {
        factoryName: "",
        factoryCode: "",
        allSelect: 1,
        orgId: "",
        depId: "",
        isEnable: 1,
        list: [],
        parentId: "",
        provinceId: "",
        cityId: "",
        countyId: "",
        type: 1,
        detailedAddress: "",
        phone: "",
        factoryEnterPriseCodeList: [{
          enterpriseType: "",
          enterpriseCreditCode: ""
        }],
        productionLicense: "",
        relationFactoryIds: ""
      },
      type: 0,
      rules: {
        factoryName: [{
          required: true,
          message: "名称不能为空，请维护",
          trigger: "submit"
        }],
        factoryCode: [{
          required: true,
          message: "编码不能为空，请维护",
          trigger: "submit"
        }],
        allSelect: [{
          required: true,
          message: "生产产品范围不能为空，请维护",
          trigger: "submit"
        }],
        phone: [{
          validator: validatewarningDesc,
          trigger: "change"
        }],
        enterpriseCreditCode: [{
          required: true,
          validator: checkUsccCode,
          message: "xx",
          trigger: "submit"
        }]
      },
      props: {
        id: "pId",
        label: "name",
        // children: 'children'
        isLeaf: "leaf"
      },
      title: "",
      dialogVisible: false,
      depList: [],
      USCITypeList: [],
      salesmanIds: [],
      disabled: false,
      scanVisible: false,
      saveNode: {}
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    this.getAreaList();
    this.getOrgListAll();
    this.getUSCITypeList();
  },
  methods: {
    // 获取企业信用代码类型
    getUSCITypeList: function getUSCITypeList() {
      var that = this;
      var param = {};
      param.dictCode = "ENTERPRISE_TYPE";
      (0, _user.getDictionaryListByCode)(param).then(function (res) {
        if (res.data.code == 200) {
          that.USCITypeList = res.data.data;
        } else {
          that.USCITypeList = [];
        }
      });
    },
    addUSCI: function addUSCI() {
      this.ruleForm.factoryEnterPriseCodeList.push({
        enterpriseType: "",
        enterpriseCreditCode: ""
      });
    },
    deleteUSCI: function deleteUSCI(index) {
      this.ruleForm.factoryEnterPriseCodeList.splice(index, 1);
    },
    openSelFac: function openSelFac() {
      // this.facShow = true
      this.$refs.facSelBox.facSelVisible = true;
      // this.$refs.facSelBox.setCurrentRow()
    },
    setSelFac: function setSelFac(val) {
      console.log(val, "选择");
      this.salesmanIds = val;
      this.facShow = false;
    },
    tag1Close: function tag1Close(tag) {
      if (!this.disabled) {
        this.salesmanIds.splice(this.salesmanIds.indexOf(tag), 1);
      }
    },
    changeorg: function changeorg(orgId) {
      var _this = this;
      this.ruleForm.depId = "";
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    changetab: function changetab(tab) {
      if (tab == 0) {
        this.ruleForm.list = [];
      }
    },
    getAreaList: function getAreaList() {
      var _this2 = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(res.data.data, "pId", "parentId", "children");
            _this2.areaList = data;
          }
        }
      });
    },
    getOrgListAll: function getOrgListAll() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: "",
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgList = response.data.data;
        }
      });
    },
    getList: function getList(val) {
      var _this3 = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _factory.factoryInfoList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    getNode: function getNode(data, node, el) {
      this.lastNode = node;
      this.saveNode = node;
    },
    getNodeChildren: function getNodeChildren() {
      var _this4 = this;
      var arr = [];
      var param = {};
      this.authId("searchbtn", this.$route.meta.authList);
      if (this.lastNode.level > 2) {
        return;
      }
      if (this.lastNode.data.parentId) {
        param.parentId = this.lastNode.data.parentId;
      } else {
        param.type = 1;
      }
      (0, _factory.factoryInfoAllList)(param).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            res.data.data.forEach(function (item) {
              if (item.isSon == 0) {
                item.leaf = true;
              } else {
                item.leaf = false;
              }
            });
            arr = res.data.data;
          }
          if (_this4.lastNode.level == 1 || !_this4.lastNode) {
            _this4.refreshLazyTree(_this4.lastNode, arr);
          } else {
            _this4.refreshLazyTree(_this4.lastNode.parent, arr);
          }
        }
      });
    },
    refreshLazyTree: function refreshLazyTree(node, children) {
      var theChildren = node.childNodes;
      theChildren.splice(0, theChildren.length);
      node.doCreateChildren(children);
    },
    loadNode: function loadNode(node, resolve) {
      var _this5 = this;
      this.getNode("", node);
      if (node.level == 0) {
        return resolve(this.normalList);
      }
      var param = {};
      if (node.level === 1) {
        param.type = 1;
      }
      if (node.level > 1) {
        param.parentId = node.data.pId;
      }
      (0, _factory.factoryInfoAllList)(param).then(function (res) {
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            res.data.data.forEach(function (item) {
              if (item.isSon == 0 || node.level > 1) {
                item.leaf = true;
              } else {
                item.leaf = false;
              }
            });
            return resolve(res.data.data);
          } else {
            return [];
          }
        }
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.factoryName && data.factoryName.indexOf(value) !== -1 || data.factoryCode && data.factoryCode.indexOf(value) !== -1;
    },
    handleFilter: function handleFilter() {
      this.$refs.tree.filter(this.filterText);
    },
    scan: function scan(data) {
      var id = data.pId;
      this.activeClass = id;
      if (id == 0) {
        this.resetForm();
        this.ruleForm.parentId = "";
      } else {
        this.type = data.type;
        this.searchForm.type = "";
        this.searchForm.parentId = id;
        this.ruleForm.parentId = id;
        this.getList();
      }
    },
    duplicated: function duplicated(array) {
      for (var i = 0; i < array.length; i++) {
        if (array.indexOf(array[i]) < array.lastIndexOf(array[i]) && array[i]) {
          return true;
        }
      }
      return false;
    },
    submitForm: function submitForm() {
      var _this6 = this;
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          _this6.btnLoading = true;
          _this6.ruleForm.provinceId = _this6.areaSelectList[0];
          _this6.ruleForm.cityId = _this6.areaSelectList[1];
          _this6.ruleForm.countyId = _this6.areaSelectList[2];
          var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this6.ruleForm)));
          var arr = [];
          if (_this6.ruleForm.list.length) {
            _this6.ruleForm.list.forEach(function (item) {
              var obj = {
                productArchivesId: item.pId,
                productClassId: item.productClassId
              };
              arr.push(obj);
            });
          }
          var factoryIds = [];
          _this6.salesmanIds.map(function (i) {
            factoryIds.push(i.pid);
          });
          submitData.list = arr;

          // if (!factoryIds.length && (this.type == 0)) {
          //   this.btnLoading = false
          //   return this.$message('请至少选择一个生产分工厂')
          // }
          if (_this6.type == 0) {
            var typeArray = [];
            var codeArray = [];
            submitData.factoryEnterPriseCodeList.map(function (i) {
              typeArray.push(i.enterpriseType);
              codeArray.push(i.enterpriseCreditCode);
            });
            if (_this6.duplicated(typeArray) || _this6.duplicated(codeArray)) {
              _this6.btnLoading = false;
              return _this6.$message("平台类型、信用代码不能重复");
            }
          }
          submitData.relationFactoryIds = factoryIds.join(",");
          delete submitData.salesmanIds;
          if (_this6.ruleForm.pId) {
            (0, _factory.factoryInfoUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this6.$message.error(res.data.msg);
              } else {
                _this6.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this6.handleClose();
                _this6.getList();
                _this6.getNodeChildren();
              }
              _this6.btnLoading = false;
            }).catch(function () {
              _this6.btnLoading = false;
            });
          } else {
            (0, _factory.factoryInfoInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this6.$message.error(res.data.msg);
              } else {
                _this6.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this6.handleClose();
                _this6.getList();
                _this6.getNodeChildren();
              }
              _this6.btnLoading = false;
            }).catch(function () {
              _this6.btnLoading = false;
            });
          }
        }
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        factoryCode: "",
        factoryName: "",
        isEnable: "",
        parentId: "",
        size: 20,
        type: 1
      };
      this.type = 0;
      this.getList();
      this.handleFilter();
    },
    add: function add() {
      var _this7 = this;
      var node = this.saveNode;
      this.authId("addbtn", this.$route.meta.authList);
      switch (Number(this.type)) {
        case 0:
          this.title = "添加工厂";
          this.getAreaList();
          this.getOrgListAll();
          this.ruleForm.type = 1;
          break;
        case 1:
          this.title = "添加车间";
          this.ruleForm.type = 2;
          break;
        default:
          this.title = "添加产线/班组";
          this.ruleForm.type = 3;
      }
      var spid = null;
      if (node.level == 2) {
        spid = node.data.pId;
      } else if (node.level == 3) {
        spid = node.parent.data.pId;
      }
      if (node.level == 2 || node.level == 3) {
        (0, _factory.factoryInfoById)({
          pId: spid
        }).then(function (res) {
          if (res.data.code != 200) {
            _this7.$message.error(res.data.msg);
          } else {
            var data = res.data.data.factoryInfoDO;
            _this7.ruleForm.orgId = data.orgId;
            _this7.ruleForm.depId = data.depId;
            console.log(_this7.ruleForm.orgId, _this7.ruleForm.depId);
          }
        });
      }
      this.dialogVisible = true;
    },
    detail: function detail(row) {
      this.title = "查看产品档案";
      this.disabled = true;
      this.setDetail(row);
      this.ruleForm.productClassName = row.productClassName;
      this.ruleForm.packScaleDesc = row.packScaleDesc;
      this.ruleForm.brandName = row.brandName;
      this.ruleForm.extendList = row.extendList;
      this.scanVisible = true;
    },
    setDetail: function setDetail(row) {
      var _this8 = this;
      (0, _factory.factoryInfoById)({
        pId: row.pId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this8.$message.error(res.data.msg);
        } else {
          var data = res.data.data.factoryInfoDO;
          _this8.ruleForm.pId = data.pid;
          _this8.ruleForm.orgId = data.orgId;
          if (_this8.title == "编辑工厂") {
            _this8.changeorg(_this8.ruleForm.orgId);
          }
          _this8.ruleForm.allSelect = Number(data.allSelect);
          // this.ruleForm.factoryCode = data.factoryCode || ''
          _this8.ruleForm.factoryName = data.factoryName || "";
          _this8.ruleForm.isEnable = data.isEnable || "";
          _this8.ruleForm.depId = data.depId;
          _this8.ruleForm.type = data.type || "";
          _this8.ruleForm.parentId = data.parentId || "";
          _this8.ruleForm.factoryCode = data.factoryCode || "";
          _this8.ruleForm.list = [];
          _this8.ruleForm.detailedAddress = data.detailedAddress || "";
          _this8.ruleForm.phone = data.phone || "";
          _this8.ruleForm.productionLicense = data.productionLicense || "";
          _this8.ruleForm.factoryEnterPriseCodeList = res.data.data.factoryEnterpriseCodeVoList;
          // this.salesmanIds = res.data.data.relationFactoryList

          if (res.data.data.relationFactoryList) {
            _this8.salesmanIds = [];
            res.data.data.relationFactoryList.forEach(function (item) {
              _this8.salesmanIds.push({
                pid: item.pid,
                factoryCode: item.factoryCode,
                factoryName: item.factoryName
              });
            });
          }
          var arr = [];
          if (data.provinceId) arr.push(data.provinceId);
          if (data.cityId) arr.push(data.cityId);
          if (data.countyId) arr.push(data.countyId);
          _this8.areaSelectList = arr;
          if (res.data.data.productList && res.data.data.productList.length > 0) {
            res.data.data.productList.forEach(function (item) {
              var obj = {
                pId: item.productArchivesId,
                productClassName: item.productClassName,
                productCode: item.productCode,
                productName: item.productName,
                packScaleDesc: item.packScaleDesc,
                productArchivesId: item.productArchivesId,
                packScaleExpression: item.packScaleExpression,
                productClassId: item.productClassId,
                packCode: item.packCode
              };
              _this8.ruleForm.list.push(obj);
            });
          }
        }
        _this8.mloading = false;
      }).catch(function () {
        _this8.mloading = false;
      });
    },
    edit: function edit(row) {
      this.authId("updatebtn", this.$route.meta.authList);
      switch (Number(this.type)) {
        case 0:
          this.title = "编辑工厂";
          this.getAreaList();
          this.getOrgListAll();
          break;
        case 1:
          this.title = "编辑车间";
          break;
        default:
          this.title = "编辑产线/班组";
      }
      this.dialogVisible = true;
      this.mloading = true;
      this.currentFacId = row.pId;
      this.setDetail(row);
    },
    handleCloseScan: function handleCloseScan() {
      this.scanVisible = false;
    },
    handleClose: function handleClose() {
      this.$refs.ruleForm.resetFields();
      this.ruleForm.factoryName = "";
      this.ruleForm.provinceId = "";
      this.ruleForm.cityId = "";
      this.ruleForm.countyId = "";
      this.ruleForm.detailedAddress = "";
      // this.title = ''
      this.ruleForm.phone = "";
      this.ruleForm.orgId = "";
      this.ruleForm.depId = "";
      this.ruleForm.factoryCode = "";
      this.ruleForm.list = [];
      this.ruleForm.isEnable = 1;
      this.ruleForm.productionLicense = "";
      this.ruleForm.factoryEnterPriseCodeList = [{
        enterpriseType: "",
        enterpriseCreditCode: ""
      }];
      this.ruleForm.relationFactoryIds = "";
      this.salesmanIds = [];
      this.currentFacId = "";
      delete this.ruleForm.pId;
      this.areaSelectList = [];
      this.dialogVisible = false;
      this.authId("searchbtn", this.$route.meta.authList);
    },
    openSel: function openSel() {
      this.proShow = true;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    facClose: function facClose() {
      this.facShow = false;
    },
    setSel: function setSel(val) {
      this.ruleForm.list = val;
      this.proShow = false;
    },
    proDel: function proDel(row) {
      this.ruleForm.list.splice(this.ruleForm.list.indexOf(row), 1);
      this.proShow = false;
    },
    updateStatus: function updateStatus(row, type) {},
    deleteID: function deleteID(row) {
      var _this9 = this;
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _factory.deleteinfo)({
          pId: row.pId,
          isDelete: 0
        }).then(function (res) {
          if (res.data.code != 200) {
            _this9.$message.error(res.data.msg);
          } else {
            _this9.$message({
              message: "操作成功",
              type: "success"
            });
            _this9.reload();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;