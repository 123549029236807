"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _message = require("@/api/systems/message");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'NoticeDetail',
  filters: {
    noticeType: function noticeType(status) {
      if (!String(status)) return '\xa0';
      var obj = {
        1: '系统公告'
      };
      return obj[status];
    }
  },
  props: {
    id: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    orgNameShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      mLoading: false,
      scanForm: {
        noticeType: 1,
        startTime: '',
        noticeTitle: '',
        noticeContent: '',
        orgName: ''
      }
    };
  },
  mounted: function mounted() {
    this.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      this.mLoading = true;
      (0, _message.noticeDetail)({
        pId: this.id
      }).then(function (res) {
        _this.mLoading = false;
        var data = res.data;
        if (data.code == 200) {
          _this.scanForm = Object.assign(_this.scanForm, data.data.noticeDetail);
          var arr = [];
          data.data.orgInfoVos.forEach(function (val) {
            arr.push(val.orgName);
          });
          _this.scanForm.orgName = arr.join(',');
        } else {
          _this.$message.error(data.msg);
        }
      }).catch(function () {
        _this.mLoading = false;
      });
    },
    close: function close(row) {
      this.$emit('close');
    }
  }
};
exports.default = _default2;