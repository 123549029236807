"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _register = require("@/api/terminal/register");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import { formatDate } from '@/api/mark/basic'
var _default = {
  name: '',
  components: {
    // Pagination
  },
  filters: {
    createType: function createType(val) {
      if (!String(val)) return '';
      var createTypeMap = {
        1: '移动端注册',
        2: '移动端认领'
      };
      return createTypeMap[val];
    },
    auditStatus: function auditStatus(val) {
      if (!String(val)) return '';
      var createTypeMap = {
        1: '待审核',
        2: '审核通过',
        3: '审核驳回'
      };
      return createTypeMap[val];
    }
  },
  props: {},
  data: function data() {
    return {
      detailVisible: false,
      title: '活动详情',
      mloading: false,
      detailShow: false,
      detailData: {},
      listLoading: false,
      reverse: false,
      ruleForm: {
        pid: '',
        auditStatus: '',
        remark: ''
      },
      rules: {
        auditStatus: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      settype: 1 // 1查看,2审核
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(rows, settype) {
      console.log(rows, 'rows');
      this.detailVisible = true;
      if (settype == 1) {
        this.title = '查看';
        this.detailShow = true;
      } else if (settype == 2) {
        this.title = '审批';
        this.detailShow = false;
      }
      this.settype = settype;
      //   this.showflow = false
      //   if (settype == 3) {
      //     this.showflow = true
      //   }
      //   this.detailVisible = true
      //   this.mloading = true
      //   this.num = rows.num
      //   this.mloading = false

      this.getList(rows);
    },
    getList: function getList(data) {
      var _this = this;
      this.mloading = true;
      (0, _register.getAuditDetail)({
        pId: data.pId
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          _this.mloading = false;
          return;
        } else {
          _this.detailData = res.data.data;
          if (_this.detailData.organizationCode) {
            _this.detailData.list = [{
              companyName: _this.detailData.companyName,
              legalName: _this.detailData.legalName,
              organizationCode: _this.detailData.organizationCode,
              registerAddress: _this.detailData.registerAddress,
              registerTime: _this.detailData.registerTime
            }];
          }
          _this.detailData.list2 = [{
            channelCode: _this.detailData.channelCode,
            channelName: _this.detailData.channelName
          }];
          _this.ruleForm.pid = _this.detailData.pid;
          if (_this.detailData.auditFlowVoList.length > 0) {
            if (_this.detailData.auditFlowVoList[0].auditStatus == 2) {
              _this.detailShow = true;
              _this.ruleForm.auditStatus = _this.detailData.auditFlowVoList[0].auditStatus;
              _this.ruleForm.remark = _this.detailData.auditFlowVoList[0].remark;
            } else {
              _this.detailShow = false;
            }
          }
          _this.mloading = false;
        }
      });
    },
    handleClose: function handleClose() {
      this.detailData = {};
      this.ruleForm = {
        pid: '',
        auditStatus: null,
        remark: ''
      };
      this.detailVisible = false;
    },
    AuditSubmit: function AuditSubmit() {
      var _this2 = this;
      console.log(this.ruleForm, '提交审核');
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          console.log('提交');
          (0, _register.audit)(_this2.ruleForm).then(function (res) {
            console.log(res.data, '审核');
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              _this2.$message.success(res.data.msg);
              _this2.handleClose();
            }
          });
        }
      });
    }
  }
};
exports.default = _default;