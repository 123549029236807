var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yw-data" },
    [
      _c("div", { staticClass: "itemTitle" }, [
        _vm._v(_vm._s(_vm.formData.panelTitle)),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.formData.fromBody, function (item, index) {
          return _c("el-col", { key: index, attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c(
                "div",
                {
                  staticClass: "form-div",
                  staticStyle: { width: "auto", "margin-right": "0.5em" },
                },
                [_vm._v(_vm._s(item.textName) + ":")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(item.textValue)),
              ]),
            ]),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v(_vm._s(_vm.tableData.panelTitle)),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "fr", staticStyle: { "margin-left": "auto" } },
          [_c("strong", [_vm._v(_vm._s(_vm.tableData.tbSummary))])]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { data: _vm.tableData.tbHead } },
        _vm._l(_vm.tableData.tbHead, function (item, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "theader", attrs: { type: "flex" } },
            _vm._l(item, function (value, i) {
              return _c(
                "el-col",
                {
                  key: i,
                  style: "width:" + (value.colspan / _vm.cols) * 100 + "%",
                },
                [_vm._v(_vm._s(value.colText))]
              )
            }),
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { data: _vm.tableData.tbRows } },
        _vm._l(_vm.tableData.tbRows, function (item, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "tbody", attrs: { type: "flex" } },
            _vm._l(item, function (value, i) {
              return _c(
                "el-col",
                {
                  key: i,
                  style: "width:" + (value.colspan / _vm.cols) * 100 + "%",
                },
                [_vm._v(_vm._s(value.colValue))]
              )
            }),
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { data: _vm.tableData.tbFoot } },
        _vm._l(_vm.tableData.tbFoot, function (item, index) {
          return _c(
            "el-row",
            { key: index, staticClass: "tfooter", attrs: { type: "flex" } },
            _vm._l(item, function (value, i) {
              return _c("el-col", { key: i }, [
                _vm._v(_vm._s(value.colText) + ":" + _vm._s(value.colValue)),
              ])
            }),
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "itemTitle" }, [
          _vm._v(_vm._s(_vm.noteData.panelTitle)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { padding: "0 0 15px 0", "font-size": "14px" } },
          [_vm._v(_vm._s(_vm.noteData.note))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [
            _vm._v(_vm._s(_vm.imgData.panelTitle)),
          ]),
          _vm._v(" "),
          _vm._l(_vm.imgData.galleryDetailPanel, function (item, index) {
            return _c("el-image", {
              key: index,
              staticStyle: { width: "80px", height: "80px", margin: "0 10px" },
              attrs: { src: item.imgUrl, "preview-src-list": _vm.preViewList },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }