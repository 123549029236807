"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDataproductmangeproductbrandindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      btnLoading: false,
      disabled: false,
      page2: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        brandName: '',
        brandStatus: '',
        current: 1,
        size: 20
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      title: '新增产品分类',
      mloading: false,
      ruleForm: {
        brandName: '',
        brandStatus: 1,
        remarks: '',
        sourceEnum: 1
      },
      rules: {
        brandName: [{
          required: true,
          message: '产品品牌名称不能为空，请维护',
          trigger: 'blur'
        }],
        brandStatus: [{
          required: true,
          message: '状态不能为空，请维护',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _product.productBrandList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          that.total = Number(res.data.data.total);
          if (res.data.data.records != null) {
            that.tableData = res.data.data.records;
          } else {
            that.tableData = [];
          }
        }
        _this.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var submitData = (0, _utils.filterKeyNull)(_this2.ruleForm);
          if (_this2.ruleForm.pId) {
            _this2.authId('updatebtn', _this2.$route.meta.authList);
            (0, _product.productBrandUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          } else {
            _this2.authId('addbtn', _this2.$route.meta.authList);
            (0, _product.productBrandInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this2.handleClose();
                _this2.getList();
              }
              _this2.btnLoading = false;
            }).catch(function () {
              _this2.btnLoading = false;
            });
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        brandName: '',
        brandStatus: '',
        current: 1,
        size: 20
      };
      this.getList();
    },
    add: function add() {
      this.title = '添加产品品牌';
      this.disabled = false;
      this.dialogVisible = true;
    },
    syncData: function syncData() {},
    detail: function detail(row) {
      this.title = '查看产品品牌';
      this.disabled = true;
      this.ruleForm.brandName = row.brandName;
      this.ruleForm.brandStatus = row.brandStatus;
      this.ruleForm.remarks = row.remarks;
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.title = '编辑产品品牌';
      this.disabled = false;
      this.ruleForm.pId = row.pId;
      this.ruleForm.brandName = row.brandName;
      this.ruleForm.brandStatus = row.brandStatus;
      this.ruleForm.remarks = row.remarks;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        brandName: '',
        brandStatus: 1,
        remarks: '',
        sourceEnum: 1
      };
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    setInUse: function setInUse(id) {
      var _this3 = this;
      this.$confirm('您确认要启用所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.authId('startbtn', _this3.$route.meta.authList);
        (0, _product.productBrandEnable)({
          brandStatus: 1,
          pId: id
        }).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          if (res.data.code != 200) {
            _this3.$message.error(res.data.msg);
          } else {
            _this3.$message({
              message: '操作成功',
              type: 'success'
            });
            _this3.getList();
          }
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(id) {
      var _this4 = this;
      this.$confirm('您确认要禁用所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('stopbtn', _this4.$route.meta.authList);
        (0, _product.productBrandEnable)({
          brandStatus: 0,
          pId: id
        }).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          if (res.data.code != 200) {
            _this4.$message.error(res.data.msg);
          } else {
            _this4.$message({
              message: '操作成功',
              type: 'success'
            });
            _this4.getList();
          }
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    DoDel: function DoDel(id) {
      var _this5 = this;
      var that = this;
      that.$confirm('您确认要删除所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.authId('deletebtn', _this5.$route.meta.authList);
        (0, _product.productBrandEnable)({
          brandStatus: 2,
          pId: id
        }).then(function (res) {
          _this5.authId('searchbtn', _this5.$route.meta.authList);
          if (res.data.code == 200) {
            _this5.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this5.getList();
          } else {
            _this5.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;