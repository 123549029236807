var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-container" }, [
        _vm.hasAuth("searchbtn", _vm.apis)
          ? _c(
              "div",
              { staticClass: "page-section" },
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "search-condition",
                    attrs: {
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                      model: _vm.searchForm,
                      "label-suffix": ":",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "qyorgId" } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "公司", prop: "qyorgId" } },
                                  [
                                    _c("el-cascader", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择公司",
                                        options: _vm.orgList,
                                        filterable: "",
                                        clearable: "",
                                        props: _vm.props,
                                      },
                                      model: {
                                        value: _vm.qyorgId,
                                        callback: function ($$v) {
                                          _vm.qyorgId = $$v
                                        },
                                        expression: "qyorgId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "邀请函名称",
                                      prop: "theme",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      attrs: {
                                        placeholder: "请输入邀请函名称",
                                      },
                                      model: {
                                        value: _vm.searchForm.theme,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.searchForm, "theme", $$v)
                                        },
                                        expression: "searchForm.theme",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "创建日期",
                                      prop: "createTime",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "daterange",
                                        format: "yyyy-MM-dd HH:mm:ss",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                      },
                                      model: {
                                        value: _vm.timeData,
                                        callback: function ($$v) {
                                          _vm.timeData = $$v
                                        },
                                        expression: "timeData",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    style: { width: _vm.formConfig.btnArea },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchForm.pageNow = 1
                                                _vm.fetchData()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.restForm("searchForm")
                                                _vm.fetchData()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c("el-divider", { staticClass: "btnDivider" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "phead" },
                  [
                    !_vm.formConfig.btnFollow
                      ? _c(
                          "el-col",
                          {
                            staticClass: "head-btn-group",
                            staticStyle: { width: "230px" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.searchForm.pageNow = 1
                                    _vm.fetchData()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.restForm("searchForm")
                                    _vm.fetchData()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _vm.hasAuth("addbtn", _vm.apis)
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.addBtn.icon,
                                },
                                on: { click: _vm.addInvite },
                              },
                              [_vm._v("制作邀请函")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.datalist,
                      "element-loading-text": "Loading",
                      fit: "",
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "邀请函主题",
                        "show-overflow-tooltip": "",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        prop: "theme",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "背景图片",
                        "show-overflow-tooltip": "",
                        align: _vm.tableConfig.align,
                        prop: "bgImageName",
                        "min-width": "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "背景音乐",
                        "show-overflow-tooltip": "",
                        align: _vm.tableConfig.align,
                        prop: "bgMusicName",
                        "min-width": "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建日期",
                        align: _vm.tableConfig.align,
                        prop: "createTime",
                        "show-overflow-tooltip": "",
                        width: "165",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        "min-width": "100",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.actStatus * 1 == 2
                                  ? _c("el-tag", [_vm._v(_vm._s("已过期"))])
                                  : scope.row.actStatus * 1 == 3
                                  ? _c("el-tag", [_vm._v(_vm._s("已过期"))])
                                  : _vm.getTimeFun(scope.row.actEndTime) <
                                    _vm.presentDate
                                  ? _c("el-tag", [_vm._v(_vm._s("已过期"))])
                                  : _vm.getTimeFun(scope.row.endTime) <
                                    _vm.presentDate
                                  ? _c("el-tag", [_vm._v(_vm._s("已过期"))])
                                  : _vm.getTimeFun(scope.row.endTime) >
                                    _vm.presentDate
                                  ? _c("el-tag", [_vm._v(_vm._s("未过期"))])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4185194690
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: _vm.tableConfig.align,
                        label: "操作",
                        width: "180",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.actStatus * 1 != 2 &&
                                scope.row.actStatus * 1 != 3 &&
                                _vm.getTimeFun(scope.row.actEndTime) >
                                  _vm.presentDate &&
                                _vm.getTimeFun(scope.row.endTime) >
                                  _vm.presentDate &&
                                _vm.hasAuth("updatebtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(
                                              1,
                                              scope.row.type,
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasAuth("scanbtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetail(
                                              2,
                                              scope.row.type,
                                              scope.row.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasAuth("previewbtn", _vm.apis)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "button configText",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.preview(scope.row.id)
                                          },
                                        },
                                      },
                                      [_vm._v("预览")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2964657970
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.searchForm.pageNow,
                    limit: _vm.searchForm.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "pageNow", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "pageSize", $event)
                    },
                    pagination: _vm.fetchData,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.dialogTableVisible,
            "before-close": _vm.cancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { staticStyle: { width: "600px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formInline",
                      staticStyle: { margin: "10 auto" },
                      attrs: {
                        rules: _vm.formInlineRules,
                        model: _vm.formInline,
                        "label-position": "right",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邀请函主题:", prop: "theme" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入邀请函主题",
                              type: "text",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.formInline.theme,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "theme", $$v)
                              },
                              expression: "formInline.theme",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          class: { disabled: !_vm.disabled },
                          attrs: { label: "活动邀请码:", prop: "qrCode" },
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: _vm.formInline.qrCode,
                              "preview-src-list": _vm.srcList,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属活动:", prop: "actName" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择活动",
                                disabled: _vm.disabled,
                              },
                              on: { change: _vm.getval },
                              model: {
                                value: _vm.formInline.actName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "actName", $$v)
                                },
                                expression: "formInline.actName",
                              },
                            },
                            _vm._l(_vm.options, function (item, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: item.actName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "二维码背景图片:",
                            prop: "bgImageAdderss",
                          },
                        },
                        [
                          _c("Upload-img", {
                            ref: "bgImageUpload",
                            attrs: {
                              data: _vm.formInline.bgImageAdderss,
                              "file-name": _vm.formInline.bgImageName,
                              text: "（只支持.png,.jpg 格式）",
                              disabled: _vm.disabled,
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgImageAdderss",
                                  $event
                                )
                              },
                              "update:fileName": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgImageName",
                                  $event
                                )
                              },
                              "update:file-name": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgImageName",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.disabled,
                              expression: "!disabled",
                            },
                          ],
                          attrs: { label: "背景音乐:", prop: "bgMusicAddress" },
                        },
                        [
                          _c("Upload-music", {
                            ref: "bgMusicUpload",
                            attrs: {
                              data: _vm.formInline.bgMusicAddress,
                              "file-name": _vm.formInline.bgMusicName,
                              text: "（只支持.png,.jpg 格式）",
                              disabled: _vm.disabled,
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgMusicAddress",
                                  $event
                                )
                              },
                              "update:fileName": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgMusicName",
                                  $event
                                )
                              },
                              "update:file-name": function ($event) {
                                return _vm.$set(
                                  _vm.formInline,
                                  "bgMusicName",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.disabled,
                              expression: "disabled",
                            },
                          ],
                          attrs: { label: "背景音乐:", prop: "bgMusicName" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "背景音乐:",
                              type: "text",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.formInline.bgMusicName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "bgMusicName", $$v)
                              },
                              expression: "formInline.bgMusicName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "formInlineimgs",
                          attrs: { label: "邀请函前景:", prop: "imgs" },
                        },
                        [
                          _c("UploadImgNum", {
                            ref: "imgsUpload",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              data: _vm.formInline.imgs,
                              text: "（只支持.png,.jpg 格式）",
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(_vm.formInline, "imgs", $event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              ref: "multipleTable",
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                fit: "",
                                border: _vm.tableConfig.border,
                                stripe: _vm.tableConfig.stripe,
                                "row-key": "pid",
                                "highlight-current-row": "",
                                data: _vm.formInline.imgs,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  prop: "imgOrder",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                  "min-width": "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "前景图片",
                                  prop: "imgName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "是否邀请确认",
                                  align: _vm.tableConfig.align,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              label: scope.$index,
                                              disabled: _vm.disabled,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.getTemplateRow(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.templateRadio,
                                              callback: function ($$v) {
                                                _vm.templateRadio = $$v
                                              },
                                              expression: "templateRadio",
                                            },
                                          },
                                          [_vm._v("是")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: _vm.tableConfig.align,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "avatar-uploader",
                                            staticStyle: {
                                              border: "none",
                                              "margin-top": "5px",
                                            },
                                            attrs: {
                                              headers: _vm.myHeaders,
                                              action: _vm.uploadUrl,
                                              "show-file-list": false,
                                              "on-success": function (
                                                res,
                                                file
                                              ) {
                                                _vm.handleAvatarSuccess(
                                                  res,
                                                  file,
                                                  scope.$index
                                                )
                                              },
                                              "before-upload":
                                                _vm.beforeAvatarUpload,
                                              accept:
                                                ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                                              disabled: _vm.disabled,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "spancolor" },
                                              [_vm._v("上传")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formInline.imgs.length
                            ? _c("i", {
                                staticClass: "el-icon-remove",
                                class: { disabled: _vm.disabled },
                                attrs: { size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTable()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "邀请函过期日期:", prop: "endTime" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              format: "yyyy-MM-dd HH:mm:ss",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择日期时间",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.formInline.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "endTime", $$v)
                              },
                              expression: "formInline.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  class: { disabled: _vm.disabled },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.choseData
                                        ? _vm.createData()
                                        : _vm.updateData()
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "normalBtn",
                                  class: { disabled: _vm.disabled },
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }