"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllItems = getAllItems;
exports.getSlectedItem = getSlectedItem;
exports.saveItems = saveItems;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取操作日志
function getAllItems(param) {
  return (0, _request.default)({
    url: '/api-user/itemConfig/process/select',
    method: 'post'
  });
}

// 添加配置项
function saveItems(param) {
  return (0, _request.default)({
    url: '/api-user/itemConfig/process/save',
    method: 'post',
    params: param
  });
}

// 获取已配置项
function getSlectedItem() {
  return (0, _request.default)({
    url: '/api-user/itemConfig/process/get',
    method: 'post'
  });
}