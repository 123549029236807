"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBasicRule = addBasicRule;
exports.addRedEnvelope = addRedEnvelope;
exports.queryBasicRule = queryBasicRule;
exports.queryRedEnvelope = queryRedEnvelope;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 红包补发信息查询
function queryRedEnvelope() {
  return (0, _request.default)({
    url: base + '/market-zdConfig/redEnvelopeReissue/redEnvelopeReissueGet',
    method: 'get'
  });
}
// 基础设置信息查询
function queryBasicRule() {
  return (0, _request.default)({
    url: base + '/dgConfig/basicRuleInfo/basicRuleInfoGet',
    method: 'get'
  });
}

// 红包补发添加
function addBasicRule(data) {
  return (0, _request.default)({
    url: base + '/dgConfig/basicRuleInfo/basicRuleInfoSave',
    method: 'post',
    data: data
  });
}

// 红包补发添加
function addRedEnvelope(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/redEnvelopeReissue/redEnvelopeReissueSave',
    method: 'post',
    data: data
  });
}