"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic");
var _cover = require("@/api/mark/coding_service/cover_apply_mange/cover");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _cover_apply = _interopRequireDefault(require("./components/cover_apply"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var temp = {
  pid: 0,
  Reason: ''
};
var _default = {
  name: 'MarkcodingServicecoverApplyMangeindex',
  components: {
    Pagination: _Pagination.default,
    apply: _cover_apply.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  data: function data() {
    return {
      handleShow: false,
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      downlist: null,
      total: 0,
      listLoading: true,
      isShow: false,
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        packageOrderNum: '',
        applyMarkType: 2,
        applyStatus: null,
        applyStyle: null,
        downloadStatus: null,
        startTime: '',
        endTime: '',
        generateStatus: '',
        isPlatform: 0,
        markOrderNo: ''
      },
      temp: temp,
      dialogStatus: '',
      textMap: {
        approval: '制码申请',
        set: '查看详情',
        edit: '编辑详情'
      },
      btnPowers: null
    };
  },
  activated: function activated() {
    this.btnPowers = {};
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _cover.fetchList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        this.$refs.searchform.resetFields();
      }
      this.listQuery.pageNo = 1;
      this.getList();
    },
    handleUpdate: function handleUpdate(row, data_type) {
      // this.$refs.applyMain.dialogStatus = 'approval'
      if (data_type == 'set') {
        this.authId('detailbtn', this.$route.meta.authList);
        this.temp.pid = row.pid;
        this.temp.dialogStatus = 'set';
        this.getUserInfo();
      }
      if (data_type == 'edit') {
        this.authId('updatebtn', this.$route.meta.authList);
        this.temp.pid = row.pid;
        this.temp.dialogStatus = 'edit';
        this.getUserInfo();
      }
      if (data_type == 'create') {
        this.authId('addbtn', this.$route.meta.authList);
        this.temp.dialogStatus = 'create';
        this.getUserInfo();
      }
      if (data_type == 'del') {
        this.authId('deletebtn', this.$route.meta.authList);
        this.setDel(row);
        return true;
      }
      // this.$refs.applyMain.dialogApplyVisible = true
      // return true
    },
    getUserInfo: function getUserInfo() {
      var _this2 = this;
      (0, _cover.getSupplierByUser)().then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.isShow = true;
          } else {
            _this2.$message.error('获取供应商信息失败，请使用供应商用户登录！');
          }
        }
      });
    },
    setDel: function setDel(row) {
      var _this3 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _cover.deleteInfo)({
          pId: row.pid
        }).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this3.$message.error(data.msg);
            return false;
          }
          _this3.$message({
            message: '操作成功',
            type: 'success'
          });
          _this3.getList();
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;