"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _WechatUser = require("@/api/operationalTools/WechatUser");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsactiveRoleindex';
var _default = {
  name: cachName,
  data: function data() {
    return {
      multipleSelection: [],
      listLoading: false,
      tabHeight: '100%',
      // 表格数据
      tableData: [],
      formInline: {
        roleName: '',
        isEnable: ''
      },
      infoList: [{
        baseinfoid: '1',
        paramField: 'membername',
        paramType: 'c',
        paramName: '姓名',
        type: '1'
      }, {
        baseinfoid: '2',
        paramField: 'phone',
        paramType: 'c',
        paramName: '手机号',
        type: '1'
      }, {
        baseinfoid: '3',
        paramField: 'idcard',
        paramType: 'c',
        paramName: '身份证',
        type: '1'
      }, {
        baseinfoid: '4',
        paramField: 'storeid',
        paramType: 'c',
        paramName: '所属门店',
        type: '1'
      }],
      roleForm: {
        roleId: '',
        roleCode: '',
        roleName: '',
        isEnable: 1,
        remark: '',
        registerInfo: [],
        registerEnable: 1
      },
      btnLoading: false,
      dialogFormVisible: false,
      title: '添加',
      rules: {
        roleCode: [{
          required: true,
          message: '请输入营销角色编码',
          trigger: 'change'
        }],
        roleName: [{
          required: true,
          message: '请输入营销角色名称',
          trigger: 'change'
        }]
      },
      lookdisabled: false,
      addInfo: false,
      infoItem: {
        baseinfoid: '',
        paramField: '',
        paramName: '',
        paramType: 'c',
        type: '2'
      },
      infoItemR: {
        paramName: [{
          required: true,
          message: '请输入字段名称',
          trigger: 'change'
        }],
        paramType: [{
          required: true,
          message: '请选择填写类型',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getyxRolelist();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
    }
  },
  methods: {
    checkSelectable: function checkSelectable(row) {},
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取活动用户列表
    getyxRolelist: function getyxRolelist() {
      var that = this;
      that.listLoading = true;
      var sumitData = JSON.parse(JSON.stringify(this.formInline));
      (0, _WechatUser.yxRolelist)(sumitData).then(function (res) {
        that.listLoading = false;
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data;
          } else {
            that.tableData = [];
          }
        }
      });
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.getyxRolelist();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.$refs[formName].resetFields();
      this.getyxRolelist();
    },
    creatBtn: function creatBtn(type, row) {
      var _this = this;
      this.lookdisabled = false;
      if (type == 'add') {
        this.title = '添加';
        this.infoList.forEach(function (item) {
          if (item.paramName === '姓名' || item.paramName === '手机号') {
            _this.roleForm.registerInfo.push(item);
          }
        });
      } else if (type == 'look') {
        this.title = '详情';
        this.lookdisabled = true;
        Object.keys(this.roleForm).forEach(function (key) {
          if (key === 'registerInfo') {
            var arr = JSON.parse(row.registerInfo);
            arr.forEach(function (item) {
              _this.infoList.forEach(function (item2) {
                if (item.paramName == item2.paramName) {
                  _this.roleForm.registerInfo.push(item2);
                }
              });
            });
          } else {
            _this.roleForm[key] = row[key];
          }
        });
        console.log(this.roleForm);
      } else if (type == 'edit') {
        this.title = '编辑';
        Object.keys(this.roleForm).forEach(function (key) {
          if (key === 'registerInfo') {
            var arr = JSON.parse(row.registerInfo);
            arr.forEach(function (item) {
              _this.infoList.forEach(function (item2) {
                if (item.paramName == item2.paramName) {
                  _this.roleForm.registerInfo.push(item2);
                  console.log(item2);
                }
              });
            });
          } else {
            _this.roleForm[key] = row[key];
          }
        });
        console.log(this.roleForm);
      }
      this.dialogFormVisible = true;
    },
    checkboxFun: function checkboxFun(val) {
      console.log(val);
    },
    submitFun: function submitFun() {
      var _this2 = this;
      this.$refs.roleForm.validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          var sdata = JSON.parse(JSON.stringify(_this2.roleForm));
          sdata.registerInfo = JSON.stringify(sdata.registerInfo);
          console.log('提交', sdata);
          (0, _WechatUser.yxRoleadd)(sdata).then(function (res) {
            console.log(res);
            _this2.btnLoading = false;
            if (res.data.data.code && res.data.data.code == 500) {
              _this2.$message.error(res.data.data.msg);
              return;
            }
            _this2.$message.success(_this2.title + '成功');
            _this2.getyxRolelist();
            _this2.handleClose();
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.dialogFormVisible = false;
      this.roleForm = {
        roleId: '',
        roleCode: '',
        roleName: '',
        isEnable: 1,
        remark: '',
        registerInfo: [],
        registerEnable: 1
      };
    },
    // 新增
    addInfoFun: function addInfoFun() {
      var _this3 = this;
      this.$refs.infoItem.validate(function (valid) {
        if (valid) {
          _this3.infoList.push(JSON.parse(JSON.stringify(_this3.infoItem)));
          console.log(_this3.infoList);
          _this3.addInfo = false;
        }
      });
    },
    handleClose2: function handleClose2() {
      this.addInfo = false;
      this.$refs.infoItem.resetFields();
    },
    // 禁用启用
    open: function open(row) {
      var _this4 = this;
      var type = '';
      var text = '';
      if (row.isEnable == 1) {
        type = 0;
        text = '禁用';
      } else {
        type = 1;
        text = '启用';
      }
      this.$confirm('是否' + text + '此活动角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _WechatUser.yxRoleeditEnable)({
          isEnable: type,
          roleIds: [row.roleId]
        }).then(function (res) {
          if (res.data.data.code && res.data.data.code == 500) {
            _this4.$message.error(res.data.data.msg);
            return;
          }
          _this4.getyxRolelist();
          _this4.$message({
            type: 'success',
            message: text + '成功!'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
exports.default = _default;