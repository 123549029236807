var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "tradeBillQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.tradeBillQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billNo",
                                    label: "生产入库单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "15",
                                    },
                                    on: {
                                      input: function (e) {
                                        return (_vm.tradeBillQuery.billNo =
                                          _vm.validSe(e))
                                      },
                                    },
                                    model: {
                                      value: _vm.tradeBillQuery.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tradeBillQuery,
                                          "billNo",
                                          $$v
                                        )
                                      },
                                      expression: "tradeBillQuery.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "relateNo",
                                    label: "生产任务单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "15",
                                    },
                                    on: {
                                      input: function (e) {
                                        return (_vm.tradeBillQuery.billProdQuery.relateNo =
                                          _vm.validSe(e))
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.tradeBillQuery.billProdQuery
                                          .relateNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tradeBillQuery.billProdQuery,
                                          "relateNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tradeBillQuery.billProdQuery.relateNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "inOrgName",
                                    label: "收货单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                      "suffix-icon": "el-icon-search",
                                    },
                                    on: {
                                      clear: _vm.clearInOrg,
                                      focus: function ($event) {
                                        return _vm.getInUnit("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.inOrgName,
                                      callback: function ($$v) {
                                        _vm.inOrgName = $$v
                                      },
                                      expression: "inOrgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "inStoreId",
                                    label: "收货库房:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { focus: _vm.getDownHouses },
                                      model: {
                                        value:
                                          _vm.tradeBillQuery.billProdQuery
                                            .inStoreId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tradeBillQuery.billProdQuery,
                                            "inStoreId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tradeBillQuery.billProdQuery.inStoreId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.TypeDataCom.StoreList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.storeHouseName,
                                            value: item.pId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "prodFactoryId",
                                        label: "生产基地:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.tradeBillQuery.billProdQuery
                                                .prodFactoryId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tradeBillQuery
                                                  .billProdQuery,
                                                "prodFactoryId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tradeBillQuery.billProdQuery.prodFactoryId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.TypeDataCom.factoryList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.pId,
                                              attrs: {
                                                label: item.factoryName,
                                                value: item.pId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productName",
                                        label: "产品名称:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          "suffix-icon": "el-icon-search",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          focus: _vm.openProductWin,
                                          clear: _vm.clearProduct,
                                        },
                                        model: {
                                          value: _vm.productName,
                                          callback: function ($$v) {
                                            _vm.productName = $$v
                                          },
                                          expression: "productName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "billStatus",
                                        label: "单据状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.tradeBillQuery.billStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tradeBillQuery,
                                                "billStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tradeBillQuery.billStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.TypeDataCom.statusList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: item.figure,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "billStatus",
                                        label: "发货方式:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.tradeBillQuery.billProdQuery
                                                .sendStyle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tradeBillQuery
                                                  .billProdQuery,
                                                "sendStyle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tradeBillQuery.billProdQuery.sendStyle",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "扫码发货",
                                              value: 1,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自动发货",
                                              value: 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "billSource",
                                        label: "单据来源:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.tradeBillQuery.billSource,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tradeBillQuery,
                                                "billSource",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tradeBillQuery.billSource",
                                          },
                                        },
                                        _vm._l(
                                          _vm.TypeDataCom.sourceList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.figure,
                                              attrs: {
                                                label: item.name,
                                                value: item.figure,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "dateValue",
                                        label: "创建时间:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.dateValue,
                                          callback: function ($$v) {
                                            _vm.dateValue = $$v
                                          },
                                          expression: "dateValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("search")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("rest")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("search")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("rest")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.downLoading,
                              },
                              on: { click: _vm.download },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "80",
                      label: "序号",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (_vm.searchForm.current - 1) *
                                      _vm.searchForm.size +
                                      scope.$index +
                                      1
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      998093746
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产入库单号",
                      prop: "billNo",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产任务单号",
                      prop: "relateNo",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产基地",
                      prop: "prodFactoryName",
                      width: "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "收货单位编码",
                          prop: "inOrgCode",
                          "min-width": "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位",
                      prop: "inOrgName",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "定制产品",
                      prop: "productType",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.productType == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("是"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.productType == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("否"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1759310982
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商编码",
                      prop: "custCode",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商名称",
                      prop: "custName",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货方式",
                      prop: "sendStyle",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.sendStyle == 1
                                      ? "扫码发货"
                                      : row.sendStyle == 2
                                      ? "自动发货"
                                      : "未知"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2320034270
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货库房",
                      prop: "inStoreName",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      prop: "billStatus",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm._f("tagStatus")(row.billStatus),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("filterStatus")(
                                          row.billStatus,
                                          row.billStatus
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2157518027
                    ),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "单据来源",
                          "min-width": "180",
                          align: _vm.tableConfig.align,
                          prop: "billSource",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterSource")(
                                        row.billSource,
                                        row.billSource
                                      )
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1267672019
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createUser",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("qrcodebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("printbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("autosendbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "a-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.getflow(row, 2)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "qrcodebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "a-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.printTb(row)
                                            },
                                          },
                                        },
                                        [_vm._v("打印")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "autosendbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "a-btn",
                                          attrs: {
                                            disabled:
                                              row.productType == 0 ||
                                              (row.productType == 1 &&
                                                row.sendStyle == 2),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.autoSend(row, 2)
                                            },
                                          },
                                        },
                                        [_vm._v("自动发货")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1392920469
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.code_title,
            visible: _vm.codeVisible,
            width: "312px !important",
            "before-close": _vm.handleCodeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("div", {
              ref: "qrCodeUrl",
              staticClass: "qrcode",
              attrs: { id: "qrcode" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "promt-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "", size: "mini" },
                  on: { click: _vm.handleCodeClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("insert", { ref: "insertMain" }),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
      _vm._v(" "),
      _c("autoSend", {
        ref: "autoSendMain",
        on: { handleRefresh: _vm.getList },
      }),
      _vm._v(" "),
      _vm.pShow
        ? _c("printDialog", {
            attrs: {
              type: _vm.typeNum,
              visible: _vm.printShow,
              "bill-no": _vm.billNo,
            },
            on: { close: _vm.printClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "shou-fa-type": _vm.shouFaType,
          "in-out-type": _vm.inOutType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }