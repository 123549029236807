"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.search");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _index = require("@/utils/index.js");
var _massSend = require("@/api/wxhyyy/massSend.js");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      // 公众号切换
      keyWord: '',
      daterangeVal: [],
      accountsList: [{
        value: '选项1',
        label: '美语派'
      }, {
        value: '选项2',
        label: '野狼派'
      }],
      accountsVal: '',
      value: '',
      // 群发查询
      formInline: {
        user: '',
        region: ''
      },
      value1: '',
      value2: '',
      // 群发表格 children
      tableData: [],
      aHref: ''
    };
  },
  mounted: function mounted() {
    this.daterangeVal = this.getDate(30);
    this.getMassFun();
    this.baseUrl = _wxHy.base;
    // this.aHref =
    //   'http://testsaas.panpass.com/api-memberscrm/member/admin/wxSends/down/detail?keyword=' +
    //   this.keyWord +
    //   'startDate=' +
    //   this.daterangeVal[0] +
    //   '&endDate=' +
    //   this.daterangeVal[1]

    this.aHref = 'http://saas.panpass.com/api-memberscrm/member/admin/wxSends/down/detail?keyword=' + this.keyWord + 'startDate=' + this.daterangeVal[0] + '&endDate=' + this.daterangeVal[1];
  },
  methods: {
    getDate: function getDate(val) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
      end.setTime(end.getTime());
      var arr = [(0, _index.parseTime)(start, '{y}-{m}-{d}'), (0, _index.parseTime)(end, '{y}-{m}-{d}')];
      return arr;
    },
    //  this.daterangeVal[0]
    // this.daterangeVal[1]
    getMassFun: function getMassFun() {
      var _this = this;
      (0, _massSend.search)({
        keyWord: this.keyWord,
        startDate: this.daterangeVal[0],
        endDate: this.daterangeVal[1],
        appId: sessionStorage.getItem('appId')
      }).then(function (resp) {
        var resp = resp.data;
        console.log(resp);
        if (resp.code === 200) {
          // row-key
          resp.data.forEach(function (item, index) {
            item.rowKey = item.id;
            if (item.wxNewsVoList.length > 0) {
              item.wxNewsVoList.forEach(function (i, v) {
                console.log(item);
                console.log(i);
                i.rowKey = i.id + item.id + '';
                // i.rowKey = index + ''
                console.log(i.rowKey);
              });
            }
          });
          _this.tableData = resp.data;
        } else {
          _this.$message(resp.msg);
        }
      });
    },
    onSubmit: function onSubmit() {
      this.getMassFun();
    },
    // 表格操作
    handleEdit: function handleEdit(o) {
      // sessionStorage.setItem('amsc', JSON.stringify(o.wxNewsVoList))
      this.$router.push({
        name: 'wxAccountswxgzhwxFunctionfoddersucai',
        query: {
          upId: o.id
        }
      });
    },
    handleDelete: function handleDelete(rows) {
      var _this2 = this;
      this.$confirm('删除后将不在列表中显示，您确定要删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _massSend.deleteSendById)({
          id: rows,
          appId: sessionStorage.getItem('appId')
        }).then(function (resp) {
          var resp = resp.data;
          console.log(resp);
          if (resp.code === 200) {
            _this2.tableData = _this2.tableData.filter(function (item) {
              return item.id !== rows;
            });
            _this2.$message.success(resp.msg);
          } else {
            _this2.$message(resp.msg);
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    creatMess: function creatMess(id) {
      var that = this;
      if (id) {
        that.$router.push({
          name: 'wxAccountswxgzhwxManagecreatMessindex',
          query: {
            upId: id
          }
        });
      } else {
        that.$router.push({
          name: 'wxAccountswxgzhwxManagecreatMessindex',
          query: {}
        });
      }
    },
    downLoad: function downLoad() {
      var that = this;
      // axios.get('http://testsaas.panpass.com/api-memberscrm/member/admin/wxSends/down/detail', { keyword: this.keyWord, startDate: this.daterangeVal[0], endDate: this.daterangeVal[1] }, { responseType: 'blob' }).then(data => {
      //   var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      //   var downloadElement = document.createElement('a')
      //   var href = window.URL.createObjectURL(blob)
      //   window.location.href = href
      //   downloadElement.href = href
      //   downloadElement.download = '群发列表.xls'
      //   document.body.appendChild(downloadElement)
      //   downloadElement.click()
      //   document.body.removeChild(downloadElement)
      //   window.URL.revokeObjectURL(href)
      //   that.downLoading = false
      // })
      //   .catch(() => {
      //     that.downLoading = false
      //   })

      (0, _massSend.downLoadData)({
        keyword: this.keyWord,
        startDate: this.daterangeVal[0],
        endDate: this.daterangeVal[1]
        // appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '群发列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function () {
        that.downLoading = false;
      });
    }
  }
};
exports.default = _default;