"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/fake_expel/basic");
var _fake = require("@/api/fake_expel/fake/fake");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _seach = _interopRequireDefault(require("../components/fake/seach"));
var _digital = _interopRequireDefault(require("../components/fake/digital"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FakeExpelwarningManagefakeindex',
  components: {
    Pagination: _Pagination.default,
    seach: _seach.default,
    digital: _digital.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      tabHeight: '100%',
      tableKey: 0,
      list: [],
      total: 0,
      downHref: '',
      handleShow: false,
      listLoading: false,
      excelOut_loading: false,
      month: '',
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        queryTime: '',
        antiNum: '',
        csType: '',
        queryCs: '',
        ppwlList: [],
        ycyjlx: '-1'
      },
      dialogTitle: '',
      textMap: {
        setdigital: '查询次数详情'
      }
    };
  },
  activated: function activated() {
    var month = parseInt(new Date().getMonth()) + 1;
    this.month = month < 10 ? '0' + month + '' : month;
    this.listQuery.queryTime = new Date().getFullYear() + '' + this.month;
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    excelOut: function excelOut() {
      var _this = this;
      this.excelOut_loading = true;
      var a = {
        antiNum: '',
        csType: '',
        ppwlList: [],
        queryCs: '',
        queryTime: '',
        ycyjlx: ''
      };
      a = (0, _basic.getKeysetVal)(a, this.listQuery);
      this.authId('exportbtn', this.$route.meta.authList);
      (0, _fake.excelOut)(a).then(function (res) {
        _this.authId('searchbtn', _this.$route.meta.authList);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '防伪预警' + _this.listQuery.queryTime + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this.excelOut_loading = false;
      }).catch(function () {
        _this.authId('searchbtn', _this.$route.meta.authList);
        _this.excelOut_loading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _fake.fetchList)(this.listQuery).then(function (response) {
        var data = response.data.data;
        console.log(data, 'data');
        _this2.list = null;
        _this2.total = 0;
        _this2.listLoading = false;
        if (response.data.code != 200) {
          _this2.$message.error(data.msg);
          return;
        }
        if (data.records == '' || data.records == null) {
          _this2.list = null;
          _this2.total = 0;
          return;
        }
        _this2.list = data.records;
        _this2.total = Number(data.total);
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    getMonthData: function getMonthData(month, year) {
      var m = month < 10 ? '0' + month : month;
      if ((0, _typeof2.default)(year) === undefined || year == null) {
        this.listQuery.queryTime = new Date().getFullYear() + m;
      } else {
        this.listQuery.queryTime = year + m;
      }
      this.getList();
    },
    searchList: function searchList(data) {
      this.listQuery = JSON.parse(JSON.stringify(data));
      this.getList();
    },
    handleFilter: function handleFilter() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    getDetails: function getDetails(row) {
      this.dialogTitle = this.textMap.setdigital;
      this.$refs.digitalDialog.rowInfo = row;
      this.$refs.digitalDialog.listQuery.pageNo = 1;
      this.$refs.digitalDialog.listQuery.queryTime = this.listQuery.queryTime;
      this.$refs.digitalDialog.listQuery.antiNum = row.antiNum;
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.digitalDialog.dialogDigitalVisible = true;
    },
    getKeysetVal: function getKeysetVal(old_v, new_v) {
      for (var i in old_v) {
        if ((0, _typeof2.default)(old_v[i]) === 'object') {
          for (var n in old_v[i]) {
            old_v[i][n] = new_v[i][n];
          }
        } else {
          old_v[i] = new_v[i];
        }
      }
      return old_v;
    }
  }
};
exports.default = _default;