"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _productMultiSelect = _interopRequireDefault(require("../component/productMultiSelect"));
var _productChose = _interopRequireDefault(require("../component/productChose"));
var _policySelect = _interopRequireDefault(require("../component/policySelect"));
var _scanZc = _interopRequireDefault(require("./scanZc"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'insertMzfn',
  components: {
    Treeselect: _vueTreeselect.default,
    productMultiSelect: _productMultiSelect.default,
    productChose: _productChose.default,
    policySelect: _policySelect.default,
    scanZc: _scanZc.default
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    })
  },
  data: function data() {
    return {
      title: '添加买赠方案',
      subType: 'add',
      containsPackScale: 1,
      mloading: false,
      proList: [],
      configData: [],
      orgList: [],
      zzOptions: [],
      status: 0,
      statusName: '待提交',
      statusOption: [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }],
      isExectued: 1,
      submitData: {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        policyName: '',
        rebateOrGift: 2,
        dateTimeRange: ''
      },
      pid: '',
      policyName: '',
      triggerAction: 3,
      channelType: 1,
      levelOneData: [],
      levelTwoData: [],
      zdData: [],
      level: '',
      index: 0,
      type: '',
      oneConfig: [],
      toConfig: [],
      zdConfig: [],
      isActive1: 1,
      isActive2: 1,
      isActive3: 1,
      chanelClassList: [],
      chanelLeveList: [],
      zdClassList: [],
      zdLeveList: [],
      requestChannelTypes: [],
      proVisible: false,
      returnData: [],
      singleProVisible: false,
      configIndex: '',
      rowData: '',
      rules: {
        schemeName: [{
          required: true,
          message: '请输入方案名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '最大长度为50字符',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请选择起始时间',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间',
          trigger: 'blur'
        }],
        dateTimeRange: [{
          required: true,
          message: '请选择起始时间',
          trigger: 'blur'
        }],
        policyName: [{
          required: true,
          message: '请选择关联政策',
          trigger: 'blur'
        }]
      },
      actionType: null,
      readonly: false,
      policyVisible: false,
      checked1: true,
      checked2: false,
      checked3: false,
      showLevelOne: true,
      showLevelTwo: false,
      showLevelThd: false,
      activeName1: '1',
      activeName2: '2',
      activeName3: '3'
    };
  },
  created: function created() {
    var that = this;
    that.getConfigTypes();
  },
  mounted: function mounted() {
    var that = this;
    var orgOptions = that.$route.params.orgOptions;
    var subType = that.$route.params.type;
    var pid = that.$route.params.pid;
    that.subType = subType;
    that.submitData.pid = pid;
    that.pid = pid;
    if (orgOptions && orgOptions.length) {
      that.zzOptions = orgOptions;
    } else {
      that.getOrgOptions();
    }
    that.getRange(1);
    that.getRange(2);
    if (that.subType == 'edit') {
      that.getBasic(pid);
      that.getPro(pid);
      that.getOrg(pid);
      that.getCofig(pid);
    } else if (that.subType == 'add') {
      that.isActive = 1;
      that.status = 0;
      that.proList = [];
      that.configData = [];
      that.orgList = [];
      that.checked1 = true;
      that.checked2 = false;
      that.checked3 = false;
      that.showLevelOne = true;
      that.showLevelTwo = false;
      that.showLevelThd = false;
      that.submitData = {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        rebateOrGift: 2,
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        policyName: ''
      };
    }
  },
  methods: {
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData != null) {
            that.submitData.associatedPolicy = resData.associatedPolicy;
            that.submitData.beginTime = resData.beginTime;
            that.submitData.endTime = resData.endTime;
            that.submitData.pid = that.pid;
            that.submitData.schemeCode = resData.schemeCode;
            that.submitData.schemeDescribe = resData.schemeDescribe;
            that.submitData.schemeName = resData.schemeName;
            that.submitData.schemeType = parseInt(resData.schemeType);
            that.submitData.policyName = resData.policyName;
            that.submitData.dateTimeRange = [resData.beginTime, resData.endTime];
            that.status = parseInt(resData.schemeState);
          }
        }
      });
    },
    getOrgOptions: function getOrgOptions() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.zzOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var response = res.data.data;
          if (response && response.length > 0) {
            that.proList = response.map(function (item) {
              return {
                productCode: item.productCode,
                productName: item.productName,
                productClass: item.productClass,
                productId: item.productId,
                packScaleId: item.packScaleId,
                packScaleLevelItemDTOList: [{
                  pid: item.packScaleId,
                  unitName: item.packScaleName
                }],
                rebateProportion: 1
              };
            });
          }
        }
      });
    },
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getRebateRangeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var arr = [];
          var rData = res.data.data;
          if (rData.length > 0) {
            rData.forEach(function (item) {
              arr.push(item.orgId);
            });
          }
          that.orgList = arr;
        }
      });
    },
    //获取一级经销商，二级经销商，终端，类型
    getConfigTypes: function getConfigTypes() {
      var that = this;
      (0, _param.getRebateTypes)().then(function (res) {
        if (res.data.code == 200) {
          var cdata = res.data.data;
          if (cdata.length > 0) {
            that.requestChannelTypes = cdata;
          } else {
            that.requestChannelTypes = [];
          }
        }
      });
    },
    getCofig: function getCofig(arg) {
      var that = this;
      that.checked1 = false;
      that.checked2 = false;
      that.checked3 = false;
      that.showLevelOne = false;
      that.showLevelTwo = false;
      that.showLevelThd = false;
      (0, _param.getRebateDetailSchemeConfig)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData && resData.length) {
            that.requestChannelTypes = resData.map(function (item) {
              return {
                channelLevelId: item.channelLevelId,
                channelType: item.channelType
              };
            });
            var array = [];
            resData.forEach(function (item) {
              var obj = {};
              obj.order = item.order;
              obj.list = [];
              if (item.detail.length > 0) {
                obj.list = item.detail.map(function (res) {
                  var pzlist = [];
                  if (res.stepVoList.length) {
                    pzlist = res.stepVoList.map(function (res) {
                      return {
                        giftNum: res.giftNum,
                        giftProductId: res.giftProductId,
                        giftProductName: res.giftProductName,
                        giftUnit: res.giftUnit,
                        giftUnits: [{
                          unitName: res.giftUnitName,
                          pid: res.giftUnit
                        }],
                        pid: res.pid,
                        productNumStep: Number(res.productNumStep),
                        schemeConfigId: '',
                        schemeId: ''
                      };
                    });
                  } else {
                    pzlist[0] = {
                      giftNum: 0,
                      giftProductId: '',
                      giftProductName: '',
                      giftUnit: '',
                      giftUnits: [],
                      pid: '',
                      productNumStep: 0,
                      schemeConfigId: '',
                      schemeId: ''
                    };
                  }
                  return {
                    buyNum: Number(res.buyNum),
                    channelLevelId: res.channelLevelId,
                    channelType: res.channelType,
                    classId: res.classId,
                    gradeId: res.gradeId,
                    presentNum: Number(res.presentNum),
                    giftUnit: res.giftUnit,
                    unitList: [{
                      unitName: res.giftUnitName,
                      pid: res.giftUnit
                    }],
                    pzRebateSchemeStepParamList: pzlist,
                    rebatePrerequisite: res.rebatePrerequisite,
                    rebateProductId: res.rebateProductId,
                    rebateProductName: res.productName,
                    rebateType: res.rebateType,
                    triggerAction: res.triggerAction
                  };
                });
              }
              array.push(obj);
            });
            array.forEach(function (item) {
              if (item.order == 1) {
                that.levelOneData = item.list;
              }
              if (item.order == 2) {
                that.levelTwoData = item.list;
              }
              if (item.order == 3) {
                that.zdData = item.list;
              }
            });
            if (that.levelOneData.length) {
              that.checked1 = true;
              that.showLevelOne = true;
              var len = that.levelOneData.length;
              for (var i = 0; i < len; i++) {
                var obj = {
                  name: '配置' + (i + 1),
                  id: i + 1
                };
                that.oneConfig.push(obj);
              }
            }
            if (that.levelTwoData.length) {
              that.checked2 = true;
              that.showLevelTwo = true;
              var len = that.levelOneData.length;
              for (var _i = 0; _i < len; _i++) {
                var obj = {
                  name: '配置' + (_i + 1),
                  id: _i + 1
                };
                that.toConfig.push(obj);
              }
            }
            if (that.zdData.length) {
              that.checked3 = true;
              that.showLevelThd = true;
              var len = that.zdData.length;
              for (var _i2 = 0; _i2 < len; _i2++) {
                var obj = {
                  name: '配置' + (_i2 + 1),
                  id: _i2 + 1
                };
                that.zdConfig.push(obj);
              }
            }
            if (that.levelOneData.length == 0 && that.levelTwoData.length == 0 && that.zdData.length == 0) {
              that.checked1 = true;
              that.showLevelOne = true;
            }
          }
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      }).then(function (res) {
        console.log(that.levelOneData, that.levelTwoData, that.zdData);
      });
    },
    //获取时间范围
    getTime: function getTime(val) {
      var that = this;
      if (val) {
        that.submitData.beginTime = val[0];
        that.submitData.endTime = val[1];
      }
    },
    //切换买赠配置
    checkBox: function checkBox(e, type) {
      var that = this;
      switch (type) {
        case 1:
          that.activeName1 = '1';
          var len = that.levelOneData.length;
          if (!e) {
            if (len > 0) {
              that.$confirm('取消选择，所配置一级经销商买赠数据将被清空，确认取消？', '确认信息', {
                distinguishCancelAndClose: true,
                showClose: false,
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }).then(function () {
                that.levelOneData = [];
                that.showLevelOne = e;
              }).catch(function () {
                that.checked1 = true;
                that.showLevelOne = true;
              });
            } else {
              that.showLevelOne = e;
            }
          } else {
            that.showLevelOne = e;
          }
          break;
        case 2:
          that.activeName2 = '2';
          var len = that.levelTwoData.length;
          if (!e) {
            if (len > 0) {
              that.$confirm('取消选择，所配置二级经销商买赠数据将被清空，确认取消？', '确认信息', {
                distinguishCancelAndClose: true,
                showClose: false,
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }).then(function () {
                that.levelTwoData = [];
                that.showLevelTwo = e;
              }).catch(function () {
                that.checked2 = true;
                that.showLevelTwo = true;
              });
            } else {
              that.showLevelTwo = e;
            }
          } else {
            that.showLevelTwo = e;
          }
          break;
        case 3:
          that.activeName3 = '3';
          var len = that.zdData.length;
          if (!e) {
            if (len > 0) {
              that.$confirm('取消选择，所配置二级经销商买赠数据将被清空，确认取消？', '确认信息', {
                distinguishCancelAndClose: true,
                showClose: false,
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }).then(function () {
                that.zdData = [];
                that.showLevelThd = e;
              }).catch(function () {
                that.checked3 = true;
                that.showLevelThd = true;
              });
            } else {
              that.showLevelThd = e;
            }
          } else {
            that.showLevelThd = e;
          }
          break;
      }
    },
    //取消
    handleCloseStep: function handleCloseStep(formName) {
      var that = this;
      that.handleClose();
      that.$router.back();
    },
    handleClose: function handleClose() {
      var that = this;
      that.submitData = {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        rebateOrGift: 2,
        policyName: ''
      };
    },
    //配置切换
    getCurList: function getCurList(id, type) {
      console.log(id);
      var that = this;
      switch (type) {
        case '1':
          that.isActive1 = id + 1;
          break;
        case '2':
          that.isActive2 = id + 1;
          break;
        case '3':
          that.isActive3 = id + 1;
          break;
      }
    },
    //获取范围选择
    getRange: function getRange(val) {
      var that = this;
      var param = {
        channelType: val,
        classStatus: '1',
        pageNo: 0,
        pageSize: 0
      };
      (0, _param.getQdFl)(param).then(function (res) {
        if (res.data.code == 200) {
          if (val == 1) {
            that.chanelClassList = res.data.data;
          }
          if (val == 2) {
            that.zdClassList = res.data.data;
          }
        }
      }).then(function () {
        var args = {
          channelType: val,
          gradeStatus: '1',
          pageNo: 0,
          pageSize: 0
        };
        (0, _param.getQdDJ)(args).then(function (res) {
          if (res.data.code == 200) {
            if (val == 1) {
              that.chanelLeveList = res.data.data;
            }
            if (val == 2) {
              that.zdLeveList = res.data.data;
            }
          }
        });
      });
    },
    //选取参与产品列表
    addProducts: function addProducts() {
      var that = this;
      that.proVisible = true;
    },
    closePro: function closePro() {
      var that = this;
      that.proVisible = false;
    },
    fetchPros: function fetchPros(val) {
      var that = this;
      var arr = val.map(function (item) {
        return {
          productCode: item.productCode,
          productName: item.productName,
          productClass: item.productClassName,
          productId: item.pId,
          packScaleId: '',
          packScaleLevelItemDTOList: item.packScaleLevelItemDTOList,
          rebateProportion: 1
        };
      });
      that.proList = (0, _utils.uniqueJsonArray)(that.proList.concat(arr), 'productId');
    },
    //删除产品
    delPro: function delPro(data) {
      var that = this;
      var id = data.productId;
      var proList = that.proList;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        for (var i = 0; i < proList.length; i++) {
          if (proList[i].productId == id) {
            that.proList.splice(i, 1);
            break;
          }
        }
      }).catch(function () {});
    },
    //获取单一产品
    getGiftProduct: function getGiftProduct(index, level, type) {
      var that = this;
      that.index = index;
      that.level = level;
      that.type = type;
      that.singleProVisible = true;
    },
    getSinglePro: function getSinglePro(val) {
      var that = this;
      if (that.level == 'levelOne') {
        var index = that.isActive1 - 1;
        if (that.type == 'basic') {
          that.levelOneData[that.index].rebateProductId = val.pId;
          that.levelOneData[that.index].rebateProductName = val.productName;
          that.levelOneData[that.index].unitList = val.packScaleLevelItemDTOList;
        }
        if (that.type == 'full') {
          that.levelOneData[index].pzRebateSchemeStepParamList[that.index].giftProductId = val.pId;
          that.levelOneData[index].pzRebateSchemeStepParamList[that.index].giftProductName = val.productName;
          that.levelOneData[index].pzRebateSchemeStepParamList[that.index].giftUnits = val.packScaleLevelItemDTOList;
        }
      }
      if (that.level == 'levelTo') {
        var index = that.isActive2 - 1;
        if (that.type == 'basic') {
          that.levelTwoData[that.index].rebateProductId = val.pId;
          that.levelTwoData[that.index].rebateProductName = val.productName;
          that.levelTwoData[that.index].unitList = val.packScaleLevelItemDTOList;
        }
        if (that.type == 'full') {
          that.levelTwoData[index].pzRebateSchemeStepParamList[that.index].giftProductId = val.pId;
          that.levelTwoData[index].pzRebateSchemeStepParamList[that.index].giftProductName = val.productName;
          that.levelTwoData[index].pzRebateSchemeStepParamList[that.index].giftUnits = val.packScaleLevelItemDTOList;
        }
      }
      if (that.level == 'levelTh') {
        var index = that.isActive3 - 1;
        console.log(index, that.index);
        if (that.type == 'basic') {
          that.zdData[that.index].rebateProductId = val.pId;
          that.zdData[that.index].rebateProductName = val.productName;
          that.zdData[that.index].unitList = val.packScaleLevelItemDTOList;
        }
        if (that.type == 'full') {
          that.zdData[index].pzRebateSchemeStepParamList[that.index].giftProductId = val.pId;
          that.zdData[index].pzRebateSchemeStepParamList[that.index].giftProductName = val.productName;
          that.zdData[index].pzRebateSchemeStepParamList[that.index].giftUnits = val.packScaleLevelItemDTOList;
        }
      }
    },
    closeSigle: function closeSigle() {
      var that = this;
      that.singleProVisible = false;
    },
    //添加配置方案
    addConfig: function addConfig(arg, data, index) {
      var that = this;
      var obj = {
        giftNum: 0,
        giftProductId: '',
        giftProductName: '',
        giftUnit: '',
        giftUnits: [],
        pid: '',
        productNumStep: 0,
        schemeConfigId: '',
        schemeId: ''
      };
      if (data.giftProductId == '') {
        that.$message.error('请选择满量赠品！');
        return;
      }
      if (data.giftUnit == '') {
        that.$message.error('请选择满量赠品单位！');
        return;
      }

      //满赠产品数量为0时
      if (data.productNumStep == 0) {
        if (data.giftNum > 0) {
          that.$message.error('请输入满量产品数量,所输入的值必须为正整数,最小值为1！');
          return;
        }
        if (data.giftNum == '') {
          that.$message.error('请输入满量赠品数量,所输入的值必须为正整数！');
          return;
        }
        if (data.giftNum < 0) {
          that.$message.error('请输入满量赠品数量,所输入的值必须为正整数！');
          data.giftNum = Math.abs(data.giftNum);
          return;
        }
      }

      //满赠产品数量为''时
      if (data.productNumStep == '') {
        if (data.giftNum > 0) {
          that.$message.error('请输入满量产品数量,所输入的值必须为正整数,最小值为1！');
          return;
        } else {
          that.$message.error('请输入满量产品数量,所输入的值必须为正整数！');
          return;
        }
      }

      //满赠产品数量为负数时
      if (data.productNumStep < 0) {
        that.$message.error('请输入满量产品数量,所输入的值必须为正整数！');
        data.productNumStep = Math.abs(data.productNumStep);
        return;
      }
      data.giftNum = Math.abs(data.giftNum);
      data.productNumStep = Math.abs(data.productNumStep);
      if (arg == 'levelOne') {
        var index = that.isActive1 - 1;
        that.levelOneData[index].pzRebateSchemeStepParamList.push(obj);
      }
      if (arg == 'levelTo') {
        var index = that.isActive2 - 1;
        that.levelTwoData[index].pzRebateSchemeStepParamList.push(obj);
      }
      if (arg == 'levelTh') {
        var index = that.isActive3 - 1;
        that.zdData[index].pzRebateSchemeStepParamList.push(obj);
      }
    },
    //删除配置方案
    delConfig: function delConfig(arg, index) {
      var that = this;
      if (arg == 'levelOne') {
        var key = that.isActive1 - 1;
        that.levelOneData[key].pzRebateSchemeStepParamList.splice(index, 1);
      }
      if (arg == 'levelTo') {
        var key = that.isActive2 - 1;
        that.levelTwoData[key].pzRebateSchemeStepParamList.splice(index, 1);
      }
      if (arg == 'levelTh') {
        var key = that.isActive3 - 1;
        that.zdData[key].pzRebateSchemeStepParamList.splice(index, 1);
      }
    },
    //添加项
    addItem: function addItem(arg) {
      var that = this;
      var level = {
        buyNum: 0,
        channelLevelId: '',
        channelType: 1,
        classId: '',
        gradeId: '',
        presentNum: 0,
        giftUnit: '',
        unitList: [],
        pzRebateSchemeStepParamList: [{
          giftNum: 0,
          giftProductId: '',
          giftProductName: '',
          giftUnit: '',
          giftUnits: [],
          pid: '',
          productNumStep: 0,
          schemeConfigId: '',
          schemeId: ''
        }],
        rebatePrerequisite: null,
        rebateProductId: '',
        rebateProductName: '',
        rebateType: 0,
        triggerAction: 3
      };
      if (arg == 'levelOne') {
        var length = that.oneConfig.length + 1;
        var obj = {
          name: '配置' + length,
          id: length
        };
        var bool = that.checkConfigData(that.levelOneData);
        level.channelType = 1;
        if (bool) {
          that.oneConfig.push(obj);
          that.levelOneData.push(level);
        }
      }
      if (arg == 'levelTo') {
        var length = that.toConfig.length + 1;
        var obj = {
          name: '配置' + length,
          id: length
        };
        var bool = that.checkConfigData(that.levelTwoData);
        level.channelType = 1;
        if (bool) {
          that.toConfig.push(obj);
          that.levelTwoData.push(level);
        }
      }
      if (arg == 'levelTh') {
        var length = that.zdConfig.length + 1;
        var obj = {
          name: '配置' + length,
          id: length
        };
        var bool = that.checkConfigData(that.zdData);
        level.channelType = 2;
        if (bool) {
          that.zdConfig.push(obj);
          that.zdData.push(level);
        }
      }
    },
    //删除项
    delItem: function delItem(arg) {
      var that = this;
      if (arg == 'levelOne') {
        var index = that.isActive1 - 1;
        that.levelOneData.splice(index, 1);
        that.oneConfig.splice(index, 1);
        if (that.oneConfig.length) {
          that.oneConfig = that.oneConfig.map(function (item, index) {
            return {
              name: '配置' + (index + 1),
              id: index + 1
            };
          });
        }
        that.isActive1 = 1;
      }
      if (arg == 'levelTo') {
        var index = that.isActive2 - 1;
        that.levelTwoData.splice(index, 1);
        that.toConfig.splice(index, 1);
        if (that.toConfig.length) {
          that.toConfig = that.toConfig.map(function (item, index) {
            return {
              name: '配置' + (index + 1),
              id: index + 1
            };
          });
        }
        that.isActive2 = 1;
      }
      if (arg == 'levelTh') {
        var index = that.isActive3 - 1;
        that.zdData.splice(index, 1);
        that.zdConfig.splice(index, 1);
        if (that.zdConfig.length) {
          that.zdConfig = that.zdConfig.map(function (item, index) {
            return {
              name: '配置' + (index + 1),
              id: index + 1
            };
          });
        }
        that.isActive3 = 1;
      }
    },
    checkConfigData: function checkConfigData(data) {
      var that = this;
      var bool = true;
      for (var i = 0; i < data.length; i++) {
        if (data[i].classId == '') {
          bool = false;
          that.$message.error('请选渠道分类');
          break;
        }
        if (data[i].gradeId == '') {
          bool = false;
          that.$message.error('请选渠道等级');
          break;
        }
      }
      return bool;
    },
    //删除
    DoDel: function DoDel(index, data) {
      var that = this;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        that.configData.splice(index, 1);
      }).catch(function () {});
    },
    //获取政策
    chosePolice: function chosePolice() {
      var that = this;
      that.$refs.policy.listQuery.policyType = 2;
      that.$refs.policy.policeSelVisible = true;
    },
    getPolice: function getPolice(value) {
      var that = this;
      that.submitData.associatedPolicy = value.pid;
      that.submitData.policyName = value.policyName;
      that.$refs.zcbm.focus();
      that.$refs.zcbm.blur();
    },
    scanPolicy: function scanPolicy() {
      var that = this;
      if (that.submitData.policyCode == '') {
        that.$message.error('请选择政策');
        return;
      } else {
        that.policyVisible = true;
      }
    },
    closeScan: function closeScan() {
      this.policyVisible = false;
    },
    //提交，保存
    submit: function submit(val, formName) {
      var that = this;
      that.mloading = true;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          //判断是否选择返利范围
          if (that.orgList.length == 0) {
            that.$message.error('请选择返利范围');
            that.mloading = false;
            return;
          }
          var config1 = that.levelOneData;
          var config2 = that.levelTwoData;
          var config3 = that.zdData;
          var id1 = that.requestChannelTypes[0].channelLevelId;
          var id2 = that.requestChannelTypes[1].channelLevelId;
          var id3 = that.requestChannelTypes[2].channelLevelId;
          var data1 = [];
          var data2 = [];
          var data3 = [];
          var check1 = true;
          var check2 = true;
          var check3 = true;
          if (config1.length) {
            data1 = that.checkSubData(config1, id1).array;
            check1 = that.checkSubData(config1, id1).bool;
          }
          if (config2.length) {
            data2 = that.checkSubData(config2, id2).array;
            check2 = that.checkSubData(config2, id2).bool;
          }
          if (config3.length) {
            data3 = that.checkSubData(config3, id3).array;
            check3 = that.checkSubData(config3, id3).bool;
          }
          if (!(check1 && check2 && check3)) {
            that.mloading = false;
            return;
          }
          var configList = [].concat(data1).concat(data2).concat(data3);
          if (configList.length) {
            var bool = true;
            configList.forEach(function (item) {
              if (item.pzRebateSchemeStepParamList.length == 0) {
                if (typeof item.rebateProductId == 'undefined') {
                  that.$message.error('请选择买赠产品！');
                  that.mloading = false;
                  bool = false;
                  return;
                }
              }
            });
            if (!bool) {
              return;
            }
          } else {
            that.$message.error('请选择返利配置！');
            that.mloading = false;
            return;
          }
          console.log(configList, '参数');
          var proList = [];
          that.proList.forEach(function (item) {
            var obj = {};
            obj.packScaleId = item.packScaleId;
            obj.productId = item.productId;
            obj.rebateProportion = 1;
            proList.push(obj);
          });
          that.submitData.orgList = that.orgList;
          that.submitData.proList = proList;
          that.submitData.configList = configList;
          that.submitData.schemeState = parseInt(val);
          that.submitData.pid = that.pid;
          that.submitData.rebateOrGift = 2;
          var sdata = (0, _utils.deepClone)(that.submitData);
          delete sdata.policyName;
          delete sdata.dateTimeRange;
          console.log(sdata);
          (0, _param.saveHdFlData)(sdata).then(function (res) {
            if (res.data.code == 200) {
              that.mloading = false;
              that.$message({
                type: 'success',
                message: res.data.msg
              });
              that.handleCloseStep('submitData');
              that.$parent.getList();
            } else {
              that.mloading = false;
              that.$message.error(res.data.msg);
            }
          }).catch(function () {
            that.mloading = false;
          });
        } else {
          that.mloading = false;
          return false;
        }
      });
    },
    //检验提交数据
    checkSubData: function checkSubData(data, id) {
      var that = this;
      var bool = true;
      var array = [];
      for (var i = 0; i < data.length; i++) {
        data[i].channelLevelId = id;
        var item = data[i];
        if (item.classId == '') {
          that.$message.error('请选择渠道分类！');
          bool = false;
          break;
        }
        if (item.gradeId == '') {
          that.$message.error('请选择渠道等级！');
          bool = false;
          break;
        }
        var pzData = item.pzRebateSchemeStepParamList;
        var obj = {};
        obj.channelLevelId = id;
        obj.channelType = item.channelType;
        obj.classId = item.classId;
        obj.gradeId = item.gradeId;
        obj.rebatePrerequisite = item.rebatePrerequisite;
        obj.rebateType = item.rebateType;
        obj.triggerAction = item.triggerAction;
        if (item.rebateProductId != '') {
          obj.rebateProductId = item.rebateProductId;
          if (item.giftUnit == '') {
            that.$message.error('请选择基础买赠单位！');
            bool = false;
            break;
          } else {
            obj.giftUnit = item.giftUnit;
          }
          if (item.buyNum != 0 && item.presentNum != 0) {
            obj.presentNum = item.presentNum;
            obj.buyNum = item.buyNum;
          }
          if (item.buyNum == 0 && item.presentNum == 0) {
            that.$message.error('请输入满赠数量，不为0的正整数！');
            bool = false;
            break;
          }
          if (item.buyNum == 0 || item.buyNum == '' || item.buyNum == null) {
            if (item.presentNum > 0) {
              that.$message.error('请输入满赠数量最小值为1！');
              bool = false;
              break;
            } else {
              that.$message.error('请输入满赠数量！');
              bool = false;
              break;
            }
          }
          if (item.presentNum == 0 || item.presentNum == '' || item.presentNum == null) {
            that.$message.error('请输入赠品数量！');
            bool = false;
            break;
          }
        }
        if (pzData.length) {
          obj.pzRebateSchemeStepParamList = [];
          for (var j = 0; j < pzData.length; j++) {
            var object = {};
            var tem = pzData[j];
            if (tem.giftProductId != '') {
              object.giftProductId = tem.giftProductId;
              object.pid = tem.pid;
              object.schemeConfigId = tem.schemeConfigId;
              object.schemeId = tem.schemeId;
              if (tem.giftUnit == '') {
                that.$message.error('请选择买赠单位！');
                bool = false;
                break;
              } else {
                object.giftUnit = tem.giftUnit;
              }
              if (tem.productNumStep != 0 && tem.giftNum != 0) {
                object.giftNum = Number(tem.giftNum);
                object.productNumStep = Number(tem.productNumStep);
              }
              if (tem.productNumStep == 0 && tem.giftNum == 0) {
                that.$message.error('请输入满赠数量，不为0的正整数！');
                bool = false;
                break;
              }
              if (tem.productNumStep == 0 || tem.productNumStep == '' || tem.productNumStep == null) {
                if (tem.giftNum > 0) {
                  that.$message.error('请输入满赠数量最小值为1！');
                  bool = false;
                  break;
                } else {
                  that.$message.error('请输入满赠数量！');
                  bool = false;
                  break;
                }
              }
              if (tem.giftNum == 0 || tem.giftNum == '' || tem.giftNum == null) {
                that.$message.error('请输入赠品数量！');
                bool = false;
                break;
              }
              obj.pzRebateSchemeStepParamList.push(object);
            }
          }
        }
        array.push(obj);
      }
      return {
        array: array,
        bool: bool
      };
    },
    //下一步
    checkStep1: function checkStep1(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var begin = new Date(that.submitData.beginTime);
          var end = new Date(that.submitData.endTime);
          if (begin > end) {
            that.$message.error('开始时间不得大于结束时间');
            return;
          }
          var pdata = that.proList;
          var pfound = true;
          if (pdata.length > 0) {
            for (var i = 0; i < pdata.length; i++) {
              if (pdata[i].packScaleId == '') {
                that.mloading = false;
                that.$message.error('参与产品列表第' + (i + 1) + '行，请选择单位');
                pfound = false;
                break;
              }
            }
          } else {
            that.$message.error('请添加产品');
            that.mloading = false;
            return;
          }
          if (!pfound) {
            that.mloading = false;
            return;
          }
          that.isExectued = 2;
        }
      });
    },
    //上一步
    prevStep: function prevStep() {
      var that = this;
      that.isExectued = 1;
    }
  }
};
exports.default = _default;