"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _memberManage = require("@/api/terminal/memberManage");
var _index = require("@/utils/index.js");
var _BarChart = _interopRequireDefault(require("@/components/Charts/BarChart"));
var _MapChart = _interopRequireDefault(require("@/components/Charts/MapChart"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.show = false;
    next();
  },
  components: {
    BarChart: _BarChart.default,
    MapChart: _MapChart.default
  },
  data: function data() {
    return {
      active: '-7',
      daterange: [],
      show: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    setTimeout(function () {
      _this.show = true;
      _this.changeChart(-7);
      _this.getmapChart();
    }, 1500);
  },
  methods: {
    fetchData: function fetchData(list) {
      var _this2 = this;
      this.listLoading = true;
      (0, _memberManage.getBar)({
        startTime: list[0],
        endTime: list[1]
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          var xData = [];
          var sData = [];
          res.data.forEach(function (item) {
            xData.push(item.time);
            sData.push(item.count);
            // sData.push(10)
          });

          _this2.$refs.barChart.xData = xData;
          _this2.$refs.barChart.sData = sData;
          _this2.$refs.barChart.initChart();
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    getmapChart: function getmapChart(list) {
      var _this3 = this;
      this.listLoading = true;
      (0, _memberManage.getMap)().then(function (res) {
        res = res.data;
        if (res.status === 200) {
          var data = [];
          var maxCount = 50;
          res.data.forEach(function (item) {
            var obj = {
              name: item.provinceName,
              value: item.count
            };
            if (item.count > maxCount) maxCount = item.count;
            data.push(obj);
          });
          _this3.$refs.mapChart.mapData = data;
          _this3.$refs.mapChart.max = maxCount;
          _this3.$refs.mapChart.initChart();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this3.listLoading = false;
      });
    },
    changeChart: function changeChart(num) {
      this.active = num;
      this.fetchData((0, _index.getFormatDate)(num));
    }
  }
};
exports.default = _default;