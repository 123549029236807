var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            staticStyle: { padding: "20px 0 0 20px" },
            attrs: {
              inline: true,
              model: _vm.formInline,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "产品" } },
              [
                _c("el-cascader", {
                  attrs: { options: _vm.formInline.options, clearable: "" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "时间区间" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.formInline.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "time", $$v)
                    },
                    expression: "formInline.time",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", { staticStyle: { "padding-top": "5px" } }, [
              _c("div", { staticClass: "btnArea" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.searchBtn.type,
                          icon: _vm.btnConfig.searchBtn.icon,
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.resetBtn.type,
                          icon: _vm.btnConfig.resetBtn.icon,
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { height: "500px" }, attrs: { id: "bar" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }