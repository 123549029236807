"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMenu = addMenu;
exports.base = void 0;
exports.getDetail = getDetail;
exports.getMedia = getMedia;
exports.getTxt = getTxt;
exports.searchMenu = searchMenu;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
var baseUrl = '';
var base = 'http://saas.panpass.com/images/';
// export const base = 'http://testsaas.panpass.com/images/'
// 查询分组
exports.base = base;
function searchMenu() {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxMenu/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get'
  });
}

// 添加菜单
function addMenu(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/wxMenu/save',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}

// 查询图文
function getTxt(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/news/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: param
  });
}

// 查询图片/音频/视频/
function getMedia(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    type: 'get',
    params: param
  });
}

// 根据mediaId,contentType 查询详细信息
function getDetail(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/files/selectDataByMediaId',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    type: 'get',
    params: param
  });
}