"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _configure = require("@/api/warehouse/configure/configure");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'table-suply',
  components: {},
  data: function data() {
    return {
      form: {
        inStyle: '1',
        pId: ''
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      (0, _configure.queryConfig)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          that.form.pId = response.data.data.pid;
          that.form.inStyle = response.data.data.inStyle + '';
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$confirm('确定要更改生产入库方式, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _configure.updateConfig)(_this2.form).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: data.msg,
            type: 'success'
          });
          _this2.getList();
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;