"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _sceneMarketing = require("@/api/market/sceneMarketing");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _UploadImgNum = _interopRequireDefault(require("@/components/UploadImgNum"));
var _UploadMusic = _interopRequireDefault(require("@/components/UploadMusic"));
var _auth = require("@/utils/auth");
var _utils = require("@/utils");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagesceneMarketinginvitationListindex';
var _default = {
  name: 'MarketingManagesceneMarketinginvitationListindex',
  components: {
    Pagination: _Pagination.default,
    UploadImg: _UploadImg.default,
    UploadMusic: _UploadMusic.default,
    UploadImgNum: _UploadImgNum.default
  },
  filters: {
    stateFilter: function stateFilter(status) {
      var statusMap = {
        1: '未开始',
        2: '进行中',
        3: '已结束'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      srcList: [],
      templateRadio: '',
      imageUrl: '',
      total: 0,
      datalist: [],
      options: '',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      tabHeight: '100%',
      Prizename: '',
      listLoading: false,
      dialogTableVisible: false,
      presentDate: new Date().getTime(),
      dialogTitle: '邀请函制作',
      choseData: true,
      fileList: [],
      disable: '',
      disabled: false,
      hideUpload: false,
      qyorgId: '',
      orgList: [],
      timeData: [],
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      searchForm: {
        pageNow: 1,
        pageSize: 20,
        qyorgId: '',
        createTime: '',
        theme: ''
      },
      formInline: {
        pageNow: 1,
        pageSize: 20,
        theme: '',
        bgImageAdderss: '',
        bgImageName: '',
        bgMusicAddress: '',
        bgMusicName: '',
        actId: '',
        endTime: '',
        imgs: [],
        qrCode: '',
        actName: ''
      },
      formInlineRules: {
        theme: [{
          required: true,
          message: '请输入邀请函主题',
          trigger: 'blur'
        }],
        actName: [{
          required: true,
          message: '请选择所属活动',
          trigger: 'blur'
        }],
        bgImageAdderss: [{
          required: true,
          message: '请上传背景图片',
          trigger: 'change'
        }],
        bgMusicAddress: [{
          required: true,
          message: '请上传背景音乐',
          trigger: 'change'
        }],
        endTime: [{
          required: true,
          message: '请选择日期时间',
          trigger: 'change'
        }]
      },
      apis: []
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadImg';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        this.fetchData();
        this.getActlist();
        this.getOrgListAll();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  methods: {
    addInvite: function addInvite() {
      this.authId('addbtn', this.apis);
      this.dialogTableVisible = true;
      this.disabled = false;
      this.disable = false;
      this.dialogTitle = '邀请函编辑';
    },
    getval: function getval(e) {
      var obj = {};
      this.options.map(function (item) {
        if (item.id === e) {
          obj = item;
        }
      });
      // console.log(obj);
      this.formInline.actId = obj.id;
      this.formInline.actName = obj.actName;
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file, index) {
      console.log(file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
        this.formInline.imgs[index].imgAddress = res.data;
        this.formInline.imgs[index].imgName = file.name;
      } else {
        this.$message({
          message: '上传失败',
          type: 'error',
          duration: 3 * 1000
        });
      }
      console.log(res, index);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      var isLt2M = file.size / 1024 / 1024 < 0.5;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 500k!');
      }
      return isLt2M;
    },
    getTemplateRow: function getTemplateRow(index, row) {
      console.log(index, row);
      if (index === this.templateRadio) {
        this.templateRadio = '';
        this.formInline.imgs.forEach(function (item, index) {
          item.isconfirm = '2';
        });
      } else {
        this.templateRadio = index;
        this.formInline.imgs.forEach(function (item, index) {
          item.isconfirm = '2';
        });
        this.formInline.imgs[index].isconfirm = '1';
      }

      // console.log(this.formInline.imgs);
    },
    getTimeFun: function getTimeFun(val) {
      var num = new Date(val).getTime();
      return num;
    },
    preview: function preview(id) {
      this.authId('previewbtn', this.$route.meta.authList);
      window.open('http://testhc.panpass.cn/wxh5/sceneWap.html?actId=' + id, '_blank');
    },
    getOrgListAll: function getOrgListAll() {
      var _this = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: ''
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    getActlist: function getActlist() {
      var _this2 = this;
      var obj = {
        pageNow: 1,
        pageSize: 99999
      };
      (0, _sceneMarketing.Actlist)(obj).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this2.options = res.data.list;
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    fetchData: function fetchData() {
      var _this3 = this;
      this.listLoading = true;
      var that = this;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      if (that.qyorgId) {
        submitData.qyorgId = that.qyorgId[that.qyorgId.length - 1];
      }
      this.searchForm.serchstartTime = this.timeData[0];
      this.searchForm.serchendTime = this.timeData[1];
      (0, _sceneMarketing.listInvitation)(this.searchForm).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.status == 200) {
          _this3.datalist = res.data.list;
          _this3.total = res.data.total;
        } else {
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this3.listLoading = false;
      });
    },
    createData: function createData() {
      var _this4 = this;
      // console.log(this.formInline);
      // return false;
      var data = new Date(this.formInline.endTime).getTime();
      if (data < this.presentDate) {
        this.$message({
          message: '过期时间不能小于当前时间',
          type: 'error',
          duration: 3 * 1000
        });
        return false;
      }
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          var imglength = _this4.formInline.imgs;
          var index = imglength.some(function (item, index) {
            return item.isconfirm == '1';
          });
          // console.log(this.formInline);
          // return false;
          if (!index) {
            _this4.$message({
              message: '请选择邀请确认',
              type: 'error',
              duration: 3 * 1000
            });
            return false;
          }
          (0, _sceneMarketing.addInvitation)(_this4.formInline).then(function (res) {
            res = res.data;
            if (res.status == 200) {
              _this4.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this4.cancel();
              _this4.fetchData();
            } else {
              _this4.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getDetail: function getDetail(val, type, id) {
      var _this5 = this;
      if (val === 2) {
        this.authId('scanbtn', this.$route.meta.authList);
        this.disabled = true;
        this.dialogTitle = '邀请函详情';
      } else {
        this.authId('updatebtn', this.$route.meta.authList);
        this.disabled = false;
        // this.disable = true;
        this.dialogTitle = '邀请函编辑';
      }
      (0, _sceneMarketing.listdetailes)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this5.choseData = false;
          _this5.dialogTableVisible = true;
          _this5.formInline = res.data;
          // this.formInline.actId = res.data.actName;
          _this5.$nextTick(function () {
            _this5.$refs.bgImageUpload.fileList = [{
              url: res.data.bgImageAdderss
            }];
            _this5.$refs.bgImageUpload.hideUpload = true;
            _this5.$refs.bgMusicUpload.fileList = [{
              url: res.data.bgMusicAddress,
              name: res.data.bgMusicName
            }];
            _this5.$refs.bgMusicUpload.hideUpload = true;
            _this5.srcList = [];
            _this5.srcList.push(res.data.qrCode);
            var arr = [];
            for (var i = 0; i < res.data.imgs.length; i++) {
              if (res.data.imgs[i].isconfirm == '1') {
                _this5.templateRadio = i;
                // console.log(this.templateRadio);
              }
            }

            res.data.imgs.forEach(function (item, index) {
              var obj = {
                url: item.imgAddress,
                name: item.imgName
              };
              arr.push(obj);
            });
            _this5.$refs.imgsUpload.fileList = arr;
            _this5.$refs.imgsUpload.hideUpload = true;
          });
        } else {
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    updateData: function updateData(id) {
      var _this6 = this;
      // console.log(this.formInline.imgs);
      // return false;
      var data = new Date(this.formInline.endTime).getTime();
      if (data < this.presentDate) {
        this.$message({
          message: '过期时间不能小于当前时间',
          type: 'error',
          duration: 3 * 1000
        });
        return false;
      }
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          var imglength = _this6.formInline.imgs;
          var index = imglength.some(function (item, index) {
            return item.isconfirm == '1';
          });
          // console.log(this.formInline);
          // return false;
          if (!index) {
            _this6.$message({
              message: '请选择邀请确认',
              type: 'error',
              duration: 3 * 1000
            });
            return false;
          }
          (0, _sceneMarketing.listupdate)(_this6.formInline).then(function (res) {
            res = res.data;
            if (res.status == 200) {
              _this6.dialogTableVisible = false;
              _this6.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this6.cancel();
              _this6.fetchData();
            } else {
              _this6.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeTable: function removeTable() {
      this.formInline.imgs.splice(this.formInline.imgs.length - 1, 1);
      this.$refs.imgsUpload.fileList.splice(this.$refs.imgsUpload.fileList.length - 1, 1);
    },
    // 弹窗取消
    cancel: function cancel() {
      this.formInline = {
        theme: '',
        bgImageAdderss: '',
        bgImageName: '',
        bgMusicAddress: '',
        bgMusicName: '',
        actId: '',
        endTime: '',
        imgs: [],
        qrCode: '',
        actName: ''
      };
      // this.templateRadio = ""
      this.restForm('formInline');
      this.$refs.bgImageUpload.fileList = [];
      this.$refs.bgImageUpload.hideUpload = false;
      this.$refs.bgMusicUpload.fileList = [];
      this.$refs.bgMusicUpload.hideUpload = false;
      this.$refs.imgsUpload.fileList = [];
      this.$refs.imgsUpload.hideUpload = false;
      this.dialogTableVisible = false;
      this.disabled = false;
      this.choseData = true;
      this.templateRadio = '';
    },
    // 弹窗取消
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.searchForm.qyorgId = '';
      this.qyorgId = '';
      this.timeData = [];
    }
  }
};
exports.default = _default;