"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _work = require("@/api/systems/work");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'systemsplcmangespsetindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listQuery: {
        moduleCode: '',
        name: '',
        billCode: '',
        pageNo: 1,
        pageSize: 20
      },
      list: [],
      tableKey: 'operratelog',
      total: 0,
      listLoading: false,
      loading: false,
      moduleData: [],
      pageData: [],
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      scanData: {},
      editData: {},
      src: '',
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false
    };
  },
  activated: function activated() {
    var that = this;
    that.getList();
    that.getMoudle();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _work.getWorkFlu)(that.listQuery).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    getMoudle: function getMoudle() {
      var that = this;
      (0, _work.getMoudleMsg)().then(function (res) {
        if (res.data.code == 200) {
          that.moduleData = res.data.data;
        }
      });
    },
    getPages: function getPages(val) {
      var that = this;
      var param = {};
      param.moduleCode = val;
      (0, _work.getPageMsg)(param).then(function (res) {
        if (res.data.code == 200) {
          that.pageData = res.data.data;
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.name = '';
      that.listQuery.billCode = '';
      that.listQuery.moduleCode = '';
      that.$refs[formName].resetFields();
      that.listQuery.pageNo = 1;
      that.getList();
    },
    DoEdit: function DoEdit(data) {
      var that = this;
      that.editData = (0, _utils.deepClone)(data);
      that.dialogVisible2 = true;
    },
    DoScan: function DoScan(data) {
      var that = this;
      that.scanData = (0, _utils.deepClone)(data);
      that.dialogVisible1 = true;
    },
    changeState: function changeState(row) {
      var that = this;
      var param = {};
      param.pId = row.pid;
      param.status = row.status;
      (0, _work.changeStates)(param).then(function (res) {
        console.log(res.data);
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '状态修改成功'
          });
        } else {
          that.getList();
          that.$message.error(res.data.msg);
        }
      }).catch(function (error) {
        that.getList();
      });
    },
    submit: function submit() {
      var that = this;
      var param = {};
      that.loading = true;
      param.PId = that.editData.pid;
      param.remark = that.editData.remark;
      param.status = that.editData.status;
      (0, _work.update)(param).then(function (res) {
        if (res.data.code == 200) {
          that.handleClose2();
          that.loading = false;
          that.getList();
          that.$message({
            type: 'success',
            message: '修改成功'
          });
        } else {
          that.loading = false;
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    doCancel: function doCancel() {
      var that = this;
      that.editData.remark = '';
      that.handleClose2();
    },
    DoSetLpc: function DoSetLpc(row) {
      var that = this;
      var param = {};
      param.name = row.modelName;
      param.key = row.modelKey;
      (0, _work.getModelId)(param).then(function (res) {
        if (res.data.code == 200) {
          that.src = 'http://testhc.panpass.cn/workflu?modelId=' + res.data.data;
          that.dialogVisible3 = true;
        } else {
          that.$message.error('未获取流程图配置参数,无法预览、配置流程图');
        }
      });
    },
    handleClose1: function handleClose1() {
      var that = this;
      that.dialogVisible1 = false;
    },
    handleClose2: function handleClose2() {
      var that = this;
      that.dialogVisible2 = false;
    },
    handleClose3: function handleClose3() {
      var that = this;
      that.dialogVisible3 = false;
    }
  }
};
exports.default = _default;