"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCapitalList = addCapitalList;
exports.defaultItem = defaultItem;
exports.delItem = delItem;
exports.getCapitalList = getCapitalList;
exports.updateItem = updateItem;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 资金配置列表
function getCapitalList(params) {
  return (0, _request.default)({
    url: base + '/market-config/officialFundConfiguration/fundConfigurationList',
    method: 'get',
    params: params
  });
}

// 资金配置列表添加
function addCapitalList(data) {
  return (0, _request.default)({
    url: base + '/market-config/officialFundConfiguration/fundConfigurationSave',
    method: 'post',
    data: data
  });
}

// 资金配置列表添加
function delItem(params) {
  return (0, _request.default)({
    url: base + '/market-config/officialFundConfiguration/fundConfigurationSwitch',
    method: 'get',
    params: params
  });
}

// 资金配置列表修改
function updateItem(data) {
  return (0, _request.default)({
    url: base + '/market-config/officialFundConfiguration/fundConfigurationUpdate',
    method: 'post',
    data: data
  });
}

// 配置默认设置
function defaultItem(params) {
  return (0, _request.default)({
    url: base + '/market-config/officialFundConfiguration/fundConfigurationDefault',
    method: 'get',
    params: params
  });
}