var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("div", {
                staticClass: "search-condition",
                staticStyle: { padding: "0" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "btnArea" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _vm.hasAuth("addbtn", _vm.apis)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.addBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: { click: _vm.add },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      align: _vm.tableConfig.align,
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属系统",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(_vm._s(_vm._f("sysName")(row.systemCode))),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4238461264
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "类型",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("osType")(scope.row.osType))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3583074111
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "版本号",
                      prop: "versionNo",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新内容",
                      prop: "updateContent",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下载地址",
                      prop: "uploadPath",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否强制更新",
                      "show-overflow-tooltip": "",
                      width: "120",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.isForceUpdate == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("是"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.isForceUpdate == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("否"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3327355072
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否发布",
                      "show-overflow-tooltip": "",
                      width: "100",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-switch",
                                {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ff4949",
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    disabled: true,
                                  },
                                  model: {
                                    value: row.isPublish,
                                    callback: function ($$v) {
                                      _vm.$set(row, "isPublish", $$v)
                                    },
                                    expression: "row.isPublish",
                                  },
                                },
                                [_vm._v(">")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1491438809
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      "show-overflow-tooltip": "",
                      width: "170",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("textDate")(scope.row.createTime)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      954006631
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "150",
                      fixed: "right",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.detail(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      row.isPublish == 1 &&
                                      _vm.hasAuth("updatebtn", _vm.apis),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      row.isPublish == 1 &&
                                      _vm.hasAuth("deletebtn", _vm.apis),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoDel(row.pid)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      250261409
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasAuth("searchbtn", _vm.apis),
              expression: "!hasAuth('searchbtn',apis)",
            },
          ],
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              ref: "dataForm",
              staticStyle: { width: "90%", margin: "0 auto" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-suffix": ":",
                "label-width": "110px",
                disabled: _vm.readOnly,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属系统", prop: "systemCode" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.temp.systemCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "systemCode", $$v)
                        },
                        expression: "temp.systemCode",
                      },
                    },
                    _vm._l(_vm.sysOptions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "APP类型", prop: "osType" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.temp.osType,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "osType", $$v)
                        },
                        expression: "temp.osType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "安卓", value: 0 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "IOS", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "PC", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否强制更新", prop: "isForceUpdate" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.isForceUpdate,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "isForceUpdate", $$v)
                        },
                        expression: "temp.isForceUpdate",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "更新内容", prop: "updateContent" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      type: "textarea",
                      maxlength: "80",
                      autosize: { minRows: 2, maxRows: 4 },
                    },
                    model: {
                      value: _vm.temp.updateContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "updateContent", $$v)
                      },
                      expression: "temp.updateContent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "versionNo" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", maxlength: "10" },
                    model: {
                      value: _vm.temp.versionNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "versionNo", $$v)
                      },
                      expression: "temp.versionNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.readOnly && _vm.temp.osType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上传安装包", prop: "upload" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          attrs: {
                            action: _vm.uploadFile,
                            headers: _vm.myHeaders,
                            limit: 1,
                            "file-list": _vm.imgList,
                            "on-change": _vm.selectImg,
                            "on-remove": _vm.handleRemove,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-success": _vm.imgUploadSuccess,
                            "on-error": _vm.uploadFileFail,
                          },
                        },
                        [_c("el-button", [_vm._v("上传")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下载地址", prop: "uploadPath" } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.temp.osType != 1 },
                    model: {
                      value: _vm.temp.uploadPath,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "uploadPath", $$v)
                      },
                      expression: "temp.uploadPath",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否发布", prop: "isPublish" } },
                [
                  _c(
                    "el-switch",
                    {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      model: {
                        value: _vm.temp.isPublish,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "isPublish", $$v)
                        },
                        expression: "temp.isPublish",
                      },
                    },
                    [_vm._v(">")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              !_vm.readOnly
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }