"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
var _default = {
  components: {
    UploadImg: _UploadImg.default
  },
  data: function data() {
    return {
      visible: false,
      dataForm: {
        categoryId: 0,
        currentId: 0,
        grade: 0,
        categoryName: '',
        seq: 1,
        status: 1,
        parentId: 0,
        pic: ''
      },
      dataRule: {
        categoryName: [{
          required: true,
          message: '分类名称不能为空',
          trigger: 'blur'
        }],
        pic: [{
          required: true,
          message: '分类图片不能为空',
          trigger: 'blur'
        }]
      },
      categoryList: [],
      selectedCategory: [],
      categoryTreeProps: {
        value: 'categoryId',
        label: 'categoryName'
      }
    };
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    init: function init(id) {
      var _this = this;
      this.dataForm.currentId = id || 0;
      this.dataForm.categoryId = id || 0;
      (0, _wXintegralMall.listCategory)().then(function (data) {
        var data = data.data;
        if (data && data.length) {
          var arr = [];
          data.forEach(function (item) {
            if (item.parentId == 0) {
              arr.push(item);
            }
          });
          _this.categoryList = arr;
        }
      }).then(function () {
        _this.visible = true;
        _this.$nextTick(function () {
          _this.$refs['dataForm'].resetFields();
          _this.selectedCategory = [];
        });
      }).then(function () {
        if (_this.dataForm.categoryId) {
          // 修改
          (0, _wXintegralMall.categoryInfo)(_this.dataForm.categoryId).then(function (data) {
            var data = data.data;
            _this.dataForm.categoryId = data.categoryId;
            _this.dataForm.categoryName = data.categoryName;
            _this.dataForm.seq = data.seq;
            _this.dataForm.pic = data.pic;
            _this.$refs.dataFormUpload.fileList.push({
              url: data.pic
            });
            _this.$refs.dataFormUpload.hideUpload = true;
            _this.dataForm.parentId = data.parentId;
            _this.dataForm.status = data.status;
            _this.selectedCategory = (0, _utils.idList)(_this.categoryList, data.parentId, 'categoryId', 'children').reverse();
          });
        }
      });
    },
    handleChange: function handleChange(val) {
      this.dataForm.parentId = val[val.length - 1];
    },
    cancel: function cancel() {
      this.$refs.dataFormUpload.fileList = [];
      this.$refs.dataFormUpload.hideUpload = false;
      this.restForm('dataForm');
      this.visible = false;
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      if (this.selectedCategory.length === 1) {
        this.dataForm.grade = 0;
      }
      if (this.selectedCategory.length === 2) {
        this.dataForm.grade = 1;
      }
      if (this.selectedCategory.length === 3) {
        this.dataForm.grade = 2;
      }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _wXintegralMall.categoryAdd)({
            categoryId: _this2.dataForm.categoryId || undefined,
            categoryName: _this2.dataForm.categoryName,
            status: _this2.dataForm.status,
            seq: _this2.dataForm.seq,
            grade: _this2.dataForm.grade,
            parentId: _this2.dataForm.parentId,
            pic: _this2.dataForm.pic
          }).then(function (data) {
            _this2.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this2.cancel();
                _this2.$emit('refreshDataList');
              }
            });
          });
        }
      });
    }
  }
};
exports.default = _default;