var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "groupBuyName",
                                    label: "团购类型",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.listQuery.groupBuyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "groupBuyName",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.groupBuyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "groupStatus", label: "状态" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.groupStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "groupStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.groupStatus",
                                      },
                                    },
                                    _vm._l(_vm.states, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "20px" },
                    },
                    [
                      _c(
                        "el-button-group",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-right": "20px",
                          },
                        },
                        [
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.DoSearch },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("addbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    plain: "",
                                    type: _vm.btnConfig.addBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.addBtn.icon,
                                  },
                                  on: { click: _vm.DoAdd },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "团购类型编码",
                      prop: "groupBuyCode",
                      align: _vm.tableConfig.align,
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "团购类型",
                      prop: "groupBuyName",
                      align: _vm.tableConfig.align,
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "groupStatus",
                      align: _vm.tableConfig.align,
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.groupStatus == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("禁用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.groupStatus == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("启用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.groupStatus == 2
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("删除"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4208333144
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "creator",
                      align: _vm.tableConfig.align,
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      width: "200",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("formatTime")(row.createTime))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1925049025
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新人",
                      prop: "updator",
                      align: _vm.tableConfig.align,
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新时间",
                      prop: "updateTime",
                      width: "200",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("formatTime")(row.updateTime))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2148074868
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.apis) ||
                  _vm.hasAuth("updatebtn", _vm.apis) ||
                  _vm.hasAuth("startbtn", _vm.apis) ||
                  _vm.hasAuth("stopbtn", _vm.apis) ||
                  _vm.hasAuth("deletebtn", _vm.apis)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "200",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth("updatebtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth("startbtn", _vm.apis) ||
                                  _vm.hasAuth("stopbtn", _vm.apis) ||
                                  _vm.hasAuth("deletebtn", _vm.apis)
                                    ? _c(
                                        "el-dropdown",
                                        { staticClass: "table-dropdown" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n                更多\n                "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  row.groupStatus == 0 &&
                                                  _vm.hasAuth(
                                                    "startbtn",
                                                    _vm.apis
                                                  )
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoStart(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("启用")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  row.groupStatus == 1 &&
                                                  _vm.hasAuth(
                                                    "stopbtn",
                                                    _vm.apis
                                                  )
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoStop(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("禁用")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dropdown-item",
                                                [
                                                  _vm.hasAuth(
                                                    "deletebtn",
                                                    _vm.apis
                                                  )
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DoDelete(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2771046838
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "团购类型-查看",
            visible: _vm.scanVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("团购类型编码:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.groupBuyCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("团购类型:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.groupBuyName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("状态:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.groupStatus == 0
                      ? _c("span", [_vm._v("禁用")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.groupStatus == 1
                      ? _c("span", [_vm._v("启用")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.groupStatus == 2
                      ? _c("span", [_vm._v("删除")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("备注:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.remarks)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "align-right",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.scanVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "addForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.addData,
                "label-width": "120px",
              },
            },
            [
              _vm.subType == "edit"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "团购类型编码", prop: "groupBuyCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          maxlength: "40",
                          readonly: "",
                        },
                        model: {
                          value: _vm.addData.groupBuyCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.addData, "groupBuyCode", $$v)
                          },
                          expression: "addData.groupBuyCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "团购类型", prop: "groupBuyName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", maxlength: "40" },
                    model: {
                      value: _vm.addData.groupBuyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "groupBuyName", $$v)
                      },
                      expression: "addData.groupBuyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "状态", prop: "groupStatus" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.addData.groupStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "groupStatus", $$v)
                      },
                      expression: "addData.groupStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: "200" },
                    model: {
                      value: _vm.addData.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remarks", $$v)
                      },
                      expression: "addData.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "align-right",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.dstate },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("addForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("addForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }