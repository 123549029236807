var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "space-between" } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 3 },
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticStyle: {
                                  "font-weight": "400",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                产品分析\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "hyyy-content" },
                      [
                        _c("el-main", [
                          _vm.showSearch
                            ? _c(
                                "div",
                                { staticClass: "search-content" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      type: "daterange",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    on: { change: _vm.getDate },
                                    model: {
                                      value: _vm.value1,
                                      callback: function ($$v) {
                                        _vm.value1 = $$v
                                      },
                                      expression: "value1",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "font-size": "14px" },
                                      attrs: { type: "text" },
                                      on: { click: _vm.getCurMonth },
                                    },
                                    [_vm._v("最近30天")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { width: "100%", height: "300px" } },
                            [_c("BarChartLeft", { ref: "barchart" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "menu-details" },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("兑换详情"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "search-content" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      type: "daterange",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    on: { change: _vm.getChangeMenu },
                                    model: {
                                      value: _vm.menuValue,
                                      callback: function ($$v) {
                                        _vm.menuValue = $$v
                                      },
                                      expression: "menuValue",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.downLoading,
                                          expression: "downLoading",
                                        },
                                      ],
                                      staticStyle: { "font-size": "14px" },
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.downLoad },
                                    },
                                    [_vm._v(_vm._s(_vm.downLoadTxt))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.menuData,
                                    border: "",
                                    height: "400",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "version",
                                      label: "产品名称",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "oneMenu",
                                      label: "已兑",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "twoMenu",
                                      label: "可兑",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "allMenuClickCount",
                                      label: "积分消耗",
                                      align: "center",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "allMenuClickUser",
                                      label: "支付金额",
                                      align: "center",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }