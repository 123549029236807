"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stockscan = require("@/api/chanelmange/stockscan");
var _rebate = require("@/api/chanelmange/rebate");
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangerebatereportStaticcashRecord';
var _default = {
  name: 'chanelmangerebatereportStaticcashRecord',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    ChannelSelectDialog: _channelSelectDialog.default
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        orgId: null,
        size: 20,
        storeId: null
      },
      orgOptions: [],
      channelName: '',
      tableKey: 'cashRecord',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      showCol: false,
      expandTxt: '展开',
      apis: []
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _rebate.getCashHx)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearZd: function clearZd() {
      var that = this;
      that.listQuery.storeId = '';
      that.channelName = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        current: 1,
        orgId: null,
        size: 20,
        storeId: null
      };
      that.channelName = '';
      that.getList();
    },
    //获取单位
    getUnit: function getUnit(val) {
      var that = this;
      this.$refs.channelSelect.proSelVisible = true;
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.listQuery.storeId = val.pId;
      that.channelName = val.channelName;
      that.unitShow = false;
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;