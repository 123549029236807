"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _work = require("@/api/systems/work");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  props: {
    pId: String,
    dialogVisible: Boolean
  },
  data: function data() {
    return {
      loading: false,
      rules: {
        modelKey: [{
          required: true,
          message: '请输入审批流编码',
          trigger: 'blur'
        }],
        modelName: [{
          required: true,
          message: '请输入审批流名称',
          trigger: 'blur'
        }],
        detailUrl: [{
          required: true,
          message: '请输入详情页地址',
          trigger: 'blur'
        }],
        callbackUrl: [{
          required: true,
          message: '请输入回调地址',
          trigger: 'blur'
        }],
        billCode: [{
          required: true,
          message: '请选择单据类型',
          trigger: 'change'
        }],
        templateId: [{
          required: true,
          message: '请选择审批流模板',
          trigger: 'change'
        }]
      },
      moduleData: [],
      templates: [],
      editData: {
        billCode: '',
        callbackUrl: '',
        detailUrl: '',
        modelKey: '',
        modelName: '',
        moduleCode: '',
        pid: '',
        remark: '',
        templateId: null
      },
      pageData: [],
      dstate: false,
      imgSrc: '',
      srcList: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var that = this;
      if (val == true) {
        that.pageData = that.$parent.pageData;
        that.moduleData = that.$parent.moduleData;
        that.getDetailData();
        that.getTemplates();
      }
    }
  },
  methods: {
    getDetailData: function getDetailData() {
      var that = this;
      that.loading = true;
      var param = {};
      param.pId = that.pId;
      (0, _work.getDetails)(param).then(function (res) {
        that.loading = false;
        if (res.data.code == 200) {
          var data = res.data.data;
          that.editData.billCode = Number(data.billCode);
          that.editData.callbackUrl = data.callbackUrl;
          that.editData.detailUrl = data.detailUrl;
          that.editData.modelKey = data.modelKey;
          that.editData.modelName = data.modelName;
          that.editData.moduleCode = data.moduleCode;
          that.editData.pid = data.pidString;
          that.editData.remark = data.remark;
          that.editData.templateId = data.templateId;
        } else {
          that.$message.error(res.data.msg + '获取详情信息失败');
        }
        return true;
      }).then(function (res) {
        that.getTemplates();
      }).catch(function () {
        that.loading = false;
      });
    },
    getTemplates: function getTemplates() {
      var that = this;
      (0, _work.getAllMoudles)().then(function (res) {
        if (res.data.code == 200) {
          that.templates = res.data.data;
        } else {
          that.templates = [];
          that.$message.error(res.data.msg + '获取审批流模板信息失败');
        }
        return true;
      }).then(function (res) {
        if (that.editData.templateId) {
          that.chnageSrc(that.editData.templateId);
        }
      });
    },
    chnageSrc: function chnageSrc(val) {
      var that = this;
      that.filterSrc(val);
    },
    filterSrc: function filterSrc(val) {
      var that = this;
      var arr = that.templates;
      var src = '';
      if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].pid == val) {
            src = arr[i].templateImg;
            break;
          }
        }
      }
      if (src) {
        that.imgSrc = 'data:image/png;base64,' + src;
        that.srcList[0] = 'data:image/png;base64,' + src;
      }
    },
    submit: function submit(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var subData = (0, _utils.deepClone)(that.editData);
          subData.billCode = subData.billCode.toString();
          (0, _work.update)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功'
              });
              that.loading = false;
              that.dsate = false;
              that.handleClose();
              that.$parent.getList();
            } else {
              that.$message.error(res.data.msg);
              that.loading = false;
              that.dsate = false;
              return;
            }
          }).catch(function () {
            that.loading = false;
            that.dsate = false;
          });
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('editForm');
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$emit('close');
    }
  }
};
exports.default = _default;