"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _prizeCheck = require("@/api/operationalTools/prizeCheck");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsprizeCheckindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsprizeCheckindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    // 奖品形式
    prizeType: function prizeType(msg) {
      if (msg == 100) {
        return '红包';
      } else if (msg == 200) {
        return '实物';
      } else if (msg == 300) {
        return '优惠券';
      } else if (msg == 400) {
        return '积分';
      } else if (msg == 500) {
        return '字卡';
      } else if (msg == 900) {
        return '礼包';
      } else if (msg == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    priority: function priority(msg) {
      if (msg == 1) {
        return '一等奖';
      } else if (msg == 2) {
        return '二等奖';
      } else if (msg == 3) {
        return '三等奖';
      } else if (msg == 4) {
        return '四等奖';
      } else if (msg == 5) {
        return '五等奖';
      } else if (msg == 6) {
        return '六等奖';
      } else if (msg == 7) {
        return '七等奖';
      } else if (msg == 8) {
        return '八等奖';
      }
    }
  },
  data: function data() {
    return {
      params: {
        current: 1,
        size: 10
      },
      total: 0,
      formInline: {
        activityName: '',
        startTime: '',
        endTime: '',
        groupType: ''
      },
      rangeTime: '',
      tableData: [],
      listLoading: false,
      tabHeight: '100%',
      dialogUpdate: false,
      prioritys: [{
        name: '一等奖',
        value: '1'
      }, {
        name: '二等奖',
        value: '2'
      }, {
        name: '三等奖',
        value: '3'
      }, {
        name: '四等奖',
        value: '4'
      }, {
        name: '五等奖',
        value: '5'
      }, {
        name: '六等奖',
        value: '6'
      }, {
        name: '七等奖',
        value: '7'
      }, {
        name: '八等奖',
        value: '8'
      }],
      prizeTypes: [{
        name: '红包',
        value: '100'
      }, {
        name: '实物奖品',
        value: '200'
      }, {
        name: '优惠券',
        value: '300'
      }, {
        name: '积分',
        value: '400'
      }, {
        name: '字卡',
        value: '500'
      }, {
        name: '礼包',
        value: '900'
      }, {
        name: '谢谢惠顾',
        value: '0'
      }],
      prizeListByType: {
        '100': [],
        '200': [],
        '300': [],
        '400': [],
        '500': [],
        '900': []
      },
      codeForm: {
        prizeItemsList: [{
          priority: null,
          prizeBudgetTotalAmount: '',
          prizeId: '',
          prizeNum: '',
          prizePr: '',
          prizeType: '',
          disable: false,
          plist: []
        }]
      },
      disabled: false,
      btnLoading: false
    };
  },
  computed: {
    // 活动预算
    codeTotal: function codeTotal() {
      var total = 0;
      if (this.codeForm.prizeItemsList.length > 0) {
        this.codeForm.prizeItemsList.forEach(function (item) {
          if (item.prizeBudgetTotalAmount) {
            total += Number(item.prizeBudgetTotalAmount);
          }
        });
      }
      return total.toFixed(2) + '元';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getInitPrizeList('100');
    this.getInitPrizeList('200');
    this.getInitPrizeList('300');
    this.getInitPrizeList('400');
    this.getInitPrizeList('500');
    this.getInitPrizeList('900');
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getPage: function getPage() {
      var that = this;
      that.listLoading = true;
      (0, _prizeCheck.getPage)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    sj: function sj(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    // 搜索
    search: function search() {
      this.params.current = 1;
      this.getPage();
    },
    // 重置搜索框
    reset: function reset() {
      this.params.current = 1;
      this.$refs['formInline'].resetFields();
      this.rangeTime = '';
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.getPage();
    },
    handleEdit: function handleEdit(res) {
      var _this = this;
      console.log(res);
      var queryData = {
        groupType: res.groupType,
        priority: res.priority,
        prizeId: res.prizeId,
        prizePr: res.prizePr
      };
      this.formInline.startTime && (queryData.startTime = this.formInline.startTime);
      this.formInline.endTime && (queryData.endTime = this.formInline.endTime);
      this.formInline.activityName && (queryData.activityName = this.formInline.activityName);
      (0, _prizeCheck.getExportExcel)(queryData).then(function (res) {
        setTimeout(function () {
          _this.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '奖品核对.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 切换选择奖品根据奖品类型
    getCurPrizeType: function getCurPrizeType($event, index) {
      var _this2 = this;
      this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = '';
      this.codeForm.prizeItemsList[index].prizeId = '';
      this.codeForm.prizeItemsList[index].prizePr = '';
      this.codeForm.prizeItemsList[index].prizeNum = '';
      this.$refs.codeForm.clearValidate();
      if ($event == '0') {
        this.codeForm.prizeItemsList[index].priority = 99999;
        this.codeForm.prizeItemsList[index].disable = false;
        return;
      }
      if ($event != '0') {
        if (this.codeForm.prizeItemsList[index].priority == 99999) {
          this.codeForm.prizeItemsList[index].priority = null;
        }
      }
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: $event
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this2.codeForm.prizeItemsList[index].disable = true;
          _this2.codeForm.prizeItemsList[index].plist = res.data.data;
        }
      });
    },
    // 根据奖品类型初始化奖品列表
    getInitPrizeList: function getInitPrizeList(type) {
      var _this3 = this;
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: type
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this3.prizeListByType[type] = res.data.data;
        }
      });
    },
    // 预算计算
    doSum: function doSum(index) {
      var data = this.codeForm.prizeItemsList[index];
      var prizePr = data.prizePr;
      var allPr = 0;
      this.codeForm.prizeItemsList.forEach(function (item) {
        allPr += Number(item.prizePr);
      });
      if (Number(allPr) > 100) {
        this.codeForm.prizeItemsList[index].prizePr = '';
        this.$message.error('总概率和应不大于100%，请调整概率！');
      }
      if (data.prizeType == '0') {
        // 谢谢惠顾
        return;
      }
      var prizeNum = data.prizeNum ? data.prizeNum : 0;
      var prizeId = data.prizeId;
      if (data.plist.length == 0) {
        return;
      }
      var baseValue = data.plist.filter(function (item) {
        return item.prizeId == prizeId;
      });
      if (baseValue.length == 0) {
        return;
      }
      var price = 0;
      if (data.prizeType == '100') {
        price = baseValue[0].redMaxValue ? baseValue[0].redMaxValue : baseValue[0].baseValue;
      } else {
        price = baseValue[0].baseValue;
      }
      if (!prizeNum) {
        this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = '';
        return;
      }
      if (Number(allPr) > 100) {
        this.codeForm.prizeItemsList[index].prizePr = '';
        this.$message.error('总概率和应不大于100%，请调整概率！');
        return;
      }
      this.codeForm.prizeItemsList[index].prizeBudgetTotalAmount = Number(price) * Number(prizeNum);
    },
    restPrize: function restPrize() {
      this.codeForm = {
        prizeItemsList: [{
          priority: null,
          prizeBudgetTotalAmount: '',
          prizeId: '',
          prizeNum: '',
          prizePr: '',
          prizeType: '',
          disable: false,
          plist: []
        }]
      };
      this.dialogUpdate = false;
    },
    // 奖品修改
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dialogUpdate = true;
      console.log(row, 'row');
      this.codeForm = {
        prizeItemsList: [{
          priority: row.priority,
          prizeBudgetTotalAmount: '',
          prizeId: row.prizeId,
          prizeNum: '',
          prizePr: row.prizePr,
          prizeType: row.prizeType,
          disable: false,
          plist: []
        }]
      };
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: row.prizeType
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          _this4.codeForm.prizeItemsList[0].plist = res.data.data;
        }
      });
    }
  }
};
exports.default = _default;