var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "500px", border: "1px solid #eee" } },
    [
      _c(
        "el-aside",
        { staticClass: "comm_aside", attrs: { width: "250px" } },
        [
          _c("el-tree", {
            attrs: {
              data: _vm.LabelList,
              props: _vm.defaultProps,
              "highlight-current": true,
              "node-key": "pid",
              accordion: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c(
                    "span",
                    {
                      staticClass: "custom-tree-node",
                      staticStyle: { width: "calc(100% - 40px)" },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            width: "100%",
                            overflow: "hidden !important",
                            "text-overflow": "ellipsis !important",
                            "white-space": "nowrap !important",
                          },
                          attrs: { label: node.data.pid },
                          on: {
                            change: function ($event) {
                              return _vm.handleNodeClick(data, node)
                            },
                          },
                          model: {
                            value: _vm.flagGroupId,
                            callback: function ($$v) {
                              _vm.flagGroupId = $$v
                            },
                            expression: "flagGroupId",
                          },
                        },
                        [_vm._v(_vm._s(node.data.flagName))]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        [_c("el-main", [_c("labelcon", { ref: "list_con" })], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }