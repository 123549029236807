"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Tips',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    aId: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    oldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      listLoading: true,
      visible: false,
      selLoading: false,
      total: 2,
      listQuery: {
        current: 1,
        size: 10,
        productIdList: this.aId,
        batchNo: null
      },
      list: [],
      listCopy: [],
      list1: [],
      list1Copy: this.oldList,
      upList: [],
      downList: [],
      multipleSelection: []
    };
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.oldList.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.fetchData();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.selLoading = false;
      }, 500);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      (0, _activityManage.getProductBatch)(this.listQuery).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this2.total = Number(res.data.total);
          _this2.list = res.data.records;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.list = [];
      this.visible = false;
      this.$emit('close');
    },
    update: function update() {
      this.$emit('fn', this.multipleSelection);
      this.visible = false;
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;