var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "padding-top": "5px" } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleabolishClick },
          model: {
            value: _vm.activeabolishName,
            callback: function ($$v) {
              _vm.activeabolishName = $$v
            },
            expression: "activeabolishName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              staticStyle: { "padding-left": "20px !important" },
              attrs: { label: "单数码废除", name: "abolish_on" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "mark_form",
                  staticStyle: { width: "90%", margin: "0 auto" },
                  attrs: {
                    model: _vm.form_on,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "135px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请输入数码:", prop: "target" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", type: "text", maxlength: "30" },
                        model: {
                          value: _vm.form_on.target,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_on, "target", $$v)
                          },
                          expression: "form_on.target",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "废除原因:", prop: "descr" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", type: "textarea" },
                        model: {
                          value: _vm.form_on.descr,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_on, "descr", $$v)
                          },
                          expression: "form_on.descr",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.onSubmitabolish(
                                _vm.form_on,
                                "codeForm"
                              )
                            },
                          },
                        },
                        [_vm._v("废 除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "制码订单号废除", name: "warehouse_receipt" } },
            [
              _c(
                "el-form",
                {
                  ref: "ListForm",
                  staticClass: "mark_form",
                  staticStyle: { width: "90%", margin: "0 auto" },
                  attrs: {
                    model: _vm.form_warehouse_receipt,
                    rules: _vm.abolishruless,
                    "label-position": "right",
                    "label-width": "135px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请输入单号:", prop: "target" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", type: "text", maxlength: "30" },
                        model: {
                          value: _vm.form_warehouse_receipt.target,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_warehouse_receipt, "target", $$v)
                          },
                          expression: "form_warehouse_receipt.target",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "废除原因:", prop: "descr" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", type: "textarea" },
                        model: {
                          value: _vm.form_warehouse_receipt.descr,
                          callback: function ($$v) {
                            _vm.$set(_vm.form_warehouse_receipt, "descr", $$v)
                          },
                          expression: "form_warehouse_receipt.descr",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.onSubmitabolish(
                                _vm.form_warehouse_receipt,
                                "ListForm"
                              )
                            },
                          },
                        },
                        [_vm._v("废 除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }