"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _pack = require("@/api/basic/pack");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _basic = require("@/api/mark/basic");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _search = _interopRequireDefault(require("./components/search"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = "",
  qrcode = "";
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: "table-suply",
  components: {
    Pagination: _Pagination.default,
    QRCode: _qrcodejs.default,
    productDialog: _productSelectDialog.default,
    search: _search.default,
    detailsflow: _details_flow.default,
    insert: _insert.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, "yyyy-MM-dd hh:mm:ss");
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = "";
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      billType: 103,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      params: {
        billReturnQuery: {
          inOrgId: "",
          inStoreId: "",
          outOrgId: ""
        },
        billNo: "",
        billSource: "",
        billStatus: "",
        billType: 103,
        endTime: "",
        productId: "",
        startTime: ""
      },
      tableKey: "rn",
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: "100%",
      submitType: "",
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: "二维码查看",
      codeVisible: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.getstoreTypeInfoList(1, "2222222");
    this.getstoreTypeInfoList(4, "source");
    this.getstoreTypeInfoList(7, "status");
    this.getstoreTypeInfoList(103, "factory");
    TypeDataComs = this.TypeDataComs;
    this.getList();
  },
  methods: {
    getstoreTypeInfoList: function getstoreTypeInfoList(type, keyName) {
      // 4:单据来源，7：单据状态，103：工厂代码
      var that = this;
      (0, _select.storeTypeInfoList)(type).then(function (res) {
        if (res.data.code != 200) {
          that.TypeDataComs[keyName] = [];
          return;
        } else {
          that.TypeDataComs[keyName] = res.data.data;
        }
      }).catch(function () {
        that.TypeDataComs[keyName] = [];
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs["searchForm"].resetFields();
      this.getList();
    },
    searchList: function searchList(data, data2) {
      this.searchForm = data;
      this.params = data2;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      (0, _index.queryForPage)(this.searchForm.current, this.searchForm.size, this.params).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    add: function add() {
      this.$refs.insertMain.show(1, this.billType);
    },
    scanDetail: function scanDetail(row) {
      this.scanData = JSON.parse(JSON.stringify(row));
      this.detailVisible = true;
    },
    doUpdate: function doUpdate(row) {
      this.$refs.insertMain.upDateShow(row);
    },
    codeDetail: function codeDetail() {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.getCodeList();
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _index.InfoDel)(row.num).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this2.$message.error(data.msg);
            return false;
          }
          _this2.$message({
            message: res.data.msg,
            type: "success"
          });
          _this2.getList();
        });
      }).catch(function () {});
    },
    //获取审核详情
    getflow: function getflow(row, qx) {
      this.$refs.flowMain.show(row, qx);
    },
    //导出功能
    download: function download() {
      var _this3 = this;
      this.downLoading = true;
      (0, _index.excelOut)(this.billType, this.searchForm.pageNo, this.searchForm.pageSize, this.searchForm).then(function (res) {
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "退货入库.xls";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.downLoading = false;
      });
    },
    //二维码功能
    getQrCode: function getQrCode(row) {
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById("qrcode").innerHTML = "";
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
    }
  }
};
exports.default = _default;