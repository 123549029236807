"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Breadcrumb = _interopRequireDefault(require("./components/Breadcrumb"));
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarkMain',
  components: {
    breadcrumb: _Breadcrumb.default
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  }
};
exports.default = _default;