var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: _vm.DoEnable ? "600px !important" : "1100px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.DoEnable
        ? _c("h4", [_vm._v("当前单据禁用后不可下载，是否确认？")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            disabled: _vm.DoEnable,
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据类型", prop: "billSource" } },
                    [
                      _vm.title == "添加销售出库"
                        ? _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.handleReset },
                              model: {
                                value: _vm.scanData.billSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "billSource", $$v)
                                },
                                expression: "scanData.billSource",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 6 } }, [
                                _vm._v("NC出库单据"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("自建单据"),
                              ]),
                            ],
                            1
                          )
                        : _vm.title == "修改销售出库"
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.scanData.billSource == 2
                                    ? "自建单据"
                                    : "NC出库单据"
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.scanData.billSource == 6
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncno",
                              attrs: { label: "NC物流单号:", prop: "NCNO" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  disabled:
                                    _vm.actionType == "update" ||
                                    _vm.ncDisabled ||
                                    _vm.onlyRemarkEditable,
                                  clearable: "",
                                  maxlength: "20",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.scanData.NCNO =
                                      _vm.validCode(e))
                                  },
                                  clear: _vm.reset,
                                },
                                model: {
                                  value: _vm.scanData.NCNO,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "NCNO", $$v)
                                  },
                                  expression: "scanData.NCNO",
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasAuth("ncSyncbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.scanData.NCNO ||
                                          _vm.actionType == "update" ||
                                          _vm.countdown > 0 ||
                                          _vm.onlyRemarkEditable,
                                        type: "primary",
                                        icon: "el-icon-refresh",
                                        loading: _vm.syncNcBtnLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.syncNcData(
                                            _vm.scanData.NCNO
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.countdown > 0
                                            ? _vm.countdown + "s后再试"
                                            : "同步单据"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.title == "添加销售出库"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#F56C6C" } },
                                    [_vm._v("备注：单据2天内提货有效")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.ncDetailLoading,
                          expression: "ncDetailLoading",
                        },
                      ],
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(0, 0, 0, 0)",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncoutStoreName",
                              attrs: {
                                label: "发货仓库:",
                                prop: "outStoreName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.scanData.outStoreName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "outStoreName", $$v)
                                  },
                                  expression: "scanData.outStoreName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncbilldate",
                              attrs: { label: "发货日期:", prop: "billDate" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.scanData.billDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "billDate", $$v)
                                  },
                                  expression: "scanData.billDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncdealerCode",
                              attrs: {
                                label: "经销商编码:",
                                prop: "dealerCode",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.scanData.dealerCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "dealerCode", $$v)
                                  },
                                  expression: "scanData.dealerCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncdealerName",
                              attrs: {
                                label: "经销商名称:",
                                prop: "dealerName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.scanData.dealerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "dealerName", $$v)
                                  },
                                  expression: "scanData.dealerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncaccountName",
                              attrs: { label: "主户头:", prop: "accountName" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.scanData.accountName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "accountName", $$v)
                                  },
                                  expression: "scanData.accountName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncregionCode",
                              attrs: { label: "大区编码:", prop: "regionCode" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.scanData.regionCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "regionCode", $$v)
                                  },
                                  expression: "scanData.regionCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncregionName",
                              attrs: { label: "大区名称:", prop: "regionName" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.scanData.regionName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "regionName", $$v)
                                  },
                                  expression: "scanData.regionName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncreceiptUser",
                              attrs: { label: "收货人:" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "35",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.scanData.receiptUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "receiptUser", $$v)
                                  },
                                  expression: "scanData.receiptUser",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncreceiptAddress",
                              attrs: {
                                label: "收货地址:",
                                prop: "receiptAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  disabled: _vm.actionType == "update",
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.scanData.receiptAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.scanData,
                                      "receiptAddress",
                                      $$v
                                    )
                                  },
                                  expression: "scanData.receiptAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "nctelePhone",
                              attrs: { label: "联系电话:", prop: "telePhone" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  disabled: _vm.actionType == "update",
                                  clearable: "",
                                  maxlength: "11",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.scanData.telePhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "telePhone", $$v)
                                  },
                                  expression: "scanData.telePhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncremark",
                              attrs: { label: "单据备注:", prop: "remark" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.scanData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "remark", $$v)
                                  },
                                  expression: "scanData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.ncDetailLoading,
                              expression: "ncDetailLoading",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "is-required",
                              attrs: { label: "产品明细:" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.listLoading,
                                      expression: "listLoading",
                                    },
                                  ],
                                  key: _vm.tableKey,
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "1px",
                                  },
                                  attrs: {
                                    data: _vm.plist,
                                    border: "",
                                    stripe: _vm.tableConfig.stripe,
                                    fit: "",
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      type: "index",
                                      fixed: "left",
                                      width: "60",
                                      align: _vm.tableConfig.align,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "NC产品编码",
                                      prop: "ncproductCode",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "NC产品名称",
                                      prop: "ncproductName",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "NC包装比例",
                                      prop: "packRadioDesc",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(row.packRadioDesc)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3299765757
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "计划总数量（瓶）",
                                      prop: "planScanAllNum",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "箱数",
                                      prop: "planScanNum",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "瓶数",
                                      prop: "planScanAtoNum",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品名称",
                                      prop: "productName",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "200px",
                                                        overflow: "hidden",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.productName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2159405690
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: _vm.tableConfig.align,
                                      width: "160",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      row.hasProduct ||
                                                      _vm.title ==
                                                        "修改销售出库",
                                                    type: "text",
                                                    icon: "el-icon-edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.ncAssociate(
                                                        row,
                                                        _vm.getIndex(row)
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑产品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.title ==
                                                      "修改销售出库",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.removeNcAssociate(
                                                        row,
                                                        _vm.getIndex(row)
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1522834467
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "ncpdaMoreDown",
                              attrs: {
                                label: "允许多次下载",
                                prop: "pdaMoreDown",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "是",
                                  "inactive-text": "否",
                                },
                                model: {
                                  value: _vm.scanData.pdaMoreDown,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "pdaMoreDown", $$v)
                                  },
                                  expression: "scanData.pdaMoreDown",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.scanData.billSource == 2
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "销售出库单号:", prop: "billNo" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: _vm.disabled.num,
                                  readonly: "",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.scanData.billNo =
                                      _vm.validSe(e))
                                  },
                                },
                                model: {
                                  value: _vm.scanData.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "billNo", $$v)
                                  },
                                  expression: "scanData.billNo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "NC单号:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled: _vm.onlyRemarkEditable,
                              clearable: "",
                              maxlength: "20",
                              "show-word-limit": "",
                            },
                            on: {
                              input: function (e) {
                                return (_vm.scanData.NCNO = _vm.validSe(e))
                              },
                            },
                            model: {
                              value: _vm.scanData.NCNO,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "NCNO", $$v)
                              },
                              expression: "scanData.NCNO",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "outStoreId",
                          attrs: { label: "发货仓库:", prop: "outStoreId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                disabled:
                                  (_vm.actionType == "update" &&
                                    _vm.modalType == "single") ||
                                  (_vm.actionType == "update" &&
                                    _vm.modalType == "whole" &&
                                    !_vm.billStatus_101) ||
                                  _vm.onlyRemarkEditable,
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.outStoreChange },
                              model: {
                                value: _vm.scanData.outStoreId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "outStoreId", $$v)
                                },
                                expression: "scanData.outStoreId",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item, index) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.storeHouseName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "accountName",
                          attrs: { label: "收货经销商:", prop: "accountName" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              ref: "fhdw",
                              staticStyle: { width: "350px" },
                              attrs: {
                                disabled:
                                  (_vm.actionType == "update" &&
                                    _vm.modalType == "single") ||
                                  (_vm.actionType == "update" &&
                                    _vm.modalType == "whole" &&
                                    !_vm.billStatus_101) ||
                                  _vm.onlyRemarkEditable,
                                readonly: "",
                              },
                              model: {
                                value: _vm.scanData.dealerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "dealerName", $$v)
                                },
                                expression: "scanData.dealerName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.channelChoose },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "dealerName",
                          attrs: { label: "主户头:", prop: "dealerName" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.scanData.accountName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "accountName", $$v)
                              },
                              expression: "scanData.accountName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "regionCode",
                          attrs: { label: "大区编码:", prop: "regionCode" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.scanData.regionCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "regionCode", $$v)
                              },
                              expression: "scanData.regionCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "regionName",
                          attrs: { label: "大区名称:", prop: "regionName" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.scanData.regionName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "regionName", $$v)
                              },
                              expression: "scanData.regionName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "telePhone",
                          attrs: { label: "联系电话:", prop: "telePhone" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled:
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "single") ||
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "whole" &&
                                  !_vm.billStatus_101) ||
                                _vm.onlyRemarkEditable,
                              clearable: "",
                              maxlength: "11",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.scanData.telePhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "telePhone", $$v)
                              },
                              expression: "scanData.telePhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { key: "receiptUser", attrs: { label: "收货人:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled:
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "single") ||
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "whole" &&
                                  !_vm.billStatus_101) ||
                                _vm.onlyRemarkEditable,
                              clearable: "",
                              maxlength: "35",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.scanData.receiptUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "receiptUser", $$v)
                              },
                              expression: "scanData.receiptUser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "receiptAddress",
                          attrs: { label: "收货地址:", prop: "receiptAddress" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled:
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "single") ||
                                (_vm.actionType == "update" &&
                                  _vm.modalType == "whole" &&
                                  !_vm.billStatus_101) ||
                                _vm.onlyRemarkEditable,
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.scanData.receiptAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "receiptAddress", $$v)
                              },
                              expression: "scanData.receiptAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "remark",
                          attrs: { label: "单据备注:", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.scanData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "remark", $$v)
                              },
                              expression: "scanData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业务类型:",
                                prop: "billOutType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "350px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.scanData.billOutType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData, "billOutType", $$v)
                                    },
                                    expression: "scanData.billOutType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "扫码出库", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: {
                                      label: "生产任务单出库",
                                      value: "2",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "批次号出库", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订单用途:",
                                prop: "billApplication",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "350px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.scanData.billApplication,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scanData,
                                        "billApplication",
                                        $$v
                                      )
                                    },
                                    expression: "scanData.billApplication",
                                  },
                                },
                                _vm._l(_vm.ddyt, function (item) {
                                  return _c("el-option", {
                                    key: item.pid,
                                    attrs: {
                                      label: item.name,
                                      value: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "发货单位:", prop: "outOrgName" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  ref: "fhdw",
                                  staticStyle: { width: "350px" },
                                  attrs: { readonly: "", clearable: "" },
                                  model: {
                                    value: _vm.scanData.outOrgName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData, "outOrgName", $$v)
                                    },
                                    expression: "scanData.outOrgName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      disabled: _vm.disType,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getInUnit("out")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "收货单位:", prop: "inOrgName" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  ref: "shdw",
                                  staticStyle: { width: "350px" },
                                  attrs: { readonly: "", clearable: "" },
                                  model: {
                                    value: _vm.scanData.inOrgName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData, "inOrgName", $$v)
                                    },
                                    expression: "scanData.inOrgName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      disabled: _vm.disType,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getInUnit("in")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "收货人:", prop: "receiptUser" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                model: {
                                  value: _vm.scanData.receiptUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "receiptUser", $$v)
                                  },
                                  expression: "scanData.receiptUser",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      false
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "发货库房:", prop: "outStrong" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "350px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { focus: _vm.getDownHouses },
                                  model: {
                                    value: _vm.scanData.outStrong,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.scanData, "outStrong", $$v)
                                    },
                                    expression: "scanData.outStrong",
                                  },
                                },
                                _vm._l(_vm.factoryList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.storeHouseName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "产品明细:" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                disabled:
                                  (_vm.actionType == "update" &&
                                    !_vm.billStatus_101) ||
                                  _vm.onlyRemarkEditable,
                              },
                              on: { click: _vm.openSel },
                            },
                            [_vm._v("添加产品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                data: _vm.plist,
                                border: "",
                                stripe: _vm.tableConfig.stripe,
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "60",
                                  align: _vm.tableConfig.align,
                                },
                              }),
                              _vm._v(" "),
                              false
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "产品分类",
                                      prop: "prodClassName",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编码",
                                  prop: "productCode",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "定制产品",
                                  prop: "isCustom",
                                  align: _vm.tableConfig.align,
                                  "min-width": "80",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          row.isCustom == 1
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("是")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.isCustom == 0
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v("否")]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3981881926
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productName",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装比例",
                                  prop: "packRadioDesc",
                                  align: _vm.tableConfig.align,
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.packScaleDesc
                                                  ? row.packScaleDesc
                                                  : row.packRadioDesc
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2086150584
                                ),
                              }),
                              _vm._v(" "),
                              false
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "包装比例描述",
                                      prop: "packScaleDesc",
                                      align: _vm.tableConfig.align,
                                      width: "80",
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              false
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "生产批次",
                                      align: _vm.tableConfig.align,
                                      width: "160",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    margin: "20px auto",
                                                  },
                                                  attrs: {
                                                    label: "",
                                                    "label-width": "0",
                                                    prop: "prodBatchNo",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      maxlength: "20",
                                                      min: "1",
                                                    },
                                                    model: {
                                                      value: row.prodBatchNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "prodBatchNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.prodBatchNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3275127602
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "总瓶数",
                                  align: _vm.tableConfig.align,
                                  prop: "planScanAllNum",
                                  width: "180",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: {
                                                margin: "20px auto",
                                              },
                                              attrs: {
                                                label: "",
                                                "label-width": "0",
                                                prop: "planScanAllNum",
                                              },
                                            },
                                            [
                                              _c("el-input-number", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  type: "number",
                                                  controls: false,
                                                  disabled:
                                                    _vm.disType ||
                                                    _vm.onlyRemarkEditable,
                                                  placeholder: "请输入",
                                                  maxlength: "6",
                                                  min: 0,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.setSumNew(
                                                      $event,
                                                      row,
                                                      "box"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: row.planScanAllNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "planScanAllNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.planScanAllNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3675887388
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "箱数",
                                  prop: "planScanNum",
                                  align: _vm.tableConfig.align,
                                  width: "160",
                                },
                              }),
                              _vm._v(" "),
                              false
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "单位",
                                      prop: "packUnit",
                                      align: _vm.tableConfig.align,
                                      width: "80",
                                      "show-overflow-tooltip": "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "零瓶数",
                                  prop: "planScanAtoNum",
                                  align: _vm.tableConfig.align,
                                  width: "160",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: _vm.tableConfig.align,
                                  width: "80",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled:
                                                  (_vm.actionType == "update" &&
                                                    !_vm.billStatus_101) ||
                                                  _vm.onlyRemarkEditable,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.proDel(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1875996790
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          key: "pdaMoreDown",
                          attrs: { label: "允许多次下载", prop: "pdaMoreDown" },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.scanData.pdaMoreDown,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "pdaMoreDown", $$v)
                              },
                              expression: "scanData.pdaMoreDown",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: {
                click: function ($event) {
                  return _vm.submitForm(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.DoEnable ? "确 认" : "提 交"))]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v(_vm._s(_vm.DoEnable ? "取 消" : "关 闭")),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "pro-sel-visible": _vm.proShow,
              "channel-id": _vm.channelId,
              "channel-type": _vm.channelType,
              data: _vm.proList,
              "request-enum": _vm.requestEnum,
              "is-code": "1",
            },
            on: {
              "update:data": function ($event) {
                _vm.proList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "in-out-type": _vm.inOutType,
          "shou-fa-type": _vm.shouFaType,
          "supplier-ids": _vm.supplierIds,
          "purchase-type": _vm.purchaseType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
      _vm._v(" "),
      _c("allChannel", {
        attrs: { visible: _vm.allChannel },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
      _vm._v(" "),
      _c("channelDialog", {
        attrs: { visible: _vm.channelShow, title: "选择收货户头经销商" },
        on: { change: _vm.setChannel, close: _vm.channelClose },
      }),
      _vm._v(" "),
      _c("productSelectDialog", {
        ref: "selProduct",
        attrs: { "business-type": "setNcAssociate" },
        on: { change: _vm.setNcAssociate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }