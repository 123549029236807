"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryMarkVlid = queryMarkVlid;
exports.resetValidCode = resetValidCode;
var _request = _interopRequireDefault(require("@/utils/request"));
// const base = process.env.VUE_APP_VERSION2
var mode = process.env.VUE_APP_VERSION1;

// 查询验证码
function queryMarkVlid(params) {
  return (0, _request.default)({
    url: mode + '/api-mark/markValid/queryMarkVlid',
    method: 'post',
    params: params
  });
}

// 重置验证码
function resetValidCode(data) {
  return (0, _request.default)({
    url: mode + '/api-mark/markValid/resetValidCode',
    method: 'post',
    data: data
  });
}
// ///////未使用,重置验证码和日志使用的是api/log/log.js的////////