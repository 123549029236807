"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ccOrgInfoList = ccOrgInfoList;
exports.factoryInfoList = factoryInfoList;
exports.getAnomalousCodeInfoList = getAnomalousCodeInfoList;
exports.getCDS = getCDS;
exports.getChannelListByproduct = getChannelListByproduct;
exports.getChildCodes = getChildCodes;
exports.getCodeContent = getCodeContent;
exports.getCodeInfoList = getCodeInfoList;
exports.getDbUnits = getDbUnits;
exports.getDelearList = getDelearList;
exports.getDicItem = getDicItem;
exports.getJkStoragesDetail = getJkStoragesDetail;
exports.getProDetail = getProDetail;
exports.getRelateStore = getRelateStore;
exports.getSaleOutList = getSaleOutList;
exports.getXgOrgInfo = getXgOrgInfo;
exports.getYwTypes = getYwTypes;
exports.getjcChannelArchivesList = getjcChannelArchivesList;
exports.queryAuthByUserId = queryAuthByUserId;
exports.queryByOrderType = queryByOrderType;
exports.storeHouseInfoList = storeHouseInfoList;
exports.storeTypeInfoList = storeTypeInfoList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 仓储字典
 * @param {*} type 2:单据状态 4:单据来源，7：单据状态，103：工厂代码
 */
function storeTypeInfoList(type) {
  return (0, _request.default)({
    url: '/storage-center/common/lib/type/' + type,
    method: 'get'
  });
}

/**
 * 库房列表
 */
function storeHouseInfoList() {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/ccGetHouseList',
    method: 'get'
  });
}

/**
 * 仓储出入库企业下拉
 */
function ccOrgInfoList() {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/ccOrgInfoList',
    method: 'get'
  });
}

/**
 * 获取渠道档案列表
 * @param {*} data
 */
function getjcChannelArchivesList(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/list',
    method: 'post',
    params: data
  });
}

/**
 * 获取工厂档案下拉框
 */
function factoryInfoList(data) {
  return (0, _request.default)({
    url: '/api-basic/factoryInfo/list',
    method: 'post',
    data: data
  });
}

/**
 * 获取销售公司
 * @param {*} data {orgType :1总部、2销售公司、3生产公司、4原料基地、0部门}
 */
function getXgOrgInfo(data) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

/**
 * 获取销售公司
 * @param {*} data {orgType :1总部、2销售公司、3生产公司、4原料基地、0部门}
 */
function queryAuthByUserId(params) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/queryAuthByUserId',
    method: 'get',
    params: params
  });
}

/**
 * 获取经销商列表
 * @param {*} data
 */
function getDelearList(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/list',
    method: 'post',
    params: data
  });
}

/**
 * 获取数码列表
 */
function getCodeInfoList(params) {
  var url = '/storage-center/billMark/products/mark/' + params.num + '/' + params.numRow;
  if (params.numSplit) {
    url = url + '/' + params.numSplit;
  }
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: params
  });
}

/**
 * 获取异常数码列表
 */
function getAnomalousCodeInfoList(params) {
  console.log(params);
  var url = '/storage-center/billMark/products/errMark/' + params.num + '/' + params.numRow;
  if (params.numSplit) {
    url = url + '/' + params.numSplit;
  }
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: params
  });
}

/**
 * 根据任务单号获取产品明细信息
 */

function getProDetail(relateType, relateNo) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryProdByTaskNo/' + relateType + '/' + relateNo,
    method: 'get'
  });
}

/**
 * 获取销售出库弹窗
 * **/

function getSaleOutList(current, size, data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryOutSaleForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
* 获取出入库业务类型
* **/
function getYwTypes(param) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/queryForPage',
    method: 'get',
    params: param
  });
}

/**
 * 根据字典code,获取相应字典明细内容
 * **/

function getDicItem(param) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getItemByDataDictionaryByCode',
    method: 'get',
    params: param
  });
}

/**
* 获取调拨入库发货单位,收货单位
* */

function getDbUnits(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/getInOutCompany',
    method: 'post',
    data: data
  });
}

/**
* 获取发货单位关联的仓库
**/

function getRelateStore(param) {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/byOrgFactoryId',
    method: 'get',
    params: param
  });
}

/**
* 获取数码详情
* **/

function getCodeContent(param) {
  return (0, _request.default)({
    url: '/storage-center/tradeMarkRelation/queryScanMark',
    method: 'get',
    params: param
  });
}

/**
* 根据父级数码获取子级数码,数码详情
* **/

function getChildCodes(param) {
  return (0, _request.default)({
    url: '/storage-center/tradeMarkRelation/queryChildMark',
    method: 'get',
    params: param
  });
}

/** **
根据供货商获取采购商
***/
function getCDS(data) {
  return (0, _request.default)({
    url: '/api-basic/cc/orderCompany/bySupplierIdList',
    method: 'post',
    data: data
  });
}

/** *
获取寄库库存详情
***/
function getJkStoragesDetail(param) {
  return (0, _request.default)({
    url: '/api-order/tradeOrder/queryDepositForPage',
    method: 'get',
    params: param
  });
}

/**
* 根据出入库类型查询单据
* ***/
function queryByOrderType(param) {
  return (0, _request.default)({
    url: '/storage-center/billConfig/queryByOrderType',
    method: 'post',
    params: param
  });
}
function getChannelListByproduct(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/getChannelListByproduct',
    method: 'get',
    params: param
  });
}