"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    value: {
      default: null,
      type: Number
    }
  },
  data: function data() {
    return {
      transportId: null,
      transportList: [{
        transportId: null,
        transName: ''
      }],
      transportInfo: {
        hasFreeCondition: false,
        transfeeFrees: [{
          freeCityList: []
        }]
      }
    };
  },
  computed: {
    tableTitle: function tableTitle() {
      var titles = [['首件(个)', '运费(元)', '续件(个)', '续费(元)'], ['首重(kg)', '运费(元)', '续重(kg)', '续费(元)'], ['首体积(m³)', '运费(元)', '续体积(m³)', '续费(元)']];
      if (this.transportInfo.chargeType) {
        return titles[this.transportInfo.chargeType];
      }
      return titles[0];
    }
  },
  watch: {
    value: function value(transportId) {
      this.transportId = transportId;
    }
  },
  created: function created() {
    this.getTransportList();
  },
  methods: {
    getTransportList: function getTransportList() {
      // this.$http({
      //   url: this.$http.adornUrl('/shop/transport/list'),
      //   method: 'get',
      //   params: this.$http.adornParams({})
      // }).then(data => {
      //   this.transportList = data
      // })
    },
    changeTransport: function changeTransport(transportId) {
      this.$emit('input', transportId);
      if (!transportId) {
        return;
      }
      // this.$http({
      //   url: this.$http.adornUrl(`/shop/transport/info/${transportId}`),
      //   method: 'get',
      //   params: this.$http.adornParams({})
      // }).then(data => {
      //   this.transportInfo = data
      // })
    }
  }
};
exports.default = _default;