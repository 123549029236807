"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _index = require("@/api/warehouse/checkout/index");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
var _CodeInfoList = _interopRequireDefault(require("./components/CodeInfoList"));
var _anomalousCodeInfoList = _interopRequireDefault(require("./components/anomalousCodeInfoList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default2 = {
  name: 'table-packset',
  components: {
    codeInfo: _CodeInfoList.default,
    anomalousInfo: _anomalousCodeInfoList.default,
    Pagination: _Pagination.default
  },
  props: {
    settype: {
      // 1：拆单，2：查看，3：审核
      type: Number,
      default: function _default() {
        return 2;
      }
    },
    num: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      miniUnit = num;
      row.sumPlanAto = num;
      return num;
      /*
        var num = sum / arr[0]
        for (var i = 1; i < le; i++) {
          num = arr[i] * num
        }
        miniUnit = num
        row.sumPlanAto = num
        return num
      */
    },
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  watch: {
    num: {
      handler: function handler(val, oldval) {
        this.getProductDetail(val);
      }
    },
    settype: {
      handler: function handler(val, oldval) {
        this.funsettype(val);
      }
    }
  },
  data: function data() {
    return {
      proList: [],
      setinfo: false,
      //查看详情显示字段
      showedmolition: false,
      //操作拆单显示字段
      demolitionVisible: false,
      addshow: true,
      btn_loading: false,
      listLoading: false,
      numRow: '',
      formData: {
        nums: 0,
        tableData: [],
        rules: {
          sumPlan: {
            type: 'number',
            required: true,
            message: '请输入计划产值数量',
            trigger: 'change'
          }
        }
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.funsettype(this.settype);
    this.getProductDetail(this.num);
  },
  methods: {
    funsettype: function funsettype(settype) {
      this.addshow = true;
      this.showedmolition = false;
      this.setinfo = false;
      console.log('this.settypethis.settypethis.settype', this.settype);
      if (settype == 1) {
        this.showedmolition = true;
        this.setinfo = false;
      }
      if (settype == 2) {
        this.showedmolition = false;
        this.setinfo = true;
      }
    },
    //获取产品详情
    getProductDetail: function getProductDetail(num) {
      var _this = this;
      this.listLoading = true;
      var that = this;
      that.proList = [];
      (0, _index.getProductDetail)(num).then(function (res) {
        _this.listLoading = false;
        if (res.data.code != 200) {
          _this.proList = [];
          return;
        } else {
          var list = [];
          res.data.data.forEach(function (v, index) {
            if (v.numSplit == null) {
              v['children'] = [];
              list['id_' + v.numRow] = v;
            }
          });
          res.data.data.forEach(function (v, index) {
            if (v.numSplit != null) {
              list['id_' + v.numRow]['children'].push(v);
            }
          });
          for (var i in list) {
            that.proList.push(list[i]);
          }
        }
      }).catch(function () {
        _this.factoryList = [];
      });
    },
    CodeInfoList: function CodeInfoList(row) {
      //数码明细
      console.log('数码明细', row);
      this.$refs.CodeInfoListMain.show(row);
    },
    anomalousCodeInfoList: function anomalousCodeInfoList(row) {
      //异常数码
      console.log('异常数码', row);
      this.$refs.anomalousCodeInfoListMain.show(row);
    },
    //点击拆单弹框显示
    handleDemolition: function handleDemolition(row, type) {
      var _this2 = this;
      var that = this;
      if (type == 2) {
        this.$confirm('是否要取消拆单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.listLoading = true;
          (0, _index.setCancelSplit)(row.num, row.numRow).then(function (res) {
            _this2.listLoading = false;
            var data = res.data;
            if (data.code != 200) {
              _this2.$message.error(data.msg);
              return false;
            }
            _this2.$message({
              message: data.msg,
              type: 'success'
            });
            _this2.getProductDetail(row.num);
          });
        }).catch(function () {});
        return true;
      }
      if (type == 3) {
        //继续增加拆单
        this.addDemolition(row);
        return true;
      }
      if (type == 4) {
        //删除以增加拆单数据
        this.formData.tableData.splice(row, 1);
        this.formData.tableData.forEach(function (v, index) {
          v.numSplit = that.getnumSplit(index);
        });
        return true;
      }
      this.numRow = row.numRow;
      this.demolitionVisible = true;
      this.formData.nums = row.sumPlan;
      this.formData.tableData = [];
      this.addDemolition(row);
    },
    //添加拆单行
    addDemolition: function addDemolition(row) {
      if (!this.getisAddRow()) return false;
      var vs = JSON.parse(JSON.stringify(row));
      vs['sumPlan'] = '';
      vs['numSplit'] = this.getnumSplit(this.formData.tableData.length);
      this.formData.tableData.push(vs);
      console.log('vsvsvsvs', this.formData.tableData);
    },
    getisAddRow: function getisAddRow() {
      var n = 0;
      this.formData.tableData.forEach(function (v) {
        n += v.sumPlan;
      });
      if (n > this.formData.nums) {
        this.$message.error('拆单数量已大于或等于计划总数量');
        return false;
      }
      return true;
    },
    //获取拆单行号
    getnumSplit: function getnumSplit(index) {
      return (Array(2).join('0') + (1 + index)).slice(-2);
    },
    DemolitionSave: function DemolitionSave() {
      var _this3 = this;
      //保存拆单
      var that = this;
      this.$refs['formDom'].validate(function (valid) {
        if (valid) {
          if (!_this3.getisAddRow()) return false;
          var values = that.setDetail();
          if (values.length <= 0) {
            that.$message.error('请输入拆单数据');
            return false;
          }
          _this3.btn_loading = true;
          (0, _index.setDetailSplit)(_this3.num, _this3.numRow, values).then(function (res) {
            _this3.btn_loading = false;
            if (res.data.code != 200) {
              that.$message.error(res.data.msg);
            } else {
              that.$message({
                message: res.data.msg,
                type: 'success'
              });
              that.getProductDetail(that.num);
              that.handleDemolitionClose();
            }
          }).catch(function (err) {
            _this3.btn_loading = false;
            console.log('err', err);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //详情组合
    setDetail: function setDetail() {
      var that = this;
      var values = [];
      if (this.formData.tableData.length > 0) {
        this.formData.tableData.forEach(function (v, index) {
          values.push({
            num: v.num,
            numRow: v.numRow,
            numSplit: v.numSplit,
            packCode: v.packCode,
            //包装比例id
            packUnit: '箱',
            //单位：箱
            prodBatch: v.prodBatch,
            //生产批次号
            prodBrand: v.prodBrand,
            //品牌id
            prodClass: v.prodClass,
            //品牌分类id
            prodCode: v.prodCode,
            //产品id
            sumPlan: v.sumPlan,
            //计划数量
            sumPlanAto: v.sumPlanAto,
            //计划数量最小单位
            sumSettle: '',
            //实际数量
            sumSettleAto: '' //实际数量最小单位
          });
        });
      }

      console.log('values', values);
      return values;
    },
    setSum: function setSum(e) {},
    handleDemolitionClose: function handleDemolitionClose() {
      this.demolitionVisible = false;
      this.formData.nums = 0;
      this.formData.tableData = [];
    }
  }
};
exports.default = _default2;