var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c(
      "div",
      { staticClass: "hyyy-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  [
                    _c("div", { staticClass: "hyyy-top-tit" }, [
                      _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                        _c("span", {
                          staticStyle: { "font-weight": "400", color: "#333" },
                          domProps: {
                            textContent: _vm._s(_vm.$route.meta.title),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "hyyy-top-tit-right" },
                        [
                          _c("admincut", {
                            staticStyle: { "margin-top": "7px" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { staticClass: "hyyy-content" },
                      [
                        _c(
                          "el-main",
                          [
                            _c(
                              "el-tabs",
                              {
                                on: { "tab-click": _vm.handleClick },
                                model: {
                                  value: _vm.activeName,
                                  callback: function ($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName",
                                },
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "被关注回复",
                                      name: "first",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        attrs: {
                                          type: "flex",
                                          justify: "space-between",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("h3", [
                                              _vm._v("被关注回复功能开关"),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "fztxt" }, [
                                              _vm._v(
                                                "\n                        用户关注公众号后，可快速进行自动回复。关闭自动回复之后，将立即对所有用户生效。\n                      "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 1 } },
                                          [
                                            _c("el-switch", {
                                              model: {
                                                value: _vm.value1,
                                                callback: function ($$v) {
                                                  _vm.value1 = $$v
                                                },
                                                expression: "value1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "form",
                                      { attrs: { action: "" } },
                                      [
                                        _c(
                                          "el-tabs",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "400px",
                                            },
                                            attrs: {
                                              type: "border-card",
                                              "label-width": "100px",
                                              "before-leave": _vm.handdc2,
                                            },
                                            on: {
                                              "tab-click": _vm.handleClick2,
                                            },
                                            model: {
                                              value: _vm.tabPosition,
                                              callback: function ($$v) {
                                                _vm.tabPosition = $$v
                                              },
                                              expression: "tabPosition",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  name: "文字",
                                                  label: "文字",
                                                },
                                              },
                                              [
                                                _c("tiny-mce", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "tinymce-height": 200,
                                                    plugins: _vm.plugins,
                                                    toolbar: _vm.toolbar,
                                                  },
                                                  on: {
                                                    input: _vm.onContentChange,
                                                  },
                                                  model: {
                                                    value: _vm.bgztext,
                                                    callback: function ($$v) {
                                                      _vm.bgztext =
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                    },
                                                    expression: "bgztext",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dialog",
                                                  {
                                                    attrs: {
                                                      title: "",
                                                      visible:
                                                        _vm.bzgdialogVisible,
                                                      width: "30%",
                                                      "before-close":
                                                        _vm.handleClose,
                                                    },
                                                    on: {
                                                      "update:visible":
                                                        function ($event) {
                                                          _vm.bzgdialogVisible =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form",
                                                      {
                                                        attrs: {
                                                          model: _vm.bzgclj,
                                                          "label-width": "80px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "文本内容",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.bzgclj
                                                                    .content,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.bzgclj,
                                                                      "content",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "bzgclj.content",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "链接地址",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.bzgclj
                                                                    .url,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.bzgclj,
                                                                      "url",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "bzgclj.url",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dialog-footer",
                                                        attrs: {
                                                          slot: "footer",
                                                        },
                                                        slot: "footer",
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.bzgdialogVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("取 消")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.bzgdialogVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("确 定")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "图片",
                                                  name: "图片",
                                                },
                                              },
                                              [
                                                _vm.proUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProimg,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-upload",
                                                              {
                                                                attrs: {
                                                                  action:
                                                                    _vm.posturl,
                                                                  headers:
                                                                    _vm.myHeaders,
                                                                  "before-upload":
                                                                    _vm.imgSize,
                                                                  "on-success":
                                                                    _vm.handleSuccess,
                                                                  "on-error":
                                                                    _vm.handError,
                                                                  "file-list":
                                                                    _vm.Lists,
                                                                  "show-file-list": false,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "上传图片"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _c(
                                                              "img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "chenkimg",
                                                                  attrs: {
                                                                    alt: "",
                                                                  },
                                                                },
                                                                "img",
                                                                {
                                                                  src:
                                                                    _vm.twImgurl +
                                                                    _vm.proUrl,
                                                                },
                                                                false
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delProurl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "语音",
                                                  name: "语音",
                                                },
                                              },
                                              [
                                                _vm.voUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProvoice,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.voiceUp = true
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传语音"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _c("audio", {
                                                              attrs: {
                                                                src: _vm.voUrl
                                                                  .url,
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.voUrl.name
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delvourl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "视频",
                                                  name: "视频",
                                                },
                                              },
                                              [
                                                _vm.viUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProvideo,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.videoUp = true
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传视频"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _vm.viUrl.name
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.viUrl
                                                                        .name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "未命名"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delviurl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          { staticClass: "btn" },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "autobtn",
                                                    attrs: {
                                                      type: "primary",
                                                      disabled: _vm.isDsb,
                                                    },
                                                    on: { click: _vm.savedata },
                                                  },
                                                  [_vm._v("保存")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "autobtn",
                                                    on: {
                                                      click: _vm.delautomsg,
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "收到消息回复",
                                      name: "second",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        attrs: {
                                          type: "flex",
                                          justify: "space-between",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("h3", [
                                              _vm._v("收到消息回复功能开关"),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "fztxt" }, [
                                              _vm._v(
                                                "\n                        用户关注公众号后，可快速进行自动回复。关闭自动回复之后，将立即对所有用户生效。\n                      "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 1 } },
                                          [
                                            _c("el-switch", {
                                              model: {
                                                value: _vm.value2,
                                                callback: function ($$v) {
                                                  _vm.value2 = $$v
                                                },
                                                expression: "value2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "form",
                                      { attrs: { action: "" } },
                                      [
                                        _c(
                                          "el-tabs",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              height: "400px",
                                            },
                                            attrs: {
                                              type: "border-card",
                                              "label-width": "100px",
                                              "before-leave": _vm.handdc3,
                                            },
                                            on: {
                                              "tab-click": _vm.handleClick3,
                                            },
                                            model: {
                                              value: _vm.tabPosition,
                                              callback: function ($$v) {
                                                _vm.tabPosition = $$v
                                              },
                                              expression: "tabPosition",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tab-pane",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                attrs: {
                                                  label: "文字",
                                                  name: "文字",
                                                },
                                              },
                                              [
                                                _c("tiny-mce", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "tinymce-height": 200,
                                                    plugins: _vm.plugins,
                                                    toolbar: _vm.toolbar,
                                                  },
                                                  on: {
                                                    input: _vm.onContentChange,
                                                  },
                                                  model: {
                                                    value: _vm.sdtext,
                                                    callback: function ($$v) {
                                                      _vm.sdtext =
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                    },
                                                    expression: "sdtext",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dialog",
                                                  {
                                                    attrs: {
                                                      title: "",
                                                      visible:
                                                        _vm.sddialogVisible,
                                                      width: "30%",
                                                      "before-close":
                                                        _vm.handleClose,
                                                    },
                                                    on: {
                                                      "update:visible":
                                                        function ($event) {
                                                          _vm.sddialogVisible =
                                                            $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form",
                                                      {
                                                        attrs: {
                                                          model: _vm.sdclj,
                                                          "label-width": "80px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "文本内容",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.sdclj
                                                                    .content,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.sdclj,
                                                                      "content",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "sdclj.content",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "链接地址",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.sdclj.url,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.sdclj,
                                                                      "url",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "sdclj.url",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "dialog-footer",
                                                        attrs: {
                                                          slot: "footer",
                                                        },
                                                        slot: "footer",
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.sddialogVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("取 消")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.sddialogVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("确 定")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "图片",
                                                  name: "图片",
                                                },
                                              },
                                              [
                                                _vm.proUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProimg,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-upload",
                                                              {
                                                                attrs: {
                                                                  action:
                                                                    _vm.posturl,
                                                                  headers:
                                                                    _vm.myHeaders,
                                                                  "before-upload":
                                                                    _vm.imgSize,
                                                                  "on-success":
                                                                    _vm.handleSuccess,
                                                                  "on-error":
                                                                    _vm.handError,
                                                                  "file-list":
                                                                    _vm.Lists,
                                                                  "show-file-list": false,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "上传图片"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _c(
                                                              "img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "chenkimg",
                                                                  attrs: {
                                                                    alt: "",
                                                                  },
                                                                },
                                                                "img",
                                                                {
                                                                  src:
                                                                    _vm.twImgurl +
                                                                    _vm.proUrl,
                                                                },
                                                                false
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delProurl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "语音",
                                                  name: "语音",
                                                },
                                              },
                                              [
                                                _vm.voUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProvoice,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.voiceUp = true
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传语音"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _c("audio", {
                                                              attrs: {
                                                                src: _vm.voUrl
                                                                  .url,
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.voUrl.name
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delvourl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "视频",
                                                  name: "视频",
                                                },
                                              },
                                              [
                                                _vm.viUrl == ""
                                                  ? _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: { span: 11 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "success",
                                                                  icon: "el-icon-folder",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.getProvideo,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "从素材库选择"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticClass:
                                                              "col-sec",
                                                            attrs: {
                                                              span: 11,
                                                              offset: 2,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.videoUp = true
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传视频"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 4 },
                                                          },
                                                          [
                                                            _vm.viUrl.name
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.viUrl
                                                                        .name
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "未命名"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 1 },
                                                          },
                                                          [
                                                            _c("el-button", {
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.delviurl,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-row",
                                          { staticClass: "btn" },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "autobtn",
                                                    attrs: {
                                                      type: "primary",
                                                      disabled: _vm.isDsb1,
                                                    },
                                                    on: {
                                                      click: _vm.savedata1,
                                                    },
                                                  },
                                                  [_vm._v("保存")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 2 } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "autobtn",
                                                    on: {
                                                      click: _vm.delautomsg,
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "关键词回复",
                                      name: "third",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        attrs: {
                                          type: "flex",
                                          justify: "space-between",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("h3", [
                                              _vm._v("关键词回复开关"),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "fztxt" }, [
                                              _vm._v(
                                                "\n                        粉丝发送的消息中包含关键字将会优先关键字自动回复（如开启了完全匹配，需粉丝发送与设置一样的关键字才会自动回复）。关闭自动回复之后，将立即对所有用户生效。\n                      "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 1 } },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": "1",
                                                "inactive-value": "2",
                                              },
                                              on: { change: _vm.gjchf },
                                              model: {
                                                value: _vm.value3,
                                                callback: function ($$v) {
                                                  _vm.value3 = $$v
                                                },
                                                expression: "value3",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      {
                                        attrs: {
                                          type: "flex",
                                          justify: "first",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入内容",
                                                "prefix-icon": "el-icon-search",
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.searchkeycode(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.keycode.keyword,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.keycode,
                                                    "keyword",
                                                    $$v
                                                  )
                                                },
                                                expression: "keycode.keyword",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8, offset: 1 } },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "gjcbtn",
                                                attrs: {
                                                  type: "primary",
                                                  size: "medium",
                                                },
                                                on: { click: _vm.addKeyws },
                                              },
                                              [_vm._v("添加关键词")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.keyload,
                                            expression: "keyload",
                                          },
                                        ],
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          data: _vm.keycodeList,
                                          stripe: "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "name",
                                            label: "规则名称",
                                            align: "center",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "val",
                                            label: "关键词",
                                            align: "center",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "type",
                                            label: "匹配条件",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.type == 1
                                                    ? _c("span", [
                                                        _vm._v("部分匹配"),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("全部匹配"),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "发送次数",
                                            prop: "numb",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.numb == null
                                                    ? _c("span", [_vm._v("0")])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(scope.row.numb)
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "操作",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editkey(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "danger",
                                                        size: "mini",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.deleteRow(
                                                            scope.$index,
                                                            scope.row,
                                                            _vm.keycodeList
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-pagination", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.total > 0,
                                          expression: "total > 0",
                                        },
                                      ],
                                      staticClass: "twpage",
                                      attrs: {
                                        background: "",
                                        "current-page": _vm.keycode.pageNo,
                                        "page-size": _vm.keycode.pageSize,
                                        layout: "total,prev, pager, next",
                                        total: _vm.total,
                                      },
                                      on: {
                                        "current-change":
                                          _vm.twhandleCurrentChange,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-dialog",
                                      {
                                        attrs: {
                                          title: _vm.keytit,
                                          visible: _vm.centerDialogVisible,
                                          width: "50%",
                                          center: "",
                                        },
                                        on: {
                                          "update:visible": function ($event) {
                                            _vm.centerDialogVisible = $event
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form",
                                          { attrs: { model: _vm.form } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "规格名称",
                                                  "label-width":
                                                    _vm.formLabelWidth,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    autocomplete: "off",
                                                    maxlength: "60",
                                                    "show-word-limit": "",
                                                  },
                                                  model: {
                                                    value: _vm.form.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "关键词",
                                                  "label-width":
                                                    _vm.formLabelWidth,
                                                },
                                              },
                                              [
                                                _vm._l(
                                                  _vm.form.wxKeywordDOList,
                                                  function (t, index) {
                                                    return _c(
                                                      "el-row",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 6 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                attrs: {
                                                                  placeholder:
                                                                    "关键词",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .wxKeywordDOList[
                                                                      index
                                                                    ].type,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form
                                                                          .wxKeywordDOList[
                                                                          index
                                                                        ],
                                                                        "type",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.wxKeywordDOList[index].type",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "部分匹配",
                                                                      value:
                                                                        "1",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "完全匹配",
                                                                      value:
                                                                        "2",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: {
                                                              span: 14,
                                                              offset: 1,
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                "label-width":
                                                                  "80px",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .wxKeywordDOList[
                                                                    index
                                                                  ].val,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form
                                                                        .wxKeywordDOList[
                                                                        index
                                                                      ],
                                                                      "val",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.wxKeywordDOList[index].val",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.form.wxKeywordDOList
                                                          .length == 1
                                                          ? _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 3,
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-circle-plus-outline",
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addKeyword(
                                                                          index,
                                                                          _vm
                                                                            .form
                                                                            .wxKeywordDOList
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 3,
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-remove-outline",
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.reduceKeyword(
                                                                          index,
                                                                          _vm
                                                                            .form
                                                                            .wxKeywordDOList
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-circle-plus-outline",
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addKeyword(
                                                                          index,
                                                                          _vm
                                                                            .form
                                                                            .wxKeywordDOList
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                          部分匹配指包含该关键词即有效；完全匹配指与输入关键词完全相同才有效！\n                        "
                                                  ),
                                                ]),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "回复内容",
                                                  "label-width":
                                                    _vm.formLabelWidth,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _vm._l(
                                                      _vm.form
                                                        .wxContentReplyDOList,
                                                      function (item, index) {
                                                        return _c(
                                                          "el-row",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "wxContentReplyDOList",
                                                            attrs: {
                                                              type: "flex",
                                                              justify: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 22,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-tabs",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "80%",
                                                                        height:
                                                                          "300px",
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    attrs: {
                                                                      type: "border-card",
                                                                      dat: (_vm.form.wxContentReplyDOList[
                                                                        index
                                                                      ].type =
                                                                        _vm.keyWordtype[
                                                                          index
                                                                        ].name),
                                                                    },
                                                                    on: {
                                                                      "tab-click":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClick1(
                                                                            index,
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .keyWordtype[
                                                                          index
                                                                        ].name,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .keyWordtype[
                                                                              index
                                                                            ],
                                                                            "name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "keyWordtype[index].name",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-tab-pane",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "文本",
                                                                          name: "1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "textarea",
                                                                                rows: 8,
                                                                                placeholder:
                                                                                  "请输入内容",
                                                                                maxlength:
                                                                                  "600",
                                                                                "show-word-limit":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changetext(
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .cen[
                                                                                    index
                                                                                  ]
                                                                                    .content,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .cen[
                                                                                        index
                                                                                      ],
                                                                                      "content",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "cen[index].content",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-tab-pane",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "图片",
                                                                          name: "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .gjcimg[
                                                                          index
                                                                        ].con ==
                                                                        ""
                                                                          ? _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "success",
                                                                                            icon: "el-icon-folder",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            _vm.getProimg,
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "从素材库选择"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                        offset: 2,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-upload",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            action:
                                                                                              _vm.posturl,
                                                                                            headers:
                                                                                              _vm.myHeaders,
                                                                                            "before-upload":
                                                                                              _vm.imgSize,
                                                                                            "on-success":
                                                                                              _vm.handleSuccess,
                                                                                            "on-error":
                                                                                              _vm.handError,
                                                                                            "file-list":
                                                                                              _vm.Lists,
                                                                                            "show-file-list": false,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-button",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                type: "primary",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "上传图片"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "chenkimg",
                                                                                          attrs:
                                                                                            {
                                                                                              alt: "",
                                                                                            },
                                                                                        },
                                                                                        "img",
                                                                                        {
                                                                                          src:
                                                                                            _vm.twImgurl +
                                                                                            _vm
                                                                                              .gjcimg[
                                                                                              index
                                                                                            ]
                                                                                              .con,
                                                                                        },
                                                                                        false
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 1,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "danger",
                                                                                            icon: "el-icon-delete",
                                                                                            size: "mini",
                                                                                            circle:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.delProurl(
                                                                                                index
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-tab-pane",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "语音",
                                                                          name: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .gjcvo[
                                                                          index
                                                                        ].url ==
                                                                        ""
                                                                          ? _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "success",
                                                                                            icon: "el-icon-folder",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            _vm.getProvoice,
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "从素材库选择"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                        offset: 2,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.voiceUp = true
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "上传语音"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "audio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: _vm
                                                                                              .gjcvo[
                                                                                              index
                                                                                            ]
                                                                                              .url,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .gjcvo[
                                                                                              index
                                                                                            ]
                                                                                              .name
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 1,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "danger",
                                                                                            icon: "el-icon-delete",
                                                                                            size: "mini",
                                                                                            circle:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.delvourl(
                                                                                                index
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-tab-pane",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "视频",
                                                                          name: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .gjcvi[
                                                                          index
                                                                        ].url ==
                                                                        ""
                                                                          ? _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "success",
                                                                                            icon: "el-icon-folder",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            _vm.getProvideo,
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "从素材库选择"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-sec",
                                                                                    attrs:
                                                                                      {
                                                                                        span: 11,
                                                                                        offset: 2,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.videoUp = true
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "上传视频"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "el-row",
                                                                              [
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 4,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm
                                                                                      .gjcvi[
                                                                                      index
                                                                                    ]
                                                                                      .name
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            domProps:
                                                                                              {
                                                                                                textContent:
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .gjcvi[
                                                                                                      index
                                                                                                    ]
                                                                                                      .name
                                                                                                  ),
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "未命名"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-col",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        span: 1,
                                                                                        offset: 2,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            type: "danger",
                                                                                            icon: "el-icon-delete",
                                                                                            size: "mini",
                                                                                            circle:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.delviurl(
                                                                                                index
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                staticClass:
                                                                  "delicon",
                                                                attrs: {
                                                                  span: 2,
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.form
                                                                          .wxContentReplyDOList
                                                                          .length >
                                                                        1,
                                                                      expression:
                                                                        "form.wxContentReplyDOList.length > 1",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "el-icon-remove-outline",
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delcontent(
                                                                          index,
                                                                          _vm
                                                                            .form
                                                                            .wxContentReplyDOList
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          "text-align":
                                                            "center",
                                                          "margin-top": "10px",
                                                        },
                                                        attrs: { span: 24 },
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "-206px",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                            icon: "el-icon-circle-plus-outline",
                                                            size: "small",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addcontent(
                                                                _vm.form
                                                                  .wxContentReplyDOList
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "",
                                                  "label-width":
                                                    _vm.formLabelWidth,
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    attrs: {
                                                      "label-width":
                                                        _vm.formLabelWidth,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          出现同时可匹配多个关键词时，回复方式为：\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: { label: "1" },
                                                    model: {
                                                      value: _vm.form.hfType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "hfType",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.hfType",
                                                    },
                                                  },
                                                  [_vm._v("随机回复一条")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: { label: "2" },
                                                    model: {
                                                      value: _vm.form.hfType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "hfType",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.hfType",
                                                    },
                                                  },
                                                  [_vm._v("全部回复")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "dialog-footer",
                                            attrs: { slot: "footer" },
                                            slot: "footer",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.centerDialogVisible = false
                                                  },
                                                },
                                              },
                                              [_vm._v("取 消")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addKeyWordReply(
                                                      _vm.form
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("确 定")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dialog",
                              {
                                attrs: {
                                  title: "选择图片",
                                  visible: _vm.dialogVisibletp,
                                  width: "70%",
                                  "before-close": _vm.handleClose,
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.dialogVisibletp = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading1,
                                        expression: "loading1",
                                      },
                                    ],
                                    staticStyle: {
                                      "flex-wrap": "wrap",
                                      "align-content": "space-around",
                                    },
                                    attrs: { type: "flex", justify: "start" },
                                  },
                                  _vm._l(_vm.Lists, function (o, index) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "margin-bottom": "20px",
                                        },
                                        attrs: { span: 3 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "imglist",
                                            on: {
                                              click: function ($event) {
                                                return _vm.tabImg(index, o)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "img",
                                              _vm._b(
                                                { attrs: { alt: "" } },
                                                "img",
                                                { src: _vm.twImgurl + o.url },
                                                false
                                              )
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  _vm.clickCheck != index
                                                    ? "hide"
                                                    : "sucheck",
                                                ],
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-check",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-pagination", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.total > 0,
                                      expression: "total>0",
                                    },
                                  ],
                                  staticClass: "imgpage",
                                  attrs: {
                                    background: "",
                                    "current-page": _vm.formInline.pageNo,
                                    "page-size": _vm.formInline.pageSize,
                                    layout: "total,prev, pager, next",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "current-change":
                                      _vm.imghandleCurrentChange,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "dialog-footer",
                                    attrs: { slot: "footer" },
                                    slot: "footer",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisibletp = false
                                          },
                                        },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisibletp = false
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dialog",
                              {
                                attrs: {
                                  title: "选择语音",
                                  visible: _vm.dialogVisibleyy,
                                  width: "70%",
                                  "before-close": _vm.handleClose,
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.dialogVisibleyy = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loading2,
                                            expression: "loading2",
                                          },
                                        ],
                                        staticStyle: { width: "70%" },
                                        attrs: {
                                          data: _vm.voLists,
                                          "highlight-current-row": "",
                                          fit: "",
                                        },
                                        on: {
                                          "current-change":
                                            _vm.handleCurrentChangevo,
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "name",
                                            label: "语音名称",
                                            width: "180",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "fileSize",
                                            label: "大小",
                                            width: "180",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "createTime",
                                            label: "更新时间",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: { label: "操作" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { size: "mini" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.pitchOn(
                                                            scope.$index,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选中")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-pagination", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.total > 0,
                                          expression: "total>0",
                                        },
                                      ],
                                      staticClass: "imgpage",
                                      attrs: {
                                        background: "",
                                        "current-page": _vm.formInline.pageNo,
                                        "page-size": _vm.formInline.pageSize,
                                        layout: "total,prev, pager, next",
                                        total: _vm.total,
                                      },
                                      on: {
                                        "current-change":
                                          _vm.vohandleCurrentChange,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "dialog-footer",
                                    attrs: { slot: "footer" },
                                    slot: "footer",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisibleyy = false
                                          },
                                        },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisibleyy = false
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dialog",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading3,
                                    expression: "loading3",
                                  },
                                ],
                                attrs: {
                                  title: "选择视频",
                                  visible: _vm.dialogVisiblesp,
                                  width: "70%",
                                  "before-close": _vm.handleClose,
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.dialogVisiblesp = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.viloaing,
                                        expression: "viloaing",
                                      },
                                    ],
                                    ref: "singleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.viLists,
                                      fit: "",
                                      "highlight-current-row": "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "name",
                                        label: "名称",
                                        width: "180",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "fileSize",
                                        label: "大小",
                                        width: "180",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "状态", width: "180" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.mediaId == "" || null
                                                ? _c("el-col", [
                                                    _vm._v("未通过"),
                                                  ])
                                                : _c("el-col", [
                                                    _vm._v("通过"),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "createTime",
                                        label: "更新时间",
                                        width: "230",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(scope.row.createTime) +
                                                  "\n                    "
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "操作", width: "330" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "mini" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.xzLoadvi(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选中")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "small",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.previewvi(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("预览")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-dialog",
                                                {
                                                  attrs: {
                                                    "append-to-body": "",
                                                    title: "视频预览",
                                                    visible: _vm.previ,
                                                    width: "30%",
                                                    center: "",
                                                  },
                                                  on: {
                                                    "update:visible": function (
                                                      $event
                                                    ) {
                                                      _vm.previ = $event
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "previ" },
                                                    [
                                                      _c(
                                                        "video",
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              controls:
                                                                "controls",
                                                            },
                                                          },
                                                          "video",
                                                          { src: _vm.viSrc },
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-pagination", {
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    background: "",
                                    "current-page": _vm.formInline.pageNo,
                                    "page-size": _vm.formInline.pageSize,
                                    layout: "total,prev, pager, next",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "current-change": _vm.vihandleCurrentChange,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "dialog-footer",
                                    attrs: { slot: "footer" },
                                    slot: "footer",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisiblesp = false
                                          },
                                        },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.dialogVisiblesp = false
                                          },
                                        },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dialog",
                              {
                                attrs: {
                                  title: "上传语音",
                                  visible: _vm.voiceUp,
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.voiceUp = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.voload,
                                        expression: "voload",
                                      },
                                    ],
                                    attrs: { model: _vm.voiceForm },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "语音名称",
                                          "label-width": _vm.formLabelWidth,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { autocomplete: "off" },
                                          model: {
                                            value: _vm.voiceForm.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.voiceForm,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "voiceForm.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "语音内容",
                                          "label-width": _vm.formLabelWidth,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            ref: "uploadvo",
                                            attrs: {
                                              data: _vm.voiceForm,
                                              action: _vm.posturl3,
                                              headers: _vm.myHeaders,
                                              "on-success": _vm.vohandleSuccess,
                                              "before-upload": _vm.voSize,
                                              "auto-upload": _vm.autoup,
                                              "on-change": _vm.vochange,
                                              "on-exceed": _vm.handleExceed,
                                              accept:
                                                ".MP3, .mp3, .WAV, .wav, .WMA, .wma, .AMR, .amr",
                                              "show-file-list": true,
                                              limit: 1,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("上传语音")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__tip",
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        格式支持mp3、wma、wav、amr，文件大小不超过2M\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dialog-footer",
                                    attrs: { slot: "footer" },
                                    slot: "footer",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.voiceUp = false
                                          },
                                        },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.upLoadvo },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dialog",
                              {
                                staticStyle: { "text-align": "left" },
                                attrs: {
                                  title: "上传视频",
                                  visible: _vm.videoUp,
                                  "append-to-body": "",
                                },
                                on: {
                                  "update:visible": function ($event) {
                                    _vm.videoUp = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.viload,
                                        expression: "viload",
                                      },
                                    ],
                                    attrs: { model: _vm.videoForm },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "视频名称",
                                          "label-width": _vm.formLabelWidth,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { autocomplete: "off" },
                                          model: {
                                            value: _vm.videoForm.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoForm,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "videoForm.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "视频内容",
                                          "label-width": _vm.formLabelWidth,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            ref: "uploadvi",
                                            attrs: {
                                              action: _vm.posturl3,
                                              data: _vm.videoForm,
                                              headers: _vm.myHeaders,
                                              "auto-upload": _vm.autoup,
                                              "on-success": _vm.vihandleSuccess,
                                              "on-change": _vm.vichange,
                                              "before-upload": _vm.viSize,
                                              multiple: "",
                                              accept: ".MP4, .mp4",
                                              limit: 1,
                                              "show-file-list": true,
                                              "on-exceed": _vm.handleExceed,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("上传视频")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__tip",
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        请上传时长小于10m的视频，支持mp4视频格式，超出限制的视频请到腾讯视频上传。\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dialog-footer",
                                    attrs: { slot: "footer" },
                                    slot: "footer",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.videoUp = false
                                          },
                                        },
                                      },
                                      [_vm._v("取 消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.upLoadvi },
                                      },
                                      [_vm._v("确 定")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }