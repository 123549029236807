"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _winningLottery = require("@/api/operationalTools2/winningLottery2");
var _exchangeRecord = require("@/api/operationalTools2/exchangeRecord2");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsexchangeRecordindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsexchangeRecordindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    // 奖品形式
    prizeType: function prizeType(msg) {
      if (msg == 100) {
        return '红包';
      } else if (msg == 200) {
        return '实物';
      } else if (msg == 300) {
        return '优惠券';
      } else if (msg == 400) {
        return '积分';
      } else if (msg == 500) {
        return '字卡';
      } else if (msg == 900) {
        return '礼包';
      } else if (msg == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    // 领取状态
    sendStatus: function sendStatus(msg) {
      if (msg == 1) {
        return '未发放';
      } else if (msg == 2) {
        return '已发放';
      } else if (msg == 3) {
        return '未核销';
      } else if (msg == 4) {
        return '已核销';
      } else if (msg == 5) {
        return '失败';
      } else {
        return '';
      }
    },
    // 状态
    wxStatus: function wxStatus(msg) {
      if (msg == 0) {
        return '正常';
      } else if (msg == 1) {
        return '限制';
      } else if (msg == 2) {
        return '黑名单';
      } else {
        return '';
      }
    }
  },
  data: function data() {
    return {
      actList: [],
      agentList: [],
      prizeList: [],
      productList: [],
      queryParams: {
        activityId: '',
        exchangeEndDate: '',
        exchangeStartDate: '',
        markCode: '',
        openIdOrUserId: '',
        prizeId: '',
        sendEndDate: '',
        sendStartDate: '',
        sendStatus: '',
        winStatus: ''
      },
      current: 1,
      size: 10,
      total: 0,
      expandTxt: '展开',
      showCol: false,
      rangeTime: '',
      rangeTime2: '',
      // 表格
      listLoading: false,
      tableData: [],
      collectInfo: [],
      show: true,
      look: false
    };
  },
  activated: function activated() {
    // this.getActivityList()
    this.getActivity();
    this.getAgentList();
    this.getPrizeList();
    this.getProductList();
    var hasCache1 = this.tableData.length > 0;
    // 其他页面体跳转到本页
    this.queryParams.activityId = this.$route.params.actid ? this.$route.params.actid : '';
    this.queryParams.openIdOrUserId = this.$route.params.userId ? this.$route.params.userId : '';
    if (this.queryParams.activityId || this.queryParams.openIdOrUserId || !hasCache1) {
      this.queryParams.joinEndDate = '';
      this.queryParams.markCode = '';
      this.queryParams.sendEndDate = '';
      this.queryParams.sendStartDate = '';
      this.queryParams.sendStatus = '';
      this.queryParams.winStatus = '';
      this.getCurrentData();
      this.getExchangeRecordPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    routerErrorPrize: function routerErrorPrize(mchBillNo, isto) {
      if (isto) {
        this.$message.error('该数据未查询到失败记录');
        return;
      }
      this.$router.push({
        name: 'marketingManageoperationalToolsawardFailureListindex',
        params: {
          mchBillNo: mchBillNo
        }
      });
    },
    getCurrentData: function getCurrentData() {
      var startDate = (0, _utils.parseTime)(new Date(new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000 * 31));
      var endDate = (0, _utils.parseTime)(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1));
      this.rangeTime = [startDate, endDate];
      this.sj(this.rangeTime);
    },
    // 活动名称
    getActivityList: function getActivityList() {
      var _this = this;
      (0, _winningLottery.getActivityList)().then(function (res) {
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this.actList = res.data.data;
          }
        }
      });
    },
    // 新活动名称下拉
    getActivity: function getActivity() {
      var _this2 = this;
      (0, _winningLottery.getActivity)().then(function (res) {
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this2.actList = res.data.data;
          }
        }
      });
    },
    // 经销商
    getAgentList: function getAgentList() {
      var _this3 = this;
      (0, _winningLottery.getAgentList)().then(function (res) {
        if (res.data.data.errCode) {
          _this3.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this3.agentList = res.data.data;
          }
        }
      });
    },
    // 奖项
    getPrizeList: function getPrizeList() {
      var _this4 = this;
      (0, _winningLottery.getPrizeList)().then(function (res) {
        if (res.data.data.errCode) {
          _this4.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this4.prizeList = res.data.data;
          }
        }
      });
    },
    // 活动产品
    getProductList: function getProductList() {
      var _this5 = this;
      (0, _winningLottery.getProductList)().then(function (res) {
        if (res.data.data.errCode) {
          _this5.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data && !res.data.errCode) {
            _this5.productList = res.data.data;
          }
        }
      });
    },
    // 获取中奖流水列表
    getExchangeRecordPage: function getExchangeRecordPage() {
      this.listLoading = true;
      var that = this;
      (0, _exchangeRecord.getExchangeRecordPage)(this.current, this.size, this.queryParams).then(function (res) {
        if (res.data.data.errCode == 500) {
          that.$message.error(res.data.data.errMsg);
          that.total = 0;
          that.tableData = [];
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    showPrizeType: function showPrizeType(row) {
      if (row.prizeType == 100) {
        return '红包';
      } else if (row.prizeType == 200) {
        return '实物';
      } else if (row.prizeType == 300) {
        return '优惠券';
      } else if (row.prizeType == 400) {
        return '积分';
      } else if (row.prizeType == 500) {
        return '字卡';
      } else if (row.prizeType == 900) {
        return '礼包';
      } else if (row.prizeType == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 重置搜索框
    resetWinningLottery: function resetWinningLottery() {
      this.current = 1;
      this.$refs['queryParams'].resetFields();
      this.queryParams.activityId = '';
      this.queryParams.openIdOrUserId = '';
      this.rangeTime = '';
      this.rangeTime2 = '';
      this.queryParams.exchangeStartDate = '';
      this.queryParams.exchangeEndDate = '';
      this.queryParams.sendStartDate = '';
      this.queryParams.sendEndDate = '';
      console.log(this.queryParams);
      this.getCurrentData();
      this.getExchangeRecordPage();
    },
    // 搜索
    querylist: function querylist() {
      this.current = 1;
      this.getExchangeRecordPage();
    },
    sj: function sj(res) {
      console.log(res);
      if (res) {
        this.queryParams.exchangeStartDate = res[0];
        this.queryParams.exchangeEndDate = res[1];
      } else {
        this.queryParams.exchangeStartDate = '';
        this.queryParams.exchangeEndDate = '';
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.queryParams.sendStartDate = res[0];
        this.queryParams.sendEndDate = res[1];
      } else {
        this.queryParams.sendStartDate = '';
        this.queryParams.sendEndDate = '';
      }
    },
    // 导出中奖流水
    exportLs: function exportLs() {
      var _this6 = this;
      // console.log(this.queryParams, 123);
      (0, _exchangeRecord.getExprotExchangeExcel)(this.queryParams).then(function (res) {
        setTimeout(function () {
          _this6.$message.success('导出成功！');
        }, 2 * 800);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '兑换记录.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  }
};
exports.default = _default;