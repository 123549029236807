"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _factory = require("@/api/basic/factory");
var _basic = require("@/api/mark/basic");
var _store = require("@/api/store");
var _lithService = require("@/api/lithService");
var _dictionary = require("@/api/systems/dictionary");
var _org = require("@/api/basic/org");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _UploadImgS = _interopRequireDefault(require("@/components/UploadImgS"));
var _utils = require("@/utils");
var _detail = _interopRequireDefault(require("./detail.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var planNum = '';
var groupList = [];
var _default = {
  name: 'StoreServicestoreManageproductionPlanindex',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    UploadImg: _UploadImgS.default,
    Arrange: _detail.default
  },
  filters: {
    groupName: function groupName(status) {
      var t = '';
      groupList.forEach(function (item) {
        if (item.figure == status) {
          t = item.name;
        }
      });
      return t;
    },
    minSum: function minSum(sum, packScaleExpression) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum / arr[0];
      miniUnit = arr[le - 1] * num;
      return arr[le - 1] * num;
    },
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    sum: function sum(_sum, packScaleExpression) {
      console.log(_sum, packScaleExpression, '总和');
      if (!_sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      planNum = parseInt(_sum / arr[le - 1]);

      // var num = sum / arr[0]
      return planNum;
    },
    statusText: function statusText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '草稿',
        5: '已创建，待审核',
        7: '已审核，未通过',
        10: '已审核，待下载',
        13: '已下载，执行中',
        16: '执行完成'
      };
      return statusMap[status];
    },
    uploadMode: function uploadMode(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '按托上传',
        1: '整单上传'
      };
      return statusMap[status];
    },
    labelText: function labelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '前关联',
        1: '后关联'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: 'ERP',
        1: '平台',
        2: '产线',
        3: 'PDA'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1].unitName;
      } else if (arr.length == 3) {
        name = arr[2].unitName;
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0].unitName;
      }
      return name;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    // const checkpackNum = (rule, value, callback) => {
    //   console.log(value)
    //   callback()
    // }
    return {
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      handleShow: false,
      groupShow: false,
      isAudit: false,
      factoryList: [],
      saleorgList: [],
      workshopList: [],
      scanworkshopList: [],
      workshopListLoading: false,
      scanworkshopListLoading: false,
      lineList: [],
      scanlineList: [],
      lineListLoading: false,
      scanlineListLoading: false,
      teamList: [],
      scanteamList: [],
      teamListLoading: false,
      scanteamListLoading: false,
      dialogBaseWine: false,
      boxList: [],
      storeList: [],
      storeSELList: [],
      codeList: [],
      proList: [],
      searchForm: {
        otherTradeBillQuery: {
          templateCode: '',
          templateName: '',
          templateStatus: ''
        },
        current: 1,
        size: 20
      },
      total: 0,
      codetotal: 0,
      tableData: [],
      tableKey: '01004',
      listLoading: false,
      btnLoading: false,
      codeListLoading: false,
      downLoading: false,
      orderNoloading: false,
      mals: '',
      orderStatus: '',
      title: '生产计划详情',
      multipleSelection: [],
      productTime: '',
      scanData: {
        templateName: '',
        templateLevel: '',
        lithiumLevelTemplateDTOS: []
      },
      detailData: {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        encryptBachNo: '',
        packScaleLevelItemDTOList: []
      },
      rules: {
        templateName: [{
          required: true,
          message: '模板名称不能为空，请维护',
          trigger: 'blur'
        }],
        templateLevel: [{
          required: true,
          message: '比例层级不能为空，请维护',
          trigger: 'change'
        }]
      },
      detailForm: {
        current: 1,
        size: 10,
        orderNo: ''
      },
      materialId: '',
      dialogVisible: false,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      groupList: [],
      page2: false,
      tabHeight: '100%',
      submitType: '',
      groupId: '',
      failVisible: false,
      failList: [],
      failQuery: {
        materialId: '',
        orderNo: '',
        current: 1,
        size: 10
      },
      failtotal: 0,
      auditForm: {
        auditReasons: '',
        handlerResult: ''
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      detailtable: [],
      arrangeShow: false,
      isUsed: '',
      shAuth: true,
      traychestScaleCodeList: [],
      dialogChoose: false,
      baseWineLess: {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      },
      baseWineName: '',
      orderList: [],
      channelList: [],
      // baseWineLesslist: {
      //   baseWineCode: '',
      //   baseWineName: '',
      //   btnId: '',
      //   current: 1,
      //   isEnable: 0,
      //   size: 1000
      // },
      tableChoose: [],
      totalBaseWineLess: 0,
      multipleList: [],
      flag: false,
      tableChooselist: [],
      channelSelectType: '',
      pickerOptions: {
        disableDate: function disableDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {
    var that = this;
    var auths = (0, _utils.getAuthBtn)();
    var id = 'scjhsh';
    if (auths.length > 0) {
      for (var i = 0; i < auths.length; i++) {
        if (auths[i].resourceId == id) {
          that.shAuth = true;
          break;
        }
      }
    } else {
      that.shAuth = false;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    this.authId('searchbtn', this.$route.meta.authList);
    this.getList();
  },
  methods: {
    number: function number(e) {
      var _value = e.target.value; // 获取到输入框的value值
      var reg = isNaN(_value); // 判断拿到的value是否为数字
      if (reg) {
        // 若为非数字键，则全部替换为空
        e.target.value = _value.replace(/[^1-9]/g, '');
      }
      if (_value === '0') {
        // 若为0，则替换为空
        e.target.value = _value.replace(/^[^1-9]/g, '');
      }
    },
    changetab: function changetab() {
      this.$forceUpdate();
    },
    changeData: function changeData(flag) {
      if (flag) {
        if (this.searchForm.endTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.startTime = null;
            this.$message.error('开始时间不能大于结束时间！');
          }
        }
      } else {
        if (this.searchForm.startTime) {
          if (this.searchForm.startTime > this.searchForm.endTime) {
            this.searchForm.endTime = null;
            this.$message.error('结束时间不能小于开始时间！');
          }
        }
      }
    },
    // handleSuccess(data) {
    //   this.scanData.poductMaterial = data
    // },
    // handleRemove() {
    //   this.scanData.poductMaterial = ''
    // },
    checkGroup: function checkGroup(id) {
      this.groupId = id;
      this.groupShow = true;
    },
    /**
     *
     * 获取生产工厂
     * @param {*} val
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList() {
      var _this2 = this;
      // this[list + 'Loading'] = true
      (0, _factory.findAllFactory)({}).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.factoryList = res.data.data;
          } else {
            _this2.factoryList = [];
          }
        }
        // this[list + 'Loading'] = false
      }).catch(function () {
        _this2[list + 'Loading'] = false;
      });
    },
    getStoreList: function getStoreList(val) {
      var _this3 = this;
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
            _this3.storeSELList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm = {
        otherTradeBillQuery: {
          templateCode: '',
          templateName: '',
          templateStatus: ''
        }
      };
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this4 = this;
      console.log(this.searchForm, 123);
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择创建结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择创建起始时间');
        return;
      }
      that.listLoading = true;
      var current = that.searchForm.current;
      var size = that.searchForm.size;
      var otherTradeBillQuery = that.searchForm.otherTradeBillQuery;
      (0, _lithService.querymubanPage)(current, size, otherTradeBillQuery).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this4.listLoading = false;
      });
      that.listLoading = false;
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = '';
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.lineList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
        this.getFactoryList(4, val, 'teamList');
      }
    },
    setScanFactory: function setScanFactory(val) {
      if (val == 3) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 4) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 5) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 6) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 7) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 8) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 9) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 10) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 11) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      } else if (val == 12) {
        this.scanData.lithiumLevelTemplateDTOS = [];
        this.scanData.lithiumLevelTemplateDTOS.push({
          levelName: '电芯码',
          levelCount: '',
          markLength: ''
        }, {
          levelName: '保护板码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '电芯条码',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        }, {
          levelName: '',
          levelCount: '1',
          markLength: ''
        });
      }
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        this.getFactoryList(4, val, 'scanteamList');
      }
    },
    add: function add() {
      var _this5 = this;
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '新增导入模板';
      this.isUsed = '';
      this.multipleList = [];
      this.flag = false;
      this.submitType = 1;
      this.getFactoryList(1, '', 'factoryList');
      // this.getStoreList()
      // this.getBoxList()
      this.getCode('storage_product_order_no');
      this.getCode('storage_product_batch_no');
      this.$nextTick(function () {
        _this5.dialogVisible = true;
      });
    },
    openSel: function openSel() {
      if (this.scanData.factoryCode) {
        if (this.proList) {
          this.proList = [];
          this.scanData.planNum = '';
          this.scanData.miniUnit = '';
          this.scanData.materialCode = '';
          this.scanData.traychestScaleCode = '';
        }
        this.$refs.selProduct.searchForm.factoryId = this.scanData.factoryCode;
        this.$refs.selProduct.getList();
        this.$refs.selProduct.proSelVisible = true;
      } else {
        this.$message.error('请选择生产工厂');
      }
    },
    setSel: function setSel(val) {
      console.log(val, '选择产品');
      this.scanData.materialCode = val.pId;
      this.scanData.planNum = '';
      this.proList = [val];
      this.getBoxList(val.productCode);
    },
    // 拖箱比例 列表
    // traychestScaleCodeFun() {
    // },
    setSum: function setSum(e) {
      this.scanData = JSON.parse(JSON.stringify(this.scanData));
      this.scanData.planNum = this.valENnum(e);
    },
    setSum2: function setSum2(e) {
      this.scanData = JSON.parse(JSON.stringify(this.scanData));
      this.scanData.miniUnit = this.valENnum(e);
    },
    proDel: function proDel() {
      this.proList = [];
      this.scanData.planNum = '';
      this.scanData.miniUnit = '';
      this.scanData.materialCode = '';
      this.scanData.traychestScaleCode = '';
    },
    getCode: function getCode(type) {
      var _this6 = this;
      (0, _store.generateOrderSequence)({
        bizType: type
      }).then(function (res) {
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
        } else {
          if (type == 'storage_product_order_no') {
            _this6.scanData.orderNo = res.data.data;
          }
          // if (type == 'storage_product_batch_no') {
          //   this.scanData.batchNo = res.data.data
          // }
        }
      });
    },
    submitForm: function submitForm(temp) {
      var _this7 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          delete _this7.scanData.orderNo;
          _this7.scanData.lithiumLevelTemplateDTOS.forEach(function (el, index) {
            el.markLength = Number(el.markLength);
            el.levelCount = Number(el.levelCount);
            _this7.$set(el, 'markLevel', Number(index + 1));
          });
          var params = JSON.parse(JSON.stringify(_this7.scanData));
          console.log(params);
          if (_this7.submitType == 2) {
            (0, _lithService.editmuban)(params).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.getList();
                _this7.dialogClose();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          } else {
            (0, _lithService.addmuban)(params).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.getList();
                _this7.dialogClose();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          }
        } else {
          _this7.$message.error('请完善信息！');
        }
      });
    },
    dialogClose: function dialogClose() {
      this.$refs.scanForm.resetFields();
      this.scanData = {
        templateName: '',
        templateLevel: '',
        lithiumLevelTemplateDTOS: []
      };
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    handleClose: function handleClose() {
      this.detailData = {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        packScaleLevelItemDTOList: []
      };
      this.$refs.scanData.resetFields();
      this.isAudit = false;
      this.detailVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    scanDetail: function scanDetail(row) {
      var _this8 = this;
      this.authId('detailbtn', this.$route.meta.authList);
      (0, _lithService.getdetailpage)({
        pId: row.pid
      }).then(function (res) {
        if (res.data.code == 200) {
          _this8.detailData = res.data.data;
          _this8.detailtable = res.data.data.lithiumLevelTemplateVOS;
          _this8.detailVisible = true;
        } else {
          _this8.$message.error(res.data.msg);
        }
      });
      this.detailVisible = true;
    },
    DoAudit: function DoAudit(row) {
      var _this9 = this;
      this.authId('scjhsh', this.$route.meta.authList);
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this9.detailData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          console.log(_this9.detailData);
          _this9.isAudit = true;
          _this9.detailVisible = true;
        } else {
          _this9.$message.error(res.data.msg);
        }
      });
    },
    doUpdate: function doUpdate(row) {
      var _this10 = this;
      this.authId('updatebtn', this.$route.meta.authList);
      var that = this;
      this.submitType = 2;
      this.dialogVisible = true;
      this.mloading = true;
      this.title = '编辑导入模板';
      (0, _lithService.getdetailpage)({
        pId: row.pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this10.$message.error(res.data.msg);
          return;
        } else {
          var data = res.data.data;
          _this10.scanData.templateLevel = data.templateLevel;
          _this10.scanData.pId = data.pid;
          _this10.isUsed = row.isUsed;
          _this10.scanData.templateName = data.templateName;
          data.lithiumLevelTemplateVOS.forEach(function (el) {
            that.scanData.lithiumLevelTemplateDTOS.push(el);
          });
        }
        _this10.mloading = false;
      }).catch(function () {
        _this10.mloading = false;
      });
    },
    codeDetail: function codeDetail(orderNo, materialId) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.orderNo = orderNo;
      this.materialId = materialId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this11 = this;
      // this.detailForm.orderNo = this.detailData.orderNo
      (0, _store.pageParentMarkCode)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this11.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this11.codeList = res.data.data.records;
            _this11.codetotal = parseInt(res.data.data.total);
          } else {
            _this11.codeList = [];
            _this11.codetotal = 0;
          }
        }
        _this11.codeListLoading = false;
      }).catch(function () {
        _this11.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this12 = this;
      (0, _store.subMarkCode)({
        markCode: tree.markCode,
        materialId: this.materialId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this12.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    codeClose: function codeClose() {
      this.detailForm = {
        current: 1,
        size: 10,
        orderNo: ''
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    showFailList: function showFailList() {
      this.failVisible = true;
      this.codeListLoading = true;
      this.failQuery.orderNo = this.detailData.orderNo;
      this.failQuery.materialId = this.detailData.materialId;
      this.getFailList();
    },
    getFailList: function getFailList() {
      var _this13 = this;
      (0, _store.backFailMarkCodeList)(this.failQuery).then(function (res) {
        if (res.data.code != 200) {
          _this13.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this13.failList = res.data.data.records;
            _this13.failtotal = parseInt(res.data.data.total);
            _this13.listLoading = false;
          } else {
            _this13.failList = [];
            _this13.failtotal = 0;
          }
        }
        _this13.codeListLoading = false;
      }).catch(function () {
        _this13.codeListLoading = false;
      });
    },
    failClose: function failClose() {
      this.failVisible = false;
      this.failQuery = {
        orderNo: '',
        current: 1,
        size: 10
      };
      this.failList = [];
      this.failtotal = 0;
    },
    del: function del(orderNo) {
      var _this14 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this14.authId('deletebtn', _this14.$route.meta.authList);
        (0, _store.orderdelete)({
          orderNo: orderNo
        }).then(function (res) {
          _this14.authId('searchbtn', _this14.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this14.$message.error(data.msg);
            return false;
          }
          _this14.$message({
            message: '删除成功',
            type: 'success'
          });
          _this14.getList();
        });
      }).catch(function () {
        _this14.authId('searchbtn', _this14.$route.meta.authList);
      });
    },
    // 导出功能
    download: function download() {
      var _this15 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _store.orderexportExcel)(this.searchForm).then(function (res) {
        _this15.authId('searchbtn', _this15.$route.meta.authList);
        if (res.status != 200) {
          _this15.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产计划单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this15.downLoading = false;
      }).catch(function () {
        _this15.authId('searchbtn', _this15.$route.meta.authList);
        _this15.downLoading = false;
      });
    },
    auditReset: function auditReset() {
      this.$refs.auditForm.resetFields();
      this.auditForm = {
        auditReasons: '',
        handlerResult: ''
      };
    },
    audit: function audit() {
      var _this16 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this16.btnLoading = true;
          if (_this16.auditForm.handlerResult) {
            (0, _store.reviewPass)({
              orderNo: _this16.detailData.orderNo,
              auditReasons: _this16.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this16.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this16.auditReset();
                _this16.handleClose();
                _this16.getList();
              } else {
                _this16.$message.error(res.data.msg);
              }
              _this16.btnLoading = false;
            }).catch(function () {
              _this16.btnLoading = false;
            });
          } else {
            (0, _store.reviewNoPass)({
              orderNo: _this16.detailData.orderNo,
              auditReasons: _this16.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this16.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this16.auditReset();
                _this16.handleClose();
                _this16.getList();
              } else {
                _this16.$message.error(res.data.msg);
              }
              _this16.btnLoading = false;
            }).catch(function () {
              _this16.btnLoading = false;
            });
          }
        } else {
          _this16.$message.error('请完善信息！');
        }
      });
    },
    // 获取基酒列表
    getBaseWineList: function getBaseWineList() {
      var _this17 = this;
      (0, _store.baseWineList)(this.baseWineLess).then(function (res) {
        if (res.data.code !== 200) {
          return _this17.$message.error('获取列表失败！');
        } else {
          _this17.totalBaseWineLess = parseInt(res.data.data.total);
          _this17.tableChoose = res.data.data.records;
          _this17.tableChooselist = res.data.data.records;
        }
      });
    },
    // baseWineList() {
    //   baseWineList(this.baseWineLesslist).then(res => {
    //     if (res.data.code !== 200) {
    //       return this.$message.error('获取列表失败！')
    //     } else {
    //       this.totalBaseWineLess = parseInt(res.data.data.total)
    //       this.tableChooselist = res.data.data.records
    //     }
    //   })
    // },
    // 基酒选择弹框
    baseWineShow: function baseWineShow() {
      var _this18 = this;
      // this.getBaseWineList()
      this.dialogChoose = true;
      this.$nextTick(function () {
        if (_this18.multipleList.length) {
          _this18.$refs.multipleTable.toggleRowSelection(_this18.multipleList[0], true);
        } else {
          _this18.$refs.multipleTable.clearSelection();
        }
      });
    },
    queryList: function queryList() {
      this.baseWineLess.current = 1;
      this.getBaseWineList();
    },
    restQueryList: function restQueryList() {
      this.baseWineLess = {
        baseWineCode: '',
        baseWineName: '',
        btnId: '',
        current: 1,
        isEnable: 0,
        size: 20
      };
      this.tableChoose = [];
      this.getBaseWineList();
    },
    handleSaveTemplateType: function handleSaveTemplateType(data) {
      this.dialogChoose = false;
      this.scanData.baseWineCode = this.multipleList[0].baseWineName;
      this.baseWineCode = this.multipleList[0].id;
      this.flag = true;
    },
    resetFormChoose: function resetFormChoose() {
      if (!this.flag) {
        this.multipleList = [];
      }

      // this.scanData.baseWineCode = ''
      // this.$nextTick(() => {
      //   this.$refs.multipleTable.clearSelection()
      // })
      this.dialogChoose = false;
    },
    select: function select(selection, row) {
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      this.multipleList = selection;
      // this.baseWineLess.baseWineName = this.multipleList[0].baseWineName
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    openBaseWine: function openBaseWine() {
      this.dialogBaseWine = true;
    },
    submit: function submit(row) {
      console.log(row);
      this.baseWineName = row.baseWineName;
      this.searchForm.baseWineCode = row.id;
      this.dialogBaseWine = false;
    },
    resetBaseWine: function resetBaseWine() {
      this.dialogBaseWine = false;
    },
    // 新增撤销
    revocation: function revocation(res) {
      var _this19 = this;
      this.$confirm('撤销审核，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _store.postRevoke)({
          orderNo: res.orderNo
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code != 200) {
            _this19.$message.error(res.data.msg);
            return false;
          }
          _this19.$message({
            message: '撤销成功',
            type: 'success'
          });
          _this19.getList();
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {});
    },
    // 启用
    startFun: function startFun(id, status) {
      var _this20 = this;
      this.$confirm(status == 0 ? '确认停用?' : '确认启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _lithService.editStatus)({
          pId: id,
          templateStatus: status
        }).then(function (res) {
          _this20.authId('searchbtn', _this20.$route.meta.authList);
          if (res.data.code == 200) {
            _this20.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this20.getList();
          } else {
            _this20.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        _this20.authId('searchbtn', _this20.$route.meta.authList);
      });
    },
    doArrange: function doArrange(row) {
      this.$refs.insertMain.show();
    }
  }
};
exports.default = _default;