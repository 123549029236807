"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchUpdateStatus = batchUpdateStatus;
exports.largeRegionUpdate = largeRegionUpdate;
exports.largeRegionlist = largeRegionlist;
exports.salesRegionInsert = salesRegionInsert;
exports.salesRegionList = salesRegionList;
exports.salesRegionUpdate = salesRegionUpdate;
exports.selectLargeRegionByParentId = selectLargeRegionByParentId;
exports.selectLargeRegionByType = selectLargeRegionByType;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';

// 根据大区类型获取大区管理-不分页
function selectLargeRegionByType(params) {
  return (0, _request.default)({
    url: modelUrl + '/largeRegion/selectLargeRegionByType',
    method: 'get',
    params: params
  });
}
// 获取大区管理列表 -分页
function largeRegionlist(data) {
  return (0, _request.default)({
    url: modelUrl + '/largeRegion/list',
    method: 'post',
    data: data
  });
}
// 大区修改
function largeRegionUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/largeRegion/update',
    method: 'post',
    data: data
  });
}
// 根据上级大区ID获取下级大区管理-不分页
function selectLargeRegionByParentId(params) {
  return (0, _request.default)({
    url: modelUrl + '/largeRegion/selectLargeRegionByParentId',
    method: 'get',
    params: params
  });
}

// 批量修改销售大区状态
function batchUpdateStatus(data) {
  return (0, _request.default)({
    url: modelUrl + '/salesRegion/batchUpdateStatus',
    method: 'post',
    data: data
  });
}

// 销售大区新增
function salesRegionInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/salesRegion/insert',
    method: 'post',
    data: data
  });
}
// 获取销售大区列表-分页
function salesRegionList(data) {
  return (0, _request.default)({
    url: modelUrl + '/salesRegion/list',
    method: 'post',
    data: data
  });
}
// 销售大区编辑
function salesRegionUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/salesRegion/update',
    method: 'post',
    data: data
  });
}