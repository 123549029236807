"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _compyuser = require("@/api/systems/compyuser");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ScanAuth',
  props: {
    auth: Boolean,
    uname: Object
  },
  data: function data() {
    return {
      activeName: 'first',
      dialogVisible: false,
      mloading: false,
      roleData: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      storelist: []
    };
  },
  watch: {
    auth: function auth(val) {
      console.log(this.uname);
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        var id = that.$parent.id;
        var param = {};
        param.id = id;
        // that.getStoreList(param)
        that.getCurAuth(param);
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.scanAuthShow = false;
      that.dialogVisible = false;
    },
    getCurAuth: function getCurAuth(param) {
      console.log(param);
      var that = this;
      (0, _compyuser.getDataAuthROrg)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data.userDataAuthOrgVOS;
          var resData = res.data.data.userDataAuthStoreVOS;
          var arr = [];
          if (resData.length > 0) {
            resData.forEach(function (item) {
              if (item != null) {
                arr.push(item);
              }
            });
            that.storelist = arr;
          } else {
            that.storelist = [];
          }
          var arr1 = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.orgId;
              obj.label = item.orgName;
              obj.pid = item.orgId;
              obj.parentPid = item.parentId;
              arr1.push(obj);
            });
            that.data = (0, _utils.transTreeData)(arr1, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getStoreList: function getStoreList(param) {
      var that = this;
      (0, _compyuser.getStoreAuth)(param).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          var arr = [];
          if (resData.length > 0) {
            resData.forEach(function (item) {
              if (item != null) {
                arr.push(item);
              }
            });
            that.storelist = arr;
          } else {
            that.storelist = [];
          }
        }
      });
    }
  }
};
exports.default = _default;