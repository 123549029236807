"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _message = require("@/api/systems/message");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SystemmessageManagemessageTemplateindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!status) return '';
      var obj = {
        1: '短信',
        2: '系统',
        3: '邮件',
        4: '默认测试发送'
      };
      return obj[status];
    }
  },
  data: function data() {
    return {
      handleShow: false,
      listQuery: {
        templetCode: '',
        templetType: '',
        templetTitle: '',
        templetContent: '',
        outsideCode: '',
        remark: '',
        current: 1,
        size: 20
      },
      list: [],
      typeList: [{
        figure: 1,
        name: '短息'
      }, {
        figure: 2,
        name: '系统'
      }, {
        figure: 3,
        name: '邮件'
      }
      // { figure: 4, name: '默认测试发送' },
      ],

      tableKey: 'operratelog',
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      dialogVisible: false,
      scanData: {
        outsideCode: '',
        remark: '',
        templetAddress: '1',
        templetCode: '',
        templetContent: '',
        templetTitle: '',
        templetType: ''
      },
      title: '模板创建',
      mloading: false,
      btnLoading: false,
      rules: {
        templetType: [{
          required: true,
          message: '模板类型不能为空，请维护',
          trigger: 'change'
        }],
        templetTitle: [{
          required: true,
          message: '模板标题不能为空，请维护',
          trigger: 'change'
        }],
        templetContent: [{
          required: true,
          message: '模板内容不能为空，请维护',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    var h = (0, _utils.tableHeight)();
    this.$nextTick(function () {
      this.tabHeight = h;
    });
  },
  activated: function activated() {
    // getItemByDataDictionaryByCode({
    //   code: 'XXMBLX',
    // })
    //   .then((res) => {
    //     if (res.data.code != 200) {
    //       this.$message.error(res.data.msg)
    //     } else {
    //       if (res.data.data != null) {
    //         this.typeList = res.data.data
    //       } else {
    //         this.typeList = []
    //       }
    //     }
    //   })
    //   .then(() => {
    this.getList();
    // })
  },

  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _message.messageTemplatelist)(that.listQuery).then(function (response) {
        var res = response.data;
        console.log(res, 'res');
        if (res.code == 200) {
          that.list = res.data.records;
          that.total = parseInt(res.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var that = this;
      that.listQuery.current = 1;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.getList();
    },
    resetForm: function resetForm(formName) {
      var that = this;
      this.listQuery = Object.assign(this.listQuery, {
        templetCode: '',
        templetType: '',
        templetTitle: '',
        templetContent: '',
        outsideCode: '',
        remark: '',
        current: 1
      });
      that.getList();
    },
    dialogClose: function dialogClose() {
      this.scanData = {
        outsideCode: '',
        remark: '',
        templetAddress: '1',
        templetCode: '',
        templetContent: '',
        templetTitle: '',
        templetType: ''
      };
      this.$refs.scanForm.resetFields();
      this.dialogVisible = false;
    },
    add: function add() {
      this.title = '模板创建';
      this.dialogVisible = true;
    },
    update: function update(row) {
      var _this = this;
      this.title = '模板编辑';
      this.dialogVisible = true;
      this.mloading = true;
      (0, _message.messageTemplateldetail)({
        pid: row.pid
      }).then(function (res) {
        _this.mloading = false;
        var data = res.data;
        if (data.code == 200) {
          _this.scanData.pid = data.data.pid;
          var _data$data = data.data,
            outsideCode = _data$data.outsideCode,
            remark = _data$data.remark,
            templetCode = _data$data.templetCode,
            templetContent = _data$data.templetContent,
            templetTitle = _data$data.templetTitle,
            templetType = _data$data.templetType;
          _this.scanData.outsideCode = outsideCode;
          _this.scanData.remark = remark;
          _this.scanData.templetCode = templetCode;
          _this.scanData.templetContent = templetContent;
          _this.scanData.templetTitle = templetTitle;
          _this.scanData.templetType = templetType;
        } else {
          _this.$message.error(res.msg);
        }
      }).catch(function () {
        _this.mloading = false;
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs.scanForm.validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          if (_this2.scanData.pid) {
            (0, _message.messageTemplateupdate)(_this2.scanData).then(function (res) {
              _this2.btnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this2.getList();
                _this2.dialogClose();
              } else {
                _this2.$message.error(data.msg);
              }
            }).catch(function () {
              _this2.btnLoading = false;
            });
          } else {
            (0, _message.messageTemplateadd)(_this2.scanData).then(function (res) {
              _this2.btnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this2.getList();
                _this2.dialogClose();
              } else {
                _this2.$message.error(data.msg);
              }
            }).catch(function () {
              _this2.btnLoading = false;
            });
          }
        } else {
          _this2.$message.error('请完善信息');
        }
      });
    },
    del: function del(id) {
      var _this3 = this;
      var that = this;
      that.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // var auth = this.authId('deletebtn', this.$route.meta.authList)
        (0, _message.messageTemplatedel)({
          pid: id
        }).then(function (res) {
          // var auth = this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this3.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            _this3.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        // var auth = this.authId('searchbtn', this.$route.meta.authList)
      });
    }
  }
};
exports.default = _default;