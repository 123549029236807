var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1600px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "div",
        { staticClass: "steps" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "" } },
            [
              _c("el-step", { attrs: { title: "基本设置" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "活动项设置" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "奖品设置" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 0,
              expression: "active==0",
            },
          ],
          staticClass: "row-bg",
          staticStyle: { padding: "20px 100px" },
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "pageOneForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    rules: _vm.pageOneRules,
                    model: _vm.pageOneForm,
                    "label-position": "right",
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动角色:",
                                prop: "targetType",
                                required: "",
                              },
                            },
                            [
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.pageOneForm.targetType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageOneForm,
                                          "targetType",
                                          $$v
                                        )
                                      },
                                      expression: "pageOneForm.targetType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { value: "0", label: "0" } },
                                      [_vm._v("消费者会员")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动名称:",
                                prop: "activityName",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "不能超过35个汉字",
                                  maxlength: "70",
                                },
                                model: {
                                  value: _vm.pageOneForm.activityName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "activityName",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.activityName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动时间:",
                                prop: "dataTimeRange",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.pageOneForm.dataTimeRange,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "dataTimeRange",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.dataTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "策略组:",
                                prop: "strategyGroupId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      _vm.isZhuiType ||
                                      (_vm.isEditType && _vm.activeType == 2),
                                  },
                                  model: {
                                    value: _vm.pageOneForm.strategyGroupId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "strategyGroupId",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.strategyGroupId",
                                  },
                                },
                                _vm._l(
                                  _vm.strategyList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.groupName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动模板:",
                                prop: "flowId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      _vm.isZhuiType ||
                                      (_vm.isEditType && _vm.activeType == 2),
                                  },
                                  model: {
                                    value: _vm.pageOneForm.flowId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageOneForm, "flowId", $$v)
                                    },
                                    expression: "pageOneForm.flowId",
                                  },
                                },
                                _vm._l(_vm.flowList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.processName,
                                      value: item.processId + "",
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动商户号:",
                                prop: "financeSourceId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      _vm.isZhuiType ||
                                      (_vm.isEditType && _vm.activeType == 2),
                                  },
                                  model: {
                                    value: _vm.pageOneForm.financeSourceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "financeSourceId",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.financeSourceId",
                                  },
                                },
                                _vm._l(_vm.sourceList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.fundConfigurationName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动备注:",
                                prop: "activityExplain",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: 3,
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.pageOneForm.activityExplain,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "activityExplain",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.activityExplain",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.active == 1
        ? _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: { padding: "20px 100px" },
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "pageTwoForm",
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        rules: _vm.pageTwoFormRules,
                        model: _vm.pageTwoForm,
                        "label-position": "right",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联方案:",
                                    prop: "schemeName",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.pageTwoForm.schemeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pageTwoForm,
                                            "schemeName",
                                            $$v
                                          )
                                        },
                                        expression: "pageTwoForm.schemeName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openWays()
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "扫码类型:",
                                    prop: "scanType",
                                    "is-required": "",
                                  },
                                },
                                [
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isZhuiType ||
                                            (_vm.isEditType &&
                                              _vm.activeType == 2),
                                        },
                                        on: { change: _vm.changeVD },
                                        model: {
                                          value: _vm.pageTwoForm.scanType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pageTwoForm,
                                              "scanType",
                                              $$v
                                            )
                                          },
                                          expression: "pageTwoForm.scanType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { value: "1", label: "1" } },
                                          [_vm._v("盖外码")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { value: "2", label: "2" } },
                                          [_vm._v("贴标箱码")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.pageTwoForm.scanType == 1
                            ? _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: 24,
                                    disabled:
                                      _vm.isZhuiType ||
                                      (_vm.isEditType && _vm.activeType == 2),
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动维度:",
                                        prop: "activityDimension",
                                        "is-required": "",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.isZhuiType ||
                                                (_vm.isEditType &&
                                                  _vm.activeType == 2),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.chageweidu(
                                                  _vm.pageTwoForm
                                                    .activityDimension
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.pageTwoForm
                                                  .activityDimension,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.pageTwoForm,
                                                  "activityDimension",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "pageTwoForm.activityDimension",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  value: "2",
                                                  label: "2",
                                                },
                                              },
                                              [_vm._v("任务单维度")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  value: "1",
                                                  label: "1",
                                                },
                                              },
                                              [_vm._v("销售出库维度")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageTwoForm.scanType == 1 &&
                          _vm.pageTwoForm.activityDimension == "2"
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "任务单据:",
                                        prop: "materialCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            disabled: _vm.isacdisable,
                                          },
                                          on: { click: _vm.openSel },
                                        },
                                        [_vm._v("恢复任务单")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "1px",
                                          },
                                          attrs: {
                                            data: _vm.proList,
                                            border: "",
                                            fit: "",
                                            "highlight-current-row": "",
                                            "max-height": "500",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              align: "center",
                                              width: "65",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "任务单号",
                                              align: "center",
                                              prop: "orderNo",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "产品编码",
                                              align: "center",
                                              prop: "materialCode",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "产品名称",
                                              align: "center",
                                              prop: "materialName",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "所在车间",
                                              prop: "shopName",
                                              align: "center",
                                              "min-width": "120",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "所在产线",
                                              prop: "productLineName",
                                              align: "center",
                                              "min-width": "120",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              align: "center",
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.proDel(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("移除")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3702426492
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageTwoForm.scanType == 1 &&
                          _vm.pageTwoForm.activityDimension == "1"
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "选择单据:",
                                        prop: "orderList",
                                        required: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            disabled: _vm.isacdisable,
                                          },
                                          on: { click: _vm.orderListShow },
                                        },
                                        [_vm._v("添加销售出库单")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "1px",
                                          },
                                          attrs: {
                                            data: _vm.pageTwoForm.orderList,
                                            border: "",
                                            fit: "",
                                            "highlight-current-row": "",
                                            "max-height": "500",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "序号",
                                              type: "index",
                                              align: "center",
                                              width: "65",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "单据编号",
                                              align: "center",
                                              prop: "orderCode",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "发货单位",
                                              align: "center",
                                              prop: "seller",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "收货单位",
                                              align: "center",
                                              prop: "buyer",
                                              width: "140",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "发货时间",
                                              prop: "sellTime",
                                              align: "center",
                                              "min-width": "120",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "经销商名称",
                                              prop: "accountName",
                                              align: "center",
                                              "min-width": "120",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              align: "center",
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selDel(
                                                                scope.$index,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("移除")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3454873803
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageTwoForm.scanType == 2
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "套标号段:" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color":
                                              "rgba(250, 250, 251, 1)",
                                            padding: "20px 0",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.pageTwoForm.segmentList,
                                            function (el, i) {
                                              return _c(
                                                "el-row",
                                                { key: i },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: { span: 8 },
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "起始号段:",
                                                            prop:
                                                              "segmentList." +
                                                              i +
                                                              ".startSegment",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "起始号段不能为空",
                                                              trigger: "change",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            directives: [
                                                              {
                                                                name: "Number",
                                                                rawName:
                                                                  "v-Number",
                                                              },
                                                            ],
                                                            attrs: {
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.checkqishihaoduan(
                                                                  el.startSegment,
                                                                  el.endSegment,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                el.startSegment,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    el,
                                                                    "startSegment",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "el.startSegment",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: { span: 8 },
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "截止号段:",
                                                            prop:
                                                              "segmentList." +
                                                              i +
                                                              ".endSegment",
                                                            rules: {
                                                              required: true,
                                                              message:
                                                                "截止号段不能为空",
                                                              trigger: "change",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            directives: [
                                                              {
                                                                name: "Number",
                                                                rawName:
                                                                  "v-Number",
                                                              },
                                                            ],
                                                            attrs: {
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.checkhaoduan(
                                                                  el.startSegment,
                                                                  el.endSegment,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                el.endSegment,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    el,
                                                                    "endSegment",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "el.endSegment",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "inlineItem",
                                                      attrs: { span: 2 },
                                                    },
                                                    [
                                                      i == 0
                                                        ? _c("el-button", {
                                                            staticStyle: {
                                                              float: "right",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              size: "mini",
                                                              icon: "el-icon-plus",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addnumber(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      i >= 1
                                                        ? _c("el-button", {
                                                            staticStyle: {
                                                              float: "right",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              size: "mini",
                                                              icon: "el-icon-delete",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delnumber(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                              attrs: { label: "共计:" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.boxNum) +
                                                    "箱，" +
                                                    _vm._s(_vm.bottleNum) +
                                                    "瓶"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 2,
              expression: "active==2",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "topLabelForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    model: _vm.codeForm,
                    "label-position": "top",
                    "label-width": "120px",
                    rules: _vm.codeFormRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { background: "#f1f1f1", padding: "10px" } },
                    [_vm._v("分组信息")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: {
                                "label-width": "100px",
                                prop: "bottleCount",
                                label: "共计：",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { placeholder: "数量" },
                                  on: {
                                    blur: function ($event) {
                                      _vm.isEditType && _vm.activeType == 2
                                        ? ""
                                        : _vm.cacltotalzushu(
                                            _vm.codeForm.groupCount,
                                            _vm.codeForm.bottleCount
                                          )
                                    },
                                  },
                                  model: {
                                    value: _vm.codeForm.bottleCount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.codeForm, "bottleCount", $$v)
                                    },
                                    expression: "codeForm.bottleCount",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("瓶"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: {
                                "label-width": "100px",
                                prop: "groupCount",
                                label: "每组瓶数：",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  { name: "Number", rawName: "v-Number" },
                                ],
                                attrs: {
                                  disabled:
                                    _vm.isZhuiType ||
                                    (_vm.isEditType && _vm.activeType == 2),
                                  placeholder: "数量",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.cacltotalzushu(
                                      _vm.codeForm.groupCount,
                                      _vm.codeForm.bottleCount
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.codeForm.groupCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.codeForm, "groupCount", $$v)
                                  },
                                  expression: "codeForm.groupCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: {
                                "label-width": "100px",
                                prop: "totalbottleCount",
                                label: "共计：",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { disabled: "", placeholder: "数量" },
                                  model: {
                                    value: _vm.codeForm.totalbottleCount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.codeForm,
                                        "totalbottleCount",
                                        $$v
                                      )
                                    },
                                    expression: "codeForm.totalbottleCount",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("组"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "100px",
                                prop: "lastbottleCount",
                                label: "末组瓶数",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  { name: "Number", rawName: "v-Number" },
                                ],
                                attrs: { disabled: "", placeholder: "数量" },
                                model: {
                                  value: _vm.codeForm.lastbottleCount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.codeForm,
                                      "lastbottleCount",
                                      $$v
                                    )
                                  },
                                  expression: "codeForm.lastbottleCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { background: "#f1f1f1", padding: "10px" } },
                    [_vm._v("奖品信息")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-bottom": "0" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { margin: "0" },
                          attrs: { type: "flex", gutter: 10 },
                        },
                        [
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("奖品设置："),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("奖品类型"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("奖品内部名称"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 2 } }, [
                            _vm._v("每组设奖"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("投放总数量"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("中奖概率"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }, [
                            _vm._v("核销返利"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }, [
                            _vm._v("红包返现"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 5 } }, [
                            _vm._v("奖品总价值（元）"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 1 } }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.codeForm.prizeItemsList,
                        function (item, index) {
                          return _c(
                            "el-row",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "18px" },
                              attrs: { gutter: 10, type: "flex" },
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".priority",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品等级",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "奖品等级",
                                                disabled:
                                                  !_vm.isShowPrizeSetBtn ||
                                                  _vm.isZhuiType ||
                                                  (_vm.isEditType &&
                                                    _vm.activeType == 2),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.checkLevel(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.priority,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "priority",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.priority",
                                              },
                                            },
                                            _vm._l(
                                              _vm.prioritys,
                                              function (
                                                priorityItem,
                                                priorityIndex
                                              ) {
                                                return _c("el-option", {
                                                  key: priorityIndex,
                                                  attrs: {
                                                    label: priorityItem.name,
                                                    value: priorityItem.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            !item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizeType",
                                        rules: {
                                          required: true,
                                          message: "请选择奖品类型",
                                          trigger: "change",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择奖品类型",
                                            disabled:
                                              _vm.isZhuiType ||
                                              (_vm.isEditType &&
                                                _vm.activeType == 2),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.getCurPrizeType(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizeType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizeType", $$v)
                                            },
                                            expression: "item.prizeType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.prizeTypes,
                                          function (prizeItem, prizeTypeIndex) {
                                            return _c("el-option", {
                                              key: prizeTypeIndex,
                                              attrs: {
                                                label: prizeItem.name,
                                                value: prizeItem.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeId",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择奖品",
                                                disabled:
                                                  _vm.isZhuiType ||
                                                  (_vm.isEditType &&
                                                    _vm.activeType == 2),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeprize(
                                                    item.prizeId,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.prizeId,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "prizeId", $$v)
                                                },
                                                expression: "item.prizeId",
                                              },
                                            },
                                            _vm._l(
                                              item.plist,
                                              function (obj, p) {
                                                return _c("el-option", {
                                                  key: p,
                                                  attrs: {
                                                    label: obj.prizeName,
                                                    value: obj.prizeId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 2 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeGroupNum",
                                            rules: {
                                              required: true,
                                              message: "请输入数量",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            directives: [
                                              { name: "Int", rawName: "v-Int" },
                                            ],
                                            attrs: {
                                              placeholder: "数量",
                                              disabled:
                                                _vm.isZhuiType ||
                                                (_vm.isEditType &&
                                                  _vm.activeType == 2),
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.doSum(index)
                                              },
                                            },
                                            model: {
                                              value: item.prizeGroupNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "prizeGroupNum",
                                                  $$v
                                                )
                                              },
                                              expression: "item.prizeGroupNum",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeNum",
                                            rules: {
                                              required: true,
                                              message: "请输入数量",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            directives: [
                                              { name: "Int", rawName: "v-Int" },
                                            ],
                                            attrs: {
                                              placeholder: "投放总数量",
                                              disabled: "",
                                            },
                                            model: {
                                              value: item.prizeNum,
                                              callback: function ($$v) {
                                                _vm.$set(item, "prizeNum", $$v)
                                              },
                                              expression: "item.prizeNum",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizePr",
                                        rules: {
                                          required: true,
                                          message: "请输入中奖概率",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number",
                                              rawName: "v-Number",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "中奖概率",
                                            disabled:
                                              _vm.isZhuiType ||
                                              (_vm.isEditType &&
                                                _vm.activeType == 2),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.checkPrizePr(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizePr,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizePr", $$v)
                                            },
                                            expression: "item.prizePr",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "inlineItem",
                                  staticStyle: {
                                    height: "40px",
                                    "line-height": "40px",
                                    display: "flex",
                                    "padding-right": "0px",
                                    color: "#909399 !important",
                                  },
                                  attrs: { md: 4 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        padding: "0 10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  核销返（元）\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        width: "40%",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.rembursementPrice))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "inlineItem",
                                  staticStyle: {
                                    height: "40px",
                                    "line-height": "40px",
                                    display: "flex",
                                    "padding-right": "0px",
                                    color: "#909399 !important",
                                  },
                                  attrs: { md: 4 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        padding: "0 10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  返利（元）\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        width: "40%",
                                        "text-align": "center",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.flValue))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "inlineItem",
                                  staticStyle: {
                                    height: "40px",
                                    "line-height": "40px",
                                    display: "flex",
                                    "padding-right": "0px",
                                    color: "#909399 !important",
                                  },
                                  attrs: { md: 5 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        padding: "0 4px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  奖品预算\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #DCDFE6",
                                        "background-color": "#F5F7FA",
                                        width: "60%",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.prizeBudgetTotalAmount)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "inlineItem",
                                  attrs: { span: 1 },
                                },
                                [
                                  index == 0
                                    ? _c("el-button", {
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          disabled:
                                            _vm.isZhuiType ||
                                            (_vm.isEditType &&
                                              _vm.activeType == 2),
                                          type: "primary",
                                          size: "mini",
                                          icon: "el-icon-plus",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addCodePrize(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index >= 1
                                    ? _c("el-button", {
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          disabled:
                                            _vm.isZhuiType ||
                                            (_vm.isEditType &&
                                              _vm.activeType == 2),
                                          type: "primary",
                                          size: "mini",
                                          icon: "el-icon-delete",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delCodePrize(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "18px" },
                          attrs: { type: "flex", gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "", value: "谢谢惠顾" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 2 } },
                            [
                              _c("el-input", {
                                directives: [
                                  { name: "Number", rawName: "v-Number" },
                                ],
                                attrs: { placeholder: "数量" },
                                on: { blur: _vm.changethankData },
                                model: {
                                  value: _vm.thanksData.prizeGroupNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.thanksData,
                                      "prizeGroupNum",
                                      $$v
                                    )
                                  },
                                  expression: "thanksData.prizeGroupNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                directives: [
                                  { name: "Number", rawName: "v-Number" },
                                ],
                                attrs: {
                                  placeholder: "数量",
                                  readonly: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.thanksData.prizeNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.thanksData, "prizeNum", $$v)
                                  },
                                  expression: "thanksData.prizeNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { placeholder: "中奖概率" },
                                  model: {
                                    value: _vm.thanksData.prizePr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.thanksData, "prizePr", $$v)
                                    },
                                    expression: "thanksData.prizePr",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "inlineItem",
                              staticStyle: {
                                height: "40px",
                                "line-height": "40px",
                                display: "flex",
                                "padding-right": "0px",
                                color: "#909399 !important",
                              },
                              attrs: { md: 4 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    padding: "0 10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  核销返（元）\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    width: "40%",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("0")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "inlineItem",
                              staticStyle: {
                                height: "40px",
                                "line-height": "40px",
                                display: "flex",
                                "padding-right": "0px",
                                color: "#909399 !important",
                              },
                              attrs: { md: 4 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    padding: "0 10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  返利（元）\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    width: "40%",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("0")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "inlineItem",
                              staticStyle: {
                                height: "40px",
                                "line-height": "40px",
                                display: "flex",
                                "padding-right": "0px",
                                color: "#909399 !important",
                              },
                              attrs: { md: 5 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    padding: "0 4px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  奖品预算\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    border: "1px solid #DCDFE6",
                                    "background-color": "#F5F7FA",
                                    width: "60%",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("0")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { span: 1 } },
                            [
                              _vm.isShowPrizeSetBtn
                                ? _c("el-button", {
                                    staticStyle: {
                                      float: "right",
                                      opacity: "0",
                                    },
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                      circle: "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 0,
                  expression: "active !==0",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.prev },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 2,
                  expression: "active !==2",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.next },
            },
            [_vm._v("下一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2,
                  expression: "active ===2",
                },
              ],
              staticClass: "normalBtn",
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.subLoading,
              },
              on: { click: _vm.finish },
            },
            [_vm._v("完成")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.orderVisible
        ? _c("ordersSelect", {
            attrs: {
              visible: _vm.orderVisible,
              data: _vm.pageTwoForm.orderRowList,
            },
            on: {
              change: _vm.getSelectOrders,
              close: function ($event) {
                _vm.orderVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.h_batcheVisibleShow
        ? _c("Horgan", {
            ref: "h_batchVisible",
            attrs: {
              "old-list": _vm.pageTwoForm.channelRowList,
              newdata: _vm.orgId,
            },
            on: {
              fn: _vm.h_setBatch,
              close: function ($event) {
                _vm.h_batcheVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taskOrdersVisible
        ? _c("taskOrdersSelect", {
            ref: "taskOrdersSelect",
            attrs: {
              visible: _vm.taskOrdersVisible,
              data: _vm.productOrderList,
            },
            on: {
              change: _vm.setTaskOrdersFun,
              close: function ($event) {
                _vm.taskOrdersVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productVisibleShow
        ? _c("ProDialog", {
            ref: "productVisible",
            attrs: {
              "pro-sel-visible": _vm.productVisibleShow,
              data: _vm.pageTwoForm.productRowList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageTwoForm, "productRowList", $event)
              },
              change: _vm.setProduct,
              close: function ($event) {
                _vm.productVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("activityWay", {
            attrs: {
              visible: _vm.factoryShow,
              "parent-scheme-code": _vm.parentSchemeCode,
              type: _vm.actingType,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.proShow
        ? _c("Order", {
            ref: "selProduct",
            attrs: { "pro-sel-visible": _vm.proShow, data: _vm.tichuproList },
            on: {
              "update:data": function ($event) {
                _vm.tichuproList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }