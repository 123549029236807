var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "detailWrap" }, [
            _c("div", { staticClass: "billTitle" }, [
              _c("img", { attrs: { src: require("@/images/topimg.png") } }),
              _vm._v(" "),
              _c("span", { staticClass: "billTitleText" }, [
                _vm._v("稽查回收单 " + _vm._s(_vm.addData.checkCode)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("稽查回收信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("稽查回收单位类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(_vm._f("typeClass")(_vm.addData.sellerType))
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("稽查单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.sellerName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("稽查员:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.creatorName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("收货信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货单位类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(_vm._f("typeClass")(_vm.addData.buyerType))
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.buyerName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "20px" },
              attrs: { shadow: "hover" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("收货仓库信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货仓库:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.storeName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "20px" },
              attrs: { shadow: "hover", "body-style": _vm.cardPadding },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/cpxx.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("稽查明细")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c("div", [
                      _vm._v(
                        "\n            稽查总数量：\n            " +
                          _vm._s(
                            _vm.addData.outNumMaxSum
                              ? _vm.addData.outNumMaxSum + "箱"
                              : "0箱"
                          ) +
                          _vm._s(
                            _vm.addData.outNumMinSum
                              ? _vm.addData.outNumMinSum + "盒"
                              : "0盒"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", margin: "-1px 0 0" },
                  attrs: {
                    "max-height": "600",
                    data: _vm.productList,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "稽查出库单号",
                      align: "center",
                      "min-width": "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.ckck(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.outNo))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "稽查入库单号",
                      align: "center",
                      prop: "productCode",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getflow(row, 2)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.inNo))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      align: "center",
                      prop: "sellerName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品分类",
                      align: "center",
                      prop: "productClassName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      align: "center",
                      prop: "packScaleDesc",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "稽查数量（箱）",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.scanCode(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.orderOutBoxNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "稽查数量（盒）",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.scanCode(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.outNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "数码详情",
            visible: _vm.scanCodeVisible,
            "append-to-body": "",
            width: "1200px !important",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanCodeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.codeParam, "label-width": "80px" } },
            [
              _c(
                "el-row",
                { staticClass: "cols", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数码", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.codeParam.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeParam, "code", $$v)
                              },
                              expression: "codeParam.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "isError", label: "异常状态:" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.codeParam.isError,
                                callback: function ($$v) {
                                  _vm.$set(_vm.codeParam, "isError", $$v)
                                },
                                expression: "codeParam.isError",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "正常", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "异常", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "180px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchCode },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "default",
                            plain: "",
                            icon: "el-icon-refresh",
                          },
                          on: { click: _vm.searchReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.codeData, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "数码",
                  prop: "code",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编号",
                  prop: "productCode",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  "min-width": "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "productClass",
                  "min-width": "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "isError",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(row.isError == 1 ? "正常" : "异常")),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total2 > 0,
                expression: "total2 > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total2,
              page: _vm.codeParam.pageNo,
              limit: _vm.codeParam.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.codeParam, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.codeParam, "pageSize", $event)
              },
              pagination: _vm.getCodeDetails,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ckShow
        ? _c("qtck", {
            attrs: {
              "scan-visible": _vm.ckShow,
              no: _vm.no,
              "order-id": _vm.orderId,
            },
            on: { close: _vm.ckClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }