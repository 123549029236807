var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c(
          "div",
          [
            _c("div", { staticClass: "btnArea" }, [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _vm.hasAuth("addbtn", _vm.apis)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.addBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.addBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addOrUpdateHandle()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dataListLoading,
                    expression: "dataListLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.dataList,
                  "row-key": "categoryId",
                  "element-loading-text": "Loading",
                  fit: "",
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "categoryName",
                    align: _vm.tableConfig.align,
                    "tree-key": "categoryId",
                    width: "150",
                    label: "分类名称",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "pic",
                    align: _vm.tableConfig.align,
                    label: "图片",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              class: { level: scope.row._level > 1 },
                              attrs: { src: scope.row.pic },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    74205990
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "status",
                    align: _vm.tableConfig.align,
                    label: "状态",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "danger" } },
                                  [_vm._v("下线")]
                                )
                              : _c("el-tag", { attrs: { size: "small" } }, [
                                  _vm._v("正常"),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    819769090
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "seq",
                    align: _vm.tableConfig.align,
                    label: "排序号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: _vm.tableConfig.align,
                    label: "操作",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.hasAuth("updatebtn", _vm.apis)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button configText",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOrUpdateHandle(
                                          scope.row.categoryId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasAuth("deletebtn", _vm.apis)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button configText red",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteHandle(
                                          scope.row.categoryId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1487076044
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.addOrUpdateVisible
              ? _c("add-or-update", {
                  ref: "addOrUpdate",
                  on: { refreshDataList: _vm.getDataList },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(0)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }